import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXFanfiction/Chapter/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const CustomFont = makeShortcode("CustomFont");
const Ads = makeShortcode("Ads");
const Divider = makeShortcode("Divider");
const MessageBox = makeShortcode("MessageBox");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The surface was hard, cold and smelled like books. Linhardt raised his head and instinctively searched for his phone, needing to know the time. He took in his surroundings while yawning, noting he had once again fallen asleep in the library. The librarian had given up ages ago trying to wake him up and left him wherever he was.`}</p>
    <CustomFont font="Brush Script MT, Papyrus, Rubik Regular" mdxType="CustomFont">
  ‘<em>Linhardt’s don’t touch.</em>’
    </CustomFont>
    <p>{`He scribbled lazily on a Post-It and stuck it on a pile of books at the table
he sat. Honestly, just the `}<em parentName="p">{`thought`}</em>{` of putting the books away made him tired enough
to doze off upright.`}</p>
    <p>{`He forced his eyes open again, shaking the sleep out of his head and sluggishly dragged himself out, but the night wind was soothing so he decided to stop by the lake to see if there were any fish he could… fish. And ooh yes, there were! His expression wasn’t obvious but he excitedly grabbed the spare fishing rod by the hut and was about to cast a line when he heard commotion coming from the greenhouse.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Linhardt made his way slowly towards it, squinting at the see-through glass. There were two? No, three people. He debated whether it was worth  investigating, and nearly didn’t but his overwhelming sense of curiosity compelled him. He tip-toed over to the entrance despite it being see-through, and peeked at what the fuss was about.`}</p>
    <p>{`“Whoa whoaa, okay boys calm down—  I swear I won't tell anyone and I’ll take it to my `}<em parentName="p">{`grave,`}</em>{` which apparently is going to be soon based on how you’re looking at me right now, Reus…” Claude was slowly backing away as Reus redirected his glare from Dimitri who smooched him, to Claude. “Uh, Dimitri? Buddy? A little help here?”`}</p>
    <p>{`Dimitri stopped in his tracks when Reus turned around and glowered at him, Dimitri held his hands up, signifying he wasn’t a threat. In that split second Linhardt decided he didn’t want to be a part of this ruckus anymore, since he’d probably hear about it in the morning. Gossip tended to spread like wildfire in schools.`}</p>
    <p>{`He removed himself from the bush he was hiding in and sauntered back to the fishing pond when Claude yelled something. Linhardt turned around to see Claude running full speed at him screaming, “`}<em parentName="p">{`Dimitri kissed Reus!`}</em>{`”`}</p>
    <p>{`“....What?”`}</p>
    <p>{`Claude skidded to a stop in front of him before turning to see his chaser gaining on him, “I’m sorry Linhardt I can’t die alone tonight, Dimitri kissed Reus-”`}</p>
    <p>{`Like the tv-documentary Seconds from Disaster, the last thing Linhardt saw was Reus launching himself at Claude who then collided into him. In a comical turn of events, all three crashed to the ground, the wind taken out of Linhardt’s scrawny, nerd body.`}</p>
    <p>{`The first thing that came to mind when he regained consciousness a second later was that he hated Claude right now. So much.`}</p>
    <p>{`“Ugh I hate you Claude,” and he made sure the boy in question knew.`}</p>
    <p>{`Claude flopped off him and he groaned again when Reus’ fuming figure came into view, picking Claude off the ground by his shirt collar.`}</p>
    <p>{`“Oh I could kill you right now.” Reus said through gritted teeth, thankfully saying it to Claude, not him.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Now Reus, you-” Claude coughed, “-you wouldn't want to lose a close friend like me just because I told one person, would you?”`}</p>
    <p>{`“I’ve lost closer friends the last time I was deloused.”`}</p>
    <p>{`Linhardt propped himself up just in time to see Claude cringing along with Reus’s deadpan expression.`}</p>
    <p>{`Dimitri approached and kindly extended him a hand, which he gracefully accepted.`}</p>
    <p>{`“Linhardt,” Dimitri said, “You may or may not forgive me, but please let me apologize for somehow getting you involved in this.”`}</p>
    <p>{`“Yeah sure, whatever.” He shrugged, but then Dimitri’s expression turned grave, “I beg you, do not repeat what you hear or saw tonight.” Linhardt wasn’t particularly acquainted with Dimitri in spite of the fact that they’ve been schoolmates for a long time. It shocked him so much he allowed his face to express his shock to see Dimitri so..un-Dimitri-like; the radiant, regal-aura-exuding dorm leader of the Blue Lions.`}</p>
    <p>{`“Uh, ok bro.” He swallowed hard then shrugged. Really, all he wanted was to fish in peace. Not hear about his classmates' sexual escapades.`}</p>
    <p>{`Well, he had himself to blame for being so curious.`}</p>
    <Divider mdxType="Divider" />
    <p>{`Saturday morning.`}</p>
    <p>{`Dimitri peered over his shoulder and his gaze fell on Linhardt who was seemingly trying to escape Claude, who in turn was telling everyone at breakfast who made eye contact with him what he ‘allegedly’ saw last night. Dimitri sighed, peeking from under his eyebrows in Reus’ direction. Which turned out to be a bad idea as Reus was `}<em parentName="p">{`somehow`}</em>{` menacingly stabbing his bowl of `}<em parentName="p">{`rice`}</em>{` and spilling them all over while glaring at him.`}</p>
    <p>{`Sylvain; dear, sweet, idiotic Sylvain had the guts to ask why Reus and him were quarelling again. And as cheesy as this sounded, if looks could kill, Sylvain would’ve been the first victim.`}</p>
    <p>{`Thankfully Reus did nothing but glower at Sylvain who now finally had the brains to back off.`}</p>
    <p>{`Dimitri’s secret was still safe. For now. Even though Claude didn’t name who he saw, Dimitri had no doubt the secret would be out soon.`}</p>
    <p>{`The day went by for Dimitri in a blur. He skipped lunch, preferring to gaze at the exact spot in the greenhouse where he had forced himself on Reus regardless of who he thought it was. He slapped his forehead again. `}<em parentName="p">{`Stupid!`}</em>{` He was absolutely disgusted with himself.`}</p>
    <p>{`What had come over him? He was 100% sure it was Byleth, but then again he was probably looking at things through rose-coloured lenses.`}</p>
    <Ads mdxType="Ads" />
    <p>{`He hadn’t felt so distraught since the day Glen died—`}</p>
    <p>{`No. This was different.`}</p>
    <p>{`It was nowhere near as bad as that, but he did not like the feeling of not being in full control of himself.`}</p>
    <p>{`“Something troubling you?” Dimitri turned around at Dedue’s voice, “I brought you a sandwich. It is simple but filing. I hope you don’t mind having the bacon and egg filling.”`}</p>
    <p>{`“Ah Dedue, you shouldn’t have.” Dimitri accepted it nevertheless. Truth be told, it didn’t really matter what flavour it was anyway. Something felt off about the sandwich he was holding, looking down, Dimitri saw a note attached to it.`}</p>
    <CustomFont font="Papyrus, Brush Script MT, Rubik Regular" mdxType="CustomFont">
  <em>Bus stop, this afternoon 3pm. - Reus</em>
    </CustomFont>
    <p>{`Dedue cleared his throat, “It is nothing. If I may be so bold, may I ask if something
happened last night? Reus seemed to be in a foul mood when I received this note.”`}</p>
    <p>{`Dimitri cringed, burying his face in his palms.`}</p>
    <p>{`“I’ve made a terrible mistake, Dedue,” Dimitri confessed. He slowly recounted what happened the night before on the way to the bus stop, terribly embarrassed and remorseful, of course. And to his great surprise, Dedue chuckled.`}</p>
    <p>{`“I’ve had my suspicions of Reus and Byleth for a couple of days now.” Dimitri raised an eyebrow at him. He continued, “Reus, or rather, Byleth often uses the greenhouse for their phone calls. Unfortunately, I was privy to a couple of the conversations.”`}</p>
    <p>{`“I see.” Dedue was always the observant type. It didn’t surprise him that Dedue knew.`}</p>
    <p>{`“Everyone makes mistakes, but how you follow up is what matters,” Dedue said, placing a hand on his shoulder. “Even I let my anger get the better of me that night after your date. I confronted them, but I have made amends. I hope they accept it.”`}</p>
    <p>{`“That’s… very kind of you.”`}</p>
    <Ads mdxType="Ads" />
    <p>{`Dedue shook his head. “You know what you did was wrong, so, be sincere in making your apologies.” He smiled and added, “And this time, be sure of the object of your affections.”`}</p>
    <p>{`Dimitri chuckled. “Thank you.”`}</p>
    <p>{`“I shall take my leave now. I suspect Reus does not want extra company.”`}</p>
    <p>{`Dimitri nodded and took a deep breath, his mind was going mad as to how he should apologize as he made his way to the meeting point, and sat looking quite like a dejected dog, he thought.`}</p>
    <p>{`Not too long after he sat, footsteps approached and stopped before him. He looked up. A pair of irritated, mint-green eyes stared down at him.`}</p>
    <p>{`“Reus!” Dimitri jumped out of his seat, nearly crashing the top of his head into Reus’ chin. “I-I would like to—!”`}</p>
    <p>{`“Hello there!” Claude emerged suddenly from behind Reus,. when no one responded, he rolled his eyes in his usual diva-fashion. “You’re supposed to say, ‘General Kenobi’.”`}</p>
    <p>{`“We’re going to meet my sister to plan for tonight.” Reus gestured at Claude, ignoring his apparent joke. “Meme lord here says he’s confident he has the password for the bank accounts.”`}</p>
    <p>{`“Password..? Oh yes! Of course, the investigation. Yes, yes, of course.” Dimitri rubbed the back of his head and paused. “I had no idea Claude was part of the team.”`}</p>
    <p>{`“I blackmailed them to let me join,” Claude said smugly.`}</p>
    <p>{`Reus scoffed, “Really? And you didn’t beg us to let you—”`}</p>
    <p>{`“The bus is here!” Claude clamped Reus’ mouth with one hand and pointed with the other.`}</p>
    <p>{`“`}<em parentName="p">{`Ugh.`}</em>{`” Reus shrugged him off and was about to say something when Claude ushered the both of them towards the bus. Or rather, shoved.`}</p>
    <p>{`The bus was filled with all forms of people, much to Dimitri’s dismay. It slipped his mind that it was a Saturday and that meant everyone and their brother wanted a day off in town. The bus wasn’t meant to accommodate this many standing passengers, but of course, the bus driver wasn’t paid enough to care.`}</p>
    <p>{`From the corner of his eye, Reus managed to squeeze into a seat, but made no effort to even make eye contact with him. Dimitri sighed. His apology would need to be postponed.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Claude then dropped himself onto Reus’s lap, grinning and feigning that he was a faint, delicate flower. Shockingly, Reus didn’t seem to mind. Suddenly the bus lurched forward and Dimitri was sandwiched between hoards of students falling over another. Shouts and uncouth swear words overlapped in the male-filled bus. Dimitri managed to pick himself up, apologising for his height and everything else. When he looked around for any clue of what happened, he noticed the glaring red light, and the nonchalant driver picking his teeth. He looked over to where Reus was, except he wasn’t there. Only Claude and a pile of students still struggling to push themselves off each other. A familiar angry outburst that could only belong to Reus came from `}<em parentName="p">{`beneath`}</em>{` Claude. Ah, there he was.`}</p>
    <p>{`Dimitri looked away, quietly thanking the Goddess he wasn’t Claude.`}</p>
    <p>{`“Okay no, get off!” Reus barked, propeling Claude off his lap once all the boys removed themselves off them. “I’m not about to get my nuts crushed the second time.”`}</p>
    <p>{`“You mean, there was a first time?” Claude grinned.`}</p>
    <p>{`Reus growled as he rose from his seat, forcing himself out of the way. Claude quickly took the grand opportunity to claim the empty seat then patted his lap while eyeing Dimitri.`}</p>
    <p>{`Dimitri planned to ignore him, but it was too late.`}</p>
    <p>{`“Come baby, have a seat.” Claude made a kissy face.`}</p>
    <p>{`“I-I’m sorry?” Dimitri sputtered, eyes wide and blinking furiously.`}</p>
    <p>{`A random student took the invitation and sat on Claude before anything else could be said.`}</p>
    <p>{`“Ew no, not `}<em parentName="p">{`you`}</em>{`.” Claude snarled as he shoved said student off him. “`}<em parentName="p">{`This`}</em>{` special seat is reserved, thank you very much! Dimitri Alexander Blaiddyd, will you please hurry up? Otherwise some important `}<em parentName="p">{`information`}</em>{` might slip out again. You wouldn’t want that, do you?”`}</p>
    <p>{`Dimitri barely let a second pass and gave in to Claude’s emotional blackmail. It was obvious Claude was well versed in `}<em parentName="p">{`all`}</em>{` forms of blackmail.`}</p>
    <p>{`“By’ says she’ll meet us at McDonalds,” Claude read from his phone. By? Who was By-`}</p>
    <p>{`“Why’re you on a nickname basis with my sister?” Reus said, now standing where Dimitri was, sounding more threatening than protective. He, on the other hand, was bubbling in jealousy.`}</p>
    <p>{`“Uh, because we’ve been texting? Duh.”`}</p>
    <p><em parentName="p">{`They’ve`}</em>{` been texting?! How did they even meet—Oh right Claude probably met her as Reus—  No wait, By’? As in Byleth? `}<em parentName="p">{`Gasp.`}</em></p>
    <p>{`Dimitri turned his head so hard to glare at Claude he nearly gave himself whiplash.`}</p>
    <p>{`Claude frowned at him. “What?”`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Why did she tell you instead of me?” Reus said scowling at Claude before Dimitri could retort.`}</p>
    <p>{`Claude leaned into Dimitri’s back while he typed on his phone, totally ignoring the seething Reus. Dimitri’s hair stood on end and he inched a little bit forward and away from the overly-affectionate boy.`}</p>
    <p>{`“She said you’re grumpy,” Claude finally replied flatly.`}</p>
    <p>{`Reus snapped, “Who is grumpy?”`}</p>
    <p>{`“You is.”`}</p>
    <p>{`Questions swirled in Dimitri’s head confusing him even more; Why? When? `}<em parentName="p">{`How?`}</em>{` `}<em parentName="p">{`Why Claude of all people?!`}</em>{` Dimitri cleared his throat and `}<em parentName="p">{`tried`}</em>{` to keep calm. This could be a misunderstanding. “Since when were you so closely acquainted with Miss Byleth?”`}</p>
    <p>{`“Oh, since… Thursday? I dunno. She’s `}<em parentName="p">{`so`}</em>{` chill. I love her.”`}</p>
    <p>{`And Dimitri’s last ounce of calm snapped.`}</p>
    <p><em parentName="p">{`L-Love?!`}</em>{` Blood drained from his face faster than a vampire ever could. It was as if dread punched him in the throat and dragged him to the bottom of a lake called ‘Depression’.`}</p>
    <p>{`“I’m gonna pretend I didn’t hear that— for `}<em parentName="p">{`your`}</em>{` sake.” Reus snarled, then pinched the bridge of his nose. “And don’t call her By’. Only `}<em parentName="p">{`I`}</em>{` can call her that. You don’t have twin-privilege.”`}</p>
    <Divider mdxType="Divider" />
    <p>{`Byleth tapped on a notification when it popped out on her screen,`}</p>
    <MessageBox totalUnread={0} contactNumber="Claude" items={[{
      timestamp: {
        day: 'Saturday',
        time: '12:54AM'
      },
      left: '<p>omgggggggggg you’ll never guess what I saww</p>'
    }, {
      right: '<p>what</p>'
    }, {
      left: '<p>DIMITRI KISSED REUS BECAUSE HE THOUGHT REUS WAS YOU AND LINDHART SAW AND YOUR BROTHER NEARLY MURDERED EVERYONE I LOVE THIS</p>'
    }, {
      right: '<p>excuse me what</p>'
    }, {
      right: '<p>oh goddess</p>'
    }, {
      right: '<p>he knows?!?!?!</p>'
    }, {
      left: '<p>duh</p>'
    }, {
      right: '<p>omg how</p>'
    }, {
      left: '<p>idk</p>'
    }, {
      right: '<p>so...dimitri still likes me i assume&gt;?</p>'
    }, {
      right: '<p>?</p>'
    }, {
      left: '<p>yes</p>'
    }, {
      left: '<p>i think</p>'
    }, {
      left: '<p>do u not care your brother tried to murder us</p>'
    }, {
      right: '<p>oops sorry yes</p>'
    }, {
      right: '<p>its his thing now</p>'
    }, {
      left: '<p>actually not sure im just assuming based on the fact ur brother tried to kill me</p>'
    }, {
      right: '<p>did he sit on you and choke you</p>'
    }, {
      left: '<p>he sat on me and tried to choke me yes</p>'
    }, {
      left: '<p>idk maybe dimitri likes the both of you now that he’s had a taste ehekk</p>'
    }, {
      left: '<p>doint tell anyone but im rooting for you</p>'
    }, {
      left: '<p>*don’t</p>'
    }, {
      left: '<p>only cuz reus tried to kill me otherwise sorry gurl</p>'
    }, {
      left: '<p>ok sorry im kidding leave it to me i’ll make dimitri kiss you</p>'
    }, {
      right: '<p>no pls</p>'
    }, {
      left: '<p>brb ttyl gotta go annoy lorenz bb</p>'
    }, {
      right: '<p>im asking my brother and dimitri to come meet to plan for tonight do you wanna come</p>'
    }, {
      left: '<p>hallelujah! Yes where</p>'
    }, {
      right: '<p>uh i’ll ask reus to tell you i gotta plan</p>'
    }, {
      left: '<p>shit</p>'
    }, {
      left: '<p>but ok yay</p>'
    }, {
      left: '<p>remember our deal By</p>'
    }, {
      right: '<p>dont call me By</p>'
    }, {
      left: '<p>😘</p>'
    }, {
      right: '<p>are you guys on the bus yet?</p>'
    }, {
      right: '<p>i’ll meet you guys at mcdonalds, the one near the bus stop</p>'
    }, {
      left: '<p>yaaas gurl gimme them fries</p>'
    }, {
      right: '<p>ok</p>'
    }, {
      left: '<p>Reus asked why didn;t you tell him where to meet</p>'
    }, {
      right: '<p>bcuz he’s grumpy</p>'
    }, {
      left: '<p>henlo we’re almost hereee</p>'
    }, {
      right: '<p>ok</p>',
      scrollto: true
    }]} mdxType="MessageBox" />
    <p>{`She took a deep breath. Truth be told, as excited as she was to see Dimitri again,
her stomach sank at the thought of confessing that she’s lied to him all this while.
Feeling conscious, she pulled up her front camera to check herself.`}</p>
    <p>{`The seconds seemed to creep slowly by the more she thought about Dimitri and what she’d say to him. `}<em parentName="p">{`‘Hi I know you know I’m a girl and oh by the way I still like you?’`}</em>{` She buried her face in her palms.`}</p>
    <p>{`“Ooh! Are these for me? Thank you very much!” Her head shot up and cheeky, green eyes and a dangling side braid greeted her.`}</p>
    <p>{`“`}<em parentName="p">{`Claude!`}</em>{`” The all too familiar voice reverberated the McDonalds. She could even imagine the expression that came with the exasperated tone. “It is rude to be consuming other people’s food without their permission—M-Miss Byleth! ”`}</p>
    <p>{`“No one gave `}<em parentName="p">{`you`}</em>{` permission when you kissed—” A hand clamped over Claude’s big mouth. Dimitri laughed awkwardly as Claude flailed from lack of air until Reus came in, and he let go.`}</p>
    <p>{`A sudden dizziness overcame her. Blood rushed to her ears and her throat turned dry. She didn’t know if it was due to low blood pressure or hearing Dimitri’s deep and eloquent voice that made her tingle every time, but she reckoned that was probably the reason why her heart got up and danced suddenly.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Earth to By’.” Claude waved at her face.`}</p>
    <p>{`“Uh what?” she blinked at Claude, keeping her eyes from glancing at Tall, Blond and Handsome.`}</p>
    <p>{`“I said, can I eat these now?” He winked at her. “You know, the deal `}<em parentName="p">{`we`}</em>{` made.”`}</p>
    <p>{`She nodded, keeping her mouth shut.`}</p>
    <p>{`“Hello, Miss Byleth,” Dimitri said again, giving her a small bow.`}</p>
    <p>{`She awkwardly waved back as Reus came up to her and flicked her forehead, “Yo.”`}</p>
    <p>{`Instinctively she punched his arm as she rubbed the now slightly pink but sore spot on her forehead.`}</p>
    <p>{`“I got you a chocolate sundae, but it melted,” she said to her brother.`}</p>
    <p>{`Reus picked up the ice-cream cup, twisting it in his large hand, and peered at it. “And it seems all the chocolate is gone.”`}</p>
    <p>{`Byleth shrugged, giving him a cheeky smile then turned to Dimitri.`}</p>
    <p>{`“Oh um, I… didn’t know what you liked so I didn’t get you anything special,” she pushed a cup of cola towards him.`}</p>
    <p>{`“I-I LOVE COLA!”`}</p>
    <p>{`Byleth quickly covered her mouth and let out a snort at Dimitri’s sudden declaration. Even Reus let out a chuckle which was strange. Perhaps Reus was still trying to act cool in front of Dimitri. Regardless, she needed to have a private conversation with `}<em parentName="p">{`both of them`}</em>{`. Goddess knew she’d need her brother’s balls of steel to confess.`}</p>
    <p>{`“Alright, alright.” Claude emptied his share of fries into his mouth. “Let’s get down to business.”`}</p>
    <p>{`Dimitri cleared his throat. “Should we not go somewhere private?”`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Tis’ fine, my eloquently spoken blondie.” Claude laid a greasy hand on Dimitri’s shoulder and patted it. “Trust me. We’re hiding in plain sight.”`}</p>
    <p>{`Byleth was about to dust the crumbs and salt off but froze, quickly pulled her hand back as a familiar scene almost played itself before her. This was no time to be reminiscing about their date night. Their relationship, or whatever it was, had to wait. Not that it had amounted to much before this.`}</p>
    <p>{`Claude cleared his throat, demanding her attention. “As I was saying, we’re meeting tonight, right? And by `}<em parentName="p">{`we`}</em>{`, I mean Byleth as Reus, and Dimitri as Alexandre Blaiddyd.”`}</p>
    <p>{`“But… I am Alexandre Blaiddyd?” Dimitri pointed to himself looking absolutely confused. Byleth let slip a chuckle but straightened herself.`}</p>
    <p>{`Claude pinched the bridge of his nose. “Yes `}<em parentName="p">{`we know,`}</em>{` thank you. My point is, you and Byleth will be doing the leg work `}<em parentName="p">{`because`}</em>{` Byleth knows the layout better than this doofus, and it’s her case, innit?” Claude stared hard at the other twin.`}</p>
    <p>{`Reus grunted and slouched into his seat with his arms folded.`}</p>
    <p>{`“As for you, D.A.B, you’re on lookout duty. Plus, no one will accuse you of anything bad if they catch you being weird at night.” Claude placed a hand on Dimitri’s cheek.`}</p>
    <p>{`“You’re the shining prince who can do no wrong,” he said and winked at Dimitri.`}</p>
    <p>{`“Wha-Claude-I-What do you mean-”`}</p>
    <p>{`“Shh. Shh. Like I’ve said many times, just trust me, okay?” Claude said as he patted Dimitri’s cheek, then dipped his fries into Reus’s vanilla ice-cream, and earned a scowl from Reus.`}</p>
    <p>{`Byleth silently nodded as Claude continued his explanation while occasionally throwing a couple of glances at Dimitri. Goddess, it felt like forever since she last laid eyes on him. His golden hair, those soulful, blue eyes… She looked away when he shifted, afraid of being found out, but peeked at him again from the corner of her eye. It wasn’t long before he caught her, and she turned away, feigned a cough and asked a few questions to cover up her ogling. The conversation then moved onto alternate plans, strategies, and escape routes and of course, the post-victory supper. Very important, that bit.`}</p>
    <p>{`While the group bickered about where to eat once the case was over, Byleth had other things on her mind.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Dimitri?” she blurted suddenly.`}</p>
    <p>{`Like Déjà vu, Dimitri stood up so fast that his chair fell backwards, attracting the split second attention of everyone else in McDonalds.`}</p>
    <p>{`“YES?”`}</p>
    <p>{`“C-can I talk to you?” Byleth felt Reus and Claude’s eyes boring a hole into her. “Privately.”`}</p>
    <p>{`“`}<em parentName="p">{`Yes`}</em>{`,” Dimitri said breathlessly after what seemed like the longest second ever.`}</p>
    <p>{`She let go a steady breath and pursed her lips, gathering her courage. She wanted to tell him everything `}<em parentName="p">{`right now`}</em>{`, but everything was all tangled up like a multicoloured ball of yarn  in her head. However, there was one thing that stood out; her growing feelings for him.`}</p>
    <p>{`It was times like these that she cursed her inability to express herself.`}</p>
    <Divider mdxType="Divider" />
    <p>{`Dimitri’s hand clenched and unclenched. It took every single ounce of his monstrous strength to stop himself from holding her hand, or bump into her shoulder as they walked side by side in silence. He didn’t like awkward silences. It suffocated him that he felt the urge to fill them with a garble of words. but it was different with Byleth. It felt... familiar.`}</p>
    <p>{`Comfortable.`}</p>
    <p>{`“I think this is far enough,” Byleth said, the sound of her voice yanking him back to reality.`}</p>
    <p>{`The view from where they stood was breathtaking. Tucked behind Garreg Mach Town, just a little ways from the busy area of the town, they stood on the observation deck that overlooked the vast mountains and dipping valleys carpeted with lush greenery, and the sparkling, blue ocean that stretched out before them.`}</p>
    <p>{`“Dimitri…” she began in a small voice, not looking at him. ”I want to apologise.”`}</p>
    <p>{`Dimitri’s eyes widened as Byleth bowed.`}</p>
    <p>{`“No, Please don’t…!” He tried to stop her, but she stood her ground with her head bowed.`}</p>
    <Ads mdxType="Ads" />
    <p>{`When she looked up at him, his heart nearly broke.`}</p>
    <p>{`“I… I have deceived you from the start.” Again, she bowed.`}</p>
    <p>{`“Yes, but—”`}</p>
    <p>{`“I-I knew what I was doing,” she confessed, scratching her cheek. She proceeded to explain the situation, her plans at that time, and what she had been doing in the school— all things he already knew mostly. Still, there was no need for an apology! She did what she had to do so—`}</p>
    <p>{`“I had many chances to tell you,” she said and a soft, defeated chuckle left her lips. “Especially during the date. It wasn’t that I couldn’t trust you, but I just couldn’t risk it.”`}</p>
    <p>{`A short pause passed between them. Dimitri couldn’t stand to see that poor, sorry look in her eyes and turned her to face him, and their eyes locked onto each other. But Byleth broke away first. She went and sat on one of the benches that overlooked the ocean.`}</p>
    <p>{`Dimitri took a deep breath. Partly because Byleth looked like a goddess herself with that backdrop, and that this was a crucial moment he `}<em parentName="p">{`could not`}</em>{` mess up.`}</p>
    <p>{`“Goddess, please don’t let me be an insensitive, bumbling idiot,” he whispered under his breath before letting another pause go by, then went to sit next to her. The wooden bench creaked under his weight.`}</p>
    <p>{`“Miss…I mean, Byleth—I... I understand.”`}</p>
    <p>{`She perked up and her eyes were alight like a lush forest on fire.`}</p>
    <p>{`“Really?” She let out a sigh of relief, and he laughed. “I’ve been feeling so bad. Especially since, you know… you got beaten up like that. By the both of us. I’m sorry about that too. I hope you weren’t hurt too badly after that particular practice.” She giggled a little.`}</p>
    <p>{`He shook his head, his heart swelling.`}</p>
    <p>{`“Oh, and thank you for looking after me, especially since you thought I was Reus and still extended such kindness even after I won the match.”`}</p>
    <p>{`Dimitri didn’t know where to put his gaze or hide his extremely scarlet cheeks. That was besides the fact that his heart was about to pound itself out of his chest as the memories came flooding back— how he discovered her secret and… and… kissed...`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Dimitri…?” Green hair and eyes filled Dimitri’s vision. Byleth’s brows furrowed with concern. “Are you alright?”`}</p>
    <p>{`“F-Fine! I’m fine! Byleth… Actually I,” He dared himself to look at her, “I-I should be the one apologizing! I stupidly interchanged you and Reus as if you were the same being.” He bowed his head low. “I am deeply ashamed of myself.”`}</p>
    <p>{`“Hey, that’s okay. To be honest, I’m quite pleased.”`}</p>
    <p>{`“S-Sorry?”`}</p>
    <p>{`“It just means that my Reus disguise is `}<em parentName="p">{`that`}</em>{` good! Besides, I’m a twin. We used to get mixed up a lot as kids.” She laughed again. Oh if he would give up all the power in his name to hear her laugh forever.`}</p>
    <p>{`“Thank you for your kindness.” Dimitri stood up. “But I must apologize again. It was that day that I” —He took a deep breath— “I saw everything. I mean—n-not `}<em parentName="p">{`everything,`}</em>{` but I saw `}<em parentName="p">{`it`}</em>{`.” He gulped.`}</p>
    <p>{`There was a beat of silence between them. A gust of wind blew, causing the hairs on the back of his neck to stand.`}</p>
    <p>{`“I… Didn’t see the whole… thing. I just, uh. You couldn’t- No, I thought you couldn’t breathe. So I took off your hoodie, then your shirt.” He gripped his fists, “From all the bandages you had on you, I assumed you as, Reus had some sort of injury from `}<em parentName="p">{`that`}</em>{` night. Therefore, I started unwrapping it. My goal was to change your dressing so that you would be more comfortable.” Dimitri was unable to stop himself, so he kept going, “The more I unwrapped, the more..I…Byleth. By the Grace of the Goddess, it suddenly occurred to me that… they weren’t pectoral muscles.”`}</p>
    <p>{`He finally stopped, taking a moment to throw a cautious glance in her direction. Her expression was unreadable. Horrifyingly so.`}</p>
    <p>{`Claude always said his honesty was going to be the death of him.`}</p>
    <p>{`“Byleth, I am truly, truly sorry.”`}</p>
    <p>{`“Well, it was… bound to happen,” she said finally and shrugged.`}</p>
    <p>{`A major weight rolled off his chest.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Byleth brushed a lock of hair from her face, mumbling words he never thought he'd hear, “I’m… glad it was you.”`}</p>
    <p>{`Did his ears hear right? He blinked. When Byleth blushed and looked away it was as if the Goddess herself had bestowed him a blessing of the heavens. What had he done to deserve her kindness? Nothing. And Goddess, was he thankful— `}<em parentName="p">{`immensely grateful`}</em>{` for the lady’s forgiveness. Joy and disbelief swelled in his little heart. He felt like he would burst. However all he could respond with was a perfect impression of a dead halibut.`}</p>
    <p>{`“W-What?”`}</p>
    <p>{`She refused to meet his eyes. Dimitri fought the urge to cup her crimson cheeks to face him, wanting her to repeat what she just said.`}</p>
    <p>{`“B-Byleth, could you… repeat that? I-I don’t think I heard—”`}</p>
    <p>{`She turned to gaze into his eyes. Goddess, how beautiful were those soft, green eyes. Their fingertips touched. His pulse raced in his ears. Every hair on his body prickled as if electricity ran through them. She leaned in slowly, but surely, and a breath hitched in his parched throat. If he breathed, he was afraid this enchanting girl before him would disappear like dust.`}</p>
    <p>{`Her eyes closed, and he did the same, anticipating the sweet—`}</p>
    <p>{`“Have the both of you seen Claude `}<em parentName="p">{`anywhere`}</em>{`? Urgh, this is so irritating!”`}</p>
    <Divider mdxType="Divider" />
    <p>{`At the sound of the disturbing intruder, she shoved Dimitri away. Only then did she realize it was Lorenz who interrupted them. She was `}<em parentName="p">{`this`}</em>{` close to strangling him.`}</p>
    <p>{`“My, my, am I interrupting something?” Lorenz laid a hand on his chin and rubbed it.`}</p>
    <p>{`Her face returned to its emotionless state and she looked away. Dimitri wasn’t so lucky. He almost managed to stammer out an answer before Lorenz interrupted again.`}</p>
    <p>{`“Not that I care, but where is that imbecile Claude Von Riegan?!”`}</p>
    <p>{`Dimitri straightened up. “If I’m not mistaken, Lorenz, he should be at the McDonald’s near the bus stop—`}</p>
    <p>{`“Well, why is he there?!”`}</p>
    <p>{`Byleth cocked her head to the side. “Because he’s there?”`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Ah for Goddess’ sake Reus!” Lorenz spat, “I `}<em parentName="p">{`know`}</em>{` he’s there-O-oh? Oh dear,” he eyed her from top to toe, pausing at her chest long enough and he gasped looking absolutely floored at this new revelation. He dropped to his knee before her and extended an elegant hand.`}</p>
    <p>{`“It is a wonderful pleasure to meet your acquaintance!”`}</p>
    <p>{`Byleth almost cringed at him.`}</p>
    <p>{`“Go away Lorenz, ya `}<em parentName="p">{`nasty`}</em>{` thot. Bonsoir mademoiselle, monsieur. It is I, Claude.” Claude bowed with a flourish and a seductive wink.`}</p>
    <p>{`“Claude! About time you got here, you cur!” Lorenz heaved himself off the ground, pointing an accusing finger at him. “You said you’d be here at 2pm. It’s nearly 4.30pm!”`}</p>
    <p>{`“Oops.” Claude stuck his tongue out at Lorenz. “Anyway, kids let’s go get ready!” He linked arms with both her and Dimitri and pulled them along.`}</p>
    <p>{`“Wai— `}<em parentName="p">{`Claude Von Riegan`}</em>{`! You said you had an important secret to tell me!”`}</p>
    <p>{`“I forgot, I uh, left the stove on! Sorry!”`}</p>
    <p>{`“You don’t have a stove!” Lorenz screamed, “We live in a dorm!”`}</p>
    <p>{`Ignoring poor Lorenz, the four went back to the real Reus’ hotel room to swap again.`}</p>
    <p>{`“Why are you such an ass to Lorenz?” The sound of Reus’s voice was muffled, but she could hear from the bathroom as she changed nevertheless.`}</p>
    <p>{`“Because he’s an ass to me,” Claude said as-a-matter-of-factly. “But he’s fun to annoy, too.”`}</p>
    <p>{`“Were you actually going to tell him something?”`}</p>
    <p>{`Claude laughed, “Nope. He’s still hung up as to why I was chosen as house leader. He said, ‘Your family’s business declared bankruptcy and all of a sudden they declare you as the new heir, then attend this school and get elected as house leader? All within a year? Where were you before then? Are you even a true Von Riegan?`}<em parentName="p">{`’”`}</em></p>
    <p>{`Byleth paused, horrified at such words. It was no wonder why she never really felt comfortable with Lorenz. She had to give Claude credit, though. For someone who has gone through all of that, he still maintained a cheery demeanor. Also, his accurate impression of Lorenz was impressive. She chuckled and put on the rest of the disguise as the boys continued their little gossip session.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Sheesh, he is an `}<em parentName="p">{`ass`}</em>{`.” Reus concluded. “I hope the rest of you rich kids aren’t like him. I’ve still got the rest of the year here.”`}</p>
    <p>{`Claude snorted. “Yeah, `}<em parentName="p">{`he`}</em>{` is well-versed in the art of snobbery but I think he means well? I dunnu. He’s almost impossible to reason with. And hey, don’t lump us with him!”`}</p>
    <p>{`“From what I’ve seen,” Dimitri spoke up, “Lorenz was aiming to be house leader. And to be frank, he was working exceptionally hard. Despite his… quirkiness, I think he was on the verge of success. But then...”`}</p>
    <p>{`“You were blessed with me instead. You’re welcome.” Claude laughed, breaking the tension.`}</p>
    <p>{`Glancing at the mirror one last time before leaving the bathroom, she threw on the hoodie Reus was wearing before, making a mental note to permanently borrow it since it was almost the most comfortable thing ever.`}</p>
    <p>{`The first thing she noticed was the three of them huddled around the bed. Claude was sprawled across the blanket, Reus sat on a chair and Dimitri leaned against the wall. His hands were tucked in his pocket and his sleeves were rolled up to his elbows.`}</p>
    <p>{`Claude propped himself up and let out a long whistle. The others followed his gaze.`}</p>
    <p>{`“Phew.” He fanned himself as he observed her, a large grin plastered on his face. “You are literally identical to him.” Claude gestured at Reus, then turned to Dimitri. “I legit see how and why you made that mistake.” He winked at Dimitri, who looked away in shame.`}</p>
    <p>{`“Thanks,” Byleth muttered. “Reus, about the hotel room.”`}</p>
    <p>{`“Yeah, don’t worry. Those nice old people paid for a week, so this is wrapping up just in time.”`}</p>
    <p>{`At the bus stop, they went through the plan one final time.`}</p>
    <p>{`“I guess this is goodbye, for now.” Reus patted her head once their discussion was over. A neutral expression decorated his face despite his slightly melancholic words. “Keep me posted.”`}</p>
    <p>{`She nodded. Earlier, Reus had tried to convince her to let him continue her task instead. Once again, she had to remind him she was perfectly capable of looking after herself. Byleth reassured him that he was just a phone call away.`}</p>
    <p>{`“She’ll be `}<em parentName="p">{`fine`}</em>{`.” Claude rolled his eyes at Reus. “She’s never going to grow up if you keep babying her like that. ‘Sides, she has us, eh buddy?” Claude nudged Dimitri.`}</p>
    <p>{`“Of course,” the blonde replied, his eyes determined.`}</p>
    <p>{`Reus pressed his lips together, “It’s `}<em parentName="p">{`you two`}</em>{` I’m worried about.”`}</p>
    <Ads mdxType="Ads" />
    <p>{`Claude clutched the imaginary pearls on his chest while Dimitri looked crestfallen. Byleth elbowed Reus in response.`}</p>
    <p>{`The bus rolled around the corner and all except Reus boarded. This time round, the bus was semi full. Claude was nice enough to sit somewhere else, giving Dimitri and her some time alone. Well, sort of.`}</p>
    <p>{`“So, you guys banging yet?” That somewhere else where Claude sat was right behind them. He leaned forward and wriggled his cunning eyebrows at them.`}</p>
    <p>{`“`}<em parentName="p">{`Claude`}</em>{`!” Dimitri hissed, but Claude simply laughed. Seemingly satisfied with the reaction he got, he ignored them by pulling out a pair of earpods and leaned into his seat, seemingly going to sleep.`}</p>
    <p>{`Dimitri sighed. “I am extremely sorry about him.”`}</p>
    <p>{`“It’s okay. I kinda expected that before I came here to… `}<em parentName="p">{`you know`}</em>{`,” she said softly, then sighed. “To be honest, I’m feeling a little scared for tonight.”`}</p>
    <p>{`She looked down, twiddling with her fingers. But continued, “I had a rough plan in my head when I first came here. But carrying it out was a completely different thing. I know we made plans and strategies just now, but… there’s still that little naggy voice in my head. Like the Goddess telling me to be careful and not get too complacent.”`}</p>
    <p>{`“You can depend on me, I hope you know that.”`}</p>
    <p>{`“Dimitri…”`}</p>
    <p>{`“I feel silly admitting this now, but when we fought in the tournament, you unnerved me.” He chuckled.`}</p>
    <p>{`“How so?”`}</p>
    <p>{`“The way you remained so calm even when you were in a pinch is truly admirable.”`}</p>
    <p>{`Byleth was truly speechless. No one had ever said to her before. She merely smiled at him, certain that he could hear her heart beat and see the obvious red on her cheeks.`}</p>
    <p>{`“Hey, Byleth. Could you smile again?” He said as he peered down at her face.`}</p>
    <p>{`“Like this?”`}</p>
    <p>{`“I don’t think I’ve ever seen you smile like that before.” His expression was cheeky, almost. “Heh. I apologize. I shouldn’t be saying this, but I’ve very nearly forgotten myself and come dangerously close to kissing you.”`}</p>
    <Ads mdxType="Ads" />
    <p>{`Oh Goddess. The entire three weeks she’s known him, he’d never once exuded so much testosterone and this ability to flirt so openly. Excluding the time they texted each other of course. She was completely taken aback, even more so that he was bravely saying all this to her, dressed as Reus.`}</p>
    <p>{`“It’s just...I’ve never seen you look so happy before. It’s downright mesmerizing.”`}</p>
    <p>{`“You said that in your binder about me.” She finally managed to say. Her heart still fluttering uncontrollably.`}</p>
    <p>{`He let out a wonderful laugh, his expression abashed. “Oh, I had forgotten that you saw that. To think I did so many embarrassing things in front of you.”`}</p>
    <p>{`All of a sudden all she could think about were his abs and the times he’d undressed in front of her.`}</p>
    <p>{`“I’m sorry again, about lying to you. I guess, in a way I’m happy I did what I did.” She rubbed the back of her head.`}</p>
    <p>{`“May I ask why?”`}</p>
    <p>{`“Because I got to see the real you, in your natural habitat, so to speak.” Saw him in his birthday suit, too, in fact. She almost kicked herself.`}</p>
    <p>{`For some reason, he looked distant. “My real self, huh?” He smiled at her. “That’s very kind of you.”`}</p>
    <p>{`She nodded at him.`}</p>
    <p>{`“Do you think the guys will be angry with me?” she said, feeling worried and lost. “I’m not sure when I should tell them. I don’t think they’ll appreciate me lying to them.”`}</p>
    <p>{`“On the contrary.” He brought a hand to his chin. “If you explain the circumstances to them, they’ll be quite understanding. Especially Sylvain. Oh he’ll be more than understanding. I think you might even make his year by telling him.” Dimitri shook his head laughing exasperatedly.`}</p>
    <p>{`“Oh!” she exclaimed when a thought came to her. “Did you know I was a girl when we were stuck in that closet?”`}</p>
    <p>{`Dimitri froze. It almost appeared as though steam was rising from his head, he blushed furiously. “N-no.”`}</p>
    <p>{`“You uh...had your uh—” She gestured at his head, “On my..um...”`}</p>
    <Ads mdxType="Ads" />
    <p>{`“I’m so sorry…!” He wheezed and stammered what sounded like an explanation.`}</p>
    <p>{`She held back a snort. “It’s alright, I’m just teasing you. To tell you the truth I was `}<em parentName="p">{`so`}</em>{` scared you’d find out.”`}</p>
    <p>{`“Byleth, that’s the last thing I’d suspect my classmate of being.”`}</p>
    <p>{`“Yeah I mean, Linny is prettier than you. Actually, so is Felix with his hair down.”`}</p>
    <p>{`Claude interjected suddenly by putting his face in between them. “If anything, I’d think of `}<em parentName="p">{`them`}</em>{` being girls! Okay, now I have to see them naked.”`}</p>
    <p>{`“Claude, I’d strongly advise you not to,” Dimitri said, extremely sternly.`}</p>
    <p>{`“They could make fake boobs out of socks!” Claude threw his hands up, beaming with his grand idea, while Byleth’s face fell.`}</p>
    <p>{`“I wore a sock in my pants for weeks,” she mumbled with a furrowed brow, remembering how uncomfortable it was. So much so she kept adjusting it and ironically, it made her look even more convincing as a boy as they were constantly adjusting their...`}<em parentName="p">{`gonads`}</em>{`.`}</p>
    <p>{`“Did you now? Can I touch it—”`}</p>
    <p>{`“`}<em parentName="p">{`Claude`}</em>{`.”`}</p>
    <p>{`“I know.” He let out an exasperated sigh. “I was `}<em parentName="p">{`joking`}</em>{`. But why can’t I see Felix naked? You do realise we share a `}<em parentName="p">{`communal`}</em>{` shower.” Claude pouted at Dimitri. Byleth swore she saw the glint in his eye.`}</p>
    <p>{`“B-Because it’s rude and inappropriate!” He hissed, “You’re a house leader! You cannot be staring at people like that! Especially Felix— he’ll murder you!”`}</p>
    <p>{`“... So what you’re saying is that, all I have to do is ask? I mean, they’re already in front of me-”`}</p>
    <p>{`“Stop it,” Byleth said and slapped his arm.`}</p>
    <p>{`Claude finally let up, guffawing and ruffled Dimitri’s golden locks before he batted Claude’s hand away.`}</p>
    <p>{`“Oh this never gets old,” He wiped a tear away, “I’m so glad I came to this school.”`}</p>
    <p>{`The three of them chatted, oblivious to their surroundings until the bus arrived at school.`}</p>
    <Divider mdxType="Divider" />
    <p>{`Dinner was uneventful, at least and a relief to Byleth. Sylvain pestered Dimitri about her, despite the fact that she sat next to him (as Reus). Dimitri still stammered in his usual way when teased. She thought it endearing, but also felt confused. On one hand, Dimitri seemed to be able to flirt so smoothly with her, but on the other, he still fumbled over his words when talking to her and failing to make eye-contact sometimes.`}</p>
    <p>{`She digressed, her main mission was about to be completed and that was her main goal, not romance.`}</p>
    <p>{`For now.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Every second at the dinner table with the boys felt an eternity in anticipation of tonight’s raid. Also because Dimitri knew exactly who she was now. Swallowing her food was difficult and surprisingly, she wasn’t hungry. Her entire being was hyper aware of Dimitri from everything that happened today. From when they nearly kissed, to Dimitri saying that he was about to kiss her- `}<em parentName="p">{`Ah`}</em>{`, no wonder she couldn’t eat.`}</p>
    <p>{`It was as though invisible eyes watched her every move, and one mistake would expose her for the fraud she believed she was. A slip up was something she couldn’t risk— not when she was `}<em parentName="p">{`this`}</em>{` close to finishing her mission once and for all. Though it wasn’t obvious, this pressure to perform was driving her insane.`}</p>
    <p>{`It seemed the boys didn’t notice anything out of the ordinary with her, thankfully. Back in the dorm, the hours went painfully slow since she didn’t have social media to distract her.`}</p>
    <p>{`She glanced over her shoulder. Dimitri was just `}<em parentName="p">{`over there`}</em>{` reading a book, yet he was so far away. There were so many things she wanted to know, like, which part of Faerghus was he from, what his family was like, and what his dreams were. Then her phone ping-ed.`}</p>
    <p>{`She pulled the notification down, but her hopes fell a little flat.`}</p>
    <MessageBox totalUnread={0} contactNumber="Reus" items={[{
      left: '<p>good luck</p>'
    }, {
      left: '<p>dont get distracted</p>'
    }, {
      right: '<p>ok</p>'
    }]} mdxType="MessageBox" />
    <p>{`She exhaled. Although disappointed, she still appreciated the thought. She turned
sideways to continue watching Youtube but nearly jumped out of her skin when a pair
of muscular legs stood next to her bed.`}</p>
    <p>{`“Reus, I was wondering if I could speak to you?” Dimitri asked.`}</p>
    <p>{`She paused, nearly blurting yes until she remembered that Reus wasn’t really on speaking terms with him after their… `}<em parentName="p">{`rendezvous`}</em>{`.`}</p>
    <p>{`“No,” She said curtly, hoping to sound gruff and angry like her brother, but it hurt her to do this. Dimitri probably was, as well.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“O-oh. I’m sorry, I won’t bother you then.” Yup, she was right.`}</p>
    <p>{`Byleth needed to text him, privately, and immediately heaved herself off the bed.`}</p>
    <p>{`“Reus!” It took her a split second to remember that that was her name. She spun around to see Ashe scuttling towards her.`}</p>
    <p>{`“Yeah?”`}</p>
    <p>{`“Are you going to the bathroom? I’ll come with you, if that’s okay.”`}</p>
    <p>{`She shrugged and nodded.`}</p>
    <p>{`Letting Ashe walk through the door first, she threw a glance at Dimitri who looked a little dejected. Guilt shot through her heart.`}</p>
    <p>{`“I’m gonna do a number 2, so uh...don’t wait for me,” she said sheepishly once they arrived.`}</p>
    <p>{`“Are you alright?”`}</p>
    <p>{`“I’m fine.” She nodded. “Thanks, Ashe.”`}</p>
    <p>{`“I-If I can be honest, I am worried for you, especially since that day, you didn’t seem well at training. A-and at dinner just now, you hardly ate anything.”`}</p>
    <p>{`Oh, Goddess bless him for being such a cinnamon roll.`}</p>
    <p>{`She chuckled. “That’s real sweet Ashe, thanks. But really, I’m fine. Don’t worry so much. You’ll get wrinkles in between your eyebrows.”`}</p>
    <p>{`Ashe blushed and she ruffled his already fluffy hair. “Anyway, don’t wait up, alright? I’ll be awhile.” Byleth pushed open the door to a stall and sat on the closed toilet.`}</p>
    <MessageBox totalUnread={0} contactNumber="Dimitri" items={[{
      right: '<p>hey</p>'
    }, {
      right: '<p>sorry about that</p>'
    }, {
      right: '<p>didn’t want the guys to be suddenly suspicious about our sudden friendliness</p>'
    }, {
      left: '<p>I am terribly sorry. And I am sorry again for forgetting our current situation. These past couple of hours with you have been wonderful.</p>'
    }, {
      right: '<p>haha</p>'
    }, {
      right: '<p>but yeah, besides its gonna be weird once Reus assumes his identity permanently after this whole ordeal is over</p>'
    }, {
      left: '<p>You’re leaving so soon?</p>'
    }, {
      right: '<p>haha of course. im not Reus you know</p>'
    }]} mdxType="MessageBox" />
    <p>{`She ‘haha’-ed but felt otherwise just from the thought of not being with him, even
if it was just as friends, for now.`}</p>
    <MessageBox totalUnread={0} contactNumber="Dimitri" items={[{
      left: '<p>Of course, yes, you’re right.</p>'
    }, {
      left: '<p>I sincerely hope we can get to know each other more, despite being away from each other. Thankfully, we’re both from Faerghus, so it won’t be so difficult in the future.</p>'
    }, {
      right: '<p>the future?</p>'
    }, {
      left: '<p>As in, when we do meet up. And it’ll be easier to organize dates with you.</p>'
    }, {
      left: '<p>If that’s okay with you.</p>'
    }, {
      left: '<p>I mean, I’m sorry if I’m coming off too direct but</p>'
    }, {
      left: '<p>I understand that our relationship is still in its infancy, and we’re still friends if I’m to label it.</p>'
    }, {
      left: '<p>But again, if it’s alright with you, I do hope we can progress from there.</p>'
    }]} mdxType="MessageBox" />
    <p>{`Thank the Goddess for her bathroom stall hiding place, because she wouldn’t know
how or what to explain to Ashe why she was blushing so furiously, or smiling stupidly
to herself. Something that rarely happened to her. No one else had made her feel
so giddy, except maybe Chrom, her favourite actor. But he didn’t really count.`}</p>
    <MessageBox totalUnread={0} contactNumber="Dimitri" items={[{
      right: '<p>i look forward to it.</p>'
    }, {
      right: '<p>i dont think i’ll be going back to the dorm so i’ll just wait at the greenhouse for you</p>'
    }, {
      right: '<p>it’ll be easier for you to sneak out</p>'
    }, {
      left: '<p>dsklg</p>'
    }, {
      right: '<p>?</p>'
    }, {
      right: '<p>Did something happen?</p>'
    }, {
      left: '<p>Sorry, Sylvain tried to steal my phone to see who am I talking to. I’ll see you in a bit okay? Please be careful.</p>'
    }]} mdxType="MessageBox" />
She walked out, almost forgetting to flush and wash her hands.
    <p>{`“Thank goodness you’re alright!” Ashe said, she looked around but didn’t see anyone until she looked down— he was squatting by the door.`}</p>
    <p>{`“I thought I told you not to wait for me.” She sighed but smiled and extended a hand.`}</p>
    <p>{`“Here, I-I got you this. I noticed you have stomach aches quite frequently, so please accept this.” He pulled out a strip of what looked like medicinal charcoal and handed it to her, “It’ll absorb all the bad stuff in your tummy so hopefully you’ll get less stomach aches.”`}</p>
    <p>{`She grabbed him by the shoulders and ruffled his head once again, unable to resist his kindness. Ashe was a national treasure.`}</p>
    <p>{`He yelped, but laughed nonetheless.`}</p>
    <p>{`“Alright, you go on ahead.” She let go of him. “I’m gonna go for a walk.”`}</p>
    <p>{`“Don't be too late, it gets dark out there!”`}</p>
    <Ads mdxType="Ads" />
    <p>{`She nodded and waved as he jogged in the opposite direction. Byleth glanced down at her watch, it was almost time.`}</p>
    <p>{`She sat on her favourite rock outside the greenhouse, for what’s possibly the last time. It was somewhat bittersweet thinking that there wouldn’t be a next time. But all things came to an end eventually, and Reus would need to come back to school.`}</p>
    <p>{`At the sound of footsteps, she turned around, senses heightened, but then relaxed. It was Dimitri. Her heart skipped a beat at his perfect posture, the way his bangs fluttered in the wind and how his gaze focused only on her. She looked away, afraid of the powerful emotions she was feeling, and got up.`}</p>
    <p>{`“Let’s go.”`}</p>
    <Divider mdxType="Divider" />
    <p>{`Byleth led the way with him following close behind since she knew the guards’ route and timetable.`}</p>
    <p>{`When a guard turned a corner, her hand pressed on his chest, pushing him back into the shadows, and put a finger to her lips.`}</p>
    <p>{`Dimitri gulped, trying to calm himself. It was a light tap, but her touch felt like a burning sword through the chest. He only hoped Byleth did not notice his wildly thumping heart.`}</p>
    <p>{`“Okay, the coast is clear,” she said, her small hand still on his chest. She looked at him when he didn’t respond. “What’s wrong?”`}</p>
    <p>{`He shook his head as she pulled away, the spot now felt a little lonely. “N-no. It’s nothing. Let’s move on.”`}</p>
    <p>{`They finally reached Coach Kostas’ office after a few more turns and up a flight of stairs.`}</p>
    <p>{`Dimitri looked around the corners for anyone hiding, and when it was safe, he nodded at her  to come out. Byleth pulled out her lock-picking tools and started working as he stepped back to give her space, marvelling at her speed and skill.`}</p>
    <p>{`He cleared his throat. “Just for the record Byleth, there is absolutely nothing interesting in my locker so please don’t—”`}</p>
    <p>{`She hissed at him while holding a bobby pin in between her teeth, which was inappropriately turning him on. He shook his head.`}</p>
    <p>{`“Just give me  a moment…” `}<em parentName="p">{`Click.`}</em>{` “And Voila!”`}</p>
    <p>{`She swung the door open without hesitation and dove straight for the computer. With practiced hands, she turned it on and began looking for evidence in his ‘History’ tab. Once she settled, he went back out to stand guard.`}</p>
    <p>{`Since the door wasn’t fully shut, he peeked in every couple of minutes to check on her. Once or twice he let himself admire the focused expression on her face.`}</p>
    <p>{`“Dimitri?” She whispered out loud, “I need you to come and see this.”`}</p>
    <p>{`Without thinking, he went in and stood by her side and bent down to look at the screen. His eyes widened in horror. “It cannot be true…!”`}</p>
    <Ads mdxType="Ads" />
    <p>{`“He’s spent the money.” Byleth sighed in frustration, then pointed at the screen. “That geezer bought himself a new watch, new phone— even made the downpayment for a new, high-end car. He’s transferred most of what’s left to another bank account.”`}</p>
    <p>{`Dimitri was speechless. He was deep in his own mind, trying to see where it all went wrong while Byleth took screenshots of everything and saved it onto a pendrive.`}</p>
    <p>{`“What is— Why are you kids here?!”`}</p>
    <p>{`Panic shot through his entire being.`}</p>
    <p>{`Coach Kostas stood at the door, his eyes screaming bloody murder. Dimitri uncharacteristically let out a curse at his mistake. He had left the door wide open and the both of them were so occupied they didn’t hear him come in.`}</p>
    <p>{`He instinctively stood in front of Byleth, effectively shielding her from view.`}</p>
    <p>{`Coach Kostas pointed a shaky finger at him. “That’s my spot..! `}<strong parentName="p">{`hic`}</strong>{` My money is alllllll in there…!”`}</p>
    <p>{`Dimitri scrunched his nose when Coach came near him. The smell of alcohol oozed from his entire body. Coach was slurring and his steps were uneven as he approached them. His eyes were glazed and barely focused. Just how much did he drink?!`}</p>
    <p>{`Before Dimitri could react, Coach raised an arm in attack but he staggered backwards as he lost balance and slammed his fleshy shoulder into the metal cabinets. Though, it didn’t look like it hurt at all. Then he suddenly lunged at him.`}</p>
    <p>{`“You stupid brat! Rich beyond `}<em parentName="p">{`hic`}</em>{` measure! Useless!” He pressed both hands on Dimitri’s shoulders, dropping the empty bottle of whiskey by their feet. Coach’s breath stank so bad it made him want to projectile vomit.`}</p>
    <p>{`“Keep him busy!” Byleth whispered loudly.`}</p>
    <p>{`As Dimitri was distracted with nodding at her, Coach shoved him so hard he fell and crashed backwards against the desk. “No problem,” he managed to painfully wheeze out.`}</p>
    <p>{`“Coach Kostas please tell me,” Dimitri collected himself and said, sounding a little more desperate than he’d like, “Why do you hate me so?”`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Because”— He jabbed a finger into Dimitri’s chest— “You are fucking rich! `}<em parentName="p">{`hic`}</em>{` For no reason! `}<strong parentName="p">{`hic`}</strong>{` I hate you stupid `}<em parentName="p">{`useless`}</em>{`” —He burped—  “children of rich people!” Coach was now needling the same spot he poked. Dimitri winced. He hadn’t exactly fully recovered either.`}</p>
    <p>{`Dimitri took a step forward, quickly pulling his phone out while Coach kept prodding and slurring, and slid it across the desk towards Byleth where she was transferring data. He glanced over his shoulder and mouthed, “R-E-C-O-R-D”, at her, hoping she’d get the message.`}</p>
    <p>{`He turned back to the drunk coach. “Is this why you’ve stolen the money? Because you think we don’t deserve it?”`}</p>
    <p>{`Coach scoffed, “Ugh, `}<em parentName="p">{`hic`}</em>{` y-yes! Hah! You idiotic kids don’t even know how much trouble I went through to make sure you won…! `}<em parentName="p">{`hic`}</em>{` You and the other brats were so useless you couldn’t even beat that shitty girl’s school! Even their leader looked like a slut—”`}</p>
    <p>{`Something flashed before his eyes. Dimitri didn’t know what or who it was, but the next thing he saw was Coach Kostas groaning and twitching on the ground. He turned around to see Byleth safe, though she had a visibly shocked expression on her face. It occurred to him that his fist was throbbing.`}</p>
    <p><em parentName="p">{`Oh… this is not good.`}</em></p>
    <p>{`He threw a quick glance at Coach, who was still groaning in pain and quickly went to kneel beside him, quite flustered and scatterbrained. He hoped nothing broke.`}</p>
    <p>{`“Is he dead?” Byleth said softly from behind him.`}</p>
    <p>{`“I… don’t know.” He touched the spot under Coach’s jaw, and sighed with relief. “Did you manage to get all the data?” he asked, before attempting to prop Coach Kostas up against the wall.`}</p>
    <p>{`Byleth patted her pocket then handed him back his phone. “I also just barely managed to record the whole thing between you and him.”`}</p>
    <p>{`“Thank yo—” Just then, Coach’s eyes flew open and something crashed against the side of his head. His vision blurred and his entire body sank like lead as he fell sideways.`}</p>
    <p>{`The last thing he heard before drifting into unconsciousness was Byleth calling out his name.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      