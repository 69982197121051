import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const RemoteLink = makeShortcode("RemoteLink");
const Ads = makeShortcode("Ads");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This blog is, as you can tell through `}<RemoteLink to="https://chrome.google.com/webstore/detail/wappalyzer/gppongmhjkpfnbhagpmjfkannfbllamg?hl=en" mdxType="RemoteLink">{`Wappalyzer add-on`}</RemoteLink>{`, is a `}<RemoteLink to="https://www.gatsbyjs.com/" mdxType="RemoteLink">{`GatsbyJS static site`}</RemoteLink>{`. Static sites are blazing fast which makes it a joy to work with. However, every change you do needs to be deployed if you want to update your live site. That’s not very productive if you publish new pages often. Maybe doing SSR would be the solution here, but if you’re adamant on the static part, you should automate the deployment workflow.`}</p>
    <Ads mdxType="Ads" />
    <p>{`So I decide that it’s best to run an automated workflow to automatically deploy my blog posts on certain days. You could do the same with `}<RemoteLink to="https://bitbucket.org/product/features/pipelines" mdxType="RemoteLink">{`Bitbucket Pipelines`}</RemoteLink>{` but I chose `}<RemoteLink to="https://github.com/features/actions" mdxType="RemoteLink">{`Github Actions`}</RemoteLink>{` for convenience sake. Plus, the free tier on Github Actions is good enough for me. Here’s how I run, build and deploy my GatsbyJS site with a click of a button or have the cron job do it automatically for me.`}</p>
    <h2>{`Step 1: Create a main.yml`}</h2>
    <p>{`This is the file where you’ll dictate the build steps for your Github Actions workflow. There’s two ways you could create this file.`}</p>
    <p>{`First method: You could go to your repository and click on the `}<strong parentName="p">{`Actions`}</strong>{` tab > `}<strong parentName="p">{`New workflow`}</strong>{` > `}<strong parentName="p">{`Skip this and set up a workflow yourself`}</strong>{`.`}</p>
    <p>{`Second method: You could create a `}<em parentName="p">{`main.yml`}</em>{` file inside your local repo at this path: `}<strong parentName="p">{`/localrepo/.github/workflows`}</strong>{`. The first method basically just helps you to create a file at the given path. You technically could just create it yourself.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Step 2: Add your build steps to main.yml`}</h2>
    <p>{`Here’s how your `}<em parentName="p">{`main.yml`}</em>{` file should look like. I'll explain what each field means below.`}</p>
    <pre>{`name: Deploy to Vercel

on:
  # this is a cron job. Everyday at 4pm UTC (12am in MYT), it will deploy to Vercel
  schedule:
    - cron: '0 16 * * 3,0'
  # enables Action button
  workflow_dispatch:
jobs:
  deploy:
    runs-on: ubuntu-latest
    steps:
      - uses: actions/checkout@v2
      - uses: amondnet/vercel-action@v20
        with:
          # Vercel token
          vercel-token: abc123
          vercel-org-id: abc123
          vercel-project-id: abc123
          vercel-args: --prod --force`}</pre>
    <p>{`You should name your workflow so it appears as the title on your Github Actions tab using the `}<em parentName="p">{`name`}</em>{` field. I named it`}<em parentName="p">{` “Deploy to Vercel” `}</em>{`for mine. You should add two keys (`}<em parentName="p">{`schedule`}</em>{` and `}<em parentName="p">{`workflow_dispatch`}</em>{`) under the `}<em parentName="p">{`on`}</em>{` section for the following behaviour; cron job and a button for you to run your workflow manually on the Github Actions tab. Here’s how you set up the cron job timing (`}<RemoteLink to="https://pubs.opengroup.org/onlinepubs/9699919799/utilities/crontab.html" mdxType="RemoteLink">{`It is using POSIX cron syntax`}</RemoteLink>{`).`}</p>
    <p><strong parentName="p">{`0 16 `}{`*`}{` `}{`*`}{` 3,0`}</strong>{` looks nonsensical but each field actually represents a specific timing. The first field is minutes (0 to 59), the second field is hours (0 to 23), the third field is day of the month (1 to 31), the fourth field is month of the year (1 to 12) and the last field is day of the week (0 to 6 whereby 0 is Sunday). Adding an asterisk means all values are valid for this field. You should add it in if you don’t wish to specify anything specific for the said field.`}</p>
    <Ads mdxType="Ads" />
    <p>{`You could also set it up so that everytime you push to a branch, the workflow runs. There are a lot of actions that could trigger this workflow to run so feel free to visit the `}<RemoteLink to="https://docs.github.com/en/actions/reference/events-that-trigger-workflows" mdxType="RemoteLink">{`full list here.`}</RemoteLink>{` Now that we have set up `}<em parentName="p">{`when this workflow should run`}</em>{`, we should set up `}<strong parentName="p">{`what this workflow should do.`}</strong>{` Under the `}<em parentName="p">{`jobs`}</em>{` section, you should name your jobs. In the example, I have named it `}<em parentName="p">{`“deploy”`}</em>{` but it could be anything you want it to be. Add `}<em parentName="p">{`“ubuntu-latest`}</em>{`” value to the `}<em parentName="p">{`runs-on`}</em>{` field to tell Github Actions what type of environment to use. You could specify a specific ubuntu version as well but `}<em parentName="p">{`ubuntu-latest`}</em>{` works fine for most cases. The `}<em parentName="p">{`steps`}</em>{` field will specify what runs on this workflow for a single job.`}</p>
    <p>{`We will be using `}<RemoteLink to="https://github.com/actions/checkout" mdxType="RemoteLink">{`actions/checkout@v2`}</RemoteLink>{` and `}<RemoteLink to="https://github.com/amondnet/vercel-action" mdxType="RemoteLink">{`amondnet/vercel-action@v20`}</RemoteLink>{` to deploy our GatsbyJS project for us. In order for it to do that, you need to add the tokens to your Vercel project. You can find the organization and project ID tokens under the `}<em parentName="p">{`.vercel`}</em>{` folder in your local repository. Whereas for the vercel-token, you will need to create one by going into your `}<strong parentName="p">{`Vercel dashboard`}</strong>{` > `}<strong parentName="p">{`Click on your profile picture`}</strong>{` > `}<strong parentName="p">{`Settings`}</strong>{` > `}<strong parentName="p">{`Tokens`}</strong>{`.I added the argument `}<em parentName="p">{`--prod --force `}</em>{`to force my blog to pull the latest blog posts despite there being no changes. You may not need the --force option but you definitely need --prod.`}</p>
    <h2>{`Step 3: Enjoy automatic deployments`}</h2>
    <p>{`Push your changes to your main/master branch. You should be able to see the options to run the workflow manually as a button on the Github Actions tab or see rows mentioning `}<em parentName="p">{`“Scheduled”`}</em>{` if the workflow runs as a cron job. Enjoy your new automated deployment!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Important Note`}</h2>
    <p>{`Make sure you update vercel-action if need be. I once failed to deploy my static site because of some breaking changes introduced from the Vercel CLI side. Remember to disable git integration from the Vercel side as well, as `}<RemoteLink to="https://github.com/amondnet/vercel-action#disable-vercel-for-github" mdxType="RemoteLink">{`mentioned here.`}</RemoteLink></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      