import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXFanfiction/Chapter/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Divider = makeShortcode("Divider");
const AuthorNote = makeShortcode("AuthorNote");
const RemoteLink = makeShortcode("RemoteLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Like children after a school trip, Byleth, Reus, Ashe, Ingrid, Felix, Sylvain, Dimitri and even Dedue were all knocked out cold in the bus on the way home. They left shortly before the Gronder Fields Theme Park closed at 5pm and planned to get dinner separately as soon as they disembarked from the bus.`}</p>
    <p>{`Byleth, Reus and Ashe who lived in the University hostels got dinner together and ate in relative silence, their social meter having dropped to almost zero after having spent the day in a `}<em parentName="p">{`theme park`}</em>{`.`}</p>
    <p>{`The silence was welcomed by Byleth, for she could finally think about her blossoming feelings for Dimitri and how and where did it come from. Byleth made a mental list in her head;`}</p>
    <Ads mdxType="Ads" />
    <p>{`Was it the mermaid's kiss? `}<em parentName="p">{`….No`}</em>{`. In fact she was rather appalled when he did it the first time. Was it...before the whole beer fiasco incident? `}<em parentName="p">{`Nope`}</em>{`, she positively disliked him at that time.`}</p>
    <p>{`Was it when she found out Dimitri needed a mate? She shuddered, she remembered being a little bit...uncomfortable - almost fearful they were going to ask her to be his mate. Strangely, the thought doesn’t seem so bad now.`}</p>
    <p>{`Then when? Was it while they were organically just spending time together?`}</p>
    <p>{`Her heart gave her chest a little thump - which meant they were getting closer to solving this question.`}</p>
    <p>{`Okay...making progress. Now, specifically...when?`}</p>
    <p>{`Byleth let out a small sigh, not wanting to think about it anymore, her thinking about him so intently made her heart beat itself into such a frenzy, it took a bit of her focus to calm down.`}</p>
    <p>{`At this point, it didn’t matter when, how or why, the most important thing right now was her decision...What was she going to do with it?`}</p>
    <p>{`“-leth.”`}</p>
    <p>{`Should she...act on it? Not so soon at least, it would be safer to observe the target of her affections more closely. Currently, she wasn’t sure if he treated her any more romantically than he would a drowning fisherman.`}</p>
    <p>{`Byleth absentmindedly stabbed a piece of beast meat sausage that Reus graciously donated to her plate, since she enjoyed it a lot.`}</p>
    <p>{`“Byleth…!”`}</p>
    <p>{`“Hrm?” She looked up frantically at whoever was calling her name only to stare into Reus’s deadpan eyes, “Yes?”`}</p>
    <p>{`“I asked what were you thinking about, you have this fierce frown on your face,” Reus said and used his thumb to iron out the wrinkled spot between her eyes, “You’re very expressive these days you know? Openly glaring at people and even smiling more.”`}</p>
    <p>{`Reus was right, and she was starting to realise it herself too. Sometimes she’d find her eyes or...even face would feel a little tired only for her to see in the mirror she had been frowning at someone or something for a long time.`}</p>
    <p>{`Byleth’s body was physically tired from their outing but her mind and heart felt like it was floating. Every breath she took took her a little bit higher until the creases in her forehead smoothened out and she smiled.`}</p>
    <p>{`The Eisner twins, having had their month’s supply of excitement from the theme park, crashed so early they slept with the light on until Byleth got up and turned off the light on the way back from the toilet.`}</p>
    <p>{`It was freeing to be able to identify what she was feeling, rather than wonder why she felt shy and nervous around Dimitri so much. Well, going to his hometown and being embraced didn't count since she was so excited she didn’t notice him much. But in their daily interactions, Byleth was…a little more aware of everything.`}</p>
    <Ads mdxType="Ads" />
    <p>{`But Byleth knew such feelings would be a burden if she didn’t manage it properly. She didn’t want people saying Dimitri was the cause of her grades slipping since their finals were coming up soon. Not that anyone else knew about her feelings, but still.`}</p>
    <p>{`Professor Alois was almost everyone’s favourite lecturer, a jovial middle-aged man who taught lessons with vigor but had the `}<em parentName="p">{`worst`}</em>{` jokes ever. Nobody laughed but Byleth would occasionally hear stifled laughter from Dimitri’s direction. The first time she heard it she thought he was holding in his sneeze but he was holding in his laughter so hard he was shaking.`}</p>
    <p>{`“Oops, nearly forgot,” Professor Alois said at the beginning of class, “Is there a...err…” He picked up a scrap piece of paper from his desk, “...Byleth Eisner and Felix Fraldarius in this lecture hall? Raise your hand please.” Byleth and Felix looked at each other and did as they were told, “Ah, wonderful, please stay back after this class, thank you.”`}</p>
    <p>{`Panic and alarm bells set off in Byleth’s head. Had she done something wrong? Were her grades already slipping from their midterm? She glanced over at Felix who seemed calmed and had his signature death stare plastered on his face.`}</p>
    <p>{`She took a deep breath and tried her best to focus on the lecture at hand, trying to ignore the fact she had never been asked to stay back ever in her entire school life. She and Reus had peacefully lived their lives as quiet, wallflower students. They never caused any trouble or stood out in any way and she was happy that way.`}</p>
    <p>{`But she still managed to focus on the topic at hand, even though the thought was nagging in the back of her mind. She let out a long, deep exhale when the lecture ended.`}</p>
    <p>{`“Right, so…,” Professor Alois cleared his throat when Felix and her stood in front of his desk, “The chancellor would like to see you in about...oooh in about 15 minutes. Best be getting along, now, don’t want to be like that math teacher who was late...because she took the `}<em parentName="p">{`rhombus`}</em>{`, AHHA! Get it? Wrong bus!” He launched into one of his famous fits of laughter which echoed in the now empty lecture.`}</p>
    <p>{`Byleth swore she heard crickets in the background as Felix rolled his eyes so far back he could probably see his own brain.`}</p>
    <p>{`“Ahhh...that was a good one,” Professor Alois wiped the tears from his eyes, “Don’t worry, you kids are not in trouble, as far as I know anyway.” He ushered them out of the hall as he packed up his materials.`}</p>
    <p>{`Byleth and Felix walked in silence to the chancellor’s office on the other side of the campus. She enjoyed her friendship with Felix and never once thought of him in a romantic way ever since they met in secondary school back at the Officer’s Academy.`}</p>
    <p>{`He wasn’t the type to be uncomfortably chatty or yapped all day long, no matter how gruff or grumpy he was, and Felix was a surprisingly kind person.`}</p>
    <p>{`As she was absorbed in her thoughts, they arrived at the administration building and gave their names to the receptionist who immediately brought them into the chancellor’s office.`}</p>
    <p>{`Chancellor Flayn, a small sized woman of a very inquisitive nature, was famous in the academic world for her youthful looks yet somewhat old fashioned wisdom. It is also well known that vice-chancellor Seteth is her father, a fact she does not let anyone use against her in her academic journey and prowess.`}</p>
    <p>{`“Ah yes, perfect timing. Please, have a seat,” Chancellor Flayn, wearing a dark blue peplum dress with gold linings, said as she stood up and gestured at the two empty seats next to a boy named Lindhardt and Claude.`}</p>
    <p>{`“Yo,” Claude waved at them, she nodded back at him.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Chancellor Flayn cleared her throat and sat back down, Vice-Chancellor Seteth stood at her side, his famous scowl on display. His reputation for never smiling was on par with Felix’s.`}</p>
    <p>{`“Now then,” she began, “I have called all four of you for the same purpose, even though how you were chosen for this decision differs, it does not matter. Simply put, we have chosen you to attend the National University of Almyra for one semester for an exchange program which, as you all know, is six months.”`}</p>
    <p>{`Apart from Lindhardt’s enormous yawn, there was pin drop silence in the room.`}</p>
    <p>{`“Oh hey, that’s great!” Claude cheered, breaking the silence after a good minute or so had passed.`}</p>
    <p>{`Chancellor Flayn nodded, “I am not sure if the four of you are aware of this tradition but we normally select a handful of students who have consistent, high achieving academic results to attend our sister university, and they send a group of students of their own choices as well. It has been a...long standing tradition here in Garreg Mach. One of many.”`}</p>
    <p>{`Byleth had heard of something like this in passing, and probably in the university prospectus as well. Since Garreg Mach University was an ancient institution, it wasn’t surprising they had such connections.`}</p>
    <p>{`“Of course, this is all paid for so long as you keep your grades up. Especially your final examinations for this semester, as long as you qualify for the Dean’s list, or indeed, the Chancellor’s list, you can be assured of a position in this program.`}</p>
    <p>{`However, your own personal expenditure, transport and additional meals are to be borne on your own. All these will be written out in an agreement for you to sign when your final results are out, as you are all of legal age...Any questions?” Chancellor Flayn paused, smiling at them in contrast to Vice-Chancellor Seteth’s permanent glare.`}</p>
    <p>{`Byleth raised a hand, “May I ask how we were chosen and why us?”`}</p>
    <p>{`“Excellent question, Miss Eisner,” Chancellor Flayn said, “Well, I will not go into too much detail but the work the four of you submitted is of outstanding calibre. The length all of you went to for research accompanied by the quality of your writings is a trait not found in many.”`}</p>
    <p>{`“I...see.” Byleth nodded, feeling proud as she gave Felix a subtle thumbs up.`}</p>
    <p>{`“Particularly Mr. Von Riegan’s and Mr. Von Hevring, your article on the discrimination of the Almyran’s in Fodlan in 1180 to 1190 was a splendid read. Engaging and...dare I say...accurate?” Chancellor Flayn chuckled, which Byleth thought was strange. Accurate? The chancellor spoke as if she was there, it was a rather strange choice of word.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Vice-Chancellor Seteth cleared his throat. “Accurate as in, based on the various historical records and the like, nothing more.” They all slowly nodded, probably unsure what was going on.`}</p>
    <p>{`“While your writing with Mr. Fraldarius here on the newly discovered Areadbhar was undeniably well researched, I expect your work will be highly referenced by many in the future,” she gave them another smile and laced her fingers together.`}</p>
    <p>{`“Regardless, a digital agreement will be sent to your emails within the week along with a physical copy. I do apologize for suddenly springing this on you, however if you do agree to this, you will be representing Garreg Mach University. I implore all of you to think of this opportunity...and how well it will look on your resume.”`}</p>
    <p>{`They left the Chancellor’s office in single file and in silence. Byleth was completely blindsided by this but Claude seemed quite elated at the offer, since he said his ancestors were from Almyra.`}</p>
    <p>{`It was too good to be true...did she do something in her past life to deserve such a thing? Was she a great war hero or something? Was she a foot soldier that saved some monarch's life? Was this the Goddess’s way of repaying her?`}</p>
    <p>{`An almost all-paid expense exchange program to the National University of Almyra, history books say that Almyra and the continent of Fodlan had terrible relations with each other, yet it wasn’t so apparent now - evident by the bustling trade and amicable relations the nations had with each other. A traveller from Fodlan didn’t even need a visa to enter Almyra, and the reverse applied as well.`}</p>
    <p>{`Felix groaned after they split ways from the other two, “That was bizarre.”`}</p>
    <p>{`Byleth sighed in response, “This all sounds amazing but...to be completely fair I’m not sure if I can handle this.”`}</p>
    <p>{`“Is there something wrong with you? This sort of thing is right up your alley,” He frowned at her.`}</p>
    <p>{`“I mean...I just feel overwhelmed is all. I barely got used to the whole...mermaid thing,” she whispered, “And I’ve barely kept my grades up, I’m scared, I guess.”`}</p>
    <p>{`Felix shook his head, “This is...weird. You’re...expressing things to me.” She chuckled at him.`}</p>
    <p>{`Byleth was telling the truth, but not the whole truth. Her heart felt heavy at the thought of leaving for six months, of course she would miss her brother, and her family and...her heart forced her to think of Dimitri even though she tried hard to suppress it.`}</p>
    <p>{`“Are you inclined to accept, Felix?” She asked.`}</p>
    <p>{`“I am,” he said without hesitation. “It’s finally a chance to go out into the world and the Almyran’s have a much better library of resources when it comes to studying ancient weaponry.” There was a glint in his eye that reminded Byleth of when he returned from the sword exhibition.`}</p>
    <p>{`“Are you not going to accept?” Felix asked, his eyebrow raised.`}</p>
    <p>{`“I want to, yes. I suppose I will after a discussion with my family...but…” Byleth sighed, “But will...Dimitri be okay on his own?”`}</p>
    <p>{`“Dimitri?” Felix scoffed as he turned his scowl on her, “We’re being offered an opportunity of a lifetime and you’re worried about...Dimitri?”`}</p>
    <p>{`She shook her head, “N-Not at all, I mean, you did task me with looking after him and...and with both of us gone…” Byleth looked away, avoiding eye-contact with Felix, in case he discovered her budding affections for the fish-man.`}</p>
    <p>{`“What?” Felix scowled even harder at her, “Look after him? He’s not five-” he stopped abruptly, causing her to turn to see what was wrong and he narrowed his eyes at her.`}</p>
    <p>{`“You’re staring, Felix,” Byleth said and started walking again but Felix stopped in front of her and she bumped into his chest.`}</p>
    <p>{`“What?” she said, slightly panicking. Felix was going to actually realise what was happening.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Wait a minute...you…like the bastard, don’t you?” He pointed a finger at her, his wrinkled eyebrows softened and Felix `}<em parentName="p">{`smirked`}</em>{` as the realization dawned on him.`}</p>
    <p>{`“That’s...a preposterous accusation, Felix,” Byleth said, swatting his finger away. “No, stop looking at me like that I do `}<em parentName="p">{`not`}</em>{`- No. I don’t.”`}</p>
    <p>{`Felix crossed his arms and let out a short exhale through his nose, “My job is done.”`}</p>
    <p>{`“What? What job? What do you mean?” Byleth chased after him as he walked away.`}</p>
    <p>{`“This has been ridiculously easy ever since you discovered the secret, thank you, Byleth,” Felix said, stretching his arms above his head, as if he actually did some sort of hard work. “Does that stupid boor-ish, fish prince like you back?”`}</p>
    <p>{`“Does he- Felix, I-I don’t know what you’re talking about-”`}</p>
    <p>{`Felix groaned and rolled his eyes, “Stop trying to deny it, it says ‘I love Dimitri’ across your forehead.”`}</p>
    <p>{`She slapped both of her hands on her forehead, “No it doesn’t!”`}</p>
    <p>{`He stopped again, spun around, bent down to look her in the eye with the most serious expression he had, “It does.”`}</p>
    <p>{`Byleth couldn’t deny it anymore and relaxed her arms so it dropped to her sides, “You’re right. And...I don’t know how or where it happened. I’ve spent an entire day just wondering how or why but...I don’t know. I’m scared of something but I can’t stop thinking about him, yet...I can’t even tell Reus.”`}</p>
    <p>{`They stood outside in the courtyard near the fishing pond as numerous students passed by, ignoring them and their little discussion.`}</p>
    <p>{`“It only takes a minute to fall in love,” Felix said softly.`}</p>
    <p>{`“Sorry?”`}</p>
    <p>{`Felix cleared his throat, “I...uh, heard Annette singing it in the greenhouse the other day- it doesn’t matter, but I think it’s what happened to you.”`}</p>
    <p>{`Byleth stayed silent, the sentence repeating in her head. Somehow or rather, it made sense and Byleth’s heart eased. Initially, she felt afraid she was falling in love so fast, or that it happened so frighteningly fast.`}</p>
    <p>{`But if people sang a song about falling in love in one minute then...`}</p>
    <p>{`Ironically, the words that cured her heart’s troubles came from the most unromantic person to ever walk the earth.`}</p>
    <Divider mdxType="Divider" />
    <p>{`Unsurprisingly, one of the places Dimitri felt closest to home was the fishing pond
in Garreg Mach. Though the waters were somewhat murky and dark, resembling a swamp
more than the crystal clear blue ocean he emerged from. It couldn’t be helped, they
were in an institution older than a museum.`}</p>
    <p>{`Still, it was nice to have something resembling home. What's more, with the sunset in the background, it reminded him of times when he was out in the middle of the vast ocean, sitting on a rock somewhere watching the sunset alone.`}</p>
    <p>{`Dimitri felt a little bit more adventurous today, trying a new electrolyte drink brand instead of his usual, since he heard Felix and...unfortunately Byleth were going on their own adventure to Almyra. Felix told him the night before, mentioning that Byleth was going as well since she and him were partners.`}</p>
    <p>{`Plus they both informed him they will not be able to spend time with him apart from lunch and some meals, since they needed excellent grades to qualify.`}</p>
    <p>{`This mysteriously made his heart ache, he was used to being alone but right now... especially when he thought of Byleth and Felix leaving, Dimitri clutched his chest.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Which was truly strange since he hadn’t felt so...distressed since his mother died. He thought of the two of them again...ah, there it was. That little twinge.`}</p>
    <p>{`So he alternated his thoughts between them and came to the conclusion that thinking of Byleth gave him chest pains and it worsened whenever he realised he wouldn't be able to see her for six months.`}</p>
    <p>{`He leaned back against the back step, sipping on his drink and tried to calm his thoughts. Dimitri of course was happy for her, but also...preparing his thoughts for the emotional turmoil ahead - which he couldn’t really understand why he was already feeling.`}</p>
    <p>{`Dimitri groaned softly, he really should be spending more time with civilised people or merfolk instead of giant octopuses who couldn’t have a proper dialogue with him - which he noted down in his trusty little notebook. It was gradually getting filled up with his thoughts and quips about people, things he should or should not do etc.`}</p>
    <p>{`He shut his notebook and stuffed it back into his pocket and as he unscrewed the cap of his drink then tipped the contents of his bottle into his mouth, a Byleth who was walking in front of him with Reus smiled and waved at him. Dimitri, being a creature born of grace, beauty and elegance spat out his drink in her direction in shock.`}</p>
    <p>{`Byleth, thankfully, managed to dodge his drink projectile just in time.`}</p>
    <p>{`“Oh Goddess, are you alright, Dimitri?” Byleth came up to him, not in the slightest bit angry and even seemed to be slightly amused even though he nearly baptised her with `}<em parentName="p">{`Pocari Sweat`}</em>{`.`}</p>
    <p>{`As Byleth extracted some tissues from her bag, time moved in slow motion for him. When her hand gently tapped lips dry, Dimitri’s mouth fell agape and whatever drink he had left in his mouth simply cascaded out.`}</p>
    <p>{`“Dimitri, you’re drooling! Do you need help?” Byleth was now frantically wiping his chin and neck, leaving a trail of heat and fire whenever she touched. His heart rate was akin to the time that goblin shark nearly took a chunk out of him and if he was in merman form right now, his tail would be radiating so brightly he would’ve been mistaken for the sun.`}</p>
    <p>{`He gripped her wrist to stop her from fussing over him anymore as he felt his heart was going to explode and violently shook his head, unable to form coherent sentences in his head, let alone try and verbalise them.`}</p>
    <p>{`“Reus, you go on ahead, Dimitri isn’t feeling well,” Byleth turned her back to her twin and ushered him. But instead of leaving, Reus came up to Dimitri as well and slapped a palm to his forehead.`}</p>
    <p>{`“He doesn’t have a fever, what’s wrong with him?” Reus asked, raising an eyebrow.`}</p>
    <p>{`“I-I’m fine. Really,” He managed to sputter out, shakily got to his feet, turned his heel and went in the opposite direction of where Byleth was.`}</p>
    <p>{`“Wha-Wait, Dimitri!” He heard Byleth call out, “Reus, I’ll meet you for dinner.”`}</p>
    <p>{`“Dimitri, stop, please,” Byleth quietly called out to him and tugged on his sleeve. “Are you alright?” He nodded in response, only now realising how small Byleth was.`}</p>
    <Ads mdxType="Ads" />
    <p>{`A few seconds passed them, he attempted to speak up and say something, anything. He opened his mouth only to quickly shut it back. He literally had no words, his brain wasn’t functioning very well right now. For some reason, ever since the theme park trip, Dimitri had been hyper aware of Byleth, and even her smell was captivating him.`}</p>
    <p>{`“I assume you must be taking the news of Felix leaving quite hard, he has been your rock all this while…” She finally said, letting go of his sleeve and gave him a sympathetic look.`}</p>
    <p>{`Without hesitation and not needing to think even, the words tumbled out of his mouth,`}</p>
    <p>{`“Not Felix, no,” he replied softly, bending a bit to meet her eyes, “It’s you.”`}</p>
    <p>{`She looked up at him and the faintest hint of pink was creeping up on her cheeks, giving her a beautiful glow as he looked down into her beguiling doe eyes that made him feel breathless, even though she wasn’t doing anything out of the ordinary apart from merely staring straight into his eyes with slight shock.`}</p>
    <p>{`“I...hardly think I deserve that honour, Dimitri…” She broke away and looked down at her feet.`}</p>
    <p>{`He shook his head, “Erm...I must disagree. While Felix has been...uh...he...is...family to me, you...make me feel like I see the world in a new light?” Dimitri rubbed the back of neck, unsure of how to tell her she made his world so much more colourful without sounding like a creep.`}</p>
    <p>{`“I will feel your absence...greatly, Byleth,” He said in a somber voice, he had this strange feeling of wanting to pour out his emotions and what he felt for her at this moment but he held himself back. It was like when he took her back to his hometown and he wanted to show her everything.`}</p>
    <p>{`“Thank you, Dimitri. That...that means a lot to me, but...I’m sure you’ll find a new friend soon enough…” Her smiling eyes turned into a more subdued expression in an instant, “You might even find your life partner...which I believe is your main mission, remember?”`}</p>
    <p>{`He did remember, yet being with Byleth made him feel as though he had already accomplished his mission.`}</p>
    <Divider mdxType="Divider" />
    <p>{`The days turned to weeks and before long, their day of departure had arrived.`}</p>
    <p>{`“Passport?”`}</p>
    <p>{`“Check.”`}</p>
    <p>{`“Boarding pass?”`}</p>
    <p>{`“Yes.”`}</p>
    <p>{`“Emergency money?”`}</p>
    <p>{`“Yes, Reus. It’s hidden in my travel belt,” Byleth sighed but then smiled at her brother who was doing a last minute check with her. He was only born a couple minutes before her but he still acted like he was 10 years older.`}</p>
    <p>{`“Just because I’m not here anymore, doesn’t mean you can leave the toilet seat up in our bathroom, okay?” And sometimes, she was the one nagging him. He rolled his eyes and held his arms out.`}</p>
    <p>{`“Safe trip and text me when you get there,” Reus said as he hugged her and patted her back.`}</p>
    <p>{`A loud distressed groan erupted behind them, “Glenn, get off me,” Felix snapped at his brother who was also giving him a hug. “You didn’t need to come. This is embarrassing.”`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Shhhh,” Glenn hushed him like a child and was about to ruffle Felix’s hair who swatted his hand away. Byleth enjoyed seeing Felix as a little brother whenever Glenn was around. The smart, cool, handsome but grumpy Felix being treated like a child was a sight one didn’t see often, or indeed, at all.`}</p>
    <p>{`Byleth couldn’t help but feel a little dejected Dimitri didn’t come to see them off, but seeing as Claude had to leave the swim team for six months, Coach Manuela wasn’t very pleased about losing a capable swimmer and went into overdrive about the team’s training for next year.`}</p>
    <p>{`It took all her willpower to suppress the mounting heartache she was feeling when he couldn’t make it, especially since their little episode at the fishpond. When Dimitri told her that he would more or less miss her, her knees almost went weak.`}</p>
    <p>{`The four of them sat two by two on the plane with Claude and Lindhardt at the back of Byleth and Felix. Byleth had come to know Lindhardt as the four of them started spending more time together, he, of course, fell promptly asleep as soon as they sat down without even reclining his chair.`}</p>
    <p>{`Felix was kind enough to let Byleth have the window seat, and so she stared out the window forlornly, wondering if she should’ve said something about her feelings to Dimitri. Especially since he might actually find a girlfriend during their little stint overseas, the Goddess willing.`}</p>
    <p>{`Byleth felt that maybe Dimitri treated her special since she was most likely the only girl who was nice to him. Had someone else found him that night, a thought that now made her chest hurt, that person might’ve been Dimitri’s special friend instead.`}</p>
    <p>{`“Will you stop moping? It’s annoying,” Felix hissed at her. “That stupid boar was exactly the same back in the apartment too.”`}</p>
    <p>{`Byleth turned to look at Felix, narrowing her eyes at him. “Boar? No, I mean, he...he was? Did he say why?” She was hoping it was because he said he missed her, but Byleth felt she was getting ahead of herself, like a lovesick teenage girl.`}</p>
    <p>{`“Look me in the eye and tell me Dimitri spills his innermost secrets to me,” Felix scoffed. She sighed in response and continued looking out the window.`}</p>
    <p>{`“Well...I...did ask,” Felix murmured a few minutes later after the plane had a successful take off.`}</p>
    <p>{`“You...asked?” She gave him an inquisitive look.`}</p>
    <p>{`“Not exactly, I sort of...ordered him to tell me,” Felix looked away in embarrassment, seemingly aware of his rough way of speaking.`}</p>
    <p>{`“He didn’t tell you, did he?” Byleth felt a smirk coming on.`}</p>
    <p>{`“Shut up, I tried, okay?” Felix hid his face in his palm and she chuckled in response. “You know what he’s like, he doesn’t say anything and just does what he wants. Like telling you he wants to attend human university or he’s off to find another coral reef or-”`}</p>
    <p>{`“-Suddenly kisses you without saying anything,” she blurted out.`}</p>
    <p>{`Felix exhaled, “Tell me about it,” he said, rather crossly.`}</p>
    <p>{`She finally tore her eyes away from the world below and gawked at Felix, “What?” Byleth shifted in her seat to face him properly and said in a soft voice, “He’s...kissed you too?”`}</p>
    <p>{`“He’s the only one I’ve kissed,” Felix murmured, sounding quite sore about that fact. Byleth accidentally let out a snort in front of his face. He pushed her face back and turned the other way to sulk. “It’s not like we’ve made out or anything, just quick pecks to get the job done,” He whispered angrily then turned his back to her again.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“He’s the only one I’ve kissed too. If that's any consolation?” Byleth took a quick peek at Felix to make sure he wasn’t too upset. While Felix got quick pecks...Byleth...couldn’t remember if Dimitri lingered on their kisses? But what she did remember was how much she enjoyed the last one, the one on their way back to the beach with Dedue, however fleeting it was.`}</p>
    <p>{`A thought pop up in her head and she blurted it out before it ran away, “The other day you mentioned you heard Annette singing ‘it only takes a minute to fall in love’, I’ve tried to look it up but I’m not sure if I’ve got the right one, do you mind asking Annette if it’s a song she made up or-”`}</p>
    <p>{`“-`}<em parentName="p">{`No`}</em>{`, I will not and you bloody well will not either,” Byleth nearly let out a yelp at the speed Felix shoved a finger in her face as he ordered her.`}</p>
    <p>{`“Why ever not?” Byleth felt a little taken aback at asking an innocent question, “I just want to listen to it, there’s no harm in asking is there?”`}</p>
    <p>{`“There`}<em parentName="p">{` is`}</em>{`,” Felix said through gritted teeth, but he relaxed his clenched jaw and teeth and...blushed?`}</p>
    <p>{`“Felix? Well, what is it?” She urged, starting to feel concerned Felix Hugo Fraldarius was blushing.`}</p>
    <p>{`“Annette…” Felix said her name in the softest voice she’d ever heard him speak in, “...Doesn’t know I listen to her sing.” Byleth gasped at the revelation that was unfolding before her.`}</p>
    <p>{`“Felix…” She gently gripped his arm and tried her darndest to hold back the excitement that was bubbling in her, threatening to escape her lips in a giggle.`}</p>
    <p>{`Upon her word, she’d never thought she’d see the day Felix, the man who would probably win the championship for the crankiest person alive, the man who probably kissed Dimitri more than anyone here, fall in love.`}</p>
    <p>{`Not with just any old girl, but with the human equivalent of a cinnamon roll. And to top it all off, Felix was somewhat stalking her to hear her sing her ridiculous songs.`}</p>
    <p>{`He ran a shaky hand through his hair and ruffled it, “If you breathe a word-”`}</p>
    <p>{`“I won’t,” she smirked at him.`}</p>
    <p>{`“Especially to her-”`}</p>
    <p>{`“`}<em parentName="p">{`Felix`}</em>{`,”`}</p>
    <p>{`“...Sorry.”`}</p>
    <p>{`Their 3 hour flight to Almyra went by rather quickly, both Byleth and Felix fell asleep sporadically after their little conversation and Claude turned around to chat with them a bit and then fell asleep as well. Lindhardt only woke up once to use the loo and then told Claude to wake him up when they arrived.`}</p>
    <p>{`While she was over the moon for Felix and hoped the best for him and Annette, whenever Byleth’s mind wasn’t occupied with worry, excitement, and anticipation for her new home for the next six months, she wondered when would be the next time she and Dimitri would kiss again.`}</p>
    <Divider mdxType="Divider" />
    <p>{`“What’s wrong, Dimitri?” Reus said as he dried his hair and approached him. “You’ve
been glaring at the empty shower stall naked ever since we finished practice. Not
going in?”’`}</p>
    <p>{`“Oh...erm.” Dimitri nodded, not realizing he’d been rudely staring at the shower curtain. Even at practice today Dimitri noticed he wasn’t really concentrating. He’d been having chest pains and every breath he took felt a little painful.`}</p>
    <p>{`“Are you ill?” Reus said as he sat down next to him, towel still on his damp head and his voice sounded concerned, “I did notice your timing was off today. I’m...not usually one for conversation...but if our star swimmer needs someone to have dialogue with, I’m all ears.”`}</p>
    <p>{`Dimitri looked at Reus’s wooden face with great affection, touched by his sincere offer to listen to him.`}</p>
    <p>{`“I...I don’t know if I can express this well, or if it is even appropriate to. I’m not very used to your human customs,” Reus squinted slightly at him but nodded nonetheless, “But I...I feel rather conflicted about something, I...don’t know what yet.”`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Erm...is it about...school?” Reus asked and Dimitri shook his head, “Swimming? No? Okay...Family?” Dimitri shook his head again. “Well I can’t read your mind, you need to work with me, Dimitri.”`}</p>
    <p>{`“I’ve...never really expressed much, this...a little difficult for me to say...I’d rather not take up your time,” Dimitri bit his bottom lip and gave Reus a meek smile.`}</p>
    <p>{`Reus shrugged, “My sister isn’t here, I don’t have anyone to have lunch with and...I could use the company, if you’d care to join me?”`}</p>
    <p>{`A sort of pain bloomed in Dimitri’s chest when Reus mentioned his sister. He clutched his chest, rubbing his left pectoral muscle to soothe the pain. It was the same type of ache whenever he thought of Byleth nowadays, since she left 2 weeks ago and usually by gently massaging his chest, the pain would go away.`}</p>
    <p>{`But right now as Reus looked at him with the same deadpan expression Byleth used to look at him with, his heart only felt more...forlorn. They resembled each other so remarkably, it was as if Reus was the male version of Byleth, just with short hair, smaller eyes and not so sweet-looking.`}</p>
    <p>{`“Yes...I would...like to join you for lunch. Umm, Dedue has Greenhouse club meeting anyway,” Dimitri said, feeling a touch better at Reus’s generosity.`}</p>
    <p>{`They both stood up, as Dimitri still hadn’t showered yet but Reus sat back down and so did Dimitri.`}</p>
    <p>{`Reus took a deep breath and exhaled, “Hang on a minute, are you having...girl troubles? I sort of...remember you had a...hickey on your neck the other day, if you don’t mind my saying.”`}</p>
    <p>{`“A hickey?” Dimitri tilted his head, “Um...What do you mean?” Did Reus mean a love bite? Like the one Byleth mentioned to him when he told her about Rufus the Giant Octopus, a close friend of Dimitri’s.`}</p>
    <p>{`Reus waved a hand, “You know what, just...forget it-”`}</p>
    <p>{`“Did I hear someone having girl troubles?” Sylvain popped his head in between him and Reus with both his arms slung over their shoulders. Sylvain squeezed his giant frame in between the both of them and they reluctantly shifted aside for him as he cleared his throat to speak.`}</p>
    <p>{`“Buddy, there is no better person to solve your lady problems than, I, Sylvain Jose Gautier, named after my great ancestor and rumoured womanizer.” Sylvain had his chest puffed out with pride as he announced his name, however, like Dimitri, Sylvain was also stark naked.`}</p>
    <p>{`“Bugger off, Sylvain,” Reus said, “I saw you chasing after some girl the other night, begging her not to leave you-”`}</p>
    <p>{`“Ah-Ah, Shhh-” Sylvain pressed a hand on Reus’s mouth, “It doesn’t matter now, does it? A friend needs my expertise and I shall be delighted to offer my services to him.”`}</p>
    <p>{`Reus groaned and slapped his hand away, “‘Expert’, sure,” he scoffed and then went to change.`}</p>
    <p>{`“Right,” Sylvain said, leaning so close to Dimitri their naked thighs were touching, making Dimitri slightly wish he was clothed. “What is your emergency?”`}</p>
    <p>{`“I...don’t know,” Dimitri furrowed his brow at them, “I’ve just...never felt this way before. At most, we’ve only shared a few stolen moments with each other...sometimes I’d steal a glance and find her already looking at me. But...right now...” he patted his chest, “It hurts here and I feel...lost.”`}</p>
    <p>{`“Ah...That’s love, my good friend, you’re love sick,” Sylvain wiped an imaginary tear away from his eyes and sniffed. “What do you love about her, mi amigo?”`}</p>
    <p>{`Dimitri tilted his head and tapped his chin, unsure of where to begin. He liked a great deal about Byleth. “She’s...kind...patient...umm…”`}</p>
    <p>{`“Yes, yes, what else? Byleth does have very nice boobs- `}<em parentName="p">{`Yeouch!`}</em>{`” A loud whipping sound echoed in their shower room, followed by Sylvain’s yelp. Dimitri turned around to see Reus wearing a dark expression on his face.`}</p>
    <p>{`“What did you say about my sister?” Reus said, bearing a wide stance with his shoulders back.`}</p>
    <p>{`“N-Nothing, nothing at all, Reus. We’re just talking about- Hold on, how much did you hear?” Sylvain, who had retreated behind Dimitri, held up his pointer finger as if he was in class as he asked Reus.`}</p>
    <p>{`Reus shrugged, “Only you saying my sister's boobs are nice.”`}</p>
    <p>{`“Ah yeah, yeah, we were just talking about how nice your sister is to Dimitri, a handsome but lonely transfer student, weren’t we, Dimitri?” Sylvain elbowed him, eliciting a quick nod from him.`}</p>
    <p>{`“As long as your conversations about my sister are…” Reus paused, seemingly searching for the right words, “Safe for work.” Dimitri wasn’t really sure what that meant, but he assumed they were only allowed respectful conversations about Byleth.`}</p>
    <p>{`Which, frankly, was all he thought about. He may ‘love’ her according to Sylvain, but he didn’t really think about her breasts. From birth, he was already acclimated to the female form and breasts didn’t really bring about any excitement so to speak.`}</p>
    <p>{`Dimitri was also no stranger to how humans made love and all that, again, he didn’t put much thought into it, even his current appearance on land was partly due to his filial obligation to fulfil his duties to...go forth and bear fruit, so to speak.`}</p>
    <Ads mdxType="Ads" />
    <p>{`While Sylvain and Reus were arguing or otherwise occupied, Dimitri sank further into his thoughts. He recalled how he was educated on how merfolk, as humans, had to copulate to ensure the survival of their species. Dimitri may have been an introvert and shy, but he knew about love making and...getting it on and...getting hard and aroused.`}</p>
    <p>{`He was an adult male, but he had never felt such passion for a person before. To be fair, most of the time, Dimitri did spend a lot of his youth being alone in the ocean with creatures mostly out to eat him, thus rarely having the opportunity to think about sexual pleasure and the like.`}</p>
    <p>{`When you’re trying to give air to a drowning person or escaping a shark who caught onto your scent, one hardly has the mental capacity to think about other things apart from survival.`}</p>
    <p>{`For the first time in a long time, Dimitri finally gave into his thoughts about making love and... Byleth instantly appeared in his mind.`}</p>
    <p>{`Dimitri seemed to have been cursed with awful timing...because his mind went into overdrive with thoughts of being one with Byleth. His whole body felt hot, his heart rate started a steady climb and he felt a certain hardness in between his legs.`}</p>
    <p>{`Immediately sensing the horrible disposition he was in in the `}<em parentName="p">{`men’s`}</em>{` shower room, and rather unfortunately for Dimitri, there was almost nowhere to hide, the shower room had shower stalls on both sides of the room with a long bench in the middle.`}</p>
    <p>{`Dimitri had no choice but to gingerly cross his legs, one above the other. Though he struggled to hide the entirety of a semi-erect Dimitri Jr. in between his now crossed legs, since it was also terribly uncomfortable. The minor inconvenience he faced with his baby-maker instantly made him snap out of his little day dream, because he was `}<em parentName="p">{`still`}</em>{` buck naked in the shower room.`}</p>
    <p>{`Dimitri had never wished harder that he was clothed right this moment, cursing his younger, stubborn self for not acclimatising to clothes as a youngling.`}</p>
    <AuthorNote mdxType="AuthorNote">
  <p>
    The song Annette sings,{' '}
    <RemoteLink to="https://www.youtube.com/watch?v=7J3U4Ke0SCY" mdxType="RemoteLink">
      it only takes a minute
    </RemoteLink>
    , of course alludes to the title of this fanfic XD!{' '}
  </p>
    </AuthorNote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      