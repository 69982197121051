import AdSense from 'react-adsense';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import {
  StartingAdsTitle,
  EndingAdsTitle,
  Container,
  NightMode,
} from './AdsStyling';
import atFanfictionChapter from '../Navbar/atFanficChapter';
import blockAds from './block';

const InnerContainer = styled.div``;

// Put this more in index pages, or in pages with listings
const Infeed = ({ className }) => {
  return blockAds() ? (
    ''
  ) : (
    <Container className={className}>
      <InnerContainer>
        {atFanfictionChapter() && <NightMode />}
        <StartingAdsTitle />
        <AdSense.Google
          client="ca-pub-3881417792061359"
          slot="5188197282"
          format="fluid"
          style={{ display: 'block', textAlign: 'center' }}
          layout-key="-ee+6o-1-jy+10g"
        />
        <EndingAdsTitle />
      </InnerContainer>
    </Container>
  );
};

Infeed.defaultProps = {
  className: '',
};

Infeed.propTypes = {
  className: PropTypes.string,
};

export default Infeed;
