import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const RemoteLink = makeShortcode("RemoteLink");
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`There’s this common denominator among popular manga, and it’s `}<strong parentName="p">{`romance`}</strong>{`. If you don’t have romance in it, you gotta go for `}<strong parentName="p">{`comedy`}</strong>{` instead. Now you might say `}<em parentName="p">{`“That’s not true!”,`}</em>{` but from what I noticed, everyone loves these two genres! Almost all wildly successful manga has a mix bag of comedy, romance or both thrown into it. In case you haven’t noticed, the recent WSJ additions in `}<RemoteLink to="https://mangaplus.shueisha.co.jp/updates" mdxType="RemoteLink">{`MangaPlus`}</RemoteLink>{` are mostly `}<strong parentName="p">{`comedies`}</strong>{`.`}</p>
    <Ads mdxType="Ads" />
    <p>{`The new WSJ selections inspired me to shed light on the `}<strong parentName="p">{`older completed comedy`}</strong>{` titles that I really enjoyed back in the days. Enjoy these new reads! Here are my`}<strong parentName="p">{` recommended completed comedy manga`}</strong>{` titles <3 (I may or may not have slotted in a rom-com :D)`}</p>
    <h2>{`Completed Comedy Manga Recommendation #1: Binbougami ga!`}</h2>
    <p>{`If you like your `}<strong parentName="p">{`shounen comedy protagonist`}</strong>{` being a `}<em parentName="p">{`female`}</em>{`, you’re in for a good time. When the idea for this blog post came to me, this manga is the first title I wish to recommend to everyone! If you noticed the art seems familiar to you, then that’s great! You followed `}<RemoteLink to="https://mangaplus.shueisha.co.jp/titles/100078" mdxType="RemoteLink"><strong parentName="p">{`Twin Star Exorcists`}</strong></RemoteLink>{`, didn’t ya? This is Yoshiaki Sukeno’s older work before he started Twin Star Exorcists. The protagonist in Binbougami ga! may seem familiar to you too.`}</p>
    <Image alt="Binbougami ga!/Good luck girl! cover art" caption="Binbougami ga! has some really cool 2-page spreads and character roster." name="binbougami-ga-manga-cover.jpg" mdxType="Image" />
    <p>{`Sakura Ichiko is a perfect ojousama. She’s rich, good in her studies, great in sports, perfect eyesight and has a really big chest. Her life throughout her 16 years of age is `}<strong parentName="p">{`perfect.`}</strong>{` Men actually fawn over her! This gave her a great boost to her ego because honestly, nothing has ever gone wrong for her. She’s basically a walking `}<strong parentName="p">{`good luck`}</strong>{` card! Since her life sounds too good to be true, the God of Misfortune, Momiji visited her to turn her luck back into a regular person’s.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Momiji explained that Sakura’s good luck comes from her surroundings. She’s sucking good luck from everyone around her which led to her abnormal good luck counter, so Momiji is here to fix that. Obviously that’s bad news for Sakura. I mean, 16 years of good luck goes poof? Nuh uh, who would surrender their good luck?! But Momiji declared that it was her duty to suck out her good luck and prevent her from absorbing more! If Sakura continues ignoring this catastrophe, she will soon absorb good luck from her loved ones, creating misfortune for them.`}</p>
    <p>{`In Chapter 1, the first to get inflicted from Sakura’s good luck absorption is her butler. Seeing the tragedy of her body’s unique disposition happen firsthand, Sakura decided to let Momiji do what she came for in a roundabout way. Unfortunately, a single syringe isn’t enough to get all of the good luck out of Sakura. In fact, Sakura’s good luck counter got refilled again from her good luck `}<em parentName="p">{`absorption. `}</em>{`So Momiji is here to stay until Sakura’s good luck counter is finally back to the normal rates! But Sakura isn’t about to let that happen so easily...`}</p>
    <p>{`This manga is all about Momiji vs Sakura. Sakura is really selfish and shown to be heartless at first, but it wasn’t without reason. Since folks fawn over a lot due to her good luck, her ego went sky high. She mellowed a lot and could be considered a `}<em parentName="p">{`tsun`}</em>{` in later chapters. But all in all, both Sakura and Momiji do quarrel a lot since both have a totally different perspective to things. Hey, they get really close in the end, no worries! The art is unpolished (the characters' proportions are really long!) but it `}<strong parentName="p">{`does `}</strong>{`gradually get a whole lot nicer to look at. The ending is pretty wholesome! Please give this manga a try! It also has a completed anime.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Completed Comedy Manga Recommendation #2: Sakamoto desu ga?`}</h2>
    <p><strong parentName="p">{`Sakamoto desu ga?`}</strong>{` is one of the rare instances where both anime and manga hold out on their own. Both are really, really great on their own!`}</p>
    <Image alt="Sakamoto desu ga manga cover art" caption="Who am I? Well... I'm Sakamoto." name="sakamoto-desu-ga-manga-cover.jpg" mdxType="Image" />
    <p>{`Our protagonist in this manga, Sakamoto is `}<strong parentName="p">{`perfect.`}</strong>{` Now by perfect, I don’t mean his looks, his cashflow or attitude. He’s just… `}<em parentName="p">{`perfect`}</em>{` in whatever he does. A classic duster prank? No problem, he’ll anticipate the prank and handle it perfectly. Someone poured dirty water over him in the bathroom? No problem, he brought an umbrella. His desk and chair is gone from his seat? No problem, he’ll take the spot by the window while acting like he’s in a kpop music video.`}</p>
    <Ads mdxType="Ads" />
    <p>{`You see, Sakamoto is perfect in a way that he handles every incident in a `}<em parentName="p">{`cool`}</em>{` way. Everyone who sees this picture perfect man just can’t help but `}<strong parentName="p">{`swoon`}</strong>{`. The ladies just go `}<em parentName="p">{`wowww`}</em>{` but the bullies in the class didn’t like what they see in Sakamoto. It pisses them off! So these bullies would do anything to trip him up. Spoiler alert, Sakamoto just can’t be tripped up. He’s just so charismatic that he turns the bullies to a new leaf! `}<em parentName="p">{`Damnnnnn`}</em>{`.`}</p>
    <p>{`One of my favourite scenes is when the bullies caught Sakamoto in an empty science lab to bully him. They accidentally left cigarettes lit and it caused a fire. With their phones out of battery and the door locked, the bullies are out of options. What do they do to extinguish the fire? Well, Sakamoto has a plan. He started doing sidesteps to create a draft(?) for containing the fire. Then for some reasons, the bullies started joining in too (LOL). In the end they were saved because their sidestepping ritual was so loud, it got the teachers’ attention downstairs.`}</p>
    <Image alt="Sakamoto desu ga! manga panel 2" caption="There's a devilish ritual going on here, sensei." name="sakamoto-desu-ga-manga-panel-3.jpg" mdxType="Image" />
    <p>{`This entire manga’s comedy hinges on silly situations Sakamoto got himself into and equally silly solutions he came up with. I mean, no one… poses like that? Isn’t he embarrassed? (I personally would be embarrassed!) Despite looking really out of place doing what he did, he made sure to do it like a cool guy! It’s a short read so please give it a try. Once you're done with this title, you might want to take a look at this `}<RemoteLink to="https://mybrainiscompletelyempty.wordpress.com/2017/08/24/the-sakamoto-death-theory-sakamoto-desu-ga-and-why-its-wrong/" mdxType="RemoteLink">{`Sakamoto desu ga ending theory`}</RemoteLink>{`. Give the anime a whirl too while you’re at it.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Completed Comedy Manga Recommendation #3: Tomo-chan wa Onnanoko!`}</h2>
    <p>{`The first two isn’t rom-com but this one is! `}<strong parentName="p">{`Tomo-chan wa Onnanoko!`}</strong>{` is one of my favourite `}<strong parentName="p">{`4-koma rom-com`}</strong>{` series out there. Despite releasing only a page a day, I can’t explain how much I love the amount of `}<em parentName="p">{`cliffhanger tension`}</em>{` for the next chapter. How on earth did a page with only 4 panels manage to grip my attention so hard? In case you’re turned off by the 900+ chapters it has, don’t be. Each chapter only has 1 page!`}</p>
    <Image alt="Tomo-chan wa Onnanoko manga cover" caption="Best rom-com best rom-com BEST ROM-COM." name="tomochan-wa-onnanoko-manga-cover.jpg" mdxType="Image" />
    <p>{`Tomo is a boyish girl. She’s strong and tough because she practices karate. She’s basically the kinda girl everyone looks up to. Jun is her childhood friend. They were best buds since they were young. Jun lived all his life thinking that Tomo is a guy (She looks and behaves like one!) until they went to the same junior high school. Tomo wore a skirt for the first time and he just brushes it off. He literally touches her everywhere with zero self-awareness that she’s a girl. Tomo had enough one day and confessed to him. He brushes it off too. He’s pretty much labelled Tomo as a guy in his head and that `}<em parentName="p">{`pisses`}</em>{` Tomo off.`}</p>
    <Ads mdxType="Ads" />
    <p>{`She wants to make Jun acknowledge her as a woman, but Tomo’s best friend Misuzu thinks she’s outta luck given how Tomo usually talks and behaves. Each chapter is a silly continuation of their school romance life! The jokes just hit so hard given how oblivious Jun is to his own actions. Then there’s Misuzu. I love how the author gave the other side characters their own screen time! I really love Misuzu. She’s honestly brutal and emits a `}<em parentName="p">{`whatever `}</em>{`mood. I liken her to Saki Hanajima from Fruits Basket. Girl is hilarious. She and Jun have this enmity going on, creating some hilariously hostile scenes.`}</p>
    <Image alt="Tomo-chan wa Onnanoko! manga panel 1" caption="Misuzu teases Jun, Jun didn’t like it so he pushed her into the rain. Both of them just doing hostile staring without a word cracks me up." name="tomochan-wa-onnanoko-manga-panel-1.jpg" mdxType="Image" />
    <p>{`The other character I really love is Carol. She looks airheaded and really friendly, but behind all that sweetness is something really, really… Let’s just say you have to read the manga to understand her real mask. Unlike Misuzu and Tomo, Carol is a super daring girl who retaliates in her own way if someone gets close to her crush.`}</p>
    <p>{`This rom-com is so good! It was really popular when it was still ongoing. I felt an empty void when it ended. Tomo-chan wa Onnanoko got a happy ending so if you’re sold on my summary, please give this manga a whirl!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Completed Comedy Manga Recommendation #4: Beelzebub`}</h2>
    <p>{`Anyone remember `}<strong parentName="p">{`Beelzebub`}</strong>{` here! It was so good when it was still an ongoing series. I was really sad when it ended, but it did get a decent ending! The author Tamura Ryuhei is currently penning `}<RemoteLink to="https://mangaplus.shueisha.co.jp/titles/100104" mdxType="RemoteLink">{`Hard-Boiled Cop and Dolphin on WSJ`}</RemoteLink>{`. It is currently available for free on MangaPlus. It took me a while to realize it’s his work since his art has improved by a lot!`}</p>
    <Image alt="Beelzebub manga cover art" caption="Oga the ogre is holding a baby bottle. The baby with green hair on his back is Beelzebub! The baby is always naked. No shirt no nothing, he just wears pampers." name="beelzebub-manga-cover.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <p>{`Oga, our protagonist is an “ogre”. He’s devilish-ly strong and would gladly pick up fights with anyone who asks for it. His school has a class full of equally strong students who each belong to a gang. Like, what is going on with his class... When I mean gangs, I mean the type that goes around in groups trying to conquer other schools or districts. Oga was out beating down some unfortunate victims when he came across an old man floating down a river. He saved the poor old man and for some reasons, he split in two and a baby appeared…?`}</p>
    <p>{`Oops sorry. I forgot to mention that while this is a comedy, this is also a fantasy shounen. There’s devils, magic and whatnot in Beelzebub! The baby’s name is Beelzebub by the way. As in, the soon-to-be demon king himself! A wet nurse named Hilda was going around looking for an appropriate human caretaker when Beelzebub picked Oga. This marks the start of an adventure of Oga as the parent for the baby Beelzebub! I wouldn’t say the art is great but it does it’s job fine. If you wonder just what is in it for you in this manga, let me rattle off some story arcs. Oga will be going around subduing the four great leaders of the school gang, visiting the demon world to bash some folks up, join an equally exciting sports battle and more fights. Beelzebub has a ton of fights in it and each one is really fun (and funny) to read.`}</p>
    <p>{`The anime may or may not interest you because it has a ton of filler episodes to fledge out the side characters. Most of these fillers are comedic episodes. I did try to jump into the anime but I find the manga’s pacing fits me better.`}</p>
    <h2>{`Completed Comedy Manga Recommendation #5: Samon-kun wa Summoner`}</h2>
    <p>{`This is a gem! I found it while browsing and I’m glad that I got interested enough to click on it. Sakura Teshigawara is an `}<em parentName="p">{`angel`}</em>{` and by that, I mean her personality. She never gets mad at jokes, treats everyone really well and never says no. Her friends refer to her as Buddha Tesshii/Saint. She’s just that nice of a person… It did irk her a little but what’s done is done when her title got out of hand. One day, a new student transferred in. His name is Samon Sousuke. On his first day at school, he openly declared that he is a demon summoner.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Samon-kun wa Summoner manga cover art" caption="From the left, Samon-kun, Sakura. The rest are all demons that Samon can summon." name="samon-kun-wa-summoner-manga-cover.jpg" mdxType="Image" />
    <p>{`Everyone avoided him after that, except Sakura. Well, she IS the class rep so she has to talk to him to get classwork done. Sakura’s goodwill was soon noticed by Samon and oh boy, he told her straight that he didn’t like her at all. Samon being the nasty guy he is then summoned demons for real to tempt Sakura. He wants to see her succumb to her inner demons but Sakura won’t yield so easily! You see, Samon is a real demon summoner and a real bastard. He has a terrible personality! This is how Sakura got involved with Samon-kun, a real demon summoner.`}</p>
    <Image alt="Samon-kun wa Summoner manga panel 2" caption="Here is Samon-kun trying to rile up a fellow demon. Poor guy, he’s just trying to make ends meet but Samon-kun couldn’t care less." name="samon-kun-wa-summoner-manga-panel-1.jpg" mdxType="Image" />
    <p>{`The art is really cute, for starters. I love their expressions and the thick lineart. It makes it easy to appreciate the cartoonish art! I did mention Samon is a bastard in terms of personality, but as the story goes on… He still IS a bastard but he mellowed just by`}<em parentName="p">{` a little`}</em>{`. Truth be told, Samon is honestly just an awkward boy trying to make friends. He and his mischief-making friends are what’s giving this manga the comedy genre tag!`}</p>
    <p>{`Then there’s Sakura who is really angelic but `}<em parentName="p">{`not.`}</em></p>
    <Ads mdxType="Ads" />
    <p>{`The story did go into explaining Sakura’s background, which I find really interesting. The ending did clarify Sakura and Samon’s relationship but it probably wasn’t what the readers wanted. It’s still really good and I highly recommend trying this title out. Unfortunately, `}<strong parentName="p">{`Samon-kun wa Summoner`}</strong>{` does not have an anime.`}</p>
    <h2>{`All in all`}</h2>
    <p>{`Writing about these titles really takes me back. There’s some good comedy manga nowadays but these manga hit me really hard when it was first published. I hope you enjoy these `}<strong parentName="p">{`completed comedy manga recommendations`}</strong>{`!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      