import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const RemoteLink = makeShortcode("RemoteLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Are you sick and tired from the lack of support for the English weeaboo fandom in Malaysia? Why are the weeb books only available in Kinokuniya, and why has Kinokuniya opened a shop in Kuala Lumpur, the heart of capitalism and traffic jams?! And why won’t Big Bad Wolf/BookXcess sell up-to-date English manga copies?! Just… why?!`}</p>
    <p>{`If my little rant struck a chord with you, then I’m glad that I’m not alone. I have always been frustrated at my tiny town. The only two major bookstores here (and forevermore) are MPH and Popular. Both always sell textbooks, magazines or popular novels only. There’s Chinese light novels and Malay ones too, sure, but their selection isn't that great. I love going to bookstores but after viewing the same selections over and over again without change, you’ll feel frustrated too. But these book stores not stocking up on `}<strong parentName="p">{`my favourite books`}</strong>{` is not without good reason… After all, in this small town, I’m probably`}<strong parentName="p">{` the only one`}</strong>{` buying them up if they ever bring in English manga or light novels. Besides, these books are bloody expensive! Who’s going to fork out RM100+ for an Overlord light novel copy other than me?!`}</p>
    <Ads mdxType="Ads" />
    <p>{`So I have no choice but to look for my books online. I could have opted for digital e-books (E.g. J-Novel, Amazon) but nothing beats the smell of a physical copy in my hands <3 I got super desperate one time, went on a Googling spree and found these stores. I have bought books from them and yes, they are legit stores. Fair warning, be prepared to fork out cash!`}</p>
    <h2>{`Online Bookstore to get English Manga and Light Novel in Malaysia #1: Opentrolley`}</h2>
    <p>{`This is one of those online stores that really screams sketchy due to their UI design. It feels so… lacking compared to other online stores. It’s really hard to find books with the categorization (Which genre is for light novels again…?). So I tried their search bar and wow, it’s kinda bad. I could only view 20 at a time and the way they try to look up your search term is kinda funky. But wow, they have the light novels I want so everything's good.`}</p>
    <Image alt="Opentrolley landing page" caption="Their landing page. If you scroll further down, it's a super simple site. It's like a... massive library just waiting for you to discover but only if you know the exact title to search for!" name="opentrolley.jpg" mdxType="Image" />
    <p>{`If they have the books in stock, they’ll request from their suppliers overseas and ship them to you. The whole delivery process took like, 2 weeks because the books will `}<strong parentName="p">{`definitely`}</strong>{` get delayed by the custom. They’ll give you a tracking code once the books are shipped so you know where your books are at! They used DHL to send my books and DHL is pretty fast in my area! They don’t really have a free shipping thing going on (unless I missed their promotion but I don't think they ever had a promotion going on so far) so I usually just pay RM8 as the shipping fee. If you want a book from the site but it says `}<strong parentName="p">{`Pre Order`}</strong>{`, you can do exactly that! But you could only pay for that 1 book alone, along with the shipping fee. They don’t allow you to pre order multiple books in 1 order.`}</p>
    <Image alt="null" caption="They keep updating this order updates table which is nice, because I’m the type to refresh the page every day." name="opentrolley-order-status.jpg" mdxType="Image" />
    <p>{`Customer service is super responsive and answers my email really fast. If you have any questions about a title, you could email them about it and they’ll give you a succinct answer right away. This is my favourite site to buy light novels from. If they don’t have the title I want… Well, time to move on to the next online store. `}<RemoteLink to="https://opentrolley.com.my/" mdxType="RemoteLink">{`Visit Opentrolley here.`}</RemoteLink></p>
    <Ads mdxType="Ads" />
    <h2>{`Online Bookstore to get English Manga and Light Novel in Malaysia #2: Bookurve`}</h2>
    <p>{`Another great find! Mostly because they have the titles I want `}<strong parentName="p">{`AND`}</strong>{` it’s cheaper than Opentrolley! I notice that most books here cost at least RM15 less compared to Opentrolley. They have a points system, along with a free shipping promotion too if you buy more than RM25, but that’s only for West Malaysia. I am sorry, Sabahans and Sarawakians...`}</p>
    <Image alt="Bookurve" caption="A screenshot of their presentation on 'why you should buy from them'. Cakap je free delivery, but they got terms and conditions." name="bookurve-delivery-conditions.jpg" mdxType="Image" />
    <p>{`The site looks fancy along with their intense book categories and sections. However! The only part of the site I visit is my Order status page `}<strong parentName="p">{`AND`}</strong>{` the search bar. Unlike Opentrolley, putting in the full title of the books I want returns me the exact book I want. On Opentrolley, they keep slipping in random book titles that only somewhat match the search term. I find it super unintuitive. If I want to look for a random book to buy, I’ll click on the categories, not look them up!`}</p>
    <Ads mdxType="Ads" />
    <p>{`Experience buying books on Bookurve is pretty good. Everything on site feels intuitive. Buy books, wait for them to ship and soon, you’ll have a tracking number to follow up. Unfortunately the only complaint I have is that if a title is unavailable, I couldn’t pre order them or tell the customer service I’m willing to pay for that book. I messaged the customer service through Whatsapp (seems like the quickest channel to get them talking) and the image below is the whole reply I got. Okay, I am used to the Malaysian customer service treatment but no need to be this harsh.. A reply would be nice. This just means further messaging will get me nothing. So if the book I want is not available, I couldn't be bothered to ping them and move on to the next bookstore. `}<RemoteLink to="https://www.bookurve.com/" mdxType="RemoteLink">{`Check out Bookurve here.`}</RemoteLink>{` Or you can visit their `}<RemoteLink to="https://shopee.com.my/bookurvemalaysia" mdxType="RemoteLink">{`store at Shopee.`}</RemoteLink></p>
    <Image alt="Bookurve CS" caption="I'm used to being ghosted by customer service peeps so this totally doesn't hurt T_T... Haiz. I'm adding this screenshot here to inform you what to expect." name="bookurve-customer-service.jpg" mdxType="Image" />
    <h2>{`Online Bookstore to get English Manga and Light Novel in Malaysia #3: Kinokuniya Online`}</h2>
    <p>{`Sigh. This store got me conditioned well when it comes to buying rare books from Japan. Or in this case, from the US. Everytime there’s a book I want, I will search their online store first. That said... If possible, I never want to touch this store ever again thanks to a terrible experience I had with them.`}</p>
    <p>{`Some time ago, I tried to pre order a book from them. They say they’ll send the book over once the book is shipped from overseas. After 2 months, no news from them at all. It turns out, the package was cancelled `}<strong parentName="p">{`WITHOUT`}</strong>{` informing me at all. Not even an email! `}<em parentName="p">{`Nada.`}</em>{` The least they could do is tell me the order was cancelled so I wouldn’t wait for the book forever! Since then, I swore never to pre order any books from them.`}</p>
    <Ads mdxType="Ads" />
    <p>{`But in the end, I couldn’t deny the amazing selection they have. So I kinda compromised ): I get books from Kinokuniya Online `}<strong parentName="p">{`only if`}</strong>{` the product page says `}<strong parentName="p">{`IN STOCK.`}</strong>{` Nevertheless, you should always think twice before buying their books. Kinokuniya’s books are really expensive online! Should you get books from here… or from Bookdepository if you are willing to wait?If everything goes well, Kinokuniya is honestly a pretty fab bookstore. That’s because they won’t ask any questions and are willing to `}<em parentName="p">{`gift wrap your books without any extra charges`}</em>{`. I like receiving presents, okay? Even if the sender is jibun wo. `}<RemoteLink to="https://malaysia.kinokuniya.com/" mdxType="RemoteLink">{`Visit Kinokuniya Online store here.`}</RemoteLink></p>
    <h2>{`Online Bookstore to get English Manga and Light Novel in Malaysia #4: Bookdepository`}</h2>
    <p>{`This store got bought over by Amazon and I’m ever so grateful to them for doing so. Why? The answer is simple. `}<strong parentName="p">{`I am always guaranteed to get my money back xD`}</strong>{` If you’re a book nut, I’m sure you’re familiar with Bookdepository. Just in case you aren’t… Think of this online store as an extension of Amazon. It has all sorts of titles in it, even older titles. They run promotions every month! Sometimes they pick a country and give them a 10% off promo code. They offer free shipping and their books are always cheap!`}</p>
    <p>{`If you’re willing to wait 1 month for your books without a tracking code, this site is for you. They’ll send your books through Royal Mail priority airmail and Pos Malaysia will be incharge of sending them to you once it reaches Malaysia. Since they are sent without a tracking code, it’s considered a “letter”. As such, the post guy will just chuck your book into your post box without telling you. Or if they see there’s no one at home, they could just leave the premises without giving you the books. Thankfully the customer service is more than happy to refund you if the books are still not here. It’s one of the reasons I love using this site if I am okay with the wait.`}</p>
    <Ads mdxType="Ads" />
    <p>{`But bear in mind you may not get your books since it could go missing. I tried looking up my missing books by visiting nearby Post Offices. I even called the HQ! Alas, they keep telling me as long as there’s no tracking code, they could do nothing. I blame it on covid. The books have been missing for three months, I’m telling ya. And still no sign of it. At least Bookdepository refunds, yea? ):That's not to say that every book sent by Bookdepository goes missing. I have been buying from them a lot before covid happened and their service is great. Books reach me within 1 month and without any damage at all. Do give Bookdepository a try! Their prices and selection will definitely change your mind. `}<RemoteLink to="https://www.bookdepository.com/" mdxType="RemoteLink">{`Visit Bookdepository here.`}</RemoteLink></p>
    <h2>{`Online Bookstore to get English Manga and Light Novel in Malaysia #5: Shopee`}</h2>
    <p>{`How desperate are you to read that particular light novel you’ve been eyeing on? Desperate enough to visit the most unlikely online shopping platform, I guess. Yes, that’s me. I went on Shopee thinking there couldn’t possibly be light novels for sale but voila, there is?! These sellers are all individual sellers, so I wasn’t sure if they could be trusted. I took the bait and bought Eighty-Six light novels from an Indonesian seller.`}</p>
    <p>{`Once they arrived, they confirmed my suspicion. They got the digital e-book and simply printed them into a nice book. The printing quality is not too shabby but since it is a digital e-book, it feels… lacking. I guess it’s good enough to read if you’re desperate but nothing beats original copies. Only try Shopee if you’re out of options, tight with money and don’t mind non-original copies.`}</p>
    <Ads mdxType="Ads" />
    <p>{`I couldn’t share the one Indonesian seller that I got my light novels from since the customer service is pretty shabby. Their packaging is so bad that the book’s edge got chipped off. I showed it to them to request a refund. Most sellers would quickly refund me my money since I have a strong argument to do so. But the gall of this seller! They rejected my refund request and Shopee have to step in. I got my money back but I’m super sour from the whole experience.`}</p>
    <Image alt="Shopee seller. Hmph." caption="But in case you want to test your luck and get the books, this is the seller I was talking about. I got unlucky and received a super badly damaged copy. But the book still can read la... Got a few repeated pages too. If you don't mind any of those, give this seller a try. Otherwise, just try to get original copies." name="shopee-seller.jpg" mdxType="Image" />
    <p>{`You just need to search "light novel" or "manga" in Shopee and you'll get some hits. You could even try looking up your series' title just to check if there's someone selling.`}</p>
    <h2>{`All in all`}</h2>
    <p>{`I hope these options I listed helped you to find the books you want! All the best, fellow weeb!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      