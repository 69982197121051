import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const RemoteLink = makeShortcode("RemoteLink");
const Ads = makeShortcode("Ads");
const Blockquote = makeShortcode("Blockquote");
const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`When it comes to building characters in Genshin Impact, everyone seems deadset on how certain characters are played. After all, why wouldn't I `}<strong parentName="p">{`NOT`}</strong>{` want to spam the Geo Archon's meteor and see some big numbers on my screen?`}</p>
    <p><em parentName="p">{`Tut-tut`}</em>{`, my good reader... You are missing out on the Geo Archon's wonderful spinning kick animation!`}</p>
    <p>{`Then again, `}<RemoteLink to="https://keqingmains.com/" mdxType="RemoteLink">{`Keqingmains guides`}</RemoteLink>{` are always about maximizing damage out of each character if you want a better chance of clearing Spiral Abyss, a terrible DPS check with a ridiculous timer. On Floor 12, you will need to clear the first part of the floor as fast as you could to give the second part a better chance. Best if you could secure at least 1 minutes 30 seconds to clear the second part, otherwise it is super hard to finish at 3 stars.`}</p>
    <Ads mdxType="Ads" />
    <p>{`You see, it makes sense to tweak your characters' damage and synergize with the rest of your team for those bigger numbers.`}</p>
    <Blockquote mdxType="Blockquote">
  But what if... you don't care about all that? Overworld enemies are easy
  enough if you know what you are doing. Let's just build characters however you
  want!
    </Blockquote>
    <p>{`I've just recently reached AR59, and most of my meta teams are pretty much done. I thought to myself, let's try building characters so they could stay longer on the field instead of swapping them out after using their elemental skill (E) or elemental burst (Q)!`}</p>
    <p>{`Here are 4 of my favourite builds! Enjoy the showcase! I hope these give you an idea of how to build your team as well. Remember, if your character's damage is lacking, make do with supports! Yunjin is especially amazing <3`}</p>
    <h2>{`Genshin Impact Jean Physical DPS Guide: What Anemo DMG?`}</h2>
    <p>{`There's this really popular team build going on with Jean and Bennett where you need both of their Q to overlap. Look up the `}<RemoteLink to="https://library.keqingmains.com/evidence/characters/anemo/jean#interaction-between-jean-sunfire-and-freedom-sworn" mdxType="RemoteLink">{`Sunfire build`}</RemoteLink>{`! Players come up with lots of creative ways to play this game, I swear...`}</p>
    <Ads mdxType="Ads" />
    <p>{`I did give it a try, and I think it's an ingenious way to apply the Pyro status on enemies. But what if... all I want to do is just whack at enemies happily? The most common way of playing Jean is 1) yeet and 2) Heal. Her auto attacks are on the slow side and she is much more useful as a healer so I understand why not many want to play her as a Physical DPS.`}</p>
    <p>{`I hope the videos below will change your mind. The first video is with an R5 Favonius Sword. The second video is with Aquila Favonia! I have Yunjin to boost my Normal Attack DMG in both videos, while the other two Pyro characters are just there for Pyro resonance.`}</p>
    <Video src="https://www.youtube.com/embed/qTBgzWBD4mM" title="Jean Physical DPS w/ Favonius Sword" mdxType="Video" />
    <Video src="https://www.youtube.com/embed/MEYW7jk7VUI" title="Jean Physical DPS w/ Aquila Favonia" mdxType="Video" />
    <p>{`The build is a super simple one. 2 set Gladiator's Finale and 2 set Pale Flame for the generic 18% ATK boost, along with 25% Physical DMG boost. Now add that 58.3% Physical DMG goblet! What Anemo DMG? Zilch. As you can see, both videos have decent E damage without an Anemo DMG goblet. Your Jean is fine without an Anemo goblet!`}</p>
    <p>{`I use ATK/Physical DMG/Crit Rate or Crit Damage as needed for Jean. You could replace the 2 set Gladiator's Finale with 2 set Pale Flame instead, it's all up to you. I only used 2 set Gladiator's Finale because I didn't want to waste my good artifacts!`}</p>
    <h2>{`Genshin Impact Fischl Physical DPS Guide: Oz as emotional support`}</h2>
    <p>{`I love using Fischl when I first started the game. Her auto attacks are fast and furious (pun intended), not to mention how useful Oz is. Her C6 is a total game-changer! But for the physical build, Oz is just a "nice-to-have" asset here.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Ever since I have Yunjin, I keep using her with anyone that peruses auto attacks. Since we're doing all-out Physical DMG boost, why not add Superconduct to the mix? Voila, I am astonished with the results :O`}</p>
    <p>{`I added two videos below. The first is an old video where I was just fooling around with Fischl. The second one is my current build.`}</p>
    <Video src="https://www.youtube.com/embed/ofUEpWIQC-U" title="Fischl Physical DPS with Rust" mdxType="Video" />
    <Video src="https://www.youtube.com/embed/FgN5UiOSd1s" title="Fischl Physical DPS build" mdxType="Video" />
    <p>{`In the first video, I had Geo resonance on. Yunjin is there for Normal Attack DMG boost, Zhongli for his 20% shred, Rosaria with her 4-set Noblesse Oblige set effect, along with her Q's crit rate buff. Without all of these buffs and Superconduct, Fischl probably wouldn't have hit that hard. But oh boy, a 15,000 hit from Fischl's auto attack? That's a first for me!`}</p>
    <p>{`In both videos, I used Rust R5, 2 set Pale Flame and 2 set Bloodstained Chivalry. I'm using ATK/Electro DMG/Crit Rate or Crit Damage as needed for Fischl. I wanted to swap out the Electro DMG goblet for a Physical one, but I'm of the opinion it is best to keep using an Electro DMG goblet since I still use Oz for other teams.`}</p>
    <h2>{`Genshin Impact Mona Main DPS Guide: I amplify my own damage!`}</h2>
    <p>{`I have Mona benched since forever. I keep telling myself I'll build her one day, but I never have enough resources in this game... I did try to build her as a Q bot initially. Meaning to say, 4 set Emblem of Severed Fate with Thrilling Tales! But the more I use her for auto attacks, you get that feeling she's wasted as a Q bot. She's really fun as you zoom around the field while splashing the enemies!`}</p>
    <p>{`So I thought, why not as a DPS? I saw many folks actually tried doing that, and it looks really fun!`}</p>
    <Ads mdxType="Ads" />
    <p>{`Here's an old video of me trying out my DPS build. Raiden is just there for her Q buff, Zhongli for shield and 20% shred while Yunjin buffs Normal Attack DMG. Geo resonance is also active! The second video is my current build.`}</p>
    <Video src="https://youtube.com/embed/9cAbyrt-4PI" title="Mona DPS with Widsith" mdxType="Video" />
    <Video src="https://youtube.com/embed/RmTbHjkEXfU" title="Mona DPS build" mdxType="Video" />
    <p>{`I'm still working on her but I'm pretty happy with her current damage! I'm using Widsith R2, 2 set Heart of Depth and 2 set Gladiator. ATK/Hydro DMG/Crit Rate or Crit Damage is what you should farm for.`}</p>
    <p>{`You could use Favonius Codex with 4 set Emblem of Severed Fate too! It's honestly doable. I had that on Mona with the same setup in the first video before swapping to the current build.`}</p>
    <p>{`I just love using her Q, followed by her charge attack. There was once I managed to deal 60,000 damage on a Mitachurl, and I was so surprised. Why didn't I build Mona earlier? (x.x)`}</p>
    <h2>{`Genshin Impact Zhongli Physical DPS Guide: Rock solid, just like my love for him`}</h2>
    <p>{`They say you should keep the best for last <3 So here's Zhongli!`}</p>
    <p>{`Zhongli is my nam ba wan (TL Note: Number 1) most used character for his shield AND as an onfield DPS. You may also find his Exuvia plushie lying on my bed.`}</p>
    <Blockquote mdxType="Blockquote">Yes, I like him a lot.</Blockquote>
    <p>{`I honestly think this man has the most builds you can do among Genshin Impact characters.`}</p>
    <ol>
      <li parentName="ol">{`You can try making him a shield bot where all you do is dump HP on him. Thick shield and even thiccer booty at 50,000 HP.`}</li>
      <li parentName="ol">{`You can work on his Q damage so each meteor he throws does a solid 80,000 and above. Yup, it is possible with The Catch R5!`}</li>
      <li parentName="ol">{`You can make him your Physical DPS guy with Crescent Pike. He pokes hard but most importantly, he spins his stick with his leg.`}</li>
      <li parentName="ol">{`You could try balancing both his auto attack and Q damage by (No surprise here) equipping Staff of Homa on him. Equip a Geo DMG goblet on him and half his HP to see impressive damage whenever he pokes or throws his meteor!`}</li>
    </ol>
    <Ads mdxType="Ads" />
    <p>{`I've listed out pretty much all the builds I've seen folks use. Most players enjoy the shield bot build because that's the easiest to work with. But that's not how I want to play him! His spinning kick is so satisfying to watch. Just swapping him out after activating his shield is such a waste ):`}</p>
    <p>{`I got Zhongli from his first banner so as a new player, I don't have any decent polearm for him. I make do with a Favonius Lance back then. I keep switching out his polearms because I wanted him to do more damage. From Favonius to Deathmatch, followed by Crescent Pike and lastly, The Catch. In the end, I `}<strong parentName="p">{`still`}</strong>{` wasn't happy enough with his damage so I got him Staff of Homa.`}</p>
    <p>{`Staff of Homa is a great polearm for everyone so obviously it works great on him. Then Vortex Vanquisher got a rerun. I can't exactly call myself a Zhongli die-hard fan if I don't have Vortex Vanquisher, right? So yes, I pulled it and I `}<em parentName="p">{`desperately`}</em>{` want to make it work on him.`}</p>
    <p>{`My solution? `}<strong parentName="p">{`4 set Retracing Bolide with Physical DMG goblet.`}</strong>{` The first video shows his damage with Superconduct and Yunjin's Normal Attack DMG buff. My Rosaria is holding 4 set Noblesse Oblige and her Q buffs crit rate.`}</p>
    <Video src="https://youtube.com/embed/ydy4JReib5Q" title="Zhongli Physical DPS with Vortex Vanquisher" mdxType="Video" />
    <Video src="https://youtube.com/embed/Xj2EFmtAKpM" title="Zhongli Physical DPS build" mdxType="Video" />
    <p>{`I first tried using Vortex Vanquisher with 4 set Pale Flame. The verdict? Damage is too conditional to be useful, and is not as strong as 4 set Retracing Bolide's effect when it comes to Normal Attack DMG. I believe it's because of Vortex Vanquisher's ATK buff. With 4 set Pale Flame's passive active, I have way too much Attack buffing Zhongli. With 4 set Retracing Bolide active, Zhongli's damage is still strong even without Superconduct active.`}</p>
    <p>{`Now with the optimal team synergy, my Zhongli gets to do at least 6,000 damage per spin during his spinning polearm animation. `}<em parentName="p">{`chef kiss`}</em>{` Ah-mah-zing.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`All in all`}</h2>
    <p>{`Phew. I recorded the newer videos and wrote this post all on the same day. I feel super proud of my builds, especially Zhongli's. Please don't feel pressured to follow guides online. Just use whatever artifacts and weapons you have! As a matter of fact, I didn't farm for Jean's, Fischl's and Mona's artifacts at all. They are just using hand-me-downs :D`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      