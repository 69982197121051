import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXFanfiction/Chapter/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Divider = makeShortcode("Divider");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`“Boar?” Byleth looked up at Felix still staring at Dimitri.`}</p>
    <p>{`“The Dimitri you see every day is an act. `}<em parentName="p">{`This`}</em>{` is the real him.” Felix nodded at the battle in front of them. “Well, not entirely either. This is quite mild, actually.” Byleth shuddered. Mild? She made a mental note to not get on his bad side.`}</p>
    <p>{`“We grew up with him, you see.” Sylvain chuckled, as though reminiscing a fond memory. Ashe added that this was a rare sight for him too, even though they have shared a room for almost three years. “All the best, pal.” Sylvain gave her a light punch on the arm as she stood up; she was up next.`}</p>
    <p>{`Oh boy.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Byleth was confident in her skills, which was why she thought they could win the tournament. She watched him bring his weapon down on his sparring partner with sharp accuracy, and gulped. Dimitri was only using a wooden sword, but it looked as though it would break from the sheer force of his grip alone. He didn’t say anything as the last student he crushed limped away, nor when she positioned herself in front of him.`}</p>
    <p>{`The timer sounded. Dimitri’s sword swung down at her shoulder, his strength monstrous. She parried at the last second. Shockwaves vibrated through her arms, threatening to buckle her knees. Her previous experiences with him were unreliable now. His patterns were erratic and relentless, combining sword play with other fighting styles. Unpredictable. Byleth’s mind raced to catch up with her survival instincts now roaring in her ears. Blow after blow were wearing out her arms and shoulders. She couldn’t defend forever. Byleth landed a few attacks, but Dimitri blocked them easily. Breathing was difficult with the tight band around her chest, and overexertion might expose her secret if the chestbind gave way.`}</p>
    <p>{`She was sensing a pattern emerge from Dimitri’s erratic attacks. If she timed this right, she might just make it. Dimitri jabbed at her. An opening at his ribs! She side-stepped and channelled her strength into her one swing, hoping to end it all. But she was suddenly lifted off the ground, and let out a yelp. Dimitri was held back by Felix, yelling at him to stop, and Ashe trying to calm Dimitri down.`}</p>
    <p>{`The congregating crowd stood silently as the dust settled.`}</p>
    <p>{`“The match is over.” Sylvain released his arms from around her waist, and her knees buckled. Sylvain caught her arm before she hit the ground, asking if she was alright. Byleth raised a weary hand, waving him away. She took three deep breaths. Her head grew faint and her heart was ramming against her chest binds. It had only been a couple weeks since the tournament and her last spar. Was she already that out of shape? No. It was Dimitri and his crazy strength. She cast a sidelong glance. Felix had already let go of him. Dimitri wasn’t smiling, but at least the murderous intent in his eyes was gone. Dimitri approached her and held out his hand for a shake. Beads of sweat trickled down his face and eyelashes.`}</p>
    <p>{`“That was a good match. You’re a worthy opponent, Reus.” She took his hand and pulled herself up.`}</p>
    <p>{`“Likewise, Dimitri.”`}</p>
    <p>{`As soon as they both walked to their benches to rest, the murmuring started. And Byleth found herself staring at the timer. It recorded just over ten minutes, but their fight felt like several seconds.`}</p>
    <Divider mdxType="Divider" />
    <p>{`Dimitri had intended to give himself a minute to wipe down his body and quench his
thirst, but Coach Kostas grabbed his shoulder and swung him around. “You’re not done
Blaiddyd!” He smirked. “Oh no, you’re today’s punching bag. You’re only done when
I say you’re done!”`}</p>
    <p>{`Dimitri opened his mouth to protest but decided against it. His anger seemed to have triggered something cruel in their coach. Nobody should have to suffer because of his inability to control himself. “Yes sir.” He let out a small sigh as soon as Coach Kostas turned his back. Sylvain managed to quickly squirt some water into Dimitri’s mouth with his squeezy bottle before jogging back to the center of the hall.`}</p>
    <p>{`Byleth clicked her tongue watching Coach Kostas. Poor Dimitri, she thought as she melted into the bench beneath her. Sweat was pouring out of every pore in her body.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“That was intense!” She almost leaped out of her skin. The boy named Caspar had planted himself next to her. She nodded but placed a hand on her head to steady herself.`}</p>
    <p>{`“You tell me, bro.”`}</p>
    <p>{`Caspar gripped her shoulders and shook it. “Dude— the way you threw Dimitri off when he smashed his sword down on you was `}<em parentName="p">{`so cool!`}</em>{`”`}</p>
    <p>{`Byleth couldn’t help but chuckle. “Thanks man! But as you can see, I’m beat. Almost literally.”`}</p>
    <p>{`He slapped her on the arm and laughed with a twinkle in his eyes. “Oh, come on, you don’t have to be so modest! The name’s Caspar! I’m not good with names, so what’s yours again?” Byleth rubbed the spot Caspar slapped her and introduced herself as Reus Eisner, and returned the friendly gesture.`}</p>
    <p>{`“I know—let’s go jogging sometime together! We can start tomorrow morning at five A.M!” Caspar said in one breath. “How about I come to your dorm at four?” Before Byleth could answer, Caspar ran off to prepare for his round against Dimitri. He was so enthusiastic she couldn’t help but feel his excitement rub off on her, but her heart sank at the thought of getting up earlier than usual. And if Casper offered to bond as newly acquainted bros via the communal shower, she wouldn’t know what to do with herself.`}</p>
    <p>{`Despite watching Dimitri for the past hour, Coach Kostas always stayed in her field of vision. The coach paid the match no attention, seemingly content with his phone. His thumb scrolled across the screen constantly as he grinned creepily to himself, and occasionally typed a couple of messages.`}</p>
    <p>{`Club meet was far from over when he stood up abruptly and marched over to Dimitri. It seemed the coach was incapable of speaking normally as he was either always rude, or screaming at them, like he was screaming at Dimitri before he stalked out the door.`}</p>
    <p>{`Byleth slipped out the hall, hoping her espionage skill was enough to sneak after Coach Kostas undetected. She followed him down the empty hall, keeping against the walls and in the shadows. Heart pounding, pulse deafening. To her delight, she followed him to his office, not too far from the training grounds. But it would be quite the trip to make from the dorms. Sneaking after curfew would prove difficult. Even the office door still used the old lock and key. Problem after problem. It looked like she would have to consult the internet on how to pick a lock.`}</p>
    <p>{`“Well, well, well, what do we have here?”`}</p>
    <p>{`She spun around. Cheeky, green eyes gleamed back at her. Suddenly painfully aware of her posture which was anything but suspicious, she straightened herself. Fully expecting some form of punishment for trespassing, she frantically came up with an excuse. But it didn’t matter what she wanted to say, the words turned to air when it came out of her mouth. However, he started laughing at her.`}</p>
    <p>{`“Don’t look so scared!” He croaked. She yanked him down into her corner and hushed him.`}</p>
    <p>{`“I’m not supposed to be here.” Byleth whispered.`}</p>
    <p>{`“Nobody is—it’s off limits for regular students.” The dark haired boy with the small dangling braid pointed at one of the signs nearby. She smacked her forehead. No wonder there weren’t any students.`}</p>
    <p>{`“Hey, then you aren’t supposed to be here either!” She hissed.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Claude Von Riegan, Captain of the Chess Club at your service sir.” He smirked at her. “You look like you’re new here, so I guess you don’t know but we keep all our equipment for club activities in our respective advisor’s office.” He jingled the bag on his shoulder. “What’s your name again?”`}</p>
    <p>{`“Uh… If I tell you, are you going to report me or something?” Her question made Claude chuckle, even more so as her expression was so serious.`}</p>
    <p>{`“I like you! So no. I won’t.” He stood up.“Besides, if I did - I’d never get to hear what you were doing here anyway.” He offered her a hand.`}</p>
    <p>{`Byleth studied his face and decided he wasn’t a threat even though his smile didn’t quite reach his eyes She took his hand. “Reus Eisner.”`}</p>
    <p>{`“I’ll be damned, you’re `}<em parentName="p">{`that`}</em>{` new kid.” Claude raised an eyebrow. “The one that got expelled for fighting yeah? Phew, I can’t believe my luck! You gotta tell me what happened. Nothing like that ever happens here in Garreg Mach.” The door to one of the office’s swung open. Byleth’s heart rate shot up, remembering she wasn’t supposed to be here.`}</p>
    <p>{`“Relax. No one’s going to kick you out of school for just being here.” He said and peeked round the corner to see who came out of the room... or went in.`}</p>
    <p>{`“You’re not the only one who trespasses here on the daily.” Claude grinned.`}</p>
    <Divider mdxType="Divider" />
    <p>{`Dimitri dropped down onto the bench and emptied the contents of his tumbler over
his face, further saturating his already sweat-drenched sports uniform. Sweet relief.
It had been two hours since he started sparring and. His muscles were trembling with
fatigue. Even though this was supposed to be his punishment, he enjoyed it. But relishing
the muscular pain would have to wait. He had Captain duties to attend to. He pushed
himself back up and gathered the other members for cool down, did a head count and
noticed one person short.`}</p>
    <p>{`Dimitri knew exactly who it was but let it slide, making a mental note to confront him later.`}</p>
    <p>{`Cool down ended quickly and Dimitri had to return all the equipment to the weapon’s cabinet in Coach Kostas’ office. A few members offered to help but he wanted some time alone to reflect on his behaviour this afternoon. Besides, they had already taken the equipment out for him before training, so it was only right he returned it. He would’ve done it himself if he hadn't taken Reus on a school tour. Dimitri looked back and counted the number of wooden swords. A second round was needed to return everything.`}</p>
    <p><em parentName="p">{`Disgraceful.`}</em>{` He sighed as he carried the wooden swords in his arms. `}<em parentName="p">{`Losing your cool like that is reprehensible for a captain and dorm leader, what’s more on the first day of a new term. You deserved today’s punishment.`}</em>{` He chastised himself. Dimitri dreaded every step to Coach Kostas’s office. He felt like a child who was scolded and now had to face the same person who scolded him; awkward and embarrassing.`}</p>
    <p>{`Dimitri knocked twice and heard an irritated ‘What?!’ inside before he opened the door.`}</p>
    <p>{`“I’m sorry to intrude Coach, I’d like to return the—”`}</p>
    <p>{`“Get out!” Coach Kostas voice rose when he heard Dimitri close the cabinet, “But sir there are still—”`}</p>
    <p>{`“`}<em parentName="p">{`Now!`}</em>{`”`}</p>
    <p>{`“Yes sir.” Dimitri held a sigh and only let it out when the door shut behind him. He could still hear Coach Kostas grumbling behind the door. He’d have to keep the rest of the wooden practice swords in the dorm then return them after the next meeting. It was strange why their Coach was in such foul mood, and that it had only gotten worse after the tournament.`}</p>
    <Ads mdxType="Ads" />
    <p><em parentName="p">{`I should not be having such thoughts about our Coach. In fact, I should be helping by not causing him problems.`}</em>{` He nodded to himself and he felt himself being watched.`}</p>
    <p>{`He looked behind him and saw a pair of green eyes peering from the shadows of an unlit corridor. He glanced around for anyone else and called out.`}</p>
    <p>{`“Claude?” Claude walked out of the corner he was hiding in and to his surprise, Reus emerged as well.`}</p>
    <p>{`“Why, if it isn’t my favourite club captain! Dimitri Alexandre Blaiddyd!” Claude gave him a quick squeeze. “About time you got here! I had to babysit this nice, young man for you.”`}</p>
    <p>{`Dimitri snorted. “Yes Claude it’s good to see you too.”`}</p>
    <p>{`Claude was about to return his things but spun around and turned to Reus. “Hey! I never got to hear what you were doing here anyway.”`}</p>
    <p>{`“I wanted to have a word with Coach, but he didn’t seem to be in a friendly mood.” Reus answered flatly. Dimitri noticed this to be his default face.`}</p>
    <p>{`“By sneaking around?” Claude grinned. Dimitri raised a brow. Was Reus sneaking around? To be fair, he did sneak out of practice.`}</p>
    <p>{`A sheepish smile formed on Reus’s lips. “He was in a foul mood so...I was waiting for an opportunity when you caught me.”`}</p>
    <p>{`“Alright you got me.” Claude shrugged and smiled. But Dimitri knew that look being classmates before, Dimitri knew that smile meant Claude was up to something.`}</p>
    <p>{`“Well, don’t lose him again, I won’t always be around to rescue him.” Claude winked as he sauntered into Professor Hanneman’s room.`}</p>
    <p>{`“I’m sorry I wandered off,” Reus said suddenly as they started walking back to the training grounds.`}</p>
    <p>{`Dimitri stopped and shook his head. “N-no, the fault is mine. I was not being a reliable leader that you needed to follow Coach all the way here. I would be happy to convey any messages for you.” Time to make amends for his behaviour now.`}</p>
    <p>{`Reus raised an eyebrow. “Even after getting screamed out of the room like that?” Dimitri chuckled sheepishly.`}</p>
    <p>{`“You heard that, huh?”`}</p>
    <p>{`“I’m pretty sure the whole school did.” Reus took a step forward but then looked back. “Actually are you okay? I mean during training you weren’t allowed to rest, a-and I’m new so I don’t actually know what Coach is actually like.”`}</p>
    <p>{`“Thank you for asking, I’m fine really.” Dimitri was touched. “As for your question, that's a little...`}<em parentName="p">{`complicated`}</em>{` but the short answer is not really. He had always been harsh, yes, but after the tournament he’s been quite a bully.” There was a beat of silence between the two of them.`}</p>
    <p>{`“But,” Dimitri continued, “Today’s Coach was a direct result of my misbehaviour. I’d like to apologize for that.” He bowed slightly.`}</p>
    <p>{`“Don’t say that.” Reus’s big mint-coloured eyes darkened. “Your reaction was appropriate, and regardless - no one deserves to be spoken to like that.”`}</p>
    <p>{`“Reus…”`}</p>
    <p>{`“From what I’ve seen you’re a great Captain, so don’t beat yourself up.” Dimitri couldn’t help but smile and Reus’s face turned red for some reason. It must be that hoodie he was wearing. It was an intense workout and it was hot too. Reus pulled out a crumpled piece of paper from his pocket, his eyes scanning it for information.`}</p>
    <p>{`“We’re supposed to be having our free time now?” Dimitri nodded. Reus stuffed the paper back into his pocket. “I left my stuff back at the training grounds. Do you mind if I went to get it?”`}</p>
    <Ads mdxType="Ads" />
    <p>{`Dimitri smacked his forehead. He had forgotten he too had to keep the training swords safe until the next time he could gain entry into Coach’s room. “Yeah. Sure. I mean of course, yes.”`}</p>
    <p>{`On the way, Dimitri explained some of the rules, such as where to store their equipment, until they arrived at the empty training hall. He scooped up the remaining wooden swords as Reus jogged over with his and Dimitri’s bag slung over his shoulder, offering to carry a few swords.`}</p>
    <p>{`“Oh don’t worry, I can manage.”`}</p>
    <p>{`“By the way, was that the same Claude that made you break the door handle?” Reus looked up at him and smirked.`}</p>
    <p>{`Dimitri gulped and turned his flushing face away. “Yes.” Reus started giggling and Dimitri’s cheeks turned an even deeper red. “Oh go on.” He relented, and let out a chortle himself.`}</p>
    <p>{`“Actually, I didn’t think it was humanly possible to break a door handle. But after what I saw today—well, colour me impressed.” Reus gave him a friendly elbow nudge.`}</p>
    <p>{`Reus’s words caused Dimitri’s mind to fall back to thinking about why he snapped. Of course, it was about the scholarship money their team had `}<em parentName="p">{`‘won’`}</em>{`. This was another thing that had Dimitri’s stomach in knots. Although he was eligible for the scholarship being on the team that day; it didn’t feel right. It was strange as their team was a couple points behind but by some stroke of luck, the referee had pinned some silly error on their opponent team and deducted points, which meant his team won in the end.`}</p>
    <p>{`Deep down, some part of him knew that error didn’t exist.`}</p>
    <Divider mdxType="Divider" />
    <p>{`Poor Reus had woken up the next day completely sore, yet still managed to go jogging
with Caspar. Reus didn’t even take a shower because he took such a long time to walk
back to their dorm. Dimitri’s guilt shot through the roof at this point and profusely
apologized, showering the smaller boy with an assortment of ointments and pain relief
gels.`}</p>
    <p>{`It had been about three days since their first, official Weapons Club meeting. And there were a few things Dimitri had noticed about their new dorm mate, Reus. He consistently refused to get changed with them, or shower together in the communal shower room. Instead, preferring to shower alone in the middle of the night—said he wanted some ‘privacy’. And he always only used the toilet stalls instead of the urinals to which he said he had a very active bowel.`}</p>
    <p>{`Sure, some people were a little shyer than others. But this was an all-boys boarding school. Getting naked and showering together has some sort of magical power to bond people together. However Reus would walk all the way to the bathroom just to change a shirt. Speaking of which; Reus’s uniform didn’t fit him very well, either. His blazer, shirt and slacks hung on his petite frame. Which was strange, seeing as their uniforms were always tailored to their exact sizes. But he gave Reus the benefit of the doubt—they only had 2 terms left in school so it could’ve been a hand-me-down.`}</p>
    <p>{`When Dimitri asked his fellow dorm mates what they thought about Reus’s strange conduct, Dedue deduced that he probably has scars he wasn’t comfortable with. Sylvain joked that he might be a woman who was so in love with Sylvain that he came all the way to see him. While Felix wouldn't give a damn. Ashe was just happy the empty bed in their dorm was finally occupied, although he did agree Reus felt familiar, he shrugged.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Nevertheless something still deeply bothered Dimitri about Reus Eisner. Everyone knew he was kicked out of his previous school for fighting. Reus was indeed a fierce fighter, demonstrated by their weapon’s club meets, and was proficient no matter what weapon was thrown at him. But there was no sign of such a person who’d start a fight willy-nilly. Reus was pleasant, amiable and very calm. Dedue-level calm, in fact.`}</p>
    <p>{`He felt like he knew Reus from somewhere and it was all Dimitri could think about. So much so that during breakfast this particular morning, Dimitri kept his gaze constantly fixed on Reus’ long mint hair tied up in a ponytail, and occasionally studying his features. His mint green eyes were noticeably large and eyelashes, long than the average boy. He’s really pretty, that’s for sure.`}</p>
    <p>{`“Can I help you?” Reus’ voice snapped Dimitri back to reality. “You’ve been staring at me.”`}</p>
    <p>{`“Ooh, into the new kid are we?” Seated opposite Dimitri, Sylvain wiggled a finger in his face. Dimitri spat out his cereal, bits of it landing on the people sitting in front of him, most of it on Reus. Felix rolled his eyes so far back he could probably see his own brain, while Ashe sat unfazed, shielded by the book he was reading. “O-oh I’m very sorry!” Dimitri pulled a pack tissue out of his blazer pocket and was ready to clean his breakfast off Reus but he merely dusted it off his shoulder. “It’s cool.”`}</p>
    <p>{`“So you `}<em parentName="p">{`do`}</em>{` like him.” A large smirk grew on Sylvain’s face as he leaned on his elbow and flicked bits of food off his blazer. He, too, had been blessed with some of Dimitri’s cereal.`}</p>
    <p>{`“Sylvain! I’ll have you know, unlike `}<em parentName="p">{`you`}</em>{`, I do not form romantic attachments with individuals this fast!” Dimitri declared so loud, even Lorenz sitting three tables away stood up to see who it was proclaiming their love. “Nobody’s confessing to anyone. Sit down you idiot.” Claude, at the same table as Lorenz, pulled him back down.`}</p>
    <p>{`Sylvain held his sides in fits of laughter and gasped for air. “You take everything so seriously Dimitri—” he wheezed “—It never gets old.”`}</p>
    <p>{`Dimitri all but blushed, and continued what was left of his cereal. Reus frowned for some reason and gave him his apple. “It’s the least I can do for making you fall in love with me before 8 am.” Oh no, not Reus too. even Dedue managed a smile.`}</p>
    <p>{`Sylvain, who had finally calmed down, snorted and gave Reus a hard pat on the back.`}</p>
    <p>{`“Are you not on Facebook?” Ashe said a moment later and looked at Byleth as he tapped on his phone. “Or Instagram? Twitter? Snapchat? I can’t seem to find you.”`}</p>
    <p>{`“Nope.”`}</p>
    <p>{`“`}<em parentName="p">{`No.`}</em>{`” Sylvain gasped with dramatical flair as his eyebrows rose high on his forehead. “In this day and age?”`}</p>
    <p>{`“Yup.”`}</p>
    <p>{`“Even Dimitri has his ancient Facebook account from when we were what, twelve?” That he did. Dimitri was sentimental about it; it recorded his growth from when he was younger. Like a timeline of sorts.`}</p>
    <p>{`“Hell, even this grump!” Sylvain declared and earned a glare from Felix. But Reus kept shaking his head. “He—I mean, `}<em parentName="p">{`I`}</em>{` did have one, but I kept getting really dumb messages and friend requests because of my expulsion. So, I deactivated it.” The table ooh-ed and aah-ed. “Plus it wouldn’t look good for future employers to see such things, but not that it matters since everyone seems to know I got kicked out.”`}</p>
    <p>{`“A wise decision,” Dedue said. Dedue was so quiet most of the time the group sometimes forgot he was around even though he towered over all of them.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Dimitri’s heart sank, he hadn’t thought about looking for Reus on Facebook to see if they had any mutual friends but now it seems he didn’t have the chance at all. Dimitri receded back into his thoughts as Sylvain, Ashe and Felix expressed their awe at Reus. This curiosity was now clawing at Dimitri’s chest. It was as if he had watched a movie, recognized an actor but could not remember where he had seen him before. Worst, this actor supposedly had no IMDB profile or any sort of digital record. It was positively frustrating—`}</p>
    <p>{`“What’s wrong, Dimitri?” Dedue asked, breaking Dimitri’s chain of thought. He realised his brows were so furrowed that when he relaxed his gaze his eyes felt weird.`}</p>
    <p>{`“O-oh. It’s nothing. I was just thinking how familiar Reus is.”`}</p>
    <p>{`Dedue frowned. “Again?”`}</p>
    <p>{`“I know I should probably let it go, but this feeling keeps coming back to me.” Dedue hummed in response and Dimitri leaned on his elbow, his gaze returning to Reus. Dimitri was normally good with his memory. He never forgot names or faces. But this? This was positively maddening. He observed Reus as he chatted, the way he controlled his laughter by covering his mouth, or the way his face fell back into a blank stare whenever he wasn’t talking. Reus wasn’t very expressive, Dimitri noted. But there were subtle movements in his face whenever he did choose to express himself.`}</p>
    <p>{`The school bell rang indicating the end of breakfast. The group disposed of their rubbish and returned their trays. At their lockers they stashed away their phones as it wasn’t allowed during school hours. Coincidentally, Reus’s locker was on top of Dimitri’s, which was annoying as he kept growing taller and taller, making reaching or bending down a pain in the back, literally.`}</p>
    <p>{`“Sorry.” Reus muttered when he reached over Dimitri as he was squatting down, grabbed a few things and tossed his phone in before shutting it.`}</p>
    <p>{`In class, Dimitri had just set himself down and pulled out his pencil case, neatly arranging it as usual when Dedue who was walking to his desk, stopped at his place.`}</p>
    <p>{`“Maybe Reus has a brother or sister?” he said quietly.`}</p>
    <p>{`“Brother or sister…” The words rolled out of Dimitri’s lips. Dimitri didn’t notice Dedue walking away because it finally dawned on Dimitri as the school bell rang a second time.`}</p>
    <p><em parentName="p">{`A sibling.`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      