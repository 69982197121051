import { useState, useEffect } from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import PropTypes from 'prop-types';
import { MDXProvider } from '@mdx-js/react';
import { BlogPostJsonLd, BreadcrumbJsonLd } from 'gatsby-plugin-next-seo';

import { useFirebase, stats } from '../../utils/firebase';
import Banner from './Banner';
import { roundUp, importOneImage } from '../../utils';
import Content from './Content';
import ImageBanner from './ImageBanner';
import InArticle from '../Ads/InArticle';
import AuthorCard from './AuthorCard';
import FollowLinks from './FollowLinks';
import Seo from '../Seo';
import CommentSection from '../DisqusComments/CommentSection';
import {
  Video,
  Blockquote,
  RemoteLink,
  Image,
  Codeblock,
  SpoilerTag,
} from './Shortcodes';
import authors from '../authors.json';

const Layout = ({ children, pageContext, location, path }) => {
  const {
    frontmatter: {
      title,
      excerpt,
      description,
      keywords,
      tags,
      banner,
      published,
      author,
      lastUpdated,
    },
  } = pageContext;
  const { allMdx } = useStaticQuery(graphql`
    {
      allMdx(filter: { fileAbsolutePath: { regex: "/mdxblog/" } }) {
        edges {
          node {
            timeToRead
            slug
          }
        }
      }
    }
  `);
  const { timeToRead } =
    typeof window !== 'undefined'
      ? allMdx.edges.filter(({ node }) => path.indexOf(node.slug) > -1)[0].node
      : { timeToRead: 1 };
  const firebase = useFirebase();
  const [pageViews, setPageViews] = useState(0);
  const [hearts, setHearts] = useState({ shown: '-', realTotal: 0 });

  useEffect(() => {
    if (firebase && typeof window !== 'undefined') {
      stats()
        .hearts.get()
        .then((value) => {
          setHearts({
            shown: value > 0 ? roundUp(value) : 0,
            realTotal: value,
          });
        });

      stats()
        .pageViews.get()
        .then((value) => {
          setPageViews(value > 0 ? roundUp(value) : 0);
        });
    }
  }, [firebase]);

  return (
    <MDXProvider
      components={{
        Video,
        Codeblock,
        Blockquote,
        LocalLink: Link,
        RemoteLink,
        SpoilerTag,
        Ads: InArticle,
        Image,
      }}
    >
      <Seo
        location={location}
        title={`${title} | Geeknabe - ACG blog`}
        description={description}
        image={`https://geeknabe.com${importOneImage(banner)}`}
        keywords={keywords}
        meta={[
          {
            property: 'og:type',
            content: 'blog',
          },
          {
            property: 'twitter:card',
            content: 'summary_large_image',
          },
        ]}
      />
      <BlogPostJsonLd
        url={`https://geeknabe.com${location.pathname}`}
        title={title}
        datePublished={published}
        dateModified={lastUpdated}
        authorName={authors[author].name}
        publisherName="Geeknabe"
        images={[banner]}
        publisherLogo="https://geeknabe.com/static/logo-e6e50c9b64aa402b446a3cd0203f163a.png"
        description={description}
      />
      <BreadcrumbJsonLd
        itemListElements={[
          {
            position: 1,
            name: 'Latest Posts',
            item: 'https://geeknabe.com',
          },
          {
            position: 2,
            name: 'Blog Index',
            item: 'https://geeknabe.com/blog/',
          },
          {
            position: 3,
            name: title,
            item: `https://geeknabe.com/blog/${path.replace('/blog/', '')}`,
          },
        ]}
      />
      <Banner
        title={title}
        published={published}
        tags={tags}
        excerpt={excerpt}
        timeToRead={timeToRead}
        slug={path}
        author={authors[author].name}
        pic={authors[author].pic}
        lastUpdated={lastUpdated}
        hearts={hearts.shown}
        pageViews={pageViews}
      />
      <ImageBanner img={banner} alt={`${title} blog post banner`} />
      <FollowLinks nabe={author === 'nabe'} />
      <Content
        hearts={hearts}
        setHearts={setHearts}
        tags={tags}
        author={authors[author].name}
      >
        {children}
      </Content>
      <AuthorCard
        name={authors[author].name}
        pic={authors[author].pic}
        summary={authors[author].summary}
        tag={tags[0]}
      />
      <CommentSection
        slug={path.replace('/blog/', '').replace('/', '')}
        title={title}
      />
    </MDXProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element])
    .isRequired,
  location: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  path: PropTypes.string.isRequired,
  pageContext: PropTypes.shape({
    frontmatter: PropTypes.shape({
      title: PropTypes.string,
      tags: PropTypes.arrayOf(PropTypes.string),
      excerpt: PropTypes.string,
      published: PropTypes.string,
      lastUpdated: PropTypes.string,
      banner: PropTypes.string,
      author: PropTypes.string,
      timeToRead: PropTypes.string,
      description: PropTypes.string,
      keywords: PropTypes.arrayOf(PropTypes.string),
    }),
  }).isRequired,
};

export default Layout;
