import PropTypes from 'prop-types';
import { BreadcrumbJsonLd } from 'gatsby-plugin-next-seo';

import Content from '../components/Series/Index/Content';
import Banner from '../components/Series/Index/Banner';
import Seo from '../components/Seo';
import keywords from '../utils/keywords';

const SeriesIndexPage = ({ location }) => (
  <>
    <Seo
      title="Series | Geeknabe - ACG Blog"
      location={location}
      description="Check out all blog post series available in Geeknabe here!"
      keywords={keywords}
      meta={[
        {
          property: 'og:type',
          content: 'website',
        },
      ]}
    />
    <BreadcrumbJsonLd
      itemListElements={[
        {
          position: 1,
          name: 'Latest Posts',
          item: 'https://geeknabe.com',
        },
        {
          position: 2,
          name: 'Series',
          item: 'https://geeknabe.com/series/',
        },
      ]}
    />
    <Banner />
    <Content />
  </>
);

SeriesIndexPage.defaultProps = {
  location: {},
};

SeriesIndexPage.propTypes = {
  location: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default SeriesIndexPage;
