import {
  getDatabase,
  ref,
  get,
  child,
  runTransaction,
} from 'firebase/database';

const getPagePath = () => {
  const url =
    typeof window !== 'undefined'
      ? window.location.pathname.replace(/^\/|\/$/g, '')
      : '';

  return url;
};

const getHeartsPath = () => {
  const isBlog = getPagePath().indexOf('blog') > -1;

  if (isBlog === true) {
    return `/blog/posts/${getPagePath()
      .replace('blog', '')
      .replace(/\//g, '')}`;
  }

  return `/blog/${getPagePath()}`;
};

const getPageViewsPath = () => {
  const url = getPagePath();

  switch (window.location.pathname) {
    case '/':
      return 'home';
    default:
      return url.split('/').join('|');
  }
};

const stats = () => {
  const pathToHearts = getHeartsPath();
  const pathToPageViews = `/blog/views/${getPageViewsPath()}`;
  const firebaseDB = ref(getDatabase());

  return {
    hearts: {
      get: () =>
        new Promise((resolve) => {
          const query = child(firebaseDB, pathToHearts);

          get(query)
            .then((snapshot) => {
              resolve(snapshot.val());
            })
            .catch(() => {
              resolve(0);
            });
        }),
      set: () => {
        const dbRef = ref(getDatabase(), pathToHearts);

        runTransaction(dbRef, (currentCounter) => currentCounter + 1);
      },
    },
    pageViews: {
      get: () =>
        new Promise((resolve) => {
          const query = child(firebaseDB, pathToPageViews);

          get(query)
            .then((snapshot) => {
              resolve(snapshot.val());
            })
            .catch(() => {
              resolve(0);
            });
        }),
      set: () => {
        const dbRef = ref(getDatabase(), pathToPageViews);

        runTransaction(dbRef, (currentCounter) => currentCounter + 1);
      },
    },
  };
};

export default stats;
