import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Blockquote = makeShortcode("Blockquote");
const RemoteLink = makeShortcode("RemoteLink");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Vivy has been hitting it out of the park since Episode 1. Through the utilisation of surprise, the unexpected and now… love. Something that is considered illogical for robots that run on algorithms.`}</p>
    <p><em parentName="p">{`Note: Major spoilers.`}</em></p>
    <p>{`Continuing from Episode 8, we were given a shocking revelation behind Ophelia’s suicide in the original timeline. The sweet Ophelia isn’t who we thought she is. It was Antonio, her AI partner all along. Her personality had been hijacked all in the name of Antonio’s goal - to make Ophelia sing her very best. Logically speaking, the whole hijacking feels counterintuitive. If supporting Ophelia so she sings her best is his mission, then why hijack Ophelia? Surely he would be improving his support skills instead of jeopardizing his partner like this.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Then there’s the whole unsolved issue with Kakitani and his obsession with Vivy. I was literally scratching my head with Kakitani’s issue. Your piano teacher died but why are you so obsessed with Vivy, an AI unrelated to the cause of your trauma? As a matter of fact, his mission makes him sound like a madman pursuing an unrealistic goal. And why aren’t you dead? Could have sworn you’re human. Unless you’re an android?! This is my silly but not that silly (Because it’s kinda hinted I think?) assumption which I believe will be explained in later episodes.`}</p>
    <p>{`And finally, Vivy. The current Vivy we see is actually Diva, her other personality who has found the answer to her mission. Ironically, Vivy couldn’t figure it out at all. Episode 9 has these three things going on at the same time which makes for an exciting spectacle when it comes to the scenes where Matsumoto busies himself on two fronts. I mulled for a bit after finishing Episode 9 (I was overwhelmed with the emotions shown in this episode) because this entire damn episode doesn’t make any sense. Each episode since the first had a clear message and idea. You could easily pick up what the author wishes to tell the viewers.`}</p>
    <Ads mdxType="Ads" />
    <p>{`However, Episode 9 befuddles me. Everyone is nuts in this episode except Matsumoto. Their motivations are illogical and their dialogues are crazy talk. Then it hit me. Of course they don’t make any sense! Each one of them had a vague goal which indirectly twisted/motivated their missions all along.`}</p>
    <Blockquote mdxType="Blockquote">It was love.</Blockquote>
    <p>{`Strange isn’t it? When we say robots, you don’t add love into the equation. These are programs. Algorithms. They don’t compute something warm, fuzzy and vague to the final equation. How do you even code an evocative feeling into a machine? But alas, this anime is all about showing how it is possible for an AI, driven solely by their missions… to be capable of `}<strong parentName="p">{`love`}</strong>{`. Heck, I should have noticed since the beginning. Especially during the Grace episode or the Sunrise episode. Everything these AI do is not only motivated by their mission, but admiration/fondness/love for their human masters or fellow AI. Episode 9 is no different in this case.`}</p>
    <p>{`Antonio’s love for Ophelia is a selfish one. He specifically mentioned he wished to monopolize her singing, which runs counter to his original mission. I find it really romantic and somewhat human to see Antonio voicing out his thoughts during the confrontation with Matsumoto. He truly wanted Ophelia to succeed. And for that to happen, he gave his best when it comes to singing. BUT! He also wanted to monopolize Ophelia’s singing. Having these two missions clashing motivated the unfortunate hijacking to happen. The final scene (having them both “die” together) is a nice touch to the the rumours of a `}<em parentName="p">{`couple/motivated by love `}</em>{`suicide. At least they are together now ):`}</p>
    <p>{`Note: Follow their `}<RemoteLink to="https://twitter.com/vivy_portal" mdxType="RemoteLink">{`official twitter`}</RemoteLink>{`! They tweet a lot of nice art and music announcement.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Vivy -Fluorite Eye's Song- screencap 1" caption="It was mentioned a few times AI can't shed tears because they don't have tear glands mechanism added in. Sigh, why are you doing this to me..." name="vivy-fluorite-eyes-song-ophelia-death.jpg" mdxType="Image" />
    <Image alt="Vivy -Fluorite Eye's Song- art from Twitter" caption="Those happier, simpler days are long gone... I need the songs on official streaming platforms, NOW!" name="vivy-fluorite-eyes-song-art.jpg" mdxType="Image" />
    <p>{`Kakitani’s love for his piano teacher is that of familial fondness. Like how a kid loves his doting parent. He made it clear that he likes his teacher, but his teacher is an AI. AI only knows everything there is to fulfill their mission. Whether his piano teacher loves him back still remains to be seen, but Kakitani truly is `}<em parentName="p">{`fond`}</em>{` of his teacher. When videos of his teacher’s final moments were played during his supposed funeral, he was furious. Now this is where I scratch my head again. He calls it disrespectful but I think it’s a lovely thought by the humans to appreciate and honour what his piano teacher did. And then, it hit me once again. While the rest of the survivors view his AI piano teacher as an `}<em parentName="p">{`AI`}</em>{`, Kakitani viewed his piano teacher… `}<em parentName="p">{`as a human`}</em>{`.`}</p>
    <p>{`This makes his anger at Vivy much more understandable. He wasn’t mad because his piano teacher went and died. He’s mad `}<strong parentName="p">{`because`}</strong>{` an AI who is supposedly only good at his mission has shown him kindness, patience and love, then went and threw the bucket by doing the opposite of what his mission entails. Why is an AI making him feel fondness towards it, when the AI would ultimately break his heart? This is the reason why he was mad at Vivy who is supposedly only a diva but is going around endangering herself to save humans. `}<em parentName="p">{`“Don’t go around breaking your loved ones’ hearts!”`}</em>{`, is what Kakitani’s saying. I find his mission (No doubt, self appointed lol) to be a bit cuckoo if seen logically, but absolutely endearing if you take into account that it was familial love motivating him.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Finally, the ending of Episode 9 tackles Vivy’s problem which Diva has already solved. What does it mean to sing with all your heart? Silly Vivy, you have already found the answer. Diva even gave a hint to her! It was the unexpected final part to the equation - love for the humans, fondness for her fans, admiration and the desire to fulfill your mission not because you have to, but because you want to. I find it strange that she was still mulling about it when clearly, she is capable of expressing it in every scene where Matsumoto asked why she is still eager to help him with the Singularity Project. If there is no love in you, then she wouldn’t have agreed to help him.`}</p>
    <Image alt="Vivy -Fluorite Eye's Song- screencap 2" caption="I got this screencap from their official Twitter. Her expression is so poignant here. I think Diva went away at this very moment so this is actually Vivy taking back control of her body." name="vivy-fluorite-eyes-song-screencap-2.jpg" mdxType="Image" />
    <p>{`All of this talk about love/feelings got me thinking about the whole AI apocalypse shown at the beginning of Episode 1. We were given 2 hints so far: 1# AI are logical and would only move to fulfill their mission - Proven somewhat false. AI are shown to be true to their mission, but are able to receive a second mission or whatnot (self appointed). What we’ve seen so far are AI who have clearly gone beyond their calling. None of these AIs are even operating on a scale of “logical”. You could clearly tell this is true from how Elizabeth went against her order by preventing Kakitani from doing a suicide mission.`}</p>
    <p>{`2# AI can’t receive a second mission that conflicts with the first. They’ll go bust. - Definitely true. We’ve seen how Antonio self-destruct himself by prioritizing both of his missions (supporting Ophelia and wanting to monopolize her singing to himself). Vivy too, couldn’t handle the stress from her original mission along with the idea of the Singularity Project (which caused Saeki, a human to be unhappy and commit suicide. The cause of his suicide is kinda related to her singing).`}</p>
    <Ads mdxType="Ads" />
    <p>{`We need to look at how Matsumoto behaves as well. He’s a super advanced AI from the future (supposedly) with a very pure and logical mindset. He’s somewhat like how I code for my web projects. If A happens, then do B. It’s a simple thought process with no other possibility inputted in. He keeps teasing how Vivy doesn’t do jokes well, but his thought process is as mechanical as it can be. With this, we could clearly see how `}<strong parentName="p">{`different`}</strong>{` Matsumoto operates when compared to the current era’s AI. Matsumoto prioritizes only a single mission without a shred of empathy in him (though he’s improving on the empathy skill in the latter episodes) while current era AI(s) has this constant turmoil of original mission + emotions that fuels each Singularity Project’s incidents.`}</p>
    <p><em parentName="p">{`What if…`}</em>{` the future AI apocalypse happened BECAUSE humans took out the emotion factor in AI? It was done because humans factored in the cause of every Singularity Project incident so far. Just a thought, but definitely a possibility. Without the ability to love humans, an AI uprising versus their human masters doesn’t seem unlikely! Which brings me to another point which I want to highlight. Before Kakitani went offline, he mentioned how the tech was brought before him through a revelation. Now assuming that the prophet is a self-motivated AI from the future, then we’re in big trouble. The prophet could be an evil twin of Matsumoto, or the original perpetrator of the AI apocalypse. Maybe even both! Whichever it is, the culprit is most definitely capitalizing on these poor AI(s) and humans ability to move mountains with strong emotions.`}</p>
    <p>{`Well, all in all, I have no clue how the story will proceed from here. Episode 9 is a lovely three-way with a definitive motive. Lovely episode, maybe second only to Grace’s episode. I’m looking forward to seeing how it goes! Thank you for reading.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      