import PropTypes from 'prop-types';
import styled from 'styled-components';

import $ from '../../styles/globals';
import { importOneImage } from '../../utils';

const Section = styled.section`
  ${$.screen.tablet} {
    padding: 0 !important;
    width: 100vw !important;
  }

  ${$.screen.mobile} {
    padding: 0 !important;
    width: 100vw !important;
  }
`;

const Container = styled.div`
  height: 500px;

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0;
  }

  ${$.screen.desktop} {
    > img {
      border-radius: ${$.borderRadius.radius1}px;
    }
  }

  ${$.screen.mobile} {
    height: 300px;
  }
`;

const ImageBanner = ({ img, alt }) => (
  <Section>
    <Container>
      <img src={importOneImage(img)} alt={alt} />
    </Container>
  </Section>
);

ImageBanner.defaultProps = {
  img: '',
  alt: '',
};

ImageBanner.propTypes = {
  img: PropTypes.string,
  alt: PropTypes.string,
};

export default ImageBanner;
