import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const RemoteLink = makeShortcode("RemoteLink");
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const Video = makeShortcode("Video");
const LocalLink = makeShortcode("LocalLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`According to Google, the definition for `}<em parentName="p">{`torture`}</em>{` is this - `}<em parentName="p">{`the action or practice of inflicting severe pain or suffering on someone as a punishment or in order to force them to do or say something.`}</em>{` Okay that sounds fair. I copied and pasted the definition here just so everyone knows what we’re about to jump in! So this manga titled `}<strong parentName="p">{`Tis' Time for "Torture", Princess`}</strong>{` is about torturing a princess? Hmmm, sounds really morbid but okay, let’s dive right in…`}</p>
    <p>{`And just like that, I finished catching up to the most `}<strong parentName="p">{`wholesome manga`}</strong>{` I’ve read from WSJ. How can this be? The title is bloody misleading! `}<RemoteLink to="https://mangaplus.shueisha.co.jp/titles/100071" mdxType="RemoteLink"><strong parentName="p">{`Tis’ Time for “Torture,” Princess`}</strong>{` is currently on MangaPlus`}</RemoteLink>{`, and it’s all about torturing the captured princess to divulge important secrets to help the Hellhorde, an army of demons. The setting is basically set in a world where kingdoms, heroes, magic and demons exist. Just think of it as a fantasy, and you’ll do okay (Since there's no other explanation given in the story)! Our protagonist is the princess of a kingdom but she holds a second title in the army - Commander of the Third Legion. She wields a talking holy sword called Ex. Her status and her weapon are pretty much legendary stuff but unfortunately, they were captured by the Hellhorde, an enemy of humankind. Given her status, it's obvious that she's privy to her country's secrets!`}</p>
    <Ads mdxType="Ads" />
    <p>{`Each chapter had the princess `}<strong parentName="p">{`“tortured”`}</strong>{` by a professional named Torture Tortura which will only end if the princess (or Ex) talk. Now this is where it gets interesting. You’d think that each torture is something really gruesome but nah! Tortura always uses something cute or tasty to tempt the princess to talk! Most of the torture are usually commoner’s food that the princess had before, so she usually couldn’t resist the temptation in the end. So what happens to the secrets she divulged? Well, the Demon Lord did jot down the secrets but it usually got tossed aside for an equally wholesome reason.`}</p>
    <p>{`So you have this food-lover princess, a wholesome cast of demons that just want to take real good care of the princess, and a Demon Lord who couldn’t care less about the human kingdoms. His priority is his family’s smile, his subordinates’ wellbeing and the captured princess’s health. Seriously, reading this manga puts a smile on my face :D Since most of the torture are about `}<em parentName="p">{`food`}</em>{` I could relate to as well, I thought it would be fun to talk about the food torture that could make me submit as well. So here’s 5 food torture that I would totally say yes too!`}</p>
    <p><em parentName="p">{`Note: If you're reading this, dear close friends and family, you know what to do!`}</em></p>
    <Ads mdxType="Ads" />
    <h2>{`Food Torture #1: Japanese Hamburg Steak`}</h2>
    <p>{`Hello my No. 1 weakness. With your sauce lightly drizzled on that plump… juicy steak, you’re a delight on`}<em parentName="p">{` mah tongue`}</em>{`! Every bite I take just sends me to heaven <3 I ate my first `}<strong parentName="p">{`hamburg steak`}</strong>{` in Arashiyama, Japan. Me and my partner is super hungry so we wandered around trying to find a place to eat. We stumbled upon this cafe selling set meals for 1000+ yen. It’s pricey for our budget but we were so tired from all the walking. So we waltz in and ordered the`}<strong parentName="p">{` hamburg steak set`}</strong>{`. Wow, the food is so good! I managed to find the photo from my phone so here you go!`}</p>
    <Image alt="Hamburg steak from mah phone" caption="The minestrone soup is too strong for my tastebuds, but everything else is perfect! The hamburg steak is mm hmmmmmmmm <3" name="tis-time-for-torture-princess-nabechan-hamburg-steak.jpg" mdxType="Image" />
    <Image alt="Tis time for torture princess hamburg steak" caption="Ze harmburg steak from the manga! Yasss it looks so good!" name="tis-time-for-torture-princess-hamburg-steak.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`Food Torture #2: Xiao Long Bao`}</h2>
    <p>{`Ah yes, these dumplings are a must if you enter a dim sum place. It’s been a while since I last had these dumplings we call xiao long bao! To best enjoy this delicacy, you pick it up gently and put it on your soup spoon. Break it open gently so you have a small opening to sip the soup. I suggest breaking it at the top so the soup doesn't gush out because it’s hot!`}</p>
    <Image alt="Xiao Long Bao from Tis Time for Torture Princess" caption="I don't recommend breaking the dumpling skin from the sides. The soup just gushes out and your spoon may overfill. So the key point here is to find a way to drink the soup without spilling it!" name="tis-time-for-torture-princess-xiao-long-bao.jpg" mdxType="Image" />
    <p>{`Enjoy the soup slowly with small sips. Don’t drink all of the soup! Make sure to leave some soup at the end so you can enjoy the soup along with the pork filling in one large gulp. `}<strong parentName="p">{`Yummy.`}</strong>{` Are you not convinced that this is the best way to eat this scrumptious dumplings? Maybe you’ll listen to the words of a `}<RemoteLink to="https://migrationology.com/din-tai-fung-taipei-101/" mdxType="RemoteLink">{`Din Tai Fung chef`}</RemoteLink>{` instead?`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Food Torture #3: Raclette`}</h2>
    <p>{`I used to watch Food Insider videos a lot on Facebook. Boy, they `}<em parentName="p">{`love `}</em>{`their `}<strong parentName="p">{`cheese`}</strong>{`! One of the dishes I really would love to try is raclette. Like, they have this big wheel of cheese that they melt at the top. They then scrap off the melted cheese onto your plate! AAAAAA CHEESE! I know, I know, I can technically make one myself but I don't have a big wheel of cheese at home ):`}</p>
    <Image alt="Raclette from Tis Time for Torture Princess" caption="Will I scream with garbled excitement if I see cheese on mah food like this? Yes, yes and yes." name="tis-time-for-torture-princess-raclette.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`Food Torture #4: Omurice`}</h2>
    <p>{`My local place has this dish we call nasi goreng pattaya which is basically fried rice wrapped in fried egg. It’s nice and all but the fried egg can be super dry and oily. I honestly really prefer my egg all `}<strong parentName="p">{`gooey`}</strong>{`. Not to mention, the rice from omurice is cooked in tomato sauce! I'm a tomato sauce advocate! Please, give me an omurice IV-drip! I'll pull out the Animal Crossing IV-drip for an omurice one. That's how much I love my food! Speaking of good food, one of the omurice I really, really want to try in my bucket list is this guy’s omurice. Have you heard of `}<RemoteLink to="https://kichikichi.com/menu/#tongue-stew" mdxType="RemoteLink">{`kichikichi`}</RemoteLink>{`? The food looks so delicious but darn, his rate is `}<em parentName="p">{`so expensive`}</em>{`. I just checked on his website and saw that the omurice costs 2700 Yen?? You need to book really early too if you want a spot.`}</p>
    <Video src="https://www.youtube.com/embed/yG5x5IX9ppM" title="kichikichi omurice" mdxType="Video" />
    <Image alt="omurice omurice omurice" caption="The omurice from the manga! The first bite is always the most delectable! My family enjoy sharing their food when we're eating at a new place. You know, we get to try more food that way. But the first bite is the one thing I am unwilling to share!" name="tis-time-for-torture-princess-omurice.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`Food Torture #5: Unlimited Chocolate Sprinkles on mah Ice Cream`}</h2>
    <p>{`Like every good meal out there, we need to end it with a dessert! Thus, I shall end this post with `}<strong parentName="p">{`ICE CREAM`}</strong>{`. Bless be to you, the creator of ice cream. I love desserts! My personal favourite cake is tiramisu or red velvet. My favourite ice cream flavour is vanilla in most cases, but my go-to flavour is chocolate!`}</p>
    <Image alt="Chocolate sprinkles from Tis Time for Torture Princess" caption="UNLIMITED CHOCOLATE SPRINKLES FLOW! Now if only my piggy bank does this... Sobs, adulthood sucks. (silently cries)" name="tis-time-for-torture-princess-ice-cream-with-chocolate-sprinkles.jpg" mdxType="Image" />
    <p>{`I really enjoyed adding chocolate sprinkles on my ice cream. If sprinkles are a no go, I'll add those chocolate drizzle instead. So if you told me I don’t need to `}<em parentName="p">{`hold back with the chocolate sprinkles`}</em>{`… My favourite colour is red, my first pet is a hamster and my mother’s maiden name is `}<strong parentName="p">{`redacted.`}</strong>{` Now please hand over that magical chocolate sprinkles pack!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`All in all`}</h2>
    <p>{`I hope you enjoyed this post! Please try reading Tis’ Time for “Torture,” Princess in MangaPlus, your official source for WSJ titles! It’s super wholesome and really enjoyable. If you love manga about food just like I do, please check `}<LocalLink to="/blog/gensou-gourmet-is-a-fantastical-trip-for-foodies/" mdxType="LocalLink">{`my post about Gensou Gourmet`}</LocalLink>{` too! Dungeon Meshi is super fun too if you enjoyed Gensou Gourmet.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      