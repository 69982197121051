import styled from 'styled-components';

import $ from '../../../styles/globals';
import Divider from '../../MDXFanfiction/Chapter/Shortcodes/Divider';

const Section = styled.section``;

const Container = styled.div`
  padding: 50px 0;
  text-align: center;
`;

const Title = styled.h1`
  color: ${$.color.red2};
  font-family: Porkys !important;
  font-size: 50px !important;
  letter-spacing: 1px;
  margin-bottom: ${$.layout.margin3}px;
`;

const Description = styled.h2`
  font-size: 18px !important;
  font-family: Rubik Regular !important;
  font-weight: bold;
  color: ${$.brown5};
`;

const Banner = () => (
  <Section>
    <Container>
      <Title>Blog Post Series Collection</Title>
      <Description>
        Take a look at our specially curated list of blog posts!
      </Description>
    </Container>
    <Divider />
  </Section>
);

export default Banner;
