import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXFanfiction/Chapter/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Divider = makeShortcode("Divider");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`This is it.`}</em></p>
    <p><em parentName="p">{`This is…actually it.`}</em></p>
    <p>{`Dimitri stood outside the `}<strong parentName="p">{`Green Dragon Cafe`}</strong>{`, so nervous he was making himself dizzy. From the outside, the cafe looked renovated since he didn’t recognise the interior anymore.`}</p>
    <p>{`Taking in the nostalgic sight and sounds of the area, Dimitri couldn’t believe he was `}<em parentName="p">{`actually`}</em>{` here. Far too long had he dreamed of coming back, but refusing to do unless he’d earn the right to.`}</p>
    <p>{`And despite failing the admission test for Garreg Mach University, the 3+1 transfer program between The Fhirdiad National University and Garreg Mach University was the next best thing— and he grabbed the chance without hesitation.`}</p>
    <p>{`Yet, here he was, dizzy from excitement and nerves. His usual courage seemingly disappeared.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“How are you feeling buddy?” Sylvain wrapped an arm around his waist, pulling Dimitri close and grinned. Sylvain’s grip was real enough to convince him this wasn’t a dream.`}</p>
    <p>{`For once, Dimitri didn’t pull away, unable to think of anything else but this cafe in front of him. The decorations may have changed, and it was smaller than he remembered but there was no mistaking it. This was `}<em parentName="p">{`the`}</em>{` place.`}</p>
    <p>{`He gulped. “I’m…I don’t know. I’ve sort of put this place on a pedestal for so long, I’m terrified it’s not what I remember it to be.” Dimitri paused, “I-I mean obviously it’s been renovated and—“`}</p>
    <p>{`“Shh, shh. Don’t panic. We’re just as excited to be here—how could we not be? You’ve literally talked of nothing else for the past decade,” Sylvain teased with a bit of snide. To be fair, Dimitri did feel guilty for hounding his friends incessantly about the place but hey, living in Garreg Mach as a child was the best years of his life, and this cafe played a big part in it.`}</p>
    <p>{`“I don’t know if I can do this—“`}</p>
    <p>{`“Are you fucking serious?” Felix spat with a scowl on his face. He gave Felix an apologetic look and stared at the pavement to further avoid Felix’s glare.`}</p>
    <p>{`Sylvain released him and gave his neck a supportive squeeze then pushed him forward. “You can do it!”`}</p>
    <p>{`Dimitri swallowed down his trepidation and reluctantly nodded at his friends.`}</p>
    <p>{`Sucking in the deepest breath he could, Dimitri took one step towards the entrance before abruptly spinning around, his heart in his throat, “I can’t do it—”`}</p>
    <p>{`Dimitri was immediately stopped by Felix and Sylvain who grabbed his arms on either side he stood his ground shaking his head and refused to move.`}</p>
    <p>{`Sylvain and Felix were struggling heavily against him and for once, and Dimitri was ever so thankful for his unnatural strength.`}</p>
    <Divider mdxType="Divider" />
    <p>{`It was the little things that made Byleth happy and today, it was getting a good deal for milk at the suppliers. She hummed all the way home in the car and pulled up to the designated parking at the entrance when she saw three suspicious men, doing some sort of… wrestling?`}</p>
    <p>{`She shrugged. University students were weird and were always up to something. In her line of work, it didn’t matter as long as they were paying customers and didn’t cause trouble. Still, she went up to them to see if she was either going to be of some sort of help or the reason Shamir, her police friend, will be called out on this lovely afternoon.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Byleth hopped out of the car, smoothened her apron and strolled up to them. “Can I help you, gentlemen?” she asked, a little apprehensive.`}</p>
    <p>{`“Hey!” The one with red-hair said and waved at her. “Sorry—uh, my buddy here is too scared to go in—” He pointed directly at the tall, blonde one, who had the most bewildered expression on his face—almost as if he’d seen a ghost.`}</p>
    <p>{`“Byleth—!” The blonde one blurted out, and she frowned at him tilting her head.`}</p>
    <p>{`She looked down at her chest and sure enough, she had removed her nametag like she always did when she went out to get supplies.`}</p>
    <p>{`Now she was alarmed. “How do you know my name?” She’d never seen him before, not recently at least. And she recognised a lot of her customers, especially if they were `}<em parentName="p">{`this`}</em>{` good-looking — even if they were somewhat suspicious.`}</p>
    <p>{`He pulled away from his friends and stumbled over to her, and she instinctively took a step back. “I-It’s me, Byleth — Dimitri.”`}</p>
    <p>{`She narrowed her eyes at him. “...Dimitri?” The name invoked something in her, a long forgotten memory, struggling to surface.`}</p>
    <p>{`“Yes! About 13 years ago, I used to come here everyday, remember? And you’d let me sit in the cafe, um—” he suddenly moved away from her, peering desperately inside the cafe and lit up when he saw what he was looking for, “—here!” He pointed excitedly at the corner seat where a baffled art student was sitting.`}</p>
    <p>{`“You’d let me sit here for hours as long as I did my homework and didn’t cry!” He chirped, smiling so genuinely at her that something clicked in her. The memory that was struggling to surface `}<em parentName="p">{`finally`}</em>{` came to light. She gasped and clasped a hand over her mouth.`}</p>
    <p><em parentName="p">{`“Dimitri?”`}</em>{` She echoed his name, disbelief obvious in her voice. The little boy who looked like the cutest little girl that frequented the cafe almost everyday, grew up into `}<em parentName="p">{`this`}</em>{`? He must be what, 6’1..6’2? Compared to her memories of when she was still a good head taller than him, he now towered over her like a giant!`}</p>
    <p>{`“Dimitri?! You mean, um— I’m sorry, is it really you? Sorry, can I—” She moved towards him, and reached out to quickly tap his chest to see if he was real, and gasped when her fingertips were met with solid muscle—so solid, she instantly retracted her hand as if she’d just touched searing iron. Byleth blinked, trying to make sense of his incredible growth spurt.`}</p>
    <Ads mdxType="Ads" />
    <p>{`He nodded shyly, chuckling. His voice had also become deep, bassy. The giggles they used to share were now rumbling, throaty laughs. But the one thing that didn’t change, was his dimpled, flustered smile. It was little crybaby Dimitri, alright.`}</p>
    <p>{`“I…might’ve grown a little,” he said, rubbing the back of his neck as he looked down at her. His adorable page boy cut had now been replaced with a slicked-back hair and a half pony-tail. The sight of him made her heart skip an involuntary beat.`}</p>
    <p>{`“A little?” She snorted, unable to suppress a grin, “You’re so much bigger than me now! Oh Goddess, you used to be so small—” she put her hand up to her chest, “—you could just barely see over the countertop. Now look at you!” She beamed at him and pulled him into a bear hug like she used to with small-Dimitri.`}</p>
    <p>{`Their sudden reunion filled Byleth to brim with glee. Dimitri had pretty much left Garreg Mach all those years ago in a rush, and didn’t even tell her why. He just came with his Uncle to say goodbye and that was it.`}</p>
    <p>{`Having forgotten that he was a grown ass man now, she suddenly came to her senses when she felt Dimitri freezing up in her arms and quickly pulled away. “Sorry! I forget that you’re not 11 anymore—!” She yelped when he unceremoniously pulled her back into the hug. His hand cradled her head affectionately into his chest. Byleth could feel him breathe and sigh over the top of her head while his other hand rested on her lower back, firmly holding on to her.`}</p>
    <p>{`“It’s been so long…” He said softly, now nuzzling slightly in her neck. Byleth’s normally calm and placid heart was sent into a frenzy, beating so hard against her ribcage it was making her disoriented. “I’ve missed you so much, Byleth.”`}</p>
    <p>{`The way he said her name sounded so much more intimate than a mere childhood reunion that in her panic, she hastily pushed him away. Dimitri looked horrified with himself as he shook his head. “I-I’m sorry, I don’t know what came over me. I hope I didn’t make you feel uncomfortable.”`}</p>
    <Ads mdxType="Ads" />
    <p>{`Byleth tucked a lock of hair that had come loose during their hug behind her ear. “No, no, um, it’s great to see you too,” she said, biting her bottom lip, hoping her embarrassment wasn’t showing too much, even though she could feel her cheeks burning up.`}</p>
    <p>{`“It’s alright, don’t mind us. We’re just part of the cafe’s new decoration.” His red
-haired friend snickered, next to his shorter and very angry looking friend.`}</p>
    <p>{`Dimitri cleared his throat and she looked up at him in shock. Even `}<em parentName="p">{`that`}</em>{` was surprisingly so manly of him. All she could see was that affectionate little boy who was always hanging around her, calling her Byleth Big Sis, in fact — it would definitely take a while to get used to this virile, rugged young man in front of her.`}</p>
    <p>{`“Oh no, my milk!” She cried out suddenly and rushed to her car, throwing herself inside the backseat to pull out the crates of various types of milk.`}</p>
    <p>{`When she turned around, Dimitri, now standing behind her, lifted the heavy milk crate off her hands as if it weighed nothing and tucked it under one arm. “Pass me the other one, please?”`}</p>
    <p>{`She blinked at him. “Oh…sure,” she replied, a little hesitant when she heaved it into his other arm. “Are you sure? It’s pretty heavy…”`}</p>
    <p>{`“It’s fine,” he assured her. And he did look steady, not even a tremble in his arms as his friends jogged over to help her carry the other supplies from her car.`}</p>
    <p>{`She led them inside, past the counter where Ashe was tending the register and into the walk-in fridge. It wasn’t big enough for three grown men and herself to fit inside so she instructed them to leave it on the prep-table.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Sylvain, the red-head, and Felix, the grumpy one, dismissed themselves and asked for a rain-check on the free drinks she offered them for their help, leaving her alone with Dimitri.`}</p>
    <p>{`“Can I help?” He offered, already shrugging off his outer-coat and revealing his lean, yet muscled torso in a cream-coloured turtleneck, and rolled his sleeves up to his elbow. Byleth tore her eyes away before he could notice her staring at his veiny arms.`}</p>
    <p>{`She chuckled at his eagerness and nodded. “It’ll be great, thanks.”`}</p>
    <p>{`Byleth stood inside the walk-in fridge while Dimtiri passed the items for her to put away, and they chatted, trying to catch up on the last 13 years.`}</p>
    <p>{`“How old are you now? I think you’re…what, 22? 23?” She pondered as she rearranged to fit the new stock - first in first out.`}</p>
    <p>{`“I’m 24 this year, please don’t make me younger than I already am,” he pouted and she laughed in reply.`}</p>
    <p>{`“You’ll always be that little boy who clung onto me, you know,” she paused her restocking and went on her toes to ruffle his hair, just like she used to do. Dimitri blushed adorably and turned away.`}</p>
    <p>{`“Are you…married?” He suddenly asked, surprising her and she gave him a half smile. The pink on his cheeks still evident but the look in his eyes was…unexpectedly intense.`}</p>
    <p>{`“Hrm, sadly no,” she said, a little woeful as she did feel the sting of seeing her classmates and friends get married, give birth etc. “Had a couple of boyfriends but… we just… didn’t click?” Byleth tapped her chin as the faces of Claude, Ferdinand Von Aegir, and Seteth—her ex-boyfriends, came to mind. “I’ve stopped worrying about marriage and the like for a while now. If it happens, it happens.”`}</p>
    <Ads mdxType="Ads" />
    <p>{`Dimitri let out a sigh, and she thought she heard a ‘Thank Goodness’ but she must’ve imagined it.`}</p>
    <p>{`“What about you? Married with kids yet?” She grinned at him and climbed up a step ladder to put away the strawberries. “Strapping young lad like you must have an army of ladies after you.”`}</p>
    <p>{`He snorted but then shook his head. “No one. I thought I had something going on with a girl back in high school but uh, we were more like siblings. She’s one of my best friends now.”`}</p>
    <p>{`Even at one step up on the ladder, she still stood shorter than him. At least making eye-contact was easier now. She tilted her head. “Really? Did you at least kiss her?”`}</p>
    <p>{`Dimitri looked at her as if she had just murdered a puppy. “What? I’m serious!” She said seriously but couldn’t help laughing at his still mortified expression, as if it was a dirty little secret he didn’t want her to know. “Alright, keep your secrets to yourself.”`}</p>
    <p>{`Byleth peered over his shoulder to see if there was anything left to put away and hopped off the ladder when she saw the empty crates and plastic bags. “Thanks, Dimitri,” she patted his head again. “Just like old times, huh?” She chuckled and pushed past him, motioning at him to leave the fridge so she could lock it.`}</p>
    <p>{`“I…I did kiss her,” Dimitri admitted finally, and averted his gaze when she looked at him, as though he was confessing to a crime. “It was then I…we knew we didn’t have romantic feelings for each other.”`}</p>
    <p>{`“Awww!” She cooed, “my baby Dimitri is all grown up and kissing girls!” She tip-toed and pinched both his cheeks. He winced slightly and grabbed her wrists, lingering in her gaze for a second too long, long enough to make her feel hot in her own skin.`}</p>
    <p>{`“Please…don’t call me that anymore,” he said huskily, but she looked away, unable to bear the intensity of his bright, blue eyes.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Sorry,” Byleth pulled her wrists and herself away, confused. In fact, she was so confused she forgot what she needed to do, mindlessly pacing around the kitchen, looking for something to do — something to deal with instead of these awkward, feverish feelings.`}</p>
    <p>{`Until Ashe called out.`}</p>
    <p>{`“Manager! Raphael says he’ll be back a little late, there’s a really bad traffic jam going up the school!”`}</p>
    <p>{`Byleth sucked in a deep breath and glanced down at her watch. She sprinted over to the schedules and sighed when she saw that Leonie was off today.`}</p>
    <p>{`“Hmm, we’ll just have to manage the dinner time rush without him. Ashe, I’ll take over counter duties, do you mind cooking until Raphael comes back?”`}</p>
    <p>{`Ashe nodded enthusiastically as he swapped aprons and pinned his hair back.`}</p>
    <p>{`“I can help,” Dimitri suddenly said. For a split second, she’d forgotten he was there. She whipped her head round to look at him and frowned.`}</p>
    <p>{`“Of course not, Dimitri.” She shook her head. “You’re a guest!”`}</p>
    <p>{`Dimitri patted her head, a strange feeling pulsed in her chest. “I’ll clean the tables and wait on them. It’ll only be for a while, until your staff comes back.”`}</p>
    <p>{`Before she could protest, Dimitri walked over to the staff room and looked pleased with himself when he found a spare apron and cap. His dimpled smile threw her back to when he was 11 and had insisted on putting on the oversized apron, waddling around to help her.`}</p>
    <p>{`She chuckled to herself but straightened up when he came over. She beamed at the sight of her new temp employee. “Alright, I’ll give you a crash course.”`}</p>
    <p>{`Even when Raphael came back from deliveries, Dimitri still continued to help, even grabbing a stack of plates off her hands as if it weighed nothing.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Gone were the days where little crybaby Dimitri struggled to carry a heavy plate of food with both hands. Now he was so big he had to duck under the door frame after knocking his head the first time. Byleth laughed of course, after she frantically ran over to check whether he was about to cry.`}</p>
    <p>{`Rubbing her palm instinctively over the red spot on his head, she tiptoed and was about to kiss it when she stopped, and her cheeks flushed. He was a grown man now. Dimitri even had faint traces of stubble on his chin.`}</p>
    <p>{`An awkward chuckle escaped her and she quickly stepped away, embarrassed to look him in the eye. “S-Sorry, Dimitri, I forget—”`}</p>
    <p><em parentName="p">{`DING!`}</em></p>
    <p>{`Ashe slapped the food bell and Dimitri quickly moved to collect it.`}</p>
    <p>{`While being short-staffed during rush hours didn’t phase her, it was still an inconvenience. She never called a staff who was off duty to come in unless it was absolutely necessary. So having Dimitri around was a huge weight off her chest. He was a fast learner and very reliable— didn’t need to be told what to do and moved on his own.`}</p>
    <p>{`He was even smiling as he cleared the tables.`}</p>
    <p>{`Finally, the last table left the premises and Byleth changed the sign from OPEN to CLOSED.`}</p>
    <Divider mdxType="Divider" />
    <p>{`“I’ll do it,” Dimitri said when he saw Byleth go on her toes to reach the top lock. Looking down, he had the perfect view of the top of her head. It made him smile — `}<em parentName="p">{`pleased`}</em>{` — that he’d grown so much taller than her.`}</p>
    <p>{`“Thanks,” she said and let out a long sigh. Dimitri had the urge to reach out and smooth the crease between her eyes. He could tell she probably had to handle rush hours like these for a while now. She looked exhausted, but he resisted.`}</p>
    <Ads mdxType="Ads" />
    <p>{`She leaned over the counter to grab her bottle, about to relax when suddenly she jumped up and said to him, “Ah, Goddess! I have to pay you, Dimitri.”`}</p>
    <p>{`He frowned at her. “What for?”`}</p>
    <p>{`She gestured at the cafe. “For helping out? I’m not going to let you work for free, you know.”`}</p>
    <p>{`“Manager!” Ashe popped his head from the serving hatch. “The kitchen is clean so, Raphael and I are going to take off now, is that okay?”`}</p>
    <p>{`“Oh, yeah, sure. Uh, take the trash on the way, please!” Byleth yelled back.`}</p>
    <p><em parentName="p">{`“Gotcha! G’night, manager!”`}</em></p>
    <p>{`“Phew,” she exhaled, taking a long swig from her bottle. “Right, where was I? Ah, yes. So you did…” Byleth lifted her hand up and counted down how many hours he worked. But he shook his head.`}</p>
    <p>{`“Please, don’t. I inserted myself and insisted on helping,” Dimitri said firmly.`}</p>
    <p>{`Byleth rolled her eyes. “I let you help, because I knew I was going to pay you.”`}</p>
    <p>{`“I volunteered, you cannot pay me,” he countered quickly, smirking when her mouth fell open a little, speechless.`}</p>
    <p>{`“Huh,” she crossed her arms and smiled back. “You have a point. Alright, in lieu of payment, what would you like? I could whip up something right now if you’d like.”`}</p>
    <p>{`Dimitri raised an eyebrow at her.`}</p>
    <p>{`“I insist. Within reason, of course.”`}</p>
    <Ads mdxType="Ads" />
    <p>{`He threw a glance at the menu, but Dimitri wanted something else altogether. “I’d like to walk you home.”`}</p>
    <p>{`Byleth scoffed. “Dimitri, I’m serious.”`}</p>
    <p>{`“I’m serious too, Byleth.” Her name rolled off his tongue so easily it still didn’t feel real. Here she was, the woman he’d been in love with for over a decade. Of course she was different from his memories, yet the changes weren't disappointing or unpleasant.`}</p>
    <p>{`She was shorter than he remembered, her hair was longer, her skin didn’t glow like those celebrity idols, nor was there sunshine shooting out of her. Yet, as dishevelled as she looked right now, the glint in her eyes never faded and her skin was as radiant as her smile.`}</p>
    <p>{`It just made her, Byleth, all the more amazing and beautiful than he remembered. He was excited to get to know her again.`}</p>
    <p>{`“Also I’d love to greet your parents, if possible,” Dimitri said sincerely, they did give him free food when he was a child.`}</p>
    <p>{`Byleth paused, staring at him with her big green eyes. He gulped and looked away. Dimitri couldn’t handle looking into them for longer than a second, or his affection for her may spill out.`}</p>
    <p>{`“Alright, I’ll let you walk me home as payment. But it will be a disappointing trip, since my parents are on a four month cruise around the continent,” she mused.`}</p>
    <p>{`Dimitri’s heart thudded so hard in his chest he might’ve blacked out for a split second when she agreed. “G-Great…!”`}</p>
    <p>{`He eagerly and quickly returned the apron and cap, then went round the premises once more to triple check the locks before waiting for her outside at the entrance. Once she was out, she did the most adorable jump to pull the shutters down and padlocked the doors.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“My memory is hazy but do you still live nearby?” Dimitri asked, looking around the area to see if there were lurkers.`}</p>
    <p>{`“Mmhmm, yeap.” She nodded and gave the padlock one last tug before holding her hand out. “Let’s go.”`}</p>
    <p>{`Dimitri tilted his head at her, “Hrm? Do you need something from me?`}</p>
    <p>{`Some sort of realisation dawned on her and she gasped. “Goddess, I am so sorry…!” She retracted her hand. “I…It’s a force of habit, I mean we used to hold hands all the time back then, didn’t we?” She covered her pink cheeks and continued apologising profusely. “Goddess, it’s the third time today,” she mumbled.`}</p>
    <p>{`Dimitri let out a hearty laugh, his heart so full at seeing her so flustered. Here was the usually calm Byleth blushing as she scuttled further away so he couldn’t see her face. What a blessing to be alive, he thought, suppressing a grin.`}</p>
    <p>{`“I know you don’t like being treated like a child anymore, I mean you’re—” she gestured at his full height, “it still takes me by surprise to be staring right into your chest instead of looking down at you.”`}</p>
    <p>{`He snorted. “Uh…thanks?”`}</p>
    <p>{`“Sorry, I’m rambling, I ramble when I’m nervous,” she chuckled. Dimitri couldn’t help but reach out and gently patted her head.`}</p>
    <p>{`Time seemed to pause when their eyes finally met as his hand rested on the top of her head. He quickly pulled back and cleared his throat. “S-sorry.”`}</p>
    <p>{`She shook her head. “It felt nice.”`}</p>
    <Ads mdxType="Ads" />
    <p>{`They continued their walk back, a small sense of awkwardness hung in the air, but they chatted and reminisced so much that 5 minutes felt like 5 seconds.`}</p>
    <p>{`“It hasn’t changed much has it?” Dimitri chirped when they reached her house. It was the same quaint cottage where she lived with her parents. Byleth had invited him in for a drink and his heart nearly stopped. While of course, it was an innocent invitation, adult-Dimitri’s mind was shamefully...`}<em parentName="p">{`adult`}</em>{` sometimes.`}</p>
    <p>{`“I couldn’t impose, it’s far too late. You need the rest. But…could I…just have a quick peep? I’m rather curious to see if anything has changed,” he asked shyly.`}</p>
    <p>{`When he was a child, she told him that it was so ridiculously old it was nearly designated as a heritage site even. Which would explain why the door frame was so low and he walked right into it.`}</p>
    <p>{`…Or so he told himself the split second pain burst in his skull. The real explanation was that he’d been so distracted by Byleth that he walked into not one, but two door frames in the same day. A new record, really.`}</p>
    <p>{`Dimitri clutched his forehead and rubbed it, waiting for the ringing in his head to settle down. But Byleth wrenched his hands away, grabbed his head to her level and inspected the big red spot.`}</p>
    <p>{`He could feel her thumb rubbing down the pain, but he did not expect her to place a soft kiss on his forehead, since she had pulled back before in the cafe.`}</p>
    <p>{`“There, all better,” she said, and grinned at him, but then turned white as a sheet, while his cheeks warmed up considerably.`}</p>
    <p>{`Byleth said nothing as she backed away, until her words tumbled out of her mouth as she frantically apologised to him again. “I am so, so, sorry. I…Dimitri, it was—”`}</p>
    <p>{`“It’s alright, Byleth.” He gently held her shoulders and pointed at his head, smiling. “See? It doesn’t hurt anymore.”`}</p>
    <Ads mdxType="Ads" />
    <p>{`She blinked and blinked at him, and snorted a little laugh. “My magic still works huh?”`}</p>
    <p>{`“It sure does,” Dimitri replied, barely keeping it together. The feel of her lips on his head was something he thought he’d never experience again.`}</p>
    <p>{`And he wanted more.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      