import styled from 'styled-components';

import $ from '../../styles/globals';
import TwitterFollow from '../SocialFollow/Twitter';

const Section = styled.section``;

const Container = styled.div`
  border-bottom: 1px solid ${$.brown3};
`;

const SocialFollow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin-top: ${$.layout.margin2}px;
  > * {
    margin-right: ${$.layout.margin2}px;
    margin-bottom: ${$.layout.margin2}px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const SocialButtons = () => (
  <Section>
    <Container>
      <SocialFollow>
        <TwitterFollow nabe />
        <TwitterFollow nabe={false} />
      </SocialFollow>
    </Container>
  </Section>
);

export default SocialButtons;
