import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXFanfiction/Chapter/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Divider = makeShortcode("Divider");
const AuthorNote = makeShortcode("AuthorNote");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`“I’m sorry, what?” Reus stared into his twin sister’s eyes. “You want to—what?”`}</em></p>
    <p><em parentName="p">{`Byleth, as serious as the day she was conceived into the world without a single cry, repeated herself. “I need to disguise myself as you, because I think…No, I’m certain the Weapons Club at your new school is cheating.”`}</em></p>
    <p><em parentName="p">{`Reus sat himself down opposite her. “And you think I’ll agree to that because...?” `}</em></p>
    <p><em parentName="p">{`She cleared her throat. “Remember when my team lost the tournament,and dad thought I was inconsolable and gave me a lot of money to cheer myself up?” Reus nodded slowly, still looking uncertain of where this was going.“I’ll give it to you if you’ll let me impersonate you for a month. You’re grounded for being kicked out of school anyway. It all works out.” Her face was expressionless except for a bright spark in her eye.`}</em></p>
    <p><em parentName="p">{`Reus’s mind echoed, ‘You’ll never be able to go to that concert in the Adrestia.’`}</em></p>
    <Ads mdxType="Ads" />
    <p><em parentName="p">{`She could see the wheels turning in his head. Manuela, his idol, `}<strong parentName="em">{`his love`}</strong>{` was holding a rare solo concert in her home of Adrestia. And the money Byleth was offering him could pay for front row seats, a backstage pass and a more-than-decent accommodation somewhere.`}</em></p>
    <p><em parentName="p">{`As though to persuade him further, she added. “No one knows what you look like in person.” But her brother still hesitated. Byleth wasn’t about to give up now. She scooted closer and tapped his knee twice, a sign for when she’s excited. “You wouldn’t need to go to school for a month.” Silence. She chewed her inner cheek. “Come on, the timing is crazy perfect! Dad is away on another security assignment again, and it’s the school holidays for me now, too.”`}</em></p>
    <p><em parentName="p">{`Byleth was initially dejected that her brother was going to school in another country, but she didn’t seem so anymore. Her school holidays in Faerghus’ Ladies Academy meant that the new school term would begin in Garreg Mach’s Boarding School for Boys.`}</em></p>
    <p><em parentName="p">{`Reus relented and groaned, “I hate you.” Byleth threw her arms around him with a gleeful yelp and they fell backwards off the plush stool.  “You’re taking full responsibility for this. If I get kicked out of school again, you’re paying me for the rest of my life.”`}</em></p>
    <Divider mdxType="Divider" />
    <p>{`Sunday evening. Byleth sighed as she stood at the school dormitory reception, with
some luggage piled behind her. No longer was she Byleth. From now on she was Reus
Eisner.`}</p>
    <p>{`She closed her eyes tight and exhaled a steady breath. What had she gotten herself into? The plan felt solid in her head, but now, she wasn’t quite sure. Suddenly a hundred and one flaws seemed to present itself in her supposedly perfect plan. All she could think of was justice for her teammates even though they `}<strong parentName="p">{`insisted`}</strong>{` it wasn’t her fault and responsibility. How could she not, as the team captain?`}</p>
    <p>{`She straightened her collar and smoothed the front of her oversized shirt. The bandage around her chest was squeezing the breath out of her and the fake sock-bulge in her pants was terribly uncomfortable. Thanks to Mercedes, she managed to put this flimsy disguise together. It was a miracle no one had seen through it yet. Only one or two had thrown furtive glances her way.`}</p>
    <p>{`Byleth swept her ponytail over her shoulder. She had considered cutting it short to match Reus’, but had second thoughts. It had taken a year to grow it out. To be fair, some of the boys had longer hair than she did, like the kid she nearly stepped on in the corridor. His dark green hair covered the side of his face as he slept. Him being dead asleep in the corridor was apparently a normal thing. “Linhardt, you’re sleeping here again?!” A boy with blue hair berated the sleeping figure so loudly that she jumped. Linhardt barely twitched a muscle as she shuffled awkwardly around him, making sure to avoid knocking her duffle bag at his head.`}</p>
    <p>{`After that strange encounter with the sleeping boy, she was faced with another ordeal. The real Reus had failed to mention that her sleeping arrangements involved other boys with raging hormones. Five almost scantily clad 18-year-old boys were in her dorm. Scantily clad, to her standards, was anything less than boxers and a shirt. And a third were thankfully `}<em parentName="p">{`only`}</em>{` half-naked from the waist up and casually strolling about. The others, well, she just had to imagine them as stuffed animals. With muscles and dangly bits in between— she shook her head. `}<em parentName="p">{`You’re a boy now. You, too, have dangly bits. Made of socks. Ugh, get a grip Byleth!`}</em>{` The room reeked of sweat and other male-associated smells. She held back the urge to wrinkle her nose or stare, and keeping a straight face, took one last deep breath of male-odor free air and strode in.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Any normal teenage girl would be thrilled. But at this moment, Byleth was absolutely freaking out.`}</p>
    <p>{`“Hey!” Byleth stopped mid-step and shifted a weary eye towards the topless, red-head coming towards her. He was bare-chested. `}<em parentName="p">{`Don’t. Stare.`}</em>{` She repeated in her head like a mantra. Oblivious to her panic, he grinned at her and rolled his shoulder, flexing his muscles in the process. “You must be the new guy. We’d heard about your crazy ass fight with that dude that got you kicked out—`}<em parentName="p">{`ow`}</em>{`!” A pale hand smacked the back of his head. Auburn eyes settled on her over the scowling red-head.`}</p>
    <p>{`“Don’t mind him. He left his brains back home. I’m Felix, this idiot here is Sylvain.” Felix had his dark hair tied up in a bun instead of a ponytail like her’s. “Also, I hope you can put up with Sylvain’s… `}<em parentName="p">{`gallant`}</em>{` personality. He has a habit of ‘getting anyone standing next to him pregnant’.”`}</p>
    <p>{`“Now, who said anything—” Felix hit Sylvian in the face with a pillow before he could retort in defence. Byleth let out a nervous chuckle.`}</p>
    <p>{`She cleared her throat to lower her voice into what sounded like a poor imitation of a young man oozing testosterone. “I’m a guy. What does it matter?”`}</p>
    <p>{`Felix scoffed. “That’s never stopped him before.”`}</p>
    <p>{`Byleth’s eyes widened but she regained composure despite the obvious colour creeping across on her face.`}</p>
    <p>{`“I’m Ashe!” A youthful face grinned at her from the back of the room. The silver hair that fell around his eyes moved as he waved frantically from his bed. She waved back. Finally, a normal person.`}</p>
    <p>{`A figure approached her from the periphery of her vision. He was one of the first boys she saw as she entered the room. She trained her eyes on his golden locks that fell just over the clear blue eyes that complemented his hair so well, and `}<em parentName="p">{`not`}</em>{` on his broad, uncovered chest in front of her. `}<em parentName="p">{`Goddess help me.`}</em></p>
    <p>{`“I must apologize for their behaviour.” She recognised this voice. Taking a better look at his face, she nearly recoiled from surprise. `}<em parentName="p">{`He`}</em>{` was part of the reason she was here, unfortunately or not, surrounded by hormonal teenage boys. “I am this dormitory’s assigned leader. Please, call me Dimitri.” Smiling, he held out his hand. She gripped it firmly and shook it once. “If you have any questions or troubles, feel free to ask me. I would be honoured to help.” He quickly slipped on a shirt. “Also, please forgive my indecency at our first meeting.”`}</p>
    <p>{`Dimitri. She would take care to remember that name.  He was there that day when her Weapons Club lost the tournament. Anyone who didn’t know her would say she was a sore loser, but she had good reason to be. Her club not only lost the championship, but her team of four also had their chances of scoring a scholarship to the prestigious Garreg Mach University taken from them. It wasn’t as if they were terrible in the tournament—it was because something suspicious was going on in the background. And Byleth was set on exposing this injustice.`}</p>
    <p>{`A large, towering figure who was turning his bed down for the night, turned to her. His stoic features could rival hers. He looked like a tanned bear with cropped silver hair.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“That’s Dedue.” Dimitri gestured. Dedue the bear nodded at her.`}</p>
    <p>{`“Sorry for not introducing myself earlier. My name is Reus Eisner.” She tried her best to maintain her ‘manly’ voice, but forcing it to come out all raspy and deep was beginning to hurt her throat. More than anything else, she sounded like a prepubescent boy whose voice was cracking.`}</p>
    <p>{`“Right, Eisner.” Her name trailed off his lips each time he repeated it to himself. “Have we met before?”`}</p>
    <p>{`She shrugged, “I do not think so.” It wasn’t a lie. Byleth indeed had met him, not Reus.`}</p>
    <p>{`Hate was too strong a word to describe what she felt about Dimitri. It was more of a distrust. The players weren’t involved in the scandal she was trying to uncover, but she needed evidence. Back at her Academy, nobody believed her. ‘You’re just upset at losing, Give yourself some time.’ Losing the game fair and square was one thing. But losing it knowing they could have had a glimmer of a chance at winning, by a slight of hand, was unforgivable.  As team captain she promised her teammates they’d win, and that they’d receive help to go to university She wasn’t about to break that promise now.`}</p>
    <p>{`“-us?” A hand waved over her face. “Reus?” Dimitri’s concerned face peered down at her.`}</p>
    <p>{`Blinking, she reminded herself of why she was here and let her eyes wander the room, past Dimitri’s well-defined chest and shoulders. `}<em parentName="p">{`There’s going to be more of this so focus, goddammit`}</em>{`.`}</p>
    <p>{`“S-Sorry. Um, which one’s my bed again?”`}</p>
    <Divider mdxType="Divider" />
    <p>{`The next day, Dimitri, being the ever so helpful and charming dorm leader took her
on a tour after school was over. So excited was he that he had put on his extra deep,
‘tour guide’ voice. One would think that he had given birth to the school from the
way he gushed about and pointed out every detail whenever they came across a plaque,
statue, or some room dedicated to some knight from somewhere.`}</p>
    <p>{`“We will go back to the dorm first to retrieve our clothes. I intend to end our tour in the changing room, in time for our first meeting this term—for the Weapons Club, I mean. I heard from our homeroom teacher that you are interested?”`}</p>
    <p>{`Byleth nodded, trying to speak as little as possible to avoid people noticing her not-so-manly-voice.`}</p>
    <p>{`Dimitri’s face lit up, but his expression quickly turned serious. “I am sincere in saying that I am delighted to have a new member so soon, as I am the Captain. But I must warn you, our coach is quite...”`}</p>
    <p>{`“Grumpy?”`}</p>
    <p>{`“Yes, ill-tempered is the word I suppose,” Dimitri said. She had seen their coach scream at them at the tournament. “That was a good guess, Reus.”`}</p>
    <p>{`She shrugged. “Lucky.”`}</p>
    <Ads mdxType="Ads" />
    <p>{`He raised an eyebrow and looked at her. “I take it you’re still interested in joining us?”`}</p>
    <p>{`“Of course,” she said a little too quickly. “How else am I going to uh… release all my raging `}<em parentName="p">{`adolescent`}</em>{` anger?”`}</p>
    <p>{`Dimitri let out a deep laugh.`}</p>
    <p>{`“Oh, that’s right. If you don’t mind me asking—” He cleared his throat. “I heard—well, `}<em parentName="p">{`we all`}</em>{` heard that you are here because of a fight?”`}</p>
    <p>{`She hesitated. “Uh, yeah. But I don’t really remember what happened.” She wasn’t there when it happened, so `}<em parentName="p">{`technically`}</em>{` she wasn’t lying. “It’s all quite fuzzy now.” By the time she rushed over from her school next door, Reus was squatting against the wall in the principal's office, his bloody nose plugged with a tissue and an ice-pack pressed against his absolute mess of a face. His opponent or some say, victim, was glaring at him from the other end of the room. He was noticeably in a worse shape than Reus; his arm in a sling and a bandage around his neck. Her brother might have also mentioned that he bit the guy on the neck.`}</p>
    <p>{`“I know what you mean.” Dimitri nodded understandingly. “It’s a blur because sometimes all you can think about is, `}<em parentName="p">{`don’t get hit`}</em>{`.” He gave her a quick and sympathetic tap on the shoulder.`}</p>
    <p>{`She continued. “The ending was that, we— as in me and the guy I fought with—  both got kicked out. Or as the school says, `}<em parentName="p">{`‘transferred to expand our horizons’.`}</em>{`” She did air quotes as they walked up a flight of steps, heading towards their dorm.`}</p>
    <p>{`Dimitri touched his chin in thought. “Yes, but… if my assumptions are correct, you were immediately expelled after this unfortunate incident, were you not?”`}</p>
    <p>{`“Yes.”`}</p>
    <p>{`“If I may be so bold, I have to say the incident does not justify the heavy punishment you  received.” He paused for a brief moment, watching her expression.`}</p>
    <p>{`“I agree. Not that it matters now, though.”`}</p>
    <p>{`“I’m sorry,” Dimitri apologized. A pang of guilt stabbed Byleth because he looked like a wounded puppy.`}</p>
    <p>{`“Don’t be. I wouldn’t be here otherwise. It’s only been a couple of hours, but I already like it here.” She had to thank Reus again one day for getting into that stupid fight. “Besides, his—I mean `}<em parentName="p">{`my`}</em>{` old principal had the biggest stick lodged firmly up his behind, anyway.”`}</p>
    <p>{`“I’m glad your stay here has been a pleasant one, so far.” Dimitri threw his head back a little, laughing. She felt a strange squeeze in her chest when he did that. He looked like a prince.`}</p>
    <p>{`They opened the door to their dorm to see Ashe asleep above his covers. Byleth remembered him being up quite late reading a novel under his blanket. They got their things and quietly shut the door behind them. Byleth made an excuse about having a stomachache so she could change in the toilet stall instead of the boys’ changing room. It was the one chance to make sure the bandage holding her ample bosom was secured extra tight. If she was exposed now, literally, all would be for nothing.`}</p>
    <p>{`“I’m done!” she exclaimed when she appeared next to Dimitri.`}</p>
    <p>{`He wore an amused expression on his face. “Done having a stomachache? No one had ever declared such a thing before.”`}</p>
    <p>{`“Whoops, yes `}<em parentName="p">{`that`}</em>{`! Yes, I’m done… taking a dump.” She shifted awkwardly. “Anyway, I thought I might as well get changed.”`}</p>
    <Ads mdxType="Ads" />
    <p>{`Dimitri nodded approvingly. “Well done you, very efficient.”`}</p>
    <p>{`He showed her around some more as they moved on towards their destination. Dimitri had a way of describing the purpose of  each room they passed by mentioning a fond memory, like the one with the slightly different door handle. He was a bit ashamed about that in particular. He had broken the original handle by gripping it too hard when a person named Claude jump-scared him. Byleth looked up at him in a shade of horror. She shook her head free of the image of a monstrous Dimitri. This sweet, prince charming looking guy breaking a door handle? Impossible. She looked around as his voice faded into the back of her mind, immersing herself in this new environment, observing all the corridors, and the number of doors preceding their destination. If this couldn’t calm herself down, nothing else would. Panic threatened to surface each time someone took a double take at her. It alarmed her that nobody realised Reus Eisner was actually female. Perhaps getting this done within a week was not so impossible after all. The real Reus would be redeemed to some extent by taking all the credit, and maybe even allowed to go back to Faerghus. By the end of the year, she and her team would be attending Garreg Mach University with their scholarship. A perfect ending.`}</p>
    <p>{`“Incidentally, all the clubs share this room. It’s also connected to the shower room which we’re only allowed to use during club activities,” Dimitri explained in his tour guide voice. Byleth nodded despite not paying attention, too busy thinking about how much she needed to brief the real Reus when he came back to take his place. “For regular day-to-day showers, we’re encouraged to use the ones on our floor.”`}</p>
    <p>{`Dimitri opened the door to the changing room and Byleth was forcefully brought back to reality as by the pungent body odour that assaulted her senses. The smell of Byleth’s dorm room was a far cry to the mortifying sights and smells of the boys’ changing room.  It was like being in the thick of a perfume factory, its workers semi-nude men, and their best seller being ‘Testosterone Dream’.`}</p>
    <p>{`She staggered a step back. The last time she saw anything remotely close to whatever flesh that dangled was when she and Reus bathed together as toddlers. She fled the room almost screaming before Dimitri could say anything.`}</p>
    <p>{`“What’s wrong with him?” Someone sitting near the door asked.`}</p>
    <p>{`“I… don’t know,” Dimitri answered, bewildered by Reus’s sudden exit. The poor boy probably had the runs again. He made a mental note to take Reus to the infirmary as soon as they were able. Dimitri wanted to protect this  newcomer. What Reus went through—the unjust punishment that fell on him for a petty fight fueled a sense of parental protection in him. No matter what, Dimitri swore to look out for him as dorm leader.`}</p>
    <Divider mdxType="Divider" />
    <p>{`Byleth groaned loudly as water hit her face and dripped down her chin and neck.`}</p>
    <p>{`“I should have closed my eyes. I’ll never get `}<em parentName="p">{`it`}</em>{` out of my mind now.” She honestly didn’t remember it being so….explicit. Thinking about it sent shivers down her back. “Stop, Byleth. Don’t be a perv. You’re here for one reason, and one reason only.”`}</p>
    <p>{`Byleth re-tied her chest bind, flattening it as much as she could, having dressed herself earlier in the school’s sports uniform and just in case; a large hoodie, hoping it would make her chest look like pectoral muscles.She slumped her shoulders obscuring her chest even more. Walking out of the bathroom she found herself in, she navigated herself back to the changing room.`}</p>
    <p>{`Byleth waited next to the door hoping to see a familiar face come out because she realised she didn’t know where the training room was. `}<em parentName="p">{`Didn’t think about that before you threw yourself out, did you?`}</em>{` She rolled her eyes.`}</p>
    <p>{`Ashe came running up to the changing room,  his bed head sticking up at odd angles. He whizzed past her but did a double-take when he saw her looking lost.`}</p>
    <p>{`“Reus! Sorry, didn’t see you. Can I help you?”`}</p>
    <p>{`“I’m sorry, I don’t know where I’m supposed to go for the Weapons Club meet.”`}</p>
    <p>{`Ashe flashed her a huge smile, “Don’t worry! Just gimme a sec, I’ll get changed and take you. I’m a member too!” Byleth thanked him profusely as he went in. While changing Ashe wondered how did such a nice person get expelled for fighting, of all things.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Sorry sorry!” Ashe burst out the door, his hair even messier. There weren’t many people in there with him.`}</p>
    <p>{`“Reus! I was getting worried about you.” Dimitri emerged behind him and closed the door. “If your stomach ache is that bad, I could take you to the infirmary for some medicine.”`}</p>
    <p>{`Byleth looked at him, puzzled. “Stomach ache?”`}</p>
    <p>{`“Is that not why you ran out just now?”`}</p>
    <p>{`Ashe let out a gasp. “You have a tummy ache? I have some meds in my bag actually—”`}</p>
    <p>{`“`}<em parentName="p">{`Oh yes`}</em>{`! I was, um, in the toilet, that is correct.” Byleth turned to Ashe to stop him from retrieving the medicine. “But I’m good now! No need to worry about me. Because I’m a man.” She pursed her lips, tightening it into a taut smile.She would need to keep up with her lies if she was to pull this whole thing off. “I had thought it was, but it was just...`}<em parentName="p">{`flatulence.`}</em>{`” She wasn’t used to talking about a fart so openly with acquaintances, although this was better than saying `}<em parentName="p">{`‘I saw a bunch of penises and ran out screaming because I’m actually a girl’.`}</em></p>
    <p>{`“I totally get’cha. False alarms like that are terrible.” Ashe agreed. “It’s so embarrassing too!”`}</p>
    <p>{`Byleth snorted. What a genuine soul. She felt a little guilty for lying. No, `}<em parentName="p">{`she had to toughen up, she told herself`}</em>{`. She was here to investigate an unjust act, not make friends.`}</p>
    <p>{`“That’s good.” Dimitri let out a sigh of relief. “I’d hate for your first day at a new school to be so dreadful. I would personally feel responsible, as I am your dorm leader.” Byleth’s pulse quickened. Despite her purpose of not making life-long comrades at this school, she was grateful for someone watching her back. To have finally met Dimitri after all this time of just shaking hands at the end of every competition. It was a shame he couldn’t recognise her, since the Byleth he shook hands with was female, not the boy he’s feeling protective over now. She kicked the dirt beneath her, the wonderful elation in her chest flattened.`}</p>
    <p>{`The three chatted as they walked a short distance then turned a corner, out a giant door and into a big open space. Byleth was again drawn into this new space, taking in the differences and comparing it with her own school training hall.`}</p>
    <p>{`Coach Kostas was late, which was apparently normal. Nevertheless, the efficient Captain Dimitri had them all prepped with warm-ups and some extra drills. In between running and push-ups, Byleth found out while chatting with the other members that her new roommates Dimitri and Ashe, along with two other boys, Raphael and Caspar had been part of the main team that fateful day. She only vaguely remembered their surnames on the board during the battle and their faces were a little hard to place when they were decked out in full armour. But she knew them now.`}</p>
    <p>{`Chatter died down suddenly. Coach Kostas strode in and the air became heavy as the students scrambled in front of him. Byleth squeezed herself into one of the rows.`}</p>
    <p>{`“Alright, you useless brats,” Coach Kostas yelled. “Let me make something clear, just because you won the championship last term  doesn’t mean you’re on the regular’s roster this term.” He eyed them. “And you’re not going to see a dime of that scholarship unless you win all the championships this year. As for you newbies, don’t even think you stand a chance at being on the roster.”`}</p>
    <p>{`Byleth’s fists balled at her sides. Her anger fumed as Coach Kostas continued demeaning his players and threatening them with withholding the prize money. Confusion. Disgust. Contempt. Questions swirled in her head. This Coach had absolutely no right to blackmail them like this or to keep the money. That should have been against the rules. Any rules!`}</p>
    <Ads mdxType="Ads" />
    <p>{`Byleth’s previously chirpy mood flew out the giant door she came in. She really needed to get a move on obtaining evidence. Not only was there foul play during the tournament; he was withholding their winnings.`}</p>
    <p>{`Something snapped loudly behind her. A wooden blade, now in two, splintery pieces dropped to the ground. She turned around and gulped. It was a solid piece of wood.`}</p>
    <p>{`Coach Kostas sneered. He walked over to Dimitri, shoving a few boys out of his way and stood right in front of him. “Well, well. If you’re not happy with how I do things `}<em parentName="p">{`Captain`}</em>{`—” He spat. “The exit’s that way. And pay for the sword before you leave.”`}</p>
    <p>{`“I’m very sorry Coach.” Dimitri rose to his full height and looked down at him. Eyes narrowed and feral. “I seemed to have lost control of my strength for a moment there.” The normally polite and pleasant Dimitri wasn’t there. His eyes were dark, intense and had a tinge of violence in them.`}</p>
    <p>{`“Since you’re so unhappy, you’re up first.” Coach Kostas jabbed a finger into Dimitri’s chest, unbothered by his sudden change of nature. “Let’s see if you’re still worthy of being Captain.”`}</p>
    <p>{`Byleth remembered the way Dimitri fought in the tournament. She was his opponent and their match ended in a draw; he was elegant and controlled, but this? His whole demeanor emitted a murderous vibe. To be honest, Coach Kostas had turned out to be an even bigger jerk than she thought.`}</p>
    <p>{`Ashe and Sylvain sat themselves next to her. “You look concerned, Reus.” Ashe said.`}</p>
    <p>{`She shrugged. “Dimitri feels...different.”`}</p>
    <p>{`Felix grunted, crossing his arms and leaned against the pillar. His eyes were like a sharp, cold blade slicing through their conversation. Ashe and Byleth kept quiet.`}</p>
    <p>{`“That’s because you’ve never seen the Boar’s real self before.” he said.`}</p>
    <AuthorNote mdxType="AuthorNote">
  <p>
    Hi all! Thank you thank you thank you so much for reading this! I haven’t
    written anything in about almost 10 years but felt the absolute urge to
    write for Fire Emblem Three Houses! I also enjoy cliche and will try to
    insert it as much as I can XD
  </p>
  <p>I wholeheartedly welcome any form of feedback and review. </p>
  <p>
    p.s I named M!Byleth, Reus, since according to the wiki Jeralt’s middle name
    is Reus.
  </p>
    </AuthorNote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      