import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const LocalLink = makeShortcode("LocalLink");
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`As of late, Nintendo loves giving out discounts for her digital games which is honestly a great thing. Back then, it’s really rare to see Nintendo games slashed up to 30% off, so I always jump on in whenever I saw gaming news site reporting about the sales. As I was browsing for a potential triple-A game to buy on my Nintendo Switch eshop, I noticed an indie game with really bright colours. That’s right, it’s Old Man’s Journey! After watching `}<LocalLink to="/blog/red-lady-robin-hood-extraordinaire-carmen-sandiego/" mdxType="LocalLink">{`Carmen Sandiego`}</LocalLink>{` on Netflix and trying out GRIS, my impressions on indie games that tells a story with specific themes in mind have definitely changed for the better. I’m more willing to try short-lengthed indie games just to experience the `}<em parentName="p">{`magic`}</em>{` it has to offer. Besides, it’s only about CAD 2++ thanks to the ongoing sales, so I paid and played it as soon as it finished downloading. It’s no GRIS, but for a game that lasts 1 hour 30 minutes at most, I am very impressed with the art direction.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="The old man kinda got lost..." caption="Ah~ The sun and the sea. Now how on earth am I supposed to get to the next map?" name="old-mans-journey-nintendo-switch-indie-game-geeknabe-blog-7.jpg" mdxType="Image" />
    <p>{`In Old Man’s Journey, you play as an old man living alone by the cliffs facing the sea. You received a letter one day and chose to read it on the spot. You mulled for a bit before heading back into your house to grab your backpack and walking stick. You’re now prepped to go on a journey! The backpack honestly look really heavy, and should an old man go on a journey in the first place? Regardless, whatever it is that’s written in the letter seems to necessitate the need, so off he goes with a grunt.`}</p>
    <Video title="Old Man's Journey" src="https://www.youtube.com/embed/tJ29Ql3xDhY" mdxType="Video" />
    <p>{`Immediately after the scene, the game then gives you control. Moving the left joy-con stick moves the cursor on the screen. Place your cursor carefully, then press the A button to place a walking stick icon on the ground to guide the old man across the map. There are limited ground and pathway that the old man can walk on, so you’ll need to hold down the A button on a potential pathway to see where he can go. The game highlights the current path he is on with stripes, while path he is not on is only highlighted with a thick stroke.`}</p>
    <Image alt="Old Man's Journey starting scene" caption="Hold down the A button and these downward stripes will indicate that you are now currently standing on one of the possible paths you can take. Take note of this, since it is useful to know for future puzzles." name="old-mans-journey-nintendo-switch-indie-game-geeknabe-blog-5.jpg" mdxType="Image" />
    <p>{`Guide the old man to benches or interesting landmarks, and he’ll sit down to reminisce on the past. Now this is probably one of the highlights of this game! The flashback scenes are wonderfully drawn and animated to make it feel lively. You can easily piece what's going on by piecing these flashbacks together. These scenes give us strong clues as to why he weathers the rain and storm to get to his destination. Plus, it is really lovely to look at.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Old Man's Journey reminiscing the past" caption="One of the first destination you arrive to. The old man would sit and reminisce the past as he watches the couple frolic on the roof." name="old-mans-journey-nintendo-switch-indie-game-geeknabe-blog-4.jpg" mdxType="Image" />
    <Image alt="Old Man's Journey town map" caption="One of the first towns you get to. You will need to drag the paths and hilly pathways up and down to create a path for the old man to walk on. Use the highlighted strokes as an indicator!" name="old-mans-journey-nintendo-switch-indie-game-geeknabe-blog.jpg" mdxType="Image" />
    <p>{`From a gameplay perspective, Old Man’s Journey is super barebones but they make it up with interactive elements on screen! Make sure to click onto anything that seems clickable! Moving the cursor through a tree moves it’s foliage. Clicking on windows closes it while doing it to a door or shutters would knock on them, causing the NPC to come out peeking at the troublemaker. Cables sway according to your cursor direction as you move through them. I often find myself clicking on `}<strong parentName="p">{`everything I see`}</strong>{`, just to see what will happen next.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Old Man’s Journey is essentially a puzzle game, so the game throws you a ton of blocking elements to confound your guidance. As you continue to guide the old man through towns and hilly landscapes, you’ll be presented with hills that you can drag up and down to raise it or lower it, flock of sheep that blocks your path, a simple momentum puzzle to destroy walls with wheels and even falling down from one level to another. You will need to work out these puzzles to create a path from Point A to Point B to help the old man move to the next map.`}</p>
    <Image alt="Old Man's Journey piecing the train tracks as a puzzle" caption="You need to piece the train tracks together as the train moves! I find this really cute." name="old-mans-journey-nintendo-switch-indie-game-geeknabe-blog-6.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <p>{`I was barely lost throughout the game despite the lack of voice acting and dialogues. The storytelling was done purely through wordless scenes so you’ll have to piece things together yourself. The game subtlely guides you through the areas (which happened to be a delight to look at) initially, but as you go deeper into the game, you’re left to figure things out on your own. The puzzles are not tough in general as long as you experiment and understand the puzzle rules. Though as I play, I feel really bad making the old man go up hills and down. I even made him slide down mini waterfalls! Are your kneecaps okay, grandpa?`}</p>
    <Image alt="Old Man's Journey the ending text. The end." caption="Wait, that's it?" name="old-mans-journey-nintendo-switch-indie-game-geeknabe-blog-2.jpg" mdxType="Image" />
    <p>{`All in all, the game felt short and I’m left a bit dissatisfied since I was expecting more puzzles and past scenes to look at even after I reached the ending. The story is nothing too convoluted to figure out, but the beautiful art makes up for it. This is definitely not a heavy-handed indie game, but a well-done and short indie game that you don’t mind paying a couple of bucks to try.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      