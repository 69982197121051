import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Blockquote = makeShortcode("Blockquote");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Hello fellow weeb bookworms from Malaysia. Are you depressed after finding out Bookdepository is closing down on April 26th this year? If you're like me, I'm sure you were flitting between being surprised, shocked, mad and just plain sad. Like, what gives? Are there not enough readers around the world buying their books? Is the free delivery offering too much for them to handle? Amazon own them, for crying out loud. They have plenty of moolahs to give the business going! Oh no, is it because I keep asking for refunds if the books never reach me?? AM I TO BLAME FOR THEIR DOWNFALL`}</p>
    <Ads mdxType="Ads" />
    <p>{`Bookdepository has been the best place to get my favourite English light novels because Kinokuniya couldn't be bothered to make them affordable and their stock plentiful. Bookurve do have them, if you're lucky. Opentrolley is just plain robbing us with their prices. And Shopee is full of "folks who printed them from the PDF version". When I buy light novels, I want the legit version with all the side stories, nice art on the inside and a free bookmark. With Bookdepository gone, where do we go? Fear not, I have 2 sites to introduce to you fellow readers; World of Books and Blackwell's. The best news? I have personally tried them too so this is also a blog post to tell you my experience.`}</p>
    <Blockquote mdxType="Blockquote">
  I apologize if my writing is amateurish, it's been a while since I last
  blogged.
    </Blockquote>
    <h2>{`Option 1: World of Books (wob.com)`}</h2>
    <p>{`A fantastic site that not only sells new books, but preloved ones. They throw out a ton of discount codes if you're willing to buy their preloved ones. You CAN find preloved light novels/manga in good condition if they are available. I buy Ascendance of a Bookworm and Tearmoon Empire here... If I can find them. A few things to note, first and foremost.`}</p>
    <Image alt="World of Books site" caption="They'll mention in the subtitle if its a new copy, or a preloved one before you click in to read more details." name="world-of-books.png" mdxType="Image" />
    <h3>{`Prices are in GBP`}</h3>
    <p>{`Because they are based in UK, duh.`}</p>
    <h3>{`Delivery to Malaysia costs GBP2 per book`}</h3>
    <p>{`That's right. It is not free. Each book costs GBP2 to reach your doorstep. EACH. BOOK. It doesn't matter how many books you bundle up in 1 order, they charge GBP2 per book.`}</p>
    <h3>{`You can't tell the language of the preloved book until it reaches you`}</h3>
    <p>{`I desperately wanted a copy of Made in Abyss Vol. 8 from wob. I saw that they have a preloved copy, so I bite the bullet. If I look up the title of the manga in English, surely I get an English copy, right? But nope, I got a Spanish copy. I was lucky that they willingly refunded me since RM51 per book is not exactly cheap. If you want to get a preloved copy, make sure to check the ISBN on the product listing page.`}</p>
    <h3>{`Time to arrive in Malaysia is the same as Bookdepository`}</h3>
    <p>{`I wasn't exactly tracking the days it took to reach me since I ordered, but it was approximately less than a month. I find my book in my mailbox, just like how abang posmen often deliver my Bookdepository books.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Option 2: Blackwell's (blackwells.co.uk)`}</h2>
    <p>{`Price is bloody expensive, but it's something I expected since the departure of Bookdepository. You can't have it good always after all... To surmise,`}</p>
    <Image alt="Blackwell's site" caption="I wanted to screenshot the site for the blog post but they are on maintenance. Just my luck." name="blackwells.png" mdxType="Image" />
    <h3>{`Price is in USD`}</h3>
    <p>{`The reason I was so gungho in buying the books here is because of the preorder price. I was getting books before they are out at USD13 (preorder price) instead of USD25 (after release).`}</p>
    <h3>{`Delivery to Malaysia is free`}</h3>
    <p>{`... Or is it? It's a small print but it was mentioned that delivery fee is included in the book pricing. Hah! Sneaky.`}</p>
    <h3>{`Books arrive in a parcel flyer`}</h3>
    <p>{`My book actually arrive in a parcel flyer that was wrapped twice. It went to China before arriving in Malaysia. What's nice is that Blackwell's gave me a tracking number that works. From the details, it took only 2 weeks for it to reach my mailbox.`}</p>
    <h2>{`All in all`}</h2>
    <p>{`I hope I shine light in your journey to fill up your shelves with more English light novels. If these two sites just ain't the answer... Allow me to tell you the third place you can get your book fix.`}</p>
    <p><strong parentName="p">{`SINGAPORE.`}</strong></p>
    <p>{`That's right, the island nation next door has them all. I checked Amazon Singapore, and they offer free shipping for books if you hit SGD40. And boy, they have a ton of books there. I have not tried Amazon SG (I tried Amazon US and the experience is terrible, but that's another story) so I was reluctant to mention it here.`}</p>
    <Image alt="Amazon Singapore" caption="Bukan takde, memang ada... Like that lo, expensive and in SGD. I think some copies are flown in from Amazon US anyway." name="amazon-singapore.png" mdxType="Image" />
    <p>{`If Amazon SG do not have the titles that you want listed, well, there's the fourth option. (Surprised? I got your back buddy.)`}</p>
    <p>{`Go to Kinokuniya SG and browse the books there instead. I'm not joking. A friend of mine found the copy (Eighty-Six Vol. 11) I wanted and ask if I want it. The price is RM91 in Kinokuniya SG while on Kinokuniya MY, it's listed as SOLD OUT. Ah, classic Kinokuniya MY experience.`}</p>
    <Ads mdxType="Ads" />
    <p>{`We no longer have the liberty to buy cheap English light novels with free shipping on Bookdepository anymore. This is the reality as English light novels fan in Malaysia, I'm afraid. I hope the options I listed help you get the physical copies you wanted.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      