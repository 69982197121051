import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const LocalLink = makeShortcode("LocalLink");
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`In a bid to start introducing waifus that I really love, I started this series - Waifu Highlights! The first one didn’t really take flight but that’s okay, because I love Alisa Reinford all the same. I just finished Trails of Cold Steel III, hopefully I’ll get to finish the review once I’m done with this post. (Haha, this is a lie.) And do not worry my fellow readers! Husbando will get their turn <3 But till then, it’s the ladies! THE LADIES! Woo! Our second waifu is `}<strong parentName="p">{`Misella`}</strong>{` from `}<strong parentName="p">{`Tales of Crestoria`}</strong>{`.`}</p>
    <p><em parentName="p">{`Note: I did think of renaming this blog post series to Queen Highlights instead, but I think Waifu catches everyone’s attention more. Yes, I am selling my soul in order to bait y’all to clicking on the blog post. If you want to know about my journey in appreciating waifus, please read this blog post - `}<LocalLink to="/blog/lets-talk-about-my-taste-in-waifu/" mdxType="LocalLink">{`Let’s talk about my taste in waifu`}</LocalLink>{`.`}</em></p>
    <Ads mdxType="Ads" />
    <p>{`Before I write this post, I told myself to write a `}<LocalLink to="/blog/ios-mobile-game-tales-of-crestoria-review/" mdxType="LocalLink">{`Tales of Crestoria review blog post`}</LocalLink>{` first! And since that is out, I could happily talk about my favourite heroine in the game - Misella. Phew. What’s there to love about Misella? `}<strong parentName="p">{`A lot, actually!`}</strong></p>
    <p><em parentName="p">{`Second Note: I expect folks reading this know about Misella and Kanata!`}</em></p>
    <p>{`First and foremost, kudos to the Tales team for creating an endearing character who stood for what she loved. Headstrong in her own personal policies and super steadfast, Misella is a type of character that would do what she first set out to do. Given a gacha game’s structure, it is hard to write a balanced story to get to know the characters! After all, the modus operandi of a F2P game is to lengthen your gameplay hours as much as possible. At first, I wasn’t attracted to the story at all. It’s dramatic for sure, but the dialogues fall flat. Our hero Kanata has this personality, and he enjoys doing this and that, yada yada. Like I say, `}<strong parentName="p">{`flat.`}</strong></p>
    <p>{`Even Misella’s character feels routine to me initially. The voice acting did help assuage my fears a little, bringing bits of liveliness to the story. It was when the story finally picked up the pace that I started being really `}<strong parentName="p">{`invested`}</strong>{` in it. Kanata’s father has announced that a family will be adopting Misella as their daughter. Kanata was distraught because he likes Misella. A day before she was to leave the orphanage, Kanata brought her to a blazing red flower field (the flowers had a name which I have conveniently forgotten). He picked up a bloom and put it on her. The entire scene had 2 wonderful looking CG and some of the loveliest voice acting. Kanata was squirming with embarrassment but Misella gave him the brightest of her smile.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Kanata and Misella CG" caption="Kanata putting on the flower on Misella T.T So romantic T.T" name="tales-of-crestoria-misella-and-kanata-cg.jpg" mdxType="Image" />
    <Image alt="Misella from Tales of Crestoria" caption="This is the sweetest thing ever." name="tales-of-crestoria-misella-and-kanata-cg-1.jpg" mdxType="Image" />
    <p>{`I thought little about this scene since I believed it was meant to be a romantic scene for the canon couple. Turns out it was important later on! The adoption turned out to be a lie and Kanata’s father wanted to sell Misella as a s`}{`*`}{`x slave. And the worst part? Misella knew all along. Yet, she still gave Kanata the brightest smile when he supposedly “sent her off”. That’s because she didn’t want Kanata to change from a justice-loving boy to someone like his father! It was this part of the story that really pushed Misella to the top of the character tier for me. I don’t do well against characters that do things in the name of love ):`}</p>
    <p>{`When Kanata is in danger, she actually swooped in to take the blame! The entire scene and voice acting in the game tugged at my heartstrings. You know how good Japanese dub is when they get to the screaming and pleading part. But to see the scene she takes the blame in animation format… Lord, it’s so heart-rending. Feel free to watch the animation short below to see what I mean.`}</p>
    <Video src="https://www.youtube.com/embed/GLsuACdAfeA" title="Tales of Crestoria trailer" mdxType="Video" />
    <p>{`After the whole flee-ing scene, we start to get to know Misella more. She’s blind when it comes to Kanata’s fault. Whatever Kanata says or thinks, she agrees immediately. She loves meat but is willing to forgo eating good meat if it’s for Kanata. You might be thinking, this girl’s head is full of Kanata. Now this is false. I wager the real numbers are actually 60% Kanata, 40% meat. She’s headstrong in her love for Kanata and is so willing to do anything for him. I find this part of her so endearing! Most of the time, writers would play this “endearing” part too strongly and it usually comes off as annoying instead. However, I find Misella’s personality a nice balance between adoring Kanata and being her own person.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Not to mention that her design is lovely! I honestly think it’s a wonderful throwback to the older titles where they love adding baubles and simple but recognizable patterns on the clothes. Then there’s the see-through sleeves, gloves, laces and ribbons which are common elements in a wedding dress. Well, it `}<strong parentName="p">{`is`}</strong>{` supposed to be a wedding dress as stated in the story, but the corset is a nice addition to give a sense of “adventuring clothes”. And I love the skirt with a tinge of pink on the frayed ends! The top screams wedding dress, but the bottom screams flower petals. The frayed-like sleeves and skirt tells me a lot about her personality. If she’s prim, the skirt ends would probably be neat and tidy. But Misella isn’t prim and proper, she’s bold and wild. The designer put a lot of thought into this flower wedding dress, I love it.`}</p>
    <Image alt="Misella artwork from Tales of Crestoria" caption="Here's her design for reference. I really love her dress!" name="tales-of-crestoria-misella-artwork.png" mdxType="Image" />
    <p>{`In Tales of Crestoria, the main cast get their own voiced episodes to fully fledged out their characters. Can you guess what are Misella’s character episode titles?`}</p>
    <Image alt="Misella Character Episode" caption="Meat, meat, meat. I was expecting Kanata, Kanata, Kanata..." name="tales-of-crestoria-misella-meat-episodes.jpg" mdxType="Image" />
    <p>{`See? Her own person, first and foremost. Loving Kanata to a 110% comes second :D Not to mention her deadpan replies to jokes are so much fun to read and listen to! Kanata and this girl are a match made in heaven. I’m loving this canon couple.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Please play Tales of Crestoria to see Misella in action! My personal hope is for this game to get an anime so everyone can see how wonderful Misella is <3 I added a few screenshots and official visuals from Twitter and Facebook, please enjoy!`}</p>
    <Image alt="Misella artwork from Face" caption="I'MMA ANNIHILATE YOU IN THE NAME OF LOVE" name="misella-artwork-1.jpg" mdxType="Image" />
    <Image alt="Misella naming Meakyu." caption="So they kinda got a new mascot friend, but all Misella can think of is meat. So Meakyu is this little rodent's name now." name="tales-of-crestoria-misella-meat.jpg" mdxType="Image" />
    <Image alt="Misella artwork from Facebook" caption="Her design sheet so everyone can cosplay as her. Taken from Facebook." name="tales-of-crestoria-misella-artwork-2.jpg" mdxType="Image" />
    <Image alt="Misella slaps Vicious. How vicious!" caption="I like that they bothered to draw art for the Misella-slap. She always slaps Vicious lol." name="tales-of-crestoria-misella-slaps-vicious.jpg" mdxType="Image" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      