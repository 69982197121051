import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const RemoteLink = makeShortcode("RemoteLink");
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I came across `}<RemoteLink to="https://shopee.com.my/-Ready-Stock-YQ-Star-Wars-LightSaber-Light-Saber-Replica-Games-Collection-RGB-LED-Glow-Toys-Obi-Wan-100-cm-i.415609658.16421878399?sp_atk=1bb266b4-7370-4034-a03a-9573677ac953&xptdk=1bb266b4-7370-4034-a03a-9573677ac953" mdxType="RemoteLink">{`this replica lightsaber product listing in Shopee`}</RemoteLink>{` while looking for a gift for my boyfriend. Needless to say, it was one of those rare ocassion where Shopee suggestion did well. I have never seen my boyfriend so gleeful while swinging around a light tube that makes sound effect you'll only hear in a movie.`}</p>
    <Ads mdxType="Ads" />
    <p>{`This is a blog that writes about anime, manga and games but just this time, I would like to recommend this replica lightsaber. If you were to check lightsabers from Hasbro, you'll balk at the price. No way am I going to buy that and swing it around haphazardly! So a replica will do nicely. And oh boy let me tell you how much fun (and of good value for your money) this replica is!`}</p>
    <h2>{`Replica Lightsaber Pros #1: The metallic matte hilt feels good in your hands`}</h2>
    <p>{`What got my attention from the thumbnail is the metal hilt. It looks really good from the photos, and even better in person. It has a satisfying weight to it. With the hilt surface being matte, plus the design having ridges... It looks real good for a replica.`}</p>
    <Image name="replica-lightsaber-hilt.jpg" alt="Replica lightsaber hilt" caption="It is pretty long too. About 27cm in length." mdxType="Image" />
    <h2>{`Replica Lightsaber Pros #2: Ya not gonna believe how many colours and sound effect this bad boy has`}</h2>
    <p>{`According to the booklet, there's 19 different colours for you to choose from and 10 sound effects. The sound effects will play when you:`}</p>
    <ol>
      <li parentName="ol">{`Swing it around.`}</li>
      <li parentName="ol">{`Change the lightsaber colour.`}</li>
      <li parentName="ol">{`Hit something that will trigger the lights to flicker.`}</li>
    </ol>
    <p>{`Swinging the lightsaber around to trigger the vooong vooong is super fun. When I find out it plays a fsssh sound and the light flickers when you hit the saber part, my happiness meter shot up another 30%. This is definitely made for lightsaber fights!`}</p>
    <h2>{`Replica Lightsaber Pros #3: You can buy extension so you can DUAL WIELD`}</h2>
    <p>{`Yes you read that right! This `}<RemoteLink to="https://shopee.com.my/mechpotato#product_list" mdxType="RemoteLink">{`shop`}</RemoteLink>{` sells `}<RemoteLink to="https://shopee.com.my/-Ready-Stock-YQ-Star-Wars-LightSaber-Replacement-Connector-Light-Saber-Replica-Games-RGB-LED-Glow-Toys-Obi-Wan-100-cm-i.415609658.16281639912?sp_atk=a9da80ae-76e3-4cad-b153-cb4ced7bc2c1&xptdk=a9da80ae-76e3-4cad-b153-cb4ced7bc2c1" mdxType="RemoteLink">{`connectors`}</RemoteLink>{` so you can join two replica lightsaber together and go nuts with it.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`All in all`}</h2>
    <p>{`If you think the Shopee shop is too expensive, feel free to try their `}<RemoteLink to="https://www.mechpotato.com/collections/replicas/products/-ready-stock-yq-star-wars-lightsaber-light-saber-replica-games-collection-rgb-led-glow-toys-obi-wan-100-cm" mdxType="RemoteLink">{`official website`}</RemoteLink>{`. They have the lightsaber cheaper there.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      