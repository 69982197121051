import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXFanfiction/Chapter/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Divider = makeShortcode("Divider");
const Image = makeShortcode("Image");
const MessageBox = makeShortcode("MessageBox");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`That same Thursday morning as Byleth was in the middle of class, Ashe’s social media question suddenly made her realise something grave. While she had decided to not cut her hair as she deemed it unnecessary, now it seemed otherwise. Reus had short hair and that meant someone was bound to recognize him from a school magazine or something of that sort.`}</p>
    <Ads mdxType="Ads" />
    <p>{`The real Reus called her just as she was retrieving her phone after school. She quickly grabbed it from her locker, hiding the name flashing impatiently on the screen. Despite changing the name from ‘Reus’ to ‘Byleth’, paranoia still beset her.`}</p>
    <p>{`“So, what’s it like being me? I bet you hate it already.” Byleth could hear the amusement in his voice. She had ran all the way to the greenhouse hoping it would be empty.`}</p>
    <p>{`“Well, aren’t you telepathic. You’re right. I hate it.” He laughed. It was mid-afternoon and Reus sounded like he had just woken up. “How’s the concert?”`}</p>
    <p>{`“It’s tomorrow night, but I’m leaving tonight just in case. I don’t want to miss even a second.” Byleth hummed a reply as she touched a nearby leaf, feeling its texture between her fingers. “How’s your investigation going?” He asked.`}</p>
    <p>{`“Hold on.” She poked her head out from amongst the plants and decided the coast was clear. “Not good, I’ve been trying to sneak into the Coach’s office but only club captains, dorm leaders or class reps are allowed on the teacher’s floor.” Byleth cupped her hands around her mouth and whispered. “Plus I’ve been trying to shadow the security guards to know their schedules. Based on their log book at their checkpoints, they’re not very punctual but it’s within a set timeframe.” Her eyes darted round the greenhouse, looking out for any movement.`}</p>
    <p>{`“As expected of you, By. So I assume your plan is to sneak in at the dead of night.” Reus’s voice was just as serious as hers. “Have you surveyed the area at that hour yet?”`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Of course—it’s when I take a shower.” Bath time for Byleth was an extreme sport and another reason why she wanted her hair short. Being naked and jumpy in an all boys school was a terrible combination. “They don’t have shower stalls here; it's one big open area with shower heads in the center. I told the guys I only shower at night because I needed some privacy and they think I jerk off.” Reus laughed. “Which is a good thing so they don’t barge in, at least the guys in my-I mean `}<em parentName="p">{`your`}</em>{` dorm don’t.”`}</p>
    <p>{`“They sound nice.”`}</p>
    <p>{`“They are, and I feel horrible about all this lying and sneaking around.”`}</p>
    <p>{`“Do what you must. I believe in you. I’ll even get you a souvenir from the concert.” Just then, Byleth heard some rustling. She carefully poked her head out of the bush. The greenhouse was silent but she couldn’t take any chances.`}</p>
    <p>{`“I gotta go,” Byleth whispered as she tiptoed into another corner.`}</p>
    <p>{`“Be careful. My ass is on the line.”`}</p>
    <p>{`“So is mine. Oh wait, wait!”`}</p>
    <p>{`“What?”`}</p>
    <p>{`“Why did you get into that fight anyway? Everyone keeps asking me about it and I’ve been having to keep pretending that I don’t remember.” Byleth’s voice lowered into a hush. “I wanted to ask but then you got expelled and everything happened so fast.”`}</p>
    <p>{`Reus hesitated long enough to elicit a ‘Hello?’ from her.`}</p>
    <p>{`“That guy I beat up apparently saw you that one time you came over to visit me.” He cleared his throat. “He said, ‘your sister's tits are so big I’d like to...all over it.’” Reus had omitted a couple of words but Byleth had a good idea what it was. It touched her that her brother had risked his education for her dignity. “So...you bit a guy because he was talking about my boobs? Wait, you `}<em parentName="p">{`did`}</em>{` bite him, right?”`}</p>
    <p>{`“That, I did.” Reus sounded quite proud. Byleth was tucking a lock of hair behind her ear when she remembered her now urgent mission.`}</p>
    <p>{`“Could you send me a selfie right now? I think… I may need to cut my hair.” Saying it outloud to someone else made the decision feel real.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Byleth, are you sure?” She was very reluctant to cut her hair, but the stress was too much. It was hair and it would grow back, besides it wasn’t completely short. If she remembered correctly, Reus himself had a mop of thick messy hair that went past his ears.`}</p>
    <p>{`“I don’t think I have a choice at this point. I’m already garnering suspicion as it is.” Byleth resigned and accepted her fate. “Anyway, I have to go, for real now. Send me pictures of the concert—on second thought, don’t. Just in case someone asks.”`}</p>
    <p>{`They both said their goodbyes and hung up. Three seconds later her phone buzzed, and in a few taps she was looking at her twin. She let out a small laugh, she was right; he had just woken up.`}</p>
    <p>{`Her next challenge was finding a barber. Too near the school and they’d recognize her. Too far and she wouldn’t be able to make it back before the school gates closed. She glanced down at her digital watch, club was about to start and she wasn’t even changed. Byleth sprinted back, flew up the stairs and when she burst into the dorm, she crashed into Dimitri standing in `}<em parentName="p">{`just`}</em>{` his briefs. He barely budged as Byleth stumbled back, her hand instinctively holding her head where it made contact with Dimitri’s solid chest.`}</p>
    <p>{`“Sorry,” she muttered under her breath.`}</p>
    <p>{`“Please, it’s my fault for standing at the door.” Dimitri attempted to reach out but Byleth waved his hand away. “I’m alright. Your bed is by the door anyway.” She kept her gaze fixed on the floor while shuffling to her assigned closet, and grabbed her training bag . As she was about to exit something dropped out of her bag. Byleth turned around to pick it up and to her horror, it was her spare chest bind rolling towards Ashe’s feet, who picked it up.`}</p>
    <p>{`“Are you injured?” Ashe frowned at her with worry. His shirt was unbuttoned, exposing just a glimpse of his chest. Byleth snorted. Ashe would need to put in extra effort to come close to Dimitri’s— wait, what?`}</p>
    <p>{`Byleth blinked at him. “I-injured?” she looked down when Ashe handed it back to her. She felt like kicking herself. She had been using a bandage to wrap her chest and sweet, innocent Ashe thought she was hurt.`}</p>
    <p>{`She shook her head, “It’s… just in case I need it.” Ashe nodded furiously. “I have a mini first aid kit in my bag too!” He beamed at her and she couldn’t help but smile back.`}</p>
    <p>{`“Sorry, I’m gonna go change, then meet you guys at the training hall.”`}</p>
    <p>{`“Oh Reus, the changing room is full. Almost all the clubs have activities today.” Dimitri was slipping on a pair of gym shorts. She averted her eyes from his chiseled chest. `}<em parentName="p">{`Goddess, help.`}</em>{` She swore it was as if Dimitri and all the other boys here were purposely getting naked just to rile her up.`}</p>
    <p>{`“I’ll just change in the bathroom then,” Byleth said nonchalantly. She was about to open the door when Sylvain walked in.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Oh hi Reus.” He tapped her on the arm, then turned to the others. “Lemme tell you guys—” He threw his book bag on his bed and shrugged off his blazer. “The changing room is still so goddamn full on Thursdays. Why does the chess club even `}<em parentName="p">{`need`}</em>{` to change?” Sylvain began undressing himself. He started ranting and Dimitri explained in earnest, saying they probably wanted to wear their club T-shirt as a sign of camaraderie. Before Byleth could set eyes on another nude male and bleed her nose, she slipped out of the room.`}</p>
    <p>{`Byleth’s anxiety peaked during training. With Coach Kostas being his usual toxic self, it was painful to watch him treat Dimitri like dirt and insulting students who didn’t perform well.  It came to the point where a handful of new and old members had already quit. So far, Coach hadn’t suspected anything and had kept his attention solely on tormenting Dimitri. She was determined to quickly get to the bottom of this mess in hopes of helping somehow.`}</p>
    <p>{`It was a little past 11.30pm and her dorm was dead asleep. Byleth was almost lulled to sleep by the gentle snores from her dorm mates when she forced her eyes open. She gave herself a good second to adjust to the darkness as she had done every night for close to a week, tumbled out of bed and staggered down the dimly lit corridor.`}</p>
    <p>{`She intuitively felt around the shower room wall for the light switch, and played around until she found the exact corner lit up. The shower room layout was the same as the club one and didn’t have a lock but Byleth had made a make-shift alarm at the first door consisting of a few shampoo bottles she found laying around.`}</p>
    <p>{`Her vigilance was at its peak at this hour but this was also her favourite time of the day when she could unwrap herself. She looked at herself in the mirror, observing the bruises caused by the tightness of her chest bandage. Byleth had gotten used to this sight by now, and she didn’t have time to admire it; she had to cut her hair `}<em parentName="p">{`and`}</em>{` shower.`}</p>
    <p><em parentName="p">{`Well I’m not going to be a hairdresser that’s for sure.`}</em>{` It was messy at best but she wasn’t going to risk any more time being topless. She’d have to live with it and get it sorted out when all this was over.`}</p>
    <p>{`It wasn’t much but it made a world of difference in terms of washing and drying her hair. She walked as fast as she quietly could back to her dorm and drifted off to sleep as soon as her damp head hit the pillow.`}</p>
    <Divider mdxType="Divider" />
    <p>{`Dimitri typically rose early and was the dorm’s human alarm clock, as was every other dorm leader. Claude, for example, preferred to blast an annoying song every morning on his bluetooth speaker to wake his mates up, which the entire dorm could hear, incidentally. Hubert, the other dorm leader would stand at the center of the room and clapped a maximum of 5 times to wake them up. But Dimitri was a gentle alarm clock, and would turn on the lights, personally go to each of them and give them a gentle shake.`}</p>
    <p>{`Some of them took several rounds of shaking, and by some he meant Sylvain. This morning when he went over to wake Reus, his heart stopped for a second as he did not recognize the short haired figure sprawled out on Reus’s bed, arm slung over their eyes to block the light.`}</p>
    <p>{`“Why the hell are you hovering over Reus like that?” Felix groaned from his bed as he sat up, eyes squinting at the morning sunlight.`}</p>
    <p>{`“G-good morning, Felix! I was merely assessing who this person is. I’m quite certain Reus has longer hair.”`}</p>
    <Ads mdxType="Ads" />
    <p>{`The person he stared at stirred. Familiar mint eyes stared back at him. “D-dimitri?”`}</p>
    <p>{`“Reus! My apologies but... did you cut your hair?” Reus peered at him through sleepy eyes and stretched as he mumbled, “Yes, last night.”`}</p>
    <p>{`Reus yawned, rubbing the back of his head. He asked if it looked bad. Dimitri shook his head. “Not at all! It could use a bit of clean up, though. May I recommend the place I normally go to? They charge reasonable rates for students.”`}</p>
    <p>{`“Um, It’s fine. I’ll live with it,” Reus croaked. Dimitri watched as Reus slid off this bed, grabbed his clothes and shuffled out the room to change as per his daily routine. He still had not asked about Reus’s sibling, even though he had prepared material to show Reus that he knew his sister.`}</p>
    <p>{`Their day continued like it did for the past five days, today being Friday. Students were allowed to leave the school during weekends as long as they were back by 7pm for dinner. Most of them visited the local town of Garreg Mach, at the base of the mountain their school was on top of.`}</p>
    <p>{`Strange as it might seem, each time Dimitri tried bringing up the whole sibling thing with Reus, someone else would always be speaking to him. It was as though a Divine power prevented Dimitri from approaching the boy.`}</p>
    <p>{`Clutching the newspaper cutting he had of the tournament like a child holding a newly finished drawing, he slid off his bed. `}<em parentName="p">{`Right, I’m going to ask him now-`}</em></p>
    <p>{`“Reus.” Dedue approached the boy just as Dimitri was about to. “I am quite confident in my skills in trimming flowers and other delicate things. If you’d like, I could help correct your hair.”`}</p>
    <p>{`“Oh, Dedue `}<em parentName="p">{`thank you`}</em>{`.” Reus’ eyes lit up. “Where do you propose we do this? Should I get my towel?”`}</p>
    <p>{`“Yes, that would be wise. I’d suggest we head to the shower room to facilitate easier clean up.” Dedue waited as Reus grabbed his towel and they both headed out the room.`}</p>
    <p>{`Dimitri groaned.`}</p>
    <Divider mdxType="Divider" />
    <p>{`Byleth had accepted Dedue’s offer so fast it totally slipped her mind as to where they were doing the haircut. As it was after school, the shower room was unfortunately bursting full with boys at different stages of nudity. She kept her eyes absolutely fixed on the floor, so focused on counting the number of tiles that she bumped into Dedue when he stopped. “Sorry,” she murmured.`}</p>
    <p>{`“Please stand here.” Dedue gestured at a specific spot as he whipped out a pair of scissors and adjusted the towel she brought on her shoulders. “Do you have a preferred hair style?”`}</p>
    <p>{`“Not too short, I guess? Just balance it out, please.” Byleth heard the door swing open as she kept her eyes shut.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Every second felt too long and was a torment. She heard nothing save for water falling on the tiles and her own pulse, praying to the Goddess Sothis to keep her eyes protected. She settled on focusing on her heartbeat and thankfully, before she knew it, Dedue was done.`}</p>
    <p>{`“It is finished,” he concluded. “Thank you for cooperating. Please have a look and I’d appreciate any feedback and complaints.”`}</p>
    <p>{`Byleth leaped back when she saw her reflection in the mirror. She was nearly identical to Reus now. “You should be a celebrity stylist,” she commented, adjusting her hair parting. Dedue smiled and nodded at her compliment.`}</p>
    <p>{`“Thank you.”`}</p>
    <Image alt="Byleth with Reus's hair style" caption="All the credit goes to the <a href='https://twitter.com/illusidy' target='_blank' rel='nooopener noreferrer'>@illusidy</a> on twitter for making this edit!" name="byleth-with-reus-hair-geeknabe-fanfiction.jpg" mdxType="Image" />
    <Image alt="Byleth and Reus's hair style side by side comparison" name="byleth-reus-side-by-side-geeknabe-fanfiction.jpg" caption="I added this image to help you visualise it better ^_^ I know they have post-timeskip mint hair in this fic but this is just as good!" mdxType="Image" />
    <p>{`Remembering where she was, Byleth thanked Dedue again and quickly excused
herself. When she got back to the room, she was greeted with compliments from
her dorm mates and even Claude, and spent the rest of her free time researching
lock picking on her phone until the dinner bell rang.{' '}`}</p>
    <p>{`“I’m going to turn in now.”  Byleth stretched when the group had finished their first round of board games. They all groaned and tried to persuade her to stay but she was unswayed.`}</p>
    <p>{`Tonight was the night. She was going to break into Coach Kostas’s office and begin her actual investigation. Most of the teachers had either gone back to their real homes for the weekend or were out. She had surveyed and memorised the security guards movements for close to five days now, and had her modified lock picking kit ready; made of bent bobby pins and a torch light she brought from home.`}</p>
    <p>{`She did actually fall asleep until she heard the door open. Their voices were loud but she heard Ashe go, “Shh, he’s sleeping.” From then on though, her eyes were wide open under her blanket as she reviewed the security guard’s schedule in her head. Byleth had to be at the office by 1:25 am the earliest and gone by 2:15 am before the guards rounded that area again.`}</p>
    <Ads mdxType="Ads" />
    <p>{`When it was time, Byleth quietly slipped out the dorm. Even if her dorm mates had heard her go out, she hoped they’d assume she was just taking a shower.`}</p>
    <p>{`The cool breeze felt good on her skin and her head was lighter thanks to her new haircut. It was a shame she couldn’t enjoy such a refreshing night out. She pulled out a makeshift map she had drawn with notes and times scribbled all over, lifting up so that the moonlight shone through it. Byleth was right, from the wall she was sticking to, the security guard had just finished his round at the teachers’ floor. She crept in the dark until she came to Coach Kostas’ room.`}</p>
    <p>{`Byleth took a deep breath as she stood at the door. “Right, there’s no turning back now,” she whispered to herself as she held the torch light between her teeth and got to work. It was taking her longer than she wanted to with constantly having to refer to her phone balanced on her knee. And the drool dripping from the corner of her mouth didn’t help at all.`}</p>
    <p>{`Suddenly footsteps echoed in the distance coming her way.  In a panic she fumbled to turn off her torch light and dropped one of her bobby pins. `}<em parentName="p">{`Shit`}</em>{`. She was about to scamper into a corner when—`}</p>
    <p>{`“Reus!” Crap, it was Dimitri. What was he `}<em parentName="p">{`doing`}</em>{` here?`}</p>
    <p>{`“There you are! You weren’t in the shower room and I was worried you had gotten lost.” This over-protectiveness of his was beginning to annoy her. “I must speak with you.” She let out a long breath through her nose. “`}<em parentName="p">{`Now`}</em>{`?” She turned on her torch light and walked past him to look for the bobby pin she dropped. “I’m kind of in the middle of something right now.”`}</p>
    <p>{`“It won’t take a minute I promise,” he said, unfolding the newspaper cutting he brought along with him. “Please have a look at this.” Dimitri pushed the crinkly paper in her face. “Please tell me if this is your sister?” He pointed at the small figure standing in the group picture. He was seemingly oblivious to her crime. Her adrenaline levels were so high she was unfazed even as she stared back at her own face.`}</p>
    <p>{`”You came all this way, looking for me in the middle of the night, to ask me `}<em parentName="p">{`this?`}</em>{`” Byleth pursed her lips.`}</p>
    <p>{`“Everyone kept getting in my way whenever I wanted to ask and—” She silenced him with a raised hand, and said yes. Dimitri did a small fist pump. “I knew your name was familiar! You are the spitting image of your sister. Can I safely assume you are twins?”`}</p>
    <p>{`“Yes.” She kept searching for her pin until she found it… It was already 1:35am and it appeared Dimitri was not going to leave her alone, as nice as that sounds, but she had a mission to accomplish and precious time was ticking.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“I was unable to sleep as I had been waiting to ask you this the whole day,” Dimitri said as a matter of factly, carefully folding his newspaper cutting back into his cardigan pocket.`}</p>
    <p>{`Byleth nodded absentmindedly as she stuck the bobby pin back into the keyhole.`}</p>
    <p>{`There was a pause before Dimitri spoke up again.`}</p>
    <p>{`“Um, Reus? May I also ask why you’re picking the lock to the coach's office?” She groaned. Dimitri had finally realised. “Dimitri, you’re going to have to `}<em parentName="p">{`swear`}</em>{` you saw nothing tonight. Okay? It’s a long story and I don’t have time to explain but I can assure you that I’m not stealing anything valuable,” she said without looking at him. `}<em parentName="p">{`Click.`}</em>{` “Finally!” Byleth hissed in victory as she stood up, her legs feeling a little cramped.`}</p>
    <p>{`“This isn’t right! I must stop you— ” He grabbed her wrist but she forced her way in anyway, dragging him in with her.`}</p>
    <p>{`“Dimitri,” she said sternly. “If you want to stop me, do it. But know that if you do, there won’t be anyone who will bring Coach Kostas to justice.” She locked the door behind them.`}</p>
    <p>{`“Reus, what are you talking about?” Dimitri’s eyes furrowed. She ignored him and fiddled around the ancient desktop on the floor for the switch. She smirked when the computer whirred to life.`}</p>
    <p>{`Byleth was hoping that Coach Kostas was a typical old man that didn’t log out of anything and left a digital trail. To her delight, he even left his Facebook logged in. She was going through every single conversation he had, almost forgetting Dimitri was standing behind her.`}</p>
    <p>{`“Reus— ”`}</p>
    <p>{`“Shh. Dimitri please— ”`}</p>
    <p>{`“`}<em parentName="p">{`Reus`}</em>{`.” Dimitri’s voice was stern now. He put his finger on his lips and nodded at the door. Footsteps. She froze. `}<em parentName="p">{`No, no. The security guard isn’t supposed to be here for another 30 minutes!`}</em>{` Hearing the nearing footsteps she quickly closed the browser and forced the computer to shut down by holding the power button down.`}</p>
    <p>{`She stood up, scanning the room for a place to hide but was yanked into a small, dark and cluttered space. Dimitri had pulled her into the weapon’s cabinet behind the coach’s desk and somehow managed to close it without causing too much of a ruckus.`}</p>
    <p>{`A second later when they heard the handle rattle; it was Coach Kostas himself cursing the lock for being faulty when he came in and kicked the door shut. Keeping her breathing as steady and quiet as possible, she peered through the slits in the metal door and squinted in the dark. Until she realised the warmth and weight on her chest.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Dimitri’s face was pressed against her. Now she wasn’t sure if her raging heartbeat was due to adrenaline or that a man had his face on her flattened bossom. She prayed hard that he wouldn’t wonder about its softness. Byleth was sort of sitting with her back against the wall of the cabinet, while Dimitri was in between her legs and his arms on either side of her head. He couldn’t sit up either as there was some gear hanging precariously over his head.`}</p>
    <p>{`They held their breaths inside their hiding spot, and could hear Coach’s mumbling. He didn’t reveal much despite talking to himself, slurring a little. He sounded drunk. “I’ll never get tired of looking at all this cash. Ah—those stupid kids will never see this money. They don’t need it anyway. Rich brats. Heh, I’ll just say the university revoked it. Or, maybe gave it to another team. Dumb kids.” At this point her eyes had adjusted to the darkness and she looked down into Dimitri’s eyes. He looked back at her in alarm. Coach was still browsing and clicking away when the security guard came over to chat with him. “Here again? Man you really work hard,” the security guard said. “I’ll work whenever I want so buzz off,” Coach yelled.`}</p>
    <p>{`Byleth wasn’t sure how much time had passed but it felt like forever since Coach left. They had waited for a while to really make sure the coast was clear.`}</p>
    <p>{`Dimitri was the one to initiate their escape, pushing the door to the cabinet open. He promptly removed himself off her, offering her a hand, and she grabbed it and tumbled out awkwardly, her joints and limbs stiff as hell.`}</p>
    <p>{`“It’s almost 3am,” Dimitri said, looking down at the computer monitor. “Did you know what’s happening? Do you know if coach's words were true?” He turned to her, his eyes serious.`}</p>
    <p>{`Byleth turned the computer on again. “We’re about to find out.”`}</p>
    <p>{`“Reus,” Dimitri’s voice was stern. “We’re alone now. Please tell me.” Byleth sighed, she might as well since the computer was taking some time to boot up.`}</p>
    <p>{`“My sister Byleth,” she said carefully, nodding at the newspaper cutting in his breast pocket. “She was your opponent at that tournament.” He dipped his eyebrows. “Yes.”`}</p>
    <p>{`“She told me that she saw something go down before your scores were finalized. An interaction of sorts between Coach Kostas and the referee.” Byleth clicked on the browser and traced back the last pages he opened. “I’m here to investigate that. Me getting expelled turned out to be a blessing in disguise. Do you mind being on the lookout for a couple of minutes? I’m going to take some pictures.” He nodded, appearing to be in deep thought. She was disappointed that the banking page had been logged out automatically but she spent the next fifteen minutes reading his email and Facebook messages. He was very sloppy. Not even bothering to delete the evidence. Too easy. Hopefully, things would get easier from here on.`}</p>
    <p>{`“Okay, let’s go.” Byleth gave the place one last look before leaving and patted down her pockets to make sure all her stuff was with her. She pressed the lock from the inside and shut the door, giving it a few pulls to ensure it was locked.`}</p>
    <p>{`They sneaked back into their building with relative ease as Byleth knew the security guard’s schedule by heart. She was about to head into their dorm when she remembered she hadn’t taken a shower yet.`}</p>
    <p>{`“Sorry, I’ll just take a quick shower— ”`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Will you let me join your investigation?” Dimitri said quietly, his hand on the door handle. “I knew something was wrong that day, but I didn’t have the courage to act on it. I-I thought I was imagining things and to be honest, I was happy my team got the scholarship. However now…” Dimitri’s hand slipped from the door handle. “It’s not right. We didn’t win this fairly. Please, let me lend you my strength. I’ll take full responsibility if anything happens.”`}</p>
    <p>{`“What about Ashe, Caspar and Raphael?” Byleth felt guilty all of a sudden. As much as her team were the rightful winners, to pull the rug so abruptly from under these boys didn’t sit  right with her either.`}</p>
    <p>{`Dimitri buried his face in his palms. “I... don’t know. I guess we'll have to take it to the authorities and see how it goes from there.” He sighed.`}</p>
    <p>{`She smiled at him. “Thank you. I have to get my towel and It’s late, we’ll start our planning tomorrow.”`}</p>
    <p>{`Byleth was unexpectedly more exhausted than she expected as the water gushed down her weary body. She decided to skip washing her hair and dragged herself back to bed, but not before sending the pictures to Reus, Dimitri and storing it on her Google Drive as backup. Password protected of course. Dimitri was already fast asleep when his phone buzzed on his nightstand, indicating the pictures had been received.`}</p>
    <p>{`This was the first time in days that she got to sleep in. But the rustling and background noises as her dorm mates stirred woke Byleth up from a restless slumber at 11am. She had been dreaming about the night before. She pushed herself up and reached for her phone, throwing a glance at Dimitri’s bed, it was empty. She checked her messages as usual and tapped on a group chat, ‘the Eisners’. It consisted of her, Reus and her father.`}</p>
    <p>{`She noticed her dad had sent the group a message at about 8am.`}</p>
    <MessageBox totalUnread={0} contactNumber="the Eisners" items={[{
      timestamp: {
        day: 'Monday',
        time: '08:01AM'
      },
      name: 'Dad',
      left: '<p>he y reus hows ur new schol</p>'
    }, {
      left: '<p>schol</p>'
    }, {
      name: 'Dad',
      left: '<p>School</p>'
    }, {
      name: 'Dad',
      left: '<p>anyway had sum time off,</p>'
    }, {
      name: 'Dad',
      left: '<p>comin to see you this sunday for lunch. styaing in hotel</p>'
    }, {
      name: 'Dad',
      left: '<p>Wud lik to meet ur new principal on mon as well</p>'
    }]} mdxType="MessageBox" />
    <p>{`Byleth’s eyes grew wide in panic as she flew out of her bed, all forms of weariness gone. She threw on a pair of slippers while she quickly dialed ‘Byleth’.`}</p>
    <p>{`“Come on pick up, pick up,” she muttered as she jogged to the greenhouse and into the furthermost corner.`}</p>
    <p><em parentName="p">{`‘The number you have dialed cannot be reached at the moment please try again later, thank you.’`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      