import { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { MDXProvider } from '@mdx-js/react';
import { BlogPostJsonLd } from 'gatsby-plugin-next-seo';

import { useFirebase, stats } from '../../../utils/firebase';
import Banner from './Banner';
import { roundUp, importOneImage } from '../../../utils';
import Seo from '../../Seo';
import CommentSection from '../../DisqusComments/CommentSection';
import {
  RemoteLink,
  AuthorNote,
  CustomFont,
  Ads,
  Image,
  MessageBox,
  StyledDivider,
} from './Shortcodes';
import authors from '../../authors.json';
import fanfictions from '../items.json';
import Content from './Content';
import Navigation from './Navigation';

const Layout = ({ children, pageContext, location, path }) => {
  const {
    frontmatter: {
      title,
      index,
      summary,
      description,
      banner,
      published,
      author,
      slug,
    },
    prev,
    next,
  } = pageContext;
  const firebase = useFirebase();
  const [pageViews, setPageViews] = useState(0);
  const [hearts, setHearts] = useState({ shown: '-', realTotal: 0 });
  const { media, pairings, genre, tags } = fanfictions.filter(
    ({ slug: indexSlug }) => indexSlug === slug
  )[0];

  useEffect(() => {
    if (firebase && typeof window !== 'undefined') {
      stats()
        .hearts.get()
        .then((value) => {
          setHearts({
            shown: value > 0 ? roundUp(value) : 0,
            realTotal: value,
          });
        });

      stats()
        .pageViews.get()
        .then((value) => {
          setPageViews(value > 0 ? roundUp(value) : 0);
        });
    }
  }, [firebase]);

  return (
    <MDXProvider
      components={{
        LocalLink: Link,
        RemoteLink,
        AuthorNote,
        MessageBox,
        CustomFont,
        Divider: StyledDivider,
        Image,
        Ads,
      }}
    >
      <Seo
        title={`${index} [${title}] | Geeknabe - ACG Blog`}
        description={`${media} fanfiction, ${title} : ${description}`}
        location={location}
        image={`https://geeknabe.com${importOneImage(banner)}`}
        keywords={[
          `${media} fanfiction ${pairings.join(' ')}`,
          `${media} ${genre.join(' ')} fanfic`,
        ]}
        meta={[
          {
            property: 'twitter:card',
            content: 'summary_large_image',
          },
        ]}
      />
      <BlogPostJsonLd
        url={`https://geeknabe.com${location.pathname}`}
        title={`${index} [${title}] | Geeknabe - ACG Blog`}
        datePublished={published}
        authorName={authors[author].name}
        publisherName="Geeknabe"
        images={[importOneImage(banner)]}
        publisherLogo="https://geeknabe.com/static/logo-e6e50c9b64aa402b446a3cd0203f163a.png"
        description={description}
      />
      <Banner
        summary={summary}
        author={authors[author]}
        tags={[
          '<a href="/fanfiction/">Fanfiction</a>',
          `<a href="/fanfiction/${slug}/">${index}</a>`,
          ...tags,
        ]}
        published={published}
        title={title}
        slug={path}
        hearts={hearts.shown}
        index={index}
        pageViews={pageViews}
      />
      <Content>{children}</Content>
      <Navigation
        hearts={hearts}
        setHearts={setHearts}
        next={next}
        prev={prev}
        titleSlug={`/fanfiction/${slug}/`}
      />
      <CommentSection slug={path} title={`${index} ${title}`} />
    </MDXProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element])
    .isRequired,
  location: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  path: PropTypes.string.isRequired,
  pageContext: PropTypes.shape({
    frontmatter: PropTypes.shape({
      title: PropTypes.string,
      tags: PropTypes.arrayOf(PropTypes.string),
      index: PropTypes.string,
      published: PropTypes.string,
      summary: PropTypes.string,
      banner: PropTypes.string,
      author: PropTypes.string,
      slug: PropTypes.string,
      description: PropTypes.string,
    }),
    prev: PropTypes.string,
    next: PropTypes.string,
  }).isRequired,
};

export default Layout;
