import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const RemoteLink = makeShortcode("RemoteLink");
const Image = makeShortcode("Image");
const LocalLink = makeShortcode("LocalLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`WEBTOON is a great platform to discover original content by newbie artists! Not only are the comics coloured, but the long scrollable format also gives a ton of freedom to the artist to draw `}<em parentName="p">{`however they want`}</em>{`. They have some really great music that you can play along with the chapter too! Music? In mah comic? Get outtt.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Chapters are wholly free. Just recently, they added a `}<em parentName="p">{`pay-to-view`}</em>{` feature where you can pay to access the latest chapters. I honestly think it’s a great move since it goes back to the artist and motivates them. Plus, the chapters are gradually made `}<em parentName="p">{`free-to-view`}</em>{` 1 chapter per week so you can wait for the chapters to turn into free ones if you don’t want to pay. In the end, everyone wins. I honestly find the mobile app to be user-friendly as well. There's nothing to dislike about `}<RemoteLink to="https://www.webtoons.com/" mdxType="RemoteLink">{`WEBTOON `}</RemoteLink>{`in general.`}</p>
    <p>{`I’ve been reading WEBTOON for years now, and here are some completed series that I think are pretty good. These are not ordered, plus it’s a mix of romance, fantasy, comedy, drama, and tragedy so I hope you guys like it!`}<em parentName="p">{`Note: All images are screencapped from`}<RemoteLink to="https://www.webtoons.com/en/" mdxType="RemoteLink">{` WEBTOON.`}</RemoteLink></em></p>
    <h2>{`Completed WEBTOONS Recommendation #1: Winter Woods`}</h2>
    <p>{`An alchemist once lost a family. In his grief, he tried creating a new son of his own through alchemy. He pieced body parts belonging to corpses together in hopes of making a new son. He ultimately succeeded, but the boy didn’t have a heart... `}<em parentName="p">{`Or did he? `}</em></p>
    <Image alt="Winter Woods banner screencapped from Webtoon" caption="From the left, Jane and Winter." name="winter_woods_webtoons_recommendation.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <p>{`The poor boy held onto his father’s hand for years until he is finally discovered by folks from modern times. You see, he’s an oddity. He’s alive yet he is not. He’s what we call a literal`}<em parentName="p">{` living corpse`}</em>{`, and scientists want to know what makes him tick. So they started an experiment. They made him live together with an inspiring writer named Jane Reina in hopes that it may spark `}<strong parentName="p">{`something`}</strong>{` in him.`}</p>
    <p>{`I really enjoyed the character development in `}<strong parentName="p">{`Winter Woods webtoon.`}</strong>{` While it’s a story about MC’s coming-of-age and discovering love, it’s the same for Jane. Winter didn't know a lick about feelings, so watching him reacting to new things is really endearing. Once things start to make sense for him, his thoughts and reaction gets a little painful to watch. `}<RemoteLink to="https://www.webtoons.com/en/romance/winter-woods/list?title_no=344" mdxType="RemoteLink">{`Read Winter Woods here.`}</RemoteLink></p>
    <h2>{`Completed WEBTOONS Recommendation #2: Annarasumanara`}</h2>
    <p>{`Buckle up, because this is as `}<em parentName="p">{`magical`}</em>{` a trip as it can get. Yun Ai is a really, really poor high school student. She’s poor enough that she drinks water for lunch and continue to use her old stockings despite it being full of holes. Her life is miserable and her dad’s a terrible person, but she tries her damn hardest to get by. Her life changed when she met a magician in an amusement park but he’s… a bit of a lunatic.`}</p>
    <Image alt="Annarasumanara banner screencapped from Webtoon" caption="Annarasumanara banner in Webtoon." name="annarasumanara-webtoons-recommendation.jpg" mdxType="Image" />
    <p>{`He strongly believes the existence of magic and would do anything to make it `}<strong parentName="p">{`look`}</strong>{` `}<strong parentName="p">{`real`}</strong>{`. The magician is crazy, but he has a good heart. Bit by bit, her encounters with the magician make her feel life is worth living if you just look at the magic of things. Maybe adults aren’t that bad after all?`}</p>
    <p>{`I won’t spoil you any further because you really need to read this webtoon to appreciate the message and the art. It’s subtle but it’s definitely there. A ton of bloggers have already reviewed it and the overall feedback is that `}<strong parentName="p">{`Annarasumanara`}</strong>{` is really, really good. Read this `}<RemoteLink to="http://www.foxymanga.com/webtoon-review-annarasumanara/" mdxType="RemoteLink">{`article`}</RemoteLink>{` once you're done with the series to fully appreciate the symbolism. `}<RemoteLink to="https://www.webtoons.com/en/drama/annarasumanara/list?title_no=77" mdxType="RemoteLink">{`Read Annarasumanara here.`}</RemoteLink></p>
    <Ads mdxType="Ads" />
    <h2>{`Completed WEBTOONS Recommendation #3: Refund High School`}</h2>
    <p>{`This series is adorable and I won’t let you change my mind. Aru Kim is a 19-year-old high schooler that has never experienced love before. Her aspirations are simple - go to university (?) and get a boyfriend. Unfortunately, she has to put that plan in her backlog because she got hit by a car and died immediately.`}</p>
    <p>{`Things happened, and uh… She’s now going through high school again to earn the ticket for an assured reincarnation into whatever she wants. Of course, there's a catch. First, her memories get wiped clean and it is up to her to earn back her memories with good grades. Second, her schoolmates include humans and monsters! Also, there are 3 other students that died along with Aru. Aru doesn't recognise them but everything links back to that same fateful day they died…`}</p>
    <Image alt="Refund High School banner screencapped from Webtoon" caption="From the left, Aru and Mook" name="refund-high-school-webtoon-recommendation.jpg" mdxType="Image" />
    <p>{`The plot is nothing catchy, but it did the theme of friendship and forgiveness really well. The monsters and expressions are really colourful so it’s enjoyable to read after a hectic day. It’s a great series if you enjoy reading about friendship amongst high schoolers. `}<RemoteLink to="https://www.webtoons.com/en/drama/refundhighschool/list?title_no=1360" mdxType="RemoteLink">{`Read Refund High School here.`}</RemoteLink></p>
    <Ads mdxType="Ads" />
    <h2>{`Completed WEBTOONS Recommendation #4: Carl`}</h2>
    <p>{`Any comic that has A.I. and robots as their theme has never failed to catch my eye. `}<strong parentName="p">{`Carl`}</strong>{` is one of these. Carl is a robot psychiatrist. Basically, he listens to humans complaining about their worries and gives them solutions. As he keeps listening to our worries, soon Carl has his own worry. He starts feeling things like fear and anger. He starts feeling… like a human! This is a problem because it’s an unprecedented event. He has to hide this or risk being demolished.`}</p>
    <Image alt="Carl banner screencapped from Webtoon" caption="Carl giving advice, like the good robot he is." name="carl-webtoon-recommendation.jpg" mdxType="Image" />
    <p>{`Okay, so I started reading this because the summary seems really interesting, but I stayed for Carl’s expression.`}</p>
    <p>{`The plot is not serious, just cute and wholesome. There's a bit conflict at the end but all ends well. `}<RemoteLink to="https://www.webtoons.com/en/comedy/carl/list?title_no=1216" mdxType="RemoteLink">{`Read Carl here.`}</RemoteLink></p>
    <Ads mdxType="Ads" />
    <h2>{`Completed WEBTOONS Recommendation #5: Oh! Holy`}</h2>
    <p>{`Jamie Oh can see ghosts. He’s timid and soft-spoken, but he is kind. Holy Joo is crazily in love with Jamie Oh, and she’s the opposite of Jamie.`}</p>
    <Image alt="Oh! Holy banner screencapped from Webtoon" caption="From the left, Jamie and Holy." name="oh-holy-webtoon-recommendation.jpg" mdxType="Image" />
    <p>{`She’s strong and good in everything she does in school (except cooking) but unfortunately, says some crazy stuff. Here’s an example:`}</p>
    <Image alt="Oh! Holy one of the panels in the webtoon." caption="Pretty please?" name="oh-holy-webtoon-panel.jpg" mdxType="Image" />
    <p>{`She’s not afraid to cuss, and a bit of a tsun-tsun towards Jamie. Unfortunately, her antics got her into a coma one day. Feeling guilty, Jamie set off to contact a reaper to save her. The plot sounds serious but it’s a good ole comedy to the bone. You’ll enjoy the series a lot if you enjoy crazy dialogues that’ll make you cackle out loud. `}<RemoteLink to="https://www.webtoons.com/en/romance/oh-holy/list?title_no=809&page=1" mdxType="RemoteLink">{`Read Oh! Holy here. `}</RemoteLink></p>
    <Ads mdxType="Ads" />
    <h2>{`Completed WEBTOONS Recommendation #6: Super Secret`}</h2>
    <p>{`Emma is a college student who lives next door to a family of mythical beings. Since any human that found out about their true identities need to have their memories wiped, they had to act normal and blend in with society. There are many things Emma didn’t know, including the fact that her neighbour’s son plus a childhood friend of hers is all of these things - a werewolf, is guilty of her clumsiness and in love with her.`}</p>
    <Image alt="Super Secret banner screencapped from Webtoon" caption="From the left, Emma and Ryan. The doggo is obviously Ryan by the way." name="super-secret-webtoon-recommendation.jpg" mdxType="Image" />
    <p>{`This author draws some amazingly nice-looking four panel comic. The choice of soft colours really makes the whole comic feel `}<em parentName="p">{`cutesy`}</em>{`. It’s a `}<strong parentName="p">{`good romance four panel comic`}</strong>{` in general. `}<RemoteLink to="https://www.webtoons.com/en/romance/super-secret/list?title_no=666" mdxType="RemoteLink">{`Read Super Secret here.`}</RemoteLink></p>
    <Ads mdxType="Ads" />
    <h2>{`Completed WEBTOONS Recommendation #7: Nano List`}</h2>
    <p>{`Do you like girls as `}<em parentName="p">{`androids`}</em>{`? Well, of course you do. They look good and they punch well too.`}</p>
    <Image alt="Nano List banner screencapped from Webtoon" caption="From the left, Nano, San and Milo." name="nano-list-webtoon-recommendation.jpg" mdxType="Image" />
    <p><strong parentName="p">{`Milo Ahn`}</strong>{` is a normal high schooler living in a world where you can find androids anywhere. They can be working for schools, on the streets, and even as celebrities. Maddie Ahn, scientist extraordinaire in android production and big sister to Milo passed away one day. She left behind a few things to her sole little brother - a fortune enough to live by comfortably, her colleagues’ name cards, and a cute killing machine for his birthday, Nano. Life goes on as usual for Milo, but things aren’t about to stay the same once he realized the extent powerful people would go to take away his new addition to the family.`}</p>
    <p>{`It wasn’t so much about seeing the androids kicking butts but instead, you are watching Milo struggling to keep his sanity and family together. Everyone wants Nano, but Milo refuses them all. Things get rowdy and people are killed when these baddies turned the whole debacle into a literal warzone. Read for Nano, stay for San <3 `}<RemoteLink to="https://www.webtoons.com/en/sf/nano-list/list?title_no=700" mdxType="RemoteLink">{`Read Nano List here.`}</RemoteLink></p>
    <Ads mdxType="Ads" />
    <h2>{`Completed WEBTOONS Recommendation #8: Athena Complex`}</h2>
    <p>{`There are a ton of comics about Gods and myths, but this series is honestly quite unique. Our MC, Athena, is the same one we knew from Greek mythology. She is the Goddess of Wisdom and War, and daughter of Zeus and Metis. The myth explained in the series isn’t far off from the `}<LocalLink to="https://www.britannica.com/topic/Athena-Greek-mythology" mdxType="LocalLink">{`original one`}</LocalLink>{` but there’s a twist - she’s in love with Poseidon, her tutor and parent-like figure. She confessed to him many times only to get rejected again and again.`}</p>
    <p><em parentName="p">{`Note: Sorry about this but I am mistaken! I finished S1 and there's like... no news about S2 at all. The story just stops there. Turns out there's a S2?! Sheesh! This is still ongoing.`}</em></p>
    <Image alt="Athena Complex banner screencapped from Webtoon" caption="From the left, Athena and Adrian." name="athena-complex-webtoon-recommendation.jpg" mdxType="Image" />
    <p>{`She finally snapped one day and the story begins 1000 years later, where Poseidon was reborn again in the body of a Korean high schooler named Adrian Waters Lee due to a curse. Athena masqueraded as a fellow male high schooler to get close to him but it wasn’t out of pity or comfort.`}<strong parentName="p">{` She wants her revenge, no matter what it takes. `}</strong>{`But nothing ever goes smoothly. Strange events are happening, and gods are meddling into Athena's affairs. Are all the events related to the secret behind Poseidon’s curse?`}</p>
    <p>{`The series is a good fantasy series. The gods and goddesses is true to their original myth, but with a Korean webcomic twist. `}<RemoteLink to="https://www.webtoons.com/en/fantasy/athena-complex/list?title_no=867" mdxType="RemoteLink">{`Read Athena Complex here.`}</RemoteLink></p>
    <Ads mdxType="Ads" />
    <h2>{`Completed WEBTOONS Recommendation #9: Soul Cartel`}</h2>
    <p>{`If you enjoy seeing fights similar to `}<strong parentName="p">{`Noblesse`}</strong>{`, this one is for you. The Cha siblings are exorcists and their family’s been in the trade since forever. Shihoon is the younger brother and he’s really good at sports. He was chasing after a soul when he encountered a devil named Mephistopheles. Luckily, all Mephisto asked for is knowledge of Faust, which Shihoon did deliver the next day. Mephisto then explained how he had waited for 500 years for Faust to be reborn, all for the sake of their contract. Things happened and Shihoon find himself diving into the afterlife together with Mephisto.`}</p>
    <Image alt="Soul Cartel banner screencapped from Webtoon." caption="From the left, Faust and Shihoon." name="soul-cartel-webtoon-recommendation.jpg" mdxType="Image" />
    <p>{`This series has a lot of fights, and I find the plot to be redundant. If you enjoy seeing angels, devils and humans just clashing, you’ll like this one. `}<RemoteLink to="https://www.webtoons.com/en/fantasy/soul-cartel/list?title_no=72" mdxType="RemoteLink">{`Read Soul Cartel here.`}</RemoteLink></p>
    <Ads mdxType="Ads" />
    <h2>{`Completed WEBTOONS Recommendation #10: Always Human`}</h2>
    <p>{`This series is all about genetic engineering and seeing past your outward appearances. It has a really good soundtrack that I enjoyed a lot. You can find the soundtrack `}<RemoteLink to="https://walkingnorth.bandcamp.com/album/always-human-ost" mdxType="RemoteLink">{`here.`}</RemoteLink></p>
    <Image alt="Always Human banner screencapped from Webtoon" caption="From the left, Austen and Sunati." name="always-human-webtoon-recommendation.jpg" mdxType="Image" />
    <p>{`In a world where genetic engineering is the norm, everyone uses mods. With mods, you can change your eye colours, change your hairdo and even your face structure. Our MC, Sunati loves using mods and changes her appearance whenever she feels like it. So it was strange to see a girl not using any mods at all. Sunati approached the girl to befriend her, only to unbolt a flood gate.`}</p>
    <p>{`It wasn’t that she `}<strong parentName="p">{`isn’t using`}</strong>{` any mods, it’s more like she `}<strong parentName="p">{`can’t use`}</strong>{` any mods. The girl quickly left, leaving Sunati to ponder her mistake. They soon meet up for a cup of coffee, and the girl named Austen explained her condition. Also, Sunati asked Austen to go out with her. Yup, I love this series already when I reached this chapter.`}</p>
    <p>{`The author used water colour-ish techniques, and panels occasionally spill out which makes it a treat for the eyes, along with the music playing in the background. I could tell the author really like the colour `}<strong parentName="p">{`blue`}</strong>{`. In case, you haven’t caught up yet, both characters are `}<strong parentName="p">{`bi`}</strong>{`. This is a relaxing and wholesome series all in all. If you like short reads (the chapters are short), this is for you. `}<RemoteLink to="https://www.webtoons.com/en/romance/always-human/list?title_no=557" mdxType="RemoteLink">{`Read Always Human here.`}</RemoteLink></p>
    <Ads mdxType="Ads" />
    <h2>{`The end of the list?`}</h2>
    <p>{`I have a few more series I want to introduce, but it’s getting a tad long so I’ll end it here. I’ll get Part 2 out real soon!`}</p>
    <p><strong parentName="p">{`Edit: Here's `}<LocalLink to="/blog/another-10-completed-webtoons-you-should-read-part-2/" mdxType="LocalLink">{`Part 2`}</LocalLink>{`!`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      