import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const Blockquote = makeShortcode("Blockquote");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I think I may have discovered the perfect time to play Animal Crossing: New Horizons leisurely. It’s during the wee hours - 4.30am! Now you see, I enjoy `}<em parentName="p">{`time travelling`}</em>{` a lot in this game. Some fans would say it’s a `}<strong parentName="p">{`heretic act`}</strong>{` to go against what the game developers want you to `}<em parentName="p">{`experience`}</em>{`. Some would just shake their head and say, do whatever you want. As for me, I’m the type that thinks Animal Crossing: New Horizon is okay to do or ignore the time travelling mechanic. First and foremost, your progress won’t hinder anyone’s enjoyment. Secondly, Animal Crossing is a super personal game. Why would your progress on your island affect me? And likewise, why would mine affect yours? Please feel free to enjoy Animal Crossing: New Horizons however you want, it’s `}<strong parentName="p">{`your island.`}</strong></p>
    <Ads mdxType="Ads" />
    <p>{`At any rate, you might be wondering… Why `}<strong parentName="p">{`4.30am`}</strong>{`? I only get to play Animal Crossing in the evening after work on weekdays, and during the afternoon on weekends. But all in all, my playtime hours are usually close to night time. Due to this reason, I have almost never played the game (without time travelling) in the morning. At first, I’m fine with this. The game wants me to play the game in real time, so I’ll do exactly that. But curiosity soon got the better of me. I got really sick of the music in the afternoon, evening and night. It’s the same ol’, same ol’ super excited and bouncy music. I wonder how things are during wee hours or odd hours? Given the nature of this game, I think there should be easter eggs out there just waiting for me to discover!`}</p>
    <Image alt="Animal Crossing New Horizons screencap 1" caption="Oof, so sleepy... Loving the sky!" name="animal-crossing-new-horizons-geeknabe-5.jpg" mdxType="Image" />
    <p>{`So I time travelled to 8am. I entered the Town Hall and voila! Tom Nook and Isabelle are exercising! How adorable! I wandered around and noticed the wind blows softer, the music more springy and all my villagers are exercising (Except me). Super adorbs. The sun beaming down is much warmer in colour. I think it looks beautiful when it casts a shadow on my flowers and trees. Initially, I would have my fill playing at whatever hours I’m at when I first boot the game up, then time travel to play in the morning. I enjoy seeing the brighter colours all around my island, it felt really refreshing compared to playing at night!`}</p>
    <p>{`To be honest, I really don’t enjoy playing the game at night. Night time Animal Crossing is super dark. I mean, the game’s night sky is actually illuminating enough for you to see the island but to me, it’s still way `}<em parentName="p">{`too dark`}</em>{` when compared to daytime. To counter this, I placed a ton of lighting furniture all around my island just to make it `}<strong parentName="p">{`brighter`}</strong>{` at night. Alas, this is not how the lighting furniture worked! The lighting they beamed doesn't exactly provide illumination, it’s just part of the furniture design! And did you try swimming at night? It gives me the `}<strong parentName="p">{`hibbie jibbies...`}</strong>{` The lack of light around me as I swim around is just creepy. I can’t even see the bubbles or the shadows properly at the bottom!`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Animal Crossing: New Horizons screencap 2" caption="The lighting in this game are not what I expected." name="animal-crossing-new-horizons-geeknabe-4.jpg" mdxType="Image" />
    <Blockquote mdxType="Blockquote">
  Hmmm, I wear glasses so do I need new glasses? Maybe I should increase
  brightness on my Switch instead.{' '}
    </Blockquote>
    <p>{`Anyway, for experiment sake I tried to wear the head bopper that Isabelle gave me this month. You know, the free hat they give out during the fireworks festival! It helped a little I suppose. It behaves a bit strangely when I swim (I think it starts to go zappy when you swim with it?). It’s a nice hat to be fair, but it’s just super neon-ish when I swim. No illumination at all, nuh uh.`}</p>
    <Image alt="Animal Crossing: New Horizons screencap 3" caption="I think this is the best screenshot I took of my Animal Crossing: New Horizons gameplay <3" name="animal-crossing-new-horizons-geeknabe-1.jpg" mdxType="Image" />
    <p>{`The dark is supposed to be soothing when you play Animal Crossing at night but I disagree. I really, really prefer playing during daytime if possible. Since 1am is technically… `}<em parentName="p">{`morning`}</em>{` so I tried playing during those hours just to see if there’s anything special. Nope, unfortunately. It was until I tried 4am that I noticed something `}<strong parentName="p">{`cool`}</strong>{`. The island is still dark, but there’s better lighting all around. The music at 4am is super chill, like lo-fi jazz style but if you listen closely, you'll notice that the developers added a tick-tock sound effect to go along with it. At first I thought nothing of it like a dummy that I am. But once the clock strikes 5am, the game interrupted me to show Isabelle giving her usual speech the first time you boot the game for the day!`}</p>
    <Ads mdxType="Ads" />
    <p>{`It was then I realized, 5am is the start of a new day in Animal Crossing: New Horizons! The tick-tock sound effect that plays along with the music is them counting down to a new day. Of course I never noticed this! I mean, who `}<strong parentName="p">{`plays their Nintendo Switch at 5am`}</strong>{`?? So nowadays, I would start my Animal Crossing day by doing whatever it is that needs to be done with Able Sisters and Nook’s Cranny. Then I would time travel to 4.30am to enjoy the music and the island! I would walk around (since my character’s feet tapping when she runs is too loud) and just take in the quiet music and view.`}</p>
    <Blockquote mdxType="Blockquote">Someone drip feed me “Animal Crossing at 4am” please? </Blockquote>
    <p>{`You see, it’s not only the music that’s cool. If you look up to the sky, it’s a perfect blend of daytime and nighttime. You won’t see plenty of stars, but the clouds are in perfect view along with the sublime daylight illuminating the cloud lining. `}<em parentName="p">{`Gorgeous`}</em>{`. The wind is blowing, there’s butterflies flying around and since it’s August, the crickets are… cricketing. Everything is here as it is during daytime but they are just much, much softer compared to their daytime counterpart. And the best part? `}<strong parentName="p">{`No villagers are awake.`}</strong>{` Good. It’s not that I hate my villagers, but sometimes I could do without them walking around and bothering me.  (Hush, stand back up Benedict! I got you a stone as a present!)`}</p>
    <Image alt="Animal Crossing: New Horizons screencap 4" caption="I really hope it's 99K bells each! (Nope, it was 10K bells)" name="animal-crossing-new-horizons-geeknabe-3.jpg" mdxType="Image" />
    <p>{`Besides, they should be in quarantine anyway! Why are they up and about for? Playing at 4.30am is me being a `}<strong parentName="p">{`good neighbour`}</strong>{` by not rattling them to Isabelle but instead, finding out the time when everyone’s asleep and making havoc during those hours. I say everyone but poor Isabelle and Tom Nook... Maybe Nintendo should just make the Town Hall staff get off work from 12am to 8am.`}</p>
    <Ads mdxType="Ads" />
    <p>{`This blog post title could say 4am but instead, I opted for 4.30am because it’s `}<strong parentName="p">{`perfect for me`}</strong>{`. I’d fool around for 30 minutes or so, or just make my character sit still on a chair and wait. I would leave my Switch on while I do other work. Now this is where a portable console is handy! I made my character sit still and angled my camera view so I get to look at the sky. I see the sky gradually turning brighter with every minute as the clock approaches 5am. The crickets too, get extra noisy. Once the Town Hall’s clock chimed at 5am and Isabelle finished her usual announcement, the music changed to using piano!! God bless the instrument choice, I love it!`}</p>
    <p>{`The piano plays really slow, as though welcoming a new day with a subdued tune. 4am music is great but 5am is the kicker! It’s so different from what I’m used to when playing at nighttime! When I first listened to it, I was so `}<strong parentName="p">{`enamored by it`}</strong>{`, I’d start walking around my island. I won’t do anything as I appreciate the music, I just… walk. Not even fishing or bug catching! 5am music is perfect to just leave my Switch on while I work. I get to listen to the wind, the crickets and stare at the flowers swaying along with the slow piano. It’s very soothing… It’s perfect for when I want some serene music playing in the background.`}</p>
    <Image alt="Animal Crossing: New Horizons screencap 5" caption="I intentionally placed a ton of chairs around my island just so I can sit and go AFK." name="animal-crossing-new-horizons-geeknabe-2.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <p>{`I really love Animal Crossing: New Horizons. Catching fish, completing the museum or even terraforming your island is fun but there are times I just want some peace and quiet. The fact that Animal Crossing can have both bustling activities and quiet moments is pretty amazing. The discovery of 4am ~ 5am music is nothing but coincidental but boy, am I glad to have discovered it. I’ll continue playing Animal Crossing for years to come and I hope you’ll do the same!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      