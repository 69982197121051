import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const RemoteLink = makeShortcode("RemoteLink");
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I have this strange obsession with magic in manga, but not the type of magic shown in Black Clover. The magic in Black Clover is super shounen-ish. The magic I really enjoy reading about are magic similar to that shown in The Ancient Magus’ Bride or Harry Potter. There’s this fantasy world that the author has built up on and in each chapter, we explore tidbits of what makes this magical world ticks. Fairies flying around, magic being an all-encompassing law but still mysterious even to the magical users, etc… It’s these little details that make magic magical to me.`}</p>
    <p>{`Note: `}<RemoteLink to="https://images4.alphacoders.com/971/thumb-1920-971572.jpg" mdxType="RemoteLink">{`Here's the link to the wallpaper I used for the banner.`}</RemoteLink></p>
    <p>{`Given how rare these stories are, I’m always on the lookout for stories that got it right. There should be this mysterious feeling of how magic works, or the slow exploration of the magical world. I apologize if you were looking for stories with romance/magic similar to `}<strong parentName="p">{`The Ancient Magus’ Bride`}</strong>{` because that is not my intention. To me, what makes Ancient Magus’ Bride tick is how they treat supernatural and magic. Enjoy the list!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Magical Fantasy Manga Recommendation #1: Champignon no Majo (シャンピニオンの魔女)`}</h2>
    <p>{`Have you guys read `}<RemoteLink to="https://myanimelist.net/anime/74/Gakuen_Alice" mdxType="RemoteLink">{`Gakuen Alice`}</RemoteLink>{`? No? Oof, you gotta! Because it’s one of the popular stories out there written by the same author that did Champignon no Majo, `}<RemoteLink to="https://myanimelist.net/people/2306/Tachibana_Higuchi" mdxType="RemoteLink">{`Higuchi Tachibana`}</RemoteLink>{`. Tachibana-sensei has a wonderful imagination that really helps to make Gakuen Alice shine. Gakuen Alice is all about children who have special powers called Alice. Each special power is really unique and creative, and the environment these children grow up in are really fantastical too. Champignon no Majo gave me the same vibes. Tachibana clearly capitalized her strengths here. She makes really great background lore for a magical world, along with some of the most believable characters.`}</p>
    <Image alt="Champignon no Majo art" caption="This is a super underrated title. The author also wrote a sequel to Gakuen Alice which... no one seems to have noticed for some reason. I love all of her works, please try reading Champignon no Majo!" name="champignon-no-majo-art.jpg" mdxType="Image" />
    <p>{`Champignon no Majo centers around a young witch called Luna. Everywhere she goes, poisonous mushrooms sprout up from her steps. These mushrooms look delicious but anyone could easily get sick from eating them. Thus, to minimize the danger, Luna lives far away from everybody, opting to visit the town only to procure or trade goods. Common folks avoid her like a plague but many who practice magic know of her malady. They treat her normally but in the end, poisonous mushroom that constantly pops up is still too much to bear. Due to her body’s special trait, she has never touched another person before. You see, the poor girl is lonely. So every night, she would draw her friends to play with her. One day, she drew a cute boy she saw in town. She’s happy because that is the first time she experienced a hug with another person. Alas, drawing a real person seems to have a terrible effect on the person himself…`}</p>
    <p>{`Champignon no Majo is a super sweet story of a girl who wants to feel loved. There’s magic here and there, and the author would add notes to explain the idea behind the magical creature’s design, etc. I love it when authors do that, please bring those back! Sweet, fluffy and magical! Please give this story a try.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Magical Fantasy Manga Recommendation #2: xxxHolic`}</h2>
    <p>{`A supernatural title? Are you serious, hotpot? Uh huh, it’s time to bring back a great CLAMP title to talk about! Have you heard of `}<RemoteLink to="https://myanimelist.net/anime/232/Cardcaptor_Sakura" mdxType="RemoteLink">{`Cardcaptor Sakura`}</RemoteLink>{` or `}<RemoteLink to="https://myanimelist.net/anime/177/Tsubasa_Chronicle" mdxType="RemoteLink">{`Tsubasa Chronicle`}</RemoteLink>{`? No? Darn, you’re missing out! Granted, these three stories (including xxxHolic) are all inter-related, you can skip out on reading the other two if you just want to experience the otherworld-liness of xxxHolic. I totally did not title-drop the other two so you would go read them…`}</p>
    <Image alt="xxxHolic manga cover art" caption="CLAMP love adding smoke effects and butterflies in most of their art for xxxHolic. You should try the xxxHolic anime if you enjoyed the manga. And the Midsummer movie! The movie is an original story but I find it really well done. It matches xxxHolic's vibe and theme despite being an original story." name="xxxHolic-cover-art.jpg" mdxType="Image" />
    <p>{`xxxHolic follows Kimihiro Watanuki, a high school student who attracts supernaturals in Tokyo. It got to the point where these ghosts/youkais/whatnot have started piling up on him and he’s dreadfully tired everyday. One day, he came across a wish-granting shop owned by a beautiful witch called Yuko Ichihara. The witch saw through his predicament and offered to grant a wish for a price. Watanuki said yes and the witch alleviated his supernatural attraction problem. Alas, he now has to work for her in her shop as the price of his wish. Was this hitsuzen after all? To have Watanuki come across her shop and work for her? More supernatural beings and humans alike would visit her shop for a wish, and Yuko would grant it for them. But what’s in it for her?`}</p>
    <Ads mdxType="Ads" />
    <p>{`The way the story portrays the supernatural gets a 10/10 from me. Each panel is bewitching not in the sense that it is beautiful, but it’s… mysteriously otherworldly. Yuko is a very wise witch. There are times that she would choose not to tell Watanuki everything because she has seen through it all. Most of the supernatural chapters would go like this -> Yuko receives a request, she would explain just a small bit and give instructions to Watanuki before sending him off, Watanuki encountered danger during the task and would follow Yuko’s instruction to save himself and finally Yuko would swoop in to explain the whole deal in the end. It’s actually a nice plot flow that lets the readers explore the wonders of supernatural incidents before getting the explanation in the end.`}</p>
    <p>{`My favourite scene (I'm reciting from memory, the finer details could be wrong) is when Watanuki was tasked to deliver a set of moonlight birds, a pair of birds that can only be seen from the shadows under the moonlight. He set off optimistically only to be attacked by supernatural forces who want the bird. Yuko only told him to carry the bag in case something happens. The story’s mascot, Mokona popped up from the bag and told him to play shiratori. Watanuki then played shiratori with Mokona, only to be told that shiratori is actually protecting him from danger in the end. Don’t you love it when your boss just shoo you off with a `}<em parentName="p">{`“It’s dangerous to go alone, take this” `}</em>{`without telling you the reason why?`}</p>
    <p>{`xxxHolic is definitely good stuff with great art. You could try the anime too if you enjoy the manga. As for the ending, you will need to read Tsubasa Chronicle. Orrrrr you could skip that out and just enjoy xxxHolic for the supernatural chapters.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Magical Fantasy Manga Recommendation #3: The Faraway Paladin (最果てのパラディン Saihate no Paladin)`}</h2>
    <p>{`This title could be considered a shounen isekai manga adapted from a light novel. However, what sets it apart is how the author handles magic. The story is really respectful of the magical rules and embed every facet of magic into the people's livelihood. Like `}<RemoteLink to="https://en.wikipedia.org/wiki/Eragon" mdxType="RemoteLink">{`Eragon`}</RemoteLink>{` (if you have read the book), the magic system in The Faraway Paladin plays around with `}<em parentName="p">{`the power of words`}</em>{`. I find it similar to how western high fantasy stories did it, which compelled me to introduce this title here.`}</p>
    <Image alt="The Faraway Paladin cover art" caption="This is the first I've laid my eyes on a coloured page by the mangaka. Oh it's gorgeous!" name="saihate-no-paladin-art.jpg" mdxType="Image" />
    <p>{`Our MC is Will, a Japanese who was reincarnated into this magical world. He was found as a baby in a ruin by three unlikely individuals - a skeletal warrior, a mummified priestess and a spectral sorceress. These three decided to raise him like their son and taught him every speck of knowledge they know of. They taught him swordsmanship, faith and magic. Soon, Will grew up to be a respectable individual that carried a piece of all three of his parents. But what is the  real story behind his parents and the ruin he is currently living in? Upon uncovering the truth, what will William choose to do?`}</p>
    <p>{`Spoiler alert, there is no romance so far. Just a great story of practicing good faith in people and showcasing William's growth into a respectable paladin. I enjoyed how he grew his group of friends by choosing what’s best for them. The story is somewhat similar to fantasy novel stories, where each arc adds to his accolade. His prowess and the common folk’s impression of him keeps improving in each arc too. I genuinely think the author is doing a great job trying to be different from the other light novel writers. Give this a try!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Magical Fantasy Manga Recommendation #4: Ran and the Gray World (乱と灰色の世界)`}</h2>
    <p>{`This title I’m about to recommend may be a yay or a nay for the readers, there is no in-between. You’ll know what I mean in a bit. But first, here’s what this magical fantasy title is all about.`}</p>
    <Image alt="Ran and the Gray World manga volume cover" caption="Nice ain't it. The busy background. The whimsical toys and the framing with the smoke. Even the manga panels are filled to the brim with this art style." name="ran-and-the-gray-world-manga-cover.jpg" mdxType="Image" />
    <p>{`You follow Ran, an inquisitive 10 year old girl with an explosive amount of magic. This is an obvious outcome since her family are all strong magic wielders in their own right. However, due to her young age, she still couldn’t control her magical powers well. So she relies on a pair of shoes to make her older in order to control her powers better + make strangers not doubt the words of a 10 year old. Due to her curiosity, she often runs around and causes trouble. Enjoy reading about Ran and her family’s trouble with their magical lives <3`}</p>
    <p>{`Folks who read this title love the whimsical art and magical plot, but one thing they didn’t enjoy is how the author made a 10-year old girl become older and thus, made it seem OK to subject her to lewd situations. Apparently she doesn’t understand the idea of wearing a bra and folks older than her noticed it. Plus, the apparent romance between her and an older working adult (which will be introduced later in the story) didn’t feel good at all even to me. I personally think this manga is a gem in terms of art and plot. Just that the author didn’t do the `}<em parentName="p">{`older-guy-likes-a-10-year-old`}</em>{` part good. If you look past that, it’s good.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Magical Fantasy Manga Recommendation #5: Witch Hat Atelier (とんがり帽子のアトリエ)`}</h2>
    <p>{`This is yet another title so underrated, it’s bloody criminal. I’m just happy it got published in English-speaking countries and got the attention it deserves.`}</p>
    <Image alt="Witch Hat Atelier art" caption="Such gorgeous art... I pulled this from their official twitter." name="witch-hat-atelier-art.jpg" mdxType="Image" />
    <p>{`Coco, our heroine, is a sweet girl from an equally sweet family. Magic exists in this world but only those with the innate ability to wield it could use magic. So for Coco who does not have this ability, she could only yearn for it despite her love for it. One day, she met a Wizard named Qifrey and witnessed how he performed magic. Curiosity got the better of her and she tried to mimic his actions. And voila, magic appeared! It turns out magic can be wielded by anyone if they know how, but Coco isn’t trained to use it. Her beginner’s mistake petrified her mother. Qifrey who is tasked to handle her magical accident then offered to take her in as an apprentice in order to figure out how she cast that magic.`}</p>
    <p>{`Tis’ a wonderful world of students learning about magic. They wear robes, those pointy hats and even draw runes to perform magic. Gosh, how lovely! I’m salivating at the background art and magical mechanics here. In Witch Hat Atelier, magic can be performed by anyone but it’s dangerous if you don’t know the specifics. So there are associations to prevent this knowledge from seeping out. But then there’s the other faction, who thinks it's not progressive to stay in this “traditional” mindset. So here you have Coco who is finally pursuing magic that she loved but as the initiator of the petrification magic, she’s kinda caught up in the two factions’ dispute. Read for the magic, stay for the background art. The background art is really lovely!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Magical Fantasy Manga Recommendation #6: Beyond the Clouds`}</h2>
    <p>{`This is the sweetest thing I’ve read so far in this list. There’s magic, there’s some machinery thrown in here and there and then there’s this magic in believing in magic >`}{`_`}{`<. Oh these kids are adorable!`}</p>
    <Image alt="Beyond the Clouds manga volume art" caption="I looked around for the Japanese version but I couldn't find it. Apparently this manga is drawn for a French publisher directly? Interesting. See how earthy the tones are? Love it, it's so warm. I think it's intentional? It gives me a Le Petit Prince vibe somehow." name="beyond-the-clouds-volume-art.jpg" mdxType="Image" />
    <p>{`Our MC is Theo, an orphan boy who is good at tinkering with machinery. He has this adorable childhood naivety of believing in magic. With the progress of machinery, humans have long since forgotten about magical beings and whatnot. Magical creatures do exist, but humans have long since regarded them as hearsays only. Kinda like how the supernaturals that exist are slowly forgotten by humanity. Theo loves reading because he could go on adventures and visit magical worlds. But as he grew older, books are now just books to him. One day, he found an injured, amnesiac winged girl lying on a heap of scraps. Her injury? Only 1 wing left on her back. Seeing how heartbroken she is, he resolved to create a new mechanical wing prosthetic for her. That is how the girl, Mia and Theo became good friends. This title is a great journey of rediscovering the world of forgotten magical wonders with Theo and Mia <3`}</p>
    <Ads mdxType="Ads" />
    <p>{`I kinda found this title by chance. The cover art is an interesting fusion of sketchiness and earthy tones. The first chapter had me enraptured by the presentation alone! I thought to myself, `}<em parentName="p">{`“Yes I found a treasure!”`}</em>{` after I’m done with 6 chapters. This is the magical feeling of adventure and friendship I love in manga. If you enjoyed the first chapter, you will enjoy the rest!`}</p>
    <h2>{`All in all`}</h2>
    <p>{`Enjoy these recommendations! I hope you have a fantastical time just indulging in the world of black and white. Let your imagination fill in those blanks, and enjoy the feeling of being a kid that believes again :D`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      