import styled from 'styled-components';
import Slider from 'react-slick';

import nabe from '../../assets/icons/eyes-open.svg';
import $ from '../../styles/globals';
import Twitter from '../SocialFollow/Twitter';
import { addKeys } from '../../utils';

const Section = styled.section`
  background-color: ${$.brown1};

  ${$.screen.mobile} {
    box-shadow: inset 0px -3px 10px rgba(0, 0, 0, 0.1);
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  ${$.screen.desktop} {
    height: 340px;
  }

  ${$.screen.tablet} {
    height: 280px;
  }

  ${$.screen.mobile} {
    flex-direction: column;
    padding: ${$.layout.padding4}px 0;
  }
`;

const NabeChanContainer = styled.div`
  ${$.screen.desktop} {
    width: 350px;
    margin-right: ${$.layout.margin3}px;
  }

  ${$.screen.tablet} {
    width: 270px;
  }

  ${$.screen.mobile} {
    width: 200px;
  }
`;

const NabeChan = styled(nabe)`
  width: 100%;
`;

const GreetingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${$.layout.padding4}px;

  ${$.screen.mobile} {
    padding-top: ${$.layout.padding6}px;
  }
`;

const Greeting = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${$.brown5};
  font-family: Porkys;
  margin-bottom: ${$.layout.margin4}px;

  > *:first-child {
    letter-spacing: 1px;
  }

  ${$.screen.desktop} {
    font-size: 50px;
  }

  ${$.screen.tablet} {
    font-size: 42px;
  }

  ${$.screen.mobile} {
    font-size: 38px;
    flex-direction: column;
    > * {
      &:first-child {
        margin-bottom: ${$.layout.margin2}px;
      }
    }
  }
`;

const SliderContainer = styled.div`
  margin: 0 ${$.layout.margin4}px;
  overflow: hidden;

  ${$.screen.desktop} {
    width: 200px;
    height: 50px;
  }

  ${$.screen.tablet} {
    width: 160px;
    height: 42px;
  }

  ${$.screen.mobile} {
    height: 38px;
    width: 155px;
  }
`;

const SliderItem = styled.div`
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: ${({ color }) => color};
  background-color: ${$.brown1};
`;

const Subtitle = styled.h1`
  font-family: Rubik Regular !important;
  color: ${$.brown5};
  font-size: 17px !important;
  text-align: center;
  line-height: 1.4em;
  width: 100%;
  max-width: 400px;
  margin-bottom: ${$.layout.margin5}px;

  a {
    color: ${$.color.orange};
    text-decoration: none;
    font-family: Rubik Regular !important;
    font-weight: bold;
    &:visited {
      color: ${$.color.orange};
    }
  }

  ${$.screen.desktop} {
    margin-top: ${$.layout.margin2}px;
  }

  ${$.screen.tablet} {
    margin-top: ${$.layout.margin2}px;
  }

  ${$.screen.mobile} {
    margin-bottom: ${$.layout.margin4}px;
    br {
      display: none;
    }
  }
`;

const SocialFollowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > a {
    margin-right: ${$.layout.margin2}px;
    &:last-child {
      margin-right: 0;
    }
  }

  ${$.screen.desktop} {
    margin-bottom: ${$.layout.margin3}px;
  }

  ${$.screen.tablet} {
    margin-bottom: ${$.layout.margin3}px;
  }
`;

const tags = addKeys([
  { value: 'anime', color: $.color.orange },
  { value: 'manga', color: $.color.red1 },
  { value: 'games', color: $.brown3 },
]);

const Banner = () => (
  <Section>
    <Container>
      <NabeChanContainer>
        <NabeChan />
      </NabeChanContainer>
      <GreetingContainer>
        <Greeting>
          <div>I blog about</div>
          <SliderContainer>
            <Slider
              dots={false}
              arrows={false}
              autoplay
              slidesToShow={1}
              slidesToScroll={1}
              swipe={false}
              draggable={false}
              vertical
              touchMove={false}
            >
              {tags.map(({ key, value, color }) => (
                <SliderItem key={key} color={color}>
                  {value}
                </SliderItem>
              ))}
            </Slider>
          </SliderContainer>
        </Greeting>
        <Subtitle>
          {`Hi, I'm nabe-chan! Welcome to Geeknabe, my personal blog where I write about `}
          <a href="/blog/">geeky topics</a>
          {` with my co-writer mari-chan. mari-chan writes `}
          <a href="/fanfiction/">fanfic</a>
          {` too, so please check her writing!`}
          <br />
          <a href="/about/"> Learn more about us here.</a>
        </Subtitle>
        <SocialFollowContainer>
          <Twitter />
          <Twitter nabe={false} />
        </SocialFollowContainer>
      </GreetingContainer>
    </Container>
  </Section>
);
export default Banner;
