import PropTypes from 'prop-types';
import styled from 'styled-components';

import $ from '../../../styles/globals';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${$.layout.padding5}px ${$.layout.padding3}px;

  ${$.screen.mobile} {
    flex-direction: column;
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 38%;
  border-right: 2px solid ${$.grey1};
  margin-right: 2%;

  ${$.screen.mobile} {
    border-right: none;
    border-bottom: 2px solid ${$.grey1};
    width: 100%;
  }
`;

const Pic = styled.img`
  border-radius: 999px;
  border: 2px solid ${$.grey1};
  margin-bottom: ${$.layout.margin3}px;
`;

const Title = styled.div`
  font-size: 20px;
  font-family: Rubik Regular;
  font-weight: bold;
  color: ${$.brown4};
  width: 100%;
  text-align: center;
  padding-bottom: ${$.layout.padding2}px;
`;

const Summary = styled.div`
  line-height: 1.4em;
  font-size: 16px;
  width: 60%;
  font-family: Rubik Regular;

  a {
    font-family: Rubik Regular;
    font-weight: bold;
    text-decoration: none;
    color: ${$.color.orange};
  }

  ${$.screen.mobile} {
    width: 100%;
    text-align: center;
    margin-top: ${$.layout.margin3}px;
  }
`;

const Profile = ({ name, summary, pic }) => (
  <Container>
    <Info>
      <Pic src={pic} width="100px" height="100px" alt="author image" />
      <Title>{`About ${name}`}</Title>
    </Info>
    <Summary
      dangerouslySetInnerHTML={{
        __html: summary,
      }}
    />
  </Container>
);

Profile.propTypes = {
  name: PropTypes.string,
  summary: PropTypes.string,
  pic: PropTypes.string,
};

Profile.defaultProps = {
  name: '',
  summary: '',
  pic: '',
};

export default Profile;
