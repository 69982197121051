import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const RemoteLink = makeShortcode("RemoteLink");
const Blockquote = makeShortcode("Blockquote");
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Welcome to the era of `}<strong parentName="p">{`webtoons`}</strong>{` (not to be confused with `}<RemoteLink to="https://www.webtoons.com/en/" mdxType="RemoteLink">{`WEBTOON`}</RemoteLink>{`), where just about any type of plot out there can be fun and enjoyable to read. The long strip of panels make it easy to incorporate some interesting arrangement. The dialogues are simple, and it’s coloured! The format of a webtoon makes it easy for anyone to jump right in. Plus, you can easily read it in on your smartphone by just doing the one gesture you’re most familiar with - `}<strong parentName="p">{`scrolling`}</strong>{`. But what I think prompted the explosive popularity of webtoons is this:`}</p>
    <Blockquote mdxType="Blockquote">
  The WEBTOON platform made webtoon super accessible, to be fair. I wager this
  platform is the reason why webtoons are so popular among English-speakers.
    </Blockquote>
    <p>{`The one thing that the public `}<em parentName="p">{`loves`}</em>{` the most is `}<strong parentName="p">{`free stuff`}</strong>{`. Not only did WEBTOON make their comics free, you can easily download the app on your phone and start reading seamlessly. If you ask me what’s my comic preference lately, I’d answer `}<em parentName="p">{`webtoon.`}</em>{` I’m starting to treat webtoons like isekai manga… I see a new one, I click on it to read then move on to the next one. The plot doesn’t have to be deep, it just needs to be enjoyable for me to keep scrolling!`}</p>
    <Ads mdxType="Ads" />
    <p>{`Just weeks ago, I chanced upon a webtoon titled `}<strong parentName="p">{`Spirit King`}</strong>{` `}<strong parentName="p">{`Elqueeness`}</strong>{`. It’s a unique webtoon (or manhwa, depending what floats your boat) in how it didn’t really have an end goal in mind. You know how wuxia light novels just keep on `}<em parentName="p">{`extending the plot`}</em>{` until there’s a `}<strong parentName="p">{`thousand chapters`}</strong>{` or more? `}<em parentName="p">{`Elqueeness`}</em>{` reminds me of such stories! But before you yawn and click away, Elqueness’s plot feels more familiar to me in a way that is `}<strong parentName="p">{`grounded`}</strong>{` and `}<strong parentName="p">{`soothing`}</strong>{` to read. It’s nothing special, but it is exactly for that reason which compelled me to keep clicking to read the next chapter!`}</p>
    <p>{`Elqueeness starts off with the MC dying in a horrible accident, only to be told that his birth was a mistake and him dying is also a mistake. Okay so the keyword here is `}<strong parentName="p">{`his destiny `}</strong>{`as `}<em parentName="p">{`Kang Ji-Hoon`}</em>{` shouldn't have happened.`}</p>
    <p>{`Our MC was 17 years old when he passed away. He lived a life of misery back when he was alive. He got bullied often, his family is a jerk to him and he’s born unlucky. It was only when he wandered around as a ghost that he was discovered by a guide that... guides lost souls. Things happened, and he was finally brought to the afterworld to search for the place he is supposed to go in the first place! Little did he know that he is the lost `}<strong parentName="p">{`Elqueeness`}</strong>{`, `}<strong parentName="p">{`the Water Spirit King`}</strong>{` from the magical world of Arkadon. He is supposed to be reborn as Elqueeness to take the place of the previous Water Spirit King that moved on but... Let's just say the afterworld's manager messed up.`}</p>
    <Image alt="Spirit King Elqueeness panel 1" caption="The birth of Elqueeness. I tried looking for some nice panels (Because nice art is the best way to get potential readers to pick a series up!) and found this." name="spirit-king-elqueeness-webtoon-1.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <p>{`Arkadon is a world where Fire, Wind, Earth and Water Spirit King must exist together to govern the balance of nature's equilibrium. Missing one would cause a catastrophe which coincidentally, is happening `}<strong parentName="p">{`now`}</strong>{`. Arkadon is experiencing a severe drought due to the missing Water Spirit King so Elqueeness `}<em parentName="p">{`must be born`}</em>{` to counter this misfortune. Now that Kang Ji-Hoon’s fate has been determined, the arbiter of the afterworld didn’t dally any further. He insists that Kang Ji-Hoon choose to walk back the path fate has set for him. Souls that are about to be reincarnated must get their memories wiped but it didn’t exactly work for Kang Ji-Hoon. So he gets to reincarnate as Elqueeness in Arkadon with all of his memories as a human retained.`}</p>
    <Image alt="Spirit King Elqueeness panel 2" caption="I think each character is super expressive and cartoonish in the Elqueness. Not exactly a minus to me, I love it." name="spirit-king-elqueeness-webtoon-3.jpg" mdxType="Image" />
    <p>{`So what’s in store for Kang Ji-Hoon as Elqueeness now? Well, since he still has his memories from when he’s Kang Ji-Hoon, he needs to learn to do the jobs of a Water Spirit King from scratch. The story will soon progress to involve Elqueeness in the affairs of the lower world aka the human world! The first few chapters first start off with him beginning to understand his role as a Spirit King. However, his curiosity about the lower world soon gets the better of him. Human spirit users can contract spirits but only the most powerful spirit users can contract with a Spirit King. Unfortunately, Elqueeness is the downright impossible to summon among the four kings since a ton of mana is needed to summon him. Most summoners get their mana drained and die if they attempt to call upon the Water Spirit King.`}</p>
    <p>{`So when a young boy managed to summon Elqueeness, he jumped at it. Elqueeness finally gained a medium to freely explore the lower world with the summoner as the anchor. The current Elqueeness is often compared with the previous generation’s Water Spirit King and for valid reason too. The story would show how ruthless the `}<em parentName="p">{`previous Elqueeness`}</em>{` is while the current `}<strong parentName="p">{`Elqueeness (Kang Ji-Hoon)`}</strong>{` is meek and approachable thanks to him retaining his human memories. It is because of this contrast that every action that Elqueeness did felt good to read.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Spirit King Elqueeness panel 3" caption="Kang Ji-Hoon has this amicable aura to him which makes him really fun to talk to. He also does great pep talk!" name="spirit-king-elqueeness-webtoon-2.jpg" mdxType="Image" />
    <p>{`Elqueeness would keep using his human morales as the basis of his actions. He doesn’t tolerate unfairness, and would jump to keep his summoner safe. Characters around him would quickly take note of this and hint to him of the dangers of continuing down the road. However it is for the same reason that they find this part of him appealing. It’s a soothing read since most blockade thrown at Elqueeness is solved `}<em parentName="p">{`fairly`}</em>{`. His miserable past life as Ji-Hoon often gets brought up to highlight the `}<strong parentName="p">{`kind treatment`}</strong>{` he is receiving now. He didn’t have a happy family or good friends back when he's Ji-Hoon, but he does now. He never stepped up to help others being bullied, but he can now. It’s these little trickles that made me feel satisfied reading Elqueeness. The boy deserves what he has now, and we’re getting these calming chapters being IV-dripped direct to our veins.`}</p>
    <Blockquote mdxType="Blockquote">
  What is there to dislike about Elqueeness? None at all, because that’s the
  whole premise!
    </Blockquote>
    <p>{`I admit, I have never liked those wuxia novels that span over `}<em parentName="p">{`thousands of chapters`}</em>{`. Folks love it because it’s not a good plot that they are looking for but the `}<strong parentName="p">{`journey`}</strong>{` that they want to read about. I personally think Elqueeness falls into that category for me but unlike wuxia, I’m really enjoying the journey so far! There’s no end goal in sight at all and I’m fine with that. Plus, the basis of the plot makes it hard to predict what will happen next. It does give clues to the next danger to be aware of but given how cosy the style of writing is for Elqueeness, it honestly doesn't really matter in the end.`}</p>
    <Ads mdxType="Ads" />
    <p>{`I checked the raws and there’s about 156 chapters so far whereas the fan translation is only up to 49. Phew, I’m glad there’s still a ton of Elqueeness to look forward to! If they are stopping the fan translations, I will need to code a script to translate Korean on images for me…`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      