import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I once said Assassin’s Pride is one of Fall 2019’s great anime series that everyone needs to look out for. When I said that, it’s half bias and half assured (of my declaration) after watching the first 2 episodes. With Episode 9 out in the wild, I take back those words.The show’s quality is declining in every episode. The only saving grace is that I have caught up to the manga, and I need some answers as to WHAT is Kufa Vampir’s true identity, and what's the deal with Melida Angel. Other than that, I have `}<strong parentName="p">{`no reasons`}</strong>{` to continue watching this.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`What’s declining`}</h2>
    <p>{`The ambience is still there, and you’ll find decent lighting in scenes that require one. But these details are marred by how subpar the attention is to the character’s art and motion. Add them together and you’ll get a really wasted potential of an anime series. An action anime needs to have great fighting scenes, or no one will watch it. Characters `}<strong parentName="p">{`MUST `}</strong>{`look good and move well in every frame. If you don’t have those, it’s just a badly clobbered series to boost the manga/light novel sales.`}</p>
    <p>{`Usually we fans don’t look too kindly to these anime series and unfortunately, Assassin’s Pride is gearing up to join them. `}<em parentName="p">{`(Note: Overlord) `}</em>{`The next saving grace is to look at how well the plot holds up. Unfortunately, Assassin's Pride's plot doesn’t hold itself very well. Granted, there’s a sufficient plot ongoing and it’s generally okay to watch so far, but knowing that there’s a manga out there that does the plot better just adds a blemish on the anime production ):`}</p>
    <p>{`Is it the lack of budget? Or are they being rushed? Or do they lack the heart to do this well? It’s like they only paid attention to the first few episodes and say, “Screw it.” for the rest of the season.`}</p>
    <h2>{`No more dynamic camera angles and flashy weapon clashing`}</h2>
    <p>{`If you’re watching this for those epic scenes, you can kiss that motivation goodbye. The scenes are flat, and they are clearly playing it safe. Camera angles? Nah. You have two characters fighting each other and the camera clearly just wants to get them within focus. Let them throw a few obvious punches and kicks, bodies went flying, some weapon clashes and that’s it. I just described most of Assassin’s Pride fighting scene.It pales so much compared to the first episode’s action scene. They were clearly doing the bare minimum for the action scenes. This just screams wasted potential!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Their eyes are jumpy`}</h2>
    <p>{`This is really bugging me but their eyes! THEIR. EYES. I get that the girls are moving and turning their heads, but instead of moving with their faces, it just jumps up and down while glued to the girls’ faces. You girls doing okay there? I hope this is just me being picky, but it’s getting way too obvious for me to enjoy the show. I don’t think there are other shows as faulty as this one in terms of animation.`}</p>
    <Image alt="Are they starving the animators here?" caption="This is probably the most obvious scene with the eyes being jumpy. The character here was shocked by the sudden noise behind her, and her eyes go all wide (and jumped from one frame to another) and uh, even her bangs went from being short to long here. This all happened within a second and it is really obvious." name="frame_1.jpg" mdxType="Image" />
    <h2>{`Still frames are hilariously drawn`}</h2>
    <p>{`Weirdly drawn stills are okay if they aren't noticeable, but these stills often took a few seconds before they move on to the next scene. That’s bad, yo. The quickest way to get what I mean is looking at these screenshots. Enjoy.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Background characters don't need faces, they say. These are main characters, I shouted back." caption="Background characters don't need faces, they say. These are main characters, I shouted back." name="faceless_kufa_melida.jpg" mdxType="Image" />
    <Image alt="Beads for eyes. That's a doll sitting there, not a background character." caption="Beads for eyes. That's a doll sitting there, not a background character." name="dots_as_eyes.jpg" mdxType="Image" />
    <Image alt="Their mouths are drawn so weirdly. Are the animators being starved or something?" caption="Their mouths are drawn so weirdly. Are the animators being starved or something?" name="rushed_mouths.jpg" mdxType="Image" />
    <Image alt="Kufa is mad for some reasons." caption="Kufa is mad for some reasons." name="angry_kufa,png.jpg" mdxType="Image" />
    <Image alt="Truly... a rushed show. I understand the need to NOT draw the eyes but this is a little too shoddy." caption="Truly... a rushed show. I understand the need to NOT draw the eyes but this is a little too shoddy." name="creepy_dead_eyes.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`Zooming past the plot`}</h2>
    <p>{`How do you do, plot? Aye, I guess you’re a little too fast to understand my snail-paced greetings. Let’s be frank here. We weren’t expecting a perfect, one-to-one conversion from light novel to anime. It’s normal to have them take liberties with the story to fit it into a 12 episode anime series. But this? This is way beyond what we hoped they will do to the anime ): If I can put this to a single adjective, I’ll use “`}<em parentName="p">{`horrendous`}</em>{`”.`}</p>
    <p>{`They basically chopped off all the dialogues and plot just so they can fit in more action. No exposition, no nothing. They expect the viewers to just go along with the flow, and boy it is bad. I had hoped to see the pacing improved when I first reviewed it but no. The anime is just chugging along with the main characters reacting to the events and of course, things just fall into place nicely.`}</p>
    <p>{`Dialogues are usually just two characters explaining things by answering each other in a turn-based manner. Kufa’s done talking, now it’s the bad guy’s turn. Then it’s back to the bad guy’s turn. What are they talking about? Of course, it’s to move the plot so we can move to the next arc as soon as possible.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Will I still watch this`}</h2>
    <p>{`I have since stopped watching it religiously every week. It’s not catchy. There are no redeeming features anymore, no hooks to compel me to continue watching. It’s unfortunate because I really liked the manga. It’s not to say that this is super bad, but I have seen better anime out there.All in all, good plot but bad anime.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      