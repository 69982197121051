import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import $ from '../../../../../styles/globals';
import Author from './Author';
import Counter from '../../../../MDXBlogpost/HeartIt/Counter';
import PageViewCounter from '../../../../MDXBlogpost/PageViewCounter';
import TotalComments from '../../../../DisqusComments/TotalComments';
import Toggle from '../../../../Toggle';
import SunIcon from '../../../../../assets/icons/sun.svg';
import MoonIcon from '../../../../../assets/icons/moon.svg';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  ${$.screen.mobile} {
    flex-direction: column;
    justify-content: initial;
    align-items: initial;
  }
`;

const Info = styled.div`
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  align-items: center;

  > * {
    margin-right: ${$.layout.margin2}px;
    &:last-child {
      margin-right: 0;
    }
  }

  ${$.screen.mobile} {
    margin-top: ${$.layout.margin5}px;
  }
`;

const ToggleContainer = styled.div`
  margin-right: ${$.layout.margin2}px;
  display: flex;
  flex-direction: row;
  align-items: center;

  > * {
    margin-right: ${$.layout.margin1}px;
    &:last-child {
      margin-right: 0;
    }
  }

  > label {
    width: 35px;
    height: 22px;
  }

  > svg {
    width: 20px;
    height: 20px;
    transition: all 0.3s ease;
    opacity: 0;
    &.show {
      opacity: 1;
    }
    &:first-child {
      fill: ${$.color.yellow};
    }
    &:last-child {
      fill: ${$.color.white};
    }
  }
`;

const getReadMode = () => {
  const localStorage =
    typeof window !== 'undefined' ? window.localStorage : null;

  return localStorage !== null ? localStorage.getItem('mode') : '';
};

const setReadMode = (readMode, setMode) => {
  const localStorage =
    typeof window !== 'undefined' ? window.localStorage : null;

  if (localStorage !== null) {
    localStorage.setItem('mode', readMode);
    setMode(readMode);
  }
};

const Stats = ({
  slug,
  published,
  author,
  hearts,
  pageViews,
  title,
  index,
}) => {
  const [mode, setMode] = useState(getReadMode() || 'day');

  return (
    <Container>
      <Helmet
        bodyAttributes={{ class: mode === 'night' ? 'night-mode' : '' }}
      />
      <Author published={published} name={author.name} img={author.pic} />
      <Info>
        <ToggleContainer title="Toggle between Day and Night mode" mode={mode}>
          <SunIcon className={mode === 'day' ? 'show' : ''} />
          <Toggle
            onClick={(checked) => {
              setReadMode(checked === true ? 'night' : 'day', setMode);
            }}
            checked={mode === 'night'}
            trueColor={$.color.orange}
            falseColor={$.color.lightblack}
          />
          <MoonIcon className={mode === 'night' ? 'show' : ''} />
        </ToggleContainer>
        <Counter hearts={hearts} swap />
        <PageViewCounter total={pageViews} swap />
        <TotalComments title={`${index} ${title}`} slug={slug} />
      </Info>
    </Container>
  );
};

Stats.defaultProps = {
  author: { name: '', pic: '' },
  hearts: '-',
  pageViews: 0,
};

Stats.propTypes = {
  published: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  index: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  pageViews: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hearts: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  author: PropTypes.objectOf(PropTypes.string),
};

export default Stats;
