import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Blockquote = makeShortcode("Blockquote");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`As an old-school shounen reader, I’m torn on the way the newer shounen titles are handling things. You see, I love reading about complex battle mechanics and training arcs. It’s archaic for sure, but I find it lovely to read about the main cast struggling to defeat the ever-growing-stronger baddies. It’s kinda how shounen titles are back then! If there’s no timeskip/training/tournament arc, then that ain’t shounen!`}</p>
    <p>{`However, the shounen formula has changed a lot since then. You won’t find any newer titles delving into this formula anymore. You could argue that the current titles are still young and have yet to touch upon this cliche, but you could tell that most mangaka has since moved away from this formula and added their own twist! It’s nice for sure and shounen nowadays are more varied in plot cliches. There’s more humour, creative fighting scenes and even consequences that felt way too relatable. Major kudos to the newer mangakas! You could tell that the shounen scene as a whole has matured. Plots are now more layered and complex while fighting scenes are one-of-a-kind now. While the writing is adapted for teens, you could still appreciate the writing as a young adult.`}</p>
    <Ads mdxType="Ads" />
    <p><em parentName="p">{`Note: I’ll assume you know the basic gist of Mairimashita! Iruma-kun’s story! I have watched the anime but here I will be talking about the manga. I have read up to Chapter 197 at the time of writing.`}</em></p>
    <p>{`In this blog post, I would like to talk about Mairimashita! Iruma-kun. It’s shounen but it… doesn’t feel like one. I would classify this manga as one of the newer generations because of the way the mangaka handled the plot. I’m not too sure if anyone has noticed this, but most manga out in the wild nowadays prefer to incorporate the `}<em parentName="p">{`comforting`}</em>{` vibe. I blame this on the surge of isekai manga adapted from syosetu. The web novel writers mostly don’t plan their stories out well. They just keep writing and writing with the same vibe in mind. Not to say it’s bad because I know many stories out there with no tension yet folks still love them all the same. A comfy plot is in a sense, their own niche. Due to the rise of isekai manga, folks would swallow them up regardless of how drawn out the plot is. So as a matter of fact, the comfy vibe of an isekai manga is within the reader’s expectation so these titles are safe from being axed in general.`}</p>
    <Blockquote mdxType="Blockquote">But can you say the same for a shounen title?</Blockquote>
    <p>{`I'm aware that there are shounen titles out there with the same premise as Iruma-kun, but they had to do the cliche really well to stay relevant! So when I tried Iruma-kun, I was really `}<strong parentName="p">{`surprised`}</strong>{` because this is a `}<em parentName="p">{`shounen title`}</em>{` published in a magazine known for titles like Beastars. I swear when I first read it, I thought of this as an `}<strong parentName="p">{`E for Everyone`}</strong>{` title instead of `}<strong parentName="p">{`T for Teens`}</strong>{`. Iruma-kun has a really good setup meant for exciting tension but the vibe it gives out is ironically... a `}<em parentName="p">{`feel good`}</em>{` vibe. Like, where’s the exciting fighting scenes?! Don’t get me wrong, I really love the `}<strong parentName="p">{`feel good vibe`}</strong>{` but I have a feeling that the editor would axe it for the slow pace/not what readers want to read in a shounen title. But thankfully it survived the axe! You honestly can’t blame me for feeling like this since the beginning chapters literally had no tension, just feel good moments plus a side dish of humour.`}</p>
    <Ads mdxType="Ads" />
    <p>{`We see Iruma-kun dragged here and there in the story. He wanted to lay low because he really does not want to end up as a demon’s meal. Unfortunately, the mangaka has other plans for him. He keeps being the center of attention and somehow, formed his own gang of close friends. Up to this part, it still feels like the mangaka is taking her sweet time with the plot (which feels really dangerous as a weekly serialization). I was dangerously close to dropping it since there is literally no tension and no setup for the future plot. If there’s nothing to look forward to, I usually keep the title on the back burner until something significant happens. Thankfully the flow quickly changed when she introduced `}<em parentName="p">{`Wicked Phase`}</em>{`. The lore on the demons are finally revealed in small bits of information. Iruma himself found his determination to be part of his new demon family. Imho, if this part is shown quicker, I think readers would be more invested in the story.`}</p>
    <p>{`Reading the initial chapters feel as though the mangaka got an idea of where to go, but didn’t know when is the best time to show it until she feels it is ready. The starting chapters can be a borefest since there’s very little character investment going on. It’s all about Iruma but we already know his character! There’s little growth to look forward to for Iruma so it really feels as though nothing much has happened. So I’m really happy when the plot started going really fast.`}</p>
    <p>{`The members of the Abnormal Class finally get their own limelight with Iruma as the side cast (but still being allowed to shine). We get to see their `}<em parentName="p">{`“cool side”`}</em>{`, and not just as part of the `}<em parentName="p">{`“classmate cast”`}</em>{`. The manga went from `}<em parentName="p">{`feeling good`}</em>{` at the end of every chapter to reading about `}<em parentName="p">{`everyone doing cool things`}</em>{` in the name of character growth. We were hinted that the end game of the story is to see Iruma become a demon king with everyone in the Abnormal Class as his entourage. It took a while, but we’re finally seeing the action as well as investing into everyone’s character growth. These characters are no longer the NPC but part of the main cast!`}</p>
    <Ads mdxType="Ads" />
    <p>{`Not to mention we’re finally seeing why the adults are so powerful instead of just hearsay. Plus, we were shown what it means to be an exemplary demon… which all members of the Abnormal Class is slowly being molded into. Perfect. To be a demon is to stay true to your desires, so we get to see the demons rising to the occasion just by being themselves. I love how the author finally found her own beat in portraying these characters. The art gets more chaotic by the chapter. The mangaka adds 2-page spreads whenever possible which really gives a strong impact for the cool scenes. I added some of my favourite scenes below just to illustrate my point! Uhm, minor spoilers, maybe?`}</p>
    <Image alt="Mairimashita! Iruma-kun panel 1" caption="For most of the story, Iruma has always been borrowing magic from his grandfather to blend in. This time, he got training as an archer and finally got his own “personal” magic. This scene is him resolving himself to aim true no matter what it takes (which gets better with context, especially after he got a scene where he resolved to stay as a demon in the demon world)" name="mairimashita-irumakun-manga-panel-1.jpg" mdxType="Image" />
    <Image alt="Mairimashita! Iruma-kun panel 2" caption="Chapter 168 is downright great. The lineart is so chaotic, I could feel the mad rush of euphonia!" name="mairimashita-irumakun-manga-panel-2.jpg" mdxType="Image" />
    <Image alt="Mairimashita! Iruma-kun panel 3" caption="The Evidol games are so much fun! Kuromu is one of those characters that never shied away from her dreams from the start. We get to see the idea reinforced again in this arc and beautifully too <3" name="mairimashita-irumakun-manga-panel-3.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`All in all`}</h2>
    <p>{`I believe this title is proof that any manga could become a winner given time. The endgame may be a long way to go for Mairimashita! Iruma-kun but I’m sure the journey will make it worthy. Please give this manga a try if you have not heard of this title before! It keeps getting better, I promise. The anime version is already out in the wild while S2 is on the way.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      