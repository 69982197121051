import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Image = makeShortcode("Image");
const Blockquote = makeShortcode("Blockquote");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`You need to breed, quick and easy. Uh, I mean, you need a `}<strong parentName="p">{`Ditto`}</strong>{` to breed Pokémon but `}<em parentName="p">{`where is it?!`}</em>{` It's in Lake of Outrage zone, at the very left corner of the Wild Area right before you enter Hammerlocke.`}</p>
    <p><em parentName="p">{`Note: All images are sourced from my Nintendo Switch Screen Share.`}</em></p>
    <Image alt="Here's the map of Wild Area with Lake of Outrage in it" caption="Here is where you can find Ditto - in Lake of Outrage." name="pokemon-sword-and-shield-ditto-map.jpg" mdxType="Image" />
    <p>{`Make sure you have all the badges before you go all in to catch a Ditto, since the Ditto(s) you'll find here are at least LVL 56 and above. You should have unlocked the bicycle that lets you cycle on water too.`}</p>
    <p>{`First, fly over to Hammerlocke Hill checkpoint. Next, head towards Lake of Outrage on the right. If you kept walking on, you shouldn't be able to proceed since you're blocked by a cliff. Look down and you'll see a small area with 1 tree and 1 max raid den. You can't see it, but there's 1 berry tree here too. There's a small patch of grass over there, and that's where you'll be hunting your Ditto.`}</p>
    <Image alt="A small little patch of grass where you'll be catching your Ditto" caption="Catch your Ditto here" name="outrage_lake.jpg" mdxType="Image" />
    <p>{`Head over to the mentioned area on your bicycle. You will need to head down to the lake and cycle over. Look out for a Ditto in the `}<strong parentName="p">{`overworld`}</strong>{`. Ditto does not rely on specific weather to appear, so this makes our work much easier. It's also a very short Pokemon so the grass will cover it. Scrutinise hard to see if Ditto is in the grass or not! It's really easy to miss. Now here's the thing that makes it so easy to catch Ditto in this particular area.`}</p>
    <Blockquote mdxType="Blockquote">
  Only 4 overworld Pokemon will spawn in this patch of grass.
    </Blockquote>
    <p>{`So far, I have only encountered `}<em parentName="p">{`1 Ditto among the 4 overworld Pokemon`}</em>{`. Assuming this is the only use case here, you will only find 3 Pokemon wandering about the grass patch at any time (They're all big Pokemon(s) so you won't have any trouble noticing them), making it possible to figure out if there's a small little Ditto in there.`}</p>
    <Image alt="Only four Pokemon will be wandering about." caption="It's easy to notice if there's only 3 Pokemon wandering about. It means a Ditto is in there!" name="four_pokemon.jpg" mdxType="Image" />
    <p>{`We can do the `}<em parentName="p">{`walk-away-and-respawn-it`}</em>{` method too, so if you wish to make the overworld Pokemon respawn again, just walk off so the Pokemon pops off. Walk back in, and new Pokemon will pop in to take it's place. Rinse and repeat to get that Ditto!`}</p>
    <p><strong parentName="p">{`PS: I am not sure if I am lucky or not, but I caught 2 Ditto(s) using Quick Balls to ensure a fast catch. I noticed they were holding Quick Powders in return. Maybe catching Ditto(s) at the first turn will yield Quick Powder. I am unable to confirm this since I ran out of Quick Balls.`}</strong></p>
    <Image alt="Quick Powder proof in my bag." caption="Quick Powder proof in my bag." name="quick_powder.jpg" mdxType="Image" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      