import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const RemoteLink = makeShortcode("RemoteLink");
const Blockquote = makeShortcode("Blockquote");
const LocalLink = makeShortcode("LocalLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I log in Genshin, clear my dailies and resin... and found myself wanting to play a little longer. So I start a random lower rank domain and press Match to see if there are any low Adventurer Rank (AR) players that need help. I notice many newbies are, well, newbies in this game. So here are 26 wisdom I wish to impart upon those who wish to try the game!`}</p>
    <p><strong parentName="p">{`Note: There are many things going on in the game, and I can't explain them all in here. So what I did is link the terms to their respective page in the wiki. Do click on them if you do not understand these terms!`}</strong></p>
    <Ads mdxType="Ads" />
    <h2>{`Genshin Impact Tip #1: Stockpile your fragile resins until Adventure Rank (AR) 45`}</h2>
    <p>{`You see that 160 counter at the top of your map? That's the total resin you are free to use for the day. It's a precious resource that allows you to farm everything required to raise your characters, including their `}<RemoteLink to="https://genshin-impact.fandom.com/wiki/Talents" mdxType="RemoteLink">{`Talent`}</RemoteLink>{`, Level, Weapon Level and `}<RemoteLink to="https://genshin-impact.fandom.com/wiki/Artifacts" mdxType="RemoteLink">{`Artifacts`}</RemoteLink>{`. Thus, it is the only thing barring you from going full speed ahead with your game progress. The only way for it to refill is to wait, pay real money to fill it or use `}<RemoteLink to="https://genshin-impact.fandom.com/wiki/Fragile_Resin" mdxType="RemoteLink">{`fragile resins`}</RemoteLink>{`.`}</p>
    <p>{`Fragile resins are a one-time item you can use to add 60 resin to your counter. It's a rare resource in the game since you can only get it from:`}</p>
    <ol>
      <li parentName="ol">{`Leveling up your `}<RemoteLink to="https://genshin-impact.fandom.com/wiki/Artifacts" mdxType="RemoteLink">{`Adventure Rank`}</RemoteLink>{`.`}</li>
      <li parentName="ol">{`Joining and clearing limited events.`}</li>
      <li parentName="ol">{`Buy from the teapot (Unlocked at AR35) shop.`}</li>
      <li parentName="ol">{`Clearing the `}<RemoteLink to="https://genshin-impact.fandom.com/wiki/Battle_Pass" mdxType="RemoteLink">{`Battle Pass`}</RemoteLink>{`.`}</li>
    </ol>
    <p>{`Once used, it is gone forever. This is important if you're a F2P (player who doesn't spend cash)! If you pay real money (which I don't encourage unless you're a working adult), it's tough to stockpile these fragile resins. Which is why many veteran players tell beginners to use them only when you reached AR45. AR45 is when you have unlocked artifact domains that gives 2 or more 5-star artifacts. Artifacts are bread and butter of your characters' strength, so if you wish to use your resins to the fullest, it is best to keep them until you are ready to splurge on 5-star artifact domains.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Genshin Impact Tip #2: You need a strong damage dealer (DPS) to clear World Rank Ascension Quest`}</h2>
    <p>{`The enemy levels scale with your World Rank which in turn, only rises if you clear the World Rank Ascension Quest. You'll get the quest at AR25, AR35, AR45 and AR50. Your World Rank won't increase until you finish the quest, so you should take your sweet time with raising your preferred characters until you're ready.`}</p>
    <p>{`Each quest starts at the same dungeon with the same enemies. However, their levels raise with the World Rank. The toughest monsters to clear are probably the initial `}<RemoteLink to="https://genshin-impact.fandom.com/wiki/Ruin_Guard" mdxType="RemoteLink">{`Ruin Guard`}</RemoteLink>{` doing the spinning attack, the `}<RemoteLink to="https://genshin-impact.fandom.com/wiki/Elements" mdxType="RemoteLink">{`Cryo`}</RemoteLink>{` and Hydro `}<RemoteLink to="https://genshin-impact.fandom.com/wiki/Abyss_Mages" mdxType="RemoteLink">{`Abyss Mage`}</RemoteLink>{` combo and lastly, the `}<RemoteLink to="https://genshin-impact.fandom.com/wiki/Electro_Hypostasis" mdxType="RemoteLink">{`Electro Hypostasis`}</RemoteLink>{`.`}</p>
    <p>{`Here's a tip for the Ruin Guard: Aim for the eye. Shooting their eye paralyzes them, so it should be easy to end them quickly. The Cryo and Hydro Abyss Mage combo gave me the most headache when I am still a newbie in this game, but here's the thing: You don't need to fight them inside the dungeon with the laser traps. You can lure them away from the dungeon and into the hallway, where you can slowly smack away at their shield. Pyro characters make short work of their shield. If you don't have Pyro characters, use Geo characters. Although it would take longer to remove their shields, at least Geo attacks work, yea?`}</p>
    <p>{`Now for the Electro Hypostasis, it is best to use range attackers. When the three prism shows up at the end, make sure to use Pyro or Cryo characters to destroy the prism. It all boils down to how well you synergize your team. You ultimately need a really strong damage dealer for the Electro Hypostasis, otherwise you won't be able to clear the boss within 480s. Remember, bring 1 strong damage dealer (DPS)! The other 3 should be characters who could help your damage dealer do its job.`}</p>
    <h2>{`Genshin Impact Coop Manners #3: Please don't use Chongyun in weekly boss or domain coop`}</h2>
    <p>{`I'm not being bias towards `}<RemoteLink to="https://genshin-impact.fandom.com/wiki/Chongyun" mdxType="RemoteLink">{`Chongyun`}</RemoteLink>{` at all when it comes to his usefulness or strength, but if you read up on his Elemental Skill (E)... His skill infuses Polearm, Sword and Claymore characters with Cryo. So every melee character within his E circle will be doing Cryo damage! If anyone is using a Physical melee character during the coop, they would be so angry because you are reducing their damage. I remember seeing someone using a Chongyun in a weekly boss fight, and the Keqing player was so angry at Chongyun xD`}</p>
    <p>{`So try not to bring Chongyun unless you know what you are doing.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Genshin Impact Coop Manners #4: You can retry a Talent Book/Artifact Domain without leaving`}</h2>
    <p>{`I noticed that many low AR host players (The host is 1P, and 1P denoting Player 1) tend to leave the domain in a coop, then start the domain challenge again from the outside. You don't need to do that! As the host (1P), you can simply retry the domain when you claim your rewards from the tree.`}</p>
    <p>{`That's why many high AR players do not claim the rewards and wait for you inside the domain. They are actually waiting for you to restart the domain challenge :)`}</p>
    <h2>{`Genshin Impact Coop Manners #5: Please ask the host before you take their resources`}</h2>
    <p>{`I understand how giddy you would be after pulling a new 5-star character. You'd want to max said character's level, but wait... You don't have enough resources!`}</p>
    <p>{`One thing you could do is joining random strangers to filch their world's resource. Before you do that, make sure to ask first! They are more likely to say Yes and just maybe, you'll find a new friend to play Genshin with :)`}</p>
    <h2>{`Genshin Impact Coop Manners #6: If a random joined you and started shooting/jumping at things, please take note`}</h2>
    <p>{`One thing I enjoy a lot is finding hidden chests and pointing them out to the host. Since language barrier is a thing at the Asia server, I try to just jump, circle around an object or shoot at chests to get their attention. So if you see any randoms joining your coop and doing that, do take note at what they are trying to tell you.`}</p>
    <p>{`Or you could... Just type "What on earth are you doing" and hope they reply with text instead of an emoji.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Genshin Impact Coop Manners #7: Please don't use C6 Bennett in coop either`}</h2>
    <p>{`Similar to the gacha mobile games you are familiar with, you can get the same copies of characters in Genshin. The first time you get a character, it is a C0 (`}<RemoteLink to="https://genshin-impact.fandom.com/wiki/Constellations" mdxType="RemoteLink">{`Constellation 0`}</RemoteLink>{`) character. The second time you get the same character, you can light up their constellation with a Stella Fortuna item and unlock a power up. Said character is now C1. Each character has 6 Constellations you can get, and by getting the 8th copy, you will no longer get character copies but instead, the game will give you 5 `}<RemoteLink to="https://genshin-impact.fandom.com/wiki/Masterless_Starglitter" mdxType="RemoteLink">{`Starglitter`}</RemoteLink>{` if it is a 4-star character, and 25 Starglitter if it is a 5-star character.`}</p>
    <Blockquote mdxType="Blockquote">
  My condolences if you got more than 8 copies or more of the same 5-star
  character. You truly have some amazing luck.
    </Blockquote>
    <p>{`Starglitter lets you exchange for rare items in the currency shop. Most players stock up the currency to exchange for 4-star characters, 4-star weapons or more gacha currency (Called Acquaint Fate or Intertwined Fate). I usually go for the gacha currency since that seems to be the most worthy purchase.`}</p>
    <p>{`Now back to the topic, `}<RemoteLink to="https://genshin-impact.fandom.com/wiki/Bennett" mdxType="RemoteLink">{`Bennett's`}</RemoteLink>{` 6th Constellation has this effect: All sword, claymore and polearm characters will be infused with Pyro within Bennett's Q. This is similar to Chongyun's E effect, so don't use a C6 Bennett during coop unless you know what you are doing.`}</p>
    <h2>{`Genshin Impact Tip #8: You don't need perfect artifacts`}</h2>
    <p>{`There are 4 ways you could increase your character's strength permanently.`}</p>
    <ol>
      <li parentName="ol">{`Character Level. Max is Lvl 90.`}</li>
      <li parentName="ol">{`Talent. You are given 3 talent to raise, namely the Auto Attack, Elemental Skill (E) and Elemental Burst (Q).`}</li>
      <li parentName="ol">{`Weapon Level. Max is Lvl 90.`}</li>
      <li parentName="ol">{`Artifacts. It works like any RPG equipment, and you have 5 slots to fill. Artifact has a 2-piece and 4-piece set effects, along with their main stat and sub stats.`}</li>
    </ol>
    <p>{`Not mentioned here are buffs (temporary strength increase) that could come from:`}</p>
    <ol>
      <li parentName="ol">{`Food.`}</li>
      <li parentName="ol">{`Weapon.`}</li>
      <li parentName="ol">{`Elemental Skill or Elemental Burst.`}</li>
      <li parentName="ol">{`Team Resonance.`}</li>
      <li parentName="ol">{`Artifact effects.`}</li>
    </ol>
    <p>{`The one thing that you could tweak the most for your characters are your artifacts. You need to be aware of a few things when it comes to choosing the best artifacts for your character:`}</p>
    <ol>
      <li parentName="ol">{`The 2-piece and 4-piece artifact set effect. Does it fit your character's strength?`}</li>
      <li parentName="ol">{`Each artifact's main stat. Does it synergies with what your character needs?`}</li>
      <li parentName="ol">{`Each artifact's sub stat. Are they useful or useless to your character?`}</li>
    </ol>
    <p>{`It's a lot to take in, I'm sure. Before AR45, you don't need to be aware of artifacts at all. Just equip whatever makes sense to you! After reaching AR50 and above, you will need to be extra particular with your character's build. This is when the game starts to be punishing if you don't know what you are doing!`}</p>
    <p>{`But here's the thing... Once you have 4 characters that is decently built, your artifacts doesn't need to be perfect. You'll be able to clear most of what the overworld throws at you. Though, you might need to have 1 character from each `}<RemoteLink to="https://genshin-impact.fandom.com/wiki/Elements" mdxType="RemoteLink">{`element`}</RemoteLink>{` (Hydro, Pyro, Cryo, Electro, Anemo, Geo) for gameplay's sake, along with 1 archer or 1 catalyst user. Long range attackers are useful for when you can't reach floating enemies!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Genshin Impact Tip #9: Take note of the Azhdaha's element shown on the domain's entrance`}</h2>
    <p>{`There are 2 types of bosses in Genshin - overworld normal bosses and `}<RemoteLink to="https://genshin-impact.fandom.com/wiki/Weekly_Bosses" mdxType="RemoteLink">{`weekly bosses`}</RemoteLink>{`. Overworld bosses will respawn after X minutes once defeated and if you have claimed the rewards with 40 resin. Weekly bosses however, are usually in special domains, and you can only claim their rewards once a week for each weekly boss.`}</p>
    <p>{`The first 3 weekly bosses you defeat and claim will only cost 30 resin. Afterwards, you will need 60 resin to claim their rewards. One of the weekly bosses is `}<RemoteLink to="https://genshin-impact.fandom.com/wiki/Beneath_the_Dragon-Queller" mdxType="RemoteLink">{`Azhdaha`}</RemoteLink>{`, and this fat dragon is a bit special. As a Geo Dragonlord, it can use Pyro, Hydro, Cryo or Electro during the boss fight. Thankfully each week, it will only randomly use 2 elements from the mentioned 4 elements. You can check what elements it will use on the domain entrance.`}</p>
    <ol>
      <li parentName="ol">{`Hydro -> Blue`}</li>
      <li parentName="ol">{`Pyro -> Red`}</li>
      <li parentName="ol">{`Electro -> Purple`}</li>
      <li parentName="ol">{`Cryo -> Light blue`}</li>
    </ol>
    <h2>{`Genshin Impact Tip #10: Weekly boss Dvalin is a solo-only boss`}</h2>
    <p>{`I noticed many newbies tried to ask for help versus Dvalin at Stormterror's Lair, the first weekly boss you'll unlock after clearing Mondstadt's Archon Quest. I'm sorry, but this boss does not allow co-op. You will need to fight it solo.`}</p>
    <h2>{`Genshin Impact Tip #11: Clear Childe's mark by touching the weekly boss domain walls`}</h2>
    <p>{`Childe is one of the weekly bosses you can challenge after clearing Liyue's Archon Quest. He has 3 phases, with him getting stronger in each phase. In Phase 2, if you get hit by him, your character will get marked with Riptide. It hurts a lot if he hits you when you are marked!`}</p>
    <p>{`You can clear the mark by touching the edge of the arena.`}</p>
    <h2>{`Genshin Impact Tip #12: Here are the many ways to dodge in this game`}</h2>
    <p>{`In most action games, there's a focus on the dodge mechanic. If you time your sprint/dash well, most of the time you can dodge an attack. In Genshin however, this... doesn't seem to be the case because the game has a second mechanic which makes this tough to dodge in succession - stamina gauge. Here are the 2 ways you can dodge (with certain success):`}</p>
    <ol>
      <li parentName="ol">{`Use Elemental Burst (Q).`}</li>
      <li parentName="ol">{`Dash.`}</li>
    </ol>
    <p>{`If you have played any action games or maybe, Monster Hunter, you will be familiar with i-frames (invincibility frames). It is a momentary action that gives your character invulnerability to any attacks by the monster. In Genshin, using Q and dashing will give you i-frames during the animation.`}</p>
    <p>{`Certain characters go even further! Some even have i-frames during their Elemental Skill (E). E.g. Mona's hold E. It takes a bit of timing to get it right if you prefer to dash or use Elemental Skill. I usually save my Q to dodge a big, powerful attack by the monster that usually spams their attack in succession.`}</p>
    <p>{`But it is not enough to just dodge an obvious attack by the enemy. If the enemy is doing a big, telegraphed attack, you will notice a large red circle where the enemy will land. After a while, you'll see a second red circle enlarging to meet the outer red circle. Now there are two things you can do when you see this happening:`}</p>
    <ol>
      <li parentName="ol">{`Move out of the red outer circle to dodge the attack altogether.`}</li>
      <li parentName="ol">{`Wait until the inner red circle meets the outer red circle, then dash to dodge.`}</li>
    </ol>
    <p>{`The inner red circle is actually a timing indicator as to when the attack will land. If the two circles meet, dodge! This is a great way to save up on stamina since most red circles are huge. Most of the time you won't have time to run out of the red circle entirely.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Genshin Impact Tip #13: Use your Elemental Sight for hidden chests and interactable objects`}</h2>
    <p>{`Genshin developers are super sneaky when it comes to hidden objects. They enjoy putting chests in places that are easy to miss in plain sight, or putting puzzles at places you least expect. Honestly, given how big the game is, I think missing puzzles or chests is a given. But how... do you NOT miss them? The answer: Use elemental sight.`}</p>
    <RemoteLink to="https://genshin-impact.fandom.com/wiki/Elemental_Sight" mdxType="RemoteLink">
  Elemental Sight
    </RemoteLink> will highlight objects that you can interact with, such as totem puzzles,
breakable rocks and CHESTS! I swear, the amount of sneaky, hidden chests I found
using Elemental Sight...
    <h2>{`Genshin Impact Tip #14: Look up and down, left and right`}</h2>
    <p>{`After playing since Day 1, I have gotten used to the developer's shenanigans. Totem puzzles usually come in 4. Imagine you finding the third totem... Now, where's the fourth totem?? The answer's usually these:`}</p>
    <ol>
      <li parentName="ol">{`Hidden in a bush.`}</li>
      <li parentName="ol">{`Above you.`}</li>
      <li parentName="ol">{`Below you.`}</li>
      <li parentName="ol">{`Behind a wall.`}</li>
      <li parentName="ol">{`Hidden in a breakable rock.`}</li>
      <li parentName="ol">{`Hidden in a room which coincidentally, is right behind where you stood.`}</li>
    </ol>
    <p>{`This is where you spam Elemental Sight to make your life easier than spinning your camera like a madman. After some time, you should be a pro in finding puzzles that you'll hit 100% completion rate for the entire map before you know it.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Genshin Impact Tip #15: Burn, destroy, pick up and whack everything that looks suspicious`}</h2>
    <p>{`When the game first launched, the only two regions we have is Mondstadt and Liyue. You can think of these two regions as the precursor to strange and weird puzzles. Chests in these two regions spawn in the strangest ways.`}</p>
    <p>{`Picking up carrots in Mondstadt may spawn a chest. Lighting up a random fireplace in Liyue spawns a chest too! In newer regions, these hidden puzzles are less likely to be reused (In a way, I'm grateful because that makes my life easier as a completionist) but the "law" still stands. If you see barrels placed in a strange arrangement, destroy them. If you see plates that lit up if you stand on them, leave heavy objects on it or stand on it. The plates by the way, are called pressure plates. Some plates require 2 heavy objects and at times, you will need to leave the objects behind to claim the chest that spawned. If the plate does not have any objects on it, it will despawn the chest.`}</p>
    <p>{`My point is, be curious, and you may be rewarded!`}</p>
    <h2>{`Genshin Impact Tip #16: Whack bloatty floatty flowers!`}</h2>
    <p>{`You see these `}<RemoteLink to="https://genshin-impact.fandom.com/wiki/Bloatty_Floatty" mdxType="RemoteLink">{`flowers`}</RemoteLink>{` with 3 round, green buds? Whack it or approach it and press the option that appears. Use an archer to hit the balloons for a chest!`}</p>
    <p>{`To some of you it may be obvious, but I've read reddit posts that many players didn't notice the flower at all.`}</p>
    <h2>{`Genshin Impact Tip #17: Do not build pity`}</h2>
    <p>{`There are altogether 3 gacha banners (excluding the beginner's banner with Noelle on it) in this game that is here to stay.`}</p>
    <ol>
      <li parentName="ol">{`Limited Character Banner - Every update (40 days per update), Genshin will announce the limited characters that will be on it.`}</li>
      <li parentName="ol">{`Limited Weapon Banner - Every update (40 days per update), Genshin will announce the limited weapons that will be on it.`}</li>
      <li parentName="ol">{`Standard Character/Weapon Banner - Characters and weapons you can pull from it do not change.`}</li>
    </ol>
    <p>{`Let's say you wish to spend your `}<RemoteLink to="https://genshin-impact.fandom.com/wiki/Intertwined_Fate" mdxType="RemoteLink">{`gacha currency`}</RemoteLink>{` to get the upcoming 5-star character, but you are addicted to pulling for new characters from the CURRENT banner. Then voila, you got the CURRENT BANNER's 5-star character. Oops, now what? You won't have enough gacha currency for the character you really want? Honestly, you only have yourself to blame. Please don't do this unless you are okay with the 5-star character that you `}<strong parentName="p">{`might`}</strong>{` get!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Genshin Impact Tip #18: Hoard everything you see`}</h2>
    <p>{`Please, do not skip on picking up flowers, whacking trees for wood, defeating enemies for their drops, etc. I promise you, you'll be forever grateful for the little things you do until you need it. Raising characters in Genshin takes a ginormous amount of effort if you did not stockpile your resources. The best way to save your sanity... is to do a little of everything instead of farming a huge amount of materials in one go.`}</p>
    <h2>{`Genshin Impact Tip #19: Use Hoyolab Interactive Map`}</h2>
    <p>{`Before the existence of `}<RemoteLink to="https://www.hoyolab.com/" mdxType="RemoteLink">{`Hoyolab`}</RemoteLink>{`, the mobile app for the game's forum... It was hell finding for the last Geoculus needed to raise the Statue of Seven. You need the last one but where is it??`}</p>
    <p>{`Then Hoyoverse released the interactive map. This is a lifesaver! Not only can you view hidden chests on it, it also lists each region's -culus items! If you click on the pin, you may see a GIF of how to get to it!`}</p>
    <LocalLink to="/blog/9-useful-genshin-impact-online-tools/" mdxType="LocalLink">
  If you're still lost on where to find info on Genshin, please take a look at
  this blog post I've written to help you out.
    </LocalLink> You'll be surprised at the amount of free Genshin online tools other
than the official interactive map!
    <h2>{`Genshin Impact Tip #20: Mark every -culus you see, or you'll regret it`}</h2>
    <p>{`In Liyue, there are 131 Geoculus up for grabs. Technically you only need 130 Geoculus to raise Liyue's Statue of Seven to Lvl 10 while the 1 remaining Geoculus is an extra. There's an extra -culus in every region, and I'm not too sure the reason why... Regardless, do take note that there's an extra in every region that you don't need to reach Statue of Seven Lvl 10.`}</p>
    <p>{`Make sure you mark every -culus you took. Otherwise it will be hell to revisit each place to see if it is the one you missed! Trust me, you don't want to revisit all 131 Geoculus spots in Liyue.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Genshin Impact Tip #21: Keep a copy of every 3-star weapon you get`}</h2>
    <p>{`There are 4 ways you can get a weapon from Genshin:`}</p>
    <ol>
      <li parentName="ol">{`You can buy them. But these are only 1-star weapons I believe. I never bought them.`}</li>
      <li parentName="ol">{`You can forge them. But you need 1 weapon billet you can get from weekly bosses and the materials to forge it.`}</li>
      <li parentName="ol">{`You can get them from chests. But these are at best, 3-star weapons.`}</li>
      <li parentName="ol">{`You can get them from the gacha banners. The only way to get a 5-star weapon, honestly speaking.`}</li>
    </ol>
    <p>{`Now the thing about 3-star weapons... You can only get them from treasure chests. So if you run out of treasure chests, you run out of these weapons too. To prevent these weapons from being used as fodder for raising weapon level, I keep a copy and lock them. Since I have multiple copies, I refine them to the max too! Maybe one day you'll have a character that needs it? I know for one, White Tassel is good for a `}<RemoteLink to="https://preview.redd.it/ajz6t3ppzfx71.png?width=960&crop=smart&auto=webp&s=2aa1d987932cf73555a35041e450e8377616d5ec" mdxType="RemoteLink">{`F2P Hutao build`}</RemoteLink>{`!`}</p>
    <h2>{`Genshin Impact Tip #22: You can plunge attack to mine ore`}</h2>
    <p>{`You see those blinking rocks/crystals? Use Claymore attacks on them. Breaking these rocks/crystals will yield ores which you can refine at the Blacksmith into Weapon Enhancement Ores. If you don't have a claymore character, switch to a polearm or a sword character, climb to a high place and use plunging attack to break the ore.`}</p>
    <h2>{`Genshin Impact Tip #23: How to collect resources scattered in the overworld map`}</h2>
    <ol>
      <li parentName="ol">{`See those purple ores with electric effects on them? Use Pyro to smack it.`}</li>
      <li parentName="ol">{`Ice flowers that freezes you when you get close? Use Pyro to collect the flower.`}</li>
      <li parentName="ol">{`Fire flowers that burns you when you get close? Use Hydro or Cryo to collect it.`}</li>
      <li parentName="ol">{`Use Anemo attacks on Dandelions right outside Mondstadt to collect them.`}</li>
      <li parentName="ol">{`Attack birds and boars to collect their tasty meat.`}</li>
      <li parentName="ol">{`Approach fireflies, butterflies and crystalflies slowly to collect them.`}</li>
    </ol>
    <h2>{`Genshin Impact Tip #24: Do not convert your primogems to fates (gacha currency)`}</h2>
    <p>{`I have seen plenty of `}<RemoteLink to="https://www.reddit.com/r/Genshin_Impact/comments/ttmlo2/my_dumb_dumby_ass_converted_20k_primos_by_mistake/" mdxType="RemoteLink">{`Reddit post`}</RemoteLink>{` about players who... got too excited about a new character banner and converted their primogems to the wrong gacha currency. So here's the thing, don't do it. Instead, go to the banner that you want to pull for, and press on the pull x1 or pull x10 button. It will automatically convert the primogems for you!`}</p>
    <p>{`This way, you reduce the chances of converting to the wrong currency you need! I linked a Reddit post in case you are curious!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Genshin Impact Tip #25: Best 5-star characters in their category`}</h2>
    <p>{`As of version 2.6, there are 47 (+1 limited character AKA `}<RemoteLink to="https://genshin-impact.fandom.com/wiki/Aloy" mdxType="RemoteLink">{`Aloy`}</RemoteLink>{` that newbies will not get) characters in the game. So who's the best 5-star character to pull for a F2P account? Obviously you pull for your favourite characters... This is a game after all, and you should have fun in a game. I can't tell you which character is a MUST to pull for, but I can tell you which 5-star character is the BEST in what they do.`}</p>
    <ol>
      <li parentName="ol">{`Best miner and shielder: Zhongli.`}</li>
      <li parentName="ol">{`Best crowd control: Venti.`}</li>
      <li parentName="ol">{`Best Electro damage dealer with strong constellation: Raiden Shogun.`}</li>
      <li parentName="ol">{`Best elemental buffer and exploration character: Kazuha.`}</li>
      <li parentName="ol">{`Strongest Pyro (By using the Elemental Reaction Vaporize) character: Hutao.`}</li>
      <li parentName="ol">{`Best Hydro applier and healer: Kokomi.`}</li>
    </ol>
    <p>{`I could go on, but here's what I noticed from playing my friends' accounts if I don't have the character. Read on for my justification for each character:`}</p>
    <h3>{`Zhongli`}</h3>
    <p>{`I wrote a `}<LocalLink to="/blog/genshin-impact-zhongli-and-ningguang-geo-team-build-guide/" mdxType="LocalLink">{`few blog posts on how to build him`}</LocalLink>{`, etc. He's my favourite character and because of this, I might be a little bias. But it is true that he is the best miner and shielder in the game. Are you sick of smacking each ore individually to mine them? Well, with Zhongli, all you need to do is hold E to mine every ore within his E's radius. There are a few shield characters in the game, but Zhongli's shield is the strongest. Plus, his shield also has a 20% physical and elemental debuff to enemies within range. It's bloody useful, I'm telling ya.`}</p>
    <h3>{`Venti`}</h3>
    <p>{`His suction is amazing. And by suction I mean, his Elemental Burst. You usually build him to be able to spam his Q as often as possible. Every light enemies get sucked into his Q, making it really easy to do crowd control! tldr: Use Venti's Q, light enemies die faster.`}</p>
    <h3>{`Raiden Shogun`}</h3>
    <p>{`Even at C0, she brings a lot to the table. But she gets ridiculously strong with every constellation. I have her at C4, and she easily dishes out 100K damage with her initial Q strike. If you're looking for a strong Electro character at C0 alone, and even better damage with every constellation, she's the one.`}</p>
    <Ads mdxType="Ads" />
    <h3>{`Kazuha`}</h3>
    <p>{`He is stupid easy to build. `}<RemoteLink to="https://genshin-impact.fandom.com/wiki/Viridescent_Venerer" mdxType="RemoteLink">{`4-set Viridescent Venerer`}</RemoteLink>{` artifact, 4-star weapon `}<RemoteLink to="https://genshin-impact.fandom.com/wiki/Iron_Sting" mdxType="RemoteLink">{`Iron Sting`}</RemoteLink>{` and aim to pull all of his artifact stats into `}<RemoteLink to="https://genshin-impact.fandom.com/wiki/Elemental_Mastery" mdxType="RemoteLink">{`Elemental Mastery`}</RemoteLink>{`. Voila, now every team with him in it do stupid big damage. Plus, his E makes it really easy to glide/climb! Great asset to any team.`}</p>
    <h3>{`Hutao`}</h3>
    <p>{`Her Pyro damage is OK without any external buff but once you start using her in a vaporize team... Wow, darn, you find yourself addicted to hitting big numbers. Plus, you can use `}<RemoteLink to="https://genshin-impact.fandom.com/wiki/White_Tassel" mdxType="RemoteLink">{`White Tassel`}</RemoteLink>{` on her and she do just fine if you're a F2P player.`}</p>
    <h3>{`Kokomi`}</h3>
    <p>{`She's first and foremost, a healer and a good one too. That's because her heals are tied to her E which you can spam as often as possible. Most healers in the game need to use their Elemental Burst to get heals which usually is hard to fill up in dire situations.`}</p>
    <p>{`Furthermore, you can't deny the usefulness of a Hydro applier. If you're familiar with Elemental Reactions, you will notice that Hydro is needed for 3 commonly used Elemental Reactions namely Freeze, Vaporize and Electro-charged. Hydro is really useful all in all!`}</p>
    <h2>{`Genshin Impact Tip #26: Elemental resonance do not take effect if you have less than 4 party members`}</h2>
    <p>{`In Genshin, each team allows up to 4 party members. You'll notice that having 2 characters of the same element in the team lights up said element's resonance in the party menu. This is called `}<RemoteLink to="https://genshin-impact.fandom.com/wiki/Elemental_Resonance" mdxType="RemoteLink">{`elemental resonance`}</RemoteLink>{`. Depending on the element, you will get a buff that helps to boost your team's damage.`}</p>
    <p>{`If you have 3 or fewer characters in your active team, said resonance will not take effect.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`All in all`}</h2>
    <p>{`This is not an exhaustive list, but I hope these tips are enough for you to get a better understanding of the game! I hope you enjoyed it!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      