import styled, { createGlobalStyle } from 'styled-components';
import { Link } from 'gatsby';

import $ from '../../styles/globals';
import atFanfictionChapter from './atFanficChapter';

const Container = styled.div`
  display: inline-flex;
  flex-direction: column;
`;

const SiteTitle = styled.div`
  font-family: Montserrat Semibold;
  text-transform: uppercase;
  color: ${$.color.orange};
  letter-spacing: 1px;
  font-size: 16px;
  background-color: ${$.white1};
  transform: translateY(0);
  transition: all 0.3s ease;

  ${$.screen.desktop} {
    &:hover {
      transform: translateY(-0.2em);
    }
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const NightMode = createGlobalStyle`
  body.night-mode {
    ${SiteTitle} {
      background-color: ${$.night};
      color: ${$.white1};
    }
  }
`;

const Logo = () => (
  <StyledLink to="/" title="Back to Geeknabe's Home">
    {atFanfictionChapter() && <NightMode />}
    <Container>
      <SiteTitle>Geeknabe</SiteTitle>
    </Container>
  </StyledLink>
);

export default Logo;
