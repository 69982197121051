export default () => {
  const language =
    typeof window !== 'undefined' ? window.navigator.language : '';

  switch (language.toLowerCase()) {
    case 'en-ph':
      return true;
    default:
      return false;
  }
};
