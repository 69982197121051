import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { createGlobalStyle } from 'styled-components';
import { CommentCount } from 'disqus-react';

import atFanfictionChapter from '../Navbar/atFanficChapter';
import $ from '../../styles/globals';
import CommentIcon from '../../assets/icons/comment.svg';
import config from './config';

const Container = styled.div`
  display: flex;
  flex-direction: ${({ swap }) => (swap === true ? 'row-reverse' : 'row')};
  align-items: center;

  span {
    color: ${$.brown4};
    font-size: 15px;
    transition: color 0.25s ease;
  }

  svg {
    width: 22px;
    height: 22px;
    fill: ${$.brown4};
    stroke: 0;
    ${({ swap }) =>
      swap === true
        ? `margin-right: ${$.layout.margin1}px;`
        : `margin-left: ${$.layout.margin1}px;`};
  }
`;

const NightMode = createGlobalStyle`
  body.night-mode {
    ${Container} {
      span {
        color: ${$.white1};
      }
      svg {
        fill: ${$.brown1};
      }
    }
  }
`;

const TotalComments = ({ title, slug, swap }) => {
  const disqusConfig = config({ title, slug });
  const [comment, setComment] = useState('');

  useEffect(() => {
    setComment(
      <>
        <CommentCount shortname="geeknabe" config={disqusConfig}>
          0
        </CommentCount>
        <CommentIcon />
      </>
    );

    return () => {
      setComment('');
    };
  }, []);

  return (
    <>
      {atFanfictionChapter() && <NightMode />}
      <Container title="Comment count" swap={swap}>
        {comment}
      </Container>
    </>
  );
};

TotalComments.defaultProps = {
  title: '',
  slug: '',
  swap: false,
};

TotalComments.propTypes = {
  swap: PropTypes.bool,
  slug: PropTypes.string,
  title: PropTypes.string,
};

export default TotalComments;
