import PropTypes from 'prop-types';
import styled from 'styled-components';

import $ from '../../../styles/globals';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: ${$.layout.margin3}px 0;
`;

const Bar = styled.div`
  width: 15px;
  align-self: stretch;
  margin-right: ${$.layout.margin2}px;
  background-color: ${$.color.green1};
`;

const Text = styled.p`
  margin: 0 !important;
  width: calc(100% - 15px - ${$.layout.margin2}px);
  font-family: Rubik Regular;
  font-weight: bold;
  font-size: 22px;
  line-height: 1.3em;
  color: ${$.brown3};
`;

const Blockquote = ({ children }) => (
  <Container>
    <Bar />
    <Text>{children}</Text>
  </Container>
);

Blockquote.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.element,
  ]).isRequired,
};

export default Blockquote;
