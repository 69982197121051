import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { LogoJsonLd } from 'gatsby-plugin-next-seo';

import { useFirebase, stats } from '../utils/firebase';
import noindex from './noindex.json';
import { on, off } from '../utils';
import Navbar from './Navbar';
import Footer from './Footer';
import $ from '../styles/globals';
import BackToTop from './BackToTop';
import blockAds from './Ads/block';

const StyledFooter = styled(Footer)`
  flex-shrink: 0;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  h1,
  h2,
  h3,
  h4 {
    font-family: Raleway Bold;
  }

  ${$.screen.desktop} {
    h1 {
      font-size: 3em;
    }
    h2 {
      font-size: 2.25em;
    }
    h3 {
      font-size: 1.75em;
    }
    h4 {
      font-size: 1.125em;
    }
  }

  ${$.screen.tablet} {
    h1 {
      font-size: 2.5em;
    }
    h2 {
      font-size: 2em;
    }
    h3 {
      font-size: 1.5em;
    }
    h4 {
      font-size: 1.125em;
    }
  }

  ${$.screen.mobile} {
    h1 {
      font-size: 2em;
    }
    h2 {
      font-size: 1.625em;
    }
    h3 {
      font-size: 1.375em;
    }
    h4 {
      font-size: 1.125em;
    }
  }
`;

const Body = styled.div`
  flex: 1 0 auto;

  ${$.screen.desktop} {
    > section {
      width: 1200px;
      padding-left: calc((100vw - 1200px) / 2);
      padding-right: calc((100vw - 1200px) / 2);
    }
  }

  ${$.screen.tablet} {
    > section {
      width: calc(100vw - ${$.layout.padding6}px * 2);
      padding-left: ${$.layout.padding6}px;
      padding-right: ${$.layout.padding6}px;
    }
  }

  ${$.screen.mobile} {
    > section {
      width: calc(100vw - ${$.layout.padding4}px * 2);
      padding-left: ${$.layout.padding4}px;
      padding-right: ${$.layout.padding4}px;
    }
  }
`;

const Layout = ({ children, location }) => {
  const firebase = useFirebase();
  const isProd =
    typeof window !== 'undefined' &&
    window.location.hostname === 'geeknabe.com';
  const [noIndexTag, setNoIndexTag] = useState(false);

  useEffect(() => {
    const runRouteChangedFunc = () => {
      if (isProd) {
        setTimeout(() => {
          stats().pageViews.set();
        }, 1500);
      }

      setNoIndexTag(() => {
        const addNoIndexTag =
          typeof window !== 'undefined' &&
          noindex.indexOf(window.location.pathname) > -1;

        return addNoIndexTag;
      });
    };
    const deferGoogleAd = () => {
      const script = document.createElement('script');

      script.src = '//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
      script.defer = true;

      document.body.appendChild(script);
    };

    if (typeof window !== 'undefined') {
      on(window, 'routeChanged', runRouteChangedFunc);
      if (!blockAds() && isProd) {
        deferGoogleAd();
      }
      setNoIndexTag(() => {
        const addNoIndexTag = noindex.indexOf(window.location.pathname) > -1;

        return addNoIndexTag;
      });
    }

    return () => {
      if (typeof window !== 'undefined') {
        off(window, 'routeChanged', runRouteChangedFunc);
      }
    };
  }, []);

  useEffect(() => {
    if (firebase && isProd) {
      setTimeout(() => {
        stats().pageViews.set();
      }, 1500);
    }
  }, [firebase]);

  return (
    <Container>
      <LogoJsonLd
        logo="https://geeknabe.com/static/logo-e6e50c9b64aa402b446a3cd0203f163a.png"
        url="https://geeknabe.com"
      />
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0"
        />
        {/* verifies pinterest that I own the site */}
        <meta
          name="p:domain_verify"
          content="a3dcae92f1f64ff5ddf05f4d7caf3880"
        />
        {noIndexTag && <meta name="robots" content="noindex" />}
      </Helmet>
      <Navbar location={location} />
      <Body>{children}</Body>
      <StyledFooter />
      <BackToTop />
    </Container>
  );
};

Layout.propTypes = {
  location: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Layout;
