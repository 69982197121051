import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXFanfiction/Chapter/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const RemoteLink = makeShortcode("RemoteLink");
const AuthorNote = makeShortcode("AuthorNote");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`“Oh Goddess, D.B! `}<em parentName="p">{`seriously`}</em>{`? Right `}<em parentName="p">{`now`}</em>{`?” Agent B, known as Byleth once upon a time, said exasperatedly at the 6 '2 naked human man stumbling out of a bush into the middle of the street. Luckily it was in the dead of the night and no one was around.`}</p>
    <p>{`She pulled out a pill from her inner coat pocket, crushed it in her palms, spit into her hands, and the particles erupted into a black trenchcoat. She tossed the coat at Agent DB.`}</p>
    <p>{`Semi-amused, somewhat annoyed, he shrugged into the trenchcoat. His naked human form embarrassed him. Agent D.B was biologically a shapeshifting alien dog from the planet Blaiddyd in the Areadbhar Galaxy, 34 light-years away. His given name was Dimitri...or at least the human version of his alien name was Dimitri. B just stood for Blaiddyd, signifying his origin planet, since there was already a senior Agent D.`}</p>
    <Ads mdxType="Ads" />
    <p>{`On Earth, he was a golden coloured `}<em parentName="p">{`miniature`}</em>{` Pomeranian and spoke in fluent dog and Blaiddydenese or a garbled mix of both, which unfortunately was not one of the 14 different alien languages Byleth spoke. Hence the need for a translator device - in this case, it took the form of a dog collar with spikes and a bell.`}</p>
    <p>{`All the humans thought D.B was just a yappy little dog, but he was actually having serious conversations with Agent B.`}</p>
    <p>{`While the spikes were a necessity in case of an actual dog or more likely, if some other alien predator decided he was a particularly delicious-looking snack.`}</p>
    <p>{`It happened before, and it was `}<em parentName="p">{`not`}</em>{` a pleasant trip for Agent B that night when she had to extract him from the bowel of an exceptionally slimy and bloated extraterrestrial that swallowed him whole. The spikes would’ve made it easier for D.B to tear himself out instead of needing assistance.`}</p>
    <p>{`“I’m sorry, but you know what the smell of bubblegum does to me,” he said apologetically, adjusting the translator collar around his neck. Which thankfully, was an elastic so he didn’t choke to death when he transformed into a human. It also housed a tracking device in case he got lost since it was a violation of his species to have microchips implanted in them.`}</p>
    <p>{`She was most thankful he wore the trenchcoat without a fuss this time before someone decided to call the human police on them `}<em parentName="p">{`again`}</em>{` - like the last time he was naked. The situation would’ve gotten worse if he and Agent B didn’t `}<RemoteLink to="https://meninblack.fandom.com/wiki/Neuralyzer" mdxType="RemoteLink">{`wipe their memory`}</RemoteLink>{`. There were no humans here but still, they didn’t want to take chances.`}</p>
    <p>{`He said sometimes it was really hard to tell when he should be clothed - being a dog meant he was always naked and he `}<em parentName="p">{`hated`}</em>{` being clothed (a notion he strongly objected to). But for the sake of the mission and MIB’s reputation, he had no choice but to be.`}</p>
    <p>{`D.B didn’t have control over his transformation; he was highly allergic to the smell of lemon flavoured bubblegum `}<em parentName="p">{`after`}</em>{` it was chewed and spat out which caused him to violently sneeze, and sneezing was the trigger to his transformation. A couple of years in service with MIB rendered Agent B numb in regards to the strangeness these ‘foreigners’ exhibited.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Originally, D.B was unfortunately duped by someone on his home planet and gave him a fake visa to Earth, causing him to be arrested and detained at MIB customs. Agent B, while never once displayed any outward emotions, an ability that made her a prized agent of MIB, could not resist but fawn over the pitiful, small, chained up teacup pomeranian in a jail cell.`}</p>
    <p>{`Inhabitants of the Planet Blaiddyd were highly talented detectors - much like real dogs only more perceptive and sentient. Agent B negotiated with Agent R`}{`*`}{` for his release, citing that she lacked a partner for a while and MIB could use someone of his skills - tracking down rogue aliens before they could cause trouble was a valuable skill.`}</p>
    <p>{`Agent B and D.B were partners, and due to the fact D.B spent most of his time as a Miniature Pomeranian, they both lived in the same apartment. D.B slept at the foot of her bed like a real dog. But boy, the first time she discovered his trigger was chewed up bubble gum that he could smell from the neighbour's apartment `}<em parentName="p">{`three doors away`}</em>{`, made her scream and almost disintegrated him when she discovered a large, naked human man warming up to her in her bed.`}</p>
    <p>{`“Anyway, let’s go home, I’m tired.” She yawned and stretched.`}</p>
    <p>{`D.B chuckled, “That `}<em parentName="p">{`Rieganite`}{`*`}{`,`}</em>{` Claude, was a handful huh? Kept trying to poison us just to- `}<em parentName="p">{`watch out`}</em>{`!” Byleth felt the wind get knocked out of her when Dimitri used his entire human body to shove her out of the way just as a ginormous acidic ball of spit razed the very spot she was standing a split second ago.`}</p>
    <p>{`“Get down!” She yelled at Dimitri who was on top of her to duck, as she pulled out her blaster from the gun holster hidden under her blazer and fired a volley of shots at a `}{`*`}{`Hegemonan`}{`*`}{`*`}{` that fell out of a sky - it was a humanoid-shaped reptilian, avian looking extraterrestrial with two curling horns emerging out of its head, giant black feathers, and scaly frame.`}</p>
    <p>{`All in all, it was a rather demonic-looking creature, more could be said to describe it but Agent B and D.B needed to try and arrest it or, if the need arises, kill it. The descriptions could come later when she submitted her report.`}</p>
    <p>{`“It’s a rogue Hegemonan!” D.B said, pushing himself off her when it nimbly dodged all her shots with its ability to fly. He ran towards it with his fangs bared at it as he leaped into the air and latched himself to it. The creature let out a nasty-sounding shriek when D.B made contact with it.`}</p>
    <p>{`Blaiddydians were also gifted with immense strength and agility in their non-canine form. But even as a dog D.B was unusually strong for a smaller-than-usual pomeranian, he actually destroyed `}<em parentName="p">{`all`}</em>{` his little doggy tug-of-war toys when they played together.`}</p>
    <Ads mdxType="Ads" />
    <p>{`He tried to sink his teeth into its neck but D.B didn’t seem to be able to penetrate its thick scaly armour. Still, he held onto it tightly even when it tried to use the sharp ends of its wings to stab him. He threw his fists at it in multiple places to try and break through its hard exterior, and to prevent it from spitting out that acidic ball attack once more.`}</p>
    <p>{`The Hegemonan used its ridiculously long, boney digits to pry D.B off of it, and then in the middle of its torso, a mouth opened up.`}</p>
    <p>{`“It’s trying to eat you, D.B! Stop struggling, I can’t get a good shot!” She shouted at him, “On my count, play-dead, just like we do at home!”`}</p>
    <p>{`“O-`}<em parentName="p">{`ugh`}</em>{`-kay!!” He managed to grunt as he pressed his feet on either side of its mouth, trying his best not to get eaten, `}<em parentName="p">{`again`}</em>{`.`}</p>
    <p>{`She ran after it as it started flying away with her partner, she got as close as she could before stopping abruptly to take a good aim at it. She held her breath and counted down, “3...2...1, `}<em parentName="p">{`now D.B`}</em>{`!” She yelled, and immediately D.B went limp, making the Hegemonan pause, probably wondering why it's dinner suddenly stopped fighting.`}</p>
    <p>{`Nevertheless, it gave Agent B that split-second window of opportunity she needed as she squeezed the trigger of her space gun, hitting the rogue extraterrestrial square in its head - destroying it as it turned into a pile of goo.`}</p>
    <p>{`D.B fell to the ground, smack in the middle of the Hegemonan remains. He groaned, his face showing evidence of pain and disgust once he realised what he was lying in. Agent B ran over and helped him to his feet. He was completely covered in dead alien gunk and he did not smell agreeable either. She gagged slightly when the smell wafted around her.`}</p>
    <p>{`She shook her head undoing the belt and buttons of his trenchcoat, “It’s absorbing the smell,” she said, helping him remove the offending piece of clothing. D.B happily obliged and tossed the damned thing as far as he could. But seeing as Agent B had run out of trenchcoat pills, D.B was left naked once more.`}</p>
    <p>{`Not that he minded, she thought.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Ummm...right. Just...try and make yourself sneeze while I contact H.Q for clean up,” she managed to say, trying her best to not look down at his lower regions. He nodded at her, turning away to probably find the original source of the spat-out bubblegum that made him sneeze earlier.`}</p>
    <p>{`“Hello?” She said into her MIB-issued communication device, “Yeah, it’s me, Agent B, uhh...can I get a clean-up crew in Garreg Mach Park? It was a rogue Hegemonan...yes. Yes, no, we’re fine. Okay, got it.” She flipped her communication device close when the conversation ended. She started casing the perimeter for any stray humans in the area.`}</p>
    <p>{`Apart from a few homeless people completely asleep in their tents and makeshift homes, there was no need to memory-wipe anyone. She walked back round to their car which took a while since they were dealing with a Rieganite before this and then the Hegemonan.`}</p>
    <p>{`At the foot of the driver’s seat door of the standard-issue MIB vehicle, was a tiny, round golden fluff ball sitting next to it.`}</p>
    <p>{`“Hey, D.B,” she bent down and scratched the back of his ears, “Great job today, are you hurt anywhere?”`}</p>
    <p>{`The collar around his neck transmitted a translation that directly fed into her earpiece, “`}<em parentName="p">{`Might’ve sprained a wrist when I landed,`}</em>{`” the little dog barked, and then held out its right front paw. She gingerly took it into her palm but he pulled it back in pain when she tried to examine it and let out a yelp.`}</p>
    <p>{`“`}<em parentName="p">{`Now that the adrenaline is wearing off, it actually hurts now`}</em>{`,” he said, letting out a whine. While Agent B was not one to express any sort of emotions, her heart ached at the sight of the tiny, wounded dog in front of her. He was her partner yes, but deep down Agent B was a huge animal lover.`}</p>
    <p>{`She was especially soft-hearted for D.B, and he was probably the only one she shared a proper, non-work conversation with. She even smiled and laughed with him, especially when he did something cute or did tricks.`}</p>
    <p>{`Agent B scooped him up in her arms, taking great care not to agitate his wrist, and placed a small kiss on the top of his head. She made a face, “Mmm, you need a bath,” she said as she got into the car and placed him on her lap. He barked angrily at her in response.`}</p>
    <Ads mdxType="Ads" />
    <p><em parentName="p">{`“No bath, I smell fine!”`}</em></p>
    <p>{`She snorted, “Suit yourself. Don’t come crying to me tonight when you can’t stand the smell of a dead alien in your fur.”`}</p>
    <p>{`The teacup pomeranian pouted at her, unleashing the full force of its puppy eyes on her. She was weak against his puppy eyes, but she tried her best to resist. She knew what he required of her whenever she had to give him a shower.`}</p>
    <p>{`It wasn’t so bad when he was a human, but D.B hated baths as a dog.`}</p>
    <p>{`D.B let out another whine and she instantly fell for his pitiful look, “...Alright but only `}<em parentName="p">{`one`}</em>{` strip of bacon okay? Just one, after a good scrub, promise?” He let out a delighted ‘Arf!’`}</p>
    <p>{`She smiled at him before setting him down in the passenger seat and put the custom-designed dog seat belt on him. Giving him a good solid head scratched before she started the engine.`}</p>
    <p>{`To everyone else, it was merely a girl and her dog. But Agent B and D.B wouldn’t trade their special bond for anything else in the universe.`}</p>
    <AuthorNote mdxType="AuthorNote">
  <p>Notes; </p>
  <p>
    *Rieganite as in Claude Von Riegan (what type of alien he is up to your
    imagination 🥰)
  </p>
  <p>*Hegemonan  - an alien inspired by Hegemon Edelgard </p>
  <p>*Agent R is Rhea XD</p>
  <p>
    - Byleth keeps spare new lemon bubble gum to chew and spit out to instantly
    transform Dimitri, he’s like her ace/ power source XD
  </p>
  <p>-behold, dimitri 💖💖</p>
  <Image alt="" name="dimitri-pomeranian.jpg" mdxType="Image" />
    </AuthorNote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      