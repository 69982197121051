import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I haven’t been blogging that often lately due to one thing and one thing only… That’s right! Clearing Genshin Impact’s latest map - Inazuma! I’ve been following the patches really closely and clearing the maps as soon as possible. My close friends who are in this game as well told me to slow down and enjoy the patch because this is all we’re getting for the month. That's fair advice but I’m the type to clear/find as many quests, puzzles and chests as I could for those delicious primogems. You see… While the game doesn’t rush you to clear the new maps as soon as possible, the character event banners are on a strict time limit ): The things I do for that shiny 5-star character…`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Genshin Impact Inazuma Islands" caption="100% completion for all the islands! I think I missed a few chests since they are hidden way too well. Even in Liyue I found 1085 treasure chests just for those primogems." name="genshin-impact-2.1-inazuma-islands.jpg" mdxType="Image" />
    <p>{`Regardless, Inazuma is one different cookie compared to the previous 2 maps we’ve been looking at for the past year. It’s more intense, packed and favors verticality more than ever. You will find yourself going up and down just to get that Electroculus. Puzzles are much harder and super fun to clear once you understand what’s up. Enemies are everywhere and that is good for bloodthirsty players like me. These islands are so packed with goodies, it’s so much easier and rewarding to explore! There aren’t any “useless” treasure chests either. Each treasure chests give you Electro Sigil which you could then use to unlock the Sakura Tree for even more rewards. There’s this great synergy between exploration and rewards in Inazuma which both Mondstadt and Liyue lack. It’s okay to miss the plentiful chests in Liyue and Mondstadt but in Inazuma? Nay, I need that last Electro Sigil for the last Sakura Tree reward! I am having a ton of fun trying to clear the maps, quests and puzzles.`}</p>
    <p>{`The World Quests in Inazuma feel super personal and serve to explore the effects of Vision Hunt Decree on the common folk. Both Liyue’s and Mondstadt’s archon lore talks about the Archon War a lot, so we get lots of snippets about what happened back then. But those happened so long ago, it feels as though these archons are just recounting the past that feels way too distant. Inazuma’s lore, however, talks a lot about what happened 500 years ago. Due to how recent it is compared to the Archon War, the effects of the Cataclysm is still being felt in present Inazuma. For example, I got super invested in the story while playing the Sakura Tree cleansing quest. The developers went the extra length for the quest's final battle. I honestly do not think they need to add the battle gimmick, but I appreciate it all the same. The whole battle gimmick honestly feels like what Kingdom Hearts would do.`}</p>
    <Ads mdxType="Ads" />
    <p>{`The Higi Village quest gave me goosebumps once I realized the truth behind the village's horrifying end. I expect the story to go all in when it comes to the Vision Hunt Decree's process but I did not expect the writers to go this route... There’s a ton of quests (E.g. the pharmacists that went missing after giving him Naku Weeds all day) that requires you to piece the information together to understand what’s going on… Never interacting with the NPC or seeing the character itself in the first place really cements the sense of foreboding. I know the pharmacist is probably dead but the game never explicitly mentions that. Another quest that hits me hard is a soldier who left the Shogunate army because he couldn’t stomach what he did. We followed him all the way to his final moments. Once again, we could only surmise what happened because we never saw the poor bloke at the end of the quest. I hope Sumeru’s story won’t do this to me again ):`}</p>
    <p>{`The World Quests are all brilliant in their own ways, serving to tell the truth behind Inazuma’s old protectors and the horrifying outcome of the Vision Hunt Decree on the common folk… But what about the Archon Quests? I think the first part of the archon quest is pretty great. We were first told to run around and help those who lost their Visions to convince the Traveler (and you, the player) that you `}<strong parentName="p">{`really should`}</strong>{` help them win against Raiden Shogun. It started off slow before quickly picking up by running off to the Resistance. Now here’s where I scratch my head. These Resistance folks are super welcoming, yea? I mean, we went from a fugitive to a captain. We then spent 5 minutes with the Swordfish II team before being waltzed off to fight Raiden Shogun. What’s with the rush? Who is in charge of the writing because I have serious problems with the plot development for the archon quest’s later parts. `}<strong parentName="p">{`Sheesh`}</strong>{`.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Problems with the main story aside, I’m happy they slotted in so many of the Inazuma characters into the story. Thoma, Sayu, Ayaka, Sara… and even Yoimiya gets some highlight. I hope they continue this trend for Sumeru! Speaking of characters, everyone and their grandmothers are doing a ton of complaining on the Internet… about these 5-star characters’ damage output and utility. I wish to talk about what I personally think about these characters! I can’t speak for Ayaka (I do not think there are a lot of complaints about her) since I don’t have her, but I can explain Yoimiya, Kokomi and Raiden since I manage to pull them.These three characters bring a whole different gameplay that most folks aren’t used to. Previously, the meta has always been about having 1 DPS with 3 supports doing off-field damage or swapping them in to do their burst/buff before swapping in the DPS. For national team players, you keep swapping in characters to use their Elemental Burst (Q) again and again. As the meta stands, a DPS character should be the one carrying the team. Either that, or the characters should have Elemental Bursts that do high damage so they could keep getting swapped in and out for max DPS.`}</p>
    <p>{`However, the latest 5-star characters aren’t geared to be played in that manner. Their Q, specifically Kokomi’s and Raiden’s require them to be on field to be fully utilized. Yoimiya’s strength lies in her Elemental Skill (E) but her Q is, as many players mentioned, `}<em parentName="p">{`barely useful`}</em>{`. For folks who love hyper carry characters or a Q-based quick swap team, these characters are rubbish!`}<em parentName="p">{` … Or are they?`}</em></p>
    <p>{`I’m telling ya, I love all three of my Inazuma characters - Yoimiya, Kokomi and Raiden. At a glance, I could tell just what miHoYo’s balancing team is trying to do. These are driver/enabler characters. Not exactly hyper carries or strong Q characters (Except Raiden, maybe). They are meant to keep your team going during skill cooldowns so once your teammates are ready, you swap them out to continue the whacking rush. Unlike the older characters that couldn’t do much damage during your Q’s and E’s downtimes, these Inazuma characters do sustained damage and enable off-field supports to keep the damage up. For example, Yoimiya’s E lasts for 10s but her cooldown is 8s. Once her E is on cooldown, you swap her out for other characters to do their own thing. Setup off-field supports, setup buffs… Yoimiya’s cooldown should be over by then when you're done. You swap her back in to continue the cycle.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Yoimiya Build" caption="C0 LVL90 Yoimiya's Talent is at 10/9/9, using Thundering Pulse R1. Her artifact is 2 set Gladiator, 2 set Crimson Witch. Focus on her ATK, CR, CD and you're good to go. With R1 Rust, you can hit steady 12K damage at her strongest auto attack hit during her E. Of course, with an R5 Rust you can hit even higher. I've seen R5 Rust Yoimiya user hit 16K to 20K at her strongest auto attack hit during E! Even her non-E damage is pretty good. 8K, 6K or so. If you can get a super good Shimenawa set, you can potentially hit higher numbers. In the end, substat is what do the talking for Yoimiya. All the best!" name="genshin-impact-yoimiya-build.jpg" mdxType="Image" />
    <p>{`I have the most fun with Yoimiya because she feels like a catalyst user with sustained DPS. Her physical damage is not bad. She can hit much harder than my Ningguang who is a charge attack/Q spammer. Plus, she hits faster too. The best fun I had with Yoimiya is using her with Overvape (Overload + Vaporize). Everything in the overworld just dies before they get the chance to attack. In Spiral Abyss, I had an easy time against Maguu Kenki because I could keep my distance and do sustained damage.`}</p>
    <p>{`As for Raiden, I got really, really lucky. I manage to get her to C4 and her one-hit damage is nothing to scoff at. I think she’s really great for that damage per screenshot even at C0. She could potentially out damage my well-invested Zhongli’s Q bot with a R5 The Catch. Raiden has a lot going for her! She buffs your teammates’ Q, recharges your team during her Q and does great damage `}<em parentName="p">{`super fast`}</em>{`. If I were to compare her with Eula, I think folks would prefer Raiden’s easier build up to do a big one-shot damage. Eula still needs to whack a bit before her massive damage comes into play. Raiden as a whole is really great even at C0! I personally built her with a bit of Elementary Mastery just so her off-field E could do some damage.`}</p>
    <Image alt="Raiden Shogun Build" caption="Raiden at C2 is a massive damage improvement. Even with R5 The Catch, you can hit 100K easily with a C2 Raiden. I am using Engulfing Lightning R1, Raiden C4 LVL90 and 4 set EOSF artifacts. Talent is at 6/8/13. At full resolve stack, I could easily hit 150K without any help. It's an absolute no-brainer to run Raiden with The Catch and Emblem of Severed Fate  (EOSF) artifact set. I've seen an invested Raiden C0 with R5 The Catch hit 80K with her Q. Even my fully invested Zhongli Q bot could only hit 60K!" name="genshin-impact-raiden-build.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <p>{`Kokomi on the other hand, is receiving a ton of backlash just because she can’t crit. Strange… I could have sworn hitting a steady 7K to 8K per auto attack during Q is pretty hard even for Barbara. But I do get that Kokomi is a niche character. I agree there are many 4-star characters who could do what she could do. However! If you don’t have a dedicated healer like Jean or Qiqi, she’s great. Then again, even if you do have Jean or Qiqi, Kokomi is still great to have. This is not my bias speaking but let me highlight some points for ya. Point 1, she could outheal Azhdaha’s damage easily (7K per tick using jellyfish at C0). Point 2, she’s basically Childe on E (enabler character) but nothing can outdo her healing and thus, making her disgustingly `}<em parentName="p">{`tanky`}</em>{`. What shield? Just heal! Point 3, she’s super easy to build for F2P. Slap on HP%/Hydro%/Healing Bonus for her artifacts and you’re ready to go.`}</p>
    <Image alt="Kokomi Build" caption="C0 Kokomi with Sacrificial Fragments, 4 set Heart of Depth set. Talent at 6/6/6 because I have no drops from La Signora yet. She scales better with Healing Bonus. I am using HP/Hydro/HP at the moment because I ate all of my Healing Bonus artifacts... Right now, my jellyfish heals at 4K while during Q, her auto attack hits for 5K. I'm not done building her so she has more room for improvement. The only reason I opt for Sacrificial Fragments is because I want her jellyfish to have more uptime in case I couldn't ready my Q in time. Also, the purple-blue ish colour looks great on Kokomi. A E S T H E T I C S. A bit of EM will help when I do reactions with Kokomi!" name="genshin-impact-kokomi-build.jpg" mdxType="Image" />
    <p>{`All three characters are honestly super F2P friendly. Yoimiya does splendid damage even with a Rust/Hanayumi. Raiden does fine at C0 because R5 The Catch and EOSF artifact exists. Build her well and she could deal good damage! Kokomi is F2P friendly just like Albedo. Albedo’s BIS weapon is a 3-star while you could give Kokomi Thrilling Tales or Prototype Amber which.. I am sure everyone has at least 1 copy. Plus, Kokomi is more than welcomed during co-op. I am personally not too sure what everyone is complaining about save for the faulty auto aim mechanics (which I agree with) plaguing archer characters in this game… I think everyone’s spoiled to a fault since Youtubers could do 100K damage easily, thus skewing expectation that all 5-star characters must do the same at C0. I think everyone is forgetting that most casual players don’t min-max like crazy or dedicate their build to clearing Spiral Abyss. All three are fine at C0 for overworld, plus their sustained damage is easy to reach even with 4-star weapons. Remember to have fun and pull for whoever you want!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`All in all`}</h2>
    <p>{`Inazuma as a whole is pretty great. Each island has their own distinct vibe. Music is pretty complex, more intense and super varied. Puzzles are getting smarter. Just a tip, those rotating boxes only require a few hits to match the petals actually. You just need to observe properly. This is true for the rotating rock puzzle in Seirai Island. The sudoku puzzle on Watatsumi Island, however…World quests are great but the archon quest plot is rather unfortunate. It feels like these two quest lines got two different writing teams on it. Inazuma characters are really fun to play and bring different gameplay to the table. I hope they keep things up for Sumeru characters too! I’m writing this when 2.1 is out so we still have 1 more Inazuma island to explore. I hope it’s worth the wait!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      