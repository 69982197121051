import { useState, useRef } from 'react';
import styled, { createGlobalStyle } from 'styled-components';

import TopBar from './TopBar';
import atFanfictionChapter from './atFanficChapter';
import $ from '../../styles/globals';

const Container = styled.div`
  z-index: 9999;
  background-color: ${$.white1};

  > div {
    background-color: inherit;
  }
`;

const NightMode = createGlobalStyle`
  body.night-mode {
    ${Container} {
      background-color: ${$.night};
    }
  }
`;

const Navbar = () => {
  const [burgerMenu, setBurgerMenu] = useState(false);
  const containerRef = useRef(null);

  return (
    <Container ref={containerRef}>
      {atFanfictionChapter() === true && <NightMode />}
      <TopBar burgerMenu={burgerMenu} setBurgerMenu={setBurgerMenu} />
    </Container>
  );
};

export default Navbar;
