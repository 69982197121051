import { useState, useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import PropTypes from 'prop-types';
import posed from 'react-pose';

import $ from '../../../styles/globals';
import atFanfictionChapter from '../../Navbar/atFanficChapter';
import HeartIcon from '../../../assets/icons/heart.svg';
import { roundUp } from '../../../utils';
import { stats, useFirebase } from '../../../utils/firebase';

const Pressable = posed.div({
  pressable: true,
  init: { scale: 1 },
  press: { scale: 0.9 },
});

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${$.screen.mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const HeartItems = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  user-select: none;
  margin-left: ${$.layout.margin2}px;

  ${$.screen.mobile} {
    margin-left: 0;
  }
`;

const Button = styled(Pressable)`
  pointer-events: ${({ disabled }) => (disabled === true ? 'none' : 'initial')};

  &:hover {
    cursor: pointer;
  }

  ${$.screen.desktop} {
    &:hover {
      svg {
        opacity: 0.8;
        transform: scale(1.3);
      }
    }
  }

  > svg {
    fill: ${$.color.red1};
    width: 24px;
    height: 24px;
    transition: all 0.3s ease;
    transform: scale(1);
  }
`;

const Total = styled.div`
  font-size: 15px;
  margin-left: ${$.layout.margin2}px;
  color: ${$.color.orange};
`;

const Message = styled.div`
  font-size: 15px;
  font-family: Rubik Regular;
  color: ${$.color.red2};
  line-height: 1.3em;

  ${$.screen.mobile} {
    margin-bottom: ${$.layout.margin2}px;
  }
`;

const NightMode = createGlobalStyle`
  body.night-mode {
    ${Message} {
      color: ${$.color.orange};
    }
    ${Total} {
      color: ${$.white1};
    }
  }
`;

const HeartIt = ({ hearts, setHearts, text }) => {
  const [addedHearts, setAddedHearts] = useState(0);
  const firebase = useFirebase();
  const [message, setMessage] = useState('');
  const addCounter = () => {
    setHearts((prev) => {
      const newPrev = { ...prev };
      const evt = new CustomEvent('syncHearts', {
        detail: roundUp(prev.realTotal + 1),
      });

      window.dispatchEvent(evt);

      newPrev.realTotal = prev.realTotal + 1;
      newPrev.shown = roundUp(newPrev.realTotal);

      return newPrev;
    });

    setAddedHearts((prev) => prev + 1);

    if (addedHearts < 501 && firebase) {
      stats().hearts.set();
    }
  };

  useEffect(() => {
    const setAndClearMessage = (mssg) => {
      setMessage(mssg);
      setTimeout(() => {
        setMessage('');
      }, 2000);
    };

    switch (addedHearts) {
      case 1:
        setAndClearMessage('Thanks!');
        break;
      case 20:
        setAndClearMessage('Thank you!');
        break;
      case 42:
        setAndClearMessage('42? Check back in 7.5 million years.');
        break;
      case 69:
        setAndClearMessage('69 hearts. Nice.');
        break;
      case 100:
        setAndClearMessage("That's a lot!");
        break;
      case 200:
        setAndClearMessage('200! Wow!');
        break;
      case 300:
        setAndClearMessage('300? SPARTAAAAA!');
        break;
      case 400:
        setAndClearMessage("400? I'm flattered...");
        break;
      case 420:
        setAndClearMessage('420! Ehek.');
        break;
      case 500:
        setAndClearMessage(
          'Error. Love overfilling, counter will not update any longer.'
        );
        break;
      default:
        break;
    }
  }, [addedHearts]);

  return (
    <Container title="Click to heart this post!">
      {atFanfictionChapter() && <NightMode />}
      <Message>{text}</Message>
      <HeartItems>
        <Button
          disabled={hearts.shown === '-'}
          onClick={() => {
            addCounter();
          }}
        >
          <HeartIcon />
        </Button>
        <Total>
          {message === ''
            ? `${hearts.shown} ${
                hearts.shown <= 1 ? 'heart' : 'hearts'
              } received`
            : message}
        </Total>
      </HeartItems>
    </Container>
  );
};

HeartIt.propTypes = {
  text: PropTypes.string,
  hearts: PropTypes.shape({
    shown: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    realTotal: PropTypes.number,
  }),
  setHearts: PropTypes.func,
};

HeartIt.defaultProps = {
  text: 'Enjoyed the post? Click to give us hearts!',
  hearts: { shown: '-', realTotal: 0 },
  setHearts: () => {},
};

export default HeartIt;
