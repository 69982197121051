import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const RemoteLink = makeShortcode("RemoteLink");
const Ads = makeShortcode("Ads");
const Blockquote = makeShortcode("Blockquote");
const Image = makeShortcode("Image");
const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`In a bid to improve my Yoimiya build, I was looking around reddit and `}<RemoteLink to="https://keqingmains.com/yoimiya/" mdxType="RemoteLink">{`Keqingmains`}</RemoteLink>{` to see if they have any good suggestions. To my utmost horror, Keqingmains suggested an Elemental Mastery (EM) build for Yoimiya. I'm a bit disheartened to read how everyone thinks that the best way to improve her damage is by boosting her EM so she could do bigger numbers from Elemental Reactions.`}</p>
    <p>{`But the thing is... Yoimiya's reaction numbers at C0 will never best C0 Hutao unless you whaled for her constellation and weapon. Plus, in order for her to hit those big numbers per auto attack when it comes to vaping, Yoimiya's artifacts must be perfect, her weapon should be the 5-star bow Thundering Pulse, plus her teammates must include Kazuha somehow. Man, who's going to have all that? I got lucky with my weapon banner pull and got myself a Thundering Pulse bow. Unfortunately, I don't have a Kazuha. I did try using Sucrose for the shred but I didn't enjoy the team synergy that much.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Besides, trying to balance EM with Crit Rate, Crit Damage and ATK is really `}<em parentName="p">{`tough`}</em>{`. How long do you need to farm perfect artifacts to get that nice 1:2 crit ratio along with a high amount of EM in the artifact's substat? If you manage to get EM, then that's good. Otherwise, it is not important.`}</p>
    <p>{`Building EM specifically means you need to pair Yoimiya with another character to get the best numbers from reactions. I don't think it's a good choice to lock her in like that since the best fun is to use the character however you want. It's not exactly fun when you know her numbers aren't going to be high anyway ): I like seeing big numbers from the individual character alone so I will play Vape or Melt `}<strong parentName="p">{`if`}</strong>{` the character's biggest strength is from reactions. However, Yoimiya simply isn't built like that.`}</p>
    <p>{`Your best bet is to focus on Yoimiya's strength... and that is pure, raw Pyro DPS per auto attack. Watching her hit really high numbers `}<strong parentName="p">{`really fast`}</strong>{` is super satisfying. Her raw Pyro numbers is way higher than Hutao's too! Using Yoimiya in co-op is super enjoyable (because I keep seeing better numbers from her than a solo Hutao haha)`}</p>
    <p>{`All things aside, if you take a `}<RemoteLink to="https://genshin.honeyhunterworld.com/db/char/yoimiya/?lang=EN" mdxType="RemoteLink">{`good look at our fireworks girl`}</RemoteLink>{`, there are many things going for her.`}</p>
    <ol>
      <li parentName="ol">{`Her auto attack DMG % (at LVL 10, 5th hit is at 188.87%) is the highest among the archers (at v2.3). Coupled with her E, it hurts `}<strong parentName="li">{`a lot`}</strong>{`. It's possible to hit 10K per auto attack, with her 5th (strongest) shot hitting 20K without reactions.`}</li>
      <li parentName="ol">{`Her E has a cooldown of 8s. It's more than enough time for you to swap her out, set things up before swapping her in to continue shooting with her E.`}</li>
      <li parentName="ol">{`Her Q is designed for off field characters to hit and it's handy because it doesn't matter if she's on the field or not! As long as someone off field can hit the enemy, it gets triggered. 20K damage every 2s is pretty good, honestly.`}</li>
      <li parentName="ol">{`Her ascension stat is crit rate. This makes it really easy to build her crit ratio.`}</li>
      <li parentName="ol">{`Her E could juggle enemies! Notice how lighter enemies goes flying whenever Yoimiya starts shooting with her E?`}</li>
      <li parentName="ol">{`As an archer, she could shoot from afar comfortably and not be in the line of fire. I find this really useful in Abyss since I don't need to chase after enemies.`}</li>
    </ol>
    <Ads mdxType="Ads" />
    <p>{`I'm really not so sure why Yoimiya is getting flak from the Genshin community when she is first released since she's so, so good. If you build around her Crit Rate, Crit Damage and ATK, she's actually really nice to use even as a solo archer... which brings me back to the point of this post.`}</p>
    <p>{`Abandon ye hope to give her an EM build and instead build her so she could fit into whatever team composition you can think of with her deliciously strong, raw Pyro auto attacks.`}</p>
    <p>{`Then, fit her into a team with plenty of off field turrets for the max fun. If folks are complaining she's single target, just add Overload into the mix! Everyone calls this the fireworks team. I swear, this team composition is the best fun I ever had. You just keep the flow going! Individual damage be damned because everyone's pew-ing along with Yoimiya. Surely everything dies if you keep slapping it with some numbers, right? Throw in some elemental reactions too, even if the numbers are on the low side.`}</p>
    <Blockquote mdxType="Blockquote">
  Having lots of low numbers will add up to big numbers, amirite?
    </Blockquote>
    <p>{`Here's my suggestion on how to build Yoimiya as a Pyro DPS. Focus on her crit ratio and attack, and you're good to go!`}</p>
    <h2>{`Yoimiya Build #1: Artifacts`}</h2>
    <p>{`I'm aware that many folks say `}<RemoteLink to="https://genshin.honeyhunterworld.com/db/art/family/a_15019/?lang=EN" mdxType="RemoteLink">{`4-set Shimenawa`}</RemoteLink>{` is her best set, but I disagree. Her best generalist set is 2-set `}<RemoteLink to="https://genshin.honeyhunterworld.com/db/art/family/a_15001/?lang=EN" mdxType="RemoteLink">{`Glad`}</RemoteLink>{`/Shimenawa and 2-set `}<RemoteLink to="https://genshin.honeyhunterworld.com/db/art/family/a_15006/?lang=EN" mdxType="RemoteLink">{`Crimson Witch`}</RemoteLink>{`, ATK/Pyro/Crit Damage or Crit Rate. Here's why:`}</p>
    <ol>
      <li parentName="ol">{`You save your sanity by reducing the need to keep farming for the perfect 4-set artifact. If you want Yoimiya to hit really hard, she needs to have a good crit ratio! It's easier to get a good artifact set from 2 by 2 set. Plus, 4-set effect is not super fun anyway. That's because...`}</li>
      <li parentName="ol">{`You shouldn't dismiss Yoimiya's Q potential damage in any team, really. It gives extra damage if you run an off field turret with Yoimiya. Plus, Q is a nice emergency i-frame button. It's useful for running Yoimiya in Spiral Abyss to dodge attacks.`}</li>
      <li parentName="ol">{`Tracking if she has 15 or more energy before using her E is not fun. Conditional passive can be strong, but if you can never get the passive at all times, why use it in the first place?`}</li>
    </ol>
    <p>{`2-set Glad/Shimenawa and 2-set Crimson Witch is by far, easy to get and gives decent raw damage to Yoimiya. I would definitely recommend this set if you enjoy playing Yoimiya without conditional effects. But if you wish to know what are other artifact sets Yoimiya can use, then here's a list:`}</p>
    <Ads mdxType="Ads" />
    <h3>{`Artifact Choice #1: 4-set Bolide`}</h3>
    <p>{`I've seen some folks using `}<RemoteLink to="https://genshin.honeyhunterworld.com/db/art/family/a_15015/?lang=EN" mdxType="RemoteLink">{`this set`}</RemoteLink>{`. It works if you could keep up a shield at all times but given how bad shield upkeep is (unless you have Zhongli), the condition for the 4-set passive is tough to fulfill. You're better off using 2-set Glad/Shimenawa and 2-set Crimson Witch or 4-set Crimson Witch!`}</p>
    <h3>{`Artifact Choice #2: 4-set Lavawalker`}</h3>
    <p>{`This `}<RemoteLink to="https://genshin.honeyhunterworld.com/db/art/family/a_14003/?lang=EN" mdxType="RemoteLink">{`artifact set`}</RemoteLink>{` feels very much like a copium set for folks who couldn't get 4-set Crimson Witch. If you manage to get a good set, the 35% DMG Bonus is nice. But the 2-set effect is pretty useless. I think it's better if you run 4-set Crimson Witch instead.`}</p>
    <h3>{`Artifact Choice #3: 4-set Crimson Witch`}</h3>
    <p>{`Great if you enjoy playing Yoimiya in a fireworks team. Now you may wonder, why didn't I suggest `}<RemoteLink to="https://genshin.honeyhunterworld.com/db/art/family/a_15006/?lang=EN" mdxType="RemoteLink">{`this 4-set`}</RemoteLink>{` instead given how well it synergies with an Overload team?`}</p>
    <p>{`Well, I've been playing for almost a year, but I still couldn't farm a good 4-set Crimson Witch. Up till now, that `}<em parentName="p">{`accursed domain`}</em>{` only dropped 1 Crit Rate hat for me. Yup. Just one. The rest is Lavawalker set drops ): I don't even have a Crit Damage crimson witch hat.`}</p>
    <Image name="yoimiya-crit-rate-hat.png" caption="I only have these 3 hats... The domain just won't give me hats ):" alt="Yoimiya Crit Rate Crimson Witch Hat" mdxType="Image" />
    <p>{`Which is why I insisted on using a 2 by 2 set. Save yourselves the pain... BUT! If you have a great 4-set Crimson Witch, go ahead and use this set. There's absolutely nothing wrong with using whichever artifacts you feel best suit your needs/gameplay style.`}</p>
    <p>{`Regardless, artifact set effects aside... You should know that `}<strong parentName="p">{`substats`}</strong>{` (ATK, Crit Rate, Crit Damage) is more important when it comes to Yoimiya's raw damage. As long as her attack reaches 2000~ and above, her Crit Rate is at 70 (for more consistency) and her crit damage as high as possible, she will surely hit super hard!`}</p>
    <p>{`If you have plenty of artifact sets she could wear, give this `}<RemoteLink to="https://genshinimpactcalculator.com/genshinCalc.html" mdxType="RemoteLink">{`damage calculator`}</RemoteLink>{` a try. Plug in all of her numbers to see which artifact sets you have on hand is better for her.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Yoimiya Build #2: Weapons`}</h2>
    <p>{`While her best-in-slot 5-star weapon has always been `}<RemoteLink to="https://genshin.honeyhunterworld.com/db/weapon/w_4407/?lang=EN" mdxType="RemoteLink">{`Thundering Pulse`}</RemoteLink>{`, any other 5-star weapon will do for her. With a high base attack and great passives to boost her damage, 5-star weapons are unrivaled compared to 4-star weapons. (E.g. Skyward Harp, Polar Star, Amos...) If you don't have a 5-star weapon, then your next best bet is Rust.`}</p>
    <RemoteLink to="https://genshin.honeyhunterworld.com/db/weapon/w_4308/?lang=EN" mdxType="RemoteLink">
  R5 Rust
    </RemoteLink> out damages even the 5-star weapons (except Thundering Pulse)! That's
because Yoimiya's damage comes from her Normal DMG, and Rust's passive is perfect
for her.
    <p>{`If you don't have Rust, then you should give Hamayumi a try. The BP bow `}<RemoteLink to="https://genshin.honeyhunterworld.com/db/weapon/w_4310/?lang=EN" mdxType="RemoteLink">{`Viridescent Hunt`}</RemoteLink>{` is also decent due to the Crit Rate sub stat. You could try `}<RemoteLink to="https://genshin.honeyhunterworld.com/db/weapon/w_4303/?lang=EN" mdxType="RemoteLink">{`Blackcliff Bow`}</RemoteLink>{` too (another stat stick due to the Crit Damage sub stat) if you had it on hand already. I do not recommend getting Blackcliff Bow just for Yoimiya because that's a waste of your currency. Keep that for fates or getting a character instead!`}</p>
    <p>{`Last but not least, the 3-star weapon `}<RemoteLink to="https://genshin.honeyhunterworld.com/db/weapon/w_4206/?lang=EN" mdxType="RemoteLink">{`Slingshot`}</RemoteLink>{` works too.`}</p>
    <h2>{`Yoimiya Build #3: Talents`}</h2>
    <p>{`It goes without saying that her auto attack talent takes priority, followed by her E then Q. (AA -> E -> Q) While her auto attack talent scales really well, her E (ironically) doesn't. After reaching Level 7, her E's damage only increases by 3%~ for the next level until Level 15 (max talent level no one could reach for now). I personally would leave it at Level 7 unless you like her a lot to triple-crown her.`}</p>
    <h2>{`Fireworks Team - 1 Yoimiya, 1 Random Element, 2 Electro`}</h2>
    <p>{`I added 2 videos below to show my Yoimiya's stat and how fireworks team work. The idea behind fireworks team composition is to use Yoimiya as a driver (with her Pyro application). If it triggers reactions (and mostly Overload) along the way, good. Otherwise, it's about getting everyone's damage to contribute! You need to have 2 Electro characters since they both will battery each other to ensure their Q is always up.`}</p>
    <Ads mdxType="Ads" />
    <p>{`You could replace Xingqiu with another Hydro character or a Pyro character, it doesn't matter. I personally enjoy using Xingqiu for his interruption resistance, along with his heals and off field damage. He could battery himself really well with a Sacrificial Sword too.`}</p>
    <Video src="https://youtube.com/embed/gcwlneog8CA" title="Yoimiya Fireworks Team" mdxType="Video" />
    <Video src="https://youtube.com/embed/VrD3k9JtYbM" title="Yoimiya Fireworks Team" mdxType="Video" />
    <p>{`If you don't have Xingqiu, you could use another Pyro character for Pyro resonance, or a healer so Yoimiya doesn't die easily. It honestly doesn't matter who's in the slot because what's important is the two Electro character that provides off field turret for Overload reactions. My personal favourite is Raiden, Beidou or Fischl. I don't have Lisa built, so I couldn't comment on her, unfortunately.`}</p>
    <p>{`Raiden is a no-brainer choice for me due to how easy it is to get her E procced by Yoimiya's attacks. Plus, her E boosts the team's Elemental Bursts damage. Raiden is a wonderful teammate for a team that relies on Q for damage. If you don't have Raiden, then Fischl would do nicely. C6 Fischl is great but Beidou is better! She provides a shield and could parry for emergency situations.`}</p>
    <p>{`C2 Beidou is an amazing leap for her Q's damage, but all said and done, the Electro characters are only there for the Overload reaction. With Overload, Yoimiya can off a group of enemies really quick since everyone's just busy getting juggled in the name of AoE explosions.`}</p>
    <p>{`And that's how you play Yoimiya in her best team composition. Turn up everyone's E and Q, and go unga bunga! Yoimiya works even in Spiral Abyss. Whenever Spiral Abyss favoured bosses at Floor 12, I often use Yoimiya in my team.`}</p>
    <Image name="yoimiya-spiral-abyss.png" caption="She's definitely viable if there's a boss enemy for you to shoot happily." caption="My Yoi <3 I love using her in Abyss. She just shoots and I cleared the floor already." mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`Extra Note: Other than Fireworks?`}</h2>
    <p>{`If you enjoy using Yoimiya in a standalone Pyro-only team, you could try 2 Pyro 2 Geo resonance team. Yoimiya with C6 Xiangling (her C6 adds 15% Pyro damage if Pyronado is up), along with 2 other Geo characters. This beefs up her raw numbers if you have a shield on! If you have C2 Jean, try using her with Yoimiya! I tried my Yoi with a friend's C2 Jean, and her attack speed is so fast. Another teammate to look out for is Yunjin (Not out yet as of v2.3) because Yunjin's 3rd passive boosts Normal ATK DMG.`}</p>
    <p>{`If you have Kazuha, then you could try this team below!`}</p>
    <Video src="https://www.youtube.com/embed/kg3q26V3_rc" title="Yoimiya Mono Pyro Team" mdxType="Video" />
    <p>{`There's a ton of ways to play Yoimiya. I didn't mention it in the Artifacts section but there are folks who used `}<RemoteLink to="https://genshin.honeyhunterworld.com/db/art/family/a_15005/?lang=EN" mdxType="RemoteLink">{`4-set Thundering Fury artifact`}</RemoteLink>{` to play Overload with Yoimiya. It's a crazy idea but it definitely works!`}</p>
    <p>{`Regardless, enjoy Yoimiya however you play her!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      