import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I must first apologize… If you’re looking for something smart in this blog post, there is none. I am not a smart person. As evidenced by this entire blog + fanfic section, both me and mari-chan just want to gush about the things we love. So this is a post about the things I love in The Case Study of Vanitas + Pandora Hearts!`}</p>
    <Ads mdxType="Ads" />
    <p><strong parentName="p">{`Note: Spoilers alert!`}</strong></p>
    <p>{`I only knew of Jun Mochizuki from 1 work of hers - Pandora Hearts. Coincidentally, she has only worked on 2 titles so far - Pandora Hearts and The Case Study of Vanitas. They say quality trumps quantity. I suppose this is what’s going on here! Pandora Hearts in general, was a story full of sadistic twists that you don’t see coming and painful romance. It’s a story about finding your own worth in a world that’s wholly against you, and doing what is ultimately right for the ones you love. Back in the 2000s, shoujo manga/shounen manga with romance were `}<strong parentName="p">{`everywhere`}</strong>{`. I have no shortage of cliches or moe scenes to read about. To tell the truth, I love moe! I love fluff moments. While romance stories may seem straightforward to write about, the really good ones are super satisfying to read.`}</p>
    <p>{`But Pandora Hearts? `}<strong parentName="p">{`It hurts a lot`}</strong>{`. Was it done for the sake of hurting the readers? Nah, the sorrowful moments are actually done really well. It was justified, it had build up and lots of heart-to-heart moments which further develop the characters. Jun Mochizuki took the time to give these characters their own screen time so when it is time to deal the damage, it hurts a megaton. This is pretty much her story telling style which I really, really love.`}</p>
    <p>{`Back in the 2000s, there were few stories out there that are focused on lore or worldbuilding. Perhaps there are a few but from what I see, many stories out there are more focused on fleshing out relationships. From familial connections to lovers… What gives these stories a kick is to see how things develop from something horrible to a happy ending. Readers have an expectation when reading these stories. They want this canon relationship to go further and this is what most stories cater to. The author will write the conclusion folks expected and they’ll tell everyone `}<em parentName="p">{`this is good.`}</em>{` But Pandora Hearts? Not only does it marry lore with relationship conflict, there’s a ton of self-assessment by these characters and none of them are positive. This is what highlights the strength of Jun Mochizuki’s writing. She knows how to write compelling characters through their dialogue, and she wants you to feel these characters’ emotions.`}</p>
    <Ads mdxType="Ads" />
    <p>{`…Okay maybe that sounds a bit biased because I know her dialogue choices are on the side of cheesy, so maybe folks don’t find them as good as I thought so. Cheesy dialogues were a thing back then (Gakuen Alice, anyone? What about Ouran High School later chapters?) so I didn’t give it much thought. But no one can deny how good Pandora Hearts is thanks to how she pulls the heartstrings then gives the readers back their heart for a great, happy ending.`}</p>
    <Image alt="Pandora Hearts ending" caption="All three are unfortunate children who is the product of their miserable childhood caused by their equally miserable parents. I am happy they got their deserved endings." name="happy-ending-pandora-hearts.jpg" mdxType="Image" />
    <p>{`Now that I have given a preface of her first work, let’s talk about her second work - The Case Study of Vanitas. I’m actually really surprised that she only worked on 1 title after Pandora Hearts. I thought she’ll go on to write a ton of one-shots before working on a big title! I guess planning Vanitas took a lot of time because wow, `}<em parentName="p">{`it’s amazing `}</em>{`if we were to take the current romance manga scene into account`}<em parentName="p">{`. `}</em></p>
    <p>{`I first found out that she wrote Vanitas years ago, but it was only recently that I tried the anime version before reading the manga chapters (In one go no less. I caught up to Chapter 53). Jun Mochizuki has a thing for Victorian era-ish dresses and coats. Thank you for that because I too, have a thing for the dresses from this era. Chapter 1 opens up with hints towards the state of the world of Vanitas. We were quickly introduced to the players of the story, along with a mysterious, sinister plot that is surely not what it seems.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Now this is what Jun Mochizuki loves doing (and what I love reading about). She has clearly set up a precedent at the beginning of the story. The readers now have an expectation of what to expect about the Blue Moon and Crimson Moon vampires. The story told us that the Blue Moon vampires are hated upon, but Vanitas’s powers seem to be more beneficial than what it was assumed to be. If Pandora Hearts’s take on The Tragedy of Sablier is any indication that this will be spun the same way, we’re in for a treat. `}<strong parentName="p">{`A massive, heartbreaking rollercoaster treat`}</strong>{`. 50+ chapters in and the first trickle is starting to pour in. A flashback on Vanitas’s past and a villain who is just a misunderstood child… Not to mention the main cast who clearly has confidence issues. This is basically Pandora Hearts but the modern version. The cast has issues, the villains have issues, and the lore the public knew of is not what it seems. Plenty of hints peppered throughout the story for you to piece things up!`}</p>
    <p>{`Plot aside, I just love how wonderful her art and fluff scenes have evolved to. Jun Mochizuki has a knack for drawing wonderful panels that seamlessly flow from one page to another. She picks great angles to deliver that massive BAM for max effect! Not to mention how `}<em parentName="p">{`clean `}</em>{`her art has become. Action scenes are a treat to look at! Steampunk background art tickles the imagination of the readers! <3 The clothing is so lovely to look at. The design is one thing, but I’m mostly talking about how the clothing wraps around the character’s body really nicely, giving the characters a sense of volume(?) instead of the usual flatness in most 2D art. And yes, the dresses. Not super puffy and really elegant when the action scene kicks in. Lots of fluttering clothing pieces! As an avid manga reader, I always take note of how the hair is drawn. I appreciate it if the hair is fluttery but sometimes you can notice that it is `}<em parentName="p">{`way too fluttery. `}</em>{`Jun Mochizuki has mastered the art of drawing really nice hair movements when needed. `}<em parentName="p">{`God bless… `}</em>{`Domi's hair is really, really nice.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Compared to Pandora Hearts, there’s less cheesy dialogue since the cast is much older. This makes me appreciate a proper build up for a heart-to-heart session, the bread and butter of Jun Mochizuki’s heartrending talk no jutsu. The scenes are always, always justified in the context of the ongoing drama and are never `}<em parentName="p">{`too much `}</em>{`to take in. Relationship troubles too, feels right at home in this series.`}</p>
    <Image alt="Jeanne first fight scene in The Case Study of Vanitas" caption="I'm okay with fight scenes but it's always good to quickly piece what's going on at a first glance. Action scenes in this title is super easy to grasp." name="jeanne-fight-scene-the-case-study-of-vanitas.jpg" mdxType="Image" />
    <p>{`Then there’s the wonderful two-page spreads and the super cute scenes. Thank you so much, Jun Mochizuki-sensei (T_T) It’s no secret that we all love a good two-page spread of a kiss scene. I must say, I think the whole scene leading up to the kiss feels a bit forced. I didn’t really like how Vanitas simply kissed her without consent because that doesn’t sound good on paper. BUT in lieu of almost every shoujo out there, this scene is super tame already. Regardless, every moe and fluff scene so far just proves how much Jun Mochizuki understood what makes us readers tick, but never stray too far from her own twist.`}</p>
    <Image alt="The Case Study of Vanitas Jeanne's smiling panel" caption="Such a lovely two-page spread. We see Jeanne smiling brightly (and honestly) at Vanitas. It was at that moment, Vanitas truly fell in love with her." name="smiling-jeanne-the-case-study-of-vanitas.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <p>{`I particularly love the chapter where Vanitas came to the conclusion that Jeanne loves him but ultimately denied it with a silly reason - he has no right to be loved. The story didn’t need to go that way but it did. It should be a simple fluff chapter but there’s more to it than it seems. Looking back, you could say that The Case Study of Vanitas is the successor to Pandora Hearts. I could tell that the general flow is pretty similar to Pandora Hearts but was given a polished take. Seriously, reading Vanitas transports me back to the early 2000s days where the romance is built upon resolving both self conflict and fantasy danger while the eventual reveal of the real lore is slow but worth the wait. Good stuff! We’re only at Chapter 53 so I expect even more goodies coming our way <3 Read both Pandora Hearts and The Case Study of Vanitas! They are both really good and primed to leave a gaping hole in your kokoro... Basically, `}<strong parentName="p">{`prepare tissue boxes.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      