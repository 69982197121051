import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';

import $ from '../../../styles/globals';
import { getDate, importOneImage } from '../../../utils';

const Section = styled.section``;

const Container = styled.div`
  padding: ${$.layout.padding4}px 0;
`;

const Title = styled.h1`
  color: ${$.brown5};
  font-size: 46px !important;
  line-height: 1.2em;
  letter-spacing: -1px;
  font-family: Raleway Bold !important;
  margin-bottom: ${$.layout.margin4}px;
`;

const Img = styled.img`
  width: 100%;
  height: 400px;
  border-radius: 10px;
  object-fit: cover;

  ${$.screen.tablet} {
    border-radius: 0;
    position: relative;
    left: -${$.layout.margin6}px;
    width: 100vw;
  }

  ${$.screen.mobile} {
    border-radius: 0;
    position: relative;
    left: -${$.layout.margin4}px;
    width: 100vw;
    height: 300px;
  }
`;

const Breadcrumbs = styled.div`
  font-family: Rubik Regular;
  font-weight: bold;
  color: ${$.brown4};
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-size: 14px;
  margin-bottom: ${$.layout.margin3}px;

  > a {
    text-decoration: none;
    color: ${$.color.orange};
    &:visited {
      color: ${$.color.orange};
    }
  }

  > span.tag-separator {
    margin: 0 ${$.layout.margin1}px;
  }
`;

const PublishedStats = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: ${$.layout.margin2}px;
  color: ${$.brown4};
  font-size: 15px;
  font-family: Rubik Regular;
  line-height: 1.2em;

  > span {
    margin: 0 ${$.layout.margin1}px;
  }

  a {
    color: ${$.color.orange};
    font-family: Rubik Regular;
    font-weight: bold;
    text-decoration: none;
    &:visit {
      color: ${$.color.orange};
    }
  }
`;

const Banner = ({ img, title, tags, published }) => (
  <Section img={img}>
    <Container>
      <Breadcrumbs
        dangerouslySetInnerHTML={{
          __html: tags.join('<span class="tag-separator">·</span>'),
        }}
      />
      <Title>{title}</Title>
      <PublishedStats>
        <time dateTime={published}>
          {`First published ${getDate(published)}`}
        </time>
        <span>·</span>
        <div>
          {'Written by '}
          <Link to="/about/" title="Learn more about mari-chan here!">
            mari-chan
          </Link>
        </div>
      </PublishedStats>
      <Img
        src={importOneImage(img)}
        alt={`Cover image for ${title} fanfiction by mari-chan, writer for Geeknabe`}
      />
    </Container>
  </Section>
);

Banner.defaultProps = {
  img: '',
  title: '',
  tags: [],
  published: '',
};

Banner.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string),
  img: PropTypes.string,
  title: PropTypes.string,
  published: PropTypes.string,
};

export default Banner;
