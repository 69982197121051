import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const RemoteLink = makeShortcode("RemoteLink");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`You can play Genshin Impact on your phone, PC and home console. Resources in this game are pretty much handed to you as long as you are willing to put in the time and effort.`}</p>
    <p><strong parentName="p">{`Update Note: This whole guide is no longer valid after 1.5! However, if you need to know where to farm flowers or ores, feel free to refer to this blog post. I gotchu covered.`}</strong></p>
    <p>{`So why aren’t we playing Genshin Impact when we have the `}<strong parentName="p">{`free time`}</strong>{` to do so? The answer is obviously... because we are good people of a normal society who `}<strong parentName="p">{`clearly`}</strong>{` have `}<em parentName="p">{`perfect control`}</em>{` over the media they consume.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Alas, if you have clicked on this blog post, I am sure you are not one of those clear-minded folks (Welcome to the club). There's absolutely nothing wrong with that! I mean, did you check out my `}<RemoteLink to="https://mangadex.org/list/797893" mdxType="RemoteLink">{`MDList `}</RemoteLink>{`and `}<RemoteLink to="https://anilist.co/user/geeknabe/animelist" mdxType="RemoteLink">{`Anilist`}</RemoteLink>{`? It's perfectly normal to have hobbies you obsess about <3`}</p>
    <p>{`They say a good blog post is best written from your own experience… So here it is - a post on how to play this game without missing a beat from yours truly.`}</p>
    <p><em parentName="p">{`Note: So far, I have pretty much solo-ed the game’s daily content except for events. Yes, you can solo the domains and weekly bosses. Try co-op if you want to clear domains/events faster!`}</em></p>
    <h2>{`Genshin Impact Tip #1: Never let your resin fill up`}</h2>
    <p>{`Ever since I unlocked Condensed Resin, my resin counter has never reached its cap (160 resin) unless I’m busy with real life. Think of it as you doing casual min-maxing in order to make the most out of your resin! Since the game is available on smartphones anyway, you might as well download the game on your phone and login daily. You don’t need to play the game normally, you could just login to create a condensed resin and be on your way. Easy!`}</p>
    <p>{`Plus, Condensed Resin is great for doubling your rewards for domains. I find it really good for artifact domains. Isn’t it sad to clear a hard domain and see only 1 5-star artifact? At least Condensed Resin doubles the chance...`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Genshin Impact Battle Pass Guide and Daily Grind Guide" caption="Y'all can spare 40 resin, right? All in the name of better and more loot." name="genshin-impact-battle-pass-guide-daily-grind-guide-1.jpg" mdxType="Image" />
    <h2>{`Genshin Impact Tip #2: Clear your dailies daily`}</h2>
    <p>{`Who can say no to 60 primogems daily? Wait, what! You skip those? `}<strong parentName="p">{`IMPOSSIBLE`}</strong>{`. I couldn’t stress this enough! Clear the 4 daily commissions! It takes barely a minute for each one! If you still can’t clear the commissions, ask for a friend’s help.`}</p>
    <h2>{`Genshin Impact Tip #3: Clear your weekly Battle Pass `}</h2>
    <p>{`And do it before the weekend is here! Yup. If I can clear Weekly Battle Pass within 5 days, you can too. Let’s go over the strategy for each one! I didn’t include the weeklies that are super obvious to complete.`}</p>
    <h3>{`Weekly Battle Pass #1: Collect 100 Mondstadt specialties`}</h3>
    <p>{`You can quickly do this within a single day. Here are the suggested places to collect your flowers! Since they respawn after 1 day or so, do not pick the flowers if you’re approaching a new week. Make sure to pick them once a new Weekly Battle Pass starts!`}</p>
    <p><strong parentName="p">{`Location #1`}</strong>{`: Outside Mondstadt’s main gate, you’ll find Dandelions. Use Anemo on them to pick the seeds.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Genshin Impact Battle Pass Guide and Daily Grind Guide" caption="Make sure to check on the left side too. This is the right side of the gate. I remember there are a few more Dandelions near the side gate (where you get Reputation Quests) so you could check that out too." name="genshin-impact-battle-pass-guide-daily-grind-guide-2.jpg" mdxType="Image" />
    <p><strong parentName="p">{`Location #2`}</strong>{`: Windrise. Teleport to Windrise’s Statue of Seven, and pick every Windwheel Aster you see. There are a few of these flowers further away from the tree, so make sure to do a full sweep of the surrounding area! If you visit the area at night, there should be Crystalflies. Catch them because you’ll need the Crystal Cores for your Condensed Resin!`}</p>
    <Image alt="Genshin Impact Battle Pass Guide and Daily Grind Guide" caption="This is a bit way off from the tree. See those Windwheel Asters in front of my Ningguang? Make sure to pick these up!" name="genshin-impact-battle-pass-guide-daily-grind-guide-3.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <p><strong parentName="p">{`Location #3`}</strong>{`: Teleport Waypoint on the right-hand side of Windrise’s Statue of Seven. It’s very close to the Windrise’s Statue of Seven so it’s hard to miss. There are many Small Lamp Grass near the ruins.`}</p>
    <Image alt="Genshin Impact Battle Pass Guide and Daily Grind Guide" caption="See those Small Lamp Grass near the ruins? It's all over the place so make sure to do a full sweep." name="genshin-impact-battle-pass-guide-daily-grind-guide-4.jpg" mdxType="Image" />
    <p><strong parentName="p">{`Location #4`}</strong>{`: Springvale. Those little Philanemo Mushrooms on the houses count too! You might want to practice your climbing skills...`}</p>
    <Image alt="Genshin Impact Battle Pass Guide and Daily Grind Guide" caption="Teleport from this Waypoint and go all the way up. There are a few Calla Lilies near the lake so pick those up before heading to the housing area." name="genshin-impact-battle-pass-guide-daily-grind-guide-5.jpg" mdxType="Image" />
    <p><strong parentName="p">{`Location #5`}</strong>{`: Wolvendom. Teleport to the Waypoint close to the beach, and work your way up towards Boreas’s arena. There should be a ton of Small Lamp Grass and Wolfhooks to pick along the way. Make sure to climb up to Boreas arena’s walls. There are some Wolfhooks clumped up near the dead trees.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Genshin Impact Battle Pass Guide and Daily Grind Guide" caption="I draw an arrow going around Boreas’s arena because there are Wolfhooks on the top of the walls too! It’s all clumped up near the trees but hidden by the bushes. Stay sharp!" name="genshin-impact-battle-pass-guide-daily-grind-guide-6.jpg" mdxType="Image" />
    <p><strong parentName="p">{`Location #6`}</strong>{`: Starfell Lake. Teleport to its sole Statue of Seven and work your way towards Whispering Woods. Lots of Small Lamp Grass and Calla Lilies to pick!`}</p>
    <Image alt="Genshin Impact Battle Pass Guide and Daily Grind Guide" caption="I remember having a few stray Calla Lilies near the lake. Walk all the way to Whispering Woods and collect any Small Lamp Grass you see. They are usually located off the main road so make sure to explore every inch." name="genshin-impact-battle-pass-guide-daily-grind-guide-7.jpg" mdxType="Image" />
    <p><strong parentName="p">{`Location #7`}</strong>{`: Near Boreas’s arena and Dawn Winery. There should be a Teleport Waypoint here that has Cryo Slimes nearby. Pick up Calla Lilies and Small Lamp Grass near here!`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Genshin Impact Battle Pass Guide and Daily Grind Guide" caption="Once you appear out of the Waypoint, move your view to your left. There should be 2-3 Calla Lilies near the lake. Usually I’d go straight towards the Small Lamp Grass near the solved Seelies puzzle so I’ll miss the Calla Lilies." name="genshin-impact-battle-pass-guide-daily-grind-guide-8.jpg" mdxType="Image" />
    <p><strong parentName="p">{`Location #8`}</strong>{`: Stormbearer Point. Pick up Valberries here.`}</p>
    <Image alt="Genshin Impact Battle Pass Guide and Daily Grind Guide" caption="From the Waypoint, you’ll fly down towards a dense forest. I notice that there’s a ton of boars for me to bully so I will take the opportunity to farm meat too. I have marked nearby Valberries with clover pins. Those are Valberries' positions!" name="genshin-impact-battle-pass-guide-daily-grind-guide-9.jpg" mdxType="Image" />
    <p><strong parentName="p">{`Location #9`}</strong>{`: Dawn Winery Statue of Seven. There are stray Crystalflies and Windwheel Asters here. Pick them all up!`}</p>
    <Image alt="Genshin Impact Battle Pass Guide and Daily Grind Guide" caption="I ran a full circle around the Statue of Seven just to make sure I didn’t miss any Windwheel Asters clustered around it. I believe there’s at least 10 Windwheel Asters here. I drew an arrow towards Dawn Winery because there are at least 2 houses there with Philanemo Mushrooms on them!" name="genshin-impact-battle-pass-guide-daily-grind-guide-10.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <p>{`Voila, you should have all 100 Mondstadt specialties collected now. Extra note: Remember the house at the end of an alley with a water seal? If you played Mona's story quest, that's her personal lab! Look up, and you'll notice Philanemo Mushrooms on the walls! If you spin your camera around, a few more are sneakily hiding from plain view on houses near Mona's lab. I believe there's at least 8 of these mushrooms here.`}</p>
    <h3>{`Weekly Battle Pass #2: Collect 100 Liyue’s specialties`}</h3>
    <p>{`Mama, I’m scared that they’ll introduce a new Inazuma specialty in the future too. I couldn’t complete Liyue’s Weekly BP pass in a day until I discovered… JUEYUN CHILI.`}</p>
    <p><strong parentName="p">{`Location #1`}</strong>{`: Liyue Harbour. First, teleport to the Waypoint near the Crafting Table. There should be 3 Glaze Lilies behind you. Next, teleport to other Waypoint to collect Glaze Lilies and Silkflowers in the garden area.`}</p>
    <Image alt="Genshin Impact Battle Pass Guide and Daily Grind Guide" caption="Here are the 3 Glaze Lilies near the Teleport Waypoint." name="genshin-battle-pass-guide.jpg" mdxType="Image" />
    <Image alt="Genshin Impact Battle Pass Guide and Daily Grind Guide" caption="There’s 3 Glaze Lilies here too, at the garden area up the staircase! There's a lazy Millelith sitting down so it's hard to miss. Once you’re done, go up the other staircase where you’ll find more Glaze Lilies and Silk Flowers. Make sure to explore the whole place. I remember missing at least 3 flowers here because I’m so used to picking the ones I see and leaving immediately after I’m done." name="genshin-impact-battle-pass-guide-daily-grind-guide-11.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <p><strong parentName="p">{`Location #2`}</strong>{`: Wangshu Inn. Teleport there and glide down. There should be a ton of Silk Flowers along the main road.`}</p>
    <Image alt="Genshin Impact Battle Pass Guide and Daily Grind Guide" caption="Once you glide down, make sure to go north. There’s 4 more Silk Flowers after you cross the bridge up north. Thus, the reason why I drew the arrow a bit further up." name="genshin-impact-battle-pass-guide-daily-grind-guide-12.jpg" mdxType="Image" />
    <p><strong parentName="p">{`Location #3`}</strong>{`: Qingce Village. Teleport to the top of the village and follow the staircase all the way down to the paddy fields. You’ll find a ton of Jueyun Chilli on both sides of the staircase. Pick those up! Next, visit every paddy field you can see for Glaze Lilies. Each paddy field section should have at least 2 Glaze Lilies.`}</p>
    <Image alt="Genshin Impact Battle Pass Guide and Daily Grind Guide" caption="Follow the staircase and go allll the way down. Make sure to check the sides of the staircase! There’s a ton of Jueyun Chillies here." name="genshin-impact-battle-pass-guide-daily-grind-guide-13.jpg" mdxType="Image" />
    <p><strong parentName="p">{`Location #4`}</strong>{`: Jueyun Karst. We are here for free to pick Jueyun Chilli!`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Genshin Impact Battle Pass Guide and Daily Grind Guide" caption="I have pinned the Jueyun Chilli locations with a clover pin. Enjoy the nice view~" name="genshin-impact-battle-pass-guide-daily-grind-guide-14.jpg" mdxType="Image" />
    <p><strong parentName="p">{`Location #5`}</strong>{`: You could fly around the peaks to collect Qingxin Flowers but that’s crazy even for me. Do you know that Noctilucous Jade and Cor Lapis counts as Liyue’s specialty too? Teleport to Mt. Hulao and go all the way down, smashing every Cor Lapis you find. While I’m at it, I find it fun to crack those Amber (the big orange rocks) too!`}</p>
    <Image alt="Genshin Impact Battle Pass Guide and Daily Grind Guide" caption="Teleport here and go all the way down. Follow the main road and down the staircase. There should be a ton of Cor Lapis for you to mine." name="genshin-impact-battle-pass-guide-daily-grind-guide-15.jpg" mdxType="Image" />
    <p><strong parentName="p">{`Location #6`}</strong>{`: Mingyun Village domain, where you farm for weapon ascension materials. There’s a cave full of Noctilucous Jade nearby!`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Genshin Impact Battle Pass Guide and Daily Grind Guide" caption="Just waiting for you to use Zhongli’s Elemental Skill on them. STABILIZE!" name="genshin-impact-battle-pass-guide-daily-grind-guide-16.jpg" mdxType="Image" />
    <p>{`Phew. All 100 Liyue specialties collected. If you couldn’t hit the target, I suggest waiting for them to respawn. Or you could... fly around the Qingyun Peak area and go from one top to another for Qingxin flowers. There's 3 Qingxin flowers if you teleport to the Qingyun Peak's Statue of Seven. Hopefully you only need 3 more to complete 100 specialties! Collect Starconches too. You can find them at beach areas. E.g. Guyun Stone Forest, Yaoguang Shoal.`}</p>
    <h3>{`Weekly Battle Pass #3: Defeat bosses 10 times`}</h3>
    <p>{`I usually do a full sweep by defeating bosses without taking the loot. Yup, you can do that and it’ll still count for your Battle Pass. If I took the loot, I'll just come back later and hopefully the boss has respawned by then. Let’s see… I usually pick the bosses that I find easy. Electro Hypostasis, Geo Hypostasis and Anemo Hypostasis. That’s three down. You could go for the Regisvine bosses too if you feel up to it. This should easily take 2 days to finish.`}</p>
    <h3>{`Weekly Battle Pass #4: Complete the Wolf of the North Challenge`}</h3>
    <p>{`Easy peasy lemon squeezy. Use a ranged character with the highest DPS and master the sprint. Dodge every ice streaks Boreas add to the field. When he starts getting enraged and sends a 3-anemo slash attack, sprint. I noticed that sprinting will give me a brief window of immunity to his slash attack. Boreas is not hard once you know his attack range and timing.`}</p>
    <Ads mdxType="Ads" />
    <h3>{`Weekly Battle Pass #5: Complete the Stormterror Domain Challenge`}</h3>
    <p>{`All of his attacks can be avoided. Make sure to keep staying in the air! If you see him blasting anemo blasts at you, fly to the next platform. If he attacks by doing a bite using his head, do a plunge attack if you're up in the air! If he attacks with his claws exposed, quickly move to the other side of the platform so you’ll be safe from his breath attack. Attack his exposed claws there! Once he is down, climb up to his neck and attack the thorn with everything you got.You could easily clear this domain without getting hit by his attacks. Except for the platform Anemo DOT of course.`}</p>
    <h3>{`Weekly Battle Pass #6: Complete 15 Domain Challenges`}</h3>
    <p>{`Pick the easiest domain challenge you can do (E.g. lowest level) and repeat until all 15 times is done. Yup, you don’t need to pick up rewards to add to the counter. You just need to complete a domain challenge and leave without taking anything.`}</p>
    <h3>{`Weekly Battle Pass #7: Complete Leyline deposit challenges 20 times`}</h3>
    <p>{`Everyday, you could complete the Leylines 4 times if you don't pick up the rewards. I usually pick up the rewards at least once so I could complete it 5 times a day.`}</p>
    <Ads mdxType="Ads" />
    <h3>{`Weekly Battle Pass #8: Complete the Golden House challenge`}</h3>
    <p>{`Not impossible to solo but it’s tough to dodge Childe’s attack. I usually play co-op for this. Good luck, fellow Genshin Impact addicts.`}</p>
    <h3>{`Weekly Battle Pass #9: Forge 20 items`}</h3>
    <p>{`Just forge 20 enhancement ores and you’re done. Easy peasy.`}</p>
    <h2>{`Genshin Impact Tip #4: Clear Fatui(s), Abyss Mage(s) and Whoppers daily`}</h2>
    <p>{`As you run around doing your dailies and weeklies, you’ll notice a ton of Fatui Agents, Abyss Mages, etc around the maps. Please defeat them all. You’ll need a ton of their drops to raise your characters. You wouldn’t want to farm them when you need it most, right? You’ll get really, really tired at the idea of farming these materials for your character’s ascension and talent when you need it. You might as well do it whenever you can! You could use your Adventurer’s Book to find these agents, or you could mark the area on your map. One of my favourite route is the one near Duyun Ruins.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Genshin Impact Battle Pass Guide and Daily Grind Guide" caption="Most of my characters need a ton of Insignias! I’m… I’m sick of defeating Fatuis. And the drop rate for the rarer Insignia is so darn low..." name="genshin-impact-battle-pass-guide-daily-grind-guide-17.jpg" mdxType="Image" />
    <Image alt="Genshin Impact Battle Pass Guide and Daily Grind Guide" caption="One of my favourite hunting routes is to teleport near Dunyu Ruins and go all the way down. There’s a ton of Fatui agents and Ruin Guards for you to beat up here. I marked the enemies with a horn-looking pin." name="genshin-impact-battle-pass-guide-daily-grind-guide-18.jpg" mdxType="Image" />
    <p>{`Another area you could visit for Fatui agents is Dragonspine. That darn map has a ton of these Fatui agents clumping up together in a small area.`}</p>
    <h2>{`Genshin Impact Tip #5: Mine all crystal ores daily`}</h2>
    <p>{`There’s not enough crystals to go around!! AAA! Thus, I make it compulsory to visit a few ore deposits to collect everything there is. You could talk to Liyue’s or Mondstadt’s blacksmith, and they’ll tell you where to mine them. It's nice but the most effective way is to mark them on your map so you can visit them everyday.Another reliable way is to use Expeditions to mine them. Since sending characters off on an expedition doesn't affect your adventuring team (They can still be included in your adventuring team), I usually send those that has their expedition time reduced to half as their talent.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Genshin Impact Battle Pass Guide and Daily Grind Guide" caption="Choose the option “About Liyue’s mining products...” and he’ll highlight the big ore deposits on your map." name="genshin-impact-battle-pass-guide-daily-grind-guide-19.jpg" mdxType="Image" />
    <Image alt="Genshin Impact Battle Pass Guide and Daily Grind Guide" caption="I marked the ore deposits with a pickaxe pin. I usually come here, and defeat some enemies too along the way. Pick up some crabs, and farm fowl meat.. Ya know?" name="genshin-impact-battle-pass-guide-daily-grind-guide-20.jpg" mdxType="Image" />
    <Image alt="Genshin Impact Battle Pass Guide and Daily Grind Guide" caption="I circled the areas where you could mine a ton of materials for enhancement ores. There’s a spot where you need to fly into a waterfall, into caves and even into a small slit in the ground that opens up to a wider area. If you teleport to the Waypoint near Qingxu pool and cross the bridge into the Chasm, there’s a mining area that has a ton of White Iron Chunks. You could pick them up just by “Investigating” the ore carts!" name="genshin-impact-battle-pass-guide-daily-grind-guide-21.jpg" mdxType="Image" />
    <p>{`Also, make sure to farm Cor Lapis and Noctilucous Jade too. If you read my Weekly Battle Pass tips above, you’ll know where to find them. Make sure to mark these locations on your map.`}</p>
    <h2>{`Genshin Impact Tip #6: Defeat every Hillichurl(s) and Slime(s) you see`}</h2>
    <p>{`I usually avoid them since I hate them a lot. I’ve been defeating them since the start of the game. I desperately need fresh enemies to look at. Then I realized I need a ton of arrow and slime materials for Fischl and Zhongli. Sigh… I used to farm them religiously. But I’m tired of doing this everyday so I opted to just hunt them whenever I see them skulking around if I’m doing my Weekly Battle Pass. You could look for them in your Adventurer Book if you need to! All the best!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Genshin Impact Tip #7: Take every dandelion, flaming or ice flowers you see`}</h2>
    <p>{`If I teleported somewhere and saw unpicked flowers, I made it my mission to collect them. Don’t be lazy! It’ll be useful in a quest one day. I noticed that Weekly Reputation Quests actually asked for these items! At least I don’t need to go out of my way to collect them, yea?`}</p>
    <h2>{`Genshin Impact Tip #8: Smash every barrel, steal every cooking ingredient you see`}</h2>
    <p>{`Please tell me you cook meals other than Fried Egg and Sweet Madame! If you are clearing a story quest domain, sometimes you’ll find barrels or boxes. Break them for free cooking materials! If I feel like it, I’ll visit Springvale or Dawn Winery for free carrots and radish. You need a ton of sweet flowers too. If you see it, make sure to collect it!`}</p>
    <h2>{`Genshin Impact Tip #9: Buy the Battle Pass only after you reached LVL 50`}</h2>
    <p>{`I personally feel that the Battle Pass is worth it’s value. When your characters reach LVL80, they eat up a ton of Mora and EXP papers to raise them. Not only does the Battle Pass give free EXP papers, they give a ton of Mora, Talent Materials, Acquaint Fate and a free weapon too. Each of the Battle Pass weapons is really, really good! If you can afford it, I suggest paying for the Battle Pass once you reached BP LVL 50 so you do not feel obligated to reach LVL 50 to get your money's worth.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Genshin Impact Tip #10: You don’t need to login everyday but...`}</h2>
    <p>{`Now do you see why folks can login to play everyday and still find a plethora of things to do? I personally wanted to clear the Battle Pass as soon as possible, as well as busy myself farming the Fatui agents, Abyss Mages, etc so I wouldn’t feel stressed up trying to raise my characters. E.g. not having enough Insignias. I’m not saying that you need to login everyday but you should at least login to get 60 free primogems! How else are you going to pull your favourite character from the character banner! 90 pulls y’all. Ya gotta work for it.## All in all`}</p>
    <p>{`Godspeed with your weekly bosses. I pray that you’ll get the drop you needed to raise your main DPS’s talent. All the best for your domain artifact runs. I hope you’ll get the 5-star artifact that you want (-`}{`_`}{`-7`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      