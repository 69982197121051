import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXFanfiction/Chapter/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Image = makeShortcode("Image");
const Ads = makeShortcode("Ads");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Image alt="Byleth's handwritten note" caption="Please right click and open in new tab/Save this image if the image is too small for you to read on your device." name="byleths-handwritten-note.jpg" mdxType="Image" />
    <p>{`Today was the day.`}</p>
    <p>{`Today was the day Byleth Eisner, a 2nd year student at Garreg Mach High School, was going to finally confess her feelings to Sylvain Gautier, a 3rd year student, after harboring a crush for close to a year. She was determined to confess to him before he graduated in a few months.`}</p>
    <p>{`Granted, confessing through a letter had been on her mind for a while now, but a sudden surge of courage halfway through class prompted her to immediately scribble down her feelings on a torn out piece of paper from her exercise book.`}</p>
    <p>{`“Eisner!” Byleth looked up in panic just as she was about to rewrite the letter, “Yes, Mr. Seteth?”`}</p>
    <Ads mdxType="Ads" />
    <p>{`He gestured at the whiteboard at the front of the class, “Solve this,” he said sternly.`}</p>
    <p>{`She quickly sandwiched her love letter in the middle of a textbook, scurried to the front and tried her best to solve the equation on the board. She could feel Mr. Seteth boring holes into her back and as her mind wasn’t very focused at the moment, she royally messed up the equation.`}</p>
    <p>{`Mr. Seteth let out a disappointed sigh and shook his head, “Go back to your seat,” he ordered. She did as she was told, feeling embarrassed as she shuffled back to her seat.`}</p>
    <p>{`Byleth’s heart truly wasn’t in the moment as she wanted to write a few more drafts of her letter but she decided against it - choosing to at least try and focus on Mr. Seteth’s demonstration of the correct answer so he wouldn’t crucify her later on.`}</p>
    <p>{`An agonizing 25 minutes later, the school bell finally rang, signifying the last class of the day. Byleth didn’t have time to rewrite her letter if she wanted to meet him outside of school and confess her feelings for him. She packed her bag in a hurry, holding the folded piece of paper securely in her hands.`}</p>
    <p>{`As she went out the door she bumped into a tall, hard, firm chest, "`}<em parentName="p">{`I’m sorr-`}</em>{`" she looked up to see the person she had potentially injured to properly apologize, but was met by a pair of angry, frowning blue eyes staring down at her.`}</p>
    <p>{`It was the school delinquent; Dimitri Alexandre Blaiddyd. He was so infamous he was almost like the school mascot. And the `}<em parentName="p">{`one`}</em>{` person she did not want to bump into, not on such an important day for her. “Sorry!” she blurted out without looking and side-stepped him before he glared actual daggers at her.`}</p>
    <p>{`She raced down to the lockers before anyone got there, and looked with a practiced eye for the locker with Sylvain’s name. Byleth stopped in front of it and gripped the letter so hard it almost crumpled in her grip; now that she was actually here, she hesitated.`}</p>
    <Ads mdxType="Ads" />
    <p>{`The secret crush she had for him, the days of watching him play basketball from afar, her overwhelming feelings for him will either culminate in an acceptance or a crushing rejection. Whatever it was, Byleth was ready to move onto the next level. But she had hope. He was always nice to her and talked sweetly to her.`}</p>
    <p>{`Just as she was about to slot the folded piece of paper into the slit, “Hey Byleth!” She looked back to see Ingrid, the girl who she walked home with everyday, calling out to her from the stairs. In a panicked blur, Byleth quickly stuffed the letter into the locker slit and went to greet her.`}</p>
    <p>{`“Why’d you run off so fast?” Ingrid asked. Byleth shrugged, “I...had to...use the loo? Mr. Seteth was really scary today so…” she chuckled awkwardly. No one, not even the girl who walked home with her everyday knew about her secret crush for Sylvain.`}</p>
    <p>{`“Anyway, Ingrid. I forgot to mention I have something I need to do, do you mind going ahead first? I’m really sorry for the last minute mention,” Byleth said apologetically with both of her hands clapped together in front of her.`}</p>
    <p>{`Ingrid grabbed her hands and laughed, “Oh no worries, I was planning to go check out this cafe anyway, I’ll let you know if it’s good, yeah?” Byleth nodded, letting out a big sigh when Ingrid was out of ear shot.`}</p>
    <p>{`3rd year seniors finished class a little bit later than the lower years due to exams and whatnot. Byleth didn’t need to rush but she just couldn’t wait anymore. She wanted to do it before her sudden burst of adrenaline wore off.`}</p>
    <p>{`Byleth found a nice spot outside the school gate under a tree to wait for him - if he showed up that is.`}</p>
    <Ads mdxType="Ads" />
    <p>{`The minutes went by slowly, as she anticipated his arrival. She turned around every time a guy walked out the school gates but her heart sank just as many times`}</p>
    <p>{`She was somewhat prepared for rejection or the fact he may not show up but still, she had to try. She waved at a couple of her friends goodbye while she patiently (and anxiously) looked out for him.`}</p>
    <p>{`A good while later and when her feet started hurting, he still didn’t show up even when a bunch of 3rd year students she recognized walked past her.`}</p>
    <p>{`Byleth told herself she would wait another 5 minutes, and give up then, since it was already almost an hour. The shade she stood under didn’t provide much cover either, thick silvers of sunlight bore down it’s heat onto her and underneath her shirt she could feel the beads of sweat rolling down her back.`}</p>
    <p>{`Suddenly a dark shadow in the shape of a person loomed over her as she stared at the tree behind her, thinking it might finally be Sylvain, she looked up with a smile only to once again stare into menacing azure eyes. One of the eyes had a vertical scar across its eyelids. It took her a good second to realise she had locked eyes once again with none other than `}<em parentName="p">{`the`}</em>{` Dimitri Alexander Blaiddyd.`}</p>
    <p>{`She froze, unable to look or move away. He was slightly panting and visibly sweating. Byleth gulped, trying to search for the courage she had a few hours earlier when she decided to confess her feelings but it was nowhere to be found.`}</p>
    <p>{`She was terrified of him. Everyone knew Dimitri of class 1-A. He walked into school one fine day with a medical eyepatch, multiple bruises on his face and bandaged fist. He then got slapped with a 2 week suspension which, according to rumours, he responded by kicking the teacher’s podium - resulting in a large foot-sized hole.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Dimitri was her junior by a year but he was `}<em parentName="p">{`huge`}</em>{`. He easily towered over her and she was trapped between him and the large tree she was standing under.`}</p>
    <p>{`He lifted his hand, she flinched and backed up squarely against the tree - deathly afraid he was going to strangle or punch her. But instead he held something out at her.`}</p>
    <p>{`“You…” He said, his face still in a deep frown, “You wrote this?”`}</p>
    <p>{`She peeled her eyes open and saw a familiar folded piece of paper in his hand, “I...had a supplementary class so I only just saw it,” he continued, gesturing at her to take it.`}</p>
    <p>{`Byleth reached out with a shaky hand and opened the paper; instantly her face turned white and her heart dropped to her stomach.`}</p>
    <p>{`“You have something to tell me?” He raised an eyebrow.`}</p>
    <p>{`She let the seconds pass by her as she gawked at her confession letter that somehow ended up in his hands. “H-How...did you get this?” She finally looked up at him, but immediately looked away, the sheer force of his gaze was too intense for her to handle.`}</p>
    <p>{`Still, it was rude to not look at someone when they’re talking and so she braced herself to look into his eyes, but he merely tilted his head and frowned again.`}</p>
    <p>{`“I found it in my locker,” he said as-a-matter-of factly, his voice somewhat annoyed. “I’ve only ever received letters of challenges from guys, never a girl before.”`}</p>
    <p>{`“S-sorry, there has been a mistake. This was intended for someone else,” she sputtered, crumpling the now cursed piece of paper in her hands and stuffed it on her skirt pocket. She frantically said goodbye to him and once more side-stepped him to escape. No wonder Sylvain wasn’t showing up.`}</p>
    <Ads mdxType="Ads" />
    <p>{`But Dimitri grabbed her arm, “What do you mean?” He asked cooly, not letting go of her arm and not even turning to look at her.`}</p>
    <p>{`“It’s nothing, this is purely an accident. Please forget it,” she yanked her arm back from him and ran as fast as she could back into the school to see how could she have made such a stupid mistake.`}</p>
    <p>{`She stood in front of Sylvain’s locker, and then finally noticed the locker next to his clearly had Dimitri’s name on it. She was still shaking from fear and the horror of her mistake. Byleth let out a loud groan and lightly punched Dimitri’s locker door. `}<em parentName="p">{`Dammit.`}</em></p>
    <p>{`“Did my locker do something to deserve that?” She turned around and saw Dimitri standing behind her without her noticing his presence. For such a huge guy, he was quiet as a mouse.`}</p>
    <p>{`Byleth sighed and pinched the bridge of her nose, shaking her head, “No, sorry. It’s just...this was not...supposed to...nevermind. Sorry again.” She walked away, gripping her bag straps tightly, her head hung low.`}</p>
    <p>{`“Oi.”`}</p>
    <p>{`She stopped and turned to look at him, completely drained of energy. He didn’t say anything as he approached her.`}</p>
    <p>{`“Yes?” She said lifelessly. “Please don’t hurt me, I will apologize as many times as you want - even for bumping into you earlier.”`}</p>
    <p>{`He snickered, “Is the impression you have of me `}<em parentName="p">{`that`}</em>{` bad?”`}</p>
    <p>{`Was that a faint smile? She had never, not just her, but no one has ever seen this kid smile before. “W-Well, uh. I mean...you know...when you walk into school looking like the ambassador of a band aid company...It happens?”`}</p>
    <Ads mdxType="Ads" />
    <p>{`He let out a laugh. Which...didn’t sound bad at all. It was deep but light and genuine. Dimitri cleared his throat, “Would you...like to be friends? Think of it as your...penance.” He held up his large hand and started counting down, “For rudely bumping into me, for making me run all the way to the school gate to meet you, for tricking me and finally, for assaulting my locker.”`}</p>
    <p>{`“Hey, none of those were intentional!” She cried out, taking a step forward towards him with her fist out and he laughed again.`}</p>
    <p>{`“I’m only joking, it’s fine. See you around, Eisner,” he said, giving her head a quick pat as he walked past her and out the main school door.`}</p>
    <p>{`She stared at his broad back, and felt bad he got pulled into her mess, but she couldn’t help but think about their bizarre interaction. He wasn’t at all like what she expected. He was...actually kind of flirty.`}</p>
    <p>{`She shrugged and decided to finally head home.`}</p>
    <p>{`The days following their encounter, Byleth noticed he would subtly nod at her, which then turned into smiles followed by shy waving at each other. Then a few months later, she was the one who struck up a conversation with him in front of everyone like he was just another guy. Dimitri didn’t have any friends at school and often sat alone at lunch. Once she persuaded her group of friends that he was mostly harmless, they started sitting together.`}</p>
    <p>{`Because Dimitri was suspended so often, his academic results dipped and was frequently attending supplementary classes. Byleth was his senior, and so she offered to tutor him in the library whenever she was there with whatever she could help.`}</p>
    <p>{`The first time he appeared in the library, audible gasps were heard - and the first time the librarian didn’t shush anyone for she too let out a shriek when he briefly looked at her.`}</p>
    <p>{`His hair was a mess, held back by a crude half-ponytail to keep it out of his face. She assumed he let his fringe grow out to cover the long scar across his eye lid.`}</p>
    <p>{`“…You’re staring, Eisner,” Dimitri said as he sat down next to her.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Sorry, I was just thinking about something,” she said, embarrassed he caught her staring.`}</p>
    <p>{`“What is it?”`}</p>
    <p>{`“Hrm?”`}</p>
    <p>{`“What were you thinking about? You had a really deep frown, right here.” He poked the spot in between her eyes.`}</p>
    <p>{`“It’s nothing, it’d be rude ask, shall we get started-“`}</p>
    <p>{`“-Spit it out,” he said bluntly and someone shushed him in the back. Dimitri turned around to see who it was and that person shrinked back in fear when they realised who it was.`}</p>
    <p>{`“Is there a reason why you don’t cut your hair?” She whispered.`}</p>
    <p>{`He paused, “I never found the need to cut it,” he replied simply.`}</p>
    <p>{`“Do your...err...opponents not take advantage of it while fighting?” He stared at her, but she looked back down at her textbook but looked back up at him, “You know, umm, like they grab it and punch you.”`}</p>
    <p>{`“They can’t reach that high,” he said flatly. She held back a snort.`}</p>
    <p>{`“I think you’d look like a...prince with a haircut,” she said absentmindedly. He was blonde and had striking blue eyes; the classic prince look.`}</p>
    <p>{`“How tall are you anyway?”`}</p>
    <p>{`He shrugged, “Last I checked, 188cm? In the last health exam, the school nurse couldn’t reach the top of the scale, so I had to do it for her.” She slapped a hand over her mouth, for some reason she found him...hilarious. He was so blunt and replied so earnestly, it was...endearing.`}</p>
    <p>{`He reached out as she was holding back her laughter, and tucked a lock of hair behind her ear. She immediately stopped laughing since she could suddenly hear her heartbeat in her ears when he did that.`}</p>
    <p>{`She cleared her throat, “Uh...you..umm, have questions for today?”`}</p>
    <p>{`“History,” he said, pulling out his workbook. “Specifically this chapter,” he flipped the book open and pointed at the title. She leaned over to see and felt relieved she actually knew something about this. There were times she couldn’t help him since she was bad at certain subjects. She wanted to feel useful to him as a senior. It made her feel like she was doing something good for once.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Byleth pulled out her exercise book and immediately drew a chart for him to visualise what was happening, she checked to see if there was anyone else around her in case she got too loud. She didn’t realise she had been rambling since Dimitri didn’t say anything or cut in, and by the time she was done explaining, her exercise book looked like an insane conspiracy theorist had scribbled in it.`}</p>
    <p>{`She sighed, “Sorry...I...ugh, I get really excited when it’s history.” She apologized. “Sorry if you didn’t catch any of that, um, I’ll...think of something-”`}</p>
    <p>{`“No, I understood it perfectly,” he said and she widened her eyes at him.`}</p>
    <p>{`“O..Okay,” she nodded, feeling pleased with herself. She ripped the pages out and handed it to him, “You can keep this, if you want. Or you could just...toss it.”`}</p>
    <p>{`As he accepted it, their fingers grazed and she felt a jolt of electricity shoot through her body. She quickly let go of it, deciding to do her homework. But for the life of her...she couldn’t focus. The tips of her fingers tingled, and not in an unpleasant manner either.`}</p>
    <p>{`Their little friendship continued throughout the year, they both spent almost everyday together in the library and slowly, Dimitri wasn’t getting suspended as frequently anymore. He didn't even need to attend supplementary classes.`}</p>
    <p>{`Time went by so quickly and before she knew it, she was already in her 3rd year, and about to graduate high school. She had invited Dimitri to her graduation ceremony since they were friends but he was nowhere to be seen. She did feel sad, seeing as they had spent the year together studying and eating lunch.`}</p>
    <p>{`Just as she was about to head home with Ingrid from school for the last time, she took one final look at her alma mater. Like every other teenager, she had complained about school, but now she was actually feeling reluctant to leave.`}</p>
    <p>{`“Eisner!” A familiar voice called loudly in the distance as a figure ran towards her. She squinted hard at the male figure approaching her.`}</p>
    <p>{`“Oh my Goddess, is that...Dimitri?” Ingrid said out loud, Byleth stared at her then at him, mouth agape, until he abruptly stopped in front of her, panting and wheezing.`}</p>
    <p>{`“I’ll just head home first, By. See you later!” Ingrid nudged and winked at her. She slapped Ingrid as Dimitri caught his breath.`}</p>
    <p>{`“I’m sorry I’m late…!” He wheezed.`}</p>
    <p>{`She shook her head, “It’s fine! You came in the end, I’m...happy.”`}</p>
    <Ads mdxType="Ads" />
    <p>{`“The hairdresser had too many people, and I didn’t know I had to make an appointment beforehand. It took forever to reach my turn, so I ran as fast as I could...” He sucked in deep breaths of air.`}</p>
    <p>{`“You look great!,” she said, awestruck at the sheer difference a haircut made. “Really...great…!” She felt a sneaky blush creeping across her face and shyly looked down at her feet. She cleared her throat.`}</p>
    <p>{`Dimitri said nothing but she could still hear his heavy breathing from the running. She looked up to see if he was okay but Dimitri came closer and closer to her until his lips were pressing on hers.`}</p>
    <p>{`She was shocked and took a step back, but he came forward again. This time, he wrapped his arms around her and pressed his lips once more, deepening the kiss.`}</p>
    <p>{`Byleth...didn’t mind it at all and gave into the kiss. But she had never kissed anyone before so It was a relatively short first kiss. They broke away and didn’t say anything to each other.`}</p>
    <p>{`“I...like you, Byleth,” Dimitri finally said, breaking the intense silence. “I’m sorry it took me so long, but I’m glad to have spent time with you. Please don’t forget me when you go to university.”`}</p>
    <p>{`She frowned at him, panicking slightly, “Uh...don’t...people become a couple after the first kiss?”`}</p>
    <p>{`His eyes grew and he blushed. “I lied, I’m sorry again. But I...I’ve liked you for a while now and...,” He breathed and pulled her into a hug. “I selfishly wanted to kiss you, and if you hated it, I was prepared to never see you again,” Dimitri whispered into her ear as he sniffed her hair.`}</p>
    <p>{`She quickly wrapped her arms around him too, inhaling his natural scent mixed with sweat. It smelled good to her. “I feel the same way, Dimitri.”`}</p>
    <p>{`He walked her home, her hand in his.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Byleth was not one bit ashamed her boyfriend was still in high school, especially since his parents gave their consent, even though she was only a year older.`}</p>
    <p>{`More than anything, she was glad she didn’t confess to Sylvain that fateful day.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      