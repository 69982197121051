import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`We at Geeknabe don’t usually get an invitation to a concert for us to review everyday! So when we were approached to review a streamed live concert by Lantis and Purple One Star for their debuting new artists, me and mari-chan jumped at it. We were both very excited to watch a concert live straight from Japan which for the record, is a first for us. The concert went on for 2 hours by having each artist sing 2 songs and attend an interview session by a really gung-ho host. There are 8 debuting artists altogether! Some of these debuting artists really surprised both of us. There’s even a VTuber in the list! Both me and mari-chan have our own thoughts on the concert, so I decided to split our thoughts into respective sections.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Thoughts on each artist`}</h2>
    <h3>{`#1 Ai Furuhata`}</h3>
    <Image alt="Ai Furuhata" caption="I do not know how she did it, but Ai Furuhata rocked her purple suit and gloves." name="furuhata-ai-lantis-purple-one-star-new-generation-live-2020.jpg" mdxType="Image" />
    <p><strong parentName="p">{`nabe-chan`}</strong>{`: I tried everyone’s music before watching this concert and Ai Furuhata’s songs are what caught my attention the most. Her debuting album is Moonrise and it had these really cool songs with an 80s vibe going on. She sang CITY and oh boy, I love the tune! And I absolutely love her purple outfit she wore in this New Generation Live concert. Absolutely spanking.`}</p>
    <p><strong parentName="p">{`mari-chan`}</strong>{`: I thoroughly enjoyed the first singer’s visuals and 80’s theme as I really like the whole 80’s vibe. Since the way to my heart currently is catchy retro music! So I hope we see more of her and her songs since CITY is stuck in my head now!`}</p>
    <Ads mdxType="Ads" />
    <h3>{`#2 Marina Horiuchi`}</h3>
    <Image alt="Marina Horiuchi" caption="Welcome to nanoland is a super cute song, but her white dress outfit is just perfection. Both me and mari-chan noticed how everyone is wearing platform shoes and we're like, how did they manage to dance in those?!" name="marina-horiuchi-lantis-purple-one-star-new-generation-live-2020.jpg" mdxType="Image" />
    <p><strong parentName="p">{`nabe-chan`}</strong>{`: While I easily found Ai Furuhata’s songs on Spotify, I couldn’t find Marina’s at all. So I didn’t know what her style was like when it’s her turn to sing! Her first song is a slow song which is unfortunately wasted on us two because we don’t have a good grasp on the Japanese language (Though we do understand simple Japanese phrases). Her second song is Welcome to Nanoland! and wow, it’s adorable. I think it really compliments her sweet, white dress with her outgoing dance. Both me and mari-chan were praising her platform boots and dress from the start.`}</p>
    <p><strong parentName="p">{`mari-chan`}</strong>{`: The second singer was very chirpy and I liked how she derived the ‘nano’ part of her songs from her name! I liked her second song, even though I couldn’t really hear what she was saying in the beginning because the music was so much louder than her mic T.T Which was such a pity because the second song was really upbeat!!`}</p>
    <Ads mdxType="Ads" />
    <h3>{`#3 Arcana Project`}</h3>
    <Image alt="Arcana Project" caption="I love how they arranged themselves in a way that everyone gets to be seen by the camera!" name="arcana-project-lantis-purple-one-star-new-generation-live-2020.jpg" mdxType="Image" />
    <p><strong parentName="p">{`nabe-chan`}</strong>{`: Alright I admit, this is way beyond my scope because I am not familiar with the idol scene at all. But I must say, their dancing is super coordinated. I preferred their second song because it had a lot of energy! From the interview, I could somewhat piece that they got together because they love anime? Darn, that’s wholesome. All the best Arcana Project! ;)`}</p>
    <p><strong parentName="p">{`mari-chan`}</strong>{`: They seemed to have good chemistry with each other which is nice to see! Even though there were some parts of their song a couple of them struggled to carry the notes a little, it’s amazing they can dance AND sing live at the same time! For an idol group that just debuted, I’m very impressed!`}</p>
    <Ads mdxType="Ads" />
    <h3>{`#4 Takao Sakuma`}</h3>
    <Image alt="Takao Sakuma" caption="I think he's perfect." name="takao-sakuma-lantis-purple-one-star-new-generation-live-2020.jpg" mdxType="Image" />
    <p><strong parentName="p">{`nabe-chan`}</strong>{`: Damn, this guy can `}<em parentName="p">{`dance`}</em>{`! Among the 8 debuting artists, Takao Sakuma is the biggest surprise for me. I wasn’t expecting him to dance so energetically for two whole songs. His songs remind me a lot of Kpop songs. A guy who can sing and dance, not to mention looks pretty cute too? Hmm… 🤔 He mentioned to the host that he only started dancing for less than a year. This is where both me and mari-chan call foul. I personally think Takao will go far in his career!`}</p>
    <p><strong parentName="p">{`mari-chan`}</strong>{`: The first male act for the night! The 4th singer was a really good dancer! He mentioned he trained for less than a year and like nabe-chan said, It’s so hard to believe because he danced`}<em parentName="p">{` really`}</em>{` well! In fact, even the host seemed really amazed by him too XD!`}</p>
    <Ads mdxType="Ads" />
    <h3>{`#5 MindaRyn`}</h3>
    <Image alt="MindaRyn" caption="When she started singing the ending for By the Grace of the Gods, I go whoaaaa!" name="mindaryn-lantis-purple-one-star-new-generation-live-2020.jpg" mdxType="Image" />
    <p><strong parentName="p">{`nabe-chan`}</strong>{`: I was really surprised to hear a familiar anisong when she started singing. I did watch By The Grace of the Gods anime! MindaRyn is really good at singing Japanese! Wow, I’m impressed by her pronunciation. Here’s hoping that I’ll see her singing more anisongs!`}</p>
    <p><strong parentName="p">{`mari-chan`}</strong>{`: Mindaryn seems to have a lot of potential! She was one of the ones who could steadily hold her notes too. I admire her courage to move to Japan for her music career and remember, this concert is the debut of the artists performing tonight!! But the star of her act was her drummer! He looked like he was enjoying himself the whole time he was performing behind her! It's so pleasant to see the musicians enjoy themselves as well!`}</p>
    <h3>{`#6 Kaede Higuchi`}</h3>
    <Image alt="Kaede Higuchi" caption="A Vocaloid? No! A VTuber!" name="kaede-higuchi-lantis-purple-one-star-new-generation-live-2020.jpg" mdxType="Image" />
    <p><strong parentName="p">{`nabe-chan`}</strong>{`: When Kaede Higuchi appeared on-screen, we were both dumbfounded. Is that a Vocaloid? A quick Google search revealed that Kaede is a famous VTuber who debuted with her single MARBLE. Vocal-wise, she’s my favourite. She has a wonderful voice!`}</p>
    <p><strong parentName="p">{`mari-chan`}</strong>{`: My first time experiencing a vtuber!! And I am so so impressed! I think she had the BEST voice out of all of them tonight, very steady notes, singing, she can control her voice well! I guess that's a given since she’s been singing since 2018! I’m still amazed at the technology behind vtubers!!`}</p>
    <Ads mdxType="Ads" />
    <h3>{`#7 Koutaro Nishiyama`}</h3>
    <Image alt="Koutarou Nishiyama" caption="He has a super clean voice and look. He can basically sing me a lullaby and I'll fall asleep in a jiffy." name="koutarou-nishiyama-lantis-purple-one-star-new-generation-live-2020.jpg" mdxType="Image" />
    <p><strong parentName="p">{`nabe-chan`}</strong>{`: I’m so happy to see another male artist, only to watch an MV-like sequence instead. I’m disappointed since I was eager to watch him sing live. Nevertheless, he has a soothing voice and his song choices really suit his style well. I think his songs are the type you’ll hear often in J-Drama openings!`}</p>
    <p><strong parentName="p">{`mari-chan`}</strong>{`: At first I was a little disappointed the 7th artist wasn't singing live since it looked like he was in an MV? But it turns out he’s my favourite act of the night! A refreshing voice with catchy songs!! His second song is my favourite from this concert! So pleased I’ve found a new song to listen to even though I can’t find it anywhere T.T`}</p>
    <Ads mdxType="Ads" />
    <h3>{`#8  Kanako Takatsuki`}</h3>
    <Image alt="Kanako Takatsuki" caption="She's feisty and really enjoyed the whole concert! A pity that this is streamed ): Like, I think she belongs to a full stadium!" name="kanako-takatsuki-lantis-purple-one-star-new-generation-live-2020.jpg" mdxType="Image" />
    <p><strong parentName="p">{`nabe-chan:`}</strong>{` I didn’t really enjoy the first song since the lights were flashing too much on-screen and Kanako Takatsuki were yelling a lot. I’m not too sure if I’m the right audience for her song. Her second song is much, much cuter however. Kanako is super energetic and was the only one who introduced her band members to the audience. That’s so wholesome, I like this lady. She’s really excited to sing!`}</p>
    <p><strong parentName="p">{`mari-chan`}</strong>{`: Oh the last singer was chosen well to end the night on a high note! And I mean that almost literally since she was kinda screaming hahaha and the lights were red and kept flashing at us, it was a little bit hard to watch ^`}{`_`}{`^” While her first song was not to my taste, her 2nd song was such a big shift and way more cuter and catchy! She was also extremely energetic despite wearing many layers and sweating! Plus she introduced her band members, the only artist to do so, that was a nice touch!!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Live streaming experience`}</h2>
    <p><strong parentName="p">{`nabe-chan:`}</strong>{` I fully expected a ticket entry form to pop up right when I visited the site. It’s only thanks to the chat that we could finally get the form to appear! Apparently we need to set our computer timezone to Japan in order to get it to pop up? I find this part of the site really strange. Nevertheless, our streaming experience is really good. The video did stutter a bit but it’s smooth sailing throughout the two hours. The video quality is really good too. My only problem with the whole streaming is the audio. I fully expected the audio to have high fidelity but instead, the vocalist’s voice sounds muted. Even the band is overpowering the vocals! The strange sound mixing did get better in the latter half but it’s honestly a shame, because the first two artists’ vocals are `}<em parentName="p">{`really good`}</em>{`.`}</p>
    <p><strong parentName="p">{`mari-chan`}</strong>{`: I agree, nabe-chan! This whole experience was exciting and also a little disappointing solely because of the audio. Most of the time the singers had to yell to get their voices across, because the band was so much louder than their voices. Which is sad because many of them had unique voices! Thankfully nabe-chan is so resourceful on finding out how the site works. On my own I would NEVER have figured out how and where to get the code to work T.T! As international fans/users, it would've been nice if they had included exact instructions for this.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`All in all`}</h2>
    <p>{`This is a great experience for the team at Geeknabe! Both me and mari-chan really enjoyed the whole stream! We were chatting/commenting on Facebook Messenger throughout the stream, just relishing the moment. I mean, we’re watching a live concert! How exciting! Everytime a performance ended, we would type, ‘clap clap!’I can see this gradually becoming a normal event even after the COVID-19 pandemic has passed. Thank you so much to qdopp for this opportunity!If anyone wants us to review anime, games, manga or even music, please contact us at `}<strong parentName="p"><a parentName="strong" {...{
          "href": "mailto:nabechan10q@gmail.com"
        }}>{`nabechan10q@gmail.com`}</a></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      