import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const RemoteLink = makeShortcode("RemoteLink");
const LocalLink = makeShortcode("LocalLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This is actually a list of my `}<strong parentName="p">{`writing habits for fanfiction`}</strong>{` that I’ve been practicing over the course of a year, plus some things I’ve discovered along the way. It honestly isn’t much, and I’ve only been writing for a short year with sporadic chapters in between, but it has helped me a lot! You don’t NEED to follow these but I do hope you’ll find it useful! ^^ p.s this isn’t really in order, just stuff that came to mind in that order XD`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Fanfiction Writing Tip #17: Write EVERYTHING down!`}</h2>
    <p>{`This is the most common advice I’ve been given, and the most popular tip many writers give - and it is for a good reason! I’m EXTREMELY forgetful and easily distracted, so writing EVERYTHING - be it a short scene, sentence or even a word is a life saver! I have soo many documents in my drive with just one word or a sentence. And you’ll thank your past self for doing so because it’s like ‘holy cow I wrote that??’ XD`}</p>
    <h2>{`Fanfiction Writing Tip #16: Set realistic goals and timetables!`}</h2>
    <p>{`It’s very, very, easy to get carried away with real life, work, studying etc. So set a realistic goal that `}<strong parentName="p"><em parentName="strong">{`you`}</em></strong>{` can achieve. How many chapters do you plan to write? What days do you want to write? I typically`}<em parentName="p">{` try `}</em>{`to write one chapter a month, and I`}<em parentName="p">{` try`}</em>{` very hard because I’m a champion procrastinator XD When I can’t seem to motivate myself to write, I make nabe-chan give me a deadline.What I normally do is visualise the end of a fanfiction, so I know what I’m working towards and how to work towards it. Sure it may change, but it helps to have an end goal!! And while I do desperately wish my favourite writers would update more often, `}<em parentName="p">{`we must remember`}</em>{` that they are people with limits and many problems/issues we don’t know about or - they’re just simply taking a break.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Fanfiction Writing Tip #15: Spacing, punctuation and grammar does matter!`}</h2>
    <p>{`I found this out the hard way hahaha. In the beginning my poor editor had to spend extra time just correcting my punctuation - and unfortunately it is still something I’m`}<em parentName="p">{` reallllyyyy`}</em>{` bad at XD. But you know, writing fanfiction is and should be fun! So don’t beat yourself up if it isn’t perfect, I honestly just stick to the basics, since this is still a learning process for me.`}</p>
    <p>{`I also notice that the way we write mimics the way we speak, and since English isn’t a first language for many people it can be difficult to make it ‘sound right’. So really, don’t be too hard on yourself, the bare minimum is honestly good enough ^`}{`_`}{`^ But many other blogs and books do help with this if you need help!!`}</p>
    <h2>{`Fanfiction Writing Tip #14: Don’t be shy to obtain workbooks or exercise books!!`}</h2>
    <p>{`One of the main reasons I started writing fanfiction was to improve my English, since I work in an environment where I don’t use English unless necessary, I was starting to lose my grip on the language - buying an elementary school workbook was a HUGE lifesaver. It gave me a big boost in grammar and sentence structuring, especially when I didn’t have the energy to write, this was a great way to improve my English!! I’m sure you can download workbooks online, but I enjoy the act of physically writing things down - it helps with committing it to memory!`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Fanfiction Writing Tip - English workbook" caption="This is a page from the workbook i bought, please ignore my horrible handwriting XD" name="fanfiction-writing-tips-image-1.jpg" mdxType="Image" />
    <h2>{`Fanfiction Writing Tip #13: Think from the reader’s perspective! `}</h2>
    <p>{`Oh let me tell you, it’s `}<em parentName="p">{`SO`}</em>{` easy to be completely absorbed when writing, especially when inspiration hits you. But there are times when we need to stop and think - Do people want to read this? Do readers find this easy to read? Is it too complicated? Can people relate to this? Do I really need this scene? - are some of the questions I ask myself.`}</p>
    <p>{`Even though ultimately we write for ourselves, it is important to make things easier for the wonderful people reading your work. Like...paragraphs are often overlooked, over-describing things or even the length of chapters. What I find helps is doing what YOU would like to see as a reader.`}</p>
    <h2>{`Fanfiction Writing Tip #12: Experience and Research are your best friends!!`}</h2>
    <p>{`I WROTE KISS SCENES AS A 13 YEAR OLD AND I HAD NO IDEA WHAT I WAS DOING T.T my goddddd, the amount of rubbish I wrote as a teenager `}<em parentName="p">{`gags`}</em>{` `}<em parentName="p">{`nuh-uh`}</em>{`. But!! You’re NEVER too old to start anything! In fact, I’m much happier writing fanfiction as an adult. I feel more..secure and stable even though I’ve only been alive for a couple of decades but man, the small amount of things I’ve seen, heard, experienced is invaluable to me.`}</p>
    <p>{`I find experience, `}<em parentName="p">{`no matter what it is`}</em>{`, even the most trivial thing like observing how people eat, extremely useful. This may sound insensitive, but even when a loved one or someone passes away can be used in writing, like what you felt, how people dressed, how they mourned etc. Experiences shape us and our perspectives.`}</p>
    <Ads mdxType="Ads" />
    <p>{`And if you don’t have the experience? RESEARCH! Watch YouTube, observe how your dad sips his coffee, look at how your partner/significant other puts on their shirt, or just...google! I’m fairly active on Facebook, so when people on my friends list take pictures of their injuries, or recount an experience, if it interests me; I ALWAYS send them a message and interview them (within reason and their comfort zone of course!!)For example, one of my fanfiction features life in boarding school, I spoke to a friend whose sister absolutely HATED boarding school despite having begged to go to one. While my dad totally enjoyed and LOVED his time there!`}</p>
    <h2>{`Fanfiction Writing Tip #11: In the same vein as point no.12, try acting out your scenes if possible!`}</h2>
    <p>{`Or read a dialogue/conversation out loud to see if it sounds right! For a scene in my fic involving two characters falling down stairs, I asked my boyfriend to catch `}<em parentName="p">{`and`}</em>{` fall while holding me as we fell on a mattress to see what it felt like. And WOW, it was SO different from just visualising it in my head. Like I didn’t think how tight you’d be held, so tight it hurt, or that you might tense up etc.However, It was much too dangerous to fall down an actual flight of stairs, so PLEASE do observe caution and don’t get carried away!!`}</p>
    <h2>{`Fanfiction Writing Tip #10: Read! `}</h2>
    <p>{`That's it. Read whatever interests you; novels, textbooks, short stories, other fanfiction, manga, subtitles etc. It might and will trigger new ideas and improve your language in the long run! (I’m a HUGEEE sucker for historical romance novels and if I get tired of romance, an Agatha Christie novel always gets me excited!!)`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Fanfiction Writing Tip #9: Whenever possible, get your work edited or have a beta reader!! `}</h2>
    <p>{`Ah, this is one a little bit difficult but is a super good way of improving! Having a beta reader/editor can be hard to find, and once you do find a willing soul, they kinda need to be familiar with your writing style, so they don’t accidentally turn it into `}<em parentName="p">{`their`}</em>{` own style. Plus you yourself need to be comfortable with feedback!`}</p>
    <p>{`Also! Know that it's okay to reject a correction your editor made if you liked how a certain scene or sentence sounded!What I find useful is letting my editor know what I need: Do I want her to go hardcore on the whole thing? Or do I just want her to check the flow and punctuation?`}</p>
    <p>{`While editing on your own is a MUST, sometimes you can’t really tell what’s right or wrong anymore. Having another pair of eyes can be the key to finding out ‘I don’t know what’s wrong with it’ XD Ah! Additionally, a beta reader/editor can sometimes spot your crutch words(filter words that you fall back on when you can’t think of anything else, usually very overused and can get annoying for the reader.)`}</p>
    <p>{`I am genuinely blessed because depending on what I write, I have `}<strong parentName="p">{`two`}</strong>{` editors;`}</p>
    <ol>
      <li parentName="ol">{`One checks my grammar, punctuation, flow, the technical stuff.`}</li>
      <li parentName="ol">{`The other one checks whether the characters are OOC.`}</li>
    </ol>
    <p>{`I love them both to bits and I make sure they know it!!!!!!!!!!!!!!!!!!!!!!!! DON’T BE ASHAMED OF NEEDING YOUR WORK TO BE EDITED! ALL PUBLISHED writers NEED to have their work edited!!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Fanfiction Writing Tip #8: DON’T TAKE IT PERSONALLY `}</h2>
    <p>{`This is also something I’ve had to learn the hard way, and in fact still something I have to constantly remind myself. Separate your writing from yourself, because feedback can sound like an attack on your personality or just you. Honestly, I get it, your work is your child, your baby! And yes, there are SOME nasty comments that `}<em parentName="p">{`are`}</em>{` attacks at you, just remember to take it in your stride and move on. If anything just ignore it - it’s them, not you.`}</p>
    <p>{`Take a deep breath, and think professionally when you see feedback - I used to get all touchy and sensitive when people told me what I needed to improve, but I was being insecure, like ‘Don’t read it then!!’ But then I realised people were taking the time to tell me how and what to improve! Random people on the internet `}<strong parentName="p"><em parentName="strong">{`actually`}</em></strong>{` cared enough to spend time helping you with your mistakes! Nowadays, I process all comments and forgive the mean ones, they probably had a bad day or something, if you have the mental energy, try and see what they’re basically trying to tell you ^`}{`_`}{`^`}</p>
    <h2>{`Fanfiction Writing Tip #7: And….Music! `}</h2>
    <p>{`I rely on music to a fault, so much so I had to put a sign on my door for my family to NOT suddenly ambush me XD in fact, I was listening to ONE `}<RemoteLink to="https://www.youtube.com/watch?v=TpxP1OBURVU" mdxType="RemoteLink">{`specific song`}</RemoteLink>{` that somehow didn’t relate to the mood on repeat for a week(about...4 hours a day for 7 days), when I was writing chapter 11 of Everything Changes but You. I don’t know why...but..It happens ok!`}</p>
    <Ads mdxType="Ads" />
    <p>{`ANYWAY. Music truly helps in getting you `}<em parentName="p">{`in the mood`}</em>{`, and organize your playlist however you like! Mine has mainly consisted of the same five to six Take That songs for a year now...my favourite song is `}<RemoteLink to="https://www.youtube.com/watch?v=7J3U4Ke0SCY" mdxType="RemoteLink">{`It Only Takes a Minute`}</RemoteLink>{`, thanks for asking, ahem XD`}</p>
    <Image alt="Save us all, do not look!" caption="This is how I have my 'playlist'. It's basically lots of tab open xD nabe-chan asked me, 'How do you live like this?'" name="save-us-all.jpg" mdxType="Image" />
    <p>{`I have no idea why I MUST have a hundred tabs open and while it drives nabe-chan insane and anyone who uses my PC (even my own PC hates it), it works for me XD Hey, artists can have their weird quirks, we writers can too! But for real, music is..irreplaceable. Could be anything, some random supermarket background jinggle you heard, a song you couldn’t get out of your head - A N Y T H I N G. I’m very basic so if I’m writing a romantic scene, I listen to really sappy love songs, if it’s an action scene, I listen to action movie soundtracks etc.`}</p>
    <h2>{`Fanfiction Writing Tip #6: Your mood/feelings affect how you write!`}</h2>
    <p>{`I notice when I’m writing just for the sake of writing, I tend to ramble too much and not pay attention to what I write and this can and probably will show up in your work. If you’re bored while writing, your readers will feel bored. If you’re feeling excited, your work will feel exciting to read, so on and so forth.`}</p>
    <p>{`Which is why I’m very reliant on music - it sets the mood. It sets `}<em parentName="p">{`my`}</em>{` mood. I’m a very fickle person and easily distracted by everything so I make appointments with myself, as in - `}<em parentName="p">{`I will write this weekend and I will focus`}</em>{`. And you know what? It works! (most of the time XD)`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Fanfiction Writing Tip #5: Have a reference sheet ready!`}</h2>
    <p>{`My vocabulary and descriptions are limited, and one day, nabe-chan sent me a`}<RemoteLink to="https://twitter.com/tomokoseph/status/1277959265186013185?s=21" mdxType="RemoteLink">{` link with a sort of cheat-sheet of words and descriptions`}</RemoteLink>{` and this has been my best friend. I `}<em parentName="p">{`cannot `}</em>{`tell you how much this has saved my bacon when I’m stuck!`}</p>
    <Image alt="Word reference cheatsheet" caption="Cheatsheet! Lots of useful terms here." name="reference-word-cheatsheet.jpg" mdxType="Image" />
    <p>{`While I personally printed mine out because I like feeling and seeing a physical thing in front of me, you can have it in a digital form, or even better, compile your own!! Having a reference sheet like this can help eliminate or lessen the use of pesky crutch words!`}</p>
    <h2>{`Fanfiction Writing Tip #4: What you sit on matters!!!!!!!!!`}</h2>
    <p>{`I didn’t realise this until after a year of writing on a hard, old, wooden chair from my dad’s childhood. I used to laugh at people who’d brag about their fancy schmancy gaming chairs - but I paid the ultimate price; I have chronic lower back pain from an unsuitable chair and bad posture. I always forget I’m not young and bendy anymore until it’s too late. Don’t make the same mistake as me!`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Kopitiam Chair. Its an antique lol." caption="I used to sit on something similar to this (picture from google)" name="kopitiam-chair.jpg" mdxType="Image" />
    <p>{`You will forget that you’re sitting for literal hours, be it writing, or gaming or researching etc. so please take care of your back and butt! If anything put an extra pillow behind your back for support if your chair allows it, or add your own butt cushion! If you’re planning on buying a new chair; do try it out in person `}<strong parentName="p">{`WHEN YOU CAN`}</strong>{`. It’s 2021 and the coronavirus is still out there!! So only go out when it’s safe to test the chairs in a showroom!! Please practice social distancing, sanitization and wear a mask when you do!`}</p>
    <p>{`I say this is important because you don’t know what your body likes. I tested the most expensive one in a brand’s line up, IT WAS SO WILDLY UNCOMFORTABLE. So just because it’s the most expensive,`}<strong parentName="p">{` it doesn’t mean it’s good for you`}</strong>{`. It was built for taller and bigger people, so the padding was hitting me in all the wrong places. (i’m smol and round)Me and nabe-chan got this chair! It’s a definite upgrade from the one I previously sat on T.T my butt is thanking me but my bank account is crying XD`}</p>
    <Image alt="TTRacing Gaming Chair" caption="It's a Swiftx2020 TTRacing Gaming Chair, for those interested. Unfortunately it's a local brand. I'm not sure if they ship overseas." name="ttracing-gaming-chair.jpg" mdxType="Image" />
    <p>{`If you can’t afford a new chair, or don’t want to get a new one; STAND UP OFTEN AND STRETCH! This is applicable no matter what you’re sitting on!## Fanfiction Writing Tip #3: Experiment+Practice to find your writing voice/style!`}</p>
    <Ads mdxType="Ads" />
    <p>{`My first fanfic in 10+ years, `}<LocalLink to="/fanfiction/everything-changes-but-you-season-1/" mdxType="LocalLink">{`Everything Changes but You`}</LocalLink>{`, was me trying to improve my writing and English! However the first few chapters to me were a `}<em parentName="p">{`disaster`}</em>{`, as I was still searching for my writing voice. Thankfully there are readers who like it, and were even kind enough to tell me it was good T.T (I love you all T.T).`}</p>
    <p>{`This is so overused but undoubtedly true;`}<strong parentName="p">{` practice makes perfect`}</strong>{`. Although I’m NOWHERE near perfect, I’m much, much more comfortable and happier with my writing. And you’d probably notice if you compared my chapter 1 to my latest work. If you have to, force yourself to write! I know I do XD Once again, when I can’t make myself write, I beg nabe-chan to make me XD`}</p>
    <h2>{`Fanfiction Writing Tip #2: OBSERVE EVERYTHING. Yes, EVERYTHING. `}</h2>
    <p>{`This is so overlooked by many people and it’s not just a tip for artists; If I had to give anyone a tip to write better, it’s `}<strong parentName="p">{`observation`}</strong>{`. I know this is similar to point no. 12, but this is the one thing that has helped me the most!! So, this probably sounds weird and….frankly a little creepy, but...I stare at people. Respectfully of course! And discreetly if I can XD! It’s actually quite exciting to see how people react to their surroundings and the unconscious habits they don’t know about.`}</p>
    <Ads mdxType="Ads" />
    <p>{`I look at everything, from top to toe, eyeing and mentally taking note of how this person’s shirt is bunching up at their waist, or that their shoes look well-worn and comfortable. Maybe they have a habit of not looking at people when they talk, suddenly you realise your colleague prefers to stand while they work. (Often I see lots of people with their fly open and check mine in panic XD)It’s truly, and very fascinating to learn these things, and if you’re close to a person, sometimes they’re happy you know so much about them, sometimes they’re horrified you know how often they adjust their pants.`}</p>
    <p>{`Personally, I’m flattered when someone notices something I don’t realise about myself. For example, recently, I’ve had 6 people tell me my voice when I answer the phone sounds COMPLETELY different in person. All this makes your characters and writing more...human. More...realistic. More relatable!`}</p>
    <h2>{`Fanfiction Writing Tip #1: Be kind to yourself and love what you do.`}</h2>
    <p>{`The biggest critique of our work...is ourselves. I find it SO hard to be kind to myself when writing. Once in a while, I’ll hate everything I write and it’s not turning out the way I want it to. Sometimes it takes a simple comment from a reader to remind myself that, ‘maybe...this isn’t so bad?’`}</p>
    <p>{`Your first fic is bound to be ‘bad’, you are going to write a couple of pieces that are not up to your standards and you know what? That’s totally fine! You’re okay! There will always be someone who likes the way you write, your writing style. That someone may not be you all the time, but remember there will `}<em parentName="p">{`always, always`}</em>{` be someone who will. And honestly, it’s really much better than you think it is! YOU’RE DOING SOMETHING MANY PEOPLE CAN’T AND WON’T DO! You’re always improving with every sentence and chapter you write!!!`}</p>
    <Ads mdxType="Ads" />
    <p>{`There have been maaaanny people who claim fanfiction isn’t, ‘real writing’, or that you’re not this, and you’re that or some other nonsense to make you feel small. The best tip is to literally ignore them. (I mean they took time out of their short lives to crap on things people enjoy...that says a lot about them. So GO OUT, ENJOY YOUR WRITING AND WRITE MORE.) More often than not, YOU’RE DOING THIS FOR FREE !!!!!`}</p>
    <p>{`First of all, do you know how much work you’re putting in??? You’re constantly, planning, THINKING, physically writing/typing, your eyes are strained, thinking AGAIN, you’re using a skill that’s easy to start but hard to master, you’re doing SO MANY THINGS AT ONCE!`}</p>
    <p>{`Seriously!! I have NO idea how some writers can write a whole novel’s worth of writing so fast, and so much and FOR FREE. So many of these fanfiction are...dare I say it, BETTER THAN PUBLISHED WORKS. Please forgive yourself when you’re not in the mood, or when you make grammar mistakes! So what if you didn’t finish a series within a year? Or that you only wrote 2 sentences a whole month?? Don’t be so hard on yourself!`}</p>
    <p>{`In the end, it's YOU who is doing the writing, it’s YOUR brain and body that is putting the work in, so take care and remember to be kind to yourself AND HAVE CONFIDENCE! I PROMISE YOU, YOU’RE DOING GREAT! AND I SEE IT!!! I’m really not the best at writing; but I LOVE doing it and I’ll die trying! >:D!`}</p>
    <h2>{`Extra Writing Tips from nabe-chan! And tips I, mari-chan also find invaluable!`}</h2>
    <h3>{`Extra Writing Tip #1`}</h3>
    <p>{`Taking the time to stop is essential! Go for a walk, stretch, SLEEP, Or...just stop thinking about it. Ideas come to you in the most unexpected time frame! REMEMBER TO HYDRATE YOURSELF AND PLEASE TAKE REGULAR TOILET BREAKS! It’s crazy how much ideas have come to us while we’re um...doing the number 2 XD And go pee! I know you’ve been holding it in! >:(`}</p>
    <Ads mdxType="Ads" />
    <h3>{`Extra Writing Tip #2`}</h3>
    <p>{`Your environment contributes a lot! Some people write better in a noisy environment (like me, mari-chan, I thrive in chaos and noise), while some need absolute silence. If your current environment isn’t working, then maybe you need a change of pace. It’s as simple as writing on paper or on your phone. Or maybe on your laptop in bed. Change the type of music you listen to etc.`}</p>
    <h3>{`Extra Writing Tip #3`}</h3>
    <p>{`You need a writing circle! Folks who enjoy writing like you, or people in the same fandom! I, mari-chan joined twitter for this purpose! (and also to shamelessly retweet fan works XD) It’s a wonderful feeling to belong somewhere, help each other out and surround yourself with like minded people!! It could be as simple as retweeting or just liking a writers/friends post. Or hitting that kudos button! Oh! Don’t forget to reach out to your readers as well!!`}</p>
    <h2>{`All in all`}</h2>
    <p>{`PHEW. That was incredibly refreshing to write!! But it is my sincerest wish that this post will help you somehow!And I would LOVE to hear what other tips do you have for fellow writers? Or really, just your writing habits!! Thank you for reading!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Bonus Infographic`}</h2>
    <p>{`nabe-chan put up each of these tip on an infographic she did from Canva!`}</p>
    <Image alt="17 Writing Tips for Beginner Fanfiction Writers" caption="Infographic for each tip written here. You can right click this image and save it that way." name="17-basic-writing-tips-for-fanfiction-writers.jpg" mdxType="Image" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      