import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXFanfiction/Chapter/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Divider = makeShortcode("Divider");
const AuthorNote = makeShortcode("AuthorNote");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`“YOU DID NOT, BYLETH EISNER!”`}</em>{` Dorothea squealed louder than Byleth anticipated.`}</p>
    <p>{`“Shhh!” Byleth motioned for Dorothea to quieten down, but it only seemed to excite the songstress even more.`}</p>
    <p>{`“BYLETH EISNER, WHO WOULD NOT EVEN HOLD HANDS ON THE FIRST DATE, JUST KIS—”`}</p>
    <p>{`Byleth reached over to clap a hand over Dorothea’s mouth, “Ashe and Raphael will hear you!”`}</p>
    <p>{`“If you’re lucky, that’s all they’ll do. Soon you’ll be snogging each other over the counter,” The brunette giggled happily.`}</p>
    <p>{`“Dorothea!” She covered her cheeks, “A-and I didn’t kiss him, not really. I just…touched his forehead with my mouth skin,” Byleth said and averted her gaze.`}</p>
    <p>{`Dorothea nodded, grinning. “More commonly known as a kiss.”`}</p>
    <p>{`While she did kiss his boo boo, she couldn’t help but wonder if it truly was out of habit as it has been what...10? 13 years since they last met?`}</p>
    <p>{`Or did she kiss his forehead because…well. Frankly, Dimitri was so handsome now.`}</p>
    <p>{`Byleth slumped over the table. This wasn’t how she normally was; she pretty much just kissed a whole stranger on the forehead.`}</p>
    <p>{`“Oh don’t be so dramatic, I’ve done worse things than kiss a man before we’ve even dated,” Dorothea prodded her. “If there’s chemistry, there’s chemistry. Age gap be damned. Besides, you dated that DILF…what…what was his name? Seth? Seteth? Didn’t you say the sex was hella good?”`}</p>
    <p>{`Byleth froze up. “It was only once, but yes, it was…okay. It was very good. I don’t know, I never once thought of Dimitri that way. Seteth was nice, reliable, good sex but…”`}</p>
    <p>{`“No spark?”`}</p>
    <p>{`“No spark.”`}</p>
    <p>{`“Oh hold on, is…is that him? That blonde guy.” Byleth swivelled round to see Dimitri standing outside the shop, with his back leaning on the glass window.`}</p>
    <p>{`Byleth got up immediately and unlocked the door, ushering him in from the cold. “Hey.”`}</p>
    <p>{`“Hi. I’m…sorry, I hope I’m not imposing too much? It's just, classes ended and I was looking for something to eat, and since you’re not open yet, I was going to wait until you were.” Dimitri said a little shyly.`}</p>
    <p>{`Her eyes went right up to his forehead, and felt her ears turn hot at the memory of cupping his head and placing a kiss on it. She was so embarrassed, especially since she nearly did it the first time he bumped his head.`}</p>
    <p>{`Byleth cleared her throat and quickly looked down at her watch, it was still their mid-day break. Dorothea sang in the nightclub a few doors down, and came over often to hang out and eat just before her performances.`}</p>
    <p>{`“He’s hot,” she whispered in Byleth’s ear, and Byleth merely looked at her in horror, hoping Dimitri didn’t hear that.`}</p>
    <p>{`“Alright, I’ll leave you two lovebirds alone, I gotta get back for rehearsals.” Dorothea winked at her. Byleth playfully slapped Dorothea on the arm as she shoo-ed her friend out.`}</p>
    <p>{`Of course her little act made her feel shy in his presence. Even made her hyper aware of him.`}</p>
    <Divider mdxType="Divider" />
    <p>{`This went on for weeks. Dimitri would come in with a group of friends to study, do assignments, and the like.`}</p>
    <p>{`According to his very charming friend, Sylvain, Dimitri was ridiculously popular in school. Lots of girls and guys alike would say hi to him or try to be his friend.`}</p>
    <p>{`“Really? Dimitri said he wasn’t when I asked.” Byleth’s eyebrows furrowed.`}</p>
    <p>{`Sylvain rolled his eyes. “Ugh, hot people don’t know they’re hot do they? Dimitri is absolutely clueless about how popular he is. He just thinks everyone is nice and friendly. Yeah— I know, I know. Lucky bastard has his choice of girls you know?” His red-headed friend winked at her.`}</p>
    <p>{`To which, Byleth felt a surprising pang of jealousy. She even found herself wishing she was his age, so she could be his peer of sorts.`}</p>
    <p>{`Dimitri and gang also came by when they `}<em parentName="p">{`didn’t`}</em>{` have homework, and he often gave her the reason for his friends choosing her cafe as their regular meeting point. Byleth nodded at the kitchen when he came over on such a day. “Come on, I’ll whip something up for you and your friends. They’re coming today too, right?”`}</p>
    <p>{`“It’s just me today, are you…disappointed?”`}</p>
    <p>{`She raised her eyebrows in response. “N-Not at all, I just thought it would be nice to cook for your friends too,” she replied, still a little surprised at his question. She turned to go to the kitchen, since she needed to eat anyway. “Do you still want something to eat? I got a good deal on cheese the other day but it’s expiring.”`}</p>
    <p>{`“Wait,” Dimitri grabbed her wrist. “Would…would it be okay for me to cook for you instead?”`}</p>
    <p>{`Shocked, she stared at the wrist he was holding on to. “You…want to cook for me?” He nodded and let go. Her heart sank unexpectedly when his warmth left her skin.`}</p>
    <p>{`“You don’t need to supervise me in the kitchen anymore. I know where everything is and I want you to take a break for once when I’m around.” The way Dimitri’s deep, bassy voice took charge made her feel at ease was rather…comforting.`}</p>
    <p>{`“Also, I can reach the top of the shelves now.” He chuckled.`}</p>
    <p>{`Byleth snorted. The past couple of weeks she found Dimitri completely and utterly charming, it wasn’t hard to see why he was so popular. “Yeah, okay, what the hell. Fine. … But can I help?”`}</p>
    <p>{`“Absolutely not,” Dimitri protested. “It’s your midday break, plus that would defeat the purpose of me cooking for you.” He took her hand and dragged her to the staff room, insisting she take a nap.`}</p>
    <p>{`She slept like a log until she felt a gentle shake. Byleth sat up with a jolt, panicking for a split second that she might’ve overslept.`}</p>
    <p>{`“Don’t worry. We still have time. You’ve got about 30 mins before opening,” Dimitri had a hand placed on her shoulder, lightly squeezing it. “I hope you’re okay with Gautier Cheese Gratin?”`}</p>
    <p>{`Byleth groggily sat up from the sofa and stretched. “Mmm, smells good,” she said, sniffing the air. “Smells `}<em parentName="p">{`really`}</em>{` good.”`}</p>
    <p>{`Dimitri looked pleased with himself when she said that and eagerly dished it out on a smaller plate. Kneeling by the sofa, he blew on a forkful of said dish, and held it in front of her mouth.`}</p>
    <p>{`“Here,” he said. He must’ve noticed her hesitating because he blew on it again and tapped the fork to his lips. “Mmm, it’s fine now.”`}</p>
    <p>{`Byleth couldn’t even remember the last time someone fed her. Shyly, she opened her mouth and he gently pushed it in her mouth. As she chewed, she was surprised by how balanced the flavours were.`}</p>
    <p>{`Byleth grabbed the fork and plate from him and shovelled the rest into her mouth unceremoniously. When she looked up, Dimitri was beaming ear to ear and patted the corners of her lips with a tissue.`}</p>
    <p>{`“I’m terrible at cooking but, Dedue, my best friend, wrote me an easy recipe to follow. Did you…like it?” He asked softly.`}</p>
    <p>{`“It’s bloody good,” she finally blurted out after swallowing. “And I haven’t slept so deeply in such a short period of time either.” It was something about the way Dimitri was so…reliable, trustworthy, and earnest.`}</p>
    <p>{`There was visible relief on Dimitri’s face. She’d wanted to say something, but Byleth could only stare, her brain being a little muddled from having slept so deeply. She found herself doing that a lot over the past few weeks — just staring at Dimitri. His incredible good looks aside, how he made her feel safe, looked after and comfortable was so attractive. The more she spent time with him, the more she saw him in a different light.`}</p>
    <p>{`A man.`}</p>
    <p>{`It also made her realise how unruly she probably looked like right now, compared to Dimitri who looked like he’d had a refreshing jog.`}</p>
    <p>{`Normally, she’d turn away once Dimitri caught her staring, or he’d be the one to turn away when she looked his way.`}</p>
    <p>{`But what she didn’t expect was Dimitri turning her face back to look at him.`}</p>
    <Divider mdxType="Divider" />
    <p>{`One would think Sylvain was the expert at giving out (unsolicited) love help, but really, it was Felix, on the brink of frustration who shouted at him. “If you keep acting like a scared little brat, that’s how she’s going to see you for the rest of your life! So get on with it! `}<em parentName="p">{`Ugh`}</em>{`.”`}</p>
    <p>{`Despite Felix’s scolding, with Byleth, everything came quite naturally. But he still couldn’t help his heart beating in his throat; he’d never been so forward in his life before. Even the time he kissed Ingrid in High School was because she stepped forward first.`}</p>
    <p>{`Dimitri was still kneeling on the floor as he leaned closer and miraculously, Byleth wasn’t moving away. Even more miraculous was her hand that had come to rest on his shoulder. Her eyes flickered down to his lips, as did his. “Byleth…may I kiss you?”`}</p>
    <p>{`He felt her hand slide, ever so lightly, from his shoulder to the nape of his neck, where it hesitantly stayed. He was sure she could feel the hairs on his neck stand with every searing touch she left on his skin. “Yes—”`}</p>
    <p>{`That was it.`}</p>
    <p>{`That was all it took for Dimitri to lose every ounce of control he had over the past few weeks when he dared himself to touch her, hold her hand, brush against her skin.`}</p>
    <p>{`Dimitri climbed onto the sofa she was still lying on and with a flip, pulled her onto his lap, pressing her body tightly against him, his mouth on hers. The heat of her body against him, her intoxicating scent and the feel of her soft, soft lips flamed the raging fire even more. He sucked and licked her cherry coloured lips. Dimitri mentally begged that she would push him away, that she would ask him to stop — because then it would’ve been the only way he could’ve regained his self-control.`}</p>
    <p>{`But she didn’t.`}</p>
    <p>{`She whimpered in his arms and leaned fully into the kiss, tangling her fingers in his hair, as if desperate to kiss him deeper. He hated that she probably had experience kissing other men, but the way she devoured him as their tongues danced and hands wandered made him forget his anger anyway.`}</p>
    <p>{`Suddenly, she frantically broke away and slapped a hand over her mouth and pushed him back a little. “N-No wait, I just ate—!” But Dimitri gripped both her hands and silenced her with another deep kiss, then gently let her down onto the sofa and continued where they left off. A moan escaped him when Byleth wrapped her legs around him and freed her hands from his grip.`}</p>
    <p>{`They must’ve been going at each other for so long that they jumped when Byleth’s phone rang impatiently in her back pocket. In fact, they were so engrossed in their little makeout session, he didn’t realise how dishevelled they looked.`}</p>
    <p>{`“Sorry, I overslept,” Dimitri overheard Byleth apologising to Leonie, Raphael and Ashe who had been waiting outside for quite a bit.`}</p>
    <p>{`Despite wanting to stay and help as usual, he quietly slipped out the back as instructed and promised to come back at closing time. Which was a good thing; he needed to do something about his raging erection.`}</p>
    <Divider mdxType="Divider" />
    <p>{`As promised, Dimitri came back that evening, way before closing time, actually. He ordered himself a drink and sat at his usual corner.`}</p>
    <p>{`Byleth found herself wishing she could just close up shop for the night because for the first time in a long time, she couldn’t focus.`}</p>
    <p>{`It felt wrong at first, her attraction for him, guilty that she was developing feelings for her childhood friend — a friend she thought of as a little brother. Sure, she’d notice him looking at her and then looking away flustered over the past couple of weeks, yet she swore her heart stopped when he asked if he could kiss her. There was just something about him that Byleth couldn’t resist. Something about the way his eyes caught hers, or the way he’d linger when they accidentally touched.`}</p>
    <p>{`Dorothea was right; it took Byleth ages to warm up to her ex-boyfriends, as fun as Claude was, as sweet as Ferdie was, or as reliable as Seteth was, the fire she felt in the pits of her stomach when Dimitri kissed her just could not be compared.`}</p>
    <p>{`Now that fire had turned into an ache. She needed more.`}</p>
    <p>{`And thank the Goddess their staff room had no CCTV, otherwise she wouldn’t know how to explain to her parents how or why, Dimitri had cummed all over her stomach after he fucked her thighs that night.`}</p>
    <p>{`Or why she was sucking him dry and made out for another 20 minutes before he went down on her, inserting his thick fingers and stroking her walls so disgustingly good she besmirched the Goddess’s name when she climaxed.`}</p>
    <p>{`Honestly, if her parents had come back the next day, she didn’t know how to explain why hers and Dimitri’s clothes were strewn all over the entrance of their home, and that they couldn’t even make it to a bed before Dimitri let her ride his face on the sofa.`}</p>
    <p>{`“I…I’m not sure how to roll this on,” Dimitri said so earnestly, Byleth couldn’t help but kiss him. In her hand, Dimitri’s hot throbbing cock twitched, leaking out pre-cum as they kissed while lounging on the sofa. Probably the second or third time now, but who’s counting anyway.`}</p>
    <p>{`“You’re awakening a side of me I didn’t know existed, Dimitri,” Byleth mirthed, leaning down to lick the salty pre-cum from his slit. “You make me feel `}<em parentName="p">{`so`}</em>{` hot.”`}</p>
    <p>{`He cupped her chin and stuck his thumb inside her mouth. She sucked on it, twirling her tongue round his calloused thumb. He leaned over and purred in her ear, “I love this side of you, too. Show me more, please?”`}</p>
    <p>{`Before Byleth could tear open the condom, Dimitri lifted her up in his arms and went up the stairs to her room. “I want to do this where you’re more comfortable,” he whispered, gingerly setting her down.`}</p>
    <p>{`She pulled him on top of her, once the condom was securely in place and lube applied, Dimitri pressed his cock on her slit, mixing the lube and her wetness together making sounds that made her even more excited. He used his tip to draw circles round her clit, sending delicious shivers up her spine before slowly sliding in.`}</p>
    <p>{`The stretch stung, but it stung `}<em parentName="p">{`so good`}</em>{`. It made her open up her legs more, and her back arched in anticipation and hunger.`}</p>
    <p>{`Dimitri stopped, brushing aside the hair from her face. “Are you alright?”`}</p>
    <p>{`Byleth bit down on her lip, nodding and Dimitri disappeared inside her, inch by inch, until his balls were skin to skin with her.`}</p>
    <p>{`He bent down and groaned into her neck, “You feel so good.”`}</p>
    <p>{`“I’m okay, please…fuck me, Dimitri,” Byleth begged. She could feel the cock inside her grow bigger and she laughed. “Oh Dimitri.” As she kissed him, Dimitri pulled out and slid back in again. And again. And again, until he was pretty much ramming her so hard the bed shook.`}</p>
    <p>{`“I-I’m sorry, I wanted to be more gentle—but, `}<em parentName="p">{`ugh`}</em>{`, the way you suck me in, Byleth… my hips can’t stop— ”`}</p>
    <p>{`“Don’t stop—” she gasped.`}</p>
    <p>{`Suddenly, he pinned her hands above her head, and he bent down, just enough to tell her, “I hate that I’m so jealous I’m not your first, I hate that there was another man before me, I hate that I’m younger than you—`}<em parentName="p">{`mmmph`}</em>{`, Byleth—” Still, true to his word, his hips were piston-fucking her non-stop.`}</p>
    <p>{`She didn’t regret her previous sexual experiences with her ex’s, now more than ever, she appreciated it actually. Anything less and this probably would have hurt. A lot.`}</p>
    <p>{`Byleth found herself addicted to him sighing in her mouth whenever he shivered with pleasure. And all she wanted to do was to hear him moan. Especially how the timbre in his voice tickled her ears when she squeezed down on him or rubbed her thumb over his nipples.`}</p>
    <p>{`Dimitri came with the sexiest groan as he confessed how much he loved her, over and over. They lay connected to each other, panting, inhaling and exhaling each other in silence.`}</p>
    <p>{`“Oh Goddess, you didn’t finish,” Dimitri flopped over in a panic, his expression guilty. “Can I…get you off?”`}</p>
    <p>{`Byleth looked at him, taken aback by his words in a good way. “Sweet Dimitri, you’re so kind. But I’m okay with not finishing. I enjoyed the process.”`}</p>
    <p>{`He furrowed his brows. “Are you sure? Would you like me to step outside for a bit?” No one had been so concerned about whether she finished or not, and her heart skipped a beat.`}</p>
    <p>{`She held a finger up, contemplating for a second, but he was so encouraging that she felt like she could trust him.`}</p>
    <p>{`Byleth rolled over to her bedside drawer and pulled out her trusty pink sex toy— a 3-in-1 vibrator, suction and mini dildo.`}</p>
    <p>{`She shyly crawled back to him. “Will you kiss me while I use this?”`}</p>
    <p>{`“You…you’ll let me watch?” Dimitri sounded as though someone told him he won the lottery.`}</p>
    <p>{`“I’m so embarrassed even though we just had sex, but…will you? I’ve never done this in front of anyone before,” she said softly and averted her gaze.`}</p>
    <p>{`But Dimitri turned his head to meet her eyes, and happily agreed without question. Never in her wildest dreams had she imagined pleasuring herself in front of a man, and she’d definitely do it again; the orgasm was so strong, so intense, that she shyly told him it was one of the best she’d had in a while.`}</p>
    <p>{`When they were cleaned up and snuggled up in bed, Byleth couldn’t help but reminisce at how she used to be the one cuddling him, but now, she fit snugly in his arms.`}</p>
    <p>{`Yet just before he fell asleep, Dimitri mumbled he loved her. Taking a moment to assess her feelings, while staring into his striking blue eyes, she kissed his forehead and whispered that she loved him, too.`}</p>
    <Divider mdxType="Divider" />
    <p>{`Dimitri, eager as he was to marry her, was determined to make something of himself before properly asking her to marry him. He couldn’t help that he was younger than his girlfriend by 4 years but he could try his best to be someone she could rely on to close the gap between them.`}</p>
    <p>{`But by the time he graduated University, Dimitri couldn’t wait anymore and quietly proposed to her just after closing time, in the cafe that brought them together all those years ago.`}</p>
    <p>{`“If I say yes, can you kiss me more?” Byleth finally replied after giving him her famous wide-eyed stare.`}</p>
    <p>{`He laughed and swept her off her feet, forgetting that she was half his size as he squeezed her. “You’ve made me the happiest man, Byleth,” Dimitri said as he picked her up and placed her on the counter, peppering her with kisses until she giggled.`}</p>
    <p>{`“Have I told you that I’m so glad you came back?” She looped her arms round his neck, smirking up at him.`}</p>
    <p>{`“I’m going to say no, so that you can tell me again,” he chuckled.`}</p>
    <p>{`Suffice to say, Sylvain and especially, Felix were more than relieved when they finally got married. To their horror, even after marriage, Dimitri never once stopped talking about his wife.`}</p>
    <p>{`But they didn’t mind, since they were godfathers to the most adorable children ever.`}</p>
    <AuthorNote mdxType="AuthorNote">
      <p>{`HAPPIEST BIRTHDAY AMANDA!!!!!!!!! I FINISHED THIS ON TIME!! XD Amanda asked for a cute cafe AU!! You’re my writing anchor and beloved mentor/big sister/bff!!!`}</p>
      <p>{`And I hope everyone who reads this will enjoy it too!!! thank you so much for all your support, everyone!!! Thank you for reading and leaving comments, likes, kudos etc!!`}</p>
      <p>{`While brainstorming ideas for this, coincidentally, my bff sent me this manga - ‘Gohoubi ha Dekiai de Wanko na Kare ha Watashi wo Amaku Torokesaseru’ so this fic is inspired by it!!`}</p>
    </AuthorNote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      