import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const RemoteLink = makeShortcode("RemoteLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`It’s no doubt that Genshin Impact took the field by storm when it was first released on Sept 28, 2020 and with it, a plethora of online guides popped up all over the place. I find that most of these guides are super badly written or incomplete because everyone wants to get traffic. Even YouTubers keep making silly content to get attention. Like, I get it. The game is wildly popular and it’s easy to cash in. When the game was first released, it’s `}<strong parentName="p">{`so hard`}</strong>{` to find trustworthy information. There’s so many puzzles and treasure chests in Liyue and Mondstadt combined, and yet no one has all of the info.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Fast forward to 2021, there are now trustworthy tools and even databases of the game’s info! At the very least, they tell you what you need to know with numbers, facts and whatnot instead of personal imho/speculations in the name of `}<em parentName="p">{`content. `}</em>{`Here are some of my favourite tools that I am currently using.`}</p>
    <h2>{`Genshin Impact Useful Online Tool #1: Resource Tracker`}</h2>
    <p>{`If you’re like me who is always super into pre farming for characters before they are released, or is just bad at keeping track of how much materials you need, this is for you. Simply select a character, and start adding in all of the Talent LVL you wanted, the total level for this said character, etc. You can even add a Custom Character (really useful for beta characters) so you could plan your farming. It also tells you what day the materials are available for you to farm! Really useful when I was pre farming for Yoimiya.`}</p>
    <Image alt="Genshin Center screenshot" caption="I used this site to plan for Her Excellency, Raiden Shogun and her 4-star weapon." name="genshin-impact-useful-tool-1.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <p>{`The site even has other tools like Achievement Tracker, Weapon Resource Tracker and such. I only used the site for my Character and Weapon tracker so far, I’m not too sure how good the rest of the site is. The resource tracker is good enough for me for now. PS: It even remembers your previous selection when you revisit the site! No login needed. `}<RemoteLink to="https://genshin-center.com/planner" mdxType="RemoteLink">{`Visit the resource tracker site here. It's called Genshin Center.`}</RemoteLink></p>
    <p><strong parentName="p">{`Update: miHoYo themselves has launched a resource calculator tool. You can access it on Hoyolab or on PC. The best part about this tool is they let you sync with you account so you can get your characters' info as well. `}<RemoteLink to="https://webstatic-sea.mihoyo.com/ys/event/calculator-sea/index.html?bbs_presentation_style=fullscreen&bbs_auth_required=true&utm_source=hoyolab&utm_medium=post#/" mdxType="RemoteLink">{`Give it a try here`}</RemoteLink>{`.`}</strong></p>
    <h2>{`Genshin Impact Useful Online Tool #2: Hoyolab App + Interactive Map `}</h2>
    <p>{`The best, most trustworthy info surely must come from the company that did the game, right? Wellll I guess. Just this year, miHoYo released a mobile app called Hoyolab so you could access their forum on the go. That’s nice and all (I only use it to check Official News/Notice) but what’s super great about this app is this… It gives us direct access to the Interactive Map AND Daily Login! Yay, no more switching window tabs to check the Interactive Map from your PC browser! And you are more likely to tap on a mobile app to claim your dailies than going through the desktop version. `}<RemoteLink to="https://www.hoyolab.com/genshin/article/551103" mdxType="RemoteLink">{`You can download Hoyolab for iOS or Android.`}</RemoteLink></p>
    <Ads mdxType="Ads" />
    <p>{`Aha yes, which reminds me... I think there are lots of folks who didn’t know about the Interactive Map. miHoYo personally released a map with all of the Statue of Sevens, Electrogana, etc marked on it. Previously, we players had to rely on this `}<RemoteLink to="https://genshin-impact-map.appsample.com/#/" mdxType="RemoteLink">{`community interactive map`}</RemoteLink>{` before. Goodbye genshin impact map app sample… You were great while it lasted.`}</p>
    <p>{`Anyway, here's the `}<RemoteLink to="https://webstatic-sea.mihoyo.com/app/ys-map-sea/?lang=en-us#/map/2?shown_types=3,111,112,2" mdxType="RemoteLink">{`Official Interactive Map`}</RemoteLink>{`. The best thing about miHoYo’s Interactive Map is that it syncs with your Genshin account and pulls out your pins too. Good stuff. I do use it if I miss a chest or two but I noticed that it doesn’t really list out all the chests properly. I remember there’s a chest in the middle of Liyue’s sea but it didn’t match up with my map when I found it. It was on Beidou’s ship when I found it. Strange.`}</p>
    <h2>{`Genshin Impact Useful Online Tool #3: Wish Tracker`}</h2>
    <p>{`Okay so this might be the most underrated tool ever. A friend of mine who browses the Chinese reddit (NGA) often shared this to me. It’s a tool that automatically extracts your wish history and displays them nicely in a readable format. You could even export it to Excel to read the finer details (It exports all of the weapon/character names you pulled). Don’t worry, it doesn’t steal your account password or username. I personally checked the source code. Plus, this is an open source project so if the author tries anything funny, the users will be the first to know since they can see what the author has coded up. It’s safe!`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Genshin Impact Wish Tracker" caption="Your Wish Tracker will look something like this. It tells you at what pity counter you managed to pull your 5-star characters. Currently, the game's Wish History only keeps up to 6 months. With this app, you can keep your Wish History forever." name="wish-tracker.jpg" mdxType="Image" />
    <p>{`I added the link to the `}<RemoteLink to="https://github.com/biuuu/genshin-wish-export/blob/main/docs/README_EN.md" mdxType="RemoteLink">{`English README here`}</RemoteLink>{`. You could use their site, or download the zip file and use it immediately. If you’re feeling super tech snazzy, you could even fork the project and build it yourself. If you didn’t like this wish tracker, you could try `}<RemoteLink to="https://paimon.moe/" mdxType="RemoteLink">{`Paimon.moe`}</RemoteLink>{`’s. Heck, that site has them all (E.g. database, wish tracker, etc) but I rarely use it. Perhaps you’ll like that site better compared to the rest of the tools I’m about to share here.`}</p>
    <h2>{`Genshin Impact Useful Online Tool #4: Spiral Abyss Tracker`}</h2>
    <p>{`I often lurk the main subreddit to see what’s up. The fan arts are nice but what’s even nicer is when someone posted an infographic! So that’s how I found out about `}<RemoteLink to="https://spiral-abyss.appsample.com/" mdxType="RemoteLink">{`this site`}</RemoteLink>{`. The site pulls info directly from submitted public Battle Chronicles to get their data-set. It shows you the most popular characters folks are using to clear Spiral Abyss.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Spiral Abyss stats" caption="You seeing that?! Yoimiya is there too! T.T" name="genshin-impact-spiral-abyss-tracker.jpg" mdxType="Image" />
    <p>{`Perhaps this could give you an idea how to build your team? Man, Zhongli and Kazuha are unbeatable. If you want to submit your Spiral Abyss team, make sure to make your Battle Chronicles in your Hoyolab account public and `}<RemoteLink to="https://spiral-abyss.appsample.com/submit-your-data" mdxType="RemoteLink">{`submit your UID to the site here.`}</RemoteLink></p>
    <h2>{`Genshin Impact Useful Online Tool #5: Honey Impact`}</h2>
    <p>{`Imagine going through Genshin Impact since Day 1 and not knowing this useful database! Hah! Honey Impact usually pulls the latest data from closed beta tests and pre-patch. So that’s how folks get to know what characters are coming BEFORE miHoYo does the livestream. I am not sure if they will change their modus operandi anytime soon, but I still consider this database to have the most accurate data and clean UI. `}<RemoteLink to="https://genshin.honeyhunterworld.com/?lang=EN" mdxType="RemoteLink">{`Visit them here.`}</RemoteLink></p>
    <h2>{`Genshin Impact Useful Online Tool #6: Primogems Tracker`}</h2>
    <p>{`This is not exactly a tool, just a really useful Google Sheet someone made and I feel obligated to share. I kinda forgot where I chanced upon it, but it’s so simple and easy to use! It tells you how many pulls you need to reach hard pity, as well as the percentage to hit your next 5-star. It’s good for those who want to know how many primogems they need to buy and what crystal pack is bang for buck for the total primogems they need.Make sure to `}<RemoteLink to="https://docs.google.com/spreadsheets/d/1sJ4exi96l9Dw8M7BSAYGnb2sFMe77_qI69gfbFcZwPo/edit#gid=0" mdxType="RemoteLink">{`make a copy of this Google Sheet`}</RemoteLink>{` if you want to use it!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Genshin Impact Useful Online Tool #7: Galaxy Bot`}</h2>
    <p>{`I believe most players are already in a Discord server with friends or by online communities. Just the other day, I discovered a really useful bot that does all of the things I want it to. Well technically, if you play the game long enough, you’ll know what’s the best builds, weapons and artifacts by heart already. But sometimes, you want to see how other folks build theirs. I’m currently using Galaxy Bot for two things; artifact rating and build rating.`}</p>
    <p>{`The artifact rating is pretty cool. They use a tool to scan your screenshot, then rate it based on what character you intend to use it with. I mean, sure, you already know what sub stats a DPS character needs but sometimes you just want to tell everyone you have this god roll artifact you have :P`}</p>
    <Image alt="Galaxy Bot artifact rater" caption="I just keep getting DEF artifacts..." name="genshin-impact-galaxy-bot-artifact-rater.jpg" mdxType="Image" />
    <p>{`Then there’s the build rating. You could rate each character’s build by sending the bot a screenshot of your artifact, or browse through their super in-depth build guides they have for EACH CHARACTER. Wow, I checked Zhongli and Ningguang’s build guide, and they pretty much got it. It’s summarized in an easy to understand fashion too. `}<RemoteLink to="https://galaxybot.net/start/" mdxType="RemoteLink">{`If you have a Discord server, you should totally add this bot in`}</RemoteLink>{`.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Galaxy Bot build guide" caption="I think they pretty much listed down all of the possible builds you can do for each character!" name="genshin-impact-galaxy-bot-build-rater.jpg" mdxType="Image" />
    <h2>{`Genshin Impact Useful Online Tool #8: Keqingmains`}</h2>
    <p>{`I think many folks have Keqing at this point. She may have spooked you when you tried for a 50/50 chance in the Character Wish banner or got a lucky 5-star pull in the Standard Banner. She’s really fun to use but Electro reactions are kinda bad (for now… I hope). Plus, her damage really couldn’t match up to the Pyro 5-star characters. Sad… Anyway, there’s this`}<RemoteLink to="https://keqingmains.com/" mdxType="RemoteLink">{` site of dedicated Keqing players`}</RemoteLink>{` that tells you what is the best way to play/build her. `}<strong parentName="p">{`Absolute based.`}</strong></p>
    <p>{`They have since expanded to add other guides too. Unfortunately, not all. I mean, I just pulled a cute Jiangshi loli cryo healer but they don’t have a build guide for her when I wrote this guide...While I’m at it, there’s also a subreddit full of dedicated`}<RemoteLink to="https://www.reddit.com/r/aether_mains" mdxType="RemoteLink">{` r/Aether_Mains`}</RemoteLink>{`. Traveler’s damage is actually pretty good. You can hit 20K steady if you build Geo traveler. But of course, everything boils down to your artifacts and weapons. Check out `}<RemoteLink to="https://keqingmains.com/" mdxType="RemoteLink">{`this site`}</RemoteLink>{` for some of the most in-depth build guides by the community.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Genshin Impact Useful Online… Tool? #9: This amazing YouTube channel Wow Quests`}</h2>
    <p>{`Inazuma is killing me. Just. WHERE. Is the last. 4 ELECTRO SIGIL?! In 2.0, the max Electro Sigil you could get is 173 apparently. I’m stuck at 169. I’m not super keen to check the Official Interactive Map because it doesn’t tell you which chest you’ve unlocked. So off I go to check out online guides. Most of these guides are so spread out! And then… I found Wow Quests.The sheer dedication of this channel is frightening. I mean, look at this screenshot below!`}</p>
    <Image alt="Wow Quests videos on Genshin Impact" caption="I think this channel covered almost everything..." name="wow-quests-video-guide-genshin-impact.jpg" mdxType="Image" />
    <p>{`This channel got them all. And the best part? He added TIMESTAMPS, and showed you where it is on the map plus how to clear the puzzles. Genshin Impact is that one game that really needs a video as a guide since the treasure chests can be underground or hidden behind a bush. It’s hard to find chests if all these online guides do is put a red circle on the overworld map and call it a day. `}<RemoteLink to="https://www.youtube.com/channel/UCVl2xNAPHlrlUtmstHMY_pw/featured" mdxType="RemoteLink">{`This channel is a godsend. Thank you Wow Quests.`}</RemoteLink></p>
    <Ads mdxType="Ads" />
    <h2>{`All in all`}</h2>
    <p>{`I hope this blog post has something new to you! I know that many younger players aren’t super tech savvy, so the idea of googling for Genshin Impact-related tools never occurred to them. For one, many didn’t know about the Resource Tracker in #1. I saw plenty of reddit posts asking how many materials do they need to reach LVL 80! So there you go. Ask and ye shall receive.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      