import { Fragment, useRef } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import $ from '../../styles/globals';
import data from './items.json';
import atFanfictionChapter from './atFanficChapter';
import { addKeys, importOneSVG, useOutsideClick } from '../../utils';

const Container = styled.div`
  position: fixed;
  z-index: 1;
  background-color: rgba(3, 6, 12, 0.67);
  opacity: 0;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  pointer-events: none;
  transition: all 0.3s ${$.transition};

  &.active {
    pointer-events: initial;
    opacity: 1;
    > * {
      transform: translateY(0);
    }
  }

  ${$.screen.desktop} {
    display: none;
  }
`;

const InnerContainer = styled.div`
  box-shadow: ${$.shadow.normal};
  background-color: ${$.white1};
  transform: translateY(-4em);
  float: right;
  height: calc(100vh - ${$.layout.padding8 * 2}px);
  max-width: 250px;
  padding: ${$.layout.padding8}px 0;
  transition: all 0.3s ${$.transition};
`;

const ScrollContainer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  width: calc(100% - 20px);
  ${$.verticalScroll}
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${$.grey1};
  margin: ${$.layout.margin1}px 0;
`;

const SectionTitle = styled.div`
  font-family: Rubik Regular;
  font-weight: bold;
  color: ${$.brown3};
  padding-bottom: ${$.layout.padding2}px;
  padding-left: ${$.layout.padding3}px;
  padding-right: ${$.layout.padding5}px;
  width: calc(100% - ${$.layout.padding3 + $.layout.padding5}px);
  font-size: 18px;
  line-height: 1.1em;

  ${$.screen.tablet} {
    padding-top: ${$.layout.padding2}px;
  }

  ${$.screen.mobile} {
    padding-top: ${({ first }) =>
      first ? `${$.layout.padding3}px` : `${$.layout.padding2}px`};
  }
`;

const SectionDescription = styled.div`
  font-family: Rubik Regular;
  color: ${$.brown4};
  width: calc(100% - ${$.layout.padding3 + $.layout.padding5}px);
  padding-bottom: ${$.layout.padding2}px;
  padding-left: ${$.layout.padding3}px;
  padding-right: ${$.layout.padding5}px;
  line-height: 1.3em;
  font-size: 16px;
`;

const SubItemContainer = styled(Link)`
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: calc(100% - ${$.layout.padding3 + $.layout.padding5}px);
  padding: ${$.layout.padding1}px ${$.layout.padding5}px ${$.layout.padding2}px
    ${$.layout.padding3}px;

  svg {
    width: 26px;
    height: 26px;
    margin-right: ${$.layout.margin2}px;
  }
`;

const SubItem = styled.div`
  font-family: Rubik Regular;
  font-weight: bold;
  align-self: center;
  color: ${$.brown4};
  font-size: 17px;
`;

const ItemContainer = styled(({ to, children, ...rest }) => {
  if (typeof to !== 'undefined') {
    return (
      <Link to={to} {...rest}>
        {children}
      </Link>
    );
  }

  return <div {...rest}>{children}</div>;
})`
  display: flex;
  flex-direction: row;
  text-decoration: none;
  align-items: center;
  width: calc(100% - ${$.layout.padding3 + $.layout.padding5}px);
  padding: ${$.layout.padding1}px ${$.layout.padding5}px ${$.layout.padding1}px
    ${$.layout.padding3}px;

  svg {
    width: 20px;
    height: 20px;
    align-self: top;
    margin-right: ${$.layout.margin1}px;
    --icon-color: ${$.brown5};
  }
`;

const ItemInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

const Item = styled(SubItem)`
  align-self: initial;
`;

const ItemDescription = styled(SectionDescription)`
  padding: 0 !important;
`;

const NightMode = createGlobalStyle`
  body.night-mode {
    ${SubItem} {
      color: ${$.white1};
    }
    ${SectionDescription} {
      color: ${$.white1};
    }
    ${Divider} {
      background-color: ${$.brown1};
    }
    ${SectionTitle} {
      color: ${$.white1};
    }
    ${InnerContainer} {
      background-color: ${$.night};
    }
    ${ItemContainer} {
      svg {
        --icon-color: ${$.white1};
      }
    }
  }
`;

const items = addKeys(data).map(({ subs, ...details }) => {
  if (typeof subs !== 'undefined') {
    return {
      subs: addKeys(subs),
      ...details,
    };
  }

  return { ...details };
});

const BurgerMenu = ({ show, setBurgerMenu }) => {
  const containerRef = useRef(null);

  useOutsideClick(containerRef, () => {
    setBurgerMenu(false);
  });

  return (
    <Container className={show ? 'active' : ''}>
      {atFanfictionChapter() && <NightMode />}
      <InnerContainer ref={containerRef}>
        <ScrollContainer>
          {items.map(
            (
              { divider, subs, type, description, icon, text, key, link },
              index
            ) => {
              switch (type) {
                case 'section':
                  return (
                    <Fragment key={key}>
                      <SectionTitle first={index === 0}>{text}</SectionTitle>
                      <SectionDescription>{description}</SectionDescription>
                      {subs.map(
                        ({
                          key: subKey,
                          link: subLink,
                          text: subText,
                          icon: subIcon,
                        }) => (
                          <SubItemContainer
                            to={subLink}
                            onClick={() => {
                              setBurgerMenu(false);
                            }}
                            key={subKey}
                          >
                            {typeof subIcon !== 'undefined'
                              ? importOneSVG(subIcon)
                              : ''}
                            <SubItem>{subText}</SubItem>
                          </SubItemContainer>
                        )
                      )}
                    </Fragment>
                  );
                case 'item':
                  return (
                    <ItemContainer
                      key={key}
                      to={link}
                      onClick={() => {
                        if (typeof link !== 'undefined') {
                          setBurgerMenu(false);
                        }
                      }}
                    >
                      {icon ? importOneSVG(icon) : ''}
                      <ItemInfoContainer>
                        <Item>{text}</Item>
                        <ItemDescription>{description}</ItemDescription>
                      </ItemInfoContainer>
                    </ItemContainer>
                  );
                default:
                  if (divider === true) {
                    return <Divider key={key} />;
                  }

                  return '';
              }
            }
          )}
        </ScrollContainer>
      </InnerContainer>
    </Container>
  );
};

BurgerMenu.propTypes = {
  show: PropTypes.bool.isRequired,
  setBurgerMenu: PropTypes.func.isRequired,
};

export default BurgerMenu;
