import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';

const config = {
  apiKey: 'AIzaSyBIfKaCiANpS11MX08K56toilWbB-inmvk',
  authDomain: 'geeknabe-79dd4.firebaseapp.com',
  databaseURL: 'https://geeknabe-79dd4.firebaseio.com',
  projectId: 'geeknabe-79dd4',
  storageBucket: 'geeknabe-79dd4.appspot.com',
  messagingSenderId: '905946719210',
  appId: '1:905946719210:web:2f8386cde64136c3fc0893',
  measurementId: 'G-8ESWCNZY6C',
};

let instance = null;

const getFirebase = () => {
  if (typeof window !== 'undefined') {
    if (instance) {
      return instance;
    }

    instance = getDatabase(initializeApp(config));

    return instance;
  }

  return null;
};

export default getFirebase;
