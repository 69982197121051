import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const RemoteLink = makeShortcode("RemoteLink");
const Image = makeShortcode("Image");
const LocalLink = makeShortcode("LocalLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I have a great fondness for manga and webtoons that let the girls take charge. I personally think our voice can be pretty muted even in popular series that have the ladies take a central position in the story. In all honesty, I think many male writers couldn’t get their female characters right. Some have this idea that a strong female character means having the strength of a man. I do not think that is correct at all. Everyone is missing the strengths of a woman - `}<em parentName="p">{`feminine grace`}</em>{` and `}<em parentName="p">{`sensibilities`}</em>{`.`}</p>
    <p>{`Some male writers `}<strong parentName="p">{`do`}</strong>{` get it right, but it’s tough to beat the fairer sex in their own game. Stories that has a female lead `}<strong parentName="p">{`and`}</strong>{` a feminist agenda are already few in total, so I wanted to write a blog post to introduce everyone to the wonderful world of stories about ladies-only agendas. That’s not to say that the characters are all female, but the ideas behind the writing lets the ladies shine for once.`}</p>
    <Ads mdxType="Ads" />
    <p>{`There may or may not be romance but I can guarantee that each webtoon and manga titles that I’m about to recommend are pretty wholesome and unique. You can say that some of these recommendations are shoujo or josei titles, but their feminist ideas just points otherwise. I didn’t want folks to think that these are all about romance, thus the female lead/feminist title for this blog post! The list is not ordered, enjoy.`}</p>
    <h2>{`Female Lead/Feminist Manga Recommendation #1: Arte`}</h2>
    <p>{`I haven’t watched the anime yet but I’m really happy this series got an anime! `}<strong parentName="p">{`Arte`}</strong>{` was super underrated and under the radar as a manga alone, it’s finally getting the spotlight it deserves. Thank you for this wonderful series Ohkubo-sensei! This is her `}<RemoteLink to="http://narumikei.jugem.jp/" mdxType="RemoteLink">{`website`}</RemoteLink>{` and her `}<RemoteLink to="https://twitter.com/ookubo_tanuki" mdxType="RemoteLink">{`twitter`}</RemoteLink>{`. Please give her a follow! She post some fantastic art for Arte occasionally.`}</p>
    <Image alt="Arte manga cover" caption="Talented you may be, but the efforts of your hard work will always shine brighter than your talent." name="arte-manga-cover.jpg" mdxType="Image" />
    <p>{`In early 16th-century Florence (Renaissance), there’s a girl named Arte Spalleti. She’s the only daughter of a noble household and she loves to paint, thanks to her doting father’s blessing to let her do so freely. Women learning the finer arts is a strange idea back then. You see, women aren’t supposed to paint in this era. It was a man’s job! Instead, ladies are expected to learn embroidery and to bear children for the family. Unfortunately, Arte’s happiness is cut short when her father passes away and thus, leaving the Spalletti family penniless. Arte’s mother forced her to find a spouse and to get married as soon as possible to save their family, but our girl insists that her path is to become a master painter instead.`}</p>
    <Ads mdxType="Ads" />
    <p>{`In Chapter 1, we see her asking every master painter to accept her as an apprentice. Of course, no one could accept a woman painter into their ranks! Not to mention that Arte is a noble. They see her as a troublemaker, or someone who wishes to join this field to fool around before giving up. Arte couldn’t find anyone until finally, a master painter finally agreed to her request. His name is Leo, and he is the only one in his shop. Arte will be his first apprentice since forever. He wasn’t going to accept her since he gave her a hard task to scare her off. But Arte managed to finish his task in time! He was moved by her diligence and perhaps, saw something in her that reminds him of his past. Joining Leo’s workshop marks the start of Arte’s career as a female master painter.`}</p>
    <p>{`Here’s a fair warning to ya. If you expect romance between Leo and Arte, you could throw that idea away. Romance was toyed for a while, but Arte chooses to focus on her career instead. This manga is all about Arte and her rising up to her dream through grit and diligence. I love the idea that hard work does trump talent and bias perception! If you expect twists in the plot, I can safely tell you this much - there’s none. It’s part of why I really love this series! It’s a slow hike to the top and the goal has always been in sight. Arte just needs to crawl towards her goal gradually. The life lessons and her realization on the way is nothing fancy, but it points to a mindset shift in her journey to reach the top.`}</p>
    <p>{`If you’re wondering how one becomes a master in a field, this is how they do it. Hard work, diligence and a mindset shift! You will eventually master your skills and learn even more, but to be a master,`}<em parentName="p">{` you yourself`}</em>{` need to `}<strong parentName="p">{`change`}</strong>{`. Plus, you need to be patient! The adversities you face along the way will mold you into a better person. That’s what Arte is all about! If you like reading how a woman had to prove herself to be twice as hardworking and better than the other sex to break the glass ceiling, this is for you. FYI, Arte is most definitely inspired by the `}<RemoteLink to="https://en.wikipedia.org/wiki/Artemisia_Gentileschi" mdxType="RemoteLink">{`Italian Baroque painter, Artemisia Lomi. `}</RemoteLink></p>
    <Ads mdxType="Ads" />
    <h2>{`Female Lead/Feminist Manga Recommendation #2: Otoyomegatari/A Bride’s Story`}</h2>
    <p>{`Written by my favourite female mangaka`}<RemoteLink to="https://myanimelist.net/people/2575/Kaoru_Mori" mdxType="RemoteLink">{` Kaoru Mori`}</RemoteLink>{`, `}<strong parentName="p">{`Otoyomegatari`}</strong>{` or `}<strong parentName="p">{`A Bride's Story`}</strong>{` is a delight to read with her wonderfully detailed art and respectful depiction of a culture I am not familiar with. The story takes place in Central Asia (where nomadic people live, and is closely tied to the Silk Road) follows the story of various young women as brides, along with their daily lives as a married woman.`}</p>
    <p>{`Our first bride story is Amir and Karluk. Amir is a 20 year old woman who travelled far to marry Karluk, a boy 8 years her junior. Amir is a trained hunter and is good in the ways of embroidery/cooking while Karluk… is learning hard to be a spouse worthy of Amir. Along the way, we get to see the community doing cooking, crafting and even hunting. While the story is called A Bride’s Story, the focus of this manga has always been about how these people lived. I learned so much about their culture just looking at these panels. I’ll let Mori-sensei’s art do the talking. Please give this a try, it’s worth a read!`}</p>
    <Image alt="Otoyomegatari manga panel 1" caption="There's a chapter where they explored how each family has their own embroidery pattern. These patterns are passed from one generation to another, so you see how each generation add their own twist to the patterns. Really fascinating stuff! I'm afraid the most generational thing my kid will get is my Gameboy Advance." name="otoyomegatari-manga-panel-2.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`Female Lead/Feminist Manga Recommendation #3: Aria`}</h2>
    <p>{`I first read `}<strong parentName="p">{`Aria`}</strong>{` a decade ago and this manga is still my favourite. To be fair, I wouldn’t say it had a feminist agenda. I included it here because all of the main characters are female and live their life to the best they can. Plus, this is a soothing read that I wager everyone looking for manga for female leads would enjoy. Aria is written by the same mangaka that did `}<strong parentName="p">{`Amanchu`}</strong>{`, and her name is`}<RemoteLink to="https://myanimelist.net/people/1937/Kozue_Amano" mdxType="RemoteLink">{` Kozue Amano`}</RemoteLink>{`!`}</p>
    <Image alt="Aria manga 2-page spread" caption="Alicia and Akari! Everyone has their name starting with A in this series. Look how gorgeous Kozue Amano did the colours??!" name="aria-manga-wallpaper.jpg" mdxType="Image" />
    <p>{`It is the early 24th century where space travel is possible and humanity has terraformed Mars into a planet filled with water. While Earth is still habitable, technology has made the blue planet too convenient to live. Folks who yearn for the blue sky and the inconvenience of the 21st century would travel to Mars (now named Aqua) to stay in Neo-Venezia, a city modelled after Venice. It is here that jobs such as a gondolier can thrive. In the beginning of the story, we see a young lady named Akari flying in from Manhome (Earth) in order to work as an undine, a tour guide cum gondolier. She first started as a trainee and worked her way up, all the while befriending other trainee gondoliers with their seniors - Alicia, Aika, Alice, Athena and Akira. It’s a wonderful manga where we see Akari going through her daily life on this ambient water planet.`}</p>
    <p>{`I really couldn’t stress enough that this is a great slice-of-life manga about the ladies doing their own thing. The background art is lovely and when in colour, it looks a hundred times lovelier. I love this series enough that I bought all four artbooks! Please read Aqua first, then continue the story in Aria. Apparently there was a hiccup with the original magazine it was serialized on, so Amano-sensei had to move to a new magazine. Thus, the change in the series’ name. Many of my blogger friends love the anime too, so if you prefer the anime format, try that too. `}<RemoteLink to="https://twitter.com/ARIA_SENDEN/status/1285771550520573958?s=20" mdxType="RemoteLink">{`There’s a new movie coming up soon`}</RemoteLink>{` <3 Yessss!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Female Lead/Feminist Manga Recommendation #4: Sing, Erinna!`}</h2>
    <p>{`I had to look super hard for the information on `}<strong parentName="p">{`Sing, Erinna!`}</strong>{` because it is pretty under-the-radar and literally no one is talking about this series at all >:(`}</p>
    <Image alt="Sing, Erinna! art" caption="Just two aspiring poetess chatting away about their daily lives~" name="sing-erinna-art.jpg" mdxType="Image" />
    <p>{`It was Ancient Greece, when myths are often sung and spoke of by poets in this era. The pursuit of art is considered one of the most respectable callings in this era, and it was this calling that drives our heroine in this story, Erinna. Erinna wishes to be a student of a `}<RemoteLink to="https://en.wikipedia.org/wiki/Sappho" mdxType="RemoteLink">{`great poetess, Sappho`}</RemoteLink>{`. You see, in this era, women had a low status and as such, little was expected of them. It was Sappho who changed this perception and pushed for a female-only choir. She built a female-only school for aspiring poetesses and encouraged ladies from all over to join her school.`}</p>
    <p>{`This is a 4-koma comic where each chapter only had 1 page of 4 panels. But it doesn’t stop it from being the most wholesome read I’ve had. Erinna is a sweet girl who had nothing but admiration for Sappho. She befriended Baucis, a pretty girl her age with a sarcastic tongue. Both girls’ personalities are like water and fire, but both wanted the best for their future. Seeing how Erinna is so earnest in her training to be a poetess, Baucis too wanted to do her best. It’s a great manga that talks about the daily lives of the womenfolks in Ancient Greece as well as what is expected of them, with a dash of choir singing. It’s super wholesome! I like it better than `}<strong parentName="p">{`Flowers crossing the Aegean Sea`}</strong>{`. Please give this a try!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Female Lead/Feminist Webtoon Recommendation #5: A Stepmother’s Märchen (어떤 계모님의 메르헨)`}</h2>
    <p>{`Picking a shoujo webtoon to read is like throwing darts at a target with predetermined cliches. Lately, the villainess section is much, much bigger than the other targets so everyone’s darts keep landing on this section. Now if you look closer at the target, there’s a smaller section that says “mothers” instead right next to the “time reversal” cliche. I know it’s a funny analogy but you agree with me, don’t ya? >`}{`_`}{`<`}</p>
    <Image alt="Stepmother’s Märchen cover" caption="This entire webtoon have amazing art. Give it a try just for the art?" name="a-stepmothers-marchen-cover.jpg" mdxType="Image" />
    <p>{`In `}<strong parentName="p">{`Stepmother’s Märchen`}</strong>{`, we follow Marchioness Shuli von Neuschwanstein as the stepmother of four children. The Neuschwanstein family is of the most noble descent, with ties close to the royal family. Shuli married into the family when she’s barely of age and her husband passed away before she could really form a bond with her new family. Since none of the children are of age, young Shuli had to step up to take the position as the new head of the family until the eldest son is of age. Shuli’s age is only a few years older than the eldest son, so it was really tough to lead and gain respect from the other noble families. She had to try many underhanded techniques to do what she had to do. It was a tough job for a young girl but she persevered to the end. Finally, she could finally call quits as the head of Neuschwanstein on the day of her eldest son’s marriage.`}</p>
    <p>{`However to her dismay, her son didn’t want her to attend the marriage. While Shuli was busy trying to fit in and fix the power dynamics between the family and the other nobles, she didn’t try to amend things with the four children. As such, the children of the Neuschwanstein family never regarded her as their real mother. It was heartbreaking but Shuli didn’t bother looking back. Now that she didn’t need to assume any responsibilities as the head, she packed up and left the house. But it looks like she created too many enemies during the seven years she was the head of her family… She was murdered and voila, time travel plot kicks in! The next time Shuli opened her eyes, it was the day of her husband’s wake seven years ago.`}</p>
    <Ads mdxType="Ads" />
    <p>{`With her knowledge of the upcoming events and her maturity as someone older, Shuli strived to fix her relationship with the kids in this new timeline.This is a great webtoon that showcased the wonderful dresses from the Victorian era. The dresses are really large and the sleeves are puffy. The men’s clothing too, is a delight to look at. The art and colour palette is wonderful. If you are reading this solely for the art, then you have nothing to worry about. The plot too, is really heartwarming. Shuli knows what’s going to happen since she has done this before, but her approach is a more mature stance. Plus, she paid attention to the kids this time. The children now acknowledge her as their mother (and love her too). The story is pretty serious, mind you. Read the webtoon!`}</p>
    <h2>{`Female Lead/Feminist Webtoon Recommendation #6: Queen Cesia wears knickerbockers (여왕 쎄시아의 반바지)`}</h2>
    <p>{`Despite the many webtoons set in Medieval Europe, none of these webtoons actually tried to tackle the issue of clothings in this era. Y’all draw nice art, but never go into details what makes these clothes tick. How crazy is that! This webtoon, `}<strong parentName="p">{`Queen Cesia wears knickerbockers`}</strong>{` is such a great webtoon about clothes. The issues they tackle are all about women empowerment too, so it fits into the theme of this blog post.`}</p>
    <Image alt="Queen Cesia wears knickerbockers webtoon cover" caption="This webtoon got me hunting the raws for new chapters. It's so good! Very mature take on the problems of women in medieval society." name="queen-cesia-wears-knickerbockers-cover.jpg" mdxType="Image" />
    <p>{`Our MC is Yuri, a clothing designer who slaved in a sweatshop to make ends meet back in Korea. She died from a car accident and was transmigrated to a medieval european-like world. In this world, men and women wear ugly pieces tied together with knots or are sewn together. Yuri, hailing from a modern world, disliked the idea of uncomfortable clothing and her family’s poverty. She’s a professional, for goodness sake! Desperate to turn her life around, she set off to a city where her skills are appreciated better. It was there that she struck a deal with the biggest clothing store but the store owner gave her one condition - she must dress up as a man. This condition wasn’t to set her up to fail but instead, to make sure no one dispute with her sex and to see her for what she is capable of.`}</p>
    <p>{`With her sales-talking skill and her designs, she managed to become a successful designer. She’s successful enough to have caught the attention of the Queen of Valencia, who’s progressive mind abhor the uncomfortable corset. Will Yuri’s modern design change this world’s outdated clothing dilemma? But first, she needs to make sure no one finds out she’s female!`}</p>
    <p>{`There’s a ton of fashion terms being thrown around. Plus, this webtoon dives into explaining what makes these medieval european clothes so unique. While clothes take center stage, the problems of being a lady in this era gets the highlight too. As a modern woman, Yuri doesn’t look well upon uncomfortable clothes that restricts movement. Luckily, Queen Cesia is a lady with a progressive mindset. She knew that the castlefolk needs to change but how? That’s how Yuri comes in.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Yuri worked hard to eventually change the society’s perception of women’s clothing and their status. Here is the list of achievements that Yuri did to better the women’s status in society according to the `}<RemoteLink to="https://forum.novelupdates.com/threads/queen-cecias-shorts.106845/" mdxType="RemoteLink">{`spoiler thread here`}</RemoteLink>{`. Tap or highlight the spoilers below to read it.`}</p>
    <p>{`||She invented tampons, condom, corset-free dresses, a school for women and rubber corsets for children.||`}</p>
    <h2>{`Female Lead/Feminist Manga Recommendation #7: Joou no Hana (女王の花)`}</h2>
    <p>{`I’ve been trying to push `}<strong parentName="p">{`Joou no Hana`}</strong>{` off the underrated stage and into popular-dom, but alas… No one is interested. Okay, maybe the tagline needs to be different? I’d liken this shoujo manga to Akatsuki no Yona, except that she only had 2 allies to help work her way up the royal castle politics. It’s more intense than Akatsuki no Yona and the love between the main couple is super `}<strong parentName="p">{`bittersweet`}</strong>{`.`}</p>
    <Image alt="Joou no Hana manga cover" caption="I wouldn't say the art is super good or whatnot, but the plot just... got me, ya know? The bittersweet love between a princess and her attendant is just so good." name="joou-no-hana-manga-cover.jpg" mdxType="Image" />
    <p>{`In the world of Joou no Hana, there are four countries - Sou, Kou, Do and Ah. The King of Ah took in a princess from Kou and Do, thus creating an alliance between the three countries. When the Second Queen gave birth to a son, King of Ah threw aside the sickly First Queen and her daughter, Princess Aki. Thus, the princess was left to her own device. She wanted the attention of her father, so she tried to be excelling in the arts of war. One day, the princess met a slave named Hakusei with golden hair and blue eyes. She saved him and soon, formed a close relationship with Hakusei as her most trusted aide. Hakusei too, would do anything to protect the princess who saved him.`}</p>
    <p>{`Years later, Aki is now a young lady who is proficient enough in the arts of war than the prince. She showed up her brother in a hunt in order to gain her father’s acknowledgement. Unfortunately, it was seen as her trying to shame the Do’s honour instead. Her mother was killed with poison and Aki was banished to Kou. Angered by this treatment, Aki vowed to return to Ah with her head held high. But how is she supposed to do that when pincered by these mighty kingdoms who wish to use her instead?`}</p>
    <Ads mdxType="Ads" />
    <p>{`This shoujo has a ton of betrayal going on to the point that I wish it ain’t so. The art is really simple and clean. I find it really easy to read! This shoujo manga focuses more on the politics between factions that wish to one-up the other. Super intense! Plus, Hakusei x Aki is basically romance that will not have a happy ending where they get married. Hakusei is a slave while Aki is a princess. If this sounds super bittersweet to you, please try the manga! It needs more readers.`}</p>
    <h2>{`Female Lead/Feminist Manga Recommendation #8: Matsurika Kanriden: Koukyuu Jokan, Kimagure Koutei ni Misomerare (茉莉花官吏伝 〜後宮女官、気まぐれ皇帝に見初められ〜)`}</h2>
    <p>{`Man, this shoujo manga’s title is long. I usually just call it `}<strong parentName="p">{`Matsurika Kanriden`}</strong>{` in my head. This shoujo manga is based on the light novel with the same title! A wonderful plot that talks about how Matsurika, the first female bureaucrat rose up from a lowly palace maid to an official within the inner palace of the Hakurou country.`}</p>
    <Image alt="Matsurika Kanriden cover" caption="I couldn't find a good cover to showcase Matsurika Kanriden's wonderful art. I really enjoyed seeing how they drew their clothing." name="matsurika-kanriden-manga-cover.jpg" mdxType="Image" />
    <p>{`Matsurika, as the title implies, is our heroine in this story. She is happy just doing the duties of a palace maid. After all, nothing good will came out of being in the spotlight for someone like Matsurika. You see, Matsurika had a gift. She can recite any books that she’s seen once. She could even recall a memory from years ago with perfect clarity! She basically has photographic memory, really cool stuff. Unfortunately, that’s not what her peers thought of her. They didn’t like the fact that Matsurika can do everything they do without even practicing it! That’s not exactly fair is it? The incident of her peers bashing her traumatized poor Matsurika. Since then, she tries to downplay her ability and simply lived her life as a normal palace maid.`}</p>
    <p>{`Of course, things changed when she was asked to attend a marriage meeting practice by her superior. I find this absolutely strange because do marriage meetings really need practices? Anyway, Matsurika went and found out that her partner… is the emperor himself?! Things happened and her spectacular photographic memory ability was found out by the emperor himself! Now, how will Matsurika wiggle herself out of this?`}</p>
    <Ads mdxType="Ads" />
    <p>{`I wasn’t about to recommend this shoujo title here, but the growth of our heroine is very motivating. Firstly, Matsurika had the ability to be the best version of herself. She might seem meek now, but initially she was really happy and proud of her memory. She became meek only after the traumatizing incident happened. She chose to stay quiet about her ability at first, but it was the emperor who pushed her to use that ability for the country. He did it by first encouraging her to see the good in her ability. He also supported her by giving financial help so she could attend the Imperial Academy. Even when she was harassed by the students, he sent help so she could improve herself. Not only did the male lead encourage her from the background, everyone made her see her real worth. Slowly, she became super confident about her photographic memory and soon, became more than happy to help the emperor with her memory.`}</p>
    <p>{`The emperor actually never treated her differently just because she’s a woman. Rather, he treated her as a sponsor because he truly believed in her and her ability. This is such a wholesome shoujo manga.  I rarely see such a supporting main cast to the heroine. I’m just really sad there are only a few chapters translated currently…`}</p>
    <h2>{`Female Lead/Feminist Manhwa Recommendation #9: Song of the Long March/Chang Ge Xing`}</h2>
    <p>{`This is the only title which is a manhwa from mainland China. Hey, that’s not what the blog post title says! You’re gonna have to forgive me for misleading you but this manhwa is super, super good. The art is amazing and the dialogue just tickles my fancy. It’s on hiatus due to some horrible hiccup the author has with the publishing company, but there’s still a sizable chunk of chapters you can read!`}</p>
    <p><em parentName="p">{`EDIT: I did a search and apparently it got a `}<RemoteLink to="https://www.youtube.com/watch?v=_S7QzbMiTro&ab_channel=C-DramaLovers" mdxType="RemoteLink">{`c-drama`}</RemoteLink>{`! I’m not too sure I like the way they changed the story…`}</em></p>
    <Image alt="Chang ge Xing manga panel" caption="This is taken from Chapter 1. Very gorgeous Han robe here. I think the author nailed the hairstyle!" name="chang-ge-xing-song-of-the-long-march-manga-panel.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <p>{`Our heroine is Li Changge, imperial princess of the Tang Dynasty. Though a princess, her wits and strength precedes her title. She’s mastered the art of war, is observant and quick-witted in her decisions. Her mastery over the sword and bow is uncanny for a 14 year old girl. At the beginning of the story, she is forced to leave her home, Chang’an after her uncle, Li Shimin murdered her family to take the throne. She vowed to one day return and take revenge for her family’s death. Changge decided that the best way to do this, is to dress as a man to disguise herself and start a revolution. Her first objective is to `}<em parentName="p">{`take over`}</em>{` Shuo Province by buttering up the lord with undeniable war achievements. Unfortunately, she loses a siege by Ashina Sun, a general under Eastern Turkic Khaganate and was taken as a war prisoner. Along the way of making new allies and experiencing new things, Changge soon changed her goal of revenge to one that wishes for the prosperity of the Tang Dynasty.`}</p>
    <p>{`Changge starts off as a genius girl with a cynical and pragmatic worldview. Now here’s the thing when you’re young and have never suffered a loss, you lack a `}<em parentName="p">{`worldly perspective`}</em>{`. Changge had everything under the sun as the imperial princess. The heavens have blessed her with great intellect but alas, she has never looked far beyond her actions. When she loses a loved one, she is forced to look deep within. A stroke of luck brought her to be under the protection of a Taoist master in a female-only temple, which she then started to ponder - `}<RemoteLink to="https://en.wikipedia.org/wiki/Tao" mdxType="RemoteLink">{`What is her Path? (Dao)`}</RemoteLink></p>
    <p>{`Her growth as an individual is, to say the least, amazing to watch. She went from a little girl with a narrow view on things to one that considers the consequences as well. Her heart is finally on the right track once she understands her own Dao. The art has a beautiful touch of classical chinese art style. And we get to see a wonderful cultural representation of Turkic Khaganate! Please give this a try.`}</p>
    <h2>{`Female Lead/Feminist Manga Recommendation #10: Holy Grail of Eris (エリスの聖杯)`}</h2>
    <p>{`This is yet another underrated title that no one is talking about. Despite the shounen-sounding title, it’s a shoujo title! It’s pretty dark so mind your step.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Holy Grail of Eris light novel cover" caption="Both girls, Constance and Scarlet are great characters. Their outlook in life and character is super different from one another, but they mesh really well in the story." name="holy-grail-of-eris-cover.jpg" mdxType="Image" />
    <p>{`16 year old Constance Grail is from a family of Viscount famed for their honesty and integrity. While these are values everyone looks up to, it… does not play well with a noble family. As such, Viscount Grail has always been a target for deception. That’s not to say the Grail family are dumb-dumb! They just can’t ignore anyone who needs help. Unfortunately, their honesty kept landing them in hot waters and finally, the Grail House is deep in debt. Thus, the Viscount is forced to marry off her daughter to a rich merchant. Constance didn’t really mind the whole forced marriage. Besides, she thought her future husband Neil Bronson seems like a decent man. At least, until she saw him cheating on her with another lady. Constance actually knew about him cheating on her since forever, but she couldn’t voice out for fear that the annulment would cause more damage to her family.`}</p>
    <p>{`It was during the party that she was finally forced to confront it all. Pamela, the lady whom Neil is cheating with, accused Constance of stealing a hairpiece. No one wanted to come up to defend the poor girl despite the obvious slander. Right when Constance finds herself in a pinch, she is possessed by a beautiful ghost lady who took control of the situation immediately in a charismatic fashion. The next day, the ghost appeared again in front of Constance declaring that she is Scarlet Castiel, the woman who was executed 10 years ago for poisoning Princess Cecilia. Yet here she is, demanding that Constance work with her to figure out the true culprit behind the poisoning in exchange for her help during the party. 10 years later, and yet the culprit’s trail has not gone cold. Just who is the person responsible for framing Scarlet?`}</p>
    <Ads mdxType="Ads" />
    <p>{`I recommended it here because Constance is a model heroine who stood on her own despite the odds. She’s honest to a fault but is that truly the wrong way to live? Constance prides herself as a member of the Grail family and would often find herself in trouble because she’s honest. Folks bully her and she wouldn’t stand up to it. With Scarlet by her side, she learns to take a stand for herself. Her growth took even Scarlet by surprise! Good for you Constance. The storytelling is lovely and Constance is such a delight to read about. The Constance x Scarlet team up is really fun to read. Give this a whirl!`}</p>
    <h2>{`All in all`}</h2>
    <p>{`All 10 manga/webtoon recommendation, done <3 I hope everyone gives these titles a chance because each one is super underrated. I honestly think they deserve an anime at least. If you enjoyed this post, please consider reading my `}<LocalLink to="/blog/15-otome-isekai-manga-and-webtoons-recommendations-you-will-enjoy/" mdxType="LocalLink">{`otome isekai blog post series`}</LocalLink>{` as well! Please scroll to the bottom to see all of the related blog posts.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      