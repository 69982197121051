import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const RemoteLink = makeShortcode("RemoteLink");
const Ads = makeShortcode("Ads");
const Video = makeShortcode("Video");
const Blockquote = makeShortcode("Blockquote");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`We live in an age of technology. The entertainment dancing on our screen as moving art that we so love and enjoy? That's the fruits of technological advancement. The fact that we can now watch pirated anime with just a few clicks is proof we are now living in an age of technological convenience.`}</p>
    <p>{`Now I'm not sure if you have been following the news, but the tech we've been inventing has been crazy in the last two decades. `}<RemoteLink to="https://www.tesla.com/" mdxType="RemoteLink">{`Self-driving cars`}</RemoteLink>{`? Check. `}<RemoteLink to="https://impossiblefoods.com/" mdxType="RemoteLink">{`Plant-based meat`}</RemoteLink>{`? Check. It's just crazy to think that exactly 20 years ago (1999), we're still using flip phones. Mind you, those screen have no colours, nor do they have touchscreens.`}</p>
    <Ads mdxType="Ads" />
    <p>{`These tech are just mindblowing, and they will surely be commemorated in textbooks for the coming future. So... what about our beloved animation? Our pop culture like `}<RemoteLink to="https://en.wikipedia.org/wiki/Kabedon" mdxType="RemoteLink">{`kabedon `}</RemoteLink>{`or `}<RemoteLink to="https://en.wikipedia.org/wiki/Moe_(slang)" mdxType="RemoteLink">{`moe`}</RemoteLink>{`? Or the fact that `}<RemoteLink to="https://www.statista.com/statistics/583079/best-selling-manga/" mdxType="RemoteLink">{`One Piece is one of the top selling manga of all time`}</RemoteLink>{`? (`}<em parentName="p">{`Please don't hurt me KNY fans)`}</em>{` All of these will be lost to the passage of time!`}</p>
    <p>{`While we certainly can't keep the exact copies of our favourite Japanese pop culture (Perhaps someone will do it), we can at least store the proof of our love for them through this program by `}<RemoteLink to="https://github.com/" mdxType="RemoteLink">{`Github `}</RemoteLink>{`- `}<strong parentName="p">{`Github Archive Program.`}</strong></p>
    <Video src="https://youtube.com/embed/fzI9FNjXQ0o" title="Github Archive Program" mdxType="Video" />
    <Ads mdxType="Ads" />
    <h2>{`What is Github Archive Program?`}</h2>
    <p>{`Before I explain, you need to understand what is `}<strong parentName="p">{`Github`}</strong>{`. In simpler terms, Github is a platform where pro software developers or amateurs store their code. It's where they discuss their ideas, improve their code and make it available to the public. Since it's publicly available, anyone can modify it and use it in their own product. This movement is what we call `}<RemoteLink to="https://en.wikipedia.org/wiki/Open_source" mdxType="RemoteLink">{`open source`}</RemoteLink>{`.What Github is trying to do here is to preserve all of that open source code stored on their platform for the future generation in an Arctic town called Svalbard. It's also where the `}<RemoteLink to="https://en.visitsvalbard.com/inspiration/various/svalbard-global-seed-vault" mdxType="RemoteLink">{`Global Seed Vault`}</RemoteLink>{` is! Moreover, we are not talking about saving them on a hard disk for 100 years...`}</p>
    <Blockquote mdxType="Blockquote">
  Github is looking to preserve all of their data for at least 1000 years.
    </Blockquote>
    <h2>{`Wait, hardware does not last that long!`}</h2>
    <p><strong parentName="p">{`Correct`}</strong>{`. ... Were you expecting me to say something else?Anyway, there are no definitive answers to how long the consumer data storage products can last, but the general idea is that hard disk, flash storage, etc`}<RemoteLink to="https://www.techwalla.com/articles/life-expectancy-of-a-usb-flash-drive" mdxType="RemoteLink">{` can last up to `}<strong parentName="p">{`10 years approximately`}</strong></RemoteLink><strong parentName="p">{`, `}</strong>{`depending on how often you use them and how you store them. `}<em parentName="p">{`So how is Github going to preserve the data for a millennium?`}</em>{` By storing the data on film made of silver halides on polyester. These are designed to last 500 years but Github is using a film by `}<RemoteLink to="https://www.piql.com/arctic-world-archive/" mdxType="RemoteLink">{`Piql,`}</RemoteLink>{` a Norwegian company that specializes in storing data on film. Their films will last 1000 years!To add to the legitimacy of this archiving program, Microsoft is also in it as part of their Project Silica effort. `}<RemoteLink to="https://news.microsoft.com/innovation-stories/ignite-project-silica-superman/" mdxType="RemoteLink">{`Just recently, they announced that they managed to store and retrieve a full movie of 1978's Superman on a piece of glass`}</RemoteLink>{`. Also, Github is a `}<RemoteLink to="https://en.wikipedia.org/wiki/GitHub#Acquisition_by_Microsoft" mdxType="RemoteLink">{`subsidiary of Microsoft`}</RemoteLink>{` :)`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Ok, so what's it gotta do with anime and manga?`}</h2>
    <p>{`Did you not realize that `}<RemoteLink to="https://github.com/inorichi/tachiyomi" mdxType="RemoteLink">{`Tachiyomi `}</RemoteLink>{`is open source? `}<em parentName="p">{`Yare yare daze.`}</em></p>
    <Image alt="Our favourite manga reader is open source, and a potential project to be stored for the future weebs." caption="Our favourite manga reader is open source, and a potential project to be stored for the future weebs." name="tachiyomi_open.jpg" mdxType="Image" />
    <p>{`There's `}<strong parentName="p">{`A TON `}</strong>{`of open source projects out there that's elligible to get into the vault. Here are some really cool projects!## style2paints`}</p>
    <RemoteLink to="https://github.com/lllyasviel/style2paints" mdxType="RemoteLink">
  style2paints{' '}
    </RemoteLink>
is an AI driven lineart colorization tool. It helps you kickstart your colouring
workflow!
    <Video src="https://youtube.com/embed/B6WEky9nY7Q" title="" mdxType="Video" />
    <Ads mdxType="Ads" />
    <h2>{`Kemono Adblocker`}</h2>
    <p>{`Kemono Friends as your `}<RemoteLink to="https://github.com/akira02/Kemono_Adblocker" mdxType="RemoteLink">{`adblocker`}</RemoteLink>{`!`}</p>
    <Image alt="Blocking the ads the moe way" caption="Blocking the ads the moe way" name="kemono-friends.jpg" mdxType="Image" />
    <h2>{`Amadeus`}</h2>
    <p>{`An Android app that `}<a target="_blank" rel="noopener" href="https://github.com/Yink/Amadeus">{`replicates the Amadeus App shown in Steins;Gate 0`}</a>{`.`}</p>
    <Image alt="Call screen for Amadeus app." caption="Call screen for Amadeus app." name="kurisu-callscreen.png" mdxType="Image" />
    <p>{`There's a ton of other projects out there, `}<RemoteLink to="https://github.com/soruly/awesome-acg" mdxType="RemoteLink">{`check out some of them here`}</RemoteLink>{`!`}</p>
    <h2>{`So how do I get my project archived?`}</h2>
    <p>{`Upload your code to Github and make your code repository public sometime before 2nd February 2020. You can make commits within months of February 2020 just to keep your repository active enough to get Github-senpai to notice you. Github will take a snapshot of all public code repository by Feb and voila! Off they go into the arctic vault.For more info on Github Archive Program, check out their`}<RemoteLink to="https://archiveprogram.github.com/" mdxType="RemoteLink">{` official site.`}</RemoteLink></p>
    <Ads mdxType="Ads" />
    <p><em parentName="p">{`Note: All images pertaining to Github Archiving Program are screencaps from the official `}<RemoteLink to="https://www.youtube.com/watch?time_continue=1&v=fzI9FNjXQ0o&feature=emb_title" mdxType="RemoteLink">{`Youtube `}</RemoteLink>{`video they released. The rest of the images are from respective Github repo.`}</em></p>
    <p><strong parentName="p">{`PS: I'm personally really excited to see what the tech industry will come up within the coming decade!`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      