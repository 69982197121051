import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`New anime season is here and with it, a bunch of new `}<em parentName="p">{`animated-from-manga`}</em>{` that I really enjoyed some time ago. `}<strong parentName="p">{`Maoujou de Oyasumi (Sleepy Princess in the Demon Castle)`}</strong>{` is one of those manga! I remember when I first read it ages ago, I binged the whole series in one go. It was that good. I was looking hard for a manga that I could feel happy reading, and Maoujou de Oyasumi really tickled my funny bone! While the anime positioned itself as a `}<em parentName="p">{`fluffy`}</em>{` series, this is first and foremost a `}<strong parentName="p">{`comedy`}</strong>{` series. I think the anime would probably keep the whole fluffy vibe going because that seems like what most folks prefer lately. But tis’ without a doubt, a comedy! `}{`*`}{`Okay, maybe there’s fluff but I’m leaning more on the comedy.`}</p>
    <p>{`*`}<em parentName="p">{`Note: I try to go light on the spoilers! When I wrote this, Episode 1 was just aired.`}</em></p>
    <p>{`The opening of the anime did a great overview of the whole Princess Suyaris getting kidnapped by the Demon King so there’s really nothing to talk about there. What’s important is the whole motive of this series - it’s all about Princess Suyaris wanting to get a good sleep, and she’ll stop at nothing to get it!`}</p>
    <Ads mdxType="Ads" />
    <p>{`Will she ask nicely for a nice bed sheet? Nope. Will she venture outskirts for more “good sleep” material? Maybe. But will the demons stop her? `}<strong parentName="p">{`They can’t. `}</strong>{`Honestly, the demons really want to stop her but she just can’t be stopped.`}</p>
    <p>{`And that’s it. That’s the whole premise. The demons wanted her to stay still in her room as a hostage, but `}<strong parentName="p">{`no one stood a chance against her`}</strong>{`. She would run around collecting materials or doing something silly for the sake of getting sleepy. You’d think that the demons could overpower a single girl but the whole premise of this series is that `}<strong parentName="p">{`Princess Suyaris can’t be stopped.`}</strong></p>
    <p>{`Eventually both the Princess and the demons get all buddy-buddy! In case you wanna know which ones are the demons she gets all friendly with, it’s the demons that are shown in the opening. Comedy in this series relies heavily on the Princess being an airhead who just wants some sleep and from that point onwards, a ton of `}<strong parentName="p">{`hilarious misunderstanding `}</strong>{`ensued`}<strong parentName="p">{`.`}</strong>{` The chapter always ends with the Princess getting a good sleep and a nice, fluffy feeling floating around the demons… All’s well that ends well <3So why should you watch Maoujou de Oyasumi?`}</p>
    <Ads mdxType="Ads" />
    <h2>{`There are more cute demons than the teddy bear one`}</h2>
    <p>{`If you love the teddy bear demons that appeared in Episode 1, I have good news for you. Not only does these teddy bears AKA `}<em parentName="p">{`“her loyal slave after some brushing”`}</em>{` appear even more in later chapters, there’s a type of adorable eggplant demon that will appear later on! I added an image of said eggplant below.`}</p>
    <Image alt="Princess Suyaris hugging the cutest plushie!" caption="Suyaris slept with a smile on her face because she’s hugging eggplant demons. Yup, those are eggplants. Notice the little grassy tuff on their heads? <3 Maybe the anime subbers will name them differently..." name="maoujou-de-oyasumi-manga-panel-1.jpg" mdxType="Image" />
    <p>{`I’m aware of the different kinds of `}<strong parentName="p">{`cute`}</strong>{` that the anime fandom prefers. My personal `}<em parentName="p">{`favourite cuties`}</em>{` are all animals, but you can’t deny the eggplant is cute! Since it’s only fair to include all sorts of cute demons, I decided to attach an image of the `}<em parentName="p">{`other type of cute`}</em>{`.`}</p>
    <Image alt="Silly Harpie, I'm sure the Princess considered you a friend." caption="Her name is Harpie. She’s super cute in her mannerism and silly misunderstandings of the princess’s thoughts." name="maoujou-de-oyasumi-manga-panel-2.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`Look out for this silly guy, he’s precious`}</h2>
    <p>{`Ditz number 1 is most definitely the Princess. But who’s number 2? Well, I’m pretty sure this guy will appear later on since he’s really important to the story, so not exactly a spoiler? I won’t say who or what’s his name but I’ll attach an image. I love this guy, he’s super silly! I think he’s better off devoting his time and attention to someone else but eh, this is fine too.`}</p>
    <Image alt="Nothing shameful can be found in my blog! Except maybe the fanfic section." caption="I noticed that I screencapped the word “cute little girls” too. Do not be afraid because there is nothing shameful going on in this panel." name="maoujou-de-oyasumi-manga-panel-3.jpg" mdxType="Image" />
    <h2>{`Princess Suyaris got so powerful, no one can stop her`}</h2>
    <p>{`Not even the Demon King. In Episode 1, you see her exchanging her crown for some scissors. It just so happened that she got herself a really big one. The big scissors will be her main melee weapon to bully the poor ghost sheet demons. She later got herself a powerful weapon that can potentially one-shot any demon to oblivion. Is it super dangerous? Yes, but in a different way than you expected! She would use the weapon to do silly things in order to get herself a good sleep instead.`}</p>
    <Image alt="If she's so powerful, why not just cast the Sleep spell on herself everytime she wants a good sleep?" caption="Thinking hard isn’t her forte, so she just blasted everything and went to sleep. Yup, she just nuked an area because she wants to sleep. Totally not thinking of world domination or wiping out the whole demon race!" name="maoujou-de-oyasumi-manga-panel-4.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`The Hero is irrelevant`}</h2>
    <p>{`In this series, it’s all about the Princess and the demons. The Hero gets shown once in a while but has made super slow progress towards the Demon King’s castle. Is he there yet in the manga? Nope, still not there.`}</p>
    <Image alt="The Hero in Maoujou de Oyasumi." caption="You thought the Demon King want to slow the Hero down? Nope. This guy is just slow." name="maoujou-de-oyasumi-manga-panel-6.jpg" mdxType="Image" />
    <h2>{`Each chapter just keeps getting better`}</h2>
    <p>{`I'm sure everyone thinks that the running joke of the series will get boring eventually. Well, Suyaris trying to get some sleep is the core premise of this series, but the author slowly sliced in some background info on the demons and answered a ton of questions along the way. What exactly is the Demon King's reason for kidnapping Suyaris? Is there something special about Suyaris, especially from how indifferent she is to the demons? I mean, shouldn’t she be wailing about wanting to go home instead of murdering ghost sheet demons for new bed sheets everyday? What about the demons from the main cast? Do they have some embarrassing secrets that we readers are dying to know!? And what does the human kingdom think of the demonkind?`}</p>
    <Ads mdxType="Ads" />
    <p>{`There’s lots of things to unwrap here but everything ends well in the end. I find the resolution of many chapters really wholesome for a comedy series. It turns out that the human kingdom’s royalty doesn’t hate the demons as much as everyone expected. I’ll leave this sentence hanging :) Read the manga to find out!`}</p>
    <h2>{`All in all`}</h2>
    <p>{`I hope these are reasons enough to watch because any more would be deep in spoiler territory. Just remember that each episode has the Princess go on a quest to get a good sleep which caused a chaotic but hilarious situation, and you’re good to go! Also, all demons are really nice guys. They work for the Demon King and get paid too! Such a fair working environment for doing such evils. I would love to work there too!`}</p>
    <p>{`I personally really love the manga for what it is, so the anime is `}<em parentName="p">{`a breath of fresh air`}</em>{`. The flow starting from the Demon King’s kidnapping, along with those quest-bubbles is `}<strong parentName="p">{`adorable.`}</strong>{` I don’t remember Maoujou de Oyasumi being this cute. However, certain scenes did get `}<strong parentName="p">{`moe-fied`}</strong>{` a lot such as the scene where the ghost sheet demon gets chopped to half. I laughed when I read the part about her just jumping straight to the point where she doesn’t need the upper part. In the anime, I just went aghast.`}</p>
    <Image alt="Suyaris will stop at nothing." caption="Did she just kill a demon?!" name="maoujou-de-oyasumi-manga-panel-5.jpg" mdxType="Image" />
    <p>{`Oh my god, that is terrifying! No mercy! My impressions on both manga and anime is a world apart! I guess reading manga and watching the anime is a totally different experience… Please watch Suyaris blasting, chopping and ignoring everyone’s plea because getting a good night sleep is her priority! It’s a great series that just keeps going, I’m sure folks will love it.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      