import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Blockquote = makeShortcode("Blockquote");
const RemoteLink = makeShortcode("RemoteLink");
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I have a Nintendo Switch and I love it to bits. I really wanted to get a new RPG game to play, so I jumped at the chance when I saw Ni no Kuni on sales in the eshop. So far, I clocked a solid 20 hours and I loved everything about this game. The story, the gameplay and the music - it screams Ghibli Studio in every sense. I was sure this JRPG is enough to keep me occupied during the quarantine but alas, it was not to be. It just happened that Animal Crossing: New Horizons was released while I was happily enjoying Ni no Kuni. I kid you not, almost everyone I know has a copy of this game, and that includes my friends who have never gamed before. It’s crazy! My Twitter is `}<em parentName="p">{`exploding`}</em>{` with #AnimalCrossing tweets. If it’s not about some clothing design, it’s about the villagers. Videos, screenshots, fanart… It’s `}<em parentName="p">{`bloody everywhere`}</em>{`. You see, when it is `}<em parentName="p">{`everywhere`}</em>{`, you start to get curious.`}</p>
    <Blockquote mdxType="Blockquote">
  Just why is everyone so crazy about Animal Crossing: New Horizons? Is it the
  7-year drought, or is there more I’m missing?
    </Blockquote>
    <p>{`I started off just scrolling past these tweets. But the amount of retweets and likes each `}<RemoteLink to="https://twitter.com/search?q=%23animalcrossing&src=typed_query" mdxType="RemoteLink">{`#AnimalCrossing`}</RemoteLink>{` post gets is absurd. I almost never see a tweet about Animal Crossing with low retweets and likes. So I start clicking on them. Most of the fan arts are very cute. It’s just fan art of the villagers doing cute things.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Animal Crossing: New Horizons flower field" caption="Just me with the beautiful flowers I just planted! I was on a mission to create flower fields all over the island. This is one of them!" name="animal-crossing-new-horizons-flower-field-on-island.jpg" mdxType="Image" />
    <p>{`The clothing design feature is probably where the players have the most fun! I saw many amazing designs of Fire Emblem Three Houses clothing, as well as popular anime characters. It’s really wondrous to see these designs at the hands of really creative people! It was all resistible until I saw the bane of my existence… You can design your own island?! And the furniture is ridiculously detailed! That’s it, `}<strong parentName="p">{`I need this game, pronto.`}</strong></p>
    <p>{`For the record, I knew about Animal Crossing thanks to a colleague who is super nuts about this game, but it never entered my view since it’s not a genre I love. When I say nuts, I mean blasting K.K. Slider songs nuts. Back then, I thought it was absurd to like VGM with silly gibberish as lyrics. That can’t be considered a song, can it? And to make things worst, he would occasionally tell me Animal Crossing tidbits (that I never asked) and I find myself nodding politely to go along with it. I thought it’s only fair since he withstood my Fire Emblem Three Houses fan-talk.`}</p>
    <Image alt="Animal Crossing: New Horizons rooms in house" caption="My room. Animal Crossing's balloons keep dropping me lovely furniture so I couldn't help myself. I fit them all into 1 small room! .... Then I managed to expand my house to add another room! I'm ded" name="animal-crossing-new-horizons-room-redecorating.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <p>{`Mm yes, fishing and turnip market… Oh, nice. Bullying an NPC to force them to leave? Mm-hm, yes yes very informative… Colleague: “So get the game when it releases!”`}<br />{`Me: “Nah.”How wrong I was.`}</p>
    <p>{`I managed to “borrow” a copy of the game since I really wasn’t sure if I’ll ever like it. I wanted to try New Horizons before committing to it because the full price is pretty steep. I started the game with absolutely zero expectation of what to expect, so I was pleasantly surprised with my initial experience. The UI is super intuitive, simple and detailed. The general colour of the UI is soft and… is that texture on the UI elements?! The sound effect as you move from one dialogue box to another is so adorable. Then I saw Timmy and Tommy greeting my hastily-put-together avatar at the airport. The other brother would follow up at the end of his sibling’s gibberish speech bubble albeit in a softer tone. Not only are they cute together, but their speech bubble quirkiness is also `}<em parentName="p">{`bloody adorable. `}</em>{`I was playing with earphones on so I heard small little details they put in into the sound design. In the airport, you can hear the bustle of the crowd and occasionally, the announcer announcing the arrivals/departures. It was really life-like! And you can tell they put care into the background music’s quality too. It was sharp and crystal clear, unlike some Nintendo Switch ports that have lower music quality.`}</p>
    <p>{`I continued tapping the A button and soon, they dropped me off to a deserted island. I haven’t the foggiest of what to do but thankfully, Tom Nook guided me all the way. Soon I was walking all over the island to catch bugs and fish up fishes. I picked up everything I could find, even weeds and sold them all. I shook all the trees on my island and picked up tree branches. My Nook phone would then ring to inform me of the Nook Miles I just earned. I quickly collected them all and repeated the whole routine again.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Generally speaking, it was `}<em parentName="p">{`only `}</em>{`2 activities that I can do when I first started; bug catching and fishing. But I find myself having `}<em parentName="p">{`so`}</em>{` much `}<strong parentName="p">{`fun`}</strong>{`. You see, the `}<em parentName="p">{`activities `}</em>{`are fun in the sense that I am discovering something new every time I cast my lure or fling down my net. It’s definitely a different kind of fun than the soothing, repetitive act of grinding for levels in an RPG. Once a bug or fish is caught, the game would zoom in on your fresh catch for you to appreciate your handiwork. I must say, for a game with cutesy design, I wasn’t expecting this level of detail!`}</p>
    <Image alt="Animal Crossing: New Horizons beautiful waves by the beach" caption="Sunsets are beautiful in this game. The waves are so inordinately rendered. Just look at it!" name="animal-crossing-new-horizons-sunset-colours.jpg" mdxType="Image" />
    <p>{`Since the game runs in real-time, I wasn’t rushed to play the game. I run around the island doing whatever I wanted. If I wanted to fish, I would run along the beaches to find shadows in the water. If I wanted to catch bugs, I would sneakily walk up to a bug and swing down my net. Looking back, it does feel amazing that nothing prompted me to do all this. All I was given by Tom Nook is some pointers and maybe some tools, then off I go. The first catch was exhilarating. The subsequent catches felt `}<em parentName="p">{`riveting`}</em>{`. I just kept catching and fishing all the way until it’s way past my usual bedtime. I never even noticed the time!`}</p>
    <Ads mdxType="Ads" />
    <p>{`As days progress, I soon unlocked a house and a museum. There are now more activities I can do around my island! I can now chop trees, or relocate them. I can dig up fossils for the museum! Rivers are no problem now as I can vault across. I can customize my furniture! With a vaulting pole and a ladder to climb cliffs, I now have access to the whole island. The island is literally, my sandbox. I can do whatever I want, whenever I want. Animal Crossing: New Horizons made no attempt to rush you even in its dialogue. Tom Nook is always the one handing out `}<em parentName="p">{`missions`}</em>{`, but he always made sure to add “come talk to me once you are free” at the end.`}</p>
    <Image alt="Animal Crossing: New Horizons party popper popping time!" caption="Party popper popping time to celebrate the opening of Nook's Cranny!" name="animal-crossing-new-horizons-opening-of-nooks-cranny.jpg" mdxType="Image" />
    <p>{`But that’s not all. Another facet that makes Animal Crossing an Animal Crossing game are… the villagers. Lord, these villagers designs are `}<em parentName="p">{`adorable`}</em>{`. Villagers are NPC(s) that will move on to your island and be your friendly neighbours. They’ll emote when you emote next them. They clap whenever they see you successfully catching a bug or fish. They’ll give you presents or DIY recipes if you talk to them! One of the most adorable things they’ll do is chase after you to give you presents if they saw you in the vicinity! Each villager has their own personality quirk that makes them unique, and sometimes really hilarious. Zipper (the Easter bunny NPC) won’t let you see his zipper on his back. He’ll always face the player when you approach him so you can never see his back. If you move away from his view (like, you got to go really far), he’ll stop his prancing, do a small sigh and walk off. It’s honestly the small little details that make the game so much enjoyable <3`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Animal Crossing villager, Tank. He's a muscle nut but he is still cute." caption="Hello Tank! Whatcha doing?" name="animal-crossing-new-horizons-villager-tank.jpg" mdxType="Image" />
    <p>{`Then there’s the museum. I’m telling you it’s perfect. You can donate your fossils, bugs and fishes to Blather (the museum curator) to fill up your museum. And you really should! The more you fill it, the more wonderful sights you’ll see in your island’s museum! I personally really like visiting museums, zoos, or aquariums so the little details and the striking similarities are seriously icing on a cake! Here are some screenshots from my island.`}</p>
    <Image alt="Animal Crossing: New Horizons museum butterfly area" caption="There's an area solely for butterflies." name="animal-crossing-new-horizons-museum-bug-section-butterflies.jpg" mdxType="Image" />
    <Image alt="Animal Crossing: New Horizons museum aquarium area" caption="There's these tubes you can walk into for the aquarium section!" name="animal-crossing-new-horizons-museum-aquarium-section.jpg" mdxType="Image" />
    <Image alt="Animal Crossing: New Horizons museum bug area that you can sit" caption="Ahhh yes, reminds me so much of this greenhouse I once visited." name="animal-crossing-new-horizons-museum-bug-section.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <Image alt="Animal Crossing: New Horizons aquarium area" caption="The water ripples looks amazing." name="animal-crossing-new-horizons-aquarium-section-beautiful-water-effects.jpg" mdxType="Image" />
    <Image alt="Animal Crossing: New Horizons prehistorical fossil area" caption="Your fossils are displayed here! From a chibi-looking item to a full-fledged dinosaur bones?! Damnnnn" name="animal-crossing-new-horizons-museum-prehistorical-fossil-section.jpg" mdxType="Image" />
    <Image alt="Animal Crossing: New Horizons aquarium area" caption="I caught these fishes, donated them to Blather and now they are in an aquarium! The reefs are stunning." name="animal-crossing-new-horizons-museum-aquarium-section-2.jpg" mdxType="Image" />
    <p>{`I haven’t unlocked terraforming yet (You can remodel the island in this game!) so the things I can do is tied to crafting new furniture for my house and island, filling up the museum and making money. The things I can do in the game is more than enough to enjoy getting lost in Animal Crossing for at least 2 hours daily. Thanks to that, my daily routine has been pleasant lately. I woke up, boot up Animal Crossing to play for a bit before starting work. I would make my Nintendo Switch sleep so I can easily get back into the game during lunchtime if I want to. After work, many of my friends would ping me to ask if they can visit my island. I’d open up my island and we’ll start playing together. My friends would visit my island first, then next I’ll visit theirs. You can skip out on waiting for days to unlock the ladder or vaulting pole - your friend can just give you one! Animal Crossing: New Horizons never stops you from one-upping the game’s dictated pacing. You do you. You can even change your Nintendo Switch’s local time to `}<em parentName="p">{`time travel`}</em>{` and the only thing you’ll lose is your turnips.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Despite the repetitive activities you can do in the game, I never once felt bored. I associate this with the attention to details done by the developers. I always made sure to put on my earphones when playing immersive games, so this is no different. The sound design is amazing. Since you’re on an island, the wind blows softly during the day but loudly at night. When it rains, your shoes thumps differently on the damp ground versus wet grass. If you approach the Drop-Off Box outside the shop when it rains, you hear the pitter-patter as the raindrops fall onto the metallic box. Enter the shop and you’ll hear raindrops hitting the roof softly. Pair this with visual eye candy and it gets even better!`}</p>
    <p>{`Cast your lure to rivers and seas, and your lure will move following the water’s flow. Cast it on lakes and your lure will never move. Walking on the beaches will leave your footprints. Yes, it’s nothing special but wait till you hear this! I have a rooster villager named Benedict, and he leaves chicken footprints on the beach! CHICKEN. FOOTPRINTS. I find this super `}<strong parentName="p">{`adorable`}</strong>{`. It doesn’t when it’s dry but walking on the damp ground leaves footprints. Every darn thing sways with the wind. It just sways harder at night! Look up to the sky after someone visits your island. You’ll see a streak of smoke left behind by the seaplane. The night sky is really pretty with the twinkling stars. The little chimes during each meteor shower is bloody `}<em parentName="p">{`adorable.`}</em>{` I swear the moon has phases according to your Nintendo Switch’s timestamp. It’s an absolute delight noticing these details.`}</p>
    <Image alt="Animal Crossings: New Horizon oarfish catch zoomed in" caption="I caught this! It's huge! Then I stuff it in my pocket, no biggie." name="animal-crossing-new-horizons-oarfish-catch.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <p>{`There are very little menus you can access in the game. You boot the game and press the A button to enter your island. That’s it. Any other dialogue boxes, modal boxes, etc will only appear for in-game features. Colour palette in this game is bright and colourful. Every darn thing in this game has texture. Animal Crossing’s design aesthetic gives a soft impression through its `}<em parentName="p">{`rounded`}</em>{` design in the trees, villagers and even buildings. Almost none of the elements in this game is overtly `}<em parentName="p">{`sharp`}</em>{` unless needed. The music, sound effect and even the gibberish speeches never overpower the other when they play. As you run everywhere on your island, the BGM would swap seamlessly.`}</p>
    <p>{`It’s a game lovingly done by Nintendo, and a much-needed one in this terrible year. I find myself obsessing with every little detail I find. I would talk to my villagers often just to see what new dialogues they have. I spent hours customizing a single room in my house. Flowers were dug up and replanted many times. I need them in a single row lining up my house’s fencing! I intentionally added a chair in my room just so I can let my character sit on it while I enjoy K.K. Slider songs being played on the radio.`}</p>
    <p>{`This is crazy. It’s consuming me. Now I finally understand why Animal Crossing fans are so obsessed with this game. It’s `}<strong parentName="p">{`adorable`}</strong>{`. Each element in this game evokes a moe feeling, and I can’t get enough of it. Even as I write this blog post, I can’t wait to get back into my copy of Animal Crossing: New Horizons. I hope this little write up will convince you to try this game!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      