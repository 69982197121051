import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../styles/globals';

const Container = styled.div`
  border: 2px solid ${$.grey1};
  padding: ${$.layout.padding4}px;
  border-radius: ${$.borderRadius.radius2}px;
  overflow-y: auto;

  ${$.screen.desktop} {
    padding: ${$.layout.padding2}px ${$.layout.padding3}px;
  }

  ${$.screen.tablet} {
    width: calc(100% - ${$.layout.padding4 * 2}px);
  }

  ${$.screen.mobile} {
    width: calc(100% - ${$.layout.padding4 * 2}px);
  }

  > * {
    margin-bottom: ${$.layout.margin3}px;
    line-height: 1.5em;
    font-family: Rubik Regular;
    font-size: 18px;
    color: ${$.brown4};

    strong {
      font-family: Rubik Regular;
      font-weight: bold;
    }

    i {
      font-family: Rubik Regular;
      font-style: italic;
    }
  }

  a {
    font-family: Rubik Regular;
    font-weight: bold;
    color: ${$.color.orange};
    text-decoration: none;
    &:visited {
      color: ${$.color.orange};
    }
  }
`;

const Story = ({ children, className }) => (
  <Container className={className}>{children}</Container>
);

Story.defaultProps = {
  className: '',
};

Story.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
    .isRequired,
};

export default Story;
