import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Blockquote = makeShortcode("Blockquote");
const RemoteLink = makeShortcode("RemoteLink");
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`True wireless earbuds have been the rage lately. I can't help noticing the surge in advertisements for wireless earbuds. It started off
with `}<strong>{`Sony WF-1000XM3`}</strong>{` back in early 2019, and now wireless earbuds seem to be everywhere. I was never one to nitpick my earphone choices so these overpriced,
yet seemingly basic earbuds `}<strong>{`confound`}</strong>{` me.`}</p>
    <p>{`Battery life seems way too low for that price, and not to mention the average music quality that these brands offer for an entry/mid-range earbuds. Sure, you could argue that higher price equates to better quality, but as a casual listener, I'm not willing to pay an exorbitant price just for a pair of earbuds.`}</p>
    <Blockquote mdxType="Blockquote">
  I was looking to get earbuds that were slightly above average in quality on
  what's offered on the market, but with a price that I am willing to pay.
    </Blockquote>
    <p>{`It was a tall order, I know. But I wasn't in a rush to get one so I looked around at my own sweet pace. Then a colleague of mine suggested `}<RemoteLink to="https://www.lypertek.com/tevi" mdxType="RemoteLink">{`Lypertek Tevi`}</RemoteLink>{`.`}</p>
    <em>
  Note: I am in no way affiliated nor endorsed by any companies mentioned here.
  This review piece is of my own opinion.
    </em>
    <Ads mdxType="Ads" />
    <p>{`Blimey, it's so `}<strong>{`good`}</strong>{`. Way too good for a newbie with that price tag. Just to give a general idea on my audio quality experience, I own a `}<RemoteLink to="https://www.sony.com/electronics/in-ear-headphones/mdr-xb50ap" mdxType="RemoteLink">{`Sony MDR-XB50AP`}</RemoteLink>{`. It is a great pair of earphones, but Tevi was way, way better. The singing voice is crystal clear and reverberates. Instrumental music is a joy to listen to on Tevi. This earpiece provides a more balanced acoustic, so you could pick out every instrument being played.`}</p>
    <p>{`No sound overpowers the other, thus giving a sense of "wide bass". Listening to Interstellar OST was pretty amazing on Tevi given how quiet the soundtracks can be. I can hear even the most minute details! The bass isn't too overwhelming either compared to Sony MDR-XB50AP's booming bass. I was very pleased with the music quality all in all. It's perfect for acapella songs and orchestras.`}</p>
    <p>{`Listening to Tevi in busy places can be pretty cool. You either hear music or music only. Seriously. You could barely hear outside unless you wear only one earbud and leave the other ear free. People were talking to me but I could barely make out their voices. It could be due to the silicone tips providing natural noise insulation.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Tevi provides 3 sets of silicone tips with different sizes plus a foam tip so it should be able to fit into your ear nicely. They barely budged even while I was jogging or walking briskly so you shouldn't worry about them falling out.`}</p>
    <Image alt="Lypertek Tevi controls" caption="Lypertek Tevi's manual sheet. Captured from Tevi's official website." name="lypertek-tevi-controls.jpg" mdxType="Image" />
    <p>{`I've been using the earbuds for 2 weeks now with my usage time averaging 2.5 hours a day, and yet there's still juice in the charging case! I made sure to put the earbuds back into the case once I'm done with it so it's always charging the earbuds whenever possible. Since I won't be using the earbuds for hours, the earbuds batteries almost `}<strong>{`never`}</strong>{` run out.`}</p>
    <p>{`Tevi provides 10 hours of playback time on a single charge and along with the case, a total of 70 hours. The site mentioned that a 15-minute charge provides a 2 hour of playback time. The total playback hours are insane!`}</p>
    <Blockquote mdxType="Blockquote">
  Given how powerful the battery life is, I'm afraid this little case might
  explode in my pocket.
    </Blockquote>
    <p>{`Another feature I love the most from Tevi is the mono mode feature. You can choose to listen to only one earbud at a time by placing the unused earbud back to the charging case or turning it off. This is great because I can continue using them even if either one ran out of juice. Unfortunately, the overall music quality is a little washed out with only one of the earbuds. You'll need to wear both to get the full experience.`}</p>
    <Ads mdxType="Ads" />
    <p>{`There are no touch controls on this earpiece, and that's okay. A gentle press is enough for Tevi to register the presses. You can use your phone's voice assistant as well. I tried with Siri and it works pretty well. The whole process of connecting to Bluetooth was easy and seamless.`}</p>
    <p>{`There were no issues with connecting to IOS, Mac Mini and my Windows laptop. I did not encounter any playback or connectivity issues even when I switched between mono mode and having both earbuds on back and fro. The switching between the modes was seamless as the music continued chugging along.`}</p>
    <p>{`Answering calls on Tevi is easy. Just press once on either one of the earbuds! With noise-cancelling built-in those pieces, your voice is much clearer to the recipient. I gave it a whirl, and it wasn't so bad. Background noise sounds much more muted and my voice comes off as pretty clear. However, I see myself rarely using this since it doesn't work so well in noisy places. I honestly prefer answering the phone normally in most cases.`}</p>
    <Ads mdxType="Ads" />
    <p>{`I'm honestly very impressed with Lypertek Tevi. Priced at SGD 139, it's a steal! Coupled with IPX7 waterproof grade, it's perfect for gym-goers too.`}</p>
    <p>{`You can find more information on Lypertek Tevi on its official website `}<RemoteLink to="https://www.lypertek.com/tevi" mdxType="RemoteLink">{`here`}</RemoteLink>{`. I bought my Tevi from `}<RemoteLink to="https://store.jaben.com/products/lypertek-tevi" mdxType="RemoteLink">{`Jaben Singapore`}</RemoteLink>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      