import styled from 'styled-components';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import $ from '../../../styles/globals';
import LeftChevron from '../../../assets/icons/left-chevron.svg';
import ShareBox from './ShareBox';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 90px;
`;

const StyledLeftChevron = styled(LeftChevron)`
  width: 24px;
  height: 24px;
  fill: ${$.brown5};

  ${$.screen.desktop} {
    position: relative;
    top: -2px;
    left: -4px;
  }
`;

const BackToHome = styled.div`
  color: ${$.brown4};

  ${$.screen.desktop} {
    transition: border-bottom 0.3s ease;
    border-bottom: 2px solid transparent;
  }
`;

const BackContainer = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;

  ${$.screen.desktop} {
    &:hover {
      ${BackToHome} {
        border-bottom: 2px solid ${$.brown4};
      }
    }
  }
`;

const SocialTool = ({ link, text, className }) => (
  <Container className={className}>
    <BackContainer to={link} title={text}>
      <StyledLeftChevron />
      <BackToHome>{text}</BackToHome>
    </BackContainer>
    <ShareBox />
  </Container>
);

SocialTool.defaultProps = {
  link: '/',
  text: 'Back to Home',
  className: '',
};

SocialTool.propTypes = {
  className: PropTypes.string,
  link: PropTypes.string,
  text: PropTypes.string,
};

export default SocialTool;
