import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Blockquote = makeShortcode("Blockquote");
const Ads = makeShortcode("Ads");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`My mini project, Tea Time (As of the edited time, this page has been removed) has been an interesting one. In order to create this web app, I compiled information from all over the place and boy, was it hard. Most of the information is correct, but many websites had conflicting information. Its time like this that you appreciate having the game's copy at hand.`}</p>
    <Blockquote mdxType="Blockquote">
  The mini game may seem simple, but it has a ton of data in it. IntSys and Koei
  Tecmo have put in a lot of effort into this.
    </Blockquote>
    <Ads mdxType="Ads" />
    <p>{`So long story short, I packed up the information in a way that's easy for me to write a script for and scrutinize the details. There's a ton of details in this mini game! I have a whole new level of appreciation towards IntSys + Koei Tecmo tag team now. Thank you for not compromising on the game's writing and eye for details.`}</p>
    <em>
  Note: The images used for this blog post are screenshots captured directly
  from my Nintendo Switch.
    </em>
    <h2>Here are the basic numbers</h2>
    <p>{`Fire Emblem Three Houses's tea time game includes three sections - the tea selection, timed topic choices and 1 timed final comment. There are 35 characters to choose from to have tea time sessions with. Including an unlockable Archbishop's tea time info that I managed to find, that totals up to 36. Each one of them has at least 1 favourite tea and 4 topics that you can pick as the correct choice.`}</p>
    <p>{`However, everyone has the same number of final comments to choose from. These final comments totalling up to 9 is unique to each one of the characters. That means there are 36 x 9 = 324 final comments, with each one of them having their unique laugh, blush, etc. choices! Oof, the number terrifies me.`}</p>
    <Ads mdxType="Ads" />
    <h2>Topics are shared but not all of them</h2>
    <p>{`Everyone shares more than a few topic choices with one another. Overall, the total topics available in this mini game is 101. It used to be 99 without Anna and Jeritza! Anna has the most topics at 50, and Rhea has the least at 4. Obviously I didn't go count them all, I made a script do that for me. I tried looking for topics shared by everyone but no luck. There are no safe choices in this game. Even the topic "Thanks for everything..." is only shared between 34 characters.`}</p>
    <p>{`Discounting Rhea, that means there are 2 characters not having this topic as the correct choice for them.`}</p>
    <h2>All characters have 1 topic unique to them</h2>
    <p>{`All 35 characters have 1 topic that is unique to them alone. E.g. Petra with her `}<em>{`"Swimming in the ocean..."`}</em>{` topic, and Dedue's `}<em>{`"Dimitri..."`}</em>{` topic. These are painfully obvious so I guess these are the safe choices by IntSys...?`}</p>
    <Ads mdxType="Ads" />
    <h2>Pick one and hope for the best</h2>
    <p>{`Each final comment has between 1 to 3 possible answers from 10 choices. These 10 choices are laugh, blush, sip tea, praise, disagree, blush, commend, admonish, chat and sigh. Out of the three choices for the randomized final comment in the game, 1 or 2 choices are the correct answers.`}</p>
    <p>{`There's 130 `}<em>{`"nods"`}</em>{` and 27 `}<em>{`"sighs"`}</em>{` set as the answer for the final comments. Basically, Byleth `}<em>{`"nods"`}</em>{` a lot and `}<em>{`"sighs"`}</em>{` the least. Everyone except Ingrid have at least 1 `}<em>{`"nod"`}</em>{` as the answer for their final comments. So when in doubt, just nod.`}</p>
    <h2>Here's some hilarious numbers regarding final comments</h2>
    <p>{`Byleth never laughs during tea time with Hubert. Zilch, nada! The award of most blushes you can choose as an answer goes to Edelgard's. Byleth gets to blush up to 6 times during Edelgard's tea time. So make sure to blush whenever it's time to sip tea with Edelgard.`}</p>
    <p>{`Byleth admonishes Bernadetta the most at 4 times, while he/she sips tea the most with Catherine at 5 times.`}</p>
    <p>{`Here's another kicker - Byleth never praises Felix and Seteth, but has never disagreed with them either.`}</p>
    <Ads mdxType="Ads" />
    <h2>What about the teas?</h2>
    <p>{`There are a total of 20 teas available for tea time including the one-for-all choices; Leicester Cortania and Hresvelg Blend. Those who only have 1 favourite tea are Caspar, Catherine, Cyril, Dimitri and Edelgard. On the other hand, Hanneman, Lysithea and Hilda each have the highest number of favourite teas at 4. The tea choices greatly hint at their personality, and I was really impressed with the amount of work they did.`}</p>
    <p>{`Ironically, Ferdinand `}<strong>{`doesn't`}</strong>{` like Rose Petal Blend.`}</p>
    <h2>All in all</h2>
    <p>{`I hope you enjoyed this write-up!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      