import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { navigate } from 'gatsby';

import $ from '../styles/globals';
import Button from './Button';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  > *:not(:last-child) {
    margin-right: ${$.layout.margin2}px;
  }
`;

const Dropdown = styled.select`
  padding: 4px 8px;
  border-radius: 8px;
  font-size: 16px;
  border: 1px solid ${$.grey2};
  font-family: Rubik Regular;
  outline: none;

  ${$.screen.mobile} {
    font-size: 15px;
  }
`;

const StyledButton = styled(Button)`
  ${({ show }) => `
  pointer-events: ${show ? 'initial' : 'none'};
  opacity: ${show ? '1' : '0'};
  padding: 3px;
  min-width: 40px !important;
  border-radius: 8px;
  pointer: cursor;

  ${$.screen.mobile} {
    font-size: 15px; 
  }
`}
`;

const Pagination = ({ maxItem, setSlice, totalItems }) => {
  const [page, setPage] = useState(1);
  const totalPages = totalItems > maxItem ? Math.ceil(totalItems / maxItem) : 1;

  useEffect(() => {
    setSlice(() => {
      switch (page) {
        case 1:
          return { start: 0, end: maxItem };
        default:
          return {
            start: maxItem * (page - 1),
            end: maxItem * (page - 1) + maxItem,
          };
      }
    });

    if (typeof window !== 'undefined') {
      window.scrollTo({ top: 0 });
    }
  }, [page]);

  useEffect(() => {
    const cb = () => {
      const searchParams = new URLSearchParams(window.location.search);

      if (searchParams && searchParams.get('page')) {
        setPage(Number(searchParams.get('page')));
      } else {
        setPage(1);
      }
    };

    if (typeof window !== 'undefined') {
      cb();
      window.addEventListener('routeChanged', cb);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('routeChanged', cb);
      }
    };
  }, []);

  return totalPages > 1 ? (
    <Container>
      <StyledButton
        onClick={() => {
          navigate(`${window.location.pathname}?page=${page - 1}`);
          setPage((prev) => prev - 1);
        }}
        title="Go to previous page"
        show={page > 1}
      >{`<<`}</StyledButton>
      <Dropdown
        value={page}
        title="Go to page"
        onChange={(e) => {
          setPage(Number(e.target.value));
          navigate(`${window.location.pathname}?page=${e.target.value}`);
        }}
      >
        {Array.apply('', Array(totalPages)).map((_, index) => (
          <option key={`topic_page_${index + 1}`} value={index + 1}>
            {`Page ${index + 1}`}
          </option>
        ))}
      </Dropdown>
      <StyledButton
        onClick={() => {
          navigate(`${window.location.pathname}?page=${page + 1}`);
          setPage((prev) => prev + 1);
        }}
        title="Go to next page"
        show={totalPages > 1 && page < totalPages}
      >
        {`>>`}
      </StyledButton>
    </Container>
  ) : (
    <></>
  );
};

Pagination.defaultProps = {
  maxItem: 20,
  totalItems: 0,
  setSlice: () => {},
};

Pagination.propTypes = {
  totalItems: PropTypes.number,
  setSlice: PropTypes.func,
  maxItem: PropTypes.number,
};

export default Pagination;
