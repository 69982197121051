import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const RemoteLink = makeShortcode("RemoteLink");
const Blockquote = makeShortcode("Blockquote");
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Ten different reasons why Iruma-kun/`}<RemoteLink to="https://en.wikipedia.org/wiki/Welcome_to_Demon_School!_Iruma-kun" mdxType="RemoteLink">{`Welcome to Demon School! Iruma-kun`}</RemoteLink>{` is a fun anime to watch? Easy. But frankly, all you need is just one. But what is Iruma-kun all about?`}</p>
    <Blockquote mdxType="Blockquote">
  Iruma-kun is an unexpected 2000s show barging into 2019 with a style we sorely
  miss from our younger times.
    </Blockquote>
    <p>{`Note: The card image is from `}<RemoteLink to="https://www6.nhk.or.jp/anime/program/detail.html?i=iruma" mdxType="RemoteLink">{`Iruma-kun's official website`}</RemoteLink>{`.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Our protagonist is a kind-hearted, yet somewhat aimless in life young boy. He’s 14 years old, has never attended school, and never says No to any requests. Basically speaking, he's a yes boy. He's weak towards any requests that starts off with Please. To have a weak-willed protagonist is something unheard of in stories nowadays, but the author managed to write him just right.The series starts off with his parents selling him for a hefty sum of cash... to a demon named Sullivan. As Iruma begin counting his final days, Sullivan proposed a preposterous request to him. Turns out all Sullivan ever wanted is a grandson to love 😩 ! Of course, Iruma-kun can’t say No to that. The scene quickly cuts to him signing off a contract to the Sullivan. Poor Iruma. At least his grandpa loves him lots?Iruma then quickly found out that Sullivan is the headmaster of a demon school, and once again, he can't say No to his loving grandpa's request to send him to school. Demons eat human here, so Iruma has to be careful not to let his classmates know he is human!`}</p>
    <Image alt="Even a succubus thinks your sex appeal is terrible! - This teacher is pretty fun" caption="Even a succubus thinks your sex appeal is terrible! You lost to a baby???" name="mairimashita_irumakun_2.jpg" mdxType="Image" />
    <h2>{`So why should you watch this series?`}</h2>
    <p>{`Remember the good ole days, where you tune in to Saturday morning cartoons? Classics such as Digimon, Pokemon and Zoids graced the channels back then. Those anime were episodic, yet the action and humour string you along. These shows are all about kids doing their own thing in a fun, fantasy world. Iruma-kun is exactly that. The style reminds me so much of a 90s anime.If the statement above doesn't convince you, then consider this - if you enjoy a colourful cast and watching them doing silly things (with a pinch of adventure), then this show is for you. I find myself happily clicking `}<em parentName="p">{`Next `}</em>{`because I wanted more Iruma-kun <3`}</p>
    <Ads mdxType="Ads" />
    <h2>{`The cast is super colourful`}</h2>
    <p>{`I mean both literally and figuratively! Everyone has a unique and crazy character design that you can easily tell apart. I mean, look at those great hair colours. This series is just exploding in variety! Characters are really expressive. Plus, the art and animation maintained the same quality throughout the show. The look of this show is nothing special but it falls into the charming category. Furthermore, the series never tries to get serious other than several `}<strong parentName="p">{`"find yourself"`}</strong>{` scenes. This is one of the series's strength, and it played them well.`}</p>
    <Image alt="The school president over reacts to everything, just like a shoujo protagonist." caption="The school president over reacts to everything, just like a shoujo protagonist. She's super tsun..." name="mairimashita_irumakun_1.jpg" mdxType="Image" />
    <h2>{`Iruma is a nice guy`}</h2>
    <p>{`The core of this series plot is mostly centred on Iruma being both a human and a kind-hearted person. All scenes played out as it is because Iruma is truly a nice guy. When I say nice, I mean he's a really, really nice guy. He looks gullible because he `}<strong parentName="p">{`IS `}</strong>{`gullible. Despite having so much naivety in him, I find him really likeable as a character. Demons are destructive in nature so Iruma's common sense does not play well with theirs. Watching the demons slowly warm up to him and mellow out makes my heart go soft in most of the episodes.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`A show you can enjoy again and again`}</h2>
    <p>{`Some shows are best watched once and done. Take, for example, Code Geass. It’s built upon plot twists and more twists. That show's second season is literally built upon having a disadvantage happening to main cast, then having the Lelouch finding some way countering it. Knowing these twists beforehand ruins the fun of the episode no matter how epic it is, so you'll never find me re-watching Code Geass unless I've forgotten most of it.Iruma-kun, on the other hand, consists of mostly silly fun episodes. It falls into a category where its a show I can watch again and again, even after I finished it. I’d put it in a list where I’ll be more than happy to rewatch it if I ever feel like doing an anime marathon.`}</p>
    <p><strong parentName="p">{`Note: The anime is ongoing as of writing, and there’s a manga that’s wayyyy beyond the anime. Give the manga a try too!`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      