import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Image = makeShortcode("Image");
const LocalLink = makeShortcode("LocalLink");
const Ads = makeShortcode("Ads");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Do you like `}<strong parentName="p">{`Dragon Quest`}</strong>{`? If the video game title sounds unfamiliar to you, let me rephrase the question. Do you enjoy watching a show about heroes and old enemies uniting under the same banner in order to defeat the big baddie known as the Demon Lord? It sounds old-fashioned for sure in this era of modern anime. I believe folks would be more inclined to explore this anime title if it included the word `}<strong parentName="p">{`isekai`}</strong>{` in its title, or if it’s about `}<em parentName="p">{`cute girls doing cute things.`}</em></p>
    <Image alt="Dragon Quest: The Adventure of Dai anime logo" caption="There's this inexplicable feeling whenever I see the anime's logo... I feel proud? Happy? Or just overjoyed to see it again?" name="dragon-quest-the-adventure-of-dai-anime-2020-2.jpg" mdxType="Image" />
    <p><em parentName="p">{`Note: I wrote a `}<LocalLink to="/blog/dragon-quest-the-adventure-of-dai-is-a-blast-from-the-past/" mdxType="LocalLink">{`blog post`}</LocalLink>{` on `}<strong parentName="em">{`Dragon Quest: The Adventure of Dai (Dai no Daibouken)`}</strong>{` some time ago, please check that if you enjoyed this write-up. The linked blog post is about the manga! This blog post is written right after watching Episode 2. `}</em></p>
    <Ads mdxType="Ads" />
    <p>{`Fortunately, the good thing about old-fashioned ideas is that `}<strong parentName="p">{`it works great.`}</strong>{` Simplicity was the key back then, and Dragon Quest: The Adventure of Dai made sure to practice this idea in it’s plot. `}<strong parentName="p">{`The Adventure of Dai`}</strong>{` is set in a world similar to Dragon Quest. Monsters and magic exist in this world, along with a Demon Lord. Monsters are the bane of humanity, threatening to kill and overwhelm humanity. And it is this Demon Lord who holds sway over these monsters. One day, a Hero (Yuusha) rose up to quell the evil once and for all, and peace returned to the world. Without a Demon Lord, some monsters break free from his control and leave the mainland to find peace on a deserted island.`}</p>
    <Image alt="Dragon Quest: The Adventure of Dai anime screenshot 1" caption="Gome-chan! He's so cute... I like a flying golden slime too. If only you could poop gold... I guess the best thing I could use you for is as a torchlight in the dark." name="dragon-quest-the-adventure-of-dai-anime-2020-4.jpg" mdxType="Image" />
    <p>{`It was a peaceful island and the monsters never returned to their violent streaks. It was then that a baby boy washed ashore on the monster island. A single monster named Brass took pity on the baby and decided to care for him since then. 12 years later, the boy (named Dai) lived happily on the island with his monster friends. He aspires to be a Hero just like the legends Brass told him about! His chance came when a group of sleazy adventurers took advantage of his naivety to steal Gome-chan, a golden metallic `}<strong parentName="p">{`Dragon Quest slime.`}</strong></p>
    <Ads mdxType="Ads" />
    <p>{`Dai managed to beat them back and in light of his bravery, he was awarded the Crown of Champions by the King of Romos. Word of a new hero spread far and wide. Soon a new group visited the island. Leona, a princess of Papnica came along with an entourage in order to complete a ritual on the island. Things happened and Dai successfully awakened his Dragon Crest. Princess Leona leaves the island with a promise to Dai, assuming that he wishes to leave the island and visit Leona in her kingdom. Spoiler alert, he did leave in the end.`}</p>
    <Image alt="Dragon Quest: The Adventure of Dai anime screenshot 2" caption="The initial main cast! From the left, Leona, Popp, Dai, Maam and Avan. I'm sure this screen will slowly fill itself up as the newer episodes air <3" name="dragon-quest-the-adventure-of-dai-anime-2020-3.jpg" mdxType="Image" />
    <p>{`Episode 1 has some really nice 3D models for the monster entourage while Episode 2 has a fun `}<em parentName="p">{`done-in-3D`}</em>{` fight scene versus a robot. In case you haven’t realized yet, everything in this series is basically Dragon Quest. Thus, if you played Dragon Quest, this is basically an anime spin-off for it. There are some really common monsters you often find in the game like slimes, golems and the killing machine. Then there’s the magic system! There’s this warm, fuzzy feeling when you see terms you often read in a childhood video game of yours… `}<strong parentName="p">{`Sizzle?`}</strong>{` `}<em parentName="p">{`Kaswoosh?`}</em>{` Nice! <3`}</p>
    <Ads mdxType="Ads" />
    <p>{`When Toei announced that they are rebooting the series again (There’s an older version of the anime aired in 1991), I was over the moon because I love the video game and I love the manga for Adventure of Dai. But I wasn’t sure how the final version would look! One of the most popular `}<strong parentName="p">{`Toei Animation works`}</strong>{` is `}<em parentName="p">{`One Piece`}</em>{`, and I disliked how they handled the`}<strong parentName="p">{` Fishman Island arc`}</strong>{`. The animation is terrible, the pacing is all over the place and the art isn’t up to snuff. All of a sudden, they did a 180 shift and Wa no Kuni was animated pretty `}<strong parentName="p">{`good`}</strong>{`. The inconsistency of this studio is infuriating.`}</p>
    <Image alt="Dragon Quest: The Adventure of Dai anime screenshot 3" caption="Loving the shot here! Could have sworn this is from a manga panel..." name="dragon-quest-the-adventure-of-dai-anime-2020-1.jpg" mdxType="Image" />
    <p>{`Thankfully, Adventure of Dai’s 2020 version didn’t look too bad. It’s more colourful than I expected though. Were they aiming for a kid-friendlier anime? But they added a ton of blood spurts in Episode 1 and 2. Nevertheless, Adventure of Dai is good so far! Hopefully it stays good. I’m watching you, Toei Animation `}{`>`}{`:(`}</p>
    <p>{`The addition of 3D models is a surprise, but I’m really happy to see it being employed in scenes that deserve a good 3D model for the maximum effect. Then there’s the pacing. A lot happened in Episode 2! The content is long enough that I expected it to be split across 2 episodes but nay, they stuff it all in 1 episode! I was surprised they even manage to do that without the whole plot feeling rushed. Honestly, it was really satisfying to watch. The manga’s content is enough for 50 episodes, and I hope they do plan to at least reach that amount of episodes. Maybe a 2-cour at least!`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Dragon Quest: The Adventure of Dai anime screenshot 4" caption="Dai's smile is to die for <3" name="dragon-quest-the-adventure-of-dai-anime-2020-5.jpg" mdxType="Image" />
    <p>{`If I were to give a quick summary on `}<strong parentName="p">{`Dragon Quest: The Adventure of Dai `}</strong>{`without going into spoiler, I’d say this is a super old-fashioned yuusha versus demon lord adventure. You should be familiar with the cliche by now! The last hero of humanity going up against a demon lord. Along the way he was joined by his party members, defeating mid bosses along the way. He went training to gain more power, helped out a kingdom or two and re-discover his own lineage.`}</p>
    <p>{`If the summary sounds interesting for you, please join in the fun! The anime has just started and I assure you, it’s going to get even more interesting later on. If you wanted to get ahead of the anime, consider reading the manga. It’s a classic! (Yes there is romance as well!)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      