import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { importOneImage } from '../../../../utils';
import $ from '../../../../styles/globals';

const Figure = styled.figure`
  margin: ${$.layout.margin3}px 0 ${$.layout.margin6}px 0;
  width: 100%;

  img {
    object-fit: cover;
    display: block;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: ${$.borderRadius.radius1}px;
  }
`;

const Caption = styled.figcaption`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-top: ${$.layout.padding5}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: ${$.brown3};
  font-family: Rubik Regular;
  font-weight: bold;
  font-size: 16px;
  line-height: 1.4em;
`;

const Image = ({ caption, alt, name }) => {
  const [fanficURL, setFanficURL] = useState('');

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const path = window.location.pathname
        .split('/')
        .filter((section) => section);

      setFanficURL(path[1]);
    }
  }, []);

  return (
    <Figure>
      {fanficURL && (
        <img
          src={importOneImage(`images/mdxfanfiction/${fanficURL}/${name}`)}
          alt={alt}
        />
      )}
      {caption && (
        <Caption>
          <div dangerouslySetInnerHTML={{ __html: caption }} />
        </Caption>
      )}
    </Figure>
  );
};

Image.defaultProps = {
  caption: '',
  alt: '',
};

Image.propTypes = {
  alt: PropTypes.string,
  name: PropTypes.string.isRequired,
  caption: PropTypes.string,
};

export default Image;
