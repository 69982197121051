import Prism from 'prismjs';
import PropTypes from 'prop-types';
import he from 'he';

const Codeblock = ({ text, label }) => (
  <code className={`language-${label}`}>
    {Prism.highlight(he.decode(text), Prism.languages[label], label)}
  </code>
);

Codeblock.defaultProps = {
  text: '',
  label: 'js',
};

Codeblock.propTypes = {
  text: PropTypes.string,
  label: PropTypes.string,
};

export default Codeblock;
