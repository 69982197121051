import { useRef, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import atFanfictionChapter from './atFanficChapter';
import BurgerMenu from './BurgerMenu';
import Dropdown from './Dropdown';
import $ from '../../styles/globals';
import Burger from '../../assets/icons/burger.svg';
import { addKeys } from '../../utils';
import data from './items.json';
import Logo from './Logo';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${$.brown3};
  height: calc(58px - ${$.layout.padding2 * 2}px);

  ${$.screen.desktop} {
    width: 1200px;
    padding-top: ${$.layout.padding2}px;
    padding-bottom: ${$.layout.padding2}px;
    padding-left: calc((100vw - 1200px) / 2);
    padding-right: calc((100vw - 1200px) / 2);
  }

  ${$.screen.tablet} {
    width: calc(100vw - ${$.layout.padding6}px * 2);
    padding: ${$.layout.padding2}px ${$.layout.padding6}px;
  }

  ${$.screen.mobile} {
    width: calc(100vw - ${$.layout.padding4}px * 2);
    padding: ${$.layout.padding2}px ${$.layout.padding4}px;
  }
`;

const NavContainer = styled.div`
  ${$.screen.desktop} {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    > * {
      margin-right: ${$.layout.margin3}px;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  ${$.screen.tablet} {
    display: none;
  }

  ${$.screen.mobile} {
    display: none;
  }
`;

const NavTitle = styled(({ to, children, ...rest }) => {
  if (typeof to !== 'undefined') {
    return (
      <Link to={to} {...rest}>
        {children}
      </Link>
    );
  }

  return <div {...rest}>{children}</div>;
})`
  text-decoration: none;

  ${$.screen.desktop} {
    font-family: Rubik Regular;
    font-weight: bold;
    font-size: 16px;
    color: ${$.brown4};
    letter-spacing: -0.4px;
    :after {
      content: '';
      display: block;
      width: 0;
      border-bottom: 2px solid ${$.brown5};
      transition: width 0.2s ease-in-out;
    }
    &.active {
      cursor: ${({ to }) =>
        typeof to !== 'undefined' ? 'pointer' : 'default'};

      :after {
        width: 100%;
      }

      > div {
        transform: translateY(0) translateX(-50%);
        opacity: 1;
        pointer-events: initial;
      }
    }
  }

  ${$.screen.tablet} {
    display: none;
  }

  ${$.screen.mobile} {
    display: none;
  }
`;

const StyledBurger = styled(Burger)`
  width: 26px;
  height: 26px;
  stroke: ${$.brown5};

  ${$.screen.desktop} {
    display: none;
  }
`;

const items = addKeys(data).map(({ subs, ...details }) => {
  if (typeof subs === 'undefined') {
    return { ...details };
  }

  return {
    subs: addKeys(subs),
    ...details,
  };
});

const NightMode = createGlobalStyle`
  body.night-mode {
    ${NavTitle} {
      color: ${$.white1};
      :after {
        border-bottom: 2px solid ${$.white1};
      }
    }
    ${StyledBurger} {
      stroke: ${$.white1};
    }
  }
`;

const TopBar = ({ setBurgerMenu, burgerMenu }) => {
  const containerRef = useRef(null);
  const [activeNav, setActiveNav] = useState('');

  return (
    <Container ref={containerRef}>
      <Logo />
      <NavContainer>
        {atFanfictionChapter() && <NightMode />}
        {items.map(({ text, divider, subs, key, description, link }) => {
          if (divider === true) {
            return '';
          }

          return (
            <NavTitle
              key={key}
              to={link}
              title={text}
              className={activeNav === key ? 'active' : ''}
              onMouseEnter={() => {
                setActiveNav(key);
              }}
              onMouseLeave={() => {
                setActiveNav('');
              }}
            >
              {text}
              {typeof subs !== 'undefined' ? (
                <Dropdown
                  items={subs}
                  setActiveNav={setActiveNav}
                  description={description}
                />
              ) : (
                ''
              )}
            </NavTitle>
          );
        })}
      </NavContainer>
      <StyledBurger
        id="burger-icon"
        onClick={() => {
          setBurgerMenu((prev) => !prev);
        }}
      />
      <BurgerMenu setBurgerMenu={setBurgerMenu} show={burgerMenu} />
    </Container>
  );
};

TopBar.defaultProps = {
  setBurgerMenu: () => {},
  burgerMenu: undefined,
};

TopBar.propTypes = {
  setBurgerMenu: PropTypes.func,
  burgerMenu: PropTypes.bool,
};

export default TopBar;
