import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXFanfiction/Chapter/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Divider = makeShortcode("Divider");
const MessageBox = makeShortcode("MessageBox");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Byleth felt panic set in again. Just when she thought death had her in its grasp, she was currently in the arms of Dimitri Alexander Blaiddyd, a man she found to be rude, grumpy and irritating. A man whose life she tried to save and nearly killed herself doing it.`}</p>
    <p>{`But here he was, his arms firmly wrapped around her, holding on to her as she gawked at...his..tail.`}</p>
    <p>{`Before another thought could take hold of her, Dimitri used his tail and propelled them upwards. When they broke the surface literally half a second later, Dimitri set her down by the pool edge.`}</p>
    <p>{`To her great shock, the change in air was no different when she was underwater. She was half expecting to be choking and gasping for air like they do in movies, but...here she was, breathing as though she wasn’t just drowning a minute ago.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“What-” she stopped, unable to find the words.`}</p>
    <p>{`“But...you—I saw…” she started breathing heavily, her head started throbbing. Unable to process what was happening.`}</p>
    <p>{`“I’m..dead aren’t I?” She finally said after a good minute of silence.`}</p>
    <p>{`Byleth never thought she would ever see such a sight, but Dimitri chuckled.`}</p>
    <p>{`He shook his head at her, “No.”`}</p>
    <p>{`“Wow…” She breathed, staring at him and his massive fish tail that was gently swaying under water as he leaned on the pool edge next to her. She felt like a child again, with her face stuck to the glass of the aquarium watching her pet guppies swim around.`}</p>
    <p>{`“Are you alright?” he asked softly, snapping her out of her trance.`}</p>
    <p>{`“No...umm, yes, but uh...you are capable of normal speech,” she said.`}</p>
    <p>{`He blinked at her, “What?”`}</p>
    <p>{`“Sorry, er...because you don’t normally...umm, talk so much? Like, uh, you’re quite rude most of the time, glaring and grunting at people. Or just straight up ignoring them,” she blurted out.`}</p>
    <p>{`Dimitri turned away, “Sorry...I’m not very used to humans.”`}</p>
    <p><em parentName="p">{`Oh.`}</em></p>
    <p><em parentName="p">{`Ohhhhhhhh`}</em>{`.`}</p>
    <p>{`It finally hit her that the man she knew as Dimitri, was a mermaid. Just right in front of her, in plain sight, was a mermaid. Byleth didn’t know what to believe anymore, her chest was heavy with doubt and she was incredibly lost.`}</p>
    <p>{`She couldn’t help but notice he was topless and pantless..or..er whatever mermaids wore since his tail was out. She didn’t know anymore, `}<em parentName="p">{`she just didn’t know.`}</em></p>
    <Ads mdxType="Ads" />
    <p>{`A long silence passed between them, Byleth still a daze, kept quiet and very still. Dimitri would probably assume she was in shock, having nearly died and now discovering that a myth wasn’t a myth. And he would’ve assumed correctly.`}</p>
    <p>{`“Um...then how are you speaking so easily to me right now?” She finally said.`}</p>
    <p>{`He shook his head, “It is not easy.”`}</p>
    <p>{`She smacked her forehead, “I’ve definitely died, there is no way mermaids exist,” Byleth violently shook her head, “There was no evidence anywhere. Just no solid proof— Hey!”`}</p>
    <p>{`Byleth cried out when Dimitri used his massive tail to splash her with water, as if he was showing her proof. It was honestly crazy how hypnotizingly beautiful a fish tail could be.`}</p>
    <p>{`“I have so many questions,” she admitted. She tried very hard to reorganise the questions in her head in an attempt to calm herself down, “First is, what was wrong with you just now?”`}</p>
    <p>{`Dimitri nodded, “I was being stupid,” he paused, looking as though he was struggling to find the right words, “Um, mermaids are...apparently deathly allergic to alcohol.”`}</p>
    <p>{`“Apparently?”`}</p>
    <p>{`“I came tonight as Dedue said it was good exposure, but I also wanted to try beer.”`}</p>
    <p>{`She shivered, the coldness reminding her she was soaked to the skin and her white shirt was completely see through.`}</p>
    <p>{`“Why did you kiss me by the way?” She looked away, feeling a little annoyed he kissed her.`}</p>
    <p>{`Dimitri cocked his head to the left, “A mermaid's kiss grants a person the ability to temporarily breathe underwater,” he said, as if it was the most obvious thing in the world.`}</p>
    <p>{`“Dimitri!” A deep voice called out before she could say anything else, they both turned to see Dedue jogging towards them.`}</p>
    <p>{`Byleth tried her best to shield Dimitri from view, since she didn’t know who or what knew his secrets.`}</p>
    <p>{`Dimitri placed a hand on her shoulder, she looked over at him and he nodded at her. “It’s okay.” She hesitated but he nodded again. She glanced at his tail, only to find it replaced by sizable human male reproductive organs, Byleth couldn’t help but stare in horror.`}</p>
    <p>{`But Dimitri merely frowned at her.`}</p>
    <p>{`Dedue cleared his throat, “Dimitri,” he said, gesturing at the exposed area. Dimitri looked down and probably realised his nakedness as he quickly dove back down, then resurfaced a few seconds later flinging his wet clothes onto dry land.`}</p>
    <p>{`Byleth too quickly covered her chest as she felt exposed and she was right; her shirt was clinging to her torso.`}</p>
    <p>{`Dedue unbuttoned his shirt and draped it over her shoulders, she gracefully accepted even though she felt bad it was going to get wet now.`}</p>
    <p>{`“Dimitri, are you alright?” Dedue asked, it was probably the first time she ever heard him speak.`}</p>
    <p>{`“I am fine,” He replied.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“I saw you by chance from the balcony, I am not sure anyone else did. Had I known you had taken alcohol I would’ve brought you here immediately. Please rely on me more.” It was definitely the first time she ever heard Dedue talk so much.`}</p>
    <p>{`“Thank you, but I think I’ll go home now.” Feeling overwhelmed by almost everything. She removed Dedue’s now wet shirt and handed it back to him. “Sorry about your shirt, but also thank you.”`}</p>
    <p>{`“Wait!” Dimitri said, hopping into his pants and hastily buttoning it. “The dormitories are too far, you will get sick.”`}</p>
    <p>{`“It’s fine,” She replied and headed for the elevator, pulling her phone out to call her brother.`}</p>
    <p>{`Dimitri took her hand, “I would...greatly appreciate it if you c-could keep this matter to yourself.”`}</p>
    <p>{`Byleth nodded, “It’s frustrating, but you have my word.”`}</p>
    <p>{`Dimitri let out a sigh of relief, “Thank you. But I...um insist on accompanying you.”`}</p>
    <p>{`She looked straight into his eyes and nodded. “I have...so many questions…” She said again, squeezing her temples with one hand, keeping the other one over her chest.`}</p>
    <p>{`Just as she was about to resume what she was doing, blackness started swimming in her eyes and Byleth leaned on the nearest wall for support but slowly, her mind drifted into darkness as her legs gave way.`}</p>
    <Divider mdxType="Divider" />
    <p>{`Dimitri caught her just as she went limp against the wall, her soft, wet skin cold
and clammy against his own warmth. He scooped her up into his arms with little effort,
carried her over to a nearby bench and gingerly set her down.`}</p>
    <p>{`He managed to procure a towel from the pool area and draped it over her, replacing Dedue’s shirt while said male had gone to fetch her brother.`}</p>
    <p>{`Dimitri took a minute to reflect on what had just happened and wondered if it was the Will of the Goddess that someone came to rescue him. He was `}<em parentName="p">{`sure`}</em>{` he was about to die of dehydration had she not come at that exact moment.`}</p>
    <p>{`He picked up his shirt and wrung it outside, it didn’t really matter to him whether he was clothed or not since this was pretty much his natural state, even in the dark cold depths of the sea; his home. Dimitri awkwardly adjusted the groin area of his trousers. To him, wearing pants and underwear was definitely uncomfortable and something he was still getting used to.`}</p>
    <p>{`He turned back and looked at her, something she said puzzled him.`}</p>
    <Ads mdxType="Ads" />
    <p>{`She said he was rude and he was quite taken aback at that. Was this not how humans interacted? His true personality and the one he exhibited to his new peers were completely different, yes, but he had been shadowing Felix for months, surely Felix was at the forefront of human interaction?`}</p>
    <p>{`Granted, Dimitri had to admit Felix did seem reluctant to do this since he didn’t seem to have much say in the matter, and probably a little bit more grumpy than, say, the ‘chattier’ ones like Ashe or Claude. But then again, people like Byleth, Reus and that chap, Lindhart, were much more similar in disposition to Felix too. Albeit less angry.`}</p>
    <p>{`He crossed his arms, unrest filling his chest. He didn’t know what to feel or think. Adapting to human life and living on land was difficult. Learning about ‘technology’ and walking everywhere was tiring and `}<em parentName="p">{`painful`}</em>{`, since he tripped and fell a lot. Computers and phones were complicated but it definitely made life a lot easier.`}</p>
    <p>{`But he was telling the truth, he wasn’t used to human interaction, life at the bottom of the ocean was already difficult without all this technology and having the ‘media’ hound him about his ‘amazing’ swimming skills was downright `}<em parentName="p">{`terrifying`}</em>{`.`}</p>
    <p>{`He wasn’t as powerful of a swimmer with legs, however to him and the other merfolk, swimming was like walking for humans, all the swimming he did was merely second nature to him.`}</p>
    <p>{`Dimitri turned around to check on Byleth, she stirred a little but still hadn’t gained consciousness, he sighed. Sure mermaids like him could talk underwater to each other, but...honestly, he literally didn’t have much, if any interactions with humans at all, apart from his mother who lived on land of course, however that was years ago.`}</p>
    <p>{`‘Mermaids and humans are more similar than you think, son. Don’t be afraid.’ - his late human mother said to him when he was young, still scared and shy of the world...Which if he was going to admit, he was still exactly the same.`}</p>
    <p>{`He continued to observe her, their basic anatomy was the same, well, her upper half was. Mermaids or merfolk, didn’t evolve much since ‘the Fall’, as they had been reproducing with humans for the past millennia, which was why mermaids still spoke the same language as the humans on this particular continent, although it didn’t make it any easier to communicate.`}</p>
    <p>{`He squeezed his head, of course humans and mermaids were more alike than he thought. Even though their biology changed to adapt to living in the ocean, his community still lived predominantly human lives, like most of his community lived on land once they reached sexual maturity, even if they mainly kept to each other most of the time. Still, this was a whole different culture. Especially for him, since he was more a recluse than the others.`}</p>
    <p>{`“You idiot, what the heck did you do this time?” Felix hissed as he stomped over from the stairwell, he must’ve ran down the stairs since he was panting.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Dimitri backed up as the shorter male pointed an accusatory finger at him and he toppled backwards on his feet, “I saw Dedue and Reus-”`}</p>
    <p>{`Dimitri scrambled to his feet when Byleth stirred again, this time her eyes fluttered open and she slowly pushed herself up, the towel falling to the ground as she did.`}</p>
    <p>{`She groaned, clutching her head. “Felix?” she asked through squinted eyes.`}</p>
    <p>{`Felix sighed, “Yeah, your brother is on the way here. Best think of an excuse of why you’re in this state.”`}</p>
    <p>{`She frowned, then seemingly realised she was wet and quickly covered herself with the fallen towel. Just as she did, Reus and Dedue emerged from the elevator.`}</p>
    <p>{`“Hey By, you okay? Dedue told me to bring a jacket but-” Reus stopped and eyed her, “Why are you wet?” Her brother said, looking at them.`}</p>
    <p>{`“I uh...fell into the pool and Dimitri rescued me,” she finally replied after a pause.`}</p>
    <p>{`Reus’s face was indifferent as he handed her the jacket, “You know you can’t swim yet, what were you doing by the pool? I thought you went to pee?”`}</p>
    <p>{`She shrugged, “Wanted to check it out but I slipped by the deep end.” Her demeanor was calm despite needing to lie to her twin brother for him...Dimitri felt bad. He had been very irresponsible tonight.`}</p>
    <p>{`“Thanks, I guess.” Reus turned to him, rubbing the back of his neck. “I’ll take it from here, can you guys tell Claude we left?” Dimtiri nodded.`}</p>
    <p>{`“I’m okay,” Byleth said when Reus offered his arm. As they left, Byleth stole a quick glance at him, and it almost seemed like she was...frowning at him. He couldn’t tell, since her expression was often more apathetic than expressive.`}</p>
    <p>{`“She knows, doesn’t she?” Felix said exasperatedly when the twins were out of sight.`}</p>
    <p>{`Dimitri let out a sigh and nodded again.`}</p>
    <p>{`“Sweet Goddess,” Felix groaned and rolled his eyes.`}</p>
    <p>{`“Please explain what happened, Your Highness,” Dedue said calmly. His loyal vassal only called him Your Highness in private, something Dimitri said he didn’t need to do but Dedue insisted.`}</p>
    <p>{`Dimitri sat down on the bench and recounted what happened, he was thrilled yet scared at attending a party, and since he was only a human for a short period of time to fulfil his ‘mission’, he wanted to try the fabled alcohol ‘beer’, that all humans seem to love so much.`}</p>
    <p>{`“What kind of a special idiot are you? `}<em parentName="p">{`You know`}</em>{` alcohol is fatal to mermaids,” Felix whispered angrily.`}</p>
    <p>{`Dimitri stood still, allowing himself to take Felix’s anger as he deserved it, “I didn’t think it was true,” he said quietly.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“You didn’t-” Felix stepped forward looking as though he was about to strangle him, but stopped, “Goddess,” Felix cursed.`}</p>
    <p>{`“We apologize for the inconvenience caused, Felix,” Dedue said with a small bow.`}</p>
    <p>{`“You—” Felix jabbed a slightly trembling finger into his chest, while absolutely seething, “—Are extremely lucky, you know that? Because Byleth is probably the most trustworthy person I know. She’ll take your secret to the grave.”`}</p>
    <p>{`“Ugh, why did I agree to this,” Felix mumbled, one hand on his hip and the other ruffling his hair. “How are we going to fix this? This wasn’t supposed to happen. You weren’t supposed to expose your secret to another human unless it was your future wife.”`}</p>
    <p>{`Again, Dimitri couldn’t help but feel maybe it was the Will of the Goddess that it was Byleth who found him like that. Although Dimitri did feel bad for causing trouble like that. “Sorry, I have no excuse for what I did,” he murmured.`}</p>
    <p>{`“I don’t know, just let me think of what to do about this. We might be able to use this to help you.”`}</p>
    <p>{`One thing Dimitri did know though, was that he was never, ever going near alcohol again.`}</p>
    <Divider mdxType="Divider" />
    <p>{`The weekend went by feverishly for Byleth. On the following Monday morning, as she
laid in bed after Reus’s alarm woke her up, she wasn’t sure if what happened that
Friday night was real. Maybe she really did fall in the pool on her own accord and
nearly drowned, and hallucinated a mermaid since Dimitri was such an amazing swimmer-`}</p>
    <p><em parentName="p">{`Ohh...so that’s why he swims `}<strong parentName="em">{`so`}</strong>{` well. He’s a mermaid. Merman-`}</em></p>
    <p>{`She shook her head. She didn’t know what reality was anymore.`}</p>
    <p>{`Her entire Sunday had been spent in bed. She felt fine, apart from the fact that Dimitri had stolen her first kiss. Maybe technically it didn’t count because it was with a mermaid?`}</p>
    <p>{`Either way she was glued to her laptop in bed, researching myths and legends about mermaids when she stumbled upon the Faerghus National Museum website and its exhibits. It stirred a deep-seeded memory that had been hidden for years within her mind, it was blurry, like replaying a jerky VHS tape, but she had flashes of her and Reus somewhere watching a documentary about mermaids.`}</p>
    <p><em parentName="p">{`‘One of our most popular exhibits, this gallery has been on display since the inception of the museum. We have an impressive collection of artefacts, relics and even a replica skeleton.`}</em></p>
    <p><em parentName="p">{`Discover the legend that happened over a thousand years ago, and see the myth come to life in our Mermaid Gallery.’`}</em></p>
    <Ads mdxType="Ads" />
    <p>{`The pictures on the website had kept flashing in her mind even while she was sleeping, it had been a restless night.`}</p>
    <p>{`“You’re not going back to sleep?” Reus asked as he zipped up his jersey. The time was 5.15am.`}</p>
    <p>{`She shook her head, her eyes wide open.`}</p>
    <p>{`“You’ve been weird ever since the party, you sure you don’t have a fever or something?” Reus came over to her bed and placed his large hand over her forehead. “Hrm, seems not.”`}</p>
    <p>{`“I’m fine,” she replied flatly.`}</p>
    <p>{`He shrugged. “See you for lunch?” She grunted her response. “Alright, bye.”`}</p>
    <p>{`As soon as the door shut, she sat upright in bed, threw the blanket aside and went straight to her desk. She had to see the museum exhibit, but it was back home in Faerghus. Byleth leaned back in the chair, scratching her chin in thought.`}</p>
    <p>{`It had been a couple of months since they went back, but she kind of had no reason to go home suddenly. What with Reus’s tournament on the horizon? She let out a long exhale.`}</p>
    <p>{`As if she was on auto-pilot, she went back to the last tab she was on; the museum website, and mindlessly started clicking around. A good 30 minutes later after getting caught up reading all the various tidbits around the website, Byleth was about to give up and try going back to sleep when she saw something that immediately perked her up again.`}</p>
    <p><em parentName="p">{`‘Areadbhar was originally wielded by Blaiddyd, one of the Ten Elites who fought in the War of Heroes. The weapon releases its full power when wielded by a bearer of the Crest of Blaiddyd. The original was lost when King Dimitri I fell in battle over a millenia ago.`}</em></p>
    <p><em parentName="p">{`New evidence has been found by Dr. Glenn Fraldarius, one of our researchers, and thanks to this new found document, we have successfully created a life size replica of the fabled Areadbhar.’`}</em></p>
    <p>{`“Fraldarius…” Byleth leaned back on her chair again, crossing her legs as she tapped her chin trying to recall where she knew that name from. She squeezed her eyes shut, focusing hard as it was on the tip of her mind, it was `}<em parentName="p">{`just`}</em>{` there...`}<em parentName="p">{`Oh!`}</em></p>
    <p>{`She quickly reached for her phone and quickly sent a message before the thought ran away.`}</p>
    <MessageBox contactNumber="Felix" items={[{
      timestamp: {
        day: 'Monday',
        time: '05:47AM'
      },
      right: '<p>Hey</p>'
    }, {
      right: '<p>your surname is fraldarius right?</p>'
    }, {
      right: '<p>do you have a relative called Dr. Glenn Fraldrius who works in the faerghus national museum?</p>'
    }, {
      seen_status: 'Sent',
      right: '<p>or is it just another common surname?</p>'
    }]} mdxType="MessageBox" />
    <p>{`“Phew,” she sighed, and put her laptop to sleep before she herself went back to sleep. If it all turned out well, she and Felix could take a quick day-trip back to Faerghus to do some research for their assignment. Since this was a new discovery, it was highly unlikely they could find other journals online at the moment. She finally drifted off to sleep, just in time to get a quick nap in before her class at 9am.`}</p>
    <p>{`But her alarmed buzzed at 8am sharp, she was about to snooze it and go back to sleep but she caught a glance at her phone; Felix had replied.`}</p>
    <p>{`At first, she could barely open her eyes since the bright light from her phone was attacking her, but after reading it, she was instantly awake, excited that her plan might `}<em parentName="p">{`actually`}</em>{` work out after all.`}</p>
    <MessageBox contactNumber={'Felix'} items={[{
      timestamp: {
        day: 'Monday',
        time: '05:47AM'
      },
      right: '<p>Hey</p>'
    }, {
      right: '<p>Your surname is fraldarius right?</p>'
    }, {
      right: '<p>do you have a relative called Dr. Glenn Fraldrius who works in the faerghus national museum?</p>'
    }, {
      right: '<p>Or is it just another common surname?</p>'
    }, {
      timestamp: {
        day: 'Monday',
        time: '07:35AM'
      },
      left: '<p>yeah</p>'
    }, {
      left: "<p>he's my brother</p>"
    }, {
      left: '<p>why</p>'
    }, {
      right: '<p>I’ll tell you in class, don’t get mad.</p>'
    }, {
      left: '<p>🙄</p>'
    }]} mdxType="MessageBox" />
    <Divider mdxType="Divider" />
    <p>{`“Hey Felix,” Byleth greeted the most grumpy looking person in the lecture hall, on his right was Dimitri, fresh out of the pool with his arms crossed across his broad chest, face in a slight grimace. All the memories from `}<em parentName="p">{`that night`}</em>{` hit her like a truck, and she nearly blushed looking at him, “Oh...Hi…” Byleth she said meekly. Dimitri looked away and grunted.`}</p>
    <p>{`Reus didn’t take the same course as her, but they had the same subjects occasionally. It didn’t hit her at first but Dimitri and even Dedue (who was sitting behind them) really did have the same classes as Felix. Maybe Felix knew as well?`}</p>
    <Ads mdxType="Ads" />
    <p>{`“-leth.”`}</p>
    <p>{`Is that why Dimitri is staying with Felix? Oh Goddess, is Felix a mermaid-`}</p>
    <p>{`“Byleth,” Felix said loudly as he nudged her. She turned to see a scowl staring at her.`}</p>
    <p>{`“Sorry.” She cleared her throat, “So uh...that Dr. Glenn is your brother?”`}</p>
    <p>{`“Yeah, why?”`}</p>
    <p>{`“Do you think...you could uh...arrange for us to analyse the new Areadbhar replica? It would give us a huge boost in marks, you know,” she said carefully, observing the intensity of his scowls to see what sort of mood he was in.`}</p>
    <p>{`Felix pulled out the lecture notes for the class a little aggressively, “I don’t really want to,” he said flatly. The scowl meter was off the charts apparently.`}</p>
    <p>{`Byleth was speechless and blinked at him.`}</p>
    <p>{`Felix squeezed his temples, “I don’t have the best relationship with him at the moment okay? He’s a great brother but ugh, my dad is obsessed with him.”`}</p>
    <p>{`She nodded. Ah, the classic favourite child problem. “I see. I guess it can’t be helped then,” she shrugged, “Thanks anyway.”`}</p>
    <p>{`They all sat in silence while waiting for the lecture to start, Professor Hanneman was one of the more knowledgeable ones but also he could be incredibly long-winded.`}</p>
    <p>{`Byleth gave herself a good stretch as soon as the class was dismissed when Felix stood up next to her and said, “What are you up to?”`}</p>
    <p>{`She looked up at him, lifted the table up and stood as well, “I was just planning to go back home this weekend and thought I might as well check out the exhibit,” she explained, “I thought if your brother could give us a more indepth look at the materials, it would’ve been nice. But I don’t like to impose.”`}</p>
    <p>{`Felix stayed quiet, merely glaring at her, which was just his normal facial expression, “I’ll get Ingrid to do it.” And he left, just like that. Why Ingrid, though?`}</p>
    <p>{`But as she was about to exit the door, Felix pulled her aside with Dimitri and Dedue listening in as well, “If we go though, these two have to come.”`}</p>
    <p>{`She nodded, it made no difference to her. “Sure.”`}</p>
    <p>{`He didn’t say anything, just scrutinizing her response. He turned to look at Dimitri who nodded at Felix.`}</p>
    <p>{`Felix then looked around, making sure the coast was somewhat clear, save for the few students passing by. He groaned lightly, “I know what happened that night.”`}</p>
    <Ads mdxType="Ads" />
    <p>{`“What night?” she feigned, throwing a side glance to Dimitri, but his expression didn’t say much. Just the same grimace he was wearing earlier.`}</p>
    <p>{`“That night, at the party,” Felis whispered, his voice a little more impatient than usual.`}</p>
    <p>{`She froze a little, was he testing her? “Um, yeah I...fell into the pool.”`}</p>
    <p>{`“Yes, to save this idiot,” He grabbed Dimitri and dragged him to the front of both of them.`}</p>
    <p>{`“W-What?” She inched away from Felix, who was pushing a very uncomfortable looking Dimitri at her, “No, it was the other way-” An extremely loud rumble erupted from her stomach.`}</p>
    <p>{`“Yeah, um. I’ll go get some lunch. Let me know how it goes with your brother.” She turned on her heel before he could say anything else and did her ‘fast-walk’ to the cafeteria. Felix definitely knows that she knows about Dimitri. But she swore to Dimitri she wouldn’t say anything to anyone regardless.`}</p>
    <p>{`It was only 11.30am, Reus normally met up with her at about 12pm, so she had some time for herself, and the queue was relatively short at this hour. She dropped her bag on the chair and herself, and eyed the huge tray of food before her. While she had great metabolism, the food was starting to pile onto her, her thighs and arms were getting flabbier and her stomach too. In a different life, she would have probably been a pretty good warrior with this appetite.`}</p>
    <p>{`Her stomach roared again and she quickly started on her meal to appease the demon. Again, all she could think about was mermaids. And it was as if there were mermaids everywhere now. She didn’t notice it before, but the stained glass windows around school had depictions of them. Even some of the doors had carvings of them.`}</p>
    <p>{`She jumped a little when Felix set down his tray crossly next to her, along with Dimitri and Dedue who quietly followed suit. Was that fish on Dimitri’s plate? Mermaids ate fish? Did it count as cannibalism?`}</p>
    <p>{`She looked up at Felix who was making eye contact at Dimitri and angrily mouthing something at him, Dimitri merely nodded.`}</p>
    <p>{`“Felix knows, Byleth,” Dimitri whispered. “He’s been my...tutor for human studies.”`}</p>
    <p>{`She blinked at him...tutor...It took her a few seconds but it finally made sense. `}<em parentName="p">{`This`}</em>{` is why Dimitri talks and acts the way he does; he’s copying Felix.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Byleth set her spoon down, “Could you be more specific about what he knows? Just so we’re on the same page?”`}</p>
    <p>{`Without a smile on his face, sporting his normal frown, Dimitri leaned in and whispered in her ear, “He knows I’m a mermaid.”`}</p>
    <p>{`She quickly pulled back and nodded, “Okay.”`}</p>
    <p>{`“So..you were saying Dimitri and Dedue have to follow us if we go to the museum because...you’re their-” she waved a hand looking for the right word, “-babysitter?”`}</p>
    <p>{`Felix snorted, “Yes.”`}</p>
    <p>{`“I mean, they don’t need to come? If they don’t want to-”`}</p>
    <p>{`“No, they have to. Their whole purpose is to adapt to human society, the more exposure they have the better. Which is why we went for that stupid party,” Felix said, then took a bite of his food. “Which turned out to be a bad idea because `}<em parentName="p">{`some idiot`}</em>{` here decided to get himself a little drunk.”`}</p>
    <p>{`Dimitri cleared his throat at the accusation while awkwardly feeding himself.`}</p>
    <p>{`She looked around them to see if anyone was nearby before talking, “Can I ask why they need to adapt? Is there even a need to...um,..come onto land?”`}</p>
    <p>{`Felix smirked, not a happy looking smirk, but a rather sinister looking one, “Go on,” he gestured at Dimitri, “Tell her.”`}</p>
    <p>{`Dimitri shook his head violently, glaring at Felix, “No.”`}</p>
    <p>{`Felix shrugged, “Suit yourself. It’s your loss. I personally think she could benefit you more than you think.”`}</p>
    <p>{`Dimitri appeared to be in deep thought as he gripped the fork in his hand. Before her eyes, the fork in his hand bent...almost as though it was a flimsy plastic straw. She raised her eyes and almost gawked at the sight in front of her.`}</p>
    <p>{`“Sir,” Dedue said, placing a hand over Dimitri’s fist.`}</p>
    <p>{`“He does that a lot,” Felix said nonchalantly.`}</p>
    <p>{`“Are all mermaids like him?” Byleth asked quietly, a little alarmed, Felix shook his head.`}</p>
    <p>{`“Just him. He’s special,” he added, “You know how he sometimes slams books on the table in the library?” She nodded, remembering the times he gave her a nasty fright by doing so.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“That’s because he’s just an idiot who can’t control his strength,” Felix explained, “Go on, Dimitri.”`}</p>
    <p>{`Dimitri took a deep breath and set down the disfigured cutlery, “My purpose here...is to...find  a mate.” This time she actually gawked at him, she slapped a hand over her mouth to suppress a laugh even.`}</p>
    <p>{`“Are you laughing?” Felix gave her a dirty look, “You’re terrible.” He shook his head.`}</p>
    <p>{`“I’m sorry, I...wasn’t expecting that at all. Sorry, please continue,” she immediately calmed herself but the word ‘mate’ lingered in her head.`}</p>
    <p>{`Dimitri continued, “Felix’s family, the Fraldarius's, have been our human custodians for centuries. Overseeing all our needs whenever we’re on land.”`}</p>
    <p>{`Byleth resumed eating her lunch as though she was eating popcorn at a movie theater, nodding every so often in reply.`}</p>
    <p>{`“Dedue was assigned to me every since I was born, and thus accompanies me everywhere,” Dimitri said after a short pause.`}</p>
    <p>{`“You really are special,” she murmured, being a mermaid was already extremely unique but it seems Dimitri was extra special.`}</p>
    <p>{`“Because His Highness is the Crown Prince of our merfolk,” Dedue said and Byleth spat out her drink back into its cup. “He is the last of his line, and it is imperative he reproduces.” She choked as she dabbed her mouth with a tissue.`}</p>
    <p>{`As she tried to stop coughing, “First, do you not `}<em parentName="p">{`-cough-`}</em>{` mate with `}<em parentName="p">{`-cough-`}</em>{` other mermaids?” she slapped her chest to ease the rasping before people started looking at her. Felix reluctantly patted her back but she pushed his hand away since she was fine, thanking him.`}</p>
    <p>{`Dimitri and Dedue shook their heads to her question.`}</p>
    <p>{`“Second,” she wheezed, “Why are you telling me this?”`}</p>
    <p>{`“Because you are now his new human studies teacher,” Felix concluded.`}</p>
    <p>{`“What?” Both Byleth and Dimitri said at the same time.`}</p>
    <p>{`“Felix, that was not why I agreed to tell her,” Dimitri said to Felix, despite the bird's nest that was Dimitri’s hair, she could see his eyebrows were knitted in apparent irritation.`}</p>
    <p>{`“Really?” Felix mocked and leaned back on his chair, “Do tell us why then.”`}</p>
    <p>{`The frown on Dimitri’s forehead slowly dissipated, “I merely thought it unfair to keep her in the dark, since she already discovered it, it only felt right to tell her my t-true purpose.” It was the first time she ever heard Dimitri speak so...softly, it made her chest feel tight. Even the other night he was speaking very monotonously. So different from the angst-y, angry Dimitri.`}</p>
    <p>{`“I believe the museum is the right place to start with the...erm.. truth, it depicts our history rather accurately,” Dimitri said as-a-matter-of-factly. Byleth couldn’t wrap her head around this version of Dimitri that was in front of her.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Byleth looked away, “Actually, that was the main reason why I wanted to go, to read more about mermaids,” she confessed, “I needed a reason so it didn’t look too suspicious. But honestly we do need the extra data if we’re going to impress Professor Seteth. Everything else has been done before.”`}</p>
    <p>{`Felix groaned and shook his head, “I hate that you’re right, and I hate it even more that I have to rely on my connections for this.”`}</p>
    <p>{`“I still don’t understand something,” Byleth held a hand up, “Why are you guys insisting I know about this? What was wrong with keeping me in the dark? I mean I’m nobody.”`}</p>
    <p>{`“Prince Charming here has his own righteous reasons because you saved him, while I’m just trying to pawn him off onto you,” Felix said.`}</p>
    <p>{`“But why?” She asked.`}</p>
    <p>{`“Look.” Felix squeezed the crease between his eyes, “Glenn was supposed to be his...babysitter but my dad decided that a university environment would be much better for him.” She slowly nodded.`}</p>
    <p>{`“That..`}<em parentName="p">{`.ugh`}</em>{`, I can’t believe I’m saying this outloud but my dad, and apparently his dad, thought the chances of him finding a mate would be higher here,” Felix said with a shudder. “Hence why I’m stuck with him.”`}</p>
    <p>{`She sighed, “Why me?”`}</p>
    <p>{`“Unfortunately, your fate was sealed the moment you decided to help him. It’s ridiculous how convenient this is.” Felix almost looked pleased.`}</p>
    <p>{`Byleth threw her hands up, “I didn’t know he was a dying mermaid at the time!” She hissed.`}</p>
    <p>{`“Hey, you’ve been my assignment partner for almost a year, you know I’m not interested, nor am I the best person to coddle and babysit a person.” Felix growled. “Look how he turned out.” He nodded at Dimitri.`}</p>
    <p>{`“I’ve turned Prince Charming here into Grumpy from that cartoon,” he said exasperatedly. “Even I can tell he’s chasing away his prospective brides.” Felix rolled his eyes.`}</p>
    <p>{`Panic rose in her chest, she wasn’t sure if she was the right person. She herself was a hardcore introvert, “Well but-”`}</p>
    <p>{`“Hey guys,” A voice made all four of them jump. Byleth looked up to see her twin brother standing at their table.`}</p>
    <p>{`She placed a hand on her chest and slapped him, “You scared me.”`}</p>
    <p>{`Reus raised an eyebrow, “That’s rare.”`}</p>
    <p>{`Felix stood up, “This weekend,” he reminded her, “I’ll text you the details.”`}</p>
    <p>{`“Wha-But I didn’t say yes?” Byleth said.`}</p>
    <p>{`“Say yes to what?” Reus asked as he placed his bag down where Felix sat.`}</p>
    <p>{`Felix grunted, “We’re going back to Faerghus for research.”`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Who’s we?” Reus placed a hand on his hips.`}</p>
    <p>{`“The four of us,” Felix replied flatly.`}</p>
    <p>{`“Am I invited?” Reus added, “It would be nice to go home.”`}</p>
    <p>{`“Reus, you can’t, you have practice for the tournament,” She said as-a-matter-of-factly.`}</p>
    <p>{`Reus scoffed, “Dimitri has practice too, you know.”`}</p>
    <p>{`“He’s sick,” Felix said.`}</p>
    <p>{`“I am?” Dimitri chipped in. Looking rather shocked.`}</p>
    <p>{`“He is?” Reus looked utterly confused. “He was fine this morning?”`}</p>
    <p>{`“Ugh, just stay out of this Eisner. It’s...an assignment thing.” Felix groaned and dragged Dimitri and Dedue away along with their food trays.`}</p>
    <p>{`Reus stared after them, “What was that about?”`}</p>
    <p>{`Byleth leaned back on her chair and closed her eyes, “Don’t ask.”`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      