import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const RemoteLink = makeShortcode("RemoteLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I’ve wanted to write this blog post series for a while now, but I haven’t got the slightest clue on how I should start and what to write in it. I needed a concrete starting point so readers can get a sense of what I’m trying to convey in a post. If I keep rambling, then this blog will be my personal diary! I am of the opinion that blog posts should be a give and take - I write content that I like writing, and the readers get to read a post that is interesting to them.Part of the reason why I think that `}<em parentName="p">{`Top X Manga and Anime`}</em>{` posts are actually great posts to write about. Readers went in with a good feeling of what to expect, and the writer themself are given a clear idea on what to write. Both enjoyed the final outcome. It’s a win-win!`}</p>
    <Ads mdxType="Ads" />
    <p>{`And thus, this is why I struggle a lot in my blog posts. My fellow bloggers would often write episode reviews for an anime, casting out their thoughts on certain plot points or even writing super fun `}<em parentName="p">{`What if`}</em>{` posts. These are seriously great types of posts to write about! If you haven’t tried writing one, do consider doing it ASAP. Anyway, the takeaway here is that `}<strong parentName="p">{`everyone`}</strong>{` has a clear idea on `}<em parentName="p">{`what`}</em>{` to write. I, on the other hand, do not have that clear clarity in every post I type.I have never considered my posts as reviews unless I specifically stated so in the title. So what exactly have I been writing all this while?! Which is why I wanted to try my hands on a blog post series with a specific format in mind. I call this series `}<strong parentName="p">{`Author Spotlight`}</strong>{` because this post is what it is - I enjoyed said author’s works so much, I have already pinned them down as Author X’s work instead of referring to the stories with their names in my head.`}</p>
    <p>{`I will first talk about the author, followed by the stories the author has written (and I have read) that I really enjoyed. The author’s work could be anime, manga, or even light novels. To be fair, since most anime are based on manga and light novels, this series will be more on those. It’s nothing fancy but I hope you enjoy this blog post that you considered trying out the series mentioned here! <3`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Who is Yu Wo?`}</h2>
    <p>{`My feelings towards Yu Wo’s work is a mix of jubilation and amazement. Both works I read by her first started out as a comedy, before delving into the more serious aspects of the plot’s adventure. Her plot twists are really simple to follow, making her works really fun to read on a free weekend. Her style of comedy is something I’m really fond of! The comedy in both ½ Prince and The Legend of Sun Knight relies heavily on being stupidly straightforward and silly. If you enjoyed Stephen Chow’s movies like Kungfu Hustle and Shaolin Soccer thanks to their silly comedy, then you’ll definitely enjoy what Yu Wo did here.`}</p>
    <Image alt="Yu Wo, author of The Legend of Sun Knight and 1/2 Prince." caption="This is Yu Wo with her published works. Bless you Yu Wo." name="yu-wo-author-geeknabe.jpg" mdxType="Image" />
    <p>{`Yu Wo (御我) is a `}<RemoteLink to="https://taiwantoday.tw/news.php?unit=18,23,45,18&post=24494" mdxType="RemoteLink">{`Taiwanese light novelist`}</RemoteLink>{` born in 1984. She loves reading light novels online so much that she began writing light novels earnestly herself. Since many stories online have male protagonists with female sidekicks, Yu Wo wishes to flip this around. So she began writing stories with a female lead. That is how ½ Prince is born! She first posted her chapters online before she got noticed by a publisher in 2004. Not long after that, ½ Prince even got a manhua!`}</p>
    <Ads mdxType="Ads" />
    <p>{`Each of her works are really `}<strong parentName="p">{`successful `}</strong>{`and when I say successful, I mean getting them published as a light novel and receiving a manhua. Her most popular work is definitely ½ Prince, followed by The Legend of Sun Knight. She wrote other stories as well but I haven’t had the time to go check them out. I heard some great reviews about her `}<em parentName="p">{`Kill No More `}</em>{`series! Here is `}<RemoteLink to="https://blog.xuite.net/kim1984429/yuwo" mdxType="RemoteLink">{`Yu Wo’s blog`}</RemoteLink>{`.`}</p>
    <h2>{`½ Prince`}</h2>
    <p>{`I first started reading this series’s light novel as a fan translation back in 2010. That’s a decade ago! The light novel fan translation scene is brimming with life back then. There’s no site that indexes everyone’s work so you can `}<em parentName="p">{`browse`}</em>{` `}<em parentName="p">{`and`}</em>{` `}<em parentName="p">{`discover`}</em>{` new light novels to read, so fans rely on `}<strong parentName="p">{`word of mouth`}</strong>{` or `}<strong parentName="p">{`forums`}</strong>{` to look for them. Everyone knew about Baka-Tsuki but no one really knew about `}<RemoteLink to="https://www.princerevolution.org/" mdxType="RemoteLink"><em parentName="p">{`Prince Revolution!`}</em></RemoteLink>{`. They do great work on translating whimsical Chinese sentences to fluid English. Kudos guys. I’ll be using some panels from the manhua to give you guys a better look at what to expect from ½ Prince.`}</p>
    <Image alt="1/2 Prince's light novel cover for volume 1" caption="1/2 Prince's light novel cover for volume 1." name="1-2-prince-light-novel-cover-geeknabe.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <p>{`½ Prince is set in 2100 AD. Virtual reality is perfected to the point that gamers can dive into a virtual world to play online games! All they need to do is wear a game helmet and sleep while connected to the Internet. The best realism level of online games so far is 85% but things began to change with a new game on the horizon - `}<strong parentName="p">{`Second Life. `}</strong>{`With a realism level of 99%, Second Life soon took control of the market. This game is where Feng Lan began the legend of the most famous player known globally - ½ Prince.Feng Lan is our MC in this story. It all began when she boasted about her mage equipment to her younger twin brother, Feng Wu Qing in an online game. Her brother scoffed at her, saying girls have it easy in MMO games. Everyone would clamour to give them free money and equipment without the girls playing the game for real! This statement of his doesn’t sit well with Feng Lan so she decided to game honestly in Second Life.`}</p>
    <Image alt="Feng Lan and Feng Wu Qing, twins in 1/2 Prince." caption="Feng Lan obviously wasn't going to take the insult lightly." name="1-2-prince-manga-panel-1.jpg" mdxType="Image" />
    <p>{`When she first booted the game, she was given a plethora of choices to customize her character. Second Life dictates that each player can only have 1 character tied to their account, and the gender must follow the player’s original sex. Since she couldn’t swap out her character, Feng Lan requests that her character be a male instead. She wanted to prove that she too, can play the game without relying on her gender! Her request was approved and the GMs even gave her a bonus for her character. This is how ½ Prince came about - a girl gamer playing as a hot male elf warrior (lol) named Prince. The ½ in the title refers to Feng Lan being a girl but her character is male.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Lolidragon forcing the name Prince down Feng Lan's throat" caption="This is not an NPC, but a real player. Her name is Lolidragon! She's a crucial player in 1/2 Prince's plot." name="1-2-prince-manga-panel-2.jpg" mdxType="Image" />
    <p>{`We first see Feng Lan landing on the newbie town and getting into trouble before she even took her first step out to the hunting area. Why? Well, it’s because she used the bonus given to her by the GM to beautify her character by 30%! She got chased down by hungry wolves (girl players) everywhere she went until Lolidragon saved her skin. It turns out Lolidragon is the same GM that helped Feng Lan create her princely character. Lolidragon then explained the whole game’s mechanics and the idea behind it - this game’s potential is infinite! They should make use of that and create a legend together in this game. This led to the formation of a 6-player team named Odd Squad which is honestly the `}<em parentName="p">{`best thing`}</em>{` that ever happened in this series.`}</p>
    <p>{`The members of Odd Squad as the name implies, really odd. The leader is Prince, our MC that somehow unlocked her sadistic killing streak in Second Life. She acts and behaves like a man convincingly in the game despite never knowing how to act like one. The other members are: Lolidragon, a thief that loves causing trouble as much as she loves money. Ugly Wolf, a big brother-like beast priest. Doll, a necromancer that loves spouting Sailormoon famous quotes. Yu Lian, a mage who joined the team for an equally odd reason… and Guilastes, he’s `}<em parentName="p">{`gay`}</em>{`. Like, gay gay.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Odd Squad, go!" caption="From the left, Prince, Gui (Short for Guilastes), Doll, Lolidragon, Yu Lian and Ugly Wolf. Odd Squad is a tight-knit gang of friends." name="1-2-prince-manga-panel-3.jpg" mdxType="Image" />
    <p>{`The best parts of this story is honestly how the team members interact with each other. It’s both wholesome and really hilarious to read in the light novel and manhua. There’s something really charming with reading how these characters act and behave. Second Life is a game after all. You’re supposed to have fun while playing a game together with friends! But the core comedy lies with Prince. You see, she is really adamant on `}<strong parentName="p">{`not`}</strong>{` telling her friends and family about her Second Life’s character name because she’s done unspeakable horrors towards them. The list includes: beating up her parents in a game tournament (lmao), kissing her cousin (and many others), and stealing her brother’s love. These are definitely acts you’ll bring to the grave…`}</p>
    <p>{`The beginning of this series is honestly the strongest part of the plot. You see Odd Squad leveling up as a team, and trying the best to win in a tournament. It’s like watching a bunch of friends causing trouble for fun and laughing together. The story began to spin out of the comedy sector and into the serious zone during the second part of the plot. We see Feng Lan struggling with his real and fake persona as more of her friends begin to mix both Prince and Feng Lan together. With romance added to the mix, things get more complicated for poor Feng Lan. Many characters began to realize Prince’s true identity. Some kept it a secret but many felt betrayed. The grand finale moves the setting from Second Life into the real world. I personally feel that the whole setup is a bit silly despite the grandiose reveal. Nevertheless, it makes for a good ending `}<strong parentName="p">{`until`}</strong>{` you realize Yu Wo wrote an extended, unnecessary epilogue.`}</p>
    <Ads mdxType="Ads" />
    <p>{`The epilogue starts off with Feng Lan’s daughter as the MC. I’ll skip out on the details but the core plot here is having Feng Lan’s daughter revisit her mother’s story (albeit a quick tour) in Second Life. You can skip out on reading this part of the story because it ends in a way that does not sit well with the fans (and me as well).All in all, ½ Prince is an enjoyable trip from the start to the end if you skip out the extended epilogue about Feng Lan’s daughter. I like to read this story as Yu Wo’s first foray into writing light novels due to the simplicity in the character’s dialogue, action and plot twists. Her writing and ideas get waaaaay better in The Legend of Sun Knight.`}</p>
    <h2>{`The Legend of Sun Knight`}</h2>
    <p>{`If ½ Prince is all about her female lead, then The Legend of Sun Knight is all about bromance! It’s such a different take from ½ Prince from the summary alone so I wasn’t sure if this is Yu Wo’s jam. The suspicion faded away once I read the first prologue chapter. Yu Wo’s take at comedy got better! While ½ Prince relies a lot on slapstick comedy (Prince beat up Gui a lot lol), but The Legend of Sun Knight has an amazing comedy relying solely on the dialogue’s implied meanings. The first volume alone had chapter titles from a list of rules the Sun Knight must follow. It sounds gibberish at first but it made sense after reading the said chapter.`}</p>
    <Image alt="The Legend of Sun Knight light novel cover" caption="First volume cover for The Legend of Sun Knight. You might notice a ton of art disparity between the light novel and the manhua. Sometimes even promotional items! That's because  Yu Wo never really stated how their full uniform looks like in the novels. At most, only mentions of capes and cloaks, etc. She only mentioned the characters hair colour and pupil at most." name="the-legend-of-sun-knight-light-novel-cover.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <p>{`The Legend of Sun Knight takes place in a fantasy world where gods are believed to be real deities. People who worship these gods do so because the faith’s followers who wield their god’s power exist. There are three religions that are considered the biggest affluent faiths out there - God of Light, God of War and Shadow God. Among the three, faith in God of Light is perhaps the most renowned of them all despite the number of believers diminishing every year. When asked about God of Light, the first thing that comes to mind is the Twelve Holy Knights. Each knight has their own trademark personality and looks but it is their `}<strong parentName="p">{`leader`}</strong>{` who scoops up the most attention - `}<em parentName="p">{`the Sun Knight`}</em>{`. Acting as both the spokesperson for the God of Light and the head of the holy knights, he always smiles, forgives and is kind to everyone. He’s basically the man closest to perfection according to everyone who described him.`}</p>
    <p>{`This story is about the 38th generation Sun Knight, Grisia and his loyal bros who refused to give up on him, the Twelve Holy Knights.`}</p>
    <p>{`I love this story more than ½ Prince because it brings out the strength in Yu Wo’s writing and extends the core plot points that I really enjoyed in her first work - reading up on the cast’s interaction with one another as a tight-knit group. While Odd Squad includes women (and romance) to the main cast, The Legend of Sun Knight consists solely of brotherly friendship. Just to give you an idea, it’s similar to how Final Fantasy XV does it with Noctis and his bros. ½ Prince’s running joke is that Prince is a girl and it’s a secret she has to keep. On the other hand, The Legend of Sun Knights running joke is that these knights’ true personalities are not what the public expects. You can see all of the `}<RemoteLink to="https://www.princerevolution.org/sunknight-main/sunknight-characters/" mdxType="RemoteLink">{`Twelve Holy Knight's biodata here`}</RemoteLink>{`.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Grisia Sun's biodata" caption="Take a gander at Grisia's biodata (lol). It makes no darn sense." name="the-legend-of-sun-knight-light-grisia-biodata.jpg" mdxType="Image" />
    <p>{`You see, The Twelve Holy Knights is a position passed down through the generations. This means that each knight in `}<em parentName="p">{`every`}</em>{` generation to the current one `}<strong parentName="p">{`IS`}</strong>{` a different person in both looks and personality. Unfortunately, the public could never tell the knights’ generation apart (E.g. 37th generation with the 38th generation). After all, they have since labelled each knight for what they are best known for `}<em parentName="p">{`centuries.`}</em>{` The Earth Knight must stutter and is soft-spoken, the Storm Knight must have blue hair, etc. So in order to keep up the facade (and to avoid diminishing their followers further), the Twelve Holy Knights have to maintain their `}<em parentName="p">{`expected personality`}</em>{` and `}<em parentName="p">{`looks`}</em>{` in the eyes of the public.`}</p>
    <p>{`For example, the Sun Knight has an eloquent speech that praises God of Light in every manner possible. His mannerism and action even when killing undead must be `}<em parentName="p">{`beautiful`}</em>{` and `}<em parentName="p">{`perfect.`}</em>{` His hair must be golden and his pupil blue. His skin must be fair and his clothes must stay clean `}<em parentName="p">{`no matter where he is`}</em>{`. He can only drink up to three cups of alcohol before he keels over. The hurdle is so high that it poses a problem when training a young soon-to-be Sun Knight. Duh, you gotta train the next generation while you are still on-duty!`}</p>
    <Image alt="The Legend of Sun Knight's horrendous training begins..." caption="Read this from right to left. You know, Grisia's teacher (ex-Sun Knight/37th Sun Knight) has a point. If you gotta fake it, you gotta practice to make it believable!" name="the-legend-of-sun-knight-manga-panel-1.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <p>{`So how do you `}<strong parentName="p">{`mold`}</strong>{` a child’s personality into what you want them to be? You train them real hard in the arts of falling down, throw them into a ton of dangerous situations (the instructor is watching nearby) and teach them the art of maintaining their skin wherever they are. Not only must they work on their personality, the young page `}<strong parentName="p">{`must know`}</strong>{` how to handle tasks by knights, work on their swordsmanship and magic. Plus, they are expected to commandeer their platoon in times of war. It’s hard work all in all… So all of the Twelve Holy Knights have their own way to handle stress and curb unhappiness as they maintain their expected personality for the public.`}</p>
    <p>{`One of the most hilarious stress-curbing facts is Leaf Knight’s. He is `}<em parentName="p">{`expected`}</em>{` to be an archer with green hair. He is kind and gentle, and would never say `}<em parentName="p">{`No`}</em>{` to anyone’s request. Seriously, blessings be unto the previous Leaf Knight, he chose the right kid to take up the mantle. However, even the `}<em parentName="p">{`real person`}</em>{` behind Leaf Knight has his own limits. If he really, `}<em parentName="p">{`really `}</em>{`couldn’t take it any longer, he would ask for the person’s hair and nails for himself (He usually asks Grisia because he infuriates him the most) to use them for his voodoo doll. No one knows about the exact details behind why he needs the hair strands and nails but at the same time, no one dared to ask why.`}</p>
    <p>{`This series is not all jokes and hardship of these knights! Obviously there’s an evil lurking in the continent… And it is up to the Sun Knight to solve the case. It first started with the Sun Knight hunted down by a powerful undead species called a Death Knight. You can’t exactly create a Death Knight out of nowhere. The corpse from which a Death Knight is born from must have a strong, lingering hate towards a person to revive as a Death Knight. So did the spokesperson of the God of Light actually tortured a person to death? (No, the culprit is someone else.) Solving this case eventually branches out more cases! You’d expect the Sun Knight to solve things honourably but no, not this 38th generation Sun Knight. I have fun reading how he pulled the strings and manipulated people just to get things done. He actually sortied out his platoon of knights to gang up on a bully (lol), can you believe that? And he has ties to a necromancer living `}<strong parentName="p">{`next`}</strong>{` to the church! He’s bff with the necromancer too. Seriously, a knight serving God of Light with ties to necromancy? What the hell.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="TLOSK I'm too lazy to write an alt here" caption="The knights are discussing whether to bust their leader or keep quiet to save their skins..." name="the-legend-of-sun-knight-manga-panel-2.jpg" mdxType="Image" />
    <p>{`The chapters in The Legend of Sun Knight are much longer, dialogues are way more thoughtful, and the characters are fledged out better. It’s such a treat to read this when you realize the story is spread out and much longer than ½ Prince. Each character gets a chance to shine. If that’s not enough, Yu Wo even wrote side stories and spin-offs for other characters! Hnng we do not deserve Yu Wo. The ending for the main story is such a blast to read because it all ties back to what Grisia first spoke of to the Twelve Holy Knights. In the beginning chapters, the story focuses mostly on Grisia’s discovery of his ties to the cases he solved. When the Twelve Holy Knights got into trouble, Grisia would spend all of his effort to help them. At the grand finale, the role was subverted and it was the Twelve Holy Knights that would put in all of their effort and heart to save Grisia. There’s a little plot twist in the end but it’s a heartwarming and happy ending. Such a great series… Hm yes, time to re-read it again.`}</p>
    <h2>{`All in all`}</h2>
    <p>{`I hope you enjoyed reading this as much as I enjoy writing it. Both ½ Prince and The Legend of Sun Knight are completed and fully translated in `}<RemoteLink to="https://www.princerevolution.org/" mdxType="RemoteLink">{`PrinceRevolution!’s blog`}</RemoteLink>{`, including their side stories and spinoffs. Basically a full package! The manhua for ½ Prince is fully scanlated too, but I’m not too sure about The Legend of Sun Knight. I suggest reading the light novel instead for the full experience. I enjoyed ½ Prince’s manhua, but The Legend of Sun Knight’s is wayyy too sparkly. It’s part of the reason why the write-up for Sun Knight has very little images. Have fun reading!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      