import { useEffect, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';

import $ from '../styles/globals';
import UpChevron from '../assets/icons/up-chevron.svg';
import DownChevron from '../assets/icons/down-chevron.svg';
import { on, off } from '../utils';
import atFanfictionChapter from './Navbar/atFanficChapter';

const Container = styled.div`
  background-color: transparent;
  opacity: 0;
  pointer-events: none;
  overflow: hidden;
  transition: opacity 0.5s ease-in-out;
  border: 2px solid ${$.brown5};
  border-radius: ${$.borderRadius.radius2}px;
  right: ${$.layout.margin3}px;
  bottom: ${$.layout.margin3}px;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;

  &.active {
    opacity: 1;
    pointer-events: initial;
  }

  ${$.screen.mobile} {
    right: ${$.layout.margin3}px;
    bottom: ${$.layout.margin3}px;

    > svg {
      width: 24px;
      height: 24px;
      padding: ${$.layout.padding1}px ${$.layout.padding2}px;
    }
  }

  > svg {
    fill: ${$.brown5};
    transition: all 0.2s ease;
    padding: ${$.layout.padding1}px;
    stroke: ${$.brown5};
    width: 28px;
    height: 28px;
  }

  ${$.screen.desktop} {
    > svg {
      &:hover {
        background-color: ${$.brown5};
        cursor: pointer;
        fill: ${$.color.white};
        stroke: ${$.color.white};
      }
    }
  }
`;

const StyledUpChevron = styled(UpChevron)`
  border-bottom: 2px solid ${$.brown5};
`;

const NightMode = createGlobalStyle`
  body.night-mode {
    ${StyledUpChevron} {
      border-bottom: 2px solid ${$.brown1};
    }
    ${Container} {
      border: 2px solid ${$.brown1};
      > svg {
        fill: ${$.brown1};
        stroke: ${$.brown1};
      }
      ${$.screen.desktop} {
        > svg { 
          &:hover {
            background-color: ${$.brown1};
            cursor: pointer;
            fill: ${$.night};
            stroke: ${$.night};
          }
        }
      } 
    }
  }
`;

const BackToTop = () => {
  const [show, setShow] = useState({ state: false, prev: 0 });

  useEffect(() => {
    const scrollCallback = () => {
      const bodyHeight = window.innerHeight * 0.1;

      setShow((prev) => {
        const newPrev = { ...prev };

        return {
          state: window.scrollY >= bodyHeight && newPrev.prev > window.scrollY,
          prev: window.scrollY,
        };
      });
    };

    if (typeof window !== 'undefined') {
      on(window, 'scroll', scrollCallback);
    }

    return () => {
      if (typeof window !== 'undefined') {
        off(window, 'scroll', scrollCallback);
      }
    };
  }, []);

  return (
    <Container className={show.state === true ? 'active' : ''}>
      {atFanfictionChapter() && <NightMode />}
      <StyledUpChevron
        title="Back to top"
        onClick={() => {
          window.scrollTo(0, 0);
        }}
      />
      <DownChevron
        title="Back to bottom"
        onClick={() => {
          window.scrollTo(0, document.body.scrollHeight);
        }}
      />
    </Container>
  );
};

export default BackToTop;
