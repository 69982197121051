import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const RemoteLink = makeShortcode("RemoteLink");
const Video = makeShortcode("Video");
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`What comes after Animal Crossing: New Horizons? For some, their new favourite game is Among Us.. As for me, it’s Genshin Impact! I’ve been stalking their`}<RemoteLink to="https://www.facebook.com/Genshinimpact" mdxType="RemoteLink">{` Facebook page`}</RemoteLink>{` for so long after their initial trailer caught my attention.`}</p>
    <Video src="https://www.youtube.com/embed/lMdsrZ1otlA" title="Genshin Impact trailer" mdxType="Video" />
    <p>{`Many said it’s a `}<RemoteLink to="https://www.nintendolife.com/news/2020/09/genshin_impact_dev_responds_to_breath_of_the_wild_clone_comments_insists_its_a_very_different_experience" mdxType="RemoteLink">{`Breath of the Wild clone`}</RemoteLink>{` down to its root. Okay so I’ve played the game since Day 1 now and I agree that there are some obvious copied elements going on here. There’s the stamina bar when climbing or dashing, the broken carts and fruits hanging from trees so obviously similar to Breath of the Wild. Not to mention the Bokoblin look-alike camps and treasure chests! And the gliding along with wind currents! The game’s aesthetics combined with these mechanics feel like a toned down Breath of the Wild game. My co-writer, mari-chan calls it a discount Breath of the Wild without the puzzles which I fervently agree with.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="genshin impact menu screen" caption="This is the menu screen. I believe the day/night view corresponds to my PC's time." name="genshin-impact-geeknabe-screenshot-5.jpg" mdxType="Image" />
    <p>{`But is it as bad as they say? `}<em parentName="p">{`Nah.`}</em>{` The character roster is `}<strong parentName="p">{`HUGE`}</strong>{` and yet, their design and moveset is unique. If you think gacha is the worst aspect of this game, don’t! If you’re just looking to enjoy the game with whatever you’re given, you’ll be super surprised by how generous the gacha is. I only pulled 4 times so far with the free gems they gave me along with the gems I earned by completing quests and yet I managed to get 4 new characters (`}<RemoteLink to="https://genshin-impact.fandom.com/wiki/Xiangling" mdxType="RemoteLink">{`Xiangling`}</RemoteLink>{`, `}<RemoteLink to="https://genshin-impact.fandom.com/wiki/Chongyun" mdxType="RemoteLink">{`Chongyun`}</RemoteLink>{`, `}<RemoteLink to="https://genshin-impact.fandom.com/wiki/Barbara" mdxType="RemoteLink">{`Barbara`}</RemoteLink>{` and`}<RemoteLink to="https://genshin-impact.fandom.com/wiki/Noelle" mdxType="RemoteLink">{` Noelle`}</RemoteLink>{`) for me to play with. Along with my own avatar and the first three starter characters (`}<RemoteLink to="https://genshin-impact.fandom.com/wiki/Amber" mdxType="RemoteLink">{`Amber`}</RemoteLink>{`, `}<RemoteLink to="https://genshin-impact.fandom.com/wiki/Kaeya" mdxType="RemoteLink">{`Kaeya`}</RemoteLink>{` and `}<RemoteLink to="https://genshin-impact.fandom.com/wiki/Lisa" mdxType="RemoteLink">{`Lisa`}</RemoteLink>{`), I have enough for 2 teams. I think that’s more than enough to play with, to be fair.`}</p>
    <p>{`The map is huge as far as I’m concerned. It’s also really beautiful to look at. I took my time to unlock the maps and teleport waypoints as I followed along the road… Unlike Breath of the Wild which has a decent amount of empty spaces, Genshin Impact’s map is choke-full of things to discover. There’s a decent amount of monsters along every patch of the map, then there’s a lot of treasure chests. You could either discover them just hanging out in corners or you’ll need to defeat monsters to unlock them. Some even require you to bash stone towers up, collect carrots or activate elemental pillars to unlock the treasure chest. One of my favourite quests is the one where you are required to follow a blue spirit to unlock treasure chests. Some of these are positioned really high or hard to reach, so I have to get creative if I want to reach them.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Battles are pretty easy to digest. You only have 3 ways of attacking - normal attack, elemental attacks and elemental burst. You could keep swapping between your characters just to reduce the cooldown time and increase DPS, but the bread and butter that allows for high damage is called Elemental Reaction. Mix and match 2 elements together while attacking a monster to create an effect! Some effects inflict `}<RemoteLink to="https://www.webopedia.com/TERM/D/damage_over_time.html#:~:text=Abbreviated%20as%20DOT%2C%20in%20video,be%20a%20type%20of%20DOT." mdxType="RemoteLink">{`damage over time (DOT)`}</RemoteLink>{`, while some just make them extra vulnerable. Each element has a special name in Genshin Impact. Fire is Pyro, Electric is Electro, Water is Hydro, Wind is Anemo, Ice is Cryo, Earth is Geo and Nature is Dendro. I have yet to see a character with the Dendro element yet...`}</p>
    <Image alt="genshin impact elemental chart" caption="I'm still experimenting the effects. If this chart is correct then there's a ton of reaction combo that I have yet to discover!" name="genshin-impact-geeknabe-screenshot-24.jpg" mdxType="Image" />
    <p>{`The image above is pulled from this link from `}<RemoteLink to="https://forums.mihoyo.com/genshin/article/4594" mdxType="RemoteLink">{`mihoyo's forum for Genshin Impact`}</RemoteLink>{`. My favourite combos are:`}</p>
    <ol>
      <li parentName="ol">{`Wind (Anemo) and fire (Pyro). I’d use my main character to create a whirlwind and quickly switch to Amber to use a fire attack. Voila, a fiery whirlwind!`}</li>
      <li parentName="ol">{`Ice (Cryo) and water (Hydro). I’ll use Barbara to attack first, then switch over to Kaeya to inflict an ice attack. The monster is now frozen, nice!`}</li>
      <li parentName="ol">{`Water (Hydro) and electric (Electro). Barbara or Lisa goes up first to attack, then the other would continue attacking. Doing this will make the poor monsters super susceptible to extra damages!`}</li>
    </ol>
    <p>{`Mixing these elements together makes up a fun battle mechanic to me. It lets you hit hard and hit fast! Then there’s a ton of quests for you to complete! Some quests just pops up when you're casually walking down a road... Nice. There's dungeons/instances where you could grind for materials to ascend your characters or weapons. Yup, we’re finally hitting some familiar gacha mechanics. The only thing that requires money in Genshin Impact is the gacha system. Through gacha, you could pull new characters or rare weapons. These weapons and characters all have stars on them… If you see star ratings in a game, then the accompanying mechanic would be the Leveling/Ascend system for both weapons and characters.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Phew, so far unlocking my characters up to Lvl 40 is still easy but I’m not sure how hard it is to unlock Lvl 90! As for ascending and leveling weapons, those can get a bit tricky since that requires you to complete dungeons. I did manage to level up my Artifacts and Weapons to the max with the free items/earned items from exploring and battling. I guess to get better items you just need to work for it! Overall, the game isn’t `}<em parentName="p">{`that hard`}</em>{` to play. You don’t really need to have impressive dodging skills or mashing button timing to win. You just need to understand the element chart and you’re good to go!`}</p>
    <p>{`Genshin Impact is basically an open world action RPG with gacha elements in it. So far I’m having a ton of fun playing it on PC! Rather than exclaiming that there are so many things for me to complete in this game, I’m honestly just excited to explore the game! The battles, puzzles and characters are secondary to me. I’m honestly just here to enjoy the story quests and the world Genshin Impact has to offer. Here are some screenshots I took while playing. I took a lot but I noticed that you have one more confirmation button to press before it saves into your screenshot folder. So don’t make this same mistake as me lol. Take a screenshot through Paimon’s Menu, then press the Save button to save it.`}</p>
    <p>{`Liyue map is `}<strong parentName="p">{`HUGE`}</strong>{` so I haven’t finished exploring it but so far, Liyue is my favourite to explore. It’s really beautiful! If you notice the screenshots isn't looking up to snuff, that's because I'm playing on the lowest settings. Please, come to Nintendo Switch so I could kiss my PC goodbye...`}</p>
    <Ads mdxType="Ads" />
    <Image alt="genshin impact screenshot 1" caption="This is Lumine. But I named her nabe-chan. Look, nabe-chan's face reveal!" name="genshin-impact-geeknabe-screenshot-21.jpg" mdxType="Image" />
    <Image alt="genshin impact screenshot 2" caption="This is Noelle! This is captured when I first started the game. I enjoyed switching between each character to see their Weapons tab animation. Click on Weapons, then switch character to see them hold their weapons in buttery smooth animation." name="genshin-impact-geeknabe-screenshot-23.jpg" mdxType="Image" />
    <Image alt="genshin impact screenshot 3" caption="I just love using Amber in my screenshots. She looks adorable. This is her before she goes gliding down Mondstadt from the God of Anemo's statue." name="genshin-impact-geeknabe-screenshot-17.jpg" mdxType="Image" />
    <Image alt="genshin impact screenshot 5" caption="Here's the so-called Paimon Menu! You can do everything here! I love the whole font and UI aesthetics in this game." name="genshin-impact-geeknabe-screenshot-22.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <Image alt="genshin impact screenshot 6" caption="This is Barbara, younger sister to Jean. Both are top class waifu material, but I love Barbara because she can heal. I think she's the only character that hums a cute song when she's idle." name="genshin-impact-geeknabe-screenshot-20.jpg" mdxType="Image" />
    <Image alt="genshin impact screenshot 7" caption="This is the Quest screen. It lists down a ton of quests here with description, etc. The UI is done really well here. I could easily understand the quest in a glance." name="genshin-impact-geeknabe-screenshot-2.jpg" mdxType="Image" />
    <Image alt="genshin impact screenshot 9" caption="I had to use my PC screenshot capabilities for this. I wanted to take a picture of the dungeon and cute Lisa, but I couldn't access the Paimon Menu..." name="genshin-impact-geeknabe-screenshot-3.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <Image alt="genshin impact screenshot 10" caption="I think this is not pre-rendered at all? Super smooth animation going on here." name="genshin-impact-geeknabe-screenshot-4.jpg" mdxType="Image" />
    <Image alt="genshin impact screenshot 11" caption="I rank this one of the top 10 pictures and angle I'll take if I ever go to Beijing and visit the Forbidden City." name="genshin-impact-geeknabe-screenshot-10.jpg" mdxType="Image" />
    <Image alt="genshin impact screenshot 13" caption="Barbara at home in her city's cathedral. The light shining in is super gorgeous!" name="genshin-impact-geeknabe-screenshot-19.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <Image alt="genshin impact screenshot 14" caption="One of the first screenshots I took to test it out. I think Liyue Harbor is a much bigger city than Mondstadt." name="genshin-impact-geeknabe-screenshot-9.jpg" mdxType="Image" />
    <Image alt="genshin impact screenshot 15" caption="I really love the fonts. It's big and comfortable to look at!" name="genshin-impact-geeknabe-screenshot-1.jpg" mdxType="Image" />
    <Image alt="genshin impact screenshot 16" caption="Just Lisa doing her rose thing with Liyue Harbor in the background. I love her. I think she's a classy lady." name="genshin-impact-geeknabe-screenshot-11.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`All in all`}</h2>
    <p>{`Genshin Impact is available now on PS4, PC, IOS and Android. Abandon all hope if your phone sucks, I've seen reports that it simply doesn't render the game well. Also, this game burns your phone battery like flashfire. I am running this game on the lowest setting on my PC with 60fps. I'm not sure if it's true 60 fps, but it does look better than 30 fps in action. My PC is a Windows 10, has 8GB of RAM, using an Intel i5 and the graphic card is GeForce GT 820M. Yup, my laptop has followed me since 2013! I am currently using Logitech Gamepad F310 as the game controller since the game just doesn't recognize Nintendo's Pro Controller. It took some time to get used to because this is the first I've seen a game use a ton of LT, RT, RS and LT buttons! Genshin Impact is good and it's free. What are you waiting for?`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      