import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXFanfiction/Chapter/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Divider = makeShortcode("Divider");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Felix vaguely apologised for not inviting her brother, but he stressed that the matter be kept between the four of them. Even though Reus was Byleth’s twin, it would be better if less people knew.`}</p>
    <p>{`“Aww will you have time to visit us sweetie?” Their mother said on video call, two days later.`}</p>
    <p>{`“Um, I’m not sure mum. It’s just a day trip, but maybe I can drop by to pick some things up,” Byleth said. “Reus has already given me a list of stuff he wants.”`}</p>
    <p>{`“Too bad your brother can’t come,” Their father said as he watched TV.`}</p>
    <p>{`“Yeah, I’ve got practice. The next round is in two weeks,” Reus yelled from his bed.`}</p>
    <p>{`“Oh this reminds me of the time you took both of them to the museum, remember?” Their mother chuckled. “You came home completely drained and exhausted.”`}</p>
    <p>{`Their dad laughed, “Yeah, yeah, we couldn’t go back for a couple of years because Byleth vomited in their fountain.”`}</p>
    <Ads mdxType="Ads" />
    <p>{`Reus snorted while she grimaced, “How come you never told us this?”`}</p>
    <p>{`“Oh pfft,” their mother laughed again, “The both of you were always vomiting or accidentally pooping yourselves. It’s very normal for children.”`}</p>
    <p>{`Byleth and Reus both went silent.`}</p>
    <p>{`“Okay you two, don’t stay up too late. Come back soon alright, Reus?” Their mother said as she waved at the video.`}</p>
    <p>{`“‘Kay mum,” She said and Reus got up from his bed to wave back at them, their mother nudged their father to wave since he was glued to the TV.`}</p>
    <p>{`A while after that, their mother sent a message to the group chat, it was a picture of the both of them at the museum as toddlers, and Byleth couldn’t believe her eyes because they were posing in front of a mermaid skeleton.`}</p>
    <p>{`“Wow, we were really identical as kids huh?” Reus said, looking at his phone. “Right down to the hair too.”`}</p>
    <p>{`“Oh yeah, you’re right. If not for the clothes, I can’t really tell us apart either.” Byleth said as she zoomed in on their faces.`}</p>
    <p>{`“You seem to be hanging out with Felix and Dimitri a lot more huh? Even Dedue too,” Reus said casually. Byleth froze.`}</p>
    <p>{`“I mean we’ve always hung out with Felix, and Dimitri lives with Felix and doesn’t have any other friends so…” She turned away, pretending to do something before Reus caught sight of her panicked expression. She wasn’t very expressive to start with, but as a twin, you tend to be really good at picking up the subtle changes in your sibling.`}</p>
    <p>{`Thankfully Reus didn’t notice anything and continued with the conversation, “Hey you know Dimitri isn’t actually grouchy or mean?”`}</p>
    <p>{`She paused for a moment, and then spun around on her chair to look at Reus, “What?”`}</p>
    <p>{`“Yeah, I think he’s actually...more shy than mean?” He sat up from his bed, “Okay I’m not too sure about this, but he doesn’t have that…’mean’ aura? I’ve been watching him for weeks now-”`}</p>
    <p>{`“Why?” She asked, genuinely amused and curious.`}</p>
    <p>{`He rolled his eyes, “Because he’s our star swimmer, me, and a lot of other people observe him very frequently.”`}</p>
    <p>{`She shrugged, “Okay, makes sense.”`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Anyway, Dimitri comes and joins our strategy meetings and stuff, or he quietly joins conversation groups, he’s a big guy so it's hard to miss him.” Reus squeezed his eyes shut, probably trying to think what to say, “But...when someone talks to him, he brushes them off- you know what I mean.” She nodded.`}</p>
    <p>{`“It’s like...he wants to be a part of us, but he doesn’t know how?” He continued. Byleth knew exactly what her brother meant, but she couldn’t tell him, ‘Dimitri is a mermaid and therefore has very little experience with humans.’ - even though she was bursting to tell him. She hated keeping secrets from Reus.`}</p>
    <p>{`“So...he’s like us...but more...curt?” She added.`}</p>
    <p>{`He snorted, “Kind of? We’re just...I don’t know, introverts? But yes. I know what you mean- Actually, he feels a lot like Felix doesn’t he?”`}</p>
    <p>{`“He does?” She feigned. To be honest, it didn’t occur to her Dimitri was shy...she truly thought he wasn’t used to humans. But then again, Dedue seemed very polite and was able to hold a civil conversation. She mentally sighed, she didn’t know anymore.`}</p>
    <p>{`Reus paused at first, then nodded, “Yes, now that you mention Dimitri lives with Felix...It makes a lot more sense. He probably picked up a lot of Felix’s speech patterns.”`}</p>
    <p>{`Byleth took a deep breath, again, unsure of what to think or act since Reus was extremely perceptive, and right now she was really cautious not to give away anything else, simply nodding at his theories.`}</p>
    <p>{`The day of the trip came, armed with a pair of her good noise-cancellation earphones, a neck pillow, a jacket and with a letter from Professor Seteth giving the students permission to leave the university for research at the museum, Byleth set out with Felix and Dimitri in tow.`}</p>
    <p>{`Dedue couldn’t come since Coach Manuela refused to let so many of her swimmers go for some ‘research’ so near the tournament - letting Dimitri go was bad enough, but she conceded since Dimitri was already so good at swimming. She said they’d just have to do Dedue’s share of work for him.`}</p>
    <p>{`They all quickly agreed since technically it was only Byleth and Felix’s homework, Dimitri was just an extra while it almost killed Dedue to be away from Dimitri and he made them swear they’ll look after him.`}</p>
    <p>{`“Can I ask why doesn’t Dedue swim as fast as you? Even though he’s a mermaid too?” Byleth asked while they waited for the train at the main train station in Garreg Mach town at 6.00am, needing to depart early because their journey took about 3.5 hours from Garreg Mach to Fhirdiad, where the museum was.`}</p>
    <p>{`Dimitri stared down at her and blinked, “He’s...much better at controlling his speed while in human form,” he replied bluntly, averting his gaze.`}</p>
    <p>{`She nodded. And the conversation ended there.`}</p>
    <p>{`The three of them managed to get seats together, facing each other with Dimitri and Felix on one side and Byleth on the other, with their bags next to her. Even then, the three of them didn’t say a word, just quietly staring at each other.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Byleth immediately fell asleep as soon as the train started moving, reluctantly waking up to show the train steward her ticket.`}</p>
    <p>{`During the one hour nap she took, her eyes fluttered open every now then when she adjusted herself. Even Felix was asleep but Dimitri was wide awake. From what she could tell through sleepy, blurred eyes, it looked as though Dimitri was almost glued to the window.`}</p>
    <p>{`It wasn’t a very peaceful nap, even though the train lights were dimmed it shined right into her eyeballs, and of course it was just her luck the air-conditioning was directly blowing at her. Byleth couldn’t take it anymore and decided to just...wake up, letting her brain buffer for a while before she did anything.`}</p>
    <p>{`In front of her, Felix was fast asleep, seemingly immune to the lights and freezing cold air conditioning. Felix was honestly a very pretty man, fair skin, long eyelashes, and soft hair framing his features as he slept. Next to him was a huge giant blonde bear, with birds' nests for hair and a...childlike fascination for the outdoors as the train sped along the countryside.`}</p>
    <p>{`Dimitri had his entire body turned to the window, with one elbow supporting his head as he stared out the window.`}</p>
    <p>{`It would never, ever occur to her in a million years that this grown man before her was a mermaid. Even now, when she knew the truth, Dimitri just seemed like a normal person to her; a human. Sure he had strange tendencies and kept unusually quiet unless spoken to, a normal person, like the rest of the passengers scattered on the train, would be stuck to their phones but Dimitri had his eyes fixed on the outside world. Looking almost as if he wanted to reach out and touch the trees that went past.`}</p>
    <p>{`He was like a toddler on his first train ride.`}</p>
    <p>{`Byleth let out a soft groan when her stomach rumbled. She looked down at her watch and hoped the lunch lady would come by with the trolley. After another 5 minutes of her stomach demanding to be fed, Byleth pulled out her earphones, grabbed her purse and stood up, stretching as much as she could in the cramped space without hitting anyone.`}</p>
    <p>{`She studied the layout of the train, looking for the meal coach and decided to head there when Dimitri stood up abruptly as well. He towered over her, with her face almost pressed up against his chest when he stood up.`}</p>
    <p>{`Byleth inched out of their shared space and headed towards the meal coach, but Dimitri followed her so closely he almost stepped on her heel.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Can I help you?” She turned and asked.`}</p>
    <p>{`Dimitri squinted through his bangs and said, “Toilet,” a little curtly.`}</p>
    <p>{`“Oh, okay,” she replied and carried on, but Dimitri was still behind her even though they just passed a vacant bathroom.`}</p>
    <p>{`“Um, do you need any help?” She stopped and he bumped into her.`}</p>
    <p>{`He looked away and gave her a small nod. She went back to the bathroom and saw nothing unusual about it, it was the common type ; you pressed a button to go in and then turned the lock- `}<em parentName="p">{`Oh.`}</em></p>
    <p>{`Dimitri didn’t know how to operate this. Byleth smacked her forehead. “Sorry, I forgot you…Nevermind. Yeah, uh, so you press this…” And gave him a quick crash course on how to use a more...complex sanitation facility like this.`}</p>
    <p>{`She replied to a few text messages while waiting for him and felt a little bit relieved when she heard a flush. She even felt proud when he successfully opened the button-operated door. He emerged, face and hair dripping with water.`}</p>
    <p>{`Byleth nodded at him and continued her journey in search of a warm meal, but he was still following her. This time, she guessed he wanted to explore the train a little and ignored him. She was getting a little hangry.`}</p>
    <p>{`She actually enjoyed train rides, especially the little train lunch sets. Seeing as the meal coach was relatively empty, she thought the change of scenery would be nice and decided to have her meal there. Unsurprisingly, Dimitri tottered closely behind and stood next to her, silently pointing at the menu for a meal and just as silently paid for it while she waited for them to heat hers up.`}</p>
    <p>{`By accident she glanced over at the cashier and it appeared as though he was...cowering. She looked at Dimitri who was sort of glowering at the poor guy. She let out a soft chuckle. Amazingly, she was already getting used to his unfriendly demeanor, it was really as though he was Felix No. 2 - but scarier and much much taller.`}</p>
    <p>{`She found an empty table and Dimitri stood at her table, his face was speaking on behalf of him, even though she couldn’t really see his eyes, she assumed he was asking permission to sit with her and she gestured at him to join her. Reus was right, he did try to join in whenever he could. But parties were probably too daunting for him. Poor thing, he can’t even drink alcohol to relax without dying.`}</p>
    <p>{`They ate their meal in silence, again but he was surprisingly really pleasant to be with, he ate quickly and continued looking out the window. He even tried to take a picture of the moving scenery outside but frowned when the picture turned out blurry - from what she could see.`}</p>
    <p>{`“You should try taking a video instead...Here I’ll show you,” she offered, holding a hand out as he gingerly passed her his phone. She showed him where and what to tap and he eagerly recorded the scenery outside and everything around him.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Did Felix not show you how to do any of this?” She asked, slurping on her hot tea.`}</p>
    <p>{`Dimitri shook his head, “He only taught me how to make phone calls and text messages.”`}</p>
    <p>{`She raised her eyebrows, “And you didn’t explore your phone?”`}</p>
    <p>{`He shrugged, “Not much. I…didn’t want to...erm..spoil it.” She chuckled.`}</p>
    <p>{`“I get it, I’ll help you from now on,” she smiled. “Since Felix has resigned from his position and forced it on me anyway.”`}</p>
    <p>{`Dimitri kept his phone and looked down, “Thank you,” he mumbled sweetly, with a small smile even.`}</p>
    <p>{`Byleth felt her heartbeat steadily rise, feeling a little shy when he said thank you, coupled by the fact that Dimitri was quite handsome when he smiled and she blushed while awkwardly replying to him.`}</p>
    <p>{`“I don’t think you need glasses,” she said when they were back at their seats.`}</p>
    <p>{`“What? Who?” Felix croaked, having just woken up from his nap.`}</p>
    <p>{`“No, no, I meant him.” She nodded at Dimitri. Said male titled his head in confusion.`}</p>
    <p>{`“I asked him the other day in the library if he needed glasses because he squints so much, but now I think he just needs a haircut,” she stated.`}</p>
    <p>{`Felix frowned, “I didn’t notice. I thought he was just...boorish.”`}</p>
    <p>{`“You know he’s just mirror-ing your mannerism right?” Byleth replied flatly.`}</p>
    <p>{`Felix clicked his tongue, “Whatever.” She snorted.`}</p>
    <p>{`“Here,” she said, pulling a black bobby pin from her pocket. “Use this to hold your hair up, it’ll make a difference.”`}</p>
    <p>{`Dimitri reluctantly accepted it and merely held it in his hands. She chuckled.`}</p>
    <p>{`He merely stared at the weird bendy contraption in between his fingers.`}</p>
    <p>{`“Um, I’ll help you.” Byleth got up from her seat, taking back the pin from his hand. Using her teeth to separate it, she grabbed his fringe, deftly twisted it and pinned it into place. From a glance, his hair looked wiry, but touching now confirmed her suspicions; it was not just dry, it was `}<em parentName="p">{`insanely `}</em>{`dry and straw-like. Probably from all the chlorine and salt-water.`}</p>
    <p>{`“Why didn’t you tie your hair like Felix?” Byleth asked.`}</p>
    <p>{`“Um...it gives me headaches,” Dimitri replied softly.`}</p>
    <p>{`She nodded, “I understand.”`}</p>
    <Ads mdxType="Ads" />
    <p>{`Felix frowned at her, not saying anything. Byleth stared back until Felix ran a hand through his hair and a light bulb lit up in her a few seconds later, “You don’t have long hair anymore,” she said. “Sorry, it looks almost identical from the front.”`}</p>
    <p>{`As she was adjusting a few stray hairs, the train went over some bumpy tracks and she was about to fall backwards when Dimitri caught her waist, steadying her. There it was again, her heartbeat thumping loudly in her chest. She looked down to see clear, blue eyes like the ocean, staring back at her.`}</p>
    <p>{`“Ahem,” Felix cleared his throat.`}</p>
    <p>{`“S-sorry,” she mumbled and pulled away, “And thanks.”`}</p>
    <p>{`“Um, thank you,” Dimitri said when she sat down. “It's never a problem when I’m in the water but on land it always flops down.”`}</p>
    <p>{`“You’re welcome,” she smiled at him.`}</p>
    <p>{`And for the first time, she could clearly see his expression; he was smiling back at her.`}</p>
    <Divider mdxType="Divider" />
    <p>{`“Felix!” A man called out as they stood in front of the museum ticket booth as he
ran towards them while waving.`}</p>
    <p>{`“Here we go,” Felix groaned and he was engulfed in a giant bear hug.`}</p>
    <p>{`Byleth couldn’t help herself from staring at the man’s face;it was as though Felix had a taller, older and totally un-grouchy version of himself. This version of Felix was `}<em parentName="p">{`smiling`}</em>{`, like a genuine, happy smile. It sent some shivers down her spine; the image of a happy Felix was slightly terrifying.`}</p>
    <p>{`“Let me go, I’m not 12 anymore,” Felix demanded. As soon as he was released, Felix unenthusiastically introduced the man to them, “This is my brother Glenn.”`}</p>
    <p>{`“Thank you so much for doing this for us, Dr. Fraldarius, I’m Byleth Eisner,” Byleth said, taking his hand and shaking it. “Sorry for the inconvenience caused as well.”`}</p>
    <p>{`“Wonderful to meet you, Byleth and please, don’t call me that. Just Glenn, will do.” He gave her a wide smile, for a split second she thought she was seeing things, she just couldn’t believe how eerily similar they looked. It was as though Felix himself was smiling at her and she felt another shiver down her spine.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Anything for my brother and his friends, I’m really happy to help!” Glenn replied as he shook Dimitri’s hand. “You look like you’re settling in well,” he winked. Dimitri nodded.`}</p>
    <p>{`“Well, Ingrid said you wanted to tour the museum too right?” Glenn asked and fished out 3 tickets from his coat pocket. “Here you go.” She accepted the ticket with thanks, extremely excited for the tour. Of course, it didn’t show on her face but she couldn’t help but express excitement through her voice.`}</p>
    <p>{`As Glenn led them in, she leaned over and asked Felix, “Is our Ingrid another relative of yours?”`}</p>
    <p>{`“She’s his fiancée.”`}</p>
    <p>{`“What?” Byleth blurted out. “Really?”`}</p>
    <p>{`“Do I look like I’m joking?” Felix spat.`}</p>
    <p>{`“Right, sorry. Um, how old is he? If you don’t mind my asking. I’m just really impressed with his credentials,” she asked in a low voice.`}</p>
    <p>{`“He’s 6 years older than us. He skipped a few years of high school and went straight to university,” Felix replied apathetically.`}</p>
    <p>{`“Amazing,” she said under her breath. Glenn was everything she wanted to be when she was a teenager. He was a certified Genius.`}</p>
    <p>{`“Whatever,” Felix shrugged.`}</p>
    <p>{`“Okay so, here’s the plan. I’ll take you guys to have a look at my research materials, then we’ll adjourn for lunch; my treat of course. And then you guys can hang around the museum as long as you like. Sounds good?” Glenn explained as they stood in front of a door that said ‘EMPLOYEE’S ONLY’. Byleth could feel the excitement rising in her, getting access to restricted sections in a museum was a dream come true.`}</p>
    <p>{`Glenn led them to his office, Byleth could hardly contain her glee when they passed by various artists restoring art works, statues, shelves and shelves of wooden crates containing who knows what treasure.`}</p>
    <p>{`“Dr. Fraldarius-”`}</p>
    <p>{`“-Glenn,” He corrected her.`}</p>
    <p>{`“Sorry, um, can I ask you a few questions?” Byleth whipped out her notebook after he sat them down on his guest chairs, flipping to a page with all the questions she prepared beforehand.`}</p>
    <p>{`Glenn nodded, “Of course.”`}</p>
    <p>{`She let out an exhale, “Okay, first of all, just...where did you find the materials to recreate Areadbhar? It’s been a millennia since King Dimitri I wielded it and no new material has been found or officially recorded for about 200 years. This is an amazing discovery, Dr.Fra- I mean, Glenn.”`}</p>
    <p>{`“That’s actually a very good question,” He clapped his hands together. “Officially, what I can tell you, is that we’ve been more or less reinvestigating the site where King Dimitri I was supposedly slain by Emperor Edelgard I with modern equipment. I cannot tell you where exactly, of course, NDA’s and all that.”`}</p>
    <p>{`She quickly jotted down everything Glenn was saying, while Felix looked bored to death. Byleth quickly went through all of her questions and Glenn was not only happy to oblige where he could, but he showed them some of the actual relics that were kept in his office. She wasn’t sure if all of the data she collected could be used, but nonetheless she felt more confident about her assignment now that she did have something.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Anything else?” Glenn smiled at her, “I still have time to answer any more questions you have.” She shook her head.`}</p>
    <p>{`“Thank you very much,” she thanked him.`}</p>
    <p>{`“Byleth,” Felix called out when Glenn was returning some relics in their protective cases, “The truth of where he found Areadbhar and the whole King Dimitri I murdered by Edelgard is just...propaganda you know?”`}</p>
    <p>{`“Felix,” Glenn said in a stern voice.`}</p>
    <p>{`Felix rolled his eyes and ignored his brother, “60% of what you know, well more like what everyone knows, is the truth. 40% are just fabricated lies to sway public opinion.”`}</p>
    <p>{`She raised an eyebrow, “Felix, are you saying our research materials are fake?”`}</p>
    <p>{`“Not all.”`}</p>
    <p>{`“Then why do you continue to use it? Why blindly follow instead of telling the truth?” She challenged him, genuinely curious rather than defensive.`}</p>
    <p>{`He shifted in his seat to look at her, “So, you want the world to know that mermaids exist? Even if the real reasons why they exist would completely throw the world into utter chaos? Is that okay with you? Just so you could say you were right?”`}</p>
    <p>{`‘Felix, that’s enough,” Glenn suddenly turned around and stared straight at Felix.`}</p>
    <p>{`“History is written by winners, Byleth. Don’t you know that?”`}</p>
    <p>{`Glenn slammed his hand on the table, “Feli-”`}</p>
    <p>{`Felix scoffed again, “She knows, Glenn.” His brother frowned.`}</p>
    <p>{`“What do you mean?” Glenn frowned.`}</p>
    <p>{`Felix seemed to be enjoying this, “She rescued him-” he gestured at Dimitri, “-from dying of dehydration because he drank beer. And then he transformed in front of her.”`}</p>
    <p>{`“What?” Glenn’s eyebrows were deeply knitted together, a sight that made him literally identical to Felix, “But...beer is fatal to-”`}</p>
    <p>{`“-Mermaids,” Felix interrupted. Glenn kept silent, his expression was in complete disbelief.`}</p>
    <p>{`Glenn leaned back on his chair, tapping his fingers on his table, “But...no mermaid would willingly consume alcohol?” he said after a few seconds of silence.`}</p>
    <p>{`Dimitri let out a sigh, “It’s true, Glenn.”`}</p>
    <p>{`“Wait hold on,” Byleth said, leaning forward. “Felix, you mentioned your brother was supposed to take charge of Dimitri?” Felix nodded.`}</p>
    <p>{`“I assumed he obviously knew about Dimitri’s...true form, but...how long have you guys known?” She blurted out.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Our whole lives,” Felix revealed, “We grew up with him.”`}</p>
    <p>{`“His dad taught us how to swim actually,” Glenn finally said.`}</p>
    <p>{`She sucked in a deep breath, hoping the fresh intake of air would clear her mind, but it did nothing of the sort. In fact her chest felt tight.`}</p>
    <p>{`“That’s why we brought you here, there’s more to this than just pure myth,” Felix explained.`}</p>
    <p>{`Byleth rubbed the back of her neck, unable to process anything. That horrible feeling of not knowing what truths were forged, what was she supposed to believe...scared her more than anything. It seems right now, what she thought were pure hard facts, were actually manufactured lies.`}</p>
    <p>{`“Dimitri, why don’t you take her around the museum for a bit?” Glenn stood up, gesturing at the door, “I’d like to have a chat with my brother here. We can examine Areadbhar later if you’re still keen, Byleth.” Glenn smiled at her.`}</p>
    <p>{`As Dimitri and her walked towards the door, Glenn spoke up, “Byleth, I know how you feel, but don’t worry. Just keep an open mind and don’t be afraid of the truth. You’ll come to understand why things are done this way.” She nodded and thanked him.`}</p>
    <Divider mdxType="Divider" />
    <p>{`“Are you alright?” Dimitri asked all of a sudden as they headed towards the museum
Cafe for a hot drink.`}</p>
    <p>{`She looked up at him, “Yeah, I’m fine. More than anything I feel...oddly fired up to learn more.”`}</p>
    <p>{`He stared at her widely, which she could clearly see now. In fact, she could see his whole face so clearly now he was actually...again, rather handsome. With clear blue eyes like the sea and a determined gaze that made her feel hot, rather than his usual haughty look which made her feel small.`}</p>
    <p>{`She looked away shyly when met directly with his gaze since she had been staring intensely at him, “Um, yeah. It’s all so confusing but I enjoy learning so, yeah it’s all good.”`}</p>
    <p>{`He nodded, looking away now and took a big gulp of his electrolyte drink, “That's...good.”`}</p>
    <p>{`Byleth sipped on her coffee, trying to fill the awkward silence. “So uh, you know this museum well?”`}</p>
    <p>{`Dimitri nodded again, “Yes, it’s...where I, I mean, we merfolk get our first exposure to humans.”`}</p>
    <p>{`Her jaw dropped a little, she was just in awe at the conversation she was having with a real, living, breathing mermaid.`}</p>
    <p>{`“I think me and Reus came here when we were kids, I vaguely remember this place,” Byleth said out loud as their tickets were being validated. Dimitri nodded, he did that a lot she noticed.`}</p>
    <p>{`“Felix’s family...have been in charge of this museum since the beginning,” Dimitri explained while fidgeting with his bottle of Gatorade.`}</p>
    <p>{`“So merfolk are able to freely come and go in the museum because of that?” Byleth asked and Dimitri nodded.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Even...little mermaids? Not the cartoon, I mean...uhh..you know,” she stammered.`}</p>
    <p>{`He paused for a while, “Yes, we are encouraged to. But I...was very shy.”`}</p>
    <p>{`The two of them spent the tour in silence, which was perfectly fine with Byleth. Silence was a good friend to her. She took a tonne of pictures too since the museum had no restrictions, except that they asked patrons to not use flash photography.`}</p>
    <p>{`Thinking about it, Byleth and Reus were very, very, somber children growing up. Around each other and their parents they were normal, but in public and in school...they barely spoke to anyone. It was only in high school did they start coming out of their shells when they attended Garreg Mach Academy. Maybe because it was a boarding school and they were forced to interact with other people in their dorms.`}</p>
    <p>{`“By the way, Dimitri, if you don't mind me asking,” Byleth turned to him, gauging his expression, “Why is this place the center for...human exposure?” She asked when his face showed no visible signs of rejection.`}</p>
    <p>{`He looked around and waited for a lady to move into the next room before speaking in a low voice, “As you probably already know, this used to be the Royal Palace,” he started, she nodded.`}</p>
    <p>{`Dimitri took another deep breath to speak, Byleth assumed he wasn’t used to speaking this much to humans, “A large portion of the merfolk I live with are...descendants of the Blaiddyd bloodline. But I-I am a direct descendant. So...ummm, I think in a way, this museum is our home.”`}</p>
    <p>{`Her eyes widened, “No wonder you’re the Prince of the merfolk.”`}</p>
    <p>{`“I do not consider myself that,” he said firmly.`}</p>
    <p>{`“I thought King Dimitri didn't have kids,” she scratched her forehead, “Or is this another fabrication as well? Sorry, I’m not so sure anymore of my facts.”`}</p>
    <p>{`He shook his head, “No, you are correct. I descend from his uncle, Lord Rufus,” she squinted at him, trying to remember if King Dimitri had an uncle, then the knowledge came to her and she unfurrowed her brows.`}</p>
    <p>{`They talked as they walked into the next room, which was coincidentally the mermaid gallery, the main reason she came here.`}</p>
    <p>{`“Unfortunately, on land, his line went....extinct,” Dimitri explained further.`}</p>
    <p>{`“Ah, now I remember, he was a serial womanizer of some sort wasn’t he?” She tapped her chin.`}</p>
    <p>{`“Yes, that is correct.” He nodded, “It is only thanks to the longevity of a mermaid’s life the Blaiddyd line still lives on.”`}</p>
    <p>{`“But there are a lot of people with the Blaiddyd surname now?”`}</p>
    <Ads mdxType="Ads" />
    <p>{`He shrugged, “That is true. But they are not related to me.”`}</p>
    <p>{`“I see.”`}</p>
    <p>{`“Apart from our biology and some minor differences...we are essentially still human,” Dimitri said, stopping in front of the mermaid skeleton replica.`}</p>
    <p>{`The skeleton sparked a memory in Byleth, which the picture her parents sent her helped to bring the memory to life. Most people don’t remember much from their childhood, but seeing a mermaid skeleton would definitely be burned into their memories.`}</p>
    <p>{`“This...is actually a replica right?” She asked, genuinely curious.`}</p>
    <p>{`Dimitri looked down at her and nodded. She let out a sigh of relief, somewhat thankful it wasn’t a real mermaid or one of Dimitri’s ancestors.`}</p>
    <p>{`They walked into a small auditorium-like room; they were just in time for the mini VR movie about mermaids. An attendant handed them some VR headsets and ushered them to some empty seats, also explaining that the VR had just been implemented last year. She peered at Dimitri from the corner of her eye and he looked like he knew what he was doing with the VR headset. Strange he didn’t know how to operate a phone but knew how to use this instead. He probably really spent a lot of time here.`}</p>
    <p>{`It took her eyes a couple of seconds to adjust, she was worried about developing a headache but totally forgot about it when the movie started.`}</p>
    <p>{`The narrator’s deep voice, explaining the lore of where mermaids came from had completely captivated Byleth. It was a simulation with VR mermaids swimming around the room in an underwater environment.`}</p>
    <p><em parentName="p">{`It was essential they return to sea every so often...for their essence had been pledged to the ocean. Their life force now forever entwined with the waves. It was only for the purposes of reproduction were they allowed to live on land.`}</em></p>
    <p>{`Byleth felt very aware of Dimitri as soon as the narrator said ‘reproduction’.`}</p>
    <p>{`The only reason why Dimitri was on land, sitting next to her, was to make mermaid babies. Which in hindsight, she thought was cute. But then a whole slew of questions invaded her mind - how were mermaid babies made? How did they reproduce? Are mermaid babies born with tails?`}</p>
    <p>{`She sat firmly rooted to her seat, drowned in her thoughts as she watched the short movie come to an end. Only when the delighted squeals of some little girls, demanding to rewatch the movie did her mind come back to earth.`}</p>
    <p>{`“Dimitri,” she whispered when they left the auditorium and sat in a more remote part of the museum to wait for Felix, “What did the narrator mean by ‘it was essential they return to sea every so often’?”`}</p>
    <p>{`He tucked a lock of hair that fell to his eyes behind his ear, “Um, I’m not sure how to...to...explain it.,” he replied meekly.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“It’s...like...erm...like…” She could see he was visibly struggling and shook her head.`}</p>
    <p>{`“It’s fine,” she said reassuringly. “Erm, do you want to get a haircut?” She asked when she saw him struggling to adjust the hair pin on his head, “It’ll make it easier for you to see and stuff.”`}</p>
    <p>{`Dimitri stared at her with his eyebrows slightly knitted, as if in thought. He nodded a few seconds later.`}</p>
    <p>{`Her phone rang at that moment, it was Felix asking them to meet back at the entrance. She checked the time and they were just in time for lunch. It was weird how fast time flew by; she really did enjoy herself at the museum.`}</p>
    <p>{`“Sorry about just now, Byleth,” Glenn stuck his hand out to apologize, she shook his hand. “Our Dimitri will be in your hands from now on,” he smiled.`}</p>
    <p>{`“What?” She blinked at him.`}</p>
    <p>{`“Felix says you have graciously accepted to help Dimitri acclimatise.” Glenn beamed at her. She reluctantly nodded.`}</p>
    <p>{`“Here,” Glenn pulled something out of his pocket and pressed it into Felix’s hand, “Lunch money.”`}</p>
    <p>{`Felix rolled his eyes, “I’m not a kid anymore.”`}</p>
    <p>{`“Just take it, it’ll make me feel useful okay?” Glenn ruffled Felix’s short hair, to which Felix angrily pulled away and hissed at his brother.`}</p>
    <p>{`The three of them set out by bus to have lunch at Glenn’s expense at a cafe near her childhood hairdresser and home. They decided to have lunch first since the hairdresser was closed for lunch, and then head to her house to collect some things before getting a haircut since it was on the way.`}</p>
    <p>{`Her parents engulfed her in a hug so tight, they were probably hugging Reus’s share too.`}</p>
    <p>{`“Hey, mom, dad,” she wheezed, tapping them to release her. “Sorry we can’t stay long, my friend needs a haircut and then we have to catch the train back.” Byleth felt guilty and a little homesick when the smell of her home hit her.`}</p>
    <p>{`“I’m gonna have a quick shower,” she said to Felix and Dimitri who were standing awkwardly at the entrance. They nodded.`}</p>
    <p>{`She could hear her mother offering them refreshments as she undressed and hopped into the shower. She let out a soft moan when the hot water hit her, it felt really good.`}</p>
    <p>{`She emerged and offered the shower to the two men who were being interrogated by her father about the swim team and school. They both declined, reminding her of their tight schedule.`}</p>
    <p>{`“You sure you can’t stay sweetie?” Her mom pleaded, handing her a box of snacks and home cooked food she and Reus liked.`}</p>
    <p>{`She shook her head, “Dimitri needs to be back for practice early tomorrow morning,” she explained to her parents. “I’ll be back soon, with Reus.”`}</p>
    <Ads mdxType="Ads" />
    <p>{`Her dad patted her head, “Okay, kid. Take care, you hear me?”`}</p>
    <p>{`She waved at her parents, as the three of them walked out of her garden, “Make sure your brother doesn’t do anything stupid!” Her dad yelled, she flashed him a thumbs up.`}</p>
    <p>{`“And make sure you don’t sleep so late!” Her dad yelled again, she gave him two thumbs up this time before they rounded a corner.`}</p>
    <p>{`Once more, the three of them walked in silence to a row of shops to see if the hairdresser was open. Thankfully, it was and they were the only customers too. Had there been too many people they would’ve left.`}</p>
    <p>{`“Good timing,” The hairdresser said as they stood at the door, “I just finished my lunch coffee. Come in, come in.” She ushered them in.`}</p>
    <p>{`She took one look at Byleth and immediately recognised her, “Ahhh, I haven’t seen you and your twin in a while!” She exclaimed.`}</p>
    <p>{`“Yeah, um, haven’t needed a haircut in a while,” Byleth replied.`}</p>
    <p>{`“What can I do for you today, darling?” The hairdresser smiled. Byleth explained that it was her friend who needed a haircut, not her.`}</p>
    <p>{`The hairdresser gestured at an empty seat and Dimitri sheepishly sat in it, looking somewhat confused, “Now young man, ooh, this hair needs some deep conditioning,” she said, fiddling with Dimitri’s hair, “Anyway, what hairstyle do you have in mind?”`}</p>
    <p>{`Dimitri shrugged, “A-anything will do.”`}</p>
    <p>{`“Tsk tsk, no, no,” she wagged a finger in his face, “You tell me what you want and I will tell you if it suits you,” she said to Dimitri very assertively. “Trust me, I know.”`}</p>
    <p>{`Her salon was old, it was as though it was a time capsule from 30 years ago with various yellowed posters of celebrities from the 80’s like George Michael, to 90’s heartthrobs like Take That peeling off the walls. It was old, yes, but her entire family had been patronising this salon for years. She didn’t feel comfortable anywhere else.`}</p>
    <p>{`Dimitri looked intimidated by her hairdresser who had the beehive hairstyle, and Byleth almost felt bad for him. Dimitri pointed a shaky finger at a dog-eared poster of Leonardo DiCaprio from the 1990’s and her hairdresser nodded approvingly.`}</p>
    <p>{`Byleth and Felix sat on an empty sofa while they waited for Dimitri’s makeover, with Byleth promptly falling asleep.`}</p>
    <p>{`It had been a rather long day, with a lot of information to take in and digest. Plus she had to interact with quite a few people today; she was thoroughly exhausted. Byleth could feel her head getting heavier as she fell deeper into her sleep, she had to jerk her head up and close her mouth a couple of times too.`}</p>
    <p>{`Byleth felt a rough nudge on her side and fluttered her eyes open, her eyelids were extremely heavy with sleep and she nearly fell asleep again if not for Felix nudging her a couple more times.`}</p>
    <p>{`“Let’s go,” Felix ordered.`}</p>
    <Ads mdxType="Ads" />
    <p>{`It took her a few moments to calibrate what was happening, and then she saw a hand stretched out in front of her, offering to help her to her feet.`}</p>
    <p>{`“Thanks,” she thanked the stranger whose hand she accepted.`}</p>
    <p>{`She groggily stumbled out of the salon, thanking the hairdresser, promising to come back another day.`}</p>
    <p>{`Byleth leaned on the wall next to the door, closing her eyes for a few seconds as she and Felix waited for Dimitri.`}</p>
    <p>{`“Why are you still sleeping? Let’s go,” Felix barked at her.`}</p>
    <p>{`Her vision, blurred with sleep, was further hampered by the sun shining right into her eyes. She squinted, looking around for Dimitri, “But where’s Dimitri-”`}</p>
    <p>{`“He’s here,” Felix said irritatingly and nodded at the tall figure next to him.`}</p>
    <p>{`She blinked at the blonde man, but this was the person who helped her up just now-`}</p>
    <p><em parentName="p">{`Oh.`}</em></p>
    <p>{`It wasn’t.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      