import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const RemoteLink = makeShortcode("RemoteLink");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Hello everyone, it’s me again! Except this time, I’m not on Twitter spamming hamster tweets. September is my turn to host The Jon’s Creator Showcase (JCS), a community effort to showcase everyone’s wonderful content. Each month, someone from the community will play host and everyone is free to submit content from the previous month for said host to showcase! Since I’m hosting in September, everyone's been submitting content that they did in August. You can find more info about `}<RemoteLink to="https://jonspencerreviews.wordpress.com/jons-creator-showcase/" mdxType="RemoteLink">{`The JCS`}</RemoteLink>{` here.`}</p>
    <p>{`I’ve received 31 submissions and each one of them is really good! I intend on showering each one of these posts with good stuff (No, not weed) to say <3 Without further ado, let’s start! The list is not ordered in any way. The numbering is just there for you and me to keep track of things.`}</p>
    <h2>{`1. Yumdeku - Top 15 Cutest Anime Manga Animals You Desperately Want To Pet`}</h2>
    <p>{`You know, when I used Twitter’s search bar to look up #TheJCS tag, `}<RemoteLink to="https://twitter.com/Yumdeku" mdxType="RemoteLink">{`Yum`}</RemoteLink>{`’s post is the first one that appears. I think Twitter actually pushed his tweet to the top for me since I message him lots among the anibloggers out there. This blog post is a collaboration between me and Yum, where we talk about cute things that tickle our fancy. Yum actually had the gall to add in a cat girl somehow >:( `}<RemoteLink to="https://www.myanime2go.com/2020/08/top-15-cutest-anime-manga-animals-you-desperately-want-to-pet.html" mdxType="RemoteLink">{`Here’s the post, check it out!`}</RemoteLink></p>
    <Image alt="The JCS September 2020 submission 1" caption="Why didn't you add the dog from Gintama instead, Yum? Did catgirl took his spot!!" name="the-jcs-september-2020-1.jpg" mdxType="Image" />
    <h2>{`2. LiteratureFBoi - Defending the Soul Eater Anime’s Final Arc`}</h2>
    <p>{`Hello `}<RemoteLink to="https://twitter.com/LiteratureFBoi" mdxType="RemoteLink">{`Lit`}</RemoteLink>{`, long time no see! I was really happy that you submitted a post because you went MIA for so long. Lit is super earnest to see more Soul Eater content. He did fanfic for it, and even started a tag to garner more attention on Soul Eater. I personally am fond of how the anime handled the ending, so this post just got me nodding from the start to the end. Check out the post here! Lit also did an amazing job on doing his own spin on how the studio will handle Soul Eater S2. Lit, make sure to share that doc with everyone! `}<RemoteLink to="https://fuckboiopinions.wordpress.com/2020/09/01/defending-the-soul-eater-animes-final-arc/" mdxType="RemoteLink">{`I think more folks need to see your stuff. `}</RemoteLink></p>
    <Image alt="The JCS September 2020 submission 2" caption="Lit picked a nice banner for his blog post, ngl." name="the-jcs-september-2020-2.jpg" mdxType="Image" />
    <h2>{`3. simplymk - A very special Enhanced Armament`}</h2>
    <p>{`It’s a post on SAO, anime of the summer! Woo! I totally understand the feeling you get when you watched a really great episode! `}<RemoteLink to="https://twitter.com/simplymknet" mdxType="RemoteLink">{`simplymk`}</RemoteLink>{` here took some really great screencaps and took note of the important scenes in SAO:A S2EP6! `}<RemoteLink to="https://simplymk.net/a-very-special-enhanced-armament/" mdxType="RemoteLink">{`Check out the post here.`}</RemoteLink></p>
    <Image alt="The JCS September 2020 submission 3" caption="To be fair, he has no business being so smug. He was mostly a vegetable in like, many episodes this season." name="the-jcs-september-2020-3.jpg" mdxType="Image" />
    <h2>{`4. The Crow - Fruits Basket Season 2 Episode 21 Review - Best in Show`}</h2>
    <p>{`Ah yes, Fruits Basket. I read the manga once and I don’t think I can do it again in anime-format. It’s part of the reason I didn’t watch Fruits Basket despite knowing how good it is. And I totally get why `}<RemoteLink to="https://twitter.com/CrowsAnimeWorld" mdxType="RemoteLink">{`Crow`}</RemoteLink>{` submitted this Fruit Basket’s episode review. It’s a super sweet episode where we get to take a look at our rat boi’s past, and how Tohru saved him. Sweet, sweet Tohru, we don’t deserve you. Crow does a ton of episode reviews for the anime he watches, `}<RemoteLink to="https://www.crowsworldofanime.com/posts/fruits-basket-season-2-episode-21-review-best-in-show/" mdxType="RemoteLink">{`do check out his blog and post here! `}</RemoteLink></p>
    <Image alt="The JCS September 2020 submission 4" caption="Is rat boi the best boi? I personally really like the cat boi!" name="the-jcs-september-2020-4.jpg" mdxType="Image" />
    <h2>{`5. RandomEditorAni - The Position of Original Anime`}</h2>
    <p>{`I love original anime. It gives the anime studio a chance to prove their dedication to their craft without needing to adhere to the original source! `}<RemoteLink to="https://twitter.com/RandomEditorAni" mdxType="RemoteLink">{`RandomEditorAni`}</RemoteLink>{` talked about how original anime is what it is due to how unpredictable it can be. It’s a gamble, definitely. Funny how I never noticed the early 2000s don’t have that many original anime. Maybe they lack the fundings? I remember how every anime back then starts off with this - `}<strong parentName="p">{`kono bangumi wa goran no sponsor de o christmas`}</strong>{`. `}<RemoteLink to="https://editorani.wordpress.com/2020/08/06/the-position-of-original-anime/" mdxType="RemoteLink">{`Check out the post here!`}</RemoteLink></p>
    <Image alt="The JCS September 2020 submission 5" caption="I really love this graphic you did Rand! I think it's really classy." name="the-jcs-september-2020-5.jpg" mdxType="Image" />
    <h2>{`6. Dewbond - Schrodinger’s Harem - Kirito and the Girls of Sword Art Online`}</h2>
    <p>{`Dewbond went deep into what `}<RemoteLink to="https://twitter.com/ShallowDivesAni" mdxType="RemoteLink">{`he`}</RemoteLink>{` thinks about Kirito and the supposed harem talk that’s been going around the fandom. Dewbond gave a convincing argument on why he thinks it isn’t! That last photo though… I picked the couple photo to go with this `}<RemoteLink to="https://shallowdivesinanime.wordpress.com/2020/08/06/schrodingers-harem-kirito-and-the-girls-of-sword-art-online/" mdxType="RemoteLink">{`write-up`}</RemoteLink>{` because as Dewbond mentioned, the relationship between Asuna and Kirito speaks for itself.`}</p>
    <Image alt="The JCS September 2020 submission 6" caption="Chu chu best SAO couple?" name="the-jcs-september-2020-6.jpg" mdxType="Image" />
    <h2>{`7. Scott & Irina - `}{`[`}{`TOG`}{`]`}{` Morality and Rachel`}</h2>
    <p>{`This post is a collaboration between `}<RemoteLink to="https://twitter.com/MechAnimeReview" mdxType="RemoteLink">{`Scott`}</RemoteLink>{` and `}<RemoteLink to="https://twitter.com/Irina_the_Great" mdxType="RemoteLink">{`Irina`}</RemoteLink>{`, two veteran anibloggers! As a longtime reader of the webtoon Tower of God, I’m `}<strong parentName="p">{`super happy`}</strong>{` to see folks talking about this series. I agree with what the two of you are going on about. While Tower of God is essentially a battle shounen series, it’s the morally grey characters that really did it for me. It was shown again and again in the series that circumstances pushed the characters to do it. Are they bad seeds because of this? Not exactly. SIU made sure to talk about every character’s motivation for doing so. The theme in Tower of God is that each character is morally grey and deserves redemption! Seeing both of you discussing about it makes me super stoked <3 Irina and Scott got some great points, `}<RemoteLink to="https://mechanicalanimereviews.com/2020/08/18/tog-morality-and-rachel/" mdxType="RemoteLink">{`please check out their post here!`}</RemoteLink></p>
    <Image alt="The JCS September 2020 submission 7" caption="Is green lizard girl the best Zahard princess? Of course not, it's YURI! <3 (Runs away)" name="the-jcs-september-2020-7.jpg" mdxType="Image" />
    <h2>{`8. Raistlin Online - Uzumaki, A Japanese Manga by Junji Ito (1999)`}</h2>
    <p>{`When I saw a submission on a Junji Ito manga review, I quivered in mah boots. I don’t do well with horror! ): Thankfully, `}<RemoteLink to="https://twitter.com/online_raistlin" mdxType="RemoteLink">{`Raistlin`}</RemoteLink>{` only added 2 images with minimal horror elements on the blog post. Phew. Raistlin did a great job talking about Uzumaki’s horror basis, especially on how the scary factor is entirely psychological. Wait, really? I could have sworn I’ve seen worse art from Junji Ito such as extra faces, extra eyes, etc.. If Uzumaki is as good as what Raistlin said, I’m gonna have to check it out too! `}<RemoteLink to="https://raistlin0903.wordpress.com/2020/08/12/uzumaki-a-japanese-manga-by-junji-ito-1999/" mdxType="RemoteLink">{`Read the post here.`}</RemoteLink></p>
    <Image alt="The JCS September 2020 submission 8" caption="This is a super nice graphic in terms of font arrangement." name="the-jcs-september-2020-8.jpg" mdxType="Image" />
    <h2>{`9. Ashley Capes - Kids on the slope (Sakamichi no Aporon)`}</h2>
    <p>{`One of the anime in my backlogs destined to `}<strong parentName="p">{`never see the light,`}</strong>{` `}<RemoteLink to="https://twitter.com/Ash_Capes" mdxType="RemoteLink">{`Ashley`}</RemoteLink>{` talks about the joys of youth and music captured super well in this anime. The screencaps are great! I took a look at the clip Ashley added and it’s super pretty. We need more anime like this one, definitely. `}<RemoteLink to="https://thereviewheap.home.blog/2020/08/11/kids-on-the-slope-sakamichi-no-aporon/" mdxType="RemoteLink">{`Okay, you convinced me to watch it!`}</RemoteLink>{` (The day to watch this anime will come… Soon-ish.)`}</p>
    <Image alt="The JCS September 2020 submission 9" caption="Awww the standard blushy cheeks. I love how some artists prefer to add blush to the ears instead, but this banner is equally cute..." name="the-jcs-september-2020-9.jpg" mdxType="Image" />
    <h2>{`10. Moyatori - `}{`[`}{`Spoiler-free`}{`]`}{` Fate/Stay Night: Heaven’s Feel III. Spring Song Review`}</h2>
    <p><strong parentName="p">{`FSN, you again!`}</strong>{` That’s my personal joke whenever I see a Fate/Stay Night series or movie. I’m not super familiar with the VN (All I knew about it is eroge) but I watched the original TV series and the remake one! I’ve been wanting to see the movies but I’m super lost on what’s going on. `}<RemoteLink to="https://twitter.com/The_Moyatorium" mdxType="RemoteLink">{`Moyatori`}</RemoteLink>{` gave a spoiler-free review on the Spring Song movie and I’m super glad that she did. Looks like the movie is a blast to watch and I can’t wait to see it! Moya has a super nice voice in her writing so please check out her other posts as well. `}<RemoteLink to="https://moyatorium.wordpress.com/2020/08/19/spoiler-free-fate-stay-night-heavens-feel-iii-spring-song-review/" mdxType="RemoteLink">{`Read her Spring Song review here.`}</RemoteLink></p>
    <Image alt="The JCS September 2020 submission 10" caption="The older Fate Stay Night art use to be so bad... I love how they kept the original style while improving it in the newer remakes/movies/graphics." name="the-jcs-september-2020-10.png" mdxType="Image" />
    <h2>{`11. iniksbane - The Problem with Shock Value`}</h2>
    <p>{`I saw how excited folks are to watch Perfect Insider for anitwitwatches tag, so I’m super surprised to see that Perfect Insider talks about the relationship between a minor and an adult. To make things worse, they are relatives? That’s effed up, yo. Is Perfect Insider trying to make a statement, or is it just using the plot device to garner attention? Read what `}<RemoteLink to="https://twitter.com/Cameron_Probert" mdxType="RemoteLink">{`iniks`}</RemoteLink>{` think about the series `}<RemoteLink to="https://searchofno9.wordpress.com/2020/08/04/the-problem-with-shock-value-an-anitwitwatches-post/" mdxType="RemoteLink">{`here`}</RemoteLink>{`!`}</p>
    <Image alt="The JCS September 2020 submission 11" caption="Hmmm so they used semi-realistic art for this show." name="the-jcs-september-2020-11.jpg" mdxType="Image" />
    <h2>{`12. Fred Heiser - This is a slow year for anime.`}</h2>
    <p>{`Fred dives right into the anime `}<RemoteLink to="https://twitter.com/AuNaturelOne" mdxType="RemoteLink">{`he`}</RemoteLink>{` watches this season - Cardcaptor Sakura, Honobono Log, YU-NO… Just to name a few. I like your tastes Fred! There’s some I have yet to watch before too. I really enjoyed Aggretsuko and I agree with your assessment of it. I hope more folks watch it too! `}<RemoteLink to="https://aunatural.org/2020/08/23/this-is-a-slow-year-for-anime/" mdxType="RemoteLink">{`Read Fred’s post here.`}</RemoteLink></p>
    <Image alt="The JCS September 2020 submission 12" caption="Oh, the sunset background is lovely!" name="the-jcs-september-2020-12.jpg" mdxType="Image" />
    <h2>{`13. GalvanicTeam - Kosaka Chihiro: From Background to Best Girl | The World God Only Knows`}</h2>
    <p>{`Folks at `}<RemoteLink to="https://twitter.com/GalvanicTeam" mdxType="RemoteLink">{`GalvanicTeam`}</RemoteLink>{` took a gander at The World God Only Knows, a great harem anime back in the days (Okay technically it aired in 2010). I’m enjoying the talk on older anime because there’s a lot of good content to talk about! Since it’s a harem, there’s a lot of best girl debate going on and among the choices, GalvanicTeam chose Kosaka Chihiro! She’s best known for being the plainest but it is exactly this point that makes her appealing <3. Despite the almost convincing argument made by GalvanicTeam, my heart belongs to Ayumi Takahara, the track and field girl <3 `}<RemoteLink to="https://galvanicmedia.net/2020/08/10/kosaka-chihiro-best-girl-the-world-god-only-knows/" mdxType="RemoteLink">{`Watch the video here.`}</RemoteLink></p>
    <Image alt="The JCS September 2020 submission 13" caption="It's bit unfair that this girl is probably the most ignored girl in the series. I did watch the anime but the my memory of the manga is much fresher here. I remember the other girls (especially the one who wants to dress up as a boy if I am not mistaken) got a ton of dedicated chapters." name="the-jcs-september-2020-13.jpg" mdxType="Image" />
    <h2>{`14. Al Pal - Wonder 3 Review`}</h2>
    <p>{`This is great, another Osamu Tezuka manga review! A quality post right here. I only read a few Osamu Tezuka works and they all explore some interesting ideas... I expected to see some funky plot in Wonder 3 and I wasn’t disappointed at all! Hmm… Maybe I’ll go check out Wonder 3 too. Read the review by `}<RemoteLink to="https://twitter.com/AlyssaTwriter" mdxType="RemoteLink">{`Al Pal`}</RemoteLink>{` `}<RemoteLink to="https://alsmangablog.wordpress.com/2020/08/15/wonder-3-review/" mdxType="RemoteLink">{`here`}</RemoteLink>{`!`}</p>
    <Image alt="The JCS September 2020 submission 14" caption="This is a wonderful cover, loving the streaks here!" name="the-jcs-september-2020-14.jpg" mdxType="Image" />
    <h2>{`15. K at the Movies - The First Hero, The Worst hero? Blackstar v. Golden Bat Review `}{`[`}{`Video`}{`]`}</h2>
    <p>{`When I read the title, I’m `}<em parentName="p">{`super lost`}</em>{` on what to expect. Uhhh… What’s this about? Oh, it’s 18 minutes about heroes. Okay, so Blackstar is a hero from the west and Golden Bat from the east. Okayyyy Golden Bat doesn’t really look like a hero, even for Japan’s standard! The flow of the video is super hilarious, I chuckled at some jokes. Seriously `}<RemoteLink to="https://twitter.com/K_at_the_movies" mdxType="RemoteLink">{`K`}</RemoteLink>{`, where on earth did you find these shows/clips to watch! I honestly didn’t know what to expect from this video but it’s really fun to watch. `}<RemoteLink to="https://katthemovies.wordpress.com/2020/08/27/the-first-hero-the-worst-hero-blackstar-v-golden-bat-review-video/" mdxType="RemoteLink">{`Watch the video here!`}</RemoteLink>{` (Thank you for adding the Azura scene from Fire Emblem Fates, that was hilarious)`}</p>
    <Image alt="The JCS September 2020 submission 15" caption="Apparently this is a hero in Japan?!" name="the-jcs-september-2020-15.jpg" mdxType="Image" />
    <h2>{`16. Jack Scheibelein - DOLLHOUSE: THE ERADICATION OF FEMALE SUBJECTIVITY FROM AMERICAN POPULAR CULTURE” IS THE WORST FILM I HAVE EVER SEEN. YOU SHOULD STILL WATCH IT.`}</h2>
    <p>{`Jack `}<RemoteLink to="https://twitter.com/JScheibelein" mdxType="RemoteLink">{`reviewed`}</RemoteLink>{` an interesting movie titled “Dollhouse”. The movie tackles issues which I’m not familiar with. Firstly, I am not a local and as such, I do not fully understand the context of said issue, as well as the local’s thoughts on it. It’s an interesting review, thanks for submitting this Jack! `}<RemoteLink to="https://animatedobservations.com/2020/08/09/dollhouse-the-eradication-of-female-subjectivity-from-american-popular-culture-is-the-worst-film-i-have-ever-seen-you-should-still-watch-it/ https://twitter.com/JScheibelein" mdxType="RemoteLink">{`Please read the review on Dollhouse here.`}</RemoteLink></p>
    <Image alt="The JCS September 2020 submission 16" caption="As the title implies (duh), everything is a doll or doll-sized here!" name="the-jcs-september-2020-16.jpg" mdxType="Image" />
    <h2>{`17. Matt-in-the-Hat - July 2020 Funko Pop haul`}</h2>
    <p>{`Matt submitted a post where `}<RemoteLink to="https://twitter.com/MattXnVHat" mdxType="RemoteLink">{`he`}</RemoteLink>{` told us all about his new Funko Pop collection! I must say, that’s a nice looking Deadpool. And I wasn’t aware Funko Pop did Kingdom Hearts too! Please share all 59 of your figures sitting on your shelves in one big photo next time, Matt! It must be a sight to behold!`}</p>
    <p>{`Check out his post `}<RemoteLink to="https://matt-in-the-hat.com/2020/08/26/july-2020-funko-pop-haul/" mdxType="RemoteLink">{`here`}</RemoteLink>{` and the `}<RemoteLink to="https://matt-in-the-hat.com/?s=funko+pop+haul&submit=Search" mdxType="RemoteLink">{`rest of his Funko Pop collection here`}</RemoteLink>{`.`}</p>
    <Image alt="The JCS September 2020 submission 17" caption="Huh, never realized Funko's logo has a small crown on it." name="the-jcs-september-2020-17.jpg" mdxType="Image" />
    <h2>{`18. Infinite Zenith - Kirakira Special Issue: Celebrating Astronomy and Earth Science in the Koisuru Asteroid Mini-Animations`}</h2>
    <p>{`This is a `}<strong parentName="p">{`wonderful`}</strong>{` post explaining the fine details that you may have missed in Koisuru Asteroid (Asteroid in Love). I never watched this anime before but I can surmise it’s basically an anime about astronomy and girls doing cute things? You can’t say no to girls doing cute things! `}<RemoteLink to="https://twitter.com/InfiniteZenith" mdxType="RemoteLink">{`Zenith`}</RemoteLink>{` is a pro in astronomy and he happily explained everything he knew (along with some beautiful shots of the night sky) and `}<RemoteLink to="https://infinitemirai.wordpress.com/2020/08/04/kirakira-special-issue-celebrating-astronomy-and-earth-science-in-the-koisuru-asteroid-mini-animations/" mdxType="RemoteLink">{`noticed in this anime`}</RemoteLink>{`. I love posts like this, thank you Zenith! Please do share more night sky photos on Twitter!`}</p>
    <Image alt="The JCS September 2020 submission 18" caption="Lovely!" name="the-jcs-september-2020-18.jpg" mdxType="Image" />
    <h2>{`19. Naja B. - 5 Women of Color Lead Indie Games You Should Be Playing`}</h2>
    <p>{`Naja's blog background is super nice, I love it! And I love how she asked game developers on Twitter in order to write this blog post, that’s so wholesome. `}<RemoteLink to="https://twitter.com/BeckNaja" mdxType="RemoteLink">{`Naja`}</RemoteLink>{` wrote a compelling blog post to shed light on the indie romance games featuring Black and Brown female leads! Some of these games are either free or only cost a few dollars, please do consider trying them out! `}<RemoteLink to="https://blerdyotome.com/2020/08/24/5-women-of-color-lead-indie-games-you-should-be-playing/" mdxType="RemoteLink">{`Check out the blog post here.`}</RemoteLink></p>
    <Image alt="The JCS September 2020 submission 19" caption="I love how wholesome it is Naja's post is <3" name="the-jcs-september-2020-19.jpg" mdxType="Image" />
    <h2>{`20. Jon Spencer Reviews - My 4 Year Journey with Hunter x Hunter (2011)`}</h2>
    <p>{`Hunter x Hunter (both 2011 and 1999) are really great shows. I’m one of those fans who would say `}<em parentName="p">{`“It’s good, please give it a try!”`}</em>{` whenever someone asks if they should watch the show. Jon wrote an interesting piece on his experience with Hiatus x Hiatus which eventually led him to buying the whole set so he can watch it at his own pace. Does `}<RemoteLink to="https://twitter.com/JS_Reviews" mdxType="RemoteLink">{`Jon`}</RemoteLink>{` think it’s good? Well, judging from the amount of praising tweets from him, I’d say he likes it lots! `}<RemoteLink to="https://jonspencerreviews.wordpress.com/2020/08/12/my-4-year-journey-with-hunter-x-hunter-2011/" mdxType="RemoteLink">{`Please do give his blog post a look`}</RemoteLink>{` <3`}</p>
    <Image alt="The JCS September 2020 submission 20" caption="Gon, your dad's gon. Killua, your bro's a killer. As for the other two? Please wait a few story arcs before seeing them again <3" name="the-jcs-september-2020-20.jpg" mdxType="Image" />
    <h2>{`21. Two Happy Cats - 4 Horror Manga I Can’t Get Out Of My Head`}</h2>
    <p>{`Oh lord oh god another horror blog post submission! I scrolled really fast to hide the third image, I found it super creepy… If this blog post is meant to give the readers a lasting impression, I think it did the job really well! `}<RemoteLink to="https://twitter.com/TwoHappyCats" mdxType="RemoteLink">{`Two Happy Cats`}</RemoteLink>{` recommended 4 horror manga in this blog post and it includes a Junji Ito title :O `}<RemoteLink to="https://twohappycats.com/2020/08/11/4-disturbing-horror-manga/" mdxType="RemoteLink">{`Quality horror post right here! `}</RemoteLink></p>
    <Image alt="The JCS September 2020 submission 21" caption="YO YO YO IS THAT THE LADY THAT OPENS HER MOUTH TO EAT PEOPLE'S FACE :O" name="the-jcs-september-2020-21.jpg" mdxType="Image" />
    <h2>{`22. A K - On the use of public office to suppress the display and sale of artistic works`}</h2>
    <p>{`I’m sure everyone has heard of the news, about how an Australian senator banned the sale of certain Japanese light novels and the likes. Here’s the `}<RemoteLink to="https://www.canberratimes.com.au/story/6650325/senator-calls-for-child-abuse-anime-review/" mdxType="RemoteLink">{`news link`}</RemoteLink>{` explaining what’s going on. `}<RemoteLink to="https://twitter.com/sonata_no1" mdxType="RemoteLink">{`A K `}</RemoteLink>{`explained his stance on the whole issue, on how it’s clearly not fair for the involved light novels just because a politician `}<strong parentName="p">{`thinks`}</strong>{` the girls in said novels look like children. He gives an in-depth explanation on how the banning shouldn’t have happened in the first place. `}<RemoteLink to="https://sonatano1.wordpress.com/2020/09/04/on-the-use-of-public-office-to-suppress-the-display-and-sale-of-artistic-works/" mdxType="RemoteLink">{`Read more here! `}</RemoteLink></p>
    <Image alt="The JCS September 2020 submission 22" caption="YOU MEN LEAVE THE POOR LADY ALONE" name="the-jcs-september-2020-22.jpg" mdxType="Image" />
    <h2>{`23. Aria - Watch Out for Kaiju No. 8! (An Exercise in Translation)`}</h2>
    <p>{`Yes, another post on Kaiju No. 8! Aria went in-depth on each English translation differences between Viz’s and Manga Plus’s for Kaiju No. 8 that she noticed. Wait, aren’t Manga Plus’s translations basically by Viz’s, or did I miss out something? It’s an interesting post since the manga just started and thus, the special terms haven’t really sinked in yet. Thank you for an interesting post `}<RemoteLink to="https://twitter.com/MagicConan14" mdxType="RemoteLink">{`Aria`}</RemoteLink>{`! Please read it `}<RemoteLink to="https://animangaspellbook.wordpress.com/2020/08/05/watch-out-for-kaiju-no-8-an-exercise-in-translation/" mdxType="RemoteLink">{`here`}</RemoteLink>{`.`}</p>
    <h2>{`24. Cody LaTosh - Robot Carnival`}</h2>
    <p>{`Cody, your blog looks great! I love how the score just goes woosh woosh, zoom zoom when I load the page. `}<RemoteLink to="https://twitter.com/CodyLatosh" mdxType="RemoteLink">{`Cody`}</RemoteLink>{` mentioned how millennials don’t bother with classics which is super strange. I mean, if someone recommends a film and a way to watch it easily somehow, I’m sure I’ll go watch it asap! This blog post is a review of Robot Carnival, an anthological OVA with 9 directors. Interesting stuff… Thank you for bringing this to everyone’s attention! `}<RemoteLink to="https://bakanow.com/robot-carnival/" mdxType="RemoteLink">{`Read the post here.`}</RemoteLink></p>
    <Image alt="The JCS September 2020 submission 24" caption="This is such a nice poster! Loving the frame and the aesthetics." name="the-jcs-september-2020-23.jpg" mdxType="Image" />
    <h2>{`25. Celestial_Sparkles60 - Time To Be Unpopular With the Unpopular Opinions Film and TV Tag! (East vs West Edition)`}</h2>
    <p>{`I think this is the first I’ve seen a tag post about unpopular opinions! `}<RemoteLink to="https://twitter.com/CSparkles60" mdxType="RemoteLink">{`Celestial`}</RemoteLink>{` answered every question asking for an unpopular opinion that she may have by choosing 1 series or character from both east and west respectively. Wait, you don’t want to watch One Piece? And you don’t like the Pokemon anime? `}{`>`}{`:( `}<RemoteLink to="https://howanimestuffworks176744600.wordpress.com/2020/09/02/time-to-be-unpopular-with-the-unpopular-opinions-film-and-tv-tag-east-vs-west-edition/" mdxType="RemoteLink">{`Read the post here! `}</RemoteLink></p>
    <Image alt="The JCS September 2020 submission 25" caption="She's screaming, YUKI I'M GETTING MY AXE YOU JUST WAIT RIGHT THERE. No, that's just what I think she's screaming." name="the-jcs-september-2020-24.png" mdxType="Image" />
    <h2>{`26. Yu Alexius - 12 Donghua (Chinese Anime) Recommendations for Female Viewers`}</h2>
    <RemoteLink to="https://twitter.com/YuAlexius" mdxType="RemoteLink">Yu</RemoteLink> is one of the unique
bloggers who would cover donghua instead of japanimation. I knew about the flourishing
donghua industry, but I didn’t know it’s gotten this big! Yu recommended 12 donghua
which I had a ton of fun reading the summaries. I might try Bringing Back Home Mr.
Perfect (*Guomin Laogong Dai Huijia), it sounds delightfully bittersweet.
    <RemoteLink to="https://www.yualexius.com/2020/08/donghua-chinese-anime-for-female-viewers.html" mdxType="RemoteLink">
  Read Yu’s blog post here.
    </RemoteLink>
    <Image alt="The JCS September 2020 submission 26" caption="Yu has mastered creating the perfect banner! The font and image choices are super nice." name="the-jcs-september-2020-25.jpg" mdxType="Image" />
    <h2>{`27. Lynn Sheridan - Deca-Dence (Prediction) - A Bald-faced Lie`}</h2>
    <p>{`I’m super interested in Deca-Dence since the trailer is out but I haven’t watched it yet. `}<RemoteLink to="https://twitter.com/TheOtakuAuthor" mdxType="RemoteLink">{`Lynn`}</RemoteLink>{` gives his low-down on what he thinks is happening in the background. Gosh, there’s some tech elements in it. I never knew… The avatars piqued my interest! Oh, the series finished airing already? Well, well… Guess what’s next on my watch list. I’ll come back again to read this once I’m done with the series. `}<RemoteLink to="https://otakuauthor.com/deca-dence-prediction-a-bald-faced-lie/" mdxType="RemoteLink">{`Read the post here!`}</RemoteLink></p>
    <Image alt="The JCS September 2020 submission 27" caption="The art looks pretty good here! ... Okay maybe cos he looks a bit like my type so I'm biased." name="the-jcs-september-2020-26.jpg" mdxType="Image" />
    <h2>{`28. Emiko - List: Dear Webtoon, Gimme Back My Heart`}</h2>
    <p>{`Okay so before I begin talking about the post… Oh my god that art you did for the post’s banner is `}<strong parentName="p">{`adorable`}</strong>{`!!!! And did you draw the rest of the images?? It’s so cuteeee!! `}<RemoteLink to="https://twitter.com/emikothewriter" mdxType="RemoteLink">{`Emiko`}</RemoteLink>{` talked about the 5 webtoon titles she enjoyed reading on the WEBTOON platform. I personally only read Your Throne so the other 4 is a surprise! `}<RemoteLink to="https://emikothewriter.wordpress.com/2020/08/02/list-dear-webtoon-gimme-back-my-heart/" mdxType="RemoteLink">{`Check out her cute art and post here.`}</RemoteLink></p>
    <Image alt="The JCS September 2020 submission 28" caption="No, no. Let WEBTOON take your heart! I'll give my heart to you as replacement cos this art is super adorable!!!" name="the-jcs-september-2020-27.jpg" mdxType="Image" />
    <h2>{`29. Minty - WEBTOON|Bunny & Her Boys - Bishies, Bishies Everywhere (aka The Dream)`}</h2>
    <p>{`Oh, mama mia… `}<strong parentName="p">{`HOT`}</strong>{`. Ahem! `}<RemoteLink to="https://twitter.com/piecesofminty" mdxType="RemoteLink">{`Minty`}</RemoteLink>{` talks about this webtoon title published on Daum (an equally popular webtoon platform) titled Bunny & Her Boys. I love this blog post because there’s so much gushing and energy in it. Like, the gifs in this post just screams fangirl excitement to me and it’s so adorbs. `}<RemoteLink to="https://blackandyellowotakugamers.com/2020/08/05/webtoon-bunnyherboys/" mdxType="RemoteLink">{`Read the post here!`}</RemoteLink></p>
    <Image alt="The JCS September 2020 submission 29" caption="There's a ton of webtoon that opted for this scribbly/casual art like Bunny & Her Boys right here. The colours are soft so it's very inviting to read!" name="the-jcs-september-2020-28.jpg" mdxType="Image" />
    <h2>{`30. IllusionPurple - Goodbye Forever: Digimon Adventure: Last Evolution Kizuna`}</h2>
    <p>{`Now this is rare. A post on Digimon! `}<RemoteLink to="https://twitter.com/illusionpurple" mdxType="RemoteLink">{`Illusionpurple`}</RemoteLink>{` reviewed the latest Digimon movie that serves as a farewell to the kids from Season 1. I finished Tri and will watch Kizuna one day. After reading the post, I’m not too sure if I should... `}<RemoteLink to="https://mirrorpurple.moe/2020/08/01/goodbye-forever-digimon-adventure-last-evolution-kizuna/" mdxType="RemoteLink">{`Read his impression on the movie here!`}</RemoteLink></p>
    <Image alt="The JCS September 2020 submission 30" caption="Bye, you won't be missed. (runs away)" name="the-jcs-september-2020-29.jpg" mdxType="Image" />
    <h2>{`31. Animated Andy - Tone Setters: Clannad’s Wistful First Episode`}</h2>
    <p>{`Yes, a post on Clannad! Wonderful! `}<RemoteLink to="https://twitter.com/Animated_Andy" mdxType="RemoteLink">{`Andy`}</RemoteLink>{` talks about how Episode 1 sets the tone and mood for the series with subtle imagery and music. I totally get the feeling when you re-watched an episode after so many years just to notice the minor details now! You just go WHOAAA and your appreciation for the series soars up even higher. Thank you for revisiting an old anime, Andy! `}<RemoteLink to="https://animatedandy.com/2020/08/10/tone-setters-clannads-wistful-first-episode/" mdxType="RemoteLink">{`Read the post here.`}</RemoteLink></p>
    <Image alt="The JCS September 2020 submission 31" caption="I want to see sakura blossoms blooming in full one day too ): It must be a great sight to behold..." name="the-jcs-september-2020-30.jpg" mdxType="Image" />
    <h2>{`All in all`}</h2>
    <p>{`And that’s all folks! Thank you for having me as host for The JCS September 2020, I enjoyed reading everyone's post. All the submissions are super different from one another, and I was exposed to new titles too! It's been a blast <3 I’ll be passing the baton to `}<RemoteLink to="https://twitter.com/MagicConan14" mdxType="RemoteLink">{`Aria`}</RemoteLink>{` for The JCS October 2020!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      