import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const LocalLink = makeShortcode("LocalLink");
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I’m stoked, I’m `}<strong parentName="p">{`floored`}</strong>{`! Mushoku Tensei Episode 1 is out in the wild! YASSS! I first mentioned about Mushoku Tensei in this blog here where `}<LocalLink to="/blog/my-personal-top-3-isekai-when-it-comes-to-storytelling/" mdxType="LocalLink">{`I explained how much I love these three isekai(s) the first time I read their light novels`}</LocalLink>{`! If you’re interested, please give said post a read.You could argue that Mushoku Tensei is the part of the OG isekai series that brought truck-kun to the genre. This series means a lot to me because not only did the story develop the magical system as detailed as possible, the cast speaks and practices family ties. This is something many isekai(s) failed to do. They got an idea, they knew how to do the cliches, they knew what hooks folks into isekai(s) but they failed to make me feel invested in the characters.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Mushoku Tensei’s main character is a 34 year old NEET who failed to practice filial piety to his own family. He refuses to change himself for the better and when he thinks it’s time, he died and was reincarnated to a magical world with adventurers as Rudeus Greyrat. Okay, so far it `}<em parentName="p">{`sounds`}</em>{` and `}<strong parentName="p">{`feels`}</strong>{` like an isekai. Little did you know that they masterfully compressed a ton of progress into a single 20-minute episode. I’ll explain the details that I remember as I go!`}</p>
    <h2>{`MC NEET dies and was reincarnated`}</h2>
    <p>{`I remember reading the light novel and the author took the time to give a small exposition on our MC’s NEET life. His thoughts are super dreary and self-immolating but in the anime, it was quickly zoomed past. Instead, we were told that he saved 3 students. 1 survived while the other 2 are missing. I’m not sure if the OG light novel talked about this, but I’m pretty sure the Syosetu one didn’t touch upon this information until midway in the story. If this is a way for the anime to hint at bringing forward a later arc, then I’m stoked.`}</p>
    <Image alt="Mushoku Tensei anime screencap 1" caption="He keeps looking around because he's feeling anxious. He has stayed indoors even as a baby all this while." name="mushoku-tensei-anime-1.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`Discovering chantless magic`}</h2>
    <p>{`Like every light novel, Rudeus took a sweet time growing up and discovering what magic can do. In fact, this part is a huge chunk of the story. He realizes that he is in a world of magic after his new mother, Zenith casted healing magic from books. After this discovery, he would sneak out of his crib to learn about magic. The adults didn't know he could read! His monologue on how he discovered the magical laws are also nicely explained. I think it’s actually really amazing that they did it all within a single episode because it took him a long time to notice these “laws”.`}</p>
    <h2>{`Rudeus’s uncanny behaviour as a baby`}</h2>
    <p>{`The maid Lillia is the first to notice the strangeness of Rudeus as a baby. She actually realized that Rudeus has been staring at her with… lewd eyes. They even added the part where Paul and Zenith are doing their nightly activities too! `}<em parentName="p">{`Snort`}</em>{`. I personally think this is great (Not because of the activity) because this sets the mood that there may be naughty stuff later on. Most viewers get shocked if you just drop them an ecchi bomb so I’m glad they are showing bits of it in Episode 1. Yes, Rudeus’s thoughts sound like an old man in his thirties because IT IS! If you are watching this anime innocently, let me give you a fair warning. If the scene with Paul and Zenith doing it bothers you, then you may have a problem with the upcoming episodes. They won’t show it explicitly (obviously) but the episode will surely hint at it.`}</p>
    <Image alt="Mushoku Tensei anime screencap 2" caption="The boonies are so pretty :O He blasted a wall open because he trained his magic so hard, his MP pool increased a lot." name="mushoku-tensei-anime-4.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`Roxy’s unexpected appearance at Episode 1`}</h2>
    <p>{`I didn’t expect to see Roxy appearing at Episode 1! What a surprise because that was fast! Despite how quick it is, I’m really happy at how they cherry picked all of the important details and compiled it nicely into 1 episode. That’s some masterful rearrangement! This goes without saying but Roxy is an important character to Rudeus. I look forward to seeing how they handled the uh, p`}{`*`}{`*`}{`*`}{`y scene later on.`}</p>
    <h2>{`Beautiful background art and art style`}</h2>
    <p>{`I admit, I did not expect to see them putting all of their effort into the background art. It’s beautiful. The magical effects are nice to look at too! Rudeus enjoy exploring magic in this world. While he's not too shabby with a sword, he is more proficient in magic. I'm happy that there's no magical circle that appeared out of nowhere with no explanations in an isekai. This time, magic is properly explained.`}</p>
    <Image alt="Mushoku Tensei anime screencap 4" caption="Ohhh the tree trunk growing back is such a nice effect." name="mushoku-tensei-anime-3.jpg" mdxType="Image" />
    <Image alt="Mushoku Tensei anime screencap 3" caption="Roxy showing him the basics of magic is nice. I'm enjoying how they portrayed magic! I can't wait to see the sword fights <3" name="mushoku-tensei-anime-2.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`All in all`}</h2>
    <p>{`I hope this is a 2-cour series. I love it. If you’re in Southeast Asia, you can watch the first episode for free (720p) on Muse Asia’s YouTube account. I added the video below for you to enjoy!`}</p>
    <Video title="muse asia mushoku tensei" src="https://www.youtube.com/embed/SoKGAOpUw_E" mdxType="Video" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      