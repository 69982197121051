import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const Blockquote = makeShortcode("Blockquote");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Greetings Geeknabe readers. As you may have realized, my blog posts lately have slowed down by a lot. I really `}<strong parentName="p">{`do want`}</strong>{` to write new posts (I have a stack of them in my ideas and draft folder!) but there’s this Nintendo Switch game I’ve recently purchased that I’m `}<em parentName="p">{`addicted to.`}</em>{` It’s taken all my strength to pull myself away from the game to start blogging again. No, it’s not `}<strong parentName="p">{`Animal Crossing: New Horizons`}</strong>{`. It’s `}<strong parentName="p">{`The Legend of Heroes: Trails of Cold Steel III`}</strong>{`! It’s only Day 4 since I got the game, but I have already sunk in 51 hours into it. I’m at Chapter 3 now but I’m not sure if I’m even halfway through the game. This game relies heavily on the plot for immersion so I’m trying not to Google for information on it…`}</p>
    <p><em parentName="p">{`Note: May include spoilers.`}</em></p>
    <Ads mdxType="Ads" />
    <p>{`Trails games is a series of continuous plot-based JRPG. Characters from older games get to come back either as a playable character or gets subtly hinted in the story. Either way, you definitely `}<strong parentName="p">{`need`}</strong>{` to play the older games to get the full immersion here. There’s a lot of throwbacks to older games’ events which kinda makes me lost sometimes. Many of my friends told me I should play Trails of Cold Steel I and II first so I won’t miss out that much but I said `}<strong parentName="p">{`screw it.`}</strong>{` I’ll watch them on YouTube if I have to since I don’t think I have the dedication and time to play the prequels (Including Trails in the Sky).`}</p>
    <p>{`This game lets you control Rean Schwarzer, the titular protagonist from Cold Steel I and II who now works as an instructor at Thor’s Branch Campus in Cold Steel III. I think this is a great starting point since I could easily get acquainted with the new Class VII. Since it’s the `}<em parentName="p">{`new`}</em>{` `}<em parentName="p">{`Class VII`}</em>{`, there’s a ton of throwbacks to the old Class VII. Heck, the old Class VII members even made frequent appearances throughout the story. You can even use them as party members! The new Class VII members are fine, but here’s the problem with me starting off with Cold Steel III… `}<strong parentName="p">{`I don’t know these characters.`}</strong>{` Fans would probably go gaga at the glow up but here I am, just using these characters in battle without knowing what made them `}<strong parentName="p">{`shine`}</strong>{` in Cold Steel I and II.`}</p>
    <p>{`To be fair, it didn’t impede on my enjoyment of the game. As a matter of fact, these `}<strong parentName="p">{`throwbacks`}</strong>{` just make me wanna`}<em parentName="p">{` know more`}</em>{` about the old cast! The designs of the old Class VII are great! I really like how they designed the men. Jusis screams `}<em parentName="p">{`noble`}</em>{` to me, while Machias looks every bit of an inspector I expected him to be. The girls’ designs have a `}<em parentName="p">{`“slender beauty”-feel `}</em>{`to them. I think purple really does suit Emma! I only controlled Fie for a bit so I do not have a strong opinion on her design or character, but my first impression on her design as an up-and-coming Bracer fits her to a tee. Did I mention how alluring Sara is as Purple Lightning? Her sword sweep animation in battle is seriously something! I could keep going, but we’re here to talk about my favourite waifu of them all <3`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Blushy-crushy <3" caption="She's blushing after getting teased by Sharon, it's so adorable <3" name="alisa-reinford-trails-of-cold-steel-3-4.jpg" mdxType="Image" />
    <Blockquote mdxType="Blockquote">
  As the title implied, it’s Alisa Reinford. Let me explain why she is top notch
  to me…
    </Blockquote>
    <p>{`Okay so first and foremost, this is a new blog post series that I gush about characters `}<strong parentName="p">{`I like`}</strong>{`. The reasons behind my bias could be logical, illogical or whatnot, it doesn’t matter. `}<strong parentName="p">{`I basically like this character and I would like to talk about it.`}</strong>{` That is the essence of this blog post!`}</p>
    <p>{`So why Alisa Reinford? If you follow my Twitter, you’d know that my personal preference is a `}<strong parentName="p">{`tsun`}</strong>{`. Tsuns are great, even better than Kuus and Yans in my honest opinion. It’s super moe to see Alisa just gushing about how she likes Rean (subtly) but denies it later. If you did Rean + Alisa’s bonding events, you could tell both parties `}<strong parentName="p">{`do like each other`}</strong>{` and I just go `}<strong parentName="p">{`super moe mode <3`}</strong>{` Sharon (her deadly maid) won’t stop teasing her about it and `}<strong parentName="p">{`IT. IS. GREAT.`}</strong>{` Thank you Sharon, you did what every tsun fan would do -`}<em parentName="p">{`tease the hell outta them`}</em>{` for some delicious reactions. This is why it’s so easy to tell a tsundere apart! (Plus, they have the cutest reactions) It’s part of the reason why I like tsuns a lot. As for why I didn’t really like kuus and yans, it takes `}<strong parentName="p">{`SKILLS`}</strong>{` to tell a kuudere from a normal character apart. Is this lady being a kuu, or is that just how she rolls? Seriously, please just tell the viewers/readers outright! As for yanderes, they just `}<strong parentName="p">{`scare me.`}</strong>{` (Please, put that axe away)`}</p>
    <Image alt="null" caption="Alisa Reinford's S-Craft mixes fire and angelic wings, lord it suits her to a tee!" name="alisa-reinford-trails-of-cold-steel-3-2.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <p>{`It also helps that Alisa is both an `}<strong parentName="p">{`ojousama`}</strong>{` with a successful career as a corporate lady. You see, ojousama that drink tea, giggles like a fairy and have perfect manners are `}<em parentName="p">{`fine on their own`}</em>{`. These characters deserve their own category `}<strong parentName="p">{`WHICH`}</strong>{` Alisa isn’t a part of. She’s part of the work hard, and act nobly character category! Besides, nothing screams `}<strong parentName="p">{`GOOD JOB`}</strong>{` other than combining an ojousama with looks, brains and hard work to go with. I’m really biased towards characters that got to where they are through hard work. It makes all their successes so much deserving! Yes, you could argue that Alisa’s position is guaranteed due to her family business `}<strong parentName="p">{`BUT`}</strong>{` you could see that she actually `}<strong parentName="p">{`works for it`}</strong>{`. I personally `}<em parentName="p">{`really, really`}</em>{` look up to a woman that `}<em parentName="p">{`works hard`}</em>{` despite the hardships thrown at her so this part of her just `}<strong parentName="p">{`cements`}</strong>{` her as my waifu in this game.`}</p>
    <p>{`The second part of Alisa that I really like (looking at) is her clothing design. Not only does it look like a perfect professional casual look for meetings, it looks good for dungeon exploring. Her vest has an upright collar and a third-quarter sleeve with an open cuff…. `}<strong parentName="p">{`Perfect`}</strong>{`. I’ve always enjoyed third-quarter sleeves in office wear. I find them really elegant `}<strong parentName="p">{`and`}</strong>{` practical for both hot and cold places. Offices are often air-conditioned but going out for lunch is a super hot walk for me (I live in a tropical country!), so third-quarter sleeves are just nice for me. It’s not too cold in the office with these sleeves, and I don’t have to roll them up if it’s really hot out there. I could go on and on about how I really like women’s office wear but all in all, the highlight of said outfit is most definitely, the sleeves with cuffs, the collar and `}<strong parentName="p">{`THE PENCIL SKIRT.`}</strong>{` Dresses as office wear is nice but honestly, nothing beats the allure of a pencil skirt! Pencil skirt is a high-waisted, body-hugging skirt `}<strong parentName="p">{`AND`}</strong>{` I love how professional it looks! It’s one of my favourite outfits if `}<em parentName="p">{`I can wear it ):`}</em>{` If you can pull off the look, know that I am super jealous of you!`}</p>
    <Image alt="Alisa Reinford full character art" caption="Here's the full body character art for Alisa so everyone can appreciate it <3" name="alisa-reinford-trails-of-cold-steel-3.png" mdxType="Image" />
    <p>{`Alisa’s clothing design has everything I `}<strong parentName="p">{`really like`}</strong>{` for a ladies office wear. Not to mention the colours are not candidly colourful compared to the rest of the casts’ design. It’s simple yet professional-looking and elegant. The designer has nailed Alisa’s very own character through her outfit <3And I love what they did to her hair! It’s a hairstyle that I think really suits Alisa. In Cold Steel I and II, she wears her hair in a twin ponytail which gives off a childish, youthful look. Now that she’s older, she sports a side ponytail. True, ponytails is her trademark so it's hard to change it to a different hairstyle. But the side ponytail is a brilliant choice! Her current hairstyle gives her a sweet, youthful first impression while still looking like a young adult in her early twenties. On the plus side, she uses a `}<strong parentName="p">{`BOW`}</strong>{`as her main weapon. `}<strong parentName="p">{`Not a shortbow or a crossbow, but a beautifully designed BOW.`}</strong>{` Urgh I’m a sucker for these bows so extra 100 points for `}<strong parentName="p">{`elegance`}</strong>{`.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Everything about her just lends credence to her character - a corporate young lady who has `}<strong parentName="p">{`yet to fully mature into her role`}</strong>{`. She’s hardworking and cares for her friends, albeit a bit shy when it comes to her love life.`}<em parentName="p">{` The bow is an extra accessory <3`}</em>{` She's so perfect, I fell in love with her character when she was first introduced in Cold Steel III. Her character is so endearing and her clothings match her to a tee. I made a post just to say that <3`}</p>
    <p>{`Thank you for reading.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      