import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Blockquote = makeShortcode("Blockquote");
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const RemoteLink = makeShortcode("RemoteLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`MERRY BELATED CHRISTMAS & HAPPY NEW YEAR!  (the new year is upon us and Nintendo has already claimed the contents of my wallet, haha help)ANYWAY. HELLO. SIZE MATTERS. Get your mind of out the gutter, I'm talking about hand size! And other sizes... `}<em parentName="p">{`ANYWAY`}</em>{`. This is the one key point is that is relevant for a few things when you're buying accessories for your Nintendo Switch. Before I went ahead with buying my grip case, I watched about ..3 videos on youtube? It had just released so there weren't many videos out yet. And they said the same thing;`}</p>
    <Blockquote mdxType="Blockquote">
  If you have big/large/meaty hands, this is perfect for you
    </Blockquote>
    <p>{`All three videos were made by men with normal-sized man hands, which are noticeably bigger than mine. It was something I overlooked. I know, `}<em parentName="p">{`genius`}</em>{`. (disclaimer: I have nothing against men, just stating my observations and my stupidity for not noticing that)`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Man and woman's hand size differences." caption="Top: a man's hand,  bottom: my hands" name="woman_man_hand_size.jpg" mdxType="Image" />
    <p>{`So if you're doing your research on shopping for your switch, here's why `}<strong parentName="p">{`Size Matters;`}</strong>{`## What fits for them may not fit for you`}</p>
    <p>{`Yes, your small hands will be able to hold on the grip, but keep in mind it might be uncomfortable. Some cases may be built larger or longer to increase comfort for `}<em parentName="p">{`some`}</em>{` people. For me, the switch is already quite hefty and bulky on its own, so the added length is very tiring to hold for long gaming sessions.`}</p>
    <p>{`I was slightly annoyed that `}<RemoteLink to="https://www.amazon.com/Dockable-Case-Nintendo-Switch-Joy/dp/B07TDLRFKK" mdxType="RemoteLink">{`Mumba`}</RemoteLink>{` made a '`}<RemoteLink to="https://www.amazon.com/Mumba-Protective-Nintendo-Shock-Absorption-Anti-Scratch/dp/B07T8GF7JS" mdxType="RemoteLink">{`Girl Power`}</RemoteLink>{`' case, because 'Why can't they make cool/slick designs for girls? Why all the pink and rose gold??' or 'So what if a guy has smaller hands and wants to use this case but feels limited because it's 'GIRL POWER'?? Ok, it's a beautiful case, but you know what I mean.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Anyway, it's good that Mumba made a smaller case however it's designed because the `}<RemoteLink to="https://www.amazon.com/Dockable-Case-Nintendo-Switch-Joy/dp/B07TDLRFKK" mdxType="RemoteLink">{`Mumba Blade Case`}</RemoteLink>{` is horribly uncomfortable for me. It's like sitting in the front seat of a car and you feel someone pressing their knees against your back.`}</p>
    <h2>{`Check if the grip fits your Nintendo Switch`}</h2>
    <p>{`Ah! You see a case that's cheap & looks small enough for your hands? Oh, you got it? But wait... IT'S FOR THE SWITCH LITE???? This may sound ridiculous and but please remember to check which Nintendo Switch version you're buying for. Because I...almost made this mistake. Come on, they made all these super bright and chirpy colours for the Switch Lite! I couldn't help it!!!`}</p>
    <h2>{`Also, does the grip fit your carrying case?`}</h2>
    <p>{`No, seriously. Your grip is wonderfully comfortable and all, and you're about to take it with you on your commute, but are you going to just chuck it into your bag?? The notoriously drift-prone & sensitive joy-cons bumping around inside?? For example, I think the original Mumba carrying case does not fit the new Mumba Blade or Battle series cases. They did release a new carrying case specifically for those, so don't forget to read the fine prints and, most companies that make grips often make their own case, browse around to see if you can find one.`}</p>
    <Ads mdxType="Ads" /> 
    <h2>{`And... your dock?`}</h2>
    <p>{`Lastly, don't forget your dock! Some cases obviously don't fit the dock, like the `}<RemoteLink to="https://www.satisfye.com/" mdxType="RemoteLink">{`Satisfye brand`}</RemoteLink>{`. I saw one review on the Mumba 'Girl Power' case that said 'doesn't fit dock', obviously, because the hand grips are sitting exactly where you'd slide your switch in.`}</p>
    <p>{`While on the other hand, there are some docks that claim to fit but sometimes don't fit. The reviews on the Blade Series by Mumba has many reviews that complain it doesn't fit their dock, even though it's advertised as dockable. Mine fits, but I kinda need to push it down, using effort. The feeling isn't nice either. It feels like your dock might just split open.`}</p>
    <Image alt="Generic TPU casing for Nintendo Switch" caption="a generic plastic sleeve for the actual tablet that is not by nyko. It was pretty cheap too! I love it but Erm... Read on" name="nintendo_switch_tpu_sleeve.jpg" mdxType="Image" />
    <h2>{`Extra: You don't need a grip`}</h2>
    <p>{`Ok, say you don't need a grip at all (like me), and you see so many versions of this.. plastic or silicone cover. See if it's like, the soft malleable plastic cover or a hard plastic shell. Or if its silicone, see if you're okay with dust getting stuck to your cover because some silicone textures tend to do that. Oh! Say you get the `}<RemoteLink to="https://nyko.com/products/thin-case-for-nintendo-switch" mdxType="RemoteLink">{`Nyko Thin`}</RemoteLink>{` case, it's essentially a hard plastic frame? Because it's not a sleeve you slide on, it's two plastic pieces that snap together. And the joy-con covers are soft plastic? I have a `}<em parentName="p">{`love/hate`}</em>{` relationship with this one.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Hate: It does not feel sturdy`}</h2>
    <p>{`I hate it because it'll take a few tries to snap it on right. And it can be scary to take it off too. The plastic is flimsy so you feel like you might accidentally break it. The joy-con sleeves can be a massive pain to take out, especially the left joy-con. The right one isn't so bad. Plus for the Ring Fit...`}<em parentName="p">{`Ring-thing`}</em>{`, your joy-con has to be naked. Basically, I hate changing '`}<em parentName="p">{`clothes`}</em>{`' for my switch. I always feel like I'm being too rough on it, specifically the joy-cons (mine has been drifting since day 1, so it hurts me more.)`}</p>
    <h2>{`Love: It's good enough`}</h2>
    <p>{`I love it because it's so simple and honestly feels 'enough' for me because I actually don't use a grip 98% of the time, and it's either sitting in its Tomtoc Slim case or in the dock(yes this super-thin cover/case definitely fits in the dock). It came with a free tempered glass screen protector too! Anything free, I will love.`}</p>
    <p>{`AND even though I just complained about the snap-on plastic frames? Yeah well, there's a reason why it's designed that way. I bought a cheap, soft plastic `}<em parentName="p">{`sleeve`}</em>{` for my switch tablet, and if you think the Nyko Thin case is a nightmare to un-snap, this one is Freddie Krueger. I was W R E S T L I N G with my switch to get the damn thing off. I was THIS close to just ripping it open. The softness of the plastic sticks to the switch, like a plastic chair sticking to your thighs on a hot, sweaty day. I still prefer soft plastic cases though.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Nintendo Switch TPU casing" caption="soft plastic cover in action when I took my switch out for a walk! Who's a good boy??" name="nintendo_switch_tpu_sleeve_2.jpg" mdxType="Image" />
    <p>{`So after all that, my number 1 tip would be, READ THE REVIEWS & READ WHAT YOU'RE BUYING!! And remember kids, Size Matters!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      