import styled from 'styled-components';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';

import $ from '../../styles/globals';
import Posts from './Posts';
import Navbar from './Navbar';

const Section = styled.section``;

const Container = styled.div`
  ${$.screen.desktop} {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: ${$.layout.padding5}px 0;
    flex-wrap: wrap;
  }

  ${$.screen.tabletA} {
    padding: ${$.layout.padding5}px 0;
  }

  ${$.screen.tabletB} {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    padding: ${$.layout.padding5}px 0;
  }

  ${$.screen.mobile} {
    padding: ${$.layout.padding5}px 0;
  }
`;

const NavbarTool = styled(Navbar)`
  ${$.screen.desktop} {
    flex-shrink: 0;
  }

  ${$.screen.tabletB} {
    flex-shrink: 0;
  }
`;

const Latest = ({ items }) => {
  const sortedByTags = {};

  items.forEach(({ node: { frontmatter, slug, timeToRead } }) => {
    sortedByTags[frontmatter.tags[0]] = sortedByTags[frontmatter.tags[0]] || [];
    sortedByTags[frontmatter.tags[0]].push({
      key: nanoid(),
      excerpt: frontmatter.excerpt,
      title: frontmatter.title,
      image: frontmatter.banner,
      slug: `/${slug}/`,
      timeToRead,
      published: frontmatter.published,
    });
  });

  Object.keys(sortedByTags).forEach((tag) => {
    sortedByTags[tag] = sortedByTags[tag].sort((curr, next) => {
      const currPublished = new Date(curr.published).getTime();
      const nextPublished = new Date(next.published).getTime();

      if (currPublished < nextPublished) {
        return 1;
      }

      if (currPublished > nextPublished) {
        return -1;
      }

      return 0;
    });
  });

  return (
    <Section>
      <Container>
        <NavbarTool />
        <Posts data={sortedByTags} />
      </Container>
    </Section>
  );
};

Latest.defaultProps = {
  items: [],
};

Latest.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
};

export default Latest;
