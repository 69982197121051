import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Blockquote = makeShortcode("Blockquote");
const Ads = makeShortcode("Ads");
const Video = makeShortcode("Video");
const LocalLink = makeShortcode("LocalLink");
const RemoteLink = makeShortcode("RemoteLink");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Happy New Year! May prosperity come knocking on your door everyday in 2022! Of course, we must not forget the ever-growing list of abandoned anime in your Anilist! Make sure to complete them in 2022 :)`}</p>
    <Blockquote mdxType="Blockquote">The joke is partially aimed at myself too...</Blockquote>
    <p>{`How is 2022 doing for everyone? I hope this year is finally a good one where we get to go back to our normal lives. But as they say, the show must keep going. 2021 has been a surprising year for anime for me. Clearly covid lockdown do nothing to slow down the influx of great anime. I wanted to pick 10 titles for this blog post, but I haven't been completing a lot of anime lately. Isekai anime are in abundance but none really catch my attention to complete it. I hope in 2022 there are more unique ones coming soon! I have my eyes on the Summoner Mage one. And let's not forget MUSHOKU TENSEI! Now that's a good one!`}</p>
    <p>{`The list below is ordered so let's start with #5 first <3`}</p>
    <p><strong parentName="p">{`Note: The only 2 prerequisite to make it into this list is first, I must have finished the anime and second, I love it a lot.`}</strong></p>
    <Ads mdxType="Ads" />
    <h2>{`Personal Favourite Anime in 2021 #5: Miss Kobayashi Dragon Maid S`}</h2>
    <p>{`In previous years, I would have rated Kobayashi somewhere at the top. It's fun, it has melancholic moments done super well and banger fluff friendship moments. Coupled with great animated scenes, this show deserves to be mentioned and crowned. It's one of Kyoto Animation most beloved works and mine as well. If there's a S3, I'll be the first to watch it the day it is aired. But when you have other great anime in the list, I'm afraid it will have to be placed away from the top.`}</p>
    <p>{`I added a Kanna video because she is part of the reason I love this show so much.`}</p>
    <Video src="https://www.youtube.com/embed/_8eNuVpXyvc" title="Kanna Cute Moments" mdxType="Video" />
    <p>{`To be fair, the show is good. It definitely kept Season 1's quality. Perhaps the episodic plot doesn't feel as wholesome as S1 to me. The fight between Tooru and Elma came out of nowhere. I appreciate the fight scenes, don't get me wrong. I think the sudden notice could have been better. Regardless, this is a great show! I'm looking forward to more Kobayashi content (if Kyoto Animation wants to do more)!`}</p>
    <h2>{`Personal Favourite Anime in 2021 #4: Irina The Vampire Cosmonaut`}</h2>
    <p>{`This show came out of the blue for me. It's based off a light novel series that ends within 7 volumes. What I love about this show is how they portray the slow romance build up between Irina and Lev. I enjoy how the author bonded them both over their ambition to go to space, and the rest just fell into place naturally. There's a great chemistry between two different but hardworking people with the same ambition, ya know?`}</p>
    <Video src="https://www.youtube.com/embed/XaD3ebxrxqk" title="Vampire Cosmonaut Irina" mdxType="Video" />
    <p>{`It's like watching a romance between 2 like-minded people. They both have different backgrounds yet their ambitions brought them together. They support each other because they knew how much their dreams meant to them. `}<em parentName="p">{`Hnnnng, moeeee.`}</em></p>
    <p>{`The ending did feel silly but it's the good kind of silly. Happy endings are always good to have! `}<LocalLink to="/blog/irina-the-vampire-cosmonaut-review/" mdxType="LocalLink">{`Read my blog post about Irina here?`}</LocalLink></p>
    <Ads mdxType="Ads" />
    <h2>{`Personal Favourite Anime in 2021 #3: Vivy -Fluorite Eye's Song-`}</h2>
    <p>{`I love shows that pit human versus machine. It's one of the many reasons why I really enjoyed `}<RemoteLink to="https://en.wikipedia.org/wiki/I,_Robot_(film)" mdxType="RemoteLink">{`I, Robot (Will Smith)`}</RemoteLink>{`! If an A.I. has developed an ego, is it... human? Should we treat them as one? Well, some human characters certainly treated them as one! While it's a fascinating topic but Vivy didn't exactly explore the theme in this manner.`}</p>
    <p>{`Instead, it explored more on the basis that A.I. in this story `}<strong parentName="p">{`are capable`}</strong>{` of love and self-reflection even if it was pre-programmed into them. The plot in Vivy had this romanticist basis in it and boy, it did feel good to see it unfold dramatically. I enjoyed each episode as it brings us closer to the ending. The ending is... eh, but not far from what I expected. The fight scenes are sparse but it's all so satisfying. I included one of my favourite fight scenes.`}</p>
    <Video src="https://www.youtube.com/embed/mHmB5mhkuP0" title="Harmony of One's Heart" mdxType="Video" />
    <p>{`The songs are really good too, do give them a try! I wrote a few Vivy blog posts but `}<LocalLink to="/blog/vivy-fluorite-eyes-song-review/" mdxType="LocalLink">{`this one is my favourite.`}</LocalLink></p>
    <h2>{`Personal Favourite Anime in 2021 #2: Mushoku Tensei`}</h2>
    <p>{`Despite ranking it `}<LocalLink to="/blog/my-personal-top-3-isekai-when-it-comes-to-storytelling/" mdxType="LocalLink">{`No. 1 in my blog post here`}</LocalLink>{`, I ranked this No. 2 in this blog post?! What is this inconsistency?! Hold up, hold up... Lower your pitchforks. I admit, I enjoyed Mushoku Tensei `}<strong parentName="p">{`A LOT`}</strong>{`. It's a wonderful adaptation of the light novel, it did world building wonderfully and they took 0 shortcuts when animating the magic/fight scenes. The anime even took liberty in some scenes which I enjoyed a lot. (E.g. the scene where Rudeus feel excluded from the world but took the courage to take the first step out of the door)`}</p>
    <Image alt="Mushoku Tensei screencap" caption="An old screenshot of Rudeus in S1 ^^" name="mushoku-tensei-screencap.png" mdxType="Image" />
    <Ads mdxType="Ads" />
    <p>{`I am currently following the manga version and has read the translated web novel to a certain extent. I forgot where I left it (It was years ago and I mean at least 7 years ago) but I remember plenty of scenes which the anime did great. Yup, the show is great. I stand by my words! However, it lacked emotional impact compared to #1.`}</p>
    <h2>{`Personal Favourite Anime in 2021 #1: Re:ZERO Season 2 Part 2`}</h2>
    <p>{`Phew. Where do I begin. 12 episodes continuing from the first part. Equally intense and emotional, this show just keeps on giving despite the roundabout dialogue they enjoy doing. Part 1 sets up this absolute misery about the Witch of Greed Echidna, her "family" and the mystery surrounding Sanctuary. Part 2 features Subaru breaking the loop and finally, gaining more allies than he could count.`}</p>
    <Video src="https://www.youtube.com/embed/TOVXrQ6R8KE" title="Re:ZERO S2 Part 2 Trailer" mdxType="Video" />
    <p>{`I didn't really like Emilia from S1, but watching her childhood just hits different. We get to see the real Petelgeuse (I call him "the guy with trembling brain") and Emilia's "mother". Wow, amazing. The whole flashback hurts even more than Subaru's meeting with his parents. Then we get to see Beatrice finally getting her happy ending.`}</p>
    <Ads mdxType="Ads" />
    <p>{`12 episodes of constant emotional impact. Such a great show! The anime convinced me to get the light novels to read, but then I saw it is still ongoing with 28 volumes published. Oof, my wallet.. I guess I'll just watch the anime when S3 airs!`}</p>
    <h2>{`All in all`}</h2>
    <p>{`I hope you enjoy my picks! If you have not watched them, they are all really good! Please give them a try.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      