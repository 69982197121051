import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Video = makeShortcode("Video");
const RemoteLink = makeShortcode("RemoteLink");
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Lately, I’ve been drowning myself with a ton of Netflix and a healthy dose of Tower of God. So it kinda explains why I haven’t been writing any blog posts as actively as I wanted to. Both sides of the entertainment I chose is too addictive! On Netflix, I just scroll and scroll till I found a show that looks decent, and I came across Carmen Sandiego. I haven’t got the slightest clue what it is about, but the fluid 2D animation attracted my attention so in I go. Boy, it was a delightful ride. I finished Season 1 in a blink of an eye.`}</p>
    <Video src="https://www.youtube.com/embed/shbiwCCMDHQ" title="Carmen Sandiego" mdxType="Video" />
    <p>{`Carmen Sandiego the `}<RemoteLink to="https://en.wikipedia.org/wiki/Carmen_Sandiego_(TV_series)" mdxType="RemoteLink">{`TV series`}</RemoteLink>{` is based on the `}<RemoteLink to="https://en.wikipedia.org/wiki/Carmen_Sandiego" mdxType="RemoteLink">{`video game series`}</RemoteLink>{` with the same name by the Broderbund company. And wait till you hear this! It was first released in 1985! Wowzer, this is a classic. In a way, that explains the impressive effort poured into this TV series. The colour palette is one of a kind with the animation as snappy as any American animation there is. All in all, it felt like a stylish Phantom Thief series with a carefully written plot progression. Unlike Saturday American cartoons like Kim Possible or Totally Spies, each episode progresses carefully with more mystery uncovered, answering back to the goals set in the first 2 episode. It’s an opening that I really appreciate, the type that literally titles the episode as “Becoming Carmen Sandiego” Part 1 and 2. Soon after these two episodes, the story kicks in with Carmen conducting heists per episode in every part of the world.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Since the show `}<strong parentName="p">{`is`}</strong>{` for kids (7+ and above, according to Netflix rating), there were some parts that ARE meant to be educational. For example, each episode opens up to have the heist team in a new location on the globe. They’ll give a short explanation on the country, their local specialities and what these heists will save. I appreciate that they did Indonesia correctly and in detail too. The dialogues aren’t too deep and are relatively short in length, but I honestly think it’s good enough even for teens to enjoy. In fact, I wasn’t so sure this is for 7+ kids (Netflix rating) since the animation is really good and the plot is enjoyable as it is. Kids nowadays have it good!`}</p>
    <p>{`So what is Carmen Sandiego about? To keep it short, Carmen Sandiego is not our protagonist’s real name. It’s her code name! She is an orphan brought up by VILE, an evil organisation dedicated to committing crime globally. Donning a red coat and hat as Carmen Sandiego, her ultimate goal is to stop any and every crime VILE cooks up with her trusty sidekicks.`}</p>
    <p>{`I call Carmen Sandiego a Phantom Thief in my head but decided against it since the summary is pegging her as Robin Hood instead. But honestly, look at her design!`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Carmen Sandiego is always decked in a red coat, and a red hat." caption="Stylish wear from top to bottom? Check." name="carmen-sandiego-geeknabe-blog.jpg" mdxType="Image" />
    <Image alt="Carmen Sandiego from Ep. 1, providing a way in for Player." caption="Futuristic tools to help her work? Check." name="carmen-sandiego-futuristic-tools-geeknabe-blog.jpg" mdxType="Image" />
    <Image alt="Carmen Sandiego is quick-witted! Love her." caption="Well-trained for every thieving activity there is? Check." name="carmen-sandiego-well-trained-geeknabe-blog.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <Image alt="No one can pull off a heist without a trustworthy team backing them up. Carmen Sandiego with her friends!" caption="A team of friends to help her out? Check." name="carmen-sandiego-trusty-sidekicks-geeknabe-blog.jpg" mdxType="Image" />
    <Image alt="Carmen Sandiego's personal assistant, Player." caption="A white hat hacker? Yes!" name="carmen-sandiego-player-white-hat-hacker-geeknabe-blog.jpg" mdxType="Image" />
    <Image alt="Detective Chase Devineaux and his assistant, Julia Argent." caption="And a detective, except this one is incompetent and it’s his assistant that is better than him at his job? Duh!" name="detective-chase-devineaux-and-julia-argent-carmen-sandiego-geeknabe-blog.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`All in all`}</h2>
    <p>{`I haven’t dipped my toes in Season 2 yet, but I know for sure Season 3 is coming soon. Fantastic. I always loved these Robin Hood/Phantom Thieves show. I personally consider this show a thieving series done right. It’s perfect for those after-work nights to wind down and enjoy the wild ride of Carmen Sandiego’s capers.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      