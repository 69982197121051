import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I keep wanting to recommend some of my favourite shounen manga but instead, I keep coming back to romance webtoons. Hey, this is totally not my fault! If you check out the list of available webtoons everywhere, they are all romance! Romance and the villainess cliche is still queen(?) of webtoons. Maybe folks just prefer drama than good ol’ shounen...`}</p>
    <p>{`I read a tweet some time ago mentioning how webtoons are so popular due to the ease of consuming one, and I agree with it wholeheartedly. You just keep scrolling and scrolling… Voila! One chapter done! Plot is easy to grasp too, and I believe coloured panels makes it easier to fill out the imagination.`}</p>
    <p>{`Given how large the pool of webtoon has become, I’m of the opinion that there will be stories out there that are really bad but will still garner some attention. Kinda like shovelwares on App Stores. You download them to try them out then delete it as quickly as you downloaded them. This makes it really hard to pick out winners among the sea of webtoons. Well, at least it’s easy to flick through a few chapters before deciding to continue reading it! These romance webtoons have been in my read list for a while and I would like to share them with you. They have a sweet plot and are really comforting to read. Enjoy!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Romance Webtoon Recommendation #1: Dear My Hunny Bunny (こんにちは、私のおチビちゃん.)`}</h2>
    <p>{`This is one slow plot but boy oh boy, it’s so sweet. I cried reading how sweet the parental figure is to the MC. Warning, you may or may not like how SHORT the chapters are. Yup, that’s my only problem with this webtoon.`}</p>
    <Image alt="Dear my Hunny Bunny art" caption="I didn't expect this to be from a Japanese artist... Really nice writing and art." name="dear-my-hunny-bunny-art.png" mdxType="Image" />
    <p>{`Our heroine is Jay, a sweet young lady who lost her family in an accident. Depressed from losing her family, she tried to commit suicide by swallowing pills but was saved by her uncle. Seeing how lost Jay is, her uncle suggested a job to her. She’s to work as a special guardian for a child for 3 to 5 years. The job of a special guardian is to shower the child with attention and love in order for them to recover. It sounds really normal so far but there’s a fantasy theme going on here. These children picked up by the agency are actually lost souls who are wounded in their original world. Broken families, unwanted by family… and so forth. So the agency picks them up and puts them under special guardians so they will heal.`}</p>
    <p>{`For her first assignment, Jay was assigned Ludwig Landgel, son of a dukedom from the Chronos Empire. He’s a pitiful child who has never received love, so Jay makes sure to love Louie (Ludwig’s nickname) like her own family. Jay, who has lost her family, found a new goal in life. She too, slowly recovered from her depression. It’s a sweet story with lots of focus on familial love. The first chapter opens up with Jay visiting Louie in his original world so yes, Jay and Louie do meet again after they separated on Earth. I have no clue what happens next since the chapters are really short but each chapter is so sweet <3 Give it a try?`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Romance Webtoon Recommendation #2: The Antagonist’s Pet (악녀의 애완동물)`}</h2>
    <p>{`I think I have recommended this before… or not. Regardless, there’s a ton of chapters out now so I think it’s time to talk about this story. I didn’t expect the story to turn out the way it is, so it is a surprisingly pleasant read!`}</p>
    <Image alt="The Antagonist's Pet light novel" caption="I was gonna post the webtoon art but I find the light novel one really flashy! Nice art." name="the-antagonists-pet-light-novel.jpg" mdxType="Image" />
    <p>{`Our MC is Sasha Tart, a sweet noble lady whose name eludes everyone else. After all, she’s just a side character in this fantasy world made true from a novel she’s read. If this keeps up, she’ll soon be pawned off to marry an old codger! Of course, any Korean lady wouldn’t want this. With her knowledge from the novel, she decided to be a cute pet to any noble who asks for it. You see, nobles have a facade to maintain. This is particularly stressful to any noble ladies who have their own problems they couldn’t voice out. Sasha would listen to their worries and comfort them. She’ll act like a pet to be coddled. This way, she earns the noble ladies trust and attention. In a way, Sasha is doing networking.`}</p>
    <Ads mdxType="Ads" />
    <p>{`She hopes to continue in this manner and avoid meeting the protagonist and antagonist of the story. In the novel, they both clashed and the story ended up in the protagonist’s favour. Of course, all that drama doesn’t sound good to Sasha who wants a safe life. One day, Sasha caught the eye of the antagonist, Rebecca. But from what she observed, Rebecca doesn’t seem that bad at all. In fact, she’s being bullied by her fiance. Sasha took pity on Rebecca and decided to help Rebecca realize her full potential. Little did she know that by doing so, Sasha is changing the main storyline…`}</p>
    <p>{`The story went from “Eh, such a strange choice of plot.” to “Aww, I hope everyone gets their happy ending.” The theme of this title is “to choose your own way of living”. The latter chapters show how everyone charmed by Sasha wants what is best for her, while doing the same for themselves. Really wholesome stuff. There are some heavy scenes but they are usually related to the characters’ growth. This is that one title you never expect the author to develop the story in this way!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Romance Webtoon Recommendation #3: Dragonsitter (드래곤 시터)`}</h2>
    <p>{`This title is a winner for me from the start. It’s definitely slow in the beginning with many questions unanswered. However, that is not the point here since the moe scenes make up for it! This is a black and white webtoon.`}</p>
    <Image alt="Dragonsitter cover art" caption="You can read this in Daum! Oh wow, from the thumbnail alone I could see how beautiful the art is." name="dragonsitter-cover-art.jpg" mdxType="Image" />
    <p>{`In this webtoon’s world, dragons exist. They have a humanoid form, their own culture and even live like a human. However, only one living dragon is left in this world. Thus, the kingdom treats this particular dragon, Axela, like a national treasure. They would send sitters to care for the dragon every year and would never jostle the dragon if he preferred to be left alone. This year, the kingdom sent a young orphan girl, Corette as the sitter. Is she a sacrifice or is the kingdom just trying something new with hopes that the dragon would stir? Regardless, it did work. How would Corette and Axela’s relationship develop?`}</p>
    <p>{`The first few chapters are basically a human girl trying to berate and fix a dragon’s alcoholic problems -> They got really close -> They learn more about each other. As the story progresses, we are fed details of Axela’s close relationship with the kingdom, and why he didn’t leave the place outright. So how does Corette fit into the story? Fufu, it's tagged as romance. Whatcha think? :3`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Romance Webtoon Recommendation #4: Princess to the Rescue! (구해주세요, 공주님)`}</h2>
    <p>{`This is perfect, I love it when the author makes efforts to make terrible characters but slowly redeem them through their actions/growth. That’s not saying that the MC is terrible, she’s just super sheltered.`}</p>
    <Image alt="Princess to the Rescue banner" caption="I got this banner from Comico! Don't let the sparkly art fool you, it has a great plot." name="princess-to-the-rescue-webtoon-twitter-banner.jpg" mdxType="Image" />
    <p>{`Our heroine is Claudia De Porto. She’s a legitimate princess who’s about to throw a wonderful 19th birthday party as sanctioned by the king, her brother Juverin. She’s sweet and pretty on the outside, but a bit selfish and naive in the head. But all of that is mute in front of her brother Juverin. Juverin loves his sister so much that he ignores every pressing matter in his kingdom that would jeopardize Claudia’s wants. Alas, all good things will come to an end. The Demon King attacked the castle and attempted to murder the king. Thankfully, the spirit Desire who is bound to the royal family saved Juverin in the nick of time. He reveals that in order to save Juverin, the other royal family members should prepare to set off to face the Demon King with him in tow. After all, only a royal member could wield Desire, the holy sword who once belonged to the hero. Yup, only Claudia, the princess could do this. How would a young princess who has never suffered hardship survive the trip?`}</p>
    <p>{`We see Claudia as a sheltered princess with a super wayward thinking. She has to come first, all important tasks be damned! But this is not her fault at all. I see this as her brother’s fault. Juverin loves his sister a ton to the point that the officials could only shake their head when it comes to the matters pertaining to his sister. Of course, this means that even the ministers didn’t look up to the princess at all as the temporary representative of the country. She will have a hard time trying to prove herself along with traversing the harsh wilderness and into the land of the demons. Eh, she did improve herself once she realized how selfish she was in the latter chapters. Writers should do characters like her more. That way, the journey to redeem the character feels way more satisfying. I know it sounds like there is no romance but there is :3 Just wait and see, I totally ship Claudia with her guard.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Romance Webtoon Recommendation #5: Doctor Elise (외과 의사 엘리제)`}</h2>
    <p>{`I was going to suggest another title since I am sure many folks have read this one. But Doctor Elise has a wonderful approach to redeeming past mistakes. Plus, it’s now completed! Readers who prefer to binge everything in one-go will love Doctor Elise!`}</p>
    <Image alt="Dr Elise webtoon" caption="Super nice art! I love how bright the colours are in the webtoon." name="dr-elise-webtoon.jpg" mdxType="Image" />
    <p>{`Doctor Elise shines light on Dr. Song, a top surgeon from Korea who died in an airplane crash. The next moment she wakes up, she’s in the body of Princess Elise, a selfish brat who wants nothing but marriage with the prince. It’s not your regular reincarnation, since Dr. Song remembers her previous life… as Princess Elise. So her life experience goes from Princess Elise -> Song as a surgeon in Korea -> Back to Princess Elise. Back when she died as Princess Elise, she swore to make things right and redeem herself as a surgeon… By saving lives in the operation room. Now that she’s back as Princess Elise, she wishes to use her knowledge as a surgeon to improve her country’s healthcare, as well as prevent the tragedies she knew are coming.`}</p>
    <p>{`I’ve read comments that some folks think this is just an average story which I think is a fair opinion. The story relied a lot on Elise’s skill as a surgeon to advance the plot. The idea here is that she’s really, really good with a scalpel and there is absolutely zero chance she can eff things up. I didn’t really dig into the deep themes because all you need to know is basically this: Elise does good -> change others’ fate as well as herself -> readers feel good. It’s the type of story that I don’t mind seeing it as a live action because I think it’ll fit pretty well into K-dramas style. After all, you just need a good story that folks feel is good and you’re good to go. Deep themes aren’t necessary if the general public enjoys it. Some folks just like things normal, yea? Do give this story a try since it’s completed! I definitely enjoyed it.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`All in all`}</h2>
    <p>{`I hope you enjoyed the romance webtoon recommendations! I know I did when I wrote the summaries and picked the titles. Will I do more recommendation posts? `}<strong parentName="p">{`checks the growing list of webtoons and manga that I have read`}</strong>{` HELL YEA.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      