import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const LocalLink = makeShortcode("LocalLink");
const Ads = makeShortcode("Ads");
const RemoteLink = makeShortcode("RemoteLink");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`2020 is ending soon… Truth be told, this year feels `}<strong parentName="p">{`unreal`}</strong>{`. If someone told me I dreamt the entire 2020, I’d be inclined to believe it. The pandemic is terrible. Here’s hoping that everyone gets a vaccine soon and we can put this horrible year behind us. Despite all that, 2020 has been a wonderful year for Geeknabe.`}</p>
    <p>{`Our stats have since doubled every month thanks to mari-chan’s hard work in writing her `}<LocalLink to="/fanfiction/" mdxType="LocalLink">{`Dimileth fanfiction`}</LocalLink>{`. I tweaked things here and there, did a ton of SEO research and had fun talking with the bloggers in the geeky circle. Everyone brought their own content to the fold. There’s a lot of talking, agreement and debate. It’s absolutely fun to see everyone’s unique ideas on the same anime/manga/games we watched, read and play!`}</p>
    <Ads mdxType="Ads" />
    <p>{`I got this blog post idea from seeing everyone’s Top 5 posts. They mentioned their favourite anime of the year, manga, etc. Many of their choices got me nodding since those are indeed great stuff! `}<strong parentName="p">{`Then it hit me.`}</strong>{` Darnit, I need to do one too. I mean, I market myself as a blogger who writes about these things! Why am I not doing it?! So I reluctantly peeled myself off Genshin Impact and started typing.`}</p>
    <p><em parentName="p">{`Note: Every series mentioned here started this year unless mentioned otherwise! None of the list is ranked here. They are in the Top 5 because I like them lots. Some lists may only have Top 3 because I don’t consume that many of the said media this year.`}</em></p>
    <h2>{`nabe-chan’s Top 5 Anime 2020`}</h2>
    <p>{`I’m so happy I finally got `}<RemoteLink to="https://anilist.co/user/geeknabe" mdxType="RemoteLink">{`Anilist`}</RemoteLink>{` to help me track the anime I watched. I have a terrible memory! That’s why I suck at AMQ. I feel super shy to play with everyone on a weekly basis since everyone’s so good…`}</p>
    <h3>{`Top 5 Anime #1: Ascendance of a Bookworm Part 2`}</h3>
    <p>{`I could have sworn Ascendance of a Bookworm Part 1 aired in the same year… Anyway, `}<LocalLink to="/blog/ascendance-of-a-bookworm-season-2-ends-on-a-high-note/" mdxType="LocalLink">{`Ascendance of a Bookworm`}</LocalLink>{` just hits all the right spots for me. It’s an isekai, but it portrayed a ton of moral values and life lessons that I really, really enjoy. It’s the small things in life that they made the effort to talk about. Besides, you couldn’t help but root for Main to go higher in her new life!`}</p>
    <Ads mdxType="Ads" />
    <h3>{`Top 5 Anime #2: Sleepy Princess in the Demon Castle`}</h3>
    <p>{`This is a no-brainer for me since I really enjoyed the manga a lot! The anime studio did a brilliant transition from the manga to anime. The comedy scenes in the manga relied a lot on unexpected dialogue and actions which the anime nailed. Plus, those quest screens is a great idea! The voice acting in Sleepy Princess really brought out the fun in the comedy scenes.`}<LocalLink to="/blog/why-you-should-watch-maoujou-de-oyasumi-anime/" mdxType="LocalLink">{` Please watch the show!`}</LocalLink></p>
    <Image alt="Suya~" caption="THE BEARS ARE THE BEST." name="sleepy-princess-in-the-demon-castle-screencap.jpg" mdxType="Image" />
    <h3>{`Top 5 Anime #3: Aggretsuko: Season 3`}</h3>
    <p>{`I find it strange that not many talked about this `}<LocalLink to="/blog/aggretsuko-season-1-isnt-this-a-throwback-to-my-early-20s/" mdxType="LocalLink">{`brilliant show on Netflix`}</LocalLink>{`. Kudos to the Hello Kitty team! They are probably not the one in charge of Aggretsuko anime but I feel obligated to thank them regardless… Like, have you seen such a multi-faceted anime that just strikes deep into every young adults’ problem?! I actually started from S3 first before watching S1 and S2. The theme and the flow is absolutely great. It’s subtle character growth so you got to pay attention to the character’s thoughts and mannerism. Once you do and piece them all together, you’ll see why I call it a multi-faceted show.`}</p>
    <Ads mdxType="Ads" />
    <h3>{`Top 5 Anime #4: BNA (Brand New Animal)`}</h3>
    <p>{`Studio Trigger shows could be a hit or a miss. Some call BNA a miss but I think this is that one show Trigger kinda… got it right? `}<LocalLink to="/blog/bna-is-a-brand-new-a-game-anime-by-studio-trigger/" mdxType="LocalLink">{`There’s a message going on here`}</LocalLink>{`. A thematic message that resonated strong from Episode 1. The show is not exactly episodic since each tells a different story on the same Beastmen problems. You got to pay attention to what they are trying to tell you! The show ended on a happy note (which ties nicely to the message again). Shows by Trigger that I watched usually fumbled on the ending, but for BNA I think they did a great job. Also, the OP is lit.`}</p>
    <h3>{`Top 5 Anime #5: Is it wrong to try to pick up girls in a dungeon? III (Danmachi S3)`}</h3>
    <p>{`Great Pretender or Re:Zero S2 almost took this spot but I just love what they did with Danmachi more. S1 and S2 got a consistent animation and art, but you could tell they had to cut some corners with S3. I’m pretty sure it’s due to the pandemic. You could tell the character’s movement lacks one or two frames to be super smooth, and most fights just lack gusto. Except the final fight with the Minotaur Xenos. Now that is fun!`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Danmachi S3" caption="Watch Danmachi S3 for her. She's adorable... Wiene <3" name="danmachi-s3.jpg" mdxType="Image" />
    <p>{`S1 and S2 had these sliced arcs where 1-cour tells a few different stories. S3 however, only had 1. I enjoyed how they approached the race problem since it’s not something you solve in a snap. Everything just came together at the ending. S1 is the starting point while S3 is the midpoint. We got a ton of throwbacks to how Bell first started but this time, it’s a fresh start for a new midpoint journey. I can’t wait for S4! We’re getting one, right?`}</p>
    <h2>{`nabe-chan’s Top 5 Manga and Webtoons 2020`}</h2>
    <p>{`Okay so this is gonna be tough. Likely because I read a lot of `}<LocalLink to="/topic/manga/" mdxType="LocalLink">{`manga and webtoons`}</LocalLink>{`. Plus, some are NOT published in 2020. Maybe they were published a few years back and I just got to know of it this year. So I’m going to cut myself some slack and just… write about the manga/webtoons that I really enjoyed this year.`}</p>
    <Ads mdxType="Ads" />
    <h3>{`Top 5 Manga and Webtoons #1: Sousou no Frieren`}</h3>
    <p>{`I’ve been wanting to talk about this manga for some time now. I’ll talk about this manga in a separate blog post soon (Haha, no promises)! Sousou no Frieren is basically about an elf mage named Frieren who was once in a hero’s party. They defeated the demon lord and went home happily. The party promised to meet again but unfortunately, an elf’s sense of time is super warped since they live a long life. The next time Frieren went to look for her old party members, they have grown old with age. This shook Frieren, since she’s a bit of a social airhead. It didn’t occur to her that her sense of time is different from her friends.`}</p>
    <Image alt="Sousou no Frieren" caption="This is Frieren. Join her on her journey to retrace the hero's party steps in the name of fond memories." name="sousou-no-frieren.jpg" mdxType="Image" />
    <p>{`Thus, Frieren decided to travel to the places the party once visited for the sake of nostalgia. The whole journey is melancholic but not exactly all gloom. I’d say the mood is along the line of bittersweet instead. Please give this manga a try!`}</p>
    <h3>{`Top 5 Manga and Webtoons #2: Kimi ga Shinu Made Koi wo Shitai`}</h3>
    <p>{`It’s been a while since I found such a lovely tragic yuri! The manga only have a few chapters for now, but it’s good stuff. This manga is set in a world where girls can use magic and is obligated to enroll in a military school. That’s right, magic in this world is used for warfare. The world’s lore isn’t explained really well and that’s not an issue. After all, I’m sold on the fluffy, tragic yuri part instead. I’ll skip out on the summary and just show you the pretty art <3 Good yuri.`}</p>
    <Image alt="Kimi ga Shinu Made Koi wo Shitai" caption="Giving this a summary means I will need to spoil you. Just enjoy the plot, ya? It's really fluffy. ... Ok I lied. It's tagged as tragic yuri afterall." name="kimi-ga-shinu-made-koi-wa-shitai.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h3>{`Top 5 Manga and Webtoons #3: Ebony (에보니)`}</h3>
    <p>{`Ebony is one of the most unexpected webtoon finds of the year. I went in expecting some otome cliche but instead, I got a powerful message about women’s rights instead. Our heroine is Ebony Vonieck, who was wrongly accused for murder. Abused in prison, she had a traumatic experience which lasted a long time, even after she was released by the Archduke.`}</p>
    <p>{`The Archduke showed her that there’s a way to get back at those who accused her, but only if she’s willing to put in the work. And she did. She masqueraded as the mysterious Raven and made everyone dance to her tune with her charismatic act. You see, the kingdom she lives in practices chauvinistic ideals. Her acting as Raven is the only way to prove to everyone that even women have a right to everything that a man has in this kingdom. Powerful message here folks. Please give it a try!`}</p>
    <h3>{`Top 5 Manga and Webtoons #4: Killing me/Killing you (キリングミー/キリングユー)`}</h3>
    <p>{`Prettttty sure this went under everyone’s radar. It got new updates recently which reminded me of how much I love these stories! The world was normal up until the meteorites fell. The meteorites wiped out most of humanity and introduced new flora/fauna to the world. Most importantly, it made Mithia an immortal when a shard embedded itself on her forehead. She couldn’t die no matter what she tried. With death as her only goal in her life, she went on a journey to look for a place to die. Along the way, she met Youthanasia who, like her, is an immortal. Thus, the unlikely two journeyed together to look for a place to die.`}</p>
    <Image alt="Killing me/Killing you manga" caption="The girl is Mithia and the slimey arm dude is Youthanasia." name="killing-me-killing-you-manga.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <p>{`This is the type of manga that goes ironic on the message. Despite the two wanting to end their life, they find solace from each other’s company. Everyone whom they met was given a new lease in life, as they found another reason to live through the two immortals‘ meddling in their own problems. The panels get really bloody since no matter how their bodies get mauled, they won’t die! It’s a fun read. I haven’t checked if the manga has ended yet in Japan, but I’m positive this is the type of manga that’s short and sweet. You know, the type you read in one sitting because it’s short and goes “Hm, that’s really good” at the ending.`}</p>
    <h3>{`Top 5 Manga and Webtoons #5: I was born as the demon lord’s daughter (마왕의 딸로 태어났습니다)`}</h3>
    <p>{`Another webtoon that really surprised me with the plot maturity! I thought for sure it’s… on the comfy read side but nope, it went beyond my expectations. Joara is a pitiful girl from South Korea who often got bullied. An accident happened that took her life and voila, reincarnated as the demon lord’s daughter.`}</p>
    <p>{`Most webtoon go all fluffy on the`}<LocalLink to="/blog/all-time-father-daughter-webtoons-and-manga/" mdxType="LocalLink">{` father-daughter dynamics`}</LocalLink>{` but this webtoon tells of a mystery that happened before her birth. It’s being slowly revealed now and I think I love the whole idea of it! Did her misfortune as Joara happened for a reason? I certainly think so! Plus, what got me hooked is the art. I wasn’t sure I like it at first, but the art has progressively become lovely in the latest chapters. The hair and eyes are super pronounced. The artist has this unique style that does detailed lineart. I am digging the same line thickness style. Not to mention the panels are framed really nicely for each scene.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`nabe-chan’s Top 3 Games `}</h2>
    <p>{`This year has been a lull for me in terms of `}<LocalLink to="/topic/games/" mdxType="LocalLink">{`Nintendo Switch games`}</LocalLink>{`. I am trying not to spend so much on games since it’s a waste for me if I don’t play them. Thus, the lack of choices for me in the first place. If the game doesn’t have a demo or a series of really great gameplay videos, I don’t consider them at all.`}</p>
    <h3>{`Top 3 Games #1: Genshin Impact`}</h3>
    <p>{`This pick is so obvious for everyone who follows me on Twitter. It’s free, it’s fun… What’s there not to like about this game? Folks who love ARPG will love this game. `}<LocalLink to="/blog/help-genshin-impact-wont-let-me-go/" mdxType="LocalLink">{`I may or may not have thrown cash at miHoYo’s face`}</LocalLink>{`... Please, just give me Klee the next time her banner shows up.`}</p>
    <h3>{`Top 3 Games #2: Animal Crossing: New Horizons`}</h3>
    <p>{`Once again, this soothing game is the best there is. Thank you Nintendo. It filled the gap when I felt pressured to work from home all day. My work and gaming seating now share the same spot. It could get really stressful since you can’t really switch the mood out in an instant. Animal Crossing helped me to get closer with my friends (We go diving in the game together), exchange DIY and just… relax after a day at work.`}<LocalLink to="/blog/i-redesigned-my-animal-crossing-new-horizon-island/" mdxType="LocalLink">{` I changed my island design 3 times already`}</LocalLink>{`. It’s that fun!`}</p>
    <Ads mdxType="Ads" />
    <h3>{`Top 3 Games #3: Trails of Cold Steel III`}</h3>
    <p>{`I keep saying I’ll do a review soon for this game. Spoiler alert, it never happened. This is my first PS2-like RPG after so many years! I was so stoked playing the demo. The feeling never changed throughout the game! There are a lot of anime-ish moments in the game. I find myself laughing every time a character pops up to save the day in the most anime cliche way. The combat is too easy if you know the tricks though. I personally can’t wait for the `}<RemoteLink to="http://thelegendofheroes.com/tocs4/product/" mdxType="RemoteLink">{`next game coming next year on Nintendo Switch`}</RemoteLink>{`!`}</p>
    <h2>{`Bonus: No. 1 Husbando`}</h2>
    <p>{`I should see things through if I started a trend. I love you Zhongli. Not in a simpy way, mind you... (Ahem, okay maybe bit simpy...) I just really enjoyed how gentleman he is. I got him in my main team with Ningguang and Fischl. He's so useful. I can't wait for v1.3 to come so he `}<RemoteLink to="https://genshin.mihoyo.com/en/news/detail/7747" mdxType="RemoteLink">{`gets a buff by miHoYo`}</RemoteLink>{`.`}</p>
    <Image alt="ZHONGLI" caption="I might need a C1 Zhongli. But wait! I need Qiqi and Klee first. Urgh, this game won't let me go..." name="genshin-impact-zhongli-banner.jpg" mdxType="Image" />
    <h2>{`Bonus: No. 1 Waifu`}</h2>
    <p>{`No one has managed to wrestle the title away from my one and only `}<LocalLink to="/blog/waifu-highlight-1-alisa-reinford/" mdxType="LocalLink">{`Alisa Reinford`}</LocalLink>{`. I'm starting to think my type of waifu are strong, independent ones.`}</p>
    <Image alt="ALISA REINFORD" caption="I love you Alisa." name="alisa-reinford.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`Bonus: No. 1 Most Annoying and Intrusive Ad`}</h2>
    <p>{`I'm just going to leave this here since I re-read my own blog post for nostalgia sake. Tis the year it happened nabe-chan, remember this.`}</p>
    <Image alt="NO YOUTUBE, I GET IT" caption="It just keeps popping up again and again. I SAID NO." name="youtube-premium-ad.jpg" mdxType="Image" />
    <h2>{`All in all`}</h2>
    <p>{`Thank you for reading. Now onwards! To 2021! Happy New Year!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      