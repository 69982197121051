import styled from 'styled-components';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';

import $ from '../../../styles/globals';
import FireIcon from '../../../assets/icons/fire.svg';
import FirstPost from './FirstPost';
import OtherPost from './OtherPost';

const Section = styled.section``;

const Container = styled.div`
  margin: ${$.layout.margin5}px 0;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${$.layout.margin5}px;

  > h2 {
    color: ${$.brown5};
    font-family: Raleway Bold !important;
    font-size: 25px;
    line-height: 25px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  > svg {
    fill: ${$.color.red1};
    width: 23px;
    height: 23px;
    margin-right: ${$.layout.margin2}px;
  }
`;

const OtherPostContainer = styled.div`
  margin: 24px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  ${$.screen.desktop} {
    > * {
      width: 32%;
    }
  }

  ${$.screen.tablet} {
    > * {
      width: 32%;
    }
  }

  ${$.screen.mobile} {
    margin: ${$.layout.margin3}px 0;
    flex-direction: column;
    justify-content: initial;
    > * {
      width: 100%;
      margin-bottom: ${$.layout.margin3}px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

const LatestSection = ({ items }) => {
  const allPosts = items
    .sort(
      (
        { node: { frontmatter: currNode } },
        { node: { frontmatter: nextNode } }
      ) => {
        const { published: firstPrev } = currNode;
        const { published: firstNext } = nextNode;
        const firstTime = new Date(firstPrev).getTime();
        const nextTime = new Date(firstNext).getTime();

        if (nextTime < firstTime) {
          return -1;
        }

        if (nextTime > firstTime) {
          return 1;
        }

        return 0;
      }
    )
    .map(({ node: { frontmatter, slug, timeToRead } }) => ({
      title: frontmatter.title,
      url: `/${slug}/`,
      img: frontmatter.banner,
      published: frontmatter.published,
      timeToRead,
      tag: frontmatter.tags[0],
      key: nanoid(),
    }));
  const firstPost = allPosts[0];

  return (
    <Section>
      <Container>
        <TitleContainer>
          <FireIcon />
          <h2>Latest Posts</h2>
        </TitleContainer>
        <FirstPost
          url={firstPost.url}
          img={firstPost.img}
          title={firstPost.title}
          published={firstPost.published}
          timeToRead={firstPost.timeToRead}
          tag={firstPost.tag}
        />
        <OtherPostContainer>
          {allPosts.map((res, index) => {
            if (index === 0) {
              return '';
            }

            return (
              <OtherPost
                url={res.url}
                img={res.img}
                title={res.title}
                published={res.published}
                timeToRead={res.timeToRead}
                tag={res.tag}
                key={res.key}
              />
            );
          })}
        </OtherPostContainer>
      </Container>
    </Section>
  );
};

LatestSection.defaultProps = {
  items: [],
};

LatestSection.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
};

export default LatestSection;
