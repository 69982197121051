import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const RemoteLink = makeShortcode("RemoteLink");
const Ads = makeShortcode("Ads");
const LocalLink = makeShortcode("LocalLink");
const Blockquote = makeShortcode("Blockquote");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This show has literally stolen my heart. It’s Myne’s now. Geddit? I'm not too sure which is Myne's proper spelling so I used them all for the blog post title. Woo! Without further ado, let me explain why you need to watch this, and how wrong I was in my initial assessment.`}</p>
    <p><em parentName="p">{`Note: This is written after watching Episode 11. All screenshots are from the `}<RemoteLink to="https://youtu.be/KfPyxG-ZbFM" mdxType="RemoteLink">{`trailer`}</RemoteLink>{`.`}</em></p>
    <Ads mdxType="Ads" />
    <p>{`So in my previous`}<LocalLink to="/blog/is-ascendance-of-a-bookworm-any-good/" mdxType="LocalLink">{` first impression post`}</LocalLink>{`, I was doubtful but hopeful. The manga was good and paced itself well. I did take a look at the fan translated light novel but I prefer the manga more. I really didn’t like the way they drew Myne in the first episode. It felt shoddy and rushed. The presentation honestly deserved better.`}</p>
    <p>{`The flow of the episode usually goes like this - Myne wanted to make paper, she goes to get the ingredients with Lutz, a chibi Myne waltz in to explain the science behind the process, and they either fail or succeed in making it because reality happened. Rinse and repeat for the next one. So why is this hooking me so well?`}</p>
    <h2>{`No superpowers`}</h2>
    <p>{`Myne may be from our world, but she has absolutely no special skills granted from her inter-dimensional leap. Instead, she’s frail. You can honestly tap her lightly and she'll fall. Even walking from her home to the south gate is a momentous task. This makes her struggle much more grounded than other series where all they need to have is `}<em parentName="p">{`imagination `}</em>{`and poof, magic! You won’t be seeing anyone doing a 2 storey tall jumps or anime fight scenes here. It’s just Myne trying her best to make paper with her frail body.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Reality sinks in`}</h2>
    <p>{`There’s this thing called plot armor in most stories. The main character never fails or miraculously rises back up after failing just `}<strong parentName="p">{`once`}</strong>{`. Most weekly shounen had to abide by this unspoken rule. After all, they are published weekly and couldn’t spare time to have the main character fail again and again. The main character has got to be in the spotlight, the winner and being a champion in everything they do. At the very least, they have to have something amazing in them that shadows the rest of the characters.`}</p>
    <p>{`But here we have a protagonist failing again and again. She's nothing special save for her slightly better knowledge compared to the modern folk. Moreover, she kept failing and failing again, even more than the common folk in the story.`}</p>
    <p>{`Reality just ain’t so simple as `}<em parentName="p">{`one, two, three`}</em>{` and voila, it worked through brute force/nakama power. Myne may have succeeded in her baby steps, but the things that can’t be done will stay that way. There’s absolutely no plot shoehorning or miracles happening here. If reality says No, it stays that way.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Fleshing out a community`}</h2>
    <p>{`One of the reasons I love slice-of-life shows is seeing how the MC interact with the people around him/her. As a matter of fact, this is actually one of Ascendance’s main strength. You see Myne interacting with her friends and families and this, in turn, shows off everyone’s character. It’s actually pretty ingenious because it does world-building too. Shows that do world-building are usually a good quality show :)`}</p>
    <h2>{`Improved art and animation`}</h2>
    <p>{`Initially, I thought the art was bad. Bad as in really, really bad. They reuse frames and had weird body proportions for the adults. Turns out the animators are finally getting a breather and everyone’s finally drawn nicely in every frame. You won’t be finding weird body proportions or crooked smiles any more. Even the eyes are properly aligned and constantly drawn the same way now. The movement was way smoother too.`}</p>
    <Blockquote mdxType="Blockquote">This is a kid’s show. Or is it…? </Blockquote>
    <Ads mdxType="Ads" />
    <h2>{`The mood is getting sombre`}</h2>
    <p>{`The first few episodes had Myne figuring things out while getting accustomed to her new life. Halfway through the episode, chibi Myne would fly in to explain things in a cutesy manner. It’s certainly presented in a way for the younger audience. I was fully convinced this presentation will continue in the same manner.`}</p>
    <p>{`Then the Consuming happened. Myne finally found out what happened to the body’s previous owner. Everyone close to her finally realized how dire things are for the young girl. Everyone was shocked as she quietly explained to her family how much time she’s left. The stifling mood as she comes to accept that there’s nothing she can do to avoid her fate is chocking.`}</p>
    <p>{`This… is totally not presented as a kid’s show. It was satisfying to see that they were actually trying to balance the mood between being a cutesy show and a serious slice-of-life. They got me there, not gonna lie.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`I’m lovin’ it`}</h2>
    <p>{`The anime adaption for this series is killing it so far. I honestly can’t wait for the next episode. If you’re ever on the fence for this show, get down right now. This anime has just joined `}<LocalLink to="/blog/why-you-should-watch-mairimashita-iruma-kun/" mdxType="LocalLink">{`Iruma-kun`}</LocalLink>{` in my list.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      