import PropTypes from 'prop-types';

import PrivacyPolicy from '../components/PrivacyPolicy';
import Seo from '../components/Seo';
import keywords from '../utils/keywords';

const PrivacyPolicyPage = ({ location }) => (
  <>
    <Seo
      title="Privacy Policy | Geeknabe - ACG Blog"
      location={location}
      description="Read geeknabe's privacy policy here."
      keywords={['about geeknabe', ...keywords]}
      meta={[
        {
          property: 'og:type',
          content: 'website',
        },
      ]}
    />
    <PrivacyPolicy />
  </>
);

PrivacyPolicyPage.defaultProps = {
  location: {},
};

PrivacyPolicyPage.propTypes = {
  location: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default PrivacyPolicyPage;
