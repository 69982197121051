import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Image = makeShortcode("Image");
const Video = makeShortcode("Video");
const RemoteLink = makeShortcode("RemoteLink");
const LocalLink = makeShortcode("LocalLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Netflix has been knocking it `}<em parentName="p">{`out of the park`}</em>{` lately! They are going from putting `}<strong parentName="p">{`popular anime`}</strong>{` on their platform to actually releasing `}<strong parentName="p">{`original anime`}</strong>{`! I mostly got Netflix because I would like to let my family watch the English movies/TV shows but I’m super happy to see so many anime titles already available on it.`}</p>
    <p>{`Just yesterday (Oct 27th), Netflix announced a ton of new anime coming soon in 2021 on Twitter. FYI, it’s already October 2020, so 2021 is just a few months away. I’m super excited to watch these anime since I really, really loved the manga they are based on. Here are some of the anime titles that I’m super excited to watch from `}<strong parentName="p">{`Netflix`}</strong>{`!`}</p>
    <h2>{`1. The Way of the Househusband`}</h2>
    <p>{`I’ve been following this manga since it’s inception. `}<strong parentName="p">{`The Way of the Househusband`}</strong>{` (Gokushufudou) follows Japan’s most famous and deadliest yakuza… as a househusband. His yakuza title, `}<em parentName="p">{`“The Immortal Tatsu”`}</em>{` strikes fear to any fellow yakuza but to his wife Miku, Tatty is just a clumsy husband who prefers an apron and a cooking knife than a gun. Now you see, Tatsu is one serious guy and it is this seriousness that this comedy series rides on. If you want to see how this series will work as a live action show, you can! It was adapted to a live action clip to celebrate the 1.2m manga sales.`}</p>
    <Image alt="Netflix's poster for Tatty" caption="The poster for The Way of the Househusband!" name="netflix-anime-2021-the-way-of-the-househusband.jpg" mdxType="Image" />
    <Video title="netflix anime" src="https://www.youtube.com/embed/ktztBx-8onk" mdxType="Video" />
    <p>{`If it’s so good as live action, I’m sure it’s even better in anime format. One of my most anticipated titles! Woo! Tatsu, please teach me the way of the housekeeping <3`}</p>
    <h2>{`2. High-Rise Invasion`}</h2>
    <p>{`Now this is one title I didn’t expect Netflix to pick up. The manga was really good in the beginning but I have since dropped it. The story got too convoluted as the serialization went on in my opinion. Some may like the strange direction the story take after the dust settles so please read the story first before dismissing it. If my summary interests you, please consider giving the manga a try! The manga is completed, by the way. Spoiler alert, it’s an action-horror manga, really fast-paced too.`}</p>
    <Image alt="High-Rise Invasion poster by Netflix" caption="One of the masked killers! Now this maid is nice, but the one that strikes fear in mah soul is that one killer that chases them around from room to room. Certain chase scenes took place indoors!" name="netflix-anime-2021-high-rise-invasion.jpg" mdxType="Image" />
    <p>{`It happened in a blink of an eye. Yuri, a high school girl, is trapped on the rooftop of a high-rise building but she didn’t know how she got there. Before she could even grasp the situation, a bloodied man with a smiley mask was chasing to kill her. Her only way around in this mysterious world is to get across the buildings from one rooftop to another. She got lucky for her first encounter, but could she do the same again for the next killer?`}</p>
    <Video title="netflix anime" src="https://www.youtube.com/embed/hVzG_1yI62M" mdxType="Video" />
    <p>{`The world of `}<strong parentName="p">{`High-Rise Invasion`}</strong>{` (Tenkuu Shinpan) has a few rules but the biggest menace has always been the folks with smiley masks on them. The masks control the victim and strengthen their body. Folks who have the mask on couldn’t control their thoughts and actions. So who’s controlling these masks? Is it the same dude that put the humans into this strange world? Please read the manga to find out :)`}</p>
    <h2>{`3. Rilakkuma’s Theme Park Adventure`}</h2>
    <p>{`Does this count as anime? I apologize for adding this here but I enjoyed `}<strong parentName="p">{`Rilakkuma and Kaoru`}</strong>{`! I think everyone should give this series a try! It’s a wonderfully made stop-motion series with an adorable plot. Rilakkuma and Kaoru on Netflix is basically 13 episodes of depicting Kaoru’s struggle with her adult life. And how does Rilakkuma and his band of cute teddy bears fit into Kaoru’s schedule? Well, they just try to help her relax as her companion… Are you relaxed yet? Yessss…This new series seems like a blast for more fluffy episodes! And I love fluffy episodes so there you go. Watch the first season, Rilakkuma and Kaoru on Netflix!`}</p>
    <Image alt="RIlakkuma Theme Park Adventure poster by Netflix" caption="We are going to relax... at the theme park! Woo! LET'S RELAX!" name="netflix-anime-rilakkuma.jpg" mdxType="Image" />
    <h2>{`4. EDEN`}</h2>
    <p>{`I’m sure EDEN is an original anime and it was! I thought it was a movie at first, but it doesn’t seem like it. Tags on the Netflix slot seems to point to a TV series. The summary and trailer points to a world where humans no longer exist and robots took over instead. That... doesn't sound too good, is it? Please watch the trailer for the summary because the 3D animation is pretty good.`}</p>
    <Video title="netflix anime" src="https://www.youtube.com/embed/uDz6kMGVAuY" mdxType="Video" />
    <p>{`I love original shows, and it looks to be directed by some folks who know their stuff. For a dystopian world, the world looks super bright and warm. I didn't mind the 3D direction since it looks right at home. I enjoy stories that talk about parenting and bridging the world between two different species. I could tell it’s going to have a sad ending ): Oh no...`}</p>
    <h2>{`5. Yasuke `}</h2>
    <p>{`I wish there’s a title I could add to this anime, but Netflix didn’t give any other details save for this tweet right here. If you’re a warring states period fan, you should recognize the name Yasuke! He’s the first African samurai that served Oda Nobunaga. Pretty cool stuff here. I have no idea what direction they will take for this anime since they didn’t even release a trailer, but the character artwork looks lit.`}<RemoteLink to="https://twitter.com/NXOnNetflix/status/1320902587856478208" mdxType="RemoteLink">{` I’m definitely interested now! `}</RemoteLink></p>
    <h2>{`6. Vampire in the Garden`}</h2>
    <p>{`Another original anime title! Splendid. Even the summary sounds fab. It’s a story of two girls who met up by chance. The human Momo wants to play the violin while the vampire queen Fine wants to see the wider world. I could literally smell the Carole & Tuesday vibes from far away <3 Apparently it’s being animated by WIT Studio. Hm, let’s see their previous works… They animated Seraph of the End, Ancient Magus Bride and The Great Pretender! Woo! Okay, `}<strong parentName="p">{`Vampire in the Garden`}</strong>{` is in good hands.`}</p>
    <Image alt="Vampire in the Garden screenshot" caption="Can Fine sing while Momo do songwriting? Cos I'm totally seeing a pattern here. Netflix only gave us a summary, and two screencaps so this is all the info you're getting from me too." name="netflix-anime-vampire-in-the-garden-2021.jpg" mdxType="Image" />
    <h2>{`All in all`}</h2>
    <p>{`2021 couldn’t come any sooner! I thought of adding Spriggan, Jojo or Godzilla to the list but I think these titles will naturally catch folks’ attention on Netflix. I had a ton of fun with the original anime by Netflix, like `}<LocalLink to="/blog/lost-song-an-anime-about-singing-with-a-smart-loop/" mdxType="LocalLink">{`Lost Song`}</LocalLink>{` or A.I.C.O. so here’s hoping the streak continues.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      