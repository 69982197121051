import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Blockquote = makeShortcode("Blockquote");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I’m on a nostalgic roll lately, and the latest anime to fall prey to my nostalgia journey is `}<strong parentName="p">{`Angelic Layer`}</strong>{`. I blame Netflix for this. And Cardcaptor Sakura (CCS)! CCS’s full episodes are readily available on Netflix and that only served to fuel my desire to watch more of the older shoujo shows by CLAMP. In case the obvious bias hasn’t sunk in yet, I `}<strong parentName="p">{`adore`}</strong>{` CLAMP’s work on their shoujo section. CLAMP’s titles have this `}<em parentName="p">{`magic`}</em>{` in them. It’s akin to seeing a girl’s dream come true, or to have someone subtly giving us girls a push to go out there and do good work.`}</p>
    <Ads mdxType="Ads" />
    <p><em parentName="p">{`Note: I am referring to the anime version of Angelic Layer. The anime changed up some details from the manga. E.g. Misaki named her Angel based on a character from Magic Knight Rayearth (a manga in Angelic Layer’s world) in the manga but in the anime, she named her Angel based on her favourite doll from childhood.`}</em></p>
    <Blockquote mdxType="Blockquote">
  From strong warrior ladies (Magic Knight Rayearth) to magical girls (CCS),
  CLAMP has done it again with Angelic Layer. 
    </Blockquote>
    <p>{`The plot in Angelic Layer is super simple, but the underlying message is what’s important! (And the dolls!) Angelic Layer starts off by introducing the protagonist`}<strong parentName="p">{` Misaki Suzuhara`}</strong>{`, a 12 year old girl who moved in with her aunt in Tokyo to study there. After arriving in the city, she saw a heated battle between two dolls on a big screen outside the station. What she saw is `}<strong parentName="p">{`Angelic Layer`}</strong>{`, a popular game in which dolls called Angels are controlled by a player through brainwaves. These Angels could only move on a field called layer and to control these dolls, the players (termed as `}<em parentName="p">{`Deus`}</em>{`) in the game must wear a headset. Misaki was enthralled by the game, but her attention was completely captured by a white Angel who dominated the battle. This is the start of the Miraculous Rookie Misaki and her angel Hikaru, who fought their way up to meet with the undefeated Champion of Angelic Layer.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Angelic Layer manga poster" caption="The dolls from the manga. I love old school CLAMP art. There's this sketchy feeling to it, and they do such a great job at making the characters' expression softer. Good shoujo art? Yes, good shoujo art." name="angelic-layer-blog-anime-review-geeknabe.jpg" mdxType="Image" />
    <Blockquote mdxType="Blockquote">
  Making dolls fight seems like the most juvenile thing to do. BARBARIC!{' '}
    </Blockquote>
    <p><em parentName="p">{`Oh hush!`}</em>{` You don’t want to know what I`}<em parentName="p">{` used`}</em>{` to make my Barbie dolls do… `}<strong parentName="p">{`Ahem`}</strong>{`. Angelic Layer presents a wonderful sentiment that all girls dreamed of - `}<em parentName="p">{`seeing our best friends move`}</em>{`. I love my teddy bears and my dolls when I was a kid. I still have them with me now since they make up most of my childhood! I eventually grew out of playing dolls but the fondness for my best friends still `}<em parentName="p">{`remains`}</em>{`. If you asked the child in me what I would wish for, I will choose to wish for my dolls to `}<em parentName="p">{`move`}</em>{`. As far as I’m concerned, CLAMP got this `}<strong parentName="p">{`childhood sentiment`}</strong>{` right with Angelic Layer.`}</p>
    <p>{`In the story, Angels are repeatedly treated as an extension of ourselves, a friend or even a daughter. On the layer, you could do anything and let your hearts`}<em parentName="p">{` fly free.`}</em>{` Who wouldn’t give their hearts to dolls that are close to your heart? The cast would talk about a connection they have with their Angels which I find absolutely `}<strong parentName="p">{`wholesome`}</strong>{`. Each Angel has a story, and they mean something to their Deus. These Angels have their clothes designed and sewed from scratch. Their names weren’t picked from a random list! There’s even an episode highlighting this sentiment about naming your dolls right. These dolls aren’t just tools, they are a girl’s `}<em parentName="p">{`partner`}</em>{`. The message was a little `}<em parentName="p">{`cheesy`}</em>{`, but I love how they made sure to highlight this in an episode where a boy gets to strut their stuff. The way the episode was handled made me feel super proud, as though CLAMP is making sure the girls watching the episode feel that `}<em parentName="p">{`they can do anything as long as they put their hearts to it`}</em>{`.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Angelic Layer characters" caption="On the left, Misaki and her doll, Hikaru. On the right, Hatoko and her doll, Suzuka." name="angelic-layer-misaki-and-honoka-deus.jpg" mdxType="Image" />
    <p>{`The message was further drilled in through Misaki and her Angel, Hikaru. Misaki designed Hikaru to be small and light on her feet. She wanted to prove that even someone small could win against bigger opponents. Misaki is a rookie in Angelic Layer, but she got so far in the Angelic Layer tournament because she refused to give up even against strong opponents. Each of her opponents had a specialty while she had none. Hikaru (Misaki’s Angel) is just really light on her feet and as her Deus, Misaki is good at remembering moves she saw once. Each of her fights were against really tough opponents, but Misaki kept up with these veterans with quick judgment and observation.`}</p>
    <p>{`I’m sure the idea behind Misaki/Hikaru’s personality and story is to motivate the girls watching the show to feel `}<em parentName="p">{`empowered`}</em>{`. Strength isn’t everything, turn your weaknesses into strengths instead, never giving up in the face of adversity, etc. These are lovely messages in a shoujo show! It’s part of the reason why Angelic Layer is one of my favourite shows even till now. Even so, Angelic Layer isn’t a one-trick pony. There’s the issue with Misaki’s mother, love problems among the cast and friendly rivalry between the strong players… I didn’t really put much stock in the “side quests” since I have my eyes glued on the battles instead. That’s not to say these “side quests” aren’t nice to have. The mother-daughter resolution at the end of the series is pretty heartwarming. You see the girls getting frustrated after losing the match because that’s how much Angelic Layer `}<em parentName="p">{`means`}</em>{` to them. It was `}<strong parentName="p">{`personal`}</strong>{`. In fact, this entire show felt really personal to the viewers.`}</p>
    <Ads mdxType="Ads" />
    <p>{`At least, it was to me that I wonder why this show doesn't get more popular than CCS. Despite the praises for the show, I `}<em parentName="p">{`do`}</em>{` have questions now that I’m rewatching it years later… Why on earth are Misaki, Kotaro and Tamayo always wearing their school uniform wherever they go? Yes, the school uniform looks lovely but… why? Why is Hatoko, a 5 year old Angelic Layer genius, being allowed to wander around without adult supervision? Perhaps the most important question of them all… Why wouldn’t you contact your daughter for seven years even for a quick call? That’s super irresponsible for a mother. CLAMP has always been off with the minute details even in CCS, as if telling us readers not to mind the small details and look at the big ones instead. But these burning questions have reminded me that just maybe, CLAMP just never thought that far in the first place. Perhaps, they just didn’t bother.`}</p>
    <p>{`It’s okay, I still love Angelic Layer for what it is. Please watch the show! It’s a great throwback if you enjoy watching classic shoujo with a dash of CLAMP’s touch. Please take note of the music too because I find the music really fitting for bringing out the mood of certain scenes. Truly, simple beats are always the best. While I’m at it, I might as well recommend Tsubasa Chronicles! Please read the manga, watch S1 but skip S2 of the anime and go straight for Tsubasa OVA (TOKYO Revelations and Shunraiki).`}</p>
    <Image alt="Angelic Layer ending scene" caption="Here's a lovely screencap to end this post. Mother and daughter, together again <3" name="angelic-layer-athena-hikaru-anime-screencap.jpg" mdxType="Image" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      