import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const RemoteLink = makeShortcode("RemoteLink");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The Liyue characters are my favourite in terms of design so far. It helps that I use most of the Liyue characters on my main team always, but Liyue character design resonates with me the most. Partially because they are using Chinese design elements that I am more familiar with compared to the Mondstadt characters. There’s this… `}<em parentName="p">{`fantasy feeling`}</em>{` to the Mondstadt’s character design whereas Liyue’s character design feels more like home. At least, most of it because the designs can be pretty modern too.`}</p>
    <p>{`Liyue’s ladies and gentlemen basically has a `}<strong parentName="p">{`mix of modern elements`}</strong>{` as well as some subtle throwbacks to `}<strong parentName="p">{`Chinese motifs`}</strong>{`. I guess they wanted to subtly hint at the players that these are based on Chinese culture but they didn’t want to borrow the motifs wholly.`}</p>
    <Ads mdxType="Ads" />
    <p><strong parentName="p">{`Update Note: This is written during 1.4 (if I am not mistaken). Old news is old! With the new 1.5 update, Zhongli's character is further expanded to show his more humane side. I hope you enjoyed 1.5 as much as I did.`}</strong></p>
    <p><strong parentName="p">{`IMPORTANT NOTE:`}</strong>{` I’m at best, an amateur at being a Chinese person (I’m not from the mainland and I got an English education) but I do speak the language and my family taught me the do’s and don’ts of being Chinese. I'm kinda the first generation of English-speakers in the family while the older generation in my family are fully Chinese educated. It took a ton of questioning with folks that got Chinese education and googling to understand deeply about these motifs because we knew about the meaning of these patterns, buttons and even the tassels! But not the idea behind it. I’ve always had a fond appreciation for Chinese culture but there really isn’t any reliable source to learn about them. I took the chance to learn more through researching for this blog post! I try my best but I may have missed a lot of the intended meaning or gotten things wrong (It was tough to verify!), so feel free to comment if you noticed anything I missed! `}<strong parentName="p">{`I would like to learn too.`}</strong></p>
    <p><strong parentName="p">{`SECOND NOTE:`}</strong>{` If you wish to know more about the Chinese culture in English, please try this`}<RemoteLink to="https://www.youtube.com/channel/UCCs8qEi8D2u_g1ZKDU82adg" mdxType="RemoteLink">{` YouTube channel Goldthread.`}</RemoteLink>{` I love this channel to bits because they touched upon everything I wish to know about the language, cultural events and food!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Common Chinese design motifs across all Liyue characters`}</h2>
    <p>{`The first thing I noticed from Liyue character clothings are the buttons and knots! The buttons are called a Chinese button knot, where you use it to fasten clothes. Knots (中国结) in general are integral to Chinese culture because it means a lot of things to us. Of course, mostly good things! The most common knots you’ll see everywhere is the `}<RemoteLink to="http://3.bp.blogspot.com/-UVGBNFbjEEA/TY_Wu-ydOQI/AAAAAAAAAKA/WlfOZoCieQA/s1600/IMG_4028.JPG" mdxType="RemoteLink">{`Pan Chang (盤長) knot`}</RemoteLink>{`. It represents the fundamental truth in `}<RemoteLink to="https://www.nationsonline.org/oneworld/Chinese_Customs/8_buddhist_symbols.htm" mdxType="RemoteLink">{`Buddhism - cyclical change of all things`}</RemoteLink>{`.`}</p>
    <Image alt="Chinese knots" caption="I'm used to seeing the Pan chang one everywhere during Chinese New Year." name="chinese-knots.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <p>{`I noticed that most of the Liyue characters only had a simple looking knot on their clothing or a lucky charm as their Vision. I suppose they weren’t going all out at the knot symbolism but if we were to take it as it is, then most of the characters have a `}<RemoteLink to="https://i.pinimg.com/originals/d2/e7/45/d2e745cdf5bf6c87ed8d1b6787cd0cc9.jpg" mdxType="RemoteLink">{`Cloverleaf knot`}</RemoteLink>{` on them. This knot is a symbol of luck and fortune. If you’re interested in learning how to make them, `}<RemoteLink to="https://artsproutsart.com/chinese-knots-how-to-chinese-new-year-traditional-craft/#buddha" mdxType="RemoteLink">{`check this guide here`}</RemoteLink>{`.`}</p>
    <p>{`The next thing I noticed are the patterns, especially those inspired by nature. One of the most common patterns I noticed are clouds. The presence of cloud patterns on the Liyue characters are probably nothing too deep since it’s a common pattern, but in Chinese culture clouds represent good luck. That’s because the Chinese word for cloud (云) can be pronounced the same as luck. Kinda the reason why you keep seeing the number 8 during Chinese New Year, because the number 8 (八) sounds the same as prosperity (发财). This is also why the uh, `}<RemoteLink to="https://genshin.mihoyo.com/en/news/detail/8717" mdxType="RemoteLink">{`Lantern Rite web event`}</RemoteLink>{` primogem award ends with an 8 (108/188/288)! Enjoy noticing the little intricate details on every Liyue character! Then there are a few characters that I couldn’t help but notice the implicit meaning right away.`}</p>
    <h2>{`Zhongli Design Analysis`}</h2>
    <p>{`Phew, I went from researching his suit design to understanding the very implication of his Chinese banner name: 陵薮市朝 (líng sǒu shì cháo). It was pretty tough because it didn’t mean anything to us casual Chinese speakers (What on earth is líng sǒu shì cháo...). I was hoping that maybe the `}<RemoteLink to="https://www.163.com/dy/article/FSR2OBJR05199PJU.html" mdxType="RemoteLink">{`Chinese bloggers`}</RemoteLink>{` could shed some light on this and voila, I found a hint. Apparently the coined words originate from a poem by 王康琚 (Wángkāngjū) that starts off with this sentence - 小隐隐陵薮，大隐隐朝市 (Xiǎo yǐnyǐn líng sǒu, dà yǐnyǐn cháoshì).`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Zhongli Chinese Wish Banner" caption="Zhongli's title in Chinese is 岩王帝君 (Yánwáng Dìjūn) which loosely translates as Stone Lord Sovereign. I don't know about you, but that sounds more badass than Rex Lapis." name="zhongli-chinese-wish-banner.jpg" mdxType="Image" />
    <p>{`This little poem had me spinning my head because I couldn’t wrap my head around the lines with my broken Mandarin! I went to 2 folks good with Chinese and had them explain it to me. Guess what they both spoke to me right after they read the lines.`}</p>
    <p>{`Them: “Man, how do I explain to you… There’s like, a ton of meaning here…”`}</p>
    <p>{`Me: “Ok fine, ELI5 but with a dose of dumbed down Chinese for a English-educated like me.”`}</p>
    <p>{`Thanks to a good friend of mine who knows his Chinese well and could explain really quickly, I finally got the basic gist down. The most basic and most simplistic interpretation of this poem (that I believe alludes to Zhongli’s personality really well) is that people with little power but big ambition will stand out in plain sight, while those with power but zero ambition enjoys hiding in the crowd. You could further interpret it as the wise man knew that if he stuck out too much, nothing good would happen. However, he will intervene should the need arise. There’s more to it, but I believe this is as simple as we can do for a basic interpretation. If anyone could explain it better, feel free to comment below!`}</p>
    <p>{`The poem really DOES match Zhongli’s story. Fit to a tee! The English banner name is called “Gentry of Hermitage” so in a way, it DOES allude to the poem’s interpretation `}<em parentName="p">{`to the best they can`}</em>{`. That’s actually pretty banger!`}</p>
    <Ads mdxType="Ads" />
    <p>{`The next thing I noticed about Zhongli is how modern his clothing designs are. I was half expecting something… outstanding for our Geo Lord but unexpectedly, it’s actually a really classy suit. I found only one Chinese motif on it, namely the dragon's body on his suit flap. There are scales that shimmers individually if you spin the camera around while admiring his suit flap. I find that pretty nice. The rest of him are diamond-shaped items which pretty much alludes to the Geo symbol from the game. Look beyond that, and you’ll find that his design gives the impression of `}<em parentName="p">{`sharpness.`}</em>{` The collar of his doesn’t need to be sharp but it is. There are lines going down his back and pretty much wherever you look, there’s a lot of straight lines here and there.`}</p>
    <Image alt="Zhongli front view" caption="The dragon scales shimmer when you move the camera or the flap moves. Very nice." name="zhongli-front-view.jpg" mdxType="Image" />
    <p>{`I could only surmise that the designer is trying to imply that Zhongli is a character that sticks to his principles, flawless and lives righteously. In Chinese, there’s a ton of idioms that use the word “square” or “lines” that speak of being proper and flawless, or even orderly for that matter! No curves, no backsies. Straight and proper. For example, 做人应当品行方正 (zòu rén yīng dāng pǐn xíng fāng zhèng) which means you, as a person, should live righteously/with flawless morality. 方正 (fāng zhèng) is an adjective which means “square and straight” which goes back to implying the meaning of the idiom. If you’re wondering what’s the connection between straight lines and squares... In Chinese culture, a square is a shape with four straight lines at degree. It is used to represent Earth in Feng Shui (while a circle is used to represent the Heavens), and of law/order. Basically, a square means stability, orderly, conformity, etc. in Chinese culture.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Zhongli back view" caption="It did find it strange that there's so much going on for his back view. There's a lot of geometrical shapes, especially squares and straight lines. The poem gave me a strong impression that this is intentional by the designer!" name="zhongli-back-view.jpg" mdxType="Image" />
    <p>{`A good friend of mine even noted that his suit design is actually symmetrical (We noticed this after we surveyed every Liyue characters’ design!). Not only does the lines go straight down, both sides of his suit mirror each other perfectly. It gives off a sense of tidiness, orderly and fairness. I wasn’t sure at first about my writeup about Zhongli’s design at first, but checking out his voice lines, battle quips and lore, it really does match somewhat. On that note, `}<RemoteLink to="https://www.reddit.com/r/Genshin_Impact/comments/k9xmqd/%E5%A4%A9%E5%8A%A8%E4%B8%87%E8%B1%A1i_will_have_order/" mdxType="RemoteLink">{`here’s a translation to his Chinese quip for Q`}</RemoteLink>{`. Absolutely badass, the English translation couldn’t come close to the original meaning. Zhongli’s meteor is actually a `}<RemoteLink to="http://wiki.china.org.cn/index.php?title=Luban_Lock" mdxType="RemoteLink">{`Lu Ban locks or a Kongming locks`}</RemoteLink>{` which is an interlocking puzzle toy.`}</p>
    <h2>{`Ningguang Design Analysis`}</h2>
    <p>{`I just love her back so much. Her clothing has the prettiest design I have ever seen in the game. Her sleeves have a floral vine pattern that shimmers when you spin the camera or when Ningguang is moving. She has a ginkgo leaf pattern wrapped around her hip and her outfit gives me a “`}<RemoteLink to="https://www.cntraditionalchineseclothing.com/image/cache/data/2019/12/2-Split-Cheongsam-Qipao-Artistic-Vintage-Fashion-Chinese-National-Style-Dress-800x800.jpg" mdxType="RemoteLink">{`cheongsam/qipao`}</RemoteLink>{`” feel. At first impression, her design reminds me so much of a high ranking imperial consort, or of an `}<RemoteLink to="https://en.wikipedia.org/wiki/Empress_Dowager_Cixi" mdxType="RemoteLink">{`empress dowager`}</RemoteLink>{`. Her hairstyle reminds me a lot of the hairdos often worn by Qing Dynasty consorts, not to mention the `}<RemoteLink to="https://guzhuangheaven.tumblr.com/post/182597279835/can-you-possibly-explain-nail-guards-to-me-or" mdxType="RemoteLink">{`nail guards`}</RemoteLink>{` and the smoking pipe.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Ningguang back view" caption="The vine pattern shimmers, just like how Zhongli's dragon scale did! Her dress's edges had a ton of fan-shaped ginkgo leaf throwbacks, I find that really nice. And her buttons? I'm not sure if it's related but do check out pankou button! It's the knot buttons found on qipao. They are usually elaborately designed, really pretty." name="ningguang-back-view.jpg" mdxType="Image" />
    <p>{`I wasn’t sure of my empress dowager interpretation until I looked up what Ginkgo tree meant in Chinese culture. Yup, Ningguang’s entire outfit design has a strong ginkgo leaf design motif on it! I attached a picture of ginkgo leaves below. It’s a beautiful tree when the leaves turn golden during autumn. Most folks knew of the ginkgo tree for its medicinal properties but in Chinese culture, a ginkgo tree symbolizes longevity. Not to mention that her name card is of a phoenix. Phoenixes are often used to represent the Yin energy (feminine qualities/energy) when paired with a dragon. Thus, you’ll often find phoenixes on an empress’s clothes while dragons are etched on the emperor’s clothes. Her title in English is Eclipsing Star which is 100% the correct meaning for her Chinese title, 掩月天权 (Yǎn yuè tiān quán). It’s a pretty bombastic title since it means that she has the power to eclipse the moon herself (Alluding to her Jade Chamber).`}</p>
    <Image alt="Ginkgo leaf" caption="Ginkgo leaves are fan-shaped. They look beautiful, even more so than Sakura trees." name="ginkgo-leaf.jpg" mdxType="Image" />
    <Image alt="Ningguang name card" caption="Ningguang's name card!" name="ningguang-name-card.png" mdxType="Image" />
    <p>{`So yes, Ningguang’s design is pretty much everything to do with an empress. A legitimate all-powerful empress! <3 GEO MAMA!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Everyone else’s Design Analysis`}</h2>
    <p>{`There’s not much to talk about for the other characters so I’ll just sum it up all of them here. This is not me being bias (My beta readers are accusing me of this... Oof)! True, I love Ningguang and Zhongli in my Geo team but the rest of the Liyue characters have very little motif to talk about.`}</p>
    <p><strong parentName="p">{`Keqing`}</strong>{` wears purple and has a peacock design. If you read her story from her menu, she hails from a prestigious family. You could say that all of the gold lining/emboss and her ojou-sama vibe IS because she’s from a prestigious family, but you could see it as proof of her ranking as part of the Qixing. `}<RemoteLink to="https://www.christies.com/features/Chinese-Ceramics-How-to-decode-the-meanings-of-traditional-symbols-7229-1.aspx" mdxType="RemoteLink">{`Peacocks were synonymous with dignity, beauty, enlightenment`}</RemoteLink>{`. Only the officials of the highest rank were allowed to wear a hat with a peacock feather!`}</p>
    <Image alt="Keqing back view" caption="Keqing's sleeves are really elaborate peacock feathers. And yes, the scroll she opens up during her idle animation is a map of Liyue!" name="keqing-back-view.jpg" mdxType="Image" />
    <p><strong parentName="p">{`Qiqi`}</strong>{` is undoubtedly a `}<RemoteLink to="https://mythus.fandom.com/wiki/Jiangshi" mdxType="RemoteLink">{`jiangshi`}</RemoteLink>{` in terms of her sad backstory and design. The hat, her braid, the talisman… Imagining Qiqi doing the hop makes me cackle. Each of her earrings are white and black respectively which is a nice throwback to the yin and yang symbol. I noticed that she got a cute ribbon on her braid, oh so adorbs.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Qiqi front view" caption="Qiqi has the cutest name. It means Seven Seven in Chinese! They even got her black fingernails correct (Jiangshi's fingernails are usually black)" name="qiqi-front-view.jpg" mdxType="Image" />
    <p>{`Ganyu’s design confuses me… I guess they were trying to go for their own interpretation of Qilin because the `}<RemoteLink to="https://cdn.britannica.com/84/138984-050-6C7DAEC6/statue-Qilin-Summer-Palace-Beijing.jpg" mdxType="RemoteLink">{`OG Qilin`}</RemoteLink>{` is not the most photogenic mythical creature out there. Her clothes mimics billowing clouds with wing-like protrusion which is pretty nice, and her purple gradient-ish hue is undoubtedly inspired from `}<RemoteLink to="https://static.wikia.nocookie.net/gensin-impact/images/e/e1/Item_Glaze_Lily.png/revision/latest?cb=20201117074109" mdxType="RemoteLink">{`Glaze Lilies`}</RemoteLink>{`. If we were to take her Vision’s tassel as it is, it’s a cloverleaf knot for good luck, which is a nice throwback to Qilin symbolizing good luck and prosperity.`}</p>
    <Image alt="Ganyu front view" caption="I think the reason I'm so confused about her design is because she's wearing tights. Her shoes has little wings on it, how cute!" name="ganyu-front-view.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <p><strong parentName="p">{`Xingqiu`}</strong>{`’s design on the other hand, has frills and boots that remind me of western boots. I think his design is supposed to evoke a 少爷 (Shàoyé) vibe/young master vibe. I cackled at his idle animation that has him holding a book and putting one of his arms on his back. I often see this pose in ancient China dramas, where a scholarly man walks around with a book to read/recite poetry!`}</p>
    <Image alt="Xingqiu side view" caption="Xingqiu doing ze pose." name="xingqiu-side-view.jpg" mdxType="Image" />
    <h2>{`All in all`}</h2>
    <p>{`And that’s all I have! Beidou’s design didn’t really have anything strike out to me other than the anchor pattern all over her clothing. As for Xiangling, her design is like a cute tiger/china doll with twin buns. I really love her design, it’s really cute! Xinyan’s design is obvious enough but if you stare at her pigtails, it… kinda looks like a Jueyun chilli with small red streaks on the inside. I find it really cute. That's all I noticed from the Liyue cast. I hope you enjoyed this little write-up!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      