import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const RemoteLink = makeShortcode("RemoteLink");
const Blockquote = makeShortcode("Blockquote");
const Ads = makeShortcode("Ads");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`We are now 3 days away from release, and many things have happened in the Pokémon scene. Both games were leaked just weeks before the release date. `}<strong>{`Gigantamax`}</strong>{` forms are all leaked as well. Fans have somehow gotten their hands on their copies and have been streaming the game on Twitch. There's even a subreddit dedicated to Sword and Shield leaks! So if you want to know which Pokémon survived the culling, visit `}<RemoteLink to="https://www.reddit.com/r/SwShLeaks/" mdxType="RemoteLink">{`/r/SwShLeaks`}</RemoteLink>{`.`}</p>
    <p>{`But wait, there's more. `}<RemoteLink to="https://www.nintendoenthusiast.com/game-freak-cancels-pokemon-sword-and-shield-tokyo-launch-event/" mdxType="RemoteLink">{`The launch event in Tokyo was cancelled`}</RemoteLink>{`, and `}<RemoteLink to="https://twitter.com/hashtag/ThankYouGameFreak?src=hashtag_click" mdxType="RemoteLink">{`#ThankYouGameFreak`}</RemoteLink>{` twitter hashtag is trending to combat the negativity by the fans.`}</p>
    <Blockquote mdxType="Blockquote">
  This is by far, the most controversial Pokémon game launch I have ever
  witnessed.
    </Blockquote>
    <Ads mdxType="Ads" />
    <p>{`Ever since we were shown the first few trailers, the fans' reception was first cold, then lukewarm. Now they are just seething with anger. `}<RemoteLink to="https://www.reddit.com/r/pokemon/" mdxType="RemoteLink">{`/r/pokemon`}</RemoteLink>{` gets an angry fan post almost everyday now, and these posts have always received a ton of silver/gold awards. As a fan myself, I understood the anger, and I quite agree with the points brought up by these posters. Obviously, there's more to this controversy but I've summarized the main points from what I've seen these past few months.`}</p>
    <em>
  Note: All images from this blog post unless stated otherwise are sourced from{' '}
  <RemoteLink to="https://swordshield.pokemon.com/en-us" mdxType="RemoteLink">
    Pokémon Sword and Shield official site
  </RemoteLink>
    </em>
.
    <p>{`Warning: `}<strong>{`Spoiler alert!`}</strong></p>
    <h2>Game Freak and their conflicting statements</h2>
    <p>{`No consumers like to be told one thing, and be given another thing. It's a shameful practice, and one that does not respect your consumers. This is made worse if your products rely on a fanbase! `}<RemoteLink to="https://www.reddit.com/r/pokemon/comments/drtjw5/never_forget_this_quote_by_junichi_masuda/" mdxType="RemoteLink">{`Here's one that really resounded with me`}</RemoteLink>{`. In essence, Pokémon has always been about catching them all. As a technical person myself, cutting off Pokémon is a fair business decision when it is about time and effort needed to deliver the product as soon as possible. But to make a PR statement about this issue and do a u-turn? NO.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Remember how Junichi Masuda, game producer for both Pokémon Sword and Pokémon Shield once said about `}<RemoteLink to="https://www.usgamer.net/articles/pokemon-sword-and-shield-interview" mdxType="RemoteLink">{`cutting half the Pokedex to improve animation`}</RemoteLink>{`? I honestly think that's fair, but looking at the spin-in-place cutscene made me think otherwise.`}</p>
    <h2>Shhh, let's not tell unless we have to</h2>
    <p>{`We have always assumed that every Pokémon will be in the game. Previous games have done it before so why not this one as well? Unfortunately, we weren't even told about the `}<RemoteLink to="https://kotaku.com/not-all-pokemon-can-be-imported-into-sword-and-shield-1835446885" mdxType="RemoteLink">{`Pokedex cut`}</RemoteLink>{` until it was quietly revealed during a Treehouse Live section in E3 2019. So far, this is the only section that revealed the unfortunate news. I guess they knew it wouldn't look too good for them if they reveal it in a main section, where most viewers would be watching.`}</p>
    <h2>Pokémon Home is a pokémon jail</h2>
    <p>{`Pokémon Home is a direct replacement for Pokémon Bank, or at least, according to first impressions from this `}<RemoteLink to="https://www.youtube.com/watch?v=vTWkBjxvBok&t=793s" mdxType="RemoteLink">{`announcement`}</RemoteLink>{` by Game Freak. However, once you transfer your Pokémon to Home, you can't transfer them into the game if said Pokémon is not available in the game. That's how Pokémon Bank worked for older games so this is fair (once more), but wait till you read what Junichi has to say about this.`}</p>
    <em>
  "We encourage people to use Pokémon Home to collect their Pokémon from old
  games there. From there, they might be able to take it to other games in the
  future. So take good care of your old Pokémon, because you might be able to go
  out with them again in the future."
    </em>
    <p>{`The decision to limit the Pokémon count in every new game after Sword and Shield will be a `}<RemoteLink to="https://www.vg247.com/2019/11/10/pokemon-future-pokedex/" mdxType="RemoteLink">{`mainstay`}</RemoteLink>{`, so your favourites may or may not be staying the Pokémon Home indefinitely. That's the same as keeping our Pokemon hostage in that app, Game Freak! But what takes the cake is that none of these information will be known to all casual players unless they dig hard into the FAQ or ask around in forums. No one, save for the hardcore fans knew about the cut. It's a storm of disappointment just waiting to happen.`}</p>
    <Ads mdxType="Ads" />
    <h2>EXP. Share cannot be turned off</h2>
    <p>{`It is baked in without any options to toggle it. Once again, I am okay with this decision, but guess what's `}<RemoteLink to="https://www.gonintendo.com/stories/348837-game-freak-explains-why-exp-share-is-baked-into-pokemon-sword-an" mdxType="RemoteLink">{`Junichi's reply`}</RemoteLink>{` to this:`}</p>
    <em>
  “Those who have it turned off want to train one specific Pokémon. We thought:
  is there a way to achieve that? There is, simply by putting the rest of your
  Pokémon in the PC.”
    </em>
    <p>{`I honestly thought this is as infuriating as it can get. Game Freak is intentionally ignoring fan requests by giving us a roundabout answer. I am seeing a pattern here from their statements. They stand firm on their bad decisions, and never listened to the fans.`}</p>
    <h2>All in all, it's 50% bad development decision, 50% bad PR</h2>
    <p>{`Reading tweets with #ThankYouGameFreak hashtag really makes me happy to see how much positivity trumps the negativity. While it is heartening to read the positive tweets, both side have legit reasoning for acting the way they did.`}</p>
    <p>{`Game Freak hasn't been responding well to fans' requests and their statements is equally just as infuriating to read. Their statements sound more like downplaying the fans' requests and not respecting the consumers at all.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Their development decisions are equally as bad. If the fans can come up with better solutions or gameplay choices, then there's something fundamentally wrong with the developers. The developers ought to be on top of their game, and come up with a great user experience in their software. If they can't do this well, then why are the consumers even buying their software?`}</p>
    <Blockquote mdxType="Blockquote">
  Vote with your wallet. Make sure The Pokémon Company knows about this. We
  can't continue letting them get away with a subpar Pokémon game.
    </Blockquote>
    <h2>PSA: Do not let your anger get to the younger fans</h2>
    <p>{`I get it, we've been playing Pokémon since Red and Green. We are the pioneers, the originals, etc. We've been anticipating for Sword and Shield, the first Pokémon console game and yet, the leaks and new information is just disappointing. Our complains are valid but let's not dish it out to the younger fans.`}</p>
    <p>{`Pokémon would be nothing without our younger selves, and we need the younger kids to continue on the legacy. Remember that feeling when you get your first Pokémon game? Don't ruin it for them.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      