import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';

import $ from '../../../styles/globals';
import LeftChevron from '../../../assets/icons/left-chevron.svg';
import RightChevron from '../../../assets/icons/right-chevron.svg';
import series from '../../Series/series.json';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;

  ${$.screen.mobile} {
    flex-direction: column;
  }
`;

const StyledLink = styled(Link)`
  ${({ color }) => `
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${color};
  text-align: center;
  text-decoration: none;
  font-size: 20px;
  padding: ${$.layout.padding3}px;
  width: calc(50% - ${$.layout.padding3 * 2}px);
  color: ${$.color.white};
  font-family: Rubik Regular;
    font-weight: bold;
  
  svg {
    fill: ${$.color.white};
    width: 28px;
    height: 28px;
  }


  ${$.screen.desktop} {
    transform: translateY(0);
    transition: all 0.3s ease;

    &:hover {
      transform: translateY(-0.3em);
      text-decoration: underline;
    }
  }

  ${$.screen.tablet} {
    text-decoration: underline;
  }

  ${$.screen.mobile} {
    text-decoration: underline;
    font-size: 20px;
    width: calc(100% - ${$.layout.padding3 * 2}px);
    
  }
`}
`;

const FurtherAction = ({ tag }) => {
  const [seriesURL, setSeriesURL] = useState({
    found: false,
    series: '/series/',
  });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setSeriesURL((prev) => {
        const newPrev = { ...prev };

        series.forEach(({ links, slug: seriesSlug }) => {
          links.forEach(({ slug }) => {
            if (
              newPrev.found === false &&
              window.location.pathname.indexOf(slug) > -1
            ) {
              newPrev.series = `/series/${seriesSlug}/`;
              newPrev.found = true;
            }
          });
        });

        return newPrev;
      });
    }
  }, []);

  return (
    <Container>
      <StyledLink
        title={`See more blog posts tagged as #${tag}!`}
        color={$.color.orange}
        to={`/topic/${tag}/`}
      >
        <LeftChevron />
        <div>{`More #${tag} posts`}</div>
      </StyledLink>
      {seriesURL.found && (
        <StyledLink
          title="See similar blog posts!"
          color={$.blue1}
          to={seriesURL.series}
        >
          <div>See similar posts</div>
        </StyledLink>
      )}

      <StyledLink
        title="Check out Geeknabe's blog index!"
        color={$.color.green2}
        to="/blog/"
      >
        <div>View all posts</div>
        <RightChevron />
      </StyledLink>
    </Container>
  );
};

FurtherAction.propTypes = {
  tag: PropTypes.string,
};

FurtherAction.defaultProps = {
  tag: '',
};

export default FurtherAction;
