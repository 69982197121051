import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const LocalLink = makeShortcode("LocalLink");
const Blockquote = makeShortcode("Blockquote");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Stories with `}<em parentName="p">{`yuusha`}</em>{` tropes are one of my personal favourites! I’d say it ranks slightly higher than isekai (otome) stories in my list, but lower than the traditional shounen or shoujo titles. Japan loves their `}<em parentName="p">{`yuusha`}</em>{` stories, where a titular swordsman would start their journey from their hometown, collect comrades to join their cause and ultimately, defeat the evil demon lord. No doubt the stereotype is highly influenced by `}<strong parentName="p">{`Dragon Quest`}</strong>{`, thus why I opted to use the term `}<strong parentName="p">{`yuusha `}</strong>{`in the title.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Dragon Quest III's hero" caption="When illustrating a yuusha, I notice most mangaka like to use this character's silhoutte. The silhoutte always have a cape, sword and a shield..." name="yuusha-dragon-quest.jpg" mdxType="Image" />
    <p>{`I didn’t want the English readers clicking on this post expecting `}<em parentName="p">{`superheroes `}</em>{`manga recommendations instead. Plus, nothing screams `}<em parentName="p">{`manga `}</em>{`more than the term `}<strong parentName="p">{`yuusha`}</strong>{`. I’m equally aware that some may not know the `}<strong parentName="p">{`Japanese word for hero`}</strong>{`, so I’ve added the English translation to the title too. I hope the title for this blog post is self-explanatory enough! Now that you know why I titled this post as `}<em parentName="p">{`yuusha, `}</em>{`I’ll be using the term `}<strong parentName="p">{`hero`}</strong>{` throughout this write-up after this point.`}</p>
    <p>{`Every manga title here follows the plot of a noble hero, trying to save the world… or is the hero simply trying to save their own skin? All titles are either ongoing or completed. They are not ordered.`}</p>
    <p><em parentName="p">{`Note: The 4K banner for this post can be found `}<a target="_blank" rel="noopener" href="https://www.reddit.com/r/Helck/comments/fvyp21/ive_made_a_4k_helck_wallpaper/">{`here`}</a>{`.`}</em></p>
    <h2>{`Yuusha Manga Recommendation #1: Helck`}</h2>
    <p>{`I hereby dub this manga the best there is to offer about heroes and heroism. The story is great, the characters are endearing and the comedy on point! If you have never heard of this title, you’re missing out!`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Helck Volume Cover 12" caption="This big, burly guy is our protagonist, Helck. He's the hero in the manga Helck! (Duh) He may not look like it, but he's a nice guy." name="helck-cover.jpg" mdxType="Image" />
    <p>{`Helck takes place in a world where demons and humans are at war with each other. I say war but honestly, it’s more like the humans hating on the demonfolk and sending troops over to the demon world just because. Humans could never oppose the demons (demons are just too strong!), so the demonfolk are super chill about human invasions. Moreover, the demons have more than just one demon lord! Defeating one barely made a dent to the demons’ overall strength. However, humanity has a `}<strong parentName="p">{`hero`}</strong>{` and luck has it, he managed to defeat `}<strong parentName="p">{`one of the demon lords`}</strong>{` that guards the border between the human world and the demon world. Thus, the demons scrambled to find a new demon lord. To do that, they hosted a tournament where the contestants would fight to prove themselves worthy for the title `}<strong parentName="p">{`Demon Lord.`}</strong>{` Sure, anyone could compete but for some reasons, the human hero Helck is competing as well?!`}</p>
    <p>{`The demon world has Four Heavenly King, each earned their colour-coded title because they are stronger than your usual demon kind. One of the kings, `}<strong parentName="p">{`“Red” Vamirio`}</strong>{` came to the tournament to inspect the progress. Obviously seeing a hero as one of the contestants didn’t sit well with her, so she came up with many silly competitions to deter Helck. But for some reason, Helck is unstoppable? Everyone in the tournament actually `}<em parentName="p">{`likes the guy`}</em>{`. They don't mind the fact that he’s `}<strong parentName="p">{`human`}</strong>{`! He’s kind, good at cooking and honestly, just a really `}<em parentName="p">{`likeable guy`}</em>{`. Vamirio is `}<strong parentName="p">{`fuming`}</strong>{`. She could tell Helck means no harm but her position as the Four Heavenly King dictates otherwise. She `}<em parentName="p">{`needs to know`}</em>{` if Helck meant harm to the demon world. So she disguised herself as Anne from management to watch over Helck, and to stop him if she decides that this man is a `}<em parentName="p">{`menace to her empire`}</em>{`.Thus Helck and Vamirio’s journey throughout the human kingdom began. We soon learn of the human kingdom’s predicament, and the true history behind the world’s madness.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Helck has some amazing humour that translates well across cultures. The art is clean and really, really easy to follow. Dialogue cuts`}<em parentName="p">{` straight to the point`}</em>{` but it wasn’t done `}<em parentName="p">{`overtly on the nose`}</em>{` either. Both `}<strong parentName="p">{`art`}</strong>{` and `}<strong parentName="p">{`dialogue`}</strong>{` combined made the story `}<em parentName="p">{`simplistic on paper`}</em>{` but has enough depth to keep the readers engaged. There’s a ton of plot twists I didn’t see coming either!`}</p>
    <p>{`While the author could have easily pushed for Helck to be the sole winner of this story, she opted to give everyone their own time to shine. There are many short stories that explore the side casts’ quirks and to show what they are doing while Helck and Vamirio are journeying together. This made the journey to the ending much more `}<strong parentName="p">{`enjoyable`}</strong>{` because the readers are now familiar with everyone, and not just the titular duo.`}</p>
    <p>{`Besides, Helck couldn’t have done it without everyone’s help. His plight and kind soul won everyone over, including Vamirio. Vamirio is the deuteragonist in this story but she wasn’t sidelined as the `}<strong parentName="p">{`sidekick`}</strong>{` `}<strong parentName="p">{`to Helck`}</strong>{` throughout the story. Her relationship with Helck is a refreshing change! It's somewhat a slow burner, but we see them grow closer through current circumstances and finally, as good friends out of respect. It’s akin to how two friends look up to one another’s accomplishment and strengths, and wouldn’t hesitate to help each other in times of need. Maybe the word I’m looking for here is `}<em parentName="p">{`bromance… Ya get my drift?`}</em></p>
    <p>{`As for Helck, I would need to `}<em parentName="p">{`spoil you`}</em>{` to talk about his character so let’s just say that he’s not what he seems to be. His buffed up appearance runs counter to his kind heart. His character type is definitely `}<em parentName="p">{`the big guy but with a bigger heart`}</em>{`. It’s completed and there’s supposedly a sequel. I’ve been waiting since `}<strong parentName="p">{`2017`}</strong>{`…`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Yuusha Manga Recommendation #2: Dragon Quest: The Adventure of Dai`}</h2>
    <p><em parentName="p">{`Hellloooo my childhood!`}</em>{` Whenever anyone mentions `}<strong parentName="p">{`yuusha`}</strong>{`, the first thing they think of is the `}<em parentName="p">{`Dragon Quest’s hero `}</em>{`(at least for me). Among the many Dragon Quest spinoffs, this title is my favourite and probably, everyone’s too since it got so much coverage! There’s an anime in the works if you’re interested. You can check out my write up for this `}<LocalLink to="/blog/dragon-quest-the-adventure-of-dai-is-a-blast-from-the-past" mdxType="LocalLink">{`The Adventure of Dai here`}</LocalLink>{`.`}</p>
    <p>{`If you’re familiar with Dragon Quest, then you can skip the summary I’m about to write. It’s the usual blurb of the villager turned hero on a fateful day. Our hero, Dai, learned of his fate and soon packed up to leave his hometown. He encountered many enemies he must defeat along the way, with each `}<strong parentName="p">{`gradually harder`}</strong>{` than the latter. Soon the hero needs to train up to learn new techniques to power up. New comrades with the same goal joined his cause. The hero and his party worked his way up the demon hierarchy and soon, reached the `}<em parentName="p">{`demon king`}</em>{`.`}</p>
    <Image alt="Dragon Quest: The Adventure of Dai Volume 1" caption="I had to look hard for a Dai image that showed the team.  This cover shows everyone you need to know about! I hope this piqued your interest <3" name="dragon-quest-the-adventure-of-dai-1.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <p>{`If you’re expecting plot twists, then I’m afraid there’s none in this story. The joy in reading this manga comes `}<em parentName="p">{`solely `}</em>{`from `}<strong parentName="p">{`the plot’s simplicity.`}</strong>{` Each character has only a few skills they can dish out against the enemy, making each of the fights predictable and the characters’ super iconic. Nevertheless, the story does not fall flat even when compared to modern shounen titles. It `}<em parentName="p">{`knew `}</em>{`what made `}<strong parentName="p">{`Dragon Quest’s stories so good`}</strong>{` on it’s own and replicated that in the manga. If you like traditional JRPG storytelling, this title’s plot should be familiar to you. It’s a blast to read this while reminiscing your childhood! This title is completed.`}</p>
    <h2>{`Yuusha Manga Recommendation #3: Yuusha no Mago to Maou no Musume (Hero's Granddaughter and Demon Lord's Daughter, Sakurako & Darkneria)`}</h2>
    <p>{`This is an underrated title that I seriously need to re-read when I have the opportunity. It’s a manga that I chanced upon and binged on the same day.`}</p>
    <Image alt="Yuusha no Mago to Maou no Musume Volume 3 cover" caption="I wanted to choose a generic image for this title but this cover is really gorgeous!" name="sakurako-and-darkneria.jpg" mdxType="Image" />
    <p>{`It’s a pity that there’s not many chapters translated because I really enjoyed this title. Both Sakurako and Darkneria were two separate individuals travelling together by circumstances but fate has it their past was much more intertwined than expected. We see both girls get closer by the day as they follow down the path the hero took on his journey. They have their differences, but they didn’t let the past dictate their present relationship. Part of my enjoyment in reading this manga comes from the scenes where the girls converse and help each other out (it’s super wholesome in a way). Tracing the footsteps of Sakurako’s grandfather shows us a different side to the story that the public knows of. It’s a fun way to get some exposition about the past without the need for a dedicated flashback arc.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Yuusha Manga Recommendation #4: Superior & Superior Cross`}</h2>
    <p>{`Superior speaks to me in a `}<strong parentName="p">{`moe`}</strong>{` sense. The plot is along the line of `}<em parentName="p">{`what if the disguised demon queen fell in love with the hero and changed her ways? `}</em>{`Yup, this manga is a true-blue `}<strong parentName="p">{`romance`}</strong>{`!`}</p>
    <Image alt="Sheila looking real gorgeous!" caption="If you like the art, you're in for a treat!" name="superior-1.jpg" mdxType="Image" />
    <p>{`The human race is in a dire state. Monsters fill their streets, conquering every ill-defended city. Thus, the king elected a hero to kill the demon queen, Sheila and end her tyrannical rule. But the demon queen is one step ahead of the hero! She could easily kill him, but a whimsical thought came to her. What if… `}<em parentName="p">{`she plays dumb`}</em>{`? She will earn the trust of the hero and `}<strong parentName="p">{`stab him from the back`}</strong>{`. And so, she joined the hero’s party as a harmless monster. They were wary, but the hero kept assuring his teammates that this girl meant no harm. Little did they know that they invited the demon queen herself to join their party… At this point, Sheila is convinced the hero is a `}<em parentName="p">{`gullible idiot`}</em>{`. Offing him is like taking candy from a baby. Unfortunately, the plot dictates otherwise.`}</p>
    <p>{`You see, the hero is a justice idiot. He loves mankind but would look for a peaceful solution with the monsters if he can. If the humans need some slaying, he would put off the slaying part unless he gets attacked. Unless the situation is `}<strong parentName="p">{`dire`}</strong>{`, he would `}<strong parentName="p">{`never`}</strong>{` turn his sword on monsters (unless it’s the demon queen). Sheila was `}<em parentName="p">{`intrigued `}</em>{`by this hero’s policy. Soon, that feeling turned to love. Now, `}<strong parentName="p">{`this`}</strong>{` is where the `}<em parentName="p">{`moe comes in`}</em>{`. You have this `}<em parentName="p">{`gullible hero`}</em>{` who is `}<strong parentName="p">{`convinced`}</strong>{` about Sheila’s goodness, but it was `}<strong parentName="p">{`the same Sheila`}</strong>{` that’s sending monsters his way. The rest of the party soon bought Sheila’s acting and considered her part of the team.`}</p>
    <Ads mdxType="Ads" />
    <p>{`But is she really acting, or was she bought over by the team’s kindness towards her? Well, read on to find out <3 The scenes where she hesitated to kill the hero makes me go `}<strong parentName="p">{`hnnng so moe`}</strong>{`. You read Superior first, followed by the sequel Superior Cross. Both titles are completed.`}</p>
    <h2>{`Yuusha Manga Recommendation #5: Yuusha ga shinda! (The hero is dead!)`}</h2>
    <p>{`This manga’s hero is a `}<strong parentName="p">{`useless pervert`}</strong>{` with a fetish for stockings. He can’t swing a sword for his darn life and would run at the`}<em parentName="p">{` first signs of danger`}</em>{`. Helping a maiden in danger? `}<strong parentName="p">{`Pfft`}</strong>{`, `}<em parentName="p">{`not`}</em>{` in his dictionary! Dude is more familiar with a hoe (pun intended) than a sword. Seriously, is this guy the hero? Well, yes and no. The real hero is kinda… `}<strong parentName="p">{`dead but not dead`}</strong>{`. This is an ongoing comedy series.`}</p>
    <Image alt="Yuusha Ga Shinda! cover" caption="This cover is pretty much it. Hero is dead, villager stepped up, and the party sucks." name="yuusha-ga-shinda-1.jpg" mdxType="Image" />
    <p>{`It all happened on a beautiful day in a small village… Touka Scott is a villager (uh… farmer?) with a strange love for vegetables. He lives a peaceful life in a peaceful village with his childhood friend, Yuna but that is about the `}<strong parentName="p">{`change`}</strong>{`. The seal on demons has weakened, thus causing many demons to revive. Villages are attacked and innocent bystanders slaughtered. With the potential attack in mind, Touka made a simple`}<em parentName="p">{` pitfall trap`}</em>{` for the demons to fall in. Yuna was then attacked by a demon (quite literally) in the next page. He tried to lure the demon to his trap but failed in a hilarious fashion.`}</p>
    <Ads mdxType="Ads" />
    <p>{`It was then the hero came and saved the day. Things happened, and the hero died by falling into Touka’s pitfall trap (LMAO). `}<strong parentName="p">{`This all happened in 1 chapter, what’s going on…`}</strong>{` All said and done, no one will `}<strong parentName="p">{`believe`}</strong>{` the hero died from a pitfall trap. So the villagers (spurred by Touka) secretly buried the hero’s body like a criminal. The next morning, Touka had a wonderful surprise. He’s in the hero’s body? Wait, this body is `}<strong parentName="p">{`DECAYING! `}</strong>{`What's going on?! Looks like Touka have to play the hero's part after all!`}</p>
    <Blockquote mdxType="Blockquote">This manga is stupid. I love it.</Blockquote>
    <p>{`Everyone’s an idiot in this story. The demons, the hero’s teammate and Touka... Everyone has a serious flaw in their character! For Touka, it’s his `}<strong parentName="p">{`perviness `}</strong>{`that the author does not bother to play down. I mean, putting stockings on carrots??? His versus demon ideas are crazy silly as well. He’ll do everything to make sure he avoids danger and every time his speech bubble comes up, I cackle hard. Like, really hard. I won’t spoil you further, so enjoy laughing as you go through the chapters. (The plot does get serious later on!)`}</p>
    <h2>{`Yuusha Manga Recommendation #6: Fantasy Bishoujo Juniku Ojisan to (Fabiniku)`}</h2>
    <p>{`This is a charming (af). This is a title that prompted me to write this post! It's an isekai comedy series. The author is really good at writing witty dialogue!`}</p>
    <Image alt="Fantasy Bishoujo Juniku Ojisan to cover" caption="The one at the front is Tachibana. I must admit, s(he)'s cute... Jinguuji at the back." name="fabiniko.jpg" mdxType="Image" />
    <p>{`Two office workers at the age of 32, Hinata Tachibana and Tsukasa Jinguuji are best friends. They’ve been together since childhood. They play together, studied together and now, they work in the same office. Such `}<strong parentName="p">{`strong bro-ship`}</strong>{` is to be envied <3 Unfortunately, this would mean Tachibana has `}<strong parentName="p">{`zero chance`}</strong>{` in getting on with a girl! After all, he spent most of his time with his best friend. To make things worse, all of the girls who approached him had their eyes on Jinguuji all along. Tachibana cursed his friend’s unfair advantage in life as he leaves the group date with Jinguuji (lol, then don’t go to a group date with him!) and voila, a goddess appeared to grant Tachibana a chance to get isekai-ed as a hero. Things happened, and Jinguuji’s earnest feelings to protect his bro backfired. Both bros got isekai-ed `}<strong parentName="p">{`BUT!`}</strong>{` Tachibana got turned into a girl, Jinguuji is super powerful and both are cursed to `}<strong parentName="p">{`feel attracted to one another. `}</strong>{`Yes, the proper term here is `}<em parentName="p">{`they have feelings for each other `}</em>{`but this is a curse by the Goddess. Things aren't supposed to be this way.`}</p>
    <Ads mdxType="Ads" />
    <p>{`So here we have two best friends fighting to `}<strong parentName="p">{`deny`}</strong>{` their feelings for each other while trying to survive in this new world. This led to a ton of hilarious scenarios since both refuse to accept their feelings in the name of bro-ship! The dialogue is a ton funnier when you include the fact that these two are 32 years old. They are at an age where `}<em parentName="p">{`logic talk trumps feeling talk. `}</em>{`Hell, their dialogue is pure `}<em parentName="p">{`icing on the cake`}</em>{`. You know the silly jabs and teasing you’ll do to your best buds in the name of friendship? Yea, there’s a ton of dialogues like these except there’s a slight `}<em parentName="p">{`ooh-la-la`}</em>{` to it since Tachibana is a girl now. You’ll definitely enjoy this manga, `}<strong parentName="p">{`read it now.`}</strong></p>
    <h2>{`Not related but I insist you try these manga too`}</h2>
    <p>{`As promised, I recommended 6 manga with hero trope in it. But along the way while compiling this list, I stumbled upon my favourites that I completed ages ago which I `}<em parentName="p">{`really, really`}</em>{` want to `}<strong parentName="p">{`recommend`}</strong>{`. They don’t have heroes in it, but their protagonist certainly acts like one!`}</p>
    <h2>{`Shitsurakuen (Paradise Lost)`}</h2>
    <p>{`If you googled for this manga title, you might notice a ton of yuri-like covers. You will be surprised when you see the tag for this manga because this is actually a `}<strong parentName="p">{`shounen `}</strong>{`title!`}{`*`}{`*`}{` `}<strong parentName="p">{`If that didn’t pique your interest, then the next info drop will - the illustrator for `}</strong>{`Kakegurui `}{`*`}{`*`}{`authored this manga!`}</p>
    <Image alt="Shitsurakuen cover art" caption="I love each girls, they are very sweet in their own way." name="shitsurakuen-1.jpg" mdxType="Image" />
    <p>{`Himoto Sora is a young girl transferred to a super elite school, Utopia. Her mannerism is similar to an easygoing boy than a girl’s. She also has this `}<em parentName="p">{`obsession`}</em>{` on justice itself. Sora abhor seeing injustice happening in front of her and would actually jump into action to right things. Her best friend, Aoi Tsuki equates her character to a knight that would jump to save her whenever.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Upon reaching the school, Sora noticed something strange in this isolated, co-ed school. The girls were following the boys meekly. Even stranger still, the boys would pull out weapons from the girls and duel each other. Aoi explained that this is a school that promotes male chauvinism. The boys each can `}<strong parentName="p">{`“own”`}</strong>{` a girl as a `}<em parentName="p">{`weapon `}</em>{`in this virtual reality game `}<em parentName="p">{`Exaclan`}</em>{`. Win the duel and you get yourself a new `}<strong parentName="p">{`“weapon”`}</strong>{`. It’s disgusting female oppression but no one `}<em parentName="p">{`dared`}</em>{` to say a word due to the powerful backing the school is receiving. The males soon took the idea to heart and embraced it while the girls could only obey their owners.`}</p>
    <p>{`Enraged from seeing the girls bullied and harassed by the boys, Sora decided to take things into her own hands. It’s a pretty short series so you should be able to finish it in a day. There’s some parts I disagree with but the manga is still a pretty great read all in all. I appreciate that the author tried some really bold ideas here! Maybe it’s just me but their uniform is so similar to Kakegurui’s design… This series is completed.`}</p>
    <h2>{`Lasboss x Hero`}</h2>
    <p>{`Have you ever wanted to enter your own manga? Like, as the all-seeing author that knew everything that’s about to happen? Sagara Masamune is a terrible mangaka. His manga is spiraling downwards on the rating chart. His manga getting `}<strong parentName="p">{`axed`}</strong>{` from the magazine is `}<em parentName="p">{`imminent `}</em>{`at this point. His editor says `}<strong parentName="p">{`NO`}</strong>{` but he knows it’s an undeniable possibility. He wanted to at least write a decent ending for his manga before moving on. Sagara then cooked up a plot to provoke the readers before shoving the ending in - `}<strong parentName="p">{`kill the heroine, Nina.`}</strong>{` It’s not a bold idea per se, but it’ll work. Just when Sagara wanted to start drawing, he was sucked into his manga.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Lasboss x Hero Volume 2 cover" caption="Nina at the front, Sagara at the back." name="lasboss-x-hero.jpg" mdxType="Image" />
    <p>{`He was distraught since seeing his characters up close made him realize… His creations are pretty `}<em parentName="p">{`lame. `}</em>{`But he needs to get back to his own world! How? Maybe if he ends the story by `}<strong parentName="p">{`killing Nina`}</strong>{`, he would be booted out of the story. With that in mind, he worked towards that goal. Obviously things didn’t go as expected…`}</p>
    <p>{`Har har, not very original is it. It’s cliched and the art is so messy. It only has 19 chapters and the tension was quickly resolved. The protagonist is still pretty heroic despite his intentions! (I think) I’d say this is a nice, short read. Please do give it a try!`}</p>
    <h2>{`All in all`}</h2>
    <p>{`I hope you enjoyed the manga recommendations! Please do check out my other posts tagged under `}<LocalLink to="/topic/manga/" mdxType="LocalLink">{`manga`}</LocalLink>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      