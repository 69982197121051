import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const RemoteLink = makeShortcode("RemoteLink");
const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The turquoise twintails Vocaloid is now 13 years old! I bet you’re as surprised as I am at how long Miku has stayed in the game. With fans keep churning out `}<RemoteLink to="https://vocaloid.fandom.com/wiki/VOCALOID_BEST_from_%E3%83%8B%E3%82%B3%E3%83%8B%E3%82%B3%E5%8B%95%E7%94%BB_(%E3%81%82%E3%81%8A)_(VOCALOID_BEST_from_Nico_Nico_Douga_(Ao))" mdxType="RemoteLink">{`new songs`}</RemoteLink>{`, Good Smile Racing creating `}<RemoteLink to="https://www.goodsmile.info/en/products/search?utf8=%E2%9C%93&search%5Bquery%5D=racing+miku&search%5Bcategory_id%5D%5B%5D=&search%5Bmanufacturer_id%5D%5B%5D=&search%5Byear%5D=&search%5Bmonth%5D=&search%5Badult%5D=0&commit=Search#searchResults" mdxType="RemoteLink">{`Racing Miku`}</RemoteLink>{` figurines, `}<RemoteLink to="https://en.wikipedia.org/wiki/Hatsune_Miku:_Project_DIVA" mdxType="RemoteLink">{`rhythm games`}</RemoteLink>{` with nothing but Vocaloid songs in them… It’s obvious to see Miku is here to stay.`}</p>
    <p>{`Note: Images for this blog post is sourced from `}<RemoteLink to="https://mikuexpo.com/" mdxType="RemoteLink">{`Miku Expo official website`}</RemoteLink>{`.`}</p>
    <p>{`I first encountered Miku during my casual Youtube browsing. Back then, anime episodes are split to Part 1, 2 and 3 so you gotta hunt them down to watch a complete episode. Songs like `}<RemoteLink to="https://www.youtube.com/watch?v=9lNZ_Rnr7Jc" mdxType="RemoteLink">{`Bad Apple`}</RemoteLink>{` are a popular hit and folks started uploading videos from Nico Nico Douga. Miku just started gaining traction and the Wild Wild West just started noticing our 3D idol back then, so it was really wild to see the numbers of Youtube search results hit number from a humble ~10,000 to an impressive `}<strong parentName="p">{`~100,000`}</strong>{`!`}</p>
    <p>{`It was crazy! Everyone loved Miku and her siblings - Kagamine Len and Rin, Megurine Luka and Kaito. These Vocaloids are the popular ones from what I see. There’s no loop option in Youtube back then so I need to find a looping site to loop my favourite songs by Hatsune Miku. Here are some of my favourites in no particular order.`}</p>
    <p>{`I tried looking for the older ones but sadly, I think most are banished to the oblivion or just… drowned by the popular ones because I can’t remember the titles.`}</p>
    <h2>{`Hatsune Miku Song #1: Last Night, Good Night`}</h2>
    <Video title="Hatsune Miku song" src="https://www.youtube.com/embed/6hlADpxjj0s" mdxType="Video" />
    <p>{`Last Night, Good Night is one of my favourites to play on loop at night. It's a calming song with minimal animation which I think was smart of the creator. I frequent MikuHatsuneWatcher often for new Miku songs many years ago, but sadly I think the account deleted most of the lesser Miku MV(s).`}</p>
    <h2>{`Hatsune Miku Song #2: Chaining Intention`}</h2>
    <Video title="Hatsune Miku song" src="https://www.youtube.com/embed/J8SYrzDHD74" mdxType="Video" />
    <h2>{`Hatsune Miku Song #3: Strobe Last`}</h2>
    <Video title="Hatsune Miku song" src="https://www.youtube.com/embed/ri-M9O6h1pg" mdxType="Video" />
    <p>{`masataka-p is an amazing MV-maker. He did some nifty customization on Miku’s model himself, and the way he presents abstract ideas is really unique back then. I got to know him through Chaining Intention first, then Strobe Last next. Both are really great MV(s) and Strobe Last really showed how much his techniques have improved.`}</p>
    <h2>{`Hatsune Miku Song #4: World is Mine`}</h2>
    <Video title="Hatsune Miku song" src="https://www.youtube.com/embed/jhl5afLEKdo" mdxType="Video" />
    <p>{`Haha, no one’s gonna pay for a holographic concert, right? Oh man, I was mistaken back then. Miku’s holographic concert came to Singapore once. It took all my willpower not to go because I had finals. It was amazing to see how much the fans love Miku as they wave their LED leek sticks.`}</p>
    <h2>{`Hatsune Miku Song #5: ARiA`}</h2>
    <Video title="Hatsune Miku song" src="https://www.youtube.com/embed/qOT0SCCYblw" mdxType="Video" />
    <p>{`I was blown away by ARiA back then when I first saw it. I liked how futuristic it is. The animation is really high quality for a fan-made MV.`}</p>
    <h2>{`Hatsune Miku Song #6: Meteor`}</h2>
    <Video title="Hatsune Miku song" src="https://www.youtube.com/embed/vt2wHUJHbbg" mdxType="Video" />
    <h2>{`Hatsune Miku Song #7: Melt`}</h2>
    <Video title="Hatsune Miku song" src="https://www.youtube.com/embed/Bak3aOj2Mn8" mdxType="Video" />
    <p>{`Melt is one of the classic songs that really made me all amped up! It's a great song. This one's the original MV. There's a better MV that most of you are more familiar with.`}</p>
    <Video title="Hatsune Miku song" src="https://www.youtube.com/embed/H2ck6atNM0I" mdxType="Video" />
    <h2>{`Hatsune Miku Song #8: Matryoshka`}</h2>
    <Video title="Hatsune Miku song" src="https://www.youtube.com/embed/HOz-9FzIDf0" mdxType="Video" />
    <p>{`Matryoshka is really unique. While most MV try their best to present Miku in a pleasing way, the creator for this MV says screw it. The song is really catchy too, and it's a duet with GUMI!`}</p>
    <h2>{`Many more in Project DIVA`}</h2>
    <p>{`Project DIVA, a Vocaloid rhythm game has a ton of songs. I tried the demo for Nintendo 3DS and it was pretty fun. The songs are catchy and the whole MV was done really well. Coincidentally, there's a Nintendo Switch game coming soon. If there's a demo, I don't see why you shouldn't give it a try!`}</p>
    <Video title="Hatsune Miku song" src="https://www.youtube.com/embed/qFRWBU47qj8" mdxType="Video" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      