import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const RemoteLink = makeShortcode("RemoteLink");
const Image = makeShortcode("Image");
const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`As I watch Slime Taoshite 300-nen, I find myself mulling at how popular slimes are in anime and manga has become lately. They aren’t so… in your face back in the days. I only see them in games! Maybe it’s because slimes are kinda cute in their own way, or maybe it’s because they are really easy to draw or imagine that it’s so pervasive in creative writing lately. At any rate, just say the word `}<em parentName="p">{`“slimes”`}</em>{` and folks would go, oh i know what you mean!`}</p>
    <p>{`I was gonna write a blog post about The History of Slimes with intensive research but I go, `}<em parentName="p">{`ehhhh I don’t want to go back to my school life of reading at least 30 scholarly papers/articles or whatnot before writing the post`}</em>{`. I know folks will call me out if I don’t do that much at least. So I thought to myself, I’ll be the source of the darn 30 papers then! The source is WATASHI. So here’s a blog post on `}<em parentName="p">{`My History with Slimes`}</em>{`! I hope you enjoy my silly write-up :) It’s honestly just my experience learning what this ubiquitous monster is.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`My History with Slimes #1: Poring, anyone?`}</h2>
    <p>{`If you watched Tensura, there’s a short scene (If my memory is correct) to explain slimes and it uses a Dragon Quest (DQ) battle menu. There’s also a `}<RemoteLink to="https://www.youtube.com/watch?v=cN7EKi2rdDA&ab_channel=TheDerpyPigs" mdxType="RemoteLink">{`throwback to the line “I’m not a bad slime!”`}</RemoteLink>{` which originates from DQ. I’m sure everyone who played Dragon Quest will go`}<em parentName="p">{` ”OOOOOH but of course!”`}</em>{` but for the folks who aren’t familiar with Dragon Quest, here’s a basic explanation. For most oldies (or fans from Japan, for that matter), their first encounter with slimes is from the popular JRPG franchise that is still around since 34 years ago, Dragon Quest (1986). In the game, they are usually the weakest monsters you meet when you first leave the beginner’s town. I remember just taking one step and voila, 3 slimes meet me in battle! I believe they have 7HP or so, which makes attacking 2-3 times normally is more than enough to KO the poor slime.`}</p>
    <Image alt="Ragnarok Poring" caption="Poring... Poring... Boing Boing!" name="ragnarok-poring.jpg" mdxType="Image" />
    <p>{`But that’s for DQ fans. As for me, my first encounter with slimes is from the MMORPG Ragnarok Online. I’m pretty sure the original idea for slimes in Ragnarok came from Dragon Quest! Regardless, this is my first encounter with slimes. Or in this case, Poring. I’m telling ya, Poring in Ragnarok is uber cute! It’s pink, has this little bob on top of his head, and does this cute sound effect when it bounces/attacks. Boing, boing! Poring is my first pet in the game too! You can feed it an Apple and pray you hit Jackpot with the Jackpot machine. Poring left such a strong impression that I still remember the sound effect when it bounces… 20 years later.`}</p>
    <Video title="Poring" src="https://www.youtube.com/embed/ov7wyBksD_4" mdxType="Video" />
    <p>{`The second strongest impression I have of Poring is that I always get a new Poring pet once the current one goes hungry. Well… You see, Ragnarok Online is my first computer game. Because of that, I have no clue what I’m doing most of the time. (What is Google?) So when my pet goes hungry, I panicked at first. I mean, how do you feed a pet? I tried everything and no item went through. So I just… let it go hungry. This is also why I keep changing pets and letting go of the hungry one. Don’t ask the kid me why, I just feel like I have to do it.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`My History with Slimes #2: Slime, but green.`}</h2>
    <p>{`After Ragnarok Online’s hype went down, another MMORPG came into my life. This time, it’s Maplestory. Granted, it was my friends' influence that got me into playing this… never-ending grindy game. Lord, the first iteration of Maplestory is super grindy. I know the game is still around and I believe they toned it down by a lot, but the GRINDFEST is real back then.`}</p>
    <Image alt="Maplestory Slime" caption="When they die, they do this really nice burst sound effect. I love smacking them as I jump across the Maplestory maps. Pop! Pop!" name="ragnarok-poring2.png" mdxType="Image" />
    <p>{`So when you first start out as a Novice in Maplestory, they’ll tell you to go beat some Slimes. I didn’t encounter any other variation of slimes after Ragnarok Online so playing Maplestory is a surprise for me! Folks would go, “Hey those are slimes!” but I went, `}<em parentName="p">{`“Hey, those are Porings!” `}</em>{`when I first saw my boing boing jellies. But the happiness is quickly eclipsed with PTSD as the Maplestory quests went from “Kill 20 Slimes” to “Kill 300 Slimes”. Urgh I know it’s easy to clear maps if you got the right job in this game but the quest scales ridiculously with your level. I have since stopped playing both MMOs but they have this “fond spot” in my heart <3`}</p>
    <Ads mdxType="Ads" />
    <h2>{`My History with Slimes #3: Metal slimes, where are you going?!`}</h2>
    <p>{`Ironic that most folks start off with Dragon Quest first, but mine came later! And worse, my first Dragon Quest is from a Nintendo DS game, Dragon Quest IX: Sentinels of the Starry Skies. My NDS have given me a ton of fond memories of JRPG. I don’t get a lot of time on the PS2 since it’s not mine so NDS is my personal haven. Not to mention the joys of owning both Internet access and a R4.. `}<strong parentName="p">{`Ahem!`}</strong>{` Anyway, I had no clue about DQ at first but boy oh boy, it became my favourite NDS game for a long, long time. You get to customize your characters and basically, do anything you want. I had so much fun grinding because it feels rewarding!`}</p>
    <Image alt="Dragon Quest metal slime" caption="This is Metal Slime from Dragon Quest. There's a tower version, a King Metal Slime version and even a liquid-ish looking Metal Slime." name="dragon-quest-metal-slime.png" mdxType="Image" />
    <p>{`So of course, I get to see slimes in this game. Lots of them in fact. And then it happened. I met a METAL SLIME. All attacks just did 1 damage and magic doesn’t work! I remember having a headache because I was sure this monster got something going for it if it’s super impervious to everything I throw at it and it's fast at fleeing. Did I manage to defeat this metal slime once? Nope. It just keeps running away on the 3rd turn! Not even Latios/Latias runs away this fast, darnit. While most folks remember slimes as this cute blue droplet from DQ, all I am reminded of is this metallic looking slime that I have no idea how it feels defeating one… Because it keeps running away.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`My History with Slimes #4: It’s everywhere now`}</h2>
    <p>{`I thought I’ve seen the last of slimes after seeing them consecutively in three games. Then the isekai boom happened. Slimes are now everywhere! The first slime that I know of from light novels, manga or anime is probably Tensura, given how early the first web novels are written. It was a fun experience, seeing a slime evolve into a Demon Lord. Granted, everything he does is so not slime-appropriate (What kind of slime gets to be super powerful from the start?) but it’s fun! Then the isekai stories started doing this`}<em parentName="p">{` “fantasy world but game stats”`}</em>{` trope with slimes as the weakest monsters or vice-versa, it’s getting repetitive. Some isekai did try to make slimes useful or incorporate them into the plot nicely. Let me share with you some of my favourites <3`}</p>
    <Image alt="Slime Taoshite 300-nen something something. Basically cute girls doing cute things." caption="Now you might be wondering, why did I share three cute anime girls? Fool! Two of them are slimes! This is from Slime Taoshite 300-nen." name="slime-taoshite-300-nen-anime-poster.jpg" mdxType="Image" />
    <Image alt="Tondemo Skill de Isekai Hourou Meshi volume cover art" caption="You see this cute slime on the MC’s shoulder? Her name is Sui and she’s the goodest slime, I love her so much. This is from Tondemo Skill de Isekai Hourou Meshi." name="tondemo-skill-de-isekai-hourou-meshi-cover.jpg" mdxType="Image" />
    <Image alt="Grace of the Gods anime poster" caption="For an isekai about rearing slime, this has more to do with the fluffiness of everyday life than slimes… This is from Grace of the Gods. I love how they handled the slime evolution line though!" name="by-the-grace-of-the-gods-anime-poster.jpg" mdxType="Image" />
    <Image alt="I want to exchange Gomechan for money" caption="Is this a slime? BUT OF COURSE! It’s Gomechan from Dragon Quest: The Adventure of Dai! It’s literally a lucky charm, shines in the dark and is an uber helpful flying slime. Such a sweet flying gold bar lmao" name="dragon-quest-the-adventure-of-dai-anime-2020-4.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`All in all`}</h2>
    <p>{`I think slimes are definitely here to stay. Did you check out how many isekai anime are being released in 2021 alone? It’s such a popular monster nowadays, even folks who aren’t familiar with the idea of slimes will be able to connect the dots! I love it when writers try to be innovative but sometimes, a throwback to the good ol’ and proven fantasy tropes will never go wrong. I hope you enjoy this write-up! Thank you for reading.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      