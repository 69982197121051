import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const RemoteLink = makeShortcode("RemoteLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I'm loving Mushoku Tensei so far! I'm really happy the anime studio actually went the extra mile to give this anime a glow up in terms of background art. It's a stark contrast to the concrete jungle I'm so used to seeing in. It's super refreshing to see the ever-stretching greenery <3`}</p>
    <p>{`I'm pretty sure the anime will soon explore the Demon Continent. That place is the total opposite of Rudy's hometown - no greenery here and there, plus a ton of monsters. I wonder how will they handle the background art there? For now, please enjoy these screencaps I took from Episode 1, Episode 2 and Episode 3. I took them from Muse Asia's YouTube channel. Assuming they didn't block your region, you can watch all 3 episodes of Mushoku Tensei there.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Mushoku Tensei anime screencap 1" caption="Screencap #1: Where can I find a house like this? Big backyard, really cosy feel to it." name="mushoku-tensei-anime-screencap-24.png" mdxType="Image" />
    <Image alt="Mushoku Tensei anime screencap 2" caption="Screencap #2: Stretching fields of golden stalks! Wow, if I ever see this in person, I think I'll just sit there and feel the breeze for hours." name="mushoku-tensei-anime-screencap-4.png" mdxType="Image" />
    <Image alt="Mushoku Tensei anime screencap 3" caption="Screencap #3: Rudy sitting on a log to appreciate the sky is one of my favourite scenes. His second life has given him a new profound appreciation for a chance to do his life right again." name="mushoku-tensei-anime-screencap-26.png" mdxType="Image" />
    <Image alt="Mushoku Tensei anime screencap 4" caption="Screencap #4: A pity the subtitles got in the way, but Rudy was walking alone in the forest and there's a ton of screencap-worthy moments here." name="mushoku-tensei-anime-screencap-33.png" mdxType="Image" />
    <Ads mdxType="Ads" />
    <Image alt="Mushoku Tensei anime screencap 5" caption="Screencap #5: The trees during autumn season always look pretty and picturesque!" name="mushoku-tensei-anime-screencap-32.png" mdxType="Image" />
    <Image alt="Mushoku Tensei anime screencap 6" caption="Screencap #6: The wide space in this anime is super refreshing to look at. I hope they keep doing this!" name="mushoku-tensei-anime-screencap-31.png" mdxType="Image" />
    <Image alt="Mushoku Tensei anime screencap 7" caption="Screencap #7: Family moments are always good to see in anime." name="mushoku-tensei-anime-screencap-30.png" mdxType="Image" />
    <Image alt="Mushoku Tensei anime screencap 8" caption="Screencap #8: So sweet <3" name="mushoku-tensei-anime-screencap-20.png" mdxType="Image" />
    <Ads mdxType="Ads" />
    <Image alt="Mushoku Tensei anime screencap 9" caption="Screencap #9: More greenery!" name="mushoku-tensei-anime-screencap-21.png" mdxType="Image" />
    <Image alt="Mushoku Tensei anime screencap 10" caption="Screencap #10: Rudy apologizing to Sylphie. Man, Paul picked a great place to raise a family. The golden fields are so nice to look at." name="mushoku-tensei-anime-screencap-36.png" mdxType="Image" />
    <Image alt="Mushoku Tensei anime screencap 11" caption="Screencap #11: Uhm, where do I find an empty field this big??" name="mushoku-tensei-anime-screencap-7.png" mdxType="Image" />
    <Image alt="Mushoku Tensei anime screencap 12" caption="Screencap #12: Loving the sunlight filtering in!" name="mushoku-tensei-anime-screencap-35.png" mdxType="Image" />
    <Ads mdxType="Ads" />
    <Image alt="Mushoku Tensei anime screencap 13" caption="Screencap #13: Loving the magical effects here!" name="mushoku-tensei-anime-screencap-18.png" mdxType="Image" />
    <Image alt="Mushoku Tensei anime screencap 14" caption="Screencap #14: Roxy teaching Rudy magic!" name="mushoku-tensei-anime-screencap-19.png" mdxType="Image" />
    <Image alt="Mushoku Tensei anime screencap 15" caption="Screencap #15: Roxy left at the crack of dawn. The sun coming up makes for an interesting lighting composition as the scene rolls on." name="mushoku-tensei-anime-screencap-28.png" mdxType="Image" />
    <Image alt="Mushoku Tensei anime screencap 16" caption="Screencap #16: I wanted to highlight how they did the clouds here. I don't think these clouds are rain clouds? Like, they don't carry much in them. This is before they did the water magic test." name="mushoku-tensei-anime-screencap-25.png" mdxType="Image" />
    <Ads mdxType="Ads" />
    <Image alt="Mushoku Tensei anime screencap 17" caption="Screencap #17: Look at this interesting composition. The clouds in the sky are much thicker and fluffier now. Now these look like rain clouds! This scene happened right after Rudy did his water magic." name="mushoku-tensei-anime-screencap-27.png" mdxType="Image" />
    <Image alt="Mushoku Tensei anime screencap 18" caption="Screencap #18: This scene is right after Roxy bid farewell to the Greyrat family. They show the sky is dark but the sun is starting to come up. Warm light eventually shining in highlights the greenery in the background beautifully." name="mushoku-tensei-anime-screencap-29.png" mdxType="Image" />
    <Image alt="Mushoku Tensei anime screencap 19" caption="Screencap #19: This happened when Rudy blasted his house with an intermediate water magic. Man, what a beautiful view." name="mushoku-tensei-anime-screencap-15.png" mdxType="Image" />
    <Image alt="Mushoku Tensei anime screencap 20" caption="Screencap #20: Damn, y'all are early risers. Loving the mountains in the background. So pretty <3" name="mushoku-tensei-anime-screencap-12.png" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`All in all`}</h2>
    <p>{`Watch Mushoku Tensei! Here's a link to the `}<RemoteLink to="https://www.youtube.com/watch?v=SoKGAOpUw_E&list=PLwLSw1_eDZl01_ftoIT3birJWkpxFZkEl" mdxType="RemoteLink">{`Muse Asia YouTube channel`}</RemoteLink>{` I was talking about.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      