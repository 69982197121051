import PropTypes from 'prop-types';
import { BreadcrumbJsonLd } from 'gatsby-plugin-next-seo';

import Banner from '../components/Topic/Banner';
import Posts from '../components/Topic/Posts';
import Seo from '../components/Seo';
import keywords from '../utils/keywords';
import SocialButtons from '../components/Topic/SocialButtons';

const Topic = ({ location, pageContext }) => {
  const { tag, name, description, metad, icon, img, items } = pageContext;

  return (
    <>
      <Seo
        title={`Tagged: ${tag} | Geeknabe - ACG Blog`}
        location={location}
        description={metad}
        keywords={keywords}
        meta={[
          {
            property: 'og:type',
            content: 'website',
          },
        ]}
      />
      <BreadcrumbJsonLd
        itemListElements={[
          {
            position: 1,
            name: 'Latest Posts',
            item: 'https://geeknabe.com',
          },
          {
            position: 2,
            name: 'Topic',
            item: 'https://geeknabe.com/topic/',
          },
          {
            position: 3,
            name: 'Anime',
            item: `https://geeknabe.com/topic/${tag}/`,
          },
        ]}
      />
      <Banner img={img} name={name} icon={icon} description={description} />
      <SocialButtons />
      <Posts items={items} type={tag} />
    </>
  );
};

Topic.defaultProps = {
  location: {},
  pageContext: {},
};

Topic.propTypes = {
  location: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  pageContext: PropTypes.shape({
    tag: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    metad: PropTypes.string,
    icon: PropTypes.string,
    img: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.object),
  }),
};

export default Topic;
