import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Blockquote = makeShortcode("Blockquote");
const RemoteLink = makeShortcode("RemoteLink");
const Ads = makeShortcode("Ads");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`With how fast the scene of web development is moving, I find it super important to keep this blog up-to-date with the latest packages (Only if the package is a major update and has a ton of bug fixes). I never touched the source code for this blog for like, 2 years and voilà! The next time I try to change anything, all hell break loose. I blame this on my stubbornness to use third party libraries for everything I do... A shiny new sliding library? Eh, why not! A new update to render images effectively? Sure! Then, if it doesn't work anymore, I had a hard time debugging why it doesn't work.`}</p>
    <p>{`Worst still, if something broke, I can never deploy my blog to the Internet. I got so tired of doing everything in such a roundabout way, I decided enough is enough. Let's go back to the basics!`}</p>
    <Blockquote mdxType="Blockquote">What is the basics, really? Well, markdowns!</Blockquote>
    <p><em parentName="p">{`Note: This blog post is peppered with web development terms. If you know them, good. It is not necessary to know them to read this blog post.`}</em></p>
    <p>{`Before I explain why I choose to use markdown pages, let me explain my initial headache with my previous blogging setup...`}</p>
    <p>{`Previously, Geeknabe is powered by `}<RemoteLink to="https://www.gatsbyjs.com/" mdxType="RemoteLink">{`Gatsby.js, a static site framework`}</RemoteLink>{` with all of her blog posts pulled from `}<RemoteLink to="https://prismic.io/" mdxType="RemoteLink">{`Prismic.io`}</RemoteLink>{`. Content, images, as well as index pages are all sourced there. I used a library called `}<RemoteLink to="https://www.gatsbyjs.com/plugins/gatsby-source-prismic/" mdxType="RemoteLink">{`gatsby-source-prismic`}</RemoteLink>{` to do everything for me. Except... You still need to do a few steps to get it working. In order to start populating my blog, I had to create a new page in Prismic.io, write in it, publish it, run my local copy of my blog to check if it's all in order, then run the command to push it to the Internet. That's a ton of steps to write a blog post. Sure, I have my content backed up, and my images hosted in a CDN but the work needed to get a blog post up is way too much. Not to mention I'm close to hitting the CDN's bandwidth limit. I love free things. Heck, the only thing I paid for to get this blog running is the domain's ownership.`}</p>
    <Ads mdxType="Ads" />
    <p>{`While Prismic.io has served me well over the years, it simply isn't working anymore. I needed a new method to get my blog running with the least foreseeable work needed. It has to do all the following, hopefully with the least headache:`}</p>
    <h2>{`Hosting Blog Content Requirement #1: Host images without any bandwidth limit`}</h2>
    <p>{`Prismic.io offers an image CDN with no storage limit, but you gotta pay once your images goes over 100 GB bandwidth limit. I'm already hitting close to 70 GB and that's with 1000+ images. I am prettty sure I will hit the limit real soon because my blog posts are peppered with lots of images. You could say that this is my biggest headache. Why oh why do I have so many images...`}</p>
    <h2>{`Hosting Blog Content Requirement #2: Blog backup`}</h2>
    <p>{`I... actually did try to back up my blog by pulling the raw blog content and uploading it to `}<RemoteLink to="https://github.com/" mdxType="RemoteLink">{`Github`}</RemoteLink>{`. However! It's so much trouble (And I am one lazy blogger) I just stop doing it entirely. If Prismic.io decides to shut me down one day, I would have lost all of my blog's content. That's no good at all!`}</p>
    <h2>{`Hosting Blog Content Requirement #3: Less work please`}</h2>
    <p>{`When I say I want less work, I mean reducing the need to update third party libraries, removing as many hoops I need to get a blog post up and hopefully, motivate myself to write more. You see, writing on Prismic.io works but it's such a bother. `}<em parentName="p">{`Urgh, it made me not want to write at all.`}</em></p>
    <Ads mdxType="Ads" />
    <h2>{`The answer to everything.. is markdown`}</h2>
    <p>{`With all said and done, the answer has always been using Markdown. Why didn't I decide on using markdowns sooner to avoid the work? ): Here's how I ported all of my content from Prismic.io to markdown pages.`}</p>
    <h2>{`Step 1: Create JSON files from my old blog posts`}</h2>
    <p>{`If you used `}<inlineCode parentName="p">{`gatsby-source-prismic`}</inlineCode>{`, you will know that all of your blog's content are made available through `}<inlineCode parentName="p">{`graphql`}</inlineCode>{`. Simply create your graphql queries and populate your JSON files with the data.`}</p>
    <pre>
  {`graphql('
// your query here
').then(({ data, error }) => {
  if (error) {
    return;
  }

  //fs.writeFileSync('blogposts.json', JSON.stringify(data));
});
`}
    </pre>
    <p>{`Now that you have your JSON files, it's time to... iterate through the JSON files and create your markdown pages using `}<inlineCode parentName="p">{`fs.writeFileSync`}</inlineCode>{` 😭 This is by far, the most intensive step for me because I like being thorough without relying on scripts. Feel free to write another script to download images. Don't download images manually like me...`}</p>
    <h2>{`Step 2: Use .mdx instead!`}</h2>
    <p>{`Now that you have created markdown pages, you realize there's a lot of things missing... Sure, you could slap on a ton of details in your markdown's frontmatter but a blog post usually has more UI elements than that. As for my blog, I require blockquotes, images in `}<inlineCode parentName="p">{`<figure>`}</inlineCode>{`, videos in `}<inlineCode parentName="p">{`<iframe>`}</inlineCode>{` and many more. To enable more feature for markdowns, I decided to use this markdown extension called `}<inlineCode parentName="p">{`.mdx`}</inlineCode>{`. `}<inlineCode parentName="p">{`.mdx`}</inlineCode>{` is basically markdown pages, but it allows you to include React components in them.`}</p>
    <p>{`By using `}<inlineCode parentName="p">{`.mdx`}</inlineCode>{`, I could write normal markdown AND add funky custom React components on the same page! Might seem like a minor improvement but oh boy, the possibilities are endless!`}</p>
    <Ads mdxType="Ads" />
    <p>{`Install `}<RemoteLink to="https://www.gatsbyjs.com/plugins/gatsby-plugin-mdx/" mdxType="RemoteLink">{`gatsby-plugin-mdx`}</RemoteLink>{` and follow the instructions to get it up and running! This plugin is such a game-changer for me. Once you are done with the setup, writing blog posts will be a cinch since everything is automated!`}</p>
    <p>{`And done.`}</p>
    <Blockquote mdxType="Blockquote">Wait, that's all?</Blockquote>
    <p>{`Yup! I might have skipped some details along the way but creating markdown pages for blog post is honestly that easy. It's the content porting that is time-intensive for me. Using `}<inlineCode parentName="p">{`gatsby-plugin-mdx`}</inlineCode>{` allows you to automate blog post creation for you. All you need to do is decide what folder to put in your posts, decide what template it should apply the markdown pages to, and done!`}</p>
    <p>{`Using `}<inlineCode parentName="p">{`.mdx`}</inlineCode>{` is my way of future-proofing my blog for years to come, in the event that I stay with `}<RemoteLink to="https://pages.cloudflare.com/" mdxType="RemoteLink">{`Cloudflare Pages`}</RemoteLink>{` or move on to another host.`}</p>
    <h2>{`MDX Benefit #1: Compatible with Gatsby's hot reload`}</h2>
    <p>{`Writing on a pad/wherever that is not on your blog gives a different vibe to seeing it live. You get me? I know what I'm writing, but I gotta see it live on my blog to see if I'm writing it right! With `}<RemoteLink to="https://www.gatsbyjs.com/docs/reference/local-development/fast-refresh/" mdxType="RemoteLink">{`Gatsby.js's hot reload`}</RemoteLink>{` feature, any updates to your markdown pages are shown on your blog locally once you hit the Save shortcut. It actually feels pretty liberating! Much better than publishing on Prismic.io, then running the script again to see it on your blog locally.`}</p>
    <h2>{`MDX Benefit #2: Writing React into blog posts has never been this easy`}</h2>
    <p>{`You have to do multiple steps to add a new UI element into Prismic.io just to see it live on your blog. It's so troublesome because it locks me into a certain mindset when doing my blog posts. If I want to add something new to the blog, I gotta write in many things and pray it works. Nah, screw that! With mdx, I could write the code and quickly insert it in to see it live on my blog locally. Easy.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`MDX Benefit #3: Solution to backing up and hosting images`}</h2>
    <p>{`My blog's content is essentially being added to my Github repository now as markdown pages along with the images. That means I don't have to do any extra steps to back up my work. Perfect.`}</p>
    <p>{`Plus, my current host, Cloudflare Pages allow `}<RemoteLink to="https://developers.cloudflare.com/pages/platform/limits" mdxType="RemoteLink">{`20K files`}</RemoteLink>{` per site. I only have 1000+ images to host. I doubt I will hit that limit soon, so it's practically free without all the bandwidth hoo hahs.`}</p>
    <h2>{`MDX Benefit #4: No more extraneous third party libraries`}</h2>
    <p>{`The thing with these libraries is that it relies on other third party libraries. If one of these libraries go down or refuses to fix a bug, everyone in the chain waves the white flag. By reducing the need to rely on these libraries, I save myself the headache. Not to mention not needing to update my source code to go with their new method of doing things! I swear, these libraries enjoy changing things for the sake of changing ):`}</p>
    <h2>{`All in all`}</h2>
    <p>{`Writing for my blog has become so much easier, I'm so pleased with my latest blog update. All of my content is on my repository, and I feel right at home writing in Visual Studio Code! Everything is automated, so I just need to create and save the images as I write for my blog. All of my pages will then update itself with the new blog post I just wrote. Woo-hoo!`}</p>
    <p>{`I hope this simple write-up gives interested folk an idea on how to code up a blog. I hope this helps!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      