import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../styles/globals';
import Twitter from '../SocialFollow/Twitter';

const Section = styled.section`
  margin-top: ${$.layout.margin3}px;
  margin-bottom: ${$.layout.margin3}px;

  ${$.screen.desktop} {
    width: 700px !important;
    padding-left: calc((100vw - 700px) / 2) !important;
    padding-right: calc((100vw - 700px) / 2) !important;
  }

  ${$.screen.tablet} {
    width: 700px !important;
    padding-left: calc((100vw - 700px) / 2) !important;
    padding-right: calc((100vw - 700px) / 2) !important;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: ${$.layout.padding1}px;
  border-bottom: 1px solid ${$.brown1};

  > iframe,
  > a {
    margin-right: ${$.layout.margin3}px;
    margin-bottom: ${$.layout.margin2}px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const FollowLinks = ({ nabe }) => (
  <Section>
    <Container>
      <Twitter nabe={nabe} />
    </Container>
  </Section>
);

FollowLinks.defaultProps = {
  nabe: true,
};

FollowLinks.propTypes = {
  nabe: PropTypes.bool,
};

export default FollowLinks;
