import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This is written for newcomers to Animal Crossing and Nintendo Switch in mind. I see many newbies who got a Nintendo Switch for this game! I understand that despite being a super chill game, there’s just so many things the game never tells you. Having fun discovering new things is a great way to enjoy the game but sometimes, you’d wish someone actually told you all of that. Coupled with some tidbits I found myself, here’s a compendium list of `}<strong parentName="p">{`EVERYTHING `}</strong>{`I can think of that you should know and do, dear Resident Representative. These tips are not ordered.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`What do you do in this game?`}</h2>
    <p>{`The ultimate goal in this game… is to do whatever you want on your island. However, you’ll need to follow Tom Nook’s instruction to `}<em parentName="p">{`unlock`}</em>{` `}<strong parentName="p">{`everything`}</strong>{` you can do in this game. According to Tom Nook, the final goal of the island is to invite K.K. Slider over for a concert! So make sure to fulfill every request that he throws at you. It'll take days to complete his requests but it'll be worth it.`}</p>
    <h2>{`You can hold the B button to speed up the speech text`}</h2>
    <p>{`While they are talking, hold B. This should make that slow gibberish a bit more bearable if you’re in a hurry for some reasons. Press B or A to move on to the next speech bubble, then repeat.`}</p>
    <h2>{`You can hold the B button to run`}</h2>
    <p>{`I know some folks who didn’t know this because they are first-time gamers.`}</p>
    <h2>{`Keep tapping the A button to speed up your crafting animation`}</h2>
    <p>{`When you are crafting, tap the A button in a flurry to make the animation go faster.`}</p>
    <h2>{`Hold the A button down while holding a net to sneak up on a bug`}</h2>
    <p>{`I recall that the tutorial will tell you but just in case I remembered wrong, I’m writing this down here.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Tools will break except these two`}</h2>
    <p>{`Vaulting pole and ladder. The rest will always break.`}</p>
    <h2>{`You can’t grow flowers on the beach`}</h2>
    <p>{`If you plant them there, they won’t bloom. If you want to put flowers on the beach, grow them elsewhere and plant them on the beach once they have bloomed.`}</p>
    <h2>{`These items attract bugs`}</h2>
    <p>{`Flowers, tree stumps and rotten turnips. You'll occasionally find bugs plastered on tree trunks (Atlas Moth) or they'll drop from trees if you shake them!`}</p>
    <h2>{`How do you get tree stumps?`}</h2>
    <p>{`By chopping them down. Only an `}<strong parentName="p">{`axe`}</strong>{` can chop trees down. You can’t chop trees down with a flimsy axe or a stone axe. Once you have a tree stump, you can use the shovel to remove it if you don't want them anymore.`}</p>
    <h2>{`Rotten turnips…? Wait, what are turnips in this game?`}</h2>
    <p>{`You can buy turnips in this game. What for? Well… to play the Stalk Market! You buy turnips at a low price, and sell it to Timmy and Tommy for a higher price the next day! That’s how you earn some good cash in this game. If you have unlocked the Nook’s Cranny (your item shop’s next upgrade after the dinky tent), then you should have unlocked the Stalk Market. Daisy Mae, the NPC who would visit your island every Sunday morning will sell you your turnips, but only in the morning. Remember to set your morning alarm and play Animal Crossing first thing in the morning! She leaves your island in the afternoon, after 12 pm. Once bought, you need to sell the turnips off before the next Sunday or it’ll rot.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="But Timmy and Tommy is really cute. I forgive them..." caption="46 bells?! Accursed tanukis!!" name="nintendo-switch-animal-crossing-new-horizons-11.jpg" mdxType="Image" />
    <p>{`You will always get a price between 90 - 110 bells when you first bought the turnips. This is called the base price. It will affect Timmy and Tommy’s prices for the next week. You can sell turnips to Timmy and Tommy from Monday to Saturday. Their prices can be as low as 9 bells, or as high as 660 bells. In fact, 660 bells is the highest their buying price can go. The prices will refresh after 12 pm, so technically you get 2 different prices every day.You can change the time on your Nintendo Switch to “time travel” to the future to save time. Your prices for the week will never change even if you try to time travel back and forth. Make sure to never go back in time as that will make your turnips rot. And that, my friend, is how you waste 364,000 bells to buy rotten turnips (Sigh, yes that's me).`}</p>
    <p>{`You can find out more from this elaborative `}<a target="_blank" rel="noopener" href="https://docs.google.com/document/d/1bSVNpOnH_dKxkAGr718-iqh8s8Z0qQ54L-0mD-lbrXo/edit">{`Google Doc`}</a>{`, and use this `}<a target="_blank" rel="noopener" href="https://nooknet.net/turnips">{`calculator`}</a>{` to predict your prices for the week.`}</p>
    <Image alt="Animal Crossing Kicks" caption="NPC that sells or buys stuff will come visit your island randomly once you unlocked Town Hall. This is Kicks. He sells all sorts of bags, socks and shoes." name="nintendo-switch-animal-crossing-new-horizons-10.jpg" mdxType="Image" />
    <h2>{`Palm trees only grow on the beach`}</h2>
    <p>{`Don’t waste your time planting them anywhere else as a sapling. Once it is fully grown, you can plant them elsewhere.`}</p>
    <p>{`EDIT: If you have unlocked Island Designer App, you can redeem a sand path permit. Along with the dirt paths, these 3 paths are the only ones where you can dig! I planted my palm trees on a sand path and it grew!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`What are manila clams for?`}</h2>
    <p>{`You can dig up manila clams from the beach. Notice the soft `}<em parentName="p">{`fsssh `}</em>{`sound when you run past the beach? The sound indicates that there’s something to dig up nearby. There should be a small darken spot nearby the area you just ran past. Dig it up and you’ll get a manila clam! Use it to craft a fish bait. You can use a fish bait to reset the current fish to a bigger shadow. So if you see a small shadow, spam fish bait on it until you get a bigger sized shadow!`}</p>
    <h2>{`What are these balloons floating around?`}</h2>
    <p><strong parentName="p">{`FREEBIES! `}</strong>{`Use a slingshot to shoot them down! You can get bells, furniture, materials like nuggets, clay, etc, DIY recipes or clothing from these balloons. Don’t shoot them down near bodies of water, weed, or flowers! They’ll suck up the presents!`}</p>
    <h2>{`The art of shaking trees`}</h2>
    <p>{`Shake them the first time, and you may get a wasp, a piece of freebie furniture or 100 bells. Shake them the second time, tree branches may or may not fall. Notice that items falling from the tree will drop in a 3x3 grid around the tree. So make sure there are no weeds or flowers surrounding the tree! These flowers and weeds can suck in your fruits and tree branches!`}</p>
    <Image alt="Shake the tree until it has no branches left!!" caption="Notice that the tree branches dropped from the tree occupies this much grid on the left. Feel free to keep shaking the tree, but nothing new will drop anymore on the left side." name="nintendo-switch-animal-crossing-new-horizons-4.jpg" mdxType="Image" />
    <h2>{`The rock trick`}</h2>
    <p>{`There are stones littered around your island that you can get clay, stones, iron nuggets or gold nuggets from. These resources are really important for your tools, especially iron nuggets so you need to hit them as many times as possible. Here’s how to get at least 8 materials from a single stone:`}</p>
    <Image alt="Animal Crossing New Horizons rock trick" caption="Notice the holes I dug behind me to prevent me from moving after recoiling." name="nintendo-switch-animal-crossing-new-horizons-12.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <ol>
      <li parentName="ol">{`Dig two holes or more behind you. This is to prevent you from recoiling from hitting the stone. You can put 2 fences behind you as well, it doesn’t matter.`}</li>
      <li parentName="ol">{`Position yourself to be between the stone and the two hole you dug and hit the stone with a shovel. Do not move away from this position, or you’ll lose the chance to get 8 materials!`}</li>
      <li parentName="ol">{`Hit the stone 8 times and done. You can now move and pick up your items. Now eat a fruit and break the stone. Another material will drop from the destroyed stone. The stone will regenerate somewhere on your island the next day so feel free to destroy it.`}</li>
    </ol>
    <p>{`You might notice a bug `}<em parentName="p">{`can`}</em>{` fly out if you hit the stone the first time. Make sure to hit 8 times first before moving to catch the bug. Money can come out of the stone instead of clay and iron nuggets too!`}</p>
    <h2>{`Create flower hybrids`}</h2>
    <p>{`Save for the original colours such as white, red and yellow (or maybe orange), you can get other colours from the flowers! All you need to do is plant the same species of flowers next to each other, and water them. You can let the rain do the watering, it doesn't matter. You just need to make sure the flowers are watered once a day until it blooms. There are 8 types of flowers in this game:`}</p>
    <ol>
      <li parentName="ol">{`Cosmos`}</li>
      <li parentName="ol">{`Hyacinth`}</li>
      <li parentName="ol">{`Lily`}</li>
      <li parentName="ol">{`Mum`}</li>
      <li parentName="ol">{`Pansy`}</li>
      <li parentName="ol">{`Rose`}</li>
      <li parentName="ol">{`Tulip`}</li>
      <li parentName="ol">{`Windflower`}</li>
    </ol>
    <p>{`Here’s the full colour combination chart:`}</p>
    <Image alt="Hybrid flower chart for Animal Crossing: New Horizons" caption="All combination of colours for each flower." name="flower-combination-animal-crossing-new-horizon.jpg" mdxType="Image" />
    <Image alt="Animal Crossing: New Horizons flower breeding optimal arrangement." caption="I found a guide to best arrange your flowers for optimal breeding! I hope this helps." name="animal-crossing-new-horizons-optimal-flower-breeding-guide.jpg" mdxType="Image" />
    <h2>{`Clone hybrid flowers fast`}</h2>
    <p>{`Use the flower breeding guide to get hybrid colours, then use this guide to quickly multiple them. All you need to do is plant a single flower on a field with no other flowers around it within a grid. Then, water said flower everyday. You should see a new bud of the same colour blooming next to it in a few days.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Get rich by planting a money tree`}</h2>
    <p>{`If you see an `}<strong parentName="p">{`X`}</strong>{` on the ground, that means there is a fossil for you to dig up. You can donate the fossil to Blathers, or place it around your island as a furniture. If you see a shining light on the ground, however, rejoice! It’s a spot to plant a money tree! Use a shovel to dig at the shining spot, and you should get 1,000 bells from your dig. Now go to your bag and take out 10,000 bells from your money stash. You can do this by going to your bells indicator and tap the A button. A menu should pop up asking how much you wish to take. Plant 10,000 bells and wait for it to grow. 3 bags of bells will be waiting for you once the tree is fully grown.`}</p>
    <p>{`That’s great `}<strong parentName="p">{`BUT `}</strong>{`did you know that… even money trees have RNG? Here’s how it works.`}</p>
    <ul>
      <li parentName="ul">{`Plant 100 - 1,000 bells, you will always get a tree with 3,000 bells in total.`}</li>
      <li parentName="ul">{`Plant 2,000 bells - 9,000 bells, there’s a 70% chance of getting 3,000 bells and a 30% chance of getting three times the amount buried.`}</li>
      <li parentName="ul">{`Plant 10,000 bells - 99,000 bells, there is a 70% chance of 30,000 bells and a 30% chance of getting three times the amount buried.`}</li>
    </ul>
    <p>{`So your best and safest bet is to plant 10,000 bells to avoid losses. If you are feeling adventurous, feel free to bury more money in!`}</p>
    <Image alt="Broke blogger" caption="Make sure to bury 10,000 bells instead. I chose 1,000 bells because I am broke in this screencap." name="nintendo-switch-animal-crossing-new-horizons-5.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`How do you move a tree from one place to another?`}</h2>
    <p>{`Eat a fruit. Notice a counter that appears at the top left of your screen? You can eat up to 10 fruits! Now you have super powers! Use a shovel, and dig up a tree. Woosh, in it goes into your pocket. Now your counter goes back to 0.You can use this method to steal from trees from your friend’s island and plant them back on your island. Big brain moment! You can break a rock as well after eating a fruit.`}</p>
    <h2>{`You can wish on a meteor shower`}</h2>
    <p>{`At night, you may notice meteor showers `}<em parentName="p">{`wooshing`}</em>{` past with a chime sound effect. Unequip any tools you are holding, and adjust your camera view to look at the sky. When a shooting star streaked by, press the A button. The shooting star will blink loudly in return. Tomorrow morning, there will be star fragments washed up on your beach. Collect them to craft a wishing wand.`}</p>
    <h2>{`There are seasons in this game`}</h2>
    <p>{`As well as events. It’s all real time so remember to keep track of the calendar! You see different things during said season, as well as bugs and fishes. Each season will take 3 months and both island types have different timing for each seasons.`}</p>
    <h2>{`Northern Hemisphere`}</h2>
    <p>{`Winter - January to MarchSpring - April to JuneSummer - July to SeptemberAutumn - October to December`}</p>
    <h2>{`Southern Hemisphere`}</h2>
    <p>{`Summer - January to MarchAutumn - April to JuneWinter - July to SeptemberSpring - October to December`}</p>
    <Ads mdxType="Ads" />
    <h2>{`There are moon phases in this game`}</h2>
    <p>{`The phases follow your Nintendo Switch’s date. Check out the night sky if you are playing at night :)`}</p>
    <h2>{`You can gift your villagers furniture`}</h2>
    <p>{`You can give it to them once a day, or mail it to them. They’ll`}<em parentName="p">{` try`}</em>{` to incorporate them into their houses. Don’t bother giving them wallpapers or flooring, they won’t use it. You can gift your villagers clothing too, and they’ll wear it.`}</p>
    <h2>{`How do I get a friend’s furniture?`}</h2>
    <p>{`You can go over to their island and buy it, or ask them to get it for you. `}<strong parentName="p">{`OR! `}</strong>{`Request them to drop it on the ground for you. Pick it up and return it to them. The furniture should appear on your Nook Shopping catalogue list now.`}</p>
    <h2>{`Celebrate your villagers’ birthday!`}</h2>
    <p>{`They’ll throw a party on that day. Make sure to bring a present! You can buy wrapping paper from Timmy and Tommy to wrap the present. You should research what they hate and like before giving them presents. You can mail a letter (together with a present) the day before their birthday too. What’s the benefit of befriending them? Well, you get more cute interactions and presents from them. You may even get letters from the villagers!`}</p>
    <h2>{`All clocks in this game have their hands correctly shown`}</h2>
    <p>{`They follow your Nintendo Switch’s time. Even the clock park furniture!`}</p>
    <Image alt="IT IS 11:50 AM" caption="The time is correct on the clock park too! This game is amazing. I suggest checking the Town Hall's clock too for proof." name="nintendo-switch-animal-crossing-new-horizons-8.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`Sunrise and sunset is beautiful in this game`}</h2>
    <p>{`They have this really amazing warm lighting. I suggest changing your Nintendo Switch’s time to experience the sunrise.`}</p>
    <h2>{`Visit the Town Hall during an early morning`}</h2>
    <p>{`As you go along fulfilling Tom Nook’s requests, you’ll unlock the Town Hall and Isabelle will come to your island as an administration helper. Go in during early mornings and you’ll notice Tom Nook and Isabelle doing absolutely adorable morning exercises.`}</p>
    <h2>{`What’s a wishing wand for?`}</h2>
    <p>{`It’ll never break and you can use it to change your clothes! You can edit these saved clothes from your wardrobe and swap them around by swinging the wishing wand!`}</p>
    <h2>{`I ate a fruit but I don’t want to break anything`}</h2>
    <p>{`Get a toilet and sit on it. The counter will gradually go back to 0.`}</p>
    <h2>{`Don’t run on your bloomed flowers`}</h2>
    <p>{`Run over them twice, and their blooms will be gone. The flower itself will return as the same colour it previously was. So if you’re worried that the colour you bred so hard is gone, that’s not going to happen here.`}</p>
    <h2>{`What type of fruits does Animal Crossing have?`}</h2>
    <p>{`Apple, Cherry, Orange, Pear, Peach. These five are the starting fruits you may have on your island. Once your island’s fruit is set, you can’t change it. The only good thing about this is you can buy your island’s fruit from the shop, and sell them a little higher. Coconut is not part of the five fruits. Also, you get a native flower on your island too.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`The trees need open space to grow`}</h2>
    <p>{`If you planted a tree sapling somewhere and it won’t grow, then there may not be enough space for it to grow. You can’t plant it too close to the cliff, river or beachside. Plant it in an open space first, then you can transplant them wherever you want once they are fully grown. Trees can’t grow if you place them too close to each other.`}</p>
    <h2>{`You can sit on tree stumps`}</h2>
    <p>{`I’m telling ya this so you can make a BBQ joint area super authentic.`}</p>
    <h2>{`Wasp is worth a lot!`}</h2>
    <p>{`The `}<strong parentName="p">{`first time`}</strong>{` you chop or shake any tree, a wasp nest may drop. This only happens the first time you shake the trees so don’t worry about getting another wasp the next time you shake! Wasp is worth 2,500 bells, so make sure to catch it! You also unlock Nook Miles by catching them in a row 5 times.`}</p>
    <h2>{`But how do I catch these wasps?`}</h2>
    <p>{`Before you begin your shaking routine, make sure to hold a net first. The moment you turn towards the wasp, press A to catch the wasp. Voila! Your avatar will automatically turn towards the wasp when it drops so you don’t even need to run away.Another method is using a popper. Once the nest drops, pop the popper towards the wasp! They will scatter immediately.If you’re lucky, you can run to a nearby building and enter it. The wasps will scatter immediately as well.Another method you can try is… calling the Rescue Services. They’ll whisk you away to safety immediately for 100 Nook miles. If you’re feeling rich and generous, you can try this.But honestly, there is no demerit to getting stung by wasp except for getting your face swollen. You can just apply medicine and you’re cured! Heck, you can even craft the medicine if you want. A villager would hand over the recipe to you once they see your sorry face.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Upgrade your tent to a house`}</h2>
    <p>{`You need to pay off your loans to Tom Nook before you can move onto the next house upgrade. When you first arrive on the island, you need to pay 5,000 miles for the tent. Next upgrade will cost 98,000 bells. Here’s how the whole upgrade will cost and benefits they’ll have:`}</p>
    <ol>
      <li parentName="ol">{`Tent - 5,000 miles`}</li>
      <li parentName="ol">{`One 6x6 grid room with 80 storage slots - 98,000 bells`}</li>
      <li parentName="ol">{`One 8x8 grid room with additional 40 storage slots. - 198,000 bells`}</li>
      <li parentName="ol">{`A new 6x6 grid room at the back of the house with an additional 120 storage slots. - 348,000 bells`}</li>
      <li parentName="ol">{`Another 6x6 grid room to the left of the house, with an additional 120 storage slots. Mailboxes and roof colour now customizable for 5,000 bells. You can now pick up your mailbox and place it elsewhere like a furniture. - 548,000 bells`}</li>
      <li parentName="ol">{`A new 6x6 grid room to the right side of the house, with additional 40 storage slots. You can now customize your door. - 758,000 bells`}</li>
      <li parentName="ol">{`10x6 grid second floor added. This adds an additional 400 storage slots. You can now change your house’s siding. - 1,248,000 bells.`}</li>
      <li parentName="ol">{`10x6 grid basement added. This adds an additional 800 slots, totalling up to 1,600 storage slots now. This is the last house loan. - 2,498,000 bells.`}</li>
    </ol>
    <p>{`Once you paid off your final house loan, you can change your house exterior for free once a day.`}</p>
    <h2>{`Why do tarantulas exist in this game...?`}</h2>
    <p>{`I don’t know, but all I know is that these buggers are worth a lot. You can sell them for 8,000 bells. They usually appear between 7 pm to 4 am. If you don’t get close to them, they do nothing. If you get really close, they’ll do a jump attack on ya. This is honestly the part where everyone gets foiled ASAP. The trick here is to calmly walk towards it (your usual pace) and once an adequate space is between you and the nasty, swoop in on it with your net!`}</p>
    <h2>{`Nothing dies in this game`}</h2>
    <p>{`You can pluck the flowers and they’ll stay planted. You don’t need to water them every day. You can get stung by wasps or downed by the stupid tarantula, but you won’t die so don’t worry. Go get your pain therapy. You might even get some Nook Miles!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Blathers, the museum curator is always asleep during the day`}</h2>
    <p>{`Because he is an owl, duh. If you noticed, he stands closer to the aquarium section. Why? Because he hates bug!`}</p>
    <h2>{`Isabelle’s bell rings everytime she tilts her head`}</h2>
    <p>{`I’m telling ya this so you’ll plug in your earphones and appreciate the small details. The bell ring is super cute!`}</p>
    <h2>{`K.K. Slider visits your island every Saturday `}</h2>
    <p>{`… but only after you completed all of Tom Nook’s requests. By then, you should have unlocked Island Remodeling App. His concert is super delightful! He’ll come over in the evening and sing a ton of songs non-stop. If you request a K.K. Song after 6 pm, he’ll give you a copy of it to play at your home afterwards.`}</p>
    <Image alt="Perfect." caption="Perfect." name="nintendo-switch-animal-crossing-new-horizons-3.jpg" mdxType="Image" />
    <h2>{`You can buy K.K. Slider songs from Nook Shopping option`}</h2>
    <p>{`But how do you play them? Get a stereo, and press A after you placed it. You’ll get to access a menu where you can register your songs into the stereo and play them! However once registered, you’ll lose the copy forever. I used to place the albums as a photo frame on walls or as an art piece.`}</p>
    <Image alt="Animal Crossing New Horizons music selection" caption="Get a furniture that plays music such as a stereo, and you can access this menu after placing it on your island. Choose a song from here!" name="nintendo-switch-animal-crossing-new-horizons-9.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`Your villagers can sing!`}</h2>
    <p>{`Okay, so do you know that you can get musical instruments as furniture and you can play them for real? So far I encountered the piano, guitar, a drum set and a tambourine. Place them really close to the plaza (where your Town Hall is) and place a stereo nearby. Make sure to have the stereo play a K. K. Slider song and never turn it off. Once in a while, you’ll notice that the villager would walk to these musical instruments and play them along with the song, while SINGING IT! I swear, I feel blessed every time I catch this in action.`}</p>
    <h2>{`Music in the game changes hourly`}</h2>
    <p>{`Each season too, technically. Once you have unlocked your Town Hall, the music will change every hour. The bell will first chime to tell you the time, then cue the music. They change by the season too so there are 24 songs per hour x 4 seasons = 96 songs in total.`}</p>
    <h2>{`Nook’s Cranny, Timmy and Tommy’s shop also has different music`}</h2>
    <p>{`Make sure to notice the music change right before 10 pm.`}</p>
    <p>{`Extra tip: If you have only just recently unlocked Nook’s Cranny, there should be a chair at the counter that you can sit. Try sitting past 10 pm. Timmy and Tommy will be forced to watch you… hoping that you’ll leave so they’ll close shop. What is this good for? Absolutely no reason. Leave your seat and they’ll kick you out to close shop.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`K. K. Slider visited my island, and the ending credits played. What now?`}</h2>
    <p>{`It’s time to remodel your island, duh! For now, you can only lay down paths. To remodel cliffs and rivers, you will need a Cliff and Waterscaping permit that costs 6,000 miles each. So I hope you stocked up your miles! Try to redecorate your island wholly and aim for a 5 star rating! You can ask Isabelle for your island rating.`}</p>
    <Image alt="Animal Crossing New Horizon's ending credits" caption="Is this the end...?" name="nintendo-switch-animal-crossing-new-horizons-2.jpg" mdxType="Image" />
    <p>{`A little tip:`}</p>
    <ol>
      <li parentName="ol">{`You can build cliffs up to 3 storey high, but you can’t climb up to the third storey.`}</li>
      <li parentName="ol">{`Using the waterscape tool, you can dig waterfalls at the edge of a cliff. You don't need to connect a river to it.`}</li>
      <li parentName="ol">{`You can’t dig for a waterfall at the corner of a cliff.`}</li>
      <li parentName="ol">{`You can only create or dig cliffs and waterscape 1 grid at a time. Digging away at 1 grid may dig away ½ grid, so use this method to create a slanted edge instead of a sharp edge. I'm sorry if this sounds vague, you can try it when you get the permits!`}</li>
      <li parentName="ol">{`You can’t dig at a cliff if there are items on the cliff, a body of water, or a villager on it. You get the idea.`}</li>
    </ol>
    <Ads mdxType="Ads" />
    <h2>{`There are 3 types of fishes`}</h2>
    <p>{`Fishes from the sea, fishes from the rivers and ponds, as well as fishes from the river mouth. These three fishing areas yield different type of fishes.`}</p>
    <h2>{`Get custom designs through Able Sister’s Portal`}</h2>
    <p>{`If you have unlocked Able Sister’s clothing store, then you should give the custom designs portal a try. I got a ton of creative designs for pathways and clothings through the portal. Fans would share their design on Twitter, so I suggest you get to hunting your favourites down! If you have the Nintendo Switch Online app, you can use QR code instead.`}</p>
    <Image alt="Animal Crossing New Horizons custom design" caption="This is one of my favourite pathway design I found on Twitter! Please visit the creator ID shown at the bottom left." name="nintendo-switch-animal-crossing-new-horizons-1.jpg" mdxType="Image" />
    <h2>{`What are mystery islands?`}</h2>
    <p>{`You can get a Mystery Island ticket by redeeming it from Redeem Nook Miles option from your Nook Shopping machine. It costs 2,000 miles so it’s a bit pricey, but these islands are worth visiting. You can take, catch and break anything you see on this island for however long you want. It’s also where you can get new villagers by inviting them to your island, or maybe even rare items! But even mystery islands have types. Here’s a list of them along with their respective chances of spawning:`}</p>
    <Image alt="Animal Crossing New Horizons mystery island chart" caption="All mystery islands listed." name="mystery-island-map-guide-reddit-twitter-user-datamine.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`All in all`}</h2>
    <p>{`I think I covered `}<em parentName="p">{`almost `}</em>{`everything I know about this game. I hope this helps you to get a better understanding of the nooks and cranny of Animal Crossing: New Horizons. Feel free to google for more tips! There should be even more hidden tricks after the new update (Art Museum) releases.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      