import AdSense from 'react-adsense';
import styled from 'styled-components';

import {
  StartingAdsTitle,
  EndingAdsTitle,
  Container,
  NightMode,
} from './AdsStyling';
import atFanfictionChapter from '../Navbar/atFanficChapter';
import blockAds from './block';

const InnerContainer = styled.div``;

// Put this more in blog posts or pages with a ton of text
const InArticle = () => {
  return blockAds() ? (
    ''
  ) : (
    <Container>
      <InnerContainer>
        {atFanfictionChapter() && <NightMode />}
        <StartingAdsTitle />
        <AdSense.Google
          client="ca-pub-3881417792061359"
          slot="9702157366"
          format="fluid"
          style={{ display: 'block', textAlign: 'center' }}
          layout="in-article"
        />
        <EndingAdsTitle />
      </InnerContainer>
    </Container>
  );
};

export default InArticle;
