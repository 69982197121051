import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXFanfiction/Chapter/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Divider = makeShortcode("Divider");
const RemoteLink = makeShortcode("RemoteLink");
const MessageBox = makeShortcode("MessageBox");
const AuthorNote = makeShortcode("AuthorNote");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`“Are you serious?”`}</p>
    <p>{`“Yes, quite.”`}</p>
    <p>{`“Tailcoats?”`}</p>
    <p>{`“The dress code does call for White Tie, so… yes, I suppose, tailcoats. ”`}</p>
    <p>{`“You mean to say Principal Rhea was serious about dance lessons?”`}</p>
    <p>{`“Well yes, I should think so.”`}</p>
    <p>{`“`}<em parentName="p">{`Fu-`}</em>{`”`}</p>
    <Ads mdxType="Ads" />
    <p>{`“-It is a rather spectacular event, I must say. As boys, we were allowed to enter and join the festivities but we didn’t have partners to dance with. Inviting guests is a privilege only seniors have. We would all herd into a corner and hope a lady would waltz by and dazzle us with her beauty,” the blonde chuckled nostalgically, but then his expression darkened. “Especially Sylvain.”`}</p>
    <p>{`A groan escaped the lips of an annoyed mint-haired male as he continued to squat by his locker, almost stuffing himself inside.`}</p>
    <p>{`“I can’t afford a tuxedo,” Reus said woefully, feeling bad his father probably had to spend more money.`}</p>
    <p>{`“Oh no, a tuxedo is different from an evening tailcoat.” Dimitri smiled, and another groan escaped Reus’ throat. “And not to worry, if I am not mistaken, our evening tailcoats are bespoke-”`}</p>
    <p>{`“Bespoke?! As in...`}<em parentName="p">{`Custom made?!`}</em>{`” Reus burst out of the locker he was inside, practically screeching. “Doesn’t that cost an arm and a leg?!”`}</p>
    <p>{`“Y-Yes, but it is included in our school fees for the year. So… fret not about the extra cost as there are none. But accessories are to be financed on your own.”`}</p>
    <p>{`“Accessories?” Reus’s face fell, these didn’t sound cheap.`}</p>
    <p>{`“For example, a top hat, or specially made cufflinks…” Dimitri tapped his chin contemplatively. “If you’d like, I do have a couple of spares I can lend you. I’ll have someone send some over when the time comes for you to choose,” the blonde beamed at him.`}</p>
    <p>{`“Great,” Reus replied, giving Dimitri a lifeless thumbs up and squatted back down by his locker again. The stark difference in social classes between them was beginning to weigh down on him.  `}<em parentName="p">{`Have someone send it over`}</em>{`. Oh Goddess.`}</p>
    <p>{`“Nevertheless, it is the event all of us upperclassmen look forward to!” Dimitri placed a hand on Reus’s shoulder. “I understand if this tradition is beyond you. However, I do hope you enjoy yourself.”`}</p>
    <p>{`“I can’t dance for shit,” Reus finally said, the scowl in his face growing.`}</p>
    <p>{`“That’s what the dance lessons are for!” Sylvain said, pulling Reus out of his locker hideaway and shook him, “I’ve been dreaming of this ball for years so don’t you and that grumpy ass face chase away all the girls!”`}</p>
    <p>{`“What in goddess’ name makes you think I’m grumpy?”`}</p>
    <p>{`Sylvain huffed. “Honestly, between you and Felix, I have no idea who’ll take the championship for the grouchiest.”`}</p>
    <p>{`“C-could it be that you’re homesick?” Ashe said as he gently pressed against his locker to shut it.`}</p>
    <p>{`“`}<em parentName="p">{`Homesick?`}</em>{`” Sylvain, Dimitri and Reus said at the same time, with Sylvain and Dimitri looking at Reus simultaneously.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Reus cocked an eyebrow. He was never homesick. “It’s already been a month since I got here. There’s `}<em parentName="p">{`no way`}</em>{` I’m homesick.”`}</p>
    <p>{`Ashe unlocked his locker again, pulled out his phone, and quickly searched the symptoms of said ailment. “A disturbed sleeping pattern,” he began saying aloud.`}</p>
    <p>{`Well, that was because he wasn’t sleeping in his own bed.`}</p>
    <p>{`“Feeling angry, nauseous, nervous or sad…”`}</p>
    <p>{`… He `}<em parentName="p">{`did`}</em>{` get kicked out of school for something stupid.`}</p>
    <p>{`“Feeling isolated, lonely or withdrawn….”`}</p>
    <p>{`This was pretty much his normal mood.`}</p>
    <p>{`“Feeling overwhelmed, insecure, anxious or panicky…”`}</p>
    <p>{`To be completely fair, he was in a new school and completely different environment, so of course he’d feel like that.`}</p>
    <p>{`“Feelings of low self-esteem or self-worth, headaches…”`}</p>
    <p>{`Reus shrugged. He was in a school with the offspring of the social elites of the country, and besides, self-esteem issues were a common teenage problem.`}</p>
    <p>{`“And a lack of appetite or concentration.” Ashe paused to take a deep breath and Reus patted Ashe on the shoulder to thank him for his efforts.`}</p>
    <p>{`Maybe Reus `}<em parentName="p">{`was`}</em>{` homesick after all.`}</p>
    <Divider mdxType="Divider" />
    <p>{`The thought of homesick-ness didn’t even cross Reus’s mind since he had more or less gotten used to life at Garreg Mach. To say the school was huge would be a massive understatement. What Reus didn’t realise was that this particularly large mountain also housed the prestigious Garreg Mach University. Although it was fenced off to the high school students, the university students had access to it. It was so prestigious that students and staff members were required to wear a special academic dress for certain occasions, Dimitri said. Maybe that’s why he had been feeling a little conscious of his self-worth?`}</p>
    <p>{`“Ahem, if I may have your attention,” Seteth said as he stood at the podium, pulling Reus back to reality.`}</p>
    <p>{`“As you are all aware, the annual Garreg Mach Ball is only but a month away.” The class cheered, but Seteth cleared his throat, demanding silence. “This distinguished event has been part of the school’s tradition for almost a millenia, and I will not have it be besirmiched by your unruly behaviour. Do I make myself clear?”`}</p>
    <Ads mdxType="Ads" />
    <p>{`Reus nearly rolled his eyes, but joined in the chorus of, “Yes, sir.”`}</p>
    <p>{`“Therefore, the first period and second period will be replaced with dance lessons, henceforth.”`}</p>
    <p>{`“Sir!” A hand belonging to Ferdinand von Aegir shot up and Seteth nodded at him, “Does this mean our first and second period lessons are cancelled?”`}</p>
    <p>{`“Most definitely not,” Seteth shook his head, “Your lessons will be extended into the afternoon after lunch. And as for your club activities, it is normally suspended for seniors during this period due to this event and your… upcoming exams.” A wave of groans filled the classroom. “Ah, and Saturday afternoons as well,” he added. The groans grew louder, accompanied by grunts this time.`}</p>
    <p>{`“Are there any more questions?”`}</p>
    <p>{`Lorenz raised his long, slender hand. “Must we all participate? I am sure many of my peers, like me, have been brought up in the art of dancing and thus need no further instruction.”`}</p>
    <p>{`Seteth pinched the bridge of his nose, “Any `}<em parentName="p">{`other`}</em>{` questions?”`}</p>
    <p>{`Lorenz looked aghast, and someone sniggered in the back. Probably Claude.`}</p>
    <p>{`“Will you be teaching us, sir?” Someone asked.`}</p>
    <p>{`“Unfortunately, I am not trained to do so.” Seteth stepped away from the podium. “And since Professor Hanneman from our own Garreg Mach University, who regularly coaches students is on sabbatical leave this year, we have decided to employ a professional consultant to teach you.”`}</p>
    <p>{`“If you will follow me to the great hall, I shall introduce you at once,” the green-haired man said, moving towards the exit. The scrapping of chairs and tables against the floor drowning out the chatter of students.`}</p>
    <p>{`Seteth spun around as they were about to march behind him. “I must once again stress that all of you must be on your absolutely best behaviour. I will not have any of you be sent to the principal’s office for foolish conduct,” he said through gritted teeth.`}</p>
    <p>{`“What’s his problem? He seems more tense than usual, maybe a longer pole is stuck up his ass today,” Sylvain whispered from behind Reus, who managed to suppress a snort.`}</p>
    <p>{`As they trudged and spilled into the main hall, Reus stretched and yawned while a tall, voluptuous figure drifted towards them.`}</p>
    <p>{`“My, my! How wonderful to see such, young, fresh faces! Why, Seteth, you made them sound like little devils. How could you?”`}</p>
    <p>{`Reus literally lost his balance and had to hold on to whoever stood next to him, and the poor victim held in an iron grip was Felix, who didn’t hesitate to voice his annoyance.`}</p>
    <p>{`It was almost as if a truck had appeared and hit Reus smack in the face, actually it might as well have, because that voice belonged to—`}</p>
    <p>{`“This is Professor Manuela, I believe some of you may be familiar with her work as a professional singer and dancer.”`}</p>
    <Ads mdxType="Ads" />
    <p>{`It seems it wasn’t just Reus who nearly fainted, and it appeared as though the entire class had forgotten what Seteth had said. Good behaviour who?`}</p>
    <p>{`“I LOVE YOU, MANUELA!” A student screamed from the back, Seteth had to stand in front of the class to calm them down with the biggest and deepest scowl known to man, but it didn’t seem to be working.`}</p>
    <p>{`“It’s..It’s…!” Reus gripped his heaving chest gladly not minding death if it came for him at this very moment. An awestruck expression was all he could muster since words had obviously failed him.`}</p>
    <p>{`Sylvain stumbled over with hearts for eyes, “Oh she’s even more beautiful in person!”`}</p>
    <p>{`“She certainly is very divine, I must ask her out for dinner!” Lorenz chimed in.`}</p>
    <p>{`“What? I thought you didn’t want to participate?” Felix smirked, “Or are you suddenly not confident with your dancing?”`}</p>
    <p>{`“Oh hush, Fraldarius! I’ll have you know—”`}</p>
    <p>{`“Whatever it is, I literally don’t care.” And Lorenz gasped. “Why is nobody questioning why they got a celebrity to teach us dancing? Any old coot would’ve been fine,” Felix groaned out loud as he stood behind Reus and Sylvain who were still clutching onto each other.`}</p>
    <p>{`Dimitri hummed in thought, then finally said when there was a lull in the screaming, “I must assume it is her skill and most likely, reputation that the school is after. As Vice-Principal Seteth has said many times, our school is rather prestigious.”`}</p>
    <p>{`“What a bunch of rot— I just want my education. Who cares about who’s teaching us dancing for a 2 hour event.”`}</p>
    <p>{`“Well, this supposedly makes us more...rounded human beings I guess.” Dimitri scratched his chin.`}</p>
    <p>{`“You mean this stupid dance secures more donations from our parents?”`}</p>
    <p>{`“`}<em parentName="p">{`It is`}</em>{` thanks to their contributions we’re allowed to attend this academy…”`}</p>
    <p>{`Felix rolled his eyes. “Shut up.”`}</p>
    <p>{`“MANUELA, PLEASE GO OUT WITH ME!” Another boy yelled but Professor Manuela placed a slender finger over her mouth as she let out the most beautiful, tinkling laugh Reus had ever heard. “Oh boys, thank you, but I am here as your new instructor. I would appreciate it if you would all just settle down.”`}</p>
    <p>{`And just like that, his classmates stood still, with their eyes paying close attention to her and the long slit that exposed her smooth legs. It looked as if Seteth almost allowed himself a shocked expression at how swift Professor Manuela had the class under control.`}</p>
    <p>{`“`}<em parentName="p">{`Thank you`}</em>{`,” Professor Manuela said with an exasperated sigh.`}</p>
    <p>{`“As I mentioned earlier, Manuela, you will need my assistance. Teenage boys `}<em parentName="p">{`are`}</em>{` a handful.” Seteth said, grimacing.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Rubbish,” Professor Manuela waved him off. “This is nothing compared to the crowds at my concerts. No one has thrown underwear at me yet, therefore I’ll say these boys are rather well behaved.”`}</p>
    <p>{`Professor Manuel clapped her hands as she turned her attention back to them, “I will turn all of you into excellent dancers.” She grinned.`}</p>
    <Divider mdxType="Divider" />
    <p>{`“I look like a tree.”`}</p>
    <p>{`“A rather nice one too!”`}</p>
    <p>{`“Oh come now, Annette,” Mercedes scowled at the girl who stood next to her. “Please don’t insult the dressmaker like that. But, having said that, unfortunately you do look like a tree, Byleth.”`}</p>
    <p>{`Byleth groaned. She had lost count the number of dresses she had tried on and although her ankle was at the end of its fractured stage, it still ached inside the medical boot she was wearing.`}</p>
    <p>{`She hobbled back inside the large changing room, but limped back out when she couldn’t reach the zip.`}</p>
    <p>{`“Oh dear, I had forgotten about your ankle. Would you like to rest for a moment?” Mercedes asked as she undid the zip, and Byleth immediately sucked in breaths of air.`}</p>
    <p>{`“I’m alright— just a little hungry,” Byleth said from behind the large curtain as she undressed. Who knew trying on dresses could be so tiring?`}</p>
    <p>{`“Me too, me too!” Annette chipped in excitedly, “Since we’re here, could we please try the new pastry shop?” Byleth popped her head out of the curtain, seemingly to sway Mercedes’ decision. She was actually `}<em parentName="p">{`very`}</em>{` hungry.`}</p>
    <p>{`Mercedes glanced down at her watch, humming. “I suppose we could spare thirty minutes, but we must be quick as we still have the rest of the mall to go through!”`}</p>
    <p>{`Although she had only been a boy for two weeks, the whole experience still had a huge impact on her being. Her relationship with Dimitri had been...cordial. Mostly harmless flirting, daily updates and the like. She went from looking at a naked Dimitri without any fuss to blushing furiously whenever Dimitri appeared in Reus’s video calls. Somehow she hardly spoke to Dimitri on the phone, she was thankful though; Byleth wasn’t sure how she’d react to having his voice right in her ear.`}</p>
    <p>{`“-leth.”`}</p>
    <p>{`He was only another teenage boy yet he was so distractingly… `}<em parentName="p">{`manly`}</em>{`.`}</p>
    <p>{`“Byleth!”`}</p>
    <p>{`“Yes?” Byleth looked at Annette apologetically, “You said something?”`}</p>
    <p>{`“This place is really expensive!” Annette whispered loudly behind the menu she was holding, “So what are you having? If you are still having anything...”`}</p>
    <p>{`“Oh um, I’m not sure...” Byleth finally peeped at the menu and her eyes widened at the number of digits for each meal.`}</p>
    <p>{`“I know, dear,” Mercedes giggled at her reaction, “That’s alright though, I can pay for this.”`}</p>
    <p>{`“Mercie, we can’t let you do that!” Annette squirmed with a crossed expression and  Byleth nodded, but Mercedes waved them off. Annette slumped in her seat. “Oh I feel so bad now, I suggested this place without knowing how much it’d cost!”`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Girls, I am older than you two! The least I can do is treat you all once in a while. I mean, how often do we get together now?” She smiled and called the waiter over.`}</p>
    <p>{`Byleth made sure she picked something relatively affordable and worth its money from the fancy-looking menu, feeling a little bad about being treated, but thankful because the food actually looked mouthwatering. Once their orders were placed, they settled in to wait.`}</p>
    <p>{`“Are you alright, Byleth? You do look a little tired, I’m sorry,” Mercedes laid a hand on her shoulder.`}</p>
    <p>{`“Please don’t be, I’m more than grateful you guys came to help me pick a dress,” Byleth smiled.`}</p>
    <p>{`“Well then, what’s the matter?” Mercedes asked, “Does your ankle still hurt? We can tell you look a little different.”`}</p>
    <p>{`Byleth hesitated as she stared down at her ankle secured snugly in a medical boot.`}</p>
    <p>{`“Oh no! Could it be....you had your first fight with Dimitri?” Annette gasped, clapping a hand over her mouth.`}</p>
    <p>{`Byleth shook her head, “Nothing like that!” Annette placed that same hand on her chest and let out a sigh of relief.`}</p>
    <p>{`The waiter brought their orders quite quickly, wished them a pleasant meal and left. Annette snapped a few pictures of each plate before they dug in.`}</p>
    <p>{`“I don’t know how to put this into words… I feel…” She poked at her food as her mind searched for the words.`}</p>
    <p>{`Mercedes and Annette looked at each other, then their eyes grew wide, “Are you…” Mercedes placed a hand on her thigh and her expression serious, “Sweetie, I want you to know we are here for you. Does your brother and father know yet?”`}</p>
    <p>{`She tilted her head in confusion. Then it struck her. “Mercie! Annette! No! I’m not-” She lowered her voice, “-I’m not pregnant! I’ve only kissed him once!”`}</p>
    <p>{`“`}<em parentName="p">{`We know!`}</em>{`” Annette giggled and nudged Mercedes.`}</p>
    <p>{`“I just feel a little insecure about my social standing that's all,” Byleth concluded with a slight huff. “He’s the son of a really big and rich tycoon and my dad is… a private bodyguard.”`}</p>
    <p>{`She prodded her fork even more. “I didn’t notice it at all while I was there— all I could see was my mission and nothing else mattered. A-And now I’m buying an evening gown and elbow-length gloves for a ball.”`}</p>
    <p>{`“Oh…” The both of them mouthed and nodded.`}</p>
    <p>{`“I don’t see anything for you to feel inferior about,” Mercedes said as-a-matter-of-factly.`}</p>
    <p>{`“Me too!” Annette smacked the table furiously, earning a few scowls from the neighbouring tables. “Sure, he has tons of money and stuff but he doesn’t have a Byleth!”`}</p>
    <p>{`“Yes but, these things are expensive. I can’t get second-hand ones either because people will talk,” she groaned and felt bad for her dad. He was really sweet about her using his credit card too, even though she was technically still grounded.`}</p>
    <p>{`“Psh, people will always talk!” Annette grabbed her shoulders and shook her, “You can’t change their minds, so why bother?”`}</p>
    <Ads mdxType="Ads" />
    <p>{`She did have a point.`}</p>
    <p>{`“You don’t have to worry much, you have us. And we’ll make you the actual belle of the ball! Come on, children! The mall won’t stay open forever!” Mercedes clapped her hands together and had a slightly cheeky smile, “Now, according to `}<em parentName="p"><RemoteLink to="https://www.debretts.com/expertise/etiquette/dress-codes/white-tie-dress-code/" mdxType="RemoteLink">{`Debretts`}</RemoteLink></em>{`,” she quickly scrolled her phone, “`}{`_`}{`‘It is traditional, but not essential, to show décolletage.’ Thankfully for us, you-” she gestured at Byleth’s chest, “-have a wonderfully full and voluptuous `}<em parentName="p">{`décolletage`}</em>{`.”`}</p>
    <p>{`“...A what?” Byleth cocked her head to one side, and Annette was on Google before Mercedes could continue.`}</p>
    <p>{`“According to Mr. Google it’s..`}<em parentName="p">{`.a woman's cleavage as revealed by a low neckline on a dress or top`}</em>{`. So basically, boobies!!” she said a little too loud and again earned looks from the tables around them, she quickly clapped a hand to her mouth.`}</p>
    <p>{`Byleth opened her mouth to say something but Mercedes held a finger up. “Sweetie, leave it to us,” Mercedes said with conviction and a smile, and Byleth knew better than to argue with her.`}</p>
    <p>{`In all honesty, Byleth had pretty much given up on a ‘dream’ dress. It was shop after shop, and dress after dress, to the point that more and more sales assistants were getting annoyed with her. But nothing seemed to have sparked anything in her.`}</p>
    <p>{`“Are you okay? Do you need to sit down?” Annette asked as she unzipped another dress for her, Byleth shook her head.`}</p>
    <p>{`“I’m alright. I’m actually more concerned for the both of you.”`}</p>
    <p>{`“I’m so sorry we haven’t found anything yet, love.” Mercedes said from the other side of the curtain, “How about we try one more shop and then call it a day?”`}</p>
    <p>{`“Thank you, Mercie. I’d like that.”`}</p>
    <p>{`Just as they were about to walk out, Byleth stopped at a pink, smooth fabric that seemed to call out to her, and she hobbled over to it.`}</p>
    <p>{`“I am sorry miss, it just arrived so we haven’t had the chance to display it yet.” The shop assistant came over in a frantic and explained, “I’m not sure it’s for sale yet-”`}</p>
    <p>{`“Can I try it on?”`}</p>
    <p>{`“O-Oh uh, erm, please wait a moment and let me get a hold of my manager.”`}</p>
    <p>{`Byleth bent down and gently tugged on the dress out of its box. The cool satin felt good between her fingers and the colour was exactly what she wanted. The size she pulled out was too small so she dug deeper and found something that would hopefully fit.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Hi Miss, I’m sorry this isn’t on display yet but we do have the price for it if you’re interested?” The manager came over as she was holding the dress against her frame, she nodded and went back inside the changing room with a few sizes the manager had handed to her.`}</p>
    <p>{`Byleth’s breath hitched in her throat. The dress had glided effortlessly onto her and pooled slightly at her feet. The straps didn’t hurt or slip off her shoulders. The plunging neckline wasn’t too deep either, and surprisingly she didn’t mind the high slit that tastefully showed off her leg. Still, it could do with some alterations.`}</p>
    <p>{`“Mercie, do you think you’re able to take in a few inches? This fits my chest but the waist is really big…” Byleth stepped out holding the extra fabric at the waist to show how big it was.`}</p>
    <p>{`When Mercedes and Annette didn’t respond, she looked up to see them clutching each other and their eyes were all teary. “You found it!” Annette squealed and leaped onto her.`}</p>
    <p>{`“Of course I can take in a few inches for you! With a little ironing and steaming this is going to look even better!” Mercedes waved at the shop girl, “Please ring this up for us!”`}</p>
    <p>{`“Would you like us to steam iron it for you as well?” The manager asked as Byleth shuffled back into the changing room.`}</p>
    <p>{`“How kind of you, yes please,” Mercedes replied, “We’ll take this shawl as well.”`}</p>
    <p>{`“I can’t wait to see her all glam-ed up! This Dimitri won’t be able to keep his eyes off her!”`}</p>
    <p><em parentName="p">{`Oh, right. Dimitri.`}</em>{` And just like that, Byleth felt hot.`}</p>
    <Divider mdxType="Divider" />
    <p>{`“Alright boys, now that I see that most of you have got the rhythm, I would like to up the ante a little.” Professor Manuela winked, and Reus felt his heart skip a beat even though it wasn’t directed at him. “Half of you today will be dancing as the girl.” A chorus of `}<em parentName="p">{`‘What’`}</em>{` rang out in the hall. Professor Manuela chuckled.`}</p>
    <p>{`“And the other half of you tomorrow, so on and so forth.” Reus remained neutral, noticing his classmates were either shocked to the point of silence or most were teasing each other and pretending to be girls.`}</p>
    <p>{`“Now,” she walked in between them, “You may wonder why such a decision was made? Well, children, I amsure you’ll pass dancing even if you barely remember your steps, but occasionally your hands and feet will not cooperate. That’s not what we want!” she whipped her pointing stick at them, “I will `}<em parentName="p">{`not`}</em>{` have my boys be mediocre dancers!” Professor Manuel said fiercely. Reus considered the chances of being smacked by her pointing stick, and his heart rattled fiercely against its cage beneath his cool exterior.`}</p>
    <p>{`“Learning the lady’s part will not only make you improve, but also see things from their point of view. And take it from me, if you want to bond with your dance partner and quite literally sweep her off her feet, you `}<em parentName="p">{`will want`}</em>{` to see things from her eyes.” The class was silent, Reus looked around and it seemed they were just as determined as she was. Even Felix was quiet.`}</p>
    <p>{`“Will we have enough time, Professor?” Raphael shot his huge, bulging arm up. “We don’t seem to have much time left, even if we do, I’m not very good at this…” the boys around him murmured similar things to themselves as well.`}</p>
    <p>{`“Absolutely!” Cried Professor Manuela but then cleared her throat, “I can and will turn all of you into excellent dancers but to do so, I will need your full cooperation. You must practice in your downtime— dance with all your roommates, your classmates. Dance in your dreams if you must. This is the only acceptable time to have multiple partners. Is that understood?”`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Yes, Ma’am!”`}</p>
    <p>{`“Wonderful. I will demonstrate the part now.” She looked around and her gaze fell onto Seteth, the chaperone of sorts to ensure the class didn’t go wild, who was sitting on a chair in a corner marking some papers. “Ah, Seteth, would you do me the honour of being my partner?” Reus felt a small stab of jealousy, and he was willing to bet he wasn’t the only one too.`}</p>
    <p>{`“What? I must decline, I am preoccupied at the moment as you can see-”`}</p>
    <p>{`“Nonsense, those can wait. It will only take a moment.”`}</p>
    <p>{`“Professor Manuela, I am a mediocre dancer at best. I will only embarrass you-”`}</p>
    <p>{`“Good, then the boys will have someone to relate to.”`}</p>
    <p>{`“`}<em parentName="p">{`Excuse me`}</em>{`?”`}</p>
    <p>{`She placed her hands on her wonderful hips and huffed, “I am not moving until you dance with me.” Seteth rubbed his temples. “Oh for heaven’s sake! This is for educational purposes, Seteth.”`}</p>
    <p>{`Reus let out a sigh, yes. Yes, of course it was for educational purposes. Nothing else between them. Well, even if they were, he hoped that the class would find out first, not the media.`}</p>
    <p>{`Seteth set down the pen and stack on the floor, straightened his suit and walked over.`}</p>
    <p>{`“Thank you,” Professor Manuela said with a bright smile, setting down her pointing stick. “Now this is just a demonstration, so don’t worry if you cannot keep up.”`}</p>
    <p>{`Seteth let out what sounded like a combination of a groan, a sigh, and a grunt, before stepping forward and placing a hand on her mid-back, and the other gently clutching her other hand.`}</p>
    <p>{`Professor Manuela nodded at a student to press play, and the music they had been practicing their rhythms to all week played. And they moved, not well, but they were dancing somewhat. Professor Manuela danced like a leaf in the breeze, while Seteth looked like a rock. Even trees danced better..`}</p>
    <p>{`Some of Reus’ classmates laughed, while others whispered to each other, but he was actually paying attention to the steps, memorizing the count in his head, unaware of his blonde dorm leader, freezing up next to him.`}</p>
    <Divider mdxType="Divider" />
    <p>{`Sure Dimitri had dance lessons before, along with horse riding, oil painting, latin and the works, as a child, but as a teenager and right now, he had completely forgotten how intimate the waltz was. YouTube and online tutorials aside, seeing Professor Manuela almost pressed up against Seteth was making his heart dance too.`}</p>
    <p>{`He placed a hand over his mouth in an attempt to cover his hot cheeks. Thankfully, he was standing behind a frowning Reus, who seemed very focused at the swaying couple at the front. Otherwise Reus would have noticed `}<em parentName="p">{`and`}</em>{` suspected Dimitri having inappropriate thoughts about his sister.`}</p>
    <p>{`The past couple of weeks had gone by so fast. All these emotions were somewhat new to Dimitri, not counting the time he had a ridiculous childish crush on Edelgard. No, these were more… adult-like. More… sexual.`}</p>
    <p>{`He nearly slapped himself, but resigned to gritting his teeth and retreating his face further inside his large palm.`}</p>
    <p>{`Everyday after class, he’d eagerly open his locker to see if there were any replies from Byleth and one time he actually broke the dial on the door, only to find a message from his telco company. Before he could comprehend what had happened, Sylvain had documented everything and sent it to the group, and sweet Byleth apologized in their private messages for causing it when she didn’t need to! Dimitri didn’t deserve her.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Her classes ended at a different time from them, and as such, they didn’t talk much. If he was lucky, Reus would video call her and he’d get a short moment with her. His heart raced at the sight of her in her natural state at home. Sometimes, she’d be doing her skincare and had her hair held back in a bunny-eared headband with googly eyes and he just about exploded. He’d never have the bravery to video call her in private, alone...all to himself.`}</p>
    <p>{`And now, to think he was going to— well not yet, he hadn’t asked her yet, but he was going to ask her to dance with him, after she danced with her brother. And hopefully the next three or four dances after that, he wasn’t greedy. He knew his girlfriend would want to dance with the rest of the Blue Lions. Oh, just the thought of calling her `}<em parentName="p">{`his girlfriend`}</em>{` made him giddy.`}</p>
    <p>{`“-mitri.”`}</p>
    <p>{`He imagined her warm and soft skin being embraced by his own rough, calloused hand as they spin together-`}</p>
    <p>{`“Dimitri!” He felt a sharp pain in his side as Sylvain elbowed him.`}</p>
    <p>{`“Yes? Yes? I’m sorry, yes?”`}</p>
    <p>{`“Your dance partner is waiting for you,” Sylvain nodded at the dark, looming figure waiting as the crowd begrudgingly went to find their partners, some more amused than others. “Were you not listening?!”`}</p>
    <p>{`“Oh dear,” Dimitri said under his breath. And Sylvain was about to keel over from laughing as he struggled to articulate the next information, “He’s your girl.”`}</p>
    <p>{`“Alright boys, hurry up, don’t keep your ladies waiting!” Professor Manuela grinned, pleased with herself. “Don’t be upset if your lady partner is taller than you, remember we are for diversity! There is nothing wrong if your future partner towers over you, we don’t do nonsense such as fragile egos and masculinity here!”`}</p>
    <p>{`“H-Hubert,” Dimitri greeted him with a warm smile that was met with an eyebrowless frown.`}</p>
    <p>{`“I assume you know what you are doing,” Hubert said cuttingly, “I expect you will not embarrass me.”`}</p>
    <p>{`“Likewise.”`}</p>
    <p>{`But that was not to be.`}</p>
    <p>{`It was fine at first, as they started out slow when Professor Manuela was demonstrating the steps, but when the music started and quickened, almost everyone was bumping into each other, and stepping on each other's toes.`}</p>
    <p>{`While extremely reliable as dorm leader, right now Hubert was stiff, sweaty and not a very pleasant sight to look at, he thought regrettably. His own body must’ve been similar as Hubert’s humourless face maintained its displeasure, frowning down at him.`}</p>
    <p>{`Dimitri was forced to imagine Hubert as a taller, eyebrowless Byleth to make the whole experience bearable. He apologized numerous times to Hubert for his mistakes, who grunted in response. In fact Dimitri tripped and nearly fell into Huberts face if not for Huberts quick response. Dimitri’s coordination was in `}<em parentName="p">{`chaos`}</em>{`.`}</p>
    <p>{`That was possibly the longest thirty minutes of Dimitri’s life and he was about to praise the Goddess until Professor Manuela announced a change of partners.`}</p>
    <p>{`And to his utter shock, bordering on horror, he was `}<em parentName="p">{`Sylvain’s`}</em>{` girl.`}</p>
    <p>{`“Sylvain, that’s my butt.”`}</p>
    <p>{`“Sorry sweetheart, my bad.”`}</p>
    <p>{`“...Please remove your hand..”`}</p>
    <Ads mdxType="Ads" />
    <p>{`“I’m merely comparing sizes, darling. My hand is extremely lucky right now- `}<em parentName="p">{`Ow`}</em>{`!” Sylvain cried out as Dimitri gripped the hand he was holding, unleashing his strength for a split second.`}</p>
    <p>{`“Is there a problem?” Professor Manuela spun around.`}</p>
    <p>{`“N-Nothing,” Sylvain managed to squeak out, then turned back to Dimitri, this time his hand was firmly placed on Dimitri’s upper back.`}</p>
    <p>{`“Don’t you ever do that again, not to me or anyone else without their consent.”`}</p>
    <p>{`“Sheesh, Dimitri! I don’t! I was just messing with you, ugh.”`}</p>
    <p>{`“...I’m sorry.”`}</p>
    <p>{`“No, no, I should’ve known better. My bad.”`}</p>
    <p>{`“It’s okay. You’re a better company than Hubert, that’s for sure.”`}</p>
    <p>{`Sylvain snorted. “That’s not a hard thing to do you know.”`}</p>
    <p>{`This time, Dimitri didn’t have to imagine Byleth as the taller male dancer. Sylvain was surprisingly really good at this.`}</p>
    <p>{`“Hey, the surprising part is unnecessary!” Sylvain retorted when Dimitri said it to him.`}</p>
    <p>{`The only problem he had right now was to not make a fool of himself when the actual day comes. Today was fortunately just practice, and while he had the basics down, actually dancing with different partners truly did up the ante.`}</p>
    <p>{`Hopefully it was enough to impress her.`}</p>
    <Divider mdxType="Divider" />
    <p>{`“Cross the long end...in front...of the short end…” Reus muttered to himself in front of the dorm mirror as he unpaused the Youtube video on his phone. “With your thumb...bring the long end behind...the short end..and pass it up through the loop...” He continued his monologues while trying to follow the instructions of the video, regretting he didn’t practice more beforehand.`}</p>
    <p>{`He groaned as his bow tie was still crooked and unsure of where he went wrong, Reus quickly glanced down at his watch to see if he had time, then ripped it from his neck to try again. A couple of minutes later, Reus groaned. Dimitri peeked over his shoulder to see the bow tie was vertical rather than horizontal.`}</p>
    <p>{`“Would you like some help?” Dimitri walked over after adjusting his hair. It was slicked back, showing his blue eyes clearly.`}</p>
    <p>{`“`}<em parentName="p">{`Ugh,`}</em>{` yes,” Reus let out a frustrated grunt and let his hands fall to his sides. Dimitri stepped forward and undid whatever mess that was around his neck and slowly tied it, stopping a couple of times to think if he got the steps right, since he was doing it from the opposite point of view and not his own neck.`}</p>
    <p>{`“Hrm.” Dimitri leaned back to see his handiwork but frowned, “Sylvain— Oh, sorry,” he said as soon as he saw the red head with his hands full trying to tame cowlick on Ashe’s head. Meanwhile, Dedue had gone ahead to tend to the greenhouse one last time before the ball.`}</p>
    <p>{`“What is it,” Felix offered, begrudgement in his voice and his face in its usual scowl.`}</p>
    <p>{`“O-oh. Well, I’m not sure if I got this right— it’s a little… flat.” Dimitri gestured to a stiff Reus, and Felix trudged over.`}</p>
    <p>{`“It is, and you suck. Move.” Dimitri gratefully obliged, and Felix deftly undid everything and with seemingly practiced hands, got it right the first round.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Well done, Felix. I had no idea you were so good at this. I’m ashamed of myself.” Dimitri raised his eyebrows in awe.`}</p>
    <p>{`“Glen taught me.” Dimitri’s face instantly fell and Felix walked to the door and called out to them but before Dimitri could say anything. “Shut up and let’s go.”`}</p>
    <p>{`“S-Sorry!” Ashe said, “You guys go ahead, I think I have a stomach ache.”`}</p>
    <p>{`“Are you alright?” Dimitri asked and Reus turned as well to see Ashe who was as pale as his white dress shirt.`}</p>
    <p>{`“I-I’m fine, just a little nervous.”`}</p>
    <p>{`“Ngaw, panic diarrhea?” Sylvain poked him in his side, “Who’s this babe that’s holding such power over you, my dude?”`}</p>
    <p>{`“It’s just my sister but I’m not very used to these events. Plus I-I’m not sure I remember the steps now.” Ashe winced, almost keening over.`}</p>
    <p>{`“Me too,” Reus said solemnly, pocketing his phone and checking the cufflinks Dimitri lent him.`}</p>
    <p>{`“The both of you will be `}<em parentName="p">{`fine`}</em>{`.” Sylvain rolled his eyes and dragged Ashe, threw an arm over Reus and pulled them out the door.`}</p>
    <p>{`“I-I’ll see you guys at the hall!” Ashe cried out from under Sylvain and dashed to the bathroom.`}</p>
    <p>{`“Are you coming or not, boar?” Felix, who was holding the door open, clicked his tongue in annoyance.`}</p>
    <p>{`“Yes, I forgot something, sorry. Please don’t wait for me.”`}</p>
    <p>{`“Wasn’t going to,” Felix said as he let the door shut behind him. Dimitri walked over to his bedside drawer and quietly pulled out a `}<RemoteLink to="https://www.tiffany.com/jewelry/necklaces-pendants/tiffany-soleste-pendant-60572852/" mdxType="RemoteLink">{`small minty-blue paper bag`}</RemoteLink>{`...`}</p>
    <p>{`Enclosed in a Tiffany `}{`&`}{` Co. box, crowned with a white ribbon, was a petite, round diamond pendant no bigger than the tip of his finger on a silver chain. It cost him quite a bit, especially when his credit card had declined again, but he kept going back to the webpage, days and days after he saw it online. So he dived into his savings.`}</p>
    <p>{`Dimitri gingerly slid the box into his pants pocket, praying it wouldn’t get squashed or make him look like he was overly excited to see Byleth. He took one last look at himself in the mirror and let out a deep breath before heading out into the foray.`}</p>
    <p>{`As he walked briskly to the hall, observing the elegant yet glamorous decorations for what was probably his last time as a student. Flowing satin and silk banners with fairy lights adorned the gardens outside the main hall, turning it into a makeshift tent to receive guests. If he had to describe it, it looked like the outside of a Met Gala tent. In fact, many famous guests were being photographed by an official photographer hired by the school.`}</p>
    <p>{`Including his own father.`}</p>
    <p>{`“Mr. Blaiddyd! Mr. Blaiddyd! Sir!” A small crowd of journalists called out to Lambert Blaiddyd, the current President of the Blaiddyd Corporation. Following closely behind was his mother, and Rodrigue Fraldarius, Felix’s father.`}</p>
    <p>{`“Mr. Blaiddyd! The rumours of Blaiddyd Corporation’s employee embezzling funds— is it all true?!” It was barely a second but Dimitri could see the pained look on his father’s face. “An embezzlement of allegedly hidden funds so big that even the Inland Revenue board is involved! Sir!”`}</p>
    <p>{`The senior Blaiddyd smiled but didn’t turn around to face the journalist, rather he made a loud proclamation, “I am here on a personal matter, therefore I shall not be entertaining any questions. Thank you for your time.”`}</p>
    <p>{`As Dimitri walked forward, father and son made eye contact.`}</p>
    <p>{`“Ah, my boy.” Lambert held his arms out to embrace his son, whom he towered over. “Come, let us pose for a picture.” Dimitri’s mother pressed a palm on his cheek before posing along with her family. The official school photographer took a couple of shots before Lambert held a hand up and looked at Rodrigue.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Rodrigue, stand here with us. You are practically family.” Felix’s father was about to say something to protest, but it seemed he knew better than to argue and cordially stood next to Lambert.`}</p>
    <p>{`“You are looking well, Dimitri.” His father laid a firm hand on his shoulder as they walked into the main hall. “I have to say,I have been looking forward to this day,” Lambert chuckled, looking around the hall with nostalgia clearly visible. “It truly feels like yesterday when I was here, with Rodrigue.” He looked at Rodrigue and they both gave each other a look as if there was a joke only they knew.`}</p>
    <p>{`“And your mother of course. We had just been engaged- do you remember, darling?” With his other hand, Lambert took Dimitri’s mother’s hand and kissed it. She chuckled and nodded.`}</p>
    <p>{`“Alright, go on and have fun, okay?” His mother straightened his bow tie and planted a kiss on his cheek.`}</p>
    <p>{`“We’ll be looking forward to your dancing. Especially if you invited someone special.” Lambert gave him a quick cheeky smile.`}</p>
    <p>{`“F-Father!” Dimitri exclaimed but recomposed himself. `}<em parentName="p">{`Ah. that’s right.`}</em>{` He remembered he hadn’t told his parents about Byleth yet. Would tonight be a good night to introduce her? Dimitri wandered around the hall aimlessly, sliding through the hustle and bustle as he tried to remember if his parents had any opposing thoughts on teenage romance. He was, afterall, barely 18.`}</p>
    <p>{`Well, if his parents were looking forward to him dancing, and they saw him dancing with her...there would be no need to hesitate, then. He instinctively touched his pocket, as if to make sure his gift was still there.`}</p>
    <p>{`In the distance he saw Felix being dragged over by Sylvain to greet their parents and Dimitri’s family but further in the background, his eyes happened to see Reus pull his phone out, put his drink down and quickly jogged out of the hall.`}</p>
    <p>{`In a heartbeat, Dimitri felt his body move on its own, at first cautiously but gradually, his footsteps quickened, pushing past drinks, plates of Hors d'oeuvres and stepping over dresses. He didn’t want to lose sight of Reus in the sea of black suits and multicoloured gowns.`}</p>
    <p>{`His breath was short and he panted as the reality of seeing Byleth again approached closer and closer with each running footstep.`}</p>
    <p>{`Reus zoomed across the barrage of incoming guests, seemingly unaware of Dimitri following closely behind him.`}</p>
    <p>{`But abruptly, Dimitri stopped just a few steps behind as Reus greeted his guest, his head blocking her face.`}</p>
    <p>{`And then, she came into his view when Reus bent down to do something, he didn’t know what, or rather, he didn’t care.`}</p>
    <p>{`Apparently Dimitri had forgotten how to breathe, because he could hear his hammering heartbeat despite the chaos..`}</p>
    <Ads mdxType="Ads" />
    <p>{`The world, the noise, the people— all faded away. Even Reus disappeared.`}</p>
    <p>{`Before him, there was only her.`}</p>
    <p>{`He gulped, he gulped hard as she and Reus walked in his direction, their arms linked.`}</p>
    <p>{`All of a sudden Dimitri didn’t know where to put himself, how to stand or even `}<em parentName="p">{`exist`}</em>{`. Phone and video calls aside, it had been a couple of months since they last saw each other in person. Panic overtook him. Suddenly his waistcoat felt too tight around his torso, and his bow tie seemed to be choking him—`}</p>
    <p>{`The blushing goddess before him and her brother stopped.`}</p>
    <p>{`He let his eyes take her in, savouring her from the tip of her toes as he slowly made his way up to her eyes.`}</p>
    <p>{`“Byleth,” his voice came out in a rasper, finally remembering how to breathe as he clasped a hand over his mouth, instantly aware of his gaping at her.`}</p>
    <p>{`Her small mouth curved into a sweet smile, and big green eyes gazed up at him, and oh, and couldn’t help but press his hand harder over his mouth, lest he cried out with overwhelming emotion.`}</p>
    <p>{`“Dimitri.”`}</p>
    <Divider mdxType="Divider" />
    <MessageBox totalUnread={0} contactNumber="Reus" items={[{
      timestamp: {
        day: 'Saturday',
        time: '07:13PM'
      },
      right: '<p>about to enter the school gate</p>'
    }, {
      right: '<p>so many cars</p>'
    }, {
      left: '<p>ok will meet u at entrance</p>'
    }, {
      right: '<p>k</p>'
    }]} mdxType="MessageBox" />
    <p>{`She sucked in a deep breath. She wasn’t adept at dancing at all, despite practising
for days with Annette and Mercedes with Youtube as their teacher. They even had a
‘full-dress rehearsal’. It just wasn’t `}<em parentName="p">{`her thing`}</em>{`. And worst of all, she suddenly
didn’t know how to...act? Behave? Be herself?`}</p>
    <p>{`She nervously smoothed out her dress, looking down she noticed the love handles around her waist that had been slowly creeping up on her since she couldn’t exercise due to her leg. She let out a sigh, pulling down the long fluffy shawl to cover it up as much as she could.`}</p>
    <p><em parentName="p">{`“Ugh I can’t believe the doctor won’t let you remove your boot! Now your entire ensemble is ruined!” A very frustrated Annette grabbed her cheeks in frustration at Byleth and groaned. “It’s been, like, two months!” `}</em></p>
    <p><em parentName="p">{`“I do admit, it is rather upsetting, but if Byleth’s ankle isn’t fully healed, dancing in heels is not going to be good for her, I imagine,” Mercedes said in her usual soothing voice as she patted Annette’s head. `}</em></p>
    <p>{`The memory flashed in Byleth’s mind as she looked out the taxi window when they were approaching the school. She looked down at the one black shoe she was wearing; the strap ensured it wouldn’t suddenly fly off her foot when she danced, and the thick, low heel made sure she didn’t go and sprain her other foot. She hoped nobody noticed she had bought it rather cheaply online or that she was only wearing one of the pair.`}</p>
    <p>{`Truth be told, Byleth was rather relieved. She had zero experience with high heels and she was not about to embarrass herself in front of the elite and upper echelons of society. Plus the boot offered her stability and comfort when she walked, her ankle felt wobbly and weak. Even walking in low heels felt precarious, let alone dancing in it.`}</p>
    <p>{`Byleth held her breath as her taxi pulled up right at the foyer of the event building. She spotted valet parking and various expensive cars ahead and behind their humble taxi. She carefully stepped out but froze when she saw the sheer amount of people that were there, dressed to the nines, and cameras flashing at her and other guests.`}</p>
    <Ads mdxType="Ads" />
    <p>{`She quickly moved out of the way for the next car behind her and stood aside, about to pull her phone out of her clutch when she spotted a green head zooming towards her.`}</p>
    <p>{`“Right on time,” Reus said. His face had a vacant expression, but she could tell he was at least somewhat happy. `}<em parentName="p">{`She`}</em>{` was happy to see him, too. They were twins, after all, and she missed her other half. Their home was upsettingly empty, even though she’d never say it out loud.`}</p>
    <p>{`He peered down slightly at her feet, and began tugging the skirt of her dress to the side. She looked down at him with a frown on her face. “What are you doing?”`}</p>
    <p>{`Reus snickered. “You really are wearing heels with a medical boot on.” She rolled her eyes and stepped away, pulling her skirt along with her.`}</p>
    <p>{`“Is...Is he here?”`}</p>
    <p>{`“Who is?”`}</p>
    <p>{`“`}<em parentName="p">{`You know`}</em>{`.”`}</p>
    <p>{`“Duh.” It was Reus’ turn to roll his eyes as she slid her arm around his for support.`}</p>
    <p>{`As they walked and pushed past the crowds, her eyes scanned the vicinity for any familiar faces.`}</p>
    <p>{`There was Raphael in the distance, already at the buffet table, and Lindhart yawning as he stood beside, whom she presumed were, his parents. Ah, Hubert was talking to a man who stood next to a petite girl with almost white-blond hair, wearing a large poofy red dress with asymmetrical skirts.`}</p>
    <p>{`And as her eyes trailed up to the large entryway of the grand hall...`}</p>
    <p>{`“Oh he really is here…”`}</p>
    <p>{`“Duh, it’s his school too.” Reus snorted in reply.`}</p>
    <p>{`There was something about the way his tailcoat fitted his broad shoulders, how the white shirt and waistcoat hugged his upper body and… goddess, the way his hair was slicked back—`}</p>
    <p>{`“Byleth,” Dimitri said with a hand covering his mouth, he was probably accessing how she looked.`}</p>
    <p>{`“Dimitri...” she said a little more shyer than she wanted. But then horror struck her when she realised she was tongue tied. What on earth were they going to talk about? Do they hold hands? Kiss each other’s cheek in greeting? `}<em parentName="p">{`What`}</em>{` was she supposed to do with her hands now?`}</p>
    <p>{`“Um, my uh, ankle is still in its boot.” She lifted her dress a little to show a grey-coloured medical boot. “But Mercedes and Annette made sure my toes were painted.” Beside her, Reus let out a sigh and pinched the bridge of his nose.`}</p>
    <p>{`Dimitri smiled but his expression changed. “Are you sure you should be walking around?” He stepped forward, “Or even dancing?”`}</p>
    <p>{`“I-I’m fine! It’s very comfortable!” She softly tapped the ground with her foot and leaned on it while holding on to Reus, who was smirking.`}</p>
    <p>{`“Are you worried you’re not gonna be able to dance with her, Dimitri?” Reus’s grin grew.`}</p>
    <p>{`“W-Well, oh, I am merely just worried for someone important to me, that is all,” Dimitri replied, but warmth spread throughout her when Dimitri openly declared that she was important to him. Assuming that he was referring to her.`}</p>
    <p>{`“Okay, see you later,” Reus said flatly and before she could protest, Reus tugged her in a different direction. “Come on, By, I know you’re starving.”`}</p>
    <p>{`She quickly turned her head to look at Dimitri, who smiled and waved at her as Reus dragged her off to the buffet table.`}</p>
    <p>{`“Why did you do that? I wanted to talk to him.” Reus unlinked their arms, took a plate and pushed it into her hands.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Take this.”`}</p>
    <p>{`“Reus`}<em parentName="p">{`—mmph!`}</em>{`”`}</p>
    <p>{`He shoved a small bruschetta looking hors d'oeuvre into her mouth. “Shut up and eat, we don’t have much time.”`}</p>
    <p>{`“For what?” she said with her mouth full. “Are you even allowed to take that much food?” she said after much effort to swallow.`}</p>
    <p>{`Reus shrugged as he piled on various forms of cocktail food, and when he was done, handed her the plate, told her to eat and told her to follow him.`}</p>
    <p>{`“Okay, now,” he looked at his wrist watch, “We really don’t have much time, Dimitri is a dorm leader so they all lead the first dances apparently, so show me how well you can dance.”`}</p>
    <p>{`“...What?”`}</p>
    <p>{`“Come on! Just dance with me.” Reus pulled the plate away from her and stuffed a Canapé in his mouth before placing it on a neighbouring table. “You said you’ve been practising right?” he said while chewing quickly. “You’re going to be Dimitri’s dance partner for the first dance.”`}</p>
    <p>{`“`}<em parentName="p">{`What?!`}</em>{` I thought I was supposed to dance with you? As awkward as that is.”`}</p>
    <p>{`“Me and the boys planned it. So the girl he’s supposedly dancing with doesn’t exist. Sylvain somehow managed to lie to him.”`}</p>
    <p>{`Reus pulled them into position: adjusted her person so she was paralleled to him, feet shoulder width apart. He held a similar stance and was looking down at her. It was a smooth transition, and within seconds they were ready for the music.`}</p>
    <p>{`Byleth was amazed, but still off-put by the whole thing. She made a face. “This is weird.”`}</p>
    <p>{`“`}<em parentName="p">{`I know`}</em>{`, so get ready.” Byleth prepared to wipe her hands off on her dress, but stopped herself. Instead, she smirked slightly and wiped them off on Reus’s suit jacket. Reus rolled his eyes and let her do it regardless.`}</p>
    <p>{`“On my count okay? Just follow my lead.”`}</p>
    <Divider mdxType="Divider" />
    <p>{`“Welcome, distinguished guests, esteemed patrons and beloved students.” The grand hall fell silent save for the soft clinking of plates and glasses. Principal Rhea’s voice was soothing and commanding all the same.`}</p>
    <p>{`To be honest, Dimitri wasn’t really paying attention. His mind was in disarray at the thought of Byleth being so near, yet so far. He didn’t know where she was and it was nerve wracking that she could be anywhere in the crowd watching him.`}</p>
    <p>{`Suddenly he didn’t know what to do with himself.`}</p>
    <p>{`As Dimitri shook his head to focus, a lock of hair fell loose over his eye. He sighed. While he was thinking about Byleth, he felt that he wasn’t being fair to his dance partner, but he also felt like he was...cheating on Byleth by dancing with someone else. He didn’t like it. He didn’t know why or how Sylvain had persuaded him. Was this how so many girls fell for Sylvain’s nonsense? It just happens and the next thing you know you’ve fallen for one of his schemes.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Allow me to fix this for you,” Dedue’s deep voice pulled him back to reality as he adjusted Dimitri’s hair, all he could do was trust Dedue to make him look presentable.`}</p>
    <p>{`“You have practised relentlessly and dance both parts extremely well. You have nothing to worry about,” Dedue said reassuringly, placing a large warm hand on his shoulder.`}</p>
    <p>{`“Thank you, Dedue. I’m not worried about that—”`}</p>
    <p>{`“And now, will the Three House leaders step up to the center of the hall to officiate this event with the first dance?” Dimitri and Dedue both stopped and looked at each other, panic slowly seeping into him. He will just have to apologize to Byleth and have a chat with Sylvain later about his boundaries.`}</p>
    <p>{`“Are ya ready, pal?” Claude trudged up to him and gave him a good nudge in the ribs.`}</p>
    <p>{`“I heard your partner is a real babe,” Claude winked at him, “Sylvain told me.”`}</p>
    <p>{`“I am sure she is a...babe.” He cringed at the word, “However, there is someone else I’d rather dance with but I will still try my best. All the best, Claude.”`}</p>
    <p>{`Claude, Hubert and Dimitri all walked forward towards the center of the hall, where a dance floor had been set up. Dimitri clenched his fists, he would just have to get this over with and...and…`}</p>
    <p>{`Is.…Is that...Byleth, walking...towards him? Dimitri glanced behind, and around him, making sure she was actually heading towards him.`}</p>
    <p>{`“I told you she’s a babe,” Claude whispered, his voice brimming with glee.`}</p>
    <p>{`“I...What?” He turned to Sylvain in panic, who was mouthing something at him when Byleth stopped in front of him.`}</p>
    <p>{`“Oh, Byleth…!”`}</p>
    <p>{`“I’m sorry, I have no idea what’s going on either, they just pushed me forward and— oh, hope I’m not embarrassing you?”`}</p>
    <p>{`The lights dimmed, and everyone took their positions; Hubert and Dimitri’s cousin, Edelgard, Claude and a girl he didn’t recognise with bright pink hair and finally...him and the girl of his dreams.`}</p>
    <p>{`He looked down at her small crease between her eyebrows and all his panic flew out the door as he melted at the pinks of her cheeks and smiling lips. “You could never embarrass me,” Dimitri said softly as he gestured to their spot on the floor.`}</p>
    <p>{`“I’m really bad at all this, so I’m sorry if I step on your-” she looked down, “-Very expensive shoes, I’m sorry,” Byleth spoke, looking a little flustered.`}</p>
    <p>{`“May I?” Dimitri asked before putting a hand on her back and when she nodded, he pulled her close and gazed into her eyes, “Trust me.”`}</p>
    <p>{`Dimitri couldn’t understand why Byleth kept undermining herself; she was `}<em parentName="p">{`perfect`}</em>{`. She let him lead and offered no resistance and even flowed with him.`}</p>
    <p>{`It felt natural, like they were just `}<em parentName="p">{`meant`}</em>{` to be and it was most… `}<em parentName="p">{`intimate`}</em>{`.`}</p>
    <p>{`“Is there something on my face?” she said finally.`}</p>
    <p>{`“What?”`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Sorry, you were staring at me, so I thought there was something...”`}</p>
    <p>{`Dimitri blushed, and he blushed so hard he could feel it in his ears. Literally caught in the act. “No, please! I was just enjoying the moment. It’s not everyday we get to dance with each other.”`}</p>
    <p>{`“Me too,” Byleth replied softly and squeezed his hand.`}</p>
    <p>{`“I am glad to hear that.” His heart skipped a beat, “How is your ankle?”`}</p>
    <p>{`“I think it’s actually healed but the doctor said to keep on for another week or so. You’re making this dance so easy for me that I have not stepped on your toes yet.”`}</p>
    <p>{`Dimitri chuckled.`}</p>
    <p>{`“I have a good partner.” He smiled at her. “I have to say, you are a much better partner than my previous ones,” Dimitri was about to recall who but Byleth’s expression changed.`}</p>
    <p>{`“You’ve had other partners…?”`}</p>
    <p>{`“Ah you seem to have misunderstood, not `}<em parentName="p">{`that`}</em>{` kind of partner. Just regular people I’ve had to practice with.” He paused and looked over to where a group of boys from the Black Eagle dorm were standing, “I’ve danced with all of them, Caspar was particularly...vigorous. Ferdinand was very good, actually, and Hubert was um...well he was…”`}</p>
    <p>{`“Grumpy?”`}</p>
    <p>{`“Yes, rather,” Dimitri laughed.`}</p>
    <p>{`Byleth herself looked like she was holding back a laugh. “Why did you have to dance with Hubert?”`}</p>
    <p>{`As Dimitri was about to reply, they almost bumped into Claude and his partner and blurted out an apology.`}</p>
    <p>{`“As I was saying, it was our Professor’s insistence that we all dance the part of the male and female to perfect it,” he explained but Byleth still seemed amused and let out a small giggle.`}</p>
    <p>{`“I would’ve loved to have been there.”`}</p>
    <p>{`“Well, I think if you’re nice to Claude, he can get you videos. I think Ignatz was recording some of our sessions to see just how...awful we were.”`}</p>
    <p>{`Byleth actually let herself laugh this time. Oh it sounded so good to him, it made him smile more than usual. In fact that’s all he was doing all night, just smiling at her. He hoped she didn’t find him creepy.`}</p>
    <p>{`Suddenly the music died down, and applause broke out as everyone bowed and curtsied to their partners. Dimitri reluctantly let Byleth go and bowed as she curtsied. They were rooted to their spot for a split second as they shyly looked into each other's eyes.`}</p>
    <p>{`“That was beautiful, thank you Hubert, Claude and Dimitri for such captivating dancing, and to your dazzling partners, as well. As principal and chancellor, I am very proud,” Principal Rhea said in the microphone.`}</p>
    <p>{`“Thank you, Dimitri,” Byleth turned to him as Rhea continued her speech, inviting everyone to partake in the dance.`}</p>
    <p>{`The six of them moved to the side as couples took their places in the center. Even Dimitri’s own parents appeared to not want to miss out on the action.`}</p>
    <p>{`“It has been a long time Dimitri, you are doing well, I presume?” Edelgard asked.`}</p>
    <p>{`“I am doing rather fine. I’d like you to meet Byleth,” Dimitri ushered Byleth forward to introduce her, “She is my girlfriend.”`}</p>
    <p>{`“How lovely to meet you, Byleth. Though we may be distantly related, I can assure you Dimitri is a man of good breeding.” Edelgard extended a hand, which Byleth took.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Oh, I’m sure. He’s been very kind to me.” Byleth gave Edelgard a small smile.`}</p>
    <p>{`“It is absolutely nice to meet your acquaintance, `}<em parentName="p">{`Byleth`}</em>{`. I’m Claude,” said Claude smiling knowingly, “And this is my pal, Hilda.”`}</p>
    <p>{`“Hi everyone!” Hilda smiled and waved. “So you’re Byleth! Oh my goddess, Claude has told me `}<em parentName="p">{`so much`}</em>{` about you!” the pink haired girl squealed.`}</p>
    <p>{`“He has?” Byleth looked at Claude, who grinned back at her.`}</p>
    <p>{`“And you need to tell me where you got this dress! I’m going to study fashion next year and I want to know everything!” Hilda latched on to Byleth, running her hands over the silky fabric.`}</p>
    <p>{`“Oh, just some local department store brand in my hometown. It was quite affordable.”`}</p>
    <p>{`“Oh.” Hilda blinked looking quite surprised. “Nevertheless,it is such a cute dress! Here, let me give you my number so we can chat more.”`}</p>
    <p>{`While they were chatting, Hubert and Edelgard excused themselves, with Edelgard telling Dimitri they should catch up more often.`}</p>
    <p>{`He was just thinking to himself about how he was going to introduce Byleth to his immediate family when Sylvian appeared and threw himself onto Dimitri.`}</p>
    <p>{`“Surprise!”`}</p>
    <p>{`Dimitri groaned, “I need to talk to you.”`}</p>
    <p>{`“Nuh-uh.” The red head shook his head. “Look me in the eyes and tell me you didn’t enjoy that.”`}</p>
    <p>{`“Yes, I did. But it was terrible of you to put Byleth on the spot like that.”`}</p>
    <p>{`“Don’t worry, I handled it.” Reus walked up to Byleth and patted her on the head, “Gave her lessons myself.”`}</p>
    <p>{`Even Reus was a part of it!`}</p>
    <p>{`“Dimitri! That was so beautiful! You didn’t miss a step and even made it look so effortless! T-that’s why you’re our dorm leader!” Ashe scuttled over with Dedue who nodded approvingly.`}</p>
    <p>{`“Thank you so much, Ashe. May I ask if the whole dorm is involved?”`}</p>
    <p>{`“Yes,” they all said together, and Dimitri let out a defeated sigh but thanked them with a smile.`}</p>
    <p>{`“By the way,” Felix chimed in, “You really shouldn’t look at her like that.”`}</p>
    <p>{`“Like what?”`}</p>
    <p>{`Sylvain nodded furiously, “I know what you mean, I bet she’s pregnant.”`}</p>
    <p>{`“What?” Dimitri blurted out.`}</p>
    <p>{`Felix groaned. “You `}<em parentName="p">{`animal`}</em>{`. The way you looked at her was if you were… impregnating her with your eyes. It’s disgusting.”`}</p>
    <p>{`“I did feel very shy looking at them looking at each other. I think I see what you mean.” Ashe nodded.`}</p>
    <p>{`“O-Oh! I’m so sorry we’re talking about you l-like this, Byleth—” Ashe said but she wasn’t around. “Byleth?”`}</p>
    <Ads mdxType="Ads" />
    <p>{`“She went to pee,” Reus said nonchalantly.`}</p>
    <p>{`“What did you think, big brother?” Sylvain smirked at Reus, “You did punch him for smooching your baby sister and now he has his giant grimy hands all over her— which you helped to plan.” Sylvain nudged him.`}</p>
    <p>{`“I wasn’t looking,” Reus instantly replied without so much as batting an eyelid. His famous expressionless face said it all and the boys snorted.`}</p>
    <Divider mdxType="Divider" />
    <p>{`Byleth was `}<em parentName="p">{`starving`}</em>{`. The plates of tiny food Reus had fed her barely lasted through all the dancing she did with everyone from the Blue Lions dorm. Surprisingly, even `}<em parentName="p">{`Felix`}</em>{` danced with her.`}</p>
    <p>{`Byleth let out a groan of relief when she found an empty bench to sit on. More than anything, going to the bathroom in such a huge and poofy dress was one of the most difficult things she had ever done in her life. Plus both her ankles were starting to ache even though all her partners made sure to not dance too long with her.`}</p>
    <p>{`The air out where she sat was chilly but refreshing. Relishing the peace and quiet, she thought about how events like these were not for her at all. She almost didn’t want to go back in if it wasn’t for Dimitri and her friends. Reus and her were from a completely different class of society. They even had the local news covering this ball!`}</p>
    <p>{`“Byleth? What are you doing here?” She immediately turned around and stood up at the sound of Dimitri’s voice.`}</p>
    <p>{`“Just needed some air,” she said, adjusting her shawl around her shoulders. “Aren’t you going to dance with Edelgard?”`}</p>
    <p>{`“No, I should think not.” Dimitri shook his head and crossed his arms. Byleth tilted her head in curiosity.`}</p>
    <p>{`“As a child, Edelgard taught me how to dance. It was...a bit awkward, honestly.” He cringed.`}</p>
    <p>{`“Edelgard? As a child?”`}</p>
    <p>{`“We spent some time together as children. Her family had some problems, so she and her mother came to live with us for some time. For almost a year actually.”`}</p>
    <p>{`“She must’ve felt like a sister to you,” Byleth said.`}</p>
    <p>{`“A little, yes. But we’ve drifted apart since then. She’s almost never visited us and we hardly ever go to Adrestia so…” He shrugged. “That year before she had to return to Adrestia was...so much fun. The time of my life, in many ways. Before so many things happened.”`}</p>
    <p>{`Byleth could sense a hint of sadness in Dimitri’s voice. It made her sad as well; she didn’t know much about him.`}</p>
    <p>{`“Byleth...Will you join me for a bit? You must be getting tired of the ball by now.” Dimitri smiled at her and there was just something about that little curve of his lips that made her heart skip. She nodded.`}</p>
    <p>{`“Oh, but a word of caution, where we’re going, it’s a little bit of a climb. I am worried about your ankle so please tell me if you are uncomfortable right now.”`}</p>
    <p>{`“I’m alright,” she said as she stretched her legs.`}</p>
    <p>{`Where they went wasn’t far from the hall. It was a short-ish tower of 3 floors near the school’s cathedral. Normally, she’d feel suspicious about being taken so far away from crowds, but this was Dimitri. Her boyfriend. Besides, they weren’t completely alone. There was the odd couple here and there littered around the school, seeking out privacy, like them.`}</p>
    <p>{`“Ah, thank goodness there’s nobody here, ” Dimitri said, jogging back down the steps to where Byleth was waiting, and gestured for her to ascend. "Ladies first."`}</p>
    <p>{`“Wow, I never got the chance to explore this place when I saw it during one of my investigations,” Byleth said as she trudged up the stairs, holding her skirt in one hand and the railing with the other.`}</p>
    <p>{`“It’s quiet here, isn’t it?” Dimitri said as he walked in-step with her, a hand on her lower back, and adjusting her skirt away from her feet when needed. When they reached the top, Dimitri took her gently by the hand and led her to the window.`}</p>
    <p>{`“I come here sometimes when I cannot sleep.” She looked up at him and he looked back down at her. “The view here is breathtaking,” he said without breaking their gaze.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Byleth looked out the window, attempting to hide her shyness, and even though it was a short tower, the views were amazing. Just miles and miles of Garreg Mach beneath them, with the city lights illuminating the night.`}</p>
    <p>{`“That reminds me, while you were here as...Reus,” he asked casually, “Do you know the legend associated with this tower?”`}</p>
    <p>{`Byleth tapped her chin. “I think so— probably saw it in the school prospectus or something.”`}</p>
    <p>{`“Is that so? You don’t strike me as the sort to enjoy stories like that,” Dimitri said, amused.`}</p>
    <p>{`“Do you?” Byleth asked in return.`}</p>
    <p>{`“Occasionally.” He shrugged. “They say that wishes made in this tower will come true. This particular one intrigues me because I do wonder who came up with such a silly notion.”`}</p>
    <p>{`“You don’t believe it?” She tilted her head slightly. She may be the champion fighter in her school, but deep down she was still a hopeless romantic teenager.`}</p>
    <p>{`“Legends are legends, nothing more. I doubt there are many who really believe that wishes can be granted.”`}</p>
    <p>{`“I see what you mean,” Byleth replied, a little deflated and embarrassed. She was about to believe the legend. “It does look just a regular, rather dilapidated tower," she quickly added, trying to appear in agreement with him.`}</p>
    <p>{`“A pity, isn’t it? I heard it used to be called the Goddess Tower, back a millenia ago.” He sighed. “If anything, the Goddess just watches us from above...that is all.”`}</p>
    <p>{`"Yeah…" Byleth looked to the dark sky, hoping perhaps that the Goddess would hear her thoughts, and that Dimitri wouldn't notice her slight disappointment.`}</p>
    <p>{`“In any case...I suppose there’s no harm in passing time with silly legends.” He finally smiled. “What do you say, Byleth? Care to make a wish?”`}</p>
    <p>{`Byleth blinked, unsure of how to react. Her eyes rested on her hands, gripping the stone. Too enthusiastic and she’d look foolish, too indifferent and she might put him off.`}</p>
    <p>{`Dimitri bent down a little and briefly peered at her face. “We `}<em parentName="p">{`are`}</em>{` here on the night of the ball, which is when the legend says it is supposed to grant your wish. Why don’t you try wishing for something?”`}</p>
    <p>{`Byleth crossed her arms in thought. She had so many things to wish for and then now she didn’t know what she wanted, “I can’t think of anything, so go ahead. After you.”`}</p>
    <p>{`“A wish of my own, huh?” Dimitri placed a hand on his chin, his eyebrows furrowed. “I come here quite often and it didn’t occur to me to think of one.”`}</p>
    <p>{`He took a deep breath, tapping his chin. “I suppose my wish would be...for peace to continue in Fodlan, and all my loved ones are safe and will not be unjustly taken away from us...Or something along those lines.” Dimitri chuckled but then frowned, “Oh no, that’s more than one.”`}</p>
    <p>{`Byleth couldn’t help but burst into giggles. “Then...I’ll wish for the same.”`}</p>
    <p>{`“Thank you, Byleth. Although, at a time like this…” Dimitri dropped his smile and took a step forward towards her.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Perhaps it would make more sense for me to wish that we’ll be together forever. What do you think?”`}</p>
    <p>{`“O-oh...I, um…” she stammered, unsure of how to react because it was so sudden. It was a good kind of shock though.`}</p>
    <p>{`Dimitri stood silent, his gaze unwavering.`}</p>
    <p>{`“Dimitri…”`}</p>
    <p>{`Suddenly, he broke into laughter.“Haha! Well now, Byleth! You must admit I’ve improved in the art of joke telling since you first met me!”`}</p>
    <p>{`She took a few steps back, backing up against the wall, and scowled at him, “That was cruel.”`}</p>
    <p>{`Byleth took a deep breath, feeling like an idiot. He was her boyfriend, after all, and to joke about something like that… it hurt.`}</p>
    <p>{`He pursed his lips, frowning a little. Byleth could see that he had a guilty look on him.`}</p>
    <p>{`“I’m sorry...I guess that was rather thoughtless of me,” Dimitri said after a short pause.`}</p>
    <p>{`She kept silent, hesitant about her feelings.`}</p>
    <p>{`“Honestly,I do regret joking about such a thing. Please think nothing of it.” Dimitri continued; his appearance still had that guilty air about him.`}</p>
    <p>{`Byleth shook her head. The sting from his words still lingered in her chest and, as she was unable to deal with it in front of him, she turned towards the steps.`}</p>
    <p>{`“I’m sorry, I need to go.”`}</p>
    <p>{`“N-no, please wait!” Dimitri's arms shot out on either side of her, his hands smacking against the wall hard enough for cracks to form, effectively cornering her. Her eyes grew wide in shock at his sheer strength.`}</p>
    <p>{`“Please, Byleth. I am truly sorry.” She let him put a hand on her cheek as he turned her to face him. “I know I have hurt your feelings and... for me to make light about something like that when we are in a relationship was especially heartless of me.”`}</p>
    <p>{`For some reason, the air had become so intense, and Dimitri’s expression was so serious, that she accidentally chuckled, “I’m sorry but you look so pitiful. Like a puppy who wants a treat but got told no.” She even held back a snort.`}</p>
    <p>{`Dimitri looked absolutely puzzled at her, but, to her surprise, he started laughing as well.`}</p>
    <p>{`The tension disappeared. An overwhelming sense of something powerful washed over Byleth when she looked into Dimitri’s eyes, now clear as day, then at his swept back hair. From when he brought her here to presumably spend some time alone together, to when he earnestly made his wish, and the face he made when he was apologizing. This was the most emotion she had ever felt in the span of 15 minutes.`}</p>
    <p>{`Her pulse quickened and her breath hitched in her throat as she clutched the skirt of her dress. She parted her lips to inhale but her eyes flickered from Dimitri’s eyes and his lips. His lips too were slightly parted, and he gulped.`}</p>
    <p>{`He slowly leaned forward, steadily closing the gap between them. Suddenly he stopped halfway to look down the stairwell, presumably to check if anyone was around.`}</p>
    <p>{`In that moment, Byleth’s heart was about to explode, and she did something uncharacteristic of her: she cupped his face, closed her eyes and pressed her lips on his. That was all it took to light a fire in her chest, and the heat spread all the way to her fingertips and toes.`}</p>
    <p>{`Within mere seconds she felt her entire being engulfed in an embrace as Dimitri pressed his lips deeper into hers, both of them making up for the past couple months of being apart.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Nothing else was in her mind except for the hot, passionate feeling burning in her stomach and the bottom half of her body. She was weak, clutching on to his chest with one hand and his hair with the other for support. She didn’t know kissing could make her feel so...so emotional, so feverish.`}</p>
    <p>{`More than that, she didn’t know kissing felt `}<em parentName="p">{`so good`}</em>{`.`}</p>
    <p>{`And all this was before he pulled her so tightly against him and licked her lips, seeking permission. Byleth felt the urgency in his ravaging kisses, and giving into his plea, she obliged. As soon as their tongues intertwined and danced, a soft moan escaped her lips. Dimitri lifted her off the ground and pressed her against the wall, one hand cradling head, protecting her from hitting against the ancient stone, while the other gripped her thigh as her legs wrapped around his torso.`}</p>
    <p>{`This was a different Dimitri than the one she used to see every day; the one she texted good morning messages with. No. This Dimitri was The Boar. The wild beast she fought with in the beginning. His passion and ferocity translated into pure, hot, fervid passion.`}</p>
    <p>{`In the heat of it all, Byleth felt the urgent need to breathe because, like the virgin she was, she forgot to inhale beforehand. She reluctantly broke away to gasp for air. She looked into Dimitri’s eyes for a moment and they both crashed into each other’s lips again. Instantly, their tongues searched for each other and she could taste him the second they entwined. It was an addictive sensation, and it seemed that no matter how much they kissed, it just `}<em parentName="p">{`wasn’t enough`}</em>{`.`}</p>
    <p>{`Her heart was about to explode as she gripped the back of Dimitri’s hair, as though she could pull him any deeper into her and let herself melt into his tight embrace.`}</p>
    <p>{`Byleth felt like a completely different individual as something was urging, almost compelling her to slide her hand further down Dimitri’s stomach and under his waistcoat, feeling his warm, rock hard muscles over his dress shirt. He shuddered at her touch, letting out a soft and breathy groan.`}</p>
    <p>{`Dimitri gently placed Byleth back on her feet, again their lips still interlocked, this time he cupped her face. She didn’t know how long this lasted but she was `}<em parentName="p">{`hungry`}</em>{` for him. His hand brushed down her neck and along her collarbone and, very gently, his thumb slid under the strap of her dress, lingering there, hesitant. To encourage him, she pulled him in and softly nipped his bottom lip. He tugged the strap down her shoulder, only stopping at the sound of snapping threads.`}</p>
    <p>{`Byleth didn’t know who she was at this point. All she felt was hot passion all over her body, and fire in her loins. She reluctantly pulled away again, gasping for air. Dimitri’s eyes were glassy and dazed looking, and she could only imagine what she looked like to him at that moment. Instead of consuming each other again, Dimitri leaned in and placed a trail of kisses right where he almost ripped her dress strap. She could feel his wet, hot breath on her shoulder, and she mewled in his ear.`}</p>
    <p>{`This seemed to have caused him to press himself against her, and she felt something hard against her belly. A small surge of panic rose in her chest and she pushed him away.`}</p>
    <p>{`“I-I’m sorry, I-I didn’t know what came over me,” Dimitri said breathily, and apologised again, and again.`}</p>
    <p>{`“I’m sorry,” he muttered again. She shook her head, turning her back to him. She noticed how insanely hot her cheeks were as she cupped them, internally screaming at what just happened.`}</p>
    <p>{`Everything just escalated so fast, her lips were wet with saliva yet chapped at the same time. Her heart was threatening to leap out of her chest, locks of hair had come loose from her updo, and she didn’t dare think of what had become of her makeup.`}</p>
    <Ads mdxType="Ads" />
    <p>{`She just stood still, trying to calm down from the sheer rush of adrenaline that could have made her go `}<em parentName="p">{`all the way`}</em>{`. Her mind continued to screech, deafening her ears even as the world outside was quiet. She gulped as she sheepishly turned to see whether Dimitri was just as disheveled as she was.`}</p>
    <p>{`To her relief, he was. Everything was out of place - his shirt was wrinkled and partially untucked, his hair had come undone and hanging seductively over his eyes, his eyes were wet, his lips were glossy and... was that her lipstick smeared around them?`}</p>
    <p>{`And realization dawned on her;`}<em parentName="p">{` she`}</em>{` did that to him.`}</p>
    <p>{`She didn’t want to, but she needed to confirm her suspicions. Byleth let her eyes trail down to below his belt, as he tucked in his shirt and sure enough, she could see a faint outline of something solid. Her virgin eyes quickly shifted away when Dimitri looked at where her eyes were looking.`}</p>
    <p><em parentName="p">{`Caught in the act. `}</em></p>
    <p>{`Byleth didn’t know how many minutes had gone by as the both of them stood in silence in the Tower. Amidst all that chaos, Byleth had even forgotten about her ankle, which began to ache from the pressure it had been under just moments ago.`}</p>
    <p>{`Suddenly someone’s phone rang and she jumped, her eyes darting around the floor to look for her clutch. She sighed - that entire session had completely turned her upside down, and she couldn’t even remember what her ringtone sounded like.`}</p>
    <p>{`“Hello?” Dimitri’s voice shook a little, “Oh...uh. Okay. I understand. We’ll be there, thank you, Ashe.” `}<em parentName="p">{`Beep`}</em>{`.`}</p>
    <p>{`“I-is everything okay?” she finally managed to stammer out from where she stood.`}</p>
    <p>{`“Yes, Ashe had ordered pizza because everyone was hungry. We’re convening at the greenhouse in 15 minutes. It seems they ordered it about an hour ago.”`}</p>
    <p>{`Right on cue, Byleth’s stomach growled. “Sorry. That was...unexpectedly intense.” She blushed. Her body was still in the throes of adrenaline; she didn’t even need her shawl anymore.`}</p>
    <p>{`Dimitri chuckled, walking towards her and stopping immediately. Byleth had just managed to calm her heart somewhat, and now it was rattling against her chest again. But all Dimitri did was readjust her dress strap and unleashed her hair from the messy bun it was in, brushing  it with his fingers. Finally, he tucked a lock behind her ears.`}</p>
    <p>{`She gestured to him to lean down and proceeded out to smooth his beautiful, blonde hair back into place. She bit her lip in embarrassment, recalling what caused it to be so tousled.`}</p>
    <p>{`Once they had somewhat adjusted themselves, Dimitri held out his hand to help her back down the steps.`}</p>
    <p>{`They laced their fingers as they made their way down.`}</p>
    <Divider mdxType="Divider" />
    <p>{`Dimitri could finally understand why so many songs, poems, ballads— why even battles and war were waged; all in the name of love.`}</p>
    <p>{`Right now, even the hand he was holding was soft and small. Without thinking, he brought the hand to his lips and kissed it. Sparkling green eyes looked up at him and his heart swelled. One thing he knew for sure, was that whatever happened a few minutes ago, wasn’t enough.`}</p>
    <Ads mdxType="Ads" />
    <p>{`He was still processing what had happened, if anything he was bewitched, bothered and bewildered.`}</p>
    <p>{`Actually, were girls always so soft? Just so wonderfully soft in all the right places. And she smelled great. It wasn’t her perfume per se, but it was so refreshing and it drove him `}<em parentName="p">{`mad`}</em>{`. He couldn’t remember what she smelled like before, back when she was Reus, but in this moment, her scent was gaining control of him.`}</p>
    <p>{`As they walked into the greenhouse, Sylvain marched up to them, “Well it’s about time- Oh...hang on a minute…” The red head quickly changed his tone and draped an arm over him. “I see you guys have been up to something very `}<em parentName="p">{`saucy`}</em>{`.” He wiggled a finger in his face, which Dimitri swatted away.`}</p>
    <p>{`“I beg your pardon?” Dimitri tried his best to hide his panic, there was absolutely `}<em parentName="p">{`no way`}</em>{` Sylvain could know.`}</p>
    <p>{`“Oh no,” Byleth bit her bottom lip and pointed at his face.`}</p>
    <p>{`“...Is there something on my face?” He looked Byleth, unable to understand what Byleth was gesturing at.`}</p>
    <p>{`Almost everyone in front of him except Felix and Reus were holding back their laughs, with an especially pleased look on Sylvain’s face, “I am `}<em parentName="p">{`so`}</em>{` proud of you.”`}</p>
    <p>{`“Ugh, you have lipstick all over your stupid mug.” Felix whipped his phone out and turned on his front camera and it was true. In fact, it was smeared all over his mouth.`}</p>
    <p>{`“I’m so sorry! I completely forgot to wipe it for you.” Byleth scuttled over and swiped both her thumbs over his lips until Ashe handed her a tissue.`}</p>
    <p>{`“Atta boy!” Sylvain slapped his arm, “I know `}<em parentName="p">{`exactly`}</em>{` what to get you the next time I run down to the store, just in case `}<em parentName="p">{`something else`}</em>{` happens.” he winked.`}</p>
    <p>{`“Say it. I dare you” Reus glowered as he tore into a big slice of pizza.`}</p>
    <p>{`“M-mints?” Sylvain replied sheepishly.`}</p>
    <p>{`“H-Here! We saved you a spot!” Ashe ushered the both of them to the center of the Greenhouse where they had somehow procured some chairs and used a few upside down buckets as tables for the enormous boxes of pizza.`}</p>
    <p>{`Almost instantly, Byleth grabbed a huge slice and in 4 bites, it was gone. Dimitri felt like cupid had once again shot him right in the heart and he didn’t know this was a sight he missed so much since she left. It didn’t matter there were people around him; watching Byleth eat was `}<em parentName="p">{`divine`}</em>{`.`}</p>
    <p>{`As she was about to reach for another one, she froze. He didn’t know why she stopped and looked at him in horror after that, but he handed her another slice and wiped some sauce from the corner of her lips.`}</p>
    <p>{`“Thank you,” she said, accepting the pizza from him but took smaller bites. He felt a nudge in his side and turned to see Felix who hissed at him to stop staring.`}</p>
    <p>{`“Oh! That’s right.” Dimitri fished out the small blue box from his pocket and presented it to Byleth who somehow was already on a 3rd slice of pizza. “I hope you’ll accept this.”`}</p>
    <p>{`She handed the pizza she was eating halfway to Reus, who was sitting next to her and wiped her hands with a tissue.`}</p>
    <p>{`“Dimitri, this is...I can’t.” She shook her head. “I can’t keep accepting gifts from you.”`}</p>
    <p>{`“I insist.” He pressed. Unaware everyone was listening.`}</p>
    <p>{`“I believe Dimitri spent quite an amount on this, it would be best if you accepted it, Miss Byleth,” Dedue added, bless him.`}</p>
    <p>{`“If it’s not to your liking, I am sure I can exchange it for a different design-”`}</p>
    <p>{`“No, please don’t. I...I love it.” He sighed in relief. In response, he removed the small pendant from its box, stood up and asked if he could put it round her neck.`}</p>
    <p>{`“Does it...does it suit me?” Byleth asked, trying to crane her neck to look at it and Dimitri chuckled.`}</p>
    <p>{`“Yes, it’s even more beautiful on you,” he replied as he placed the box in her hand.`}</p>
    <p>{`“I thought this delicate design would compliment your personality and beauty the best, this is of course purely assumptions on my part. I do apologize if I...I...um...”`}</p>
    <p>{`“Oh get a room,” Felix barked. Dimitri muttered another apology to the group.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Don’t you have something for him as well?” Reus said to Byleth and Dimitri’s heart rate sped up. Byleth had a gift for him?`}</p>
    <p>{`“Well I can’t give it to him now, it’s garbage.” She whispered back loudly to Reus.`}</p>
    <p>{`“Fine. I’ll do it,” Reus said, stuffing the rest of whatever was left into his mouth and dug Byleth’s clutch back.`}</p>
    <p>{`“`}<em parentName="p">{`Reus, no`}</em>{`-” Reus pushed her aside and pressed something small into Dimitri’s palm.`}</p>
    <p>{`“Oh!” He exclaimed, examining the small pink keychain between his fingers, “A pig, how adorable!”`}</p>
    <p>{`Reus snorted and then burst out laughing as Byleth slapped him on the arm.`}</p>
    <p>{`“I-Is it not a pig…?” Dimitri looked down at the item, it had a rather long face and unusually long legs for a pig but sure it had the signature characteristics of a pig.`}</p>
    <p>{`“It’s a horse.” Reus chortled out in between laughs, and Byleth slapped her twin brother even harder.`}</p>
    <p>{`“A...A what?” Dimitri stammered, and everyone crowded around him to see.`}</p>
    <p>{`“Sorry, it’s a stupid little thing I made. I wanted to get you something but I didn’t know what.” she explained, “The internet suggested handmade things for someone who has everything. So I asked Reus to ask you what you like...and he mentioned horses.” Dimitri was about to melt.`}</p>
    <p>{`“The store didn’t have any other air dry clay colours left. Sorry, I’ll toss it and get you something else.” Byleth reached out for the little horse in his fist.`}</p>
    <p>{`“No!” He cried out, holding the handmade gift away from her, “Byleth, I `}<em parentName="p">{`love`}</em>{` it.”`}</p>
    <p>{`“But after you gave me this necklace, that horse is literal trash.” She went on her toes and tried again to reach for it. This time, he held it above his head, protecting it from its creator.`}</p>
    <p>{`“That necklace is worth nothing compared to this,” Dimitri insisted, “You have made me the happiest person. I will treasure this.”`}</p>
    <p>{`“But-”`}</p>
    <p>{`“By,” Reus started, “You put so much of your heart into it after spending days making and remaking that thing. Let him have it.”`}</p>
    <p>{`Byleth’s face softened. “Okay.”`}</p>
    <p>{`“I will use it right now.” He excitedly took his phone out and attached it to a small loop at the bottom of his phone. Byleth finally gave him a small smile. “Thank you, again.”`}</p>
    <p>{`“Ashe, are you crying?” Felix suddenly said.`}</p>
    <p>{`“N-no, I just thought it’s so sweet. I’m so happy to be able to share this moment with you all!” Dimitri’s heart grew, almost feeling surreal at how perfect this moment was in front of him.`}</p>
    <p>{`Maybe it was alright to feel happy like this? Maybe it was okay to...move on?`}</p>
    <p>{`“Dimitri, hurry up and eat! I wanna go back in and have a lech at some girls!”`}</p>
    <p>{`“Absolutely not!”`}</p>
    <Divider mdxType="Divider" />
    <p>{`“Byleth, I...I-I don’t want to scare you or anything. And I know we’ve only been together for a few months, but I’d like you to meet my parents,” Dimitri said as they walked into the hall, hand in hand.`}</p>
    <p>{`She looked up at him, taken aback. “Y-your what?”`}</p>
    <p>{`“Oh I understand this is all so sudden, please forget what I said,” he said and tried to continue his walk, but she pulled him back.`}</p>
    <p>{`“I-I would be honoured,” she blurted out, definitely scared. She was only 18! `}<em parentName="p">{`And`}</em>{` about to meet the famed patriarch of the Blaiddyd bloodline.`}</p>
    <p>{`Dimitri gripped her hand once more and led her through the crowds, turning back every so often to make sure she was still with him. Just as they spotted the senior Mr. and Mrs. Blaiddyd, she stopped him.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Do I look alright? I don’t want to make a bad impression,” she asked, fumbling around with her hair, smoothening her dress and adjusting her shawl.`}</p>
    <p>{`“Perfect.” He leaned down and kissed her forehead, and her heart went into overdrive again. She wasn’t used to affection like this yet, but she was `}<em parentName="p">{`definitely`}</em>{` getting used to it.`}</p>
    <p>{`She sucked in a deep breath as Dimitri called out to his parents who were talking to some pretty important looking people.`}</p>
    <p>{`“Mother, Father, I’d like you to meet Byleth.” He gestured at her, and she curtsied. She didn’t know why she curtsied, they weren’t even royalty.`}</p>
    <p>{`“Good evening, Mr. and Mrs. Blaiddyd,” she said as calmly as she could.`}</p>
    <p>{`“Hello dear, it is lovely to meet you,” Mrs. Blaiddyd said as she pulled her into a quick embrace.`}</p>
    <p>{`But when she craned her neck to see Dimitri’s father who was even taller than him, she swore she was looking at Dimitri’s bearded, older twin.`}</p>
    <p>{`“Ah, nice to meet you young lady.” Mr. Blaiddyd senior held a hand out and gave her own a good firm shake.`}</p>
    <p>{`Dimitri placed a hand on her back. “Byleth is someone dear to me, and it would mean the world to me if you accepted her.”`}</p>
    <p>{`“Oh we accept all your friends, my boy, why, only just now Felix and Sylvain came to greet us!” His father replied. “All growing up to be fine, `}<em parentName="p">{`fine`}</em>{` boys.”`}</p>
    <p>{`“Darling, this is his girlfriend,” his mother said to his father.`}</p>
    <p>{`“O-Oh! Well done, son!” Mr. Blaiddyd slapped Dimitri on the back. “Come girl, join us at our table.” Oh goddess. Byleth panicked internally but smiled at Dimitri nonetheless, allowing him to usher her to their table.`}</p>
    <p>{`And to her great relief, they were very, very warm and accommodating. Asking her regular questions about her family, his mother shared stories about Dimitri’s childhood with Felix, Sylvain and a girl called Ingrid. After a while, his uncle stumbled over and she was introduced to him as well.`}</p>
    <p>{`“Hey By- Oh I beg your pardon, Ma’am, Sir,” Reus said apologetically, “Principal Rhea wants to see us.”`}</p>
    <p>{`“Sorry,” she said to the group, getting up from her seat.`}</p>
    <p>{`“I’ll see you later,” Dimitri said to her, she nodded in reply and took her leave.`}</p>
    <p>{`On the way, she had to fend off some “Do I know you?” from a couple of Reus’ classmates, Lorenz was especially relentless and insisted on dancing with her but Reus shooed him off.`}</p>
    <p>{`It wasn’t anything urgent with Principal Rhea, she had just wanted to know how her grandchildren were getting along, talking about their education and the like.`}</p>
    <p>{`But for some reason, despite the happy mood, Byleth just could not shake this sense of dread that clouded her conscience.`}</p>
    <Divider mdxType="Divider" />
    <p>{`“Father, I’ve been meaning to ask. It seems that none of my credit cards work recently. Everytime I try to purchase something, it declines,” Dimitri said quietly, trying not to sound ungrateful but he noticed his father’s expression turned grave.`}</p>
    <p>{`His father let out a sigh. “I’m sorry about that. We’ve had some trouble with the company lately so a couple of my bank accounts have been frozen for investigation. I forgot to tell you, again, I am sorry. Do you need money now?”`}</p>
    <p>{`Dimitri shook his head. “Is everything alright?” He suddenly felt fear and guilt. He was happily pursuing romance while his father was facing problems he could not help with.`}</p>
    <p>{`Lambert Blaiddyd placed a hand on his shoulder. “Nothing you should worry yourself about. Enjoy the rest of the evening and your youth, Dimitri. The adult world isn’t going to be kind.`}</p>
    <p>{`Savour it while it lasts, my boy.” Dimitri could barely make out what his father said as the speakers suddenly blasted out loud club music and hordes of his schoolmates rushed to the middle of the hall to dance.`}</p>
    <p>{`“I beg your pardon father, could you repeat that?”`}</p>
    <p>{`“I said, go and live your life, Dimitri. Enjoy it!”`}</p>
    <p>{`“Yes, father. I promise.”`}</p>
    <Divider mdxType="Divider" />
    <p>{`The night whizzed by and before everyone knew it, it was midnight. Byleth and Dimitri had met up to spend the last couple of minutes together before they parted ways again.`}</p>
    <Ads mdxType="Ads" />
    <p>{`The ball tonight also marked the start of a short term break before the end of the year exams, Reus had mentioned. Therefore, many students would be returning home with their parents over the weekend. Reus seemed especially happy to be going home to his bed.`}</p>
    <p>{`“I suppose this is goodbye, for now,” Dimitri said, scratching the back of his head. “Will you send me a message when you arrive back to your hotel safely?”`}</p>
    <p>{`“Sure.” She smiled.`}</p>
    <p>{`“I’ll do the same, of course. But I think my parents are going straight to the airport. We’re flying back home because father has some things to sort out tomorrow.”`}</p>
    <p>{`She nodded in reply. “Then...I’ll wait for your message too.”`}</p>
    <p>{`“Dimitri dear, it’s time.” He looked over his shoulder and nodded at his mother.`}</p>
    <p>{`“Thank you for everything, tonight. I hope you had a good time, too,” he said before placing a small kiss on her cheek. Byleth nearly exploded from the embarrassment because he was so bold in front of his parents.`}</p>
    <p>{`“I did. Thank you,” she managed to reply.`}</p>
    <p>{`Her heart ached when they said their goodbyes, even though she wasn't openly expressing it.`}</p>
    <p>{`Reus let out a groan as he stretched next to her, haphazardly dropping his luggage on the ground. “Ugh, I’m exhausted. Have you called an Uber yet?”`}</p>
    <p>{`“Yeah,” she said, unsure about why her heart was hurting. She’d already made peace with saying goodbye to Dimitri but still it hurt. Was she...love sick?`}</p>
    <p>{`“Let me tell you, By,” her brother said while loosening his bowtie as he leaned on the railing where many of the guests were waiting for their transport, “We are not made for events like this.”`}</p>
    <p>{`“Why’re you suddenly so nice to me and Dimitri?” She asked, taking her place next to him. She couldn’t tell if her feet or heart ached more.`}</p>
    <p>{`He shrugged. “Dunnu.” Reus looked down at her and she knew that look; it was probably their twin telepathy so she had a feeling it was his guilt for punching Dimitri.`}</p>
    <p>{`As expected of a big event, it was a while before their ride arrived and the two of them stood in silence. Silence for the Eisner twins was comfort, and they leaned on each other for support.`}</p>
    <p>{`Byleth had more social interactions that night than her entire life, and by the time they reached their hotel room. The both of them barely showered and crashed into their beds.`}</p>
    <p>{`Just as she was about to drift off into sleep, she shot her eyes open and sent Dimitri a text.`}</p>
    <MessageBox totalUnread={0} contactNumber="Dimitri" items={[{
      timestamp: {
        day: 'Sunday',
        time: '01:58AM'
      },
      right: '<p>am in bed.</p>'
    }, {
      right: '<p>thanks for the necklace</p>'
    }, {
      right: '<p>goodnight</p>'
    }, {
      typingLeft: true
    }, {
      left: '<p>We’re still on the way to the airport. Please rest and I will let you know when I’ve reached home,</p>'
    }, {
      left: '<p>I am in love with the horse you made me, I’m sorry I called it a pig. I have decided to call it Loog. I hope that’s okay with you.</p>'
    }, {
      right: '<p>haha</p>'
    }, {
      right: '<p>yes</p>'
    }, {
      left: '<p>Good Night.</p>'
    }, {
      right: '<p>🥰</p>'
    }]} mdxType="MessageBox" />
    <p>{`Byleth fell asleep instantly once she hit send, not even bothering to lock her phone.
The sleep she had was deep but vivid, bright and unsettling. It was probably from
such an exciting night and her brain was in overdrive.`}</p>
    <p>{`But the unsettling sense of dread did not leave her body the entire night. Not even when she took a shower and finally washed off all the makeup and hair spray.`}</p>
    <p>{`Her stomach sank when she checked her phone, and saw she had forgotten to charge it the entire night, it had only 2% left. Curious about not receiving a message from Dimitri, she sat down on her bed to quickly unlock it to see if her phone data was working.`}</p>
    <Ads mdxType="Ads" />
    <p>{`She moved her eyes up to the time on her screen, `}<em parentName="p">{`9:37 am`}</em>{`.`}</p>
    <p>{`It shouldn’t have taken more than 2 hours to reach Faerghus by plane. Instead of a text from Dimitri, there was one single message from Felix.`}</p>
    <p>{`It finally dawned on her why she tumbled and tossed all night. And this message was the reason why she was filled with dread from the night before until now.`}</p>
    <MessageBox totalUnread={0} contactNumber="Felix" items={[{
      timestamp: {
        day: 'Sunday',
        time: '09:37AM'
      },
      left: '<p>Dimitri was in an accident.</p>'
    }]} mdxType="MessageBox" />
    <AuthorNote mdxType="AuthorNote">
  <p>
     HELLOOOOO PHEWWWWW It actually took me a whole year to write this fanfic
    and honestly, i couldn’t have done it without my editor and nabe-chan. I
    don’t think any amount of screaming can convey how much i adore these two
    sakjhksadjfh 
  </p>
  <p>ANYWAY! I thought I’d explain a few things here;</p>
  <p>
    -Dimitri’s BIOLOGICAL parents are alive, because I wanted to give Dimitri a
    chance to be with his parents for a little while longer. (i apologize if I
    butchered your image of them, I envisioned Lambert to be very charismatic
    and his mother a loving and maybe a little fussy over her baby boy :’D )
  </p>
  <p>
    -The incident that caused Dimitri to have dark thoughts was Glenn’s death,
    which I will probably elaborate more in Season 2!! 
  </p>
  <p>
    -It is still my dream and wish to have a Golden Route with Claude, Dimitri
    and Edelgard work together and be at peace, so I’m <em>sort of</em>{' '}
    incorporating it here. Basically, the 3 of them just don’t fight is all
    hahahahhaha. 
  </p>
  <p>
    -Yes, Edelgard is Dimitri’s cousin here and he did develop a small crush of
    sorts on her, incest-y yes, but to be fair, I did admire one or two of my
    older cousins DON’T SHAME ME PLEASE XD 
  </p>
  <p>
    -The story in season 2 will shift of course, since Byleth doesn’t need to
    disguise herself anywhere and anymore. I do miss writing about their
    shenanigans, so maybe MAYBE I will write a side piece as an extra and MAYBE
    write that famous She’s the man scene with the tampon XD 
  </p>
  <p>
    -I adore feedback and knowing what you liked or disliked, and I also love
    hearing what you want to see more!! You readers are the blood in my veins
    that keep me going so please, dont be shy to tell me :3  
  </p>
  <p>
    I’d be happy to answer any questions and plot holes/inconsistencies you find
    here!! I’m sorry if you do find any, I’m not as organized as I should be and
    it’s still a learning process for me! 
  </p>
  <p>
    LASTLY, I CANNOT- CAAANNNNOOOOTT EXPRESS HOW MUCH I LOVE YOU GUYS FOR
    READING?@?!!!?!? Every single view, kudos and click means
    E.V.E.R.Y.T.H.I.N.G TO ME!! 
  </p>
  <p>
    I literally drop EVERYTHING every time someone comments, whether its on AO3,
    the blog, EVEN TWITTER PEOPLE TAG ME AND TELL ME THEY LOVE THIS FIC
    kjsdfkjadhsfkjdshjhf I work in a big, serious-environment office and I need
    to excuse myself to toilet and squeal!! And oh my god don’t get me started
    on FAN ART SOME OF YOU CRAZY AMAZING PEOPLE HAVE DRAWN. This is literally my
    life goal and if I died tomorrow, please know I died with my heart’s desire
    fulfilled!
  </p>
  <p>
    Season 2 will be out in early 2021, I hope you guys will still be here
    BECAUSE I CAN'T LIVE WITHOUT YOU ALL T.T 
  </p>
  <p>
    P.s, here’s the dress I envisioned her wearing, the amazing and wonderful
    @illusidy on twitter did a rough edit for me BUT I LOVE IT SO MUCH
    SDKJFHKSADJFH In my head it’s much poofier and of course you’ll have the
    imagine the medical boot. By the way this picture is ‘DIMITRI’S VISION’
    hahahahahah, hence the pinkness hahahaha.{' '}
    <RemoteLink to="https://www.aliexpress.com/item/32973120863.html?srcSns=sns_Copy&amp;spreadType=socialShare&amp;bizType=ProductDetail&amp;tt=MG&amp;aff_platform=default&amp;sk=_dT0xmJi&amp;mergeHashcode=32047972951&amp;description=MYR+275.96++11%EF%BC%85+Off+%7C+Champagne+Muslim+Evening+Dresses+2020+High+Slit+Satin+Spaghetti+Straps+Sweetheart+A-Line+Long+Prom+Dress+Burgundy+Evening+Gown&amp;aff_trace_key=d4d103e0809f4652b49d86e290b5da7c-1606921814798-06609-_dT0xmJi&amp;businessType=ProductDetail&amp;title=MYR+275.96++11%EF%BC%85+Off+%7C+Champagne+Muslim+Evening+Dresses+2020+High+Slit+Satin+Spaghetti+Straps+Sweetheart+A-Line+Long+Prom+Dress+Burgundy+Evening+Gown&amp;platform=AE&amp;terminal_id=ce16fcddea814b3f9df8a8151d850837&amp;templateKey=white_backgroup_101" mdxType="RemoteLink">
      Here’s
    </RemoteLink>
    the link for where i found it from(please dont judge me) OF COURSE, you are
    free to imagine your own choice of dress!! 
  </p> 
  
  <Image name="byleth-dress-everything-changes-but-you-fanfic.jpg" mdxType="Image" />
  <p>And here is the pendant from Tiffany &amp; Co. Dimitri got her ^_^!</p>
  <Image name="tiffany-byleth-fanfic.jpg" mdxType="Image" />
  <Image name="tiffany-byleth-fanfic-2.jpg" mdxType="Image" /> 
    </AuthorNote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      