import { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../styles/globals';
import TinyImageCard from '../TinyImageCard';
import Pagination from '../Pagination';
import InFeedAd from '../Ads/InFeed';

const Section = styled.section``;

const Container = styled.div`
  padding: ${$.layout.padding5}px 0;
`;

const StyledInFeedAd = styled(InFeedAd)`
  margin: 20px auto;
  width: 100%;
`;

const PostsContainer = styled.div`
  display: grid;
  grid-gap: 25px;

  ${$.screen.desktop} {
    grid-template-columns: repeat(4, 1fr);
  }

  ${$.screen.tabletB} {
    grid-template-columns: repeat(3, 1fr);
  }

  ${$.screen.tabletA} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${$.screen.mobile} {
    padding: ${$.layout.padding5}px 0;
    grid-gap: 10px;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
`;

const Posts = ({ items }) => {
  const totalPosts = 20;
  const [slice, setSlice] = useState({ start: 0, end: totalPosts });

  return (
    <Section>
      <Container>
        <StyledInFeedAd />
        <PostsContainer>
          {items.slice(slice.start, slice.end).map(({ node }) => {
            const {
              timeToRead,
              slug,
              frontmatter: { banner, excerpt, published, title },
            } = node;

            return (
              <TinyImageCard
                key={`${slug}_${published}`}
                img={banner}
                timeToRead={timeToRead}
                slug={`/${slug}/`}
                date={published}
                excerpt={excerpt}
                title={title}
              />
            );
          })}
        </PostsContainer>
        <StyledInFeedAd />
        <Pagination
          totalItems={items.length}
          maxItem={totalPosts}
          setSlice={setSlice}
        />
      </Container>
    </Section>
  );
};

Posts.defaultProps = {
  items: [],
};

Posts.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
};

export default Posts;
