import PropTypes from 'prop-types';
import styled, { createGlobalStyle } from 'styled-components';

import atFanfictionChapter from '../Navbar/atFanficChapter';
import $ from '../../styles/globals';
import ViewIcon from '../../assets/icons/view.svg';

const Container = styled.div`
  display: flex;
  flex-direction: ${({ swap }) => (swap === true ? 'row-reverse' : 'row')};
  align-items: center;
  svg {
    fill: ${$.brown4};
    width: 20px;
    height: 20px;
    ${({ swap }) =>
      swap === true
        ? `margin-left: ${$.layout.margin1}px;`
        : `margin-right: ${$.layout.margin1}px;`};
  }
`;

const TotalView = styled.div`
  font-size: 15px;
  color: ${$.brown4};
`;

const NightMode = createGlobalStyle`
  body.night-mode {
    ${TotalView} {
      color: ${$.white1};
    }
    ${Container} {
      svg {
        fill: ${$.brown1};
      }
    }
  }
`;

const PageViewCounter = ({ total, swap }) => (
  <Container swap={swap} title={`Total page views: ${total}`}>
    {atFanfictionChapter() && <NightMode />}
    <ViewIcon />
    <TotalView>{total}</TotalView>
  </Container>
);

PageViewCounter.defaultProps = {
  total: 0,
  swap: false,
};

PageViewCounter.propTypes = {
  swap: PropTypes.bool,
  total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default PageViewCounter;
