import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const Blockquote = makeShortcode("Blockquote");
const RemoteLink = makeShortcode("RemoteLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Persona Q2 has been on my wish list for such a long time now so I quickly snapped it up when it went on sales. I played the prequel, Persona Q and it was brilliant. Etrian Odyssey (EO) gameplay with a Persona twist is a match made in heaven. I started from EO 1 and continued pressing on till EO V: Beyond the Myth and EO Untold 2. EO is an `}<em parentName="p">{`absolutely`}</em>{` `}<strong parentName="p">{`brilliant`}</strong>{` dungeon-crawling series, and you can’t change my mind. You see, Etrian Odyssey is perfect as it is - challenging, a chance to self-insert your guild’s adventure and a party you can customize on the fly. Then Atlus tacks on Persona’s plot and added the series’s stylistic user interface (UI)! Mamamia… Someone hold me…`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Theodore from Persona 3 in a popcorn costume" caption="SURPRISE! Theo in a dashing costume." name="theodore-persona-3-portable.jpg" mdxType="Image" />
    <p>{`But enough about gushing. I just completed my third hour with Persona Q2 before I feel compelled to write a blog post about it. So far it’s great. I’m left very impressed by the effort they put in to match Persona 5’s equally impressive UI. All menus and modal boxes are super edgy. Moving from one item to another has smooth transitions! Heck, almost `}<strong parentName="p">{`every button, modal boxes and even the background on your bottom screen`}</strong>{` has a subtle animation. It felt like everything has a 300ms animation/transition tacked on it, just like modern websites. Kudos to Atlus, because that’s how you do a game with style. However...`}</p>
    <Blockquote mdxType="Blockquote">
  It’s unfortunate that Persona Q2 had to be on the Nintendo 3DS.
    </Blockquote>
    <p>{`This is my honest thoughts after I reached a point where Persona 3 Portable’s (P3P) female main character (MC) joined my team. This game is bursting with energy and the design team had some amazing ideas for this game. It is clear to see they were forcing it all to on to the tiny screen of Nintendo 3DS. Don’t get me wrong! It looks great but everything… just seems extra pixelated. I really think the game deserves to be on a better console. (Hint: Nintendo Switch)`}</p>
    <p>{`Note: I used my phone to take screenshots. Sorry about the blurry pics.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Great looking character models, too fine details`}</h2>
    <p>{`RPG games on Nintendo 3DS love using chibi sprites or static art, and that’s totally understandable. But Atlus says screw understandable limitation, you get a full 3D model moving and emoting according to dialogue on screen. Not only did they fit 1 character on screen, but they also managed to fit three at the same time! Maybe it’s pre-rendered. Maybe it’s some magical but technical prowess of their development team that did this. Regardless, it’s impressive. The models look even more detailed than Persona Q’s! But there’s this tiny little problem that’s bugging me a lot…`}</p>
    <Blockquote mdxType="Blockquote">
  You can’t appreciate the details on a pixelated screen of a Nintendo 3DS.
    </Blockquote>
    <p>{`Here’s an example. The model is literally the exact same copy of the character art with no details lost. The one nitpick I have is that the outline of these models is too thin. I can’t even see past the glaring white canvas that is Nagi’s clothes design. If you squint, you can see some details but obviously you won’t be squinting all the time while you play the game.`}</p>
    <Image alt="Nagi from Persona Q2 has a glaring white outfit" caption="I really like Nagi's look, but her glaring all-white outfit with really thin outline makes it really hard for me to look at it." name="nagi-persona-q-2-geeknabe-blog.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`Way too pixelated `}</h2>
    <p>{`Most developers would try to find a way around in presenting their characters on the 3DS. Making the outline thicker, creating a less detailed model, using chibi models instead, etc. Anything to get that sweet 3D toggle to work. But right now we are talking about Atlus, the very same company that treats Persona 5’s UI as a `}<RemoteLink to="https://personacentral.com/persona-5-interview-ui-design-sound-music/" mdxType="RemoteLink">{`thing on its own`}</RemoteLink>{`.`}</p>
    <p>{`The colours, the shapes, everything is poppy on my screen. But unfortunately, due to Atlus’ choice to do slanted menus and buttons, everything is super pixelated. Usually, I’d brush it off since it’s the 3DS after all. The last thing you’d expect is super sharp models dancing on your 400x240 screen. Unfortunately, I `}<strong parentName="p">{`can’t`}</strong>{` brush it off.`}</p>
    <Image alt="Persona Q2 menu design" caption="Great design. I'm glad the menu options have a bigger font." name="persona-q2-slanted-menus.jpg" mdxType="Image" />
    <p>{`The pixelation is just way too obvious! The buttons, dialogue boxes and even the models themselves. They move often too so the pixelation is double obvious. I wear glasses so it annoys me to see such blurry details as I play the game. My bias and love for this series will undoubtedly overpower this slight inconvenience, but I’ll remember the game as `}<em parentName="p">{`"the one where it looks good but everything is blurry".`}</em></p>
    <Ads mdxType="Ads" />
    <h2>{`The font is unacceptable`}</h2>
    <p>{`Monster Hunter 3 Ultimate’s font is bad, and Atlus is more than happy to place Persona Q2’s font next to it. Font choices for the 3DS have always been terrible (Small, blurry, hard to read, etc) but this takes the cake. I get that it is supposed to blend in well with the graphics but in this case, I’d take readability over style. This is made worse knowing that it’s a text-heavy game. It’s so blurred, you can see the zig zags clearly (But not the whole sentence).`}</p>
    <Image alt="Futaba best girl." caption="One of the many fully voice dialogues. The font blurriness is way more obvious when seen in person on a Nintendo 3DS screen." name="futaba-velvet-room-twins-persona-5-geeknabe-blog.jpg" mdxType="Image" />
    <h2>{`Having a love-hate relationship with this game`}</h2>
    <p>{`If Persona Q is any indication, I will grow to enjoy this game. Every inconvenience or bad choices by Atlus will be ignored or forgiven because Persona Q2 is the love child of the Etrian Odyssey series that I love. I’ll slowly grow used to the font too. This blog post is a plea to the gaming companies to `}<strong parentName="p">{`STOP `}</strong>{`prioritising style over accessibility/readability. I don’t want to go through the situation with Monster Hunter 3 Ultimate `}<strong parentName="p">{`AND `}</strong>{`Xenoblade Chronicles X. Both games have excellent gameplay, but both have terrible font size and choices. Xenoblade Chronicles X’s UI is so hard to navigate! Please don’t make it hard for me to enjoy a game.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      