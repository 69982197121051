import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const RemoteLink = makeShortcode("RemoteLink");
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Geeknabe is a `}<RemoteLink to="https://jamstack.wtf/" mdxType="RemoteLink">{`Jamstack`}</RemoteLink>{` site. It was built using `}<RemoteLink to="https://www.gatsbyjs.org/" mdxType="RemoteLink">{`GatsbyJS`}</RemoteLink>{` and deployed to the world wide web using Vercel. My experience with `}<RemoteLink to="https://vercel.com/" mdxType="RemoteLink">{`Vercel`}</RemoteLink>{` (formerly Zeit) has been amazing. It’s easy to set things up and get your site running real quick. All I need to do is run a single command line! I was able to quickly add my Github project to it and view my site live as well. It’s effortless and takes away the complexity from the hassle of deploying a site. And all that for free! As a front-end web developer, I am really liking what Vercel is doing here.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Trying to get Vercel to play nice with my domain bought from `}<RemoteLink to="https://www.cloudflare.com/" mdxType="RemoteLink">{`Cloudflare`}</RemoteLink>{`, however, is a `}<em parentName="p">{`terrible experience`}</em>{`. Previously, my blog was hosted on a `}<RemoteLink to="https://aws.amazon.com/s3/" mdxType="RemoteLink">{`AWS S3 bucket`}</RemoteLink>{` with Cloudflare as the `}<RemoteLink to="https://www.cloudflare.com/learning/cdn/what-is-a-cdn/" mdxType="RemoteLink">{`CDN`}</RemoteLink>{`. It worked for a bit, but the inconvenience made me rethink my deployment process. Generally, what I wanted to do with Vercel is to:`}</p>
    <ol>
      <li parentName="ol">{`Host my project on Vercel.`}</li>
      <li parentName="ol">{`Add my Cloudflare domain to Vercel so it links to my Github project.`}</li>
      <li parentName="ol">{`Use Vercel’s CDN and other services, such as free SSL cert.`}</li>
    </ol>
    <p>{`There is no guide (when I first started doing this in 2019) to make the shift, so I struggled a bit. Plus, this blog is my first proper website deployment. Coding is one thing, but managing hosting and whatnot is a totally new experience to me. With some help from Vercel’s Support by emailing back and forth, I managed to get it done. My blog is now using Vercel’s CDN! `}<RemoteLink to="https://levelup.gitconnected.com/how-to-set-up-cloudflare-with-zeit-93daa7d45dd" mdxType="RemoteLink">{`You can find the basic steps I did to add the domain to Vercel here`}</RemoteLink>{`. While Dom Talbot chose to use Cloudflare CDN and their SSL cert renewal, I did not. I chose `}<strong parentName="p">{`DNS`}</strong>{` under the Cloudflare’s Proxy status so I could use Vercel's CDN and did my best to tweak the caching settings through `}<strong parentName="p"><RemoteLink to="https://vercel.com/docs/configuration#project/routes" mdxType="RemoteLink">{`vercel.json`}</RemoteLink></strong>{`. It was going fine until I began noticing many bots visiting my site, thus pushing my bandwidth usage to go really high for a Jamstack site.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Vercel has a `}<RemoteLink to="https://vercel.com/docs/v2/platform/fair-use-policy" mdxType="RemoteLink">{`Fair Use Policy`}</RemoteLink>{` that allow my blog to use up to 100GB of bandwidth. It was `}<em parentName="p">{`more than enough`}</em>{` since it’s all static pages but the bots are starting to get `}<strong parentName="p">{`unmanageable`}</strong>{`. I mulled that it’s best that I `}<em parentName="p">{`block`}</em>{` these bots before they even get to visit my blog. Unfortunately, Vercel `}<em parentName="p">{`does not`}</em>{` have that feature. I shoot an email to Vercel’s Support only to get a firm `}<strong parentName="p">{`NO`}</strong>{` and a sales talk. So I decided that my only choice is to move back to using Cloudflare CDN.`}</p>
    <p>{`Do the following to use Cloudflare CDN despite having your site hosted at Vercel's:`}</p>
    <h2>{`Step 1. Make sure your CNAME is proxied`}</h2>
    <p>{`Under Cloudflare’s dashboard, visit the DNS tab and switch from `}<strong parentName="p">{`DNS `}</strong>{`to `}<strong parentName="p">{`Proxied `}</strong>{`for your domain’s CNAME.`}</p>
    <Image alt="Make sure to set Cloudflare as the proxy!" caption="You can find this under Cloudflare's Dashboard > DNS tab. Make sure the Proxy Status is Proxied!" name="vercel-cloudflare-cdn-1.jpg" mdxType="Image" />
    <h2>{`Step 2. Update your vercel.json`}</h2>
    <p>{`Set your site’s header to have `}<em parentName="p">{`s-maxage=0`}</em>{`. You can do this by setting the `}<em parentName="p">{`routes`}</em>{` key. This prevents Vercel from caching your content, thus letting Cloudflare to do the work. Make sure to deploy your project for the changes to take effect!`}</p>
    <Ads mdxType="Ads" />
    <pre>
  {`// vercel.json
{
  "routes": [{
    "src": "/.*",
    "headers": {
      "cache-control": "s-maxage=0"
    }
  }]
}
`}
    </pre>
    <p>{`And that’s it. You can check if the changes took effect by clearing your browser’s cache and checking the Network panel through your Developer’s tool. The request header’s `}<em parentName="p">{`server`}</em>{` field should say `}<strong parentName="p">{`cloudflare`}</strong>{` instead of `}<strong parentName="p">{`Vercel.`}</strong>{` You should start seeing visitor logs from your Cloudflare dashboard too. Now you can use Cloudflare’s tools such as Firewall to block bots and their nifty Page Rules! The only trouble you will have is that you may need to `}<strong parentName="p">{`purge the cache`}</strong>{` from Cloudflare everytime you make a fresh deployment through Vercel (since Vercel can't purge the cache for you now).`}</p>
    <p>{`I was concerned that Cloudflare has imposed an `}<em parentName="p">{`unspoken`}</em>{` limit to the bandwidth for free users. Thankfully, all I need to do is send an email to squash that doubt. I took a look at the Fair Use Policy, and it seems like sites that host static pages are free to use Cloudflare’s CDN with no limit <3 Hurray! I should have done this sooner, seriously...`}</p>
    <Image alt="FREE! YAS" caption="Hurray! As long as I don't abuse it by hosting videos or images for download, this blog should be fine." name="vercel-cloudflare-cdn-2.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`Additional Tips`}</h2>
    <p>{`If your site has static content like mine, I suggest that you add a Page Rule to cache everything (Because Cloudflare doesn't cache HTML pages for you) to speed up your site.`}</p>
    <Image alt="Example of how to add a Cloudflare Page Rule to cache your site" caption="Replace the URL match with your own domain, and choose Cache Everything. I am getting ~80% of my files cached from this newly added setting alone." name="vercel-cloudflare-cdn-3.jpg" mdxType="Image" />
    <p>{`You should also make a Firewall Rule to weed out the bad bots! Some bad bots will not respect your robots.txt, so it's best to use the Firewall Rule to outright block them. These blocked bots would then show up as logs under your Firewall Rules overview tab. You could then cherry-pick which bot to whitelist or block until you're satisfied with the list. Here’s my Firewall Rule for bad bots:`}</p>
    <pre>
  {`
(http.host eq "bottraffic4free.host") or (http.user_agent contains "muckrack") or (http.user_agent contains "Qwantify") or (http.user_agent contains "Sogou") or (http.user_agent contains "BUbiNG") or (http.user_agent contains "knowledge") or (http.user_agent contains "CFNetwork") or (http.user_agent contains "Scrapy") or (http.user_agent contains "SemrushBot") or (http.user_agent contains "AhrefsBot") or (http.user_agent contains "Baiduspider") or (http.user_agent contains "python-requests") or (http.user_agent contains "crawl" and not cf.client.bot) or (http.user_agent contains "Crawl" and not cf.client.bot) or (http.user_agent contains "bot" and not http.user_agent contains "Discord" and not http.user_agent contains "bingbot" and not http.user_agent contains "Google" and not http.user_agent contains "Twitter" and not cf.client.bot) or (http.user_agent contains "Bot" and not http.user_agent contains "Google" and not cf.client.bot) or (http.user_agent contains "Spider" and not cf.client.bot) or (http.user_agent contains "spider" and not cf.client.bot)
`}
    </pre>
    <p>{`I hope my short write-up has helped you in setting up a static site for free using Vercel and Cloudflare. Please leave a comment if I made a mistake in this post. Thank you for reading!`}</p>
    <Ads mdxType="Ads" />
    <p><strong parentName="p">{`Important Note: I am currently using Cloudflare Pages. Much, much easier to deploy your blog to live site without the need for Vercel. Give it a try!`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      