import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const RemoteLink = makeShortcode("RemoteLink");
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const Video = makeShortcode("Video");
const Blockquote = makeShortcode("Blockquote");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I’ve been playing this game every night after work for the past 3 weeks, just completing every darn event thrown my way. Clear the meteorite shards for primogems and a free Fischl? Eh, that’s great! Complete gliding quests for free 60 primogems? Eh, why not! And there’s the upcoming `}<RemoteLink to="https://twitter.com/GenshinImpact/status/1336527638202966024?s=20" mdxType="RemoteLink">{`Dragonspine area`}</RemoteLink>{` too… All of these events are stacked right after another, leaving absolutely zero space for me to take a breather. The events just keep coming and `}<em parentName="p">{`I don’t know what to do`}</em>{`. The big brain move is obviously to `}<strong parentName="p">{`play them all`}</strong>{`! Miss absolutely zero events! Clear all of it! I mean, these events are essentially free primogems and as a casual player, I really should be hogging these events. How else do I get primogems to pull on the banners? ):`}</p>
    <Ads mdxType="Ads" />
    <Image alt="ZHONGLI" caption="ZHONGLI ZHONGLI ZHONGLI" name="genshin-impact-zhongli-banner.jpg" mdxType="Image" />
    <p>{`But there’s this little voice at the back of my brain, telling me to `}<em parentName="p">{`take a break`}</em>{` from Genshin Impact. After all, you should be the one playing the game, not `}<strong parentName="p">{`the other way around`}</strong>{`. However… Let’s just say this is not going as I hope to be. You see, my gameplay style is to play whenever `}<em parentName="p">{`I feel like it`}</em>{`. I feel that games shouldn’t have a time-locked event that requires my immediate attention. This is part of the reason why I enjoy playing RPG a lot. I get to advance the game on my own time and pace with nothing to lose since the game is a full package.`}</p>
    <p>{`Yes, yes I know Genshin Impact is not exactly the RPG I had hoped for but I naively tried the game out regardless, with hopes that it’s a full package Action RPG that I could solo or co-op whenever I want to. As for the gacha part that requires paying, it’s just a surplus that did little to mar your gaming experience. Besides, the game gave us a ton of ways to earn primogems. I honestly think this game could be played at your own pace if you really didn’t care about the events and whatnot.`}</p>
    <p>{`But alas, miHoYo has done a brilliant job in a `}<em parentName="p">{`sneaky manner`}</em>{` to make sure that you `}<em parentName="p">{`keep playing`}</em>{`… `}<strong parentName="p">{`EVERYDAY.`}</strong>{` If you have not dive deep into this game, I suggest that A) Stay away from this game or B) Don’t let it control you. And most importantly, don’t let this game take hold of your wallet.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Seriously, I’m in a love-hate relationship with this game. The production is great and I really enjoyed every minute of it. The music is fantastic. Plus, they uploaded it to Spotify which makes them double fantastic. The other best part about Genshin Impact is that there’s only 2 out of 7 regions out so far! They have released a spoiler-ish trailer (I added it below, please watch it!) to hint us on what’s to come in the other 5 regions, which furthers my interest to `}<strong parentName="p">{`stay`}</strong>{` invested in this game. The elemental reaction mechanic is fun to combo with, and the whole “`}<em parentName="p">{`improving your team”`}</em>{` through artifacts, talents, etc. got me hooked deep. The base game alone is honestly, good enough for casual players to enjoy. If you’re in need of help, get help from stronger players or play with your own friends. Everything and I mean `}<strong parentName="p">{`everything`}</strong>{` in this game is positioned as a `}<em parentName="p">{`choice`}</em>{` you are free to take.`}</p>
    <Video title="Genshin Impact" src="https://www.youtube.com/embed/TAlKhARUcoY" mdxType="Video" />
    <Blockquote mdxType="Blockquote">
  There are many options for you to improve your team. You can buy the ascension
  materials, or farm it yourself. You could even craft them! Everything is
  already in the base game. None of the features you need to enjoy the game is
  locked behind a paywall. You could pay, of course but you can have it for
  free. 
    </Blockquote>
    <p>{`Now here’s where the hook begins and miHoYo fully understands this. We currently live in an age of convenience. Given a readily cooked and fabulous meal `}<em parentName="p">{`versus`}</em>{` a normal meal you need to prep on your own, which would you choose if these choices are dangled in front of you? Folks who love instant gratification would pick the first choice. Now you might say, the correct choice should be the `}<em parentName="p">{`latter`}</em>{` one. Both choices are neither right or wrong, technically. You should choose the choice which you can afford to do in your situation. But here’s the thing - it didn’t `}<em parentName="p">{`matter`}</em>{` to miHoYo which choices you take since both would benefit them regardless. In case you haven’t noticed it yet, I was alluding to the `}<strong parentName="p">{`gacha situation`}</strong>{` versus a casual player who tries to `}<strong parentName="p">{`play the game without paying`}</strong>{`. I mean, there’s nothing wrong with playing for fun and without paying right? It’s not like Genshin Impact needs you to pay in order to play the game right.`}</p>
    <Ads mdxType="Ads" />
    <p>{`But everything in this game feels as if it is structured to make you `}<strong parentName="p">{`stay longer`}</strong>{` and thus, making you so invested that your wallet just naturally opens up. No, my hard-earned salary! Ahem, allow me to explain how they did it. I must say though, they are super good at prying open my wallet...`}</p>
    <Blockquote mdxType="Blockquote">
  PS: Yes. I, too, paid for Zhongli. I don't know how they did it, but I just...
  happily paid for him.{' '}
    </Blockquote>
    <p>{`Let’s talk about `}<strong parentName="p">{`Primogems`}</strong>{`. They are the bread and butter behind everything good about Genshin Impact. With Primogems, you could pull on the weapon or character banners. In turn, you get free tokens to buy materials to boost your newly pulled characters in their Shop. Fair, isn’t it? It’s a nice feedback loop that softly nudges you to keep pulling if you get the chance to. I mean, there’s nothing to lose, ain’t it? Now if you were to pay for the Primogems, you get to pull immediately and use the tokens too. You even get access to a paid player only Shop! This thread of thought is going `}<strong parentName="p">{`okay`}</strong>{` so far. If you pay, you get better stuff! But no pressure, yea? You don’t need to pay if you don’t want to. In fact, you don’t need to pull if you don’t feel like it either. However! miHoYo made it possible to gain Primogems in every manner they can think of even if you’re playing it casually. So really… If you have these Primogems, why aren’t you pulling? You’ll pull one or two banners for sure.`}</p>
    <p>{`Once you get a taste of Primogems, the temptation starts `}<em parentName="p">{`trickling in`}</em>{`. You want more and they made it `}<em parentName="p">{`easy`}</em>{` to get `}<strong parentName="p">{`more.`}</strong>{` First, the quests. You take some time to complete the quests, learn some lore, fledge out the story and voila, free Primogems. As you go around killing Hillichurls, your World Rank levels up naturally. The monsters get harder and you feel, like every RPG game out there, to improve your team. So you start looking into getting better gear or `}<em parentName="p">{`worse,`}</em>{` (HAHA) better `}<strong parentName="p">{`teammates`}</strong>{` to join your team. If you’re interested in playing the game, I’m sure you’re interested enough to build your team. It’s inevitable, says miHoYo. So you start looking into ascending them by completing domains! If you’re lucky, you get more than enough materials that you need. If you don’t… Well, try again. Now here’s the part that miHoYo has absolute control over, and how they’re making us come back for more - `}<em parentName="p">{`the rate of your resin filling up.`}</em></p>
    <Ads mdxType="Ads" />
    <p>{`It’s all very calculative, really. To ascend your characters or gain materials/artifacts, you need to complete domains or beat bosses. That’s fair but in order to claim the rewards, you need to cough up your 20 resin for the domain rewards and 40 resin for the bosses. There’s even a harder, weekly boss that you could beat up and claim their reward for 60 resin. With a max total of 160 resin, you could only claim a domain’s reward 8 times. Plus, the resin refills at a rate of 1 resin every 8 minutes. It takes 21 hours and 20 minutes for it to go from 0 to 160. The higher your character level is, the more resources it requires to reach his/her max potential. Thus, the longer it takes to farm the materials they need. Obviously, everything points to pushing you to `}<em parentName="p">{`try again`}</em>{` tomorrow, again and again… I must remind you, there are only 2 regions out so far. Since each region will most undoubtedly bring about more characters to pull, I don’t see an end to this. So many things to do, so little resin...`}</p>
    <p>{`But that’s not all they do to make sure you come back for more. There’s the City Reputation quests that let you do weekly quests or bounties. You complete them in a day and do it again the next week. And there’s even the Battle Pass, plus the dailies and inevitable new events they’ll announce! Everything in this game encourages you to come again another day and do it all over again. There are a ton of rewards for doing it so I’m sure you are thinking along the line, `}<em parentName="p">{`“This is a game. I play to have `}<strong parentName="em">{`fun`}</strong>{`. They are giving me a ton of rewards for playing, so why shouldn’t I give in?”`}</em>{`.`}</p>
    <p>{`NO! STOP! IT’S A TRAP!`}</p>
    <Image alt="TRAP" caption="This is mihoyo playing the trap card. The very moment you installed the game, you’re a goner. The only way out is to uninstall Genshin Impact." name="genshin-impact-is-a-darn-trap.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <p>{`I have played a ton of MMOs, RPGs and gacha games… And I assure you, I have never seen a game locking you in as masterfully as how Genshin Impact did it. These rewards aren’t there to make you feel rewarded! It’s to `}<strong parentName="p">{`cage you in`}</strong>{`, get you `}<em parentName="p">{`invested `}</em>{`and start the neverending feedback loop. Complete more quests, pull more banners and get more materials to ascend them! Then you start it all over again. Rinse and repeat. Dailies, Battle Pass, the slow refill rate of your resin and currently, new events! Everything is to ensure that you keep playing everyday. Sure, there’s nothing wrong with playing Genshin Impact everyday. The only thing that feels wrong here (to me) is how they make you feel `}<strong parentName="p">{`obligated`}</strong>{` to play everyday! At this rate, the game is controlling me and not the other way around (Help!).`}</p>
    <p>{`I think the resin refill rate could have been improved to make this experience much more pleasant. They could have made the fill rate much faster and it wouldn’t break the game. Honestly, that's the only thing that everyone is asking for! Other than that, I’m happy to have fallen into this sweet, sweet trap. Their ploy is so obvious but why, why, `}<strong parentName="p">{`WHY `}</strong>{`am I still playing this game?`}</p>
    <Image alt="ZHONGLI" caption="Oh. I get it now. It was to meet Zhongli. (Please ignore this caption, I’m being silly) (Actually nevermind yes I played this game for Zhongli)" name="genshin-impact-zhongli-he-is-so-sweet-and-gentlemanly-im-ded-please-google-pick-this-title-up.jpg" mdxType="Image" />
    <p>{`Remember guys, don’t let this game or really, `}<strong parentName="p">{`any game`}</strong>{` to own you. Keep that wallet of yours away from danger! Don’t get yourself locked in. Don’t feel obligated to complete the quests because you think it’s a waste not to! Now excuse me, I have flowers to pick across two regions. They should have respawned by now :D`}</p>
    <Ads mdxType="Ads" />
    <p><em parentName="p">{`Note: This is an opinion piece. I have my qualms about this game but I’m still enjoying it a lot. Check out my `}<RemoteLink to="https://www.pinterest.com/geeknabe/geeknabes-zhongli-from-genshin-impact/" mdxType="RemoteLink">{`Pinterest folder for Zhongli`}</RemoteLink>{`? :P`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      