import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const RemoteLink = makeShortcode("RemoteLink");
const Image = makeShortcode("Image");
const Ads = makeShortcode("Ads");
const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Just recently, I discovered a plethora of `}<strong parentName="p">{`Gundam Seed`}</strong>{` and `}<strong parentName="p">{`Gundam Seed Destiny`}</strong>{` music on Spotify. No, it’s not the opening and ending songs but instead, every background music that played in the series! I was so surprised that they have so many different songs. Mind you, this is not just a single album, but 8! There’s 8 of these on Spotify. 4 albums each for Seed and Seed Destiny with at least 30 songs in each album. That’s a whole lot of tracks that I don’t remember hearing…`}</p>
    <p>{`Note: `}<RemoteLink to="https://i.imgur.com/Vgblt5Q.jpg" mdxType="RemoteLink">{`Here's the link for the Gundam Unicorn art I use for this blog post's banner.`}</RemoteLink></p>
    <Image alt="Mobile Suit Gundam Seed and Mobile Suit Gundam Seed Destiny on Spotify" caption="I saved them all into my list so I can blast them whenever <3" name="spotify-mobile-suit-gundam-seed.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <p>{`Gundam Seed and Seed Destiny has a special place in my memories of the first `}<em parentName="p">{`mecha anime series`}</em>{` I watched. Second was Zoids! What I came to know about Gundam firsthand is through Seed and it’s prequel, so I'm super biased towards these two anime series. I’m aware they are not exactly good representations of what the long-time Gundam franchise entails but you can’t deny that they have great BGM and op/ed songs!`}</p>
    <p>{`As I walked along the memory lane blasting Gundam Seed & Seed Destiny’s music, I came across yet another track from the same series. This track is a full 6 minutes of undulated sound effect from the series - the opening of the hatch, the reverberating of the mobile suits taking off, the repeated siren signaling battle… Folks call it noise, I call it `}<em parentName="p">{`heavenly music`}</em>{`. Here, I managed to find a YouTube video of the track.`}</p>
    <Video title="Gundam Seed OST" src="https://www.youtube.com/embed/E-Zlg3bC0Kk" mdxType="Video" />
    <p>{`The track reminded me of what I really enjoyed and missed from the anime industry - a proper sci-fi anime with mecha/Gundam in it with lots of technological goodies to look at. In my book, a sci-fi anime with spaceships and suits need to be `}<strong parentName="p">{`serious`}</strong>{` and it has to present some fascinating ideas about the future of humanity in an era of space exploration. Two of my all-time favourites tackling this theme is Terra e… (Towards the Terra) and Banner of the Stars. Both introduced some fascinating ideas on space travel and pseudo-politics brought forth by different perspectives. Plus, spaceships! You can never say `}<strong parentName="p">{`No`}</strong>{` to interesting looking ships blasting beams or steering headlong into asteroids!`}</p>
    <Image alt="Banner of the Stars poster" caption="One of the best series out there with a bold idea - how would two different species with different upbringing get along? Also, it has SPACESHIP BATTLES!" name="banner-of-the-stars.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <Image alt="Toward the Terra poster" caption="I remember going on a anime oldie spree when I found this. I was floored on how simple the bad and good concept here, yet the plot is so gripping. I was cheering so hard for the main cast to survive and find a place where they feel at home." name="toward-the-terra.jpg" mdxType="Image" />
    <p>{`So I tried my luck with Netflix in my region just to see if they have a sci-fi anime for me to watch and voila, I found Gundam Unicorn. I was a bit worried because I could tell from the art that this does not seem to be a `}<em parentName="p">{`standalone series`}</em>{` like `}<strong parentName="p">{`Gundam 00`}</strong>{` or `}<strong parentName="p">{`Gundam Iron-Blooded Orphans`}</strong>{`. Thankfully, my fears were assuaged when I watched the first episode. Gundam Unicorn starts off with a short clip explaining the start of the UC era and how a terrorist incident made a lasting impact that could be felt in many years to come. Fast-forward to UC0096, a struggle to take hold of a mysterious object known as Laplace Box destroyed the home of Banagher Links, our MC which `}<em parentName="p">{`somehow`}</em>{` got designated as the pilot of this series’ main Gundam.`}</p>
    <p>{`I had a great time in Episode 1 to the point that I didn’t realize it’s a full 1-hour episode. There’s a ton of things I noticed which is similar to how Seed started off. I’m not sure if this is a series tradition or I’m looking at the tradition’s origin! There’s the boy who was pulled into the fray out of sheer coincidence. His character is a blank slate but eh, at least he’s good at piloting. Also, he’s a modified human (Of course he is!). Then there’s the girl who is super noble and has mastered public speaking, a pro lady pilot who stayed with her superior because she felt indebted to him, a crazy overly-zealous subordinate, an honest-down-to-earth pilot who screams death flag later on and a blonde dude with a mask on. Don’t forget to include scheming parties in the background along with civilians that came along for the ride!`}</p>
    <p>{`I find these character’s inclusion hilarious at first, because it just screams KORE WA GUNDAM DA! to me. But deep into Episode 3, I just find these characters really `}<em parentName="p">{`loveable`}</em>{`. Episode 1 and 2 is just a dip while Episode 3 is a dunk! Banagher is an earnest boy who truly believed that he should do everything he can to save the victims or stop the war. The adults familiar with warfare say `}<strong parentName="p">{`it can’t be done`}</strong>{` but Banagher insists that it can and `}<strong parentName="p">{`he should`}</strong>{`. The stark contrast between`}<em parentName="p">{` Banagher’s inexperience`}</em>{` versus `}<em parentName="p">{`experienced adults`}</em>{` is a take every anime fan is familiar with, but to see it done so well feels good to watch. Well, it’s because it wasn’t just the dialogue that `}<strong parentName="p">{`accentuated`}</strong>{` this point about their character, but their `}<em parentName="p">{`mannerism and action`}</em>{` as well.`}</p>
    <Ads mdxType="Ads" />
    <p>{`For example, you could tell Audrey (Mineva) is a person of dignity and a proper leader. I really enjoyed every scene that has this girl voicing out her thoughts because not only does her words cut straight to the chase, she stood her ground and looked the other party straight in the eye. It’s a small detail but I find it super delightful. When she replies to the other party, she wasn’t giving an emotional response. She always responds with a thoughtful reply and it screams to me that this girl `}<em parentName="p">{`knows her stuff`}</em>{`. Even when a certain pilot was dissing her, she kept her eyes on him and most importantly, `}<strong parentName="p">{`she listened`}</strong>{`. In almost every scene, she always kept eye contact with the other party and listened without interrupting. I find this a super important point to her character because it tells me she respects the other person’s view regardless of where they are coming from.`}</p>
    <p>{`I’m just at Episode 3 at the moment but these two stood out to me the most that work in tandem with Gundam Unicorn’s theme. But enough about the characters, let’s talk about the technology in Gundam Unicorn. Many think that the plot is the most important in sci-fi anime but I think otherwise. Plot is secondary, music is tertiary and the first is… SPACESHIPS. The engine noises, the effects of zero gravity, the dangers of space vacuum… Like how this blog post title implies, I’m honestly just watching Gundam Unicorn for the sci-fi sakuga. Episode 1 is full of it! `}<strong parentName="p">{`Hnnnng.`}</strong>{` I added some of the screencaps just to illustrate what I mean. I found a sakugabooru clip on Gundam Unicorn too just so we can all appreciate the action <3 Unfortunately I haven't a clue how to add it as a video so here's a `}<RemoteLink to="https://www.sakugabooru.com/post/show/5376" mdxType="RemoteLink">{`link`}</RemoteLink>{`.`}</p>
    <Image alt="Gundam Unicorn screencap 1" caption="I always love the effective use of lighting in anime. The whole flare along with the debris is a wonder to look at." name="gundam-unicorn-11.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <Image alt="Gundam Unicorn screencap 2" caption="The framing here is super nice. You see the terrorist just floating helplessly trying to grasp at empty space. Why did he do it? What is he trying to get hold of?" name="gundam-unicorn-10.jpg" mdxType="Image" />
    <Image alt="Gundam Unicorn screencap 3" caption="I’m always super happy to see creative launch pads. You could say that half of my joy in watching sci-fi anime is looking out for these contraptions on spaceships." name="gundam-unicorn-9.jpg" mdxType="Image" />
    <Image alt="Gundam Unicorn screencap 4" caption="The hand-drawn explosions in this anime is one of the best I’ve seen. They don’t burst immediately like most anime, but it took half a second for the burst to start from the center and bloom earnestly with a loud bang. They even add stray flares that burst out from the center, making it look and sound realistic." name="gundam-unicorn-8.jpg" mdxType="Image" />
    <Image alt="Gundam Unicorn screencap 5" caption="I know y’all are floating but can the students see the board?? Interesting concept!!" name="gundam-unicorn-7.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <Image alt="Gundam Unicorn screencap 6" caption="The whole scene where Audrey gets sucked out and dropped into the space colony is pretty satisfying to look at because we can see the pretty lights from the city at the bottom while she tries to balance herself midair." name="gundam-unicorn-6.jpg" mdxType="Image" />
    <Image alt="Gundam Unicorn screencap 7" caption="Boy, I’m so glad to see an elderly in a position of power. It’s been ten thousand years since I saw an old dude in an anime…" name="gundam-unicorn-5.jpg" mdxType="Image" />
    <Image alt="Gundam Unicorn screencap 8" caption="Half of my joy is enjoying the setting of sci-fi anime, the other is looking at dashboards or screens that made sense! The design of the dashboard is great!" name="gundam-unicorn-4.jpg" mdxType="Image" />
    <Image alt="Gundam Unicorn screencap 9" caption="The whole chopping and slicing mobile suits is soooo satisfying. For once you see the edges of the mobile suit all scorched and not just conveniently exploding because the MC needs to move on to the next victim quickly." name="gundam-unicorn-3.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <Image alt="Gundam Unicorn screencap 10" caption="The small deadly beams/beamers(?) (LOL) being sent out and returning is the absolute best! They even mark them properly!" name="gundam-unicorn-2.jpg" mdxType="Image" />
    <Image alt="Gundam Unicorn screencap 11" caption="The whole startup sequence is nice, but the moment that horn turns into the iconic V, I went ballistic! That’s really smart and sneaky to hide in plain sight!" name="gundam-unicorn-1.jpg" mdxType="Image" />
    <h2>{`All in all`}</h2>
    <p>{`Phew. I think I might have gone a little crazy when collecting the screenshots. Gundam Unicorn is a fantastic anime series that really pushed what sci-fi can do on-screen. The plot is easy enough to understand even without needing to learn what happens in the OG series. I did need to look up some of the terms they were throwing around, but the whole Laplace Box is pretty straightforward so far. It’s a great show and I can’t wait to see what happens next. I’m still at Episode 3! I think I should check out the OG series after this. I was convinced to watch the original Gundam after seeing the meme below first, before watching Gundam Unicorn.`}</p>
    <Image alt="Gundam Char meme" caption="Oof, brutal." name="gundam-char-meme.jpg" mdxType="Image" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      