import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const LocalLink = makeShortcode("LocalLink");
const Ads = makeShortcode("Ads");
const RemoteLink = makeShortcode("RemoteLink");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Continuing from my previous post on `}<LocalLink to="/blog/19-genshin-impact-piano-cover-tracks-by-chewie-melodies/" mdxType="LocalLink">{`Genshin Impact piano music recommendation`}</LocalLink>{`, I wanted to continue introducing even more video game music that I really enjoyed on Spotify. Woo! Let’s go! I enjoy JRPG a lot so there’s a ton of JRPG music here ranging from super chill piano tunes to orchestra music. The list is not ordered :) I will add the album title in case you wish to search them up on YouTube. Enjoy!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Great Video Game Music Playlist on Spotify #1: Song for Tales of the Abyss Motoo Fujiwara`}</h2>
    <p>{`I honestly didn’t expect to see music from `}<RemoteLink to="https://open.spotify.com/album/7gT7YyLI1oMM3QrxVxuXsC" mdxType="RemoteLink">{`Tales of Abyss on Spotify`}</RemoteLink>{`, since it’s such an old and niche game! There are only a few tracks in this album, and they are all the popular tracks in the game. I was expecting a full blast OST but I guess it’ll do. I really love the track that has`}<RemoteLink to="https://open.spotify.com/track/18VIlRWrQueObE0JH5Th8G" mdxType="RemoteLink">{` Tear singing the full Grand Fonic Hymn`}</RemoteLink>{`. Then there’s the battle track that plays during `}<RemoteLink to="https://open.spotify.com/track/4u7dre9wK4ZMQgvj98kpnJ" mdxType="RemoteLink">{`Luke vs Asch`}</RemoteLink>{`!`}</p>
    <Image alt="Song for Tales of the Abyss Motoo Fujiwara" caption="This.... This is either the most simplest album cover I've seen or the graphic artist guy just couldn't be bothered." name="song-for-tales-of-abyss-motoo-fujiwara-cover.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`Great Video Game Music Playlist on Spotify #2: Bastion Original Soundtrack`}</h2>
    <p>{`Bastion is such a `}<RemoteLink to="https://open.spotify.com/album/2r4a3PREYIRF2QdbcPnrEO" mdxType="RemoteLink">{`great indie game`}</RemoteLink>{` that I found out a few months ago. It was on sale on Nintendo Switch’s eshop when I noticed the game. My friends recommended the game, saying it’s a great one-off purchase so eh, why not? It turns out I really like the whole story and battles. It’s fun while it lasted. Bit short but I’m okay with that, given the price tag! The narrator’s voice is really deep and soothing in the story cutscenes. There are a few really great songs I really enjoyed like`}</p>
    <RemoteLink to="https://open.spotify.com/track/5DutiJxznQmcV5a5a1zfRW" mdxType="RemoteLink">
  Zia’s theme
    </RemoteLink> and
    <RemoteLink to="https://open.spotify.com/track/2yvrSikYS1UsZhhfmXHrar?si=12z-JJpZQUG44tzOS_f0Fg" mdxType="RemoteLink">
  {' '}
  Zulf’s theme
    </RemoteLink>
.{' '}
    <Image alt="Bastion OST Cover" caption="I highly recommend this indie game to folks who love action role-playing games. It's simple, but oh darn it's so good. The OST be bangin'!" name="bastion-original-soundtrack-cd-cover.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`Great Video Game Music Playlist on Spotify #3: Final Fantasy XIII Original Soundtrack`}</h2>
    <p>{`Many dismissed the game for being too linear, Vanille for her silly way of running and the cheesy J-drama plot. I agree with some of these points but you must admit, the music is `}<em parentName="p">{`great`}</em>{`. You see, Nobuo Uematsu’s music is iconic but I find `}<RemoteLink to="https://open.spotify.com/album/5GkUPRHDvq179CmE9zXh4g" mdxType="RemoteLink">{`Masashi Hamauzu’s beats`}</RemoteLink>{` are more polished. Different composers, different styles, yea? To each of their own! My personal favourites are `}<RemoteLink to="https://open.spotify.com/track/0WIAZIziAzAC52iyuZELn1" mdxType="RemoteLink">{`Blinded by Light`}</RemoteLink>{` and `}<RemoteLink to="https://open.spotify.com/track/4YJbgM3nIeqnqgiL4gXx4P" mdxType="RemoteLink">{`Nautilus`}</RemoteLink>{`. Each track is great if you listen to them with context. I really enjoyed Nautilus’s track as I ran around in the city. As for Blinded by Light, it’s perfect as a battle theme!`}</p>
    <Image alt="Final Fantasy XIII Soundtrack cover" caption="I think Lightning is really iconic as FF13's spokeperson. Alright, incoming short excerpt about the game. FF13 has a really polished UI and music, along with a fun battle system. Then came along FF13-2. For a rush job, I think it's not too shabby. Obviously it could stand to get more polish but yea, I'll take it. I have yet to play FF13-3 yet but here's hoping I will someday." name="final-fantasy-xiii-soundtrack-art-cover.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`Great Video Game Music Playlist on Spotify #4: Songs from Final Fantasy XV`}</h2>
    <p>{`The addition of pop songs is a wonderful move by Square Enix folks. I believe they started the trend from Final Fantasy XIII onwards? I hope the new Final Fantasy XVI has one too, despite it looking gritty and similar to Final Fantasy XII… Okay, maybe pop songs will feel out of place. My personal favourite in this album is the song `}<RemoteLink to="https://open.spotify.com/track/5XSU59wtE5CRCAEyHmmGy4" mdxType="RemoteLink">{`Stand By Me`}</RemoteLink>{`. It just matches the whole mood of Final Fantasy XV so well! `}<RemoteLink to="https://open.spotify.com/album/3G5GOLjvWUBRIrRp3GKnXO" mdxType="RemoteLink">{`Florence + The Machine`}</RemoteLink>{` has other splendid songs, not just Stand By Me alone on their own Spotify page. Please do give them a try!`}</p>
    <Image alt="Songs from Final Fantasy XV" caption="Stand By Me is just *chef kiss* perfection." name="songs-from-final-fantasy-xv-florence-the-machine.png" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`Great Video Game Music Playlist on Spotify #5: Sid Meier’s Civilization VI (Original Game Soundtrack)`}</h2>
    <p>{`How on earth did this video game music recommendation post go from JRPG to a strategy game? Good soundtracks, that’s what! I tried CIV on Nintendo Switch and I find it a wonderful slow burn. Except I could never win a single game. Science victory is easy but these darn barbarians are always in the way! Maybe I just suck in general... My personal favourite track is `}<RemoteLink to="https://open.spotify.com/track/4Jt5UkcyYL261tokw058x0" mdxType="RemoteLink">{`England (The Medieval Era)`}</RemoteLink>{` because it’s a wonderful rendition of the famous Scarborough’s Fair. Listen to `}<RemoteLink to="https://open.spotify.com/album/6ERIx2c5kFBBFj6CEI7BWd" mdxType="RemoteLink">{`Sid Meier's Civilization VI album`}</RemoteLink>{` on Spotify! It's a wonderful album to play in the background while you work.`}</p>
    <Image alt="Sid Meier Civilization VI Original Soundtrack" caption="Please give this game a try on IOS or Android. I believe the game has a free trial on these platforms." name="sid-meier-civilization-vi-original-soundtrack-cover.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`All in all`}</h2>
    <p>{`Enjoy these VGM recommendations!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      