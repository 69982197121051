import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Tales of Berseria is the very reason I installed Steam on my PC and oh boy, `}<strong parentName="p">{`I have no regrets`}</strong>{`. I wasn’t going to go anywhere near Steam at all (I have heard stories of how Steam sales just suck your money like a blackhole!) but I saw a Twitter mutual posted her `}<strong parentName="p">{`Tales of Berseria`}</strong>{` progress. Everything just went poof afterwards. Before I knew it, I had Tales of Berseria installed on my PC, ready to go. I was itching for a good ol’ JRPG to play. Plus, you can never go wrong with a Tales game!`}</p>
    <p><em parentName="p">{`Note: I have not finished the game (13 hours in). I try to write my thoughts/reviews after I’m done with the game but that never works out. I’ll just jump to the next game soon after! Game review sites should never hire me.`}</em></p>
    <p><em parentName="p">{`Second Note: Major spoilers!`}</em></p>
    <Ads mdxType="Ads" />
    <p>{`There’s a lot to talk about with regards to Tales of Berseria but there’s only one topic I wish to touch upon in this blog post - storytelling. Aye, the very basis of what makes JRPG really addictive to me… `}<strong parentName="p">{`The story.`}</strong>{` Or rather, how they tell `}<strong parentName="p">{`the story`}</strong>{`. Berseria features an uncanny plot when compared to the rest of the main series. Most Tales games start off simple, but they are mostly talking about doing (whatever it needs to be done) for the greater good. It’s an easy-to-understand approach. It allows the player to believe they are truly the shining, leading example for the fantasy world. I mean, you can’t go wrong with the story if defeating a big baddie is the ultimate goal!`}</p>
    <Image alt="Tales of Berseria logo" caption="Seeing Velvet walking and it seamlessly turns into the game’s logo is so mesmerizing." name="tales-of-berseria-first-impression-10.jpg" mdxType="Image" />
    <p>{`Tales of Berseria (ToB), however, made it `}<strong parentName="p">{`personal.`}</strong>{` There’s no big baddie that threatens the world (Maybe there's a big baddie since I’m only 13 hours in) and our main cast isn’t all good at heart. ToB features Velvet Crowe, a girl-turned-daemon who wants nothing but vengeance against the one who killed her brother Laphicet. To be fair, I’ve seen this plotline before in Xenoblade Chronicles 1 (XC1) but the mood in XC1 is heavier and darker. In ToB, they did a great job of balancing what’s pushing Velvet to do this and showing us the inevitable consequences of her actions. Is her vengeance truly necessary? Must she push on with it? While the story/mood in XC1 is clearly driven by Shulk’s `}<strong parentName="p">{`anger`}</strong>{`, ToB’s is showing us that Velvet is doing this out of `}<strong parentName="p">{`guilt.`}</strong>{` She feels guilty that Laphicet did so much for her and yet, she did nothing for him as she watches his life expire.`}</p>
    <Ads mdxType="Ads" />
    <p>{`There were many instances where the cast would ask if she’s willing to abandon her revenge scheme to live instead. Yet her answer remained the same. As the player, I feel the right thing to do would be to abandon her fruitless plan. But the story made it really easy to sympathize with the Crowe sibling’s circumstances. The whole backstory on Velvet is heartbreaking, to say the least. Their parents are gone and they had to rely on their bigger sister, Celica. Afterwards, even Celica is gone. Velvet and Laphicet could only rely on their brother-in-law, Artorius to survive. Then, Artorius murdered Laphicet right in front of Velvet. With these turn of events, who could blame the poor girl for wanting to pursue revenge?`}</p>
    <p>{`While the goal in this story is revenge, the writing looks elsewhere to develop Velvet’s character along with her bittersweet relationship with the malak she named Laphicet. If the writers want me to weep like a baby after reading Velvet’s and Malak-Laphicet’s dialogue, then they are doing a great job. Velvet’s thoughts aren’t always about revenge. We see her have doubts as she walked around town, realizing how her brother’s sacrifice created a paradise for mankind. There’s even a scene where she has to complete an assassination job. She says she is ready to kill, but you could easily read between the lines/skits that tells you… `}<em parentName="p">{`she is not. `}</em>{`Then came along the malak who looks and even enjoys the same thing as her brother. Blimey, my heart hurts... When Velvet named the malak as Laphicet, she’s clearly projecting him to be the brother that she couldn’t save. There were a ton of skits about her teaching him how to live, how to think for himself. She’s clearly treating him like a brother. It was really heartwarming to see but once the reason behind it kicks in, you just can’t help but feel sad for Velvet.`}</p>
    <p>{`All of these little nuances and details in Tales of Berseria couldn’t have done it’s job if not for the brilliant dialogue, voice acting and the meticulously done cutscenes. This is clearly a JRPG, but it doesn’t `}<em parentName="p">{`feel`}</em>{` like one. Yes, I know I’m contradicting myself so let me explain. The most recent JRPG I completed before ToB is Trails of Cold Steel III (ToC3). That series prides itself with their utterly long dialogues and light novel-ish writing. Now, that is one JRPG that feels like an anime. They have a ton of cliches that you could find in any fantasy anime series. The English translation too, is 100% true to the Japanese meaning. If the voice actor says`}<em parentName="p">{` “I’m going to kill you!”`}</em>{`, then all I could hear was `}<em parentName="p">{`“Omae wa korosu!”`}</em>{`. Trails of Cold Steel III feels like an `}<em parentName="p">{`anime`}</em>{` even with the English dialogue. It’s hilarious in a way because my brain is doing the auto-translation on its own whenever they start talking. Nevertheless, ToC3 is a game that has done their cliches so well. I really love Cold Steel for what it is.`}</p>
    <Ads mdxType="Ads" />
    <p>{`ToC3 is a thing I expect most JRPG to be but ToB on the other hand, feels super different. The dialogue is given their own localization flair. The dialogue changes aren’t overbearing or cringey. In fact, it feels right at home. Voice acting too, sounds really natural. Velvet’s English voice actor did a great job with switching her tone to suit her character’s new circumstances. You can choose the English or Japanese voice acting everytime you boot the game. I always choose English because it’s that good! There were scenes where the literal translation wouldn’t have made much impact but the translation team managed to do it nevertheless.`}</p>
    <p>{`All of these changes made ToB such a great experience on its own. I think if I play the Japanese one, I’ll experience a totally different game altogether. It did come to me that the translation work is easier since the writing seems to be aiming at young adults/older teens. Most of the cast talk business a lot and feelings less which gives less room for cringey talk (If you watch anime, you know what I’m talking about). These folks (In their 20s or later) already knew what they wanted in life after all. It also helps that the cast are all in this together because of their own selfish reasons. I literally battled a team member and he just joined back the team afterwards with no hard feelings. Not even an apology (lol) was given. I love how everything is just business as usual. Everyone in the team practices professionalism.`}</p>
    <h2>{`All in all`}</h2>
    <p>{`Once all is said and done, Tales of Berseria is still a story about how Velvet would do anything to kill Artorius. The story has been throwing a ton of shade, about how `}<strong parentName="p">{`NOT`}</strong>{` killing Artorius could be the right thing and how every good in this world is not what it seems. As I continue playing this game, I could feel myself being conflicted too. Will Velvet push on with her vengeance or will she stop after discovering some hidden secret of their own world? Hey, it’s every Tales game plot. I’m sure there’s a secret that involves a final boss somewhere down the line. That said, I have zero idea on how they’ll push this story forward. Well, only one thing to do. Time to continue playing this game! I added my favourite screenshots from the game below!`}</p>
    <Image alt="Tales of Berseria 1" caption="This is Laphicet. Look how sweet he is, smiling at his own big sister ;)" name="tales-of-berseria-first-impression-1.jpg" mdxType="Image" />
    <Image alt="Tales of Berseria 2" caption="Before Artorius did the heinous deed, he keep saying these stupid lines. Like, you still murdered a family member?! Greater good my ass." name="tales-of-berseria-first-impression-2.jpg" mdxType="Image" />
    <Image alt="Tales of Berseria 3" caption="ufotable animated the cutscenes. It’s silky smooth animation with some great effects <3" name="tales-of-berseria-first-impression-3.jpg" mdxType="Image" />
    <Image alt="Tales of Berseria 4" caption="They keep throwing these scenes at me. I don’t know how to process it other than to cry like a baby. This happened after Laphicet dies. Velvet won’t stop having these nightmares about him being alive and leaving her." name="tales-of-berseria-first-impression-4.jpg" mdxType="Image" />
    <Image alt="Tales of Berseria 5" caption="She smiled for the first time in 3 years. Bless this image." name="tales-of-berseria-first-impression-5.jpg" mdxType="Image" />
    <Image alt="Tales of Berseria 6" caption="This is Magilou. Her skirt made out of books is held by a magical belt. (This is me just assuming things, I have no idea how her skirt works)" name="tales-of-berseria-first-impression-6.jpg" mdxType="Image" />
    <Image alt="Tales of Berseria 7" caption="I love how the cast won’t stop bullying Magilou. Hehe, silly witch." name="tales-of-berseria-first-impression-7.jpg" mdxType="Image" />
    <Image alt="Tales of Berseria 8" caption="They were caught by the guards as they were trying to enter the city. Magilou forced Velvet to play a pigeon as a performance for the guards." name="tales-of-berseria-first-impression-8.jpg" mdxType="Image" />
    <Image alt="Tales of Berseria 9" caption="This is her blushing with embarrassment afterwards XD" name="tales-of-berseria-first-impression-9.jpg" mdxType="Image" />
    <Image alt="Tales of Berseria 10" caption="Poor Malak-Laphicet. This is the scene where he realized that Velvet named him after her brother. Velvet confesses that she feels hopeless because she couldn’t do anything for her brother before he dies. My headcanon is that Malak-Laphicet isn’t crying because of her brother’s misfortune. He was empathizing with Velvet’s pain." name="tales-of-berseria-first-impression-11.jpg" mdxType="Image" />
    <Image alt="Tales of Berseria 11" caption="Awwwww he’s reading." name="tales-of-berseria-first-impression-12.jpg" mdxType="Image" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      