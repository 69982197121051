import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXFanfiction/Chapter/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Divider = makeShortcode("Divider");
const MessageBox = makeShortcode("MessageBox");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Reus tackled Dimitri to the ground and straddling him, began throwing punches at his face. Byleth swore she saw murder in her brother's eyes and would've strangled the poor blond if she didn't intervene. And the worst part is; Dimitri would’ve let him.`}</p>
    <p>{`“Stop!” Byleth attempted prying them apart, her heart breaking further with every hit to Dimitri’s beautiful face. But to no avail.`}</p>
    <p>{`"Dimitri, you bastard! You `}<em parentName="p">{`promised`}</em>{`!" Reus pushed Byleth away when she was close to separating them. He was livid; mad with bloodlust, and it didn’t seem like he would see reason.`}</p>
    <p>{`"Reus, stop!" she pleaded but her brother continued to ignore her. She cursed her stupidly tight and short dress and put all her strength into yanking Reus off Dimitri. In all her years, Byleth never raised her voice at anyone, let alone her twin brother, despite their countless arguments as children. They were probably one of the rare opposite gendered twins who fought silently with each other. But here she was, screaming till her voice cracked.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“`}<em parentName="p">{`Stop it!`}</em>{`” Byleth was finally able to stand between the two, over Dimitri sprawled in a bloody mess. “What the `}<em parentName="p">{`hell`}</em>{` is wrong with you!?” She refused to budge even when Reus tried to push her aside again. Reus glowered at her when she proved stubborn.`}</p>
    <p>{`“Move.”`}</p>
    <p>{`“Byleth, I deserve this,” Dimitri said weakly while attempting to get up.`}</p>
    <p>{`“No,” she answered coldly to them both.`}</p>
    <p>{`“I’m your older brother.” It was more of a warning than anything.`}</p>
    <p>{`Byleth scoffed, “You’re a grand minute older than me so don’t start acting like you’re my big brother now.”`}</p>
    <p>{`“`}<em parentName="p">{`By,`}</em>{`” Reus said through gritted teeth, “he promised he wouldn’t compromise your virtue.”`}</p>
    <p>{`“`}<em parentName="p">{`Virtue`}</em>{`? You make it sound as if he touched me inappropriately when all he did was kiss me!” She threw her hands in the air. “Why did you even agree to this date if you’re so ‘concerned’ right now!”`}</p>
    <p>{`“I-I… didn’t think he’d have the guts to actually kiss you,” Reus finally said in a softer voice. But his voice turned cold again. More disappointed than angry. “And a broken promise is a broken promise.”`}</p>
    <p>{`Before she could throw another retort, Reus grabbed her arm and started pulling her away as she struggled. He turned around and glared at Dimitri.`}</p>
    <p>{`“I swear, if you ever go near her after this, I consider any trust between us over.”`}</p>
    <p>{`“Reus, it wasn’t his fault,” Byleth snapped as she snatched her arm back, “`}<em parentName="p">{`I`}</em>{` led him on and If `}<em parentName="p">{`I`}</em>{` didn’t like it, `}<em parentName="p">{`I`}</em>{` could’ve defended myself. You know that damn well.”`}</p>
    <p>{`“Just— `}<em parentName="p">{`stop`}</em>{` talking.” Reus covered her mouth, then addressed Dimitri. “If you can’t keep a simple promise like this, how can I trust you to keep it in your pants next time? `}<em parentName="p">{`Ow`}</em>{`!” Reus winced as Byleth twisted his arm behind his back in one fell swoop.`}</p>
    <p>{`“Don’t `}<em parentName="p">{`ever`}</em>{` shut me up like that again,” she hissed in his ear then released him.`}</p>
    <p>{`Byleth took a deep breath to calm down. Her dress was a mess. It had a rip going up one of her thighs. She straightened it the best as she could and picked up the ruined rose bouquet on the ground. The teddy bear that had sat in the center was nowhere to be seen.`}</p>
    <p>{`“I’m sorry this happened, Dimitri.” She looked at how her brother had almost destroyed Dimitri’s face. Drying blood stained his lips, his right eye swollen shut and his clothes were in disarray. Her heart ached. It hurt even more that Dimitri, Captain of the Weapons Club, didn’t even retaliate to defend himself. Part of her was grateful, otherwise she would have to tend to two physically injured boys instead of one bruised ego (Reus). Goddess knew how she was going to explain all this when it came to.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“I-I did have a lovely evening,” she added, hoping it’d ease Dimitri’s pain somehow. The guilt on Dimitri’s face tore her apart and punctured a hole in her chest.`}</p>
    <p>{`“Thank you, but I… I deserved that. I am truly, truly sorry… Reus.” Dimitri flinched as he spoke, and gently wiped his blood-stained chin. “Regardless, the fault is mine and mine alone. If you do not wish to resume our friendship, I completely understand. I did break a promise, after all.”`}</p>
    <p>{`“Just get out of here,” Reus spat. Byleth curled her hands into a fist. Despite his childish views, she could see his side of the argument. However stupid the promise was.`}</p>
    <p>{`Byleth couldn’t bear seeing Dimitri wince when he sighed, or when he clutched his ribs. If she relented now, her body would probably run to him. Instead she turned to her brother with the hardest scowl she could manage.`}</p>
    <p>{`“Look what you’ve done.” Reus did at least look guilty. “Was that `}<em parentName="p">{`really`}</em>{` necessary?”`}</p>
    <p>{`Silence.`}</p>
    <p>{`And silence was all that hung between the Eisner twins on the way back to the hotel. And to her horror, Reus said the words she dreaded to hear,`}</p>
    <p>{`“I’ll take over your investigation. You don’t have to go back there anymore. ”`}</p>
    <p>{`She steeled herself. There had been an inkling at the back of her mind—that perhaps out of blind fury Reus would disallow her from returning to Garreg Mach. She didn’t expect him to actually say it.`}</p>
    <p>{`And it wasn’t fair now that he did.`}</p>
    <p>{`“Reus.” Byleth took a deep breath to steady her voice and the rare anger rising in her head. `}<em parentName="p">{`Please listen to reason`}</em>{`, she prayed to whichever god or goddess that cared to listen, and spoke. “I’ve been doing fine over the past week on my own. I’m 18 years old and I can kiss whoever I want— and `}<em parentName="p">{`you`}</em>{` consented to this!”`}</p>
    <p>{`Reus kept his gaze on her but stayed silent.`}</p>
    <p>{`“Also, how could one harmless kiss throw you off like that? You should’ve handled that much better. Or did being expelled not teach you that?” Her lips pursed at the last moment. She shouldn’t have said that.`}</p>
    <p>{`Reus let out a groan and started stripping to return the clothes. His way of saying sorry, she guessed.`}</p>
    <p>{`“I’m still pissed at Dimitri so don’t go forgiving him as me, or talk to him like nothing happened.” Byleth nodded silently, relieved and glad. She wasn’t about to look the gift horse in the mouth, so to speak.`}</p>
    <Ads mdxType="Ads" />
    <p>{`It was almost 10pm by the time she was done, which meant she had to run like the wind to catch the last bus back to school.`}</p>
    <p>{`The wind was cool against her skin, but it wasn’t enough to soothe her anger and disappointment. Instead, she repeated the tender moment she shared with Dimitri in her head and the thought of him calmed her down somewhat.`}</p>
    <p>{`Until she saw the battered up Dimitri slouched on a bench with his head leaning against the bus stop pole. Byleth felt like she’d just been slapped. Despite all Reus had done to inflict physical and emotional pain on Dimitri, he could’ve—should’ve taken the earlier bus. But here he was. Sitting on a cold steel bench, waiting for Reus.`}</p>
    <p>{`He seemed stunned when he saw her, perhaps finally seeing through her disguise when their eyes met. However he lowered his gaze when he realized she was not ‘Byleth’, but Reus. He looked as if he was about to say something but closed his mouth and looked at the ground.`}</p>
    <p>{`The bus came soon after, breaking the awkward silence between them. Dimitri sat a couple of seats away from her, probably not wanting to anger ‘Reus’ further, even though Dimitri was perfectly capable of handing Reus’ ass back to him.`}</p>
    <p>{`As expected, their entire dorm was still awake when they walked through the door. All were appropriately shocked when they saw the state of Dimitri’s face. Ashe nearly had to pick his jaw off the ground.`}</p>
    <p>{`“What `}<em parentName="p">{`the hell`}</em>{` happened?” Felix spat.`}</p>
    <p>{`“Just who could’ve done this to `}<em parentName="p">{`you`}</em>{` man?” Sylvain found his voice and looked at her. “Reus, what happened dude?”`}</p>
    <p>{`She closed her eyes. “He kissed my sister.”`}</p>
    <p>{`Sylvain gnawed on his knuckles while holding on to Felix’s shoulder for support, and for once, Felix did not brush him off.`}</p>
    <p>{`“Wait...” Sylvain walked towards them and pointed at her, then at Dimitri. “`}<em parentName="p">{`You`}</em>{` did this?” She said nothing, but apparently her vacant expression was enough of an answer for him. “`}<em parentName="p">{`Damn son!`}</em>{` I have no idea whether to be proud of you for `}<em parentName="p">{`finally`}</em>{` losing your first kiss, or to feel bad for you for being reduced to… this,” Sylvain shrugged and Ashe squeezed passed him with a first aid box.`}</p>
    <p>{`Byleth could feel Dedue tower over her from behind. If looks could kill, he probably had it. She gulped but stood her ground. Never had she seen anyone this silently furious. Not even Reus.`}</p>
    <p>{`Dimitri pushed Ashe’s hand away, thanking him. He stood up from his bed wincing slightly, his eye barely able to open. “Dedue please, I am the one who was at fault.” The darker skinned male didn’t budge. “I… made a promise, but I broke it.”`}</p>
    <p>{`The room fell silent. Dimitri picked up his shower kit and clothes and left the room.`}</p>
    <p>{`“I feel kinda bad.” Sylvain ran a hand through his hair with a slight frown. “I didn’t think you’d
actually take it so seriously, Reus.” Was that a hint of disdain coming from Sylvain?`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Not many people can land a punch, let alone injure the boar,” Felix added suspiciously as he walked back to his bed. She wished she could just tell them how Dimitri barely defended himself.`}</p>
    <p>{`“I cannot believe that to be the truth,” Dedue spoke finally, still unmoving, but crossed his arms, “For the entire duration since we’ve been here, Dimitri has never been injured this badly.” Dedue’s heavy, pale eyes weighed on her like a pile of bricks. “Was it necessary to use so much force on a man who was holding back?” Byleth shivered at his words. It seemed he knew Dimitri well enough to guess what had happened.`}</p>
    <p>{`She had to harden her heart. She was Reus now. “The fact that he made a promise and broke it remains unchanged.” Also taking a change of clothes, Byleth walked out.`}</p>
    <p>{`When she returned, Byleth couldn’t help but notice their chatter died down when she walked in. It relieved her to see Dimitri let Ashe treat his wounds though, however tense the air was.`}</p>
    <p>{`Perhaps she should’ve taken up her brother’s offer after all.`}</p>
    <Divider mdxType="Divider" />
    <p>{`Breakfast was barely the same. It’s only been a week with the boys, and just when she was finally getting comfortable, they’ve turned cold towards her. Ashe was still cordial, but even though Sylvain was talking to her somewhat, he didn’t initiate any conversions with her nor did he have that ‘chirp’ in his voice. Needless to say, Dedue and Felix were down right glacial. It was assuring to see Dimitri’s friends have his back, but not when they had their backs turned to her. Were boys also this petty? Didn’t they duke it out with each other and then call it a day? Her head hurt and she was eating less than usual and it got worse when people kept staring at Dimitri. Even Claude pulled up a chair next to him.`}</p>
    <p>{`“I heard through the grapevine you kissed a girl and then came home looking like this.” Dimitri choked, sputtering food across the table. “Relax buddy, we’ve all been there.” Claude patted Dimitri’s back.`}</p>
    <p>{`“We have?” Ashe looked confused.`}</p>
    <p>{`“We have.” Claude nodded solemnly at him. “I suspect it was an appropriately jealous boyfriend. But, you’re not Sylvain.” Claude grinned at the aforementioned person.`}</p>
    <p>{`“Damn right he isn’t.” Sylvain seemed proud for some reason.`}</p>
    <p>{`“You’re not the type who’d go after people’s things. So, I’d think it was an overly-protective family member.” Dimitri froze. “Unless she was underaged, then you deserve whatever this is.” He gestured at Dimitri’s face.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“`}<em parentName="p">{`Claude`}</em>{`.” Dimitri eyed the brown haired male.`}</p>
    <p>{`“Alright, alright.” Claude was about to drag his chair back when Lorenz showed at their table.`}</p>
    <p>{`“Ah, Dimitri.” Lorenz laid a hand on Dimitri’s shoulder.`}</p>
    <p>{`Dimitri stood up to greet the purple haired male. “Lorenz. How may I help you?”`}</p>
    <p>{`Lorenz waved his hand. “If I may be so bold to offer you `}<em parentName="p">{`my`}</em>{` assistance.” Dimitri looked puzzled, while Sylvain and Claude scooted closer to each other, anticipating the scene that would unfold before them. The only thing lacking was popcorn.`}</p>
    <p>{`“Ugh, here we go,” Felix groaned, seemingly aware of what’s to come.`}</p>
    <p>{`“I hear, Dimitri, that you are having lady problems.” Dimitri’s face fell. Byleth was shocked that news travelled `}<em parentName="p">{`this`}</em>{` fast. And here she thought girls to be the main patrons of gossip. Boys, as it appeared, would give the female gender a run for their money.`}</p>
    <p>{`“Lorenz, thank you for your—”`}</p>
    <p>{`“Ah, ah, ah—!” Lorenz waggled a finger in Dimitri’s face. “I will not take ‘no’ for an answer. I will meet you after school, and I, Lorenz Hellman Gloucester, shall teach you my infallible ways of charming a woman.” His chest puffed up smugly. Was that a rose on his blazer? Was that even allowed?`}</p>
    <p>{`A loud groan erupted from Sylvain. “Unlike `}<em parentName="p">{`you,`}</em>{` Lorenz Hellman Glouscester,” he spat, “Homeboy here has `}<em parentName="p">{`actually`}</em>{` kissed a girl. He doesn’t need your nasty-ass techniques. Begone thot.”`}</p>
    <p>{`Claude’s shoulders shook from holding in his laughter, in fact Ashe spat out the juice he was unfortunately drinking.`}</p>
    <p>{`Lorenz’s face scrunched up. “What on earth is a `}<em parentName="p">{`thought`}</em>{`?”`}</p>
    <p>{`“T-h-o-t,” Claude spelled it out. “It means uh, a very classy person who is amazing with the ladies.”`}</p>
    <p>{`“Well, if I assume correctly, Sylvain,” Lorenz placed a hand on his chin, “Does this mean that you acknowledge my being superior to you?” Even Byleth was silently giggling, however dejected she was.`}</p>
    <p>{`Sylvain was shaking in his seat trying his hardest not to burst out laughing at Lorenz, and struggled to even answer.  “Yes,” he finally wheezed.`}</p>
    <p>{`Lorenz, pleased with his supposed victory over Sylvain, strode away with his nose in the air. Byleth had never seen anyone look so pleased with himself. Sylvain and Claude clutched onto each other as they cried literal tears of laughter into their arms and gasped for air.`}</p>
    <p>{`Dimitri, too, had no idea what the word meant but at least had the common sense to google it. His expression changed from confusion to horror when he realized what Claude and Sylvain had done. Dimitri stood up and called after Lorenz, but Sylvain latched onto Dimitri  before he could do anything.`}</p>
    <p>{`“Unhand me, Sylvain!” Dimitri winced from the bruises on his body when he tried to forcefully remove the red-head from his waist—somehow still possessing his monstrous strength that even Claude had to cling onto him to stop him.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Dimitri. Trust. Me. This. Will. Be. Good—!” Claude grunted as he held on to Dimitri who almost dragged them. “Please…! Dimitri…! He’s been so smug in my face I need this…!” Sylvain also pleaded.`}</p>
    <p>{`With his injury from the night before hurting, Dimitri finally gave in and stopped struggling. He huffed. “Have you no honour, Claude?”`}</p>
    <p>{`“Felix doesn’t call you boar for nothing! And why yes—I sold the last of my honour for pizza last night. So good. No regrets.”`}</p>
    <p>{`At least the tension was somewhat lifted, even though she wasn’t part of the fun. She quietly gathered her tray and left before she could ruin the mood. It would be awkward at the lockers if they met there. She sensed that she, as Reus, was causing him pain and didn’t want to hurt him any longer.`}</p>
    <Divider mdxType="Divider" />
    <p>{`By lunch everyone was teasing the heck out of an oblivious Lorenz, mercifully diverting the attention away from Dimitri’s tragic love story. Apparently Lorenz had changed ALL his social media profiles to include ‘Proud THOT”. Technology was as amazing as it was terrifying.`}</p>
    <p>{`“It seems everyone is acknowledging my prowess,” Lorenz said haughtily in between classes, eagerly checking his phone before the next teacher came in and seemingly pleased with all the comments he was getting from everyone.`}</p>
    <p>{`“Lorenz, do you even know what ‘thot’ means?” Linhardt who sat next to Lorenz, awoke from his incessant bragging.`}</p>
    <p>{`“Why, it merely means I am someone of class and charm. Really Linhardt, I am disappointed you did not know.”`}</p>
    <p>{`“It means you’re a slut.”`}</p>
    <p>{`The class fell silent at Linhardt’s mincing words. Byleth’s eyes widened as she watched realization set in Lorenz’s face. Sylvain and Claude looked scandalized that their plan was cut short too soon. Lorenz spun around to gasp dramatically at the two culprits but his vision was blocked by Dimitri attempting to apologize for not stopping them.`}</p>
    <p>{`“I am sincerely sorry, Lorenz. I would love to take you up on your offer from this morning. That is, if you are still willing?” Byleth felt her heart squeeze, it was no fault of Dimitri’s at all, yet here he was taking the fall and even consoling Lorenz despite his wounded heart and being.`}</p>
    <p>{`I... see no harm in doing so. I might as well live up to my new status.” He turned to Sylvain, “I will permit you to join us, Sylvain. As according to you, I am the `}<em parentName="p">{`superior`}</em>{` thot. You might even learn something from me. Consider yourself fortunate.”`}</p>
    <p>{`Sylvain groaned into his palms while Claude smirked.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Judging from the bits of conversations she picked up, now that she wasn’t really interacting with the group anymore, Dimitri (thankfully) did not pick up any of Lorenz’s coaching as Lorenz and Sylvain challenged each other to see who could pick-up the most girls. The score was a firm 0-0. But according to Sylvain, it did take Dimitri’s mind off what happened.`}</p>
    <p>{`Byleth decided to toss all care out the door and text Dimitri. Although it was to soothe `}<em parentName="p">{`her own`}</em>{` heart more than anything;`}</p>
    <MessageBox totalUnread={0} contactNumber="Dimitri" items={[{
      timestamp: {
        day: 'Wednesday',
        time: '07:45PM'
      },
      right: '<p>Hey Dimitri, how are you doing? Are your wounds ok?</p>'
    }, {
      left: '<p>Good Evening Byleth. Thank you for your message and I am sorry to have worried you like this. Thankfully, I am alright. It is nothing I cannot handle, please do not concern yourself with such trivial things.</p>'
    }, {
      right: '<p>trivial? Please dont say such things.</p>'
    }, {
      right: '<p>you got hurt because of me.</p>'
    }, {
      right: '<p>anyway im glad youre ok.</p>'
    }, {
      left: '<p>I must also apologize, as Reus seems to be rather upset with you as well. He’s been very withdrawn these few days. I’m afraid our dorm has been rather cold to him. I’ve spoken to them, but I cannot seem to sway their decision. I am sorry.</p>'
    }, {
      left: '<p>On that note, I am once again sorry, but I do not wish to further upset Reus. Therefore I will regretfully not be engaging in conversation with you anymore unless Reus has given me his blessing.</p>'
    }, {
      right: '<p>my brother doesn’t dictate my love life dimitri. I’ll talk and beat some sense into him if i have to</p>'
    }, {
      left: '<p>I beg you, please do not do so. I cherish my friendship with him and I will always cherish the night I spent with you.</p>'
    }]} mdxType="MessageBox" />
    <p>{`No. No, no, no. It wasn’t supposed to happen this way. Byleth heaved a sigh and collapsed
her head onto her arms. More and more students were retreating to the library to
do their homework, but all concentration for the essay in front of her has been reduced
to ashes, replaced by burning indignation. All she could think of was how to murder
her twin brother without actually killing him. She was on the verge of calling him
and just lashing out at him, but Dimitri was right.`}</p>
    <p>{`Maybe after sparring with someone at training tomorrow would her mind clear.`}</p>
    <Divider mdxType="Divider" />
    <p>{`Byleth woke up the next day, feeling an ominous wetness in her underwear. She had a faint idea of what it was. Her gut continued churning uncomfortably as she ran to the toilet, a sanitary pad and a fresh pair of panties hidden in her bag. And to her absolute horror, she was right; It was her period. She wasn’t expecting it at all, but given the amount of emotional stress she had been going through the last couple of days, it wasn’t so surprising.`}</p>
    <p>{`She cleaned the stained male briefs she’d bought for her purpose here and threw it away, thanking her past-self for having the brains to buy black ones so stains wouldn’t be obvious.`}</p>
    <p>{`Period cramps plagued her pretty much the entire day, and it didn’t help that she had training. Bless the gods for giving her an unreadable face.`}</p>
    <p>{`But of course, the coach `}<em parentName="p">{`had`}</em>{` to insist on an unarmed, battle-royale style of training that day. Just her luck.`}</p>
    <p>{`And it only got worse.`}</p>
    <Ads mdxType="Ads" />
    <p>{`When all the weaker fighters had been weeded out, she was up against the main team from the tournament consisting of Dimitri, Ashe, Caspar, Raphael, plus Sylvain and Felix.`}</p>
    <p>{`This was definitely her divine punishment from the Goddess, yet she had no idea how she managed to fight for so long. The sun scorched their backs, the air was stuffy and on top of that, the cramps seared through her every so often that her vision blurred and she couldn’t seem to breathe quickly enough because of her bound chest.`}</p>
    <p>{`For some reason though, Dimitri was always positioned in front of her, or at her back defending her in some way. He even took a huge blow for her when Raphael hammered down his fist. Dimitri thankfully managed to block it.`}</p>
    <p>{`Before she could react to Dimitri protecting her, she barely escaped a punch from an over-excited Caspar. He was relentless. He seemed to have endless stamina and kept up a flurry of punches and kicks. But he was brash and his moves messy. She lunged at him and they struggled on the ground, wrestling over control over the other. Byleth spotted an opening as he frantically switched into a defensive position, and grabbed him in a chokehold until he blacked out.`}</p>
    <p>{`Caspar woke up a couple of seconds later with a jolt looking confused and cried out in frustration when he realised what had happened. Disqualified.`}</p>
    <p>{`Byleth staggered to her feet. Her world threatened to spin if she moved too quickly and her vision was beginning to whiteout. She thought about giving up when Ashe tapped her shoulder, asking if she was okay as she looked pale. She nodded, slapped her cheeks and got into position.`}</p>
    <p>{`It didn’t take long and they were both rolling on the ground, with Ashe trying to pass her guard. Byleth was exhausted so she didn’t waste any time. When Ashe’s focus was distracted that one second, she locked him in between her legs and twisted his arm hard enough for him to tap out. He too was disqualified.`}</p>
    <p>{`Ashe got up quickly, but Byleth remained on the ground, kneeling with her head down and gasping for air. She didn’t care if anyone saw and tugged at her chest bind, trying to loosen it even a little. Even then her body was intent on murdering her and another wave of cramps clenched and twisted her insides. Mercifully, Ashe extended a hand to her and she heaved herself up.`}</p>
    <p>{`She looked around the arena, all the participants were either sitting or sprawled out on the sidelines. Even Felix and Sylvain had been disqualified, which meant her last opponent was—`}</p>
    <p>{`“Dimitri, are you alright?” Ashe called out, Dimitri was just as battered as she was since almost everyone attacked him from the get-go as he was the strongest. Compared to her miserable state he looked like he just had a refreshing workout session, despite being previously injured. He truly was a beast.`}</p>
    <p>{`Byleth emptied the contents of her bottle onto her face, it offered little relief from her suffering. Her head spun and her mind was in a daze. She knew facing Dimitri required every single ounce of strength, however little was left.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Or so she thought.`}</p>
    <p>{`It was as if the night with Reus repeated itself. Except she was Reus and she was the one hurting him. Dimitri barely deflected any of her attacks or even attacked her directly. Byleth hardly felt the resistance in his arm when she swung her leg at him, even letting her grab his knee and he fell on his back.`}</p>
    <p>{`He was literally letting his guard down. Why? He didn’t put up much of a fight when she tried to bypass his guard. He didn’t block when she had her legs on either side of him, her arm pressing against his neck. `}<em parentName="p">{`Why?`}</em>{` The hands that gripped her own had no strength in them at all. She could force him to tap out by cutting off his air supply. But he only stared at her. Put up a show.`}</p>
    <p>{`All it took was the look of guilt on his face while being pinned down that made Byleth realise his intent as she pushed herself away from his throat.`}</p>
    <p>{`He was letting Reus take it out on him.`}</p>
    <p>{`It was at that realization that the last of her adrenaline left her body and all her emotions, pain, and fatigue came crashing down.`}</p>
    <p>{`The next thing Byleth saw was blackness swimming in her eyes as her body went limp and her head thudded on something solid and warm. The smell of sweat filled her senses as Dimitri’s panicked voice called out to her as her mind gave into the darkness.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      