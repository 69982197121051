import styled from 'styled-components';

import $ from '../../../styles/globals';
import Divider from '../../MDXFanfiction/Chapter/Shortcodes/Divider';

const Section = styled.section``;

const Container = styled.div`
  padding: 40px 0 60px 0;
`;

const Title = styled.h1`
  text-transform: uppercase;
  color: ${$.brown5};
  letter-spacing: 3px;
  font-family: Porkys !important;
  font-size: 40px !important;
  margin-bottom: ${$.layout.margin3}px;

  ${$.screen.mobile} {
    font-size: 35px !important;
  }
`;

const Description = styled.h2`
  font-size: 17px !important;
  font-family: Rubik Regular !important;
  line-height: 1.3em;
  color: ${$.brown4};

  ${$.screen.tablet} {
    font-size: 17px !important;
  }

  ${$.screen.mobile} {
    font-size: 16px !important;
  }
`;

const Banner = () => (
  <Section>
    <Container>
      <Title>Topics</Title>
      <Description>
        Here are the topics that we talk about in Geeknabe. nabe-chan blogs
        about most things that the hotpot fancies, while mari-chan writes
        fanfic... a lot.
      </Description>
    </Container>
    <Divider />
  </Section>
);

export default Banner;
