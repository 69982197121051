import { useState, Fragment } from 'react';
import styled from 'styled-components';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';

import $ from '../../../styles/globals';
import TinyImageCard from '../../TinyImageCard';
import InFeedAd from '../../Ads/InFeed';
import Pagination from '../../Pagination';

const Section = styled.section``;

const Container = styled.div`
  padding-bottom: 50px;
`;

const StyledInFeedAd = styled(InFeedAd)`
  margin: 20px auto;
  width: 100%;
`;

const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  grid-gap: 25px;
  padding: ${$.layout.padding4}px 0;

  ${StyledInFeedAd} {
    grid-column: 1 / span 4;
  }

  ${$.screen.tabletA} {
    grid-template-columns: repeat(2, 1fr);
    ${StyledInFeedAd} {
      grid-column: 1 / span 2;
    }
  }

  ${$.screen.tabletB} {
    grid-template-columns: repeat(3, 1fr);
    ${StyledInFeedAd} {
      grid-column: 1 / span 3;
    }
  }

  ${$.screen.mobile} {
    grid-gap: 20px;
    grid-template-columns: repeat(1, 1fr);
    ${StyledInFeedAd} {
      grid-column: 1 / span 1;
    }
  }
`;

const Content = ({ items }) => {
  const totalPosts = 24;
  const [slice, setSlice] = useState({ start: 0, end: totalPosts });

  const filtered = items
    .map(({ node }) => {
      const {
        frontmatter: { banner, excerpt, published, tags, title },
        slug,
        timeToRead,
      } = node;

      return {
        slug: `/${slug}/`,
        timeToRead,
        tag: tags[0],
        excerpt,
        img: banner,
        key: nanoid(),
        title,
        date: published,
        isApp: false,
        showInMobile: false,
      };
    })
    .sort((curr, prev) => {
      const { date: currDate } = curr;
      const { date: prevDate } = prev;
      const parsedPrevDate = new Date(prevDate).getTime();
      const parsedCurrDate = new Date(currDate).getTime();

      if (parsedCurrDate < parsedPrevDate) {
        return 1;
      }

      if (parsedCurrDate > parsedPrevDate) {
        return -1;
      }

      return 0;
    });

  return (
    <Section>
      <Container>
        <StyledInFeedAd />
        <CardsContainer>
          {filtered
            .slice(slice.start, slice.end)
            .map(
              (
                { slug, timeToRead, excerpt, img, title, date, tag, key },
                cardIndex
              ) => {
                const showAd =
                  cardIndex !== 0 &&
                  cardIndex !== filtered.length - 1 &&
                  (cardIndex + 1) % 12 === 0;

                return (
                  <Fragment key={key}>
                    <TinyImageCard
                      slug={slug}
                      tag={tag}
                      timeToRead={timeToRead}
                      excerpt={excerpt}
                      img={img}
                      title={title}
                      date={date}
                    />
                    {showAd && <StyledInFeedAd />}
                  </Fragment>
                );
              }
            )}
        </CardsContainer>
        <Pagination
          totalItems={items.length}
          setSlice={setSlice}
          maxItems={totalPosts}
        />
      </Container>
    </Section>
  );
};

Content.defaultProps = {
  items: [],
};

Content.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
};

export default Content;
