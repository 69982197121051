import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXFanfiction/Chapter/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Divider = makeShortcode("Divider");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Everything happened in a flash. Coach Kostas, seemingly unconscious but wasn’t, suddenly lunged at Dimitri from where he was and slugged him with a heavy fist. Byleth had caught him just seconds before he hit the floor and went crashing down together due to his sheer size and weight.`}</p>
    <p><em parentName="p">{`No, no no!`}</em>{` Everything was `}<em parentName="p">{`not`}</em>{` going according to plan. Coach Kostas wasn’t even supposed to be here!`}</p>
    <p>{`“Dimitri!” She cradled the dazed boy in her arms, tapping his cheeks to stir him. She glared at the staggering Coach gripping his bloody nose. He wiped the blood from his split-open lip with the back of his other hand, spat on the ground, and snarled.`}</p>
    <p>{`“`}<em parentName="p">{`You’re dead!`}</em>{`” he roared, “I’ll be reporting `}<em parentName="p">{`both of you`}</em>{` for assault and violence against a teacher!”`}</p>
    <Ads mdxType="Ads" />
    <p>{`“You assaulted him first, and I am witness to that. You will never succeed—”`}</p>
    <p>{`“Shut your trap, you girly-looking wuss! I’ll get all of you expelled and you’ll never see a dime of that sponsorship money again. But if you hand over whatever it is you took, I might spare you the pain of unemployment by expulsion and a criminal record.” Coach smirked. His eyes read malicious intent despite the broad grin on his face.`}</p>
    <p>{`It was shocking how fast he recovered from his earlier drunken stupor. Suddenly he was speaking with clarity, no more slurring or incoherent words. Dimitri probably punched the drunk out of him. Not a minute ago he was almost foaming at the mouth. But then again, he was their combat coach.`}</p>
    <p>{`“No,” she said defiantly.`}</p>
    <p>{`“N-No?! Oh, you’ll live to regret this.”`}</p>
    <p>{`Dimitri stirred in her arms, first blinking a few times, then slowly raising himself up as he clutched the injured part of his head.`}</p>
    <p>{`“`}<em parentName="p">{`Hand it over!`}</em>{`” Coach was bellowing at the top of his voice now.`}</p>
    <p>{`“Over my carcass.” Rigorously patting her pockets, she took out a pen and brandished it at Coach while helping Dimitri to his feet. Despite his seemingly unfocused state, he pushed her behind him.`}</p>
    <p>{`“Coach, I… Goddess, my head… I cannot let this injustice go on any further.” Dimitri winced, and shook his head.`}</p>
    <p>{`Coach Kostas howled with laughter, seemingly crazy, then in an instant his voice became still, and eerily calm. “You can’t even handle a punch from me, how do you think you’re gonna stop me?”`}</p>
    <p>{`Dimitri straightened himself, making him larger and taller to match Coach. “You will not abuse us anymore.”`}</p>
    <p>{`She scanned the room. Coach’s office was full of weapons.`}</p>
    <p>{`“If you two fools won’t hand it over, I’ll just have to beat it out of you.” He went behind the door and grabbed a wooden sword.`}</p>
    <p>{`What was she expecting? Of `}<em parentName="p">{`course`}</em>{` he’d grab the nearest weapon! At least it wasn’t a real sword.`}</p>
    <p>{`Dimitri’s eye had been forced shut and was starting to bruise. Blood matted his beautiful, blonde brow, and trickled into the corner of his eye, but he looked more than ready to fight for his life, and hers.`}</p>
    <p>{`She shuddered, reminding her of their first fight where Felix called him a boar.`}</p>
    <p>{`No, Dimitri was a lion; ready to devour his prey.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Your bullying will cease,” Dimitri said sharply, shoving the muscular Coach aside with surprising ease and pulling Byleth out of the room to stand in the hallway.`}</p>
    <p><em parentName="p">{`It’ll be easier to fight and call for help,`}</em>{` she thought.`}</p>
    <p>{`Hurried footsteps echoed from the stairwell. Coach smiled wickedly.`}</p>
    <p>{`“Oh, that’ll be the security guards. You better believe it’ll be my word against yours, seeing as students aren’t supposed to be here.”`}</p>
    <p>{`“Dimitri?!” A frantic Sylvain emerged, along with Felix, Ashe, Dedue and… Claude?`}</p>
    <p>{`“What?!” Coach Kostas yelled, “Why’re you fuckin’ twats— Where’s the security?!”`}</p>
    <p>{`“Whoa Coach, what’s with the nasty language? We’re all underage you know.” Ah, such a Claude thing to say, even in the face of danger.`}</p>
    <p>{`“Who the hell are you?” Coach spat at Claude as the gang gathered around them.`}</p>
    <p>{`Dimitri assured them he was fine when Ashe began to fuss over him, and asked that they secure the other exits while he and Byleth stood at the stairs; to make sure he didn’t escape.`}</p>
    <p>{`“Guards! Guards! Students out of bed!” He shrieked. “Just you all wait, I’ll be getting the management involved in this, and none of them will believe you, a gang of students breaking into a teacher’s office and stealing valuables.” He dropped the wooden sword he was holding at their feet, as if they were the ones using it. Suddenly he made a dash towards her and Dimitri, about to shove them out of his way but he tripped, ironically pushed her by accident and she tumbled towards the top of the staircase.`}</p>
    <p>{`It happened in slow motion and instantly at the same time. Her entire form felt weightless as she fell backwards. Her arms instinctively wrapped around her head and curled into a ball, preparing for impact.`}</p>
    <p>{`“`}<em parentName="p">{`Byleth!`}</em>{`”`}</p>
    <p>{`She felt a strong force grab her and pull her head into their chest. For a brief moment, Byleth felt gravity take its hold, and within moments the world spun into a chaotic blunder as they tumbled down the stairs. She could feel every single thud as they crashed against each downward step, but the impact felt cushioned. She didn’t open her eyes until she stopped rolling, and only then did she understand that Dimitri had absorbed all the impact as they fell.`}</p>
    <p>{`He was still cradling her in his arms when she came to. Byleth groaned in pain. Despite being shielded, her body ached all over.`}</p>
    <p>{`Her mind spun as she looked up at Dimitri. The iron grip he had around her was starting to hurt. She heard him grunt, and felt the muscles coiled around her loosen.`}</p>
    <p>{`“Are you okay?” he breathed raspily.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“I-I-I’m okay. Are you?” She grimaced. This was going to hurt tomorrow morning.`}</p>
    <p>{`He nodded, rolling to the side and got on his knees.`}</p>
    <p>{`The ground shook with thundering footsteps as Felix looped an arm around her waist to help her stand, while Ashe helped Dimitri.`}</p>
    <p>{`“No, wait—Where’s Coach!? We can’t let him escape— `}<em parentName="p">{`Ow!`}</em>{`” She winced as she nearly fell forward if not for Felix catching her.`}</p>
    <p>{`“Relax,” Felix said calmly. “Dedue’s got him.”`}</p>
    <p>{`Byleth sighed with relief and attempting to walk again but a searing pain shot from her ankle. She tried putting pressure on the other foot but she almost toppled if Felix hadn’t had a good grip on her arm.`}</p>
    <p>{`Dimitri walked up to her, looking to be in a worse state than her. “You’re hurt.”`}</p>
    <p>{`“It’s fine, but how could you do that? You would have broken something!” She chided him, feeling her anger rising.`}</p>
    <p>{`“If I didn’t, you’d only be hurt more. I’m sorry I couldn’t do it properly,” DimitrI said softly, nodding at her now swollen ankle.`}</p>
    <p>{`Felix rolled his eyes. “Ew.”`}</p>
    <p>{`“Felix!” She slapped his arm.`}</p>
    <p>{`“This guy is built like a tank. You don’t have to worry about him,” Felix slapped Dimitri’s shoulder who did seem quite unfazed.`}</p>
    <p>{`But still.`}</p>
    <p>{`She slowly climbed back up the stairs, shrugged off Felix’s grip and Dimitri’s fussing, except for Ashe who insisted she slung her arm over him as support. When they reached the landing, Dedue had ‘handcuffed’ Coach’s hands and feet with zip ties he miraculously had, and forced him to sit on the floor.`}</p>
    <p>{`“Let me go!” Coach Kostas squawked. Claude popped out of the Chess Club coach’s office with some duct tape.`}</p>
    <p>{`“This oughta shut him up…!” Claude said as he used his teeth to cut the duct tape and plastered it over the Coach’s mouth.`}</p>
    <p>{`“I-It really looks like we’re kidnapping him now though,” Ashe said quietly. Claude shrugged. “Should we knock him out? He’s going to wake up the entire school.”`}</p>
    <p>{`Wordlessly, Claude kicked Coach in the face and he slumped over.`}</p>
    <p>{`“I’ll apologise later,” he said, shrugging.`}</p>
    <p>{`“Check that he’s `}<em parentName="p">{`really`}</em>{` knocked out. Wouldn’t want another surprise attack.” Byleth nodded to Claude and he poked Coach in the face several times and even tickled him but he was knocked out cold. Dedue then carried the unconscious coach on his shoulders.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Where’s Sylvain?” Dimitri asked, looking around.`}</p>
    <p>{`The redhead came out of Coach Kostas’s office carrying his entire CPU under his arm. “Right here.”`}</p>
    <p>{`“Alright, let’s go wake Rhea and Seteth up and go back to bed, I’m exhausted,” Claude said, stretching his arms and legs. “Actually, I’m amazed there aren’t any guards here or the fact that Rhea and Seteth didn’t hear all that screaming from him.”`}</p>
    <p>{`“That’s because they live on the third floor,” Felix said as a matter of factly as he glanced down at his watch. “And it’s 2am.”`}</p>
    <p>{`“Fair enough,” Claude said.`}</p>
    <p>{`“May I ask how did you all know we were here?” Dimitri spoke up.`}</p>
    <p>{`Byleth only then noticed they were all in their PJ’s.`}</p>
    <p>{`“I got your text…?” Claude furrowed his brows. “It was a video of this guy confessing his crime. Nice job by the way.”`}</p>
    <p>{`“Oh, that was me,” Byleth answered, “I saw Claude had sent you a message, so I just tapped on that to record the video.”`}</p>
    <p>{`“And so I went to get back up. You’re welcome.” He winked.`}</p>
    <p>{`Sylvain snorted. “Ch’yeah right. More like you burst into our dorm screaming that Coach was about to murder Dimitri and Reus—”`}</p>
    <p>{`“I said Byleth.”`}</p>
    <p>{`“No, you didn’t.”`}</p>
    <p>{`“Yes, I did. I know what I screamed.”`}</p>
    <p>{`Ashe suddenly stopped in his tracks, causing Byleth to stop as well. “Actually, Claude did say Byleth.”`}</p>
    <p>{`Now they all stopped and looked at her. Dimitri stood defensively in front of her, but she laid a hand on his arm and he fell back.`}</p>
    <p>{`She pulled her arm back from Ashe, leaned on her good leg for support and bowed. “I am sorry for deceiving you guys. It is true that I am Byleth. It’s a long story, but once this is all over, I’ll explain. I promise.”`}</p>
    <p>{`Sylvain nearly dropped the CPU he was holding while Felix pinched the bridge of his nose.`}</p>
    <p>{`“Oops.” Claude grinned. “I’ll uh, go wake the hounds up. And by hounds I mean Seteth.” He bolted up the stairs to the 3rd floor before anyone could pummel him. And by anyone, meaning Byleth.`}</p>
    <p>{`“I understand if you were to be angry with me, and I intend to take full responsibility for everything,” She said, rising from her bow. “I’ll be going back to Faerghus as soon as possible.”`}</p>
    <p>{`“Nuh-uh,” Sylvain interjected, she cocked her head to the side.`}</p>
    <p>{`“No way. No stinkin’ way.”`}</p>
    <p>{`“Sylvain, I’m sorry I don’t follow…?”`}</p>
    <Ads mdxType="Ads" />
    <p>{`“There’s `}<em parentName="p">{`no way in hell`}</em>{` I didn’t realise you’re a girl.” He puffed his chest out.`}</p>
    <p>{`Felix cursed under his breath. “Can we do this `}<em parentName="p">{`later`}</em>{`?”`}</p>
    <p>{`“I `}<em parentName="p">{`refuse`}</em>{` to believe your womanly scent slipped past my nose!” Sylvian ignored him and went on, “My super, ultra effective pheromone detector—” Sylvain declared loudly before being hit on the head by Felix.`}</p>
    <p>{`“Who cares about your `}<em parentName="p">{`stupid`}</em>{` detector,” Felix groaned loudly, “We have a more important matter right now, you stupid idiot.”`}</p>
    <p>{`“Right, sorry.” Sylvain rubbed the back of his head. “Dimitri.”`}</p>
    <p>{`“Yes?”`}</p>
    <p>{`“You knew?”`}</p>
    <p>{`“... Yes.”`}</p>
    <p>{`Sylvain’s expression darkened, causing Dimitri to go in an even more defensive stance in front of her.`}</p>
    <p>{`“Sylvain? Are you alright?” Dimitri asked, concerned.`}</p>
    <p>{`“Alright? Am I `}<em parentName="p">{`alright`}</em>{`?! You-” He pointed an angry finger at Dimitri, “You who didn’t even know how babies are made until you were fourteen—” Sylvain bared his teeth. “You knew Reus was Byleth before `}<em parentName="p">{`me`}</em>{`?!” Sylvain nearly dropped the CPU again as he fell to his knees, hammering the floor with apparent grief.`}</p>
    <p>{`“Wake up the whole damn school, would you?”`}</p>
    <p>{`Byleth swore she could see Felix’s veins about to explode, but thankfully Ashe stepped in.`}</p>
    <p>{`“S-Shall we proceed? I-I think I see Claude is waving at us.”`}</p>
    <p>{`“Speaking of Claude, does he seem more...hyper to you these days?” Sylvain whispered loudly to Dimitri as they started walking up the stairs in front of her.`}</p>
    <p>{`“Hm? I suppose. It was rather suspicious that he managed to show up that night when I ki-” Dimitri stopped, she assumed it was that night when he kissed Reus.`}</p>
    <p>{`“What? When you what?” Sylvain pressed, sounding annoyed.`}</p>
    <p>{`“I-It’s nothing. But yes, I do agree he does seem rather out of character these days.” Dimitri paused. “It’s not like him to fool around so much, I wonder if something is bothering him. I shall see if there is anything he would like to discuss.”`}</p>
    <p>{`Sylvain snorted. “Dude, you’ve barely got your own issues handled. Just… forget I asked, yeah?”`}</p>
    <p>{`When they reached the landing, all she heard had been pushed to the back of her mind as Claude had just managed to coax a very, very angry Seteth out of his room.`}</p>
    <p>{`“Claude, What in the Goddess’ name is this? More students out of bed at this unholy hour—” Seteth’s face turned pale. “I-Is that Coach Kostas? Oh Goddess—” Seteth clasped a hand to his mouth, when he saw Dedue tossed Coach Kostas on the floor like a sack of rice.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“I told you we need to see Principal Rhea.” Claude smirked and smugly crossed his arms across his chest. “You’re both going to want to hear this.”`}</p>
    <Divider mdxType="Divider" />
    <p>{`Everyone sat very still in Principal Rhea’s office. She was dressed not in her nightgown but a simple form-fitting white dress. In fact, Seteth was the one in a silk bathrobe and slippers.`}</p>
    <p>{`Coach Kostas sat slumped against the wall in a corner, with Dedue watching him.`}</p>
    <p>{`“As I’ve mentioned, Claude is making these ridiculous accusations—”`}</p>
    <p>{`“`}<em parentName="p">{`Facts`}</em>{`.” Claude interrupted, looking really annoyed.`}</p>
    <p>{`Seteth glared at Claude, probably wondering how someone so impertinent was made house leader.`}</p>
    <p>{`He cleared his throat and continued. “These ridiculous accusations that Coach Kostas is embezzling the sponsorship money from the recent tournament.”`}</p>
    <p>{`Principal Rhea raised an eyebrow. “I see.” She folded her hands neatly on the table. “I’d like to hear from the students themselves. From the start, if you’d please.” Principal Rhea nodded at them to start, but they stared at each other and then finally, Byleth.`}</p>
    <p>{`She limped forward, her injured foot had swelled so much that her shoe felt too constricting. But despite `}<em parentName="p">{`everything—`}</em>{` her injuries, the aches, her tightly bound chest that made her breathless— nothing could compare to this moment; she was about to officially reveal her plan, her strategy, to the head of school, her `}<em parentName="p">{`grandmother`}</em>{` no less.`}</p>
    <p>{`She sucked in a breath, conjuring courage from the adrenaline still coursing her veins. It wasn’t supposed to happen this way. She was supposed to hand over the data she had discreetly gathered to Reus to present, to give him a better reputation at this school. No one was supposed to know she was ever here, let alone present her findings in front of an audience. The Goddess certainly had other plans for her.`}</p>
    <p>{`She recounted starting with what she saw at the tournament; how she saw Coach Kostas with a man who was allegedly called Tomas, the referee that pinned a non-existent mistake on her team; how she persuaded her brother to let her swap places with him; how she disguised herself as a boy (this made Seteth gasp); how Coach Kostas abused the Weapon’s Club members, yet none of them could do anything for fear of backlash.`}</p>
    <p>{`“And your father consented to all this while you were here?” Seteth asked through clenched teeth, his eyes squinted shut as he rubbed his temples, seemingly distressed by her every word.`}</p>
    <p>{`“Um, he didn’t know about it at first,” she confessed, having trouble maintaining eye contact, “But me and Reus—”`}</p>
    <p>{`“Reus and I,” Seteth corrected her.`}</p>
    <p>{`“`}<em parentName="p">{`Reus and I`}</em>{` saw the need to explain the situation to him, and he understood.”`}</p>
    <p>{`Seteth lifted his chin and sneered, “I must say I do not agree with such liberal parenting styles.”`}</p>
    <p>{`“He said we’re grounded as soon as this is over though.”`}</p>
    <p>{`The cranky Vice-Principal crossed his arms. “Good.”`}</p>
    <p>{`“Seteth,” Principal Rhea spoke up, “Kindly call the authorities.”`}</p>
    <p>{`“`}<em parentName="p">{`What`}</em>{`? Rhea—” He pursed his lips then cleared his throat. “`}<em parentName="p">{`Principal Rhea`}</em>{`, are we to just take the word of this… this imposter?” Seteth pointed at Byleth. “Should we not call for an internal investigation first? If word gets out that the institution of Garreg Mach, from primary to tertiary education, has such negligent security by allowing outsiders to `}<em parentName="p">{`masquerade`}</em>{` as our students, let alone allowed alleged corrupt educators to be hired—”`}</p>
    <p>{`“Do not worry yourself, Seteth, for I very much intend to launch an internal investigation, along with collaborating with the appropriate authorities,” Principal Rhea said calmly.`}</p>
    <p>{`“Yes, however—”`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Seteth,” she said sternly, “These children have come to us for help, and help we shall give them.” She paused, giving Seteth a moment to compose himself. “Please do not forget— we are educators. Should students come to us in the dead of night, not only to seek assistance, but to offer us their aid, we `}<em parentName="p">{`must`}</em>{` offer ourselves wholeheartedly.”`}</p>
    <p>{`Seteth nodded meekly.`}</p>
    <p>{`“Now,” she turned to them, “Based on what I understand, it seems time is of the essence as the sponsorship money has already left his bank account. Please make haste.”`}</p>
    <p>{`“Very well,” Seteth said defeatedly and quickly left the room with his head held high.`}</p>
    <p>{`As soon as the door shut behind Seteth, Byleth released the breath she had unknowingly been holding, and noticed the rest did the same.`}</p>
    <p>{`“I would like to thank you, Byleth, for bringing this to light.” Principal Rhea stood, and approached her.`}</p>
    <p>{`Byleth bowed her head, unsure of how to react. “I had help.”`}</p>
    <p>{`“It was incredibly brave of you to do this,” Principal Rhea laid a hand on her shoulder, “I must imagine that it was not an easy task. But it seemed the Goddess had guided, even protected you throughout this ordeal. All I ask now, is that all of you return to your dorm safely and rest your weary beings. Please rest assured that I will handle this matter personally. I shall not let harm befall any of you for being involved in this circumstance.”`}</p>
    <p>{`“Principal Rhea,” Dedue spoke up, “Where would you like me to put Coach Kostas? It is likely that he will try to escape when he wakes up.”`}</p>
    <p>{`“You may leave him and the evidence there. I shall have a word with him, thank you.” A shiver ran down Byleth’s spine at her chilly voice.`}</p>
    <p>{`They all wished Principal Rhea good night before leaving, but she called out to Byleth just as she was about to walk out the door. Byleth nodded at them to go ahead.`}</p>
    <p>{`“Byleth,” Principal Rhea said softly, yet still maintaining her regalness, “I always knew it was you.”`}</p>
    <p>{`“I’m sorry?”`}</p>
    <p>{`“You’re the spitting image of your mother.” she smiled warmly at her. “I’m glad the Goddess has brought you back here. The last time I saw you, you and your brother were mere babes.”`}</p>
    <p>{`Byleth smiled, not knowing how to react.`}</p>
    <p>{`“I must confess, while I was not particularly observant of Kostas, I was aware of the presence of those who slither in the dark like him. Years ago, your mother, too, was investigating something similar. But that is a story for another time. Just know Sitri would’ve been immensely proud of her children.”`}</p>
    <p>{`“Thank you,” Byleth said, suddenly overcome with emotions about her mother.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“I’d like to discuss with you about your future and education, but for now, please tend to your injuries. Our medical facility is well equipped and the doctor is highly qualified.”`}</p>
    <p>{`“I-I will. Thank you.”`}</p>
    <p>{`Principal Rhea stepped forward. “And personally, I would prefer if you spent the night in our guest house. I will speak to Alois about your situation, so do not hesitate to use the facilities provided to you. You are after all, family.”`}</p>
    <Divider mdxType="Divider" />
    <p>{`Dimitri sat on the top step, head leaned against the cold, hard brick. Every breath he took, the pain from the fall rippled through his back and chest. More bruised ribs, he thought. The burst of pain he felt initially when they fell was getting worse as his adrenaline wore off.`}</p>
    <p><em parentName="p">{`“Boy I can never get over how dramatic she is all the time,” Claude said as he stretched when they came out of Principal Rhea’s office.`}</em></p>
    <p><em parentName="p">{`“I know exactly what you mean! Phew, I need a shower. Who’s in?” said Sylvain as he slung an arm over Ashe, who yawned but raised his hand in favor of the shower.`}</em></p>
    <p><em parentName="p">{`“I shall join you,” Dedue said, turning his arm, probably strained from hoisting Coach Kostas on his shoulder.`}</em></p>
    <p><em parentName="p">{`“Ugh fine, me too then,” Claude said reluctantly. “And what about Prince Dimitri here?”`}</em></p>
    <p><em parentName="p">{`“Why do you call me Prince Dimitri?”`}</em></p>
    <p><em parentName="p">{`“Because you look like one?? Are you coming or not.”`}</em></p>
    <p><em parentName="p">{`“I…” He looked back at the door. “I think I’ll wait for Byleth.”`}</em></p>
    <p><em parentName="p">{`“Ooh, can’t wait to pounce on her eh?” Claude shuffled over and nudged him, “Hopefully she’ll come back to bed with you.” He winked.`}</em></p>
    <p><em parentName="p">{`“Claude!” Dimitri cried out, and he laughed.`}</em></p>
    <p><em parentName="p">{`“Claude,” he called again, his voice serious, “May I have a word with you?”`}</em></p>
    <p><em parentName="p">{`Claude shrugged. “Sure. What’s up?”`}</em></p>
    <p><em parentName="p">{`“Thank you for coming to our aid,” Dimitri said, putting a hand on his shoulder.`}</em></p>
    <p><em parentName="p">{`Claude waved. “It’s nothing. I’m normally still awake at this hour anyway.”`}</em></p>
    <p><em parentName="p">{`“Are you sleeping alright?” Dimitri asked, his brows furrowed.`}</em></p>
    <p><em parentName="p">{`“I’m fine, I nap in class. Night is the only time I get to think and prowl unhindered.”`}</em></p>
    <p><em parentName="p">{`“Is… that why you managed to catch me um...” Dimitri cleared his throat, but that only caused him more pain. `}</em></p>
    <p><em parentName="p">{`“Catch you what?”`}</em></p>
    <Ads mdxType="Ads" />
    <p><em parentName="p">{`Dimitri cringed. “When I… k-kissed-”`}</em></p>
    <p><em parentName="p">{`“Ahh… that! You were unlucky I saw that! Not just me, fellow night prowler Linhardt as well.” Claude rubbed his chin as Dimitri’s face fell at that memory. “Well, let’s just say Byleth is not the only one in this school with secrets.”  `}</em></p>
    <p><em parentName="p">{`“Is everything okay?” Dimitri asked, “Do you need help with your studies? Or If you have something to talk about, I often find myself awake during the night as well.”`}</em></p>
    <p><em parentName="p">{`“Hah!” Claude scoffed and slapped Dimitri’s back, causing him to groan in pain, “Oops, uh. Sorry ‘bout that, you worry about yourself first, eh?” Claude smiled, but that smile didn’t reach his ears.`}</em></p>
    <p>{`His head was throbbing into a terrible migraine. On top of that, an ominous feeling tugged at his heart.`}</p>
    <p>{`For some reason, his mind kept repeating the events of his date with Byleth, the fact that his credit card was rejected multiple times felt unsettling and Claude seemed to be hiding something.`}</p>
    <p>{`Plus, the fact that he gave into his emotions so willingly and too easily unnerved him a little.`}</p>
    <p>{`Why had he done so if he wasn’t prepared for a relationship he couldn’t sustain?`}</p>
    <p>{`His mind raced. A variety of thoughts flashed past his mind’s eye and he felt as if he was spiraling out of control. It was as if there was an iron grip around his throat, stopping the breath from entering his lungs. He tried shaking himself back to reality before the darkness started consuming him again, like it did when Glenn died.`}</p>
    <p>{`He stared into space, the fatigue catching up to him. It wasn’t like him though. Dimitri had successfully avoided his problems by allowing himself to relish the joys of youth and potential romance.`}</p>
    <p>{`He wondered if Byleth’s upcoming departure was the cause of this? Dimitri sighed. It almost felt like he was being abandoned again. He had been so audacious and even kissed her. Did he deserve to do so?`}</p>
    <Ads mdxType="Ads" />
    <p>{`“—mitri?”`}</p>
    <p>{`Did he even deserve to be with her?`}</p>
    <p>{`“Dimitri?” He slowly looked up at the voice calling him and felt his insecurities melt away at eye contact. The passion he felt was almost strong enough to make him forget the actual physical pain.`}</p>
    <p>{`“Byleth,” he breathed. Okay, maybe love wasn’t as much of a painkiller as he thought when the pain reverberated throughout his body when he spoke. He clutched his ribs as he stood up.`}</p>
    <p>{`“I’m sorry,” Byleth said with a pained expression, “I know Felix said you’re sturdy, but don’t push yourself.” He wanted to chuckle but decided that would be a terrible idea.`}</p>
    <p>{`“I’m leaving tomorrow,” Byleth said when they both successfully, but painfully made it down the stairs.`}</p>
    <p>{`It seemed even breathing made his torso hurt, or was it his heart that ached?`}</p>
    <p>{`“I see. I suppose that’s inevitable,” he said woefully, the reality of it all finally starting to sink in. “You can always come and visit. I’m sure Principal Rhea and everyone would like that.”`}</p>
    <p>{`“Oh, I’d love to. But I think I’m grounded.” She laughed. “And Reus too.”`}</p>
    <p>{`He couldn’t help but let out that chuckle now, and winced. Byleth’s face turned serious.`}</p>
    <p>{`“Okay, I think I’d better stop talking to you. I can tell that it hurts when you breathe.”`}</p>
    <p>{`“Please do not worry yourself about me—”`}</p>
    <p>{`“`}<em parentName="p">{`Shh`}</em>{`.”`}</p>
    <p>{`He smiled. Although he had so many things he wanted to talk to her about, his mind was in no state to discuss anything and he’d assume the same for her. It had been a long night. He was sure she’d wanted some time to think about the night’s events as well.`}</p>
    <p>{`Their walk back was pleasant as the air was still and quiet. Silence with her, he thought again, was comfortable. It was almost as if his mind was finally at rest, settled. Happy, even.`}</p>
    <p>{`He felt a little stupid though, they had only first met three weeks ago, yet it was as though he had known her for a long time. Did everyone else feel the same way about their crushes?`}</p>
    <p>{`He didn’t know.`}</p>
    <Divider mdxType="Divider" />
    <p>{`Byleth wasn’t sure where or what she was doing in an unfamiliar room when she was rudely awakened by incessant knocking at the door. Alarmed, she sat up with a start, and pain hammered throughout her body.`}</p>
    <p><em parentName="p">{`Oh right, the guest house.`}</em></p>
    <p>{`Soon after she and Dimitri had been tended to, the authorities came and took their statements. It had been a very long night.`}</p>
    <p>{`Byleth hadn’t felt this way in a long time; her entire being was almost unbearably sore. Of course nothing was going to be healed overnight. All she could do was the usual R.I.C.E - Rest, Ice, Compress and Elevate.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Reus?” she called through squinted eyes when the door opened and a recognizable figure came in. “What’re you doing here? Goddess, what time is it...”`}</p>
    <p>{`He kicked off his shoes and moved past her.`}</p>
    <p>{`“You’re unbelievably dense, you know that? What am I doing here, she asks.” He rolled his eyes as he sat on her bed. “Sylvain said you fell down the stairs and broke your ankle, which now I see isn’t broken.”`}</p>
    <p>{`“I’m fine,” she said, hopping back to the bed and started unraveling the bandage around her ankle, the same one she had been using around her chest the whole time. It was great to finally put it to good and proper use.`}</p>
    <p>{`“Okay good. You’re being shipped back home, now that the cat’s out of the bag,” Reus said carefully, but she kept quiet and continued to unwrap her ankle to reapply ointment. “Dad’s orders. I think grandma Rhea called him or something, I dunno.”`}</p>
    <p><em parentName="p">{`So it was time huh.`}</em>{` Byleth reached for her watch, craning her neck to see the time. 12:49pm.`}</p>
    <p>{`“Well, I better get back to the dorm and pack then.” She sighed, pushing herself off the bed to head to the bathroom to change.`}</p>
    <p>{`“Oh, and uh, the principal wants to see you before you go. I believe the authorities are here and want to take another statement.” Reus stretched and laid himself on her bed, pulling his phone out to check for messages. “Dad told me to tell you because he said you weren’t picking up.”`}</p>
    <p>{`She hobbled back to her phone and tried unlocking it but it was dead. She must’ve been so exhausted she forgot to charge it.`}</p>
    <p>{`In all honesty, Byleth was reluctant to leave as going back to the real world was tiring, tedious, and so terribly mundane. Sure, she could come back and visit but things would be different. In fact, she was still struggling to believe that she had managed to disguise herself for almost a month, `}<em parentName="p">{`and`}</em>{` succeeded in her mission.`}</p>
    <p>{`Still dressed in Reus’s oversized hoodie and sweatpants but mercifully without the need to bind her chests anymore, the sports bra she was wearing felt like freedom. The Eisner twins walked in silence, as was their norm, back to the dorm to collect her remaining things.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Just as she was about to turn the door knob…`}</p>
    <p>{`“Eisner, this is ridiculous,” A rather pompous voice said from behind them, they spun around to see Lorenz trudging up to them, wearing a scowl on his face. “Did you hear? A girl has infiltrated our school! How preposterous! This institution is only for males! ”`}</p>
    <p>{`They both looked at each other, almost amused Lorenz couldn’t see the perpetrator in front of him.`}</p>
    <p>{`“Oh really,” Reus replied blandly.`}</p>
    <p>{`“Now, why would I lie?” Lorenz narrowed his eyes. “And that louse, Claude, seems to know who it is but will not tell me— Oh, I apologize Miss Eisner, I believe I may be a better company than this person. May I take you out for lunch this afternoon?”`}</p>
    <p>{`She didn’t react to him at all, rather she didn’t know how except to grimace at him.`}</p>
    <p>{`“Bye,” said Reus, looping an arm around her shoulder. Lorenz was probably about to explode but was caught in a bear hug by Raphael.`}</p>
    <p>{`“Lorenz! Ah, there you are buddy!” Raphael popped out from the Golden Deer Dorm, “Breakfast is about to end!”`}</p>
    <p>{`“Raphael, please, there is an urgent matter at hand!” Lorenz said as he tried to wave the human bear away.`}</p>
    <p>{`The door to the Blue Lions dorm flew open, and a half-asleep Sylvain emerged. “I swear to the Goddess, Lorenz, if you don’t stop yelling—” His frown turned into a smile. “Oh, hi guys!” He waved at her and Reus and ushered them in.`}</p>
    <p>{`“`}<em parentName="p">{`Oh!`}</em>{`” Lorenz let out an exasperated puff. “Sylvain! I will have you know—”`}</p>
    <p>{`The red-head shut the door in his face. “Blue Lions only!”`}</p>
    <p>{`To her absolute shock, all but Dedue, who was sitting upright on his bed reading, were still soundly asleep. Gentle snores emitted from Ashe’s bed, while Felix had an arm over his eyes. Dimitri was nowhere in sight.`}</p>
    <p>{`Sylvain rubbed his eyes and crawled back into his bed, and only then did she realise he was topless. Byleth wasn’t sure if she was going to miss half-naked, sweaty and smelly teens, though. Although at this point, she had become mostly immune to naked teenagers.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Where’s blondie?” Reus blurted out before she could.`}</p>
    <p>{`“Dimitri is currently in a meeting with Principal Rhea and the other house leaders. If my assumptions are correct, they are further discussing last night’s incident,” Dedue explained.`}</p>
    <p>{`They nodded. She tottered over to her bed, or rather Reus’ bed, reached underneath for her duffel bag to begin packing. There wasn’t much, since most of the clothes she had brought were Reus’s.`}</p>
    <p>{`“Is he okay?” she asked, stuffing her towel into her bag. “Dimitri, I mean.” Dimitri had been x-rayed and fortunately nothing was broken.`}</p>
    <p>{`But Dedue shook his head, and her stomach churned.`}</p>
    <p>{`“He is in pain, but he insists he is okay.”`}</p>
    <p>{`Dedue went up to her and rested his giant palm on her shoulder. “Please do not feel guilty. Dimitri is strong and he will recover. The important thing is that both of you are safe, and that the atrocities done by Coach Kostas have been brought to light, thanks to you.”`}</p>
    <p>{`She nodded at him. “Thank you. I guess I should be going now,” she said, packing the last of the things.`}</p>
    <p>{`“We were up all night talking about what happened, so give us a minute to get ready,” Sylvain said but he was still wrapped in his blanket.`}</p>
    <p>{`She chuckled, and told Dedue that she’d meet up with them before she leaves.`}</p>
    <p>{`At Principal Rhea’s office, Byleth fidgeted as she and Reus stood waiting. Did she look okay? Were her eye bags extra prominent after last night? Reus threw her a sidelong glance, and indicator for her to stop moving so much.`}</p>
    <p>{`Since she was about to meet Dimitri again, probably for the last time for a long while, she wanted to look nice. And herself. Unfortunately, she smelled like muscle ache cream and men’s deodorant.`}</p>
    <p>{`“You know, I just realised how posh this school is,” Reus said quietly as they waited for Principal Rhea.`}</p>
    <p>{`“They even have their own X-ray machine in their doctor’s office,” she replied in the same tone.`}</p>
    <p>{`“I wonder why they sleep in a dormitory instead of having their own rooms.”`}</p>
    <Ads mdxType="Ads" />
    <p>{`“It is to foster a sense of community amongst the students. And to teach them to be aware, mindful and considerate of their neighbours,” Principal Rhea replied to them and they fumbled to stand at attention when she walked in. “Good morning, children.”`}</p>
    <p>{`“G-Good Morning, Principal Rhea,” Reus blurted out, and she followed suit. Reus then attempted to excuse himself since she only wanted to see Byleth. But Principal Rhea stopped him and invited him to sit in on the conversation as well.`}</p>
    <p>{`“It is truly a blessing to have the both of you here in front of me,” Principal Rhea said, smoothening her crisp, white suit as she sat down. “I have several matters to discuss with the both of you. Your father is on his way here as we speak, and has given me permission to start the discussion without him.”`}</p>
    <p>{`Byleth held her breath as Principal Rhea turned to her.`}</p>
    <p>{`“As you are aware, it is against the rules to impersonate a student here, and you could have caused your brother to be expelled for being an accomplice. However, your actions have led us to discover something more sinister happening right under our noses in this school. I have taken the liberties to speak and explain the situation to your Headmistress at Faerghus’ Ladies Academy, and we both agree that you shall not be punished.” she paused, watching Byleth. “You and your team will be given formal apologies regarding the tournament. For you to have gone to such extreme measures to ensure justice be served, is a sign that we, as educators, have failed you.”`}</p>
    <p>{`The twins looked at each other, shocked.`}</p>
    <p>{`“Though not entirely impossible, the sponsorship money will be terribly difficult to recover. We do not know how long and how deep this issue has been going on for, and there is no certainty that even half of the amount can be returned. But, the future is not all bleak. I have called the council members to have a meeting this very day to discuss the options we may extend to you and your team, along with the team here at Garreg Mach.” Byleth thought she was a saint, not only was she helping her team, her opponents were getting help as well.`}</p>
    <p>{`It suddenly hit her that Principal Rhea was most likely up all night dealing with this yet she still looked immaculate and fresh. A literal goddess.`}</p>
    <p>{`“As for you, Reus. We may not be able to offer the same generosity to you, but I will see what I can do.”`}</p>
    <p>{`It seemed Reus was just as speechless as she was since all he did was nod at Principal Rhea.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Now, Byleth, I must ask a favour of you. Even though you are absolved of your crimes, I must ask that you do not reveal your identity to the masses.” Her kind expression turned serious. “The fact remains that Garreg Mach and all it’s levels of education, from Primary to Tertiary have stood above the rest for a millenia, and has a certain image to uphold. If we confirm that there has indeed been a girl who had impersonated a student here, I fear for our reputation.”`}</p>
    <p>{`“I understand,” Byleth said, sitting up straight.`}</p>
    <p>{`“I have already spoken to the dorm leaders, and they have been sworn to secrecy. I pray that this will only be another casual gossip amongst students to amuse themselves. This is especially relevant to you, Reus. Since you will be resuming your identity here.”`}</p>
    <p>{`“Got it.”`}</p>
    <p>{`The door to her office opened and Seteth walked in, the crease in between his brows deeper than ever.`}</p>
    <p>{`“Chancellor— Oh, my apologies.” He cleared his throat. “Principal Rhea, I should like to have a word with you once you are finished,” Seteth said as he stood behind her chair.`}</p>
    <p>{`Principal Rhea nodded once, and continued.`}</p>
    <p>{`“I have to confess I am saddened by your departure. After all, the Goddess had finally brought the two of you back to me. But it seems the Goddess’ timing is impeccable; if I’m not mistaken, Byleth, your school term is about to resume tomorrow, yes?”`}</p>
    <p>{`She dipped her eyebrows but hesitated to make eye-contact, and amazingly, Principal Rhea picked up her cues.`}</p>
    <p>{`“Is something bothering you?” she asked. Byleth pause, unsure.`}</p>
    <p>{`“It… doesn't feel right getting off scot free like this,” Byleth finally confessed, gripping the ends of her hoodie. “It was the quickest way for results. Granted, I was blinded by my ideas of dispensing justice, but I heard a voice telling me to do it. I was so compelled by this voice, I-I know I sound crazy but it felt `}<em parentName="p">{`right`}</em>{`.” Reus put his hand on her shoulder, supporting her.`}</p>
    <p>{`“The both of you are wonderful children. So very much like your parents. It is true, your methods were... bold. And I am most displeased with the way you exposed yourself to danger like that. I must stress that you come to me directly in the future, both of you, should you find yourself in a predicament.”`}</p>
    <p>{`Byleth looked down, biting the inside of her cheek. She did take many risks, calculated risks, but still risks nonetheless.`}</p>
    <p>{`“Not only did you endanger yourself, but you have caused the people around you harm as well,” Seteth chipped in and she hung her head even lower. “You are teenagers, children—”`}</p>
    <p>{`“Seteth,” Rhea interrupted but he persisted.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“What if the gamble you took went awry? What if Dimitri had died? Do you know who he is?”`}</p>
    <p>{`She shook her head.`}</p>
    <p>{`“Dimitri is the sole heir to the Blaiddyd fortune, a large multinational company that employs thousands of employees,” Seteth went on, “What would we say to his father? Even this incident has the potential to cause a scandal—”`}</p>
    <p>{`“`}<em parentName="p">{`Seteth`}</em>{`,” Principal Rhea said curtly. “That is quite enough.”`}</p>
    <p>{`Seteth took a deep breath. “I apologize. What I am trying to say is that, you are both children. You should not be undertaking such dangerous tasks upon yourselves. Nevertheless, I understand that you were desperate after being driven into a corner.”`}</p>
    <p>{`Byleth and Reus were stunned silence, even more so than their usual quiet disposition. Seteth, the notoriously stiff-upper-lip, uptight, stick up his bottom Vice-principal, was shedding his scales in front of them.`}</p>
    <p>{`Principal Rhea smiled again. “I shall not mete punishment to you, Byleth. Your wounds itself are punishment. Please accept my judgement, it is my gift to you for your help.”`}</p>
    <p>{`Byleth sat still blinking in acknowledgement. Principal Rhea then waved that they may leave.`}</p>
    <p>{`“Before I forget,” she said just as they opened the door, “I would be pleased if you came for the school ball, Byleth.”`}</p>
    <p>{`“School ball?” Reus blurted out.`}</p>
    <p>{`“Yes. It is the end of the year celebrations for senior students who will be graduating. You, Reus, are a senior this year are you not?”`}</p>
    <p>{`He nodded.`}</p>
    <p>{`“While this may be an all boys school, the dance at the ball is highly anticipated by all students, not just the seniors. However the liberty to invite guests is a privilege given only to senior students. Therefore, Byleth, and anyone who is important to you may attend as guests.”`}</p>
    <p>{`Byleth perked up, she had nearly given up on seeing Dimitri again being that they not only went to different schools, they were in different states. While she was happy, Reus looked mildly worried.`}</p>
    <p>{`“Not to worry, we will be instructing seniors in the art of ballroom dancing.”`}</p>
    <p>{`Reus’s expression changed to horrified.`}</p>
    <p>{`Principal Rhea chuckled. “As we have adapted to modern day practices, the dance floor will be open to all students and the band will be available to play contemporary tunes. Please look forward to it.”`}</p>
    <Divider mdxType="Divider" />
    <p>{`“Goddess, that was intense,” Reus mumbled as he stretched his limbs, twisting his waist.`}</p>
    <p>{`She did the same but felt her ankle hurt more than it did when she went down the stairs.`}</p>
    <p>{`“Ugh, I can’t believe they’re making us dance.”`}</p>
    <Ads mdxType="Ads" />
    <p>{`“I wonder if dad will let me go to the school ball,” Byleth wondered out loud.`}</p>
    <p>{`Reus snorted. “Oh yeah, you’re grounded huh.”`}</p>
    <p>{`“So are `}<em parentName="p">{`you.`}</em>{`”`}</p>
    <p>{`“There you guys are!” Claude exclaimed when they reached the bottom. “Did you” —he pointed at Reus, then at Byleth— “or did `}<em parentName="p">{`you`}</em>{` get grilled by Rhea and Seteth?”`}</p>
    <p>{`“Yes,” Reus and Byleth said.`}</p>
    <p>{`Hubert, along with Dimitri followed closely behind.`}</p>
    <p>{`“I’m surprised we didn’t bump into you. Dedue said you had a meeting with Principal Rhea,” Byleth said.`}</p>
    <p>{`“Oh we were in the council room, it’s where we house leaders have meetings with her,” Claude explained then pulled out his phone to text.`}</p>
    <p>{`“Hm, I see why those plebs from the Blue Lions house couldn’t tell that you were an imposter,” Hubert said, scrutinizing her from top to toe with his eyebrowless eyes.`}</p>
    <p>{`Reus stepped forward but she quickly put a hand on his arm, about to say something when Dimitri spoke up.`}</p>
    <p>{`“We at the Blue Lions try our best not to judge people based on appearance, imposter or not.”`}</p>
    <p>{`“And may I ask if `}<em parentName="p">{`you`}</em>{` noticed the difference, Mr. Von Vestra?” Claude smirked and crossed his arms.`}</p>
    <p>{`“`}<em parentName="p">{`The Honourable`}</em>{` Hubert Von Vestra,” Hubert said. “Unlike many of the students here, I was born into nobility. And yes, I did notice some discrepancies with… I believe your name is Byleth? It is unfortunate that none of the students here noticed your sloppy disguise. I would’ve personally reported you, but having said that, I was interested in what your motives were, thus it seems I have made the right call. However now, we are sworn to secrecy as a result of the disorder you have caused.”`}</p>
    <p>{`“As expected,” she replied, somewhat taken aback by Hubert’s villainous demeanor.`}</p>
    <p>{`“I shall take my leave now, I hope to not encounter you in such a situation again. Good afternoon.” And he vanished haughtily around a corner.`}</p>
    <p>{`“Little shit,” Claude spat as soon as he was out of earshot.`}</p>
    <p>{`“You can say that again,” Reus said, shaking his head.`}</p>
    <p>{`“The Honourable Ass, more like,” Claude said, mocking Hubert’s voice.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“I’m sorry you had to endure that,” Dimitri said to her, “he’s not one to mince his words.”`}</p>
    <p>{`“It’s fine,” she let out a sigh of relief, “I think I’ll go and wait for my father now. He said we’ll go once he’s done talking to Principal Rhea.”`}</p>
    <p>{`“So soon?” Dimitri asked, he looked like he was about to say something but shut his mouth.`}</p>
    <p>{`“Yes, coincidentally school is restarting tomorrow.”`}</p>
    <p>{`“Hey Reus, I need to pee, come with me,” Claude looped Reus’s arm and started tugging him, but Reus stood firm.`}</p>
    <p>{`“What? Ew no—”`}</p>
    <p>{`Claude not-so-subtly nodded at her and Dimitri.`}</p>
    <p>{`“—`}<em parentName="p">{`Oh`}</em>{` uh, yeah okay,” Reus replied begrudgingly and let himself be dragged.`}</p>
    <p>{`“Shall I walk you to your meeting place?” Dimitri offered, gesturing in the opposite direction.`}</p>
    <p>{`She nodded. “Thanks.”`}</p>
    <p>{`Their walk was understandably slow, given that she was limping, and that Dimitri smelled like muscle ache cream, too.`}</p>
    <p>{`Even now as they shuffled in silence, she wasn’t sure of what to say. More like, she wasn’t sure if what she felt was genuine, or just her hormones—`}</p>
    <p>{`“How are you?” Dimitri asked suddenly.`}</p>
    <p>{`“Hormones,” she blurted out, then blinked a few times at him. “What?”`}</p>
    <p>{`“O-oh, I merely meant to ask if you are alright?”`}</p>
    <p>{`She looked up at him, his blonde hair was perfectly styled despite the bruises on his face and body. He wore something less body fitting today, but even so she could tell the curves and contours hiding beneath it; the mere sight of him made her heart pound.`}</p>
    <p>{`Yup, it was definitely the hormones.`}</p>
    <p>{`“Everything hurts, but I’ll live,” she finally got her mouth to say. “I have not felt such aches in a long time. In a way, it’s refreshing to feel so alive.”`}</p>
    <p>{`Dimitri exhaled, a look of relief evident.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Although,” she continued, hitching her duffle bag up her shoulder, “I can’t seem to stand on my ankle anymore, maybe it actually is fractured.” She slowly twisted her ankle in different directions to see where her pain limits were, when it suddenly got too quiet, she turned her gaze back to Dimitri who was staring back at her in horror.`}</p>
    <p>{`“Byleth, please! You cannot be so casual about this! This might cause lifelong problems if untreated. I `}<em parentName="p">{`insist`}</em>{` you come with me to get an x-ray done for this.”`}</p>
    <p>{`She merely stared wide-eyed back at him, then shook her head.`}</p>
    <p>{`“I’ll get it checked when I get back to Faerghus. This is nothing compared to the time me and Reus fell out of our tree house—” Dimitri instantly turned pale, and she chuckled. “We survived. Minimal bruising.”`}</p>
    <p>{`He cleared his throat, “It’s fortunate that you were children then— injuries seem to heal faster.”`}</p>
    <p>{`“Oh no.” She waved her hand. “This was last year.”`}</p>
    <p>{`Dimitri stared at her, mouth agape. “I have to question your life choices, Byleth.” He tilted his head, and scratched his neck. “But I hope this won’t be a questionable life choice for you.”`}</p>
    <p>{`“Hmm? What is?”`}</p>
    <p>{`“Would you… be mine?”`}</p>
    <p>{`“... P-Pardon?”`}</p>
    <p>{`Her mind raced to process what he just said. Be mine? Did he mean it in a literal sense… like marriage? No, no. It can’t be. Perhaps a girlfriend— is he serious?!`}</p>
    <p>{`A gust of wind blew, throwing her hair about. Realization dawned on her and the hairs on her neck stood. For the first time in her life, she didn’t know what expression her face was wearing. Nor did she know how to do so in that split second that felt like forever.`}</p>
    <p>{`“I-I-I am so sorry— I don’t know what came over me. I know I am undeserving to ask you to do such a thing, please just forget everything I just… said.”`}</p>
    <p>{`As though suddenly possessed, a strange sense of bravery surged in her veins as she hobbled forward, placed her hand on his chest and went on her toes with her good foot. Not caring that people were around them, she placed a quick peck on his lips, and then on his cheek for good measure.`}</p>
    <p>{`The thrill went straight to her head and she felt giddy with the rush of what she just did. If this was what cloud nine felt like, she never wanted to descend down to earth again. Her heart urged her to do it again, and she almost gave in if not for the loud, familiar voices in the distance approaching them.`}</p>
    <p>{`Dimitri also looked, and let out a sigh of relief. She presumed it was because they seemed to be happily chatting amongst themselves, that they hadn’t noticed their intimate moment. He quickly pulled something out of his pocket and pressed it in her hand.`}</p>
    <p>{`Still holding onto her hand, he said, “This is a small trinket from our date at the museum. It is a mini Areadbhar charm. Please accept it as a sign of our new relationship.”`}</p>
    <Ads mdxType="Ads" />
    <p>{`She dangled it from her hands as the sun reflected off its plastic packaging, the length of the mini lance was probably the same length as her palm. She held back from beaming too much at him.`}</p>
    <p>{`“Thanks. I like it.”`}</p>
    <p>{`“You do not love it?” Dimitri looked slightly downhearted.`}</p>
    <p>{`“Well…” She fiddled with it, feeling kind of mean. “I… actually liked the Sword of the Creator that was on display, the one that was supposedly made out of the bones of the Goddess.”`}</p>
    <p>{`“Oh?”`}</p>
    <p>{`“I felt it resonate with me—”`}</p>
    <p>{`Sylvain yelled at them, “Dimitri! Stop hogging her” —Felix elbowed him— “I mean uh, stop doing….” He gestured aimlessly. “That.”`}</p>
    <p>{`Dimitri clasped her hands and quickly said in a hushed tone, “Please consider coming to the ball, I would love nothing more than to share a dance with you.”`}</p>
    <p>{`It took her a split second to understand what he was talking about, but she nodded and discreetly stuffed her gift into her pocket just as the group surrounded them.`}</p>
    <p>{`Sylvain immediately grabbed her hands, a desperate, pleading look on his face.`}</p>
    <p>{`“Please swap back with Reus. I can’t stand him.”`}</p>
    <p>{`“I heard that,” Reus said menacingly from behind him.`}</p>
    <p>{`She took her hands back, letting out a chuckle.`}</p>
    <p>{`“We’ll miss you,” Ashe said, and handed her a small plastic box. “We packed this. It has some ointment for your injuries, a strip of charcoal for your s-stomach aches" —Dedue then stepped forward and presented a lunch box— “A-And a simple meal just in case you get hungry on the way home, but we made extra as well.”`}</p>
    <p>{`He took the boxes back from her to put it in a paper bag for her convenience.`}</p>
    <p>{`Feeling overcome, she gave them both a quick hug. “Thank you. I’ll miss you guys too.”`}</p>
    <p>{`Reus then stood next to Ashe and ruffled his hair. Byleth knew this was his way of showing gratitude.`}</p>
    <p>{`“Why’re you guys talking like we’ll never see her again?” Felix barked, “We all live in the same state, and it’s not like she’s not coming for the stupid ball?”`}</p>
    <p>{`Everyone perked up, she chuckled. It looked like she’d better behave herself if she wanted to come, since she was grounded indefinitely.`}</p>
    <p>{`“Not me, I live at the other end of Fodlan! Hey, mail me coupons for french fries or something, yeah?” Claude winked at her. She rolled her eyes at him but grinned back. She did promise him french fries for life. Claude clasped her shoulder, a hint of nostalgia in his smile. “Looks like we’ll have to have our post-victory celebrations another time eh?”`}</p>
    <p>{`She nodded, a little regretful she had to leave so soon.`}</p>
    <p>{`“Actually, how about… after the ball?”`}</p>
    <p>{`“And go where? Steal leftovers from dinner and eat it by the lake?” Felix scowled.`}</p>
    <p>{`“We’ll decide when the time comes, I’m sure plenty of options will present themselves by then,” Dimitri added, seemingly pleased with the idea.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Felix grunted in response, which was probably his way of agreeing.`}</p>
    <p>{`“You’re paying,” Reus said out of the blue to Dimitri.`}</p>
    <p>{`Dimitri stared at him in surprise, then one of his brightest smiles emerged. He happily agreed to do so.`}</p>
    <p>{`Byleth eyed Reus, and when he caught it, she smirked. He rolled his eyes and turned away. Like her, her brother wasn’t good with words, but she was pleased with how he made the first move to reconcile with Dimitri.`}</p>
    <p>{`Their dad eventually found his way to them, awkwardly thanking the boys for looking after his kids. Jeralt engulfed the both of them in a tight hug at the same time before departing.`}</p>
    <p>{`“D-Dad…! I’m coming with you…!” she wheezed as she and Reus frantically tapped on their dad’s arm to let go.`}</p>
    <p>{`Jeralt let out a loud guffaw. “Oops, sorry it’s a habit.”`}</p>
    <p>{`Just as they were about to drive off, Jeralt suddenly wound down the car window and yelled, “`}<em parentName="p">{`Reus!`}</em>{` You’re still grounded!”`}</p>
    <p>{`“Yeah, yeah,” Reus yelled back, waving them off. She glanced into the rear-view mirror and saw that they were still waving at them as the car drove away, her heart squeezed; she’ll definitely miss them.`}</p>
    <p>{`Byleth leaned back into the headrest, closing her eyes, almost falling asleep before forcing it open again. She pulled out the charm Dimitri gave her, removed the packing and hooked it to her phone. After examining her new accessory from all angles, she put her phone away, pleased.`}</p>
    <p>{`She didn’t notice it at first, but exhaustion crept up on her and in an instant, overwhelmed her— the stress of having to hide her identity, the pressure to find out the truth, the chaos that was her teenage hormones, and the whole experience all suddenly weighed down on her.`}</p>
    <p>{`The seat was uncomfortable, made worse by her aching muscles, and her ankle throbbed painfully. On top of that, a headache was beginning to form from the sheer load of everything coming down at once.`}</p>
    <p>{`But honestly?`}</p>
    <p>{`She’d turn back time and do it all over again.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      