import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXFanfiction/Chapter/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Divider = makeShortcode("Divider");
const Image = makeShortcode("Image");
const AuthorNote = makeShortcode("AuthorNote");
const RemoteLink = makeShortcode("RemoteLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`“Good night! Don’t stay too late!” She smiled and waved at the new intern in reply, who was wonderfully earnest and hardworking. But it was the 19th, and the time had come once again for her to fulfill her promise to him—that she’d celebrate his birthday whether he was with her or not, as she had done so for quite literally the last thousand times.`}</p>
    <p>{`She looked up at the giant clock that hung over the museum entrance and her former home, Fhirdiad Castle, and decided to get dinner and cake nearby since it was only 8pm before she carried out her ‘birthday’ plan at midnight.`}</p>
    <Ads mdxType="Ads" />
    <p>{`It was a small cafe, the interior was simple and open with lots of greenery and plants as decoration, and run by two young men who reminded her strongly of Ashe and Dedue so much so that she allowed herself to pretend they were. She frequented it quite often due to its proximity to her workplace and even called them Ashe and Dedue in her head even though they had different names— and nearly calling them that on a few occasions.`}</p>
    <p>{`Byleth peered into the glass display of cakes, and let out a sigh of relief to see a couple of slices left still.`}</p>
    <p>{`“I’ll take all of them to-go, please. And I’ll collect it after I eat, is that alright?” she asked, whipping out her phone to pay through her e-wallet app.`}</p>
    <p>{`“It’s no problem,” Dedue said as he rang her up, “If it is for a birthday, I will pack candles and write a small message in icing for you.”`}</p>
    <p>{`She blinked at him in response. “Birthday? Oh, um. Just the candles please.” She smiled and continued her dinner order.`}</p>
    <p>{`Byleth found a place to sit, tore off her coat and squeezed her forehead. If she was being honest, this whole ‘celebrate his birthday’ was more of a yearly chore for her now. More of an excuse to indulge in cake under the guise of someone’s birthday.`}</p>
    <p>{`The view of Fhirdiad from where she sat was nostalgic, as the last few hundred years went by in a flash as soon as she gave up waiting for him. She stopped praying to Sothis for him to reincarnate. Gave up praying for death, even.`}</p>
    <p>{`It made life a little easier, instead of waking up every day and wishing she’d bump into him on the street. There were a handful of times when she would suddenly have this strong urge to seek out a certain location, as if her heart was telling her ‘He’s here.’ But only disappointment awaited her.`}</p>
    <p>{`Nevertheless, she could not sever all her connections to him, like this odd tradition she had every year on his birthday, and the day he died. It was her promise to him instead of his request, since Dimitri wasn’t that kind of person. And for the first 150 years, it gave her something to look forward to after her descendants died out.`}</p>
    <p>{`Suicide—intentional or unintentional—didn’t work. Somehow. someone or something would always find and rescue her. Her Divine Pulse didn’t go back far enough and she had sealed its abilities a long time ago.`}</p>
    <p>{`It had been some centuries since she ‘moved on’ and life was just life to her, even though she’d meet the odd one or two Dimitri’s, maybe a Blaiddyd here and there, but they were not him.`}</p>
    <p>{`She had a routine; live in her properties around the continent every couple of years, then move back to Fhirdiad to work in what is now called the Fhirdiad National Museum. Every so often, she’d meet up with Seteth and Flayn and catch up.`}</p>
    <Ads mdxType="Ads" />
    <p>{`However in the last two decades or so, ever since the world had achieved the year 2000, things began to shift drastically. It was as though the cycle of souls had been reset, and so, so many people she knew began emerging around her, whether they were just being born or just suddenly showing up. Like, there was an upcoming instagram famous bodybuilder who looked eerily similar to Raphael, who made headlines for a new world record, or something along those lines.`}</p>
    <p>{`“Thank you,” she said to the Ashe look-alike who brought her food. She stared hard at the back of his head. She’d spent the last millenia researching reincarnation, but nothing. Science, the occult, and religion had no solid proof of anything. Yet, just because the calendar had reached its 2000th birthday, all her former students and friends were suddenly cropping up like daisies `}<em parentName="p">{`everywhere`}</em>{`.`}</p>
    <p>{`Even this cafe had just popped up one day, and she had to hold on to the door frame for support when the two of them greeted her. There was nothing for centuries, and then everything was happening all at once.`}</p>
    <p>{`It gave her the slightest glimmer of hope— a hope that maybe it was time for him to finally show up.`}</p>
    <p>{`But it wasn’t enough to make her heart beat. Nevertheless, it was close enough. She had a noticeable skip in her walk, now, and her eyes were coming back to life again, Flayn said last week.`}</p>
    <p>{`The food hit the spot, it always did. It genuinely amazed her as to how modern-day Ashe and Dedue could cook so similar to her old companions. She was over a thousand years old, yet things like these could still spook her. She loved it.`}</p>
    <p>{`“Will you be heading straight home after this?” Ashe asked while collecting her dishes, “I-I’m not asking you out, I-I was just wondering if you need me to pack some ice for your cakes?”`}</p>
    <p>{`“That’s a great idea, thank you.” She couldn’t help but chuckle. It made her happy to come here and pretend she had her friends back. And… she didn’t care if they weren’t. After being around for ten centuries, she learned not to give a damn anymore.`}</p>
    <p>{`Byleth placed her hand on the floor-to-ceiling window; it was cold. It was the same biting cold as when she would huddle up with Dimitri in front of the fire. She left her hand on the window, letting the coldness numb her. She scoffed to herself. Moved on from Dimitri? Gave up waiting for him?`}</p>
    <p><em parentName="p">{`Rubbish`}</em>{`.`}</p>
    <Ads mdxType="Ads" />
    <p>{`It was as though she was waiting for a parcel that said, ‘Out for Delivery’ but it never came. Sure she’d tell herself she’d stop waiting, but deep down, she would never stop. These thoughts were always the strongest on days like this.`}</p>
    <p>{`She leaned back on the chair, her hands now warming around the cup of hot chamomile tea Ashe had topped up for her. It was 10:34 pm and time didn’t have an effect on her anymore. Thankfully the Little Faerghus Cafe opened late.`}</p>
    <p>{`She stood up a while later and put on her coat, moving to the counter to collect her cakes.`}</p>
    <p>{`“Oh, miss hold on!” Ashe called out to her, “Please take this. It’s snowing, so I hope this will help!” He handed her a small cup of something hot.`}</p>
    <p>{`“I hope hot chocolate is agreeable with you, miss,” Ashe said as he scuttled to open the door for her.`}</p>
    <p>{`“I love hot chocolate. Thank you, again,” She flipped the hood of her coat up. “See you tomorrow.”`}</p>
    <p>{`She trudged back to the Museum in the snow, then locked its ginormous gate behind her, pausing to look at the renovated gazebo in the front which was now a gift shop. Visions of her past danced in front of her, but she shook it out of her head.`}</p>
    <p>{`The visions were particularly strong these few years and it always caused a migraine to spring in its place once they disappeared.`}</p>
    <p>{`Byleth was now the museum curator, a role she undertook every other decade. Her love for educating people didn’t change, and she now admirably has a few PhDs under her belt. But right now, in this current cycle she lived in, she was content with just 3 doctorates.`}</p>
    <p>{`It wasn’t hard research at all; since she was there for all the events she was researching. Quoting and citing her previous works made it easy. Dr. Byleth Eisner, although young in appearance, was strangely and intricately knowledgeable beyond her much, much older looking colleagues.`}</p>
    <p>{`One big change she made in the museum was to finally restore every painting of Dimitri. She had refused to do so previously because she wanted to preserve the ‘feeling’ that it was painted while he was alive, that it might still contain his ‘essence’. She had no regrets restoring them now, including their wedding portrait. Actually, she only agreed to do so when a man who looked like the spitting image of a much older Ignatz had sent in his resume.`}</p>
    <p>{`She thought she was merely indulging herself when she agreed to have him restore a smaller portrait of Dimitri, which the original Ignatz painted and to her complete and utter shock, he restored it `}<em parentName="p">{`perfectly`}</em>{`. Did Sothis suddenly decide to be nice to her and sprinkle little bits and pieces here and there to make her happy?`}</p>
    <p>{`It still amazed her at how things could change so radically, yet, still maintain some sort of similarity at the same time. How and why did scholars not believe in reincarnation?`}</p>
    <Ads mdxType="Ads" />
    <p><em parentName="p">{`Maybe they didn’t have a literal Goddess inhibit their body at one point.`}</em></p>
    <p>{`“What?” she said out loud, “Sothis?” Her eyes darted around the portrait gallery. She sighed, of course it wasn’t Sothis anymore. The more she lingered on the thought, the more she realised strange things happening to her. Even this morning, she woke up and noticed her hands looked older. Almost as if she was…`}<em parentName="p">{`aging`}</em>{`.`}</p>
    <p>{`She exhaled, refreshing her brain and set up a small picnic on the floor in front of her wedding portrait. The only light she turned on was the one above the portrait of her and Dimitri.`}</p>
    <p>{`“Mmm. Right on time.” And when her alarm beeped, she lit the candle on the cake cafe-Dedue had included with some matches. “Happy Birthday, Dimitri.” She brought the cake up to her face and blew it out for him. “I miss you.” She gazed at the expression Ignatz had captured, and teared up. She held back a sniffle, feeding herself a large mouthful of cake. It was `}<em parentName="p">{`so good`}</em>{` she almost forgot she was in mourning for her lost love.`}</p>
    <p>{`But it also made it worse; the taste of the light, airy and fluffy cake, with subtle hints of peach spreading on her palate made her burst out into tears. It was similar to the ones Ashe, Dedue and her Blue Lions students used to make together,when Annette had spilled a bag of flour on Felix, or when Mercedes was teaching Dimitri how to gently fold the cake mixture and he of course, broke the spatula and got bits of splinter in the batter.`}</p>
    <p>{`She looked back up at the wedding portrait, and wondered if that girl up in the picture knew how much pain she would be in, how much she would long for death some nights instead of immortality. Byleth fiddled with the wedding ring that she now wore around her neck. It’s metal base worn down and was slightly thinner than when Dimitri had proposed to her. But the jewel still shone as bright as her eyes, he’d say. Which is why he chose it; it was the colour of her eyes. She held back a sob, covering her mouth with her hands.`}</p>
    <p>{`Time did go by faster when she was holding strong, promising herself over and over that she’d stop waiting. It seemed particularly harder this year to steel herself, made worse by cake so delicious it made her cry.`}</p>
    <p>{`It was so strange how she never expressed herself, and how being alone in solitude was normal before becoming a teacher at Garreg Mach all those ages ago, and now she was slumped to the side on the ground, letting herself have another good cry before she went back to resolving to ‘move on’ once again.`}</p>
    <Divider mdxType="Divider" />
    <p>{`“Dr. Eisner,” her assistant said, knocking on her already open door. “The students
from the Officer’s Academy are here.”`}</p>
    <p>{`She put her pen down and removed her glasses. “Oh for the tour? Already?”`}</p>
    <p>{`“Yes.” Her assistant looked down at her thick journal. “Today’s tour will have a mix of students from their Bachelor’s degree and also their Master’s program… Plus a couple of PhD students.”`}</p>
    <p>{`“Right.” Byleth pushed herself off her chair and put on her blazer. She was about to walk out the door when her assistant handed her a note. “Dr. Seteth asked me to give this to you.”`}</p>
    <Image caption="Seteth's handwritten note. Please right click and open in new tab if image is too small." alt="Seteth's handwritten note" name="seteth-handwritten-note.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <p>{`“Ascertain what?” she wondered out loud and cocked her head to the side, wondering
what he was going on about, that he even sent her a handwritten note instead of a
text message. She shrugged and slid the paper into her pocket.`}</p>
    <p><em parentName="p">{`He’s here.`}</em></p>
    <p>{`“What? Who’s here?” She looked at her assistant just as she was about to push the door open.`}</p>
    <p>{`“Sorry?”`}</p>
    <p>{`“You didn’t say anything?”`}</p>
    <p>{`“No, Dr. Eisner.”`}</p>
    <p>{`“Sorry, I’m… it’s nothing.” She frowned, and placed a hand on her forehead. Her temperature was normal, her heart wasn’t beating as usual. So what was happening to her?`}</p>
    <p>{`“Are you alright, Dr. Eisner? Would you like to cancel the tour?” Her assistant put a hand on her arm, concerned.`}</p>
    <p>{`“No, I’m fine.” She gave her assistant her hand back and patted it. Reassuring her that her boss wasn’t going crazy. Not openly at least.`}</p>
    <p>{`She emerged into the waiting room, where a sizeable group of students were waiting for her, decked out in the Garreg Mach Officer’s Academy uniform, with modern changes of course. She taught at Garreg Mach a few times since then, alternating between many of her interests.`}</p>
    <p>{`“Hello everyone, I am Byleth Eisn-”`}</p>
    <p><em parentName="p">{`He’s here`}</em>{`.`}</p>
    <p>{`“-Eisner. And I will be your tour guide today.” She smiled as she genuinely enjoyed doing tours whenever she could, rather than being cooped up with Museum Management all the time.`}</p>
    <p>{`“Are you really the museum curator?” A girl asked, “I don’t mean any offence, I am just honoured we have the actual curator facilitating our tour today!” Byleth paused a little too long, peering into the girls face.`}</p>
    <p>{`“Professor?” the girl said.`}</p>
    <p>{`“Oh...I enjoy doing tours and Dr. Seteth is an old friend,” Byleth replied. “Shall we start?”`}</p>
    <p><em parentName="p">{`Look around you.`}</em></p>
    <p>{`“I believe you all have the museum booklet with you.” She nodded at a copy a student was holding. “I understand you are all from the Historical Weapons department?” A chorus of “yes” rang out. “I’d like to leave the actual weapon’s exhibit for last and take you on a short tour of the events surrounding the weapons. It is so that you will gain a deeper understanding-”`}</p>
    <p><em parentName="p">{`He’s here, focus.`}</em></p>
    <p>{`She groaned and pinched the crease between her brows, “Sorry.” She cleared her throat. “What I would like you to take away from this tour, is the ability to see the story, the logic and the reasoning from all sides.” They all nodded, but her vision… became shaky and a little blurred…? Was eating all 5 slices of cake last night doing this to her? Was she finally developing diabetes or something?`}</p>
    <p><em parentName="p">{`I told you, he’s here.`}</em></p>
    <Ads mdxType="Ads" />
    <p>{`For some reason, her vision blurred every time she tried to focus her eyes on the crowd that was following behind her as she explained the exhibits, the various documents. The voice in her head spoke with such conviction about a certain someone being here made it worse. There was absolutely no way it was Sothis talking to her though, it had been ages since they fused together.`}</p>
    <p>{`And who was here? Why did it matter? She just couldn’t focus-`}</p>
    <p>{`“Professor!” A student’s hand shot up, “Is it true all these weapons were made from bones?”`}</p>
    <p>{`“Yes. While the divine—and I use the word ‘Divine’ loosely here— took on human form, they were butchered and their remains turned into these powerful weapons,” she explained, “But only those with crests could wield it. History and record has shown that these unfortunate wielders turn into literal monsters.” A few in the crowd gasped. “Though some of you might already know this, of course.”`}</p>
    <p>{`“Was the case of Miklan Gautier something that really happened, or was he a work of fiction?” A voice from the group asked.`}</p>
    <p>{`“Very real.” She walked over to the Lance of Ruin and the crowd followed. “Miklan’s was a tragic case of a family discriminating against offspring who were born without crests. While it was common back then, Miklan was the most prominent case in recorded history.”`}</p>
    <p>{`“Then how do you handle it here in the museum? If that’s the case, then almost everyone here would be a monster.”`}</p>
    <p>{`“That is a great question,” she replied. “It’s… more of the intention of the user that triggers such an event. Which is why it is behind bullet-proof glass now, for safety purposes.” She chuckled. The whole tragedy of Miklan morphing into a demonic beast never failed to impress, whether the person was 12 or 56.`}</p>
    <p><em parentName="p">{`Stop getting distracted, I implore you to search for him.`}</em></p>
    <p>{`She looked around the room, squinting at every single face that was present.`}</p>
    <p>{`“She’s hot.” A voice from the real world brought her back.`}</p>
    <p>{`“I can’t believe you’re still like this,” a girl retorted back.`}</p>
    <p>{`“Like what?”`}</p>
    <p>{`“We’re on an educational tour! You’re still trying to flirt with anything that has two legs!”`}</p>
    <p>{`“Brandi, I don’t discriminate, okay? In fact, I’d love to take her out for dinner,” an extremely familiar voice said.`}</p>
    <p>{`“Not before `}<em parentName="p">{`I`}</em>{` make the first move.” This voice...`}<em parentName="p">{`Lorenz?`}</em></p>
    <p>{`“Shut up Hellman, you’re embarrassing us.”`}</p>
    <p>{`She cleared her throat. “Are there any more questions?”`}</p>
    <p><em parentName="p">{`Why can’t you just find him? Ugh, you have not changed at all! Fine! Here!`}</em></p>
    <p>{`Almost right on queue; a migraine throbbed in her temples and radiated throughout her skull.`}</p>
    <p>{`“I have some...work to get back to— ” She winced. “Please email me if you have anything else you’d like to discuss. It’s on the website. Thank you.”`}</p>
    <p>{`Byleth managed to stagger back to her department, clutching her head in her hands, trying to massage the migraine out of her brain.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Dr. Eisner,” Her assistant ran up to her from her desk, clutching the office phone, “Are you willing to see a student for an interview right now? Apparently he is Dr. Seteth’s new student.”`}</p>
    <p>{`Byleth blinked at her assistant. “Sorry, I have a splitting headache right now, could you reschedule him?”`}</p>
    <p>{`“Ah, I will check with your schedule then.” Byleth nodded in thanks.`}</p>
    <p>{`Byleth let out a soft groan of relief when she finally dropped herself down on her chair, squeezing his eyes shut and took slow, deep breaths.`}</p>
    <p><em parentName="p">{`You’ve always been so stubborn!`}</em></p>
    <p>{`“Sothis...is that...you?” Byleth said out loud, even though the idea was highly improbable to her.`}</p>
    <p><em parentName="p">{`Who else do you think it could be?!`}</em></p>
    <p>{`“But how?”`}</p>
    <p><em parentName="p">{`It seems a few long years of slumber has helped me regain enough strength to speak to you like this.`}</em></p>
    <p>{`“Why now?”`}</p>
    <p><em parentName="p">{`Ugh, you’re as clueless as ever. I have appeared numerous times to you! You were only too distraught to be able to discern it was me!`}</em></p>
    <p>{`“You have…? I...don’t-”`}</p>
    <p><em parentName="p">{`Of course you didn’t! I don’t always materialize as a voice or a figure! There were times when I was an urge, desire or simply a feeling.`}</em></p>
    <p>{`“You mean all those times-” Byleth flinched from another throb in her head.`}</p>
    <p><em parentName="p">{`Yes, yes—enough about this. I don’t have much time left, come on! We have to find him, you fool! He’s here!`}</em></p>
    <Divider mdxType="Divider" />
    <p>{`He didn’t know how to explain it, but he always felt like an old soul. Plenty of
children and classmates enjoyed history, just like him. But there was something about
the Fhirdiad Museum that made him feel...at home.`}</p>
    <p>{`His friends told him it was probably because Fhirdiad Castle had been used as a model in one of the Assassin’s Creed games they played. Which he accepted at the time, but there was a driving force in his being right now, in this moment, that disagreed with his childhood self.`}</p>
    <p>{`Was a video game solely the cause of his obsession with King Dimitri? Or the legends of his wife, the Archbishop? Why was he so enthralled with the journals of a specific university professor from so many years ago? Whenever he felt like he could relate to an article or it resonated strongly with him, as strange as that sounded, it was always written by the same person. whether it be something from 20 years ago, or even way back before then.`}</p>
    <p>{`Dr. Byleth Eisner, or some variation of the name.`}</p>
    <p>{`Who, coincidentally shared the same name as King Dimitri’s wife. Of course, many other people did as well. But still.`}</p>
    <p>{`This feeling did not change and he begged his parents to enroll him in the Officer’s Academy `}<em parentName="p">{`just because`}</em>{` King Dimitri had attended it and Dr. Byleth Eisner had written many journals while she was teaching there. He knew it was wishful thinking to attend her teaching in the Officer’s Academy when it was his time to enroll, regardless, it was disappointing to not be able to.`}</p>
    <p>{`As hard as he tried, he just couldn’t shake this admiration that was bordering on obsession. He absorbed everything that had to do with the history of Garreg Mach, Faerghus or Fhirdiad and the like.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Before he knew it, he had breezed through a Bachelor’s degree in Fodlan History, a Master’s degree in Crests and another Master’s program on the Heroes Relics. He even met a group of friends who shared the same sentiments as him! They all felt the same sense that they were old souls, Déjà vu episodes happened so frequently to them that sometimes, it would be hard to tell which memories were real.`}</p>
    <p>{`Yet…It still wasn’t enough. He wasn’t satisfied, this insatiable thirst for knowledge— no, it was something else, something deeper that shook his existence. It was something more than mere education could ever hope to provide.`}</p>
    <p>{`“Oh? She’s not available? That’s alright, I’m the one imposing without an appointment,” Dimitri said apologetically. “Thank you for your time.” The assistant thanked him and left.`}</p>
    <p>{`Unfortunately, it was not to be and he didn’t seem to have any luck today. He was supposed to join the museum tour with his fellow post-graduate students today by Dr. Eisner, but his PHD supervisor, Dr. Seteth needed to see him `}<em parentName="p">{`today`}</em>{` at that exact hour, and their meeting went on longer than he had anticipated. Dr. Seteth then sent him to the museum to see Dr. Eisner regarding his dissertation only to find out he missed her by a hair when he went to the bathroom, and that she wasn’t available today.`}</p>
    <p>{`He decided since he was already here, he might as well have a look around. As a child, he frequented the Fhirdiad Museum so often he knew the layout and exhibits by heart. But being at Garreg Mach for so many years meant less visits and it seemed  they also had a change of staff over the years.`}</p>
    <p>{`It had been a couple of years, but the exhibits were mostly unchanged. Save for a few sections here and there, he felt a wave of nostalgia hit him and a brain splitting headache at the same time.`}</p>
    <p>{`He let out a soft groan as he pinched the crease between his eyes to ease the unforgiving tension in his head. It was happening quite frequently recently, the headaches and sometimes migraines. Followed by a series of vivid dreams of him in an older version of the Officer’s Academy uniform, or in a suit of armour. For the past 30 years of his life, the dreams were repetitive. He would wake up and his right eye would throb. Not painfully, but just throb and twitch.`}</p>
    <p>{`Even as a child, the vision in his right eye had been particularly bad, and the eye specialist merely recommended wearing glasses. He grew up used to it, so he didn’t really bother doing anything else about it.`}</p>
    <p>{`He needed to sit down, the pain was spreading through his head and swallowing his usual acti-fast painkillers didn’t work. He took slow, deep breaths. Focusing on each breath as he exhaled and inhaled.`}</p>
    <p>{`He groaned again when a wave of radiating pain shot through his brain and now he felt a compelling, almost forceful urge to look for something to ease his pain. But in a museum? Maybe the gift shop had something?`}</p>
    <p>{`He stood up, squinting his eyes to focus through the pain when his friends came over.`}</p>
    <p>{`“Hey Alex, you finally made it!” His red-headed friend slapped an arm around him, nearly throwing him off balance.`}</p>
    <p>{`“Oh, you look terrible, are you alright?” Brandi asked, peering into his face. “You’re pale.”`}</p>
    <p>{`“Did you forget to eat again? Obsessively researching again? You’re such a pig,” Hugo said, annoyed, but pulled out a protein bar from his pocket. “Take it.”`}</p>
    <Ads mdxType="Ads" />
    <p>{`“I’m fine. I just have this headache...” He pushed Hugo’s hand away, rejecting his offer.`}</p>
    <p>{`“Again?” Brandi asked, “You’re really going to have to see a doctor about these headaches Alex.”`}</p>
    <p>{`He shook his head, “I-No, I know the cure is somewhere here.”`}</p>
    <p>{`Hugo scoffed, “In a museum? Don’t be stupid.”`}</p>
    <p>{`“Yeah, and that’s what you said about the last bottle of painkillers you finished.” Jose, the red-head added.`}</p>
    <p>{`“Look, I know this sounds ridiculous but please, let me get this out of my system,” he pleaded. Their concern was touching but their voices were making his head hurt more.`}</p>
    <p>{`“Alright then,” Brandi sighed. “Don’t be too late! It `}<em parentName="p">{`is`}</em>{` your birthday dinner!”`}</p>
    <p>{`“I’ll be there,” he said, putting a brave face as the headache wrecked his head.`}</p>
    <p>{`Brandi, Jose and Hugo looked at each other, and then at him.`}</p>
    <p>{`“Are you sure? Do you want us to stay and help you look?” Brandi asked, more concerned this time.`}</p>
    <p>{`“Whatever it is you’re looking for,” Hugo added, “Just give it up and rest.”`}</p>
    <p>{`Alex shook his head. “I’m fine, really. The headaches are not that bad.” He lied, it was making him nauseated.`}</p>
    <p>{`The three of them looked at each other and sighed. Alex hoped it was enough to convince them since he didn’t have enough energy to fend them off anymore.`}</p>
    <p>{`“See ya, pal.” Jose patted his shoulder.`}</p>
    <p>{`He exhaled, finally some peace to calm his mind.`}</p>
    <p>{`Once they were out of ear-shot, Alexandre began pacing, at first slowly, peeking into different exhibit rooms, but then quickened his pace as what he was searching for wasn’t in any of the locations. Headache be damned, he needed to find whatever that was eating him from the inside.`}</p>
    <p>{`And soon, he was outside in the cold. His breathing materializing into cold breaths of air, his breathing ragged and heavy. He clutched his chest, while his head still hurt the feeling of something missing was raging inside him. He didn’t know what it was, but it was `}<em parentName="p">{`so close`}</em>{`.`}</p>
    <p>{`He skimmed the area, trying to see if anything clicked. Something in his mind, body and soul told him he’d know it once he sees it. But what was it?`}</p>
    <p>{`Or rather...who?`}</p>
    <p>{`As he turned to go back inside to search again, a flash of green moved passed him and his hand instinctively reached out, grabbing it.`}</p>
    <p>{`And time seemed to stand still.`}</p>
    <p>{`For when he gazed into sparkling mint green eyes, the searing pains in his head vanished. His lips moved on its own, as though calling the name that escaped his lips was the most natural thing in the world.`}</p>
    <p>{`“`}<em parentName="p">{`Byleth...`}</em>{`”`}</p>
    <Divider mdxType="Divider" />
    <p><em parentName="p">{`See! I told you he was here!`}</em></p>
    <p>{`Electric shocks shot up her arm where he grabbed her, unmistakable blue eyes and beautiful blonde hair that crowned his head; but most of all it was the soul behind those eyes.`}</p>
    <p>{`“Professor…” He breathed, and with the sound of his voice, her brain-splitting migraine disappeared.`}</p>
    <p>{`“Dimitri?” She managed to blurt out once her vision cleared. In fact, she even reached out to touch his face, but quickly pulled back.`}</p>
    <p>{`“I’m sorry, that was highly inappropriate of me,” she said, clearing her throat.`}</p>
    <p>{`“No..I..I know you., he said, still holding on to her wrist. “I feel like I’ve known you for a long time.`}</p>
    <Ads mdxType="Ads" />
    <p>{`She was unable to believe he even sounded the same. The only thing that was different was the fact he wore glasses now, and modern day clothes.`}</p>
    <p><em parentName="p">{`I am much pleased with the results this time. I do hope you have no complaints!`}</em></p>
    <p>{`‘This time?’ She mentally thought, in reply to Sothis’s statement. Did this mean...he had been reincarnated before?`}</p>
    <p><em parentName="p">{`Yes, ugh. I told you, didn’t I? But in the last round, Dimitri’s soul was reincarnated into a little girl. It took a lot of my ability to orchestrate what you see before you. And even that took so much of my energy, I needed more respite before I could speak to you.`}</em></p>
    <p>{`A little girl...No wonder she couldn’t find him. She felt stupid. Of course he wasn’t going to reincarnate in the same form every time.`}</p>
    <p><em parentName="p">{`It is good that you know you were a little witless in that sense. However, I must warn you. Like I mentioned before, it took a tremendous amount of my ability to create what you see before you, and this will have affected you as well. It would seem that your immortality is waning.`}</em></p>
    <p>{`It was all starting to make sense now. Why all her attempts at death failed, why she was always drawn back to this palace no matter how far she travelled, why all the strange urges she had to visit some locations - It was all so that it culminated in this moment.`}</p>
    <p><em parentName="p">{`Judging by how you felt these thousand years...I am confident that losing your immortality might not be such a bad thing.`}</em></p>
    <p>{`Like the feeling after that migraine that disappeared, she felt...liberated. It had been a curse on her. Especially since she lost not only her love, her family, her friends, but also her father.`}</p>
    <p>{`“You are...Professor Byleth Eisner, yes?” He called out but let go of her wrist. The voice which only lived in her memories, were now as clear as day to her.`}</p>
    <p><em parentName="p">{`I am...so tired. I do not know when I shall awaken again, or if I even will. If your immortality is in its final stages...This might be the last time we speak. For if you die...So will I. Never forget that I am always with you. So do not despair anymore and please do not mess this up. Let’s make this last one a happy one.`}</em></p>
    <p>{`She blinked back tears as Sothis faded away from her consciousness, turning her full attention to him now, “Sorry...I...Yes. I am Byleth Eisner.”`}</p>
    <p>{`“Dr. Seteth mentioned your work many times, and I am personally a great fan of your journals as well.”`}</p>
    <p>{`“You...you are?” She stammered out, a thousand years of being sure of herself flew out the door and she was nothing more than a shy teenager in front of him.`}</p>
    <p>{`“Oh, how rude of me, I’m Alexandre. Actually, I went to see your assistant an hour ago to set up a meeting with you. But she mentioned you weren’t available. I hope I’m not taking too much of your time right now?”`}</p>
    <p>{`“Not at all, Alexandre.” Her heart, or whatever it was, swelled. His name rolled off her tongue so smoothly, she smiled at him. The Dimitri she knew and loved almost never used his middle name except when introducing himself.`}</p>
    <p>{`“I...I’m so sorry but I truly feel like I know you from somewhere.” He scratched the back of his head and chuckled.`}</p>
    <p>{`“Well, if you read my work then maybe you might’ve developed a sense of familiarity?” she said cautiously, trying to not get overly excited.`}</p>
    <p>{`“No, no. I feel like I know you not as Dr. Byleth Eisner...but just Byleth,” he said, looking into her eyes, squinting like he was trying to remember something. “I’m sorry, that was rude...I didn’t mean to disrespect you,” he stammered, blush visible on his cheeks, probably because she was still a professor and he was casually addressing her.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Either way, she didn’t mind. Silence escaped her mouth since she was in shock at everything. She felt like her brain was in overdrive, trying to process everything that was happening all at the same time.`}</p>
    <p>{`But then his expression changed, as though something had clicked inside him. “Professor...I am aware I am a complete stranger to you. And I have no right to ask anything of you. However, I have one request. It’s...I have a feeling you might understand once you see it.”`}</p>
    <p>{`“Once I see it?” She couldn’t control her excitement, but needed to keep calm to appear neutral, and not as if she knew exactly what he was saying and was in fact trying to encourage him.`}</p>
    <p>{`“Yes, I know I probably sound like a stalker and...someone with dubious intentions.” He looked alarmed, but then perked up. “I actually have a letter from Dr. Seteth,” He brought his backpack forward, and pulled a letter out from the inside of a hard plastic folder and handed it to her.`}</p>
    <p>{`She scanned it and she could tell at once it had Seteth written all over it. Not that she needed his confirmation for the Dimitri-reincarnation in front of her. Since Sothis herself vouched for it.`}</p>
    <p><em parentName="p">{`Oh...Is this what Seteth meant by making sure?`}</em>{` She shook her head. Everything was truly making sense now.`}</p>
    <p>{`“Is there a problem with the letter? If you like, I can contact him right now,” Alexandre said as he pulled out his phone.`}</p>
    <p>{`“Oh no, there’s no problem at all.” She returned his letter to him. “How may I help you?” she asked, gesturing to the inside of the museum where it was instantly warmer.`}</p>
    <p>{`He removed his glasses to wipe the fog that had condensated on it with the tip of his shirt, and she couldn’t help but glance at him. Seteth’s letter or not, she had no doubt this was him.`}</p>
    <p>{`“I believe there is a permanent exhibit on King Dimitri the First?” She nodded. “This may sound absolutely mad and borderline insulting to an academician like you, Professor Eisner. But there is something on Areadbhar I am sure no one has talked about before.”`}</p>
    <p>{`She tilted her head, it was impossible. She had covered every single aspect of all the Heroes’ Relics meticulously.`}</p>
    <p>{`“Interesting.” She smiled, intrigued. “Was this what you were going to make an appointment with me for?”`}</p>
    <p>{`“Not at all, it was for a different topic for my dissertation. And actually, this thought just came to mind.” He sighed. “I apologize, this is...ridiculous. I...these memories, I looked at you and something inside me kept pushing me to ask.”`}</p>
    <p>{`“Alexandre,” she said, placing a hand on his shoulder. “Come with me.”`}</p>
    <Divider mdxType="Divider" />
    <p>{`Alexandre had no idea what had happened, as soon as they made eye-contact, 30 years
worth of dreams came rushing to his head, oddly giving him a new sense of clarity.
He was amazed that Professor Eisner was so accommodating even, he had a gut feeling
she knew more than she let on.`}</p>
    <p>{`And he was sure she was breaking a few rules by taking him back to the museum’s storage, by-passing the usual paperwork a normal person would need, which was almost empty, save a few employees here and there restoring artwork, exhibits and the like. She dipped her eyebrows in greeting at them and quickly ushered him into the bowels of the warehouse.`}</p>
    <p>{`“Unfortunately, we’ve had to keep the real Areadbhar in here,” she said as they walked into a smaller room that needed her thumbprint verification to go in. “It’s one of the most popular items here that people have tried to steal.” Professor Eisner grabbed 2 pairs of disposable white gloves from a box and handed a pair to him.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“King Dimitri was that popular?” He asked looking around the various drawers, reading its labels as he wriggled his fingers into the gloves.`}</p>
    <p>{`“Extremely. They loved him so much, the people of Faerghus were inconsolable when he died. Even though he was extremely old, he was still their beloved King,” she replied to him while carefully removing Areadbhar from its soft cushioned drawer, unwrapping the delicate cloth protecting it.`}</p>
    <p>{`Alexandre was immediately drawn to the lance, awed by its presence, yet, a sense of familiarity washed over him. His heart rate shot up, now pounding in his chest as a wave of visions flooded his mind when Professor Eisner brought the lance closer to him.`}</p>
    <p>{`He let out a groan as he dropped to his knees, trying to hold back this feeling that something was trying to break free.`}</p>
    <p>{`“Alexandre?!” she cried out, ripping the gloves from her and going down to his level, still clutching the lance in her hand.`}</p>
    <p><em parentName="p">{`Touch it.`}</em></p>
    <p>{`“What…?” he said out loud.`}</p>
    <p><em parentName="p">{`Touch Areadbhar and let it flow through you.`}</em></p>
    <p>{`“Are you alright?!” she asked, gingerly setting the lance down.`}</p>
    <p>{`He removed his gloves and hesitantly reached for the lance but pulled back, unsure...scared.`}</p>
    <p><em parentName="p">{`Do not be afraid, Areadbhar is a part of you.`}</em></p>
    <p>{`He took a deep breath and gripped the hilt, letting go all his inhibitions and let the power of Areadbhar flow into him. Instantly, it glowed.`}</p>
    <p><em parentName="p">{`‘When I feel down, I go out for a long ride on my own. There is no place more relaxing than a trusted steed's back.’`}</em></p>
    <p>{`He blinked, disoriented at what he was seeing.`}</p>
    <p><em parentName="p">{`‘Perhaps, because of my strength, I have always been rather clumsy. I always end up breaking things that are precious…’`}</em></p>
    <p>{`Strength? He was an average person but before he could think anymore, the lance throbbed in his hand and a surge of strength sent jolts of electricity through his body. He grunted and panted as the power filled him.`}</p>
    <p><em parentName="p">{`‘My right eye? The scar is healed now. It narrows my sight a little, but it does not interfere with fighting.’`}</em></p>
    <p>{`More and more visions were being awakened as he held on to Areadbhar. No..not visions. `}<em parentName="p">{`Memories`}</em>{`. And the answers to many of his life’s questions.`}</p>
    <p>{`“Alexandre? Please, answer me.” Professor Eisner was now peering into eyes with a hand on his cheek. Her gentle touch immediately calming the storm that was his mind.`}</p>
    <p>{`“Professor...I...I think I activated `}<em parentName="p">{`his`}</em>{`...crest? And `}<em parentName="p">{`his`}</em>{`...memories,” he said, sucking in breaths as he looked at Areadbhar. He felt like he just ran a marathon. “He spoke to me.”`}</p>
    <p>{`“Areadbhar?”`}</p>
    <Ads mdxType="Ads" />
    <p>{`“No...Dimitri. King Dimitri.” Searching the sea of memories that nearly engulfed him earlier, he placed Areadbhar on his lap and deftly yanked open the bottom of the hilt as if he had done so many times before, and out dropped two incredibly yellowed, but surprisingly well-maintained pieces of parchment.`}</p>
    <p>{`“He kept this in here for you.” He picked it up, putting in her hand. Alexandre watched as her face changed from concern to a warm and soft expression.`}</p>
    <p>{`“I...I must’ve examined Areadbhar hundreds of times. How did I...” She was holding back tears as she unrolled the fragile parchment.`}</p>
    <Image alt="Dimitri's last letter to Byleth. Please right click and open in new tab if image is too small." name="dimitri-letter-dimileth-fanfic-geeknabe.jpg" mdxType="Image" />
    <p>{`Alexandre watched her, and couldn’t take his eyes off her. An overwhelming sense
of...affection and...`}<em parentName="p">{`love`}</em>{` filled his existence as he gazed at her.`}</p>
    <p>{`He was a hundred percent sure he didn’t write the letter she was reading, but he knew the contents. Along with the small picture that someone called Ignatz had sketched. It was a sketch of him and her, the both of them looking into each other's eyes. It was a simple drawing, but it captured the deep feelings the both of them shared.`}</p>
    <p>{`Especially since waves of Dimitri’s emotions and memories were flowing through him now, from when he was a student, admiring her from his position as House Leader, to becoming her husband.`}</p>
    <p>{`He, Alexandre, didn’t know how it was possible that the Professor Byleth Eisner in front of her was the very same Byleth Eisner that married King Dimitri a millenia ago, but it felt true. His heart had finally settled into a steady rhythm. All those years, he yearned for something, the years of feeling unsettled, searching and searching for something, Alexandre understood why now.`}</p>
    <p>{`He wasn’t looking for something, he was looking for `}<em parentName="p">{`someone`}</em>{`.`}</p>
    <p>{`He wiped the tears that were on her face with his thumb as she looked at the picture, and let his hand linger on her cheek.`}</p>
    <p>{`“I’ve finally found you,” he said assuringly. “I’m sorry it took me so long.”`}</p>
    <p>{`She tossed the letter aside and threw herself on him, pulling him close to her. Her scent, her `}<em parentName="p">{`own`}</em>{` pure scent drowned his senses and with the intimacy of the position they were in, he wrapped his arms around her. He barely knew this woman for all of an hour yet he `}<em parentName="p">{`knew`}</em>{` her and this felt like second nature to him.`}</p>
    <p>{`Alexandre closed his eyes, savouring the moment.`}</p>
    <p>{`She pulled them apart, wiping her tears and cleared her throat. “Dimitri…” She held his hand in hers and kissed his palm. “Is it...is it really you?”`}</p>
    <Ads mdxType="Ads" />
    <p>{`He looked into her eyes, “Yes, but it’s all rather confusing,” he said, slumping his shoulders, “I am... Dimitri and also Alexandre. Alexandre is his own person, yet he holds the memory of both Dimitri `}<em parentName="p">{`and`}</em>{` Alex.” She nodded, still holding his hand in hers.`}</p>
    <p>{`“I don’t know how to describe this; it’s almost as if we’re two souls joined together. He’s left with a lifetime of emotions and memories that are now part of my own memories.”`}</p>
    <p>{`“I hope...you’ll learn to accept me, Alexandre, as well, Professor Eisner.” He gripped her hand, her warm, kind hands.`}</p>
    <p>{`“I may be his vessel..or reincarnation?” He furrowed his brow. “Other than sharing his birthday, I grew up completely different from him. I’m just a regular person. I’m not a prince or a king, I’m 30 years old today, I’ve never ridden a horse before, the vision in my right eye has been bad since I was born and I..I’m not exactly him. But I am?” He let out a sigh.`}</p>
    <p>{`“I know.” She placed a hand on his shoulder. “If you’ll allow me, perhaps we can start over? I would like to get to know you as Alexandre.”`}</p>
    <p>{`“Professor…” He fixed his gaze on her. Before this, his heart was filled with confusion and restlessness. At this moment in time, he was feeling a sense of hope and excitement. The same feelings one would feel at starting a new adventure.`}</p>
    <p>{`“I hope you’ll accept me as well, Alexandre,” she smiled at him, “I’m probably not what you picture me as. And I’ve changed too. I’m not the same Byleth in his memories.”`}</p>
    <p>{`He tucked a lock of hair behind her ear, appearance-wise she was definitely different, like, her hair was longer than in his memories. “How so?”`}</p>
    <p>{`She shrugged. “I...talk more now, I guess?” He laughed.`}</p>
    <p>{`“I look forward to getting to know you, Professor Eisner.”`}</p>
    <p>{`“It’s Byleth.” She stood up, extending her hand towards him. And a similar vision flashed past his eyes.`}</p>
    <p>{`He smiled as he took her hand.`}</p>
    <Divider mdxType="Divider" />
    <p>{`It was true, she did walk with a skip now and she woke up everyday with a new sense
of purpose. Seteth even smiled when she thanked him and when she recounted what happened.
Flayn was probably even more excited than her, begging her to tell her everything
and more.`}</p>
    <p>{`Alex travelled routinely between Garreg Mach and Fhirdiad for his doctorate, and to study under the erudite and scholarly Dr. Byleth Eisner. Though, certain topics they ‘studied’ was a personal affair between them.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Byleth observed his thick framed glasses, the modern day clothes, the smartphone in his hand and the mess that was his blonde hair as she jogged up to him for their date. She was going to teach him how to ride a horse. It was something he requested actually. And something she hadn’t done in a while even though she owned a couple of horses. Alex had specifically asked for the smallest one she had, and she couldn’t help but giggle at how awkward he was with it.`}</p>
    <p>{`She caressed the very old and worn down Blue Lion’s brooch on her chest with her thumb, silently thanking Sothis for allowing her one more chance at experiencing life with her beloved again. She will make sure to take lots of pictures and videos of him and with him.`}</p>
    <p>{`Maybe all those years of being alone was her purgatory.`}</p>
    <p>{`And maybe, `}<em parentName="p">{`just maybe`}</em>{`, right now...she finally found heaven.`}</p>
    <AuthorNote mdxType="AuthorNote">
  <p>
    I’m a huge sucker for the reincarnation tag and while I have other ideas for
    this, this’ll have to do for now while I explore other AU’s. Thank you for
    letting me indulge in my whims by reading this!!! Here are a few things i’d
    like to explain - 
  </p>
  <p>
    -I mentioned the year 2000 as the year that all the people around Byleth
    started emerging, what I was trying to say is that, they literally just
    popped up around her in a variety of ways, not just in person, like Raphael.
    They were not necessarily born in the year 2000, hope that makes sense.
  </p>
  <p>
    -I assume Byleth would be filthy rich from working and amassing her own
    wealth over the years. Plus probably keeping a fixed deposit account for a
    thousand years will probably make her some pretty good money.
  </p>
  <p>
    -As for whether people recognise her from the portraits well….I
    mean..portraits don’t really resemble the actual person anyway XD 
  </p>
  <p>
    -And her using her real name over and over….I’m pretty sure loads of people
    named their kids Byleth after their heroic actions that changed Fodlan XDXD
  </p>
  <p>
    -I’m kinda using the characters middle names because I can’t think of
    anything else that will tie them back, random names don’t really make sense
    to me. 
  </p>
  <p>
    -While Brandl is Ingrid’s middle name, I used Brandi since it sounds similar
    and a little more feminine.
  </p>
  <p>
    -Lorenz’s middle name is Hellman, but it’s more a surname here. I wanted to
    add mayonnaise and sauce jokes but meh. XD
  </p>
  <p>
    -I know the name Alexandre is pretty common but I just wanted an excuse to
    use it XD
  </p>
  <p>
    -i wanted to elaborate more, but in my head, the reincarnations of Sylvain,
    Ingrid and Felix ‘suffer’ similar visions as Alexandre, which is what draws
    them to each other. 
  </p>
  <p>
    -I apologize to anyone who has real experience in museum management if I did
    anything wrong, and to all PHD students if I used the wrong terminology. 
  </p>
  <p>
    <strong>
      {'Lastly, the title is a song by '}
      <RemoteLink to="https://www.youtube.com/watch?v=CggiMJ59U1Q" mdxType="RemoteLink">
        Take That
      </RemoteLink>
      {`! ^_^ `}
    </strong>
  </p>
    </AuthorNote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      