import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const LocalLink = makeShortcode("LocalLink");
const Ads = makeShortcode("Ads");
const Video = makeShortcode("Video");
const RemoteLink = makeShortcode("RemoteLink");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I’m back with another list blog post! Please, watch these 12-episode/1-cour/1 season anime because they are super, super good. I tried to keep it 12-episode only but some have fewer than that, or contain 13 episodes instead. These are all 1 season only so go all in and have a blast!If you enjoyed this write-up for this list,`}<LocalLink to="/blog/10-best-12-episode-anime-recommendation/" mdxType="LocalLink">{` please consider checking out the other one I did`}</LocalLink>{` <3 This list is not ordered.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Completed 12-Episode Anime Recommendation #1: Vivy -Fluorite Eye’s Song-`}</h2>
    <p>{`This anime is probably my favourite for the year 2021. Written by Tappei (Re:Zero), I think it hit all of its thematic points really well. Coupled with tension and plot twists, this original anime is a fun ride for me. The ending just hits OK but all in all, it ties up the story nicely. `}<LocalLink to="/blog/vivy-fluorite-eyes-song-review/" mdxType="LocalLink">{`I wrote a review for it if you’re interested`}</LocalLink>{`!`}</p>
    <Video title="Vivy Fluorite Eye's Song Op" src="https://www.youtube.com/embed/FmzkbCo70B0" mdxType="Video" />
    <p>{`Vivy -Fluorite Eye’s Song- is set in a world where robots (AI) are created to serve humanity. As time progressed, AI kept improving by leaps and bounds till humanity saw it fit to supersede their welfare to AI entirely. Our protagonist is a singing AI called Diva, who’s job is to give happiness to her human masters through singing. One day, a supposedly from-the-future AI called Matsumoto visited her with a proposition to enact `}<em parentName="p">{`The Singularity Project`}</em>{` in a bid to avoid an all-out war between AI and humanity in the future. As the duo progresses through 100 years of AI + Human history, they get to experience the truth behind every event that greatly affected humanity’s view on AI. Will The Singularity Project stop AI(s) from wiping out humanity?`}</p>
    <p>{`The plot is pretty fun if you watch them every week as it releases. Each episode ends with a big cliffhanger that makes you question just what is going on. Diva (who soons calls herself Vivy) had an amazing character development as the plot progresses. She questions herself after interacting with the other cast and we see her change, which ultimately ties up nicely for the show’s ending. The songs are available on `}<RemoteLink to="https://music.apple.com/us/album/vivy-fluorite-eyes-song-original-soundtrack/1571467263" mdxType="RemoteLink">{`Apple Music`}</RemoteLink>{`, `}<RemoteLink to="https://open.spotify.com/playlist/6oNwTOtvndlSK8baxKDNDu" mdxType="RemoteLink">{`Spotify`}</RemoteLink>{` and `}<RemoteLink to="https://www.youtube.com/watch?v=2qcvp5nE4Lc&ab_channel=Vivy%28Vo.KairiYagi%29-Topic" mdxType="RemoteLink">{`YouTube`}</RemoteLink>{` <3 AS YOU LIKE MY PLEASUREEEE`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Completed 12-Episode Anime Recommendation #2: Alice & Zoroku`}</h2>
    <p>{`I saw this show popping up on my YouTube recommendation and I just went, Wow it’s been a while! Alice & Zoroku is a perfect fit for anyone who loves anime that explores parent-like relationships with a child that’s learning.`}</p>
    <Image alt="Alice and Zoroku" caption="Zoroku grandpa and Alice!" name="alice-and-zoroku.jpg" mdxType="Image" />
    <p>{`Set in our world, there lies a supernatural power called “Dreams of Alice” tappable only by children (or perhaps, adults with a child-like wonder?). The Japanese government sees this as both a potential hazard for the outside world AND a useful tool if they could learn to harness it. Thus, a pharmaceutical company took on the job to experiment on children who could use Dreams of Alice. Our MC is Sana, a powerful Dreams of Alice user who escaped from the research facility. While she is on the run, she meets Zoroku, an old man who works as a florist. Zoroku is straightforward and brutally honest. He sees Sana’s problem as a headache but he’s not one to ignore a child’s plea for help. He took Sana in and through him, Sana learns just a bit more about the outside world while fending off dangerous situations.`}</p>
    <p>{`One word about this show: CUTE. Zoroku is a grandpa and you know how grandpas dote on kids! Zoroku doesn’t show it but his kind acts towards Sana is just so, so adorable… Then there’s Sana who slowly learns the truth of her powers (and self). All in all, a nice 12-episode show. God bless the author for the story.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Completed 11-Episode Anime Recommendation #3: Anohana The Flower We Saw That Day`}</h2>
    <p>{`Are you ready to go on a trip of suffering and tears? Because this is what Anohana is all about. Lots of pain, friendship drama because no one wants to admit their faults and tears. Lots of them. I added a MV for the opening below. It's amazing.`}</p>
    <Video title="anohana" src="https://www.youtube.com/embed/T3bxbVGWy5k" mdxType="Video" />
    <p>{`In a small town of Chichibu, a group of close childhood friends went to their secret base to play. Things get heated and their play session turns into a disaster when one of the children, Menma dies in an accident. The group of childhood friends then drift apart and soon, it’s as if their childhood years never happened. Fast forward to their high school years, Jinta the MC of the story has since become a withdrawn young man who refuses to go to school after the death of Menma and his mother. As of late, he’s been seeing a ghost of Menma who refuses to leave his side. Menma requested to have her wish fulfilled so she could pass on, but she didn’t know what the wish was. Jinta is convinced that this `}<em parentName="p">{`ghost`}</em>{` he’s seeing is just a hallucination, so he just tries minimally to help her. Thus marks the start of uncovering the mystery of what happened on the day of the accident, as well as a way for Jinta and his childhood friends to move on from that day.`}</p>
    <p>{`This, by far, is the most heartbreaking friendship drama I’ve watched. The plot and the vibe is pretty much drama-ish, lots of heart-to-heart sessions, etc. The kids are all older now and ironically, kept their secrets super hard. It’s only when Menma got involved that they opened up their flood gates. Good stuff, please watch the show!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Completed 12-Episode Anime Recommendation #4: Barakamon`}</h2>
    <p>{`You usually don’t open up an episode by punching an old man for giving an honest critique of your work but here we are.`}</p>
    <Video title="barakamon op" src="https://www.youtube.com/embed/_vvL3z3pAs0" mdxType="Video" />
    <p>{`Our MC is Seishu Handa, a professional calligrapher despite his young age. He showcased his calligraphy work in an exhibition and got an elderly curator commenting on it. He was told that his work is just like a “textbook”. This got Handa super mad and he punched the poor old man. After that little incident, he was sent off to a remote island for him to “cool off”, and to get inspiration for his calligraphy. It was in this tiny village that he interacted with the fun folks and that sparks his own style.`}</p>
    <p>{`Eh, nothing much to talk about since this is technically a slice-of-life show. Seishu Handa is a super rigid guy. He’s young so he has a lot of pride in his work. I think that’s fairly normal for a young man who rock and rolled hard his way to success. What’s super satisfying is seeing him interact with the villagers! He is super stiff in a way. Doesn’t really like people. Then there’s the friendly villagers who would constantly talk with him and it kinda rubs off on him. He starts to look forward to meeting people, smiles more and actually has fun with the kids. This eventually took hold on him and changed his mindset on his calligraphy style. You see him just interacting casually with the folks around him and all of a sudden, he cooped up in his room to write down his spark of inspiration.`}</p>
    <p>{`The sudden VOILA! vibe was animated really well. Think of how a writer just gets an idea for a novel then suddenly, the writer jumps up in excitement and rushes off to write the idea down. Yea pretty much that! He did another exhibition afterwards and the elderly curator actually praised it. Of course, Handa has since mellowed down and apologized to the curator on the spot for his rudeness (In Episode 1). Now that’s what I call character growth done right!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Completed 8-Episode Anime Recommendation #5: Black Rock Shooter`}</h2>
    <p>{`Black Rock Shooter first started as a `}<RemoteLink to="https://www.deviantart.com/ryoheihuke/art/BLACK-ROCK-SHOOTER-7-155834812" mdxType="RemoteLink">{`fanart/fan design(?)`}</RemoteLink>{` for Hatsune Miku by huke, a popular illustrator. I followed him back when Deviantart is the `}<em parentName="p">{`jammm`}</em>{`. Dude draws some really nice art. Nothing beats `}<RemoteLink to="https://www.deviantart.com/redjuice999/art/Cocytus-379734903" mdxType="RemoteLink">{`redjuice999`}</RemoteLink>{` though.. The art style for the fan art kinda took hold in the Japanese Hatsune Miku scene. The art style is grungy, dark and features a darker haired Hatsune Miku with a bikini + hot pants + jacket. I remember watching the OVA first (which felt more like a strange showcase than a plot) before it became a revamped basis for the 8-episode show. I added links so you could enjoy the `}<RemoteLink to="https://en.wikipedia.org/wiki/Black_Rock_Shooter" mdxType="RemoteLink">{`history of this Black Rock Shooter show`}</RemoteLink>{`.`}</p>
    <Video title="black rock shooter trailer" src="https://www.youtube.com/embed/zN9gcAgn9u0" mdxType="Video" />
    <p>{`Our MC is a middle school girl named Kuro Matoi who befriended a girl called Yomi Takanashi. That’s sweet and all but unfortunately, their friendship draws irk from a close friend of Yomi. This confuses Matoi because this is the first time that she encountered such a strange situation. Coupled with the weird dreams she’s been having… Just who is the girl in black fighting the monsters in her dream?`}</p>
    <p>{`Okay buckle up, this may seem confusing. Black Rock Shooter features two worlds that are happening in parallel. The real world with all of our main characters in it doing the friendship thingie and an alternate, brutal world where their alter egos are fighting to survive as the remaining victor. Both sides are affected by one another. If their alter egos die, their real self “dies” too. I wasn’t too keen on the who’s who, but one thing’s for sure… Man, the design and the fight scenes are pretty lit. I dig the aesthetics too. The alter egos are all black as their base colour followed by a primary colour that represents them. Fun show that has the friendship plot slightly over the top but eh, not bad all in all.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Completed 12-Episode Anime Recommendation #6: BNA Brand New Animal`}</h2>
    <p>{`An original anime done by Studio Trigger (The same studio who did Promare and Kill la Kill) is an instant 5-star in my book. No matter what plot they are doing, I’m sure they’ll end the show with a blast and boy, I was right! `}<LocalLink to="/blog/bna-is-a-brand-new-a-game-anime-by-studio-trigger/" mdxType="LocalLink">{`I did a review for BNA if you’re interested`}</LocalLink>{`.`}</p>
    <Video title="bna" src="https://www.youtube.com/embed/3Okzra8Anr4" mdxType="Video" />
    <p>{`BNA is set in a world where beastmen exist and are prosecuted by humans for being… different. Our protagonist is Michiru Kagemori, a young teen who found herself turning into a tanuki beastman after her best friend Nazuna was kidnapped. She kept mostly to herself after realizing that she couldn’t turn back into a human. One day, she decided that she needs to take action and saw an ad about Anima City, a city for beastmen. She thus braved the journey there and upon arrival, got entangled with a mysterious wolf beastman. This (handsom- ahem!) wolf dude took in Michiru after finding out about her sudden transformation while he struts about to keep the peace in Anima City. Of course, like all stories, Michiru soon found herself discovering an evil plot involving a pharmaceutical company and a cult dedicated to the Silver Wolf.`}</p>
    <p>{`The episodes feel episodic but it’s actually… Not episodic. Each episode actually contributes to the viewers’ understanding of the open secret of Anima City, how humans view beastmen and likewise, etc. There’s a lot of themes being thrusted upon the viewers to mull at the end of the episode. These open-ended themes were eventually resolved (in a shounen-ish way) for the ending. Was it satisfying? Kinda, if you took in what each episode’s message was. Was it a bad ending? Nope. It might be cheesy for some folks but I definitely like it! It’s super Studio Trigger-ish to end the show as it is. Do give Episode 1 a try and see if you like it!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Completed 12-Episode Anime Recommendation #7: Kemono Jihen`}</h2>
    <p>{`Remember the good old days of 2000s anime? When you often watch a shounen show about a group of kids trying to do their own thing amidst the adults’ scheming? Yup, it’s paced well, the plot is kinda catchy to watch during your lunch break, it’s Kemono Jihen!`}</p>
    <Video title="kemono jihen op" src="https://www.youtube.com/embed/6JyDgWe_UrQ" mdxType="Video" />
    <p>{`The story starts in a small village in Japan. A hostess of a local inn requested the service of an occult detective from Tokyo after a string of incidents involving livestocks that died in an unnatural manner. The detective who answered the call is Kohachi Inugami. Upon arrival, he quickly befriends a rigid young boy called Dorotabou (Real name is Kabane) who works as a helper in the inn. Inugami would often call upon the boy to help him out in his investigation. Initially, it felt as if Inugami had his eye on Kabane for some reasons but as it turns out, Inugami noticed that the young boy is a `}<em parentName="p">{`kemono`}</em>{`. He’s a half human, half ghoul who is in possession of a lifestone given to him by his missing parents. After solving the supernatural case in the village, Inugami offered Kabane a position in his office. In return, Inugami will look for Kabane’s parents for him. This is how the story of Kemono Jihen starts! A group of young kemono kids who stuck together because of their complicated family problems…`}</p>
    <p>{`So you have Kabane who’s emotionless but he still wishes to know if his parents are out there, Shiki who’s a half arachne and Akira, who’s super girly but is a yuki-onna dude. Uhm, yea. All three of them have their differences but they work together to solve kemono cases led by Inugami. Pretty nice build up in each episode along with answers to their unique family situations. I hope there’s Season 2!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Completed 12-Episode Anime Recommendation #8: Tsuritama`}</h2>
    <p>{`This is a super old show but I have fond memories for Tsuritama. The reason being… The show has such a bright palette. It’s also one of those rare “show, don’t tell” anime! Not to mention how youthful-vibe the plot is. Ah~ My teen years~`}</p>
    <Video title="tsuritama op" src="https://www.youtube.com/embed/CQBw_XrZWkw" mdxType="Video" />
    <p>{`Our protagonist is Yuki Sanada. A high school boy who goes in and out of schools because he follows his grandmother around. Because of this, Yuki couldn’t build any strong friendship with any of the kids his age. This led to him being anxious in social situations. He would freeze up and find himself unable to breath (Cue a wonderful drowning scene in the episode). He would then put up a demonic face in response to his anxiousness. This causes many to avoid him… and the vicious cycle continues. Poor boy. Things changed for Yuki when he met Haru, a super energetic (but mysterious) boy who wants him to fish. Yuki wasn't eager to learn how to fish at first but soon became fond of the activity. Along his fishing journey, Yuki met two good pals - Natsuki and Akira. Each of his pals are a little cuckoo but what’s a journey of self improvement without some fun?`}</p>
    <p>{`It’s been a long time since I watched the anime so my memory about the plot is a bit rusty. I definitely remember how strange each scene is in the anime. Strange, silly but good fun. The ending is a feel good ending. Re-reading the plot made me nostalgic... Yea, I might rewatch this show in the near future.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Completed 12-Episode Anime Recommendation #9: Dance in the Vampire Bund`}</h2>
    <p>{`I first found this series from the manga. I think the author is good at drawing poses with thick lines, but definitely not good at drawing flowy panels. Nevertheless, the art is something to behold.. Tantalizing, even. Translated to 2D animation, the story feels more gripping. It felt… personal. It’s a good story with lotsa personal revenge and ideals going on here and there.`}</p>
    <Image alt="Dance in the Vampire Bund." caption="Mina with her maids!" name="dance-in-the-vampire-bund.jpg" mdxType="Image" />
    <p>{`Dance in the Vampire Bund starts off by introducing Mina Tepes, ruler of all vampires revealing herself to the human world. Her sudden emergence along with the existence of vampires, shock the world. In a bid to have both races coexist, she negotiated with the Japanese Government to build a special district “The Bund'' for vampires off the coast of Tokyo. This causes humans fearful of vampires to stir, along with vampire extremists who seem to think otherwise… It is up to Mina along with her werewolf protector, Akira to safeguard the peace of “The Bund”.`}</p>
    <p>{`This is one of those shows that did pseudo-politics and personal gain really well. I like how everything intertwines somehow in the story. The werewolves, the vampires and even the humans. Lots of subplots running in the background that eventually led to where we are in the story. Please give this a try! If you enjoy the anime, try the manga. They even got a few sequels to continue the story, but I enjoy the first story (Dance in the Vampire Bund) the most.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Completed 12-Episode Anime Recommendation #10: Sarazanmai`}</h2>
    <p>{`You know the guy that did Penguindrum, Utena and Yurikuma Arashi? Yup, this is by the same director, Kunihiko Ikuhara! Man, this guy is lit. His works are so unique (and gay). Yup, this show is basically there xD`}</p>
    <Video title="sarazanmai op" src="https://www.youtube.com/embed/3MJZPfuJ9HU" mdxType="Video" />
    <p>{`Three middle school boys are about to get pulled from the ass! (You gotta watch the show… LOL)  The show starts off with Kazuki, Toi and Enta breaking a kappa statue that serves as the guardian god of the district Asakusa. The kappa revealed himself (as Keppi, the prince of the Kappa Kingdom) and punished the three boys by transforming them into kappas. To transform back, they need to help Keppi collect Dishes of Hope. These dishes are procured by collecting the… uh, shirikodama of baddies created by Reo and Mabu, agents of the Otter Empire. Are you following me? I guess not. Here’s my favourite song from the show and it will soon be yours too.`}</p>
    <Video title="sarazanmai op" src="https://www.youtube.com/embed/flROz36Rqv8" mdxType="Video" />
    <p>{`This may seem like a kiddy show but it's intended for adults. Not to say anyone younger can’t watch it, but certain themes may be lost. There’s a lot of smart hints and foreshadowing here and there. Yes, it’s all gay. Gay, gay, gay. But it’s so, so good. A super well done show for what it’s worth.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`All in all`}</h2>
    <p>{`If you’re like me and couldn’t finish 12 anime episodes within a day… Well, welcome to the 30s club. Don’t fret, take your time xD And enjoy these anime recommendations!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      