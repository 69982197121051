import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Blockquote = makeShortcode("Blockquote");
const LocalLink = makeShortcode("LocalLink");
const RemoteLink = makeShortcode("RemoteLink");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`You just finished typing a new blog post and begun adding images into it. It's unlikely that you'll find a blog post out there without one or two images in it. It helps to break up the wall of text and to attract the attention of the readers. Little did you know that those high fidelity images load way too slow for readers with slow Internet speed. Why? The answer is simple - `}<strong parentName="p">{`THE IMAGE SIZE IS TOO BIG`}</strong>{`!`}</p>
    <Blockquote mdxType="Blockquote">
  We are spoiled silly with impressive cameras on our phone. Taking great photos
  is but a tap away on our phone screen.
    </Blockquote>
    <p>{`Try taking a photo on your phone. Import it to your computer, and check the size. Last I checked, my photos always go up to 2MB each. Google's Lighthouse Audit tool which I covered `}<LocalLink to="/blog/handy-tips-and-apps-you-should-know-as-a-blogger/" mdxType="LocalLink">{`here`}</LocalLink>{`, will warn you if your images are more than `}<strong parentName="p">{`1MB`}</strong>{`. If your readers have really slow Internet speed, it might take forever for a page with many images to load and they'll leave. If you don't want your readers to leave, you need to resize your images to be approximately 500KB. There are times you can't do that, because you need to balance between high resolution and size, but the idea here is to make your images reasonably sized (`}<em parentName="p">{`Below 1MB at least`}</em>{`)`}</p>
    <p>{`I usually use Window's`}<strong parentName="p">{` Photos`}</strong>{` software to do the resizing, but I realized this ain't cutting it. I needed more control over how the end results turn out. Generally speaking, all I need are these features:`}</p>
    <ol>
      <li parentName="ol">{`Image proportion is preserved, and I get to choose the image quality.`}</li>
      <li parentName="ol">{`Resizing actually tones down the size (MB) by a lot. If it only reduces the size by 1%, it's useless.`}</li>
    </ol>
    <p>{`I googled and found this - `}<RemoteLink to="https://www.bricelam.net/ImageResizer/" mdxType="RemoteLink">{`Image Resizer for Windows by Brice Lambson.`}</RemoteLink>{` Bless you, Brice. This is by far the easiest and useful tool I've ever used.`}</p>
    <h2>{`A right-click away`}</h2>
    <p>{`What I love about this tool is that you don't need to open the app to use it. You access it through using the right-click menu. I don't need to open an app to use a feature! Ah, bliss. Just right click on an image and you'll see the option right there.`}</p>
    <Image alt="Right click to use the Image Resizer." caption="Right click and voila, it's right there. Fast and easy." name="resize_image_menu.jpg" mdxType="Image" />
    <h2>{`All I ask for`}</h2>
    <p>{`Click on the option `}<em parentName="p">{`Resize pictures`}</em>{` in the right-click menu and a popup should appear. You can choose any of these sizes, or add a size yourself. Then there's this encoder option and you can change the image quality too.`}</p>
    <h2>{`Resizing works pretty well`}</h2>
    <p>{`Mari-chan's `}<a href="/blog/guide-to-choosing-nintendo-switch-grips/">{`latest blog post`}</a>{` had a ton of images taken using her phone. Those images are really big! If there's 5 images in the blog post, then the reader is loading 5MB the first time they load the blog post! Using the Image Resizer, the sizes went from ~1MB to ~100KB. Now you're loading only ~500KB for that blog post.`}</p>
    <Image alt="Proof of Image Resizer's compression rate" caption="Reducing image sizes with a snap" name="resize_image_compression.jpg" mdxType="Image" />
    <h2>{`Project is active`}</h2>
    <p>{`Last I checked, the author of this project is still updating the code base, so it is unlikely it gets abandoned. Check out the project status `}<RemoteLink to="https://github.com/bricelam/ImageResizer" mdxType="RemoteLink">{`here`}</RemoteLink>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      