import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const RemoteLink = makeShortcode("RemoteLink");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The announcement for Season 3 came out of the blue. I knew there are 3 movies titled Sinners of the System (SS). These serve as a filler to explain what happened after S2 and Psycho-Pass: The Movie (the one with Kogami and Akane). What I didn't expect is Psycho-Pass S3 with a pair of new MC(s) AND 45 minutes of absolute goodness per episode. That's right, S3 has 8 episodes and each of them is approximately 45 minutes long. That's crazy! This just oozes "we're going to do this sci-fi crime anime good". Thanks, Production I.G.`}</p>
    <p><em parentName="p">{`Note: This is written after watching Episode 1.`}</em></p>
    <p>{`I went in blind since I did not watch the three SS movies at all. I didn’t know if Akane is in this, or the old cast is included at all in this season. I was really surprised when it started off strong and with a clear goal, no less. The writers clearly spent a ton of effort writing the new casts because every nuance, word and the way these characters move are all in line with their personality. When folks take the effort to write this good, I leap with joy because I know I’m in for a ride.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`A clear motive from the outset`}</h2>
    <p>{`In S1, the strength of the plot lies solely with `}<RemoteLink to="https://psychopass.fandom.com/wiki/Shogo_Makishima" mdxType="RemoteLink">{`Makishima Shogo`}</RemoteLink>{` and the societal havoc he created. While S1’s first episode serves as an introduction to the Psycho-Pass world, the viewers had no idea what to expect for the next episode. If the viewers are interested in the concept, then they’ll stay for the ride. In S3 however, the world of Psycho-Pass is set. Yet, we didn’t know what’s the plot is all about this time. That’s where the 45 minutes of exposition comes in. We were given the news of Akane being locked up for a case she mishandled right from the start. Now our MC is out of the picture, we were introduced to two new detectives - Kei and Arata.`}</p>
    <Image alt="Kei and Arata in the announcement trailer" caption="From the left, Kei and Arata. Our new detective duo." name="psycho-pass-s3-op.jpg" mdxType="Image" />
    <p>{`I was half-expecting them to be characters that would slowly reintroduce us to the state of the plot but nay, these detectives are our new faces now and they are here to stay. They aren’t here to go along with the plot either. This time, both detectives were hinted to have a motive in joining the Public Safety Bureau, and they’ll do whatever it takes to get it done. This really pulled me in, because I knew it had something to do with Arata’s father. But what about Kei? What’s in it for him? Why are they both best buds despite being such polar opposites? Questions kept piling up and by the time the episode’s over, I have already clicked on the next episode.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Dynamic duo`}</h2>
    <p>{`It’s either 1 MC or none at all. It’s tough to write a story with 2 MC(s). You need to give them both ample screentime, and neither should overshadow the other. With limited pages and time, writers will find it a challenge to present two MC equally. In S3, you’ll find none of that. The episode length really helped to develop these two detectives character, and I find that neither overshadows the other. True, at a quick glance the main character is clearly Arata. But you’ll find that Kei too has a stake at hand in this. Both are polar opposites of each other, but they get along so well I thought for sure they are childhood friends. (Maybe they are, I haven’t completed the show yet).`}</p>
    <p>{`Arata is a lackadaisical youth. He addresses things casually and had an open-minded view toward enforcers. Kei, on the other hand, is really stoic. He’s proper and he knew what to say during awkward situations. Kei always back up Arata when he needs to, and Arata would always tell Kei what he sees in people. Both scratch each other’s back really naturally, and it’s a delight to see such friendship on-screen handled so well.`}</p>
    <h2>{`Mental trace is the gimmick for S3`}</h2>
    <p>{`In S1, the focus was on the Dominator. In S2, it’s holographic technology. In S3, it’s mental trace. Each season has it’s own impressive sci-fi tech going on which ultimately leads to furthering the plot naturally. Arata’s mental trace is honestly a smart inclusion to the story. The mental trace’s graphics is uncanny as heck but it fits really well with the aesthetics of Psycho-Pass. We get to dive in to see what happened to the victims as well as their thoughts too. Way better than the time where everyone is just flitting ideas around in `}<RemoteLink to="https://psychopass.fandom.com/wiki/Shion_Karanomori" mdxType="RemoteLink">{`Shion Karanomori`}</RemoteLink>{`’s office.`}</p>
    <Image alt="Arata's father depicted during his mental trace." caption="A devil with goat's horn or just a dog's head? I honestly can't tell." name="psycho-pass-s3-screencap.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`Plot is crystal clear`}</h2>
    <p>{`One of my main complaints about S2 is that I do not know what is going on. What is the theme for this season? Just what does Kirito Kamui hope to achieve? The fact that I had to google for Kamui’s name (I knew he is the main antagonist, but I forgot his name) shows how forgettable S2 is in general. This season, however, the writers’ game has changed. The detective duo is charming, the end-game of the story is hinted to us from the outset and the story paces at a `}<em parentName="p">{`just nice `}</em>{`pace. Each plot piece falls into place nicely and while there’s a hidden plot going on, we knew what to expect. The dialogues aren’t as stupidly cryptic as S2’s and that’s good enough for me.`}</p>
    <h2>{`Hand-to-hand combat is a delight to watch <3`}</h2>
    <p>{`One of the many action scenes I love (in both anime and Hollywood movies) are those that do sensible and believable hand-to-hand combats. Watching two fighters swiping and blocking each other’s strikes is pretty amazing. Honestly, if they could replicate how they did the fight between Kogami and Makishima, I can die happy. But damn, they took it a step further. Camera angles are interesting as they switch around the fighters. And they even replicated the shaky camera effect! I find it hilarious because Hollywood movies would shake the camera to convey dynamism. It’s actually to convince the viewers that these actors are good at fighting when they’re not. Of course, we’re not talking about real-life actors now but 2D characters moving on paper. And I must say, it worked. The camera shake really conveyed the whole urgency of the action scene and it helps to up the amp a lot.`}</p>
    <Image alt="Arata and Kei having a bro moment." caption="Arata backing Kei up. Really good duos. I like them." name="psycho-pass-s3-arata-kei.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`A well-done sequel `}</h2>
    <p>{`I like my Psycho-Pass like a well-done steak. Grilled to perfection when you hit the button at the right timing. Everything is just nicely done in S3. If you haven’t watched it yet, I suggest it’s time to dip your feet!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      