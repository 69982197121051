import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXFanfiction/Chapter/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Divider = makeShortcode("Divider");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`“What the hell’s wrong with you?” Felix berated Dimitri as soon as the twins left the table. Dimitri’s blue eyes were still fixed on the table stain.`}</p>
    <p>{`“I… don’t know, Felix.”`}</p>
    <p>{`“What do you mean you don’t know? You can’t `}<em parentName="p">{`do`}</em>{` that!” Dimitri flinched at Felix’s voice.`}</p>
    <p>{`“I’m sorry, I don’t follow—”`}</p>
    <p>{`“You can’t undress people with your eyes like that, `}<em parentName="p">{`you animal`}</em>{`!” Dimitri finally tore away his gaze from the table and buried his face in his hands.`}</p>
    <p>{`He did that, didn’t he? Undress Byleth with his eyes right in front of her brother, who incidentally had gotten expelled for fighting.`}</p>
    <p>{`For his sister, no less.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Oh god, what had he done? Dimitri’s face sank further into his palms, trying as he might to hide the fact that he had probably just doomed himself right then and there. Sylvain looked pleased as punch though; he was laughing so much he gasped for air.`}</p>
    <p>{`“I’d never thought”—Sylvain wheezed between chortles—“I’d see the day Dimitri ogle at someone like that—I can’t `}<em parentName="p">{`breathe!`}</em>{`” Sylvain, laughing till his body shook the table, clutched onto Felix’s shoulder for support. The latter rolled his eyes in obvious contempt for the redhead gasping like a goldfish and Dimitri’s incompetence at handling the situation.`}</p>
    <p>{`Felix rubbed his temples. “I literally had to stop Sylvain from physically shutting your jaw when she was eating.” Dimitri had never wished for someone to strangle him more than ever. “So much for your, `}<em parentName="p">{`‘I don’t form romantic attachments this fast’`}</em>{` speech,” Felix mocked. Sylvain was already in tears.`}</p>
    <p>{`“To be fair, this is not the first time Dimitri has met Byleth so…” Ashe chipped in quietly.`}</p>
    <p>{`Dimitri looked up and nodded. “Y-yes! It’s not! It’s just you know, I uh….”`}</p>
    <p>{`Sylvain leaned in on his elbow and smirked hard at him. “You fell in love at second sight?”`}</p>
    <p>{`“Yes—No! I did not fall in love with anyone!”`}</p>
    <p>{`“Not yet, you’re at the crush stage now,” Sylvain clarified. So overwhelmed with emotions was Dimitri that he didn’t know what was what.`}</p>
    <p>{`She looked different from the newspaper cutting he had and the camera didn’t do her justice. She was `}<em parentName="p">{`much`}</em>{` nicer looking in person. It was his first time officially meeting Byleth asides from shaking her hands post-tournament—yet he felt like he’d seen that blank stare from somewhere else. Dimitri groaned. Just when he had gotten rid of this nagging sense of familiarity, it was back.`}</p>
    <p>{`Dimitri stepped out of the cafeteria into the veranda for some fresh air, while Sylvain and Ashe left to catch the shuttle bus into town. Hushed voices came from below. Dimitri’s eyes widened and his heart skipped so many beats he was starting to feel faint.`}</p>
    <p>{`From where he stood, he could see Byleth and Reus chatting on the brick steps outside the greenhouse. They looked to be deep in conversation and Dimitri couldn’t help but admire her serious expression. Again, the pestering feeling scratched at his chest. It was the same when he saw her eat. He cringed. Perhaps Byleth was not the observant sort to have noticed his impolite stares.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Are you alright?” Dimitri turned around at Dedue’s voice. He nodded. Dedue turned his attention to the greenhouse, and chuckled. “From my observations, I must agree with Sylvain and Felix. Your conduct, while unsightly, was a nice change.”`}</p>
    <p>{`Dimitri looked at Dedue in shock. “Truly?”`}</p>
    <p>{`Dedue nodded. “If you must, don’t be ashamed to strike up a conversation with her. From what I gather, she’s leaving tomorrow.”`}</p>
    <p>{`“After how I behaved? Dedue, I’m `}<em parentName="p">{`this`}</em>{` close from throwing myself into the lake and—”`}</p>
    <p>{`“Opportunities like this don’t come often for boys like us who attend boarding schools. I doubt Reus would mind if you approached her with tact.”`}</p>
    <p>{`“Honestly I was surprised he was so accepting of Sylvain’s blatant flirting.” He definitely wouldn’t do as much damage as Sylvain. Besides, he was just going to chat about… about their tournament together! Ah, he was thankful for a topic to discuss. He would, of course, focus on something or somewhere else that wasn’t her. He didn’t know where to put his eyes exactly.`}</p>
    <p>{`So he had a fleeting infatuation for her. But it would all go away once she left.`}</p>
    <p>{`He was sure of it.`}</p>
    <Divider mdxType="Divider" />
    <p>{`“Wow, she really is gorgeous in person and uh, very `}<em parentName="p">{`endowed`}</em>{`.” Byleth zoomed into the photo of Manuela on Reus’s phone. “They look real enough.”`}</p>
    <p>{`“Of course they’re real.” Reus scoffed at her.`}</p>
    <p>{`“How would you know?” She smirked, handing the phone back to him.`}</p>
    <p>{`“She gave me a hug and it— it hung naturally. I don’t know how to describe it,” he said woefully and Byleth regretted asking, nearly gagging.`}</p>
    <p>{`Reus cleared his throat. “Anyway, you seem to get along well with the boys. And that Dimitri-boy seems to have a crush on you.” He grinned. “I mean, did you see the way he looked at you, `}<em parentName="p">{`Miss Eisner`}</em>{`?” She slapped him on the arm.`}</p>
    <p>{`“Shut up, he doesn’t. He’s just… shy.” From the little she spent with him the entire week, she didn’t know Dimitri was capable of such a hot gaze. It made her heart beat funny.`}</p>
    <p>{`“And `}<em parentName="p">{`horny`}</em>{`.” Reus let out a hearty laugh.`}</p>
    <p>{`Byleth used force in her punch this time, hitting Reus so hard on his arm he went sideways, which only made him laugh harder.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Why didn’t you punch him or any of them anyway?” She was genuinely curious at his behaviour today. Reus had to take a few deep breaths to calm himself down at her question.`}</p>
    <p>{`“That’s because you’ve lived with them for a week. If anything, I thought you would’ve punched that Sylvain guy for flirting so hard.”`}</p>
    <p>{`Byleth cocked her head sideways. “Then why did you bite that other guy?”`}</p>
    <p>{`“Because you weren’t there to defend yourself,” Reus said as a matter of factly. “Actually, Sylvain is harmless. It’s Dimitri who seemed quite enamoured with you.”`}</p>
    <p>{`She let out a small grunt, but then suddenly remembered her night in the closet with Dimitri’s face on her chest, and his pale lips almost on hers the few times he moved within that terribly enclosed space.`}</p>
    <p>{`Her cheeks turned pink. Great, now she was feeling weird.`}</p>
    <p>{`“Like I said, he’s shy! I mean these guys live together, in an all boys boarding school! I imagine the opportunity to meet girls is rare.”`}</p>
    <p>{`“By the way, you haven’t told me about what happened during your investigation.” Reus tapped on his phone. “Did you get anything else besides these screenshots?”`}</p>
    <p>{`Was Dimitri the shy type though? She tried remembering how he’d acted when they matched against each other during the tournament. Try as she did, all she could remember was Dimitri’s body warmth in between her legs—`}</p>
    <p>{`Byleth shook her head trying to get the thoughts of Dimitri out of her head, baffling Reus.`}</p>
    <p>{`“Nothing? Really?”`}</p>
    <p>{`“Sorry, I’m trying to remember. Let’s see now...” Byleth started explaining how she snuck out as usual, how Dimitri had followed her all the way just to ask her something trivial, and how she hid in a closet while the coach was drunkenly admiring the cash on his computer.`}</p>
    <p>{`“Where was Dimitri?”`}</p>
    <p>{`“Uh, hiding.” Byleth looked away.`}</p>
    <p>{`“Yeah, `}<em parentName="p">{`where`}</em>{`?”`}</p>
    <Ads mdxType="Ads" />
    <p>{`Byleth knew she wouldn’t be able to avoid it.`}</p>
    <p>{`“In the cabinet. With me.” Reus’s blank expression perked up slightly at her words. She rolled her eyes. “Nothing happened, okay? He was just—”`}</p>
    <p>{`“How big was the cabinet?”`}</p>
    <p>{`“Big enough for two people? I don’t know it was dark.”`}</p>
    <p>{`“So the both of you were in close proximity to each other. No wonder he’s all flustered.”`}</p>
    <p>{`“Reus, I was you. Dimitri doesn’t know I am or rather, `}<em parentName="p">{`was`}</em>{` you.”`}</p>
    <p>{`He smacked his forehead. “Did you accidentally kiss or something?”`}</p>
    <p>{`“No!” She hit him again. “What’s with you and Dimitri?”`}</p>
    <p>{`“He’s just so fun to tease!”`}</p>
    <p>{`She suddenly remembered his ‘Boar’ mode during their training and shuddered. “Mm, better not do it too much, he has a beast mode.”`}</p>
    <p>{`“Riiight, okay go on—Oh no, wait.” Reus looked over her shoulder behind her. He grinned. “Speak of the devil, this guy is relentless. Man, he’s going to figure out the truth soon if he keeps coming to look at you.”`}</p>
    <p>{`She turned her head and saw Dimitri striding towards them. Were his legs always so long? She shot Reus a glare for making her hyper aware of Dimitri, who just stared blankly back at her.`}</p>
    <p>{`“You have to discuss `}<em parentName="p">{`it`}</em>{` with him,” she quickly whispered to Reus.`}</p>
    <p>{`“Discuss what—ah, right. Gotcha.” He nodded slightly and Byleth adjusted herself to look all dainty and innocent. Nothing suspicious was happening here, just a couple of twins talking quietly outside a greenhouse in the hot afternoon sun. Totally inconspicuous.`}</p>
    <p>{`“Yo.” Reus waved. “We were just talking about you!”`}</p>
    <p>{`Byleth almost laughed when Dimitri stopped dead in his tracks and his face drained of colour. He was so expressive it was endearing.`}</p>
    <p>{`Reus scooted away from Byleth and patted the space between them, inviting Dimitri to sit. But Dimitri didn’t move, the colour rushed back to his cheeks. Reus stood and forcefully sat him down next to Byleth, whose almost bare leg brushed against his as he sat. The spot where their skin touched burned, he emitted so much body heat it was as if Dimitri was a furnace.`}</p>
    <p>{`“Are you okay?” She looked at him. “You look really red.” As if on cue his face turned even brighter than before.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“I-I’m fine thank you, Miss Byleth,” Dimitri replied, his legs too long for the bricked step they sat on. He held his knees to his chest. He looked like a giant child.`}</p>
    <p>{`“`}<em parentName="p">{`Byleth`}</em>{`,” she corrected him and smiled.`}</p>
    <p>{`“Sorry, B-Byleth.” He turned his face away from her only to be greeted with a smirk from Reus, absolutely amused by everything in front of him.`}</p>
    <p>{`“Relax.” Reus squeezed his shoulders. “Dude, these are some serious deltoids. Anyway, we have to talk about that night.” Dimitri looked at him puzzled. “The one me and you almost kissed in the cabinet.”`}</p>
    <p>{`It was as if her warning about his beast mode went in one ear and out the other. Byleth peeked from behind Dimitri to do a throat-slitting gesture at Reus, meaning both ‘cut it out’ and ‘I’ll kill you’.`}</p>
    <p>{`“O-oh. Um. Yes I have been meaning to speak to you about it as well.” Dimitri cleared his throat. “I assume your plan is to meet up with Principal Rhea about this?”`}</p>
    <p>{`“Um... well uh, y-you’ve been here longer, what do you think we should do?”`}</p>
    <p>{`Byleth had to admit, her brother was pretty good at keeping the act up despite not knowing what was actually going on. It even got Dimitri thinking hard about his question.`}</p>
    <p>{`“Based on these pictures you’ve sent me, `}<em parentName="p">{`Reus`}</em>{`.” She interjected, throwing a glance at him who looked appreciative. “Do you have any proof your coach plans to spend or withhold the money? I mean, you’ve only sent me pictures of his chats online with someone telling him to keep the money in his account. You could’ve taken some form of video evidence of this coach.”`}</p>
    <p>{`“That’s a great question, By,” Reus said, almost glaring at her.`}</p>
    <p>{`“My sincerest apologies… Byleth, it wasn’t the most opportune time for us to do so. Plus…” Dimitri threw a quick glance at Reus and he looked away again, abashed. “We were quite preoccupied.”`}</p>
    <p>{`“Anyway, we’re doing this for you, Byleth—right Dimitri?” Reus nudged the blonde.`}</p>
    <p>{`“Yes, we are. I promise you, we’ll get to the bottom of this.” Dimitri shot her such a fiery gaze it made her heart skip a beat, spreading fire across her chest right down to her toes.`}</p>
    <p>{`“I’m counting on you.” Byleth meant what she said. Dimitri didn’t know it yet, but she was his Reus.`}</p>
    <p>{`“So I suppose this means we need to break into coach’s office again for more evidence? It’s difficult, seeing as he’s there almost nightly according to the security guard.” Dimitri looked at Reus for confirmation. Who then looked at Byleth for help, again.`}</p>
    <p>{`“What I think you guys should do is… uh,” Byleth started, “Is uh…” Reus waved his hand in circles as he prompted her.`}</p>
    <p>{`“... Is to do interviews with everyone who was there to see if they saw anything… ?” she slowly said, with Reus repeating after her with an added flourish.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Dimitri didn't seem to notice the charade happening behind him and nodded in agreement. “That’s a good start. Gathering eye witnesses is a wise idea. I’m just worried if they’ll cooperate since Coach Kostas is not a kind man, but neither will having the sponsorship taken away from the winning team be of any help.” Dimitri rubbed his chin in thought.`}</p>
    <p>{`Reus leaned back on a step. “I guess just telling them the coach is potentially stealing money and that you’re trying to investigate will make anyone feel righteous enough to help.”`}</p>
    <p>{`Dimitri hummed, now tapping his chin. “I’d like to think about this,” he said.`}</p>
    <p>{`“Don’t take too long though, the money might be gone soon,” Reus added.`}</p>
    <p>{`“I agree, time is of the essence. I will have an answer and solution for you soon.” Dimitri turned to Byleth. “Please rest assured that I intend to keep my promise.”`}</p>
    <p>{`She smiled at him in thanks. “By the way, Dimitri, my brother mentioned you have a newspaper cutting of our tournament. May I take a picture of it? I’d like to show my dad — I didn’t keep any since I was kind of distraught over the incident. Plus, we haven’t seen him in a couple of months so...”`}</p>
    <p>{`“Sure, I’d happily make you a copy as well, if you’d like.”`}</p>
    <p>{`“Oh, that’s fine. I wouldn’t want to trouble you—”`}</p>
    <p>{`“It would be my pleasure.” She blushed at the way he said pleasure, and nodded meekly. He continued while gesturing at Reus, “Reus also mentioned that you saw something suspicious that day?”`}</p>
    <p>{`“Yes” She closed her eyes for a second to remember, it was a little bit fuzzy now. “I think your coach was speaking to the referee outside in a corner near, the men’s changing room I suppose. It was pure coincidence—I was on my way back from the bathroom when I heard them talking.”`}</p>
    <p>{`“That’s not really suspicious per se though.” Reus raised an eyebrow.`}</p>
    <p>{`She nodded. “But it’s what I heard—the referee said something along the lines of ‘don’t worry, I’ll make sure it’s not an obvious mistake’. At the time I didn’t think much of it, but after we lost over some stupid foul… I couldn’t help but think this had something to do with it.”`}</p>
    <p>{`Dimitri stood up suddenly.`}</p>
    <p>{`“I’d like to formally apologize for accepting the win so easily, Miss Byleth.” He bowed deeply. “I-I knew something was wrong—I, too, thought the foul didn’t exist. But the referee was so insistent, I-I didn’t want to seem pretentious, and my team was already rejoicing at the victory.”`}</p>
    <p>{`“Please—please raise your head.” She put her hands on his shoulders to push him up and boy Reus was right, he did have nice deltoids. She could feel and see his chiseled muscles under the sleeveless gym shirt he wore.`}</p>
    <p>{`When he straightened, their eyes met and she felt a shock of electricity run from her fingertips and down her spine. They held each other’s gaze for a good long second until she heard something like a hackling old man, but it was just Reus clearing his throat. She promptly released Dimitri’s shoulder.`}</p>
    <Ads mdxType="Ads" />
    <p>{`They both looked away from each other, flustered and heart beating out of control, or for Byleth at least. Reus had cleared his throat so much that he started coughing. On impulse she went over to her twin to rub his back, but so did Dimitri and their fingers touched on Reus’s back. Until Reus was frantically tapping at her knee.`}</p>
    <p>{`“`}<em parentName="p">{`Help—`}</em>{`” She shook her head free of pesky romantic feelings and continued rubbing his back as Dimitri ran to the cafeteria to get water. In two minutes he was back and cracked open the bottle for a grateful Reus, who gulped it down.`}</p>
    <p>{`When Reus had settled, she turned to Dimitri and avoided his piercing blue eyes. “I-It’s okay. I don’t blame you to be honest. I don’t see how it would’ve worked out even if you had spoken up about it,” she said thoughtfully.`}</p>
    <p>{`“That’s true, but still my sentiments remain the same.” Chivalry would be the death of her, she thought, so close to bashing her head against Reus’s back to force whatever romantic ghost that seemed to be possessing her.`}</p>
    <p>{`Reus was about to say something else when Felix sauntered up to them.`}</p>
    <p>{`“Reus,” he said cooly, and both twins turned around. “The R.A wants to see you and your sister.”`}</p>
    <p>{`“Thanks bro.” Reus stood and patted Felix’s shoulder in acknowledgement, who grunted a response.`}</p>
    <p>{`Byleth got up as well, dusted debris off her and was about to walk off with Reus, when she heard Felix growl at Dimitri, ‘Boar, stop staring and ask for her number’. She panicked a little as he did indeed have her number—as Reus. But with each step Byleth and Reus took, it didn’t seem like Dimitri was going to ask so she glanced back to see Felix berating him. She couldn’t help but smile at their friendship.`}</p>
    <Divider mdxType="Divider" />
    <p>{`It turned out that Mr. Alois had a room prepared for Jeralt, but insisted Byleth used it as their dad already had a hotel room. It was free, so Byleth thought she might as well utilize it and texted their dad that they would meet him at the bus station, instead of him going out of his way to get them.`}</p>
    <p>{`“I’ve not been back here in years. It still smells the same!” Jeralt exclaimed as he browsed the menu. Dinner had been lively—their dad recounted new memories of their mother, brought back by the sight and smells of Garreg Mach.`}</p>
    <p>{`During dessert, Byleth brought out a copy of the newspaper clipping Dimitri had made for her.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Here, dad. We lost, but I know you like keeping stuff like this.”`}</p>
    <p>{`Jeralt grabbed it and squinted. “Which one’s you? And Reus, did you go and support your sister?” Byleth leaned over and pointed a finger at her face in the picture of the teams that participated that day.`}</p>
    <p>{`“Of course, I had nothing else to do after getting expelled anyway.” Reus shrugged.`}</p>
    <p>{`Byleth continued shoveling spoonfuls of ice-cream as her brother and father talked, when her dad’s expression suddenly turned pale.`}</p>
    <p>{`“It can’t be…” He said bringing the newspaper clipping closer to his face.`}</p>
    <p>{`“What?” Byleth and Reus said simultaneously.`}</p>
    <p>{`“Who’s this man in the tournament?” Jeralt jabbed a stubby finger at the image of a semi-bald, hunched-over old man.`}</p>
    <p>{`“I think that's the referee.” Byleth knew exactly who it was though, it was the same man who was talking to Coach Kostas. “Why? Do you know him?”`}</p>
    <p>{`“Know him?” Jeralt scoffed. “That’s the old bastard that tried to assault your mother in the staff room when I met her, remember?” Both her and Reus looked at each other gravely.`}</p>
    <p>{`“Well uh, h-how is he still able to be a referee? Shouldn’t he be banned from the sport or something?” Reus asked.`}</p>
    <p>{`“If I’m not mistaken, your mother didn’t actually manage to get him to confess his crime for the whole fraud thing. He was fired for attempted assault on a co-worker instead. As for his association with your sport, it seems he’s still running free.” Jeraly folded the newspaper clipping and stuffed it into his pocket.`}</p>
    <p>{`Byleth bit her bottom lip. Would it be a wise choice telling her father the truth? About her being here at Garreg Mach snooping around disguised as her brother. Reus seemed to have guessed what she was saying and nodded. Byleth took a deep breath.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Uh, dad? I have something to tell you…”`}</p>
    <p>{`Jeralt looked up. “What? You’re not pregnant are you?” He slapped his thigh, laughing at his own joke.`}</p>
    <p>{`“Goodness no—dad, this is serious.”`}</p>
    <p>{`“Okay, okay. What’s it?” He set his ice-cream spoon down.`}</p>
    <p>{`“How should I say this… I’m… actually not visiting Reus. I’ve become Reus,” she said slowly, gauging his expression, which was still neutral albeit slightly confused. “What I’m trying to say is, I`}{`'`}{`ve been impersonating Reus. The whole tournament thing has been messed up, I saw something that confirmed my suspicions that the games were rigged. That the foul didn’t exist. That my team should’ve rightfully won. I’m not a sore loser, but what you just said about the man—what’s his name?”`}</p>
    <p>{`“Uh, I think it was Thomas? No, Tomas. Yeah, that’s his name. Tomas.”`}</p>
    <p>{`Byleth then continued her explanation of how she saw a shady exchange of words, how she’s been investigating the case with Dimitri, her short hair, why Reus was here.`}</p>
    <p>{`Jeralt merely kept quiet the whole time, seemingly contemplating all this new information.`}</p>
    <p>{`“This could potentially be dangerous, kid. Are you sure you don’t want Reus to continue your investigation for you?” he finally said.`}</p>
    <p>{`“I could,” Reus spoke up. “But this is her investigation—she’s already been here a week and she seems to be holding her own really well. I’m sure she wants to see it through to the end.”`}</p>
    <p>{`Byleth nodded.`}</p>
    <p>{`“Gah, I know that look. Nothing is going to change your mind is it?” Their dad relented. She was surprised, honestly. She didn’t think it’d be that easy to convince him. “I kinda knew something was off actually. The two of you were sneaking around doing your... twin thing.” He laughed. “And I think it’s nice that you're indirectly ‘avenging’ your mother by catching this guy—if he really is part of this whole sham.”`}</p>
    <p>{`“It is weird, isn’t it? To think that we’re facing the same man that was faced before we were born,” Reus commented and Byleth smiled, feeling a new sense of closeness to her mother. But if this referee was indeed the man their mother tried to catch, then she had inadvertently lured a bigger fish than she’d thought. But her fishing rod was barely enough, and her bait meager. A greedy fish might just topple her boat over, and everyone in it.`}</p>
    <p>{`“You’re not worried about Reus or me being kicked out or anything?” she asked. Perhaps her dad was right, and she should hand over everything to Reus—`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Not one bit. I uh, hate to brag, but I have connections here. I’m sure Rhea would love absolutely nothing but to weed these people out. If you haven’t met her, well, she’s very… righteous. Besides, your school back in Faerghus would be pleased to know that more of their students would be getting the scholarship to Garreg Mach University.”`}</p>
    <p>{`“Easily said than done,” she said, crossing her arms.`}</p>
    <p>{`“Trust me, schools are stupidly competitive. However, you do know that Reus would have to present your evidence—as in Reus himself?”`}</p>
    <p>{`“Fret not, father dearest.” Reus smirked. “We’ve got it all worked out.” The twins fist-bumped without looking at each other.`}</p>
    <p>{`They continued throughout the night discussing their plans and how Byleth would go forward with it. Seemingly satisfied, Jeralt made them promise they’d update him about the whole thing. Jeralt drove them back to their school and said he’d see Reus in the morning, or whoever was being Reus the next day as he was going to thank Principal Rhea for accepting his brat of a son. Or daughter.`}</p>
    <Divider mdxType="Divider" />
    <p>{`Dimitri stared at his ceiling after dinner. It didn’t feel long but when Sylvain came to lie down next to him, and whispered, “You’ve been lovesick for the past 2 hours” he jumped out of his bed and realised he hadn’t even taken his shower after dinner.`}</p>
    <p>{`“Aww, come back to bed, baby.” Sylvain cooed at him and tapped his bed. Dimitri made a face.`}</p>
    <p>{`“I told you to get her number, you ass,” Felix said loudly from his end of the room. Sigh. Yes, Dimitri was regretting now.`}</p>
    <p>{`Sylvain sat up straight and glowered at Dimitri. “What—you didn’t get her number?” He shook Dimitri, repeating himself louder, “Dimitri Alexandre Blaiddyd, you didn’t ask for her number?!”`}</p>
    <p>{`Dimitri shook his head woefully.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“He could always ask Reus though, couldn’t he?” Ashe walked over to his bed and sat on it. “Judging by how he acted at lunch, I don’t think he’d mind.”`}</p>
    <p>{`“Oh my sweet boy, no.” Sylvain let Dimitri go and now went to harass Ashe. “If he does that, how is she supposed to know he’s interested in her?” Sylvain had a point`}</p>
    <p>{`“How would she know, you say?” Felix scoffed. “When I passed them the message, Dimitri was already naming their children in his head.” Even Dedue cracked a smile. Even if the school burned down, Dimitri didn’t care anymore. He was determined to never move his head from where it was now; connected to the wall. Felix was spot on.`}</p>
    <p>{`“So she’s aware of his emotions.” Dedue nodded. “Did she seem acceptive of it?”`}</p>
    <p>{`Dimitri lifted his head from the wall. “I don’t know. I-I couldn’t think—”`}</p>
    <p>{`“All she has to do is sigh in his direction and he’ll be at her feet,” Sylvain concluded with the biggest smirk on his face, Ashe nodded.`}</p>
    <p>{`“She’s extremely similar to Reus in terms of behaviour and expression, but I think she’s nice. A-and she fights well!” Ashe turned to Dimitri. Dimitri was absolutely sure his infatuation for her would go away as soon as she left. But the little touches they shared had set him on fire.`}</p>
    <p>{`While deep in thought, the door knob turned and Reus emerged. Dimitri’s heart sank—he was half hoping it would be Byleth, as impossible as that was.`}</p>
    <p>{`“Dimitri has a crush on your sister!” Sylvain declared and immediately jumped off Dimitri’s bed and hid behind Felix.`}</p>
    <p>{`“Get off me!” The shorter male growled, shrugging Sylvain off like a dirty coat.`}</p>
    <p>{`Dimitri stood completely still in horror as he watched for changes in Reus’s expression, accepting that he would probably die tonight, surrounded by his friends-turned-traitors—`}</p>
    <Ads mdxType="Ads" />
    <p>{`“You’d have to be blind `}<em parentName="p">{`not`}</em>{` to see that.” Reus grinned at him. “You should’ve seen him around my sister.” Reus peeled off his shirt and tossed it on his bed, the rest of his dorm mates trying their hardest not to die laughing. Dimitri was not only shocked at his words, but the fact that Reus was finally somewhat naked around them.`}</p>
    <p>{`“I’d like to apologize for my awful behaviour. I-I didn’t know what came over me.” Despite the meek apology, Dimitri was approaching Reus with suspicion. Sylvain emerged from behind Dedue who was his new hiding spot, and slowly made his way to Reus putting an arm around the topless mint-haired male.`}</p>
    <p>{`“You don’t mind this guy having a crush on your sister? Didn’t you beat a guy up for saying something about her? You mean you’re not the over-protective brother we all know and love?” Everyone was paying attention now.`}</p>
    <p>{`“Didn’t I tell you what that ass said about her? You would’ve beaten the guy up too if you heard what he said. At any rate, she can handle her own romances. Didn’t she sweep the floor with one of you guys at the tournament?” Reus looked at them. Dimitri sheepishly put his hand up.`}</p>
    <p>{`“But I must clarify that our scores were tied.” He was extremely impressed by her though. She held her own against his own admittedly monstrous strength.`}</p>
    <p>{`“I’m gonna shower, see you guys later.” Reus stood up and looked for his shower bucket. Dimitri then remembered he had to shower, offering to accompany Reus. The others simply stared in disbelief as the two left the room.`}</p>
    <p>{`Reus seemed completely at ease with himself, stripping his clothes without a single care. He was also very well endowed `}<em parentName="p">{`there`}</em>{`, along with a toned stomach and muscular legs. Dimitri wondered just what was he suspecting Reus of a couple of days ago? Something about Reus never being naked in front of them? He couldn’t remember anymore. Reus even used the urinals on the way back.`}</p>
    <p>{`He still hadn’t worked up the courage to ask for Byleth’s number though. It was an infatuation and he was going to prove it was.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      