import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../../styles/globals';

const Section = styled.section``;

const Container = styled.div`
  padding: 30px 0;
  text-align: center;
`;

const InnerContainer = styled.div`
  margin: 0 20px;
  padding: 20px;
  border: 2px dashed ${$.brown5};
  width: calc(100% - 80px);
  height: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 200px;
`;

const Title = styled.h1`
  font-family: Porkys !important;
  font-size: 50px !important;
  color: ${$.brown4};
  letter-spacing: 1px;
  margin-bottom: ${$.layout.margin4}px;

  ${$.screen.mobile} {
    font-size: 40px !important;
  }
`;

const Description = styled.h2`
  font-family: Rubik Regular !important;
  color: ${$.color.brown4};
  font-size: 18px !important;
  margin-bottom: 35px;
`;

const TotalCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${$.color.green2};

  > span {
    font-size: 50px;
  }

  > div {
    font-size: 16px;
  }

  ${$.screen.mobile} {
    > span {
      font-size: 40px;
    }
  }
`;

const Banner = ({ total }) => (
  <Section>
    <Container>
      <InnerContainer>
        <Title>Blog Index</Title>
        <Description>Check out all of our blog posts here!</Description>
        <TotalCard>
          <span>{total}</span>
          <div>blog posts</div>
        </TotalCard>
      </InnerContainer>
    </Container>
  </Section>
);

Banner.defaultProps = {
  total: 0,
};

Banner.propTypes = {
  total: PropTypes.number,
};

export default Banner;
