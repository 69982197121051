import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const RemoteLink = makeShortcode("RemoteLink");
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const Blockquote = makeShortcode("Blockquote");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Pokémon is the world's highest-grossing media franchises, and it seems unlikely that any other franchises can `}<RemoteLink to="https://digg.com/2019/highest-grossing-media-franchise-chart" mdxType="RemoteLink">{`beat it`}</RemoteLink>{`. If this is surprising to you, then take a look at this `}<RemoteLink to="https://sea.ign.com/switch/155571/news/pokemon-sword-and-shield-is-now-the-fastest-selling-switch-game" mdxType="RemoteLink">{`article`}</RemoteLink>{`. They sold 6M of Pokémon Sword and Shield copies within the first week of sales! It's clearly a franchise loved on an unprecedented scale globally. As the sole driver of these numbers, Game Freak will have to think twice about how they should go about developing the games.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Much is at stake for every new Pokémon generation Game Freak dishes out. If you've noticed, new games will bring about new gimmicks and Pokémon. Using those designs, the Pokémon Company would sell merchandises, create anime and even movies from it. That's how they earn their moolahs. Refer to this infographics to get a good sense of `}<RemoteLink to="https://www.visualcapitalist.com/successful-media-franchises/" mdxType="RemoteLink">{`how media franchises earn their cash`}</RemoteLink>{`.`}</p>
    <p>{`However, business reasoning aside, I believe there are other reasons why Game Freak still won't shy from their old formula.`}</p>
    <p>{`Note: All images are screenshots I took while playing Pokemon Shield on my Nintendo Switch.`}</p>
    <h2>{`The essence of Pokémon games is the sense of childhood wonder`}</h2>
    <p>{`Many fans were clamouring for something new. Give us an open-world game, they say. But that's not what Pokémon is all about. Pokémon wasn't striving to become a big, cool game where you go around solving side quests. The core essence of Pokémon games wasn't freedom, but a feeling of child-like excitement as you explore the region. `}<strong parentName="p">{`It was always striving to become a game close to your childhood and to let you relive the sense of wonder as you adventure across the region.`}</strong></p>
    <Ads mdxType="Ads" />
    <Image alt="One of the beautiful towns in Pokémon Sword and Shield" caption="One of the beautiful towns in Pokémon Sword and Shield" name="pokemon_open_world_2.jpg" mdxType="Image" />
    <p>{`This is part of the reason why Pokémon will never go for super-realistic designs. Characters are always drawn cartoonish, and the game's environment design gives a sense of awe and wonders through colours and creativity as opposed to realistic designs. Pokémon designs too, are "cool" and fascinating to learn of.`}</p>
    <Blockquote mdxType="Blockquote">
  It's supposed to be a world the child in you can enjoy happily.
    </Blockquote>
    <p>{`This is something I believe Game Freak knew, and how they managed to ensnare fans again and again.`}</p>
    <Image alt="You battled many trainers and now, you are finally here. The Champion Cup awaits!" caption="You battled many trainers and now, you are finally here. The Champion Cup awaits!" name="pokemon_open_world_1.jpg" mdxType="Image" />
    <h2>{`Still a kid's RPG at heart`}</h2>
    <p>{`Role-playing games are easy to understand. You leave your comfortable home to do something great that no one else has ever done. You encountered friends along the way that supports your endeavour. They are great folks, and everyone in your party is different from one another. You go through thick and thin to overcome the hurdle that is the big baddie, and your goal from the start of the game never changed. Of course, you'll definitely win in the end.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Dynamax all you want Nessa. I'll still win in the end." caption="Dynamax all you want Nessa. I'll still win in the end." name="pokemon_open_world_3.jpg" mdxType="Image" />
    <p>{`What I just described can be applied to almost all RPG. It is simple, and a tried formula that engages children easily. While Game Freak has done `}<em parentName="p">{`some`}</em>{` homework to cater to the older fans, Pokémon is still a kid's game. It relies on newer fans to continue holding onto its title of No. 1 Highest Grossing Media franchise.One can argue that open-world games are pretty much the norm now, and kids aren't as gullible as they seem. However, introducing Pokémon as an open world would introduce a plethora of controls and mechanics. That's not what a kid would want to play. They want something simple to get into, and it must get them started in the game quickly. Short and sweet, but fun is the key.`}</p>
    <h2>{`Making expectations easy to beat`}</h2>
    <p>{`Despite the Wild Area being as "open world" as it can be in `}<em parentName="p">{`Game Freak terms`}</em>{`, we fans don't think of it as one. It lacks both size and freedom. To be frank, it's just a bigger and wider, winding paths you take to the next town. Generally speaking, if Game Freak can do a `}<em parentName="p">{`wild area`}</em>{`, then why aren't they doing it on a bigger scale?`}</p>
    <Blockquote mdxType="Blockquote">
  Creating a better game increases the expectation of fans.
    </Blockquote>
    <p>{`Pokémon has always been an average game, and I don't see this pattern changing anytime soon. To keep the franchise going, there need to be folks buying the said game. It boosts merchandises, trading card games and BD/CD/DVD sales. We aren't talking about companies that just churns out 1 sequel and move on to the next IP. `}<strong parentName="p">{`We are talking about a global franchise depending on a singular point (Pokémon game) to renew interest in the franchise as a whole!`}</strong>{` The game `}<strong parentName="p">{`needs`}</strong>{` to be average so the fans would buy the next one. Are we being strung along? Yes.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Then why are still we fans of Pokémon?`}</h2>
    <p>{`There's a child in you that Pokémon satisfies. There are a ton of games rated `}<strong parentName="p">{`M`}</strong>{` or `}<strong parentName="p">{`T`}</strong>{` out there that fulfils the niche for you. These games have some really fun mechanics, and the satisfaction just clicks for you. You get to experiment with a ton of meta, and the game's graphics just outright trumps a Pokémon game.`}</p>
    <Blockquote mdxType="Blockquote">
  However, nothing fills that oddly specific spot like a Pokémon game would.
    </Blockquote>
    <p>{`Thank you for reading.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      