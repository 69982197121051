import styled from 'styled-components';

import $ from '../../styles/globals';
import Title from './Title';
import NabeChanCard from './NabeChanCard';
// import MariChanCard from './MariChanCard';

const Section = styled.section``;

const Container = styled.div`
  padding: ${$.layout.padding7}px 0;
`;

const About = () => (
  <Section>
    <Container>
      <Title />
      <NabeChanCard />
      {/* <MariChanCard /> */}
    </Container>
  </Section>
);

export default About;
