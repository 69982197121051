import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../styles/globals';

const Container = styled.div`
  width: 100%;
  > svg {
    filter: drop-shadow(${$.shadow.normal});
    width: 70%;
    display: block;
    margin: ${$.layout.margin4}px auto 10px auto;
  }

  ${$.screen.mobile} {
    > svg {
      width: 100%;
      margin: ${$.layout.margin4}px 0 0 0;
    }
  }

  ${$.screen.mobile} {
    > svg {
      width: 80%;
      display: block;
      margin: ${$.layout.margin4}px auto 10px auto;
    }
  }
`;

const Bubble = ({ className, image }) => (
  <Container className={className}>
    {typeof image === 'string' ? (
      <img src={image} alt="mari-chan's profile pic" />
    ) : (
      image
    )}
  </Container>
);

Bubble.defaultProps = {
  className: '',
};

Bubble.propTypes = {
  className: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
};

export default Bubble;
