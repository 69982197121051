import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Cardcaptor Sakura is a `}<strong parentName="p">{`great shoujo anime`}</strong>{` for young girls back when it was first aired on TV during the year 1998. It inspired me, it inspired my girl friends. My gang back then `}<em parentName="p">{`loves`}</em>{` Sailor Moon. We really took the show to heart, especially on the whole idea of a group of girls who fight against the baddies with love and willpower. When I asked, “Do you guys watch Cardcaptor Sakura?”, it’s no surprise that everyone nodded their heads. I think the 1990s is such a great era for anime because for once, the girls get the highlight!`}</p>
    <Ads mdxType="Ads" />
    <p>{`I wanted to see a girl taking the lead for once! And the best thing about Cardcaptor Sakura? She gets to be as girly as possible but she still rises up to the occasion in times of need. I was binging Cardcaptor Sakura when I remembered how much I love the idea that Sakura gets to wear cute clothes when on a mission to collect cards. `}<em parentName="p">{`“Looking your best when taking on danger”`}</em>{` is such an adorable idea, not to mention the clothes themselves look real good! (Thank you Tomoyo!) So I thought, let’s rank them by how much `}<strong parentName="p">{`I love them`}</strong>{`! (Okay, maybe by how cute they are...)`}</p>
    <p>{`I’m going to start from the last to No. 1! Enjoy me gushing <3 I apologize for the terrible screenshot skills, it was tough finding a nice frame where Sakura’s outfit is shown fully. I’m aware there are even more outfits that I may have missed (E.g. from opening and ending) but I only took the ones which Tomoyo specifically wanted Sakura to wear before heading out to catch the cards.`}</p>
    <h2>{`24. Sakura vs Yue, Episode 46`}</h2>
    <p>{`They say the last battle requires your best garment. After all, it’s the ultimate battle versus the final boss! The designer (CLAMP) would have gone all out to make sure Sakura looks the best here but it didn’t really give me an impression that it’s a good outfit save for how `}<em parentName="p">{`official `}</em>{`it looked.`}</p>
    <Image alt="Cardcaptor Sakura 1" caption="This is the only full shot of her outfit that looks good enough for this blog post." name="cardcaptor-sakura-outfit-vs-yue-1.jpg" mdxType="Image" />
    <Image alt="Cardcaptor Sakura 2" caption="Here's a close up of her hat and ribbon! And Tomoyo!" name="cardcaptor-sakura-outfit-vs-yue-2.jpg" mdxType="Image" />
    <p>{`There’s the big yellow ribbon and a big cape, but there’s two things that I did not love about this outfit - those pants and socks. Plus, pink and yellow here doesn’t look striking enough to me. She `}<strong parentName="p">{`does `}</strong>{`look like a legit card captor here, but the colours… ): I think the colours look bit washed out to me, thus this outfit gets the last spot for me.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`23. Sakura vs The Shadow card, Episode 2`}</h2>
    <p>{`This is Sakura’s first card-catching mission, and her first outfit! I personally really love the big cape and the equally big ribbon at the front. I placed it second last because there’s plenty of other outfits nicer than this!`}</p>
    <Image alt="Cardcaptor Sakura 3" caption="I love this shot because it included Kero in it! He has a big red ribbon too!" name="cardcaptor-sakura-outfit-vs-shadow-card.jpg" mdxType="Image" />
    <h2>{`22. Sakura vs The Illusion card, Episode 6`}</h2>
    <p>{`Bunny girl! Oh, adorable! But it’s pink and yellow again... I personally think the concept is good but it didn’t `}<em parentName="p">{`“flow” `}</em>{`right to me as an outfit that worked. I’m sure it looked great in the manga but in anime format? Not so much. I think it’s the way the whole form of the outfit doesn’t look appealing to me.`}</p>
    <Image alt="Cardcaptor Sakura 4" caption="I think it's the straight cut that didn't do it for me. Most of Sakura's best outfit is triangle shaped." name="cardcaptor-sakura-outfit-vs-illusion-2.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`21. Sakura vs The Twin card, Episode 43`}</h2>
    <p>{`This episode is the best I’ve seen when it comes to the Syaoran and Meiling combo. The show didn’t give these two enough credit! Sakura’s outfit here looks practical but the colours… are once again pink and yellow. I really love the pattern on the clothes but the black outlines just don't do it for me.`}</p>
    <Image alt="Cardcaptor Sakura 5" caption="Imho, I think it's the belly button and the hot pants that didn't do it for me ):" name="cardcaptor-sakura-outfit-vs-twins-1.jpg" mdxType="Image" />
    <Image alt="Cardcaptor Sakura 6" caption="A close up on the flower patterns on her clothes!" name="cardcaptor-sakura-outfit-vs-twins-2.jpg" mdxType="Image" />
    <h2>{`20. Sakura in Episode 50`}</h2>
    <p>{`I think this is the first I’ve seen a monochrome outfit by Tomoyo! The cute badge on her chest, her jacket with a skirt! It’s perfect <3 She looks like an honour student in a fantasy school that practices swordsmanship. Sweet and simple, I like it a lot! Besides, it suits her when handling the Sword card in this episode.`}</p>
    <Image alt="Cardcaptor Sakura 7" caption="That's an honour student badge she's wearing!" name="cardcaptor-sakura-outfit-s2-string-1.jpg" mdxType="Image" />
    <Image alt="Cardcaptor Sakura 8" caption="A full shot of her outfit. CLAMP just love adding tailcoat tails to most of Sakura's outfit..." name="cardcaptor-sakura-outfit-s2-string-2.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`19. Sakura vs The Fight card, Episode 20`}</h2>
    <p>{`I placed this outfit a spot higher despite the pink/yellow colour scheme because her skirt is adorable! Sure, I didn’t like the colour choices but the ribbons are at the right places for me. I count three ribbons - on her hat, her back and the chest. The yellow ribbon on her back along with her bulb skirt make her look like a sweets wrapping!`}</p>
    <Image alt="Cardcaptor Sakura 9" caption="She looks like a Hershey chocolate <3" name="cardcaptor-sakura-outfit-vs-fight-3.jpg" mdxType="Image" />
    <h2>{`18. Sakura vs The Rain and The Wood cards, Episode 4`}</h2>
    <p>{`Urgh, adorbs... She looks like an Angel from the series Angelic Layer, also by CLAMP! I love her angel wings hair band! If I’m not mistaken, if Sakura turns her head so we can see the headband carefully, the wings have a cute twirl to it at the base of the band. I love how they’ll tack on `}<strong parentName="p">{`pink`}</strong>{` on Sakura’s clothing whenever they can <3`}</p>
    <Image alt="Cardcaptor Sakura 10" caption="Those little angelic wings on her hair band is just so adorbs <3" name="cardcaptor-sakura-outfit-vs-rain-wood-1.jpg" mdxType="Image" />
    <Image alt="Cardcaptor Sakura 11" caption="Full shot of her outfit! I told you she looks like a doll from Angelic Layer!" name="cardcaptor-sakura-outfit-vs-rain-wood-2.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`17. Sakura vs The Watery card, Episode 4`}</h2>
    <p>{`I love the concept here. She’s all blue and a `}<em parentName="p">{`jester`}</em>{`! Her skirt has little fluffy balls on them, and her hat is super good-looking! Great concept, nice colour! Reminds me a lot of Jack Frost from Shin Megami Tensei.`}</p>
    <Image alt="Cardcaptor Sakura 12" caption="Yellow fluffy balls on each end! Naise!" name="cardcaptor-sakura-outfit-vs-watery-1.jpg" mdxType="Image" />
    <h2>{`16. Sakura vs The Thunder card, Episode 8`}</h2>
    <p>{`The kitty cat outfit reminds me so much of Tokyo Mew Mew. I remember seeing this outfit in one of Tokyo Mew Mew’s volume covers but I could be mistaken. There’s the bell and the black cat ears complete with a tail! And it looks like a maid outfit too! Black and pink is a new colour combo I didn’t know I needed.`}</p>
    <Image alt="Cardcaptor Sakura 13" caption="Meowcaptor reporting in!" name="cardcaptor-sakura-outfit-vs-thunder.jpg" mdxType="Image" />
    <h2>{`15. Sakura vs The Time card, Episode 12`}</h2>
    <p>{`She looks like Thumbelina! Or a cute fairy that goes from one flower to another <3 Her fairy-like wings did it for me, but her scrunchie like add-ons on her arms just completes her outfit! Yes, Tomoyo! You did it! She looks like an adorable fairy <3`}</p>
    <Image alt="Cardcaptor Sakura 14" caption="This is one of the super adorable ones!" name="cardcaptor-sakura-outfit-vs-time.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`14. Sakura in Episode 48`}</h2>
    <p>{`Hello Nurse Joy, Sakura is here to take your job. Her apron-ish front is super unique but the takeaway for me is her nice her flare skirt looks when animated or in static! It doesn’t look puffy now but when she moves, the animation on her skirt looks super good.`}</p>
    <Image alt="Cardcaptor Sakura 15" caption="Pink looks so good on her <3" name="cardcaptor-sakura-outfit-s2-rain-5.jpg" mdxType="Image" />
    <h2>{`13. Sakura vs The Illusion card, Episode 6`}</h2>
    <p>{`This outfit only appeared for a brief moment so I couldn’t get any good shots of the whole outfit. The shoulder pads are great, and I love how futuristic her outfit is. And belts! You can’t go wrong with belts. I think her headband is like a headphone? Loving how Kero gets an earring too.`}</p>
    <Image alt="Cardcaptor Sakura 16" caption="I think the shoulder pads are the highlight of this outfit." name="cardcaptor-sakura-outfit-vs-illusion-3.jpg" mdxType="Image" />
    <h2>{`12. Sakura vs The Song, Episode 23`}</h2>
    <p>{`Is this the first, normal-looking dress I see Sakura wearing in the anime? I love the layers on her dress, the inner layer has a pattern to the edges which reminds me a lot of Sakura petals. And her hat has a music note on it! Cute <3 Her whole outfit screams demure little pink mage!`}</p>
    <Image alt="Cardcaptor Sakura 17" caption="Full shot of her pretty dress <3" name="cardcaptor-sakura-outfit-vs-song-2.jpg" mdxType="Image" />
    <Image alt="Cardcaptor Sakura 18" caption="A close-up of her hat. Awwww, it has a music note!" name="cardcaptor-sakura-outfit-vs-song-1.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`11. Sakura in Episode 68`}</h2>
    <p>{`This is the outfit worn by Sakura when she travelled back in time to meet Clow Reed. It looks way, way better than I expected compared to S1’s “official” outfit. She looks like a legit magician and her outfit has star patterns on it! There’s a lot more details in this outfit, I love it. And there’s no pink on it? Bold move. … Okay, maybe it’s dark pink instead of red? You pick!`}</p>
    <Image alt="Cardcaptor Sakura 19" caption="She looks so dignified in this outfit." name="cardcaptor-sakura-outfit-s2-ep22-1.jpg" mdxType="Image" />
    <h2>{`10. Sakura in Episode 59`}</h2>
    <p>{`Oh my gosh, she looks adorable! Like a cleric! I am loving the colour combo, it reminds me so much of pastel colour aesthetics! And the little brooch holding the cape is a star <3 I’m starting to think the later episodes have absolute banger designs, and this is one of them!`}</p>
    <Image alt="Cardcaptor Sakura 20" caption="Loving the stars on her outfit!" name="cardcaptor-sakura-outfit-s2-ep13-1.jpg" mdxType="Image" />
    <h2>{`9. Sakura in Episode 58`}</h2>
    <p>{`I died when I saw the two little hair buns. Plus the hair buns is the first thing you see in this episode when Sakura appears on screen! She looks very much like a Chinese doll, along with those shoulder pads and squiggles on her clothes.`}</p>
    <Image alt="Cardcaptor Sakura 21" caption="HAIR BUNS HAIR BUNS!" name="cardcaptor-sakura-outfit-s2-ep12-1.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`8. Sakura vs The Little card, Episode 24`}</h2>
    <p>{`This is perhaps the most basic outfit Tomoyo ever designed. But I love basic clothes so it gets a high rank on my list! Oversized pink ribbon and an Alice in the Wonderland outfit! Brb, I need to dress up my Animal Crossing villager just like Sakura here.`}</p>
    <Image alt="Cardcaptor Sakura 22" caption="So cute <3 Alice in CLAMPland!" name="cardcaptor-sakura-outfit-vs-little-2.jpg" mdxType="Image" />
    <h2>{`7. Sakura vs The Dash card, Episode 30`}</h2>
    <p>{`This is the first outfit in S1 that really catches my eye. No, it’s not the colour combo but the hat that caught my eye. She looks like a green house elf! Unfortunately this outfit is only shown briefly ):`}</p>
    <Image alt="Cardcaptor Sakura 23" caption="Minty green and pink?" name="cardcaptor-sakura-outfit-vs-dash-1.jpg" mdxType="Image" />
    <h2>{`6. Sakura in Episode 47`}</h2>
    <p>{`I tried my best but I couldn’t get more shots of Tomoyo facing the front because I love `}<em parentName="p">{`both girls’ outfits`}</em>{` here! It was raining nonstop in this episode, so Tomoyo whipped up raincoat-inspired outfits! Loving Sakura’s cape and the hood! Both the girl's colour scheme and design reminds me a lot of frogs.`}</p>
    <Image alt="Cardcaptor Sakura 24" caption="I couldn't get a good shot... They both look adorable!" name="cardcaptor-sakura-outfit-s2-rain-2.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`5. Sakura vs The Silent card, Episode `}</h2>
    <p>{`In this episode, Sakura and Tomoyo needed to sneak into the museum to catch The Silent card. It kept booting them out since they made a ton of noise. Her whole getup here is like a shrewd imp. Most of Tomoyo’s designs are all lovely-looking with angelic wings and fairy wings. But this one just screams `}<strong parentName="p">{`sneaky!`}</strong>{` I find the devilish wings a nice addition.`}</p>
    <Image alt="Cardcaptor Sakura 25" caption="Isn't the devilish design cute? <3" name="cardcaptor-sakura-outfit-vs-silent-1.jpg" mdxType="Image" />
    <h2>{`4. Sakura vs The Big card, Episode 31`}</h2>
    <p>{`Who’s up for some heroic design!! I’m pretty sure this outfit is partially inspired by Magic Knight Rayearth and Dragon Quest! You have that shoulder pad with a gem at the center. Plus a super nice blue colour scheme! All she needs is a sword to complete the package.`}</p>
    <Image alt="Cardcaptor Sakura 26" caption="And this outfit has angelic wing hair band too! Kero has the heroic belt on him!" name="cardcaptor-sakura-outfit-vs-big-1.jpg" mdxType="Image" />
    <h2>{`3. Sakura in Episode 52`}</h2>
    <p>{`I could have sworn I’ve seen this outfit somewhere. I believe it’s inspired from one of the CLAMP series I’m not familiar with. Her cap with twirly ends, plus her shoes is a nice touch! But what I really like about this outfit is the ingenuitive flared jacket that opens up at the front.`}</p>
    <Image alt="Cardcaptor Sakura 27" caption="The twirly ends and the blue outfit matches really well. Totally my aesthetics." name="cardcaptor-sakura-outfit-s2-erase-1.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`2. Sakura vs The Storm, Episode 15`}</h2>
    <p>{`This mage outfit appeared only at the beginning of the episode, but I `}<strong parentName="p">{`love it`}</strong>{` so much. One of my favourite outfit designs across all media is a `}<em parentName="p">{`basic mage outfit`}</em>{`. As long as the outfit has a tall hat with a wide brim, a capelet and modest clothing underneath, I’ll `}<strong parentName="p">{`spam`}</strong>{` that like button all day.`}</p>
    <Image alt="Cardcaptor Sakura 28" caption="This outfit was shown at the beginning of the episode, and this is the best full shot I could get. The cape has a wing tassel on it <3" name="cardcaptor-sakura-outfit-vs-storm-2.jpg" mdxType="Image" />
    <Image alt="Cardcaptor Sakura 29" caption="Here's the back of the outfit! I love the yellow lining." name="cardcaptor-sakura-outfit-vs-storm-1.jpg" mdxType="Image" />
    <h2>{`1. Sakura vs The Snow card, Episode 36`}</h2>
    <p>{`And here it is! The winner among all 24 Cardcaptor Sakura outfits - the bunny outfit! This and No. 2 came in real close for me because I really love them on Sakura. Both outfits really captured Sakura’s essence - cute and fashionable. But this outfit that clearly screams `}<strong parentName="p">{`BUNNY`}</strong>{` takes the cake for me. First, the colours are sharp. Secondly, the cutting of this outfit is in-line with the bunny theme. It made Sakura look small, just like how a bunny would look like. The little puff balls just, `}<strong parentName="p">{`hnnng`}</strong>{`, completes the outfit for me.`}</p>
    <Image alt="Cardcaptor Sakura 30" caption="So cute so cute so cuteeee! The ears, the fluffy balls... Yea, this outfit is perfect." name="cardcaptor-sakura-outfit-vs-snow-1.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`All in all`}</h2>
    <p>{`All 24 outfits ranked! I hope the screenshots trigger some happy nostalgic memories. It's currently on Netflix, and the sequel (Clear Card manga) is finally shedding some light! If this series is new to you, please watch Cardcaptor Sakura, then read the manga XxxHolic and Tsubasa Chronicles. It's good stuff.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      