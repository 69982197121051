import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const SEO = ({ description, meta, title, location, image, keywords }) => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          author
        }
      }
    }
  `);
  const metaItems = [];
  const metaDescription = description || site.siteMetadata.description;
  const metaUrl = `https://geeknabe.com${location.pathname}`;
  const logoImg = image || `https://geeknabe.com/geeknabe-blog-banner.png`;

  if (keywords.length > 0) {
    metaItems.push({
      name: 'keywords',
      content: keywords.join(', '),
    });
  }

  metaItems.push(
    {
      name: 'description',
      content: metaDescription,
    },
    {
      property: 'og:title',
      content: title,
    },
    {
      property: 'og:description',
      content: metaDescription,
    },
    {
      property: 'og:url',
      content: metaUrl,
    },
    {
      name: 'twitter:title',
      content: title,
    },
    {
      name: 'twitter:description',
      content: metaDescription,
    },
    {
      name: 'twitter:creator',
      content: 'geeknabe',
    },
    {
      property: 'og:image',
      content: logoImg,
    },
    {
      name: 'twitter:image',
      content: logoImg,
    }
  );

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en',
      }}
      title={title}
      meta={metaItems.concat(meta)}
    >
      <link rel="canonical" href={metaUrl} />
    </Helmet>
  );
};

SEO.defaultProps = {
  meta: [],
  description: '',
  image: '',
  keywords: [],
  location: {},
};

SEO.propTypes = {
  keywords: PropTypes.arrayOf(PropTypes.string),
  location: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  description: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  image: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default SEO;
