import PropTypes from 'prop-types';
import styled from 'styled-components';

import $ from '../../../../../styles/globals';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${({ right }) =>
    right === true ? $.color.green1 : $.grey1};
  position: relative;
  align-self: ${({ right }) => (right === true ? 'initial' : 'flex-start')};
  ${({ right }) =>
    right === true ? 'margin-right: 12px' : 'margin-left: 12px'};
  padding: ${$.layout.padding2}px;
  border-radius: 13px;
  width: calc(30% - ${$.layout.padding2 * 2}px);

  > * {
    margin-right: ${$.layout.margin2}px;
    &:last-child {
      margin-right: 0;
    }

    border-radius: 999px;
    width: 6px;
    height: 6px;
    background-color: ${({ right }) =>
      right === true ? $.color.white : $.color.lightblack};
  }

  ${({ right }) => {
    if (right === true) {
      return `
      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 90%;
        width: 0;
        height: 0;
        border: 10px solid transparent;
        border-left-color: ${$.grey1};
        border-right: 0;
        border-bottom: 0; 
        margin-top: -10px;
        margin-right: -5px; 
      }
      `;
    }

    return `
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 90%;
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-right-color: ${$.grey1};
      border-left: 0;
      border-bottom: 0;
      margin-top: -10px;
      margin-left: -5px; 
    }
    `;
  }}
`;

const TypingStatus = ({ right }) => (
  <Container right={right}>
    <div />
    <div />
    <div />
  </Container>
);

TypingStatus.defaultProps = {
  right: false,
};

TypingStatus.propTypes = {
  right: PropTypes.bool,
};

export default TypingStatus;
