import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const RemoteLink = makeShortcode("RemoteLink");
const Image = makeShortcode("Image");
const LocalLink = makeShortcode("LocalLink");
const Blockquote = makeShortcode("Blockquote");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`While we all know about manga, you’ll be really surprised to hear that manhwa (Korean… comic? Manga?) has also been thriving for a long time! And I don’t mean webtoons. Manhwa back then had an art style that used thick lineart and edges, giving a sense of edgy vibes on every panel. Lots of action lines too. I suppose there were more male readers than female readers back then? It could get really hard to read what’s going on in each panel but oh boy, they do really fun and bombastic action scenes.`}</p>
    <Ads mdxType="Ads" />
    <p>{`This blog post is not exactly a recommendation post. Thus, some of the titles aren’t those that I would actually recommend since I know different folks love different stuff. I guess these manhwa titles stuck out to me as my personal favourites. Each of these titles fills a different niche to me! If you enjoyed my personal thoughts about each title, then great <3 Please do give them a try. Unfortunately most of them are either on hiatus or sacked. Sobs… But this won’t stop me from talking about them!`}</p>
    <p>{`Note: I am not sure how to talk about these titles without tacking `}<em parentName="p">{`shoujo`}</em>{` and `}<em parentName="p">{`shounen`}</em>{` tags to them. I don’t think there’s an equivalent word that I know of (popular enough that otaku fans would recognize) in Korean so shoujo/shounen it is! You get my drift, yea? (In all honesty, I actually have no clue how to title this blog post)`}</p>
    <h2>{`Personal Favourite Manhwa #1: Ragnarok`}</h2>
    <p>{`I believe the myth Ragnarok is really popular amongst nerds. You got the Marvel folks, the uh DC folks perhaps?, the gaming peeps (God of War) and even fantasy novel writers! This myth has been reused again and again with the writer’s own personal twist, I find it really rare to meet any pop culture fans out there who have not heard of Ragnarok. So I find it even stranger you have not heard of Ragnarok, the manhwa version… that went on to inspire the MMORPG Ragnarok Online. Hell yea, you heard me right! `}<RemoteLink to="https://en.wikipedia.org/wiki/Lee_Myung-jin" mdxType="RemoteLink">{`The author of this manhwa abandoned it halfway to go work on an online game, damnit.`}</RemoteLink></p>
    <Image alt="Ragnarok Manhwa cover" caption="If there's one thing the author is good at, it's drawing bones and ungodly creatures. Plus the magic system he designed is really fun." name="ragnarok-manhwa-cover.jpg" mdxType="Image" />
    <p>{`If you played Ragnarok Online, you’ll know that the game is set in Midgard, you’ll get to choose different jobs for your character and each job belongs to a city. Now here’s the fun part, all of these are already in the manhwa, along with popular gods from the same Norse mythology. The story starts off with an amnesiac swordsman called Chaos who lives in Payon. Payon is a city of strong warriors and it is there that he met Iris, the daughter of the village’s chief. She is to train herself as a cleric to inherit her father’s position. Little did Chaos know that there’s a secret to his forgotten past… And it all starts to unravel when the reincarnation of Fenrir (a human named Fenris Fenrir) comes looking for him in order to bring about Ragnarok.`}</p>
    <p>{`The story basically has all of the prime gods from the Norse mythology be reborn as humans in Midgard. Just what on earth happened in their past lives? Unfortunately, no one knows because the author stopped working on the story since forever ): The art and panel is really `}<em parentName="p">{`edgy`}</em>{` at first. I totally appreciate the author’s idea of character design. Dialogues are pretty fun to read! As the story continues, the lineart starts to get softer to look at while backgrounds are busier. You could tell the author has a ton of ideas for the lore, and fun ideas for the fighting scenes. Why didn’t you continue this story?!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Personal Favourite Manhwa #2: Id - The Greatest Fusion Fantasy`}</h2>
    <p>{`This is yet another personal favourite of mine. I believe it’s a hit or miss for other readers but I seriously love this one. If you have read `}<LocalLink to="/blog/7-comfy-and-relaxing-webtoon-recommendation/" mdxType="LocalLink">{`my other blog post on webtoon recommendation`}</LocalLink>{`, you’ll find that I recommended Trinity Wonder. It has this plot where it mashes up tech, murim and magic together. Id is no different! But is it better than Trinity Wonder? Well… I’d say it hits the `}<em parentName="p">{`satisfying`}</em>{` spot for me. If you google up `}<em parentName="p">{`Id manhwa`}</em>{`, you’ll find the correct hits. I added `}<em parentName="p">{`The Greatest Fusion Fantasy`}</em>{` so you’ll know what to look for! Now I’m just reciting the summary from memory, so please don’t feel cheated if you read the manhwa and found some discrepancies >`}{`_`}{`<`}</p>
    <Image alt="Id The Greatest Fusion Fantasy manhwa cover" caption="If you see this art and you go, Eh what on earth are those limbs... I totally get you. The art takes some time to get used to." name="id-the-greatest-fusion-fantasy-cover.jpg" mdxType="Image" />
    <p>{`In Id, you follow Ye Chun Hwa, a feminine looking man good in martial arts. One day, he was transported to a world of magic due to a plot by his most trusted friend(?). Upon arrival, he met Greydrone, a dying dragon who bequeathed his dragon heart to Chun Hwa. He also found a talking sword called Lamia. With both in hand (and in body), he sets off on a journey in this new world with hopes of finding his way back home. Along the way, he met a group of adventurers who would soon be his good friends in this world. It just so happens that this team of adventurers with Id in it has a knack of looking for troubles. So you got this plot where every incident that involves Id is slowly unraveling his backstory while exploring the lore in the current world.`}</p>
    <p>{`I would say Id is a nice read if you enjoy these types of stories and art. It can also be a big miss if you abhor the art. It… takes a long time to get used to the art! The reason I got so used to it is because almost every manhwa prefers these clothing designs and art. Is it the `}<em parentName="p">{`trend`}</em>{` to draw them that way back then? Maybe. Come to think of it, I stopped following the manhwa after updates stopped. Maybe it’s time to go back in...`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Personal Favourite Manhwa #3: The Legend of Maian`}</h2>
    <p>{`Urgh, the opening is so good. The midway point too! I couldn’t help but spoil myself for the ending since the updates stopped so long ago. It turns out to be… a bad ending that no one enjoyed? What on earth? Okay, regardless, Legend of Maian is such a fun read to me so I added it into this list.`}</p>
    <Image alt="The Legend of Maian Felicia Wallpaper" caption="Maian who? ALL HAIL OUR QUEEN, FELICIA!" name="the-legend-of-maian-wallpaper.jpg" mdxType="Image" />
    <p>{`This story is a classic demon lord + hero kinda story, where both parties respected one another for their strength and resolve. In this story, there lived a terrifying demon lord named Felicia Rand Philistin whose magic is unmatched. With her powers and her Eleven Knights of Regers (whom she blessed with her magic), she moved to conquer the world. Thankfully, humanity had a hero blessed by the goddess named Maian. Thus, the war that lasted 2 years ended with Maian sealing Felicia. Maian then turned the place where they did battle to a village. A thousand years later, his descendant Fenix unlocked the seal on the demon lord and voila, a journey to uncover the whole truth of Felicia’s ambition begins.`}</p>
    <p>{`So the story has romance in it which is done pretty nicely between Felicia and Fenix. There’s a lot of throwbacks to Felicia’s past since they went on a journey to meet the Eleven Knights of Reger. Some eagerly joined Felicia again, some opted to enjoy the normal human life and others defected. There’s plenty to unwrap here but all in all, I really like Legend of Maian for being a fun read. Why did the ending happen though… Yes, this manhwa is incomplete. Sigh.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Personal Favourite Manhwa #4: Dorothy of Oz`}</h2>
    <p>{`Speaking of Dorothy from Oz, there’s this NDS game that I really love called `}<RemoteLink to="https://en.wikipedia.org/wiki/The_Wizard_of_Oz:_Beyond_the_Yellow_Brick_Road" mdxType="RemoteLink">{`The Wizard of Oz: Beyond the Yellow Brick Road`}</RemoteLink>{`. You control Dorothy by swiping the touch screen to make her run down the yellow brick road. The bosses in this game are tough but I find the whole idea (plus the team of Tin Man, Toto, Lion and Strawman) really charming. You could say that this manhwa is pretty much the same idea. I mean, duh! The original book is so good, you couldn’t spin it too far from the book!`}</p>
    <Image alt="Dorothy of Oz manhwa cover" caption="Mara is on the left, and her Witch form is on the right." name="dorothy-of-oz-manhwa-cover.jpg" mdxType="Image" />
    <p>{`Our MC is Mara Shin, a young girl from Korea who ended up in the world of Oz. Since young, she has the ability to see a yellow brick road but she is never brave enough to venture into the unknown road. But fate has it she would one day follow the yellow brick road and end up in Oz again. Wait, again? Yup! She once went to Oz when she was really young but she couldn’t remember the details. So the story has her going into Oz again but this time, she encountered Selluriah, the Witch of the East. In Oz, the witches control a city at each cardinal direction with the almighty Wizard of Oz controlling the center area. She was eager to go back home and Selluriah promised to bring her back home.`}</p>
    <p>{`Unfortunately, she was murdered and Mara Shin was framed as the murderer. With Selluriah’s boots and magic wand in tow, Mara escaped her pursuers by following the yellow brick road that only she could see. Of course, along the way, she met her 3 teammates - Abee (Scarecrow), Namu (Tin Man) and Tail (Lion). Together, they would join Mara’s journey to see what’s on the end of the yellow brick road.`}</p>
    <p>{`There’s only 5 volumes in the wild because it got cancelled halfway? A pity because I really enjoyed the whole idea of a Oz manhwa with a shounen-ish plot. The art is really cartoonish which I may or may not be biased to. Sometimes simple is key, ya know? It’s a really fun story with nice execution of a shounen-ish Oz plot. Why… did it get cancelled…`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Personal Favourite Manhwa #5: Dark Air`}</h2>
    <p>{`If you think the whole idea of a team composition in games is a staple that couldn’t be replicated elsewhere, think again (LOL). I enjoyed the mystery at first and the whole idea of magic being omnipotent! But the story kinda peutered off as it reached the ending… Strange. Did the whole story get rushed? Regardless, it’s an interesting take.`}</p>
    <Image alt="Dark Air manhwa cover" caption="From the left is the swordswoman who's part of the Blue Nova team. Our MC is the blonde guy, Melrose." name="dark-air-manhwa.jpg" mdxType="Image" />
    <p>{`Long time ago, magic filled the air. Magicians are casters who would pull in magic into their own bodies to cast magic. Magic in this world are really strong OHKO moves so they are often feared in battle. One day, all of the magic in the world went missing. Powerful magicians are now nobodies! Warriors or Fighters on the other hand, rose to popularity. But there exist 2 magicians who are able to cast magic. One is our MC named Melrose and the other is Melrose’s enemy, Vincent Zappa. Just why did Melrose and Zappa only get to cast magic easily? Where has all the magic gone? Melrose seeks to discover the truth with his adventuring team called Blue Nova.`}</p>
    <p>{`My goodness, the whole idea of team composition is so strongly rooted in this story. It’s kinda fun and meta-ish to read. Without magicians to play the glass cannon role, many adventurer teams opted to go for the melee team composition. You have warriors, archers and even thieves playing a strong role in teams. And then you have the buffers AKA musicians. Huh, how does this work… Oh and healers exist in the story! Yea nah, I have no idea how their skills work. Thankfully, unlike the previous titles I talked about, Dark Air got an ending and is out in the wild for you to discover. I hope you like the series if you decide to read it!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Personal Favourite Manhwa #6: Witch Hunter`}</h2>
    <p>{`It is with regret that I inform you… I’m reading this series solely for the witches. I mean, they are so seductive and super nice to look at… The male characters are all the cool types in this story while the witches are the sexy type. Thumbs up to the author for doing really nice character designs!`}</p>
    <Image alt="Witch Hunter manhwa cover" caption="Oho, turns out the English title is called the Witch Buster. Did the title clash with another existing title? o_o Anyway, this is Aria, the little sister Tasha is looking for. The witches in this series has great fashion sense." name="witch-hunter-manhwa-cover.jpg" mdxType="Image" />
    <p>{`Long, long time ago, witches and humanity lived peacefully. There exist 4 strong witches who each control a cardinal direction. The power shift quickly changed when North and South cooperated to bring down the other two witches. With the “peace faction” gone, they quickly declare war on humanity. With no powerful forces protecting humans, humanity decided to DIY… and created the Witch Hunter organization. The purpose of the organization is, of course, to counter the witches by getting powerful human and sane witches to work for them. You see, humans can turn into witches but only the ladies. It is unheard of to have a man turn into a witch. Once mana blossomed, continual usage of magic to kill will corrupt the witches sanity. Before they go gaga, witch hunters could choose to kill the witch before their powers erupt or convince them to join the Witch Hunter association.`}</p>
    <p>{`Our MC in this story is Tasha who uses magic guns as his weapons and owns Halloween, a pumpkin familiar. Tasha is a A-class witch hunter so in a way, he is pretty strong in his own right. He goes about doing missions to try and get back his sister-turned-witch. Alas, this is all but a ploy to the Witch of the North. Just what are the witches trying to stir through Tasha and Halloween?`}</p>
    <p>{`There’s a lot of cool characters in this story, but I’m all for the witches. Like, wow. I hope they win and not the humans! And then there’s the Halloween subplot. I’m not too sure I fancy the author’s take on it but she’s cute so she gets a passing grade. The first half of the story is great, but with the strange pauses and short updates afterwards, the story gets muddied really fast. I don’t know how the author will end the story but I’m not looking forward to it. The story got really messy, like the author didn’t know how to lead the story anymore. But I’m here only for mah witches! Alrighty, I rest my case.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Personal Favourite Manhwa #7: Immortal Regis`}</h2>
    <p>{`Man, the art is so good. This title serves as a prologue to Cavalier of the Abyss which I think is still ongoing. But hey! Good news is that Immortal Regis is short and sweet, so you can quickly get the full package in one go.`}</p>
    <Image alt="Immortal Regis manhwa cover" caption="From the left is Jae-Hyuk and the girl on the right is Serin." name="immortal-regis-manhwa-cover.jpg" mdxType="Image" />
    <p>{`Chae Jae-Hyuk is a pitiful brother to his sickly younger brother, Cha Jae-Hoon. Since their family consists of only two brothers, Jae Hyuk has to work extra hard to earn food for the both of them. But it isn’t going swimmingly since he is still a minor. One day, he was caught up in a fight between two beings of Chaos (another fantasy realm separate from the real world). He was promptly killed in a misfire. One of the battlers, Serin, felt guilty for involving an innocent bystander. She decided to make Jae-Hyuk into her kin. Revived as an undead, he first went about his daily lives without thinking too much about it. But Serin has other ideas. She proclaimed that he has no choice but to follow her back to Chaos due to his need for her blood as his master.`}</p>
    <p>{`These unexpected turn of events pushed Jae-Hyuk to the brink. He is tired, pressured and now his life turned a 180 because of an accident? The entirety of Immortal Regis turned him into a bitter person which we can see how it turned out in the sequel, Cavalier of the Abyss. Serin may have sympathized with Jae-Hyuk, but all of her actions unknowingly stirred a new era for Chaos.`}</p>
    <p>{`Unlike other manhwas I read, Immortal Regis has the thickest lineart I’ve seen with some of the easiest to tell character designs. Dresses are really nice to look at, tails and hair goes whoosh wherever they can while blood splatters are nicely done. I personally think the prequel has an easier plot to follow. But of course, if you read the prequel, you’d want to read the sequel, yea?`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Personal Favourite Manhwa #8: Cavalier of the Abyss`}</h2>
    <p>{`I gave this a lot of flak in my previous paragraph but when it comes down to it… Eh, this title is not bad. The art got way better, and the whole dramatization even better. Just why does Jae-Hyuk have so much anger in him? Just how many kids does this guy have?! It went from shounen-ish plot with an open ending in the prequel to a k-drama for the sequel.`}</p>
    <Image alt="Cavalier of the Abyss manhwa cover" caption="It's pretty amazing to read the sequel and go, Whoa! This is drawn by the same person? The art improved by leaps and bounds, I'm telling ya." name="cavalier-of-the-abyss-manhwa-cover.jpg" mdxType="Image" />
    <p>{`The story opens up with a totally different protagonist who is out for blood. His name is Xix, a villager turned undead who swore to take his revenge on Regis Nex for murdering his whole village. Now here’s the thing… After the prequel happened, many years went by and Jae-Hyuk became king. He took on a new name, Regis Nex and Serin as wife. The sequel is basically what happened after the prequel, followed by Serin’s betrayal due to a prophecy about Chaos’s destruction and uh, family issues. Then there’s some power struggle, lots of throwbacks to the old days, yada yada. We get to see a lot of mystery at first regarding Xix’s background. The new characters get their spotlight, followed by the Immortal Regis’s original cast.`}</p>
    <p>{`There’s a lot of jumping around to tell the story. I think the best story arc is definitely the one that shows us Immortal Regis’s cast. We were introduced to new characters while adding more fire to the original cast’s relationship. It’s the type of story where you enjoy seeing characters sowing chaos by doing the wrong (but understandable) choices. The story does get really confusing since you’re supposed to piece things together, but the author didn’t really do a good job of explaining things. It’s kinda like the Tsubasa Chronicles plot where everything is jumbled up and you’re supposed to piece together the throwbacks. Still, I really enjoyed the story. The drama and romance really makes up for it.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Personal Favourite Manhwa #9: Ciel The Last Autumn Story`}</h2>
    <p>{`This manhwa is the reason why I added the `}<em parentName="p">{`shoujo`}</em>{` term to this blog post’s title. I didn’t want to mislead my readers! So it’s 8 shounen titles and 1 shoujo title. I really enjoyed this title because it’s really unique and pleases my sweet/comfy aesthetics. I’m fond of seeing designs that peruse dolly, laces, pink or turquoise… Along with flowers. Yes, lotsa flowers. If you get my drift then we dolly buddies! Unfortunately I couldn’t say the same with the ending.`}</p>
    <Image alt="Ciel The Last Autumn Story manhwa" caption="I absolutely love their cover art. It's soft, lots of flowers and dolly aesthetics where applicable." name="ciel-the-last-autumn-story-manhwa.jpg" mdxType="Image" />
    <Blockquote mdxType="Blockquote">
  Hey yo what on earth, hotpot? How many times have you talked about bad endings
  for each title here?
    </Blockquote>
    <p>{`Which is why I keep iterating that these titles wouldn’t be good recommendations to folks who want a good read. I love them because they fill a niche. They fit into this satisfaction niche thingie I got going. Ciel is a confusing story, partly because it changed its tune halfway down the road. Was it hinted at in the story? Maybe. It’s one of those stories that `}<em parentName="p">{`“didn’t do the hint well”`}</em>{`. You follow Yvienne Magnolia, a village girl who has it all (beauty, popularity) trying to escape her hometown by enrolling in Lowood Institution, a school for witches and wizards. There, she met her witch partner, Lariatte KingDiamond whose family is famous for martial artists and January Lightsphere… a curious young man whose magical powers eclipsed even the most powerful of dragons. Yvienne was shown to be apathetic in life since she has it all, but meeting Lariatte changed her for the better. Her encounter with Lariatte and January marks a historical change to the planet.`}</p>
    <p>{`There’s only a few characters who play a major role in the story which the author would keep bringing back. The first half (at school) shows how Yvienne tries to adapt to her new life and learn how to live life freely. The story then does a major shift by causing a traumatic experience to Yvienne, followed by… an unexpected revelation about the planet? Huh? Wut? Things just kept spiraling further, I wasn’t sure if I’m reading the same Ciel the author wants me to. I personally think it would be better if the author continued to explore Yvienne’s growth on a personal basis. It felt OOC to me in the end… Maybe I’m just not paying attention but mannnn. It’s a nice read at the beginning, but I wish I could get at whatever it is the author wants me to in the end. Anyway, feel free to give this a try, maybe you’ll like it too!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`All in all`}</h2>
    <p>{`Thank you for reading my personal bloopers on each title. Like I say, these are my personal favourites but I’ll be really happy if anyone is curious enough to give these titles a try! Happy reading <3`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      