import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I wasn’t going to write blog posts at all for the month because I find myself busy with rearranging my blog’s source code. But THIS! Urgh. Why is Ascendance of a Bookworm so good?!`}</p>
    <p>{`With S3 coming soon, I find myself starved for more Ascendance of a Bookworm’s content. I was struck with a reading bug by Eighty-Six first. I bought all 8 volumes and chow them down like a hungry wolf. If you enjoy plot about machine-vs-humans with lots of action written in, then you’ll enjoy Eighty-Six’s light novel. Or you could try the anime. I heard it’s fantastic! After completing all 8 volumes, I wanted to read more, so I got Ascendance of a Bookworm light novels next.`}</p>
    <Ads mdxType="Ads" />
    <p>{`I wrote a few blog posts about the anime if you are interested. My thoughts about this series can be generally summed up as “YES. THIS IS GOOD. I WANT MORE”. The story had a natural build up to Myne’s success. She failed again and again, but she never stopped trying to make her own books. As she gradually inches towards her goal, she makes plenty of connections and widens her circle. Not only that, she gained experience and her mindset grew as well. It is such a joy to watch Myne spreading her wings like she ought to. The story progression felt natural. Her successes just keep adding up. S1 and S2 are super satisfying to watch. I bought the books knowing I’m about to experience the whole journey again but boy, it’s so much better.`}</p>
    <p>{`Note: Minor spoilers? I don’t talk beyond what is written as short summaries on the back of each paperback volume + S1, S2 anime.`}</p>
    <h2>{`Part 1: Daughter of a Soldier`}</h2>
    <p>{`Myne’s journey is split into many parts. The JP light novels go up to Part 5 but for now, the English translation has up to Part 4 Vol. 4 but only volumes up to Part 3 Vol. 2 are out in the wild as of this writing. I believe J-Novel has more volumes translated if you subscribed to their e-book offerings. The anime’s first season covers Part 1, where we see Urano waking up as Myne and trying to adjust to her new life. She tried to make paper on her own but failed. It was only when she enlisted Lutz’s help that she succeeded. The anime’s plot pretty much is a copy of the book’s plot but like every anime, they skipped out on a lot of details.`}</p>
    <p>{`The anime’s first season gave me a comforting vibe, where Myne tries to soak up as much as possible about this new world. She’s no longer a Japanese young lady from a modern era anymore, but a sickly young girl who could easily flop over and die if she’s not careful. She treated her family like a stranger at first, but soon warmed up to them. This line of thought is understandable. After all, you just died and entered a young child’s body. She couldn’t do housework nor could she walk far from home. In the anime, it was portrayed that she’s just slightly inconvenienced and mad from her situation.`}</p>
    <p>{`In the light novel, however… I honestly didn’t enjoy reading her monologue in the beginning. Her monologue when she first woke up as Myne is always revolving around the idea that she looks down on her family’s situation. She disliked her weak body, hated her family’s idea of cleanliness and most of all, never saw Tuuli as a sweet, responsible sister. Her new family are strangers and thus, her monologue feels super unkind when it comes to her new family. She didn’t care about helping her family out at all and is only highly invested in creating books.`}</p>
    <Ads mdxType="Ads" />
    <p>{`This is such a stark difference to the impression I got of Myne in the anime’s first season, so I was super surprised. Myne in the book took her own sweet time to start appreciating her family. It was when she realized how loving her family is despite her strange behaviour that she started changing her mind about them. It was a long process, but the book soon pivoted into the wholesome vibe I got from the anime afterwards. Part 1 is pretty much S1’s plot but do give Part 1 a try if you want to read more side stories! Yup, not only did the book talk about the story from Myne’s perspective. It even added side stories about Benno, Lutz and even Myne’s mother. It’s a nice touch to flesh out the world of Ascendance of a Bookworm even more <3`}</p>
    <h2>{`Part 2: Apprentice Shrine Maiden`}</h2>
    <p>{`Part 2 is basically the anime’s second season and as usual… I repeat… better. We start off with Myne joining the temple on Day 1 and not liking her attendants. The attendants running off when Myne’s eyes started glowing wasn’t explained well in the anime but in the book, they ran off because children with mana couldn’t control their mana well. When children with mana got angry, commoners without mana could easily die from their outburst. Having mana is actually a huge deal. It’s something that wasn’t highlighted enough in the anime but plenty in the book.`}</p>
    <p>{`It was also specifically mentioned that Myne’s Crushing (the anime scene where the High Bishop keels over from Myne’s rainbow eyes) is strong because not only did she overpower the High Bishop, she overpowered the High Priest (Ferdinand) too. Ferdinand’s mana is much, much more than the High Bishop’s, which is why the attendants were so shocked to see their master struggling from a child’s Crushing. The strength of your Crushing shows the amount of mana you have and Myne has a lot of it.`}</p>
    <p>{`There were a ton of other minor details that the anime skipped, like how female gray priests could be impregnated by nobles and thrown away. The vibe in S2 is so much lighter compared to the book! Yes, Myne did save the orphans by teaching them the way to get their own food. The book explained how Myne came up with the process, explained the micromanagement required to get it done. It was done so well, it didn’t feel like story padding at all. Honestly, reading how she micromanaged everything is actually pretty fun.`}</p>
    <Ads mdxType="Ads" />
    <p>{`The book included a ton of moments where you can feel the gray priests’ and orphans’ appreciation for Myne. Reorganizing the orphanage is actually a pretty big deal, and it was shown to be so in the book. Ferdinand is highly aware of Myne’s capabilities but time and time again, she would surprise him with even more achievements. Soon, even the noble society could no longer ignore Myne’s achievement.`}</p>
    <h2>{`All in all`}</h2>
    <p>{`The anime’s S3 will definitely cover the rest of Part 2, followed by Part 3. After all, it’s that part of the story where Myne is introduced to noble society. While this post only talks about Part 1 and Part 2, I personally read up to Part 3 Vol. 1. In Part 1, it’s a super slow hike to introduce Myne as a bookworm. While I enjoyed Part 1, I did find it slow in picking up. In Part 2, things start to pile up. It got even more exciting when the noble society took note of Myne. Nobles are no joke in this fantasy world, and we were reminded of it in Part 2. It’s super satisfying and fun to see how everything just keeps building up. None of Myne’s achievements went to waste. It all piled up to propel her even higher in society. Just how far will she go? What will she encounter along the way? Gosh, I’m dying to find out. I have already pre-ordered Part 3’s Volume 2 and 3 only because they are releasing the copies this year. The rest is coming out soon next year!`}</p>
    <p>{`Should you read this story? Of course! I binged Part 2 until 5am so that should tell you how gripping the story is when you start reading.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      