import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const RemoteLink = makeShortcode("RemoteLink");
const Ads = makeShortcode("Ads");
const Video = makeShortcode("Video");
const LocalLink = makeShortcode("LocalLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I’ve been binging a ton of anime from Netflix lately, including titles that I usually won’t watch unless someone holds a knife to my neck. `}<RemoteLink to="https://myanimelist.net/anime/33197/Lostorage_Incited_WIXOSS" mdxType="RemoteLink">{`Wixoss`}</RemoteLink>{` is one of those titles. I remember watching the first episode and got turned off by how the series handles the apparent friendship theme. Oh, I’m sure the director had an idea but the execution is pretty poor. I decided to give this title another try and I realized that I really like it. It reminded me of many anime series from the 2000s that didn’t get to the bottom of the show until a few episodes in. Unfortunately, the glaring weaknesses for this title is simply too obvious to ignore. I love the idea, I simply don’t enjoy the execution. I’m at Episode 4 so far, and I’m not sure I like the new direction it is going into.`}</p>
    <Ads mdxType="Ads" />
    <p>{`How Wixoss was handled gave me an idea for this blog post. I realized that maybe and just maybe, these aspects of an anime really `}<strong parentName="p">{`do`}</strong>{` affect my enjoyment of a show. The anime could have a great plot, but since this media encompasses the music, voice acting and animation… Everything comes together to `}<strong parentName="p">{`define`}</strong>{` the anime that we love. It affects how we view said anime. While we easily fill in the blanks when it comes to reading manga, we couldn’t do the same with anime.Here are the four things that will make or break my enjoyment for an anime.`}</p>
    <p><em parentName="p">{`Warning: This post is an opinion piece. It’s harsher in tone compared to my usual posts. In a way, it’s me dumping my gripe with certain anime, all in a single blog post.`}</em></p>
    <h2>{`Make or Break #1: Mood`}</h2>
    <p>{`While reading a manga, the very definition of a mood is the `}<strong parentName="p">{`dialogue`}</strong>{` and the panel composition. For anime, it’s the camera angle and the music, as well as the slow build up leading to said scene. I really couldn’t stress the importance of the `}<em parentName="p">{`“mood”`}</em>{` in important scenes. If it is a serious scene, it should be done so. If it’s a melancholic scene, it should be `}<em parentName="p">{`made `}</em>{`so. Some anime studios just couldn’t nail the scenes I envisioned from the manga. One example is the recent `}<RemoteLink to="https://myanimelist.net/anime/38408/Boku_no_Hero_Academia_4th_Season" mdxType="RemoteLink">{`My Hero Academia S4`}</RemoteLink>{` that I just completed in Netflix. The entire scene of Lemillion versus Overhaul is `}<strong parentName="p">{`intense `}</strong>{`in the manga. They both have stakes in this fight and they refuse to budge from their beliefs. But the build up to the scene was done poorly in the anime. I ain’t feeling the intensity of their supposed battle. They actually gave Powerpoint slides for the scene that has Overhaul bashing up Lemillion after he lost his quirk!`}</p>
    <p>{`I was furious when I saw the episode. Like, darnit I was looking forward to the scene! Lemillion is every bit of a hero like Deku which is kinda the theme/message for the Overhaul arc. While I understand that Deku is the MC, the Overhaul arc is `}<strong parentName="p">{`Lemillion’s arc`}</strong>{`! We get to see how the Big Three works on the field, and why Sir Nighteye thinks Lemillion is the perfect All for One holder. We should be given the opportunity to see him shine in full sakuga mode… So why on earth is his fight scene done so `}<em parentName="p">{`poorly`}</em>{`?`}</p>
    <p>{`Later on, I noticed that they actually allocated the effort to animate Deku’s Full Cowling 100% scene versus Overhaul instead. I… I think the effort is wasted here. It wasn’t as satisfying as I expected it to be. Seriously, I think S4 is really `}<em parentName="p">{`lacking `}</em>{`in terms of `}<strong parentName="p">{`impact`}</strong>{` that the first three seasons gave us. The `}<strong parentName="p">{`mood`}</strong>{`… The intensity of each scene as well as the build up… It just ain’t there.Funny how this single scene defined the entire season for me ): Come on, S5! You better do this good.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Make or Break #2: Flow`}</h2>
    <p>{`Along with the mood of an anime, I think the `}<strong parentName="p">{`story flow`}</strong>{` is equally important. How does one make a serious scene the way it should be? The answer - the build up. I’m sure that you get what I mean when I say that each anime got this`}<em parentName="p">{` certain flow`}</em>{` to it. Each scene has this throwback to the other scene, or everything that happened is in reaction to the things that happened prior to this. All of which comes along for a grand finale, etc. If you watch a shoujo, you expect the couple’s romance to build up nicely. If you are watching an anime movie, you’d expect a certain theme that the director will happily unfold and explore. Each anime’s humble beginning starts off by telling you the basics and as the episode count goes higher… You learn more about the story. You, the viewer, starts having certain expectations for the story. Plot twists start trickling in. They may have been hinted or otherwise, but the flow is the very reason you watch the anime for.`}</p>
    <p>{`You enjoy the flow. It feels… `}<em parentName="p">{`expected`}</em>{`. Thus, it takes a masterful twist and turn to create an absolutely different ending that the viewers want it to be. Some directors managed to do it, some couldn’t. The freshest example that I could think of (Since I rewatched it recently) that fails to do this is `}<RemoteLink to="https://myanimelist.net/anime/35849/Darling_in_the_FranXX" mdxType="RemoteLink">{` Darling in the Franxx`}</RemoteLink>{`. You see, Darling in the Franxx (ignoring the sexual innuendos/implication) had a solid statement to it. I really enjoyed the whole idea of Darling in the Franxx, but the sudden twist at the end didn’t feel natural at all. I read many comments on how it was hinted all along. However, due to how the previous scenes were set up, I think they screwed up their chance to do the whole she-bang right.`}</p>
    <p>{`True, the hints were there. We expected a grand reveal of the nefarious plot behind the adults but the `}<strong parentName="p">{`flow `}</strong>{`leading to the scene isn’t done well. I think if they got a few extra episodes to set the mood in before the reveal, it would have been much, much better received.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Make or Break #3: Dialogue`}</h2>
    <p>{`Different types of authors do different types of dialogue. Some enjoy the straightforward fashion where the dialogues are written by having the characters react to things, or they simply handle the entire dialogue carried by answers and questions. Some (And mostly light novel writers, I noticed) enjoy writing their dialogue in an implication manner. The characters don’t necessarily react to the current happenings. They insinuate. Or they simply laced their words with hidden jabs, so only those who knew the context understood.`}</p>
    <p>{`I think many viewers actually enjoy the latter dialogue type since it spawns a ton of forum threads on `}<strong parentName="p">{`“what does character A truly mean”`}</strong>{`, but the dialogue doesn’t have to be written like a maze for me to enjoy the anime. `}<em parentName="p">{`I just need them to be written as though these characters are living, breathing individuals in their own world`}</em>{`. What I mean by that is, make those dialogue from Character A sound `}<strong parentName="p">{`believable`}</strong>{` as a person from the fantasy world. Sometimes it feels as if the writer isn’t even thinking about how the character would talk in an intimate or dangerous situation. It feels as though they pluck the dialogue from a Manga for Beginner textbook! It’s easy to quickly break the immersion if the dialogue isn’t written well for me. Once it comes falling apart, you can just feel yourself drawing away from the story. Fanfic writers can do your story better! >:(`}</p>
    <p>{`One such anime is `}<RemoteLink to="https://myanimelist.net/anime/38080/Kono_Oto_Tomare" mdxType="RemoteLink">{`Kono Oto Tomare`}</RemoteLink>{`. I tried the first episode on Netflix and didn’t like how they handled the dialogue. It was super textbook-ish! The delinquents’ dialogue is 100% the same as any textbook delinquent out there. The MC’s and his soon-to-be friend’s dialogue just feels like a copypasta. The dialogue didn’t have it’s own soul at all. I heard many good things about this series so I feel compelled to at least finish Episode 3 with hopes that it gets better. But for now, I’m pausing my progress with this anime. I’m sorry to those who truly enjoyed this anime. Maybe this anime is not for me.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Make or Break #4 - Consistency and Continuity`}</h2>
    <p>{`I remember watching a documentary on moviemaking, about how each scene had a script supervisor to make sure every scene flows correctly. I’ll add the YouTube video link below so you can watch the explanation.`}</p>
    <Video src="https://www.youtube.com/embed/NAvn7CNpdB8" title="Consistency and continuity of a scene" mdxType="Video" />
    <p>{`When it comes to anime, if a pivoting scene is animated shoddily, I could easily drop the anime depending on how serious the faults are. For example, colouring done wrongly, characters teleporting from across the maps or even in-between frames go poof. I get it, animators are rushed to complete their work and the studio needs to push the episode out. But even the viewers have a certain standard if you want us to continue watching the show! I remember watching `}<LocalLink to="/blog/assassins-pride-is-an-assassins-shame/" mdxType="LocalLink">{`Assassin’s Pride`}</LocalLink>{` and there’s this episode that made me boil on the inside due to how they approached the animation. It was good at first then it went downhill after that. It felt as if the studio didn’t even bother to do a good job because they couldn’t be bothered.`}</p>
    <p>{`I’m not asking for a perfectly fluid animation, nuh-uh. Anime studios should pick an animation pace and keep up the standards from Episode 1 to the last episode. Done.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`All in all`}</h2>
    <p>{`Phew. I got it out of my chest, finally. After years of watching anime, you’d think that one would be more lenient with what they watch. Nope, I get more picky actually. Either do it good or I’ll drop the anime in a heartbeat. I need to clear my backlog!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      