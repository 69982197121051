import PropTypes from 'prop-types';
import styled from 'styled-components';

import $ from '../../../styles/globals';
import { getDate } from '../../../utils';

const Published = styled.div`
  color: ${$.brown4};
  font-size: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;

  > span {
    margin: 0 ${$.layout.margin1}px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${$.layout.margin8}px;
  width: 100%;
  max-width: 300px;
`;

const ProfilePic = styled.img`
  border-radius: 999px;
  margin-right: ${$.layout.margin2}px;
  width: 50px;
  height: 50px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Rubik Regular;
  font-size: 15px;
  color: ${$.brown4};
  width: calc(100% - 50px - ${$.layout.margin2}px);

  > *:first-child {
    font-family: Rubik Regular;
    font-weight: bold;
    margin-bottom: ${$.layout.margin1}px;
  }
`;

const Author = ({ published, timeToRead, name, pic }) => (
  <Container>
    <ProfilePic src={pic} alt={`${name} profile picture`} />
    <Info>
      <div>{`By ${name}`}</div>
      <Published>
        <time dateTime={new Date(published).toISOString()}>
          {getDate(published)}
        </time>
        <span>·</span>
        <div>{`${timeToRead} min read`}</div>
      </Published>
    </Info>
  </Container>
);

Author.defaultProps = {
  published: '',
  timeToRead: 1,
  name: 'nabe-chan',
  pic: '',
};

Author.propTypes = {
  name: PropTypes.string,
  pic: PropTypes.string,
  published: PropTypes.string,
  timeToRead: PropTypes.number,
};

export default Author;
