import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const RemoteLink = makeShortcode("RemoteLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`AWS has a ton of services waiting to be added into your dev flow, but sometimes you find yourself wanting to test things out first before you fully commit into said service. I prefer to use my test account to `}<em parentName="p">{`try`}</em>{` services first before I move on to the production account.`}</p>
    <p>{`One of the many conundrums I have when trying AWS services out is dictating which SSH identities I am currently using. You `}<em parentName="p">{`could`}</em>{` switch SSH identities on the fly, but it is too troublesome! Here's how you skip all of that.`}</p>
    <p>{`Once you're done with these steps, you can use git without needing to know which SSH identity you are currently using. It'll always work!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Step 1: Open your ~/.ssh/config file`}</h2>
    <p>{`We'll be editing the `}<code>{`SSH config`}</code>{` file directly for this. Here's how your SSH identity entry should look like before we change it:`}</p>
    <pre>
  Host git-codecommit.*.amazonaws.com
  <br /> HostName git-codecommit.*.amazonaws.com
  <br /> User AWS_SSH_ID
  <br /> IdentityFile ~/.ssh/aws_key
    </pre>
    <h2>{`Step 2: Rename your Host and HostName`}</h2>
    <p>{`Rename your `}<code>{`Host`}</code>{` to something easily recognizable to you. E.g. test, dev. Then change the `}<code>{`HostName`}</code>{` to AWS CodeCommit's URL. Make sure the region is where your repo lives. Below is an example of how your changes should look like with multiple AWS CodeCommit SSH identities:`}</p>
    <pre>
  Host test
  <br /> HostName git-codecommit.ap-southeast-1.amazonaws.com
  <br /> User AWS_SSH_ID
  <br /> IdentityFile ~/.ssh/aws_key1
    </pre>
    <pre>
  Host prod
  <br /> HostName git-codecommit.ap-southeast-1.amazonaws.com
  <br /> User AWS_SSH_ID
  <br /> IdentityFile ~/.ssh/aws_key2
    </pre>
    <h2>{`Step 3: Update your AWS CodeCommit repo remote URL`}</h2>
    <p><inlineCode parentName="p">{`cd`}</inlineCode>{` into your AWS CodeCommit repo, and run `}<code>{`git remove remote origin`}</code>{`. Then update the repo's remote URL to the following URL:`}</p>
    <code>ssh://test/v1/repos/your repository name</code>
    <p>{`We are essentially mapping the remote URL based on the `}<inlineCode parentName="p">{`Host`}</inlineCode>{` name. Now you can have multiple SSH identities mapped to each of your local repo! Goodbye headache!`}</p>
    <Ads mdxType="Ads" />
    <p>{`This blog post is based on this `}<RemoteLink to="https://gist.github.com/justinpawela/3a7056cd592d688425e59de2ef6f1da0" mdxType="RemoteLink">{`gist`}</RemoteLink>{`! Thanks justinpawela!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      