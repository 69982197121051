import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const RemoteLink = makeShortcode("RemoteLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`It was a fairly short ride for you, the readers, but it felt like ages to me when this blog first came to be. It was about May 2019 when the basic structure of the blog and design came to me. I honestly really wanted a blog by then, but I hadn't got a clue how I want it to be. So when the lightbulb goes on in my head, I quickly scribbled down the idea and sketched out the basic idea of how the hotpot mascot is going to be. It was then that I came up with the name `}<em parentName="p">{`geeknabe`}</em>{`. I wanted it to be a mix of everything geeky that I love like how you would do a hotpot, hence the name of this blog. I kinda jumped the gun too by buying the domain name almost immediately after I thought of it.`}</p>
    <p>{`Since then, this blog has undergone a full design change three times and moved from one domain service provider to another twice. This blog was under development for about 6 months before I am fully confident that it is ready. Creating and hosting a blog is an entirely new experience for me, and I wasn't peeved that I had to experiment a ton to get to where I am now. The whole development and writing are really fun for me, and I honestly can't wait to do some writing whenever I have free time.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Blog Stats from October to December`}</h2>
    <p>{`Technically speaking, my blog only started hosting her first visitors during late October but I've been really proud of where it is now. Here are some stats so I can brag a little :DIn October, I posted a link to Fire Emblem's Subreddit and the page views ballooned. It was a link to a Tea Time (Edit: No longer active) I did. I was absolutely floored by the response.`}</p>
    <Image alt="Blog stats for October 2019" caption="Blog stats for October 2019" name="october_page_views_geeknabe.jpg" mdxType="Image" />
    <p>{`In November and December, I started looking for a circle of anime/manga/games bloggers to join and it honestly paid off. I get to connect with like-minded folks, and it's just so much happier blogging with that in mind. Even `}<RemoteLink to="https://twitter.com/geeknabe" mdxType="RemoteLink">{`tweeting`}</RemoteLink>{` is fun now.`}</p>
    <Image alt="Blog stats for November 2019" caption="Blog stats for November 2019" name="november_page_view.jpg" mdxType="Image" />
    <Image alt="Blog stats for December 2019" caption="Blog stats for December 2019" name="december_pageviews_geeknabe.jpg" mdxType="Image" />
    <p>{`I made an account in `}<RemoteLink to="https://www.pinterest.com/geeknabe/" mdxType="RemoteLink">{`Pinterest `}</RemoteLink>{`too, so you'll occasionally see custom made graphics to promote my blog post there. It's nothing too fancy in terms of Pinterest stats but I am still pretty happy with it. After all, I only started this account in November.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Graphic for tea time quiz as a pin in Pinterest" caption="I made a graphic for the Tea Time app I did, and added it as a pin in Pinterest." name="fire-emblem-tea-time-app-geeknabe.jpg" mdxType="Image" />
    <Image alt="Graphic for blog posts as a pin in Pinterest" caption="Graphic for blog posts look something like this. Not the best but eh, gets the job done." name="ascendance-of-a-bookworm-blog-post-pinterest.jpg" mdxType="Image" />
    <h2>{`What's my blog content like?`}</h2>
    <p>{`In 2019, I wrote `}<strong parentName="p">{`20 blog posts`}</strong>{`, `}<strong parentName="p">{`1 app`}</strong>{`, `}<strong parentName="p">{`1 fan page`}</strong>{`, and added `}<strong parentName="p">{`2 guest posts`}</strong>{`. I actually planned to write at least `}<strong parentName="p">{`30 blog posts`}</strong>{` and code at least`}<strong parentName="p">{` 2 apps`}</strong>{` this year but all's fair I guess. I'm really happy with the content writing overall. Also, you might have noticed that we have a guest writer. Yup, `}<em parentName="p">{`mari-chan`}</em>{` is one of `}<em parentName="p">{`geeknabe`}</em>{`'s newest addition to the writer's team! I haven't got the time (and design inspiration) to create a team page yet, but I will announce her as a team member officially soon.`}</p>
    <h2>{`The blog is constantly improving`}</h2>
    <p>{`Throughout November and December, I've been constantly improving my blog's look and user experience. It's a little closer to what I envision now, but there's still more work to be done. I have uh, about 5 projects that I want to get worked on as soon as possible next year too! It's a list of stupid crazy projects but damn it, if I don't get it worked on it'll stay in my backlog forever. What projects are those? Well, it's a secret for now :)`}</p>
    <h2>{`Future plans`}</h2>
    <p>{`In 2020, I plan to write even more blog posts focused on anime and manga. I love these two niche hobbies of mine, so I never run out of things to write about. As for games, I'm (always) a little tight on money so this topic is a little harder for me, so I may jump into writing for IOS games. There are a ton of great free-to-play IOS games out there if you know where to look. I have a ton of tech topics to write about too, since my job involves developing for the web. I try to write about topics that relates to blogging, or tips that I find really useful. I may even write a guide on SEO or how to create a blog like geeknabe, but it's a little involved so it may take awhile.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`All in all`}</h2>
    <p>{`I notice blog posts that answers to the readers' needs get more viewership. I admit, I like seeing the stats of my blog rising up. However, I plan to balance between writing clickbaity ones and opinionated ones. I try my best to adhere to SEO keywords but there are times I want to write what I want to write. This blog is my little happy place and I intend for it to stay that way.## Thank you!`}</p>
    <p>{`This blog couldn't have been what it is without the readers. I am really thankful to all the anibloggers that I met along this journey. You can check them out Everyone's been really supportive and friendly, it's hard not to like what I do now! Thank you so much!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      