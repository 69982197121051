import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const RemoteLink = makeShortcode("RemoteLink");
const Ads = makeShortcode("Ads");
const Blockquote = makeShortcode("Blockquote");
const Image = makeShortcode("Image");
const LocalLink = makeShortcode("LocalLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I need to make this clear before I start gushing about this anime. I have read the manga and I `}<strong parentName="p">{`love`}</strong>{` it. The best thing about the anime is that it took in what’s unique about the manga and `}<strong parentName="p">{`made it a reality on screen.`}</strong>{` `}<RemoteLink to="https://en.wikipedia.org/wiki/Lerche_(studio)" mdxType="RemoteLink">{`Studio Lerche`}</RemoteLink>{` could have gone with the generic stylistic choice but nope, they recognized what made the manga good and took it to the big screen. This is honestly the best marriage they can do for both mediums. Here’s a quick summary of the plot.`}</p>
    <Ads mdxType="Ads" />
    <RemoteLink to="https://myanimelist.net/anime/39534/Jibaku_Shounen_Hanako-kun" mdxType="RemoteLink">
  Jibaku Shounen Hanako-kun
    </RemoteLink> is a shounen series with a dash of supernatural incidents and troubled
children. You’ll get your fill of action scenes, self-reflections, friendship and
whatnot but all in all, Hanako-kun is first and foremost a mystery-themed story.
Set in a high school with 7 mysteries, Yashiro Nene is just looking for someone to
love her. Feeling tad desperate for a boyfriend after her first rejection, she seeks
the 7th school mystery to grant her wish whom we all know as Hanako-san of the Bathroom.{' '}
    <p>{`It turns out the school mysteries are real, and Nene worked her hardest under Hanako-kun’s advice to win the love of her crush. Things happened, and now Nene is stuck with Hanako-kun for a great adventure (in school). She’ll gradually meet other school mysteries and made friends with fellow students. The plot starts to open up a little, and it turns out the whole school mystery debacle is more than it seems.`}</p>
    <Blockquote mdxType="Blockquote">
  Just what are the school mysteries all about? Is there more to Hanako-kun than
  what meets the eye?
    </Blockquote>
    <p>{`So far, the manga never went past the school borders as most of the adventure took place within it. The focus of the plot is generally between Hanako-kun and the school mysteries, as well as with Nene. Each school mystery is actually an apparition with their personal motives. They aren’t bad seeds, but just plain weird in their own ways. Without spoiling too much, let’s just say that the slow reveal of the mystery is done really well. Here are some of the things I noticed and really like in the anime.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Thick lines, just the way I like it`}</h2>
    <p>{`The uniqueness of the art is partially due to how thick the lines can be. The characters moving on screen is exactly the way it is from the manga. I wasn’t expecting a full-on copy of the manga’s art, but I am glad they did.`}</p>
    <Image alt="Hanako-kun is saying hi here! Hello!" caption="It's the exact same pose from the manga, plus the lineart! Urgh, so good looking." name="jibaku_shonen_hanakokun_2.jpg" mdxType="Image" />
    <h2>{`Warm colour palette, just the way I love it`}</h2>
    <p>{`Each artist has their own way to colour their manga cover pages, and Hanako-kun’s artist like them warm. It’s similar to Neverland but both are very distinguishable. While Neverland like to draw perspective-based cover, Hanako-kun loves to fill every inch of space available with art. Here I thought they’ll make the whole anime as colourful as possible but nay, it’s as though they took Hanako-kun’s preferred colours and splashed the entire anime with it.`}</p>
    <Image alt="Jibaku Shounen Hanako-kun manga cover page" caption="This is from the manga's first chapter. Notice the colours?" name="jibaku_shonen_hanakokun_3.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`Borders and manga panels as effects`}</h2>
    <p>{`It’s a smart choice and I’m glad they went with that. The anime oozes with a ton of effects. There were scenes where manga panels slide in to depict scene change or motion, and there’s a ton of nouveau borders added in. It made the scenes more lively, and I’m glad they added it in.`}</p>
    <Image alt="Screencap from the opening" caption="A screencap from the opening. I really like the borders and stylistic approach here." name="jibaku_shonen_hanakokun_7.jpg" mdxType="Image" />
    <Image alt="Cutscene overlay at work here." caption="Interesting approach at work here. Nene in the background is still moving, but the box with the current scene is added on top of it." name="jibaku_shonen_hanakokun_5.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`Ton of shading and really nice lighting at work`}</h2>
    <p>{`Do you know what they say about anime with some amazing shading at work? You’re watching good quality anime.`}</p>
    <Image alt="Final scene for the first episode." caption="Here's a nice scene. Lighting is really nice, and the shadows really pull your attention in." name="jibaku_shonen_hanakokun_6.jpg" mdxType="Image" />
    <h2>{`All in all`}</h2>
    <p>{`It’s only the first episode but I’m feeling it. If they followed the manga to the core, then this ride will be a `}<strong parentName="p">{`great one.`}</strong>{` Very beautifully done! Thanks Studio Lerche. I hope it won’t turn bad like how Assassin’s Pride turn out to be. I covered Assassin’s Pride `}<LocalLink to="/blog/assassins-pride-is-a-solid-fall-2019-show/" mdxType="LocalLink">{`here `}</LocalLink>{`and `}<LocalLink to="/blog/assassins-pride-is-an-assassins-shame/" mdxType="LocalLink">{`here`}</LocalLink>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      