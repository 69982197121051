import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Blockquote = makeShortcode("Blockquote");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I’ve been playing My Time at Portia (MTAP) for at least 90 hours now, and I love every minute of it. Unfortunately, if dropping frame rates, occasional bugs and glitches are deal breakers for you, then you probably won’t like this game. Nevertheless, please look past the faults and give it a try!`}</p>
    <p>{`In this Nintendo Switch game, you are a builder in a post-apocalyptic world. The game starts by dropping you off into a house-cum-workshop your Pa left you in the world of Portia. The goal of this game is to rebuild the world and in the meantime, get married, and have kids. (Psst. You get to two-time your partner or three-time! And divorce! God I love it)`}</p>
    <Ads mdxType="Ads" />
    <p>{`It’s so… charming! The NPC(s) have personalities that somehow make you love them tons, despite their`}<em parentName="p">{` repetitive`}</em>{` dialogues. It just sucks you in deep and the next thing you know, its already 3 am on a workday. I only have like, 4 hours to sleep because of this game. Chopping wood is just so relaxing...`}</p>
    <p>{`There are many things to do and happening at the same time. While the goal of this game is to rebuild the world, you as the player would need to level yourself up. The higher level you are, the more actions you can do. Everything in the world of Portia requires `}<strong parentName="p">{`stamina`}</strong>{`. For example, fighting enemies, fishing, collecting, chopping wood. So in a manner of speaking, stamina = experience.`}</p>
    <p>{`Time flows in this game like Harvest Moon, including a day/night system. As such, you can only do limited actions based on the time of the day. Here are 6 tips and tricks that I did to improve the whole My Time at Portia experience.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`1. Make sure to hoard Wood and Stone`}</h2>
    <p>{`When you first step foot into Portia, you will need a steady stream of Wood supply. And supply comes in the form of stacks in which each stack can hold 999 of a single item. Oh, you think you’ve got enough Wood? NO YOU DON’T! Hoard Wood, like how a dragon hoards their gold.`}</p>
    <p>{`Wood is important because it powers a large majority of your furnaces, and you will find yourself always scrounging up for some extra Wood as it’s crafting ingredient. So make the most of your time and chop for more wood if you’re done with the day. Gain more stamina, and you’ll have more opportunity to chop more wood!`}<strong parentName="p">{` Trust me`}</strong>{`. You’ll know what I mean. So here are some sub-tips and tricks on how I make the best of my time in the game to collect more wood and stone.`}</p>
    <Ads mdxType="Ads" />
    <h3>{`1a. Spend 2 weeks each month collecting Wood and Stone`}</h3>
    <p>{`I like to dedicate 2 weeks each season/month to stock up on Wood and Stone. One week for wood, one week for stone. I normally do it when my Abandoned Ruins weekly fee expires, so I don’t waste my money. You `}<em parentName="p">{`can`}</em>{` get stone in the Abandoned Ruins but I prefer getting the regular chunks of Stone dotted around Portia. These give you other stuff such as Marbles, Blood Stones and Topaz(s). You will need these for some missions and crafting.`}</p>
    <h3>{`1b. Keep your Stone to generate more Gols`}</h3>
    <p>{`You might be wondering, why am I asking you to hoard Stone when most people suggest trading Stone for Wood at A&G Construction (on the right of Gust’s desk)? Because as you now know, Wood is the `}<strong parentName="p">{`life source of this game`}</strong>{`. Well, it’s `}<em parentName="p">{`because`}</em>{` you can earn`}<strong parentName="p">{` extra gols `}</strong>{`by turning your Stone into either Stone Stools or Stone Grinders! (I personally prefer Stone Grinders)`}</p>
    <p>{`If you’re like me and have been putting aside some days to chop wood, you shouldn’t have the need to trade stone for wood unless of course, you urgently need a large amount. Then go ahead by all means! By the way, you can also trade Wood for Stone. A handy tip if you require more Stone.I mentioned why I prefer turning Stone into Stone Grinder. Here’s why:`}</p>
    <ol>
      <li parentName="ol">{`Stone Stools sells for 7 gols but uses 10 stools.Stone Grinders sells for 4 gols, but only uses 5 stones. 2 Stone Grinders uses the same amount as 1 Stone Stool, but 2 Stone Grinders sells for 1 gols more than 1 Stone Stool.`}</li>
      <li parentName="ol">{`If we were looking at return on investment (ROI) alone, Stone Grinders just seem more worth it.`}</li>
    </ol>
    <Ads mdxType="Ads" />
    <p>{`You can sell these Stone items you crafted to either Mars, Paulie or both. There’s a catch though! Each day, a shop has a limited budget to buy things. The shop with the biggest budget is A&G Construction but unfortunately,`}<em parentName="p">{` they don’t buy Stone Grinders`}</em>{`!`}</p>
    <p>{`I don’t calculate how much each shop is able to buy from me so I usually just make like… roughly 70% Stone Grinders, and 30% Stone Stools every time.Another thing to note is that the market prices fluctuate daily. I suggest holding out until the market rate is at least 120% ABOVE. Mine has gone up to 133%. So, be patient if you want to make the most profits! Make sure to buy all your necessary supplies when the market is down, like below 100%, and sell all your things when it’s high.`}</p>
    <Blockquote mdxType="Blockquote">Remember to always BUY LOW, SELL HIGH. </Blockquote>
    <h2>{`2. Always keep your furnaces going 24/7, all year round`}</h2>
    <p>{`This is one reason why you’ll always need wood; it powers most of your furnaces.  Some people describe this game as Harvest Moon-like which it `}<strong parentName="p">{`is`}</strong>{` `}<strong parentName="p">{`in a way`}</strong>{`, but you’re not a farmer. You are a `}<strong parentName="p">{`builder`}</strong>{`. Planting stuff is a… cute side hobby you do to get ingredients to cook.`}</p>
    <p>{`As a builder, you’re gonna need building materials. So yes, you’ll be mining and quarrying your raw materials to smelt them. This is where furnaces and wood come in. You’ll be starting off with a basic furnace and as you progress, you’ll be assembling even bigger furnaces which in turn, are able to smelt a bigger variety of ores/materials. I recommend always having all types of these already-smelted materials like bronze, copper, iron etc. on hand, `}<strong parentName="p">{`at all times.`}</strong></p>
    <Ads mdxType="Ads" />
    <p>{`I came across this tip by chance on Reddit and the one material you’ll need quite heavily in the future, in big amounts are `}<strong parentName="p">{`CARBON STEEL BARS`}</strong>{`. From the moment you have access to this material, `}<strong parentName="p">{`START SMELTING IT A.S.A.P.`}</strong>{` And keep smelting it, constantly. The additional ingredient needed to make a Carbon Steel Bar is Charcoal which comes from, tadaaaa! You’ve guessed it, `}<strong parentName="p">{`WOOD`}</strong>{`.`}</p>
    <p>{`Another one late-game tip is when you have the electric furnace, every item available takes `}<strong parentName="p">{`ages `}</strong>{`to produce. Once you have access to this, `}<strong parentName="p">{`START SMELTING`}</strong>{` once you have the ores and the correct power stone so you won’t be wasting your time!`}</p>
    <h2>{`3. Do not rush your missions, but sometimes rush`}</h2>
    <p>{`If the mission/commission does not have a deadline, please...`}<strong parentName="p">{`TAKE YOUR TIME. `}</strong>{`A majority of the story missions and commissions don’t usually have deadlines. Most of the time, you’ll find yourself with 2 or 3 missions or commissions ongoing at the same time. It can get overwhelming with all the wood chopping and relic or part hunting you have to do as well.`}</p>
    <Ads mdxType="Ads" />
    <p>{`But I `}<em parentName="p">{`HIGHLY`}</em>{` recommend that you finish the commissions you get from the commerce guild within 1 day, as you can only accept `}<strong parentName="p">{`ONE`}</strong>{` commission at a time. No, not one a day, but `}<strong parentName="p">{`ONE`}</strong>{` at a time.So why should you `}<strong parentName="p">{`not idle`}</strong>{` with a story mission that does not have a deadline? That’s because...`}</p>
    <ol>
      <li parentName="ol">{`You’ll get gols. If you finish the mission daily, that means you’ll be earning daily. We need to make the most of our time! Save your money because you’ll need lots of it for land and upgrades.`}</li>
      <li parentName="ol">{`Get to the commerce guild by 7:57 AM, as you will want to snag the best ones before that `}<em parentName="li">{`jerk`}</em>{` `}<strong parentName="li">{`HIGGINS`}</strong>{` gets to it. Higgins can and will take story commissions that are placed on the board. You should complete all of it as they often give out the highest amount of money and exp.`}</li>
      <li parentName="ol">{`Okay, so you got the best commission and finished it. Now keep it up! By the end of the year, you should be ahead of Higgins in terms on workshop rankings. Not only did you get some delicious rewards and presents, but you also get to rub it in Higgins’s face, hah TAKE THAT!`}</li>
      <li parentName="ol">{`If you wanna play dirty, look at the next one with the highest rep points once you’ve accepted a commission. Keep looking at it until Higgins chooses one and goes away (it’ll take 1-2 minutes IRL). That way, he’ll be forced to choose the ones with lower rep points. Don’t feel bad cuz he’s a jerk. Trust me.`}</li>
    </ol>
    <h2>{`4. Friends with benefits make your life in Portia easier`}</h2>
    <p>{`It’s exactly what it sounds like. Be friends with everyone and you’ll get really good perks! You can check out the wiki to see who offers what. Choose which NPC is worth the effort to craft their favourite things! You could also buy `}<strong parentName="p">{`Rose bouquets and Asteria bouquets`}</strong>{` daily from Alice when you have stable, daily income from doing commissions and have extra gols.`}</p>
    <p>{`Rose bouquet is the one universal item that everyone in Portia likes, but there’s only ONE for sale a day. Fortunately, Alice sells 3 Asteria bouquets a day. However, Asteria bouquets doesn’t give as much friendship points as Rose bouquets.Here are the ones that many people recommend you befriend, and rightly so too.`}</p>
    <Ads mdxType="Ads" />
    <ul>
      <li parentName="ul">{`Gale - He offers land discounts when you buy land! Up to 25% if you’re good friends with him. His easiest gift is Tea Table.`}</li>
      <li parentName="ul">{`Gust and Albert – These 2 offer discounts for items and upgrades at their store - A&G Construction. Gust likes Umbrellas, and Albert likes Talismans.`}</li>
      <li parentName="ul">{`Antoine and Presley – Antoine offers extra gols after a commission, while Presley offers more rep points. I normally give Antoine Yellow Bow Ties from fighting a certain monster that’s only accessible after a certain mission. When I run out of these, I just give him and Presley Asteria bouquets.`}</li>
      <li parentName="ul">{`The Civil Corps members – The three of them offers boost for Attack, Defense and Resilience. Most of the time I give them Asteria bouquets.`}</li>
    </ul>
    <p>{`I’m just writing this off the top of my mind, I highly recommend the wiki to see what gifts are the most convenient for you.`}</p>
    <Blockquote mdxType="Blockquote">Don’t forget to talk to people every day!</Blockquote>
    <h2>{`5. Label your storage`}</h2>
    <p>{`Like all games, you’re going to be limited by inventory space when you start as a beginner. The solution to this is making `}<strong parentName="p">{`wooden boxes`}</strong>{`. Lots of it. I started off with five in the beginning. Now, I have 20 to 30 boxes.`}</p>
    <p>{`The most important thing here is to name `}<strong parentName="p"><em parentName="strong">{`every single one`}</em></strong>{`. Not ‘`}<em parentName="p">{`Wooden Box 1`}</em>{`’, no. More like, ‘`}<em parentName="p">{`Wood`}</em>{`’, `}<em parentName="p">{`‘Food’`}</em>{`, `}<em parentName="p">{`‘Loot’`}</em>{`, `}<em parentName="p">{`‘Relics’`}</em>{`, `}<em parentName="p">{`‘Ores’ `}</em>{`etc. you get what I mean. I’ve seen a couple of people saying they regret not organizing and naming these early on because moving things between boxes can be a hassle.`}</p>
    <p>{`I recommend keeping a box just for the things you’re gonna sell, like all your extra Sand and Soil, Stone Grinders, Stone Stools etc. It’ll be easier to keep track. Plus, when the market rate is high you just need to empty this box into your inventory and dump it all on your preferred seller. Easy!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`6. Slow or quicken the time and choose your boosts `}</h2>
    <p>{`Slow time down to 50% from your settings so you’ll get more done in a day. Or speed it up if you want something really fast! Time is in your hands. Lastly, don’t forget the skill tree! The most important skill at the beginning of the game is maxing out the intensive training one!`}</p>
    <h2><strong parentName="h2">{`All in all`}</strong></h2>
    <p>{`This is only my first playthrough so I may have made mistakes in writing this. I got these tips from reading through Reddit threads so these are purely suggestions. Please play however you feel like it! This truly is a fun game, so don’t let it overwhelm you!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      