import styled from 'styled-components';

import RemoteLink from './RemoteLink';
import AuthorNote from './AuthorNote';
import InArticle from '../../../Ads/InArticle';
import Divider from './Divider';
import Image from './Image';
import MessageBox from './MessageBox';
import CustomFont from './CustomFont';

const AdsContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
`;

const Ads = () => (
  <AdsContainer>
    <InArticle />
  </AdsContainer>
);

const StyledDivider = styled(Divider)`
  margin-top: 70px;
  margin-bottom: 70px;
`;

export {
  RemoteLink,
  AuthorNote,
  CustomFont,
  Ads,
  Image,
  StyledDivider,
  MessageBox,
};
