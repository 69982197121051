import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXFanfiction/Chapter/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const AuthorNote = makeShortcode("AuthorNote");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Despite having his head buried under his pillow and blanket, Felix could still hear the sounds of incessant humping next door. He looked out the window and it was still dark. He groaned.`}</p>
    <p>{`Fortunately, despite him covering his head with everything on his bed, he could still hear someone knocking on his door.`}</p>
    <p>{`But unfortunately, Felix was in an even more sour mood since his neighbour was being absolutely inconsiderate about the noise. Basically, he couldn't sleep. So whoever was knocking on his door was at risk of getting their head bitten off.`}</p>
    <p>{`“What?” He hissed through gritted teeth.`}</p>
    <p>{`A familiar redhead forced his head through the opening, “.....”`}</p>
    <p>{`“What the hell is wrong with you? What do you want, Sylvain?” Felix growled at Sylvain's silence.`}</p>
    <p>{`“...Yeap. It's bad here too. Come on, let's go.” Sylvain urged, “Grab your pillow and blanket.”`}</p>
    <p>{`“What for?”`}</p>
    <p>{`Sylvain blinked at him and gestured at the wall between his room and Dimitri's, “Can you sleep?” Felix let out a disgruntled sigh.`}</p>
    <p>{`“No.”`}</p>
    <p>{`“I'm going to crash on Dedue's floor, wanna come?”`}</p>
    <p>{`“....” He looked towards their shared wall and by his calculations— taking in Dimitri's stupidly monstrous stamina— it didn't sound like it was going to stop anytime soon. Felix sighed again, and begrudgingly grabbed his pillow and blanket.`}</p>
    <p>{`“Thought so,” Sylvain grinned.`}</p>
    <p>{`“Shut up.”`}</p>
    <p>{`Yet the moment Dedue opened his door after observing their bedding clutched in their hands, the duscur man said, “Regrettably, the noise has travelled down here as well. Unless you do not mind then, by all means.”`}</p>
    <p>{`“Yikes. Man, you have it worse,” Sylvain said after listening to the rhythmic thumping that echoed in Dedue's room.`}</p>
    <p>{`“.....I'm going back to my room,” Felix said. Annoyed he even entertained the idea in the first place.`}</p>
    <p>{`“No, no, wait. Let's try Ashe's.” Sylvain grabbed him by the collar and pulled him towards Ashe's door. Felix was too tired to fight back and reluctantly followed Sylvain to badger his next victim.`}</p>
    <p>{`“Ashe! Open up, buddy!” Sylvain had to knock a few times actually before the door opened. A blanket-wrapped Ashe greeted them at the door, his face seemingly full of sleep. Felix was about to shake the boy out of irritation for making him wait outside in the cold mountain air while he slept in his quiet, no-sex-noise room.`}</p>
    <p>{`As per his modus operandi, Sylvain stuck his head through the door and paused for a good minute to check whether it was quiet enough. “....Oh….No wonder you're dead asleep, Ashe.”`}</p>
    <p>{`Ashe's sleepy face looked as if he could barely form a sentence in his head, let alone comprehend what was happening, “W-What?”`}</p>
    <p>{`“Could we crash here for the night? Our rooms are…” Sylvain looked at him for an answer.`}</p>
    <p>{`“....Compromised,” Felix finished.`}</p>
    <p>{`“What do you mean?” Ashe asked, his voice croaked.`}</p>
    <p>{`“Well let's just say—”`}</p>
    <p>{`“—The Professor and the Boar have been going at it for hours and we can't sleep,” Felix blurted out before Sylvain could give some pathetic excuse.`}</p>
    <p>{`“W-What…?” Ashe's face went from confused, to shocked, and then confused again as his blanket fell to the floor.`}</p>
    <p>{`Sylvain grinned, “So…can we crash on your floor?”`}</p>
    <p>{`“Wha—I mean sure but, but, what about his Highness and the Professor—”`}</p>
    <p>{`“Hey, Dedue!” Sylvain said loudly, waving at the tall male who was standing outside his room, probably eagerly waiting for good news. “Ashe said it's cool! Come on over!”`}</p>
    <p>{`“Thanks, buddy,” Sylvain gave Ashe, who yelped, a friendly punch on the arm and pushed past him. “Dibs on the corner spot!” Sylvain quickly laid down a cloth in the corner spot and made himself comfortable before Felix could protest.`}</p>
    <p>{`Dedue, despite his massive frame, had near silent footsteps as he entered the room and shut the door behind him, “Sorry for the inconvenience, Ashe.”`}</p>
    <p>{`“N-Not at all, but, but the Professor and his Highness— what, what did you mean, Felix?”`}</p>
    <p>{`Felix blinked, then frowned at the doe-eyed Ashe, “What do you mean, what did you mean? Your future King is having sex with his teacher right now.”`}</p>
    <p>{`“Wha—?! WHAT?!” Ashe was so shocked he stumbled over backwards onto his bed, his face instantly turned red as apparent realisation dawned on him. “But! BUT—”
The silver-haired male was most definitely awake now.`}</p>
    <p>{`“Oh Ashe, don't tell me you couldn't tell?” Sylvain teased from his corner, “You were the one who told me about the thingy at the Goddess Tower? Surely you knew Dimitri was mentally undressing the Professor and already naming their children this whole time?”`}</p>
    <p>{`“S-Sylvain!” He cried. Actually, Ashe was `}<em parentName="p">{`literally`}</em>{` on the verge of tears. It seems Sylvain had shattered Ashe's perfect image of Dimitri.`}</p>
    <p>{`“Ahem,” Dedue cleared his throat. “May I suggest we all get the sleep we came here for?”`}</p>
    <p>{`“Quite right, my man, quite right,” Sylvain chuckled as Ashe crawled underneath his blanket and hid his face under his pillow. Mumbling some incoherent words. Felix couldn't hear and couldn't care less. He just wanted to sleep.`}</p>
    <p>{`He didn't mind the hard floor, it was nothing he wasn't used to, nor was he a stranger to sleeping next to men. They were essentially soldiers after all. Besides, anything would be miles better than listening to his childhood friend have rough sex with their teacher. Anything.`}</p>
    <p>{`Though having thought that, Felix digressed, “I am relieved the Boar finally got it out of his system. Anymore moping from him and I would've personally shoved my boot up his royal ass,” he said. Felix hated seeing Dimitri mope. And Dimitri moped a lot, plus with the added heartbreak the blonde prince was going through, Felix was going insane.`}</p>
    <p>{`“He's definitely getting something out of his system for sure, eh?” Sylvain elbowed Felix.`}</p>
    <p>{`“Tsk, shush.” Felix slapped Sylvain's arm away.`}</p>
    <p>{`“G-Good night, everyone,” Ashe meekly said, his voice quivering. Probably still processing his newfound trauma.`}</p>
    <p>{`“Good night,” Dedue replied, Felix could feel the gentle giant beside him relaxing.`}</p>
    <p>{`Sylvain spoke up, “Good night and thank you for staying with me through these trying times, gentlemen. Same time, same place tomorrow night?”`}</p>
    <AuthorNote mdxType="AuthorNote">
  <p>
    TADAAA! My first smut sdkjfhalskdjfh im so shy please dont look at me
    HAHAHAH As this is a sort of one shot, I didn't write a longer smut scene,
    sorry HAHAH i still hope you enjoyed it! Maybe in another fic i'll write
    Byleth getting a taste of the junior crown prince, eh? XD
  </p>
  <p>
    (also thank you to a bunch of you guys on the discord server for the help
    and ideas HAH, you know who you are XD) While I realise that their walls are
    made of stone and noise may not travel— IT'S OK LET ME HAVE MY FUN OK! XD
  </p>
  <p>
    p.s The scene where Dimitri pins Byleth against the wall is highly inspired
    by this{' '}
    <a href="https://www.pixiv.net/en/artworks/87603933" target="_blank" rel="noopener noreferrer">
      art
    </a>{' '}
    by{' '}
    <a href="https://twitter.com/kokiu_" target="_blank" rel="noopener noreferrer">
      noe
    </a>
    !
  </p>
    </AuthorNote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      