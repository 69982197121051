import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const RemoteLink = makeShortcode("RemoteLink");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I’m sure every aniblogger out there would prefer to write an anime review `}<strong parentName="p">{`after`}</strong>{` they have finished it. Unfortunately, I’m the type of hotpot that enjoys taking her sweet time watching an anime, then forget to write a review once I finish the show. I hop between anime(s) `}<strong parentName="p">{`a lot`}</strong>{` so I think it’s best that I write it now. I get the feeling that my feelings/thoughts for this shoujo anime will remain the same even after completing 26 episodes!`}</p>
    <Ads mdxType="Ads" />
    <p>{`KareKano was suggested to me by `}<RemoteLink to="https://twitter.com/wiredgaze" mdxType="RemoteLink">{`SSSS.HARRISON`}</RemoteLink>{` for the `}<RemoteLink to="https://twitter.com/search?q=%23animexchange&src=typeahead_click" mdxType="RemoteLink">{`Anime X-Change Community Event`}</RemoteLink>{` hosted by `}<RemoteLink to="https://twitter.com/JS_Reviews" mdxType="RemoteLink">{`Jon`}</RemoteLink>{`! It's an event where everyone gets a partner, and they recommend each other a 1-cour anime. I was expecting to get a mystery show, something shounen-ish or even a thematic anime! That’s kinda how 1 cour anime roll, right? So when I got a 2-cour shoujo anime instead, it’s a happy surprise! Yassss, shoujo! I missed you! And it’s from 1998? By the gods, I am `}<strong parentName="p">{`P L E A S E D`}</strong>{`. I am enjoying KareKano a lot, so I’ll trust your taste from now on, SSSS.HARRISON! I’mma stalk your Anilist once I’m done with KareKano <3`}</p>
    <p><em parentName="p">{`Note: I finished Episode 6 as of writing. I might check the manga later! I’m trying my best to preserve this joy from not knowing the story beforehand and just enjoying the anime as it is… `}</em></p>
    <p>{`Shoujo back then are such kickers. They got dem fluff, the doki-doki moments and fun heroines/wingman/wingwoman! I went into KareKano not knowing what to expect and by the end of Episode 1, I got `}<strong parentName="p">{`everything I wanted`}</strong>{` from a shoujo. KareKano is about a girl (Miyazawa) who is super enthusiastic about being the best student because she `}<em parentName="p">{`wants`}</em>{` to and a boy (Arima) who is doing the same because he `}<em parentName="p">{`has`}</em>{` to. Two polar opposites with absolutely different perspectives! Yet they eventually get super close after understanding each other’s circumstances and hurray, they get hitched off by first `}<strong parentName="p">{`HOLDING HANDS`}</strong>{`! Did I scream out of sheer joy at this scene? Yes. Yes I did.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="AAAA" caption="AAAA" name="holding-hands-aaaa.jpg" mdxType="Image" />
    <p>{`Everything went really fast in KareKano. Most shoujo take their sweet time in building the main couple’s relationship, throw a few wrenches here and there before they realize their honest feelings. But KareKano says `}<strong parentName="p">{`nah`}</strong>{` to that and throws us not just one, but A TON OF PROGRESS for us! We first get a really quick and easy explanation of why Miyazawa is pretending to be a model student. She cackles a lot to herself, and calls these thoughts `}<strong parentName="p">{`evil`}</strong>{`. Oh sweetie, would you like to know what I think of everyday?`}</p>
    <Image alt="Mood." caption="Me everyday." name="evil-meme.jpg" mdxType="Image" />
    <p>{`Obviously she couldn’t keep up that `}<em parentName="p">{`honest student`}</em>{` mask at home, amirite? Girl gotta relax too! Anddd her real mask got busted by Arima, the other model student she’s trying to beat. The entire scene came out of nowhere, I was cackling so hard when she kicked him in the gut. Ohhh, she screwed up! Surprisingly, all of this happened in 1 episode. How on earth did they fit in so much content in 1 episode? What sorcery is this? I could have sworn this is enough for 2 episodes. Then the episode ended with this impressive, continuous loop of the camera man/woman running backwards on a bridge placed on top of buildings for 2 minutes or less. Yup, the image below is a screencap of the ending. He/She managed to finish running the whole loop within 2 minutes, wow… (This is a joke, I know it’s fast-forwarded.) I love the ending song but I want my ending animation done in 2D instead of using real life footage! Whyyy.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="KareKano ending screencap" caption="Sorry about the terrible screencap. I could only find KareKano at 360p quality... Oops, is it that obvious I watched the show from somewhere I shouldn't?" name="karekano-ending-screencap.jpg" mdxType="Image" />
    <p>{`Then Episode 2 gets even `}<strong parentName="p">{`meatier`}</strong>{`. You have Miyazawa’s two sisters giving us a recap of the previous episode. We get to see Miyazawa’s family bonding happily and cracking jokes. It’s super wholesome and hilarious, coupled with Miyazawa’s inner monologue all the way to Episode 6. Her thoughts are super relatable (HAHA). I admit, I do get those thoughts where I curse someone’s luck/fortune. Now? I’m just an innocent hotpot :) While Miyazawa’s silly thoughts make up most of the comedic relief, there are a ton of other hilarious scenes that just happened out of the blue. Since the scenes just happened without warning, it doubles the funny factor. It was a really wild ride from the start, I love it.`}</p>
    <p>{`But what’s shoujo without a bit of heart-to-heart sessions and doubting yourself? Ah, the follies of youth. I think KareKano really hit the nail about teenage insecurities when it comes to Miyazawa’s and Arima’s very own thoughts. Miyazawa believes that her pretend-act is just covering up her own ugly self. She doesn’t think too highly of her own real thoughts, unsurprisingly. This caused her to doubt if Arima really does love her. On the other hand, Arima has gotten so used to doing the model student act, he thinks that he is a boring and emotionless person. He himself believes that no one will ever come to love a dull person like him, not even Miyazawa. Part of the reason he came to be fond of Miyazawa is because he sees her as an `}<em parentName="p">{`“original”`}</em>{` person. Sheesh, these two kids… No wonder everything concluded so fast in Episode 1!`}</p>
    <Ads mdxType="Ads" />
    <p>{`The romance in this story is important, but the theme in KareKano is obviously to accept yourself as it is and strive to be a better person. They both need to grow and in high school, you do it with good friends :D All these two need are friends in their circle, some drama, a fight between the couple and they are good to go! I’m looking forward to seeing their growth in later episodes.`}</p>
    <p>{`To be honest, I really `}<strong parentName="p">{`love`}</strong>{` how they portrayed the casts’ inner struggles since I find them really relatable and grounded. I think this is the part that they did really well in KareKano’s story. However, I didn’t enjoy how they slip in a ton of manga panels as replacement for certain scenes. Now, this is totally on the anime studio. It was lovely at first since they only used manga panels for important scenes, but it got used wayyy too often in Episode 5 or 6 (Sorry, I can’t remember which one! Too engrossed to notice!). Is this intentional? Did they miscalculate the budget for each episode? Wait, is that why the ending is just real life footage running on a bridge on top of buildings?!`}</p>
    <p>{`Nevertheless, KareKano is a great shoujo ride so far. I’m loving every silly/doki-doki/serious/heart scene in this anime. It’s perfect. It’s everything I love and what’s great from shoujo back in the days. Thanks SSSS.HARRISON!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      