import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXFanfiction/Chapter/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Divider = makeShortcode("Divider");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Dimitri couldn’t remember the last time he had hair so short.`}</p>
    <p>{`The face staring back at him in the window reflection was a completely different person. His head was light and airy, and he didn’t have to unintentionally glare at people anymore.`}</p>
    <p>{`It had been a few months since Dimitri stepped on to land, he had somewhat grown accustomed to human life, but again, communication with them was a whole different thing. Before he did, his hair had been waist-length. Swimming wasn’t an issue at all, since hair ‘floated’ in the water.`}</p>
    <p>{`It was only when he came onto land as a human did it pose some problems. When Felix and Glenn came to pick him up from their usual meeting spot, Glenn suggested Dimitri get a haircut. But Dimitri pulled out a knife he used to hunt for food and protect himself from sea predators, merely cutting his hair off there and then; tossing the golden locks of hair into the sea to dispose of it.`}</p>
    <p>{`The Fraldarius brothers deemed his haircut sufficient at the time and sent him off with Felix to attend Garreg Mach.`}</p>
    <Ads mdxType="Ads" />
    <p>{`He turned to Byleth and Felix, Byleth who was once again fast asleep in her seat on the train. Felix was listening to something on his phone, since he was wearing earphones.`}</p>
    <p>{`It did occur to Dimitri that his fringe was annoying and it did poke his eyes more often than he liked. But it didn’t occur to him that it was causing him to glare at people. Not until Byleth pointed it out.`}</p>
    <p>{`Dimitri leaned back and groaned, remembering all the times he accidentally hurt other people’s feelings. He buried his face in his palms, unable to handle the embarrassment. He was so focused on swimming like a normal human being he forgot to actually learn how to be a person.`}</p>
    <p>{`And of course, focusing on controlling his strength. It was so much easier to lose control on land. The water dampened a lot of things, it was nature's way of helping him control it. And then there was learning how to walk...smoothly...without tripping. He did give himself credit, he wasn’t tripping or falling over as much anymore. Running was a whole other matter though.`}</p>
    <p>{`Being a fulltime human was so complicated, he had to worry about his image, the grooming involved, and learn to operate their complicated technologies and gadgets. Even though he did use some simpler, modern underwater technology, it still wasn’t as hard as adapting to life on land.`}</p>
    <p>{`He let out another groan. He really should’ve come onto land much earlier than this. Dimitri was painfully aware of how shy he was and hated being so. He didn’t talk much underwater either if he was being honest. He resorted to a lot of nodding and one word replies. People there understood him anyway.`}</p>
    <p>{`He downed his bottle of gatorade that he got at the vending machine before they left, and another embarrassing memory suddenly hit him when he glanced over at Byleth.`}</p>
    <p>{`Dimitri smacked his forehead. It was the time he accidentally kicked her coin under the machine when he meant to lift his foot up and give it to her. He pinched the bridge of his nose, trying to will away the memory. But it didn’t go away, in fact he started remembering more and more things he accidentally did to offend her.`}</p>
    <p>{`Like the time he wanted to reach for his bottle after swim practice and she was standing in front of his bag. `}<em parentName="p">{`Ugh`}</em>{`. He actually said ‘`}<em parentName="p">{`Please`}</em>{` move,’ but he didn’t say the ‘Please’ loud enough and it came out as him ordering her to move. Ah, but even ‘Please move’ was rude. He should've said excuse me instead.`}</p>
    <p>{`He sighed and turned to look out the window, the sky and the outside world had turned dark, lit only by the city lights. It was a beautiful sight, it reminded him of the night sky when he’d swim out into the middle of the ocean.`}</p>
    <p>{`Which reminded him, he needed to go to an ocean, or somewhere with salt water soon. He was starting to feel a trace of dehydration in him, evident by his increasing consumption of electrolyte drinks. Plus it was exacerbated by his little stunt at the party, submerging himself in the pool was only a temporary fix.`}</p>
    <Ads mdxType="Ads" />
    <p>{`While Dedue and him had been sneaking out once a month to the nearest ocean to Garreg Mach, it was proving to be a little bit more difficult since they had this ‘tournament’ coming and Miss Manuela was adamant on the team not missing practice.`}</p>
    <p>{`Again, sure, joining the swim team to be in the water more frequently helped a bit, but they, as mermaids of the Goddess Sothis, needed to be in a real ocean. Dying of dehydration was painful and not at all pleasant, so they say.`}</p>
    <p>{`Byleth and Felix woke up when the meal lady came by and Felix shoved a meal into his hands. Cooked food was something he was finally able to stomach recently, since his diet had pretty much been all manner of seafood, or whatever was available.`}</p>
    <p>{`He looked up to see Byleth staring at him, she did that a lot recently. Ever since she discovered his secret, he’d catch her big purple-blue eyes observing him and then hastily turning away when he met her gaze.`}</p>
    <p>{`“Stop being weird, Eisner,” Felix said to her, “You’re making him blush, it’s gross.”`}</p>
    <p>{`“I was just wondering if he could talk to fish, since he always orders fish to eat,” Byleth said nonchalantly as she fed herself.`}</p>
    <p>{`“He can’t, that’s why he eats them,” Felix answered for him.`}</p>
    <p>{`“How do you know so much, Felix? It’s like...you’re his answering machine,” Byleth said with a chuckle.`}</p>
    <p>{`He turned to see Felix rolling his eyes, “I had to spend every summer holiday at the beach with him, my dad and his dad, and everyone’s dad before me had to do this babysitting shit their whole lives. And now he lives with me, of course I know.”`}</p>
    <p>{`Trains were, in Dimitri’s opinion, very noisy contraptions, there was a constant low buzz and hum. The many posters around the train stations boasted newer train models that were very quiet but they weren’t quiet at all. He wondered if it was common for humans to lie on advertisements like this.`}</p>
    <p>{`But thanks to the noise trains produced, the three of them could have a relatively private conversation.`}</p>
    <p>{`“If you don’t mind me asking,” Byleth continued, “Why did you and your ancestor have to spend so much time with them?”`}</p>
    <p>{`“I mind,” Felix retorted, but explained anyway not without a sigh and his signature grunt of course, “I assume you watched that dumb mermaid movie at the museum?” She nodded.`}</p>
    <p>{`Felix swallowed his food, “Whatever you saw and read, is true. So when Saint Seiros sank the squalid bit of land where his ancestor had run off to hide from her wrath,” Felix pointed at him, “The Fraldrius’s and a few of the King’s loyal retainers were too late to save him. Hence why I’m not a mermaid.”`}</p>
    <Ads mdxType="Ads" />
    <p>{`Byleth frowned, “Hold on, so it’s true Saint Seiros did such a thing? That’s...blasphemy isn’t it?”`}</p>
    <p>{`Felix shrugged, “Who cares? The Church of Seiros doesn’t exist anymore.”`}</p>
    <p>{`Byleth posed another question, unrelenting. “Based on history,” she recounted, “Emperor Edelgard slew King Dimitri because he had allied himself with the corrupted Archbishop Rhea. And the Archbishop, who in a last ditch attempt to allow anyone including the enemy and her supposed allies from escaping, destroys the capital city of Faerghus by grazing it to the ground.”`}</p>
    <p>{`“That’s partially true,” Felix said, “I of course, don’t know exactly what happened, or why it happened, what is true is that Saint Seiros, was the corrupt Archbishop Rhea. Which is why the Church of Seiros was dissolved, replaced by the current Church of Sothis.`}</p>
    <p>{`What actually happened was, King Dimitri was barely alive after Emperor Edelgard did what she did. Dedue’s ancestor, who was one of his vassals, dragged him to the remote island along with whatever was left of his army to recover.`}</p>
    <p>{`One of the soldiers turned out to be King’s Dimtri’s uncle, Lord Rufus’s bastard child, and King Dimitri’s declared him his heir.” Dimitri saw Byleth’s eyes widen in surprise. He assumed she hadn’t known this bit of history. “But Saint Seiros, or Rhea, whatever, found out that King Dimitri had lived. At the same time Emperor Edelgard supposedly felt immense guilt for King Dimitri’s death, since she realised he had been a pawn in the corrupted Archbishop's schemes.”`}</p>
    <p>{`Felix paused to finish his drink. “So to tie up loose ends with the Blaiddyd bloodline, the Archbishop Rhea, now known as the Immaculate one, literally destroyed and sank the entire island into the ocean. And then the whole nonsense about the Goddess deciding to turn them into mermaids yada, yada.”`}</p>
    <p>{`Byleth merely kept quiet, silently scooping food into her mouth. She opened her mouth to speak but stopped, then opened it again, “You said winners write history, right?”`}</p>
    <p>{`Felix shrugged. “Yeah, what of it?”`}</p>
    <p>{`“How…why....no, um. W-Was history rewritten to protect the existence of the mermaids?” Byleth asked, her brows furrowed.`}</p>
    <p>{`Felix squinted at her, letting a few seconds pass between them, “There’s a reason why the Fraldariuses have been in charge of the Fhirdiad museum for a millennia, and in fact Byleth, if you dig deep enough, many of the historical documents were written by my ancestors. Or...rather, rewritten,” he scoffed.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“But the King had many vassals and followers. Why only your family and ancestors? If I’m not wrong, um,” Byleth squeezed her temples, “Our history textbook says that King Dimitri had close relations with the Gautiers and Galateas?”`}</p>
    <p>{`Felix sighed, “That was forged too. They all actually defected to Edelgard’s side.”`}</p>
    <p>{`“And you said that Emperor Edelgard felt guilty for slaying King Dimitri?” She asked. Felix nodded.`}</p>
    <p>{`Byleth put her elbows on the table, steepling her fingers together in thought, “I’m just thinking of why she didn’t tell the world what the Archbishop did to King Dimitri, but in fact, chose to hide it.”`}</p>
    <p>{`“How the hell should I know?” Felix snapped, “Like you, I too found all this out not too long ago, and I’m still finding out what’s true and what’s not true. And who’s to say that what my ancestors wrote is the actual truth also? Everything is a lie.”`}</p>
    <p>{`“Exactly,” Byleth said, pointing at Felix, “If she was striving to take the church down, why not just expose even more of her crimes?”`}</p>
    <p>{`“She did it to protect us,” Dimitri blurted out, and the two debaters spun their heads to look at him. He shrank back a little from the intensity of their gaze.`}</p>
    <p>{`The knot between Byleth’s eyes softened. “She did?”`}</p>
    <p>{`He nodded, “Um, from...what I was told, um, she and King Dimitri didn’t know if there were anymore of her followers out there. So it was safer for King Dimitri to remain beneath the waters.”`}</p>
    <p>{`Byleth had an expression of what Dimitri could describe as a moment of revelation, and she tapped her chin rapidly.`}</p>
    <p>{`“Was this her form of an amnesty between them?” Byleth asked, her eyes sparkling, all hints of sleep had definitely disappeared.`}</p>
    <p>{`He shrugged, ‘I-I...think so.” He paused a while, struggling to get his thoughts out.`}</p>
    <p>{`“Well? Then?” Felix pressed.`}</p>
    <p>{`“But um...sadly, King Dimitri died soon after from his injuries. He didn’t really get to experience much as a mermaid, if you’re wondering,” Dimitri said softly.`}</p>
    <p>{`She narrowed her eyes at him, “You’re named after him right?” He nodded.`}</p>
    <p>{`“Duh,” Felix said as-a-matter-of-factly. “A whole lot of them are Dimitri’s, like the French and their Louis.”`}</p>
    <p>{`Dimitri tilted his head and narrowed his eyes at Felix, “Your family practices the same tradition too though-”`}</p>
    <p>{`“Shut up.”`}</p>
    <Ads mdxType="Ads" />
    <p>{`Even though he was the last King of the Kingdom of Faerghus, King Dimitri was a much beloved figure of the merfolk. Like Felix said, many of his ancestors and relatives were named after him.`}</p>
    <p>{`A chimed rang through the train, Dimitri paused to listen to the announcement, having forgotten where they were actually.`}</p>
    <p><em parentName="p">{`‘Next Station is Garreg Mach.’`}</em></p>
    <p>{`“By the way Dimitri,” Byleth said as she stuffed their rubbish into a plastic bag, “How old are you?”`}</p>
    <p>{`Dimitri’s eyes fell, unsure of where to look.`}</p>
    <p>{`Felix elbowed him, “Just tell her.”`}</p>
    <p>{`Dimitri bit his bottom lip and held his breath, as though a parent was making him tell this nice stranger his age, “I...I think I’m twenty one.”`}</p>
    <Divider mdxType="Divider" />
    <p>{`It had been a month since they went back to Faerghus and visited the museum. Reus
was extremely pleased with the goodies she came back with, evident by the very subtle
smile he gave her.`}</p>
    <p>{`Byleth was thankful her brother’s team got through the qualifying rounds quite easily, but just as luck would have it, they were already up against their arch-nemesis;`}</p>
    <p>{`The University of Ylisse.`}</p>
    <p>{`Once more, a few journalists and reporters from local sports magazines had come to the event, The University of Ylisse was led by their newly minted captain, Chrom, a young man who inherited the enormous burden of making sure that Garreg Mach stayed defeated.`}</p>
    <p>{`And it was a task he succeeded at.`}</p>
    <p>{`While the Garreg Mach team were good, they trained hard and long but the team was just a few seconds behind the University of Ylisse. Dimitri and Dedue of course didn’t have any problems, but perhaps it was also due to Dimitri that the team had a false sense of security that, ‘Everything would be fine’.`}</p>
    <p>{`Sylvain collapsed on the ground as soon as he emerged from the pool, he had tapped the wall of the pool a few seconds behind a swimmer named Lon’Qu in the relay event, sealing their loss.`}</p>
    <p>{`A sob burst out of Sylvain’s chest as his team members pulled him to his feet. From the bleachers, Byleth could see her twin brother quietly placing a hand on Sylvain's back, wearing a very subtle pained expression on his face.`}</p>
    <p>{`They bowed to their opponents before heading off to the showers with their heads held high but it was clear to her they were holding back tears.`}</p>
    <Ads mdxType="Ads" />
    <p>{`In the bus, a heavy silence was crushing the atmosphere, no one said anything. Not even Yuri or Ashe. Coach Manuela had given them some time to mourn their loss and Reus had retreated further into himself - like when they were children.`}</p>
    <p>{`“Alright! That’s enough!” Coach Manuela said as she leaped from her seat, holding on to it for support as the bus was going up the winding road back to Garreg Mach.`}</p>
    <p>{`“As I’ve said before, `}<em parentName="p">{`all`}</em>{` of you have done well. Exceptionally well - exceeding all my expectations. You were only 2 seconds behind Ylisse!” She said loudly, tapping the seat she was holding on to, “2 seconds! And they are the national champions! If anything I think you guys lit a fire under their asses and they are feeling the heat!”`}</p>
    <p>{`Slowly, the atmosphere that was so suffocating before, was gradually being lightened by her.`}</p>
    <p>{`“Be thankful none of you are in your final year yet - you still have a chance next year! And I `}<em parentName="p">{`will not`}</em>{` - I repeat, will not give up until we win the championship!” She roared. “I will keep training you, your children, your grandchildren until that trophy is displayed in the Garreg Mach Hall of Fame once more!”`}</p>
    <p>{`The boys let out a loud cheer, even Reus clapped his hand furiously at her speech. Byleth let out a sigh of relief, her brother was feeling better. She glanced over at Dimitri who was sitting next to Dedue and saw a glint in his eyes, a fire of sorts.`}</p>
    <p>{`It was such an intense gaze it made her feel fire in her chest as well.`}</p>
    <Divider mdxType="Divider" />
    <p>{`Coach Manuela had given them a 2 week break from practice, they earned it and even
suggested they go somewhere on the weekend to have fun. In fact, she suggested staying
away from water parks or the beach, to completely take their mind off swimming.`}</p>
    <p>{`Sylvain, who had bounced back from their defeat rather quickly, instantly suggested the team go to the theme park in Gronder Field.`}</p>
    <p>{`Once the location for the legendary Battle of the Eagle and Lion, it had been turned into a cultural theme park that was regularly used as a movie set with a museum, a themed inn, and even a bustling theme park with rides.`}</p>
    <p>{`Sure, almost everyone had been there once, either during a school excursion or as a family holiday but the park had recently been re-opened after a 2 year renovation period and it was the perfect time to go again.`}</p>
    <p>{`Felix was more than relieved to be rid of Dimitri and to be able to brood on his own again. It seems Felix really did spend his entire life around Dimitri, who was a 21 year old fish, man, or both. He refused to go on ‘a stupid field trip’ despite being invited and just wanted to have some peace.`}</p>
    <p>{`He said it was a little bit tiring to have his whole life planned around Dimitri and Felix actually thanked Byleth. She wasn’t going to lie, Felix thanking her actually shocked her a little. Well, he didn’t actually say it directly, but she knew what he meant.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Byleth, like Felix, wasn’t a trained mermaid speech therapist, or a professional mermaid-human relation officer, so she didn’t know exactly what to do. Apart from the haircut, which improved his visibility a lot of course as for his speech, he was still curtly replying to people since you can’t just change your speech patterns overnight.`}</p>
    <p>{`She thought the trip to the theme park would actually be a great experience for Dimitri, Felix confirmed he hadn’t been to one before. But a slither of worry began to nag her; she was worried how Dimitri would react to suddenly being exposed to so many people. Theme parks were notoriously crowded and hot.`}</p>
    <p>{`The thought of being swallowed by a swarm of sweaty, loud and rowdy people during the weekend sent a shiver down her spine.  Even normal human beings like her felt overwhelmed, what more a mermaid like Dimitri?`}</p>
    <p>{`Initially, she was at a complete loss at how to help Dimitri acclimatise to land, there were so many aspects to think about and this was before the whole theme park thing.`}</p>
    <p>{`But then one day she just decided that it would help her come out of her shell too if she interacted more with him, literally just...talk to him. When she explained her plan, Dimitri looked a little alarmed, like a teacher had forced him to give an impromptu presentation in front of the school.`}</p>
    <p>{`She actually laughed and reassured him they were just going to have conversations with each other like normal friends. His frown relaxed and his body slowly unclenched itself. Dimitri ran a hand over his hair, he seemed to like it and it suited him really well. A few people did a double take and Dimitri was actually blushing when his team mates said he looked good.`}</p>
    <p>{`Dimitri didn’t initiate much conversation, and since this was a practice of sorts for her as well, since becoming a Professor required her to actually...communicate and interact with people - Byleth forced herself to ask him various and random questions here and there.`}</p>
    <p>{`There was one very important thing Byleth wanted to ask Dimitri but it didn’t seem very appropriate, and he didn’t look very comfortable with her asking so many things at once but  he still obliged her; either by giving her nods or head shakes and sometimes one word answers. Occasionally, he’d actually take the time to explain his answers.`}</p>
    <p>{`But...she just...really wanted to ask if he’s given other people ‘mermaid’ kisses before.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Not that she was the type to harp on things, but it was her first kiss. But she guessed mermaid CPR didn’t count. She sucked in a deep breath and just decided to get it over with.`}</p>
    <p>{`“So...why did you give me that um, kiss? T-The mermaid’s kiss. You could’ve just, you know, brought me up to the surface.” She tried her best to be as nonchalant about it as much as she could, hiding her bursting curiosity behind an expressionless face.`}</p>
    <p>{`Dimitri glared a little at her, and Byleth realised she might’ve sounded ungrateful, “Ah, please don’t get me wrong. I am very thankful you saved me that night. I was just wondering about the different outcomes. It’s...a...habit.”`}</p>
    <p>{`His facial anger relaxed and he nodded, “I know.” She let out a sigh of relief. He was still glaring at people...maybe it wasn’t the haircut’s problem after all.`}</p>
    <p>{`It took a few seconds for him to continue his reply, but Byleth was patient. In fact she felt a little bad for Dimitri since Felix was a rather...explode-y person.`}</p>
    <p>{`“I understand the social norms here, that mouth..or lip..joining like that are signs of affection,” he said softly, taking a few more moments to talk, “But this is...merely resuscitation. I...um...I like to..travel the ocean and help drowning sailors, or lost fishermen...”`}</p>
    <p>{`It may not have been evident to him, but Byleth’s eyes lit up. She felt a wave of awe and respect wash over her. And even guilt for springing the question on him like that.`}</p>
    <p>{`“I-If you would allow me…” Dimitri said standing up from his seat opposite her in the cafeteria and he sat down next to her. He came up so close to her she could faintly smell traces of sunblock on him and she leaned back. He wasn’t..going to kiss her again was he?`}</p>
    <p>{`“I-It’s been on my mind for a while now, I...um..I would like…” He took a deep breath and closed his eyes, “I would like to apologize for my behaviour.”`}</p>
    <p>{`“What?” She said, blinking at him in confusion.`}</p>
    <p>{`“S-sorry,” He stammered, “For..my rude behaviour previously. I...accidentally..stepped on your money,” Dimitri gulped. His eyes were fiercely focused on getting the words out. “I..I...had meant to remove my feet and pick it up..b-but I..kicked it under the machine.”`}</p>
    <p>{`“I...uh…” Byleth was truly speechless. She didn’t know what to say. She felt an immense sense of guilt because she almost hated him during that phase, and she most definitely disliked him, however here he was sincerely apologizing to her.`}</p>
    <Ads mdxType="Ads" />
    <p>{`She nodded, “Thank you for the apology, I’m very...um, thank- Uh no, grateful for it,” Byleth replied, stumbling over her words. Dimitri let out the biggest sigh she’d seen anyone let go. The crease in between his eyes smoothened out. A small fire of pride lit in her chest; it must’ve taken a huge amount of effort to apologize, she was hopeful that maybe her method had worked a little.`}</p>
    <p>{`“Although I have to say, when you glared at me, it was pretty scary,” She chuckled, remembering the moment she looked up to see his piercing blue eyes looking down at her.`}</p>
    <p>{`She turned to see him looking down and his eyebrows were creased again, he was a large person but he looked like a puppy who was being scolded right now, “Oh no, I-I meant that um, y-you know you’re umm..Well..”`}</p>
    <p>{`“S-sorry,” he mumbled, “I was not aware of my facial expressions. I often feel frustrated at my social skills so my face might reflect my e-emotions,” he explained, looking away.`}</p>
    <p>{`She placed a hand on his shoulder, “That’s okay, I understand how you feel. I now know better how to read your emotions, so don’t be afraid to express more.”`}</p>
    <p>{`He looked into her eyes, the knot between his eyes slowly gave way to a rare genuine smile. Byleth could count the number of times she’s seen Dimitri smile, but this one...it was...time-stopping, breath-taking.`}</p>
    <p>{`Her heart started beating wildly in her chest so hard she could hear it in her ears, Byleth felt a little scared - Reus and her had low blood pressure and it was unusual for her heart to be thumping so furiously against her ribcage.`}</p>
    <p>{`She quickly removed her hand from his shoulder since it felt like it was burning where she touched him. She briefly allowed herself a quick glance at him and all her eyes could see was him and his beautiful features, it was like she was looking into his soul. Byleth had to turn away because it was too intense.`}</p>
    <p>{`Her breath felt ragged in her chest, she was feeling as though she couldn’t breathe. Her mind felt like it was spinning and she wasn’t sure what was happening. She even felt slightly faint yet her body was on fire and her eyes couldn’t handle any more eye-contact with him.`}</p>
    <p>{`She turned away and pressed a hand to her forehead, checking to see if she was burning from a fever but she wasn’t. Her stomach was churning uncomfortably but it wasn’t from her lunch; in fact it didn’t feel like any stomach ache she felt before.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“B-Byleth?” Dimitri called out gently. The sound of his deep baritone voice made her heart race even more inside her chest and it made her entire body overheat. Her body wasn’t her own right now and she panicked.`}</p>
    <p>{`“Sorry, I have to go, I’m not feeling well,” She blurted out and grabbed her bag, breaking into a light sprint.`}</p>
    <p>{`She had no idea what had overcome her, one moment she was her usual calm, somber self, the next she was a flustered, blushing mess who couldn’t even think straight..`}</p>
    <p>{`All in just one minute.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      