const keywords = [
  'anime blog',
  'manga blog',
  'games blog',
  'fanfiction blog',
  'anime review',
  'games review',
  'manga review',
];

export default keywords;
