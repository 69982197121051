import PropTypes from 'prop-types';

import Banner from '../components/MDXFanfiction/Collection/Banner';
import Content from '../components/MDXFanfiction/Collection/Content';
import Seo from '../components/Seo';
import keywords from '../utils/keywords';

const FanfictionTemplate = ({ location }) => (
  <>
    <Seo
      title="Fanfiction | Geeknabe - ACG Blog"
      description="mari-chan's own personal corner for her fanfictions! Currently obsessed with writing Fire Emblem Three Houses fanfic, specifically the Dimileth pairing! Or DimitrixByleth, however you would like to call it."
      location={location}
      keywords={[
        'fire emblem three houses fanfiction blog',
        'dimileth pairing fe3h fanfic',
        ...keywords,
      ]}
    />
    <Banner />
    <Content />
  </>
);

FanfictionTemplate.propTypes = {
  location: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
};

export default FanfictionTemplate;
