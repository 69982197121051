import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const RemoteLink = makeShortcode("RemoteLink");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I wrote about Part 1 that I really enjoyed back in December 2019, and it got really, really long. I thought to split it to 2 blog posts to save your thumbs from scrolling your phone too much. Alas, procrastination got the better of me. Finally, here’s Part 2.`}</p>
    <h2>{`Completed WEBTOONS Recommendation #1: Hooky`}</h2>
    <p>{`I have a confession to make. This `}<strong parentName="p">{`webtoon`}</strong>{` wasn’t going to be on the list but the series actually ended while I keep delaying this post! It’s good in a way since I can recommend it here. `}<strong parentName="p">{`Hooky`}</strong>{` is a `}<em parentName="p">{`whimsical`}</em>{` series about magic by Míriam Bonastre Tur. Dani and Dorian are supposed to get on a bus to a magic school but as fate has it, they missed their only chance to school! So instead, they are stuck with having to find a mentor to teach them to be a good witch and wizard.`}</p>
    <p>{`Sounds bubbly? Don’t be `}<strong parentName="p">{`bamboozled`}</strong>{`, this series has some amazing twists and turns which got me really hooked (pun not intended). The colour palette really stood out in the beginning chapters, but it got even more colourful in the later chapters. The lineart gradually smoothen out and the background gets really detailed. Despite the obvious art progression, the art still manages to stay whimsical and lovely right to the end. It’s something I really appreciate because it really brings out the mood of the story. If we were rating webtoons, I’d give this a solid 10 out of 10. `}<RemoteLink to="https://www.webtoons.com/en/fantasy/hooky/list?title_no=425" mdxType="RemoteLink">{`Read Hooky here.`}</RemoteLink></p>
    <p>{`Note: Miriam, the author of Hooky announced that her work is getting published! `}<RemoteLink to="https://twitter.com/miriambonastre/status/1355206710043017218?s=20" mdxType="RemoteLink">{`Here's the tweet!`}</RemoteLink></p>
    <Image alt="Monica, Dorian and Dani from Hooky webtoon." caption="From the left, Monica, Dorian and Dani." name="hooky_webtoon_recommendation.jpg" mdxType="Image" />
    <h2>{`Completed WEBTOONS Recommendation #2: Ecstasy Heart`}</h2>
    <p>{`This series had me scratching my head most of the time. What is the author trying to show the readers here? The dialogue doesn’t really flow well and the scene transitions really vaguely. Regardless of the strange dialogue direction, this story is pretty good in its own way. Shimizu Anna is the daughter of two top tennis players and works as a model. Generally speaking, she’s perfect. However, her heart lies with tennis and she wants to make it big. But first, she has a rival she `}<strong parentName="p">{`must`}</strong>{` defeat… This is a romance story with tennis as the theme. Kudos to the author for putting amazing effort into drawing the backgrounds. `}<RemoteLink to="https://www.webtoons.com/en/romance/ecstasy-hearts/list?title_no=604" mdxType="RemoteLink">{`Read Ecstasy Heart here.`}</RemoteLink></p>
    <Image alt="Shimizu Anna, MC of Ecstasy Heart webtoon" caption="Shimizu Anna, our MC for Ecstasy Heart." name="ecstasy_hearts_webtoon_recommendation.jpg" mdxType="Image" />
    <h2>{`Completed WEBTOONS Recommendation #3: Fantasy Sketch - The Game`}</h2>
    <p>{`This series is an unexpected gem even amongst the list of amazing webtoon comics. It’s short but really well-written. I honestly think it deserves more views. Granted, the art is nothing amazing but it gets the work done. Johan Adam is the `}<strong parentName="p">{`best`}</strong>{` professional gamer out there, but he is only doing this to pay for his mother’s medical bills (Duh). He was working as a cashier when a guy in a suit came to him with the sweetest deal he can’t resist - a high-risk, dangerous game where all the big guns are. There’s some crazy money being bet on this game but this game is not played with a mouse and a keyboard. I’m not going to spoil the mechanics of the game because it ties strongly to the ending, please try this webtoon! `}<RemoteLink to="https://www.webtoons.com/en/fantasy/fantasy-sketch/list?title_no=1020" mdxType="RemoteLink">{`Read Fantasy Sketch - The Game here.`}</RemoteLink></p>
    <Image alt="Fantasy Sketch - The Game banner in Webtoon" caption="Why is Johan Adam wearing an armor? Well, time to find out by reading the webtoon." name="fantasy_sketch_the_game_webtoon_recommendation.jpg" mdxType="Image" />
    <h2>{`Completed WEBTOONS Recommendation #4: Dr. Frost`}</h2>
    <p>{`When it was first serialized, `}<strong parentName="p">{`Dr. Frost`}</strong>{` is everyone’s sweetheart and favourite thanks to its unique story-telling style. Set in Korea, Dr. Frost is a genius psychologist with distinctive white hair even amongst his peers. What sets him apart from the rest however, is how he handles all of his clients in a pragmatic way. And that’s it. That’s the plot.`}</p>
    <p><em parentName="p">{`Note: The last time I checked, it's definitely COMPLETED and stated in most wiki. Apparently I was wrong about this since it's still ongoing. Sorry about that!`}</em></p>
    <p>{`Each client of his gets a few chapters to explain the scenario which then leads to Dr. Frost carefully connecting the dots to deduce the type of mental disorder the client has. At the end of the case, the author will add notes to explain the illness in detail. It’s a really smart way to learn more about these illnesses and it gives the series a grounded feel. If this doesn’t convince you, it got adapted for a `}<RemoteLink to="https://en.wikipedia.org/wiki/Dr._Frost_(TV_series)" mdxType="RemoteLink">{`TV drama`}</RemoteLink>{` too. `}<RemoteLink to="https://www.webtoons.com/en/drama/dr-frost/list?title_no=371" mdxType="RemoteLink">{`Read Dr. Frost here.`}</RemoteLink></p>
    <Image alt="Dr. Frost banner in Webtoon" caption="Dr. Frost banner in Webtoon" name="dr_frost_webtoon_recommendation.jpg" mdxType="Image" />
    <h2>{`Completed WEBTOONS Recommendation #5: unTouchable`}</h2>
    <p><strong parentName="p">{`unTouchable`}</strong>{` is a silly and warm `}<em parentName="p">{`romance`}</em>{` webtoon. If you are looking for a comic to read and feel good seeing the ship sail (as it should) at the end, this is for you. Sia Lee is a modern-day vampire that eats her fill by... touching humans. She’s pretty, confident and works as a model after leaving her family to strike out on her own. One day, she touched hands with Jiho and it felt different to her. Since then, she’s desperate to touch him whenever and however she can. Unfortunately, Jiho is a germaphobe so things aren’t exactly going smoothly. You get the drill. The ship has sailed! We are just in to see how they’ll end up together, honestly. `}<RemoteLink to="https://www.webtoons.com/en/romance/untouchable/list?title_no=79" mdxType="RemoteLink">{`Read unTouchable here.`}</RemoteLink></p>
    <Image alt="Jiho and Sia, the (obvious) couple in unTouchable webtoon" caption="From the left, Jiho and Sia." name="unTouchable_webtoon_recommendation.jpg" mdxType="Image" />
    <h2>{`Completed WEBTOONS Recommendation #6: Sidekicks`}</h2>
    <p>{`This is an unexpected favourite of mine. I repeat! This is amazingly good. Superheroes exist in this world. If you want to climb up the ranks to be a true superhero, you gotta be a `}<strong parentName="p">{`sidekick`}</strong>{` first. Even if it means your master is an abusive boss… But a sidekick gotta do what they gotta do! Our protagonist is Dream Girl right here as she tries her darndest to graduate from being a sidekick to No. 1 superhero Darkslug to a legit superhero.`}</p>
    <p>{`There are 2 parts to this story. First part is decent and sets the mood. Part 2 kicks up the amp and the story gets way better. The art can get a bit corny and the English translation is inconsistent at times, but the plot is a solid 8 out of 10 from me. It’s an enjoyable read if you stay past the starting chapters. `}<RemoteLink to="https://www.webtoons.com/en/super-hero/sidekicks/list?title_no=92" mdxType="RemoteLink">{`Read Sidekicks here.`}</RemoteLink></p>
    <Image alt="Darkslug and Dream Girl, characters from Sidekicks webtoon" caption="From the left, Darkslug and Dream Girl." name="sidekicks_webtoon_recommendation.jpg" mdxType="Image" />
    <h2>{`Completed WEBTOONS Recommendation #7: Girls of the Wild's`}</h2>
    <p>{`These girls of `}<strong parentName="p">{`Wild’s High`}</strong>{` just want to pummel the living daylights out of challengers. This series is all about martial arts and satisfying roundhouse kicks. Our protagonist, Jaegu is dirt poor but hardworking. Due to a family incident, he became fearful of women. Unfortunately, he’ll have to overcome his fears if he wants to accept the full scholarship offered Wild’s High School, an all-girls `}<strong parentName="p">{`martial arts`}</strong>{` school. Will Jaegu, the sole boy in this recently co-ed school be okay?`}</p>
    <p>{`While this series is all about the three girls shown on the cover, Jaegu too gets some limelight. You see him learning boxing himself to rid himself of his fears and gain a backbone against bullies. The girls are really strong with their own specialities and you’ll see some really satisfying fights they’ll get themselves into.`}<RemoteLink to="https://www.webtoons.com/en/action/girls-of-the-wilds/list?title_no=93" mdxType="RemoteLink">{` Read Girls of the Wild's here.`}</RemoteLink></p>
    <Image alt="Moon Young Lee, Dal Dal Choi and Ingui Yoon (Queen) from Girl of the Wild webtoon" caption="From the left, Moon Young Lee, Dal Dal Choi and Ingui Yoon (Muh Queen!)" name="girl_of_the_wilds_webtoon_recommendation.jpg" mdxType="Image" />
    <h2>{`Completed WEBTOONS Recommendation #8: Witch Hunt`}</h2>
    <p>{`I honestly never expected this storyline from such a simple looking webtoon. The chapters are unfortunately short, but the author made it look so easy to tell a story within a few panels. Gamin Shin is a newly transferred student in school. He looks like any other innocent kid except he’s a master at `}<strong parentName="p">{`pickpocketing`}</strong>{`, and he enjoys taking things that he sets his eye on. As he was emptying the content of the wallets he stole, he spotted our protagonist Izzy Zang cleaning the classroom on her own. It was then he witnessed something `}<strong parentName="p">{`magical`}</strong>{`. `}<RemoteLink to="https://www.webtoons.com/en/drama/witch-hunt/list?title_no=363" mdxType="RemoteLink">{`Read Witch Hunt here.`}</RemoteLink></p>
    <Image alt="Izzy Zang from Witch Hunt webtoon" caption="Izzy Zang, everyone's favourite witch." name="witch_hunt_webtoon_recommendation.jpg" mdxType="Image" />
    <h2>{`Completed WEBTOONS Recommendation #9: Bastard`}</h2>
    <p>{`If there’s an adjective for this webtoon, it should be the word `}<strong parentName="p">{`INTENSE.`}</strong>{` Every chapter had me holding my breath, wondering what dreadful thing is going to happen next. You’ll find that Webtoon only added a one-liner as the summary and I agree wholeheartedly with it. I don’t think a full-fledged summary is needed here because that just screams spoiler. It’ll mar your whole experience with this webtoon if I tell you what’s going on, even if it's just a small exposition. I gave `}<strong parentName="p">{`Hooky`}</strong>{` a solid 10 out of 10 for being such a great fantasy story. As for `}<strong parentName="p">{`Bastard`}</strong>{`, I’ll give it 11 out of 10 for being such an amazing thriller story. Please give it a try! `}<RemoteLink to="https://www.webtoons.com/en/thriller/bastard/list?title_no=485" mdxType="RemoteLink">{`Read Bastard here.`}</RemoteLink></p>
    <Image alt="Dongsoo Seon and Jin Seon, characters from Bastard webtoon" caption="From the left, Dongsoo Seon and Jin Seon." name="bastard_webtoon_recommendation.jpg" mdxType="Image" />
    <h2>{`Completed WEBTOONS Recommendation #10: Catharsis`}</h2>
    <p>{`I have always been a sucker for stories that explore the human mind. This one’s no different. Our MC, Leon, has special eyes. He sees colours in every sound he came across. His days are relatively peaceful until one day, his nightmare finally seeps into his reality. He encountered a Timorem, a species that feeds off people’s fear and stress. Things happened, and he decides to join the organization called `}<strong parentName="p">{`Catharsis`}</strong>{` that combats these Timorem(s).`}</p>
    <p>{`This series chose fear as the theme, and you’ll find many terms being thrown around related to it. I really enjoyed the colour choices and presentation. Timorem(s) are portrayed really well as the embodiment of fear. Reading these panels with these creatures always gives me chills. Unfortunately, the chapters are super short. Some webtoons have the same length and it usually doesn’t bother me. However, it bothers me `}<strong parentName="p">{`a lot `}</strong>{`in this series`}<strong parentName="p">{`.`}</strong>{` The cutoff to the next chapter just doesn’t give me any excitement for the next. Instead, I always end up feeling annoyed it ended abruptly without a good close off for the current chapter. `}<RemoteLink to="https://www.webtoons.com/en/fantasy/catharsis/list?title_no=396" mdxType="RemoteLink">{`Read Catharsis here.`}</RemoteLink></p>
    <Image alt="Leon, main character of Catharsis webtoon." caption="Leon looking cool in Catharsis webtoon's banner" name="catharsis_webtoon_recommendation.jpg" mdxType="Image" />
    <h2>{`A bit dated, but still good`}</h2>
    <p>{`These webtoon comics are completed many years ago, but I took care to recommend the really good ones. I hope you enjoyed this list as much as I enjoy putting them up! Enjoy your reading folks.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      