import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Blockquote = makeShortcode("Blockquote");
const RemoteLink = makeShortcode("RemoteLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`It was late at night when I remembered that there’s a Pokemon Direct to be aired later on the very same night. I was outside just hanging out with my friends then, and it’s guaranteed that I’ll miss the live stream as I’ll be commuting back home without a power bank in hand - my phone is barely chugging on with 10% of its battery left. I wasn’t expecting much from the Pokemon Direct but curiosity got me, so I borrowed a friend’s phone to watch the stream.`}</p>
    <p>{`Boy, was it a treat! I was absolutely shaking when I saw the opening. `}<strong parentName="p">{`Pokemon`}</strong>{` `}<strong parentName="p">{`Mystery Dungeon?`}</strong>{` Crazy! And it’s a remaster for the first (and the best) one too! I remember jumping with joy (and yelling pretty loudly) with passersby just looking at me disapprovingly. I didn’t bother me that much because MYSTERY DUNGEON IS BACK! I downloaded the demo pronto and played it immediately. The demo length is unfortunately short, but it gave me a good feeling of what to expect for the game. I wasn’t disappointed with the direction they are taking with this. Graphics is a throwback, and they kept the UI the same, save for some stylistic improvement. However, in all honesty, I expected `}<strong parentName="p">{`more`}</strong>{`.`}</p>
    <Ads mdxType="Ads" /> 
    <Blockquote mdxType="Blockquote">
  I wish it had emulated the gameplay experiences the original game had. 
    </Blockquote>
    <p>{`This is a problem that `}<strong parentName="p">{`Chunsoft`}</strong>{` doesn’t seem to grasp when it comes to this franchise. The appeal of `}<strong parentName="p">{`Pokemon Mystery Dungeon`}</strong>{` isn’t the `}<em parentName="p">{`story`}</em>{` or its `}<em parentName="p">{`graphics`}</em>{`. It’s the `}<em parentName="p">{`precariously balancing on a thin rope`}</em>{`, rogue-like dungeon exploring experience. What made `}<RemoteLink to="https://en.wikipedia.org/wiki/Pok%C3%A9mon_Mystery_Dungeon:_Blue_Rescue_Team_and_Red_Rescue_Team" mdxType="RemoteLink">{`Pokemon Mystery Dungeon: Blue Rescue Team and Red Rescue Team`}</RemoteLink>{` such a great game is that it is a `}<strong parentName="p">{`challenging game.`}</strong>{` In every newer iteration, the game gets progressively easier. Dungeon exploring mechanics get less punishing and the difficulty bar is lowered progressively in every new game. Not to say it is a bad thing, but it `}<strong parentName="p">{`watered`}</strong>{` down the experience for me.`}</p>
    <Blockquote mdxType="Blockquote">
  There’s no kick in exploring a dungeon, the very heart of the Mystery
  Dungeon. 
    </Blockquote>
    <p>{`Enemies don’t hit as hard as they should. There’s almost no challenge in exploring dungeons, and it feels less rewarding to conquer a tough dungeon. Players can easily breeze through the game without the need to grind or knowing the ins and outs of the game. This takes away the fun from the core gameplay. I think this is the main reason players remember this franchise as `}<em parentName="p">{`just okay`}</em>{`. I played most of the games back in the GBA (Only 1 game I believe), DS and 3DS era. It was still okay then, but the first games, Red and Blue are truly something else.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Move carefully or suffer defeat`}</h2>
    <p>{`Red and Blue Rescue Team made this point loud and clear. This is not a game where the player’s mental wellbeing is coddled and cared for. There’s no super skills or moves you can use to get yourself out of a pinch. You only have your moves and items to help explore a dungeon. If you move 1 wrong step, you’re doomed. Every choice you make matters, whether it is to attack, run, reposition your team or use an item. The newer games lower down the bar by a lot so you’ll run into this overpowered problem less which I honestly think is a pity. A dungeon-crawling game governed by turns should make the player think twice before committing to the action. My team always get wiped out thanks to inadequate preparation or making the wrong choice. This forces me to think really carefully before I make a move because every move I make counts.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Items are to be used, not hogged`}</h2>
    <p>{`Mystery Dungeon drops a ton of items, and that is intentional. It’s not for you to hog them for later dungeons, it’s for the players to use `}<strong parentName="p">{`immediately `}</strong>{`to get them out of a pinch. This is something I really enjoyed from Red and Blue. Get caught in a pincer attack? Use items! A monster house? Teleport away! Or you’ll run the risk of facing a wipeout. I need to make sure I brought the correct items when starting a difficult dungeon or I’ll get booted out despite having my team having the same levels as the enemies. Items are imperative if you wish to survive past 6F! Unfortunately, difficulties were lowered in the newer games so I don’t use items much which I honestly think is a pity. Item management is one of the things I really enjoyed in Mystery Dungeon, and I hope I’ll see it more in DX.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`You don’t need to win`}</h2>
    <p>{`There are times when it’s best to run to the next floor, because there’s just not much you can do against a steady stream of enemies. You can win, but at what cost? I remember blazing through the Gates to Infinity and I told myself, this is not fun. I need my kick. I need my `}<em parentName="p">{`CAFFEINE`}</em>{`.`}</p>
    <h2>{`Bring the difficulty back`}</h2>
    <p>{`My memory is a little fuzzy on the details (It was 14 years ago when Red and Blue was first released), but Mystery Dungeon `}<strong parentName="p">{`USED `}</strong>{`to be good. It was challenging, and really tough to beat if you don’t know what you are doing. DX needs to bring back those glorious days! Without the  difficulty built into it, it’s just a mediocre game.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`All in all`}</h2>
    <p>{`Fast forward to October 2020, and I still did not feel compelled to get this game. I really want a Mystery Dungeon game! Perhaps I'll get the incoming Shiren the Wanderer game. If they did not change the game's mechanic at all, then Shiren The Wanderer will be a blast to play!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      