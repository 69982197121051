import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXFanfiction/Chapter/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Divider = makeShortcode("Divider");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`It's a typical weekday night for Dimitri, a typical `}<em parentName="p">{`sleepless`}</em>{` night that is. These days Dimitri didn't bother trying to force himself to sleep, merely sitting on top of the covers reading until he felt somewhat tired. Otherwise he'd be up studying or maintaining his weapons all night.`}</p>
    <p>{`Dedue had already been dismissed long ago and judging by the sounds of Sylvain sneaking back safely into his room, it must've been the small hours of the night.`}</p>
    <p>{`He could feel the onset of a headache and quickly decided to take a walk in the cool mountain air, like he normally did when he wasn't in the library, pouring over books on ways to exorcise the ghosts that haunted him.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Although deep down, tonight, he knew the walk wasn't really to soothe the tension headache. When the Goddess felt like blessing him, he'd catch the Professor somewhere and secretly watch her from afar. Sometimes he'd accidentally break something and alert her of his presence and they'd share a quiet study session together. Or help her sneak into the kitchen for leftovers because she was `}<em parentName="p">{`always`}</em>{` hungry.`}</p>
    <p>{`Almost like she was feeding another being in her.`}</p>
    <p>{`She wasn't in the library tonight, nor was she discussing something in her father's office. Somewhat disappointed, he decided to give himself one last look in their classroom.`}</p>
    <p><em parentName="p">{`Ah`}</em>{`. His heart sang with hope. From afar, he saw the door slightly ajar and a light flickering in the darkness. As he neared his destination, he hoped to the Goddess it wasn't someone else. He wanted to see her one more time before he tried to sleep again.`}</p>
    <p>{`He slowly pushed the door open and there she was, slumped over her desk. At her back, what looked to be various strategies scribbled on the blackboard for tomorrow's excursion.`}</p>
    <p>{`Dimitri tried his best to be as quiet as he could as he walked over, and gently shook her sleeping body while calling out to her.`}</p>
    <p>{`A hand flew out, waving him away along with some incoherent mumbling. He chuckled. Here was the famed Ashen Demon, merciless mercenary; fast asleep and unguarded. Dimitri paused for a bit, unsure of what to do. He didn't want to wake her again, she deserved `}<em parentName="p">{`and`}</em>{` crucially needed the sleep to lead them tomorrow.`}</p>
    <p>{`While he stood by her side admiring her features up close, it suddenly occurred to him he looked absolutely dubious standing over a sleeping woman and it wouldn't look good if anyone caught him in this position.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Dimitri looked around once more before making a decision. He took a deep breath and decided to do something he only ever thought about; he gingerly and effortlessly lifted her into his arms. He blew out the lamp and stuffed the documents she gripped in her small hands into his back pocket, then made his way out the door, closing it as he held her with one arm — it was easy given his strength, she weighed almost nothing.`}</p>
    <p>{`In fact, he could walk to her room carrying her with just one arm, but he didn't. She was a few years older than him, yet she was `}<em parentName="p">{`tiny`}</em>{` in his arms. He cheekily wanted as much contact as he could have with her. To feel whatever he was allowed with both arms. To feel as much of her warmth pressed against him.`}</p>
    <p>{`Tonight, he was extra pleased he was only wearing his plain white shirt instead of his full regalia even though the mountain air was cold. Because this meant he was only one layer away from full skin contact.`}</p>
    <p>{`The Crown Prince of Faerghus was gleefully enjoying a moment with his crush, `}<em parentName="p">{`slowly`}</em>{` walking back to savour this chance he may never have again. He will probably be seen, be teased and even be scolded. It was okay. Just this one time, he wanted to enjoy something without guilt.`}</p>
    <p>{`And as he felt her soft, tickling breaths on the crook of his neck, his ghosts were quiet.`}</p>
    <Divider mdxType="Divider" />
    <p>{`“What's wrong with Dimitri today?” Sylvain whispered loudly behind him. “He's unusually… more mopey than usual.” He paused, “Actually I think he broke his bedroom door this morning, the metal door knob was crumpled like paper. I had to manoeuvre past the big chunks of broken door. It's `}<em parentName="p">{`pretty`}</em>{` bad.”`}</p>
    <p>{`“F-From what I've heard, he met the Professor at the Goddess Tower last night and…it didn't go well,” Ashe whispered. Dimitri was impressed that rumours travelled so fast. But to be fair, rumours surrounding royalty always travelled fast anyway.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Sylvain gasped, “No way— `}<em parentName="p">{`Ouch!`}</em>{` Hey! Who threw that—”`}</p>
    <p>{`“Shut up,” Felix said, “Leave the bugger alone. And hand me back my tome.” Dimitri silently thanked Felix in his heart as Felix and Sylvain got into a small quarrel.`}</p>
    <p>{`But the rumours were true and he was heartbroken. And yes, he also broke his door this morning.`}</p>
    <p>{`Lost in his thoughts and still wallowing in shame about what happened in the Goddess Tower, Dimitri nearly jumped out of his skin when he heard the familiar footsteps walking into class and for a split second he didn't know what to do.`}</p>
    <p>{`He was sitting in his usual seat in the front, Dimitri couldn't help but feel the tension and awkwardness between him and his professor. Honestly, `}<em parentName="p">{`what`}</em>{` was he thinking? Proposing to his teacher like that? `}<em parentName="p">{`His teacher.`}</em>{` Seteth would have them skinned alive and turned into armour, it didn't matter if he was the Crown Prince either.`}</p>
    <p>{`They made eye-contact a couple of times and her countenance was emotionless as usual. But Dimitri knew. He could read and understand the little twitches here and there. And he could tell she was still somewhat hurt.`}</p>
    <p>{`As soon as she walked out when class was dismissed, Dimitri let his head drop and hit the table and a loud crack sounded out. A yelp escaped Annette's lips, and only then did it occur to him he broke the table. He let out a groan.`}</p>
    <p>{`His strength was all over the place today.`}</p>
    <p>{`“That bad huh?” He felt a sympathetic pat on his shoulder and turned his head to see Sylvain hovering over him. “Never thought I'd see the day Your Highness would be suffering from love issues though. Gotta say I am `}<em parentName="p">{`slightly`}</em>{` enjoying this.”`}</p>
    <Ads mdxType="Ads" />
    <p>{`Dimitri groaned again.`}</p>
    <p>{`“Can I ask though, just for good measure,” Sylvain leaned forward and whispered in his ear, “You…didn't give her a dagger right? Not like the last time—”`}</p>
    <p>{`“—No. I didn't,” he replied.`}</p>
    <p>{`The red-head let out a sigh of relief, “Thank the Goddess. Well uh…if you need help. You know where to find me.” Dimitri finally lifted his head, actually thinking of asking Sylvain what he could do to rectify his mistake. But then decided against it. The last time he let Sylvain talk him into asking a girl out, it was a `}<em parentName="p">{`disaster`}</em>{`.`}</p>
    <p>{`Dimitri thought back to the time a few weeks ago when he'd secretly held her in his arms and his heart ached. He wished and wished to be transported back to that time so he could feel her in his embrace again.`}</p>
    <p>{`Last night, he had ruined a special moment and passed it off as a joke.`}</p>
    <p>{`The next few days went by in a painful blur. Dimitri was in so much agony he almost didn't notice the incessant haunting in his mind. Even when they were in battle together, she'd be covered in blood and entrails and he'd still want to pull her into his chest and sniff her hair.`}</p>
    <p>{`Sylvain was right, it `}<em parentName="p">{`was`}</em>{` pretty bad. `}<em parentName="p">{`Very`}</em>{` bad, actually.`}</p>
    <p>{`That night, he was lying face down on his pillow, still dressed in his uniform for what must've been a whole hour, he even missed dinner. His chest ached and ached, she'd gone back to being normal with him far earlier than he'd hoped. Discussing House matters like nothing happened.`}</p>
    <p>{`Dimitri was reluctant to go out on his night walks for fear of bumping into her, yet his room was suffocating him. It wasn't big enough to accommodate him and the wraiths, let alone the invisible but gaping wound in his heart. He didn't let it show, of course he didn't.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Dimitri Alexandre Blaiddyd, the Crown Prince and sole Heir to the throne of Faerghus showing signs of heartbreak?`}</p>
    <p>{`No.`}</p>
    <p>{`Dimitri had an image to uphold; one of immaculateness and regalness. Even if he did break the occasional furniture. On a daily basis.`}</p>
    <p>{`Still, a walk would be better than wallowing on his pillow. He finally heaved himself off his bed and opened the door.`}</p>
    <Divider mdxType="Divider" />
    <p>{`The door opened with a force so great it finally dawned on Byleth how Dimitri broke his door so often.`}</p>
    <p>{`He pretty much walked into her and she let out a yelp as she bumped into his chest.`}</p>
    <p>{`“P-Professor?!” He exclaimed, shock evident on his face. “I am so sorry, a-are you alright? I must confess I was deep in my thoughts that I...didn't see you.”`}</p>
    <p>{`“I'm alright,” she replied, holding up the basket she was holding, “I brought you some dinner…if you'd like some?”`}</p>
    <p>{`Dimitri looked around, “Are you here alone?”`}</p>
    <p>{`Byleth tilted her head, “Well, yes. Is there a problem?”`}</p>
    <p>{`“N-No, not at all.”`}</p>
    <p>{`“Would you prefer Dedue? I can fetch him—”`}</p>
    <Ads mdxType="Ads" />
    <p>{`A strong grip grabbed her arm before she could finish her sentence, “No, I…would very much prefer you instead.”`}</p>
    <p><em parentName="p">{`Ah.`}</em>{` There it was. That little jump in her chest. That squeeze, that tightness, that...addictive feeling. Whenever Dimitri said things like that, or touched her, she'd feel it.`}</p>
    <p>{`She cleared her throat, “I…was worried about you. Dedue mentioned you weren't sleeping much, and you've occasionally skipped meals, like today. It's not much, just some leftovers I've salvaged from the kitchen.” She nodded at the basket she was holding.`}</p>
    <p>{`“Professor…” He whispered. The air was surprisingly tense, and not as awkward as Sothis predicted it to be. Sothis had been annoyed with her so she retreated for a bit, since she was a little down for a couple of days when Dimitri `}<em parentName="p">{`laughed`}</em>{` at his own proposal.`}</p>
    <p>{`She pushed the basket into his hands. “It's…It's not fit for a Prince to eat leftovers, so…feel free to discard it.”`}</p>
    <p>{`A smile grew on his face, the first genuine smile she'd seen him wear in…a long time actually. “Thank you, Professor.”`}</p>
    <p>{`“I'll leave you to it,” she said and turned on her heel.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Ah wait!” He called out to her, “Would…Would it be impertinent of me to share this with you?”`}</p>
    <p>{`Byleth paused for a bit, she was peckish, as she always was at this hour. “It would be a pleasure.”`}</p>
    <p>{`Dimitri gestured for her to enter his room and she did so without hesitation. It wouldn't be the first time she'd done so anyway.`}</p>
    <p>{`He quickly cleared his desk and placed the basket on it, laying out its contents with a small smile on his face. It looked as though he was trying to suppress it though.`}</p>
    <p>{`Byleth sat herself by his window seat. The view from his window was much nicer than her room on the ground floor.`}</p>
    <p>{`“Oh um, I remembered you liked cheese so I picked a few varieties for you,” she explained. “And a flask with some chamomile tea.”`}</p>
    <p>{`“You…did?” For a brief moment, Dimitri's eyes were wide with surprise, “I-I…thank you.”`}</p>
    <p>{`Byleth merely observed him from where she sat, taking in the scent of his room. It smelled…faintly of freshly washed laundry, weapon polish and...a hint of sweat and old books.`}</p>
    <p>{`It was a very Dimitri like smell and it made her…dizzy to be surrounded by it. As she stared shamelessly at him, his words or rather, proposal that night at the Goddess Tower repeated in her mind.`}</p>
    <Ads mdxType="Ads" />
    <p>{`She felt for the first time in a long time, incredibly disappointed and hurt. Sure she was fond of Dimitri, until that night when he brushed it off as a joke, and was even proud of it, did she realise she might've harboured more than just 'fondness' for him.`}</p>
    <p>{`Evident by her feeling what might've felt like a heartbeat just now, outside his door. When he said he'd prefer her. Oh how her chest burned with some strange passion that spread to her loins.`}</p>
    <p>{`“The calming scent of chamomile never fails to make me feel at ease,” Dimitri said when he uncapped the canteen, breaking her out of her reverie.`}</p>
    <p>{`“I know,” she said. “You mentioned it before during one of our tea times.”`}</p>
    <p>{`“I'm sure it tastes just how I remember it.” A strange comment, she thought.`}</p>
    <p>{`“I didn't bring a cup did I?” She hopped off her seat and peered into the empty basket. “Ah, I didn't. Sorry. I…sometimes forget we're not camping in a forest.”`}</p>
    <p>{`He let out a laugh and it sounded so good in her ears. “We'll share. Here, help yourself to some—”`}</p>
    <p>{`He suddenly whipped his head round towards the door, his body alert at the incoming footsteps. “It's Sylvain,” he whispered and quickly shut the door. He froze for a second and then checked the door, letting out a sigh when it didn't break.`}</p>
    <p>{`Dimitri turned around and made direct eye contact with her before scrunching up his nose, “I'm sorry, Professor. I…don't know why I closed the door. Um…”`}</p>
    <p>{`“I don't mind,” she replied, walking towards the door. “I'll be leaving soon anyway—”`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Your Highness?” A knock rang out, it was Sylvain's voice. “Dimitri? You alright?”`}</p>
    <p>{`Byleth had her lips shut when Dimitri clapped a hand over her mouth, “Sylvain! Uh…you're back early tonight.”`}</p>
    <p>{`“Yeah, your door isn't broken again is it? You slammed it pretty hard just now. Are you sure you're okay? Need me to come and check for monsters under your bed again like when we were kids?”`}</p>
    <p>{`Byleth managed to let out a snort and Dimitiri looked at her in panic and at the door again. “Shhh,” he pressed a finger to his lips. She nodded, his hand still on her mouth. It was…strangely…turning her on.`}</p>
    <p>{`“No, I'm fine. The door is fine,” Dimitri replied. “Good night.”`}</p>
    <p>{`“Okaaay…If you say so, Your Highness,” Sylvain said and eventually left.`}</p>
    <p>{`Dimitri let out the breath he was holding and nearly jumped out of his skin when he saw how close their bodies were. He'd not only had his hand on her mouth, but had backed her against the wall and pressed his firm, muscled chest against her breasts.`}</p>
    <p>{`“Professor! I-I'm terribly sorry, I..I really don't know what's come over me—”`}</p>
    <p>{`“I…don't mind,” she blurted out.`}</p>
    <p>{`“...What?”`}</p>
    <p>{`“I...don't mind…being like this…with you.”`}</p>
    <Ads mdxType="Ads" />
    <p>{`He removed his hand but didn't move his frame. She was still trapped between him and the wall.`}</p>
    <p>{`Instinctively, she knew that if she struggled even the smallest amount, he would let her go. But she froze, enjoying the feeling of her large, very male student flush against her chest, his arms on either side of her.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      