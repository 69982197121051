import PropTypes from 'prop-types';
import styled from 'styled-components';

import $ from '../../../styles/globals';
import SocialTool from '../SocialTool';
import FurtherAction from './FurtherAction';
import Profile from './Profile';

const Section = styled.section``;

const Container = styled.div`
  max-width: 700px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`;

const BorderContainer = styled.div`
  border-radius: ${$.borderRadius.radius2}px;
  box-shadow: ${$.shadow.repressed};
  margin-bottom: ${$.layout.margin6}px;
  overflow: hidden;
`;

const BottomToolContainer = styled.div`
  width: 100%;
  margin-bottom: ${$.layout.margin6}px;

  > * {
    margin-top: 0 !important;
  }
`;

const Author = ({ name, summary, pic, tag }) => (
  <Section>
    <Container>
      <BorderContainer>
        <Profile pic={pic} name={name} summary={summary} />
        <FurtherAction tag={tag} />
      </BorderContainer>
      <BottomToolContainer>
        <SocialTool />
      </BottomToolContainer>
    </Container>
  </Section>
);

Author.propTypes = {
  name: PropTypes.string,
  summary: PropTypes.string,
  pic: PropTypes.string,
  tag: PropTypes.string,
};

Author.defaultProps = {
  name: '',
  summary: '',
  tag: '',
  pic: '',
};

export default Author;
