import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const LocalLink = makeShortcode("LocalLink");
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const RemoteLink = makeShortcode("RemoteLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Phew this otome title is a mouthful. `}<strong parentName="p">{`May I please ask for one last thing? (最後にひとつだけお願いしてもよろしいでしょうか)`}</strong>{` is such a unique `}<strong parentName="p">{`villainess otome isekai manga`}</strong>{` amongst the many I recommended in my `}<LocalLink to="/blog/im-recommending-more-otome-isekai-manga-and-webtoons-part-1/" mdxType="LocalLink">{`post here`}</LocalLink>{`. Regardless, I’m having too much fun with this title. I mean, our MC punches so good!!`}</p>
    <p>{`I didn’t expect it could get this fun just to watch Scarlet send folks flying! So for starters, here’s a refresher about what this manga is all about for the ones who aren't familiar with this series.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Scarlet El Vandimion is our `}<strong parentName="p">{`otome isekai heroine`}</strong>{` in this series `}<em parentName="p">{`(Her antagonist is the one who got isekai’ed, not her)`}</em>{`. She’s betrothed to the second prince (whose name is `}<strong parentName="p">{`NOT`}</strong>{` worth remembering) and has played her part as a perfect fiancee. Her fiance is an as`}{`*`}{`hat who relishes in bullying her whenever he could. His lackeys would pick this up as an okay sign to do the same, and would look out for opportunities to do the same. But Scarlet is a responsible lady of a ducal family! She was trained to rein in her anger because it is unbecoming to punch folks just because she dislikes them. Then came the villainess denouncement scene in front of everyone in a ball! The second prince declared that the engagement is annulled because he found a better woman than Scarlet. Silly prince, now you’re `}<em parentName="p">{`dead`}</em>{`.`}</p>
    <p>{`With no strings attached, Scarlet can finally `}<strong parentName="p">{`punch everyone`}</strong>{` in the ball. The first to taste her fist is the prince’s new fiancee. Then some unimportant nobles and lastly, her ex-fiance. The whole scene is lovely to read! If you think this couldn’t get better, wait till you see how her background gets explained! Apparently she loves hitting people when she’s a child.`}</p>
    <Image alt="Scarlet sho cute" caption="'I bath only in the blood of my enemies.' - Scarlet, maybe." name="otome-isekai-may-i-please-ask-for-one-last-thing-panel-1.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <p><strong parentName="p">{`Is that normal?`}</strong>{` I certainly think so because I personally do `}<em parentName="p">{`love`}</em>{` hitting people if they irk me. Obviously, not to the point where they keel over unconscious… Okay, I went off-track. After Scarlet cut off her ties with her ex-fiance, she made a new one with the first prince, Julius. Now `}<em parentName="p">{`this name`}</em>{` is worth remembering! Julius is a much, much better prince. He’s respectful, works hard to solve problems, really smart and is bloody charming. He's basically 200% more effective than his younger brother. He’s enamored by Scarlet because he find her interesting (standard otome plot here, you know the drill) but she couldn’t give `}<em parentName="p">{`two cents`}</em>{` about it. Scarlet just wants to `}<strong parentName="p">{`beat`}</strong>{` folks up. The fatter, the better. All her victims need to do is give her a reason to!`}</p>
    <p>{`But that didn’t exactly `}<strong parentName="p">{`explain`}</strong>{` Scarlet’s mighty punches. I'm fine with the idea that she's strong because the men are all weak-willed but apparently there's magic in this world. The story goes further by giving Scarlet an OP skill - the power to reverse and accelerate time. So in all honesty, the reason why these men couldn’t dodge or handle her punches and kicks is because she’s accelerating her hits. Lots of them too. I hope this series gets animated because I can’t wait to see her in action!`}</p>
    <Image alt="We don't deserve Scarlet" caption="And I'm glad that you exist :)" name="otome-isekai-may-i-please-ask-for-one-last-thing-panel-2.jpg" mdxType="Image" />
    <p>{`Okay, spoilers over! I’m not here to tell you how the story goes. I wrote this post so I could gush about this woman right here. While I admit, I’m drawn to her because she takes absolutely zero bullshit and hits people really good, Scarlet is a stern woman who honestly couldn’t care less about the public nor her social circle’s perception of her. After breaking free from her engagement, you see her act with more freedom. She was once tied down and was told to refrain from lashing out even if the other party’s a jerk. She’s always super mad at the second prince so she adopted her passive face to prevent emotions from leaking out. Now that she doesn’t need to, she smiles more often and follows her heart more. She’s more confident and honestly, I think she behaves more like a shounen protagonist than a shoujo one. She’s certainly not meek just because she has the strength to back it up, she just doesn't tolerate not following what her heart tells her to. Scarlet’s personality just screams `}<RemoteLink to="https://hinative.com/en-US/questions/15058620" mdxType="RemoteLink">{`anesan`}</RemoteLink>{` to me! (And I love it)`}</p>
    <Ads mdxType="Ads" />
    <Image alt="<3 Scarlet <3" caption="Hell yea! She is out for blood! Watch out, sinners!" name="otome-isekai-may-i-please-ask-for-one-last-thing-panel-3.jpg" mdxType="Image" />
    <p>{`She doesn’t hit people nilly-willy which everyone in the story is grateful for. She only does it to folks who really `}<strong parentName="p">{`deserve`}</strong>{` it. It turns out that the second prince’s faction which has tasted Scarlet's fury from Chapter 1 are all into human trafficking. This gives her a reason to oust the ringleader of said faction.. Okay, maybe she just wants to punch the poor man since he kinda sic an assassin on her.Whenever she gets punching, I’m just cheering for her so hard. Usually it’s the men who do the protecting but instead, it's Scarlet who rushes to the front! You thought she was being brave and protective but no, she found an excuse to beat folks up and she took it like a champ. I’m literally swooning at this point in the story <3`}</p>
    <p>{`Chapter 1 opens up with some nice punching scenes, Chapter 6 has her punching some ruffian which is oddly not satisfying for me because they went down way too easily. There’s a scene where she makes a beastmen so much bigger than her size flying with a punch! Now that’s pretty badass. The best punching scene (so far) is most definitely in Chapter 11, followed by Chapter 12. Scarlet disguised herself as a buyer in a slave auction to get close to the slave trafficking ringleader. Of course, her sole motive in doing so is to punch the man that started it all… But the scenes leading to the villain’s capture are just so much fun.`}</p>
    <p>{`She first punches all of his lackeys while the band is playing a symphony so no one really noticed her. She then wrecked the whole place up with a smile on her face :D I can almost image the whole medley if this gets animated! Her punching and the band is just playing a gorgeous tune! Oh, and she looks gorgeous even when she’s throwing a man down a floor! I can’t help adding the panels in here because the art is simply amazing. I mean, look at our girl! She’s the eye of a storm (LOL)!`}</p>
    <Ads mdxType="Ads" />
    <Image alt="I don't know what to put here but bless be Scarlet" caption="The band is playing some classical music, idk. But what's important her is that she kicked a man in the face with her high heels. Oof, that must hurt!" name="otome-isekai-may-i-please-ask-for-one-last-thing-panel-4.jpg" mdxType="Image" />
    <Image alt="scarlet <3 powerful" caption="This is the smile I was talking about! She grabbed a poor man and spin him around!" name="otome-isekai-may-i-please-ask-for-one-last-thing-panel-5.jpg" mdxType="Image" />
    <Image alt="hurricane scarlet" caption="SHE CAME IN LIKE A HURRICANE! Everyone: AAAA, Scarlet: HAHAHAHA" name="otome-isekai-may-i-please-ask-for-one-last-thing-panel-6.jpg" mdxType="Image" />
    <p>{`The follow up to this scene is also pretty fun. A silly interloper interfered so Scarlet punched him as well. She also knocked down a dragon in the meantime! What a champion, I’m in love <3 While I’m here just to see her kick ass, the author is pretty good at lining up the next plot thread. I’m sure the whole trafficking incident will get wrapped up pretty quickly and the next plot will come in real fast. The art did grow on me after Chapter 10! At first I thought the eyes on these characters were a tad too sharp, but as I keep reading I thought how the mangaka drew the eyes are pretty good. With so many kicks and punches, I have a few favourite scenes but the panels below are the best. The guy was just standing there taking it all, so Scarlet threw in even more punches and kicks.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="May I please ask for one last thing? panel 1" caption="A smack to the head followed by..." name="otome-isekai-may-i-please-ask-for-one-last-thing-panel-7.jpg" mdxType="Image" />
    <Image alt="May I please ask for one last thing? panel 2" caption="... a kick to the face. Oof, perfect form! Her leg looks amazing <3" name="otome-isekai-may-i-please-ask-for-one-last-thing-panel-8.jpg" mdxType="Image" />
    <h2>{`All in all`}</h2>
    <p>{`Scarlet is amazing, please kick me and send me flying! If you haven’t read this otome isekai title, please do so `}<strong parentName="p">{`immediately! `}</strong>{`I’m sure a ton of folks would scramble to scanlate this series because it’s just `}<em parentName="p">{`that good`}</em>{`, so it’s unlikely to get dropped. Read for Scarlet, stay for Scarlet.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      