import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Blockquote = makeShortcode("Blockquote");
const RemoteLink = makeShortcode("RemoteLink");
const Video = makeShortcode("Video");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I’m sure everyone who played Animal Crossing from ye olde days know of `}<strong parentName="p">{`K.K. Slider`}</strong>{`, the artist that can play and sing all sorts of music genre in his delectable... sexy... voice. When you first reached the island, you are given a radio that plays his songs 24/7. (Oof, perfection...) If you access the Nook Shopping option through the Nook Stop, you’ll see a new song by K.K. Slider up for purchase every day. If that’s not enough to remind you of K.K. Slider’s prowess, getting him over to your island for a concert is the last hurdle you need to overcome to terraform your island.`}</p>
    <Ads mdxType="Ads" />
    <p>{`The villagers in Animal Crossing `}<strong parentName="p">{`love`}</strong>{` him. That’s to be expected, but I don’t speak gibberish as my mother tongue. My mother tongue is `}<em parentName="p">{`Engrish!`}</em>{` I simply can’t relate at all to his songs. They have nice tunes and all, but the `}<em parentName="p">{`wee wo wee lyrics`}</em>{` is simply too distracting. I was sure I could never enjoy his songs outside of Animal Crossing: New Horizons until...`}</p>
    <Blockquote mdxType="Blockquote">I found K.K. Slider remixes on YouTube. </Blockquote>
    <p>{`Someone actually remixed popular songs and made `}<strong parentName="p">{`K.K. Slider sing them`}</strong>{`! I was super amused until I hit the play button. Hot damn, it’s actually really `}<strong parentName="p">{`good`}</strong>{`! There’s a lot of remixes out there, but my favourite remixes are definitely by `}<strong parentName="p">{`Clay Kramer`}</strong>{`. They are even up on streaming services! Thank you for putting it up on Spotify. Hitting the repeat button has never felt this satisfying.Here are some of my favourites from Clay Kramer’s YouTube channel.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Buttercup by Jack Stauber`}</h2>
    <p>{`Bob is just `}<RemoteLink to="https://animalcrossing.fandom.com/wiki/Bob" mdxType="RemoteLink">{`vibing`}</RemoteLink>{` in the video! I find this video super perfect. Spotify’s inability to show Bob on my phone screen is a minus in my book. I gave Spotify 1 star on the app store because of this. The `}<em parentName="p">{`woh woh woh`}</em>{` is just amazing. Coupled with the whistling as the base, it’s `}<em parentName="p">{`perfect.`}</em></p>
    <Video title="KK Slider song" src="https://www.youtube.com/embed/PHqqDu7fcVI" mdxType="Video" />
    <h2>{`Bad Guy by Billie Eilish`}</h2>
    <p>{`Seeing Isabelle like that is a nightmare, so I usually scroll down to hide the video. But damn, `}<em parentName="p">{`na oh na wei`}</em>{` lyrics just hits so good and super satisfying from the original. The beats are super well-replicated! The part at 1:41 is sooo good, followed by the derisive `}<em parentName="p">{`hmph `}</em>{`at 1:59… God, please inject every K.K. Sliders `}<em parentName="p">{`hmph`}</em>{` into my veins. There’s another `}<em parentName="p">{`hmph `}</em>{`at 0:58 if that’s your type of drug.`}</p>
    <Video title="KK Slider song" src="https://www.youtube.com/embed/YiB5AkuARQ0" mdxType="Video" />
    <Ads mdxType="Ads" />
    <h2>{`Bohemian Rhapsody by Queen`}</h2>
    <p>{`If you salivate at a lone K. K. Slider `}<em parentName="p">{`wo wee wo`}</em>{`, then have `}<strong parentName="p">{`MORE`}</strong>{`! This could be bias speaking since I really like the original one too, but this song is really remixed to perfection. It replicates every note really well, and I could see K.K. Slider jamming to this song by the campfire. The great part of this song is definitely from 0:00 to 6:06, but my favourite part starts from 3:00.`}</p>
    <Video title="KK Slider song" src="https://www.youtube.com/embed/abzFk2SlFD8" mdxType="Video" />
    <h2>{`I just can’t wait to be king from Lion King`}</h2>
    <p>{`I’m linking this song here because K. K. Slider goes `}<em parentName="p">{`awooo`}</em>{` `}<strong parentName="p">{`multiple times`}</strong>{` here, does `}<em parentName="p">{`hmph`}</em>{` and his backup singing is so adorable… The backup part is just him literally doing `}<em parentName="p">{`wo wee na oh`}</em>{` in quick successions, it’s so cute I’m so dead.  I need this song in Animal Crossing: New Horizons.`}</p>
    <Video title="KK Slider song" src="https://www.youtube.com/embed/UAlN7qI81rM" mdxType="Video" />
    <Ads mdxType="Ads" />
    <p>{`Clay Kramer did a ton of other songs too in his `}<RemoteLink to="https://www.youtube.com/channel/UC1JB0XkovQwI4yeZFdJJ1bg" mdxType="RemoteLink">{`YouTube channel`}</RemoteLink>{`. You can check them out there, or on `}<RemoteLink to="https://open.spotify.com/album/0UBK7oymoowTKxYBz8VGeU?si=9zL5d31HRee41axoQwjB8A" mdxType="RemoteLink">{`Spotify`}</RemoteLink>{` and `}<RemoteLink to="https://music.apple.com/us/album/kk-slider-covers/1485309913" mdxType="RemoteLink">{`Apple Music`}</RemoteLink>{`. The Animal Crossing fanbase is amazing. Thank you for blessing us with these remixes. To end the post, I would like to share a meme about K.K. Slider that I find super hilarious.`}</p>
    <Image alt="Animal Crossing KK Slider meme" caption="I stole this from a Facebook group, Animal Crossing Memes. His seat placement on the plaza is such a terrible choice..." name="animal-crossing-kk-slider-meme.jpg" mdxType="Image" />
    <p>{`Please check out the `}<RemoteLink to="https://www.facebook.com/groups/animalcrossingmemes" mdxType="RemoteLink">{`Facebook group here`}</RemoteLink>{`. The memes are amazing.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      