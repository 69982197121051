import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const LocalLink = makeShortcode("LocalLink");
const Ads = makeShortcode("Ads");
const Blockquote = makeShortcode("Blockquote");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This blog’s domain was first registered in May 2019 (You can take a look at this blog’s progress in my `}<LocalLink to="/releases/" mdxType="LocalLink">{`Release Notes page`}</LocalLink>{`) but I’ve only `}<em parentName="p">{`officially`}</em>{` started this blog in October 2019. In a manner of speaking, I have already shot past this blog’s `}<strong parentName="p">{`1st year anniversary`}</strong>{`. One of my favourite things to do whenever I whipped out my phone… is to take a look at my own blog. I’d click around and read my own posts (Cringey, I know). I try not to write blog posts that I didn’t want to read myself so that serves as my own quality check.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Revisiting what I wrote is a `}<strong parentName="p">{`wonderful`}</strong>{` time travel. It’s like this… really `}<strong parentName="p">{`personal`}</strong>{` `}<em parentName="p">{`diary`}</em>{` you share with everyone. Your thoughts, your own personality oozes out through these sentences. My writing is nothing special but it’s my own `}<strong parentName="p">{`writing`}</strong>{`. It’s embarrassing but I’d smile whenever I saw the view counter rising on each of my blog posts. I feel `}<strong parentName="p">{`appreciated`}</strong>{` knowing that there are people out there reading my posts! I remember how much I `}<em parentName="p">{`struggled`}</em>{` to piece my opinions into a proper post. I know what I want to say, but I don't know `}<strong parentName="p">{`how`}</strong>{` to say it. Folks often told me I suck at getting my ideas across so I worked `}<em parentName="p">{`extra hard`}</em>{` on my `}<em parentName="p">{`word choices`}</em>{`. I started reading everyone’s writing so I get a good idea on how to do aniblogging more. I’m envious at how everyone seems to get it `}<strong parentName="p">{`right`}</strong>{`, or have found their own style. Seriously, y’all make it look so `}<em parentName="p">{`effortlessly`}</em>{`.`}</p>
    <Blockquote mdxType="Blockquote">
  What’s your secret? I’ve seen folks who blog everyday (Whoa, how tho)... 
    </Blockquote>
    <p>{`Anyway, back to the topic… Blogging takes up so much of my time but I’m loving `}<strong parentName="p">{`every`}</strong>{` `}<strong parentName="p">{`minute`}</strong>{` of it. My writing process starts in Google Docs first, followed by moving the content into my blogging platform and finally, making sure it shows up nicely on my blog. I personally find it a `}<em parentName="p">{`lot of work`}</em>{` since my workflow is not a simple `}<strong parentName="p">{`write`}</strong>{` and `}<strong parentName="p">{`publish`}</strong>{`, but seeing the end results is `}<em parentName="p">{`more than enough`}</em>{` for me. But then again, this feels like I like creating more work so I could enjoy the process instead (Haha)! My friends who know me personally in my social circle would ask me many things about my blog, but the one question that stood out to me is this:`}</p>
    <Blockquote mdxType="Blockquote">
  Blogging takes up so much effort. I’d stop after messing around. How are you
  doing it?
    </Blockquote>
    <Ads mdxType="Ads" />
    <p>{`Yes indeed, seriously why am I doing `}<strong parentName="p">{`this`}</strong>{`? I often tell myself I should stop blogging and divert the time to other beneficial hobbies. Like, coding a personal project or reading more non-fiction books. There’s honestly nothing in it for me in this blog, except maybe… `}<strong parentName="p">{`self-contentment.`}</strong></p>
    <p>{`My `}<em parentName="p">{`number one priority`}</em>{` for geeknabe.com is definitely getting `}<strong parentName="p">{`more readers`}</strong>{`. After all, nothing makes me happier than knowing someone out there `}<em parentName="p">{`nodded along`}</em>{` while reading my write-ups! But first and foremost, this blog is honestly just a platform for me to `}{`*`}{`write for my own `}<strong parentName="p">{`*`}</strong>{`joy`}<strong parentName="p">{`. The hotpot mascot `}</strong>{`nabe-chan`}<strong parentName="p">{`, the welcoming aesthetics I chose for my blog… All of them are there because `}</strong>{`I like them`}{`*`}{`*`}{`.`}</p>
    <Blockquote mdxType="Blockquote">I like cute things. I like them fluffy and round!</Blockquote>
    <p>{`Those blog posts that list out my favourite manga titles? I `}<strong parentName="p">{`like`}</strong>{` writing those, it’s fun to get word out on `}<em parentName="p">{`obscure titles`}</em>{`! It’s the same thought that led to mari-chan’s `}<em parentName="p">{`personal`}</em>{` `}<em parentName="p">{`fanfiction corner`}</em>{`. She loves writing fanfics with her friend, Judith but I told her she should put them up online because her fanfics are `}<em parentName="p">{`that good`}</em>{`. Writing fanfics make her happy so I say go for it!`}</p>
    <p>{`I must say though, enjoying the blogging process is `}<em parentName="p">{`one thing`}</em>{`. If you were to look past all the fun, I’m doing this because I don’t want to `}<strong parentName="p">{`regret`}</strong>{` not doing it if I look back one day. You see… I spent most of my days in school studying. Back then, watching anime or playing games is something I do because `}<em parentName="p">{`I don’t want to study. `}</em>{`It’s a small act of rebellion towards my parents who kept forcing school subjects down my throat. I know they want the best for me but what is a young teenager to do with all the `}<em parentName="p">{`frustration?`}</em>{` So I look to anime, manga and games as a form of `}<strong parentName="p">{`escapism`}</strong>{`. I wouldn’t say it’s a hobby because there really is nothing else I could do (that I’m aware of) to have fun back then.`}</p>
    <Ads mdxType="Ads" />
    <p>{`You `}<em parentName="p">{`don’t know`}</em>{` what you `}<strong parentName="p">{`don’t know`}</strong>{`, so with no Google (I’m talking about ~20 years ago) to tell me what interesting things that I `}<em parentName="p">{`don’t know,`}</em>{` I’d emulate what my friends were playing and call it a hobby. Back then, I don’t really have a strong opinion on my favourites so I usually just go along with the trendsetters.`}</p>
    <p>{`So when I first started work, I felt like an `}<em parentName="p">{`alien`}</em>{`. I have… free time that I don’t need to use for `}<strong parentName="p">{`studying?`}</strong>{` I can actually do the `}<strong parentName="p">{`choosing`}</strong>{` this time?! What do I do with it?? I’m telling ya, it boggles my mind at first when I realized I could choose for myself and there’s no one to tell me what to do. The free time spent for anime and so forth is fun for a brief period of time but past the superficiality, I wanted to do `}<strong parentName="p">{`more`}</strong>{`. I wanted to pick a hobby that I really want to do, so `}<strong parentName="p">{`I chose to blog`}</strong>{`.`}</p>
    <p>{`Blogging is a choice at first but now, it’s my interest. I like how I did the blog’s aesthetics (Haha this is not intended self-praise but I am very pleased with how it turned out), and the theme’s colour choices. I enjoyed researching a topic for a heavy blog post! It’s super satisfying when my writing got the message across. This blog means a lot to me now. Like, a lot. Thank you so much for reading.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      