import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const Blockquote = makeShortcode("Blockquote");
const LocalLink = makeShortcode("LocalLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I’m writing this while I’m stuck at home thanks to the quarantine. I’m currently working from home so that means I’m still getting paid. I’m really thankful for my situation but there are folks that are probably not that lucky. For those of you whose funds are low or you have to be wary of what to spend (Like me) since the economy is likely going down the drain... Try the Jumbo Demo for Dragon Quest Builders 2. This game will keep you glued to your Nintendo Switch for at least 5 to 10 hours! `}<strong parentName="p">{`FOR FREE! `}</strong>{`(It’s only available until Dec. 2029 according to the Steam page. Hopefully, you’ll download the demo by then! :D)`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Dragon Quest Builders 2 lets you farm to your heart's content" caption="My very humble but messy farm <3" name="dragon-quest-builders-2-jumbo-demo-farm-geeknabe-blog.jpg" mdxType="Image" />
    <Blockquote mdxType="Blockquote">
  It’s like Minecraft with some Harvest Moon and JRPG elements, but with Dragon
  Ball characters
    </Blockquote>
    <p>{`Okay, so that is probably how I would describe this game in general. Here are some things I love about this stupidly cute game!`}</p>
    <ul>
      <li parentName="ul">{`The villagers do almost everything for you! If there’s one thing I find really tedious and monotonous in this game, it’s watering your crops. But these cute little people do it for you! <3 From hoe-ing the ground, watering, replanting your seeds for you, they do it all for you. You only have to do the work once, and your job after that is pretty much just harvesting.`}</li>
      <li parentName="ul">{`Another great thing once you unlock the `}<em parentName="li">{`“do-it-for-you”`}</em>{` feature, they’ll cook for you and the town too! Bless these cute little people.`}</li>
    </ul>
    <Image alt="My grandmother dresses okay, thank you very much!" caption="The dialogue is hilarious in this game, lmao I love it" name="dragon-quest-builders-2-jumbo-demo-npc-dialogue-geeknabe-blog.jpg" mdxType="Image" />
    <ul>
      <li parentName="ul">{`They poop out hearts when they’re happy. Ok, so they don’t actually poop it out but it just… pops out and litters the farm with little hearts. I can’t it’s so adorable-`}</li>
      <li parentName="ul">{`THIS GAME HAS TOILETS AND SHOWERS, I REPEAT THIS GAME HAS TOILETS AND SHOWERS`}</li>
    </ul>
    <Ads mdxType="Ads" />
    <ul>
      <li parentName="ul">
        <p parentName="li">{`NPCs can and will use the toilets to POOP which you then have to empty and use their crap to make stuff... Ahem.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`THEY ALSO LINEUP TO USE THE TOILET AND SHOWER ROOMS IT’S SO CUTE TO SEE THEM COMPLAIN ABOUT THE QUEUE`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The villagers are also quite independent; they run the town when you’re gone so you don’t have to worry about anything dying. From what I’ve seen, they also defend your little civilization from monsters that attack daily to eat your crops. So don’t forget to equip your citizens with weapons!`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`I never knew building things can be so addictive? There’s always something to do and improve and oh look a new recipe-`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can technically `}<em parentName="p">{`“swim”`}</em>{`, I love it. The view from underwater is so fun I have no idea why.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`YOU CAN PET THE DOG`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`And lastly, you have a `}<em parentName="p">{`sidekick`}</em>{` that isn’t just decoration. He actually does stuff! You’re cutting trees? He’ll go to the nearest one to do it. Oh, harvesting kelp? He’s on it! And whatever he collects automatically goes into your bag. He’s does bodyguard duty too, so any monsters that dare breathe in your direction are dead. Yup, I love him so much. I ship him with the builder so, so much, in fact, I want to write a fanfic about it when I’m done with the game ^`}{`_`}{`^ (check out my Fire Emblem Three Houses fanfiction `}<LocalLink to="/fanfiction/everything-changes-but-you/" mdxType="LocalLink">{`here!`}</LocalLink>{`)`}</p>
      </li>
    </ul>
    <Image alt="Wonderful! Wonderful! Wonderful! I'm so happy!" caption="Your current missions are displayed on the right and/or either over someone’s head as an exclamation mark! Look at them being all encouraging and chirpy!" name="dragon-quest-builders-2-jumbo-demo-quest-geeknabe-blog.jpg" mdxType="Image" />
    <p>{`The real part of the game starts when you arrive on this island called Furrowfield. This is where a large chunk of the demo takes place. I read that this demo lasts for 5 hours. It ends when you’re about the face-off the Badboon that’s wreaking havoc. It’ll then thank you and ask you to purchase the full game to continue. Don’t worry, your save file will be carried over when you get the full game!Fortunately, you can still continue playing from there! There’s honestly SO much to do, or just replay the demo!`}</p>
    <Ads mdxType="Ads" />
    <p>{`Here’s what I’d recommend to double your gameplay hours for this game. It’s really simple!`}</p>
    <ol>
      <li parentName="ol">{`Re-organize your farm! This is much more fun than it sounds! I have the creativity of a garden rake so I’m rubbish at building things, but I was extremely engrossed in just cleaning up my farm and buildings.`}</li>
      <li parentName="ol">{`You can do a lot already at this point in terms of building so build a multistory apartment if you want.`}</li>
      <li parentName="ol">{`Finish all the mini-medal puzzles around the island!`}</li>
      <li parentName="ol">{`Build private rooms for all your cute villagers! They’ll drop more hearts every morning when they wake up.`}</li>
      <li parentName="ol">{`Collect resources, seeds, etc.`}</li>
      <li parentName="ol">{`Organize the content of your storage boxes!`}</li>
    </ol>
    <p>{`That’s pretty much it. Don’t underestimate this demo though, seriously. It’s unlike certain demos where they give you a taste and thank you for playing please purchase- No, it’s very fleshed out! Thank god I’m working from home now because when I looked up at the time it was 2.35 am.`}</p>
    <Ads mdxType="Ads" />
    <p>{`The Jumbo Demo convinced me to get the full game (so much for saving money). There’s apparently SO much more to play based on the trailer and characters I’ve yet to meet! I mean, I don’t even feel like playing Animal Crossing New Horizons anymore. Please give Dragon Quest Builders 2 a try!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      