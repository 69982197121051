import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Video = makeShortcode("Video");
const Ads = makeShortcode("Ads");
const LocalLink = makeShortcode("LocalLink");
const RemoteLink = makeShortcode("RemoteLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`It’s been a while since I last talked about anime so here’s one about my favourite 12-episode or 1-cour anime. I picked the ones that got me glued to the screen the first time I watched the anime, so I hope they’ll pique your interest just like how they did for me.`}</p>
    <h2>{`12-Episode Anime Recommendation #1: Izetta The Last Witch`}</h2>
    <p>{`This is my favourite anime of the season when it was first aired. The trailer got my attention when they showed a girl flying around on a rifle like a broomstick.`}</p>
    <Video title="izetta the last witch op" src="https://www.youtube.com/embed/euCMiWkqem4" mdxType="Video" />
    <p>{`The story is set in 1939, when the totally-not-Germany imperialist nation of Germania invaded a neighbouring country. The crown princess of Eylstadt, Ortfine (Fine) crossed the borders with hopes to  request for aid from Britannia. Her attempts were foiled by Germania and she was captured as a hostage. But luck has it that the very same plane carrying Fine is also the one carrying a `}<em parentName="p">{`witch.`}</em>{` The witch awoke from her slumber and saved Fine. Thus begins Eylstadt’s counterattack with the help of an all-powerful witch. But how will the presence of a witch turn the tide of the battle?`}</p>
    <Ads mdxType="Ads" />
    <p>{`I love how Izetta zips around the battlefield skies with a rifle. While the animation isn’t anything special, the anime’s setting makes Izetta’s actions spellbinding and surreal to watch. The rifles, tanks and even swords zipping around is really fun to watch. I think the writing could use more work but with all that said and done, it’s actually not too bad. It’s anime after all!`}</p>
    <h2>{`12-Episode Anime Recommendation #2: Astra Lost in Space`}</h2>
    <p>{`This anime is one of those that everyone is telling me to watch but I only got to it recently. I wrote a `}<a href="/blog/astra-lost-in-space-review/">{`lengthy review for it`}</a>{` if you are interested.`}</p>
    <Video title="astra lost in space op" src="https://www.youtube.com/embed/GhXpbeKcHu0" mdxType="Video" />
    <p>{`Astra Lost in Space takes place in a world where space travelling is possible. In the year 2063, a group of eight students and one child met up at the spaceport to attend a camp on McPa, a neighbouring planet. Things go awry when the group is sucked into a sphere of light. The next thing they knew, they were flung into the far reaches of outer space. They were saved when they discovered an abandoned spaceship near where they are. They thought they were near their camping site but they were instead, a few thousand light years from home. In a bid to survive, they decided to hop from planet to planet in order to stock up enough resources to get back home. This is how Astra begins a dangerous voyage back home and along the way, learned the truth of their supposed home planet.`}</p>
    <p>{`I think folks who love a nicely animated anime will enjoy Astra Lost in Space. Each planet they go to has an ambience filter on. The lighting, the angles and the space flora/fauna ideas are delightful! The plot twist is not what I expected either. It’s definitely good stuff. Please give this a try!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`12-Episode Anime Recommendation #3: Cautious Hero: The Hero Is Overpowered but Overly Cautious`}</h2>
    <p>{`I’m sure every other blog post or article out there has suggested this anime. Let me join the club! This is one of those anime that got me watching from the start to end in one night. I didn’t sleep that day.`}</p>
    <Video title="cautious hero op" src="https://www.youtube.com/embed/UB3JzCvXVEo" mdxType="Video" />
    <p>{`You guys know isekai(s) right? You got this goddess summoning a hero to save a world from the demon lord. Except this time, we get to see the goddesses doing her job and accompanying the hero! The Healing Goddess Ristarte was assigned to save the world of Gaeabrande. There’s nothing unusual with the work assigned… other than the fact that the world of Gaeabrande has an S difficulty! How will a new goddess handle this assignment? She decides to summon Seiya Ryuuguuin, a Japanese hero with out of ordinary specs. Little did she know that Seiya has this strange obsession with over preparing… He’s basically the title of this anime - Way too Cautious Hero. That said and done, how will they save a world with an S difficulty?`}</p>
    <p>{`The comedy in this anime is lit. There’s a ton of scenes that I really, really did not expect. Seiya is crude and uncaring. He’s basically the type of hero who would ditch you if you’re not useful. He won’t hesitate to pour holy water on you because he suspects you’re a zombie! Seiya is so cautious, it never occurred to him that a goddess can’t become a zombie! … Wait, actually maybe he did think of it but he’s too cautious that he needs to double check, just in case. There’s a great plot twist at the end, along with a super bittersweet happy ending. Good anime? No, `}<strong parentName="p">{`great`}</strong>{` anime.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`12-Episode Anime Recommendation #4: Lost Song`}</h2>
    <p>{`Lost Song belongs to the `}<em parentName="p">{`underrated`}</em>{` section. No one talks about it since it’s for Netflix only, and I swear absolutely zero folks out there love what they did in Episode 1. Sure, it’s a bit childish but if you get past that scene, everything is fine and dandy. `}<LocalLink to="/blog/lost-song-an-anime-about-singing-with-a-smart-loop/" mdxType="LocalLink">{`I wrote a review for it`}</LocalLink>{` if you're interested!`}</p>
    <Video title="lost song op" src="https://www.youtube.com/embed/nS_8WnqkzvI" mdxType="Video" />
    <p>{`You follow Rin, an energetic girl that holds the Power of Song. She could sing a song that could heal! In this world, songs are powerful magic that could do wonders. Create water, control wind and even create fire. Only the Songstress could sing songs of wonder. So when soldiers found out about Rin, they pursued her relentlessly. Rin escaped her village only to encounter more unfortunate folks affected by war. It was then that Rin noticed Finis. The young girl from a frontier village felt a connection with Finis. This is the story of how two young maidens guided by the hands of fate come together for a song of hope.`}</p>
    <p>{`Despite the whole wondrous summary I wrote, Lost Song has a twist after the mid-story that gets really dark. It introduces a whole new twist that you didn’t expect from a musical anime. Also, the songs are really good. The TV versions are the short ones. You could find the full song on YouTube! I won’t spoil you so go enjoy Lost Song <3 It’s good, I love the songs.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`12-Episode Anime Recommendation #5: Sleepy Princess in the Demon Castle`}</h2>
    <p>{`All of my blogger friends love this anime. If that doesn’t convince you, you should try the manga! It’s equally fun to read but I think the anime added their own touch which made it better. If this summary doesn't convince you, I `}<a href="/blog/why-you-should-watch-maoujou-de-oyasumi-anime/">{`wrote a separate `}{`"`}{`Why you should watch it`}{`"`}{` post.`}</a></p>
    <Video title="sleepy princess trailer" src="https://www.youtube.com/embed/Ui-KKFZKIq4" mdxType="Video" />
    <p>{`Princess Suyaris is a princess of the human kingdom. As she sleeps soundly in her room, she was suddenly kidnapped by the Demon King! The human kingdom’s hero along with his other two party members vow to bring the princess back. Fast forward to the Princess andddd she’s bored in her cell at the Demon Castle. I mean, there’s nothing for her to do but to sleep! But here’s the thing - `}<strong parentName="p">{`she can’t sleep.`}</strong>{` Her pillow is thin and the sheet is rough for her skin! The princess had enough. She’ll make her own pillow and sheets if she has to! So with some smart thinking, she broke out of her jail and started murdering demons left and right (Don’t worry, they can revive) all in the name of good sleep. Wait... If she’s so powerful, then why don’t she just walk out of the demon castle herself?`}</p>
    <p>{`This is a comedy anime. The joke here is that nothing can stop the princess, not even the demon king. She’s a bloodthirsty princess who will stop at nothing for a good night's sleep! Her accomplishments are really fun to watch. The anime added this quest screen which makes the whole “make your own pillow” conquest really fun to digest! Also, there’s a cute bear devil in this anime. I wish they would make a forehead devil plushie already!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`12-Episode Anime Recommendation #6: Croisée in a Foreign Labyrinth`}</h2>
    <p>{`This is a super old anime that aired back in 2011. I remember watching with lots of questions in my head. Why did the parents allow their kid to sail to the other side of the planet? That’s dangerous... Anyway, it’s a super comforting and fluffy anime to watch.`}</p>
    <Video title="croisee in a foreign labyrinth" src="https://www.youtube.com/embed/lv0rQ2NBy20" mdxType="Video" />
    <p>{`This story took place at the end of the 19th century. Croisée in a Foreign Labyrinth is all about Yune, a young Japanese girl who’s petite for her age. She befriended a French traveller, Oscar Claudel while he’s travelling in Japan and offered to help out at his family’s ironwork shop in Paris. The one in charge of the ironwork shop is Claude, Oscar’s grandson. He was reluctant at first (He thought she’s way too young to be helping, and he works alone) but eventually, he warms up to Yune's earnest wish to help. Watch as the two’s friendship explores the culture differences between the east and the west.`}</p>
    <p>{`There’s really no goal in this anime. It’s fascinating to watch how they introduce Japan’s and Paris’s culture though. You watch Yune help out at the ironwork shop and Claude doing his own thing. Yune is fascinated with his work but alas, little did she know that Claude’s small, dinky shop won’t last long against the new shopping mall. Yune eventually learned of Claude’s history with the shopping mall. On the other hand, Claude didn’t really understand Yune’s perspective as a in-house helper. The very idea that Yune must bath everyday shocked him! It’s these small details that make it really fun to watch.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`12-Episode Anime Recommendation #7: Erased`}</h2>
    <p>{`The Japanese title is Boku dake ga Inai Machi but I find Erased to be short and sweet. Fits the story to a tee too. I prefer the anime version instead of the manga. If you wish to know the OG story, read the manga! I believe this got a live action series too.`}</p>
    <Video title="boku dake ga inai machi op" src="https://www.youtube.com/embed/fodAJ-1dN3I" mdxType="Video" />
    <p>{`Satoru Fujinuma is a 29 year old manga artist who works as a part-time delivery guy. Even before the story starts, he possesses an ability to go back a certain point in life before a life-threatening situation occurs. He dubbed this ability “Revival”. At first he thought nothing of it but he soon uses it to save innocent lives if his ability kicks in. One day, his mother was murdered and he was wrongfully framed for it. His ability kicked in and he was sent back to 18 years in the past. Not only that, he’s back in his hometown where there’s a kidnapping incident that claimed the lives of three girls. He wasn’t sure why he was back but as he collected all of the evidence… He is convinced that preventing the kidnap incident is key to saving his mother. But can Satoru save them with the body of an 11 year old?`}</p>
    <p>{`The story from the start is super gripping. The poor dude came back home just to find his mother murdered. The first thing the police do is point him out as the potential murderer. The beginning episodes that showed Satoru running from the police sets an intense mood for the latter episodes. The sudden swap to his younger days is a surprise. As he tries to piece the information, the episode did a great job of making you doubt everything Satoru learns of. It’s a gripping story, please watch the anime!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`12-Episode Anime Recommendation #8: Girls’ Last Tour`}</h2>
    <p>{`Who’s up for an apocalyptic world, where the remaining humans have to wage war for the last of their dwindling resources?`}</p>
    <Video title="girls last tour op" src="https://www.youtube.com/embed/mF5MKNwbRhg" mdxType="Video" />
    <p>{`Follow Chito and Yuuri, the last of the human race. They left home in order to reach the surface, the last wish their grandfather told them to pursue. Along the way, they’ll encounter the last remnants of humanity’s civilization while seeking for food and supplies to survive day-to-day on their Kettenkrad.`}</p>
    <p>{`Each episode has the girls wander around to look for supplies from ruins. These girls do not have the common sense that came from living a peaceful life. They only knew war all their life after all. You see them pondering on the idea of having comfort and staying in a single place all their life after reaching an apartment building. They didn’t know music, so they couldn’t recognize a song once they heard of one. They never had fish before (All living creatures died off) so they didn’t know they could eat it until they tried one! It’s a great anime that lets you appreciate the modern lifestyle and comfort we know today. Let’s hope humanity doesn't nuke themselves in the future! Being the last two humans alive must feel terrible.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`12-Episode Anime Recommendation #9: Grimgar of Fantasy and Ash`}</h2>
    <p>{`I remember reading the light novel on a fan site once. The characters are dying left and right like the characters from Game of Thrones. It’s the type of anime that makes you feel scared for your favourite character. Do you wish to get attached to them and see them die, or just enjoy the anime as it is? Tough choice, tough choice…`}</p>
    <Video title="grimgar of fantasy and ash op 2" src="https://www.youtube.com/embed/yHCh8ffTZWM" mdxType="Video" />
    <p>{`A group of school children arrived in a fantasy world with no recollection of who they were before. The residents of this new world forced them to take on the jobs of an adventurer, which is synonymous with defeating monsters for their loot. The children needed money to survive in this world, and adventuring is the only option they have. Little did they know that the job that was thrusted onto them is the most dangerous of them all…`}</p>
    <p>{`Fantasy worlds with adventurers in them have this strange obsession with classes. Sure, classes are great since each member could wield a different skill set and thus, fits nicely into the party. You work together in a party after all. But what if they go solo? This is not a video game so if these children encountered a situation their current skill set couldn’t take on, they are `}<strong parentName="p">{`doomed`}</strong>{`. Please watch this anime with this sense of impending doom in every episode.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`12-Episode Anime Recommendation #10: Katanagatari`}</h2>
    <p>{`Warning! Each episode takes ~50 minutes. Okay, maybe that’s not a warning… I know some folks out there who love lengthy episodes. Remember Fate/Zero’s first episode? Two dudes just spinning around in circles to talk some exposition.`}</p>
    <Video title="katanagatari" src="https://www.youtube.com/embed/9wNuMDvD6h0" mdxType="Video" />
    <p>{`I’m sure everyone has heard of Monogatari. But for some reason, Katanagatari didn’t catch on that same popularity. It’s by the same author, yes, but he challenged himself to write a light novel volume for this series every month. There’s 12 episodes, so there’s 12 volumes released per month. You could check the wiki for their release date if you doubt my info. `}<RemoteLink to="https://en.wikipedia.org/wiki/Katanagatari#Light_novels" mdxType="RemoteLink">{`Each light novel volume released monthly!`}</RemoteLink></p>
    <p>{`Our male lead is Yasuri Shichika. He is a swordsman who fights without a sword (He’s technically a martial artist but for the sake of the plot, he’s a swordsman). Our female lead is Togame, a strategist who wants to collect all 12 legendary swords for the shogunate. She seeked out Shichika to help her collect these swords, but is her goal truly that simple? Each of these swords are wielded by these powerful users who were tempted by the swords. Each episode shows how Shichika triumphed over these swordsmen with Togame’s “strategy”.`}</p>
    <p>{`Each sword is not a sword. It’s strange but the 12 swords include a pair of handguns and armor. Everything gets answered in the last episode where they explain the truth behind Togame and the swords. FYI, please take note of Togame’s first conversation with Shichika about their “contract” and their conversation with the same dialogue again in the latter episode. They are repeating the same words albeit with different context now. I love how the author managed to pull this off. The scene went from “Ok, you win lady” to absolutely bittersweet after you noticed the context. Yup, I forgot to mention but there’s romance in this story. Enjoy your 50 minute episodes!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`All in all`}</h2>
    <p>{`I noticed there’s a few more 12 episode anime I wish to introduce. Some actually fall under 13 episodes instead so I didn’t include them in here. Enjoy these recommendations!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      