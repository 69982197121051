import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`As the episode’s ending shows the happy faces of the orphans as they continue working on making children books, I shed a tear or two. It was a sweet wrap-up on the happenings in S2 that Main set in motion. Main couldn’t have gotten to where she is now without the `}<strong parentName="p">{`bonds`}</strong>{` she made with everyone around her, and neither could they have improved their lives by a good margin without Main. It was a delightful give-and-take relationship that could only be done with `}<em parentName="p">{`goodness from both sides`}</em>{`.`}</p>
    <Ads mdxType="Ads" />
    <p>{`This is the `}<strong parentName="p">{`strongest message`}</strong>{` the series has done, and it’s this `}<em parentName="p">{`wholesome community`}</em>{` that I’m here for. To see Main’s effort reciprocated and revisited in the final episode in S2 made me well up. It was a nicely done, heartwarming scene as we recap on Main’s grit and determination to get things up and running before we move on to the next stage. Yes, she failed and stumbled along the way, but it was her endeavour with everyone’s help that got her to where she is now. Next, we are ready to enter the noble’s domain (clearly hinted with Ferdinand’s little talk with Karstedt).`}</p>
    <p>{`I was wondering when they’ll show Ferdinand probing Main’s memories as shown from a short clip in S1E1 and boy, I’m glad they had S1 and S2 episodes to pad things out until they reached the current episode. S1’s art and animation isn’t something I enjoy seeing since they made so many noticeable errors. Inconsistent art and odd perspective caused the series to look like it was a kid’s show. It was bearable because I really enjoyed the plot but it takes out of my enjoyment since I keep noticing them. Thankfully, S2 improved a lot on the art. The lineart is thinner, and you see more details in each character. I really like what they did with the opening and ending, even more so than S1’s. When I saw the distinct imagery that they use to depict the synchronization between Main and Ferdinand’s subconscious, I was really surprised. It doesn’t seem like it’s something the studio will do outside of the opening and ending. It was a unique take on magic items which in a way, matched up the story’s approach to it.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Ascendance of a Bookworm Season 2's synchronization scene with Ferdinand" caption="The lines switches from being tangled and creating faces familiar to her makes for an interesting imagery to show Main's mind state." name="ascendance-of-a-bookworm-season-2-2.jpg" mdxType="Image" />
    <p>{`But in this entire episode, what hit me the hardest is when Main apologized profusely to her mother she’ll never see again. As Urano, she’s knee-deep into her books. She reads whenever she can, even while she’s eating. It’s bad table manners and I do not blame her mother for chastising her. I was moved to tears when Main sobbed due to her selfish actions. It’s a common story everywhere, honestly. A child regretting not being able to say sorry when it mattered and now, they won’t have the chance to say it anymore. So please tell your family you love them while you still can ):`}</p>
    <Image alt="Ascendance of a Bookworm Season 2 flashback scene" caption="Main shouting out for her mother affected poor Ferdinand as well. I wonder if Ferdinand's family still care for him? I hope we'll find out in the next season." name="ascendance-of-a-bookworm-season-2-3.jpg" mdxType="Image" />
    <p>{`S2 hits hard and is by far, the better season compared to S1. This series honestly struck a chord with me (part of the reason why I couldn’t stop writing blog posts about it). The way it handled goodwill towards others, mindfulness with your actions and words, along with explaining familial love made this series a very special isekai anime to me. There’s no bombastic magic that our protagonist set out to master or a great evil wishing to harm the world. This isekai anime doesn't even have the prevalent status board mechanic either!`}</p>
    <Ads mdxType="Ads" />
    <p>{`Ascendance of a Bookworm simply talks about our protagonist struggling to live in a harsh, fantasy world with her sickly body. The series could have solely focused on Main’s journey on making books in a monotonous manner but no, it explored moral values and life lessons close to the heart. I struggled to find the right words to justify my fondness for this series, but the closest I could piece is this - I love Ascendance of a Bookworm simply because it’s down-to-earth.`}</p>
    <p>{`Ascendance of a Bookworm showed us that familial love is universal through Main and Lutz. Thrown into a foreign world, Main could have given up but it was her mother’s love that pulled her through. Main’s ideas are shown to be peculiar to the residents of this new world, but her family still dotes on her. Lutz is bitter about his situation with his brothers but the situation was resolved in the end after a good heart-to-heart session. Lutz’s family had a special way of showing love to their youngest brother but Lutz just didn’t realize it.`}</p>
    <Image alt="Ascendance of a Bookworm Lutz" caption="Lutz cared and that's all it matters. I hope the series won't sideline the boy in the coming arc." name="ascendance-of-a-bookworm-season-2-1.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <p>{`The series talks about how your actions and words have consequences, and to be mindful with how others perceive you. We see this through the grey-robed attendants dismissing Main due to her commoner’s background and supposedly unruly upbringing. Things did come around in the end, but only when Main behaved as expected of a noble. We were shown again and again to keep what’s important to us closer to the heart than your lips, as they’ll be used against us in a negotiation. We see this through Main’s interaction with two skilled business people, Freida and Benno.`}</p>
    <p>{`S2 talks about how children require affection and acknowledgement to bloom. We see this through Gil’s rebelliousness towards his tasks as Main’s retainer. In the end, it was Main’s quick willingness to acknowledge Gil’s hard work to turn him around. Delia too, is forced to do what she must to survive. She was saved when Main reached out to her when she needed it most. Wilma and Rosina couldn’t have been the best versions of themselves without Main’s kindness.`}</p>
    <p>{`The series had simple messages but it created some of the most beautifully portrayed life lessons interwoven with it’s own world-building effort. I’m really happy to see the ending card showing a potential Season 3 in the making. Ascendance of a Bookworm is one of the best isekai anime that strikes close to the heart, and I can’t wait to see S3’s Episode 1.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      