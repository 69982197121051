import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const LocalLink = makeShortcode("LocalLink");
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Every 4-star character in this game is a `}<strong parentName="p">{`winner`}</strong>{`. Seriously, I really appreciate how varied these characters from Mondstadt and Liyue are so far! While I have my qualms with how`}<LocalLink to="/blog/help-genshin-impact-wont-let-me-go/" mdxType="LocalLink">{` miHoYo designed the resin mechanic`}</LocalLink>{`, I’m happy that they designed the 4-star characters’ strengths to mesh really well with the rest of your team. Each character isn’t meant to be played solo! The game is encouraging you to swap your teammates in and out to play `}<strong parentName="p">{`Elemental Reactions`}</strong>{` to their fullest potential. This allows you to play whichever character you like. Your team, your build. Not a single character gets benched! This part of Genshin Impact is probably my favourite.`}</p>
    <p><em parentName="p">{`Note: I updated this post with my current Ningguang build. It's at the bottom of this post.`}</em></p>
    <p><em parentName="p">{`Second Note: I found out that Ningguang is a 5-star during beta testing. That explains her crazy damage!`}</em></p>
    <p><em parentName="p">{`Third Note: `}<LocalLink to="/blog/genshin-impact-zhongli-and-ningguang-geo-team-build-guide/" mdxType="LocalLink">{`I did an in-depth build guide for Ningguang and Zhongli here.`}</LocalLink></em></p>
    <Ads mdxType="Ads" />
    <p>{`There are games that came up with stronger characters with each release and voila, the older characters get `}<em parentName="p">{`benched`}</em>{`. The inevitable power scaling is commonly seen in many games with a long lifespan. E.g. Diablo 3. I kid you not, the Release Notes for `}<a target="_blank" rel="noopener" href="https://us.diablo3.com/en-us/blog/22820656/season-16-the-season-of-grandeur-is-now-live-1-18-2019">{`Diablo 3 literally just mentioned that they add more 0s to the damage multiplier`}</a>{`. It’s crazy how these big gaming companies couldn’t nail an evergreen battle mechanic. Elemental Reactions is honestly a really smart battle system that miHoYo came up with! As long as the 4-star characters bring their own unique strengths to the field, they get to stay in the team. I have my Noelle, Barbara and Xiangling at Level 50 and below because I don’t see the point in raising their levels up. I barely use them as my main DPS. Plus, Barbara just needs to heal and I’ll swap her out promptly.`}</p>
    <p>{`Many people suggested that you only need 1 main DPS character in your team while the other 3 will be in the team for elemental reactions. I agree with this sentiment because there’s only so many materials you could collect everyday! Boosting everyone’s level to the max takes a ton of time. It’s better to focus on 1 character first, and slowly work on the rest. I had trouble doing this since I enjoy levelling everyone up at the same pace, a habit I picked up from playing Pokemon. And yeaaaaaa, by the time I reached AR40, I was in serious trouble. It was really tough since I only played my favourites. Fischl’s damage is great but when ganged up by a Cryo and Hydro Abyss Mage, I’m dead.`}</p>
    <Ads mdxType="Ads" />
    <p>{`It was kinda my fault since I barely touched other elemental reactions save for Hydro + Electro. It was frustrating to see my Fischl get knocked back over and over again. Once you get chained by Frozen, you’re dead. The first World Rank quest took me a long time to finish! So I swapped her out for Ningguang (Fischl is my sub DPS now) and oh boy, best decision ever. I admit, I tried Ningguang at first because I love her design. Now, she’s my best 4-star character that I use wayyyy too often. Like, just look at her kit! Everything points to her giving extra DMG. Let me explain how she works.`}</p>
    <Image alt="Ningguang geo mummy stats" caption="Say hello to my Ningguang! <3 I only gave her 4-star artifacts since I have yet to start farming for 5-stars. I think I need to reach AR45 first to get better drop rates." name="ningguang-geo-mummy-genshin-impact-7.jpg" mdxType="Image" />
    <h2>{`Ningguang’s Star Jades`}</h2>
    <p>{`Everytime Ningguang attacks, she gains a Star Jade. Star Jades are those little pebbles that she has floating around her. She can have a max of 3 Star Jade at any time. If she does a charge attack when she has Star Jades, the Star Jades get used up to attack and the charge attack doesn’t use any stamina (You need to unlock it in her Talents). I noticed that the damage with additional Star Jades usually goes x1.5 or x2.0 times more than my usual attacks! If you have three Star Jades, it goes flying and yay, the boss is dead. The extra damage is such a lifesaver in tight situations. Plus, her attack range is pretty wide. All I do is stand still and just let her pebbles do the homing.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Ningguang’s Elemental Skill, Jade Screen`}</h2>
    <p>{`Her Elemental Skill is a godsend, I’m telling ya. While you can’t climb it like Zhongli’s pillar or the Traveler’s rock barrel, you can use it to `}<strong parentName="p">{`block projectiles`}</strong>{`. I’ve been using it to block Ruin Guard’s flying bombs, Hillichurl arrows and Whopperflowers continuous barrage attack. Yes, I managed to block them all successfully with the Jade Screen! The screen even gave a 10% Geo DMG raise for 10s if you walk through it so I always make sure to run through it and back before continuing my attacks on the poor Treasure Hunter that uses a bow…`}</p>
    <Image alt="Ningguang Jade Screen" caption="As a Geo character, her base HP is already high. If you boost her HP, her screen last a bit longer against projectiles. I remember seeing Hillichurls that chuck fire slimes at me get blocked by the Jade Screen (as long as the fire slimes don't fly over the screen). This screen is super good!" name="ningguang-geo-mummy-genshin-impact-5.jpg" mdxType="Image" />
    <h2>{`Ningguang’s Elemental Burst, Starshatter`}</h2>
    <p>{`Both her Elemental Burst and Skill are so spammable at 12.0s CD! I usually activate Jade Screen first before using Starshatter because the screen will send extra 5 shots along with your Starshatter. The additional damage is mighty appreciated. Plus, I noticed that any lightweight enemies (Hillichurls, Abyss Mages, Fatui Agents) get knocked back and their attack gets interrupted if you use Starshatter on them. They literally went flying and couldn’t counterattack. Thank you geo mummy. I now kiss the ground where you walk. But I’m not done talking about her!`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Ningguang Starshatter" caption="5 additional projectiles! The Jade Screen will automatically disappear after the projectiles are shot." name="ningguang-geo-mummy-genshin-impact-4.jpg" mdxType="Image" />
    <Image alt="Ningguang Starshatter" caption="Damage per gem is 115% at Lv. 5... If you boost her Energy Recharge %, her Starshatter + Jade Screen combo is literally spammable." name="ningguang-geo-mummy-genshin-impact-8.jpg" mdxType="Image" />
    <h2>{`Shield procured from Geo + any element`}</h2>
    <p>{`The Geo element screams useless in terms of Elemental Reactions but it’s just under utilized. With a shield on, you can absorb damage and you won’t be sent flying by knockback attacks (Unless of course, the attack broke your shield). So here we have Ningguang who applies the Geo element on every auto attack she does. As long as the enemy has any element applied to it, Ningguang attacking the enemy will most certainly drop an Elemental Shard. Pick it up for a shield and continue attacking with Ningguang! With Ningguang attacking, you’re almost guaranteed a ton of Elemental Shards dropping so your shields get replenished really fast. You really don’t have to worry about dying or repositioning yourself because Ningguang got you covered. All you need to do is stand still while the shield is up, and continue attacking.`}</p>
    <p>{`I used her to fight the Regisvine boss and I always win without swapping her out. You just need to position her close to the base and keep firing her attacks. Pick up any shards you see and continue firing! I was so surprised that she didn’t get flung backwards when the boss started doing the spin attack. Shields are such a lifesaver.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Extra: Geo Elemental Resonance`}</h2>
    <p>{`If you have an extra Geo character in your team, add it to the main team because...`}</p>
    <Image alt="Geo Elemental Resonance boost" caption="Since Ningguang is always with a shield, this is perfect. Extra DMG? Count me in! I use Zhongli with Ningguang in my team since his Petrify and shield helps a bit! It's also because I didn't really train up my Noelle. I guess I need to take a look at Noelle too later..." name="ningguang-geo-mummy-genshin-impact-1.jpg" mdxType="Image" />
    <h2>{`Another Extra: Breaking Abyss Mage’s shield`}</h2>
    <p>{`Obviously not as great as using the Abyss Mage’s opposing element, but it beats running around, swapping your characters and smashing them down. Since the Abyss Mage’s shield is an active element, spamming Ningguang’s attack on it will create Elemental Shards. Pick them up for shields and continue the barrage!`}</p>
    <p>{`Ningguang works great in domains too. Since these domains will most certainly have slimes in them, they make such fun whack-a-mole games for Ningguang. My modus operandi when using Ningguang is usually to spam 3 normal attacks first, then use charge attack on them. Pick up any Elemental Shards I see and continue the barrage. Once the Jade Screen is ready, use it and walk through it once every few seconds for extra damage. If the Starshatter skill is ready and the Jade Screen is almost done with the cooldown, use Starshatter first for extra 5 shots. Then, use Jade Screen when needed.`}</p>
    <h2>{`Another super extra: Ningguang Build Guide`}</h2>
    <p>{`I got this image from a Facebook group, I hope it helps!`}</p>
    <Image alt="Ningguang Build Guide." caption="Ningguang Build Guide." name="ningguang-build-guide.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`All in all`}</h2>
    <p>{`Ningguang is just so easy to use, super durable and could easily reach high DMG if you build her well. I just checked her constellation and I think it’s worth taking a crack at unlocking all of it. Since she’s a 4-star, Ningguang is easier to max! She could easily solo Boreas if she wanted to. All she needs to do is dodge those icy trails, spam her auto attacks and voila, collect rewards time! `}<strong parentName="p">{`I love her`}</strong>{`. Until I pulled someone better, she’s staying in my team forever.`}</p>
    <h2>{`Update: My Ningguang's Current Build`}</h2>
    <p>{`I wrote this before I reached AR45 and now, I've reached AR48. It's starting to get slow hiking up to AR50, so here's hoping they dump more events for us so we could accumulate more Adventure EXP. My Ningguang has just hit 2K total ATK (Remember, it doesn't translate to 100% damage) after hunting for `}<em parentName="p">{`countless artifacts`}</em>{`. I've been hitting LVL 90 artifact domains with my Genshin Impact gang daily after work. These LVL 90 domains unlock `}<strong parentName="p">{`after`}</strong>{` you reached AR45 with or without completing the World Rank quest, so feel free to go co-op once you hit AR45! Stupid resin only lets you hit 4 times a day if you make Condensed Resin from 160 total resin. I get 3 or 2 5-star artifacts if I use Condensed Resin so there's no reason `}<strong parentName="p">{`NOT`}</strong>{` to use them. I got really lucky with my banner pulls because I manage to get a `}<strong parentName="p">{`Skyward Atlas`}</strong>{` for my Ningguang. Both Ningguang and Skyward Atlas is at LVL 80 in the image below.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Genshin Impact Ningguang Build 1" caption="DEF be damned, ATK is the way to a happier Genshin Impact gameplay style." name="genshin-impact-ningguang-build-1.jpg" mdxType="Image" />
    <p>{`At first, I was aiming for artifacts with GEO DMG and ATK % alone for Ningguang. Obviously if you have limited runs per day, you have less chances of getting the artifact you want. So I got really lazy and just slap any artifacts that gives her the best ATK number-wise. Voila, I noticed that Ningguang's ATK is great IF you included CRIT DMG. When she attacks normally, two gems go flying towards the enemy. Each gem do a solid 700+ damage. Couple that with a GEO RESONANCE team and a shield on (+ ATK DMG 15%), she hits even harder! If she crits (which she does really often), she will do more than 1000+ damage per gem. With a charge attack, each gem that goes flying towards the enemy does 2000+ or more. Sometimes, I've seen the numbers go as high as 4000+.`}</p>
    <p>{`If she uses Starshatter (Elemental Burst), the numbers can go up to 8000+. Amazing. Obviously not as great as the LVL 90 characters, but good enough for a LVL 80.It really helps that her Jade Screen and Starshatter is super spammable. Both do phenomenal damage! I didn't even bother looking for artifacts with energy recharge anymore because they are easy to spam. You wouldn't even notice the skills' CD. Plus, I have yet to finish maxing out her talent, LVL and weapon LVL! Her numbers could go even higher <3 Lady is a steady tank <3 In a way, the build guide image I added before this section really does work wonders.`}</p>
    <Image alt="Genshin Impact Ningguang Build 2" caption="This is Page 1 of her stats. I would swap out her current artifacts if I could find better ones, but these stats are good enough. You don't need Elemental Mastery. Geo doesn't do elemental reaction and besides, if you play Ningguang, you know how often those shield shards pop up. I raised her HP high simply because I want her Jade Screen to last longer and so she doesn't die to bosses in one hit." name="genshin-impact-ningguang-build-3.jpg" mdxType="Image" />
    <Image alt="Genshin Impact Ningguang Build 3" caption="Ignore the other elements' DMG Bonus, I believe it comes from the team resonance bonus. Instead, focus on the GEO DMG Bonus. Woo hoo! Let's get it to 100%, because that's where the bulk of her damage comes from." name="genshin-impact-ningguang-build-4.jpg" mdxType="Image" />
    <p>{`I'm using two Gladiator and two Archaic Petra set. I was trying to aim for Noblesse Oblige set because I love spamming her Elemental Burst. Alas, absolutely NONE of the artifact I hunted for has the stats I want. NONE. Only the artifact dropped by the bosses I hunt daily for the ascension gems and weekly bosses dropped artifacts with GEO DMG.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Genshin Impact Ningguang Build 4" caption="All of them are 5-star artifacts because their base stats are so much higher if you raise them to LVL20. I simply feed 4-star artifacts (even if I raised them before) to raise the 5-star artifacts' levels." name="genshin-impact-ningguang-build-5.jpg" mdxType="Image" />
    <Image alt="Genshin Impact Ningguang Build 5" caption="I got really, really lucky with this one. Whenever I hit a certain LVL cap, they keep raising the artifact's CRIT DMG for me untillll they swap over to raise the DEF for me. Eh, good enough." name="genshin-impact-ningguang-build-7.jpg" mdxType="Image" />
    <Image alt="Genshin Impact Ningguang Build 6" caption="I have 1 Artifact with GEO DMG. And it's this Gladiator goblet. Only this. So what does my Zhongli equip? An Archaic Petra goblet with HP %. Sorry baby, I'll get you a proper GEO DMG goblet next time. Just a heads up, only goblets have elemental DMG so most folks would pick a random goblet with the elemental DMG they need and the other 4 artifacts need to be from the sets that they need. Good luck with your artifact hunting!" name="genshin-impact-ningguang-build-6.jpg" mdxType="Image" />
    <Image alt="Genshin Impact Ningguang Build 7" caption="Here are the materials needed to raise her to LVL 90. I'm still at AR48 so I need to wait longer... But don't wait until you reached AR50 to hunt for the character ascension materials. That geo gem you see as the first material needed? The Geo boss never dropped it for me. This is why I have 40+ Basalt Pillars. I have to keep hitting the Geo boss to collect at least 3 lesser gems in order to craft the bigger geo gems. Prepare to hit the Geo boss for many, many times. Sigh, I need to hit the Geo boss for my Zhongli too..." name="genshin-impact-ningguang-build-2.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <Image alt="Genshin Impact Ningguang Build 8" caption="I honestly can't wait to raise her normal attack talent... This will surely boost her damage even further. LET'S GO! All the best with your Ningguang build! If you can't pull a Skyward Atlas for her or any 5-star weapon, the BP weapon Solar Pearl works good enough. My Ningguang uses Eye of Perception before Skyward Atlas. Boy, that weapon is a monster too. The only thing great about 5-star weapons is the base stats, along with their extra effects. Other than that, 4-star weapons is good enough." name="genshin-impact-ningguang-build-8.jpg" mdxType="Image" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      