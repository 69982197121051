import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXFanfiction/Chapter/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const RemoteLink = makeShortcode("RemoteLink");
const Ads = makeShortcode("Ads");
const Divider = makeShortcode("Divider");
const AuthorNote = makeShortcode("AuthorNote");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Byleth looked down at her digital watch and to her great surprise, it was still working. What felt like hours on the way here, was only an hour. It wasn’t even 11.30am.`}</p>
    <p>{`The darkness was unremarkable, she didn’t or rather, couldn’t see any creatures even if they were right next to her. The depths of the ocean was eerily quiet, in fact the silence was so loud it was almost deafening. She focused on Dimitri’s breathing and the warmth of his embrace to keep her hold on reality.`}</p>
    <p>{`However what truly surprised her was Dimitri’s...Little Mermaid-Ariel voice. She literally could not tell the difference whether her eyes were open or closed, and the fact that she was sort of standing on the ocean floor surrounded in complete darkness was just...terrifying.`}</p>
    <p>{`But when she heard Dimitri’s `}<RemoteLink to="https://www.youtube.com/watch?v=o1BwWoMcIdI" mdxType="RemoteLink">{`special Ariel-voice`}</RemoteLink>{`, the warmth and tranquility she felt completely vanquished all forms of fear in her. It was...actually magical.`}</p>
    <Ads mdxType="Ads" />
    <p>{`She couldn’t see what was happening, but the sound of enormous rocks moving was akin to a minor earthquake. As soon as the tremors subsided, it slowly revealed a tunnel with light at the end of it. In a sense, it was like she was crossing over to ‘the other side’.`}</p>
    <p>{`She was starting to feel Dimitri’s significance when two mermaid guards with spears greeted him as ‘Your Highness’. She turned back to look at them, and then back at Dimitri, and Dimitri really did exude a different aura from them.`}</p>
    <p>{`Byleth didn’t know what excited her more; even more mermaids or the small bustling city the tunnel revealed. Her jaw dropped at just the sheer presence of it all. It was as if she was trapped in her own mind, she had no idea what was going on around her even though Dedue swam up to them and said something to Dimitri.`}</p>
    <p>{`Byleth was just...soaking everything in. Until Dimitri’s father came up to them and assumed she was Dimitri’s girlfriend, that is.`}</p>
    <p>{`“S-sorry?” Byleth suddenly spoke up. “I’m what?” Byleth probably sounded a little more snappy than she intended, but her face was hot and she wished the ocean swallowed her whole right now.`}</p>
    <p>{`Dimitri was still holding on to her, looked like he was about to explode, tossed her to Dedue who gingerly received her. “I apologize for making you feel uncomfortable,” Dedue said with a pained expression.`}</p>
    <p>{`Byleth shook her head, “Oh no, not at all, I’m probably imposing right now as well. I...didn’t even bring a gift.”`}</p>
    <p>{`“Your presence itself is a gift to this community,” Dedue said firmly, “You do not know how happy you make His Highness-”`}</p>
    <p>{`“No. She’s just a good friend to me!” Dimitri said loudly, both Byleth and Dedue jerked their heads to see Dimitri having a rather heated conversation with his father.`}</p>
    <p>{`Byleth didn’t know if it was even possible for Dedue to have a more pained look on his face, but here he was, looking as if he was asking someone to end him, “Please...pay them no need,” the gentle giant said with remorse.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Strangely, as much as she tried, the way Dimitri insisted he didn’t have any feelings for her seemed to cause her chest to ache. It felt like someone just punched her in the gut and she felt a little light headed even. She shrugged it off, chalking it up to feeling overwhelmed at the whole experience of being in a mermaid community.`}</p>
    <p>{`Byleth forced herself to look around her, afterall she was standing- well, `}<em parentName="p">{`floating`}</em>{` in a myth that had come to life. She didn’t have time to mope for whatever mysterious reason.`}</p>
    <p>{`Dedue was still holding on to her so she didn’t have much wriggle room, but from what she could see, Dimitri’s hometown was totally wasn’t what she expected. At all.`}</p>
    <p>{`Naively, she thought it’d look like Ariel’s home, Atlantica, but...it didn’t. She didn’t really know how to describe it but it looked as if someone submerged the original buildings of Garreg Mach University underwater. There were visible streets, pavements, and even street lamps.`}</p>
    <p>{`Of course a lot of it was covered in moss, but it resembled ruins more than anything but the one thing that completely amazed her was how it was bathed in a warm glow of sunlight. Byleth had an inkling they were really, really deep beneath the sea somewhere `}<em parentName="p">{`yet`}</em>{` somehow...it was as if someone had attached a lamp at the top of the city to illuminate it.`}</p>
    <p>{`They had traveled in complete darkness on the way here, seeing the way Dimitri’s hometown was lit up at the bottom of the ocean was a surreal experience. She felt like she was in a handcrafted ocean-themed snow globe on display.`}</p>
    <p>{`It wasn’t even covered in seashells, clams or dead crustaceans. It truly looked like a regular town with petrified trees and some benches dotted around them. It was then that Byleth remembered the lore, ‘`}<em parentName="p">{`Saint Seiros sunk a small and remote portion of the Holy Kingdom of Faerghus into the ocean in a fit of rage.`}</em>{`’.`}</p>
    <p>{`She looked around even more intensely now, squinting into the distances and the more she observed, the more plausible it seemed that it was part of a city that was sunk. It was...like a city sitting inside a large bowl made of rocks, caves and coves. Sort of like....a gargantuan valley with a city underwater.`}</p>
    <p>{`It wasn’t full of grandeur like Ariel’s Atlantica, with bright golden lights shooting out of its palace, it was more...idyllic and...somewhat melancholic as well. Since it was a city of ruins, Byleth felt a sad wave of emotions wash over her. If and, most likely the legend was true, this entire settlement used to be above land, and it used to be home to people. It was probably an actual bustling city.`}</p>
    <p>{`She couldn’t even start to imagine what the original humans felt when their home was sunk to the bottom of the ocean. The apprehension of being isolated from the rest of the world without any form of communication, and trying to adapt and live in their new environment while an angry deity was out to get them must’ve been...just...unthinkable.`}</p>
    <p>{`Did they all drown? Or did the Goddess immediately intervene? What was it like to adapt to life after the one you knew was robbed of you? Just thinking about the fear and uncertainty those people felt, made Byleth suddenly appreciative of her own life.`}</p>
    <p>{`She felt the grip around her arm loosened as Dimitri swam towards them and she quickly grabbed onto something before she floated away. Dimitri then resumed his hold of her, something her body seemed to enjoy as her heart started racing again.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“I’m sorry for being rude,” Byleth said, “I didn’t introduce myself to your father yet.”`}</p>
    <p>{`Dimitri shook his head, “No, we were the rude ones. He’s...like me..um, ever since my mother died...he hasn’t...well,” he paused, “Erm, he doesn’t venture much to the surface anymore. He still...misses her, quite a bit.”`}</p>
    <p>{`She gasped slightly, feeling horrible at how she blurted out her thoughts so impolitely, “I’m so, so, sorry. Can I apologize now?” Byleth asked, looking for his father.`}</p>
    <p>{`He was only holding her hand at first but now he placed both hands on her shoulders, giving her a firm grip, “We can do that later...but...w-would you like a tour?”`}</p>
    <p>{`His father, who looked like an older Dimitri in his mid-40’s with a beard, was nowhere in sight. Byleth already felt like she was imposing and decided to just go along with whatever Dimitri had planned for her.`}</p>
    <p>{`She genuinely felt excited about exploring his home and learning about how they lived. Not like she could tell anyone about it, but still it thrilled her. Byleth nodded at him and this time, Dimitri suggested she climb, or rather, cling onto his back, wrapping her arms around his neck as if he was giving her a piggy-back ride.`}</p>
    <p>{`Byleth noticed as she floated round to his back, just how...long Dimitri was. She assumed he was about 185-190cm as a human, but as a mermaid, Dimitri was probably no longer than 2.5 meters from the top of his head to the tip of his beautiful translucent iridescent fins.`}</p>
    <p>{`She even started to notice the various...attachments on his tail, like the fin on where his human butt would’ve been. It was probably his dorsal fin...or anal fin or something when he swam, helping him cut through the water… Actually it reminded her of a shark’s fin.`}</p>
    <p>{`And up close on his back, she could see the numerous scars of various sizes and shapes that decorated it like battle scars. Especially the one shaped like a bite mark, it was a very distinct bite mark that went round his shoulder and to his chest, the circumference easily twice the size of her hand. She traced the scar, feeling the bumps and the soft keloid skin.`}</p>
    <p>{`“That was from a goblin shark,” Dimitri suddenly said and Byleth retracted her fingers, apologizing for being rude again.`}</p>
    <p>{`“You actually got bitten by a shark?” Byleth asked, wincing at the thought of being bitten by a deep sea creature, “W-Why? I mean, how?”`}</p>
    <p>{`“Erm...I suspect it thought I was food? I...uh...I..have really bad luck sometimes...” Dimitri explained as he started swimming at a leisurely pace instead of his previously record breaking speed. Byleth likened it to a walking pace. “Goblin sharks are ambush predators so..it...ambushed me...”`}</p>
    <p>{`Byleth wasn’t about to say how fascinating it was for a shark to try and eat a mermaid, so she merely replied, “I see,” in her most somber voice, while trying to picture Dimitri fighting off whatever a `}<RemoteLink to="https://www.google.com/search?q=Goblin+Shark&amp;sxsrf=ALeKk03xMgdTIkbVB8VjEjC4pQRq5Rtpgw:1625900603234&amp;source=lnms&amp;tbm=isch&amp;sa=X&amp;ved=2ahUKEwj96JTf99fxAhXjyDgGHZtRBGwQ_AUoAXoECAEQAw&amp;biw=1920&amp;bih=969" mdxType="RemoteLink">{`Goblin Shark`}</RemoteLink>{` was, putting in a mental note to google it later.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“This is our main street,” Dimitri said, starting his tour, “It’s the most frequented and utilized area in our...um...town.”`}</p>
    <p>{`Byleth was slightly disappointed when the female mermaids weren’t wearing seashells, but were in fact, wearing...T-shirts, while some of the males were topless, like Dimitri. Her eyes jumped from one mermaid, to another, noticing they were all dressed like regular humans, some in T-shirts, some in tank tops or bikinis.`}</p>
    <p>{`“Can I...ask you a fairly stupid question?” Byleth asked shyly.`}</p>
    <p>{`“Hrm?”`}</p>
    <p>{`“Why aren’t the female mermaids wearing..seashell bras…?” Byleth hid her face from view, as if Dimitri’s head could turn 180 degrees to see her blushing cheeks.`}</p>
    <p>{`“Why...would they be wearing seashells?” Dimitri asked her back.`}</p>
    <p>{`“Because...Ariel and her sisters wore seashell bras?” Byleth replied sheepishly, ashamed she was stereotyping `}<em parentName="p">{`mermaids`}</em>{`.`}</p>
    <p>{`Dimitri stopped his swimming and turned to look at her with his head tilted, “Who is Ariel? I don’t think anyone in our community is called Ariel...”`}</p>
    <p>{`“Oh...um...no one, just a cartoon mermaid character that is like the poster girl of mermaids on land,” Byleth said, feeling more and more embarrassed by the second.`}</p>
    <p>{`“Erm...I don’t think seashells are particularly comfortable to wear...” he replied. Byleth was thinking of organizing a movie night for Dimitri to watch The Little Mermaid, although she wondered if it would be an insensitive thing to do.`}</p>
    <p>{`“Ah, I see, that makes sense actually,” she said quickly. “Although is there a need for them to cover up their modesty? You don’t seem to have any problems with walking around how the Goddess intended you to be,” she blurted out.`}</p>
    <p>{`Instantly, Dimitri’s entire face turned red, right up to his earlobes, “I-I...I-I..I’m um,...s-still w-working on that…!”`}</p>
    <p>{`Byleth chuckled, “Sorry, couldn’t help it.”`}</p>
    <p>{`Dimitri turned away, covering his face with a hand while still holding on to her with his other, “I-I..I wasn’t brave enough to venture up to the surface for long periods of time, so..so I’m still getting used to the customs up there...and clothes...I’m still getting used to....c-clothes.”`}</p>
    <p>{`“So...how come they’re wearing t-shirts?” Byleth gestured to a mermaid who waved at her and she waved back, reeling in her glee from having a mermaid greet her who had bright yellow to orange gradient tail.`}</p>
    <p>{`Dimitri nodded at the mermaid who waved at her, “Oh umm...many merfolk here often wear human clothes to get used to it…it’s actually...ah...encouraged.”`}</p>
    <p>{`Byleth blinked at him, “Then why aren’t you-”`}</p>
    <p>{`“-I-I don’t like wearing clothes…” Dimitri suddenly said, eliciting another chuckle from Byleth. He was like a toddler who decided that clothes were irritating and was perfectly fine being naked all day.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Dimitri pulled her back onto his back while ignoring her laughter and continued with his tour, his ears still red, “The Goddess Sothis made it so that our home would always be bathed in an eternal glow, instead of living in darkness.”`}</p>
    <p>{`“Yeah...it’s...beautiful,” Byleth said. They went up the main street and headed towards a large-ish building in the background.`}</p>
    <p>{`“Time runs...differently here,” he continued, “While we do have sunlight...it...it..erm...it’s distorted. It’s kind of...a...it’s..a sort of magical source of light? It blends seamlessly into the moonlight so...after a while it can be hard to tell the passing of time.”`}</p>
    <p>{`“Oh, is that why you weren’t really sure how old you are?” He nodded in reply.`}</p>
    <p>{`“For sunlight to be able to pierce through, the Goddess placed the enchantment that protects us from harm and discovery. Even if humans and their submersibles were to...dive right above our ‘dome’, they would see nothing but blackness and….uh....just ocean seafloor, I think. Which we do see occasionally...it’s...quite e-exciting.”`}</p>
    <p>{`Dimitri swam round the city, pointing out that their original settlement was much larger, but since their population had dwindled, they only maintained a smaller section in which to live.`}</p>
    <p>{`“Only merfolk who live here are able to...ah...unlock the entrance, as it were.” Dimitri said, “This...bubble we live in, is completely self-sustainable. It’s...n-not uncommon for us to not venture out into the surface and...lose track of time and years.”`}</p>
    <p>{`After listening very quietly but attentively, Byleth concluded that their protection charm was similar to Hogwarts. The Goddess really did think of everything to protect them.`}</p>
    <p>{`Dedue came up to them, holding what looked like scuba fins and a...underwater propulsion jet?`}</p>
    <p>{`“I have brought these, Your Highness,” Dedue said, “I have chosen what should be befitting her size and her swimming capabilities.”`}</p>
    <p>{`Dimitri shook his head, “That’s alright, Dedue. She doesn’t need it.”`}</p>
    <p>{`Dedue frowned, “But sir, you’ve been carrying her all morning-”`}</p>
    <p>{`“It’s fine,” Dimitri insisted. Dedue bowed and swam away, Byleth didn’t even get a chance to say she would’ve gladly used it instead of relying on Dimitri all the time. Not that she minded, but still.`}</p>
    <p>{`“Did you...prepare that specially for today?” Byleth asked.`}</p>
    <p>{`Dimitri shook his head, “Not really, we have a few of those on hand for the handful of human spouses and people from the Fraldarius family.”`}</p>
    <p>{`“That...so cool,” she blurted out, “You guys really have a solid system going on here. Even the basket of spare clothes by the beach was really thoughtful.”`}</p>
    <p>{`“It’s...nothing, really,” he replied.`}</p>
    <p>{`He pointed out various buildings that housed their small community of less than a hundred merfolk. They didn’t have ‘shops’ per se, they mostly traded items with each other and if they really wanted something from the human world, they could put in a request with the administration, and a ‘hunter’ so to speak, would venture out to the surface to obtain it.  Of course the merfolk themselves could get it, but it was safer for one hunter than a group of them to do so. Byleth said, “That sounds like a personal shopper.” Dimitri didn’t know what that was but he nodded nevertheless.`}</p>
    <p>{`Dimitri also mentioned that thanks to the enchantment, a few places in his little city had pockets of air which some of the merfolk used as dry-storage, which explained the few caves and coves she saw in the background when they came in.`}</p>
    <Ads mdxType="Ads" />
    <p>{`They passed by a small chapel, dedicated to the Goddess Sothis who made life possible beneath the waves. It seems the merfolk were still devout followers of her. There was even a small school next to it, which was unsurprisingly, an actual building, or what was left of a building.`}</p>
    <p>{`“Did you attend this school too?” Byleth asked excitedly as she peered into a hole where a window would’ve been.`}</p>
    <p>{`“Yes...we all did,” he said, “While most of us start living on land once we reach s-s-s-sexual maturity, we send a few up to study human education, with the help of the Fraldarius’ of course.”`}</p>
    <p>{`“Amazing...just...amazing,” Byleth said in awe, her curiosity and inquisitiveness were getting fed every second she was down here.`}</p>
    <p>{`Dimitri bashfully scratched his cheek, “W-We’ve been here for a millenia a-after all.”`}</p>
    <p>{`Byleth noticed Dimitri didn’t seem to have any problems with his speech when talking about his hometown, it made her smile to see him proudly telling her about his home and life below the sea. Her heart even felt..full. Like...she was completely and utterly happy.`}</p>
    <p>{`Dimitri stopped in front of the tall building at the end of the street, it was in a similar style to the other buildings, albeit slightly more grander and...intact. “This...is...um...our...administration building?”`}</p>
    <p>{`“You’re...not sure what it’s called?” Byleth asked.`}</p>
    <p>{`Dimitri shrugged, “It’s...my home...house, I mean. I-I live here. I...don’t really like calling it the Palace...because it’s not. It’s just...where my father runs our little city from.”`}</p>
    <p>{`Byleth was just...speechless. Many of the movies that had mermaids barely showed anything about their way of life, just them swimming beautifully in the water, enchanting men and women alike, sometimes to their deaths. So she totally wasn’t expecting a...mermaid city running like clockwork with their own royalty and administration even.`}</p>
    <p>{`“B-Byleth?” Dimitri called out to her, “Are you o-okay?”`}</p>
    <p>{`“Yes, absolutely. I’m..just...taking it all in. It’s...phew, umm, breathtaking and...amazing and...just...so cool and..I’m running out adjectives, sorry,” Byleth said, babbling more than she normally did.`}</p>
    <p>{`There it was, the Dimitri smile that made her body feel all weird and tingly.`}</p>
    <p>{`“Thank you,” he said, smiling and blushing. “I’m...very relieved you l-like my hometown, it’s...my first time bringing an outsider on my own here.”`}</p>
    <p>{`“T-Thank you for um, bringing me all the way here. Sorry I’m not much help,” she replied, feeling completely and utterly useless. Mermaids were clearly the superior beings. If it wasn't for his magical kiss, Byleth would’ve drowned in two inches of seawater.`}</p>
    <p>{`Dimitri took her into the building, which was much bigger than it looked. They even had a receptionist of sorts. He took her to the upper floors, through what was originally a hole in the ceiling for a grand looking staircase and they arrived at a room on the top floor.`}</p>
    <p>{`“This is...my...room,” Dimitri said quietly to her.`}</p>
    <p>{`It was...almost barren, save for a shelf with some items on it and something that resembled a bed.`}</p>
    <p>{`“Um...is that your bed? H-How do you..sleep on stone?” Byleth pointed at the flat slab of stone at the center of the room. “Do you even...sleep?”`}</p>
    <p>{`“I don’t sleep on it, it’s just a..placeholder of sorts, we...chain ourselves down while we sleep.”`}</p>
    <p>{`She tilted her head and furrowed her brows, she was obviously confused. Dimitri pulled her towards his ‘bed’, gesturing at her to hold on to something.`}</p>
    <p>{`Dimitri lifted a thin, linked metal chain the length of about 1 inch from the floor that was attached to a metal pole next to his ‘bed’, and looped it around his waist, while using a carabiner to hold it in place. “It’s to...uh...how to say...prevent us..floating away while we sleep.”`}</p>
    <p>{`And Byleth just burst out into giggles, “Oh that is so cute. And so smart.” Without thinking, she kicked her legs and ‘swam’ over to him (more like flailing), examining the chain that was looped around his waist.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“We do replace it once it starts to rust. Um...I think my ancestors used to use rope, but it rotted away...very quickly while leather became..er...brittle? So these modern technologies are very useful, like using galvanized steel. Although it does get a little uncomfortable sometimes but...uh...we make do.” Dimitri said, unchaining himself and letting the metal chain drop to the floor.`}</p>
    <p>{`By accident, Byleth brushed her hand against Dimitri’s tail, it felt...exactly like a fish. Soft, fragile and slightly slimy...but it...glowed?`}</p>
    <p>{`She did it again, this time intentionally and where her hand made contact, his scales lit up in the shape of her hand.`}</p>
    <p>{`“You...you can glow in the dark?” As if his tail wasn’t already the most beautiful thing she had ever seen, it was...glowing a beautiful soft blue light right in front of her. Like...those bioluminescent planktons on the beach.`}</p>
    <p>{`Byleth looked up at Dimitri who was blushing furiously, “Yes...however I don’t...use it much. It...attracts too much attention. I got...bitten a few times when I...erm...turned it on. Although it does scare away predators sometimes...Ah, we use it to communicate with each other in the dark too. It’s...actually rather useful.”`}</p>
    <p>{`She couldn’t take her eyes off the gentle illumination that his tail was emitting as she gasped in excitement. “You can turn it on?”`}</p>
    <p>{`He nodded shyly and then as if on queue, his entire tail, from his waist, to the tip of his fins, gleamed with a soft shimmering light. She placed her hand on his side and this time, where she touched, there was a blank spot in the shape of her hand. Byleth couldn’t understand the hype of going to beaches at night to purposely play with those planktons, but now she did.`}</p>
    <p>{`Byleth couldn’t resist and did the unthinkable; she started writing her name on his scales with her finger and to her absolute glee, it worked.`}</p>
    <p>{`“This...is...awesome,” she gasped again. She kept tapping his tail like an excited child with a new toy. But then she realised, this was a living, breathing person she was molesting, “Oh Goddess, I’m sorry, Dimitri,” she said and quickly retracted her hand but kept her eyes on the fading luminescence from his tail.`}</p>
    <p>{`“It's okay,” Dimitri replied, “I don’t mind.”`}</p>
    <p>{`As the glow slowly dimmed from his tail, “Can...all of you do this?” She asked.`}</p>
    <p>{`“Yes.”`}</p>
    <p>{`“Even...Dedue?”`}</p>
    <p>{`“Yes.”`}</p>
    <p>{`“Your father?”`}</p>
    <p>{`“Of course.”`}</p>
    <p>{`This was the best day of Byleth’s life.`}</p>
    <Divider mdxType="Divider" />
    <p>{`It amused Dimitri to no end seeing Byleth’s reaction to everything he was showing
her. He loved having a friend. Again, Dedue was always a given but Dedue himself
set firm boundaries that he was there to serve. Felix and Glenn were like cousins
but Byleth was...something that made his heart sing, and he enjoyed showing her part
of his world to her. On land, Byleth was expressionless, and showed almost no reaction
to anything, save for the few times she exhibited subtle emotions on her face - a
trait her twin brother shared, he noticed.`}</p>
    <Ads mdxType="Ads" />
    <p>{`The Byleth in front of him now, was like a completely different person. And so was he.`}</p>
    <p>{`In just a span of about two months, Dimitri felt more and more comfortable coming out of his shell. All this just by spending time with her and just patiently talking to each other.`}</p>
    <p>{`In the short time he existed, Dimitri found comfort in the dark depths of the sea, even though it wasn’t exactly dark to him but the silence was home. He loved going out to explore the oceans and wouldn’t come home sometimes for weeks at a time. Swimming among the fishes and undiscovered coral reefs and rescuing the occasional unconscious fishermen or sailor was initially a good distraction from his grief.`}</p>
    <p>{`His mother, a human, was wonderful, kind and fun. Like all merfolk, he was born on land, and by the time he was a toddler, he could transform into a mermaid at will, although somewhat uncontrollably. Different merfolk parents had different preferences on where they wanted to raise their child. Dimitri and his parents spent his earliest years on land, and it was the happiest time of their lives.`}</p>
    <p>{`Being with Byleth...reminded him of those happy times. And he was more than happy to let her play with his tail, even though it was slightly ticklish. His tail, after all, had the same nerves as human skin.`}</p>
    <p>{`But Dimitri just couldn’t bring himself to tell her that tail caressing was a tradition that was reserved for lovers. Afterall, another purpose of a mermaid’s bioluminescence tail was similar to a firefly squid; to attract a mate.`}</p>
    <p>{`He thoroughly enjoyed seeing her have so much fun, in fact, Dimitri thought of whether he wanted to show her his secret hideout located just above his room. It was a little cave that could only be accessed through the hole in his room, and it was roughly the size of a car with just enough standing space but Dimitri had to crouch now that he was fully grown.`}</p>
    <p>{`The little air pocket was where he kept all his mementos and little treasures dry and safe from water damage in a few waterproof bags he had obtained.`}</p>
    <p>{`Dimitri decided it was a surprise for another day, worried that Byleth would be suffering an overload of things to process. But it was time for lunch anyway.`}</p>
    <p>{`There were so many more things he wanted to show and tell her, he too was bursting with excitement and riding the high feelings that came with it. He actually had to calm himself down a few times from overdoing it.`}</p>
    <p>{`“Ah yes, good! You’re here!” His father, Lambert greeted them when Dimitri brought them to his father’s office, “Come, we will be dining on the ‘terrace’ today, so that your new friend here will be more comfortable.”`}</p>
    <p>{`“Thank you, sir,” Byleth said, unsure of whether she should curtsy or...bow. She didn’t know how to anyway.`}</p>
    <p>{`His father led the way out of his office and out of the administration building. They followed the pathway further down their town, an act they didn’t need to follow since they didn’t actually need pathways, but they still did anyway.`}</p>
    <p>{`“Oh I didn’t notice at first but that’s beautiful,” Byleth suddenly said, pointing at a small group of children with glowing tails as they played amongst each other.`}</p>
    <p>{`Dimitri felt a small pang of jealousy looking at the children, since he was a painfully shy child and didn’t have any friends, except Dedue who also didn’t talk unless necessary. But more than that, Dimitri was delighted to see the children of his small kingdom getting along. A sight he didn’t get tired of. He even allowed himself a smile as he looked down at Byleth who seemed happy as well.`}</p>
    <p>{`“Um...For children, they are not as skilled as adult merpeople in controlling the glow of their tail,” Dimitri said as they swam away, but Byleth’s eyes were still fixed on them, “So their tails will often light up involuntarily. Or...er...e-even in adults, sometimes even sneezing will cause our tails to glow.”`}</p>
    <Ads mdxType="Ads" />
    <p>{`“....That is...adorable,” she chuckled.`}</p>
    <p>{`And...involuntarily and without her noticing, Dimitri’s own tail began to light up when the sounds of her laughter reached his ears.`}</p>
    <Divider mdxType="Divider" />
    <p>{`Once her excitement had calmed down, Byleth was beginning to notice many more things
in his hometown. Like the group of children with glowing tails, they were now swimming
over a coral reef garden, probably the size of a football field. She noticed subtle
movements in the corals, indicating life was nestled between its colourful tendrils.`}</p>
    <p>{`This truly was a paradise under the sea. As an introvert, she could understand why Dimitri didn’t venture out onto land for so long.`}</p>
    <p>{`They pierced the surface and once again, Byleth didn’t notice the change in air at all. She was probably under water for more than 2 hours, yet...here she was. Alive and breathing.`}</p>
    <p>{`They had emerged in a fairly large cave which looked like it had a mini beach in it with a sliver of sunlight shining down, seashell decorations, a sandy beach-like floor and even some...plastic lounge chairs and a changing tent. There was also picnic cloth spread out on the sand with various seafood on it and some drinks even.`}</p>
    <p>{`Dimitri heaved her onto land with his father extending a hand towards her, which she gracefully accepted. And thankfully, his father was already wrapped in a towel, provided by an attendant where they were emerging.`}</p>
    <p>{`As she observed the slightly damp cave, she shivered for the air cool and there was a slight breeze coming in from somewhere. Dimitri had taken a towel from the attendant and draped it over her shoulders, giving her some much needed warmth.`}</p>
    <p>{`At first, she swore she thought it was Felix, but upon closer inspection, this human man was bearded and had his long hair tied up in a low ponytail.`}</p>
    <p>{`“You must be Miss Byleth,” Said another of Felix’s lookalike as he extended a hand, “I’m Rodrigue, I’m Felix’s father.” Ah, no wonder.`}</p>
    <p>{`“It’s a pleasure to meet you sir,” Byleth said, shaking his hand.`}</p>
    <p>{`“It’s not much, and it’s already probably cold but I hope some grilled and raw seafood is agreeable with you?” Rodrigue said. Byleth squinted at the spread on the picnic cloth. Grilled?`}</p>
    <p>{`Her confusion must’ve been visibly on her face, since Rodrigue let out a laugh, “I know what you’re thinking. But I actually brought the cooked food from above. You’d be surprised at how watertight the Tupperware brand is.” Byleth’s eyes widened and she too let out a chuckle.`}</p>
    <p>{`Dimitri’s father, Lambert now having reintroduced himself again, gestured at them to sit and begin their meal.`}</p>
    <p>{`“Sorry, cold grilled octopus probably doesn’t taste very nice,” Lambert said apologetically.`}</p>
    <p>{`Byleth shook her head as she took a bite, “Oh no sir- oh wow, this delicious,” she covered her mouth with a hand as the smoky sweet flavour melted onto her tongue. Lambert let out a hearty laugh.`}</p>
    <p>{`“It’s so nice to have guests, isn’t it?” Lambert said, sipping on what looked like juice. Dimitri nodded. “This must all be very bizarre to you, Miss Byleth. I hope you’ll continue being kind to our Dimitri here. He’s a very shy and sensitive child.”`}</p>
    <p>{`“At first he was scary but it turns out he’s really nice,” she replied, “He’s been nice to me.”`}</p>
    <Ads mdxType="Ads" />
    <p>{`His father suddenly let out another loud guffaw and slapped his son on the back, causing poor Dimitri to choke. “Ahh I’m so sorry, he’s always been like this, it’s hard enough to get him to wear clothes-”`}</p>
    <p>{`“Father!” Dimitri almost squealed at his dad. Byleth let out a snort before quickly covering her mouth. This was a completely different side to Dimitri than what she saw daily.`}</p>
    <p>{`“I must also apologize for that, Miss Byleth,” Rodrigue said, “Dimitri and Felix were always close and I think he must’ve picked up some of my son's mannerisms,” he bowed his head slightly at her.`}</p>
    <p>{`She violently shook her head and urged him to raise his head, “N-No, sir, please uh, not at all, it’s just my erm...timid nature. Dimitri is kind and underneath all that scowl, Felix is very caring.” Rodrigue chuckled in response.`}</p>
    <p>{`Byleth felt incredibly flustered at the situation, she’d always been terrible at handling conversations like these and here were parents apologizing to her; it was an introvert’s nightmare.`}</p>
    <p>{`She had to turn away to let out a sigh and remember to breathe, and as she looked up, she made contact with a few pairs of eyes making contact with her. It was a group of little mermaids and she gave them a small wave. Having been caught in the act, they frantically swam away, accidentally splashing her with their gorgeous glowing tails. She couldn’t help but laugh as she used her towel to wipe her face, it seems children everywhere were similar.`}</p>
    <p>{`The conversation simmered down as Byleth finished the most fragrant salt grilled fish she had ever eaten at the insistence of Rodrigue, along with a couple slices of the freshest raw fish ever. She didn’t know if it was fish but it was `}<em parentName="p">{`good`}</em>{`.`}</p>
    <p>{`“We have a...sort of half-way house on the surface almost directly above this town, Lambert picked me up from there,” Rodrigue began when Dimitri and his father started a private conversation. “It’s also where I grilled these today in case you’re wondering, and also where many of the merfolk here come to adapt to humans before they find mates.”`}</p>
    <p>{`“Um, might I ask if there are...facilities here to use?” Byleth asked softly, having crossed her legs rather tightly. She thought it would be rude to...let it go while in the middle of Dimitri’s hometown. It would’ve been akin to peeing on the streets.`}</p>
    <p>{`“Ah yes of course, ah, it’s on the surface but I believe with Dimitri’s speed it won’t take you 5 minutes to reach,” Rodrigue said. “Dimitri, do you mind taking her to use the facilities above?”`}</p>
    <p>{`Dimitri nodded, tossing aside his towel and jumping into the water before Byleth could see anything.`}</p>
    <p>{`“In that case, can I help to clean up?” Byleth asked as she started stacking the containers up.`}</p>
    <p>{`“Oh no, no, no, no-” Lambert said. Both he and Rodrigue reached out and grabbed it away from her. “You are a guest.”`}</p>
    <p>{`“B-But sir I have been imposing, it’s the least I can do.” Lambert and Rodrigue looked at each other with what looked like a glint in their eyes.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“You know what?” Lambert said after a few seconds, “You should teach Dimitri how to do the dishes, I can tell he’s still not very good at human chores.”`}</p>
    <p>{`“Oh I’d love to, thank you sir,” Byleth said, as she put them all in the watertight box it was brought in. Dimitri was waiting for her with his arms open, so she passed the box to her but he pushed it aside.`}</p>
    <p>{`“We’re supposed to take that to the surface to clean-” Dimitri yanked her into his arms and once more pressed his lips onto hers. Byleth couldn’t resist and reluctantly admitted to herself it felt...good. She wanted more but realised there were people around and...Dimitri was only doing it because she couldn’t breathe under water.`}</p>
    <p>{`For a moment, every breath she took hurt as she remembered Dimitri saying they were only good friends.`}</p>
    <p>{`“Dimitri!” Lambert cried out, “You should always ask for consent before doing that!”`}</p>
    <p>{`“S-sorry,” Dimitri mumbled.`}</p>
    <p>{`“I apologize again for my rude son again,” Lambert flicked the back of Dimitri’s head.`}</p>
    <p>{`“Oh sir, please don’t, he’s doing it for my sake isn’t he? I-I don’t mind,” She said frantically, not wanting to get Dimitri in trouble.`}</p>
    <p>{`Rodrigue was chuckling in the background as Lambert continued, “You should treat her well, Dimitri,” Lambert winked.`}</p>
    <p>{`“S-Shut up, dad,” Dimitri sputtered, grabbing the watertight box with one hand and pulled her closer with the other.`}</p>
    <p>{`“Aww, you called me dad-” Dimitri splashed his tail at his father and quickly swam away before Lambert could finish his sentence. Dimitri tried hard to look away from her, but the blush on his face was evident.`}</p>
    <p>{`Again, she didn’t know what to expect but she certainly wasn’t expecting a beachfront hotel with a pool. Granted it was old and looked more like a chalet but it was very nice. And thankfully Byleth managed to get to the bathrooms in one piece. She was a little embarrassed for being there for quite a while since she hadn’t gone since they left the beach this morning.`}</p>
    <p>{`Dedue was with them this time on their way back after they had washed up but...it was still dead silent and dark. It’s not like Dedue, Byleth and Dimitri were the most chattiest of people to begin with anyway. But as Dimitri held her in his arms again, the warmth he was emitting, along with the calm swishing of the water and even now, despite the dangers the darkness hid, Byleth was unable to fight away the sleepiness, especially since she couldn’t tell if her eyes were open or close from the sheer darkness of the abyss they were in.`}</p>
    <p>{`She fought to stay awake, afraid to fall asleep in such darkness and underwater, she even gave herself a few quick slaps to stay awake.`}</p>
    <p>{`“...What are you doing?” Dimitri whispered in her ear.`}</p>
    <p>{`She pinched her own cheeks, “I’m trying not to fall asleep.”`}</p>
    <p>{`“...”`}</p>
    <p>{`“...”`}</p>
    <p>{`“...You should sleep. It’ll be a while more before we arrive.”`}</p>
    <p>{`“It’s alright for me to sleep underwater? I won’t drown?”`}</p>
    <p>{`“You won’t.”`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Okay.” Almost instantly, tired and heavy from the experience of the day, Byleth fell into a deep sleep.`}</p>
    <p>{`She felt gentle taps on her cheek the next second and when she opened her eyes, the area was unfamiliar to her and panic rose in her chest.`}</p>
    <p>{`“We’re back at the beach...you...should...uh...you should...change,” Dimitri pointed at the wall of rocks Dedue was removing as she sat up, pushing her hair away from her face and looked around. They were back at the beach again.`}</p>
    <p>{`Byleth groggily stepped into the cove to change, looking back to make sure Dimitri and Deude weren’t peeking. Even if she was awake, changing out of sopping wet clothes on a sandy beach inside a cove she could barely stand straight in was difficult enough.`}</p>
    <p>{`All she wanted right now was a shower and a lot of conditioner for her extremely tangled hair.`}</p>
    <p>{`“Thank you for today,” Byleth said to Dimitri on the bus, “I’ll never forget it.”`}</p>
    <p>{`Dimitri nodded, ‘I’m...glad you didn’t get scared.”`}</p>
    <p>{`“Not at all, it was an amazing experience. I’m just...a little sad I can’t tell anyone about it,” she said, feeling guilty Reus couldn’t experience it. He would’ve been equally amazed.`}</p>
    <p>{`“You....you can...talk to me about it,” Dimitri said softly.`}</p>
    <p>{`She nodded and smiled at him but he turned away and stared out the window as if he was glaring at it.`}</p>
    <p><em parentName="p">{`Oh no.`}</em></p>
    <p>{`She totally forgot to ask him to teach her how to swim.`}</p>
    <AuthorNote mdxType="AuthorNote">
  <p>
    Okay Dimitri doesn’t exactly sing in Ariel’s voice or the sample singing she
    did, but it's all I can hear in my head HAHAHAH I mean we just have to
    imagine Chris Hackney singing something HAHAH
  </p>
  <p>
    Also this scene is from Lego Friends: Girls on a Mission, but it’s where I
    got the idea of their little lunch ‘terrace’ cave thingy ^_^
  </p>
  <Image name="heart-of-the-sea.jpg" mdxType="Image" />
  <p>Here's the fanart by @AmandaOeiWells!</p>
  <Image name="dimileth-mermaid-au-fanfic.jpg" mdxType="Image" />
    </AuthorNote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      