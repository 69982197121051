import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const RemoteLink = makeShortcode("RemoteLink");
const Image = makeShortcode("Image");
const Ads = makeShortcode("Ads");
const Blockquote = makeShortcode("Blockquote");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I’ve been wanting to write about every new manga title that came out on `}<RemoteLink to="https://mangaplus.shueisha.co.jp/updates" mdxType="RemoteLink">{`MangaPlus`}</RemoteLink>{` recently but I’ve been… putting it off every now and then. Magu-chan is great, AGRAVITY BOYS is hilarious, and Ayakashi Triangle is (unexpectedly) `}<em parentName="p">{`tame`}</em>{`, etc. Each one of these new titles are really good but I wasn’t `}<em parentName="p">{`strongly compelled`}</em>{` to write about them. I’m not exactly the most dedicated blogger out there (I’m sorry). I will only write about it if I `}<strong parentName="p">{`really, really`}</strong>{` enjoy it or I have things `}<strong parentName="p">{`I want to talk`}</strong>{` about regarding the media at hand. So here we are now! I finally found a title that I really want to talk about - `}<RemoteLink to="https://mangaplus.shueisha.co.jp/titles/100110" mdxType="RemoteLink">{`Monster #8 (Kaijuu 8-gou)`}</RemoteLink>{`.`}</p>
    <Image alt="Monster #8/Kaijuu 8-gou expressive characters lol" caption="Ichikawa - :O Hibino - :O!!!!" name="monster-8-kaijuu-8-manga-5.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <p>{`The title is really simple. Like, Kaijuu 8-gou? It leaves a lot to imagination! The plot is easy to follow too. Our main protagonist Kafka Hibino is a 32 year old guy who lives in a world where kaijuu(s) are real. But the mangaka (Naoya Matsumoto) didn’t take the high page count for granted! These monsters devastate cities wherever they appear and Japan happens to have the highest outbreak ratio amongst the other countries out there (Duh!). Obviously the nation isn’t going to sit around and let these monsters trample her cities! Japan has a Defense Force dedicated solely to wipe these monsters out and they are pretty much celebs. Unfortunately, that’s not where Hibino works. He’s part of the monster cleanup team. While the heroes swoop in to save the day, he has to break his back to clean up the monster carcass. It wasn’t that he didn’t dream big. He did, and it was a promise to his childhood friend Mina. They’ll aim for the skies and join the Defense Force one day.`}</p>
    <Image alt="Monster #8/Kaijuu 8-gou double page spread 1" caption="I really like how he added perspective here! It gives a sense of size to the monster carcass. And that looming shadow! I really like the line art here." name="monster-8-kaijuu-8-manga-2.jpg" mdxType="Image" />
    <p>{`Alas, Hibino didn’t have the aptitude for it. He tried the exam many times, but the results never changed. Since then, he’s stuck cleaning up monster intestines while Mina kept moving up the ladder as a respectable Defense Force member. He’s 32 years old now so his dream is pretty much dead. Regardless, Hibino is adamant that not all hope is lost. If he can’t make it as a Defense Force, he’ll make it as part of the cleaners. His luck soon changed when he saved a young newbie from a monster. Things happened (Please read Chapter 1, I didn’t want to spoil anymore!) and Hibino is given a second chance to join the task force he admired since young.`}</p>
    <Ads mdxType="Ads" />
    <p>{`When I read Chapter 1, I was quickly pulled in by the `}<strong parentName="p">{`art`}</strong>{` and `}<strong parentName="p">{`pacing`}</strong>{`. The first chapter had 58 pages in it so you could argue that there’s plenty of pages to stuff in the plot. But the story pushed through at a decent speed. `}<em parentName="p">{`Not too fast, not too slow`}</em>{`. Things that needn’t be told are `}<em parentName="p">{`implied`}</em>{`. Things that need to be told are `}<em parentName="p">{`slipped in`}</em>{` through dialogues and character’s circumstances. The background art doesn’t overwhelm the characters, and neither do the characters look out of place where they stood. It’s probably me nitpicking but I am super biased about background art. It’s important to me that I could differentiate the character from the background. Some manga just have these two elements meld together like ink smudge.`}</p>
    <Image alt="Monster #8/Kaijuu 8-gou double page spread 2" caption="This double page spread showed the same monster from the initial pages. It was clearly dismantled halfway by the cleaning team! Them jumping out of the building with the monster at the background gives a nice sense of continuity." name="monster-8-kaijuu-8-manga-3.jpg" mdxType="Image" />
    <p>{`The panel flow is `}<strong parentName="p">{`really, really`}</strong>{` easy to `}<em parentName="p">{`follow`}</em>{`. Matsumoto doesn’t overdo his creativity at all! Each panel is done `}<em parentName="p">{`just enough`}</em>{` to tell the story. I could easily imagine a scene where each character or monster is positioned. I don’t have to `}<strong parentName="p">{`re-read a scene`}</strong>{` just to check how the whole fight scene flowed! It built up nicely as my eyes moved from one panel to another. This manga has some of the `}<em parentName="p">{`cleanest`}</em>{` yet most `}<strong parentName="p">{`detailed`}</strong>{` lineart I’ve seen. Matsumoto’s control over line art thickness is `}<strong parentName="p">{`superb`}</strong>{`. Try zooming in to appreciate how he did the clothing creases and shadow! I must say, Matsumoto has a wonderful understanding over shadow and lighting! Every scene that requires depth has an `}<strong parentName="p">{`easy-to-grasp`}</strong>{` `}<em parentName="p">{`perspective`}</em>{`.`}</p>
    <Ads mdxType="Ads" />
    <p>{`What’s even more `}<em parentName="p">{`impressive`}</em>{` that the basis of the story was quickly `}<strong parentName="p">{`wrapped up in Chapter 1`}</strong>{`. In just 58 pages, we now know Hibino's character and motive. Then there’s Ishikawa, a wonderful sidekick of his whom I’m convinced is the `}<strong parentName="p">{`best bro`}</strong>{` you could ever have. Here’s the potential heroine he’s chasing after, Mina. And here’s the current situation and what you need to know to get started for Monster #8! Woosh, on to Chapter 2. Everything is just `}<em parentName="p">{`packaged nicely into 58 pages`}</em>{` without `}<strong parentName="p">{`overdoing`}</strong>{` it. It doesn’t leave the readers thinking they know too little nor was it overloaded with information.`}</p>
    <Image alt="Monster #8/Kaijuu 8-gou manga panel 1" caption="Despite the many double page spread beauties this manga has, I like this panel the most. Here, Hibino was so shocked that he gripped the wall way too hard. The way the cracks just spreads out nicely... The detailed destruction on the ceiling, floor and the wall is really nicely done. I think the inclusion of the ceiling, wall and floor gives a wonderful three-dimensional view." name="monster-8-kaijuu-8-manga-4.jpg" mdxType="Image" />
    <p>{`I have just finished reading Chapter 8 (Latest chapter!) when I wrote this blog post and I can happily say that the art quality is as `}<strong parentName="p">{`great`}</strong>{` as it is from Chapter 1. Why is the art so good and easy to follow? The plot is so simple but why is it so gripping (it was pretty exciting for me, I can’t wait to check what’s going to happen next)? I honestly believe it’s because Matsumoto understands well what makes us readers `}<strong parentName="p">{`tick`}</strong>{`, and he polished his skill well. He knew how best to write `}<strong parentName="p">{`easy-to-understand`}</strong>{` dialogues and he knew when and what `}<em parentName="p">{`perspective`}</em>{` to draw the scenes in. Each of these just piles up to make for an enjoyable manga. Plot is one thing, but the art just makes it `}<em parentName="p">{`so easy to read it`}</em>{`!`}</p>
    <Ads mdxType="Ads" />
    <Blockquote mdxType="Blockquote">
  Sometimes the best stories out there isn’t an epic story, it’s one that
  everyone could easily jump in and enjoy.
    </Blockquote>
    <p>{`I find it strange that such an underrated mangaka could go unnoticed. Is this his first work that got this much publicity? His previous works are Neko Wappa! and Pochi Kuro which I haven’t heard of. Now I have and it’s time to read those too! I think this manga could get even bigger as more chapters roll in. I’m excited to see where it’s heading!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      