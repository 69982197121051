import PropTypes from 'prop-types';

import Releases from '../components/Releases';
import Seo from '../components/Seo';
import keywords from '../utils/keywords';

const ReleasePage = ({ location }) => (
  <>
    <Seo
      location={location}
      title="Releases | Geeknabe - ACG Blog"
      description="Keep track of Geeknabe's bug fixes and feature updates here (It's honestly more for me to reminisce on the silly coding mistakes though...)"
      keywords={keywords}
      meta={[
        {
          property: 'og:type',
          content: 'website',
        },
      ]}
    />
    <Releases />
  </>
);

ReleasePage.defaultProps = {
  location: {},
};

ReleasePage.propTypes = {
  location: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default ReleasePage;
