import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Video = makeShortcode("Video");
const LocalLink = makeShortcode("LocalLink");
const RemoteLink = makeShortcode("RemoteLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I have this bug I call the `}<em parentName="p">{`"occasional anime watcher"`}</em>{` that comes and go whenever it pleases. If there's an anime I deem OK, I'll go watch 1 episode during work's lunchtime and that's it. I then say goodbye to every single anime released during said season because the first anime I picked couldn't hold my attention. This is why my anime blog posts are so rare lately.`}</p>
    <Ads mdxType="Ads" />
    <p>{`The only thing that could turn my mood around is if said anime season has my favourite anime in it. My anime-watching momentum will then keep rolling on and before I know it, I watched every darn title that strikes my fancy. Yes, this is `}<em parentName="p">{`that anime season`}</em>{`. I'll have you know that having a steady flow of `}<strong parentName="p">{`good anime`}</strong>{` to watch during work's lunchtime is a super happy thing. They just keep coming!`}</p>
    <p>{`I usually don't go deep into reviewing each title so as usual, I dump everything into 1 blog post. These titles are really fun, do try them out if you feel like it!`}</p>
    <h2>{`Spring 2022 Anime Review #1: Ya Boy Kongming!`}</h2>
    <p>{`I first found out about this anime when it was first announced by anime news sites. Zhuge Liang in our modern world? The title didn't really grab my attention back then. When the show finally aired, my friend keeps talking about it. It's a great show, she said. She started chanting `}<em parentName="p">{`chitty chitty bang bang`}</em>{` or something in our chat real often. Alright, alright, I got the hint.`}</p>
    <Video src="https://www.youtube.com/embed/XicdpSmxuT0" title="Ya Boy Kongming!" mdxType="Video" />
    <p>{`Out of curiosity I watched the opening MV first. I thought the song is pretty catchy! I like that the art and animation is easy on the eye. So I watched the first episode and wow, I had a ton of fun <3 The premise of the show is basically (Ya Boy!) Kongming died but was reincarnated(?) with all his memories intact in modern Japan. There, he meets an aspiring singer, Eiko who just can't seem to go big. Things happened, and the first episode ends with Kongming vowing to help her achieve her dream.`}</p>
    <p>{`The voice actress for Eiko has a lovely voice. Her English pronunciation is a pass in my book! Super fluid and her enunciation is fantastic. I enjoyed the first episode's flow, especially how they introduced Kongming, Eiko and bam! The first episode did a brief overview of the whys for Kongming's presence in Eiko's life and showed us the goal they'll be tackling for the coming episodes. When the anime has a good flow in Episode 1, I know the next episode will be a good one!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Spring 2022 Anime Review #2: Ascendance of a Bookworm S3`}</h2>
    <p>{`In all honesty, I bounced back up from my anime-watching inactivity `}<strong parentName="p">{`because`}</strong>{` of this show. I love Ascendance of a Bookworm! I read the light novel `}<LocalLink to="/blog/ascendance-of-a-bookworm-light-novel-review/" mdxType="LocalLink">{`up to Part 3 Volume 3`}</LocalLink>{` as of this writing, and I'm planning to get the rest of the series. Apparently the series will end within 30+ volumes... At least, that's what the author is hinting at in an `}<RemoteLink to="https://animecorner.me/ascendance-of-a-bookworm-light-novel-is-nearing-its-final-arc/" mdxType="RemoteLink">{`interview`}</RemoteLink>{`.`}</p>
    <Video src="https://www.youtube.com/embed/Z_hgZN_RBq8" title="Ascendance of a Bookworm S3" mdxType="Video" />
    <p>{`When it comes to super long fantasy stories, there are a few things that keeps egging you to read on. Maybe you wanted to know what's the mystery the author introduced in the first book, or perhaps you wish to see a happy ending for your favourite character! But when it comes to Ascendance of a Bookworm, I already know what's going to happen next, albeit roughly. And I'm not really into reading Myne's happy ending, because she will surely get one. So what's `}<em parentName="p">{`egging`}</em>{` me to read on?`}</p>
    <p>{`It's the comfort of steady progression that makes Myne's story so soothing to read. She improved her family's life, helped others do the same and continue to climb up the social ladder. Honestly, that sounds boring on paper but it's exactly for that reason that the fans are watching the show. Season 3 is no different! Same ol' Myne, but with a dash of danger this time!`}</p>
    <p>{`I noticed they improved the uh, `}<em parentName="p">{`"fps"`}</em>{` of the animation. Art is more detailed too. Feels like they pumped up the in-between frames! Obviously that's not the proper term here, but you get what I mean, ya? Things will continue to ramp up from S3, and I'm here to stay <3 Please please please do S4 real soon!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Spring 2022 Anime Review #3: Kaguya-sama: Love is War -Ultra Romantic- (S3)`}</h2>
    <p>{`I had the most unfortunate experience of having my windows open while I binged 3 episodes from Kaguya-sama S3. You see, when the show is hilarious, I do a really, really hideous guttural laugh. You know, the laugh you do when your friend told a spontaneous joke, and it hits hard. `}<em parentName="p">{`I am so, so sorry, dear neighbours that you had to listen to my ghastly WAHAHA(s)...`}</em></p>
    <Video src="https://www.youtube.com/embed/cxTxrKrYkcY" title="Kaguya-sama: Love is War -Ultra Romantic- S3" mdxType="Video" />
    <p>{`Kaguya-sama is such a fun rom-com. I'm putting this show right next to Ouran High School Host Club as part of the `}<strong parentName="p">{`Memorable Rom-Com Anime of All Time`}</strong>{`. Except Kaguya-sama actually got not one, not two, `}<strong parentName="p">{`BUT THREE SEASONS`}</strong>{` and potentially more! Urgh, why won't they give Ouran the same treatment!`}</p>
    <p>{`Jokes in Kaguya-sama just hits the right spot for me. These are just my personal imho, but I think the show is one-of-a-kind because...`}</p>
    <ol>
      <li parentName="ol">{`Each "battle" between Shinomiya and Shirogane adds a high-risk tension, and you'll always know who "won" within 15 minutes of the episode. Gets your heart beating and settling down within minutes, literally.`}</li>
      <li parentName="ol">{`The characters' silliness hits close to home. Modern too! I'm sure you have one or two friends who love playing and coming up with board games? What about someone who prefers Discord to messaging apps?`}</li>
      <li parentName="ol">{`The minor first world problems the show comes up with is often hilarious because I too, experienced that before. The episode where Shinomiya discovered the "Seen" status feature is pretty much my experience when I try explaining that to my friends xD`}</li>
    </ol>
    <p>{`S3 is basically a repeat of S1 and S2. I do follow the manga for a while, but I have since forgotten the plot. Here's hoping S3 is when Shinomiya and Shirogane finally confesses!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Spring 2022 Anime Review #4: The Executioner and Her Way of Life`}</h2>
    <p>{`When it comes to isekai shounen manga, I am super confident `}<em parentName="p">{`I have read them all`}</em>{`. At least, the popular ones! So when this title got animated, I was pleasantly surprised. I've since labelled it as `}<em parentName="p">{`"not bad, but probably won't get attention from the mainstream folks"`}</em>{` when I first read it. The manga only reached the part where the train scene happened when I last left it, so I went in blind. My verdict? It's adapted really well!`}</p>
    <Video src="https://www.youtube.com/embed/6ixSWvF7L-A" title="The Executioner and Her Way of Life" mdxType="Video" />
    <p>{`The Executioner had a unique plot amidst all the isekai manga. First things first, the highlight is on the people in the fantasy world, not the Japanese folks who got transported over. There's no harem, no elves, no soulful journey to discover rice or soy sauce... Everything's already been done. These interdimensional travelers (usually students) have unstable powers that are capable of wiping out continents. So they were offed before they reach that stage.`}</p>
    <p>{`Our protagonist is Menou, the "executioner" who is in-charge of murdering these otherworldly travelers. We see her went on her way to kill her next target, Akari (the deuteragonist). Things were going smoothly, but Akari simply can't be killed. Thus Menou have no choice but to bring the unsuspecting Akari to where she can be disposed of for good.`}</p>
    <p>{`The first episode hints at us that Menou and Akari may be acquainted before this interdimensional shenanigan. Even before I jumped into Episode 4, plenty of hints were thrown around already. The four Human Error(s) (calamities) that greatly harmed this fantasy world, the magic (Ether) powering up almost every technology (Seems a little dangerous to rely on one source of power, no?)... I'm of the opinion the two girls' relationship may evolve to something unusual. Maybe Akari doesn't die in the end? I have no clue what's going to happen next and that's good.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Spring 2022 Anime Review #5: SPY x FAMILY`}</h2>
    <p>{`I can proudly say I've been reading SPY x FAMILY since the manga first published in `}<RemoteLink to="https://mangaplus.shueisha.co.jp/titles/100056" mdxType="RemoteLink">{`MangaPlus`}</RemoteLink>{`. I knew this will be popular if it got animated! Now I'm waiting for Monster #8 to get the same treatment :D`}</p>
    <Video src="https://www.youtube.com/embed/U_rWZK_8vUY" title="SPY x FAMILY" mdxType="Video" />
    <Video src="https://www.youtube.com/embed/N3uWjNpOoyU" title="SPY x Family" mdxType="Video" />
    <p>{`But the one thing I couldn't predict... is how well it translates into an animated piece. Scenes that didn't impress me as much in the manga gave me a strong impact in the anime. And most importantly... Anya is `}<em parentName="p">{`much, much cuter`}</em>{` in the anime. I think it's how her voice actress delivered her concise and blunt dialogue.`}</p>
    <p>{`Apparently this show was greenlit to have 25 episodes. Hurray! More Anya scenes, BANZAI!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`All in all`}</h2>
    <p>{`Life is good when there's 5 anime to catch up on. I was browsing Anilist to see if I missed any Spring 2022 anime and voila! I did miss one, and that's Tiger & Bunny S2. Wow, I can't believe I get to watch S2 after a decade of waiting... But if Tiger & Bunny got a S2 after a decade, where's Ouran's S2? Princess Tutu S3? Gakuen Alice S2? Hello? ):`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      