import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const LocalLink = makeShortcode("LocalLink");
const Image = makeShortcode("Image");
const RemoteLink = makeShortcode("RemoteLink");
const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I wrote this `}<strong parentName="p">{`because`}</strong>{` ever since the COVID-19 lockdown started, my close friends and families have approached me for anime recommendations. `}<em parentName="p">{`“What anime is good on Netflix?”`}</em>{` or `}<em parentName="p">{`“Where can I watch anime?”`}</em>{` are one of the popular questions they’ll drop on Whatsapp or Messenger. Is that the only reason you guys look for me? I’m sad now… (Kidding, we are on good terms.)`}</p>
    <Ads mdxType="Ads" />
    <p>{`I find it really funny because these folks are usually the ones who would watch k-drama, TVB drama and never touch any anime. It turns out that many`}<em parentName="p">{` changed their perception`}</em>{` on anime after seeing how good Your Name movie is, or after watching anime with their close friends. Good. This is how society should progress.`}</p>
    <p>{`But I can’t remember all of the anime I watched on Netflix, and I never bothered to make a list so I could share them immediately. So I decided to just write a blog post so I could share it with them. I’ll just say, `}<em parentName="p">{`“Here, refer to this blog post for recommendations instead!”`}</em>{` to them. So here’s 10 TV anime series and 10 anime movie recommendations. Chatting with my friends also gave me an idea for a silly blog post here - `}<LocalLink to="/blog/4-types-of-casual-anime-fans/" mdxType="LocalLink">{`What type of casual anime fans are out there`}</LocalLink>{`?`}</p>
    <p>{`IMPORTANT NOTE: I noticed that many thought this is a list for family friendly shows. Sorry, I meant it in a way that these are shows you could suggest to your friends and family that `}<strong parentName="p">{`rarely watches anime`}</strong>{`! So the cliches and plot are those that anyone would understand right away. Thus, this is the reason you won't find Kakegurui or Shokugeki no Soma here. Like, those are shows for hardcore anime fans because they could accept the weirdness.`}</p>
    <p><em parentName="p">{`Note: I made sure to include anime that you could readily find on Netflix (at the time of writing). If you have a subscription and found it on Crunchyroll, Hulu or whatnot, then great! But I only have Netflix so I’ll base my recommendation list on what’s available on Netflix instead. Sorry!`}</em></p>
    <p><em parentName="p">{`Second Note: Summaries are less dense than what I used to write because I know some folks dislike like long summaries or spoilers. I assure you, these anime on this list got my 100% approval!`}</em></p>
    <Ads mdxType="Ads" />
    <h2>{`Recommended TV Anime Series on Netflix #1: Violet Evergarden`}</h2>
    <p>{`Blessed is for us anime fans, for this is the most `}<em parentName="p">{`beautiful`}</em>{`, well done and `}<strong parentName="p">{`perfect `}</strong>{`anime on exploring the themes of love. You follow Violet Evergarden, a child soldier who had an empty past until it was painted in by Gilbert Bougainvillea. Gilbert is a soldier enlisted in the Leidenschaftlich army and he was told to use Violet as a tool to win the war. The final battle happened and Gilbert went MIA while Violet lost her arms. In order to recuperate and live as a civilian, a good friend of Gilbert took her in as an Auto Memories Doll at his postal company.`}</p>
    <Image alt="Violet Evergarden The Movie screencap 1" caption="It's such a joy to watch the detailed animation in Violet Evergarden." name="violet-evergarden-eternity-and-the-auto-memory-doll-10.jpg" mdxType="Image" />
    <p>{`Auto Memories Doll (Doll) is a job that assists others by writing letters for them. As a child soldier, Violet is oblivious to social settings and feelings in general. You watch her develop and grow her emotions as she helps write letters to join the hearts of estranged siblings, a dying mother, and even a despondent princess.Watch the TV Series first (titled Violet Evergarden), then watch the special episode (titled Violet Evergarden: Special), followed by the movie (titled Violet Evergarden: Eternity and the Auto Memories Doll). I once did a blog post on`}<LocalLink to="/blog/violet-evergarden-eternity-and-the-auto-memory-doll-is-the-epitome-of-what-kyoto-animation-does-best/" mdxType="LocalLink">{` Violet Evergarden screenshots`}</LocalLink>{`! `}<RemoteLink to="https://www.netflix.com/my-en/title/81208936" mdxType="RemoteLink">{`Watch the show here!`}</RemoteLink></p>
    <Ads mdxType="Ads" />
    <h2>{`Recommended TV Anime Series on Netflix #2: Carole & Tuesday`}</h2>
    <p>{`If you enjoy English pop songs or acapella, then surprise! You’ll enjoy this anime too. Follow two girls who love to sing as they rise from a nobody to stardom. I’ll add my favourite songs below if you’re undecided to watch this show. I love it! `}<RemoteLink to="https://www.netflix.com/my-en/title/80992137" mdxType="RemoteLink">{`Watch the show here!`}</RemoteLink>{` I added a video of the two girls singing! Yup, they got singers to sing the songs and voice actors to voice the characters. There's like, 20 songs or more in this show?`}</p>
    <Video title="carole and tuesday" src="https://www.youtube.com/embed/vgmJoRvnZkI" mdxType="Video" />
    <Ads mdxType="Ads" />
    <h2>{`Recommended TV Anime Series on Netflix #3: Cautious Hero`}</h2>
    <p>{`There’s this ongoing cliche in the anime world right now, where you have this normal Japanese person summoned into a fantasy world. Upon arrival, they are forced to become a hero to defeat a demon lord before they can return home. Cautious Hero is using that cliche but this time, the goddess is also added to the hero’s party!`}</p>
    <p>{`Follow Seiya, the hero in question of this anime’s title. He was summoned by Ristarte, the Goddess of Healing to save the world of Gaeabrande from a demon lord. Everything seems fine until you notice that Gaeabrande had a difficulty of S, the hardest difficulty there is! How will a newbie hero save a world with an S-class difficulty? Well, he goes really hard on the cautious part. He makes sure to incinerate an enemy so it’s deader than dead. He trains himself twice as hard in order to overcome his enemies. The show is extra funny, extra hilarious and had some jokes that came out of nowhere. There’s a wonderful plot twist at the end which makes the whole trip to the anime’s ending even better. Watch it. NOW. `}<RemoteLink to="https://www.netflix.com/my-en/title/81196818" mdxType="RemoteLink">{`And do it here!`}</RemoteLink></p>
    <p><em parentName="p">{`Note: Some folks have approached me to tell me that maybe this show isn't supposed to be here due to some naughty scenes. I admit, I was so desensitized by anime that these scenes are hilarious to me instead of screaming R-18/Mature. So I'm leaving this as a fair warning! There's some funny but naughty scenes! Beware!`}</em></p>
    <Ads mdxType="Ads" />
    <h2>{`Recommended TV Anime Series on Netflix #4: Sakura Wars The Animation`}</h2>
    <p>{`This anime is based on a video game! The original video game had 5 girls as capture targets that your avatar (a boy) could pursue. There’s a ton of dialogue, plot and action in the original game. I wish it’s not only on Playstation… I added the opening below, here's hoping you like what you see!`}</p>
    <Video title="sakura wars the animation" src="https://www.youtube.com/embed/z4RrdkippGw" mdxType="Video" />
    <p>{`Sakura Wars is set in the 1940s where demons are real, born from darkness in human hearts. Humanity has set up an organization in each capital to protect themselves. However, these soldiers aren’t men at all. They are girls who could pilot a steam-based mecha powered by spiritual energies. I don’t think too deeply at the lore because the plot usually boils down to one baddie the good characters need to defeat. It just so happened that the good characters are all ladies which makes it fun to watch when they bicker or have fun!It’s a solid trip within 12 episodes. If you enjoy fantasy shows/girl power shows, this is for you! `}<RemoteLink to="https://www.netflix.com/my-en/title/81266425" mdxType="RemoteLink">{`Watch it here!`}</RemoteLink></p>
    <Ads mdxType="Ads" />
    <h2>{`Recommended TV Anime Series on Netflix #5: Zombieland Saga`}</h2>
    <p>{`While Sakura Wars is all about fantasy, fighting and girl power… Zombieland Saga is on crack. I mean, sure, the main cast are all girls but this show is crazy fun rather than the focus being on girl power. I added my favourite rap battle scene below <3`}</p>
    <Video title="zombieland saga" src="https://www.youtube.com/embed/XOcERX0MzCE" mdxType="Video" />
    <p>{`Zombieland Saga took place in the Saga prefecture in Japan. Sakura is a normal high school girl who wanted to become an idol. She set off to submit her idol application but was abruptly killed by a truck. The next time she opened her eyes, she’s a zombie along with 6 other zombie girls. These girls hail from different eras and each one is famous in their own right! They were brought back to life by a man named Kotaro Tatsumi. The reason? Revitalize Saga prefecture with an all-zombie idol group.`}</p>
    <p>{`See? This show is on crack. `}<RemoteLink to="https://www.netflix.com/my-en/title/81145695" mdxType="RemoteLink">{`Watch the show here!`}</RemoteLink></p>
    <Ads mdxType="Ads" />
    <h2>{`Recommended TV Anime Series on Netflix #6: Rilakkuma and Kaoru`}</h2>
    <p>{`I’m sure everyone knew of Rilakkuma, correct? It’s a teddy bear whose name is a combination of relax + kuma (bear in Japanese). You’re supposed to look at this teddy bear and go, oh~ I feel relaxed~ Thus, the point of this stop-motion animation show “Rilakkuma and Kaoru”.`}</p>
    <p>{`Kaoru is an office lady in her thirties who lives with Rilakkuma, Korilakkuma and Kiiroitori. No questions were asked about why 2 teddy bears and 1 chick moves as though they understand human language. That’s not the point here. The show just wants you to watch Kaoru live her own life with teddy bears and you know, relax~ `}<RemoteLink to="https://www.netflix.com/my-en/title/80196883" mdxType="RemoteLink">{`Watch kuma here!`}</RemoteLink></p>
    <Ads mdxType="Ads" />
    <h2>{`Recommended TV Anime Series on Netflix #7: Castlevania`}</h2>
    <p>{`Castlevania is one of my favourite shows out there, animated by a western studio. In fact, I believe it’s so good `}<strong parentName="p">{`because `}</strong>{`it’s animated by a western studio. There are so many creative ideas and thoughts that go into this show which separates the quality from Japanese animation. It’s… so different. Yes, you may have noticed that Castlevania is a name of a game (created by a Japanese studio “Konami”, ironically) but if I’m not mistaken, the anime is doing their own thing but borrows Castlevania elements and characters.`}</p>
    <Video title="castlevania" src="https://www.youtube.com/embed/Kbb8zPQBmOw" mdxType="Video" />
    <p>{`Castlevania’s story starts with Count Dracula, a vampire who wishes to massacre humanity after his wife was burned at the stake for being falsely accused of witchcraft. A monster hunter, a magician and the Dracula’s son banded together to stop his heinous acts. Lots of action, witty dialogue and fast-paced plot!`}<strong parentName="p">{` Best watched in English dub`}</strong>{`. Be warned, lots of f`}{`*`}{`cks thrown around. There’s 3 seasons altogether and Season 4 is in the works! `}<RemoteLink to="https://www.netflix.com/my-en/title/80095241" mdxType="RemoteLink">{`Watch the show here.`}</RemoteLink></p>
    <p><em parentName="p">{`Warning: While I say all shows in this list are those you can share with friends and families, this one is on the gory side. I added it here because it is very mature, really great and those without anime experience could still appreciate it! Of course, 18 and above only!`}</em></p>
    <Ads mdxType="Ads" />
    <h2>{`Recommended TV Anime Series on Netflix #8: Aggretsuko`}</h2>
    <p>{`At a first glance, it looks cute. Cuddly, even. I first went in with a positive outlook that this anime is that fluffy and happy type. Alas, I was mistaken. My expectations were dashed, rebuilt and soared by the time I finished Season 3. This is an amazing show for young adults.`}</p>
    <Video title="sanrio aggretsuko" src="https://www.youtube.com/embed/1n3xXuEyr40" mdxType="Video" />
    <p>{`Aggretsuko, as you noticed, has character designs by Sanrio, the same company that owns Hello Kitty. Aggretsuko follows Retsuko, a 25 year old single accountant lady who works under the worst boss and the noisiest colleagues. You can say that all she knew is work. Work, work, work. Worst still, she couldn’t say No to her supervisors as they lambasted her with more work. Anyone would break under these circumstances but not Retsuko. After work, she’ll go to her favourite karaoke bar and sing out her heart with death metal. But should this situation of hers continue like this?If you’re a young adult who lives away from home for your job, lives in a rented room and has nothing going in their life except your job, then this is for you. Seriously, lots of interwoven messages in this show. It’s so good! When is Season 4? `}<RemoteLink to="https://www.netflix.com/my-en/title/80198505" mdxType="RemoteLink">{`Watch Retsuko here!`}</RemoteLink></p>
    <Ads mdxType="Ads" />
    <h2>{`Recommended TV Anime Series on Netflix #9: Cells at Work`}</h2>
    <p>{`This is that one show all parents would approve of. I mean, an anime about cells in a body? Biology stuff? Oh wow, who’s the genius who crafted a Biology textbook better than my country’s Biology syllabus?`}</p>
    <p>{`You explore an anonymous body through the eyes of a Red Blood Cell character. The RBC character would transport oxygen to cells throughout the body and along the way, she would encounter viruses/bacteria who would attack the body. That is when the White Blood Cell characters would show up! The narrator would explain what’s going on with the body as well as the condition the body is experiencing. The narration actually makes the whole show really fun to watch! `}<RemoteLink to="https://www.netflix.com/my-en/title/81028791" mdxType="RemoteLink">{`Watch your cell's at work (pun intended) here!`}</RemoteLink></p>
    <Ads mdxType="Ads" />
    <h2>{`Recommended TV Anime Series on Netflix #10: Dr Stone`}</h2>
    <p>{`I was about to recommend another title, but my cousin told me how much she enjoyed this show! If she is okay with it, I guess the general public should be okay with this title too.`}</p>
    <p>{`Dr Stone is a science show, but with an anime twist. All of humanity was turned to stone in the year of 2038. Nobody knows why and nobody could find out why, because they are all stone now. 3,700 years later, a science genius named Senku broke out of his petrified state and started working on a cure. 6 months later, Taiju broke out of his petrified state too and joined his best friend’s quest to find a cure. Thus starts the journey to revive humanity!`}</p>
    <p>{`Since these guys are all stone statues for 3,700 years, all buildings and progress humanity made are all lost. Senku and Taiju now had to work their way up by doing everything manually. There’s a ton of action going on later but in the end, it’s all about science! You see Senku explaining what they need to build to progress the story which is pretty cool. They made a waterwheel, a vacuumed light bulb, electricity and even ramen! Every invention they did got a short explanation by a narrator which helps the viewer to understand the process behind the invention. Cool stuff all in all.`}<RemoteLink to="https://www.netflix.com/my-en/title/81046193" mdxType="RemoteLink">{`Watch a teen scientist at action here!`}</RemoteLink></p>
    <Ads mdxType="Ads" />
    <h2>{`Recommended Anime Movie on Netflix #11: The Wind Rises`}</h2>
    <p>{`There’s a nationalistic theme going on in this Ghibli movie but if you’re okay with that, then this movie is a wonderful romance movie. Follow Jiro Horikoshi’s life as he works hard to design a better Zero Fighter airship for the Empire of Japan during World War II. I didn’t look too deep into the airship design plot too much since I’m enamored by the romance subplot. The couple’s relationship progress is really natural and full of… love.`}</p>
    <p>{`It’s pretty nice all in all, watching the children grow up with fond memories of their childhood and how they connect it back to how it shapes their adulthood. `}<RemoteLink to="https://www.netflix.com/my-en/title/70293674" mdxType="RemoteLink">{`Watch the movie here!`}</RemoteLink></p>
    <Ads mdxType="Ads" />
    <h2>{`Recommended Anime Movie on Netflix #13: Paprika`}</h2>
    <p>{`What if… you could dream within a dream? Okay, maybe that’s a bit on the nose if you watched Inception but Paprika isn’t about jumping into dreams and planting ideas. Paprika starts off by explaining about the DC Mini, a device that allows you to view people’s dreams. DC Mini was invented to help psychiatric patients but of course, it got abused. It is up to Dr. Atsuko Chiba to wrestle back control by assuming her dream world alter-ego, Paprika.`}</p>
    <Video title="paprika" src="https://www.youtube.com/embed/yn7U1KIGeuQ" mdxType="Video" />
    <p>{`The visuals are one of the most hallucinatory-inductive ever. It’s like I’m watching a movie made while the animators are high and it makes you high as well. You know what? I think I’ll add the trailer to explain Paprika and call it a wrap. Paprika is definitely good stuff.`}</p>
    <RemoteLink to="https://www.netflix.com/my-en/title/70065105" mdxType="RemoteLink">
  Watch Paprika here!
    </RemoteLink>
    <Ads mdxType="Ads" />
    <h2>{`Recommended Anime Movie on Netflix #14: Mirai`}</h2>
    <p>{`Movies gracing the cinema are usually blockbusters and highly exciting. Explosions and car chasing scenes are a must in these movies. Then there’s romance movies where it either breaks your heart or sends it soaring. So it’s super rare to see a movie so unflinching when doing a plot and visual so out of the norm like Mirai. How can the director be so sure that the general public will be able to grasp the message behind this movie?`}</p>
    <p>{`llow Kun as he plays with his imagination while his parents are busy tending to his newly born sister, Mirai. There’s really not much to talk about since you gotta watch it to appreciate the trippiness. All in all, Mirai is a wonderfully artistic movie! It’s basically a love letter to go back to making arty movies. I think folks who enjoy arty movies will love this one!`}</p>
    <RemoteLink to="https://www.netflix.com/my-en/title/81004268" mdxType="RemoteLink">
  Watch Mirai here!
    </RemoteLink>
    <Ads mdxType="Ads" />
    <h2>{`Recommended Anime Movie on Netflix #16: A Whisker Away`}</h2>
    <p>{`I think A Whisker Away wanted to replicate the success of Your Name by trying to bring back the vibe, but A Whisker Away is still good at doing its own thing. It’s a not-too-bad movie that you can watch and call it a day.`}</p>
    <p>{`Follow Miyo, a girl who does not get along with her stepmother. She goes out of her way to flirt with Hinode, the boy she likes at school. One day, she received a magical mask from a mask seller. When she wears the mask, she can transform into a cat. Using her newfound powers, she approached Hinode as Taro, a cat Hinode is fond of. But little did Miyo know, there’s a price to pay for the power she received.The movie had a wonderful idea and animation when it comes to animating Miyo as a cat. The latter part of the movie did a 180 and gave off a different vibe altogether. The movie is still fun nevertheless! Good movie all in all. `}<RemoteLink to="https://www.netflix.com/my-en/title/81281872" mdxType="RemoteLink">{`Watch it here!`}</RemoteLink></p>
    <Ads mdxType="Ads" />
    <h2>{`Recommended Anime Movie on Netflix #17: Children who Chase Lost Voices`}</h2>
    <p>{`By now you should be aware of the name Makoto Shinkai, the dude who did a ton of wonderful movies. Yup, Children who Chase Lost Voices is also a movie by Makoto Shinkai. This one had a totally different vibe than the thoughtful and mature mood that he loves to do.`}</p>
    <Video title="children who chase lost voices" src="https://www.youtube.com/embed/7TdR4Wxcays" mdxType="Video" />
    <p>{`Asuna is a young girl who was forced to grow up quickly after her father died. She spends most of her days alone by listening to a receiver her father gave to her as a memento. One day, she was attacked by a monster but was saved by a mysterious boy named Shun. Shun tells Asuna that he is from a country called Agartha. The next day, Shun was found dead in the river. Heartbroken by this news, Asuna enters Agartha with the help of a boy who looks like Shun in order to see Shun again. It’s a nice movie with the theme “accepting goodbyes as they come”. Please watch it! I highly recommend it.`}<RemoteLink to="https://www.netflix.com/my-en/title/80104167" mdxType="RemoteLink">{` Watch the movie here!`}</RemoteLink></p>
    <Ads mdxType="Ads" />
    <h2>{`Recommended Anime Movie on Netflix #18: 5 Centimeters Per Second`}</h2>
    <p>{`I have not finished this movie yet (at the time of writing) but the first 5 minutes alone is giving me Makoto Shinkai vibes. Wait, the same dude directed this? Hell yea, my anime experience has finally paid off!`}</p>
    <p>{`Two good friends, Takaki and Akari were separated after Akari moved away. They kept in touch by writing letters in one of the most melancholic presentations I’ve seen. We see Takaki growing up and yet the distance makes his heart grow fonder. I think my summary couldn’t do this movie justice, you seriously have to watch it to appreciate how Makoto Shinkai did the whole “distance makes you grow fonder” theme because wow, story makes the movie but presentation is important too. `}<RemoteLink to="https://www.netflix.com/my-en/title/70093699" mdxType="RemoteLink">{`Watch the movie here.`}</RemoteLink></p>
    <Ads mdxType="Ads" />
    <h2>{`Recommended Anime Movie on Netflix #19: Princess Mononoke`}</h2>
    <p>{`Maybe I should just dump all Ghibli movies into a “Watch on Netflix” list and call it a day, but I wanted to be super particular at the type of Ghibli movies I wish to add in this list! So I chose my favourite and will-never-go-wrong Princess Mononoke.`}</p>
    <Video title="princess mononoke" src="https://www.youtube.com/embed/4OiMOHRDs14" mdxType="Video" />
    <p>{`This movie has an environmental theme going on with wonderful animation to behold. Follow Ashitaka as he travels to find a cure for his cursed arm. He met San, a human girl who’s part of the Wolf Clan. In this world, gods are real and are usually protectors of an area. A village was chopping down trees to power their furnaces to make guns, which angered the Wolf Clan. So here we have Ashitaka confronting the Wolf Clan while trying to cure his arm. It’s a family friendly movie that anyone would enjoy! Please give this movie a try if you have not watched it yet. You could try `}<RemoteLink to="https://www.netflix.com/my-en/title/60023642" mdxType="RemoteLink">{`Spirited Away`}</RemoteLink>{` too if you enjoy Princess Mononoke. `}<RemoteLink to="https://www.netflix.com/my-en/title/28630857" mdxType="RemoteLink">{`Watch Mononoke here!`}</RemoteLink></p>
    <Ads mdxType="Ads" />
    <h2>{`Recommended Anime Movie on Netflix #20: When Marnie was there`}</h2>
    <p>{`I was going to recommend another movie but When Marnie was there gave me a strong bittersweet vibe that I think any viewers would enjoy.`}</p>
    <p>{`Anna, our protagonist has asthma so the doctor suggested that she stayed in a rural area with fresher air. Her parents sent her to stay with her relatives in a town in Kushiro wetlands, Hokkaido. Anna went spelunking and found an abandoned mansion across a salt marsh. Afterwards, she met Marnie, a mysterious girl who invited Anna to the abandoned mansion for a party. It turns out that Anna dreamt the entire sequence and Marnie isn’t real. Just who is Marnie?Without going into spoiler territory, the ending is wholesome. It’s not a creepy movie, don’t worry! `}<RemoteLink to="https://www.netflix.com/my-en/title/80036398" mdxType="RemoteLink">{`Watch it here.`}</RemoteLink></p>
    <Ads mdxType="Ads" />
    <h2>{`All in all`}</h2>
    <p>{`I hope these are more than enough to satiate your appetite for anime on Netflix! Enjoy watching them all!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      