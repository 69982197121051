import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const RemoteLink = makeShortcode("RemoteLink");
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const Blockquote = makeShortcode("Blockquote");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`As an avid sitter/cross legger in front of computer screens, I couldn’t stress this enough - you need a good chair to sit on comfortably for long hours. Then comes the next question… What makes a chair `}<em parentName="p">{`good`}</em>{`? A chair is a good purchase if your back doesn’t hurt after long hours? `}<strong parentName="p">{`Nah, there’s more to that! `}</strong>{`Your bottom shouldn’t be sore, your armrest should be comfortable for your height and most importantly, your back and neck should feel well rested even after long hours on the chair.`}</p>
    <p>{`Note: `}<RemoteLink to="https://ttracing.my/" mdxType="RemoteLink">{`TT Racing`}</RemoteLink>{` is a Malaysian gaming chair store based in Ipoh. `}<RemoteLink to="https://goo.gl/maps/9SNych4LqzNeeg4w6" mdxType="RemoteLink">{`They have a warehouse where you can try the chairs yourself`}</RemoteLink>{`! I linked the location so feel free to visit.`}</p>
    <Ads mdxType="Ads" />
    <p>{`I used to have this comfortable executive chair that I love sitting on, but the foam padding gets thinner by the day until I have to add cushions to feel comfortable. That won’t do, I thought. So I got myself a `}<strong parentName="p">{`TT Racing Swift x 2020`}</strong>{` gaming chair. I’ve been sitting on it for 3 months now, and I think I am ready to write a review on it. This review is not sponsored.`}</p>
    <p>{`I got the chair in December 2020 when they were running a `}<RemoteLink to="https://shopee.com.my/ttracing_my" mdxType="RemoteLink">{`massive sale on Shopee`}</RemoteLink>{`. I had to wait about 3 weeks before the chair arrived on my doorstep. Apparently their backlog is so long, some folks had to wait until January to get their chair! And when the chair arrived… Boy, the box is so big and heavy! I had to take out the contents and move them one by one to my room. Assembling it is easy enough even for a beginner like me. Tools were provided along with an instruction manual which had more images than text to guide you. Bolts are already added to their positions so all you need to do is just follow the instructions and voila, you have a gaming chair!`}</p>
    <Image alt="TT Racing Swift x 2020 gaming chair review photo 1" caption="Surely you jest, how big can the box be? Ehe... I put it upright but if you lay it flat, it's huge." name="tt-racing-swift-x-2020-review-7.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <p>{`I chose the one where it has the `}<RemoteLink to="https://ttracing.my/products/ttracing-swift-x-2020-gaming-chair?variant=32254960009278" mdxType="RemoteLink">{`TT Racing logo all dark with no stripes`}</RemoteLink>{`. I personally think it looks pretty nice. Many gaming chairs out there have gaudy stripes (I’m sorry, stripe fans) so I appreciate this design in particular! It’s all black and you wouldn’t mind having this chair in any setting!`}</p>
    <p>{`My initial impression when I first handled the parts individually is, to be fair, `}<strong parentName="p">{`not that good`}</strong>{`. While the cushion itself is great, the finish on the plastic mold is not done well at all. You can see bubbles here and there, and the edges are rough to the touch. If you’re not picky like me, you probably wouldn’t mind but the finish is a sad reminder that I got a “budget” gaming chair. I mean, have you seen the prices of most gaming chairs?! These chairs better have every part smooth to the touch! Nevertheless, TT Racing Swift x 2020 is honestly a great gaming chair for the price. Here’s why.`}</p>
    <Image alt="TT Racing Swift x 2020 gaming chair review photo 2" caption="See the little bubbles? ): There's even more parts that has these little bubbles on it. The edges are rough too, as though they didn't do quality check on them." name="tt-racing-swift-x-2020-review-2.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`It’s really comfy`}</h2>
    <p>{`I never owned a gaming chair before due to its exorbitant price and hearsays. I heard many folks tell me how the faux leather started peeling before long, or the foam goes out of shape really fast. For that price, you’d honestly expect something more lasting, amirite? So I stayed away from gaming chairs for a long time… until I tried sitting on one. Boy oh boy, I knew I should have tried it first before judging! Prices be damned!`}</p>
    <p>{`3 months in, the foam barely lost its shape and is still thick as the day I first got it. Of course I couldn’t comment on the faux leather peeling since it is an eventuality waiting to happen (It’s just a matter of when!) but so far, nothing bad has happened to my chair. Still feels pretty new!`}</p>
    <p>{`I was skeptical of how a straight backrest that does not follow your back curvature could do for comfort at first but now, I’m convinced folks who designed a gaming chair knew what they were doing. Like wow, my back barely hurts anymore. My old executive chair had this really soft foam padding that followed the shape of my back after months of sitting on it. It was comfy at first but the novelty quickly wore off when the padding got thinner. I could feel the wooden plank if I did not add cushions on my executive chair! Now I see why gaming chairs had to use cold cure foam to maintain its shape and thickness. Basically, the thicker and the better it is at maintaining the shape, the comfier it is to sit on it. No wonder my old folks could sit on wooden chairs for hours… Ever since I got my Swift x 2020, my butt, back, neck and shoulder never feels sore anymore after long hours of sitting. When I say long, I mean at least 8 hours.`}</p>
    <Ads mdxType="Ads" />
    <p>{`TT Racing Swift x 2020 comes with a head pillow and a lumbar which I highly recommend! If you’re 165cm like me, then your neck will rest on the head pillow nicely if you sit up straight. The lumbar is there because without it, your back won’t feel as comfortable as you should!`}</p>
    <h2>{`Everything is adjustable`}</h2>
    <p>{`The armrest doesn’t need to be adjustable but it is. And I appreciate it for that! There are three adjustable parts on this gaming chair, and that is the armrest, the backrest and the chair’s height. Being able to adjust the height of the chair is a given whereas adjusting the backrest is a must feature for a gaming chair. Armrest on the other hand, is a nice addition to have! You could adjust it to be as high as possible to support your arm while you type, or push it all the way down so it doesn’t block your arms flailing around.`}</p>
    <p>{`You can pull up your back rest to be 90 degree straight up, or go all the way down to about 165 degree recline. I tried adjusting it all the way down so I could lie on it and yes, the lowest recline is good enough to get a decent nap on your chair.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Rolling around is a smooth experience`}</h2>
    <p>{`I couldn’t stress this enough but having rollers that could roll around smoothly is a personal criteria for me. So yes, TT Racing Swift x 2020 has passed the test. The second thing I noticed about the base is that there’s this smooth part they added which makes for a nice part to step on. I can’t believe I’m saying this but along with the smooth rolling experience, the second thing I enjoyed the most on this chair is being able to place my feet on a base with a smooth protrusion.`}</p>
    <h2>{`I can sit cross-legged`}</h2>
    <p>{`If you can sit cross-legged on any chair, that means two things - the sitting area of the chair is deep and wide or you’re small. Well, good news because this gaming chair’s sitting area is deep and wide enough for anyone to sit cross-legged! It feels liberating being able to do this on any chairs! In case you didn’t catch my height, I’m 165cm so the sales guy suggested I get Swift instead of Surge for my height. So if you’re 180cm tall, please consider getting `}<a target="_blank" rel="noopener" href="https://ttracing.my/products/ttracing-surge-gaming-chair">{`the Surge model`}</a>{`.`}</p>
    <h2>{`All in all`}</h2>
    <p>{`A few months ago, I was skeptical about getting a gaming chair. The price and the perceived value doesn’t seem to match at all! But my aching back keeps screaming for a better chair and phew, I’m glad I bought a gaming chair in the end. A soft reminder, any faux leather will eventually peel off after a lot of wear and tear. It’s just a matter of when! Foams will go out of shape as well so I’m prepared to get a new one if my current chair goes out of whack in 2 years.`}</p>
    <Ads mdxType="Ads" />
    <p>{`But is this chair a good investment? I would say a resounding `}<strong parentName="p">{`yes.`}</strong>{` The reason being it’s one of the cheapest gaming chairs out there for it’s quality. I believe there are more budget gaming chairs propping up but the prices are mostly about RM700 to RM1000? I got mine at RM530 from Shopee so I call that a win. If folks ask about a gaming chair’s seating comfort, just let them sit in one! No amount of convincing would work unless they try it for themselves. Gaming chairs are arguably catered to folks who sit long hours so I would say it’s much more comfortable than an office chair.`}</p>
    <p>{`I’m happy with my TT Racing Swift x 2020! Dear everyone who has only sat on plastic chairs or office chairs… You have no idea what you’re missing out.`}</p>
    <p><strong parentName="p">{`Extra Arguments (4th Nov 2021 update):`}</strong>{` I was in the midst of cleaning up my blog and I realize just maybe, I am not pushing my arguments as strong as I expect them to be.`}</p>
    <p>{`I'm aware that my review may sound like I'm trying my best to sell this gaming chair. Many folks in forums (Lowyat, Reddit) talked about how you're better off with a office chair compared to an expensive gaming chair. There are many conflicting comments on which is better but I personally prefer a gaming chair (which is what this whole blog post is all about).`}</p>
    <p>{`But in order to understand how I came to this conclusion, I need to explain my situation. Different folks came up with their own conclusion so here's mine and why I prefer a gaming chair to an office chair.`}</p>
    <Ads mdxType="Ads" />
    <p>{`I am used to sitting in mesh office chairs. They are good for office work but for long hours? I get irritated when sitting long hours on it. I like to fidget around in my seat but the small base of an office chair makes it hard to do so. Plus, my back never feels comfortable in an office chair. Office chair forces you to sit in a specific way to feel comfortable. You can't fidget in it because it's so small. Plus, my thighs feel sore sitting on a mesh chair.`}</p>
    <p>{`Then there's the executive chair I used to sit in. It's good until the foam thins out. When it comes to chairs, I prioritize those that lets me fidget around, with good back support and a wide armrest. Now you might be thinking, why armrests? If you're in an office job that types a lot, you'll appreciate the armrest. Some folks think they don't need the armrest, but have you considered maybe that's because you have an office table at work? Office tables are really deep (65cm~), so that lets you put your arm on it to type comfortably. Please, please consider a way to support your arm while typing. Your arms will thank you for it.`}</p>
    <p>{`Finally, the back support. If there's an office chair with good back support, I will most definitely recommend it. But honestly, I have only found gaming chairs that prioritizes your back. Maybe you can get a lumbar chair that supports your neck too but the pricing is equally expensive. Ever since I sat in TT Racing Swift, my back doesn't hurt anymore. Mind you, I sit a minimum of 8 hours a day in front of a PC. For work, for blogging or for gaming... My life is forever glued to a screen. I've been trying to ease away from the screen by getting books to read so I can... read on the bed/sofa but yeaaaa, I think I'm married to my screen forever.`}</p>
    <Blockquote mdxType="Blockquote">Cari makan susah, bro</Blockquote>
    <p>{`So back support is super important to me. Some folks added cushion to mitigate this but a good chair with good back support doesn't need a pillow for your back!`}</p>
    <Ads mdxType="Ads" />
    <p>{`So far, this is the chair that fits all of my requirements for an affordable price. If you found a chair that can do all of this, please do recommend me! If you dislike the faux leather because it gets hot easily for you, then you should get the `}<RemoteLink to="https://ttracing.my/collections/frontpage/products/ttracing-swift-x-2020-air-threads-fabric-gaming-chair" mdxType="RemoteLink">{`fabric version that TT Racing`}</RemoteLink>{` released recently.`}</p>
    <p>{`Please take good care of your back! And your wrist too!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      