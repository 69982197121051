import PropTypes from 'prop-types';
import styled, { createGlobalStyle } from 'styled-components';

import $ from '../../../styles/globals';
import Divider from './Shortcodes/Divider';

const Section = styled.section`
  margin: ${$.layout.margin8}px 0;

  ${$.screen.desktop} {
    width: 700px !important;
    padding-left: calc((100vw - 700px) / 2) !important;
    padding-right: calc((100vw - 700px) / 2) !important;
  }

  ${$.screen.tablet} {
    width: 700px !important;
    padding-left: calc((100vw - 700px) / 2) !important;
    padding-right: calc((100vw - 700px) / 2) !important;
  }
`;

const Container = styled.div``;

const Article = styled.article`
  ${$.br}

  font-family: Rubik Regular;
  font-size: 17px;
  line-height: 1.7em;
  color: ${$.brown4};

  p {
    margin: 1.5em 0;
  }

  pre {
    font-family: 'Lucida Console', Monaco, monospace;
    white-space: pre-line;
  }

  strong,
  b {
    font-family: Rubik Regular;
    font-weight: bold;
  }

  em,
  i {
    font-family: Rubik Regular;
    font-style: italic;
  }

  a {
    font-family: Rubik Regular;
    font-weight: bold;
    color: ${$.color.orange};
    text-decoration: none;
    &:visited {
      color: ${$.color.orange};
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: Rubik Regular !important;
    font-weight: bold;
    margin-top: 60px !important;

    & + p {
      margin: ${$.layout.margin4}px 0 1.5em 0;
    }
  }

  h1 {
    font-size: 34px !important;
  }

  h2 {
    font-size: 30px !important;
  }

  h3 {
    font-size: 26px !important;
  }
`;

const NightMode = createGlobalStyle`
  body.night-mode {
    ${Article} {
      color: ${$.white1};
    }
  }
`;

const Content = ({ children }) => (
  <Section>
    <Container>
      <NightMode />
      <Article>{children}</Article>
    </Container>
  </Section>
);

Content.defaultProps = {
  children: <></>,
};

Content.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
};

export default Content;
