const getStrDate = (str) => {
  const givenDate = new Date(str.split('T')[0]);
  const givenYear = givenDate.getFullYear().toString();
  const currYear = new Date().getFullYear().toString();
  const givenFullDate = givenDate.toLocaleDateString('en-GB', {
    month: 'short',
    day: '2-digit',
  });

  return givenYear === currYear
    ? givenFullDate
    : `${givenFullDate} '${givenYear.substring(2, 4)}`;
};

const showNewPill = (str) => {
  const givenDate = new Date(str.split('T')[0]);
  const currDate = Date.now();

  return Math.abs(givenDate.getTime() - currDate) <= 1000 * 60 * 60 * 24 * 14;
};

export { showNewPill, getStrDate };
