import styled, { createGlobalStyle } from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import atFanfictionChapter from './atFanficChapter';
import $ from '../../styles/globals';
import { importOneSVG } from '../../utils';

const Container = styled.div`
  ${$.screen.desktop} {
    width: 210px;
    position: absolute;
    opacity: 0;
    pointer-events: none;
    transform: translateY(-2em) translateX(-50%);
    will-change: transform, opacity;
    transition:
      opacity 0.2s ease-in-out 0s,
      transform 0.2s ease-in-out 0s;
  }

  ${$.screen.tablet} {
    display: none;
  }

  ${$.screen.mobile} {
    display: none;
  }
`;

const ItemContainer = styled.div`
  background-color: ${$.white1};
  border-radius: ${$.borderRadius.radius2}px;
  box-shadow: ${$.shadow.normal};
  > a:last-child {
    border-bottom-left-radius: ${$.borderRadius.radius2}px;
    border-bottom-right-radius: ${$.borderRadius.radius2}px;
  }
`;

const ItemRow = styled(Link)`
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: ${$.layout.padding2}px;
  padding-bottom: ${$.layout.padding2}px;
  padding-left: ${$.layout.padding3}px;
  padding-right: ${$.layout.padding3}px;
  color: inherit;
  background-color: inherit;
  transition: background-color 0.2s ease-in-out;
  &:visited {
    color: inherit;
  }

  > svg {
    width: 20px;
    height: 20px;
    margin-right: ${$.layout.margin2}px;
  }

  &:hover {
    background-color: rgba(240, 220, 203, 0.3);
  }
`;

const ItemText = styled.div`
  font-family: Rubik Regular;
  font-weight: bold;
  font-size: 16px;
  color: ${$.brown4};
`;

const ItemDescription = styled.div`
  padding-top: ${$.layout.padding3}px;
  padding-bottom: ${$.layout.padding2}px;
  padding-left: ${$.layout.padding3}px;
  padding-right: ${$.layout.padding3}px;
  color: ${$.brown4};
  font-family: Rubik Regular;
  font-size: 15px;
  line-height: 1.2em;
  border-bottom: 1px solid ${$.grey1};
`;

const HoverArea = styled.div`
  background-color: transparent;
  width: 100%;
  height: ${$.layout.padding4}px;
`;

const NightMode = createGlobalStyle`
  body.night-mode {
    ${ItemContainer} {
      background-color: #1e2c3b;
    }
    ${ItemRow} {
      &:hover {
        background-color: rgba(237, 236, 236, 0.2);
      }
    }
    ${ItemText} {
      color: ${$.white1};
    }
    ${ItemDescription} {
      color: ${$.white1};
      border-bottom: 1px solid ${$.brown1};
    }
  }
`;

const Dropdown = ({ items, setActiveNav, description }) => (
  <Container>
    {atFanfictionChapter() && <NightMode />}
    <HoverArea />
    <ItemContainer>
      <ItemDescription>{description}</ItemDescription>
      {items.map(({ text, key, icon, link }) => (
        <ItemRow
          to={link}
          key={key}
          onClick={() => {
            setActiveNav('');
          }}
        >
          {typeof icon !== 'undefined' ? importOneSVG(icon) : ''}
          <ItemText>{text}</ItemText>
        </ItemRow>
      ))}
    </ItemContainer>
  </Container>
);

Dropdown.defaultProps = {
  items: [],
  description: '',
  setActiveNav: () => {},
};

Dropdown.propTypes = {
  setActiveNav: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.object),
  description: PropTypes.string,
};

export default Dropdown;
