import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`When it comes to 1 cour anime, it could be either of these two things - a wonderful concept but terribly executed or the other way around. `}<strong parentName="p">{`Astra Lost in Space/Kanata no Astra`}</strong>{` is neither of these two things. Since 1 cour only has 12 episodes (or more), the story could only go so far if they wish to wrap it up in a clean manner! They could either go for a cliche troupe then pull a twist at the end, or simply go with a simple story all the way to the end. Both are solid story-telling techniques since you can’t go wrong with taking the easy route. Viewers love it and the anime merchandise flies off the shelf. All in a day’s work, yeehaw. Nevertheless, what sets these anime studios apart (or the story, for that matter) is how they execute and portray their vision in the end.`}</p>
    <p><em parentName="p">{`Note: Minor spoilers ahead.`}</em></p>
    <Ads mdxType="Ads" />
    <p>{`Mannnn, I say all that mumbo-jumbo but in the end, that’s not what Astra is all about. This darn anime has this interesting take on a cliche everyone is familiar with. Astra has a simple story that anyone could guess how things will unfold later on, but the twists at the end tied up the story nicely. It even gave us a great epilogue! Is this anime a full package? Yes, but with many `}<em parentName="p">{`unexpected`}</em>{` takes as the mystery unravels. I knew Astra Lost in Space is an underrated gem of an anime from what I can see (a ton of `}<em parentName="p">{`anibloggers`}</em>{` recommended me this anime), but I only got the chance to watch when it finally arrived on Netflix.`}</p>
    <p>{`Astra Lost in Space is about a group of 9 children struggling to reach home after they were flung into the far reaches of space. As the name implies, Astra Lost in Space is set in a futuristic world where space travel is possible. As such, school camping trips get `}<em parentName="p">{`super fancy`}</em>{`. I mean, you get to camp on another planet? Darn, I don’t even get to camp in the first place. My school sucks. Anyway, these 8 teenagers along with a 10 year old kid went on a school camping trip on the planet McPa. They are supposed to stay there for 5 days without adult supervision and go back home happy. But upon arrival, they were sucked into a white sphere which transported them into space. 9 kids flung out into space is a surefire DOA but thankfully, they found an empty and ready-to-fly spaceship nearby. And that's how these kids start their journey home!`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Astra Lost in Space screencap 1" caption="The entire scene where they float around in space flailing wildly is nicely done. I enjoyed the entire sequence, especially the part about thrusters, gravity and whatnot." name="astra-lost-in-space-anime-screencap-1.jpg" mdxType="Image" />
    <p>{`Honestly, I could `}<em parentName="p">{`smell the conspiracy`}</em>{` from EP2 alone. A school camping trip on another planet without adult supervision? What could go wrong indeed. Not to mention that these kids didn’t know each other well? I mean, isn’t that the strangest thing ever. Aren’t camping trips by the school done for each class? These kids should be classmates! Not to mention that these kids have the strangest set of survival skills. Are they just lucky or is their trip planned by someone politically powerful? After watching EP3, I could only come to a few conclusions. Bear with me because I love doing anime-ish plot deduction as the story unfolds!`}</p>
    <p>{`First, who leaves a spaceship unmanned and ready to be piloted in outer space? That just screams `}<em parentName="p">{`this is planned`}</em>{`. Now the only question left is… `}<em parentName="p">{`why?`}</em>{` Secondly, these kids are `}<em parentName="p">{`way too `}</em>{`well-versed in specific skills necessary to survive. An athlete sounds not too far-fetched but a spaceship pilot that is good in engineering and a biologist/zoologist that knows way beyond being a hobbyist? If this team isn’t `}<em parentName="p">{`handpicked `}</em>{`for some `}<strong parentName="p">{`nefarious plot `}</strong>{`that they’ll tell us at the end of the anime, I’ll eat my hotpot lid.`}</p>
    <Ads mdxType="Ads" />
    <p>{`My assumptions did turn out `}<em parentName="p">{`kinda right`}</em>{` as the story gets closer to the end, but let’s just say I was super surprised by how wholesome it is. You see, I’m pretty sure they’ll go for a doom-and-gloom vibe. That’s how these cliches go, no? Something along the line where the members of the spaceship will do whatever it takes to survive, bla bla murder or bla bla someone is the culprit. However, we need to take into account that these are children, not adults tainted by a pragmatic society. They should be `}<strong parentName="p">{`panicking`}</strong>{` like `}<em parentName="p">{`crazy`}</em>{` that they were just flung out to god-knows-where with no way to contact home. Do they even have what it takes to `}<strong parentName="p">{`survive`}</strong>{`? I fully expect the anime to `}<em parentName="p">{`relish`}</em>{` in these `}<strong parentName="p">{`negative emotions `}</strong>{`and produce some `}<em parentName="p">{`heartbreaking drama`}</em>{` (I mean, this is why anibloggers recommended it to me, yea?) but instead… Why are they doing heart-to-heart sessions and being so chill about their predicament?`}</p>
    <Image alt="Astra Lost in Space screencap 2" caption="This scene came out of nowhere hahaha! I get it, they trying to defuse the tension but the sudden art change is a surprise." name="astra-lost-in-space-anime-screencap-5.jpg" mdxType="Image" />
    <p>{`This anime basically took me by surprise thanks to their take on the whole `}<em parentName="p">{`“survival trip” `}</em>{`cliche. They threw out the whole `}<em parentName="p">{`survival trip cliche`}</em>{` and instead, presented us with a super wholesome, fluffy journey across 5 planets before the kids get to reach home. Mind you, these kids don’t know each other well. They are strangers and yet, they bonded so quickly within a few episodes. If they are any older, they should be accusing each other and start stabbing each other already. That’s how these stories usually go, yea? Fortunately, Astra Lost in Space didn’t want to do the doom-and-gloom approach which I really appreciate. I do love the doom-and-gloom approach but I’m sick of those.`}</p>
    <Ads mdxType="Ads" />
    <p>{`That’s not to say that they didn’t show `}<em parentName="p">{`utter despair`}</em>{` scenes just to balance the mood back. Traveling across space has their dangers after all! But more than that, I love how they took into account that despite how well they are taking it, these 8 children + 1 younger kid are still `}<em parentName="p">{`children at heart`}</em>{`. They do want to go back home safe but all in all, they prioritise getting a good vibe back in. Children make friends, not enemies! How utterly pure, I love it. So when the episodes started adding a ton of comedic scenes, it didn’t feel forced at all. Not even when the story started giving the plucky crew the sheer luck/coincidence that they need to get home safe, nuh-uh. I have zero complaints on how they bring about the story to its end, though I do question the need to do the reveal.`}</p>
    <p>{`But still, even if I find the twist at the end a bit unnecessary (because the OG twist is more than enough already)... I honestly am of the opinion that what made Astra Lost in Space so much fun to watch is the quality of the characters and production. Most leader-ish characters in anime are either charismatic or have a way of pulling the team together. Kanata however, is simply the type of character that tries his best. I wouldn’t say he is charismatic as a leader nor is he smart. He is the type of character that will do what needs to be done when he needs to, and I love him for it. Aries on the other hand, is the tiebreaker that calms everyone down. When two parties are at odds, she’ll come in to quell the fight. Aries is such a sweet lass who’s job is to see the good in everyone. Her position as the heroine is well-deserved. Then there’s Urgal and Luca combo. Their drama is exactly what I expected from a survival cliche series but the outcome of it? Wonderfully done.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Astra Lost in Space screencap 3" caption="This is a small detail thingy, but I love how they handled portraying space. The colours <3 <3 They could have made them boring black with stars in them but they added space dust colours!" name="astra-lost-in-space-anime-screencap-4.jpg" mdxType="Image" />
    <p>{`It's these little bits of drama and interaction between the characters that sold me on Astra. Does the story need these fights in the first place? I’d say yes since you can’t justify the bond without a fight or two, but to see the story tying the loose ends together with a good reason why make it all super bittersweet. I’m so glad there’s a reason why everything happened! It made the conflict and emotions much more real. And did I mention how good the production is for this anime? Okay, it’s obviously not sakuga-worthy but I’m so glad they added those black bars at the top and bottom. It made me feel like I’m watching a really, really good movie. Plus, the lighting and the background blur! Masterfully done. It added dimension and gave a good sense of distance. The animation is decently done, the background art (and concept) of each planet as well as the beautiful starlit space is candy for mah eyes. Seriously, I don’t ask for much but dear anime studios out there… I just want the anime to have a nice background art and background blur. This should be the new standard!`}</p>
    <Image alt="Astra Lost in Space screencap 4" caption="Loving the blur here!" name="astra-lost-in-space-anime-screencap-3.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <Image alt="Astra Lost in Space screencap 5" caption="Maybe it's just me, but I love the filter and how they did the lighting filtering in. Each planet got their own filter + lighting style, so it give this really unique and distinct ambience for every episode." name="astra-lost-in-space-anime-screencap-6.jpg" mdxType="Image" />
    <p>{`Phew. It’s 11:30pm on my side of the planet and yet, I’m just hammering away on my keyboard to try to get these tumultuous emotions about this anime written down for a blog post. Guys, watch Astra Lost in Space. It’s an underrated gem that I couldn’t recommend enough. I mean, 12 episodes is short enough for anyone to complete in a jiffy, right?`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      