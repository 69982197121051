import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const RemoteLink = makeShortcode("RemoteLink");
const Ads = makeShortcode("Ads");
const Video = makeShortcode("Video");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The title of this blog post is my literal, very honest thoughts on this Nintendo 3DS game by Marvelous, the very same company that did Rune Factory. It’s not exactly a bowling game but the battle mechanics definitely feels like it. Despite clearly being rushed and having certain sections not fully explored, it’s a really well-done game. You can tell the studio poured their heart into making sure even the smallest and minor features get to shine.`}</p>
    <p><strong parentName="p">{`Note: I was gonna include screenshots I took using my phone. Unfortunately, it is just plain blurry. So I’m just going to pull some photos from the official `}<RemoteLink to="http://lordofmagna.com/" mdxType="RemoteLink">{`site`}</RemoteLink>{`. Please visit the site for the full experience!`}</strong></p>
    <p>{`Lord of Magna: Maiden Heaven is all about the seven girls (faeries) you’ll encounter throughout your gameplay. The game made no effort to hide the plot importance of these seven faeries. They show up in game trailers and was even mentioned in the main site, complete with their full body character art. Heck, when you first saved the game, there are seven empty slots for each girl shown in your save file.`}</p>
    <Ads mdxType="Ads" />
    <p>{`You play as an innkeeper named Luchs Eduard, managing an inn located on the outskirts of a small town. You are so far out from foot traffic, you get literally zero customers coming in. Even your best friend, Bart question your sanity in keeping the business alive. How do you eat, he asked. Luchs replied that it’s a family tradition, passed down from father to son. You kept the business going solely to uphold the promise to your father - `}<em parentName="p">{`“When our cherished guests finally arrive, think of them as nothing less than family.`}</em>{`”.`}</p>
    <p>{`Since best buds never question each other, Bart let it be. He also tells you about a profitable side income (since you need to eat too) that even an innkeeper can do - crystal collecting. And it just happened a crystal cave showed up after an earthquake that happened right after the conversation! The story and the battle tutorial starts immediately after Luchs encountered a girl in the said crystal cave.`}</p>
    <Video title="Lord of Magna" src="https://www.youtube.com/embed/mlx3L-tN8xU" mdxType="Video" />
    <p>{`It’s really nothing original and really cliched opening. Of course, it’s a JRPG by`}<RemoteLink to="https://marvelousgames.com/" mdxType="RemoteLink">{` Marvelous`}</RemoteLink>{`. Marvelous isn’t exactly known for their ingenious plot. I mean, every Rune Factory game always starts off with having an amnesiac protagonist. They play it safe and prefer to use gameplay mechanics to do the talking which is always well appreciated. That’s not saying Marvelous is `}<em parentName="p">{`bad `}</em>{`in writing stories, they just prefer to start off simple and go out with a bang for the ending. So I was really puzzled when they did such a beautifully looking inn as your home base but the replay value/features it holds is so… barebones. Knowing Marvelous, they’d probably hid a ton of stuff to do in this game but nay, empty rooms that you can count with all 10 fingers on your hand and simple mechanics. Even the game’s menu is on par with Nintendo DS’s Ragnarok Online game menu in terms of simplicity. The items, weapons and skills are too simplistic for their standards, which led me to think this is a `}<strong parentName="p">{`rushed`}</strong>{` game. Nevertheless, it seems like the developers are fully aware of this and ensured that the basic features are completed at the least.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Ragnarok Online Nintendo DS game menu" caption="Obviously Lord of Magna's menu is much more nicer in terms of design, but you get the point." name="ragnarok-online-nintendo-ds-game-menu.jpg" mdxType="Image" />
    <p>{`Despite being an innkeeper in a meticulously designed inn, the game had nothing to do with innkeeping. It’s merely a plot setup for the grand story to unfold. In the heart of Lord Magna: Maiden of Heaven is but two things:`}</p>
    <ol>
      <li parentName="ol">{`Bowling-based battle mechanics`}</li>
      <li parentName="ol">{`Pick a girl and roll with it`}</li>
    </ol>
    <p>{`The game really only has these two mechanics to play with. The plot advances and you are treated with dialogues and cutscenes. The game then forces you to do a series of battles. Come out victorious and you will be given free time to roam around the inn. You can then choose a faerie to see her HEART event (similar to support dialogue) or do free battles until you choose to move to the next story battle. My final level in the game is Level 59 and I completed the game in 20 hours.`}</p>
    <p>{`This game has New Game+, and a gallery to fill in your unlocked supports/CG/anime cutscene with the faeries. You can choose to carry 2 unit's Level and Skill, plus 5 items, all your money and HEART events that you've completed. The game's meant to be replayed if you want to fill up every inch of the gallery and unlock every achievement there is.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Why I call this a bowling game`}</h2>
    <p>{`The battle system is really unique. Generally speaking, it’s a strategy game but all in all, it’s just a smartly coded bowling game with JRPG elements. You get to control four characters and move them around a restricted range on the battlefield when it is your turn to attack. The quickest character moves first, and the slowest last. To attack, you’ll need to position yourself so enemies are within your attack range. Aim well… and bowl these suckers down! Enemy units can potentially damage nearby enemies as they keel over by flying into them, so you should use this to effectively KO enemies that you weren’t directly aiming for.`}</p>
    <Video title="Lord of Magna" src="https://www.youtube.com/embed/ypXUvTd1_hI" mdxType="Video" />
    <p>{`The more enemies you mow down, the faster your Tension bar fills up. Tension is kinda like a gauge to fill in order to use special, overpowered skills, but the only way to refill it is to get damaged, use skills or defeat enemies. Defeat 10 enemies and above, you get a free turn. A legit strategy to spam these skills, again and again, is to first fill up the Tension bar, and aim carefully to guarantee a 10-kill. If done right, getting the 10 kills in would fill up your bar again and you also get a free turn, so you can rinse and repeat!`}</p>
    <p>{`There are 3 ways to attack in this game - use Skills, attack normally or use items. There are 2 types of skills - a normal skill that uses AP, and one that uses Tension. AP is your action points. Every turn, you are awarded 1 AP. You can choose to use that AP to attack, use items or guard for that turn. By choosing to do nothing, you get to save that AP for the next turn. If you wish to use skills (they usually cost 2 AP or more), you will need to position your unit far away from the enemy’s range and hope you don’t get attack as you stood there doing nothing. As for items, they have elemental bombs. Extremely useful to clear a huge group of enemies blocking your way.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Exploration? Nay. Detailed backgrounds? Yay.`}</h2>
    <p>{`I definitely wasn’t expecting any maps or areas for me to explore freely so this was a no-brainer. Lord of Magna has only three types of areas - your inn which you can run around freely and talk to the girls, the areas where you do battle, and an overworld map where you choose… you next free battle. The limitation does make the whole game feels constrained, but I really enjoy seeing the attention to the background art so it all balances out. I pulled some from the main site, enjoy!`}</p>
    <Image alt="Lord of Magna: Maiden Heaven Farm map" caption="One of the initial battle maps you'll encounter in the game. It's a farm!" name="lord-of-magna-maiden-heaven-farm-geeknabe-blog.jpg" mdxType="Image" />
    <Image alt="Lord of Magna: Maiden Heaven inn area" caption="The front yard of your inn." name="lord-of-magna-maiden-heaven-inn-geeknabe-blog.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`Chibi model done right`}</h2>
    <p>{`Everyone knows Nintendo 3DS’s pixel count is really, really low. Even anime cutscenes played on this screen can be blurry, making it really hard to enjoy what’s going on in the scene. What’s more with chibi 3D models that are ridiculously small on an even smaller and pixelated screen? You’ll be seeing the very same chibi models as you explore your inn or do battle. I’m honestly very impressed with the effort they put into animating the chibi despite the player not being able to see most of it all the time. The models actually smile! The mouth flaps move when they talk! They have these deliberate actions unique to each character that they do when they attack, walk or run! And you need to see how much work they put into adding pixels to the clothes just to match their original design. I barely see these unless the game zooms in for me (only happens during certain cutscenes) so I’m left really impressed with the honest effort. They definitely did not try to cut corners here.`}</p>
    <h2>{`The seven faeries`}</h2>
    <p>{`This is probably personal bias, but I personally think the faeries have some pretty nice design. And of course, JRPG has always never shied from adhering to the usual character cliches. You have the bubbly and spunky girl, the calm and collected girl, the geeky spectacle girl, a loli, the martial artist outgoing girl, the big sister and an even bigger sister. Looking at their portraits in the main site should give you a quick sense which is which. They fill in each niche nicely and I’m really happy that they aren’t one-dimensional characters. My personal favourite is Beatrice. She’s calm and collected but behind the facade is a less-than-confident that didn't realise how dependable she is. The main site has some really nice art of each character so I pulled some from there.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Lord of Magna: Maiden Heaven Charlotte" caption="Charlotte, the pink and bubbly faerie. The first faerie to join your team." name="lord-of-magna-maiden-heaven-charlotte-geeknabe-blog.jpg" mdxType="Image" />
    <Image alt="Lord of Magna: Maiden Heaven Beatrix" caption="Beatrix, the calm and collected faerie. The second to join your team." name="lord-of-magna-maiden-heaven-beatrice-geeknabe-blog.jpg" mdxType="Image" />
    <Image alt="Lord of Magna: Maiden Heaven Beatrix in maid outfit" caption="A wild maid outfit appears!" name="lord-of-magna-maiden-heaven-beatrice-maid-outfit-geeknabe-blog.jpg" mdxType="Image" />
    <p>{`Surprise! Each girl also has a maid character art, courtesy of Bart (He made them maid outfits in the game). It’s a silly cliche that’s overdone to death, but one that I don’t really mind. Thank you Bart. Not shown here are bathing suit outfits. Yes, the game has bathing suit portraits!`}</p>
    <h2>{`Choosing only one? No, you choose them all`}</h2>
    <p>{`You get to grow closer to the girls as you progress through the game. Each girl has three HEART events (I, II, III) that you can unlock. The last one, IV is tied to plot progress so I’m skipping that out. Unlocking each Hearts lets her use her respective unlocked level’s special move. These moves are powerful and if used right, you can keep spamming it to get free turns and wipe out a huge swath of enemies.`}</p>
    <p>{`These Hearts event also unlocks special dialogues and CG for you to fill your gallery in-game. After every battle or specific chapters, you get 1 chance to talk to any of the girls. Pick 1, and you get to experience a special dialogue and a battle afterwards. Complete the battle, and you are rewarded with more dialogue and maybe even CG. The flow of the supports usually goes like this: HEART event I have you assuaging the girl’s fears/problems. HEART event II (or was it III? I couldn’t find a definitive answer for this and my memory is hazy) gives me a special image and voiceover that I can replay in my room. HEART event III(?) gives me a thumbs up (figuratively) for choosing the girl as a life partner. The game does not punish you for choosing to have reached HEART event III with more than 1 girl so go ahead and cheat on them. The HEART event system is overall, a nice addition to a game consisting solely of battles since this is the only way to bond/know more about the girls other than reading the same dialogues over and over again as they stand ready in the inn.`}</p>
    <Ads mdxType="Ads" />
    <p>{`The game isn’t explicit about this, but you can accidentally miss the completing the events. E.g. you can unlock HEART event II and III but missed HEART event I. This is because, after each plot-specific battle, the game cherry-picks the girls you can do support with. I didn’t notice it so I missed Beatrix’s HEART event I while I was doing Charlotte’s. It’s a pity but thankfully, the game does not punish you for it. The game encourages you to unlock HEART event III with as many girls as possible so you can choose which girl to get the final HEART event.`}</p>
    <h2>{`The plot is good`}</h2>
    <p>{`Maiden Heaven locks you into their plot-driven gameplay. There’s little you can do outside of the normal gameplay, and rightfully so. The marketing pushes the girls and the bowling battles as the focal point, but that’s because they can’t spoil the story! The content of this game is 30% about the girls, 30% battle mechanics and the remaining 40% goes to the story. I was pulled along with the plot progression, and every revelation just had me revelling on how such a simple story can be written so good. Without spoiling too much, take note of the narrative voice in the beginning! You’ll enjoy the story more if you managed to connect the dots when the plot twist happens.`}</p>
    <h2>{`All in all`}</h2>
    <p>{`It’s simple and feels unfinished, but the effort going into the creative details is undeniable. The girls and Luchs have moving portraits. Every NPC is given a unique portrait and voice acting, even the grandma NPC that only appeared once. Similar to Rune Factory character introductory anime cutscenes, each girl here gets one too. The art quality of the CG is really nice too.`}</p>
    <Ads mdxType="Ads" />
    <p>{`The battle system is fun as I find ways to create chokepoints where I can stifle the continuous stream of enemies and have the glass cannons spam attacks from behind. There’s a limited set amount of enemies (Around 60?) that they can generate on-screen before your Nintendo 3DS lags from the sheer amount of units it had to render. It’s simple and you definitely need to strategize a bit so you don’t get overwhelmed by enemies surrounding you.`}</p>
    <p>{`You can change the difficulty level too, but I find the nicest difficulty level is NORMAL. Any higher and it gets stupidly punishing, especially the final boss. The only challenge I find in the game so far is the final boss, where he won’t stop summoning new enemies and using a 1-hit KO move every time his HP goes below 50%. The game is relatively easy and you’re unlikely to have characters dying left and right unless you make a wrong move. EXP distribution is very generous too as long as you fight enemies with levels higher than you. This makes it easier to grind to Level 99.`}</p>
    <p>{`I also do not recommend players to play this game in public in full volume. Yes, it’s dubbed in English but their deathly throes as their HP reaches 0 is oddly… loud and misleading.(´ つヮ ⊂)`}</p>
    <p>{`Lord of Magna: Maiden Heaven is a well done JRPG game for the Nintendo 3DS that you should sink your teeth to.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      