import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const RemoteLink = makeShortcode("RemoteLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Alright. Phew. I’ve been wanting to do this post for a while but I withheld myself since I think the time is not right. Besides, chucking these posts out way too often and they lose the genuine thankfulness from the poster. Anyway! I sound like a broken record but this blog has been operational since October 2019, but it was only during November that I started joining the blogging circle. Since then, everything’s been a wild ride. I joined Discord servers and got to know some really good folks, mari-chan joined my team and I now got a fanfiction section too!`}</p>
    <p>{`For the month of April, my blog has finally reached 12,000 page views. For a blog that racked up only 100 page views a month last year, this is a tremendous leap and I’m really proud of its progress. I’m not sure if it’s because of COVID-19 that everyone’s surfing the web more, or it is the natural progression of page view count. Nevertheless, I’ve always thought that geeknabe’s viewership would at best, stop rising after reaching 1000 page views and that’s the end of it. Seeing this huge number of viewership is amazing, and I could never have reached this number if not for the support of my fellow bloggers retweeting my blog posts and getting the word out.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="12K!" caption="Hurray! This is amazing!" name="google-analytics-12k-pageviews-geeknabe-blog.png" mdxType="Image" />
    <p>{`So here are some of my friendliest, favourite bloggers and youtubers. There is no order to it. Enjoy the list! You could also check them out from my Community page.`}</p>
    <h2>{`Yumdeku & The Nerdy Girl News`}</h2>
    <p>{`Gloria (`}<RemoteLink to="https://twitter.com/gloriagordon648" mdxType="RemoteLink"><a parentName="p" {...{
          "href": "https://twitter.com/gloriagordon648"
        }}>{`https://twitter.com/gloriagordon648`}</a></RemoteLink>{`) and Yumdeku (`}<RemoteLink to="https://twitter.com/YumDeku" mdxType="RemoteLink"><a parentName="p" {...{
          "href": "https://twitter.com/YumDeku"
        }}>{`https://twitter.com/YumDeku`}</a></RemoteLink>{`), the team at anime2go.com is one of the first bloggers that reached out to help me when I first started this blogging journey. I left a comment on their blog and everything’s history. Both are legit really nice people, and Yum would always PM me for small talk. Their blog is huggeeee with a seriously big list of readers. Check them out? <3`}</p>
    <h2>{`Leth`}</h2>
    <p>{`Leth (`}<RemoteLink to="https://twitter.com/AlwaysLethargic" mdxType="RemoteLink"><a parentName="p" {...{
          "href": "https://twitter.com/AlwaysLethargic"
        }}>{`https://twitter.com/AlwaysLethargic`}</a></RemoteLink>{`) is the admin for a Discord server I am pretty active in. He is also the first to guide me through the aniblogging scene. They are a fun bunch and really nice people. He loves Sword Art Online and Blood on the Dance Floor albums. If that’s how you swing, check his Twitter out?`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Mel`}</h2>
    <p>{`Mel (`}<RemoteLink to="https://twitter.com/MelinAnimeland" mdxType="RemoteLink"><a parentName="p" {...{
          "href": "https://twitter.com/MelinAnimeland"
        }}>{`https://twitter.com/MelinAnimeland`}</a></RemoteLink>{`) loves her manga a lot and by a lot, I mean `}<em parentName="p">{`a lot`}</em>{`. Shonen, shoujo, etc. She loves Haikyuu! too. Basically, all the hot 2D men. If you love manga, check out her blog!`}</p>
    <h2>{`Nana`}</h2>
    <p>{`Nana (`}<RemoteLink to="https://twitter.com/Nana__Marfo" mdxType="RemoteLink"><a parentName="p" {...{
          "href": "https://twitter.com/Nana__Marfo"
        }}>{`https://twitter.com/Nana__Marfo`}</a></RemoteLink>{`) is a student studying 3D modeling. He does some pretty cool 3D models, and he writes too! He has a Redbubble store, is an active gamer and tweets a ton about his 3D work. His outgoingness in getting word out puts my Twitter account (that only retweets stuff) to shame... Follow his Twitter!`}</p>
    <h2>{`Taryn`}</h2>
    <p>{`Taryn (`}<RemoteLink to="https://twitter.com/chaosinab00k" mdxType="RemoteLink"><a parentName="p" {...{
          "href": "https://twitter.com/chaosinab00k"
        }}>{`https://twitter.com/chaosinab00k`}</a></RemoteLink>{`) is a book blogger, but she’s also active in doing interviews. Taryn is probably the most supportive blogger out there. She’ll retweet to get word out on your blog. She’ll tag you whenever there’s a tweet chain to get attention on you. I really appreciate Taryn including me in the fun whenever she could. Thank you Taryn! It’s thanks to you I get to meet more bloggers.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Pete`}</h2>
    <p>{`Pete or MoeGamer (`}<RemoteLink to="https://twitter.com/MoeGamer" mdxType="RemoteLink"><a parentName="p" {...{
          "href": "https://twitter.com/MoeGamer"
        }}>{`https://twitter.com/MoeGamer`}</a></RemoteLink>{`) is one of the most experienced bloggers/youtubers out there. He does mostly retro games and Waifu Wednesday posts. Boy did he do them good. See how these two pop up first whenever I think of Pete? It’s proof the stuff he does is really memorable. His site is definitely the good stuff. Follow him for weekly (maybe even daily) blog updates!`}</p>
    <h2>{`Jon Spencer Reviews`}</h2>
    <p>{`Creator of the #JSC tag and #antwitwatches, Jon (`}<RemoteLink to="https://twitter.com/JS_Reviews" mdxType="RemoteLink"><a parentName="p" {...{
          "href": "https://twitter.com/JS_Reviews"
        }}>{`https://twitter.com/JS_Reviews`}</a></RemoteLink>{`) is not only a nice guy. The post he writes is great stuff too! Like, he makes me feel my English is at a kindergarten level. He does some pretty lit graphic works too. Check out his Twitter!`}</p>
    <h2>{`Selcouth`}</h2>
    <p>{`Selcouth (`}<RemoteLink to="https://twitter.com/s_selcouth" mdxType="RemoteLink"><a parentName="p" {...{
          "href": "https://twitter.com/s_selcouth"
        }}>{`https://twitter.com/s_selcouth`}</a></RemoteLink>{`) is a book blogger. He loves reading books and writing reviews for them. In fact, he does it so timely and quickly, it feels `}<em parentName="p">{`unreal`}</em>{`. There was once he tweeted that he finished a book, then not long after that a review post was published. Two days after that, it happened again! Another review post was quickly published after his prior announcement of getting a book to read. If you enjoy reading snappy book reviews, you should definitely check out his Twitter!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Scott`}</h2>
    <p>{`Scott (`}<RemoteLink to="https://twitter.com/MechAnimeReview" mdxType="RemoteLink"><a parentName="p" {...{
          "href": "https://twitter.com/MechAnimeReview"
        }}>{`https://twitter.com/MechAnimeReview`}</a></RemoteLink>{`) does mecha blog posts. He only has eyes for mecha anime. Mecha mecha mecha. His writing has a genuine love to it so it’s a delight to read his new blog posts. Lately I noticed Scott wrote a ton of blog posts on retro mecha anime. I should check these retro anime out one day...`}</p>
    <h2>{`Shay Altair`}</h2>
    <p>{`Shay (`}<RemoteLink to="https://twitter.com/AlterCreativity" mdxType="RemoteLink"><a parentName="p" {...{
          "href": "https://twitter.com/AlterCreativity"
        }}>{`https://twitter.com/AlterCreativity`}</a></RemoteLink>{`) is one of the first to follow me on my Twitter account. I added her here because I really appreciate the stuff she tweets, as well as the support she gave me when I first started out. Her tweets are just super homely and sweet to read. Thank you so much Shay!`}</p>
    <h2>{`Vel`}</h2>
    <p>{`Vel (`}<RemoteLink to="https://twitter.com/velarixion" mdxType="RemoteLink"><a parentName="p" {...{
          "href": "https://twitter.com/velarixion"
        }}>{`https://twitter.com/velarixion`}</a></RemoteLink>{`) is an anituber with some pretty great videos. The stuff she chugs out are about the anime and manga she watched and she has some prettttty strong opinions. With valid reasoning of course! Her vids are definitely worth checking out! Follow her Twitter and maybe, join her Discord?`}</p>
    <h2>{`Lit`}</h2>
    <p>{`Lit (`}<RemoteLink to="https://twitter.com/LiteratureFBoi" mdxType="RemoteLink"><a parentName="p" {...{
          "href": "https://twitter.com/LiteratureFBoi"
        }}>{`https://twitter.com/LiteratureFBoi`}</a></RemoteLink>{`) is one of the active users in Leth’s Discord server. He has a blog, loves Soul Eater and is currently trying to break into anituber scene. Follow his Twitter to support him!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Logy`}</h2>
    <p>{`Logy (`}<RemoteLink to="https://twitter.com/qt_Alchemist" mdxType="RemoteLink"><a parentName="p" {...{
          "href": "https://twitter.com/qt_Alchemist"
        }}>{`https://twitter.com/qt_Alchemist`}</a></RemoteLink>{`) is yet another one of the active users in Leth' Discord server. She loves Atelier game `}<em parentName="p">{`a lot`}</em>{`, and is currently trying to break into the youtube scene as well. Support Logy!`}</p>
    <p>{`If you’re not in the list, it doesn’t mean that I did not read your blog! If you’re in my Community page, then I have seen your tweets! Thank you to everyone that retweets and reads my stuff! Before I end this post, mari-chan has some appreciative words for everyone.`}</p>
    <p>{`*`}{`EEE THANK YOU EVERYONE FOR TAKING THE TIME TO READ OUR BLOG!`}</p>
    <p><strong parentName="p">{`Especially all of you who gave you time reading my fanfic! I haven't written anything in close to 10 years so I'm still improving my writing and storytelling!`}</strong>{` Of course, without the genius nabe-chan, I wouldn't even have the opportunity to do anything, and to J, my amazing editor, who turns my vomit into something readable, I LUP YOU GUYS <3 and lastly, to everyone who commented on the blog+fic, Ao3 and you guys who re-tweeted/liked my fic updates on twitter, all the comments and encouragement, some of you guys who just let me bounce ideas off you- everything honestly helps A LOT, especially on days when I’m burnt out and just...unmotivated to do anything.`}{`*`}</p>
    <p><strong parentName="p">{`YOU MAKE ALL MY LATE NIGHTS SO WORTH IT <3<3 THANK YOU!! <3`}</strong></p>
    <Image alt="Timmy and Tommy thanking everyone on our behalf" caption="Thank you! (... thank you!)" name="timmy-and-tommy-thank-you.jpg" mdxType="Image" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      