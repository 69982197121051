import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Image = makeShortcode("Image");
const Ads = makeShortcode("Ads");
const Blockquote = makeShortcode("Blockquote");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Just some time ago I have this `}<strong parentName="p">{`urge `}</strong>{`to browse Netflix and pick an anime to watch. You know these urge just came to you in a flitting moment, and you just act upon it? Yea, so that’s what happened when I picked up `}<a target="_blank" rel="noopener noreferrer" href="https://nntheblog.com/akame-ga-kill-season-2-everything-you-need-to-know-in-2021/">{`Akame Ga Kill!`}</a>{` on that fine day. I heard good things about it so it’s in my backlog for YEARS now. I kid you not. Everyone’s been telling me to watch it but I wasn’t keen on the idea. The cover photo I’ve seen plastered on every website is pretty much this one.`}</p>
    <Image alt="Akame Ga Kill! cover poster" caption="Commonly found poster of Akame Ga Kill! from the Internet. At least, this is what I often see as the anime's cover poster." name="akame-ga-kill-geeknabe-blog-cover-poster.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <p>{`Modern clothing and that title… Is this show about this character Akame slicing folks down? My first impression for this anime is that these are all high school students with quirky characters and all they do is chopping zombies down (You know which anime I’m talking about :D) so it kept me away from watching it for the longest of time. Turns out I was bloody wrong about all my assumptions. I think the author was `}<em parentName="p">{`high`}</em>{` on drugs when he wrote the plot.`}</p>
    <p>{`There’s no consistent world-building and setup. The environment aesthetics as well as the character design was a pool of Chinese, Japanese, European, etc all mashed up together. These girls aren’t wearing high school uniform because they are students! It’s their assassination group’s uniform. The main character isn’t wearing modern clothing! It’s their fashion sense from the boonies. The design aesthetics are everywhere! It came to a point where I stop trying to make sense of things and just nod in agreement for every new scene and fashion sense I saw. The author is definitely doing whatever he wants with his characters because he...  likes it that way. A tsun sniper in pink? Yea, why not.`}</p>
    <p>{`Disregarding the confusing aesthetic, Akame Ga Kill! is honestly, a really fun show. Half of the fun is in the fight scene, the other half is just the characters being batsh`}{`*`}{`t crazy. The general idea of the plot is that these characters you see gracing the cover poster are members of an assassination band, `}<strong parentName="p">{`Night Raid`}</strong>{`. They go around killing corrupted nobles as their `}<em parentName="p">{`modus operandi`}</em>{`. Most of the scenes take place in the Capital where nobles partake in the fun of torturing the innocents and bribing their way up. The goal of Night Raid is to oust these nobles out and restore the Capital to its former glory. Our (supposedly) main character is Tatsumi, a boy from a village burdened by the heavy taxes from the Capital. He came to the Capital in hopes to make money for his poverty-stricken village. As fate has it, he encountered Night Raid during his stay and was urged to join their cause.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Tatsumi, character from Akame Ga Kill!" caption="Our main character, Tatsumi. He's gloating after a successful kill." name="akame-ga-kill-tatsumi.jpg" mdxType="Image" />
    <p>{`Akame Ga Kill! can be generally be split into three parts. The first part had Tatsumi engaging with each Night Raid members to know them better, as well as getting some training done. The second part shows `}<em parentName="p">{`Jaegers`}</em>{`, a special force formed to defeat Night Raid. The final part had the Night Raid and their affiliated revolution army pulling out all the stops to hit hard at the Capital for a grand finale.`}</p>
    <p>{`The first part really highlights what’s great about this show. Tatsumi is shown to be a strong fighter on his own but Leone, Night Raid’s beast fighter notes that his strength lies elsewhere. His conviction is unwavering, and it just happens that this is what a Night Raid assassin should have. When he first joined the team, he is lost on what to do so the Night Raid leader, Najenda tossed him over to each senior member to get him trained by the best in the industry. It was done to improve his skills and determination to kill. Tatsumi soon bonded with these assassins and they began to tell him bits of their past.`}</p>
    <Image alt="Akame Ga Kill! fight scene - Akame versus Kurome" caption="A death match between Akame and her sister, Kurome. All fights in Akame Ga Kill! are either bloody or stylish, or both." name="akame-ga-kill-death-match-akame.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <p>{`Each member has their own sad tale caused by the corruption in the Capital. Their honest wish to push for a change in the Capital’s administration moved Tatsumi’s heart. He proclaimed that they were doing it for justice and they should be proud. But the Night Raid members disagreed with him. What they are doing is essentially murder after all. Tatsumi feels conflicted over what they do for a supposedly noble cause, but he has no time to waver. After waves of assassination and fighting, our MC is now a bonafide Night Raid member.`}</p>
    <p>{`The setting up of their fighting cause and how the series handled the assassination scenes is honestly what makes Akame Ga Kill! so`}<em parentName="p">{` fun to watch`}</em>{` for me in the first part. We were also shown the Imperial Arms, a series of special battle tools meant to overwhelm the opponent. Each fight scene has the Night Raid members either overwhelming their opponent or fighting for their lives. These folks are out for blood and they do it with style! Watching Tatsumi slowly integrates himself with the Night Raid is heartwarming since they made it clear they are doing this for a noble purpose.`}</p>
    <Image alt="Tatsumi killing Aria, the supposedly kind noble who lent him a hand in the Capital." caption="Tatsumi doing his first kill. Of course, we need to commemorate it with a special art. Some fight scenes gets these type of art as a finisher, some may not. It's a nice touch to be frank." name="akame-ga-kill-tatsumi-first-kill.jpg" mdxType="Image" />
    <p>{`The second part in Akame Ga Kill! is where the heat is. It’s also when the general mood of the show did a 180-degree change. The success of Night Raid assassinations has finally turned the Capital’s eyes on them. Not only are the Night Raid members once a part of the Capital’s best killing machine troops, but they are now wielding stolen Imperial Arms. So in comes a new and powerful character chasing the Night Raid, `}<strong parentName="p">{`Esdeath`}</strong>{`. Esdeath is a general who wields the Ice Imperial Arm. Her mastery over her arms is second to none, and her AOE attacks are really wide and fast. Night Raid is a powerful group on their own, so Esdeath was tasked to create a group of specialists to counterattack the assassins. Esdeath aptly named her team `}<em parentName="p">{`Jaegers.`}</em></p>
    <Ads mdxType="Ads" />
    <Image alt="Akame Ga Kill!'s Jaegers team members" caption="Esdeath (blue haired) at the front with the rest of the Jaegers team members. Each member have their own Imperial Arms and is equal in power to their counterpart in Night Raid. The orange haired girl next to Esdeath is Seryu. She's crazy." name="akame-ga-kill-jaeger-members.jpg" mdxType="Image" />
    <p>{`The focus of the show in the second part shifts from the familiar mood of Night Raid nightly missions to Jaeger team members. Once again, we were treated to the usual exposition - these Jaeger members all have their own story and past. They too have their own reasons in meting out justice in their own way. The first part has the spotlight on Night Raid, but the second part is all about Jaegers. The author was clearly trying to juxtapose both teams. Their members treat each other like family. Plus, they have their own reasons to why they are in this together. The first part gave us a cold, hard reason to cheer on the Night Raid missions and hate on the baddies. The second part explained that even the baddies have their own reasons.`}</p>
    <p>{`The moral clashes and death matches between the Jaegers and Night Raid continued on till the grand finale in the Capital. Both sides have casualties, and they have more than enough reasons to hate one another. I was seriously on the edge of my seat when both sides are out for blood and the casualty counter just keeps rising. Just when everything died down, a final battle between Akame and Esdeath happened.`}</p>
    <Ads mdxType="Ads" />
    <Blockquote mdxType="Blockquote">This is where I scratch my head.</Blockquote>
    <p>{`Tatsumi who we assumed to be the main character of this story is pushed aside. He gets to have his spotlight but it was abruptly stolen by Akame, a Night Raid member that we were first introduced to. Despite having her name in the anime title, she was treated like a side character in the story. She does get her spotlight, yes, but her treatment by the series is the same as any Night Raid member. Of course anyone would assume that her role in the story is minimal. To add salt to the wound, Tatsumi is the one being treated like a side character near the ending despite hogging the spotlight of the entire series to himself.`}</p>
    <p>{`It’s as though the author has just remembered the anime was titled `}<strong parentName="p">{`Akame Ga Kill!`}</strong>{`, not `}<em parentName="p">{`Tatsumi Ga Kill!`}</em>{`. Esdeath and Akame have almost zero relation to each other unless you count their relation through Tatsumi. If their sudden death match was hinted in the anime, then I’m afraid it wasn’t made super clear at all. For a great show that had everything pegged down, the ending isn’t what I expected. I checked out the manga ending for cross-referencing, thinking that maybe the anime studio adapted the ending badly. Unfortunately, the manga ending played out almost the same as the anime’s. Perhaps in the manga, Akame and Esdeath have a better rival relationship but the anime flopped it. I should take a look at the manga next time.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Nevertheless, I still think this is a great anime all in all. `}<strong parentName="p">{`But`}</strong>{` the series’s biggest takeaway for me isn’t Tatsumi or Akame. It’s `}<em parentName="p">{`Esdeath`}</em>{`. This ice wielding general is my favourite character in Akame Ga Kill!. Esdeath is a really unique character in a series that made folks clash over different beliefs as a plot point. She’s evil but only because that’s the only credo she ever knew all her life. Can her powers be used for good if her childhood is slightly different? Perhaps. She’s only in the opposing team because she loves havoc after all.`}</p>
    <Image alt="Smol Esdeath" caption="Smol Esdeath. She's adorbs... This scene is where she mercilessly cuts open her kill after a hunt." name="akame-ga-kill-esdeath-child.jpg" mdxType="Image" />
    <Image alt="Is my Esdeath bias too obvious? Sorry..." caption="Esdeath knocked Tatsumi out because he isn't listening! Bad Tatsumi. Look at Esdeath blushing, it's adorbs..." name="akame-ga-kill-moe-esdeath.jpg" mdxType="Image" />
    <p>{`But the best thing about Esdeath isn’t because she’s a domineering and powerful character! It’s because she does whatever she feels like it. She is so strong in will and power, you just can’t say No to her. The ending might be messed up, the Jaegers may have stolen some of the Night Raid’s spotlight but that’s okay. Just having Esdeath in the story made it all the better.If you haven’t watched this series, I hope my little write up convinced you to do so. Even so, just watch the show for Esdeath and your day will be so much better.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      