import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Video = makeShortcode("Video");
const LocalLink = makeShortcode("LocalLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`There are many `}<strong parentName="p">{`great anime`}</strong>{` out there, but I don’t feel as excited as I am nowadays. I think the lack of excitement simply comes with the age. (I’m olddddd) Another part of me thinks I’m a stubborn millennial who constantly wears her nostalgia glasses in every new anime she watches! But you must agree with me when I say they don’t do anime like they used to anymore. Tell me, how many shows out there can do a solid plot continuity for 60 episodes? Rare, isn’t it? Heck, I don’t think I’ve seen any shows that go beyond 24 episodes lately and that’s understandable, given how stressful it is to animate a good episode!`}</p>
    <p>{`If the current anime lineup doesn't cut it for me, it’s time to look back on shows from the  childhood days. Specifically, anime from the 2000s! I was thinking of adding a list of `}<strong parentName="p">{`anime you could marathon `}</strong>{`to the title of this blog post, but I realized many would assume that I will list `}<strong parentName="p">{`anime you could complete in a day.`}</strong>{` Nuh-uh, we’re going for broke!`}</p>
    <Ads mdxType="Ads" />
    <p>{`So here’s a list of `}<strong parentName="p">{`anime from my childhood`}</strong>{` that has a ton of episodes. I’m talking about 50 episodes and more at least! Here’s hoping your childhood and mine are `}<em parentName="p">{`almost `}</em>{`the same, because I was hoping to spark some `}<em parentName="p">{`#throwback`}</em>{` memories :D The list is not ordered. The numbers are there for you and me to keep track.`}<em parentName="p">{`Note: After compiling the list, I noticed that most of the title’s are on the girly side. I hope these anime titles are okay with you!`}</em></p>
    <h2>{`Binge-worthy Anime Recommendation #1: Mermaid Melody Pichi Pichi Pitch`}</h2>
    <p>{`Who’s up for some idol anime! `}<strong parentName="p">{`Mermaid Melody Pichi Pichi Pitch`}</strong>{` is all about mermaids, love and singing. I remember the days where I would sing along with the mermaids (in my head because the only TV box is in the living room) and go gaga whenever they transformed. Pichi Pichi Pitch follows `}<em parentName="p">{`Lucia Nanami`}</em>{`, the mermaid princess of the North Pacific Ocean.`}</p>
    <Video title="mermaid melody pichi pichi pitch" src="https://www.youtube.com/embed/eOu_gJ5qOHc" mdxType="Video" />
    <Ads mdxType="Ads" />
    <p>{`She’s all pink, has twin pigtails and `}<em parentName="p">{`in love`}</em>{` with a human boy whom she saved and entrusted her pink pearl to 7 years ago. In order to get back her pink pearl (which she needs to transform), she came up to dry land to find the boy named Kaito. Things would have been easier for her if she told him straight but things aren’t that easy for mermaids. If she told him who she really is, she would turn into bubbles.`}</p>
    <p>{`There’s this constant pull at your heartstring where she really wants to tell Kaito but she couldn’t. To make things worse, she has to battle demons harming people in both land and sea with other mermaid princesses. But to combat the evils once and for all, Lucia was told to gather all 7 mermaid princesses from other seas. 7 seas, 7 mermaid princesses!`}</p>
    <p>{`Each mermaid princess has a colour to themselves, just like Power Rangers (lol). Each princess has a wonderful dress and song to go along with their theme. If my memory serves me right, each princess gets paired up with a guy too. It’s a wonderful anime to watch if you enjoy magical shoujo anime that have singing in them. The first season has 52 episodes while the second season has 39 episodes.`}</p>
    <h2>{`Binge-worthy Anime Recommendation #2: Zoids`}</h2>
    <p>{`The only thing I remembered distinctly about this show is the title `}<strong parentName="p">{`Zoids.`}</strong>{` I used to catch this anime on TV, and they actually aired 2 different Zoids shows one after another! Both are really good shows that I want to recommend for this blog post but I only remembered the characters, not the title. After doing some digging, I finally found out their full titles.`}</p>
    <Ads mdxType="Ads" />
    <Video title="Zoids Chaotic Century" src="https://www.youtube.com/embed/Gdr1eaZOQ3E" mdxType="Video" />
    <p>{`Zoids is a giant mecha franchise with lotsa model kit and 5 anime series to explore the lore. The two Zoids anime that I really enjoyed are `}<strong parentName="p">{`Zoids: Chaotic Century`}</strong>{` and `}<strong parentName="p">{`Zoids: Genesis`}</strong>{`, but I would like to talk about Chaotic Century only! Genesis is nice, but it’s my personal favourite given the niche. It may or may not be good for folks. So I’ll leave that to you to find out if you’ll like it or not.`}</p>
    <p>{`I did say I’ll recommend anime titles with a ton of episodes but Zoids: Chaotic Century is super long even for my standards. There are two parts to this sci-fi anime. The first part talks about Van Flyheight, a boy who discovered a mysterious girl named Fiona and an Organoid named Zeke while he is on the run from bandits. Along with the two new comrades, he ran around trying to survive as a new war broke out on Zi.`}</p>
    <p>{`It’s the usual anime cliche where a boy meets a girl who has ties to an ancient race, and the boy is  capable of using the ancient race’s tech to turn the tides of the war. I don’t remember how the plot goes anymore (it’s been almost two decades since I last watched it on TV), but I remember how much fun I had watching them pilot Zoids for battle. Van and Fiona have this amazing synergy going on too. Part two takes place 2 years later. Van and Fiona got a wardrobe update and is shown to be much more mature during battle and discussions. Part two has a ton of fights and really nice scenes. It’s basically faster paced than Part 1 and better! If you like seeing robots fight each other to the death, this one’s for you. If Escaflowne sounds familiar, then you’ll feel right at home watching Zoids: Chaotic Century. Altogether, Zoids: Chaotic Century has 67 episodes.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Binge-worthy Anime Recommendation #3: Pretty Cure Season 1`}</h2>
    <p>{`It’s been a long time since I last watched a`}<strong parentName="p">{` Pretty Cure`}</strong>{`. The newer seasons are really colourful and honestly? I didn’t really like the designs that much. I have fond memories of Season 1 since it’s a superb magical girl show. The idea is simple and I love how they’ll jump from one building to another. PreCure S1 has two parts!`}</p>
    <Video title="precure s1" src="https://www.youtube.com/embed/SX836fYQL4E" mdxType="Video" />
    <p>{`Our two PreCures are Nagisa Misumi and Honoka Yukishiro. Both girls encountered Mipple and Mepple who gave them the powers to transform to Cure Black and Cure White. Each episode has them fighting against Dark Zone emissaries. If you’re familiar with magical girl shows, then the plot style is no stranger to you. What differs from each magical girl show is how they portrayed the girls! I do enjoy magical girl shows that tells a story of coming-of-age, or being the better versions of yourself. However, PreCure S1 is so simplistic in nature, I just enjoy watching Cure Black and Cure White go around defeating the baddies. It’s simple and nice to watch for 20 minutes on a Saturday morning. Ah yes, the good old days. S1 has 49 episodes and S2 has 47 episodes.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Binge-worthy Anime Recommendation #4: Kyo Kara Maoh!`}</h2>
    <p>{`It was hard for any anime to catch my interest when I was a young girl. I didn’t like gore or serious shows back then but it’s a 180 degree change now that I’m older! (I laugh while watching Blood-C, just to give you an idea) The anime show had to be cute and bubbly if you want the kid me to watch it. It wasn’t until my best friend forced me to watch an episode that I’m hooked. Handsome bishies? Well, why not!`}</p>
    <Video title="kyo kara maoh s1" src="https://www.youtube.com/embed/scbKPA0qDMg" mdxType="Video" />
    <p><strong parentName="p">{`Kyo Kara Maoh! `}</strong>{`has a ton of episodes! But it’s structured so well, I honestly don’t mind more! It’s kind of like Inuyasha, where each episode just leaves you asking “Eh, when’s the next one?” or “I want moreeee!”`}</p>
    <p>{`Our protagonist is `}<strong parentName="p">{`Yuri Shibuya`}</strong>{`, a 15 year old Japanese high school student with a strong sense of justice and a doting mother. One day, he saw his classmate Ken Murata being bullied and he stepped in to save him. Murata quickly leaves after seeing Yuri stepping in, leaving Yuri to be dragged to a girl’s toilet. The bullies then dunk his head into the toilet and voila, he is transported to another world through the toilet. `}<em parentName="p">{`Bleah`}</em>{`.`}<strong parentName="p">{` Of all places! `}</strong>{`Upon reaching the new world, he thought everyone’s playing a sick joke on him. The men around him are all handsome and they are calling him the Demon Lord? What on earth?`}</p>
    <Ads mdxType="Ads" />
    <p>{`It took awhile for him to get used to things. The first arc got him trying to win the favour of the famous noble families. As a young king, he got kidnapped a lot, he went out to settle issues, he got caught up in assassination plots, yada yada. Most isekai is a one way trip but Yuri has the power to go back and forth! Sometimes he solves problems on our side, or he solves them on the other side!`}</p>
    <p>{`This show has a ton of episodes that talks about settling problems in the kingdom as well as family and lover problems. Now you might be thinking, who’s the heroine? HAHAHA. There’s none, unless we’re talking about Wolfram <3 He’s a guy and yes, they share a kiss. This show is meant for the ladies but I wager it’s more than enough fun for the guys to hop in too! The first two seasons had 78 episodes, followed by 5 episodes of OVA. The third season that came out of nowhere had 39 episodes. So, the golden question… When is S4?`}</p>
    <h2>{`Binge-worthy Anime Recommendation #5: Cardcaptor Sakura`}</h2>
    <p>{`I thought `}<strong parentName="p">{`Cardcaptor Sakura (CCS)`}</strong>{` was a popular hit! At least, popular enough that a single utterance of this title gets everyone saying, “Oooh, I’ve heard of it!”. Alas, I was mistaken. But you guys have heard of CLAMP right?`}</p>
    <Video title="cardcaptor sakura s1" src="https://www.youtube.com/embed/6YBDo5S8soo" mdxType="Video" />
    <p>{`Cardcaptor Sakura is all about Sakura, a 10 year old girl who is all smiles and lovely… in the quest of catching every Clow Card created by a powerful sorcerer called Clow Reed. Thus the title `}<em parentName="p">{`Cardcaptor Sakura.`}</em>{` Each card contains a power hinted in their names. Now that they are on the loose, these cards are causing a ton of troubles in her city. Chosen by Kerberus, the guardian of the cards… It is up to Sakura to catch them all! Each episode has her good friend Tomoyo making her wear a cute outfit for her to do card catching while she films Sakura’s  exploits. I wrote a blog post to `}<a href="/blog/heres-all-24-cardcaptor-sakura-outfits-ranked/">{`rank Cardcaptor Sakura's outfits`}</a>{`, feel free to take a look if you’re interested!`}</p>
    <Ads mdxType="Ads" />
    <p>{`It’s a great shoujo/magical girl show that tickles my sense of adventure. Catching the card isn’t hard, but it requires Sakura to understand the nature of the card first. Every episode is kid-friendly to watch since there’s not much violence going on. There are 70 episodes (S1, S2 and S3) altogether, along with 2 movies. If you liked Cardcaptor Sakura, make sure to try the Clear Card season! I haven’t watched it yet, but I’m currently following Clear Card’s manga. The anime has 22 episodes. Enjoy one of my favourite works from CLAMP!`}</p>
    <p><em parentName="p">{`Extra note: If you like CCS, try… Angelic Layer? It has less episodes but I find it really wholesome to watch. I wrote about `}<LocalLink to="/blog/angelic-layer-anime-review-let-the-girls-and-their-dolls-shine/" mdxType="LocalLink">{`Angelic Layer here`}</LocalLink>{`!`}</em></p>
    <h2>{`Binge-worthy Anime Recommendation #6: Shaman King`}</h2>
    <p>{`Helllloooo childhood! It’s funny that I knew Shaman King from the anime, but many of my blogger friends knew Shaman King from the manga. Both mediums are so different, I think it garnered fans that love it for different reasons. I’d say that the anime version is rated E for everyone while the manga version is rated T for teenagers. Both have a different ending and vibe to it. Still, Shaman King’s anime is really fun to watch.`}</p>
    <p>{`I linked the latest Shaman King anime trailer by Netflix. I personally think you should watch that instead since it follows the manga more.`}</p>
    <Video title="shaman king 2021" src="https://www.youtube.com/embed/rV8RZrZskdk" mdxType="Video" />
    <Ads mdxType="Ads" />
    <p>{`The plot in Shaman King is super simple. Follow the adventures and growth of Yoh Asakura as he hones his shaman skills in order to become the Shaman King. This anime is as shounen as it can get! Training arcs, tournament arcs, rival that became a friend in the end, etc. Plus, our Yoh Asakura has a fiance named Anna Kyoyama! She’s one of the best things in Shaman King. Seriously, this franchise is so popular, I’m sure you must have heard of it at least once! Besides, it’s getting a reboot in 2021! Please look forward to said reboot. I’m personally really excited for it!The anime have 64 episodes and an anime-only ending. If you didn’t like the anime, please try the manga instead!`}</p>
    <h2>{`Binge-worthy Anime Recommendation #7: Mobile Suit Gundam Seed & Mobile Suit Gundam Seed Destiny`}</h2>
    <p>{`I debated with myself for a long, long time if I should recommend this anime to you guys. I’m sure everyone has heard of this anime, along with its… reused frames and debatable plot. It feels as if I’m showing my dirtiest secret to everyone… (Athrun <3) But that would mean denying my childhood memories! So here I am telling everyone that Gundam Seed and it’s sequel ain’t that bad actually. In fact, this is my first Gundam series and I love it lots.`}</p>
    <Video title="gundam seed destiny" src="https://www.youtube.com/embed/Xb69eSq5GE4" mdxType="Video" />
    <Ads mdxType="Ads" />
    <p><strong parentName="p">{`Gundam Seed`}</strong>{` is all about the conflict between the two subspecies of humanity - Naturals and Coordinators. Naturals are those that live their lives out on Earth. As their name implies, they are… really normal with no enhanced abilities whatsoever. Coordinators on the other hand, are genetically enhanced and such, they have amazing intellect and capabilities. Coordinators live in space colonies in order to escape persecutions from the Naturals. The story in Gundam Seed revolves around `}<strong parentName="p">{`Kira Yamato`}</strong>{`, a `}<em parentName="p">{`Coordinator `}</em>{`who was pulled into war between the two races.`}</p>
    <p>{`The first season talks about how Kira made a name for himself as the`}<em parentName="p">{` pilot of Freedom `}</em>{`aboard the battleship `}<strong parentName="p">{`Archangel`}</strong>{`. The war is primarily between the Earth Forces and ZAFT, whereby the ZAFT forces won’t stop chasing Archangel down. S1 ended with a goal to end the war between the two subspecies by intervention which I find unrealistic, to be fair. S1 had a lot of love troubles and drama which I think is more interesting than the mobile suit battles. In all honesty, S1 can get a bit angsty here and there. That’s not to say it isn’t good, I just find that we could use less teenage angst! And did I mention how fun it is to watch Archangel shoot beams at other ships?`}</p>
    <p>{`The second season did a whole 180 degree shift and was set 2 years after Seed. Our new protagonist is Shinn Asuka who pilots `}<em parentName="p">{`Impulse`}</em>{` aboard the battleship `}<strong parentName="p">{`Minerva`}</strong>{`.  In the previous season, Archangel is on the run and we see the events happening from the Archangel’s perspective. This time, it was Minerva who did the chasing so we are seeing the events happening from the hunter’s perspective. Kira and the rest of the older cast do appear again and play a strong role in Destiny, but we see things through the eyes of Shinn instead. S2 has less drama but darnit, Shinn is such an angry teenager. Quiet Shinn! Let Athrun speak!`}</p>
    <Ads mdxType="Ads" />
    <p>{`Both `}<strong parentName="p">{`Gundam Seed`}</strong>{` and `}<strong parentName="p">{`Gundam Seed Destiny`}</strong>{` have 50 episodes each, along with their own OVA episodes. Both series have a ton of great BGM and opening/ending songs which are fortunately fully available on Spotify. Please search the album by looking for the title `}<em parentName="p">{`“Mobile Suit Gundam Seed Original Motion Picture Soundtrack”`}</em>{`! I believe there’s about 4 albums for each season, so there’s about 120 tracks per season. Enjoy!`}</p>
    <h2>{`Binge-worthy Anime Recommendation #8: Medabots S1`}</h2>
    <p>{`I remember watching this on TV every night, but I couldn’t remember if I’m watching the sequel or prequel. All I remember is this yellow Medabot, some collecting medals plot and a renegade bot that loves to say `}<em parentName="p">{`“My name is of no importance...”`}</em>{`. After some digging around (Thank you Google!), I finally remembered which season I love. It was S1!`}</p>
    <Video title="Medabots s1" src="https://www.youtube.com/embed/g8KdXnmLZF0" mdxType="Video" />
    <p>{`It turns out that `}<strong parentName="p">{`Medabots`}</strong>{` is based on a RPG that centers around collecting robots and battling with them. It was adapted into anime in 1999 and got two seasons to boot! Follow Ikki, a 9 year old boy who really, really loves robots and wishes to become the champion of the World Robattle Tournament. He lucked out by buying a really cheap, outdated model. But these robots need a medal to move. You could say that they derive their “type” from their medals so in a way, their personalities are all in said medal.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Ikki managed to get lucky and found an old medal in a river. He put it on his Medabot and it worked! The only problem with his Medabot partner, `}<strong parentName="p">{`Metabee`}</strong>{`, is that he can be difficult to work with. Turns out Ikki actually picked up a “rare” medal. These rare medals have a special ability that allows them to call upon an attack called the “Medaforce”. Can Ikki realize his dream to become the champion of the World Robattle Tournament? And what’s with Metabee? Is he… special?`}</p>
    <p>{`I really enjoyed Medabot in the same manner one would enjoy Keroro. Keroro has this fun beat to them in each episode. The sergeants do silly stuff each episode but ultimately, they are getting closer to their original goal. Medabot is the same too! You have Ikki slowly figuring out Metabee and they get to be best buds. Along the way, they defeated many difficult opponents and slowly climbed their way up the tournament brackets. This is really fun to watch, please give it a try!Medabots has 52 episodes in S1 and in S2, 39 episodes. I only watched S1 unfortunately. Maybe I should try S2 in the coming future!`}</p>
    <h2>{`Binge-worthy Anime Recommendation #9: Sugar Sugar Rune`}</h2>
    <p>{`Sugar Sugar Rune! Choco Rune! <3 Another underrated shoujo anime that no one… seems to be talking about. Have you heard of `}<strong parentName="p">{`Anno Moyoco`}</strong>{`? If you haven’t, then maybe `}<a target="_blank" rel="noopener" href="https://grapee.jp/en/70186">{`these artworks will jog your memory`}</a>{`! She has a super distinct art that anyone could easily recognize from afar. I remember seeing her making ad graphics for perfumes and cosmetics too.`}</p>
    <Ads mdxType="Ads" />
    <Video title="sugar sugar rune" src="https://www.youtube.com/embed/k9C5-79U_Kk" mdxType="Video" />
    <p>{`Everyone knew about Sakuran by Anno Moyoco, but `}<strong parentName="p">{`Sugar Sugar Rune`}</strong>{` is one of her best works for the children's manga category. In Sugar Sugar Rune, we follow Chocolat Meilleure and Vanilla Mieux, two budding witches from the Magical World competing to be the next Queen. In order to prove their worth, they are sent to the Human World where they’ll be collecting points by capturing the hearts of boys. The two girls are aided by their animal familiars and a pop idol witch, Rockin' Robin as they enroll in a school to pretend as students.`}</p>
    <p>{`You have these two girls busy trying to catch the boy’s heart but there’s more to the story. Just where is Chocolat’s mother? Vanilla, who has a confidence problem, thought she could never live up to her mother’s expectation of her. Her meekness was then quickly exploited by the prince of the school, Pierre. Just who is Pierre? Sugar Sugar Rune is basically a really kid-friendly magical show that everyone could jump in and enjoy. The magical effects are really cute and girly, and the girls are super individualistic. Sugar Sugar Rune has 51 episodes! Enjoy!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`All in all`}</h2>
    <p>{`I had so much fun writing about these anime title recommendations. I enjoyed it enough that I feel compelled to start rewatching Gundam Seed. Zoids episode count scares me but I really need to go back in to fill my mecha needs… Enjoy watching these anime recommendations!`}<em parentName="p">{`Note: There are a few more anime that I wanted to add to the list, but I am not sure if you will enjoy it. I will drop the titles here regardless. Law of Ueki, Houshin Engi (1999), Saiyuki Reloaded and Soul Eater. `}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      