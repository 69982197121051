import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const LocalLink = makeShortcode("LocalLink");
const RemoteLink = makeShortcode("RemoteLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This is the first time I did a blog post on seasonal anime while they are still airing! This year’s anime stack is really, really good so far. It’s been awhile since I last watched an anime episode the moment it is released. I used to watch each series only after it had finished airing 1-cour! By doing so, it’s easier to binge everything in one-go without feeling antsy for the next episode. Here are my thoughts of each anime I watched so far! I completed at least up to Episode 3 for each anime listed here.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Seasonal Anime Winter 2021 #1: Dr.Stone: Stone Wars`}</h2>
    <p>{`If they keep up the momentum in S1, no one will be able to find fault in S2. Luckily, they knew what the viewers love from S1! What you are seeing in S2 is the same in S1. Science in a stone world! But this time, they have plenty at stake…`}</p>
    <Image alt="Dr Stone Stone Wars full size poster" caption="I wanted to add a screencap here but this poster is brilliant." name="dr-stone-stone-wars-full-size-poster.jpg" mdxType="Image" />
    <p>{`To be fair, I think S2 will be hard pressed to emulate the same type of wonder you get from watching the Christmas light episode. Seeing the same scene from the manga is great, but seeing the lights lighting up in the anime gave me a new sense of appreciation for the technology we have now. Nevertheless, S2 continues to give great explanations (Using robo-Senku!) of each science project. I wish my science classes were this intense!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Seasonal Anime Winter 2021 #2: Tensei Shitara Slime Datta Ken S2`}</h2>
    <p>{`Tensura S1 is a wonderful breakdown of what makes this isekai so much fun. It’s a comfy progression of Rimuru’s life with his subordinates, along with his nifty Sage skill. Did I mention how much I love the graphics when his Sage skill is doing the talking? It actually helped to portray a sense of personality to the skill! I’m glad they did this instead of Rimuru just sitting there, talking with the wall.`}</p>
    <p>{`S2 is finally where the meat of the action (and pain) happens. The first three episodes involve creating connections with the nations from S1, all of which will soon bear fruit later on. I personally can’t wait to see the coming episodes! Woo! I’m really enjoying how they did the slice-of-life moments too, but I’m not so big on the crackling explosion noises. I find it strange that all big names are doing this - crackling noises for explosions. I get that they are trying to make the action scenes bombastic, but I think the explosion noises grates on my ear more than it impresses.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Seasonal Anime Winter 2021 #3: Re:Zero kara Hajimeru Isekai Seikatsu S2 Part 2`}</h2>
    <p>{`Helllooo suffering! I’m starting to think I’m one sadistic hotpot because in all honesty, I enjoy seeing Subaru dying at every turn. He died a ton in S2 Part 1 but not so much in Part 2. Is it still enjoyable? Yes, of course! For once, Subaru picked himself, inspired others to pick themselves up to and do things right for once. Good on you, Subaru and Emilia!`}</p>
    <p>{`Now that I talked about the good things, I would like to talk about a point that did not sit well with me next. I was too absorbed in S1 to notice it, but the problem (for me it is a problem, feel free to dispute) is starting to feel glaring to me in S2. After hearing a ton of dialogue from the protag and antagonists, I’m starting to see that the author has a habit of making everyone talk from the same vein. For example, Subaru has a way of speaking in jest while Echidna speaks with convoluted hints. Look past that and you could tell that their dialogue is written by the same author. I find it really hard to discern who’s talking since their speech pattern is way too similar despite the word choices being different.`}</p>
    <p>{`I personally blame it on cultural differences. Maybe I just ain’t acclimated to the way Japanese speaks of feelings as I assume myself to be. When the heart-to-heart talk starts, I get really confused as to what they are alluding to. There’s a ton of spinning going on and the dialogue doesn’t seem to be in the same context I assume it to be. I find it difficult to follow these characters’ line of thought in the series since their speech pattern sound so samey... I really love the series, but sometimes I have to rewind a scene to get what they are talking about.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Seasonal Anime Winter 2021 #4: Mushoku Tensei: Isekai Ittara Honki Dasu`}</h2>
    <p>{`One of the best shows this season! Thank you for doing this series justice with wonderful background art and magical effects. I’ve been looking forward to Mushoku Tensei all year and I’m so happy they surpassed my expectation. I did a `}<LocalLink to="/blog/mushoku-tensei-isekai-anime-episode-1-first-impression/" mdxType="LocalLink">{`blog post for Episode 1 `}</LocalLink>{`and a `}<LocalLink to="/blog/20-beautiful-background-art-screencaps-from-mushoku-tensei-anime/" mdxType="LocalLink">{`screencap post on the background art`}</LocalLink>{`. If you want to see a recluse prove himself in an isekai that speaks of family ties more than ever, this show is for you.`}</p>
    <h2>{`Seasonal Anime Winter 2021 #5: Log Horizon: Entaku Houkai`}</h2>
    <p>{`Woo! Log Horizon is backkkkk! With a new plot too! S1 is an absolute joyride. S2 is such a fun example of raiding. Now S3 talks about… politics? Huh, just like guild politics in MMOs but with a pinch of serious consequences. I could see why folks may not like this season. There’s a lot of talking going on that involves the People of the Land and less with the players in the first three episodes. At first, I wasn’t sure where the Log Horizon guild is heading but Episode 3 has finally given us a hint. The good scenes have just started, I’m sure of this. I’ll most certainly watch this show’s new episode religiously every week.`}</p>
    <Image alt="Log Horizon Entaku Houkai anime screencap" caption="Seeing this lady being all-reliable is perhaps the strangest thing in S3... Maybe I should watch S2 again because I don't remember her having a change of heart? What did I miss??" name="log-horizon-entaku-houkai-season-3-anime-screencap.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`Seasonal Anime Winter 2021 #6: Wonder Egg Priority`}</h2>
    <p>{`I got into Wonder Egg Priority after seeing`}<RemoteLink to="https://twitter.com/GetInTheMecha" mdxType="RemoteLink">{` jamal’s tweets`}</RemoteLink>{`. He won’t stop talking about it! The title got me scratching my head because.. Wonder…. Egg Priority? Huhhh, what is that? I jumped in at Episode 1 and came out still scratching my head. A girl with a really nice jacket went into a dream world, fought nightmares off for another girl and somehow the injuries transferred to her real body? Yes, I have no clue what’s happening.`}</p>
    <Image alt="Wonder Egg Priority anime screencap" caption="This is my favourite shot from Wonder Egg Priority's opening. It's funny how we escape into anime because we hate reality. Now these folks are putting real life photos into anime and we call it brilliant." name="wonder-egg-priority-anime-screencap.jpg" mdxType="Image" />
    <p>{`Then I saw this wonderful blog post explaining the flower language hinted throughout the show. Oh, so that’s what is happening! Then Episode 2 or Episode 3 happened and we finally got to see a super obvious hint - a pair of slippers with the pride flag colours on it. Ahhh, I get it now. This show oozes style, plenty of sakuga moments and the colour palette used is super nice! Really great show that talks about young people’s social problems/mental health.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Seasonal Anime Winter 2021 #7: Kumo Desu ga, Nani ka?`}</h2>
    <p>{`Another isekai classic got animated! (I once wrote a recommendation post on other `}<a href="/blog/4-game-like-skills-you-often-see-in-isekai-manga/">{`shounen isekai manga titles`}</a>{`, please check it out if you are interested!) At this point I’m no longer surprised anymore. I’m a hotpot, so what? Aha yes, I think I nailed making isekai light novel titles on the spot xD`}</p>
    <Image alt="Kumo desuka nani ga? anime screencap" caption="I crave some lava catfish now." name="kumo-desu-ka-nani-ga-anime-screencap.jpg" mdxType="Image" />
    <p>{`There’s a lot of things to talk about regarding this adaptation. Firstly, the CG model for Kumoko. I half expected them to go this route but I never expected them to show the heroes side too! I’m cool if they only showed the parts where Kumoko struggled against the monsters in the dungeon but this is pretty great nevertheless. I’m sad that they skipped a ton of details though. You could try reading the manga for the extra goodies! Or the light novel, of course. I’m happy they nailed Kumoko’s snappy tongue <3 It’s a fun show. At this point, I’m 100% sure that they’ll announce S2 right after the last episode aired.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Seasonal Anime Winter 2021 #8: Kemono Jihen`}</h2>
    <p>{`I watched the first episode out of curiosity and I’m super happy with the route the plot is taking. It’s basically youkai children quelling supernatural cases with the help of a kemono adult. I’m super stoked with the whole idea of Kemono and the author’s choice of using Kemono children to tell the story. It’s certainly a breath of fresh air when compared to recent isekai anime releases!`}</p>
    <Image alt="Kemono Jihen anime screencap" caption="I got most of my screencaps from Muse Asia YouTube channel nowadays. It's my goto channel now. God bless this channel for putting the show online." name="kemono-jihen-anime-screencap.jpg" mdxType="Image" />
    <h2>{`Winter 2021 Binge List`}</h2>
    <p>{`I’m waiting for these shows to finish airing first before diving right in.`}</p>
    <ol>
      <li parentName="ol">{`Yakusoku no Neverland S2 - I heard that they are doing an anime original ending? Hmm, I’ll hold off my judgment first until I watch it.`}</li>
      <li parentName="ol">{`Hataraku Saibou Black - GENDERSWAPPED CELLS LET’S GO!`}</li>
      <li parentName="ol">{`Hortensia Saga - I crave a traditional fantasy story and this seems like one!`}</li>
      <li parentName="ol">{`Back Arrow - It’s a mecha show? Hell yea! Been some time since I watched one!`}</li>
    </ol>
    <Ads mdxType="Ads" />
    <h2>{`All in all`}</h2>
    <p>{`Absolutely fantastic season. I noticed that they announced even more original anime airing in April 2021. Perfect. And Netflix have their own anime shows too! Awesome! Now watch as my `}<a target="_blank" rel="noopener" href="https://anilist.co/user/geeknabe/animelist">{`Anilist Watching List`}</a>{` grows even more with almost zero anime being completed.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      