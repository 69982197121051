import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Blockquote = makeShortcode("Blockquote");
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I'm not too sure when or how I came to detest Angus, one of the villagers in my Animal Crossing New Horizons game. I just simply do, ya know? He waltzed in into my empty house I was preparing for another villager by doing island hopping. I was busy that day, so I didn't jump straight into picking a new villager for myself. I thought I have plenty of time before the game could randomly give me a new villager. Oh boy, I was wrong. I boot up my game the very next day and voilà, a new villager has moved in.`}</p>
    <p>{`I checked the name, and it says "Angus". I have an inkling it's a moo-moo villager. Plus, it certainly did not sound like any of the names that are trending on Twitter (Back when everyone is playing Animal Crossing in 2020).`}</p>
    <Blockquote mdxType="Blockquote">I was... disappointed.</Blockquote>
    <Ads mdxType="Ads" />
    <p>{`But no matter, I may come to like the villager. That's just how the game rolls after all. Nothing ever makes you mad in this game. The only thing closest to dying in this game is being touched by a scorpion or a tarantula. Or so I thought...`}</p>
    <p>{`The first thing that came to mind when I lay my eyes upon Angus is how ugly he is. His Animal Crossing mumbo jumbo sounds like an old man berating me for not keeping up with the times. His house looks like the house of an old man with a penchant of cool (gaudy) things related to gambling. `}<em parentName="p">{`Bleah.`}</em>{` He likes to dress, think and decorate with black and red. I always thought both colours are nice together but on Angus? `}<em parentName="p">{`No.`}</em></p>
    <Image name="animal-crossing-new-horizons-kicking-angus-7.jpg" caption="I gave him the gold shirt to match his ugly interior. Glad to know he never wore it before." alt="Angus Animal Crossing New Horizons" mdxType="Image" />
    <p>{`Heck, if not for the `}<em parentName="p">{`E for Everyone`}</em>{` rating, I bet Angus will be at the corner of his house smoking a substance I refuse to name to keep this blog PG13. That's just the impression he gives. Now you might think I'm just hating on the poor bull for no reason. After all, Nintendo has clearly toned down the oof dialogue in New Horizons. Compared to the older games, villagers are much more... approachable and sweet.`}</p>
    <p><strong parentName="p">{`Nah.`}</strong>{` Angus in particular is rude. At least, to me.`}</p>
    <p>{`I don't have screenshots to prove his nasty dialogues, unfortunately. All I remember is how unwelcoming it is to talk to him. Dude `}<em parentName="p">{`DOES NOT`}</em>{` spark joy. I never want to even go near his house when I first started the game. The everlasting hate I feel for this bull is so strong, a year later I still check to see if he wants to leave my island every day. Unfortunately... he likes my island so much he never wants to leave it.`}</p>
    <Blockquote mdxType="Blockquote">
  I got him in 2020(?) and he is still on my island. The whole villager roster
  has already switched up twice, yet he is the only one that has not asked to
  leave the island.
    </Blockquote>
    <Ads mdxType="Ads" />
    <p>{`I wanted to get him to leave since last year. The feeling sets in after a month(?) I got him. Urgh, get out! Out you go! But how? I was so new to the game when Angus landed on my island, I didn't even know you could make a villager leave on their own. So I did the pettiest thing I could think of in the game.`}</p>
    <p>{`I put on my terraforming hat and build a moat around his house. Hah! He shall never leave, and I shall never need to see him. Out of sight, out of mind! But that didn't work at all. I still see him walking around my island like he owns the place. `}<strong parentName="p">{`Urgh.`}</strong></p>
    <p>{`Furious with this bull, I always made sure to smack his head with my net many, many times. If he passes by, I switch to my net and whack his head. He gets mad (as he should) but that didn't stop me from smacking a few more times. If I see him in the store, I walk out. If he is taking his sweet time just enjoying flowers, I pretend he is air. If he is fishing, I make sure to use the laugh emoji because he is fishing in an empty body of water. Hopefully that should send him a hint.`}</p>
    <Blockquote mdxType="Blockquote">But he did not get the hint.</Blockquote>
    <p>{`I have no clue how nor why, but he considers me his best friend. He sends me letters asking how I'm doing, he's always thinking of me, etc. And he actually came to my birthday party! What is this buffoonery? Who gave this bull the BFF medal without my approval? I barely speak to the guy! Did the game not check what I wrote in the previous paragraph?!`}</p>
    <Image name="animal-crossing-new-horizons-kicking-angus-9.jpg" caption="To my surprise, he's part of the surprise party gang. Why?" alt="Angus Animal Crossing New Horizons" mdxType="Image" />
    <p>{`I have a strong suspicion that perhaps, after leaving the game untouched for half a year, the only villagers with high friendship points are the older ones. So yes, he `}<strong parentName="p">{`is`}</strong>{` one of the older villagers that has not left. I consider him part of the OG villagers now. The rest of my villagers are still new on the island. `}<em parentName="p">{`Sigh.`}</em></p>
    <Ads mdxType="Ads" />
    <p>{`Truth be told, I'm somewhat spent when it comes to hating Angus. It took half a year of inactivity to accept that maybe, he's here to stay. So I thought I should try speaking with him to see what nice things he has to say.`}</p>
    <Image name="animal-crossing-new-horizons-kicking-angus-3.jpg" caption="=.=" alt="Angus Animal Crossing New Horizons" mdxType="Image" />
    <Blockquote mdxType="Blockquote">He has none.</Blockquote>
    <p>{`My heart is set. The moo-moo man must go. So I bought a series of fake amiibo cards to boot him out. On my way to use my amiibo, I saw him for one last time in the plaza. It's as though the game wishes for me to think twice about my decision. Unfortunately my decision is firm. Out of the way Angus.`}</p>
    <Image name="animal-crossing-new-horizons-kicking-angus-5.jpg" caption="That's because I'm trying to find good in you Angus. I got the amiibo cards at hand already. It's now up to you to convince me that you should stay, but you failed that spectacularly." alt="Angus Animal Crossing New Horizons" mdxType="Image" />
    <p>{`I used the amiibo, got the amiibo villager in my campsite, but the sheep had the gall to say, "Invite me again tomorrow before I choose to stay". Alright, fine. Angus gets to stay another day... But that's only if I play by the rules. Rules are mild suggestions meant to be broken, and I enjoy breaking them. I time travelled to the future and... `}<em parentName="p">{`she wants me to invite her again`}</em>{`.`}</p>
    <Image name="animal-crossing-new-horizons-kicking-angus-4.jpg" caption="The sheer audacity of this sheep..." alt="Etoile Animal Crossing New Horizons" mdxType="Image" />
    <p>{`3 days to invite an amiibo villager in?! And I need to make 3 different DIY for the sheep to move in?! Oh Angus, the things I do to kick you out.`}</p>
    <Image name="animal-crossing-new-horizons-kicking-angus-8.jpg" caption="It was worth it nevertheless. Sayonara Angus." mdxType="Image" />
    <p>{`And nothing of value was lost. Goodbye old man.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      