import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../styles/globals';
import TwitterIcon from '../../assets/icons/twitter.svg';

const TwitterButton = styled.a`
  text-decoration: none;
  color: ${$.color.white};
  background-color: #1b95e0;
  border-radius: ${$.borderRadius.radius1}px;
  padding: 8px;
  font-size: 14.5px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 150px;
  transform: translateY(0);
  transition: all 0.25s ${$.transition};

  &:visited {
    color: ${$.color.white};
  }

  &:hover {
    transform: translateY(-0.2em);
  }
`;

const StyledTwitterIcon = styled(TwitterIcon)`
  fill: ${$.color.white};
  width: 15px;
  height: 15px;
  margin-right: ${$.layout.margin1}px;
`;

const Twitter = ({ nabe }) => (
  <TwitterButton
    title={
      nabe === true
        ? 'Follow nabe-chan on Twitter!'
        : 'Follow mari-chan on Twitter!'
    }
    href={`https://twitter.com/${nabe === true ? 'geeknabe' : '_marichanx'}`}
    target="_blank"
    rel="nofollow noopener noreferrer"
  >
    <StyledTwitterIcon />
    <span>{`Follow ${nabe === true ? 'nabe-chan' : 'mari-chan'}`}</span>
  </TwitterButton>
);

Twitter.defaultProps = {
  nabe: true,
};

Twitter.propTypes = {
  nabe: PropTypes.bool,
};

export default Twitter;
