import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Image = makeShortcode("Image");
const Ads = makeShortcode("Ads");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Many, many apologies for the lack of blog updates lately. Everyone found my blog through my romance webtoon recommendation posts but I haven’t been posting lately! What heresy! This is going against this very blog’s lifeline! It wasn’t that I didn’t want to update, but I’ve been busy moving house, going around trying to settle things… Not to mention how tiring work from home setting is lately. I couldn’t focus at all ): So I tried to motivate myself to write once I got an idea. These past few months I have no idea at all what to write but `}<strong parentName="p">{`now I do`}</strong>{`.`}</p>
    <p>{`I noticed how much everyone loved the fluffy moe and silly panel screencaps I tweeted. That seems to catch everyone’s attention. Good, good… I am, after all, one of you. I love fluff scenes! <3 I just purchased a copy of Eighty-Six Volume 7 specifically because that volume had a kiss scene illustrated.`}</p>
    <Image alt="Eighty-Six Volume 7 kiss scene" caption="The scene in question. YEA BABEH THEY FINALLY KISSED" name="eighty-six-volume-7-kiss-scene.jpg" mdxType="Image" />
    <p>{`So eh, a recommendation post with all the juicy bits… Hmm. So I thought, `}<em parentName="p">{`why not?`}</em>{` During my off hours I’ve been reading a ton of romance webtoons. All of these titles have been browsed through with intense scrutiny by yours truly! There might be some time reversal, reincarnation, fantasy, otome isekai, villainess isekai, yada yada and so forth in here. No matter how the plot starts, I honestly don’t mind. I just want to see the canon couple hold hands, kiss, get married and have babies. If you’re like me, hop in <3 This list is not ordered.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Romance Webtoon Recommendation #1: The Meaning of You`}</h2>
    <p>{`This darn webtoon has so many panels I want to pick! There’s the fluff, there’s the emotional one but I opted to pick this one. Just to give a little context, our protagonist is Dayeon, the girl on the right. Hailing from South Korea, she was bullied at her office work and is experiencing burnout symptoms. She was suddenly whisked away to a fantasy world one fine day, and everything went to shambles. She is demotivated, tired and depressed yet everyone keeps putting her on a pedestal. They think she is the saint from the prophecy due to the way she appeared in their world.`}</p>
    <Image alt="The Meaning of You" caption="Do you know that women look for compassion in men? https://www.brides.com/what-women-want-in-a-man-4176695" name="the-meaning-of-you.jpg" mdxType="Image" />
    <p>{`Folks in this fantasy world have so much expectation on her that she crawled back into her room, never to come out again. The blonde hottie on the left is the emperor. People in this world couldn’t recognize depression symptoms. But nevertheless, the emperor did all he could to encourage her to come out of her room and see the world for what it is.`}</p>
    <p>{`This panel right here is him trying to tell her that it’s okay to take your time, and he’ll be there for her. Instead of him trying to change her, he’ll adjust to her pace and wait for her to feel better. It’s a really sweet moment because Dayeon is finally trying to come out of her shell and begin to love. She really hated herself yet this man just won’t stop telling her to love herself. Damn, what an absolute champ of a male lead. The next panel showed wonderful character progression from Dayeon. All the best Dayeon! This story is all about healing through kindness and love. It’s really good so please give it a shot!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Romance Webtoon Recommendation #2: Revolutionary Princess Eve`}</h2>
    <p>{`Yeaaa this webtoon is amazing. I am happy the author tried something different in this “era” of romance webtoon. I know I said 1 panel but `}<strong parentName="p">{`THIS`}</strong>{`. Man, it’s beautiful. I had to try my best to put them in one image.`}</p>
    <Image alt="Revolutionary Princess Eve" caption="Straight to the heart! Pew pew!" name="revolutionary-princess-eve-panel.jpg" mdxType="Image" />
    <p>{`As the title implies, this is a romance webtoon about a princess called Eve, but there’s a women empowerment theme going on here. In the story, the 7th Princess, Eve, was murdered by her sibling. Enraged by her death, the King of Homunculus rewinded time so he could go back and save her but in turn, he sacrificed his memories.`}</p>
    <p>{`The pretty lady on the left is Eve. She knew she died and went back in time, but didn’t know that the guy on the right followed her back in time too. That’s right, the guy on the right is Mikael, future King of Homunculus who fell in love with her.Homunculus in this story are basically slaves to the imperial family. The imperials made them and so they have the right to enslave them through magic contracts. Mikael however, is so strong he overpowered the magic contract. With his newfound powers, he took over the country and liberated his comrades. So here you have two lovebirds (not yet but we’ll see it slowly develop into romance) who couldn’t recognize each other but still fell in love. In her new life, Eve wanted to become the crown princess to liberate the homunculus. To do so, she used her previous life’s knowledge to curry favour with her father and pull everyone who could help her into her circle.`}</p>
    <p>{`I intentionally chose this scene because 1) Eve didn’t know Mikael is THE Mikael she knew back then but still told him straight up she’ll protect him from harm’s way. Strangers they may be, but Eve’s heart is as gold as ever. This is practically the very reason Mikael fell in love with her in the first place in the previous life. 2) Mikael in this scene still couldn’t trust Eve. After all, without his memories, Eve is just an imperial princess who picked him as his slave. God knows if this princess will turn around and backstab him. But here you see him just shocked at her words… Perhaps, this is the very moment he fell in love with her (and her kind heart) in this life.Romantic desho? `}<strong parentName="p"><em parentName="strong">{`insert super touched emoji here`}</em></strong>{` Will Eve be able to claim the throne? Please give this a try! There’s more to it than just romantic scenes.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Romance Webtoon Recommendation #3: Saving My Sweetheart`}</h2>
    <p>{`Maybe I overestimated myself… I did say 1 panel but most of the scenes require at least 2 panels to show the dialogue (and to give a bit of context). You’ll forgive me, right? >`}{`_`}{`< Now this title is one good romance webtoon! Art is pretty nice too. Lots of`}<em parentName="p">{` kokoro ga itai`}</em>{` but this makes the couple’s journey to stay together even better.`}</p>
    <Image alt="Saving My Sweetheart" caption="You two are made for each otherrrrr" name="saving-my-sweetheart.jpg" mdxType="Image" />
    <p>{`Our girl is Leticia and she is one sorrowful girl. Her mother is the famed Saintess and the Holy Empire’s divine leader. That sounds great on paper but apparently the mother is a she-devil spoiled by her obsession for power. And poor Leticia is her scapegoat in everything she does. You see, Leticia’s mother made the public believe her daughter is a tyrant but Leticia is none of those. Kind and gentle, Leticia was soon given up to King Ditrian as his bride. But there’s a catch… Leticia’s mother put a curse on her. She must kill King Ditrian, otherwise she’ll die. But like every romance story goes… Ditrian and Leticia fell in love, Leticia died(? I presume this is the case?) because she refuses to kill her one true love and we are shown the scene in the screenshot I shared.`}</p>
    <p>{`Leticia is really, really sweet. She truly loved Ditrian. Now that she knows what will happen next, she uses her knowledge to save Ditrian in her own way. Looking back on Chapter 1 (the chapter I screencapped the panel), it’s so sweet I’m dying. It’s a bit slow but it’s worth the slog. Give this title a try!PS: I was using Google Docs to write this blog post and something funny came up.`}</p>
    <Image alt="King Durian" caption="Always correct to 'Durian'" name="king-ditrian-saving-my-sweetheart.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`Romance Webtoon Recommendation #4: The Villainess Is A Marionette`}</h2>
    <p>{`I thought I’ve seen my fair share of wonderful lacy, sparkly dresses but this webtoon takes the cake! Make sure to wear shaded glasses while you read because I swear, the men and women sparkle in this romance webtoon. There are many romantic scenes but I think the real crux of this webtoon is getting out of her half brother’s grasp. It’s actually pretty exciting.`}</p>
    <Image alt="The Villainess is a marionette" caption="It gets super sparkly in the later chapters. The eyes are drawn really nice. The girls have nice dress while the guys have booba windows. Yes." name="the-villainess-is-a-marionette.jpg" mdxType="Image" />
    <p>{`The girl being hugged is Princess Kayena. But before she was a princess, she was first an office lady from South Korea. She was murdered in our world by a guy who’s mad at her for “stealing” his project. The next time she woke up, she realized she’s in a world of a light novel she once read. Boy, does she hate the story because Princess Kayena was manipulated by her half brother all because he wanted to earn the love of the female lead.`}</p>
    <p>{`So we see her trying to spin her way out of her sadistic brother’s grasp by pulling all stops. But alas, she is only a powerless princess. How could she gain freedom when she had to rely on the male lead and wits to save her own skin? Reading this kinda makes me mad and feel powerless because I totally understand her position. Kayena is being cornered by everyone, so she has to work her way up from zero. Great read all in all. Romance is secondary to me in this story. I’m reading to see if Kayena rises above all else.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Romance Webtoon Recommendation #5: I Became The Secretary Of The Tyrant`}</h2>
    <p>{`Do you like those scenes where you have the male lead just gushing over the female lead without him realizing? Everyone around them rolled their eyes whenever they saw this blatant favouritism but the canon couple kept saying, “Oh no we’re not a couple!” Uh-huh, sureee.`}</p>
    <Image alt="I Became The Secretary Of The Tyrant" caption="He treats her super special but they are both denying the chemistry. Sheeeeesh" name="i-became-the-secretary-of-the-tyrant.jpg" mdxType="Image" />
    <p>{`Our female lead is Rosalyn (the girl on the right), who is a secretary before and after she is pulled into the fantasy novel. Secretary is one tough life… But Rosalyn has the experience of being an effective secretary thanks to her modern life. She thinks that living as “Rosalyn” isn’t too shabby… After all, she gets to eat, live and sleep well as a pampered noble lady. But when the emperor (the guy on the left) demanded a new secretary, she knew she had to step into the limelight. If she doesn't do a good job, her whole family will be in serious trouble.`}</p>
    <p>{`At first, this emperor is super nasty and quick to anger. Anything that displeases him? Off with their head! But Rosalyn is tough on him. No! Bad emperor! No beheading people as you please! Strangely, the emperor relented. They soon get close enough to be teasing each other despite their position as a secretary and her supervisor. The story is basically about a noble lady with common sense training her soon-to-be husband to be more kind, gentle and reasonable. Well technically, the previous sentence is my headcanon BUT I am sure it will come true soon. I mean, LOOK AT THEM. Sniff… All that training paid off, Rosalyn ;)`}</p>
    <Ads mdxType="Ads" />
    <h2>{`All in all `}</h2>
    <p>{`There’s supposed to be 10 romance webtoon that I’m supposed to recommend… My attention span cut it to 5 only. I’m sorry… But I’m sure these titles are more than enough to stave off your romance needs! I’ll be back with more titles soon <3`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      