import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const Blockquote = makeShortcode("Blockquote");
const LocalLink = makeShortcode("LocalLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`It just happened all of a sudden. I was chit-chatting with a friend about waifu(s) and whatnot and BAAM! I got told that I’m a closet pervert. Like, how dare you! I’m not anywhere near the term `}<strong parentName="p">{`closet pervert`}</strong>{`! I have simply achieved a perfect balance of being both a `}<em parentName="p">{`degenerate`}</em>{` and a member of the `}<em parentName="p">{`normal folks society`}</em>{`. I personally think that my mental state is `}<strong parentName="p">{`100%`}</strong>{` healthy and clean, but then it hit me. Maybe, just maybe… I am not what I think `}<em parentName="p">{`I am`}</em>{`?`}</p>
    <Ads mdxType="Ads" />
    <p><em parentName="p">{`NAH!`}</em>{` I know `}<strong parentName="p">{`perfectly`}</strong>{` what I am and I’m here to explain that! This blog post is to clear that misconception of this innocent hotpot mascot! Besides, this is one fun blog post idea that came to me while I lay on my bed, trying to get some rest before work tomorrow. I just thought that this would be fun to write for my 100th blog post! ...Okay, maybe the closet pervert statement hit me harder than I think.`}</p>
    <p><em parentName="p">{`Note: Yup, this is my 100th blog post since this blog came to conception! HURRAY! Thank you for reading this note, the content of this blog post continues below.`}</em></p>
    <p>{`But before I even jump right in, I need to tell you readers a story. Please bear with me because I need to clear this part up before we jump to the meat of this post <3 My first encounter with anime/manga is when I was 10 years old. All and I mean `}<strong parentName="p">{`ALL `}</strong>{`of the anime I watched back then are for children. They were colourful, taught us good morals or just plain fun for my age group.`}</p>
    <p>{`There’s absolutely nothing wrong with these anime and I grew up thinking that this is how anime should be. Moving on to the era of the Internet, I was exposed to even more anime titles. However, the anime titles are `}<em parentName="p">{`a little risque `}</em>{`this time. Yes, there were cleavages and unexplainable shots. My juvenile mind couldn’t comprehend why the anime is doing this. Why are they sitting like that? Why is this shot done like that? That’s not very nice, she should cover up.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Pakunoda's design is... hmm" caption="Is Pakunoda’s cleavage necessary? I had a hard time explaining things to my Dad because I was watching the Yorknew arc in the living room and he just won’t leave his seat. He started laughing at her cleavage. “Why isn’t she wearing a bra? The 7pm scene is nice and all, but what on earth are you watching?” “Dad, please stop asking questions and let me enjoy my anime. She’s going to die. This is a serious scene.”" name="pakunoda-hunter-x-hunter.jpg" mdxType="Image" />
    <Image alt="Kallen must be going to the chiropractor every week." caption="Does Kallen need to sit like that in the cockpit? “Her back must have hurt a lot.” is my sentiment throughout the whole two seasons of Code Geass. Thankfully I watched the entirety of Code Geass alone or with my best friend, so I don’t have to explain this to my family." name="kallen-code-geass.jpg" mdxType="Image" />
    <Image alt="Kira x Flay is... hmmm" caption="I remember being so confused watching their (Flay and Kira) steamy scene. Like, what are they doing? Why are they nakey? I haven’t received my s*x education yet when the episode aired. By the way, it was aired on a Saturday morning so it was a kid-friendly timeslot." name="gundam-seed-kira-flay.jpg" mdxType="Image" />
    <p>{`I `}<em parentName="p">{`did`}</em>{` have my disdain for these anime titles. The incomprehensible sexiness isn’t even fun! I remember dropping an anime (whose title eludes me because it has been a decade, but I still remember the vivid disgust at the back of my mind) because I couldn’t stand the fanservice! You’d think that I could ignore the sexiness if the story is fun but no, the fanservice is annoying and `}<strong parentName="p">{`CHOP! `}</strong>{`I dropped the anime like an unappetizing cold meal.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Since I couldn’t keep bleaching my eyes and brain for the sake of the story, I chose to stop watching anime entirely. It was a pretty long period of time (Maybe a year or so) where I stopped seeking new anime to watch because I knew these `}<em parentName="p">{`degenerate animations from Japan`}</em>{` are either of these two things - all about cute girls doing cute things `}<strong parentName="p">{`OR`}</strong>{` 99% fanservice, 1% dialogue and 0% story.`}</p>
    <p><strong parentName="p">{`It was infuriating.`}</strong>{` In my head, I just keep repeating the idea that `}<strong parentName="p">{`anime is bad nowadays`}</strong>{` because they had to include fanservice one way or another. But on the plus side, it was the same year that got me into reading manga instead! The disdain slowly pewtered off thanks to my best friend’s insistence of trying the anime titles she recommended. I had to double confirm with her first before jumping right in…`}</p>
    <p>{`Me: Does it have panty shots?`}<br />{`
Her: No.`}<br />{`
Me: Sexy shots?`}<br />{`
Her: No.`}<br />{`
Me: What about jiggly boobs?`}<br />{`
Her: No! What on earth did you watch to scar you this much?!`}<br />{`
Me: Your recommendations.`}<br />{`
Her: Oh.`}<br /></p>
    <p>{`So long story short, I tried the anime she suggested and `}<em parentName="p">{`I love it`}</em>{`. This is how I got back into watching anime on a weekly basis! Once in a while I’ll encounter anime that has really dense fanservice content packed into it. No biggie, I’ll drop it and move on to the next anime. The anime break (as I call it) along with my age (Older and more mature) has made me more `}<em parentName="p">{`tolerant`}</em>{` to fanservice. Soon, I didn’t even bother dropping anime with fanservice in them. I started `}<strong parentName="p">{`blurring `}</strong>{`these fanservice scenes in my head. Heck, I basically don’t even notice fanservice scenes anymore. This is my first step to embracing the degeneracy that is anime from the modern era.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Fanservice soon feels `}<strong parentName="p">{`normal`}</strong>{` to me. A pantyshot? Mm, okay. A sexy shot? Hm, okay. Jiggly boobies? Haha, cool. I got so used to seeing these scenes that they start being hilarious to me. Of course, I still shy away from the more serious fanservice shows because I couldn’t stomach it despite my higher tolerance for it. Let the boys have their fun, I have mine! Fast forward to the current me, I have since accepted that fanservice is here to `}<strong parentName="p">{`stay`}</strong>{`.`}</p>
    <p>{`It is without a doubt, a common theme even in many mainstream anime. If I want to continue enjoying the fun in watching anime, I have to choose between giving them the thumbs down or thumbs up when it comes to fanservice. And I chose thumbs up because I find joy in watching fantasy anime! Lesser evil, you see...`}</p>
    <p>{`My only window to the fantasy genre is either from books or anime. Good books are hard to get a hold of since you need to buy them then read a whole ton of pages before you get to the good part. I find anime hits just `}<em parentName="p">{`nice`}</em>{` for me when it comes to the whole fantasy plot so yea… I sold my soul to the devil called `}<em parentName="p">{`anime`}</em>{`.`}</p>
    <p>{`The next thing I knew, I have completely embraced the many aspects of otaku-dom. Lolis, zettai ryouki, ecchi, husbandos and even waifus! I honestly find them hilarious the way the fans keep parroting the terms wherever and whenever they meet their own fandom. My circle of RL friends would talk about scenes from an anime that we would enjoy, throw in some of these terms and laugh in good humour. To us, otaku-dom is just a fun way to pass time and as a hobby, no biggie. Now that I’m older, I finally realized that these terms are probably cooked up by some really thirsty fans but hey, everyone is eventually thirsty anyway. (Once again, I’ve embraced it.) Now that I’m done talking about my story, it’s time to talk about waifus.`}</p>
    <Blockquote mdxType="Blockquote">
  But before we begin, it’s important to draw a line between appreciating 2D and
  3D characters. Okay, honest advice sent. Let’s talk about appreciating 2D
  characters!
    </Blockquote>
    <Ads mdxType="Ads" />
    <p>{`At first, I found the whole waifu/husbando thing silly. Like, why can’t these thirsty fans just appreciate the characters for what they are. Then it hit me. They `}<em parentName="p">{`are`}</em>{` enjoying these characters for what they are! `}<strong parentName="p">{`Slightly`}</strong>{` `}<em parentName="p">{`thirsty`}</em>{`, yea, but they `}<strong parentName="p">{`are`}</strong>{` appreciating these 2D characters. Before I knew it, I soon replaced my usage of the common term `}<strong parentName="p">{`great female character`}</strong>{` to `}<strong parentName="p">{`waifu`}</strong>{` instead. It’s shorter, sounds more endearing and gets the point across real fast. Unfortunately, folks started saying I’m a closet pervert for referring to my favourites as `}<strong parentName="p">{`waifu.`}</strong>{` A horrible mistake. Perhaps I should use the term `}<strong parentName="p">{`Queen`}</strong>{` instead, but I really enjoyed the term `}<strong parentName="p">{`waifu…`}</strong>{` In case you haven’t noticed, this is a `}<strong parentName="p">{`clickbait`}</strong>{` blog post. You expected something else didn’t ya?`}</p>
    <Blockquote mdxType="Blockquote">
  There is no shame in thirst but hmmm.... ఠ_ఠ *silently judging*
    </Blockquote>
    <p>{`I’m sorry to say, this blog post’s real title should be `}<strong parentName="p">{`Let’s talk about my taste in great female characters AKA QUEENS that I look up to`}</strong>{`. I noticed this blog post is getting `}<em parentName="p">{`wayyyy`}</em>{` too long so let’s get right to it!`}</p>
    <h2>{`Yona from Akatsuki no Yona`}</h2>
    <p>{`Hailing from a shoujo manga written by Mizuho Kusanagi, one of the most underrated lady mangaka... Yona is every bit a heroine I wish many fantasy shoujo out there would emulate. It starts off really basic with a tinge of mystery. Yona is a princess of her kingdom and she has a cute crush on her cousin, Soo-Won. On her birthday, Soo-Won murdered her father and thus, Yona is forced to flee the castle with her bodyguard, Hak. Destiny guided her to meet a priest which would soon lead her down to a hunt for the Four Dragons spoken of only in myths.`}</p>
    <p>{`The very first character development Yona had wasn’t related to wielding weapons or embracing the idea of revenge at all, it was to first acknowledge that she knows nothing of the world outside. She first learns of humility.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Akatsuki no Yona manga panel 1" caption="Hak was in grave danger from protecting her. Yoon, the boy who helped her and Hak accused Yona of being a sheltered princess who knew nothing of the world and zero sense of gratitude for what she has in life." name="akatsuki-no-yona-manga-panel-1.jpg" mdxType="Image" />
    <p>{`As a princess, it was never required of her to make decisions for herself. Everyone around her decided things for her and as a princess, she just needs to stay pretty. However, things changed after her father was murdered. After being ousted from her home, she needed to decide and she did! It was for a very noble decision too.`}</p>
    <Image alt="Akatsuki no Yona manga panel 2" caption="Hak brought her to the Wind Tribe where she could stay hidden and live quietly. But Yona decided it was best for her to leave in order to protect these people who kept her safe." name="akatsuki-no-yona-manga-panel-2.jpg" mdxType="Image" />
    <p>{`Time and time again, Yona made it clear that she was the leading heroine in the story. The dragons and Hak follow her not because they were obligated to, but because she gave them a valid reason to. She gave them a place to belong to, and they reciprocated. But the first best part about Yona isn’t her charisma! It’s how Kusanagi-sensei portrayed her.`}</p>
    <p>{`She’s strong and courageous but she wasn’t pushing herself to get acknowledged to be as tough as a man. That’s not who Yona is. She embodies both the mental fortitude a woman possesses as well as feminine grace of a lady. Folks seem to think a woman could only be strong when possessing a man’s quality. In my humble opinion, the fairer sex has their own strength they should be proud of and this is what Yona is to me. I wrote a short post about `}<LocalLink to="/blog/i-was-wrong-about-soo-won/" mdxType="LocalLink">{`what I think of Soo-Won`}</LocalLink>{` here, but I’ve since changed my mind after reading Yonhi’s memoir arc.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Alisa Reinford from Trails of Cold Steel`}</h2>
    <p>{`This sounds like me being on a broken record, but I love women who could do fashion, family and career. She sounds like a 3D entity that shouldn’t exist but this type of woman does exist (in 2D)! Her name is Alisa Reinford and I have nothing but utter respect for her. She’s both graceful yet a tsun, I have absolutely no complaints about Alisa as a character. I have already written all about her here in this blog post titled `}<LocalLink to="/blog/waifu-highlight-1-alisa-reinford/" mdxType="LocalLink">{`Waifu Highlight 1: Alisa Reinford`}</LocalLink>{`, please take a look! I might need to fix the title to Queen Highlight 1 just to prevent folks from misunderstanding…`}</p>
    <Image alt="QUEEN" caption="Hello Alisa! My QUEEN <3" name="alisa-reinford.jpg" mdxType="Image" />
    <h2>{`Izumi Curtis from Fullmetal Alchemist`}</h2>
    <p>{`I was thinking hard about including the type of female characters I wish to talk about in this blog post. 3 is a nice number, so I knew I had to talk about 3 different female characters that really resonated with me. I knew that Yona and Alisa would make the list, but I wasn’t sure about the last one.`}</p>
    <p>{`Each of these ladies has a part of them that I really, really admire. Yona represents the courage to overcome your fears. Embracing them and facing them head on is a wonderful attitude to have, and I look up to Yona since her fears/blockades are a hundred times worse than your usual shoujo heroine.`}</p>
    <p>{`As for Alisa, I wanted to talk about a career woman who got herself all figured out. I think this aspect of her is what makes Alisa so appealing to me. Then next up, I wanted to talk about the virtues and strength of a mother so I decided to talk about Izumi Curtis.`}</p>
    <Ads mdxType="Ads" />
    <p>{`You might be wondering, why a mother? To me, a mother is one of the most self-sacrificial and honourable jobs to have. Children expect a lot from mothers, and mothers would give it to her children unconditionally. Seeing how anime portray mothers is a great reminder that mothers (Or parents, for that matter) is a position that shouldn’t be envied, but treasured instead.`}</p>
    <Image alt="Izumi Curtis from Fullmetal Alchemist" caption="This scene just hits me hard. She may not be their real mother, but her love for the two brothers is real." name="fullmetal-alchemist-izumi-curtis.jpg" mdxType="Image" />
    <p>{`My enjoyment in Fullmetal Alchemist came mostly from how well the author, Hiromu Arakawa portrayed the ladies. There’s Riza Hawkeye, Winry Rockbell, Olivier Armstrong, Paninya and many more! Each and every one of these ladies aren’t shown to be wallflowers. I appreciate how Arakawa-sensei write their character as a person trying hard to live and survive in their environment! They didn’t let their gender stop them from doing what needs to be done! One of the topics in FMA is all about mothers. Edward and Alphonse’s mother did what she could to protect her children with their father gone. The two protagonists did alchemy to bring their mother back. Izumi Curtis on the other hand, dabbled with alchemy to bring back her child. Her backstory serves to tell the danger/truth of the equivalent exchange rule, as well as The Gate of Truth.`}</p>
    <p>{`Izumi strucks me as a tough, no-nonsense lady when we were first introduced to her. She was strict towards her two students but as time goes, she is clearly fond of them and treats them like her own children. I honestly think of `}<em parentName="p">{`mothers`}</em>{` as an underrated title that many didn’t understand until they are older. Mothers are fantastic! Izumi was portrayed to be such a loving mother in her own way, I find it really hard to skip out on talking about her when it comes to female characters (mother ver.). I love how Hiromu Arakawa portrayed mothers in this series, it’s just so good. Love your mum, okay?`}</p>
    <Ads mdxType="Ads" />
    <h2>{`All in all?`}</h2>
    <p>{`Okay I’ll throw in a bone for folks who expected a post talking about my taste in cute waifu(s). To be fair, I have a lot of favourites if we’re solely talking about character design. But this blog post is getting way too long. I’ll just talk about 1 waifu!`}</p>
    <h2>{`Ishtar from Fate Grand Order Absolute Demonic Front`}</h2>
    <p>{`I love her design, not gonna lie. And by design, I mean her version from the FGO anime! From her crown, to her golden embellishment wrapping her body… Her design oozes class and grace despite her clothing is basically bikini! Most of the designs in anime are all symmetrical since you can’t go wrong with that, but Ishtar’s design is asymmetrical! Check out her asymmetrical leggings, gloves… And I believe her armlet too! Did I mention how much I love her bow weapon too? Besides, I can’t say no to tsuns.`}</p>
    <Image alt="Ishtar from Fate Grand Order" caption="I tried finding the anime version of her design, but I couldn’t find a full body one. So here’s her artwork from the mobile game!" name="ishtar-fate-grand-order.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`All in all for real`}</h2>
    <p>{`I was going to write about husbandos (AKA great male characters) instead but it didn’t resonate well with me. I really enjoyed shows that did female characters right so waifu blog post it is! Remember, strength can come from within. This blog post may not be what you expected from me, but I hope you enjoyed it. Thank you for reading my 100th post.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      