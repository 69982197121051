import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const RemoteLink = makeShortcode("RemoteLink");
const Blockquote = makeShortcode("Blockquote");
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`There's a ton of quality romance webtoon out there, and this is one of them. I'm absolutely in love with how they portray the characters. I can't go into many details without giving spoilers but the general gist of the comic is this; `}<strong>{`obsession`}</strong>{`.`}</p>
    <em>
  Note: All images in this blog post are sourced from{' '}
  <RemoteLink to="https://www.tappytoon.com/series/thiswitch/1" mdxType="RemoteLink">
    Tappytoon
  </RemoteLink>
  . The first three chapters are free so give it a try before diving right in.
    </em>
    <p>{`The love between family members is made of unyielding bonds. Fondness between friends is what we call a strong friendship. A relationship between a man and a woman, on the other hand, can be so many things. A young couple would probably declare their infatuation towards each other a great, undying love but a relationship is so much more than that. It requires understanding and patience between the two parties. You are no longer the sole passenger of your boat. It now has two, and it takes cooperation to row the boat together.`}</p>
    <Blockquote mdxType="Blockquote">
  This webtoon is packed with romance, loss, and tragedy. Cordelia, the girl on
  this post's cover image is the first among the many other ladies we will be
  reading about.
    </Blockquote>
    <Ads mdxType="Ads" />
    <Image caption="I actually made this cover for Pinterest. But it didn't get any attention and I'm sad no one actually saw my edits. I hope you don't mind me parading it here!" alt="This Witch of Mine pinterest cover 1" name="this-witch-of-mine-pinterest-1.jpg" mdxType="Image" />
    <Image caption="I used canva to create this graphic. Once again, Pinterest didn't really care for it so I'm going to add it here for visitors to enjoy ): She seems all sweet here but don't be fooled! This witch is really crazy (for love)." alt="This Witch of Mine pinterest cover 2" name="this-witch-of-mine-pinterest-2.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <p>{`I really enjoyed this webtoon partly due to how warped and self-centred these ladies are. The first couple was born out of obsession and necessity. She actively pursues her first love without respecting the other party. Is it truly love if every thought of your partner was manipulated, be it directly or otherwise, by you? The second couple is, unfortunately, quite tragic. You get to see how an obsession with the past can absolutely destroy you on the inside. As the story goes, you watch how every witch falls in love and how they come to terms with it.`}</p>
    <p>{`Separation, loss and falling out of love are common plot points in a romance, but This Witch of Mine spices things up by breaking the mould. Witches do not follow the common sense of a mortal being, thus their love is very different. This makes an interesting read because these love stories are intensely warped from the reader's perspective, yet still ends up being well-written as we understand each character's motivation.`}</p>
    <p>{`This webtoon has two seasons. Altogether, around 50 main chapters and 8 or so side chapters. Each chapter is a sizeable length, and the art is easy to digest. It's already completed so don't wait to jump right in!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      