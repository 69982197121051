import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Blockquote = makeShortcode("Blockquote");
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Since the first design, I dub my Animal Crossing: New Horizons island experience as `}<strong parentName="p">{`perfect.`}</strong>{` I only used `}<em parentName="p">{`minimal steps`}</em>{` to get to where I want, everywhere is `}<strong parentName="p">{`accessible`}</strong>{` and absolutely `}<strong parentName="p">{`nothing`}</strong>{` blocks my way from one area to another. But like every Animal Crossing player out there, I get really antsy to `}<strong parentName="p">{`redo`}</strong>{` my island `}<strong parentName="p">{`again.`}</strong>{` I blame Twitter for this. I’ve been seeing a ton of `}<em parentName="p">{`beautiful designs`}</em>{` and `}<em parentName="p">{`amazing creativity`}</em>{` that challenges how Nintendo designed the game to be. Then there’s the ever-growing list of furniture and flowers that I now have. Grid City (the first island design I did) is great at first, but I realized that `}<strong parentName="p">{`accessibility`}</strong>{` is`}<em parentName="p">{` not what I want `}</em>{`from my island.`}</p>
    <Blockquote mdxType="Blockquote">I wanted variety.</Blockquote>
    <p>{`So I thought maybe it’s time to `}<strong parentName="p">{`change things up`}</strong>{` on my Hotpot (I’m sorry if I never mentioned it, but my island is named Hotpot)! Taking inspirations from Twitter, YouTube and my friends’ meticulously-designed islands, I decided to `}<strong parentName="p">{`chop off`}</strong>{` the cliffs at the top part of my island and create more cliffs. So for almost 2 weeks, I’d log on to my island, wear my safety hat and start `}<strong parentName="p">{`building and demolishing cliffs.`}</strong>{` If there’s one thing that I find really, really hard work in Animal Crossing: New Horizons, it’s both `}<em parentName="p">{`cliffscaping`}</em>{` and `}<em parentName="p">{`riverscaping.`}</em>{` Lord, I just wanna chop off a full grid, not remove half a grid!`}</p>
    <Ads mdxType="Ads" />
    <p><strong parentName="p">{`Those 2 weeks are hell.`}</strong>{` My thumb hurt a lot from the button mashing. It was my determination to see things through that I continued to demolish, build then build, build... Ah damn, I created a cliff that occupies 11 grid instead of 12. Time to go back down a level and patch things up a little… Urgh, I hate it but oof, do I want my island to look `}<strong parentName="p">{`nice`}</strong>{`? `}<strong parentName="p">{`Yes, of course!`}</strong>{` So down I go, we got cliffs to build!`}</p>
    <p>{`A month and a half later, my island is finished! `}<em parentName="p">{`Woo!`}</em>{` My island’s first core design philosophy is being `}<strong parentName="p">{`accessible`}</strong>{`, `}<strong parentName="p">{`modular`}</strong>{` and customizable without any `}<strong parentName="p">{`hassle`}</strong>{`. It had every building and furniture placed on the first level. The only second level is for my house and museum area. There are `}<strong parentName="p">{`no cliffs`}</strong>{` for you to `}<em parentName="p">{`climb`}</em>{`, no rivers or lakes to `}<strong parentName="p">{`block your way`}</strong>{`. Every building `}<em parentName="p">{`except`}</em>{` my house and museum are placed within their own boundaries in each section. It’s nice but for a game that is designed to let you waste time, it’s `}<strong parentName="p">{`boring`}</strong>{`. So this time, I decided that I’ll spice things up a little.`}</p>
    <p>{`The idea came to me after my cousin commented on my island’s creativity. She was playing this game too and she casually commented that my island is `}<strong parentName="p">{`too plain`}</strong>{`. She always has this sharp tongue that wouldn’t bother sugar-coating her words to me. Ah, yes... To love is to speak plainly to your cousin. Anyway, I asked what she meant by `}<strong parentName="p">{`plain.`}</strong>{` She explained that there’s no cliff or rivers for her to hop or climb. `}<em parentName="p">{`My whole island experience is boring!,`}</em>{` she said.`}</p>
    <Ads mdxType="Ads" />
    <Blockquote mdxType="Blockquote">Okay fine, out you go cousin. *end session*</Blockquote>
    <p>{`So I decided to `}<strong parentName="p">{`separate the island into multiple areas with cliffs`}</strong>{` to create a sense of distinction. The cliffs are arranged in a `}<em parentName="p">{`straight line`}</em>{` that goes from the top of the island to the bottom. This forces me to go from one area to another by `}<strong parentName="p">{`zig-zagging`}</strong>{`. Now my island should feel less straightforward to move around! `}<strong parentName="p">{`Hah!`}</strong>{` Let’s see you call my island plain now! `}<em parentName="p">{`>:(`}</em>{` With these `}<em parentName="p">{`areas,`}</em>{` I get to work to create an omatsuri area, a long walk to my island, an equally long walk to my house, a flower breeding area, etc. I wanted a much more `}<em parentName="p">{`natural`}</em>{` design for my island since the first one felt `}<strong parentName="p">{`too modern`}</strong>{`. I craved for more greenery and colourful flowers so I chose to fill my island with as many flowers as possible.`}</p>
    <p>{`I also chose to put my decorations as randomly as possible. Decorations are honestly placed here, there and `}<strong parentName="p">{`everywhere`}</strong>{`! If they look nice where they are, then I’ll put them there. It’s such a waste to store them so I tried to use as `}<strong parentName="p">{`much furniture as possible`}</strong>{`! I really like bamboo too so I’m in favour of bamboo more than fruit trees <3 I try to put as many benches as possible around the island so I could make my character sit while I enjoy the view. This is imperative when enjoying fireworks!`}</p>
    <p>{`Enjoy the photos!`}</p>
    <Image alt="1" caption="Here's the entrance to my island. I'm definitely not inspired by everyone's obsession with straight paths and airport entrance :D The cliffs looked a little empty if I spaced them up so I just dug waterfall to fill the space. It's super loud when walking to the airport!" name="animal-crossing-hotpot-island-4.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <Image alt="2" caption="Here is the area right before entering my house's frontyard. I like the idea of an area with nothing but lilies and has a sitting area, so I added them here." name="animal-crossing-hotpot-island-1.jpg" mdxType="Image" />
    <Image alt="3" caption="As you walk into my island, you'll eventually find yourself here, the freebie area! Pick up whatever you want here, it's all free <3 The wooden incline leads to my house." name="animal-crossing-hotpot-island-5.jpg" mdxType="Image" />
    <Image alt="4" caption="Here's the area right outside the Nook's Cranny store! My idea for this area is to have a storage area outside the store. The boring-looking boxes and shelves reminded me a lot of grocery stores that I often visit when I'm young. They are dusty, with lots of random products lying around and stacked up high." name="animal-crossing-hotpot-island-2.jpg" mdxType="Image" />
    <Image alt="5" caption="Behind the store is a small area where I could place a few furniture. I took the chance to create a 'rest area' for the nooklings. In my head, they'll come here to relax during lunchtime. So I added a fridge and clothesline. I feel that they'll do their laundry here too :D" name="animal-crossing-hotpot-island-9.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <Image alt="6" caption="I wanted to create a posh toilet by the beach, but it didn't feel right... Hmmm." name="animal-crossing-hotpot-island-8.jpg" mdxType="Image" />
    <Image alt="7" caption="Here is the flower breeding area! It's a long stretch so I couldn't show everything. I intentionally placed the flowers in a double row to make watering easier. Watering will always water 1 grid around you, so this arrangement is perfect for watering the flowers without wasting a space or missing any." name="animal-crossing-hotpot-island-7.jpg" mdxType="Image" />
    <Image alt="8" caption="Here's the start of the park area. I didn't like the trees being placed really close to each other so I took care to space them up. This makes sneaking up on bugs much, much easier. At the center, a sitting area <3" name="animal-crossing-hotpot-island-13.jpg" mdxType="Image" />
    <Image alt="9" caption="I made another area at the center of the park here. It's akin to a bamboo-related product showcase area." name="animal-crossing-hotpot-island-11.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <Image alt="10" caption="The idea here is to make a 'workshop' area. I added a DIY table here for that genuine feel haha! On the right side is my tree stump area. I catch gem beetles there!" name="animal-crossing-hotpot-island-6.jpg" mdxType="Image" />
    <Image alt="11" caption="This is a walk in the park XD This leads to the Town Hall at the end. I love the benches here!" name="animal-crossing-hotpot-island-10.jpg" mdxType="Image" />
    <Image alt="12" caption="Right after the Town Hall, I added a play area for the villagers. When I made this area, I was thinking of how beaches have a chill area that folks can buy beer and relax out in the open." name="animal-crossing-hotpot-island-17.jpg" mdxType="Image" />
    <Image alt="13" caption="This area wasn't planned at all. I had too much furniture! So I did this area where the villagers could look out at the beach." name="animal-crossing-hotpot-island-16.jpg" mdxType="Image" />
    <Image alt="14" caption="Here's the start of the omatsuri area. My idea of omatsuri is to create a ton of stores along the way up to the garden, but I gave up the idea. I decided to just make a few stores only because I didn't have enough furniture and space to do it. Here's the bookstore! I added the stove area for the villagers to sit and drink tea." name="animal-crossing-hotpot-island-15.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <Image alt="15" caption="Here's the next store, a flower store. I know the custom design on the store says 'Tea', but I couldn't find a nice design to go with the flower store. Eh, 'tea' looks fine. I added a kotatsu at the bottom that villagers could sit and sew happily." name="animal-crossing-hotpot-island-14.jpg" mdxType="Image" />
    <Image alt="16" caption="Here's the final store, a seafood store. You can sit around and eat seafood! There's even a condiment counter on the left! Please ignore the bidet on the right, I forgot to remove it before taking this screenshot..." name="animal-crossing-hotpot-island-26.jpg" mdxType="Image" />
    <Image alt="17" caption="On the way up, I did a bamboo area! I put them around randomly to give a more natural feel. I love the sound of the deer scare, it's really soothing." name="animal-crossing-hotpot-island-21.jpg" mdxType="Image" />
    <Image alt="18" caption="Here's what you'll find at the top! A MOON GARDEN! To nail this name to every visitor's mind, I added moon jellyfish and yellow flowers! The rocks are not planned at all. While I was building the cliffs, all 4 rocks spawned on this cliff and I don't have the heart to remove them." name="animal-crossing-hotpot-island-20.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <Image alt="19" caption="This is supposed to be an empty space reserved for a villager's house. But I decided otherwise. I didn't like having empty spaces so I made a classroom and a mini outdoor cinema!" name="animal-crossing-hotpot-island-22.jpg" mdxType="Image" />
    <Image alt="20" caption="Here's where I keep a copy of every flower there is! It's a long stretch similar to the flower breeding area." name="animal-crossing-hotpot-island-25.jpg" mdxType="Image" />
    <Image alt="21" caption="Here's where all the villagers get to swim. A pool, an organ and even a bed! I was thinking of a posh recreation area when I did this area." name="animal-crossing-hotpot-island-18.jpg" mdxType="Image" />
    <Image alt="22" caption="Here's the front of the clothing store! I really like the Japanese-inspired pool here, because the sound of running water is so much soothing than the waterfall's. And yes, more bamboo! I put a few clothing out as display too." name="animal-crossing-hotpot-island-19.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <Image alt="23" caption="Here's the front of the museum. I have a ton of fossils since I completed my museum's fossil area, so I pick a few completed fossil pieces and placed them here. There's more decorations below which I didn't take screenshots of." name="animal-crossing-hotpot-island-24.jpg" mdxType="Image" />
    <Image alt="24" caption="Island tour done! Now it's time to see my interior design skills! Here's my first room. I was trying to design a room with antique furniture." name="animal-crossing-new-horizons-hotpot-house-room-4.jpg" mdxType="Image" />
    <Image alt="25" caption="Here's my kitchen and dining room. Inspired by my grandma's kitchen + dining area!" name="animal-crossing-new-horizons-hotpot-house-room-3.jpg" mdxType="Image" />
    <Image alt="26" caption="This is the room that I'm very proud to call my own. I was going for a computer room with all sorts of geeky contraption that I will personally use if they are available in real life. I really want a mini fridge next to my work area... Nah, my room's power would trip." name="animal-crossing-new-horizons-hotpot-house-room-2.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <Image alt="27" caption="I didn't have a lot of bathroom-related furniture so I did the best I could with this. I was going for a hotel bathroom + pool area for this room." name="animal-crossing-new-horizons-hotpot-house-room-1.jpg" mdxType="Image" />
    <p>{`Phew. Man, I’m relieved that I finally wrote this post. That’s because I can now move on to the next island design! <3 What’s next? Well, you’ll find out soon enough. There's an area that I did not screenshot actually... Yup, I forgot to screenshot the neighbourhood area (the villagers' houses). Darn it. But it's really hard to screenshot everything since it's a long stretch of land, so please visit my island using the Dream Address below!!`}</p>
    <p>{`Thank you for reading <3`}</p>
    <Image alt="28" caption="Here's my final island map! Visit my island using this Dream Address <3" name="animal-crossing-hotpot-island-23.jpg" mdxType="Image" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      