import PropTypes from 'prop-types';
import styled, { createGlobalStyle } from 'styled-components';
import { Link } from 'gatsby';

import $ from '../../../../../styles/globals';
import { getDate } from '../../../../../utils';

const Published = styled.div`
  color: ${$.brown4};
  font-size: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;

  > span {
    margin: 0 ${$.layout.margin1}px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: 250px;
`;

const ProfilePic = styled(Link)`
  border-radius: 999px;
  width: 50px;
  height: 50px;
  margin-right: ${$.layout.margin2}px;
  text-decoration: none;
  overflow: hidden;

  > img {
    width: 100%;
    height: 100%;
    border-radius: inherit;
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Rubik Regular;
  font-size: 15px;
  color: ${$.brown4};
  width: calc(100% - 50px - ${$.layout.margin2}px);

  > *:first-child {
    font-family: Rubik Regular;
    font-weight: bold;
    margin-bottom: ${$.layout.margin1}px;
  }
`;

const NightMode = createGlobalStyle`
  body.night-mode {
    ${Info} {
      color: ${$.white1};
    }
    ${Published} {
      color: ${$.brown1};
    }
  }
`;

const Author = ({ published, name, img }) => (
  <Container>
    <NightMode />
    <ProfilePic to="/about/" title="Click to learn more about mari-chan!">
      <img src={img} alt="mari-chan's profile" />
    </ProfilePic>
    <Info>
      <div>{`By ${name}`}</div>
      <Published>
        <time dateTime={published}>{`Published ${getDate(published)}`}</time>
      </Published>
    </Info>
  </Container>
);

Author.defaultProps = {
  published: '',
  name: '',
  img: '',
};

Author.propTypes = {
  name: PropTypes.string,
  published: PropTypes.string,
  img: PropTypes.string,
};

export default Author;
