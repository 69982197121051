import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Blockquote = makeShortcode("Blockquote");
const Video = makeShortcode("Video");
const Image = makeShortcode("Image");
const RemoteLink = makeShortcode("RemoteLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`It's been a while since I wrote about anime, so I thought I should move my pot (I'm technically a hotpot..) and get started! So I picked one of my go-to shows this season, Tsuki to Laika to Nosferatu. Strange how the Japanese title feels more natural/better than the English one...`}</p>
    <Ads mdxType="Ads" />
    <p>{`Despite not being tagged explicitly as romance, it definitely `}<strong parentName="p">{`feels`}</strong>{` like one. And I'm not talking about the two main characters (Oh I'm sure they will get together eventually) getting together, but the very theme the story relies on.`}</p>
    <Blockquote mdxType="Blockquote">THE SPACE! Oh how romantic!</Blockquote>
    <p><em parentName="p">{`Note: I watched up to Episode 9 before writing this post.`}</em></p>
    <p>{`Mind you, it's a good theme. One that has been long forgotten since yonder. THE GREAT VASTNESS! The universe beyond our blue dot in which we stand on! Bright lights that represent the unknown! That's right, SPACE!`}</p>
    <Video src="https://www.youtube.com/embed/XaD3ebxrxqk" title="Irina: The Vampire Cosmonaut trailer" mdxType="Video" />
    <p>{`I, for one, truly enjoyed any anime that includes `}<em parentName="p">{`space`}</em>{` in their worldbuilding. Naturally, Gundam comes to mind. Throw in some teen melodrama, mechs capable of shooting laser beams, and you'll have... a Gundam show. Gundam is nice and all, but perhaps these stories could go for something simpler... Like a story about two different people from two different race came together because they wish to go to space. This is the driving force behind Irina: The Vampire Cosmonaut's story, and I'm in love with it.`}</p>
    <p>{`The story is set in fictional Earth with two superpowers, fantasy Russia (Union of Zirnitra Republics) in the east and United Kingdom (USA?) of Arnak in the west. War has passed, but the sentiment to outpace the other did not. In the age of space race, Zirnitra is desperate to be the first to launch humans to the moon. Thus, they build a city called Laika44 to focus all of their space development efforts there in secrecy.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Our protagonist is Lev Leps, a backup astronaut candidate. He `}<strong parentName="p">{`IS`}</strong>{` an astronaut candidate at first, but a certain incident demoted him to his position he has now. At the start of the show, he was given the task to oversee Irina's (heroine) cosmonaut training. But here's the thing... Irina is a vampire and apparently, humans detest her kind. Despite the hate, she volunteered to be a test subject before they start putting humans in combustible-prone space rockets... All for the sake of being the first to go to space.`}</p>
    <p>{`So here we have a cute vampire girl with a shrill voice (I like her JP voice a lot actually). She's small but determined to see things through. Irina might not be friendly at first, but deep down she's all fluff. Then you have Lev, a really, really straitlaced guy. If he sees someone being verbally bullied right in front of him, he'll be the first to sock the bully in the eye. Once he is done bashing the poor bloke, he would straighten up his collar and give himself up. That's just how Lev is! Both are grounded characters that you'll find really hard to dislike. I would say they are both really earnest about their dreams to go to space, and that's honestly `}<em parentName="p">{`so romantic`}</em>{`.`}</p>
    <Image name="irina-the-vampire-cosmonaut-fanart.png" caption="I saw this lovely fanart many moons ago but I hesitate to put the full art in my blog. So here's a partial screenshot of the source. Please check the fanart on Twitter." mdxType="Image" />
    <p>{`Here's the source to the fanart: `}<RemoteLink to="https://twitter.com/PinaxPinakes/status/1446751787797647361" mdxType="RemoteLink">{`Twitter`}</RemoteLink>{`.`}</p>
    <p>{`Every time both of them get a scene together, I just swoon. It wasn't the moe scenes that make me go aww, but how both of them are so `}<em parentName="p">{`deep`}</em>{` into making their dreams come true, they would stop at nothing to see it done. Irina barely complains about her training intensity even when she's clearly being harassed. She takes them all in stride! While she admitted that her pride as a vampire pulled her through, I personally think it's her desire to go to space that gives her the push she needs.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Lev on the other hand, is a guy any boss would love to have in their office. True, Lev will have some issues with the HR department, but he's the kind of employee who would put aside his feelings to see things through. Sometimes, he may even put in more effort than usual because he wishes to see his colleague succeed. You know, I don't think I could do that. Maybe that's why Lev is the main protagonist, and I'm not.`}</p>
    <p>{`Regardless, it was thanks to this "chemistry" that the two carried the show. Both are earnest and likeable even to the show's side cast. They both support the other's dream because they understood each other. Irina will stop at nothing to go to space while Lev would try his hardest to support Irina in her training. Once Lev gets back his old position as an astronaut candidate, Irina would openly tell him to go chase his dreams. Oh, such a wonderful relationship!`}</p>
    <p>{`Episode 1 to 9 is like a slow waltz with occasional quick step up for those fun moments. You know they will get together eventually, but watching how their relationship came to be feels so, so good. It was all thanks to their dreams to go to space that pulled them together. Thank you `}<RemoteLink to="https://nihongomaster.com/japanese/dictionary/word/16078/uchuu" mdxType="RemoteLink">{`uchuu`}</RemoteLink>{`.`}</p>
    <p>{`And it didn't help how the show always plays slow jazz or sweet piano tunes to match the mood. Urgh, I'm hopeless for these BGM. It's really romantic <3`}</p>
    <p>{`But I admit, I was rooting for them both to fulfill their dreams (and I'm sure they did) even more than wanting the two getting together. That's secondary yo, go chase uchuu instead!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`All in all`}</h2>
    <p>{`This show bundles up everything I love about the 12-episode format. You take an idea, marry that with plot progression and tie up the loose ends at the last episode. The story establishes itself quickly through Lev and Irina's relationship by tying them both with the dreams of going to space. From then on, the story introduced other sub-plot to spice up the main one, and just like that, I'm sold. It's simple, down-to-earth plot. No plot twists, just steady progression. That's how easy it is to convince me to sit through 20~ minutes of Japanese animation.`}</p>
    <p>{`Do give this show a try if you enjoyed Spice & Wolf or Crest of the Stars! Irina: The Vampire Cosmonaut is a lovely show.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      