import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Video = makeShortcode("Video");
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const Blockquote = makeShortcode("Blockquote");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`When I first saw that trailer being shared around, I knew I had to write this blog post. It was such a surprise seeing an old favourite of mine being animated! Dragon Quest: The Adventure of Dai is one of my first forays into the world of manga, along with Doraemon, Sailormoon and Dragon Ball. Comic books are cheap then. I could just waltz in into any book shop and buy a volume with coins to spare. I usually buy sweets so I am left with no change in the end, hah!`}</p>
    <p>{`The best parts of the ye olde days are that there’s nothing being censored, the translation to my mother tongue is superb (they keep the skill names/character names from Japanese!), and they even translate little editor blurbs wherever they could. If only we could go back to those days...`}</p>
    <Video title="" src="https://www.youtube.com/embed/FX7p7CKhI3o" mdxType="Video" />
    <Ads mdxType="Ads" />
    <p>{`I still have my copy of Vol. 34 from years back. I like it so much I kinda snitched it from my brother before he gave it away… Sorry, bro.`}</p>
    <Image alt="November 1998 is the first edition of Volume 34." caption="Dragon Quest: The Adventure of Dai - Volume 34. Subtitle says, The real demon king appears!!!" name="dragon-quest-the-adventure-of-dai-volume-34-geeknabe-blog.jpg" mdxType="Image" />
    <p><strong parentName="p">{`Note`}</strong>{`: It’s been years since I last read this story, so my memory is a little fuzzy on the details. I wish to write this blog post without referring to the wiki so I am honestly going off based on what I remember (and love).`}{`*`}{`*`}</p>
    <p><em parentName="p">{`Extra note from after I wrote this blog post`}</em>{`: `}<em parentName="p">{`I didn’t know they have an anime already done in 1991! It’s time to go watch the old one!`}</em></p>
    <p>{`I wasn’t sure how long since I have my copy of Vol. 34 so I referred to the last page and it says `}<em parentName="p">{`November 1998`}</em>{`. I’m pretty sure this isn’t the First Edition so maybe it was printed a few years later. I wager this copy of mine is at least 18 years old. The pages are so yellowish!`}</p>
    <p>{`Dragon Quest: The Adventure of Dai is a representative of a simpler time in telling a fantasy story. You won’t find any convoluted plot or mysteries here, unlike the more modern stories you find gracing Shounen Jump. Romance is told in a simple fashion. Pairings are shown explicitly and it’s obvious to see who gets together with who once the story ends. The growth of our hero Dai is as straightforward as it can be. He learned the basics, then mastered the rest. He struggled at times against an opponent or two, but he always pulls through in the end. It’s a really simple storytelling technique that I really enjoyed.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Everything happened in a linear format in this story, including getting new friends to join his cause. You have the gunner + healer turned fighter, the magician, a princess and a gifted lancer that studied under the same master. Of course, everyone banded together because the hero has this gift of charisma which once again, is an absolute must for heroes in these stories.`}</p>
    <p>{`It had magic, heroes, demons and lastly, it tells of an adventure to defeat the true evil with your trusted band of friends (or foe turned friend) you made along the way. The story is as cliched as it can get but as a kid who barely knew any other fantasy story but this, it’s one hell of an addictive adventure. I rooted hard for Dai. When they split their forces to overcome the chess warriors, I was on the edge of my seat.`}</p>
    <Image alt="Cat's Eye advert" caption="An advert for Cat's Eye! Nice." name="cats-eye-manga-geeknabe-blog.jpg" mdxType="Image" />
    <p>{`For the veterans, it’s the Dragon Quest plot you knew well but I hadn’t even heard of Dragon Quest back then. The plot progression, from the island where Dai grew up to the floating castle of the Demon King… It reads like a beautiful tune that goes from a soft volume to a loud one as it approaches the climax. This sounds like a silly analogy, I know. But that’s how I feel the plot is paced and arranged. They took the cliches and perfected it. The plot is nothing fancy, but it is done extremely well.`}</p>
    <Ads mdxType="Ads" />
    <p>{`I loved every single one of the characters portrayed in this story. Good or bad, each character has a place in the story and the author wasted no effort explaining each character’s goals and backstory. Everyone played a part! Remember Launch from Dragonball? I don’t. In the manga, she was only shown briefly and was forgotten altogether.`}</p>
    <Image alt="Dragon Ball Launch is one of the ladies forgotten by the mangaka Akira Toriyama..." caption="I don't think she appeared again (after her role is done) in the manga. Maybe in the anime she gets more screentime but her character is super forgettable to be frank." name="dragon-ball-launch-geeknabe-blog.jpg" mdxType="Image" />
    <p>{`Another part I really love in this story is that skills and magic actually have their own weight in the plot! I enjoyed reading how each character only have a few skillsets (except Avan, dude is a monster), making each of the moves they make very memorable. They don’t have 200 skills or moves to show. Heck, the mage probably only had less than 10 magics to use. The rat warrior only has 1 move to show! All of these factors in to make some of the easiest to digest fighting scenes in the story. It’s not that I dislike messy panels with a ton of sound effects of sword clashes. E.g. Bleach (once upon a time). I don’t hate complicated magic/power systems that the author has clearly put the effort into cooking it up. E.g. Hunter x Hunter.`}</p>
    <Image alt="Myst Vearn, one of the baddies in Dragon Quest: The Adventure of Dai" caption="Myst Vearn, one of the baddies in the story. This page here explains his Dark Ki-based move, Demonic Finishing Palm." name="dragon-quest-the-adventure-of-dai-myst-vearn-geeknabe-blog.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <p>{`Complicated stories have their own good points but once in a while...`}</p>
    <Blockquote mdxType="Blockquote">
  I like to enjoy simpler stories, and DQ: Dai is one of them.
    </Blockquote>
    <h2>{`All in all`}</h2>
    <p>{`The trailer just screams nostalgia to me, especially the 90s art. I will reserve judgment until the anime is out but until then, it’s a definite 10/10 for me. Please give the manga a try!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      