import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const RemoteLink = makeShortcode("RemoteLink");
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I think I really should wait for all of the episodes to finish airing before I write a blog post on this anime, but Episode 7 struck a chord in me. Wonder Egg Priority had everyone praising the animation in each episode. I’ve been reading a ton of sakuga tweets from everyone on the fight scenes, etc. Then there are the flower languages secretly added into every important scene for the girls which `}<RemoteLink to="https://formeinfullbloom.wordpress.com/2021/01/26/i-hate-you-flower-language-in-wonder-egg-priority-continued/" mdxType="RemoteLink">{`someone has quickly deciphered`}</RemoteLink>{`. As a casual viewer, all of these details went over my head. I for one, will not be able to look at a flower and say, “This is a Magnolia flower.” which I believe is the same for everyone.`}</p>
    <Ads mdxType="Ads" />
    <p>{`I am of the opinion that `}<strong parentName="p">{`this is what WEP directors expected`}</strong>{` from most viewers anyway. If you noticed the flower and intentional angle details, cool. If you couldn’t, then that’s OK too. The sakuga are there because they can and if you appreciate them, then great! What’s important in Wonder Egg Priority isn’t the sakuga or the little details they snuck in, it’s the story of these girls with problems that are very, very real even amongst our friends and families. Watching Episode 7 feels like a jab directly to my heart because I know, not only is this possible, I’ve seen it happen.`}</p>
    <p>{`Do you know what is the hardest issue to verbalize? `}<strong parentName="p">{`Emotions`}</strong>{`. I find it strange that despite all of the adjectives out there to describe how we are `}<em parentName="p">{`currently feeling`}</em>{`, there’s a chance that describing it and expecting the other party to feel the magnitude of your feelings is `}<em parentName="p">{`pretty much impossible`}</em>{`. If I say I am remorseful, could you feel how much my heart trembles as thoughts of self-defeat fills my mind? It’s tough to put yourself in someone’s shoes if you have never experienced it before. You could imagine what they are thinking about so you could make sense about their `}<em parentName="p">{`supposed feelings`}</em>{` but all in all, `}<strong parentName="p">{`it can’t be done`}</strong>{`. If you were never sad, then you could never have known how it feels. `}<em parentName="p">{`How could you understand the feeling of not knowing who’s your father if you have never been in the same position?`}</em></p>
    <p>{`Which is why this episode of WEP struck a chord in me! It’s `}<strong parentName="p">{`brilliant`}</strong>{` because it caught `}<strong parentName="p">{`all of that emotion`}</strong>{` and somehow `}<strong parentName="p">{`wonderfully portrayed it`}</strong>{` in the most understandable fashion to viewers. You see, I wager human relationships are the most complicated thing we have invited into our lives, especially when it comes to our own family. I’m sure every family has their own problems, but none as heartbreaking as the one that affects the children. I’ve seen broken families with parents divorced and leaving the children all alone or worse, one of the parents ran away due to debt. Thus, leaving the remaining parent to handle the aftermath. It sounds like an anime plot with a humorous ending but alas, `}<strong parentName="p">{`it’s rea`}</strong>{`l. The poor kid is never the same anymore. We stopped talking for a long time and I could only hope he is doing okay now. Anyway, the point I wish to illustrate here is that `}<em parentName="p">{`these`}</em>{` are the stories we hear second-handedly. We weren’t there to experience and all we could say at the end of the day is, “Oh, what a pity!” or “How terrible!” while we secretly feel glad that it did not happen to us.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Rika Kawai from Wonder Egg Priority" caption="You won't know the person's pain until you have experienced it. Which is why many couldn't understand how a sane person would join a cult. Now we know why!" name="wonder-egg-priority-rika-kawai.jpg" mdxType="Image" />
    <p>{`Rika’s episode is `}<strong parentName="p">{`important `}</strong>{`because anyone who watches it now knows `}<strong parentName="p">{`how it feels`}</strong>{` to be stuck between your heart and what’s in your mind. Folks who have a perfect family or have always thought that depression/self-hate is impossible now know `}<strong parentName="p">{`this is how it feels`}</strong>{`. I personally think the ACCA folks are right when they say girls are emotional folks. I’m not going to beat around the bush and say, “How dare you!” when honestly, it IS true. Yes, we ladies are emotional. It’s a constant tug between the mind and the heart, and this is wonderfully portrayed with Rika’s behaviour. Major kudos to the writers for doing it right!`}</p>
    <p>{`By now, the viewers have already made up their minds about Rika - outgoing, rebellious but all soft on the inside. She’s all talk to pretend that everything is fine in her life, which is why the turtle guardian is perfect for her. In this episode, we were finally told that not only is she feeling guilty about her fan’s (Chiemi) suicide, the guilt is `}<strong parentName="p">{`TWO-FOLD`}</strong>{`. It also stems from her broken family. Rika feels unwanted by her missing father, unloved by her alcoholic mother and guilty for potentially being the cause of Chiemi’s death. All of this dumped onto a teenage girl could result in a disastrous aftermath which we see as Rika cutting herself. Poor girl doesn’t love herself and is constantly embroiled in self-loathing. Is she to blame for her current state? No, it was her `}<strong parentName="p">{`environment `}</strong>{`that made her this way. If Rika had a loving family, would she have ended up this way? Definitely not! See how much a complete and loving family do wonders? We all took this for granted.`}</p>
    <Ads mdxType="Ads" />
    <p>{`The episode could have ended quickly at the self-loathing portrayal part but WEP wanted to do better. They showed us even more of Rika’s emotional turmoil between what’s in her heart and mind. They showed us how the other girls reacted to her problem, and how defensive she is when Neiru presented a logical perspective. They also showed us how Ai stepped up to talk about their single parents. All of this is related and is added to show `}<strong parentName="p">{`YOU`}</strong>{` Rika’s thoughts of herself. While she loathed her mother, she still looks up to her as a single parent who cared for her. While she yearns for love, she knew that she must first break out of her self-defeatist habit and thoughts. She knows what she `}<em parentName="p">{`has to do`}</em>{`, but she `}<strong parentName="p">{`can’t. `}</strong>{`She hates herself but she has a sliver of strength and hopes to break out of it. But she `}<strong parentName="p">{`can’t.`}</strong></p>
    <p>{`The scenes leading up to the cult boss Rika going up against is a wonderful message hinting to the solution (which can be very tough for the person itself) - you need supportive words to pull you out but in the end, the one doing the pulling is YOU. You need to accept the help and come to a realization that the only one that could help yourself is `}<em parentName="p">{`you`}</em>{`. Seeing how Rika accepted the cult boss brainwashing, only to deny all of it after coming to a realization by watching Mannen protecting her. It’s a wonderful ending to Rika’s self-hate! Kudos to the writers!`}</p>
    <Ads mdxType="Ads" />
    <p>{`Watching Episode 7 reminded me that there are problems that I could never understand, even if I put myself in their shoes. But at the very least, I could sympathize and offer supportive words. Be there for those who need it because who knows? Maybe all they need is moral support to pull themselves up from the rut. Some folks just can’t do it by themselves so we need to help them do it. Be kind to people and empathize. Empathy goes a long way :)`}</p>
    <p><em parentName="p">{`Note: To be fair, the only prompt pushing me to write this blog post is to highlight how important it is to empathize and give moral support to those who need it. Let's be kind when we can, okay? Thank you for reading.`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      