import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const RemoteLink = makeShortcode("RemoteLink");
const Image = makeShortcode("Image");
const LocalLink = makeShortcode("LocalLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`As a blogger, you need to do a ton of things in order to see your blog fly high. Sowing good relations with fellow bloggers, doing graphic editing, cleaning up your blog’s source code, etc. It’s honestly a lot of work and sometimes, you feel `}<strong parentName="p">{`overwhelmed`}</strong>{`. It’s okay to get a second pair of hands to help you out because in all honesty, this `}<em parentName="p">{`is `}</em>{`a lot of work for a one-man team. If you wish to grow your blog higher, it’s inevitable. You either pay for help or do this solo.`}</p>
    <p>{`Personal bloggers like me tend to do everything on their own. I do find the neverending work part of the fun but sometimes, I feel like throwing my computer to the wall! By the end of the day, I just wish to`}<em parentName="p">{` write content`}</em>{`! You get me, right? So much work not related to writing! Thus, I find a need to be `}<strong parentName="p">{`effective`}</strong>{`. There are things I can do on my own and things that I can’t. For the things I can’t do on my own, I look for free apps or software to do it for me.`}</p>
    <Ads mdxType="Ads" />
    <p><em parentName="p">{`Technically speaking`}</em>{` you still need to do some clicks here and there but the usefulness is `}<strong parentName="p">{`undeniable`}</strong>{`. I wouldn’t be able to work fast enough without these two apps. I’ve been using them quite a lot and I wish to share these two tools to the blogging community.`}</p>
    <h2>{`Useful Blogging Tool #1: Canva`}</h2>
    <p>{`I know many veteran bloggers have heard of this but I’m surprised that none of them actually blurted it out loud. How would I know if no one talk about it ): I had to ask before someone spilled the beans ): So allow me to explain about this wonderful tool to the newcomers!`}</p>
    <p>{`Canva is a `}<RemoteLink to="https://canva.com" mdxType="RemoteLink">{`free online graphic editing tool`}</RemoteLink>{`. You can create banners, posters and even logos with this tool easily. It has a ton of templates, images, fonts and even effects for you to fool around. What most folks do is login for a free account and start creating by using their free templates as the base. It’s very easy to use since all you need to do is drag and drop the effects, fonts and images in. It feels like a super simple tool with basic options at first but Canva understood that simplicity is `}<strong parentName="p">{`key`}</strong>{`. Most banners/graphics are pleasing to look at because they are designed minimally. I guess that’s where Canva got their money and popularity!`}</p>
    <Image alt="Canva list of canvas sizes." caption="Here's a list of canvas sizes that they have. This further reduces the need for me to find out what's the best size for a Pinterest pin, or for an Instagram story. Everything is just there for you to choose. I love tools like this. Leave all the heavy lifting to them and you do what needs to be done!" name="canva-list-of-sizes.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <p>{`The very heart of Canva is undoubtedly `}<strong parentName="p">{`templates.`}</strong>{` Choose a template, fill in the images and text and voila! For some reasons, it looks way better than the same banner created using Photoshop. Thanks to how easy it is to use the features, I could easily create banners for my blog posts within 5 minutes using Canva. It’s a great tool for any blogger who wishes to leave the creativity to a tool and just dump the images and text in.`}</p>
    <Image alt="Canvas graphics example" caption="Since Canva's free account has a limit of 1GB for assets, I cleared off most of the older graphics I did. Here are some of the banners I did for my blog! All I did was drag and drop some images, and repositioned the text." name="canva-graphic-examples-geeknabe.jpg" mdxType="Image" />
    <p>{`Canva `}<strong parentName="p">{`is free`}</strong>{` but you `}<em parentName="p">{`can`}</em>{` pay to use more features such as scheduling Pinterest pins beyond 2 weeks and enjoy more editing options. If you have a business account on Pinterest, I’m sure you have tried scheduling pins. Pinterest’s scheduling option only allow adding pins up to 2 weeks in advance. It’s simply not enough if you wish to go at Pinterest aggresively! Thus, this is where a ton of paid services plug in to fill the need, and one of those is Canva. You can create the graphics on Canva, and schedule it for Pinterest (and Instagram, if I'm not mistaken) on the same browser tab. `}<em parentName="p">{`Easy`}</em>{`. Plus, a paid account has access to a ton of paid features such as more fonts, shapes, images and even effects.`}</p>
    <p>{`I personally think the free account has more than enough fonts to use. If you wish to use icons or shapes, you could just drag and drop one of your own assets to use in Canva. No biggie. Nevertheless, I honestly think `}<RemoteLink to="https://canva.com" mdxType="RemoteLink">{`Canva`}</RemoteLink>{` is a great tool. Please give it a try!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Useful Blogging Tool #2: Keyword Surfer`}</h2>
    <p>{`Now this is the one tool I wish to introduce. Canva is just scratching the surface! As you know, the majority of your readers come from search engine searches. I’m sure they make a huge bulk of your visitors to your blog! But how do you increase it `}<strong parentName="p">{`further?`}</strong>{` I believe it’s imperative for any serious or hobbyist blogger to understand Search Engine Optimization and keywords, at least on the surface. I wrote a ton of blog posts about `}<LocalLink to="/blog/what-is-seo-and-how-to-tell-google-your-keywords/" mdxType="LocalLink">{`Search Engine Optimization (SEO) and keywords`}</LocalLink>{`, feel free to check them out `}<LocalLink to="/topic/tech/" mdxType="LocalLink">{`individually`}</LocalLink>{` later. If you are new to this SEO/Keywords thingie, feel free to look at it from this angle - `}<strong parentName="p">{`keywords`}</strong>{` are terms that your viewers `}<strong parentName="p">{`want to look for.`}</strong></p>
    <p>{`The very goal of a search engine is this - to give what the searchers want. Folks wouldn’t type in `}<em parentName="p">{`“books”`}</em>{` alone into the search bar. They would type the title of the book, followed by the action they wish to take. For example, `}<em parentName="p">{`“Where to buy Harry Potter and the Philosopher’s Stone in Canada”`}</em>{`. Aiming for these terms are what we should be doing! But only if the search engine indexed your blog properly and boosts you all the way up.`}</p>
    <p>{`Thus, in order to catch the attention of the search engine, you will need to include terms that the searches would include. As one of the avid users of Google, I’m sure you know what terms these searches would be. After all, you’re one of them! But… `}<em parentName="p">{`How sure are you?`}</em>{` If you do your keyword research properly, the resulting terms will `}<strong parentName="p">{`surprise you`}</strong>{`. It certainly surprised me! I remember trying to target a certain term, just to notice that what I assume to be `}<strong parentName="p">{`the correct term`}</strong>{` is not `}<em parentName="p">{`it`}</em>{` at all. Do you know that folks looking to binge an anime are usually looking for 1-cour anime? I was under the assumption that folks who want to binge anime are looking for anime with a ton of episodes... This is part of the reason why I made sure to double check the keywords first before mislabeling my blog posts.`}</p>
    <Ads mdxType="Ads" />
    <p>{`If you try to look for a keyword tool, you’ll be bombarded by a ton of offers… that ranges from $100 to $400. Perhaps even more! You see, to know which keywords to aim for is to conquer the Internet searches. We aren’t talking about 100K page views a month. It’s millions! If you think about it, $100 is a small price to pay on a monthly basis. But we’re the type of bloggers who are doing it for fun and personal joy. So we’re back to picking up scraps left by the big boys. But what if… `}<strong parentName="p">{`What if`}</strong>{` we don’t need to pick up the scraps at all?`}</p>
    <p>{`Sorry about the lengthy introduction but here is the tool - `}<RemoteLink to="https://surferseo.com/keyword-surfer-extension/" mdxType="RemoteLink">{`Keyword Surfer`}</RemoteLink>{`. It’s a forever free (that’s what they promote it to be) keyword and volume tool that you can install as an add-on to your Google Chrome browser. It’s super simple to use. All you need to do is type in a search term into Google, and it will automatically display similar terms along with their search volume per country on the right hand side of your browser.`}</p>
    <Image alt="Keyword Surfer Google Chrome extension add-on" caption="An example of the Keyword Surfer add-on in action. There's a ton of things happening here, so I'll leave you to explore this tool! It even tells you the website's estimated visits!" name="keyword-surfer-google-chrome-extension-example.jpg" mdxType="Image" />
    <p>{`This is how I use Keyword Surfer. First, I enter a generic search term into Google. Based on the volume and similar terms, I pick the most promising term by going down the rabbit hole. I just keep clicking on these similar terms until I’m convinced I hit the jackpot. In a way, this is a fun method to see what folks on the Internet are looking for!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`All in all`}</h2>
    <p>{`I do have more tools up my sleeve but let’s just say these are the two that I’m convinced is a winner to any bloggers out there. It’s simple to grasp, makes our job easier and gives results instantaneously. Good luck dear bloggers! I hope everyone doubles their page views on a monthly basis!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      