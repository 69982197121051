import TYPES from '../actions/actionTypes';

const initialState = {};

export default (state = initialState, { payload, type }) => {
  const newState = { ...state };

  switch (type) {
    case TYPES.SET_TOPIC_PAGE_NUMBER:
      if (typeof payload.topic !== 'undefined') {
        newState[payload.topic] = payload.page;
      }

      return newState;
    default:
      return state;
  }
};
