import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const RemoteLink = makeShortcode("RemoteLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I love to eat. Like, food is the one thing I’ll never skimp out on paying more for. I was told that I’m `}<em parentName="p">{`super`}</em>{` picky with my food, so my family always teased me that I’ll get into `}<strong parentName="p">{`big trouble`}</strong>{` if I ever need to eat cheap meals in the future. Hey, ramen is pretty good too! (I personally love Nissin cup noodles…) My love for food is kinda part of the reason why my blog’s mascot is a hotpot. Hotpot is good too y’all! ...Please stop poking my flabby stomach, thank you.`}</p>
    <Ads mdxType="Ads" />
    <p>{`This is just something I noticed in recent years, but manga about cooking or food in general has always been a rarity. After all, it’s `}<strong parentName="p">{`hard `}</strong>{`to explain the great things about food if you can’t taste or smell it. All you have is words and art to do this in a comic book. You have to be really `}<em parentName="p">{`eloquent `}</em>{`about how you portray the food in question to your readers because all in all, the greatness of your meals can only be fully appreciated in 4D. You have to talk about the aromatic whiff when the lid opens up, or the savoury texture of a meal the characters are eating. I honestly think that full appreciation of food in 2D entertainment can only be done if you can replicate the sensation in real life.`}</p>
    <Image alt="Gensou Gourmet banner" caption="Huh 2016... How did I miss you, you're such a great series Gensou Gourmet." name="gensou-gourmet-2.jpg" mdxType="Image" />
    <p>{`I’d say Shokugeki no Soma is the first to actually get it `}<em parentName="p">{`right `}</em>{`somewhat. From the opening of a lid to how you enjoy your noodles by slurping it in... The series explained and depicted a great variety of food too, both western and eastern in perfect clarity and as to what made these dishes great. I find myself nodding in agreement to every eastern dish mentioned that I’m familiar with such as `}<RemoteLink to="https://en.wikipedia.org/wiki/Mapo_tofu" mdxType="RemoteLink">{`mapo tofu`}</RemoteLink>{` and `}<RemoteLink to="https://en.wikipedia.org/wiki/Hujiao_bing" mdxType="RemoteLink">{`hujiao bing`}</RemoteLink>{` and many more. Each of these dishes are correctly portrayed which doubles my appreciation for Shokugeki no Soma.`}</p>
    <Ads mdxType="Ads" />
    <p>{`I’m going to veer off the topic for a bit and talk about `}<RemoteLink to="https://en.wikipedia.org/wiki/Laksa" mdxType="RemoteLink"><strong parentName="p">{`laksa`}</strong></RemoteLink>{`, one of the dishes that Shokugeki no Soma got right. Shokugeki explained that laksa originates from the South East Asia territory (which is correct) and the episode also mentioned how locals in Singapore love laksa. Laksa in Soma is shown to have a thick and stinky soup. The noodles are made of rice flour. So they have this `}<strong parentName="p">{`satisfying tanginess`}</strong>{` as you slurp them down. What they’ve shown is something I’d say, a `}<em parentName="p">{`common interpretation`}</em>{` of the dish. Singapore has their own interpretation and the soup leans more towards as a curry-ish soup base. I wouldn’t say it’s spicy but it definitely doesn’t stink. In Malaysia, we have our own interpretation as well. I believe we have two varieties that really stood out amongst the rest - Penang’s and Sarawak’s. Penang’s laksa is more stinky and has a sweet and spicy aftertaste. As for Sarawak’s, well, please take a look at the `}<RemoteLink to="https://www.facebook.com/redhongyi/videos/1674451192603220/?v=1674451192603220" mdxType="RemoteLink">{`Facebook video`}</RemoteLink>{` :) Rest in peace, Anthony Bourdain. If you’re in Singapore, please try `}<RemoteLink to="https://www.328katonglaksa.sg/" mdxType="RemoteLink">{`328 Katong Laksa`}</RemoteLink>{`. If you’re in Penang, please give the `}<RemoteLink to="https://www.tripadvisor.com.my/Restaurant_Review-g298303-d3327007-Reviews-Joo_Hooi_Cafe-George_Town_Penang_Island_Penang.html" mdxType="RemoteLink">{`assam laksa from Joo Hooi Cafe`}</RemoteLink>{`.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Laksa photo credit: `}<RemoteLink to="https://www.facebook.com/AnthonyBourdain/photos/a.698316083564920/934278943301965/?type=3&theater" mdxType="RemoteLink">{`here`}</RemoteLink></p>
    <Image alt="Laksa!" caption="Laksa in its full glory... Mm yes. I took this photo from Anthony Bourdain's Facebook page." name="laksa-anthony-bourdain.jpg" mdxType="Image" />
    <p>{`With the success of Shokugeki no Soma, more manga portraying the `}<em parentName="p">{`deliciousness of food`}</em>{` popped up. Some of my favourites (which I’m sure you’ve heard of) are Isekai Izakaya “Nobu” and Isekai Shokudou. But these titles did their trick well only because they featured food from their main readers’ hometown, Japan. As such, you don’t need to specifically explain `}<strong parentName="p">{`everything`}</strong>{` to your readers, they already know how it tastes like. They are both great titles to appreciate the little things in life that I enjoy about food, but I’m personally `}<em parentName="p">{`not satisfied`}</em>{` with these two titles. I wanted `}<em parentName="p">{`more`}</em>{` manga talking about food and hopefully, overwhelm isekai manga in terms of quantity.`}</p>
    <p>{`So when I came across Gensou Gourmet (幻想グルメ | `}<em parentName="p">{`Gourmet in different world`}</em>{`), I was ecstatic. While food manga talks about familiar dishes, Gensou Gourmet did what a manga can do - it’s `}<em parentName="p">{`food in a fantasy world`}</em>{`. The story starts with the usual isekai transportation from Japan to a fantasy world filled with magic and mythical beings. Shunichirou is an effective salesman back in Japan. One day on a rainy day, Shunichirou slipped on the staircase and fell while preoccupied on his phone. When he woke up, he found himself surrounded by beings that can only be described as `}<em parentName="p">{`magical.`}</em>{` Lost and alone, Shunichirou used his skills to scam folks from the slums to get by, until one day a noble approached him to ask for his business consultation skills. The noble is Bart Sakbar, one of the four great noble families in the country. His family fell from power after his father passed away. Seeking to revive House Sakbar, Bart sought advice from Shunichirou. Within a year, Bart reclaimed both wealth and status with the help of a modern human’s wisdom.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Fast-forward to Chapter 1, Shunichirou is being paid handsomely for his services. He rose from living in a slum to owning an estate and a hefty bank account. He’s pretty much set for life with very little work to do. So like every billionaire out there, he threw down a ton of money to do what he loves doing - `}<strong parentName="p">{`eat.`}</strong>{` It’s in a fantasy world and he’s rich! He even hired a cute maid, Sylphin to help with cleaning his estates. He went around with Sylphin wherever his fat wad of cash allowed him to and immerse himself with fantasy dishes. Dragon steak, phoenix egg and treant pancakes! Imagination is the only limit here, and the author knew of this. By drawing in inspiration from the real world, he made up fantasy dishes that the readers could relate to but still manage to embed `}<em parentName="p">{`a sense of wonder`}</em>{`. What does a dragon steak taste like? Well, it tastes like steak but it’s `}<em parentName="p">{`dragon `}</em>{`steak :P`}</p>
    <Image alt="One of the manga panel in Gensou Gourmet" caption="When faced with so many unknowns, the adventurer in me wants to explore them all. I totally get you Shunichirou." name="gensou-gourmet-6.jpg" mdxType="Image" />
    <p>{`But the author didn’t stop there with the dragon steak’s introduction. He branched it further and added tidbits of world-building through these fantasy dishes. Since a dragon steak is tough, it’s meant to be eaten by races with sharp fangs. Our teeth are meant to chew, not to shred and tear the meat like carnivore animals. It’s a smart inclusion to introduce the fantasy world through their food culture! As I dived into more chapters, I got more glimpses into the fantasy world that sucked both me and Shunichirou. Sugar is expensive in this world, so you could only spare a small spoon of it for treant pancakes. Japanese pancakes are fluffy so I half-expected for our characters to make it jiggle first, but Shunichirou was quick to note that you usually don’t eat tree trunks as pancakes! (It looks so good, please I need treant pancakes...)`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Treant pancakes from Gensou Gourmet" caption="Treant pancakes! Argh my tastebuds are craving for some pancakes!" name="gensou-gourmet-8.jpg" mdxType="Image" />
    <p>{`Another chapter introduces aged wine in the form of water apples. It’s kinda like an apple, but it’s made of solid water. It’s a super intriguing idea for a fantasy fruit. I was curious as to how they’ll explain the taste, so I was really surprised that Shunichirou outright declared that it’s bad. To him, it tastes like a refreshing bottle of water. Now that’s actually a fair assessment for a water apple. So he was given an `}<strong parentName="p">{`aged water apple`}</strong>{` to try instead and he loved it. Just like how I love my wine… Aged wine is always better after all!`}</p>
    <Image alt="Gensou Gourmet manga cover for volume 1" caption="What meat is that? That looks delicious! The manga covers for this series are so fun to look at. Sylphin is such an adorable beastman maid..." name="gensou-gourmet-1.jpg" mdxType="Image" />
    <p>{`The fantasy food depicted is delectable and intrigues the curious mind. What would a `}<em parentName="p">{`phoenix egg`}</em>{` taste like? Do sour fruits that turn sweet the longer you suck on it really that tasty? I find myself salivating at every dish they introduced. It’s as good as Shokugeki no Soma when it comes to describing the mouth-watering taste of a meal! But as every isekai story goes, it’s not only about food for Gensou Gourmet. Shunichirou was whisked away to an alien world, and it was luck that got him to where he is now. He’s technically the `}<em parentName="p">{`only human`}</em>{` in this world. Shunichirou is `}<strong parentName="p">{`alone`}</strong>{` in this world. He’s lucky that he has cash to last him until he breathes his last but what then? Money can buy him food and experience, but it can’t buy him relationships. As the story goes, we see Shunichirou building up his social circles gradually. He still loves his food, but his new social circles kept growing and soon, he’s not alone anymore. When our titular protagonist isn’t talking about his next meal, he’s busy befriending a new denizen from this fantasy world. It’s a nice balance because all in all, you can’t always be talking about fantasy food only.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="To live is to eat... or was it to eat is to live?" caption="That is such a big squid leg!! Oof it looks so good. I need me some seafood now." name="gensou-gourmet-3.jpg" mdxType="Image" />
    <p>{`The story takes it’s time to explain the denizens, and how research into mana has made their livelihood better. We see powerful, mythical dragons that used to be weapons for war now trying to fit in with the new peace. Mana was once used only to cast magic but new ideas and research now steered it away from said idea. Magicians are now researching to use mana as a source of energy to benefit society. Sounds a bit like our world, to be fair. So you have fantasy food culture and a changing society to explore from the eyes of a mortal from our world. It’s both wondrous and fascinating to read. And have I mentioned the art direction yet? The streets are always busy and lively, background art is chock full of fantasy-like contraptions and it’s people have varied looks. The character designs (except for our main cast) leans more to beast-like characteristics. This series is a mix of everything I love in a fantasy series. Magic should be `}<em parentName="p">{`wondrous,`}</em>{` races should have unique `}<strong parentName="p">{`cultures`}</strong>{` and fantasy civilisation should be… well, `}<strong parentName="p">{`fantasy`}</strong>{`!`}</p>
    <Ads mdxType="Ads" />
    <Image alt="I'm fish now" caption="I want to dive into that tank" name="gensou-gourmet-4.jpg" mdxType="Image" />
    <Image alt="Gensou Gourmet showcasing it's fantasy denizens." caption="Busy streets and fantasy beings. Hell yea." name="gensou-gourmet-7.jpg" mdxType="Image" />
    <p>{`While only 12 chapters are translated at the time of this writing, the series’s raw has up to 42 chapters and it’s completed. It got a happy ending in case you’re wondering! Give this manga a try because it’s the good stuff I’ve been wanting to read in a long time. Try `}<strong parentName="p">{`Dungeon Meshi`}</strong>{` too while you’re at it.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      