import { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../styles/globals';
import TinyImageCard from '../TinyImageCard';
import Pagination from '../Pagination';
import InFeedAd from '../Ads/InFeed';

const Section = styled.section``;

const Container = styled.div`
  padding: 50px 0;
`;

const StyledInFeedAd = styled(InFeedAd)`
  margin: 20px auto;
  width: 100%;
`;

const InnerContainer = styled.div`
  padding: 35px 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  grid-gap: 20px;

  ${StyledInFeedAd} {
    grid-column: 1 / span 4;
  }

  ${$.screen.tabletA} {
    grid-template-columns: repeat(2, 1fr);
    ${StyledInFeedAd} {
      grid-column: 1 / span 2;
    }
  }

  ${$.screen.tabletB} {
    grid-template-columns: repeat(3, 1fr);
    ${StyledInFeedAd} {
      grid-column: 1 / span 3;
    }
  }

  ${$.screen.mobile} {
    grid-template-columns: repeat(1, 1fr);
    ${StyledInFeedAd} {
      grid-column: 1 / span 1;
    }
  }
`;

const Content = ({ items }) => {
  const totalCards = 20;
  const [slice, setSlice] = useState({ start: 0, end: totalCards });

  return (
    <Section>
      <Container>
        <StyledInFeedAd />
        <InnerContainer>
          {items
            .slice(slice.start, slice.end)
            .map(
              (
                {
                  slug,
                  timeToRead,
                  frontmatter: { banner, excerpt, published, title, tags },
                },
                index
              ) => {
                const showAd =
                  index !== 0 &&
                  index !== items.length - 1 &&
                  (index + 1) % 9 === 0;

                return showAd ? (
                  <StyledInFeedAd />
                ) : (
                  <TinyImageCard
                    key={title}
                    img={banner}
                    tag={tags[0]}
                    timeToRead={timeToRead}
                    title={title}
                    excerpt={excerpt}
                    slug={`/${slug}/`}
                    date={published}
                  />
                );
              }
            )}
        </InnerContainer>
        <Pagination
          setSlice={setSlice}
          totalItems={items.length}
          maxItem={totalCards}
        />
      </Container>
    </Section>
  );
};

Content.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Content;
