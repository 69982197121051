import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const RemoteLink = makeShortcode("RemoteLink");
const Image = makeShortcode("Image");
const Ads = makeShortcode("Ads");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I got nominated again for another blogger award. This time, the nomination came from `}<RemoteLink to="https://www.yualexius.com/2020/04/sunshine-blogger-award.html" mdxType="RemoteLink">{`YuAlexius.`}</RemoteLink>{` I apologize for not noticing the nomination and let it be without a reply for 2 months!`}</p>
    <p>{`Let me list out the rules for this award:`}</p>
    <ul>
      <li parentName="ul">{`Thank the blogger who nominated you in the blog post and link back to their blog. (DONE)`}</li>
      <li parentName="ul">{`Answer the 11 questions the blogger asked you.`}</li>
      <li parentName="ul">{`Nominate 11 new blogs to receive the award and write them 11 new questions.`}</li>
      <li parentName="ul">{`List the rules and display the Sunshine Blogger Award logo in your post and/or on your blog.`}</li>
      <li parentName="ul">{`Awryt let’s see what are the questions from YuAlexius…`}</li>
    </ul>
    <h2>{`1. If you could choose 5 anime characters to live inside a house for a lifetime, who are they?`}</h2>
    <p>{`I am rewatching a really quirky anime called Cautious Hero. The hero in this anime, Seiya is a super cautious, extra-paranoid hero that wouldn’t stop getting suspicious of everyone and everything. Now imagine… FIVE OF SEIYA IN A HOUSE. That would be hilarious to see! They would probably start blasting Hell’s Fire or pour Holy Water at each other (just because).`}</p>
    <Image alt="Cautious Hero anime" caption="You can't hit a Goddess on her head! That's BLASPHEMY, SEIYA!" name="cautious-hero-1.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`2. Do you like Chinese anime? If yes, which one is your favorite?`}</h2>
    <p>{`There is this one anime about Nezha that I watched during my childhood years. It’s a movie and I’m afraid I don’t remember the name. It’s my favourite on how it tackles the coming of age for Nezha. A quick google brought up the new Nezha CG movie, I couldn’t seem to find any traces of the old one ):`}</p>
    <h2>{`3. How old are you when you realized that anime had become an integral part of your life and in what ways it became important to you?`}</h2>
    <p>{`I started watching anime at 12 years old and I haven't stopped watching since then. Anime has been a part of my routine since forever! Manga came in later, followed by light novels. Har har, I really don’t have many hobbies other than anime when I was young. Books are expensive if you keep buying them (I loved reading back then), so anime episodes uploaded to Youtube are a godsend.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`4. If one day you’ll have the power to change the world: would you change it with diplomacy or revolution?`}</h2>
    <p>{`Revolution. If it can be done through diplomacy, our world would be in peace now. So Japan needs to hurry up with the development of Gundam for warfare :P`}</p>
    <h2>{`5. Among various anime characters, who you think is your spirit animal?`}</h2>
    <p>{`I believe this Sanrio mascot got an anime somewhat, so it should count! I love Gudetama… Just show me Gudetama merch and I’ll be more than happy to pay for it. He’s lazy and not motivated… Sounds just like me.`}</p>
    <Image alt="gudetama being lazy" caption="Stoopppp... Let this lazy egg go" name="gudetama-1.jpg" mdxType="Image" />
    <h2>{`6. What’s your dream job and why you choose it?`}</h2>
    <p>{`A rich, stay-at-home girl. I get to do whatever I want, whenever I want. Perfection. … I joke. Ok, maybe a partial joke. My dream job has always been the same - to do something fun with computers. Creating graphics? Creating apps? It didn’t matter as long as I get to include a computer in it. I didn’t have a strong image of what job it is but all I knew is I wanted to do something with computers, so here I am as a web developer.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`7. If you’ll direct your own anime someday, what title will you give it?`}</h2>
    <p><em parentName="p">{`I am a blogger who loves isekai anime and somehow I got transported into one?!!`}</em></p>
    <h2>{`8. When did you start blogging, and what important lessons you learn from it?`}</h2>
    <p>{`I started blogging many years ago. When Friendster and Blogspot came into the picture, I would start a blog on each platform and share among my friends. It’s a fun public diary sharing session! Of course, the blogging scene has changed and so did my interest. I started this blog in October 2019 and along the way, learned a ton of lessons. I hope these tips help you out as well!`}</p>
    <ul>
      <li parentName="ul">{`You need to do networking. Talk to fellow bloggers, exchange links and write guest posts if need be! Getting word out is imperative to pushing your blog traffic higher.`}</li>
      <li parentName="ul">{`Understand that while you are writing for yourself, you need to care about your readers too. Make sure your blog is readable, easy to navigate and has a decent user experience!`}</li>
      <li parentName="ul">{`Be patient. It takes time to get to where you want to go`}</li>
    </ul>
    <h2>{`9. Tell me which country you came from by dropping 3 clues/hints only?`}</h2>
    <p>{`I won’t be dropping hints haha! I’m from Malaysia :)`}</p>
    <Ads mdxType="Ads" />
    <h2>{`10. Which anime do you think is the most revolutionary?`}</h2>
    <p>{`This is a trick question, right? Damn, a revolutionary anime… Let’s see, I think Sailormoon is revolutionary in its own right. A shoujo series that lets the girls become their own heroes in sailor uniforms! Back then, it’s a really unique series that I think sparked the start of the magical girls era. Dragon Ball is in its own league too when it comes to being a revolutionary series during its time. I don’t think any series out there can emulate what Dragon Ball did to shounen fight scenes, power levels, etc.`}</p>
    <h2>{`11. If you’ll go isekai, which anime world would you like to live and why?`}</h2>
    <p>{`Thank god you didn’t ask `}<em parentName="p">{`which isekai anime`}</em>{` because each one of these worlds are terrible to live in as a female introvert. I would choose to live in the world of Pokemon. Really, there’s no other answer for these questions :D POKEMON! Woo! I’m gonna be a Pokemon trainer!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`The end`}</h2>
    <p>{`And that’s the end! I answered all the questions! It was fun, thank you for the nomination Yu! I’m sorry but I don’t think I’ll nominate any other person for this. If anyone reading this post would like to try, please go ahead and make your own Sunshine Blogger Award answer post!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      