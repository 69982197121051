import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const RemoteLink = makeShortcode("RemoteLink");
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This is written for absolute beginners who wanted to style their WordPress blog with the CSS stylesheet option, but didn’t know where to begin. First and foremost, let’s get things straight - `}<strong parentName="p">{`CSS is not hard`}</strong>{`. It is not a `}<em parentName="p">{`martian language`}</em>{` that does a different behaviour every time you refresh your browser tab! Nuh uh, it has rules and is just very strict about them. Follow them and you’ll soon bend CSS to your will! I’ll write by assuming that everyone who reads this just wants to know `}<em parentName="p">{`how to get it done`}</em>{` without `}<strong parentName="p">{`knowing the tech behind it`}</strong>{`. I try my best to put it as layman as possible, please do tell me if you’re having trouble in the comments below or reach me through `}<RemoteLink to="https://twitter.com/geeknabe" mdxType="RemoteLink">{`Twitter`}</RemoteLink>{`.`}</p>
    <Ads mdxType="Ads" />
    <p><em parentName="p">{`Note: Please try this on a desktop since we will be needing browser tools available only on desktops! You will need to access the `}<RemoteLink to="https://developers.google.com/web/tools/chrome-devtools" mdxType="RemoteLink">{`Developer Tools`}</RemoteLink>{`. Try to use Chrome to follow along, but any other browser works fine.`}{`*`}{`*`}{`Second Note: I am referring to WordPress.com most of the time. If you're hosting WordPress on your own, feel free to ignore my free/paid plan talk!`}</em></p>
    <p>{`The easiest way to get things up and running is by showing the end product immediately, so I decided to pick a `}<RemoteLink to="https://mechanicalanimereviews.com/" mdxType="RemoteLink">{`fellow blogger’s blog`}</RemoteLink>{` to test things out on! Thanks Scott!You can add your custom CSS into the theme by clicking on `}<em parentName="p">{`Design > Themes > Customise `}</em>{`or`}<em parentName="p">{` Design > Customise`}</em>{` to begin customizing! Write your custom CSS here! It was then I ran into a roadblock.`}</p>
    <Image alt="Stingy free WordPress plan user." caption="Yes I am a free plan user." name="wordpress-css-options.jpg" mdxType="Image" />
    <Image alt="CSS Customizer option" caption="If you’re a paid plan user, you should be able to see an option to write your CSS code in. The CSS Customizer option should look something like this." name="wordpress-custom-css-options.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <p>{`I decided to look around to see what other options I got. Turns out I have no options at all because WordPress disabled custom CSS changes if you’re on a free plan! WordPress wants me to pay but I am adamant on doing this free. A quick Googling tells me I could either use the Customize option (which I have explained in the beginning) or add a CSS plugin if you’re a paid plan user. Please consider using a plugin such as `}<RemoteLink to="https://wordpress.org/plugins/simple-custom-css/" mdxType="RemoteLink">{`Simple Custom CSS`}</RemoteLink>{` to prevent the CSS changes getting wiped out if you change your theme!`}</p>
    <p>{`Alright, now that we have settled the problem of `}<strong parentName="p">{`getting the CSS into your blog`}</strong>{` (I am sorry, free plan users), let’s start by explaining what CSS is.`}</p>
    <h2>{`What is CSS all about`}</h2>
    <p>{`CSS stands for Cascading Style Sheet, whom the naming will make more sense after fooling around with it. Your webpage is `}<strong parentName="p">{`generally`}</strong>{` made up of 3 components - HTML, CSS and JavaScript. HTML is the structure of the webpage, JavaScript is what makes your webpage tick and CSS is how your webpage dresses up. So basically speaking, you’re here to understand how to make your webpage dress up fashionably!`}</p>
    <Image alt="Forget about the details, you're here to think simple." caption="I don’t careeee about the details! And you shouldn’t too!" name="wordpress-css-no-need-for-details.jpg" mdxType="Image" />
    <p>{`We have settled `}<strong parentName="p">{`what is CSS`}</strong>{` all about, and `}<strong parentName="p">{`how to get the CSS changes into your blog`}</strong>{`. Let’s talk about the details of `}<strong parentName="p">{`how to start writing CSS`}</strong>{` - selecting the element you wish to change. When I say `}<em parentName="p">{`element, `}</em>{`I mean the very blocks your webpage is composed of. For example, the text, title, date and even the cards!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`How to select elements`}</h2>
    <p>{`Before we even start styling things, we need to tell WordPress what to change first, right? In CSS, there are generally 2 ways you can do this. Each element on a WordPress theme has two types of identifiers called `}<strong parentName="p">{`id`}</strong>{` and `}<strong parentName="p">{`class.`}</strong>{` These two identifiers don’t do anything in general when left alone on your browser, but it’s there to let you `}<strong parentName="p">{`select the element`}</strong>{` `}<em parentName="p">{`specifically`}</em>{`. Think of it as a super specific name these elements have. If I say, `}<em parentName="p">{`“Hey paragraph!”`}</em>{` on a webpage, then every paragraph would turn around to look at me.`}</p>
    <p>{`But I simply wanted a single paragraph to answer me! To do that, I would change the way I call it to `}<em parentName="p">{`“Hey, p-1!”.`}</em>{` This way, only the paragraph with that name would answer me. But how do we ascertain each paragraphs’ names? You do so by accessing your `}<strong parentName="p">{`Developers Tool`}</strong>{` on your desktop browser. You could quickly tap on `}<strong parentName="p">{`F12`}</strong>{` to bring it up on most browsers, or go to `}<em parentName="p">{`Settings > Developer Tools`}</em>{` to bring it up.`}</p>
    <Image alt="Settings on the Google Chrome's desktop version." caption="Here’s how to bring it up on Chrome. Click on the triple dot menu, go to More Tools and click on Developer Tools." name="chrome-developer-tools-settings.jpg" mdxType="Image" />
    <p>{`You should see a sidebar popping up. Once it popped up, look for the icon below and click it. You could press the shortcut `}<em parentName="p">{`CMD + SHIFT + C`}</em>{` on Mac/`}<em parentName="p">{`CTRL + SHIFT + C`}</em>{` on Windows too. The icon is called the Inspector Tool. Click on any elements after that and the browser will automatically highlight the element in the Developers Tool for you.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Inspector Tool icon!" caption="Here’s how the icon would look like in most browsers. I have highlighted the icon with a red box. You should have no trouble finding it! I usually smack the shortcut key to quickly access it in most cases." name="chrome-dev-tools-inspector-tool-icon.jpg" mdxType="Image" />
    <Image alt="Once inspected, the element is quickly highlighted on the Dev Tool." caption="I clicked on the title using the Inspector Tool and it highlighted for me where the element is!" name="wordpress-site-title-class.jpg" mdxType="Image" />
    <p>{`By using the Inspector Tool on the site’s title, I could tell that it has the `}<strong parentName="p">{`class`}</strong>{` name `}<em parentName="p">{`site-title.`}</em>{` This is what you should write to change how your site’s title looks!`}</p>
    <pre>{`.site-title { text-transform: uppercase; }`}</pre>
    <p>{`Now the line above will change the look of your site title to this image below!`}</p>
    <Image alt="The title is now uppercase!" caption="The site's title is now uppercase! Woo!" name="wordpress-site-title-css-changed.jpg" mdxType="Image" />
    <p>{`Now let’s practice a bit more to get used to this. Let’s pick the navigation bar below instead. Click on the Inspector Tool icon, and click on the navigation bar. You should see the highlight similar to the image below.`}</p>
    <Image alt="Inspecting the webpage's element using the Inspector Tool." caption="I clicked on the Inspector Tool, and this is what’s being highlighted. It's super handy!" name="wordpress-navbar-element.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <p>{`You’ll notice that this element has both the `}<strong parentName="p">{`id`}</strong>{` and the `}<strong parentName="p">{`class`}</strong>{`! You could pick to select either the id, class or both. Let’s explore doing all three ways! Here’s how you select the element’s id alone. Notice that it has a hashtag in front of it’s id name.`}</p>
    <pre>{`#primary-menu { background-color: white; }`}</pre>
    <p>{`The code below is how you would write to select the class alone. Notice that it has a full stop in front of its class name.`}</p>
    <pre>{`.menu { background-color: white; }`}</pre>
    <p>{`And here’s how you would write to select both the id and class together. Make sure the id and class are stuck together!`}</p>
    <pre>{`#primary-menu.menu { background-color: white; }`}</pre>
    <p>{`This is how it would look like once your CSS works!`}</p>
    <Image alt="White navbar!" caption="I made the navigation bar's background white!" name="wordpress-navbar-element.jpg" mdxType="Image" />
    <p>{`Practice selecting the elements a few more times and you should be able to get it, it’s that simple. Hashtag (#) represents the id name and full stops (.) represents the class name. If you want to use them together, stick the names together!`}</p>
    <p>{`And that’s it. That’s how you select these elements without confusing them up. Let’s jump straight into action by listing out the different styling looks you could do. Feel free to combine them up!`}</p>
    <p><em parentName="p">{`Note: Make sure to end each line of CSS with a semicolon (;) if you’re wondering why it isn’t working properly!`}</em></p>
    <Ads mdxType="Ads" />
    <h2>{`Whip up something different with the text`}</h2>
    <p>{`text-transform changes your text to use uppercase, lowercase or capitalize. It’s great if you’re too lazy to make sure it is capitalized, lowercase or uppercase because of human error, just smack this CSS line in. I added three different possible CSS lines you could use, so please give each of them a try! Make sure to select the elements you wish to style and add either of these lines in.`}</p>
    <h3>{`text-transform`}</h3>
    <br />
    <pre>
  text-transform: lowercase;
  <br />
  text-transform: uppercase;
  <br />
  text-transform: capitalize;
    </pre>
    <Image alt="WordPress CSS Changes 1" caption="I added text-transform: lowercase into this element's CSS and look how everyting is in lowercase. Using CSS does not change the text directly. It's just a visual effect." name="wordpress-css-changes-1.jpg" mdxType="Image" />
    <h3>{`letter-spacing`}</h3>
    <p>{`Add this line in, and your text will have spacing between each character. Great if you think the default font is too tight for each character and would like to space them up. Use positive numbers to space them up, and use negative numbers to make the characters closer.`}</p>
    <pre>
  letter-spacing: 1px;
  <br />
  letter-spacing: -1px;
    </pre>
    <Image alt="WordPress CSS Changes 2" caption="I spaced up the characters in this blog post's description. See how wide apart the characters are starting from 'Content Creator...'?" name="wordpress-css-changes-2.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h3>{`line-height`}</h3>
    <p>{`Paragraphs between each line can be too close for comfort. Use this to space them up or make them even closer (for aesthetics!).`}</p>
    <pre>line-height: 10px;</pre>
    <Image alt="WordPress CSS Changes 3" caption="I made the each line in this blog post's title closer than usual. Play with the numbers to get the height you want!" name="wordpress-css-changes-3.jpg" mdxType="Image" />
    <h3>{`text-shadow`}</h3>
    <p>{`Wanna add some shadow to your text? Yes you can with CSS. Phew, CSS wasn’t this good when I first started my web development internship! This text-shadow only needs 3 values separated with a space to work but you can add a 4th value for a blur effect. E.g. 3px 8px pink means offset horizontally by 3px, vertically by 8px and as the colour pink. Negative values are a-okay too!`}</p>
    <pre>
  text-shadow: 3px 8px pink;
  <br />
  text-shadow: -3px -8px pink;
  <br />
  text-shadow: 3px 8px 6px pink;
    </pre>
    <Image alt="WordPress CSS Changes 4" caption="Without blur. I used text-shadow: 3px 8px pink; to create this effect." name="wordpress-css-changes-4.jpg" mdxType="Image" />
    <Image alt="WordPress CSS Changes 5" caption="With blur. I used text-shadow: 3px 8px 6px pink; to create this effect." name="wordpress-css-changes-5.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`Add shadow to your boxes`}</h2>
    <p>{`One of my favourite CSS effects to do is box-shadow. It allows me to add a shadow at the back of a box. It makes the box look popped up, so folks would know that this is a button you can click. There are 4 values separated by a space that you need to add to box-shadow to make it work properly - offset blur spread color. E.g. 0 3px 8px #000 means zero offset, blur by 3px, spread by 8px, and the colour is black. #000 is the hex code for black! I suggest using `}<RemoteLink to="https://www.google.com/search?q=color+picker" mdxType="RemoteLink">{`Google’s colour picker`}</RemoteLink>{` to get the hex code for the colour you need.`}</p>
    <h3>{`box-shadow`}</h3>
    <pre>
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
  <br />
  box-shadow: 0 5px 5px black;
    </pre>
    <Image alt="WordPress CSS Changes 6" caption="This entire card has a drop-shadow, did you notice? It's kinda like a shadow at the back of the card. Slightly subtle too. My favourite CSS line is this -> box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15); You'll find it plastered everywhere in this blog if you inspected my blog post cards." name="wordpress-css-changes-6.jpg" mdxType="Image" />
    <h2>{`Make your box corner rounder`}</h2>
    <p>{`This often goes together with my box-shadow. I never really liked seeing my boxes have sharp corners. I use border-radius to make the corners rounder. I usually put 5px or maybe 10px for a subtle rounded corner.`}</p>
    <Ads mdxType="Ads" />
    <h3>{`border-radius`}</h3>
    <pre>
  border-radius: 30px;
  <br />
  border-radius: 999px;
    </pre>{' '}
    <Image alt="WordPress CSS Changes 7" caption="If you set a really high number for your border-radius, you get a circle instead! Play with the numbers to get the effect you want!" name="wordpress-css-changes-7.jpg" mdxType="Image" />
    <h2>{`Add filter to your images`}</h2>
    <p>{`Now this only affects images. Wanna add some filter effects such as grayscale or blur to your images? You can with CSS! These filters will not change your original image. It just adds a bit of flair on the images itself when seen in a webpage. If you see me using the values 0, 0.1 or 1, it just means 0%, 10% and 100%. So when I set the grayscale to 1, it means I am setting the grayscale to 100%. Feel free to play with the numbers to achieve the effect you want! Here are some of the filters you could get started with.`}</p>
    <h3>{`filter`}</h3>
    <pre>
  filter: blur(10px);
  <br />
  filter: grayscale(1);
  <br />
  filter: brightness(0.2);
  <br />
  filter: contrast(0.5);
  <br />
  filter: invert(1);
  <br />
  filter: saturate(0.5);
  <br />
  filter: opacity(0.4);
  <br />
  filter: sepia(0.5);
    </pre>
    <Image alt="WordPress CSS Changes 8" caption="I set the brightness to 0.2 for this image." name="wordpress-css-changes-8.jpg" mdxType="Image" />
    <Image alt="WordPress CSS Changes 9" caption="I have cheekily set this image’s contrast to 0." name="wordpress-css-changes-9.jpg" mdxType="Image" />
    <Image alt="WordPress CSS Changes 10" caption="I set this image’s filter to invert 1!" name="wordpress-css-changes-10.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`Goodbye boring lists`}</h2>
    <p>{`You know those little dots in a list? Apparently you can change those little dots to something else! I only know of the square and circle values, but a quick Google reminded me folks who implemented CSS themselves are always prepared for anything. Try using `}<em parentName="p">{`armenian `}</em>{`instead of `}<em parentName="p">{`decimal`}</em>{` to see what I mean. Here’s the `}<RemoteLink to="https://www.w3schools.com/cssref/tryit.asp?filename=trycss_list-style-type_all" mdxType="RemoteLink">{`full list of list-style-type values`}</RemoteLink>{` you can use!`}</p>
    <h3>{`list-style-type`}</h3>
    <pre>
  list-style-type: decimal;
  <br />
  list-style-type: square;
  <br />
  list-style-type: upper-roman;
    </pre>
    <Image alt="WordPress CSS Changes 11" caption="I made them square-ish instead of the usual boring dots." name="wordpress-css-changes-11.jpg" mdxType="Image" />
    <h2>{`All in all`}</h2>
    <p>{`Now that we know how to add CSS to the WordPress blog, as well as writing said CSS! Easy. I hope the styling options listed here really showed you how far CSS has come since the old days. If you’re wondering why these examples aren’t what you expected, that’s because I picked the rarely heard CSS styling options! Didn’t expect CSS could do all of these, didn’t ya?`}</p>
    <Ads mdxType="Ads" />
    <p>{`And I stand by my title that says `}<strong parentName="p">{`Basic CSS`}</strong>{`! I mean, all examples I’ve shown did the job with only one line. I hope this blog post helps you to style your WordPress blog, or at least give you a better idea how to do so. If you wish to do an effect that isn’t listed here, you should google the term `}<em parentName="p">{`“CSS how to”`}</em>{`, followed by the effect you wish to do. Chances are someone has already asked before you, so that increases your chance of finding a hit is higher if you word your search term in that manner. Thank you for reading.`}</p>
    <Image alt="How to search WordPress CSS How to" caption="CSS How to is what you should google, followed by the effects you wish to create. All the best!" name="google-search-wordpress-css.jpg" mdxType="Image" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      