import PropTypes from 'prop-types';
import styled from 'styled-components';

import $ from '../../../styles/globals';
import Chapters from './Chapters';
import Info from './Info';

const Section = styled.section``;

const Container = styled.div`
  width: 700px;
  margin: 0 auto;

  ${$.screen.mobile} {
    width: 100%;
  }
`;

const Index = ({ chapters, summary, genres, pairings, rating, status }) => (
  <Section>
    <Container>
      <Info
        chapters={chapters}
        summary={summary}
        genres={genres}
        pairings={pairings}
        rating={rating}
        status={status}
      />
      <Chapters chapters={chapters} />
    </Container>
  </Section>
);

Index.defaultProps = {
  pairings: [],
};

Index.propTypes = {
  chapters: PropTypes.arrayOf(PropTypes.object).isRequired,
  summary: PropTypes.string.isRequired,
  genres: PropTypes.arrayOf(PropTypes.string).isRequired,
  pairings: PropTypes.arrayOf(PropTypes.string),
  rating: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};

export default Index;
