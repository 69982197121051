import styled from 'styled-components';

import $ from '../../../../styles/globals';

const Container = styled.div`
  position: relative;
`;

const Circle = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${$.brown4};
  border-radius: 999px;
  border: 2px solid ${$.brown4};
`;

const LastCircle = () => (
  <Container>
    <Circle />
  </Container>
);

export default LastCircle;
