import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXFanfiction/Chapter/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Divider = makeShortcode("Divider");
const MessageBox = makeShortcode("MessageBox");
const AuthorNote = makeShortcode("AuthorNote");
const RemoteLink = makeShortcode("RemoteLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`“Wha-what? Me too?”`}</p>
    <p>{`It was almost 7am and Byleth had slept like a corpse, even missing her daily get-ready-as-Reus 5:30am alarm. Until her dad gave her a call and said he and Reus were walking towards her room as they spoke. The one that Alois insisted she stay in.`}</p>
    <p>{`“Yes, you too,” Jeralt said, dropping himself on her bed. “I mentioned to Rhea that my daughter was here as well and she `}<em parentName="p">{`insisted`}</em>{` on meeting you.” She looked at Reus in panic, he shrugged.`}</p>
    <p>{`“She’s going to find out the Reus she’s been seeing isn’t Reus, dad.”`}</p>
    <p>{`Jeralt sat in silence for a couple of seconds and let out a deep breath. “That..might not be a bad thing though.”`}</p>
    <Ads mdxType="Ads" />
    <p>{`“It is If I get expelled from this school,” Reus said, crossing his arms.`}</p>
    <p>{`“I don’t think so,” Jeralt answered firmly. “I never told you guys but... your Principal Rhea—how should I say this—she raised your mother.”`}</p>
    <p>{`Reus pinched the bridge of his nose. A knit forming between his brows. “Why is it only `}<em parentName="p">{`now`}</em>{` that all these...`}<em parentName="p">{`secrets`}</em>{`—were you never going to tell us? You said mum was an orphan!”`}</p>
    <p>{`“To be absolutely frank with you, and you know how frank I am, I don’t know much about her either. We got married pretty quickly, then you two came along and—”`}</p>
    <p>{`The air in the room grew heavy. Jeralt stopped talking, sensing their frustration despite their expressionless faces. But he knew his kids enough to know they were quite displeased with this revelation.`}</p>
    <p>{`“I’m sorry. But yes, that’s the truth. She was an orphan but Rhea raised her. Which is why she’s lived here her whole life in Garreg Mach’s and eventually taught here.”`}</p>
    <p>{`“Let me get this straight.” Reus cleared his throat, “If she raised mum, technically— ”`}</p>
    <p>{`“—Principle Rhea’s our grandmother?” Byleth’s eyes bulged wide.`}</p>
    <p>{`“I mean if you put it that way, I guess so.”`}</p>
    <p>{`Byleth and Reus looked at each other.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“And that’s why I won’t get expelled? Because she’s our sort-of grandmother?” Reus raised an eyebrow.`}</p>
    <p>{`“She looks really young, though. Like, younger than you, dad.” There was not one wrinkle on her skin, and yet this woman was their grandmother?`}</p>
    <p>{`“She’s a mystery to me as well. Look, she wants to see the both of you okay? And she’s done worse than letting her adopted granddaughter dress as a boy in her school.”`}</p>
    <p>{`“How would you know?” Byleth asked.`}</p>
    <p>{`“I was her head of security for years, kid.” Jeralt had a pained look on his scarred face. She didn’t press the issue further. “If she asks, just tell her the truth.”`}</p>
    <p>{`Both Reus and Byleth sighed simultaneously, they didn’t have a choice but to meet their supposed-grandmother, Principal Rhea at 8am.`}</p>
    <p>{`“It’s been years since I last set eyes on this place. To be forced to see her now…” Jeralt took in the view of Rhea’s office, as though it was a sight to behold, then bent over like a deflated balloon. A sigh escaped his lips.`}</p>
    <p>{`“Forced?” Byleth said, dressed as herself—a feminine contrast to Reus who fitted really nicely in the uniform she’d been wearing the past week. “I thought you wanted to thank her?”`}</p>
    <p>{`“Yes, thank her out of force.” Jeralt looked at Reus—the sole reason for his visit, who looked away awkwardly. Byleth cleared her throat as Principal Rhea walked in, with the sternest looking man she’d ever seen in her life.`}</p>
    <p>{`“Thank you for your patience, Jeralt. My name is Seteth. I am the Deputy Principal.”`}</p>
    <p>{`“Right. Hello.” Jeralt replied. Well, he must’ve been somewhat new if her dad didn’t know him.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Principal Rhea had a dignified air around her as she stood before them with her hands placed neatly in front of her. She was a tall and slender woman, dressed in a crisp, white pants suit and a navy blue, silk shirt. She had long flowing green hair partially held up by what looked like a large, golden barrette at the back of her head. Her voice exuded regalness as she spoke.`}</p>
    <p>{`“It has been a long time, Jeralt. I wonder...was it the will of the Goddess that we have another chance of meeting like this?” And boy was she melodramatic.`}</p>
    <p>{`“Forgive my silence all these years,” their dad replied. Byleth was shocked at the way he spoke. Was this their dad? She saw Reus’ lips twitch from the corner of her eye, trying to hold in his laugh.`}</p>
    <p>{`“So I see. It is a wonderful pleasure seeing your children, although the circumstance of this meeting was unfortunate, I am relieved we are able to offer a place for your son to continue his education.” Principal Rhea shifted her body slightly at Reus. “This is your son I presume?”`}</p>
    <p>{`“Yes.” Jeralt stepped forward and placed a hand on Reus’s shoulder. “This is Reus.”`}</p>
    <p>{`“Ah, Reus is your middle name, if I remember correctly?” Rhea held out a delicate hand and Reus gingerly shook it twice.`}</p>
    <p>{`“Y-yes. You remembered.” Jeralt then gestured at Byleth. “And this is my daughter, Byleth.” For a split second it looked as if Rhea’s lips quivered before holding her hand out for Byleth to shake as well. As they exchanged handshakes, for reasons unknown to Byleth, Rhea peered deeply into her eyes. Searching. Another gentle hand covered Byleth’s own, as though in comfort.`}</p>
    <p>{`“How are you settling in, Reus?” The man named Seteth spoke up as Byleth and Rhea let go of each other.`}</p>
    <p>{`“Great. I like it here,” Reus replied in his pleasant voice.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Good.” Seteth said in a strange curt but courteous voice. “I expect you to be on your best behaviour. And as for your conduct in your previous institution, well, we all hope it will not be repeated. This school has a reputation to uphold and it will not be besmirched by unruly conduct regardless of location.”`}</p>
    <p>{`“Of course,” Reus responded with a polite smile.`}</p>
    <p>{`“Very good. I apologise for I must step away for now. You are welcome to stay for as long as you like Jeralt, Byleth. Our guest house will always be open for you. Free of charge, naturally.”`}</p>
    <p>{`“Oh no, I cannot impose like that. You already did me this big favour I—”`}</p>
    <p>{`“Please, I insist.” Principal Rhea smiled, despite Seteth objecting quietly in her ear. “I will inform Alois. I implore you to speak to him whenever it is convenient for you.”`}</p>
    <p>{`“Uh, sure. I mean, thank you.” Jeralt bowed. Principal Rhea excused herself, with Seteth dipping his eyebrow at Jeralt as they left the room.`}</p>
    <p>{`Byleth let go of the breath she was holding and looked down at the hand Principal Rhea shook. “That was a… strange experience.”`}</p>
    <p>{`“You can say that again. I have a feeling that Seteth-man might be watching me closely, or rather, you, for that matter.” Reus smirked at her. “He doesn’t seem too fond of my attending here.”`}</p>
    <p>{`“Yes, well, you did get in from just a phone call, you know,” Jeralt said, rubbing the back of his head. “Almost all the students here get in with either exceptional grades, and or with large financial backgrounds.” Jeralt snorted. “We’re neither.”`}</p>
    <p>{`“Well then! It’s clear that By’s team deserves the sponsorship more. The kids here can obviously afford it—they shouldn’t even be competing for it,” Reus said as-a-matter-of-factly.`}</p>
    <p>{`“You do know we’re talking about `}<em parentName="p">{`the`}</em>{` Garreg Mach University right?” Jeralt said.`}</p>
    <p>{`“Then how are we affording this?” Reus asked, sounding concerned.`}</p>
    <p>{`“To put it simply, she gave me a discount. Staff price or something I don’t know—look kid, don’t worry about it. Just don’t muck this up.”`}</p>
    <Ads mdxType="Ads" />
    <p>{`“He’s talking to you.” Reus elbowed her with a cheeky grin.`}</p>
    <p>{`“`}<em parentName="p">{`Both`}</em>{` of you,” Jeralt said firmly. Byleth glared back at Reus.`}</p>
    <p>{`“Anyway Reus, do you want to swap back now?” If they went back to her room to change, Reus could sneak back out with their dad, since everyone was in class now.`}</p>
    <p>{`“Whoever Reus is going to be, just watch out for Principal Rhea too. I know she won’t expel you, but I also don’t know what she has planned. Everything is going too smoothly and I’m suspicious.`}</p>
    <p>{`Byleth and Reus smirked at each other.`}</p>
    <p>{`“We got it.”`}</p>
    <p>{`Jeralt groaned.`}</p>
    <Divider mdxType="Divider" />
    <p>{`Dimitri’s emotions for Byleth did not go away as he had planned. By not seeing her in person, he thought it’d miraculously disappear. On the contrary, now he was starting to feel things for `}<em parentName="p">{`Reus`}</em>{`. Reus! There weren’t these… fluttering sensations in his stomach when Reus yanked his clothes off and bared it all in the shower, or when he changed last night or this morning. Yet during lunch, whenever Reus looked at him with his big, green eyes, his heart rate increased and his stomach churned in horror as realization dawned on him.`}</p>
    <p>{`He could barely handle (or admit for that matter), his growing feelings for Byleth, let alone Reus now.`}</p>
    <p>{`In the midst of his agony, Dimitri stabbed a piece of onion too hard and his bowl cracked, spilling hot soup on him. He yelped and jumped out of his seat as the piping hot soup spread across his pants.`}</p>
    <p>{`Ashe and Reus also leapt from their seats as the liquid flowed over to their end. Dimitri stood, still in shock but Reus  exclaimed, “You’ll get burned!” and quickly grabbed some napkins and started patting down Dimitri’s soiled pants. The rest of the gang watched onat Reus’s selfless gesture, seemingly just as shocked as Dimitri. Dimitri’s heart pounded uncontrollably against his ribcage as he looked down at Reus on his knees dabbing his pants dry near his… his… regions and suddenly the heat wasn’t coming from the soup that burned his thighs anymore.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Are you okay?” Ashe said as he frantically mopped up the spilled liquid on the table and bench.`}</p>
    <p>{`“You were thinking about boobs right?” Sylvain blurted all of a sudden. Dimitri whipped up his head at Sylvain, his eyes wide, eyebrows furrowed, cheeks as red as a newborn.`}</p>
    <p>{`“N-no, Sylvain! I do not have such indecent thoughts. Please do not associate the contents of my mind to be akin to yours!” he sputtered.`}</p>
    <p>{`“Yeah, he’s fine.” Sylvain snorted, turning to Felix, who shook his head at the situation.`}</p>
    <p>{`“You do look like you peed your pants though,” Reus said, chuckling a little, but curtly apologised. It spooked Dimitri that Reus sounded almost—no, `}<em parentName="p">{`exactly`}</em>{` like Byleth.`}</p>
    <p>{`“Would you like me to buy you a new meal while you change?” Dedue asked. He shook his head. But Dedue insisted and left to order before Dimitri could say anything else.`}</p>
    <p>{`“R-Reus, if I may ask, how’s your father’s visit? You don’t have to answer if it’s something you can’t share, I-I don’t mean to pry.” Ashe side-glanced at Reus as they picked up the shards of the broken bowl.`}</p>
    <p>{`“I found out a couple of interesting things, actually. Never knew Mr Alois and my dad were so tight back in the day. I didn’t know that they knew each other, in fact! We also met with Principal Rhea. I was expecting her to be harsh, you know, up-tight, stubborn and all that. And Mr. Seteth was very, `}<em parentName="p">{`very`}</em>{` firm on me 'not besmirching our school’s reputation'. Otherwise, I think everything went splendidly.”`}</p>
    <p>{`“I’m surprised Mr. Seteth didn’t pull that stick out of his ass and beat you with it.” Sylvain chuckled then stopped, “All I did was talk to his little sister once. You know, the girl who comes occasionally to stay in the guest house— curly green hair, slightly naive looking, always talking about fish— ”`}</p>
    <p>{`“Miss Flayn?” Ashe answered.`}</p>
    <p>{`“Yep, that’s the one. Apparently he told her to maintain a distance of exactly 6 feet from me, and that any girl who speaks to me is ‘doomed to misfortune’.” Sylvain did air quotes while mimicking Seteth’s voice. “I mean what kind of guy says that to his sister?” Sylvain then suddenly gripped Reus’ shoulders.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Oh goddess, please tell me you didn’t tell your sister that.”`}</p>
    <p>{`“She can figure that out herself.” Reus looked amused.`}</p>
    <p>{`“That I am an absolutely charming young man with no ill intentions whatsoever? Don’t mind if I do—Oh wait, sorry. Can’t steal the one girl Dimitri finally develops a crush on.”`}</p>
    <p>{`Reus choked on his food and sputtered. A look of horror plastered on his face. Dimitri didn’t mind having another bowl of soup spilling over him. Anything to save him the pain and embarrassment of dealing with this again.`}</p>
    <p>{`“You said it yourself last night that anyone would’ve been blind to not see this stupid Boar having a shameless crush on her,” Felix said pointedly.`}</p>
    <p>{`“I-I... totally did, right. Sorry. I forgot. The whole Principal Rhea and Mr. Seteth thing kinda… shook me.”`}</p>
    <p>{`“R-Reus! Please pay no heed to their words. I would not do anything to damage your sister’s virtue—sorry, that’s not the right word. I-I mean I would not do anything you would  disapprove of. I understand how important she is to you.” Dimitri was prepared to give up these emotions if it meant respecting Reus’s wishes, however hard it was.`}</p>
    <p>{`Reus paused at his words. Suspense grew heavy in Dimitri's chest. Everyone seemed to hold their breaths as they leaned in, awaiting Reus’ answer.`}</p>
    <p>{`“I don’t think she’d mind.” Reus finally spoke, blushing for some reason. Sylvain and Ashe ooh-ed.`}</p>
    <p>{`A relieved sigh escaped Dimitri’s lips. The Reus who defended his sister’s honour with violence was now giving him permission to formally court his sister? Perhaps the goddess was watching over him after all. As elated as he was, the image of Reus’ flushed face seared into his consciousness. He couldn’t put his finger on it, but it felt like deja vu. There comes a point where twins ceased being similar—a distinct personality trait, different personal tastes, opposing hair partings—what more for fraternal twins. But the feeling he felt being around Reus was indistinguishable to when he was a bumbling mess around Byleth. Now he wasn’t sure if he was comfortable going out with her, when his heart also palpitates at the mere sight of her brother—`}</p>
    <p>{`Wait a minute. `}<em parentName="p">{`Both`}</em>{` twins?!`}</p>
    <p>{`His spine tingled at the sheer thought. How unbecoming!He was turning into Sylvain. Worse, he had declared that he wouldn’t fall in love at first sight with anyone, yet this was all he had been doing recently. Felix was right about mocking him. But why did it have to be both brother and sister?!`}</p>
    <p>{`“Stop standing there like an idiot, and quickly get changed. We still have class after this and I don’t want to be seen with a guy who looks like he pissed himself.” Felix had a way with words, but Dimitri knew he meant well.`}</p>
    <Divider mdxType="Divider" />
    <p>{`Afternoon training was uneventful. Coach Kostas had let them spar freely amongst each other while he gleefully scrolled his phone. Even Dimitri was off his radar. Reus, on the other hand, having been challenged by Felix to a sword match, surprisingly emerged victorious. Not that Dimitri ever doubted Reus. He knew what a fierce fighter Felix was, but Reus had some sort of wolfish hunger whenever he clashed swords.`}</p>
    <p>{`He could say the same for the literal, as well. Dimitri hadn’t noticed it before, but were there always `}<em parentName="p">{`this many`}</em>{` plates before Reus each meal time?`}</p>
    <Ads mdxType="Ads" />
    <p>{`The green-haired boy sat wolfing down his food beside him. Opposite Dimitri was Sylvain, mouthing ‘numb-ber’ at him. He ignored the red-head but was kicked in the leg. He shot a glare at Sylvain. Unfortunately even he was not spared by Ashe, who also eyed between him and Reus.`}</p>
    <p>{`Reus remained oblivious to the charade happening in front of him as Dimitri mouthed a ‘no’ back to his friends. Thank the Goddess. But Felix was fully aware and already extremely annoyed.`}</p>
    <p>{`“His Royal Beastliness wants your sister's number.” If the heavens could open up in all its glory and take Dimitri to the afterlife, this would be the time. He hid his face in the crook of his elbow.`}</p>
    <p>{`“Y-you want my sister's number?” Reus’ voice hitched.`}</p>
    <p>{`Dimitri looked up, hoping that Reus would be anything but furious. Instead Reus’ cheeks were bursting with food, like a hamster hoarding nuts. And those eyes. Dimitri tore his gaze away before he made a bigger fool of himself.`}</p>
    <p>{`Reus’s question still hung in the air. “Ah…” Did Dimitri want Byleth’s number now though? So far he’d been completely mesmerized by Reus. This Reus who was swallowed by his enormous hoodie, sitting cross legged as he shoveled spoonfuls of dinner into his mouth. Byleth seemed like an afterthought now. Although, the thought of her still made his heart squeeze. It just wasn’t as powerful as when her twin was sitting beside him.`}</p>
    <p>{`Dimitri’s fist clenched under the table. He felt like a cheater having affections for the  both of them. But his heart had trembled for Byleth first. No matter how Reus made butterflies erupt in his stomach now, he would not do the lady such a disservice by liking her brother.`}</p>
    <p>{`“I would like her number, if that's alright with you?” Dimitri said finally. Reus’ large eyes stared back at him, and he nearly reached out to wipe some crumbs off the boy’s lips. Dimitri pulled back his hands instantly. “I-I thought it was appropriate to start a correspondence with her first before formally asking her out on a date.”`}</p>
    <p>{`Reus froze. And Dimitri almost slapped himself in reflex for saying something stupid. Again.`}</p>
    <p>{`“It’s happening, it’s happening.” Sylvain nudged, whispering loudly at Ashe next to him.`}</p>
    <p>{`“When were you planning on asking her on this... ‘date’?” Reus swallowed and downed an entire cup of juice in one gulp.`}</p>
    <p>{`“I... didn’t think that far actually,” he said in a small voice. His palms sweaty. Knees weak. He twirled his now-cold spaghetti with a fork. Dejected.`}</p>
    <p>{`“... `}<em parentName="p">{`What`}</em>{`?” Sylvain butted in.`}</p>
    <p>{`“I mean, I didn’t think you would actually give me your sister’s number, let alone allow me to take her out on a date...” Dimitri replied, his eyes observing Reus who muttered something about never being on a date with anyone before.`}</p>
    <p>{`“Sorry, I didn’t catch—”`}</p>
    <Ads mdxType="Ads" />
    <p>{`“I don’t think my sister has been on a date before,” Reus repeated himself quietly.`}</p>
    <p>{`“Hang on—you’re saying your `}<em parentName="p">{`smoking hot`}</em>{` sister has never been on a date before, or are my ears broken?” Sylvain’s mouth fell open. So did Ashe’s. Reus landed a swift kick at Sylvian under the table and the latter howled in pain.`}</p>
    <p>{`“She’s `}<em parentName="p">{`not`}</em>{` hot okay? It’s weird to talk about my sister like that,” Reus retorted, a little vexed. “Anyway, let me know when you’re going on this date. Give me your phone, I’ll key in her number for you.” And Dimitri obliged a little too enthusiastically.`}</p>
    <p>{`“Aww, are you going to secretly spy on them or something?” Sylvain teased.`}</p>
    <p>{`“Ew no. Why would I do that? Wh-what if they kiss or something? That’s like, a breach of privacy!”`}</p>
    <p>{`Sylvain let out a burst of laughter. “Oh, don’t worry, Dimitri doesn’t know how to kiss.”`}</p>
    <p>{`“That’s a horrible thought,” Felix, who had his eyes glued to his phone the whole time, suddenly said out loud and shut his eyes. “Goddess I can’t unsee it now.”`}</p>
    <p>{`Dimitri was surprisingly a little hurt at the teasing, but it was the truth. He had never even held hands with a girl, not on purpose, at least. “But how do you know that? I could’ve… could’ve… you know…?”`}</p>
    <p>{`Sylvain grinned as wide as the Chesire cat.`}</p>
    <p>{`“Oh we would definitely know.”`}</p>
    <p>{`“I-if i may, Reus.” All turned to Ashe, Dimitri in particular was grateful for this interjection. “If you are worried, Sylvain does have a point. O-of course you needn’t spy on them—perhaps you could chaperone? I know you want to give them privacy but I think it would give everyone some sort of peace of mind.”`}</p>
    <p>{`Reus tapped his chin in contemplation. Meanwhile Dimitri was taken aback by Ashe’s suggestion. Need they be so old fashioned? Still, this would be the perfect time to reassess his feelings for Byleth and get these worrisome butterflies out of his system. Having Reus tag along as a ‘chaperone’ was oddly… exciting, too, for lack of a better word. No. No, he shouldn’t. It could only be one, and he chose Byleth. He was merely appreciative to bond with a new friend.`}</p>
    <p>{`Yes, that was it.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“I’m fine with the idea,” Dimitri finally said. Reus looked at him in slight horror. And Dimitri glanced back with equal alarm. “Y-You are… not fine with this arrangement, Reus?”`}</p>
    <p>{`“I’m pretty sure she doesn’t need to be accompanied by me. I mean come on, in this day and age?” Reus fired his words quickly, avoiding eye contact, and tucked his hands into the deep pockets of his hoodie.`}</p>
    <p>{`“Dude, you’re way too relaxed about this. One one hand, you’re the infamous Reus who beat the shit out of a guy for his sister, and on the other, you’re letting her go on a date alone? With `}<em parentName="p">{`this`}</em>{` beast?” Sylvain rapped his knuckles on the table. “ Come on, man, where’s your sense of fun.”`}</p>
    <p>{`“What do you mean?” Reus narrowed his eyes at him. “I thought you were vying for them?”`}</p>
    <p>{`“I am, and I also want first-hand news. We all know Dimitri is going to be so starstruck by her, he’s just gonna do a wonderful impersonation of a dead halibut.” Ashe let out a snort at Sylvain’s last comment but straightened up.`}</p>
    <p>{`“I’ll have you know that I will be the perfect gentlemen and entertain her about my innate knowledge of—”`}</p>
    <p>{`“Swords? Weapons? Breaking shit?” Felix cut him off. “Yeah, I’d be `}<em parentName="p">{`so`}</em>{` charmed if I were her.”`}</p>
    <p>{`“But she’s also in the weapon’s club….” Dimitri replied meekly.`}</p>
    <p>{`“Shut up.” Felix rolled his eyes.`}</p>
    <p>{`Sylvain leaned across the dinner table and took Reus’ small hands into his own. A sharp pang stabbed Dimitri in the heart. “Come on Reus, please? For us?”`}</p>
    <p>{`“But he hasn’t even talked to her yet!” Reus objected, did he dislike chaperoning so much? Dimitri’s heart sank.`}</p>
    <p>{`“We’re being prepared, and besides, she’s leaving soon isn’t she? If she hasn’t already. ” Sylvain clutched his chest and gasped dramatically, “It has to happen tomorrow!”`}</p>
    <p>{`Reus blinked at Sylvain. “But aren’t we not allowed out?”`}</p>
    <p>{`“You said your dad and Mr. Alois were tight, and this is your sister we’re talking about. You could say something and he might let you guys out.” There it was, that twinkle in Sylvain’s eye. Dimitri just knew some devious plan was forming in that head of his.`}</p>
    <p>{`They sat in silence for a few seconds when Reus opened his mouth then closed it again. “I don’t have a choice do I?”`}</p>
    <p>{`“Naw, I’m just gonna yeet you over this mountain and make you go anyway, right Felix?”`}</p>
    <p>{`“Not before I toss `}<em parentName="p">{`you`}</em>{`. Ugh. I’m going to shower.”`}</p>
    <p>{`“Give me a second.” Reus wiped his mouth and stood up abruptly.`}</p>
    <p>{`“S-sure,” Dimitri replied, his eyes trailing after the smaller male. Reus’s hair was the perfect imitation of a bird’s nest. His hoodie so big it fell off his shoulder, and his pants were so long he had to roll it up to his knees but one side slid down. Reus was an absolute mess but it was a warm sight for Dimitri’s very sore eyes.`}</p>
    <Ads mdxType="Ads" />
    <Divider mdxType="Divider" />
    <p>{`Sitting once again on her favourite brick in her favourite location; the greenhouse, Byleth was on the phone with the Real Reus.`}</p>
    <p>{`“Please tell me that by some miracle, you’re still around,” she pleaded and her brother responded with a chuckle.`}</p>
    <p>{`“I wish I weren't so I could listen to you suffer. Why?”`}</p>
    <p>{`“So, you’re still around then?”`}</p>
    <p>{`“Dad said I was still grounded and I didn’t wanna be stuck at home.So I checked into a backpackers hotel in Garreg Mach, and I’m going on a tour of some garden somewhere tomorrow with some old people. They have a tour bus, fancy that!” She stared at her phone, perplexed. The caller ID was correct. Strange words were coming out of the real Reus’ mouth. Him, being excited about spending the day with old folks? “Oh and dad said you’re grounded too when you’re done with this whole thing.” `}<em parentName="p">{`Now`}</em>{` there’s the Reus she knew.`}</p>
    <p>{`She groaned, she could deal with that later but now she had a more dire situation on her hands, “Yeah, yeah, okay. Anyway, when are you not going to be occupied with geriatrics? I kinda need you to chaperone.”`}</p>
    <p>{`“Chaperone? For what?”`}</p>
    <p>{`“Me.”`}</p>
    <p>{`“Elaborate, dumbass.”`}</p>
    <p>{`“Dimitri is going to ask me on a date and the guys are `}<em parentName="p">{`insisting`}</em>{` Reus tag along to make sure nothing happens and to tell them exactly what happens.”`}</p>
    <p>{`“Pfft, Dimitri? `}<em parentName="p">{`That`}</em>{` Dimitri-who-couldn’t-keep-his-eyes-off-you Dimitri? He could barely say two words to you without blushing himself to death.”`}</p>
    <p>{`She sighed and pinched the bridge of her nose. “I know, and it hasn’t even happened yet. Not until tomorrow night.”`}</p>
    <p>{`“What?”`}</p>
    <p>{`“So yeah, you uh… have to get me more clothes—”`}</p>
    <p>{`“Again?”`}</p>
    <p>{`“Yes, date-appropriate clothes please.” Byleth chuckled as Reus groaned and grunted on the other end of the line. “I’ll probably have to come down the mountain with Dimitri. And... you’ll also be getting texts from Dimitri. Sorry—I had to give him my number and delete our previous chats with me as you so just… I don’t know, go with the flow? Please don’t mess up.”`}</p>
    <p>{`“Hold up. `}<em parentName="p">{`I`}</em>{` need to pretend to be you now?” She could imagine the face he was making and the fake gagging.`}</p>
    <p>{`“No, I mean—ugh, you just do you, and I’ll do me. Capish? Leave all the dating to me. You just bring your ass to the date.”`}</p>
    <Ads mdxType="Ads" />
    <p>{`“I’m gonna make you suffer for this.”`}</p>
    <p>{`“I love you too, Reus.” More groaning followed by a reluctant ‘love you too’ then, click. She quickly jogged back to the cafeteria and saw the guys huddled around Dimitri.`}</p>
    <p>{`“What did I miss?” she said as she squeezed herself in between Ashe and Sylvain. Surprisingly Felix was still around too. Not long after her, Dedue also appeared and hovered over the group.`}</p>
    <p>{`Sylvain spoke without lifting his eyes off Dimitri’s screen, “Dimitri’s trying to sext your sister `}<em parentName="p">{`oof—`}</em>{`”`}</p>
    <p>{`“You have a death wish?” Felix elbowed Sylvain so hard the latter leaned on a chair to catch his breath. Felix then said to her, “The Boar here is struggling to use human language to try and talk to your sister.”`}</p>
    <p>{`Shit.`}</p>
    <p>{`Byleth excused herself to the toilet, and while she was looking for somewhere to hide and text, her phone ping-ed.`}</p>
    <MessageBox totalUnread={1} contactNumber="Dimitri" items={[{
      timestamp: {
        day: 'Monday',
        time: '07:51PM'
      },
      left: '<p>Is this Miss Byleth Eisner? I am Dimitri Alexandre Blaiddyd from the tournament. I share a dorm with your brother, Reus Eisner. We met earlier with your father.</p>'
    }, {
      left: '<p>I do hope I’m not intruding or anything... Reus gave me \tyour number. If this is making you uncomfortable, please let me know and I will cease all forms of communication.</p>'
    }]} mdxType="MessageBox" />
    <p>{`Byleth gave up her search, retreated to the dorm, put her phone on silent and giggled
at the way Dimitri texted as the messages came in.`}</p>
    <MessageBox totalUnread={1} contactNumber="Dimitri" items={[{
      right: '<p>haha youre so formal even when youre texting. I thought i told you to call me byleth? </p>'
    }, {
      right: '<p>and yeah he told me whats up </p>'
    }, {
      left: '<p>I do apologize. Would you allow me to make amends by taking you out to dinner tomorrow night?</p>'
    }]} mdxType="MessageBox" />
    <p>{`She nearly gawked at her phone at how shockingly smooth he was.`}</p>
    <MessageBox totalUnread={1} contactNumber="Dimitri" items={[{
      right: '<p>im back in faerghus tho</p>'
    }, {
      left: '<p>Oh, I understand. Fortunately, I hail from Faerghus as well, and I’d be happy to take you to dinner then.</p>'
    }, {
      right: '<p>im kidding im still here. tmr night? I thought you guys werent allowed out or smtg</p>'
    }, {
      left: '<p>My my, you are very knowledgeable about our school rules. Though, it’s not surprising since your brother attends this school as well. Please do not worry about that, neither I or your brother will break any rules.</p>'
    }, {
      typingLeft: true
    }]} mdxType="MessageBox" />
    <p>{`Byleth was about to type a reply when she heard voices from the corridor and quickly
hid under her blanket.`}</p>
    <p>{`“Even while flirting you type like an old man.” Sylvain’s voice echoed into the dorm when he opened the door. “Hey Reus, Dimitri is shit at flirting,” he said, yanking the sheets from her. “Why’re you hiding? Are you jerking off?”`}</p>
    <p>{`Byleth nearly gagged.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“I would at least have the decency to do it somewhere privately.”`}</p>
    <p>{`“She hasn’t replied yet, has she?” Ashe asked, stealing a glance at Dimitri’s phone.`}</p>
    <p>{`“She’s probably showing off to her friends about what a dumbass you are,” Felix said as he stripped all but his briefs, grabbed his shower kit and headed out.`}</p>
    <p>{`“Miss Byleth has been receptive so far, I believe it is too late for Dimitri to change his writing style now.” Dedue reached for a flower patterned shower bucket under his bed. “Please do not give up. It is not unreasonable for people to reply to text messages late. She might be preoccupied,” he said to Dimitri as he walked out.`}</p>
    <p>{`She sat up straight with her back to the wall and discreetly texted Dimitri back.`}</p>
    <MessageBox totalUnread={1} contactNumber="Dimitri" items={[{
      right: '<p>ok thanks. text me the time and locatios</p>'
    }, {
      right: '<p>*location</p>'
    }, {
      right: '<p>or tell Reus or smtg anything is ok with me. I gotta go see you tmr</p>'
    }]} mdxType="MessageBox" />
    <p>{`As soon as she hit send and heard the pings coming from Dimitri’s phone, she threw
a glance at him. He was about to say something to the guys but became completely
absorbed in her messages.`}</p>
    <p>{`Sylvain threw himself on Dimitri’s bed as soon as he heard the notification. “What did she say? Is she gonna marry you yet?”`}</p>
    <p>{`“W-what? No! She merely accepted my invitation!” Dimitri’s slender fingers tapped and glided over the screen in quick succession while managing to protest.`}</p>
    <p>{`“Are you going to say you love her?”`}</p>
    <p>{`“Sylvain, not now—no, I am not. I—please let me focus.”`}</p>
    <p>{`“Aww, my baby boy is all grown up and sexting girls!” Sylvain wiped a fake tear from his eyes.`}</p>
    <p>{`“Sylvain, please cease being so crass,” Dimitri pleaded. He then paused moving. From where Byleth was, it sounded like he was backspacing.`}</p>
    <p>{`“What’s sexting?” Ashe asked.`}</p>
    <p>{`“Actually, yes. What `}<em parentName="p">{`is`}</em>{` sexting?” Dimitri chipped in as well.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“You guys don’t know?” Sylvain sat up, observing them. “And you,” He looked at Dimitri, “You don’t know what’s sexting and you’ve been correctly fending me off?”`}</p>
    <p>{`“It… It has the word s-sex in it,” Dimitri said softly, blushing, “I thought it meant something sexually inappropriate.”`}</p>
    <p>{`Sylvain cleared his throat and gripped Dimitri and Ashe’s shoulders, with the biggest shit-eating grin Byleth had ever seen.`}</p>
    <p>{`“It’s when you send dick pics and or sexy messages to someone. Telling them what you’d like them to do, or do to them. Here, I’ll show you some of my past messages-”`}</p>
    <p>{`“-`}<em parentName="p">{`No!`}</em>{`” Dimitri and Ashe cried out.`}</p>
    <p>{`Byleth was on the verge of tears when she saw Dimitri and Ashe’s expression. She couldn’t tell if they were horrified, embarrassed or just had the purity robbed from their minds. The poor boys.`}</p>
    <p>{`“I’m going to… lie down,” Ashe mumbled, looking petrified as he dragged himself over to his bed. He buried his face in his pillow, probably attempting to suffocate the impurity out of his mind.`}</p>
    <p>{`Dimitri pushed the wheezing Sylvain aside who nearly rolled off Dimitri’s bed from his sheer strength and went over to her bed, almost kneeling in front of her.`}</p>
    <p>{`“Reus please, I promise you I would never do such a thing to your sister. Her virtue and honour is safe with me.”`}</p>
    <Ads mdxType="Ads" />
    <p>{`“I-I don’t want to know what you guys text. I’m her brother, for goodness' sake. This is grossing me out.” Byleth tried her best Reus-impression in this situation, but she was absolutely giddy with glee. Keeping the grin off her face proved impossible. Although to Dimitri it was just the corners of her mouth curling slightly upwards.`}</p>
    <p>{`“Oh right, let me add you to the group chat,” Sylvain said as he whipped out his phone.`}</p>
    <p>{`“G-group chat?” She asked, panicking.`}</p>
    <p>{`“We all want live updates from their date tomorrow, duh.”`}</p>
    <p>{`“Of course, of course,” she replied, giving them Reus’ actual number. Maybe she could borrow his sim card? But she shook the thought out of her head. He might actually kill her if she asked anymore. She fired off a quick text to the real Reus to expect incoming messages from his dorm mates, along with a string of apologies.`}</p>
    <p>{`Tomorrow was going to be such an interesting day for her, the idea of pursuing justice for her team almost felt like a distant dream.`}</p>
    <AuthorNote mdxType="AuthorNote">
  <p>
    HELLO EVERYONE THIS AMAZING PERSON{' '}
    <RemoteLink to="https://twitter.com/petorahs/status/1256819173025406976?s=20" mdxType="RemoteLink">
      DREW THIS
    </RemoteLink>{' '}
    SKETCHES OF THIS CHAPTER PLEASE CHECK IT OUT IM DYING I LOVE IT SO MUCH 
  </p>
    </AuthorNote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      