import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../styles/globals';

const Section = styled.section`
  text-align: center;
  background-color: ${$.brown2};
  border-bottom: 1px dashed ${$.color.black};
`;

const Container = styled.div`
  padding: 50px 0;
`;

const Title = styled.h1`
  font-family: Porkys !important;
  font-size: 40px !important;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${$.color.black};
  margin-bottom: ${$.layout.margin4}px;
`;

const Description = styled.h2`
  font-size: 17px !important;
  font-family: Rubik Regular !important;
  color: ${$.color.black};
  line-height: 1.3em;
  max-width: 600px;
  margin: 0 auto;
`;

const Banner = ({ title, description }) => (
  <Section>
    <Container>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </Container>
  </Section>
);

Banner.defaultProps = {
  title: '',
  description: '',
};

Banner.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default Banner;
