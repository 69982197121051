import { useState } from 'react';
import styled from 'styled-components';
import Masonry from 'react-masonry-component';

import $ from '../../styles/globals';
import data from './items.json';
import NoteCard from './NoteCard';
import { addKeys } from '../../utils';
import Button from '../Button';

const Section = styled.section``;

const Container = styled.div`
  text-align: center;
  padding: ${$.layout.padding6}px 0;

  ${$.screen.mobile} {
    padding: ${$.layout.padding3}px 0;
  }
`;

const Title = styled.h1`
  text-align: initial;
  font-family: Rubik Regular !important;
  font-weight: bold;
  color: ${$.brown4};
  margin-bottom: ${$.layout.margin10}px;
  font-size: 40px !important;
`;

const LoadMoreButton = styled(Button)`
  ${({ hide }) => (hide === true ? 'display: none;' : '')}
  outline: none;
`;

const items = addKeys(data);

const Releases = () => {
  const [total, setTotal] = useState(10);

  return (
    <Section>
      <Container>
        <Title>Release Notes</Title>
        <Masonry>
          {items.slice(0, total).map(({ key, note, date, title }, index) => (
            <NoteCard
              note={note}
              key={key}
              first={index === 0}
              date={date}
              title={title}
              showContinuation={total === index + 1}
              last={index === items.length - 1}
            />
          ))}
        </Masonry>
        <LoadMoreButton
          onClick={() => {
            setTotal((prev) => prev + 5);
          }}
          color="red"
          hide={total >= items.length}
        >
          Load Older Releases
        </LoadMoreButton>
      </Container>
    </Section>
  );
};

export default Releases;
