import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This is a continuation of the SEO blog post series where I write about the nooks and crannies of Search Engine Optimization (SEO). I’m aware that as bloggers who write for fun, SEO restricts your creativity and burdens your writing style. However, I beg to differ. As a small-time blogger, SEO is important in getting word about your blog post out without you actively posting about it. If you’ve done all the SEO tips and tricks right, then the next step is to further your understanding on how Google ranks your pages and domain.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Google never talks about their ranking algorithm openly, so how did these terms come about? Well, folks at Moz created a machine learning (ML) model to accurately predict your blog’s score and rank based on data from the web. Through the scores calculated by their ML model, you can predict how well your blog posts and domain fare against the other fellow heavyweights in the game. The scores are further separated into two types of score - Page Authority and Domain Authority.`}</p>
    <p>{`Page Authority is the score for how well your specific `}<em parentName="p">{`page`}</em>{` will rank on search engine result pages, while Domain Authority is the score for your blog `}<em parentName="p">{`domain`}</em>{`. Moz gives out scores on a scale of 100 for both authorities. I frequently check my blog’s stats as well as others, so here’s how Domain Authority scores scale based on my personal observation:`}</p>
    <ol>
      <li parentName="ol">{`Small websites or blogs usually get a score of 10 and below. Everyone starts out here. If you continue your efforts on building your blog, you’ll easily break out of a score of 10.`}</li>
      <li parentName="ol">{`If your website is ranked 20 or so, it’s now considered a relatively popular site for its own niche. This is also a sign that many websites link to your blog.`}</li>
      <li parentName="ol">{`Anywhere between 40 and 50 is considered average and above 60 is excellent. Big time websites such as Kotaku and IGN has a really high Domain Authority and that’s to be expected.`}</li>
    </ol>
    <Image alt="Domain Authority and Page Authority from anime blogs" caption="Here are some numbers from fellow bloggers. 7 is okay since I just recently started this blog!" name="geeknabe-blog-seo-da-for-fellow-bloggers.jpg" mdxType="Image" />
    <Image alt="Domain Authority and Page Authority from gaming news site" caption="Kotaku, IGN and Gamestop have ridiculously high Page Authority and Domain Authority. If you're trying to knock them down please look elsewhere." name="geeknabe-blog-seo-da-for-famous-gaming-news-site.jpg" mdxType="Image" />
    <p>{`So why are these scores so important? It tells you if Google will prioritize putting your pages on their first result page or otherwise. The higher your scores are, the better your chances are on getting the top spot. Let’s say you have a great blog post that answers the googler’s question, but due to your low Page Authority, it gets pushed off the first page. You just lost a ton of readership because Google deems your content isn’t good enough. It’s frustrating because there are so little viewers clicking on contents past the first page of Google search results. You need to get on the first page if you wish to win this game of SEO!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`But which authority is more important to us bloggers?`}</h2>
    <p>{`I’ll be frank. The answer is `}<strong parentName="p">{`both.`}</strong>{` However, raising your Domain Authority (DA) and Page Authority (PA) is really, really hard in the beginning. Take these baby steps I’m about to explain and practice these. There is no other way to raise your PA and DA other than time and effort.`}</p>
    <h2>{`Step 1: Raise Page Authority through social signals`}</h2>
    <p>{`I’m sure every blogger has a social media account (E.g. Twitter, Facebook, Pinterest) to share your blog posts. Your first steps rely heavily on what SEO experts call “social signals”. Social signals are the many types of engagement your blog post gets from social media platforms such as likes, shares and comments. Many argued that social signals are negligible since Google does not account social media engagement into their algorithms, but I beg to differ. Social signals do help our cause - getting more readers to click on our blog posts.`}</p>
    <ol>
      <li parentName="ol">{`If your blog post goes viral on `}<strong parentName="li">{`Twitter`}</strong>{`, it’ll pop up in Google’s search results. More importantly, if your tweet has images, it’ll pop up in Google’s image search results as well. Despite not accounted for as part of your PA and DA, it is still being included in Google’s search result which is what we want.`}</li>
      <li parentName="ol">{`Be active on `}<strong parentName="li">{`Pinterest`}</strong>{`! Even Pinterest pins appear in Google’s search result. Once again, not included in your PA and DA score but it is included in the search results. I call this win-win.`}</li>
      <li parentName="ol">{`If `}<strong parentName="li">{`Tumblr`}</strong>{` is your thing, make sure to post there often as well. Tumblr is a gold mine for dedicated fanbases so your blog post will get shared more often if you know what you’re doing.`}</li>
      <li parentName="ol"><strong parentName="li">{`Add your website link`}</strong>{` to your Twitter profile, Pinterest profile, etc. Write comments wherever you can. Seriously, put up your prized blog link everywhere. If you wrote a blog post that may answer someone else’s question, put it up! The trick here is to make your blog so visible that unassuming, potential readers would chance upon it.`}</li>
    </ol>
    <p>{`If Google is not ranking your blog post well, this is your (perhaps `}<em parentName="p">{`only`}</em>{`) chance on getting your blog post out there. As more viewers click on your blog posts, it’ll get more popular and soon, may have a chance on being on the first page in Google’s search result with a raised PA.`}</p>
    <Image alt="geeknabe's search result on Google" caption="Put up your blog link on every social media platform. You'll know if you did it right if a simple search returns every platform your blog link is on." name="geeknabe-blog-google-search-results-seo.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`Step 2: Tap into your most popular blog post`}</h2>
    <p>{`Once in a while, you struck gold in terms of blog post content. One of your blog posts is popular for some reasons! You checked your blog stats and noticed that it ranked really well by Google. Take this opportunity to add similar blog post links in, and make it really obvious for everyone to see that this is the next blog post they should read. I find that many readers don’t mind checking things out in your blog if you make things really easy to find. If your first blog post gave them a good impression, they’ll check out the next.The trick here is to make them stay as long as possible by showing them content they are interested in. The longer they stay, the better it is for your Page Authority.`}</p>
    <Image alt="geeknabe's most popular blog post." caption="Most popular blog post on geeknabe is this. As of today, this blog post has garnered 6500~ page views. I quickly tapped into this opportunity and added a few other posts into this page." name="geeknabe-blog-seo-most-popular-posts.jpg" mdxType="Image" />
    <Image alt="Completed webtoons blog post screencap" caption="I added a side bar to subtly inform the readers of similar posts. My post on Completed Webtoons have since garnered 1900~ page views." name="geeknabe-blog-tap-most-popular-post-seo.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`Step 3: Write really interesting content with specific keywords`}</h2>
    <p>{`I know what you are thinking. `}<em parentName="p">{`“But of course?!”`}</em>{` You see, there are many blog posts out there that plays it safe. It’s nothing too interesting, but Google puts it on the first page of its search result. `}<em parentName="p">{`Why?`}</em></p>
    <p>{`It’s definitely a combination of many factors but if I were to put it simply, these are the most obvious factors:`}</p>
    <ol>
      <li parentName="ol">{`These blogs have a higher DA and PA than yours.`}</li>
      <li parentName="ol">{`The blog post answers to the googlers search term more than yours.`}</li>
    </ol>
    <p>{`You honestly don’t stand a chance going against these bigger blogs, so how do you pull in clicks from your competitors? The answer is simple. Hit `}<strong parentName="p">{`specific keywords`}</strong>{` and make your content more `}<strong parentName="p">{`interesting `}</strong>{`than theirs. Keywords are terms that match the googlers search term. For example, when looking for comparisons, we tend to write “A vs B” so we should try out best to add “A vs B” in our blog post title, description or in the post itself. This should signal to Google that our blog post is what the googlers want to look for. But there are tons of competitors vying for the same keywords! You’ll have a better chance of looking for `}<em parentName="p">{`gold `}</em>{`elsewhere.`}</p>
    <p>{`Thus, `}<em parentName="p">{`specific`}</em>{` keywords or rather `}<strong parentName="p">{`long-tail keywords`}</strong>{`. You stand a better chance against these competitors if your content answers the rarely searched keywords. Since these keywords get less traffic, they have a better chance of getting on the first page! Potential readers will click on it more often too. But that’s not enough to raise your PA. You have to make them `}<strong parentName="p">{`stay.`}</strong>{` If a reader clicks on your blog post and leaves almost immediately, it’s a sure sign to Google that the content is not what they are looking for. This will pull down your blog post PA! Hence, the need to write `}<em parentName="p">{`interesting content`}</em>{`. Make sure to write at least 1000~ words or so because Google doesn’t rank thin content well.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Step 4: Get as many backlinks as possible to raise Domain Authority`}</h2>
    <p>{`It’s important to boost your PA through Step 1 to 3 since blog posts are the lifeline of a blog. No one looks up a blog’s landing page after all! Googlers will always look for answers to a question they have, so you’ll need to make sure your blog posts’ PA is as high as possible to rank better in Google’s search result. The next step is to raise your DA.`}</p>
    <p>{`The only way to raise your blog’s Domain Authority is through `}<strong parentName="p">{`backlinks.`}</strong>{` Backlinks are links to your blog hosted on another website. The more backlinks you have, the better it is for your DA obviously. However! The quality of these backlinks is equally important. If the website linking to yours is deemed to be spammy by Google or has thin contents, your DA goes down. Likewise, if you added links to spammy websites on your blog, your DA goes down too.`}</p>
    <p>{`Here’s how to get your blog link on someone else’s website:`}</p>
    <ol>
      <li parentName="ol">{`Be a guest writer. Write for other bloggers! I honestly think this is a great way of doing two things - get a free backlink AND getting the word out about your blog.`}</li>
      <li parentName="ol">{`Comment on blogs! Not only do you get to join a community, but you are also getting links to your blog directly on theirs. This does not boost your DA directly, however.`}</li>
      <li parentName="ol">{`Join community effort. I’m sure there are many bloggers that use “share community blog posts” as their blog’s modus operandi. Try your best to get yours in the list!`}</li>
    </ol>
    <p>{`I’m sure you’ll need to link to an external site to prove a point in your blog post. So in order to prevent these links on your blog to pull your DA down, add `}<em parentName="p">{`rel=”nofollow”`}</em>{` to your link tag. This informs Google scrapping your site that you want no part in boosting the site’s DA.`}</p>
    <p>{`Here’s what I mean when I say `}<em parentName="p">{`link tag.`}</em>{` If you have been writing your blog posts in text format, it’s time to take a look at the HTML format. Zoom in on a link. You’ll notice that your link is in this format:`}</p>
    <pre>{`<a href=”{your link}”>some text here</a>`}</pre>
    <p>{`Now add the following `}<em parentName="p">{`rel=”nofollow”`}</em>{` to it.`}</p>
    <pre>{`<a href=”{your link}” rel=”nofollow”>some text here</a>`}</pre>
    <p>{`You should do a spot check for your blog and add `}<em parentName="p">{`nofollow`}</em>{` to every outgoing link.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`All in all`}</h2>
    <p>{`Practice Step 1 to 4 always when it comes to writing each of your blog posts. Your Page Authority and Domain Authority will eventually go up! All the best, bloggers.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      