import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../styles/globals';

const StyledButton = styled.button`
  border-radius: ${$.borderRadius.radius2}px;
  background-color: transparent;
  min-width: 50px;
  text-transform: uppercase;
  user-select: none;
  transition:
    background-color 0.15s ease-in,
    color 0.15s ease-in;
  padding: ${$.layout.padding1}px ${$.layout.padding3}px;
  font-family: Rubik Regular;
  font-weight: bold;

  &:hover {
    cursor: pointer;
  }

  ${$.screen.desktop} {
    font-size: 15px;
    line-height: 21px;
  }

  ${$.screen.tablet} {
    line-height: 20px;
    font-size: 14px;
  }

  ${$.screen.mobile} {
    font-size: 13px;
    line-height: 19px;
  }
`;

const GreenButton = styled(StyledButton)`
  color: ${$.color.green1};
  border: 2px solid ${$.color.green1};

  ${$.screen.desktop} {
    &:hover {
      color: ${$.color.white};
      background-color: ${$.color.green1};
    }
  }
`;

const RedButton = styled(StyledButton)`
  color: ${$.color.red1};
  border: 2px solid ${$.color.red1};

  ${$.screen.desktop} {
    &:hover {
      color: ${$.color.white};
      background-color: ${$.color.red1};
    }
  }
`;

const OrangeButton = styled(StyledButton)`
  color: ${$.color.orange};
  border: 2px solid ${$.color.orange};

  ${$.screen.desktop} {
    &:hover {
      color: ${$.color.white};
      background-color: ${$.color.orange};
    }
  }
`;

const BrownButton = styled(StyledButton)`
  color: ${$.brown5};
  border: 2px solid ${$.brown5};

  ${$.screen.desktop} {
    &:hover {
      color: ${$.color.white};
      background-color: ${$.brown5};
    }
  }
`;

const Button = ({ color, children, ...props }) => {
  switch (color) {
    case 'red':
      return <RedButton {...props}>{children}</RedButton>;
    case 'orange':
      return <OrangeButton {...props}>{children}</OrangeButton>;
    case 'green':
      return <GreenButton {...props}>{children}</GreenButton>;
    default:
      return <BrownButton {...props}>{children}</BrownButton>;
  }
};

Button.defaultProps = {
  color: '',
  children: <></>,
};

Button.propTypes = {
  color: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.element,
  ]),
};

export default Button;
