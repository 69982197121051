import styled, { createGlobalStyle } from 'styled-components';

import $ from '../../styles/globals';

const css = `
font-size: 13px;
text-transform: uppercase;
font-family: Rubik Regular;
letter-spacing: 1px;
text-align: center;
color: ${$.brown3};
line-height: initial;
`;

const Container = styled.div`
  max-width: 700px;
  margin: ${$.layout.margin4}px auto;
  padding: ${$.layout.padding3}px 0;

  iframe {
    margin: 0 !important;
  }

  // hide if smaller than 252px. This is to prevent the ads from showing error.
  @media only screen and (max-width: 252px) {
    display: none;
  }
`;

const StartingAdsTitle = styled(({ className }) => (
  <div className={className}>Advert</div>
))`
  ${css}
  padding-bottom: ${$.layout.padding1}px;
  margin-bottom: ${$.layout.margin2}px;
  border-bottom: 1px solid ${$.brown3};
`;

const EndingAdsTitle = styled(({ className }) => (
  <div className={className}>End of Advert</div>
))`
  ${css}
  margin-top: ${$.layout.margin2}px;
  padding-top: ${$.layout.padding1}px;
  margin-bottom: ${$.layout.margin2}px;
  border-top: 1px solid ${$.brown3};
`;

const StartingPersonalAds = styled(({ className }) => (
  <div className={className}>Blog Advert</div>
))`
  ${css}
  padding-bottom: ${$.layout.padding1}px;
  margin-bottom: ${$.layout.margin2}px;
  border-bottom: 1px solid ${$.brown3};
`;

const EndingPersonalAds = styled(({ className }) => (
  <div className={className}>End of Blog Advert</div>
))`
  ${css}
  margin-top: ${$.layout.margin2}px;
  padding-top: ${$.layout.padding1}px;
  margin-bottom: ${$.layout.margin2}px;
  border-top: 1px solid ${$.brown3};
`;

const NightMode = createGlobalStyle`
  body.night-mode {
    ${StartingAdsTitle}, ${EndingAdsTitle}, ${StartingPersonalAds}, ${EndingPersonalAds} {
      color: ${$.brown1};
    }
  }
`;

export {
  EndingPersonalAds,
  StartingPersonalAds,
  StartingAdsTitle,
  EndingAdsTitle,
  Container,
  NightMode,
};
