import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const LocalLink = makeShortcode("LocalLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Welcome! I see that you've stumbled upon my personal blog. I prefer anonymity on the web so
I call myself `}<strong parentName="p">{`nabe-chan`}</strong>{` in here. This blog is mostly used as a personal space for testing out current web development tech or to write about any anime, manga or games I find really interesting. Sometimes I might even talk about tech topics! You'll occasionally see posts on news, reviews, first impressions or maybe even opinion pieces on these topics. I code web apps related to ACG (anime, comics, games) as well using any tech stack I find interesting, or exploring ideas that I won't get to use at work. The blog posts and web apps are a mix of every hobby I enjoy.`}</p>
    <Ads mdxType="Ads" />
    <p>{`It'll make my day if you find any of my blog posts useful or interesting! You can find out more about me `}<LocalLink to="/about/" mdxType="LocalLink">{`here`}</LocalLink>{`. All links to available pages can be found in the footer at the bottom of this page, or at the top of this page's navigation bar.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      