import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Video = makeShortcode("Video");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`It’s either isekai, villainess or romance lately, nothing in between. Just where have these shounen titles gone? ): And by shounen titles, I mean new and upcoming ones. Or recent ones! It doesn’t matter, I just want to read some shounen! I think most folks would point me to read One Piece, Black Clover, or the titles currently running on MangaPlus. Yea I do read those but I would love to read `}<em parentName="p">{`more`}</em>{` shounen titles.`}</p>
    <p>{`So I try searching up my old manga list just to see if there’s any shounen titles I could binge again. `}<em parentName="p">{`Ah~ The memories`}</em>{`. For this blog post, I was thinking that maybe I should try to introduce some of my favourite and underrated ones. These are completed titles. Enjoy :)`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Underrated Completed Shounen Manga #1: Claymore`}</h2>
    <p>{`I know my blog post title says underrated and this title is far from being underrated. I think not many folks know about this title (To my surprise!) so I feel obligated to add this title here. You can try the anime but it didn’t cover the whole manga. Both mediums are great since they got their own thing going.`}</p>
    <Video title="claymore" src="https://www.youtube.com/embed/c5DDD0ofspk" mdxType="Video" />
    <p>{`Claymore is all about chopping up monsters in the most gruesome manner, with a side dish of mystery. Humans live in a land full of shapeshifting monsters called Yoma. Their only salvation being the Organization, a group of female warriors adept at hunting these monsters down. Our protagonist is Clare who was shown to have superhuman strength and amazing self healing abilities. Alas, this is only if she is compared with a normal human. Amongst the Organization’s warriors dubbed `}<em parentName="p">{`Claymores`}</em>{`, she is the weakest and was given the last rank of No. 47. Just what is the deal with Clare’s strength? What are Yoma(s), and when will these brave warriors finally subjugate the last of these Yoma(s)?`}</p>
    <Ads mdxType="Ads" />
    <p>{`Claymore, despite the super mystery-ish summary I wrote, is a battle-intensive shounen manga. There’s lots of chopping, lots of gore and plenty of power ups for our protagonist Clare. We see the girls’ backstory, the Organization’s shifty higher ups and then, we get back to`}<em parentName="p">{` more chopping`}</em>{`. The monsters are super basic in the beginning chapters but they get more sly, more grotesque and of course, more `}<strong parentName="p">{`powerful`}</strong>{`. I love this title because even the chopping and flesh breaking up by both the Yoma(s) and Claymores oozes so much style (and gore). Haha, without context I sound like a lunatic typing this. Claymore is great! Try both the anime and manga, you won’t regret it.`}</p>
    <h2>{`Underrated Completed Shounen Manga #2: Psyren`}</h2>
    <p>{`This shounen manga has so many things going for it. I wonder why it ended the way it did… While I chanced upon the reviews on MAL calling this title `}<em parentName="p">{`average,`}</em>{` I think this is still worth reading. Plus, I definitely enjoyed it.`}</p>
    <Image alt="Psyren Manga Cover" caption="Read Psyren! I know its short but its pretty good." name="psyren-manga-cover.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <p>{`Our MC is Ageha Yoshina and like every battle shounen titles out there, is a high schooler, got pulled into a death game and must learn to harness special abilities if he wishes to survive. The game he’s pulled in is called Psyren and is held in another world filled with bloodthirsty monsters called Taboo. In the Psyren world, humans have the ability to use psychic powers which they dub PSI.`}</p>
    <p>{`Back in the days, shounen plots often followed a specific path: Opening -> Get new members -> Training -> Tournament -> More training -> Timeskip -> Final arc. Or something that goes along that line. Psyren falls into this category of cliche! However, what caught my attention is how creative the author was with the PSI powers. Each battle is super fun to read! Do give this a try!`}</p>
    <h2>{`Underrated Completed Shounen Manga #3: Love Fighter Shuravan`}</h2>
    <p>{`Now this is that one underrated title no one has heard of! It’s largely comedic relief but yandere. That’s right! `}<strong parentName="p">{`Yandere`}</strong>{`. I remember finding this title by chance and binged it all the way. It’s really funny! My sides ache at the comedic scenes xD There’s only 18 chapters out there so I don’t think you need a summary from me.`}</p>
    <Image alt="Love Fighter Shuravan" caption="It looks super sentai-ish (Because it is) but it's more than that! I cackled so hard because its so silly..." name="love-fighter-shuravan.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`Underrated Completed Shounen Manga #4: Nurarihyon no Mago`}</h2>
    <p>{`This is one of my favourite shounen titles when it was first published. It had sass, romance and an interesting concept for a Yokai story. Do consider trying the anime! It got 2 seasons with wildly different vibes. The first season is more serious while the second season feels… relaxing. Both are good!`}</p>
    <Video title="Nurarihyon no Mago" src="https://www.youtube.com/embed/7nreCjekf8k" mdxType="Video" />
    <p>{`Nurarihyon no Mago follows the story of Rikuo Nura, the grandson of a strong yokai named Nurarihyon who leads his own night parade (Hyakki Yako). Due to both Rikuo’s grandfather and father choosing a human bride, Rikuo is only 1/4 yokai and ¾ human. This complicates the matter of succession in the Nura Clan. You see, Rikuo’s grandfather wanted him to be the Third Head of the Nura Clan but his human blood makes it hard for any factions to support him. Just how will Rikuo regain support from these factions? Can he gather allies to make his own Hyakki Yako?`}</p>
    <p>{`Of course he can. That’s a rhetorical question from me. What’s super interesting in this manga is `}<em parentName="p">{`how`}</em>{` the whole Hyakki Yako business unfolded for Rikuo. The build up and power ups are pretty nice, with the last boss being super tenacious and powerful that everyone had to gang up on her to stand a chance. Then there’s the obligatory romance part slit in. Eh, the romance is okay but I’m reading this solely for the yokai scenes. I learned a lot about yokais from this series! Good stuff, one of my favourites. Give it a try!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Underrated Completed Shounen Manga #5: Gunslinger Girl`}</h2>
    <p>{`There’s so much pain in this series, I had to double confirm if this is really for the shounen demographic. Anddd yup, it’s published in Dengeki Daioh, a shounen magazine. This title got two anime seasons. I only watched S1 and a few episodes of S2. I think the manga version is better when it comes to raw emotion.`}</p>
    <Video title="gunslinger girl" src="https://www.youtube.com/embed/NTxoH_5CnhM" mdxType="Video" />
    <p>{`Gunslinger Girl is by far, the most heartbreaking show/manga for me. The story focuses on cybernetic girls and their male handlers (Together, the duo is dubbed `}<em parentName="p">{`fratello`}</em>{`) who use them as assassins for the government in modern Italy. When I say cybernetic girls, I mean young girls that are beyond recovery. They modified these girls, brainwashed them and armed them with a gun. It sounds cruel, yes, but they are doing it for society (what these adults are saying in the story). The story delves into both the shooting action and relationship between the handler and their cyborg girl. Each fratello has their own unique backstory. Some find comfort in nurturing a good relationship with their partner while some… couldn’t care less about their cyborg partner.`}</p>
    <Ads mdxType="Ads" />
    <p>{`It’s definitely a unique story with lots of heartbreaking moments. It’s that type of story where they highlight the relationship between the parent figure and the child, but there is no happy ending. Perhaps the girls getting a second chance at life is enough as their happy ending… Make of that what you will and prepare your tissue box.`}</p>
    <h2>{`Underrated Completed Shounen Manga #6: Tokyo ESP`}</h2>
    <p>{`It’s basically folks who awakened their psychic powers but in Tokyo. Hence, `}<strong parentName="p">{`Tokyo ESP.`}</strong>{` This is by far, the shortest, most self-explanatory title before the isekai light novel titles kicked in.`}</p>
    <Video title="tokyo esp" src="https://www.youtube.com/embed/iKSiY_w0eMg" mdxType="Video" />
    <p>{`Our MC is Rinka Urushiba, a high school girl. She lives with her father and they are both not exactly well off. On the way back home from her part time job, she encountered fishes soaring freely in the sky. Out of curiosity, she touched the fish. The next day, she gained the ability to phase through solid objects. This marks the start of Rinka battling individuals who use their ESP powers for evil. The plot is nothing special but if I can summarize the whole plot in general, it’s super fun. Like, Rinka is a super likeable female MC for a shounen story. Her powers fall into those types where it’s not super strong unless you know what you’re doing. I repeat, this manga is super, super fun to read. Try the anime too!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Underrated Completed Shounen Manga #7: Kekkaishi`}</h2>
    <p>{`I actually never completed the manga. Oof, the audacity of recommending a title before finishing the story! I remember reading it halfway before jumping into the anime. I was totally blown away by the anime, much more than the manga actually. Was it because the anime feels more refreshing and straightforward? At any rate, I can vouch for the story’s quality. It’s definitely good. Enjoy the video I added. You could see how their barrier powers work.`}</p>
    <Video title="kekkaishi" src="https://www.youtube.com/embed/xht0MY1Tjnk" mdxType="Video" />
    <p>{`Kekkaishi literally means Barrier Master, and it is what this story is all about. Entering the fold are two rival families of kekkai (barrier) users, Sumimura and Yukimura. Bestowed with kekkai powers, they must protect the very land that attracts ayakashi every now and then. It just so happens that the land is now their school grounds. The two heirs to the kekkaishi art are Yoshimori Sumimura and Tokine Yukimura. While both are rivals with differing kekkai techniques, they help each other out to protect the school, just like how their forefathers did. However, the ayakashi grows ever stronger. How will the both of them handle the ever increasing danger?`}</p>
    <p>{`I love how they defend the school grounds with their kekkai techniques. Since the basis of their kekkai techniques are well defined, the author just needs to spice things up a little and we go, that’s creative! While this post is to recommend the manga, I really want to recommend the anime too. They have 52 episodes. Heck, I was rewatching the show as I write this recommendation post. Good stuff.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Underrated Completed Shounen Manga #8: Rave Master`}</h2>
    <p>{`Rave Master (I often short form this to Rave. Folks will know what I’m talking about) is the better Fairy Tail, hands down. While I enjoyed Fairy Tail, I have many problems with it as it approaches the ending. Ahem! You can’t help but compare both of these stories because they are by the same author. Rave is good, Rave is amazing, read Rave. I know old school folks have heard of Rave but many only knew of the author from Fairy Tail. Come on guys, Rave is his `}<em parentName="p">{`magnum opus`}</em>{`.`}</p>
    <Video title="rave master" src="https://www.youtube.com/embed/oTpRZqpkPQA" mdxType="Video" />
    <p>{`Our protagonist is Haru Glory who lives in a village with his older sister Cattleya. He accidentally fished up Plue, uhm, a dog(?) with a horn for a nose. Right after he fished up Plue, he encountered the Sword Saint, Shiba Roses. Shiba claimed that he has been searching for Plue for a long time. Shiba Roses then recounts his tale about the war 50 years ago and how Plue is the only one capable of locating Dark Brings, magical stones that are scattered across the world. Right after he recounted his tale, a member of the Demon Card (who collects Dark Brings) engaged Shiba. Things happened and Shiba declared that Haru is the new Rave Master. He passed on his sword, The Ten Commandments to Haru and thus began Haru’s journey to collect the missing Rave stones required to power up the sword.`}</p>
    <p>{`I hope I did the summary okay because it’s been a while since I last read the story. It’s really, really good. Lots of interweaving plots, lots of hints. It’s basically a really engaging story from the start to the end. You know how satisfying it is to see the hint at Chapter 1 and it got used in Chapter 100? Yea, basically that feeling! Read the manga, watch the anime… I was told that the anime is some amazing stuff. While I can’t vouch for the anime (I did not watch it), I can vouch for the manga. Good stuff.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`All in all`}</h2>
    <p>{`Enjoy these completed shounen manga recommendations! You’re in for a real treat <3 I love titles with lots of chapters. It makes me feel happier knowing that this good story has even more content to finish. Now you might be wondering... Why did I put videos instead of manga images? Well... My blog is starting to run out of space for storing images. I'm sorry.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      