import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Video = makeShortcode("Video");
const RemoteLink = makeShortcode("RemoteLink");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I guess it’s time to confess - I’ve been playing Waifu Impact 3rd lately and I’m having a blast. (Well, almost) Playing this mobile game on PC gave me more insight into how miHoYo handles their creative side of things. I wasn’t sure what to expect from Honkai Impact when I booted it up, but it certainly blew my expectations out of the water.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Honkai Impact 3rd (HI3) is a sequel to Gun Girl Z (GGZ), miHoYo’s other successful mobile game. Characters from GGZ make an appearance in HI3 as an alternate universe other self and oh boy, so much alternate universe potential that may or may not include Genshin. miHoYo did their very best to build background lore and lovable characters in HI3. Did they succeed? Certainly. So… just what IS HI3 all about? In HI3, an unknown force of nature(?)/otherworldly(?) called Honkai threatens human civilization. The more humanity advances their tech, the harder Honkai retaliates. To combat these monsters, humanity took in Honkai monster genes into their own body to create their own set of warriors. That’s basically the gist of HI3. I repeat, `}<strong parentName="p">{`basically.`}</strong>{` miHoYo also added a silly lore that only ladies could peruse the Honkai gene effectively, thus there are only female characters you could play as in HI3.`}</p>
    <p>{`There are a ton of reasons that could explain HI3’s popularity when it was first released. There’s the battle system that is really, really polished for a mobile game. The dodging mechanic and animation is really, really smooth. Battles are fun and a visual firework. And of course, the waifu reason! But I digress. These are only good enough of a reason for explaining a mobile game’s short run. As you know, most mobile games don’t stay popular forever. So what makes HI3 so long-lived compared to other mobile games? Well I believe I have the answer.`}</p>
    <p>{`This is where miHoYo’s strength as a weeb company truly shines. These guys know what hooks us otaku fans! HI3 is still popular now because they have successfully created a `}<strong parentName="p">{`compelling story`}</strong>{` and 14 wonderful characters in order to make you feel invested in HI3 as a whole. They created a ton of manga compilation to explain where these characters are coming from. There’s a ton of backstories which I’m sure every weeb would love to read `}<strong parentName="p">{`more.`}</strong>{` They animated amazing 3DCG clips to truly let these battle scenes shine. They did a ton of events amplifying the characters’ lovability by doing Open World, a separate map from the main game. I added one of my favourite 3DCG clips that they did below! Enjoy the video!`}</p>
    <Ads mdxType="Ads" />
    <Video title="Honkai Impact 3rd short video" src="https://www.youtube.com/embed/ppDfdCnOF1o" mdxType="Video" />
    <p>{`And not to mention the character songs they did! Plus a `}<RemoteLink to="https://www.youtube.com/watch?v=4uGERVkhsgo&list=PLLX1bpH-W3ZADp3sYwTL9TkoDjvPamZNy&ab_channel=HonkaiImpact3rd" mdxType="RemoteLink">{`short anime series to showcase the characters cooking`}</RemoteLink>{`? You know what sucks me in when it comes to doing a series justice? A spinoff of my favourite characters in a slice-of-life series! That’s why Tensura Nikki is so popular. Folks doing Tensura knew their audience well. miHoYo did all of the right things to lengthen HI3’s lifespan. All the money from the gacha goes back into developing more content to make the characters more lovable to you, the player. If players are already super invested (oh, you know how we weebs operate...), it's harder for them to leave midway.`}</p>
    <p>{`The second reason, other than the characters and whatnot, is how welcoming the game is to F2P players. Of course, we all know gacha games are a F2P lie (F2P is short for Forced to Pay in gacha games, I’m sure of this) but in HI3, you can grind for anything in the game. I don’t think there’s anything that you specifically need to pay for unless you’re in a rush to have it. Playable characters in the game are called Valkyries, and they go from a class of B -> A -> S -> SS. You don’t need to pull for these characters to raise their rank. You could grind in the game and slowly… raise their rank. Some characters are event-only so you will need to complete the event for the character. In a way, that’s how the game promotes fairness I suppose. Clear the event, get your reward! So far, I feel that the game is newbie-friendly enough in terms of rewards. Do whatever you need in this game and get a ton of rewards! Then again, we need to consider that this game has been ongoing for 3 years. Most older gacha games are more reward friendly to make you stay. Regardless, everything in this game feels `}<strong parentName="p">{`rewarding`}</strong>{` which is kinda how gacha games pull you in. You just need time to get the weapons and characters you want which coincidentally… `}<strong parentName="p">{`is`}</strong>{` `}<em parentName="p">{`a lot of time`}</em>{`.`}</p>
    <Ads mdxType="Ads" /> 
    <p>{`Phew, the amount of time needed just to clear my dailies in this game... There’s a lot of things going for HI3 in terms of content and grind-ability but in a sense, it is also a major disadvantage. I kid you not. There are `}<strong parentName="p">{`a ton `}</strong>{`of things to do in this game. You get the story quests which go by chapters. There are about 23 chapters out now and each chapter got a ton of mini maps to clear. The story is not too bad, plus they are fully voiced in JP/CN. The story also gets some nice static CG which increases immersion. Some maps are easy to beat, some are difficult. I’d say it's a good balance of difficulty and fun. Then there’s the grinding part (Expedition/Weeklies) where you clear maps to get weapon and character materials. It will take a while to collect enough materials for the character/weapon you want so this takes a long time. Separate from the main story quests are three Open World maps where you get to control another set of characters they give you to explore an open area. These Open World maps also serve to explain character backstories and whatnot. More stories! What’s there not to like?`}</p>
    <Image alt="Honkai Impact 3rd Screenshot 1" caption="Just to give a quick look on what gameplay modes HI3 has. This is Page 1. If you click onto any modes here, there are even more option displayed for you to pick." name="honkai-impact-3rd-screenshot-2.jpg" mdxType="Image" />
    <Image alt="Honkai Impact 3rd Screenshot 2" caption="Here's Page 2. And of course, clicking on the modes will bring up even more options. When I first started, I have no clue what's going on. But the modes are pretty fun! Do I know what's the best team synergy setup? No idea, I just spam the Attack/Dodge button." name="honkai-impact-3rd-screenshot-3.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <p>{`And there’s even more gameplay modes! There’s the whale only rank-climbing mode called Abyss if I am not mistaken. Keep clearing floors to unlock better rewards and bragging rights. Then the co-op mode, where you play with other players to clear a stage. Then there’s even more character-based battle mode where you clear stages by using said character. And….! And…! …Okay, so basically this game has a ton of things to do if you include time-locked events per game update. Playing this game daily is fun but each day takes a ton of my time. The battles do not have an auto mode unlike other mobile games. So you gotta take control of your team manually and go through each map by yourself. Folks who love content will love this game. Folks who have no time but still wish to enjoy the game will probably drop this game once they realize how time consuming the game is.`}</p>
    <p>{`You see, the major disadvantage of this game isn’t the sheer overload of content. It’s the time it takes to finish them all and reach a satisfying point of “reaching the end game”. Gacha games will never truly have an end game so I find it important that games set a clear distinction of reaching a point where you have cleared most of the permanent content and are now waiting for newer content.`}</p>
    <Image alt="Honkai Impact 3rd Screenshot 3" caption="There's just so many characters! I believe there's like, 14 of them? They are further split into subtypes called 'battlesuits' that has different gameplay. So many options... Players who love variety will love the game. You can unlock each battlesuit if you took the time to grind." name="honkai-impact-3rd-screenshot-8.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <p>{`I keep on reading comments on how Genshin is so empty now but I’m of the opinion that miHoYo knew what they were doing. If they give us too much content from the outset, players will easily get overwhelmed and disinterested to continue a never-ending game that they feel they could never catch up to. I believe miHoYo doesn’t want to promote this vibe in Genshin. F2P players will easily get shoo-ed away. I’m the type of player who feels bad if I couldn’t finish an event. It feels such a waste to pass up the chance to get rewards! This is exactly what I’m feeling while playing HI3. There’s a ton of events so the chase is never-ending. I love the game but in all honesty, it feels `}<em parentName="p">{`overwhelming `}</em>{`in obligatory content. And I doubt reaching LVL80 will reduce this feeling either! I login to clear weekly quests, co-op if I can, clear bounties, clear character quests, click on my Dorm, clear Sim Battles… It’s like a weekly job that doesn’t end. You could skip those but your team will never get upgrades if you skip them! Oof. Now imagine Genshin getting that much content, it’ll get really overwhelming for newer players.`}</p>
    <p>{`Right now, the artifact grinding + character banners + simple weekly quests that take short time to complete feels just nice. Even Hangout Events and Story Quests feels decently paced. Not too long that you take hours to complete, and not too short that you finish everything within seconds. Everything in Genshin feels… `}<strong parentName="p">{`digestible`}</strong>{`. miHoYo intentionally designed many of Genshin's content to be `}<em parentName="p">{`simple to play`}</em>{` because they don’t want both newer and older players to feel overwhelmed. If you noticed, even game update events aren’t too tough that you will miss anything if you solo. You just need to clear a certain minimum to get all of the rewards. There’s this sense of inclusion for everyone, be it veterans or beginners. No one gets left behind (unless it's pulling the characters you want… C12 Noelle, anyone?).`}</p>
    <Ads mdxType="Ads" />
    <p>{`There’s a vibe of friendly gameplay that they are going for in Genshin which is a stark contrast to HI3. HI3 is so… obligatory grindy with a ton of effort needed while Genshin doesn’t force that down your throat. Genshin is doing the “just do the bare minimum and you’ll be fine” kinda gameplay which I’m really happy about! Plus, we are only 2 regions into Genshin. Sooner or later, miHoYo will add the remaining 5 regions which is an indicator of the sheer amount of content that we will be getting.`}</p>
    <p>{`HI3 is a wonderfully polished game which I’m sure will continue for many more years to come. It’s a game that I hope they will… simplify things but nah, I guess HI3 players are fine with the way things are. HI3 is definitely not for folks with little time, and I guess that’s what miHoYo understood from the game. They used what they knew best from creating HI3 and used that experience to create Genshin.`}</p>
    <p>{`Expansive lore, lovable characters in terms of backstory, wonderfully designed characters that every anime fan would love, simple UI and controls, battle mechanics that aren't too hard to digest… Genshin is a game that anyone could get into and quickly get up to speed without needing to understand what’s going on, really. It’s also a game that anyone could take their sweet time with. I’ve met a player who just wants to cook all the dishes he/she unlocked. I was so impressed since their version of fun is so different from mine. But what’s important is that Genshin’s gameplay format allowed the player to have their own version of fun! Here’s hoping they continue doing a good job with HI3’s story content and allow Genshin to continue down this line of easy freedom… but without sacrificing the lore/story/character quality.`}</p>
    <p>{`Thank you for reading.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      