import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import $ from '../../../styles/globals';
import { getStatusColor } from '../helpers';
import { importOneImage } from '../../../utils';

const Container = styled(Link)`
  width: 100%;
  text-decoration: none;
  border: 1px solid ${$.brown2};
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: ${$.color.white};
  box-shadow: ${$.shadow.normal};
  transform: translateY(0);
  overflow: hidden;
  transition: all 0.3s ease;

  ${$.screen.desktop} {
    &:hover {
      transform: translateY(-0.5em);
      box-shadow: ${$.shadow.repressed};
    }
  }
`;

const Title = styled.div`
  font-size: 30px;
  color: ${$.brown5};
  line-height: 1.2em;
  font-family: Raleway Bold;
  padding: ${$.layout.padding3}px ${$.layout.padding3}px ${$.layout.padding1}px
    ${$.layout.padding3}px;

  ${$.screen.mobile} {
    font-size: 25px;
  }
`;

const MediaFranchise = styled.div`
  font-family: Rubik Regular;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${$.color.orange};
  font-size: 13px;
  padding: ${$.layout.padding3}px;
`;

const Status = styled.div`
  color: ${$.color.white};
  font-size: 15px;
  padding: ${$.layout.padding2}px;
  font-family: Raleway Bold;
  border-radius: 20px 0 0 20px;
  border: 2px solid ${$.white1};
  right: -5px;
  bottom: 0px;
  margin-bottom: ${$.layout.margin2}px;
  background-color: ${({ status }) => getStatusColor(status)};
  text-transform: uppercase;
  letter-spacing: 1px;
  position: absolute;
  z-index: 1;
`;

const ImageContainer = styled.div`
  height: 200px;
  position: relative;

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }

  ${$.screen.mobile} {
    height: 150px;
  }
`;

const Info = styled.div`
  padding: ${$.layout.padding3}px;
  text-align: center;
`;

const TextPerLine = styled.div`
  padding: ${$.layout.padding1}px 0;
  line-height: 1.2em;
  span {
    &:not(:last-child) {
      margin-right: ${$.layout.margin1}px;
    }
  }
  span:first-child {
    font-family: Raleway Bold;
    font-size: 17px;
    text-transform: uppercase;
    color: ${$.color.orange};
  }
  span:last-child {
    font-family: Raleway Regular;
    font-size: 16px;
    color: ${$.brown4};
  }
`;

const Card = ({
  title,
  rating,
  media,
  genres,
  pairings,
  banner,
  slug,
  status,
}) => (
  <Container to={`/fanfiction/${slug}/`}>
    <MediaFranchise>{media}</MediaFranchise>
    <ImageContainer>
      <img src={importOneImage(banner)} alt={title} />
      <Status status={status}>{status}</Status>
    </ImageContainer>
    <Title>{title}</Title>
    <Info>
      <TextPerLine>
        <span>Rating</span>
        <span>{rating}</span>
      </TextPerLine>
      <TextPerLine>
        <span>Genres</span>
        <span>{genres.join(', ')}</span>
      </TextPerLine>
      <TextPerLine>
        <span>Pairings</span>
        <span>{pairings.join(', ')}</span>
      </TextPerLine>
    </Info>
  </Container>
);

Card.defaultProps = {
  title: '',
  rating: '',
  media: '',
  genres: [],
  pairings: [],
  slug: '',
  status: '',
  banner: '',
};

Card.propTypes = {
  status: PropTypes.string,
  slug: PropTypes.string,
  banner: PropTypes.string,
  title: PropTypes.string,
  rating: PropTypes.string,
  media: PropTypes.string,
  genres: PropTypes.arrayOf(PropTypes.string),
  pairings: PropTypes.arrayOf(PropTypes.string),
};

export default Card;
