import styled from 'styled-components';

import $ from '../../styles/globals';
import Story from './Story';
import Card from './Card';
import Twitter from '../SocialFollow/Twitter';
import NabeTalking from '../../assets/icons/talking.svg';

const Container = styled.div``;

const SocialFollowTool = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  > * {
    margin-right: ${$.layout.margin3}px;
    margin-bottom: ${$.layout.margin4}px;
    > &:last-child {
      margin-right: 0;
    }
  }
`;

const StyledStory = styled(Story)`
  margin-bottom: ${$.layout.margin4}px;
`;

const quoteList = [
  'No matter how the wind howls, the mountain cannot bow to it.',
  'The flower that blooms in adversity is the most rare and beautiful of all.',
  'Pride is not the opposite of shame, but its source. True humility is the only antidote to shame.',
  'To infinity and beyond!',
  'How do you spell FBI?',
  "I see now that the circumstances of one's birth are irrelevant; it is what you do with the gift of life that determines who you are.",
  "Hey, I know! I'll use my trusty frying pan as a drying pan!",
  'When do you think people die? When they are shot through the heart by the bullet of a pistol? No. When they are ravaged by an incurable disease? No. When they drink a soup made from a poisonous mushroom!? No! It’s when… they are forgotten.',
  "Why don't you die in a fire, Zeke?",
  'Poppi could tell you, but then Poppi would have to kill you',
  "Listen up, Simon. Don't believe in yourself. Believe in me! Believe in the Kamina who believes in you!",
  'You could not live with your own failure. Where did that bring you? Back to me. I thought by eliminating half of life, the other half would thrive. But you’ve shown me, that’s impossible. And as long as there are those that remember what was, there will always be those that are unable to accept what can be. They will resist.',
];

const NabeChanCard = () => (
  <Container>
    <Card
      quoteList={quoteList}
      title="Owner & Blog Writer"
      image={<NabeTalking />}
      email="nabechan10q@gmail.com"
      fullName="nabemono dish"
      type="chanko-nabe"
      likes="shiitake, tofu, meatball"
      dislikes="spicy soup base"
      bgColor={$.color.orange}
    />
    <SocialFollowTool>
      <Twitter />
    </SocialFollowTool>
    <StyledStory>
      <p>
        Hello there! I work as a full time web developer and I enjoy watching
        anime, reading manga and playing games as a hobby. Please call me
        nabe-chan here!
      </p>
      <p>
        I love fantasy works and JRPG games since I was a child. Back then,
        googling wasn&apos;t an option so finding geeky communities was a tad
        hard. When access to the Internet got a bit more widespread, my geeky
        repertoire increased by a <strong>lot</strong>. From MySpace to Blogger
        and Facebook, I kept hopping from one platform to another but I still
        enjoy sharing my personal thoughts on geeky topics. Times have changed
        now and most of my friends have since moved on.
      </p>
      <p>
        {
          "I thought to myself, if there's no community or platform to share my thoughts on, I'll make one myself! So voila, a personal blog was born. The "
        }
        <i>&apos;nabe&apos;</i>
        {
          ' in the title means hotpot in Japanese. Just like how you would add many different ingredients to the hotpot, this blog will have a little of every geeky hobby I enjoy; anime, manga and games.'
        }
      </p>
    </StyledStory>
  </Container>
);

export default NabeChanCard;
