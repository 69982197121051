import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const RemoteLink = makeShortcode("RemoteLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I befriended a ton of anime fans along this otaku journey. There are many types of them out there, this I’m sure of! One of my most favourite types are the casual ones. You know.. The one that reads/watches one or two series only in their entire lives, then brings it up in a conversation casually when the opportunity arises? They are the last person you’d expect to watch anime! Casual anime fans are those that really didn’t think too deep into the anime they watch. If they like it, they’ll watch them all. Otherwise, they’ll drop it without a fuss. They may pick up more anime since they enjoyed the first ones they watched but other than that, they are pretty chill in general.`}</p>
    <p>{`Anime is supposed to be enjoyed after all! No fuss, just enjoy the show. I talked to a lot of my friends who watched one or two anime only, and came up with this list. Granted, some are based on my own experience after observing how they react whenever I suggested an anime or two… But I’m sure everyone has experienced this before!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Casual Anime Fan #1: Only watches anime movie`}</h2>
    <p>{`I know a couple of my family and friends who fall under this category. They knew about anime, they have heard of these titles but they’ll never go anywhere near anime unless it’s shown in the cinema. This is just my personal opinion and observation, but I think they consider anime as a fiction they couldn’t get into. However, if the anime is set in the real world or is about a relationship, I see them hop into it because they have a small interest in it. Otherwise, they wouldn’t bother, not even with heavy persuasion from my side.`}</p>
    <Image alt="Kimi no Na Wa anime poster" caption="My cousin got really interested in anime after watching Your Name. She asked for more anime recommendations which I gladly give to her. I'm not sure if she actually watches those since a movie and a TV series requires different concentration... I hope she likes my recommendations though!" name="kimi-no-na-wa-anime-poster.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`Casual Anime Fan #2: Completed anime only, thanks!`}</h2>
    <p>{`I tried really hard to get my good friends to watch an anime, only to get a firm NO. The reason? It’s still ongoing! This is a sentiment I fully understand because I do the same (once in a blue moon). They’ll tell me that they’ll watch it once the episodes are fully out because they hate not knowing what happens next. But my dude! It’s ONE PIECE! You’ll wait for years and forget about it! (Which is exactly what happened.)`}</p>
    <Image alt="One Piece Wanokuni Poster" caption="I have this friend who enjoyed everything I recommended, including One Piece. He watched the first arc (Arlong) and asked how many episodes are there. I told him that it's still ongoing. Guess what he told me? 'Oh, in that case I'll wait for it to finish airing first.' Owkayyyy then~" name="one-piece-wanokuni-poster.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`Casual Anime Fan #3: If my favourite couple didn’t get together, why should I watch it?`}</h2>
    <p>{`Since I’m always up-to-date with the story, I’m the first they ask about a romance anime. “Did they get married?”, they ask. “Uhhh, no but the-”, I answered. Annnnd their interest in the anime went poof. I didn’t even finish explaining the whys.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Casual Anime Fan #4: Is it a happy ending?`}</h2>
    <p>{`I know many fans out there who prefer anime with happy endings. They asked for an anime suggestion and went straight to asking about spoilers. Like, if it’s not a happy ending, they’ll declare these suggestions moot. No matter what I do, they do not think that anime with a sad ending is enjoyable.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`All in all`}</h2>
    <p>{`I’m aware that these are all subjective, but I can’t help thinking that there are fans like the ones I mentioned out there. If you need to recommend anime titles anyone would enjoy, I suggest `}<RemoteLink to="https://myanimelist.net/anime/38680/Fruits_Basket_1st_Season?q=fruits&cat=anime" mdxType="RemoteLink">{`Fruits Basket`}</RemoteLink>{` for shoujo, `}<RemoteLink to="https://myanimelist.net/anime/918/Gintama?q=gintama&cat=anime" mdxType="RemoteLink">{`Gintama `}</RemoteLink>{`for shounen and `}<RemoteLink to="https://myanimelist.net/anime/457/Mushishi" mdxType="RemoteLink">{`Mushishi `}</RemoteLink>{`for seinen. For movies, suggest Ghibli or anything by `}<RemoteLink to="https://myanimelist.net/people/1117/Makoto_Shinkai?q=makoto%20s&cat=person" mdxType="RemoteLink">{`Makoto Shinkai`}</RemoteLink>{`. I’m sure these titles will never go wrong! If you’re feeling adventurous, suggest titles by `}<RemoteLink to="https://myanimelist.net/people/5082/Kunihiko_Ikuhara?q=kunihiko&cat=person" mdxType="RemoteLink">{`Kunihiko Ikuhara`}</RemoteLink>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      