import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const LocalLink = makeShortcode("LocalLink");
const Ads = makeShortcode("Ads");
const Video = makeShortcode("Video");
const RemoteLink = makeShortcode("RemoteLink");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`We’re back with another lit season for great anime. `}<LocalLink to="/blog/seasonal-anime-winter-2021-first-impressions-review/" mdxType="LocalLink">{`Last season is stacked af`}</LocalLink>{` and they are continuing that tradition for this spring! I admit, I couldn’t follow up with most of the anime last season but I tried my best… This time, I admit defeat. I wave the white flag because there’s too many of them! My eyes are glazing over, oh my god. But darnit, these anime are so good yet I have so little time! So I decided to just chalk them up as `}<strong parentName="p">{`TBWL`}</strong>{` aka `}<em parentName="p">{`To Be Watched Later`}</em>{`. I’ll come back to you one day… One day… At any rate, these are my favourites so far!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`My Hero Academia Season 5`}</h2>
    <p>{`I’m so happy Netflix decides to add this season to their platform. It makes watching the show much, much easier for me. We continue the story of Midoriya trying his best to become the worthy hero of the quirk One For All but this time… We get to see his classmates in action! They often start the season with a filler episode and I love it. I wanted to see more of The Big Three in action and we got it in full glory. I think what makes MHA so lovable is because of the other quirk users. They get the time to shine and we see them work together as a team. It’s not an exaggeration to say that this is what hooked me in Naruto at first. Seeing it in full blast in another story reminds me of how fun stories are if they let the side characters shine.I didn’t enjoy MHA’s S4 due to the strange pacing and scene choices but cheers! I think it will be remedied in S5. I’m genuinely excited <3`}</p>
    <Video src="https://www.youtube.com/embed/Q8bmv-14OVI" title="MHA S5" mdxType="Video" />
    <h2>{`Tensura Nikki: Tensura`}</h2>
    <p>{`Yesss! This is one of my favourite spinoff manga from Tensura. Tensura got really popular when Tensura S1 aired, so I think it got a ton of spinoff manga depicting slice-of-life of the Tensura gang. Tensura Nikki is one of the best out there. The art here is much softer, I love it already. It’s a nice accompaniment to the main series so I’m definitely enjoying it! Besides, getting to see the rest of the cast in their daily lives? Count me in!`}</p>
    <Video src="https://www.youtube.com/embed/U2GVrnPxRlw" title="Tensura" mdxType="Video" />
    <Ads mdxType="Ads" />
    <h2>{`86 (Eighty-Six)`}</h2>
    <p>{`I like the first episode so much, I went looking for the light novels to read. There are a total of 9 volumes out in Japan and the English translation only goes up to 7 volumes. I’m waiting for `}<RemoteLink to="https://www.bookdepository.com/search?searchTerm=eighty+six+light+novel&search=Find+book" mdxType="RemoteLink">{`Book Depository`}</RemoteLink>{` to do a price drop + restock before buying them up! From the first episode, I thought this is more about racism/changing the society but alas, after finishing the third volume that I managed to find somewhere online, it turns out to be more on surviving against these drones called Legion. Legion are basically bots programmed to wage war against humans. The latter volumes mentioned that they have since evolved into something even more dreadful. All in all, the light novel is a nice read with a lot of manned drone action. Plus, a lot of deaths of course. I look forward to seeing the anime animate the first volume!`}</p>
    <h2>{`Zombie Land Saga: Revenge`}</h2>
    <p>{`O. HAYOOOOOO GOZAIIIIMASU SAGAAAA! The energetic voice acting for Kotaro is backkkk and I’m loving it already! I have no clue what is the plot for S2, so you bet that I got surprised to see Franchouchou fail for their latest concert. What on earth happened? Did the crowd lose their mojo or did Franchouchou lose their winning streak? Regardless, dejected Kotaro is an unexpected scene here. I have no idea what’s next but I have a good feeling we’ll continue the craziness from S1 in Episode 2. Woo!`}</p>
    <Video title="Zombie Land Saga: Revenge" src="https://www.youtube.com/embed/gNJbGp6cnfw" mdxType="Video" />
    <Ads mdxType="Ads" />
    <h2>{`Vivy -Fluorite Eye’s Song-`}</h2>
    <p>{`An unexpected original anime that really captured my attention! I thought this is all about singing but I am so wrong. The first episode starts off with androids murdering humanity. This is definitely an `}<em parentName="p">{`androids-gone-berserk`}</em>{` kinda plot and it just happens that this hotpot loves seeing people die. A+ starting scene right there. The next scene went 180 degrees and all of a sudden, we’re off to a plot of a 100-year journey to change history. Aite, what? The whole show is all about surprise. I’m left to ponder what will happen next and I keep getting my assumptions wrong. You know the show is good if it left you pondering what will happen next! Is there singing? A bit. Is there gore though? Only for the opening scene but it didn’t shy away from explosions/head shots in later scenes. Good stuff right here.`}</p>
    <h2>{`Jouran: The Princess of Snow and Blood`}</h2>
    <p>{`I found this on Ani-One YouTube channel, another legal streaming channel that puts up anime episodes on YouTube for SEA regions. From the looks of the poster and art, I thought it’s about Japan from the Meiji era. I at least got the era right, but I didn’t get the modern/steampunk-ish alternate Japan right. There’s Tokyo in here, there’s guns, there’s… some sort of blue blooded monsters too? Okay, I have no idea what’s the mix here but I could tell that the heroine is one angsty, revenge-thirsty lady. Fortunately, they didn’t push the angsty agenda a lot but instead, choose to expand the plot by exploring the real mystery behind the happenings of the blue-blooded monsters. I just watched up to Episode 2 so I have no clue on the full story but it’s okay so far.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Seijo no Maryoku wa Bannou Desu`}</h2>
    <p>{`I started this spring’s seasonal anime with this show first actually!`}<LocalLink to="/blog/the-saints-magic-power-is-omnipotent-first-impression/" mdxType="LocalLink">{` I wrote a lengthy first impression here if you’re interested.`}</LocalLink>{` This is one of those isekai for ladies that got animated. It’s not too shabby so far and I’m interested to continue watching.`}</p>
    <h2>{`Super Cub`}</h2>
    <p>{`Ah yes, Honda Super Cub. I have heard of you. It’s a super prolific brand name with a super recognizable design. Cute girls driving motorcycles? Sign me up! The background art, animation and pacing… the art direction is done intentionally to accentuate the strength of a slice-of-life anime. Everything in this show is there for you to enjoy it as a `}<em parentName="p">{`take-it-slow`}</em>{` anime. If you like these types of anime, you’ll love this one.`}</p>
    <Image alt="Honda Super Cub C125" caption="Vroom vroom. Just imagine cute girls sitting on them and you'll get the anime Super Cub." name="honda-super-cub.jpg" mdxType="Image" />
    <h2>{`Kumo Desu ga, Nani ka?`}</h2>
    <p>{`I thought it ended last episode but nooooope, they continued straight into the second cour! Yes, more spidey action for me! I’m loving how the studio blended the hero’s story and Kumoko’s story so well. I enjoyed this short but sweet rendition much, much better than the light novel. As you know, light novels can get really wordy. Extra details here and there. I thought the anime would feel like a chopped plot that I couldn’t enjoy without being reminded of the skipped details. So far, I didn’t get that feeling at all. I’m really loving it and I hope they go all the way, just like how they did for Tensura. I have a feeling Tensura will keep going until they finish animating the story <3`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Anime that I will watch later`}</h2>
    <p>{`There’s way too many anime I’m interested out there so I’m putting them all into this list.`}</p>
    <ol>
      <li parentName="ol">{`Shaman King (2021) - It’s HEREEEEE. Childhood but even better, here we go!`}</li>
      <li parentName="ol">{`Fumetsu no Anata e - Watch this anime, guys! I love the manga. It’s good stuff.`}</li>
      <li parentName="ol">{`Slime Taoshite 300-nen bla bla long title - It’s a comfort anime with an all-ladies cast. What are you waiting for?!`}</li>
      <li parentName="ol">{`Edens Zero - I think this title really encapsulates the new strength of Fairy Tail’s author. You could tell he kinda lost his mojo and interest for Fairy Tail. He has new ideas that really didn’t fit in the fantasy world of Fairy Tail. Edens Zero is actually a really interesting blend of everything he wanted to do so far. I think he can do really good sci-fi story if he puts in effort.`}</li>
      <li parentName="ol">{`Mairimashita Iruma-kun S2 - Ah, you’re back! I’ll get back to you once you finished airing.`}</li>
      <li parentName="ol">{`Mars Red - The summary says vampires. Good. I’m in.`}</li>
      <li parentName="ol">{`Odd Taxi - This got my attention since I saw the trailer. If you don’t see any cute moe anime girls, then rest assured this is `}<em parentName="li">{`that one original anime`}</em>{` that many directors want to try at least once.`}</li>
      <li parentName="ol">{`Dragon, Ie wo kau. - House hunting! Dragon! Go go gooooo!`}</li>
    </ol>
    <h2>{`All in all`}</h2>
    <p>{`Wow, I counted 17 anime that I’m interested in. How fulfilling! This is ze life! I hope you enjoy your seasonal anime as well as I did <3`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      