import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Blockquote = makeShortcode("Blockquote");
const Ads = makeShortcode("Ads");
const RemoteLink = makeShortcode("RemoteLink");
const Image = makeShortcode("Image");
const LocalLink = makeShortcode("LocalLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`It’s a nice weekday at work. I have just completed my task and with that, a ton of free time just opened up. They say to be given free time to `}<em parentName="p">{`brush up your skills during work hours`}</em>{` is the best thing a developer could ask for and I agree with it wholeheartedly. But like everyone out there, my mind `}<em parentName="p">{`wandered`}</em>{`.`}</p>
    <p>{`I could finally do something I really wanted to do since I don’t feel super tired after work! Just like that, `}<strong parentName="p">{`a hotpot mascot came to mind`}</strong>{`. The rest is history <3`}</p>
    <Blockquote mdxType="Blockquote">
  “On my business card, I am a corporate president. In my mind, I am a game
  developer. But in my heart, I am a gamer.”
    </Blockquote>
    <Ads mdxType="Ads" />
    <p>{`The quote above is my favourite from the late `}<RemoteLink to="https://en.wikipedia.org/wiki/Satoru_Iwata" mdxType="RemoteLink">{`Satoru Iwata`}</RemoteLink>{`, ex-president of Nintendo Japan. He’s a great CEO who could work programming miracles back in his developer days but before he is a developer, he is a gamer who enjoys what he does. It is for that reason that I look up to him! It’s definitely possible to do what you `}<strong parentName="p">{`love`}</strong>{` and `}<em parentName="p">{`earn from it`}</em>{`. I took this quote to heart in a lot of things I do! It is my principle to find even the smallest joy I could find in the things I pursue. After all, there’s nothing happier than applying what you learn and love to your work. It’s a win-win situation! I find myself doing work better if I am doing the things I love.Do it because you love it. `}<strong parentName="p">{`Do it better because you enjoyed it.`}</strong></p>
    <p>{`In this blog post, I would like to talk about what drives every design and writing decision behind Geeknabe! I say `}<em parentName="p">{`drive`}</em>{` but honestly, it’s just a sprinkle of guidance and belief I knew I had to do. I hope this gives you an idea on a web developer’s thought process! (I say web developer but I’m mostly self-taught. Please don’t take my `}<em parentName="p">{`advice`}</em>{` here as law...)`}</p>
    <h2>{`Decide on the theme of the blog`}</h2>
    <p>{`Before I start any web projects, I would sit down and `}<strong parentName="p">{`think. `}</strong>{`I don’t even pull out a pen! All I do is throw some ideas around! I try not to design from the outset because it’s important to plan things out first.`}</p>
    <Blockquote mdxType="Blockquote">
  What do I want to do with my blog? What do I want from it? What should the
  blog have?
    </Blockquote>
    <p>{`I knew I wanted a geeky blog, but what should it `}<em parentName="p">{`really`}</em>{` be `}<strong parentName="p">{`about`}</strong>{`? This step will take maybe months if you can’t decide (Hahaha :()! Once the content has been decided, everything just fits into the puzzle by themselves. I quickly came up with the mascot! I’m glad I have some experience with drawing icons from my old job because it helped a lot. Some boxes here and a rectangle here… Whoa, I made a hotpot icon!`}</p>
    <p>{`The mascot `}<em parentName="p">{`nabe-chan`}</em>{` serves as a face for this blog. It’s cute, it’s relatable. It’s an everyday item (for me at least) that speaks for itself. The fundamental design for the entire blog should be cute and soft to the eye. A `}<strong parentName="p">{`wholesome vibe`}</strong>{` should be the focus of everything I do for this blog. The reason behind this is simple - this blog represents my `}<strong parentName="p">{`childhood`}</strong>{`. The excitement I get from waking up on a Saturday morning to catch cartoons on TV. The joy I feel from buying the latest manga volume from the bookstore auntie. The sleepless nights I get from playing games on my Gameboy Advance.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Geeknabe's first logo" caption="This is my initial design for the Geeknabe logo! I realized that it’s hard to see the details since the logo will get so small on the browser’s tab, so I had to go for something even simpler." name="geeknabe-design-document-2.jpg" mdxType="Image" />
    <p>{`I think I’m seriously a `}<strong parentName="p">{`sentimental`}</strong>{` person. My childhood days are nothing but kiddy happiness, but it’s these `}<em parentName="p">{`fuzzycontentment`}</em>{` that I really wish to go back to. So my blog’s design is a reflection of that! I wanted to create a final product that I could proudly declare `}<em parentName="p">{`that’s me`}</em>{`, so I took care to design every element on the blog before I start coding. I could opt for a more professional look in the coming future but for now, this is the design I currently prefer.`}</p>
    <h2>{`Pick a set of colours and stick to them`}</h2>
    <p>{`With that in mind, all the colours I picked for the blog stems directly from the `}<strong parentName="p">{`same colours I used for nabe-chan.`}</strong>{` From brown to green and orange… I just used the colour picker to pull out the colours from nabe-chan. These colours would then be the basis for the blog’s main colour set. I use them in text, buttons, background and even cards.`}</p>
    <Image alt="Geeknabe colour palette" caption="The Big Five… Kidding. Here are the main colours of my blog! I pull the extra browns from the dark brown colour." name="geeknabe-design-document-3.jpg" mdxType="Image" />
    <p>{`The colours are intentionally soft so as to `}<strong parentName="p">{`not let`}</strong>{` any single colour `}<em parentName="p">{`take charge`}</em>{` when they are on your screen. I rarely use the colour black. Pure black (#000) is super `}<strong parentName="p">{`harsh`}</strong>{` on the eye on a soft background, so I opted to use a lighter black (#333). I need to pick a `}<em parentName="p">{`main colour`}</em>{` for the blog so I chose brown (#6D4C41). From there, I increased or decreased the lightness of the chosen brown so I have a wide choice of browns to use in my blog! I’m sure you noticed by now, everything on my blog is super `}<strong parentName="p">{`brown-ish`}</strong>{`. I was inspired after seeing how `}<RemoteLink to="https://stripe.com" mdxType="RemoteLink">{`Stripe`}</RemoteLink>{` masterfully used `}<em parentName="p">{`purple`}</em>{` in every element on their site. I mean, did you see the text! It’s subtly purple! Their texts are never harsh on the eye and contrasts beautifully with the background the text is on. Depending on your country, it may show a different design but the best Stripe site is still the default US one.`}</p>
    <Ads mdxType="Ads" />
    <p>{`I didn’t use the colour white (#fff) either throughout the blog, I find it too harsh on my mobile screen. It just looks so darn bright with the text! So I changed the blog’s background to use light brown colour instead of white! I hope you noticed! Brown, brown, brown… The only time I did not use brown is for the fanfiction’s Night mode. Please visit one of the `}<LocalLink to="/fanfiction/everything-changes-but-you-season-1/1/" mdxType="LocalLink">{`fanfiction chapters here`}</LocalLink>{` and turn on Night mode :)`}</p>
    <h2>{`The best user experience is when you know what to do without me telling you`}</h2>
    <p>{`All boxes, buttons or pop-ups have a round edge to them. I would never use sharp corners unless I have to. I try my best to use less borders so as to give a minimalistic look and instead, use `}<strong parentName="p">{`spacing`}</strong>{` to denote sections. Titles and subtitles stick closer together, while images get a big, wide margin to separate it from the rest of the content. Buttons have a theme too! Red for warning buttons, green for affirmative buttons and brown for default buttons, etc. They all follow the same set of behaviours when you hover or click on them, so that helps to tell the users what to expect from the buttons. The colours are what everyone is used to as well, so I don’t have to tell you a red button means `}<strong parentName="p">{`Cancel`}</strong>{` or `}<strong parentName="p">{`Delete.`}</strong></p>
    <p>{`Here are the buttons! The HELLO buttons are the normal buttons. It’ll highlight itself if you hover over it. The big button is the “further action on the same page” button.`}</p>
    <Image alt="Geeknabe's button design" caption="Here are the buttons! The HELLO buttons are the normal buttons. It’ll highlight itself if you hover over it. The big button is the “further action on the same page” button." name="geeknabe-design-document-4.jpg" mdxType="Image" />
    <Image alt="Geeknabe icons galore!" caption="Here is the list of my favourite icons. I personally made the anime, manga, games and blog icons! The rest is pulled from free icon sites." name="geeknabe-design-document-5.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <p>{`I enjoy adding icons so I kinda… slapped cute icons wherever I could on the blog. I pulled most of the icons from `}<RemoteLink to="https://material.io/resources/icons/?style=baseline" mdxType="RemoteLink">{`Material Design`}</RemoteLink>{` site, but the unique ones are created by me. The best icons are those that tell you what to do with one look, so I took extra care to pick icons that everyone could easily understand.`}</p>
    <h2>{`Simplicity is key`}</h2>
    <p>{`If you think my blog has a simple look to it, that’s because it is done intentionally! I didn’t want to create new items unless I have to in order to reduce possible work for the future. Less things for me to code, the less I need to do! Huzzah! Not only is a simple layout easy to work with, it’s also great to pull your attention to what I want you to see.`}</p>
    <p>{`I try my best to put everything in one column so your focus doesn’t wander around. E.g. The blog post page only has one column and it’s centered! While most of my page content takes up full width (1200px), the blog post page’s main text takes up only 700px. I didn’t like how wide the paragraphs are on certain blogs I read. It’s super tiring to read from Point A, travel the full length of my screen then reach Point B for 1 line of text. I prefer the text squeezed to the center so my eyes don’t have to travel so much! I make sure to put a ton of space between the lines too so you can pick out the sentences comfortably.`}</p>
    <Image alt="700px width only!" caption="The main text takes up 700px, but the images in the blog post could go even wider! I saw how Google’s blog did the images and I wish to emulate it. I think it breaks up the visual flow nicely so the whole content doesn’t look constrained to the 700px size alone." name="geeknabe-design-document-6.jpg" mdxType="Image" />
    <p>{`Here is `}<RemoteLink to="https://blog.google/" mdxType="RemoteLink">{`Google’s blog, Keyword`}</RemoteLink>{` for reference. I refer to this blog design a lot when designing Geeknabe. When you resize the page, Keyword just beautifully resizes everything. Nothing is missing. I took many ideas from Keyword!`}</p>
    <p>{`When your eye travels across the screen, you are looking at any elements that attract your attention and this could get really tiring if `}<strong parentName="p">{`everything`}</strong>{` is grabbing your attention. This is why each page on my blog only has 2 column max. One column will have the miscellaneous information on it, while the remaining column will be where most of your focus will be.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Besides, 2 columns is much easier to handle than 3 when it comes to coding it for mobile users! I understand that you can easily feel overwhelmed on mobile. There’s so many things going on the screen and plenty of things for you to click! I wish to remove that barrier. It’s just you, and my content. (Okay, that sounds `}<em parentName="p">{`way`}</em>{` too `}<strong parentName="p">{`nice`}</strong>{` even for me. I’m honestly just really lazy to think of a creative layout...)`}</p>
    <h2>{`Geeknabe only uses 2 types of font`}</h2>
    <p>{`Surprised? Of course you are, because I used more than 3 actually! `}<em parentName="p">{`Har har`}</em>{`… Okay so, I used a lot of fonts but generally speaking, there are only 2 main fonts everywhere you look - `}<RemoteLink to="https://fonts.google.com/specimen/Lato" mdxType="RemoteLink">{`Lato`}</RemoteLink>{` and `}<RemoteLink to="https://fonts.google.com/specimen/Raleway" mdxType="RemoteLink">{`Raleway`}</RemoteLink>{`. I use Lato for the titles or subtitles and Raleway for mainly text. If I need to, I’ll swap them around! The miscellaneous fonts are just there for pretties.`}</p>
    <Image alt="Geeknabe font list" caption="To reduce the amount of fonts you download, I only added bold and regular fonts for Lato and Raleway. Cormorant and Helvetica is for the fanfiction section and Fire Emblem Three House Tea Time app. The other two are there… because they look extra nice as titles for special pages." name="geeknabe-design-document-7.jpg" mdxType="Image" />
    <p>{`Many designers often tell you that there’s no need for a ton of fonts on your site, and they are right! You just need to pick 2 and stick with that. A bit of bolding here and there or making it italics is more than enough to spice things up. Besides, sticking with only 2 makes it easier to work with. Sooner or later, your users will get used to seeing these fonts on your site, and they’ll naturally know if the text is a title or just a description.`}</p>
    <p>{`I hope you noticed that the text on my blog is `}<strong parentName="p">{`EXTRA BIG!`}</strong>{` You see, I’m getting old and from the intense screen session I get from my hobby and work is seriously killing my eyes… `}<strong parentName="p">{`cracks shoulder and massages temple`}</strong>{` Am I the type to enlarge the font sizes as big as possible when I read on my mobile screen? Yes. `}<strong parentName="p">{`Ahem!`}</strong>{` My preference aside, the font sizes are intentionally big (19px~). This decision is due to a problem I noticed from a lot of mobile sites - y’all killing everyone’s eyes with `}<strong parentName="p">{`small fonts`}</strong>{`! I wanted to let everyone enjoy reading from mobile screens without needing to enlarge the text yourself or enter Reader mode. It’s important to me that you have a pleasant time reading my content <3 The ease of finding my blog posts easily on site, buttons and links that work as expected and aesthetically pleasing font will eventually pile up and motivate you to think that `}<strong parentName="p">{`Geeknabe is a great blog to read from.`}</strong>{` (I hope)`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Write good for both myself and the readers`}</h2>
    <p>{`I’m not going to pretend I’m a good writer. There’s plenty of other great bloggers out there that smashed my expectation on what the English language can do! I was told that my writing style is super conversational and I took the compliment proudly. I honestly am not a fan of bombastic words or eloquent sentences (Because I suck at it, duh). I could try but I would probably laugh at my attempt. Phew, those usually go into the Drafts folder since I don’t have the heart to delete it.`}</p>
    <p>{`My idea of the best writings out there is one that is easy to digest at a `}<strong parentName="p">{`single glance`}</strong>{`. My write-ups don't need to be long, though I make it a point to write a minimum of 1000 words per post.`}</p>
    <p>{`It also helps that I treat my blog like an open diary so I try not to write about stuff that I `}{`*`}{`don’t want `}<strong parentName="p">{`*`}</strong>{`others`}{`*`}{`*`}{` to read about. My whole idea on this blog is to be as approachable and wholesome as possible. I try not to swear since I don’t think that’s nice but one or two might have passed over my personal rule (Oops). I try my best to steer away from writing negative clickbaits and contents that demean others because that’s not very nice… I don’t think I have the heart to do that. I love reading positive things so I thought I should strive to do the same.`}</p>
    <h2>{`All in all`}</h2>
    <p>{`I wrote this with hope that I’ll get to look back and pat myself on the back. This is my first time doing the whole website by myself since I’m always delegated a small part in any project I’m in. Now I got to do the whole big chunk, including designing it! Phew, it’s a lot of work. Since I’m free to choose and decide, I’ve done a lot of experimenting and along the way, encountered lots of mistakes and blockades. You’d think that there’s a guide out there to tell you how to get a website running but no, they only tell you bits and pieces. I had to piece the info together and figure things out along the way. It’s a great way to learn new things! I’ve actually applied some of my new findings at work! So all’s good in the end.`}</p>
    <Ads mdxType="Ads" />{' '}
    <p>{`Speaking of which, I `}<em parentName="p">{`think`}</em>{` I have improved on my written idea delivery…? Sure, it’s different from public speaking but writing down my thoughts made me realize I know so little! If I can’t elaborate it in writing, then I couldn’t have done it through speaking! I need to open up different tabs to research my point, and double check to see if folks reading it could understand the points I’m trying to make. I’m really glad I chose to blog because I’m learning so much I never knew I could! I’m slowly reaching a point where I’m comfortable with my own writing voice too. I admit, it’s super satisfying to hit the `}<strong parentName="p">{`Publish`}</strong>{` button at the end of the day. In the end, I’m super proud of this blog <3 Thank you for reading!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      