import styled from 'styled-components';

import $ from '../../../styles/globals';
import Card from './Card';
import Horizontal from '../../Ads/Horizontal';
import items from '../items.json';

const Section = styled.section``;

const Container = styled.div``;

const InnerContainer = styled.div`
  padding: ${$.layout.padding8}px 0;
  display: grid;

  ${$.screen.desktop} {
    grid-template-rows: auto;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 25px;
    grid-row-gap: 25px;
  }

  ${$.screen.tablet} {
    grid-template-rows: auto;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 25px;
    grid-row-gap: 25px;
  }

  ${$.screen.mobile} {
    display: flex;
    flex-direction: column;
    > * {
      margin-bottom: 25px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

const Content = () => (
  <Section>
    <Container>
      <Horizontal />
      <InnerContainer>
        {items.map(
          ({
            slug,
            title,
            rating,
            genre,
            banner,
            pairings,
            media,
            summary,
            status,
          }) => (
            <Card
              key={slug}
              slug={slug}
              banner={banner}
              title={title}
              rating={rating}
              media={media}
              status={status}
              summary={summary}
              genres={genre}
              pairings={pairings}
            />
          )
        )}
      </InnerContainer>
    </Container>
  </Section>
);

export default Content;
