import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import { getStrDate, showNewPill } from './Latest/helper';
import $ from '../styles/globals';
import { importOneImage } from '../utils';

const Container = styled(Link)`
  text-decoration: none;
  position: relative;
  border-radius: ${$.borderRadius.radius1}px;
  border: 1px solid ${$.brown2};
  width: 100%;
  transition: transform 0.4s ${$.transition};
  background-color: ${$.color.white1};
  display: flex;
  flex-direction: column;
  overflow: hidden;

  ${$.screen.desktop} {
    transform: translateY(0);
    &:hover {
      transform: translateY(-0.5em);
      cursor: pointer;
    }
  }
`;

const ImgContainer = styled.div`
  width: 100%;
  position: relative;
  background-color: ${$.brown4};
  height: 120px;
  overflow: hidden;

  > *:first-child {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  ${$.screen.mobile} {
    height: 100px;
  }
`;

const InfoContainer = styled.div`
  padding: ${$.layout.padding3}px;
  display: flex;
  flex-direction: column;

  ${$.screen.mobile} {
    padding: ${$.layout.padding3}px ${$.layout.padding2}px;
  }
`;

const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${$.layout.margin2}px;
  width: 100%;
`;

const PublishedDate = styled.div`
  font-family: Rubik Regular;
  font-weight: bold;
  text-transform: uppercase;
  color: ${$.brown4};
  font-size: 15px;

  ${$.screen.mobile} {
    font-size: 13px;
  }
`;

const Divider = styled.div`
  height: 16px;
  width: 8px;
  margin: 0 ${$.layout.margin2}px;
  background-color: ${$.brown5};

  ${$.screen.mobile} {
    height: 16px;
    width: 5px;
    margin: 0 ${$.layout.margin1}px;
  }
`;

const Title = styled.div`
  color: ${$.brown4};
  font-family: Raleway Bold;
  word-break: all;
  hyphens: auto;
  width: 100%;
  line-height: 1.1em;
  margin-bottom: ${$.layout.margin2}px;

  ${$.screen.desktop} {
    font-size: 24px;
  }

  ${$.screen.tablet} {
    font-size: 21px;
  }

  ${$.screen.mobile} {
    font-size: 20px;
    margin-bottom: 0;
  }
`;

const NewPill = styled.div`
  padding: ${$.layout.padding1}px ${$.layout.padding2}px;
  background-color: ${$.color.red1};
  color: ${$.white1};
  font-family: Raleway Bold;
  text-transform: uppercase;
  font-size: 15px;
  position: absolute;
  letter-spacing: 1px;
  top: 10px;
  right: -1px;
  box-shadow: ${$.shadow.normal};
  border-radius: ${$.borderRadius.radius1}px 0 0 ${$.borderRadius.radius1}px;

  ${$.screen.mobile} {
    font-size: 13px;
    top: 9px;
    padding: ${$.layout.padding1}px ${$.layout.padding2}px;
  }
`;

const Excerpt = styled.div`
  font-family: Rubik Regular;
  color: ${$.brown4};
  font-size: 17px;
  width: 100%;
  line-height: 1.3em;
  hyphens: auto;

  ${$.screen.tablet} {
    font-size: 16px;
  }

  ${$.screen.mobile} {
    display: none;
  }
`;

const TimeToRead = styled.div`
  font-family: Rubik Regular;
  font-weight: bold;
  color: ${$.brown2};
  font-size: 15px;

  ${$.screen.mobile} {
    font-size: 13px;
    align-self: initial;
  }
`;

const Tag = styled.div`
  font-family: Rubik Regular;
  font-weight: bold;
  color: ${$.color.red1};
  text-transform: uppercase;
  font-size: 15px;
  margin-right: ${$.layout.margin1}px;

  ${$.screen.mobile} {
    display: none;
  }
`;

const FullWidthTag = styled.div`
  font-family: Rubik Regular;
  font-weight: bold;
  color: ${$.color.red1};
  text-transform: uppercase;
  font-size: 16px;
  margin-bottom: ${$.layout.margin2}px;

  ${$.screen.desktop} {
    display: none;
  }

  ${$.screen.tablet} {
    display: none;
  }
`;

const TinyImageCard = ({
  date,
  title,
  excerpt,
  img,
  className,
  tag,
  timeToRead,
  slug,
  showInMobile,
}) => {
  const parsedDate = getStrDate(date);

  return (
    <Container
      className={`post-cards ${className}`}
      to={slug}
      title={`${title}${timeToRead ? ` | ${timeToRead} min` : ''}`}
    >
      <ImgContainer showInMobile={showInMobile}>
        <img src={importOneImage(img)} alt={`${title} banner`} />
      </ImgContainer>
      <InfoContainer>
        {tag ? <FullWidthTag>{tag}</FullWidthTag> : ''}
        <TagContainer>
          {tag ? <Tag>{tag}</Tag> : ''}
          <PublishedDate>{parsedDate}</PublishedDate>
          <Divider />
          <TimeToRead>{`${timeToRead} min`}</TimeToRead>
          {showNewPill(date) === true && <NewPill>New</NewPill>}
        </TagContainer>
        <Title>{title}</Title>
        <Excerpt>{excerpt}</Excerpt>
      </InfoContainer>
    </Container>
  );
};

TinyImageCard.defaultProps = {
  date: '',
  title: '',
  excerpt: '',
  className: '',
  slug: '',
  timeToRead: 1,
  showInMobile: true,
  tag: '',
  img: '',
};

TinyImageCard.propTypes = {
  showInMobile: PropTypes.bool,
  img: PropTypes.string,
  tag: PropTypes.string,
  date: PropTypes.string,
  slug: PropTypes.string,
  title: PropTypes.string,
  excerpt: PropTypes.string,
  timeToRead: PropTypes.number,
  className: PropTypes.string,
};

export default TinyImageCard;
