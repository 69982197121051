import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Blockquote = makeShortcode("Blockquote");
const RemoteLink = makeShortcode("RemoteLink");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I tapped on Aggretsuko S1 on my Netflix mobile app expecting an `}<em parentName="p">{`“office life comedy”`}</em>{` but to my surprise, it’s a really good `}<em parentName="p">{`coming-of-age`}</em>{` series! I’ve been seeing lots of vague praise for Aggretsuko. `}<em parentName="p">{`“It’s good, you should watch it”`}</em>{`, they say. When I ask what’s good about it, they would say that `}<strong parentName="p">{`it’s relatable`}</strong>{`. Alright, that’s a fair assessment given how the trailers would show a Sanrio mascot screaming about her work colleagues. But just like every anime/shows/movies that are listed snugly on my backlog, I only got to watch it when I saw S3 was released on Netflix.`}</p>
    <Ads mdxType="Ads" />
    <Blockquote mdxType="Blockquote">
  So yes, I’ve finally watched it. And yes, it’s bloody relatable. This blog
  post is my thoughts solely on S1!
    </Blockquote>
    <p>{`Aggretsuko is an anime(?) that features Sanrio mascots (duh). Our death metal singer is Retsuko, the protagonist in this series. She’s a 25 year old accountant that works for an `}<RemoteLink to="https://en.wikipedia.org/wiki/Small_and_medium-sized_enterprises" mdxType="RemoteLink">{`SME company`}</RemoteLink>{`. She’s a Scorpio and her blood type is A. She’s every bit an obedient employee as you make her out to be. She’s diligent and could never say `}<strong parentName="p">{`No`}</strong>{` to a fellow colleague out of politeness. Her daily routine on weekdays is this - get up for work, work her dues, go back home after work, surf the internet then sleep. Rinse and repeat until the weekend arrives. Sounds familiar? Of course! Retsuko is living the life of any normal and busy working adult out there! Including the work colleagues you hate but can’t say out loud (`}<em parentName="p">{`Sigh…`}</em>{`). She has to de-stress somehow, so she sang her heart out through karaoke with her… `}<strong parentName="p">{`deathly screams`}</strong>{`.`}</p>
    <Image alt="AAAAAAAAAA I HATE WORK I HATE MY BOSS phew okay back to work" caption="She's angry, but she has no one to rant to. So she screamed her anger out in the toilet. This de-stressing method is not going to last long..." name="aggretsuko-season-1-screenshot-4.jpg" mdxType="Image" />
    <p>{`At the beginning of the season, the series is every bit as I expected. There’s the `}<em parentName="p">{`work colleagues`}</em>{` with their assigned characters that every office seems to have and the `}<strong parentName="p">{`insurmountable, sexist boss`}</strong>{` bullying our poor Retsuko. You see, Retsuko is responsible and diligent with her work. She has yet to say `}<strong parentName="p">{`No`}</strong>{` to any of the boss’s or manager’s demands, which make her a prime target for work pressure. It’s a terrible chicken and egg problem. Should Retsuko voice out her worries to the higher ups or risk making her life difficult? Or should she weather it out and hope things will change for the better? Regardless, she feels terrible and no amount of `}<em parentName="p">{`weathering it through`}</em>{` could heal the pent up stress she has. I feel for her because this is the most stressful position to be.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Urgh. Annoying." caption="This is the part where he demands that Retsuko make him a cup of tea. Why? Because she's a woman. Sheesh, these bosses are terrible. Please don't ever make me meet one, I might slap them if I crack under pressure." name="aggretsuko-season-1-screenshot-2.jpg" mdxType="Image" />
    <p>{`To make things worse, Retsuko has `}<em parentName="p">{`no allies at work`}</em>{`. She works hard to fulfill her tasks and go home unfulfilled (and super late too!) after that. Even her closer colleagues, Haida and Fenneko couldn’t break through her wall. Retsuko kept replying to their concerns politely even when they were pressing her. One day, the poor girl is going to break because no amount of screaming will help alleviate her worries nor solve her problems. The trick to make a hateful workplace much more bearable is to get close friends from the same company that could understand your work situation. You could explain the situation all you want to a friend, but they won’t be able to understand your position. Retsuko needs understanding friends from her workplace and thankfully, she did make new friends!`}</p>
    <Image alt="They are both really great characters! I like these two ladies' dynamics with each other." caption="From the left, Washimi and Gori walking confidently at work." name="aggretsuko-season-1-screenshot-5.jpg" mdxType="Image" />
    <p>{`She befriended two really good folks, Gori and Washimi from Marketing and Secretariat. The series didn’t give these two ladies enough credit. Gori is in her 40s while Washimi is a divorcee. The three girls have different backgrounds but the friendship started because Gori and Washimi saw Retsuko for who she is, even after they witnessed Retsuko `}<strong parentName="p">{`screaming`}</strong>{` in her favourite karaoke place. It was only to these two ladies that Retsuko could speak up her worries unhindered. It was around this part in the story that Retsuko wanted to marry rich to quit her job.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Your early 20s can be scary or life-changing. You lived your life super carefree as a student when all of a sudden, you’re asked tough questions on what you want to do in your life. No one’s leading you now so you must decide for yourself. The leap into the adult working life is a sudden but high hurdle. No one out there could be fully prepared for it. Work is tough, no one signed up for this! I empathize with Retsuko’s silly conclusion that marriage is the only way out from the working life. It’s `}<strong parentName="p">{`understandable`}</strong>{`, seriously.`}</p>
    <p>{`Soon in the story, Retsuko had her first love. She mistook an initial meeting as `}<em parentName="p">{`clicking well with the other party `}</em>{`with Resasuke, a quiet office worker from the Sales department. Now if you’re wondering if such a quiet and apathetic person could exist in real life then yes, it’s possible. I have my fair share of meeting such folks… Hard to talk to but definitely not a bad person in general. Their heads are always up in the clouds and you could never count on them to react normally in pressing social situations. Honestly, Resasuke isn’t bad to the bone. That’s just how he is!`}</p>
    <p>{`He answers with one sentence and never bothered asking intimate questions about Retsuko. This translates to him being a dork in these social situations - not noticing Retsuko’s injured heels, walking faster than Retsuko and not bothering to slow down for her. All of these happened because he had no interest in Retsuko as a girl, he’s only happy with his plants. These are clearly warning signs but Retsuko is `}<strong parentName="p">{`desperate`}</strong>{` for something exciting in her life. She’s clutching at straws, hoping that this is her chance to get a better life that she sees everyone is leading.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Be considerate!" caption="Please slow down for your partner or wait for her at least. It's small gestures like this that builds up to a favourable impression!" name="aggretsuko-season-1-screenshot-7.jpg" mdxType="Image" />
    <p>{`S1 closes with Retsuko snapping out of her first love and re-establishing her thoughts on the reason how and why the boss’s treat her as such. All is slightly better than when it first started, but it’s clear to see that there’s more coming soon in Aggretsuko’s life. I was really surprised to see how Retsuko’s thoughts and situations were handled in the series, including the side characters. I mean, it worked so well! Office politics is such a headache and Retsuko’s troubles seem not too far from my own. I’d keep my distance and keep up a facade too if I think no one would understand why I love screaming my heart out.`}</p>
    <Image alt="At least take some interest dammit." caption="If Resasuke actually took interest in Retsuko, he may have stood a chance. Unfortunately, he didn't. Seeing how he didn't even react to her screaming, Retsuko decided that he's not the one after all." name="aggretsuko-season-1-screenshot-8.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <p>{`Aggretsuko S1 was about Retsuko coming to terms with her new reality, except it was 5 years late. It was pretty amazing to see how a series with Sanrio-like designs could accurately portray how it felt like jumping into the next stage in life, along with the hidden intricacies of making a connection with someone new. Aggretsuko is an underrated gem that I never expected. Onwards! To S2!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      