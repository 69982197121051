import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Have you ever had that urge to shut down your brain and just pick a random webtoon to read for fun? You had a tough day at school or work, and all you want now is to waste your time not thinking about anything. You just wanted to absorb and not think about things too deeply! Shounen manga is nice, but sometimes just scrolling down and down without thinking too much about it feels much better.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Webtoons/Manga with heavy or deep themes are great but you’ll want to switch to a webtoon with a more… soothing vibe. So here are 7 of my favourite webtoons with a simple plot, easy to digest dialogue and easy-on-the-eyes art!`}</p>
    <h2>{`Comfy & Relaxing Webtoon Recommendation #1: Roland Roland`}</h2>
    <p>{`I admit, the reason I even bothered to click on this title is because of the corgis. That’s right, this webtoon has good boys and girls in them!`}</p>
    <Image alt="Roland Roland Webtoon Art" caption="I found this image on a Facebook page. I noticed that it had all of the main cast's dog and human form! I have yet to see a Pomeranian yet though..." name="roland-roland-webtoon-recommendation.jpg" mdxType="Image" />
    <p>{`Introducing Roland, the prince of a kingdom that is entirely occupied by dogs. Roland’s parents themselves are corgis, and so is Prince Roland! These dogs could talk, transform into humans and think like one… But why? Well, once upon a time, the humans angered God and for that, they were banished into oblivion. Being humanity’s best friend, the dogs begged God to forgive the humans. God finally relented but promised to forgive humanity only if the dogs could complete their duties with humanity’s gifts - appearance, intelligence and magic. It’s a simple lore but think of this as the backstory to explain why dogs could transform into their human self and speak.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Our main protagonist, Prince Roland is a shortie because… well, he’s a corgi. Displeased with this, he thought to ask the High Priest Rhode to give him longer legs. But the journey there is treacherous! So he sought to improve his combat skills by training under Ydi, the Royal Family’s Bodyguard. Fast forward to 2 years later, Prince Roland… did not improve AT ALL. So he kinda just gives up and enjoys his happy but lazy princeling life as it is. Man, he lacks conviction. Unfortunately, something dreadful happened to the Queen that forced him to move his butt. For some reasons, the Queen’s intelligence reverted back to a normal dog and she could not speak nor transform into a human! So off Prince Roland goes to seek out High Priest Rhode for a cure! But he couldn’t even beat a monster on his own! Can he do it?`}</p>
    <p>{`The story is presented in a 4-panel per scene with a really bright and poppy colour palette. It’s actually pretty genius if you think about it. Usually 4-panel comics have this obvious plot flow across the panels, which kinda defeats the purpose of 4-panel comics. Why not just make it a full page instead, amirite? But Roland Roland’s take on the 4-panel concept is a proper one! Each 4-panel scene has a clear idea and is presented really well! Humour, idea, colour and even choice of angles for each panel! The author is actually really good at this! The colours are flat so it’s easy on my eyes and you just know which character is which when reading. It also helps that their dog form and human form matches up nicely in terms of design! It’s an unexpected win for me when I randomly clicked on it to read. Please give it a try!`}</p>
    <h2>{`Comfy & Relaxing Webtoon Recommendation #2: Fantasy Sister!`}</h2>
    <p>{`Oh, this is such a cute webtoon! If you love stories that involve playing an online game together with a bunch of friends, discovering meta together or acing the game in a team, then this is for you.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Fantasy Sister! Webtoon Art" caption="The art is all cutesy but the plot is even better! If you enjoy stories that has the main cast just enjoying the online game as a group of friends, you'll love this one." name="fantasy-sister-webtoon-recommendation.jpg" mdxType="Image" />
    <p>{`As everyone knows, Korean love their online games. It’s kinda where all popular MMORPGs are born! Everyone loves games in this story except our protagonist Kang Eun Jung. She’s strangely one of those who held out her no-games stance in her class despite everyone is into games! Thus, this makes her the odd outlier who are often left out in her circle of friends. This frustrated Eun Jung since not a single friend of hers wanted to hang out with her anymore. In retaliation, she decided to try the popular game Aurum Wing that everyone’s talking about and reach max level in order to win a bet with her brother.`}</p>
    <p>{`Everything’s fine and all but here’s the thing - she’s clueless about games! So she made a ton of strange decisions in the game which totally turned her gaming experience upside-down. It’s those types of stories where the main character makes a ton of silly decisions a normal gamer wouldn’t and gained a ton of new friends along the way. It’s definitely a cute story and FYI, her brother is a siscon in a hilarious way, lmao.`}</p>
    <h2>{`Comfy & Relaxing Webtoon Recommendation #3: Empress Cesia wears knickerbockers`}</h2>
    <p>{`I once recommended this webtoon title in this `}<a href="/blog/10-female-lead-feminist-webtoon-and-manga-recommendation/">{`blog post here`}</a>{`. If you like stories on female empowerment, you should read the blog post I linked… as well as this title I’m about to recommend! Sure, it’s a female empowerment storyline but `}<em parentName="p">{`Empress Cesia wear knickerbockers`}</em>{` has an intriguing, down-to-earth story that made me feel that it fits right into this blog post. In fact, I binge it whenever I feel fancy.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Empress Cesia Wears Knickerbockers Webtoon Art" caption="I pulled this from WEBTOON! Yup, it's up on WEBTOON. You can read it there OFFICIALLY and for FREE! But only if you understand Bahasa Indon... Here's hoping it's coming to the English one soon." name="empress-cesia-wears-shorts-webtoon.jpg" mdxType="Image" />
    <p>{`Empress Cesia is one powerful lady. At the tender (Still young!) age of 29, she has conquered all 99 countries and unified the continent! Despite sitting on the most desirable throne, the queen of Valencia is troubled by her corset. It’s tight, it’s uncomfortable and it’s certainly unbearable for the queen! And so she sent out her trusted aide to look for comfortable clothes that she could wear. Lucky for her, a certain modern designer from Korea happened to be reincarnated as Yuri, a dressmaker who’s about to revolutionize fashion.`}</p>
    <p>{`I find the story so wholesome and respectful on portraying women’s difficulty in medieval society. Seeing how Yuri rose up to the occasion and Cesia reciprocating in return really warms my heart. Yuri and Cesia are both really progressive individuals that they in turn, influence each other to do better. I highly recommend that you read this with a clear mind and let the webtoon guide you!`}</p>
    <h2>{`Comfy & Relaxing Webtoon Recommendation #4: ReLIFE`}</h2>
    <p>{`ReLIFE actually got an anime adaptation and it’s equally wholesome. What will you do if you get a second chance at life to `}<strong parentName="p">{`relive your high school life`}</strong>{` and `}<strong parentName="p">{`be a better person`}</strong>{` than you are now? This is a completed series and it’s a long, long hike. There’s about, 200+ chapters? If you like the total number of chapters (I know folks who love reading a long story), then it’s definitely for you.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="ReLIFE cover art" caption="I remember the anime being hot stuff when it first aired. It's definitely a great story." name="relife-webtoon-recommendation.jpg" mdxType="Image" />
    <p>{`Meet Arata Kaizaki, a 27 year old bloke who’s out there hitting at every job interview he landed and yet, he couldn’t get a job at all. Was it his jumpiness during the interviews that deterred the interviewers? Nay, he couldn’t explain why he left his first job after a mere 3 months. At 27 years old and no job, he’s finally feeling the pressure. One day, a suspicious man approached him out of nowhere to make him an offer he couldn’t resist - take a pill to revert back to a 17 year old and enjoy high school again while under surveillance for a year. It sounds really dubious but Arata is forced to accept the deal when he notices that he really `}<em parentName="p">{`did`}</em>{` turn younger after taking the pill. Now, will Arata finally get to enjoy his forgotten school days to the fullest, or repeat his same mistakes that led to his situation as a 27 year old?`}</p>
    <p>{`If you’re wondering what’s the deal with this webtoon, it’s a bit of romance and a ton of character growth. You see, there’s a reason why Arata was approached in the first place. The company ReLIFE handpicked individuals with troubled history to see if they could change them by giving these individuals the incentive - reliving your high school life. It’s an interesting webtoon that pitched a lot of ideas and thoughts behind character growth. Pretty mature take for a high school setting all in all.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Comfy & Relaxing Webtoon Recommendation #5: The dragon next door`}</h2>
    <p>{`It’s a super silly webtoon that I flicked through years ago. I thought of this title the very moment I wanted to write a webtoon recommendation blog post. I cackled a lot while reading this because it’s so hilarious and silly, I hope you will too!`}</p>
    <Image alt="The Dragon Next Door book art" caption="Huh. I actually found out the webtoon got a hardcopy! This is the cover art for the printed version." name="the-dragon-next-door-book-art.jpg" mdxType="Image" />
    <p>{`Woo-Hyuk is a freelancer and he worked hard to get his own place to stay in the name of independence. He got tricked by the sweet aunty to buy rice cakes for the neighbours and voila, he found out that his neighbour is a… dragon? Turns out dragons do exist! Some hid in the wilderness while some hid amongst the humans. But why would a dragon stay in an apartment meant for humans? Well…`}</p>
    <Image alt="The Dragon Next Door Webtoon Panel 1" caption="I, too, could not resist the allures of Korean MMORPG. Maplestory, anyone? I tried Cabal and Dragon Nest for a bit, but Maplestory is still the best." name="dragon-next-door-webtoon-panel.jpg" mdxType="Image" />
    <p>{`This webtoon is basically a bimbo dragon enjoying his human lifestyle while Woo-Hyuk would interject his jokes a lot. Like, a dragon trying to get a jackpot? That’s not very dignified of you, Mr. Dragon Kim. If you like the first chapter, then you’ll enjoy the rest!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Comfy & Relaxing Webtoon Recommendation #6: The wolf that picked up something`}</h2>
    <p>{`I’m not too sure if you’ve seen images of an ex-K9 dog having sweet moments with a cat/puppy floating around on Facebook/Instagram/9gag but if you do, then this webtoon is basically that trope!`}</p>
    <Image alt="The wolf that picked up something webtoon art" caption="King of Wolves is now a big, bad nanny <3" name="the-wolf-that-picked-something-up-webtoon-recommendation.jpg" mdxType="Image" />
    <p>{`So you have this really unfriendly looking wolf who claims he is the fiercest wolf there is… And he’s currently having the worst day of his life! A cute puppy has attached himself to him and he doesn’t know what to do! The wolf is going to chase the puppy off, but the puppy is too weak and defenseless to fend for himself. Seeing how weak the puppy is, the wolf decided to take him into his pack. Thus mark the start of fluffy, warm moments between the wolf and the puppy...And that’s it. That’s the plot. It warms my heart to read this webtoon. It’s short and sweet. Give it a try!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Comfy & Relaxing Webtoon Recommendation #7: Trinity Wonder`}</h2>
    <p>{`The author of Trinity Wonder did The Breaker. If you have read The Breaker, then that’s great! If you have not, I highly recommend you do! But before that, please try Trinity Wonder first and with your logic gears turned off. This is because Trinity Wonder has the silliest setup for the plot and I’m okay with that. There’s a reason why I added this title into this recommendation list.`}</p>
    <Image alt="Trinity Wonder Emily" caption="Trinity Wonder don't really have any cover art I could add here. So I added Emily instead. She's really cute and uber strong." name="trinity-wonder-emily.jpg" mdxType="Image" />
    <p>{`Have you ever wondered what would happen if a martial artist, a magician and a technologist got together at the same place? That’s what the author had in mind. Let’s put a bulky martial artist from Murim, a cute and powerful magician from high fantasy and a sexy CEO from a tech company together in one webtoon! Sylvia (Sexy tech CEO) is about to do a test run of her teleporter, one of the revolutionary products that her company created. The moment it booted up, two unlucky folks appeared through the portal. The first is Gang Woo-Jin (martial artist from Murim) who was flung into the portal during his fight with Lim Hoh-Tan and ended up in Sylvia's world. The second is Emily (cute magician) who is supposed to be on her way to defeat the Demon Lord, until Sylvia’s portal sucked her in.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Both were confused when they first landed at Sylvia’s world. Plus, they couldn’t communicate due to language barriers. Gang Woo-Jin and Emily duked it out until Sylvia had to rush in to stop them. The two outsiders were quickly placated and briefed about the situation. Both wanted to go home but they kinda bashed up the teleporter Sylvia spent years to build. So they have no choice but to follow Sylvia back home. Little did they know, their enemies followed them to the new world too...`}</p>
    <p>{`I had so much fun reading Trinity Wonder! The author probably got the idea of doing this after a night of drinking with friends, it’s absolutely bonkers! The art is great, the colours and effects are super well done. As for the plot… I can imagine the author giggling to himself while writing the dialogue. Honestly, dude just wanna have some fun drawing his fanfic. I highly recommend this title. It’s completed so have fun!`}</p>
    <h2>{`All in all`}</h2>
    <p>{`I hope you enjoy these webtoon recommendations! While I admit, the genre varies but each one of these titles gave me a relaxing vibe/feeling. There’s not much intense plot going on in these titles so you’re free to just let the story guide you in naturally. Enjoy!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      