import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXFanfiction/Chapter/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const RemoteLink = makeShortcode("RemoteLink");
const Divider = makeShortcode("Divider");
const Image = makeShortcode("Image");
const AuthorNote = makeShortcode("AuthorNote");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`“I’m so tired.” Byleth yawned as she rested her head on the rattling bus window. It was 7.25am and she had been up almost all night worrying and fretting about Dimitri’s first trip to a theme park. She didn’t know how he’d take it since Dimitri had spent almost 20 years underwater away from humans.`}</p>
    <p>{`She couldn’t help but worry since she was now partly responsible for his ‘Human-Life Adaption Programme’ after being forcefully deputised by Felix a month or so ago.`}</p>
    <p>{`In fact the day before she suggested he take another trip to the beach in case the rollercoasters sucked out his life force, even though they went a few weeks ago. She didn’t follow him this time, since he wasn’t partially dying of dehydration, plus there was no need to show her his hometown again and he seemed familiar enough with the bus system to go with Dedue as he usually did.`}</p>
    <p>{`“Too bad,” Reus replied flatly. He, like Byleth, wasn't very fond of crowded public spaces. “By the way, are you sure Dimitri isn’t your boyfriend?”`}</p>
    <Ads mdxType="Ads" />
    <p>{`Byleth turned her head to glare at her brother at his sudden question, “No, why?”`}</p>
    <p>{`“Does he even have a girlfriend at all?” Reus said, ignoring her glare.`}</p>
    <p>{`“I don’t think so? Why?” She squinted at him.`}</p>
    <p>{`Reus leaned in and whispered as he nodded at Dimitri who was sitting on the aisle next to them, “He has hickeys on his neck.”`}</p>
    <p>{`“What?” Byleth slowly peeked over her brother’s shoulder and sure enough, there was a trail of love bites from the base of Dimitri’s earlobe that disappeared into his shirt. She must’ve been really tired not to notice it before.`}</p>
    <p>{`Did...Dimitri have a lover he didn’t tell her about? Her heart sank at the thought of him not telling her, even though it really wasn’t any of her business.`}</p>
    <p>{`While the whole swimming team had been invited and in fact, urged to go on this little excursion to the Gronder Field theme park, only Sylvain, Ingrid, Dimitri, Dedue, Ashe, Reus, Byleth and somehow...Felix were on the bus today.`}</p>
    <p>{`Of course Felix had sat right at the back of the bus, insisting that he only came ‘because his dad was giving him a hard time about shirking his responsibilities with Dimitri since the alcohol episode happened.’`}</p>
    <p>{`After hearing about the close relationship Dimitri and Felix had as children and now adults, she concluded that Felix actually cared for Dimitri and came along to make sure the poor mermaid didn’t accidentally kill himself on a merry-go-round.`}</p>
    <p>{`Byleth climbed on her seat and sat on her knees, sneaking a look at Felix and sure enough, the dark haired male was sulking furiously at the back while Sylvain was talking loudly to him about something indecent.`}</p>
    <p>{`She turned back to the front when Ingrid, who was sitting next to Ashe, offered her some sandwiches she packed for the 1 hour 30 minute journey. “Thanks, I’ll share this with Reus,” Byleth said.`}</p>
    <p>{`“Oh no, please, I made enough to go around so don’t be shy,” Ingrid insisted and pressed another sandwich wrapped in cling film in her hand. Byleth handed the freshly made egg salad sandwich to Reus who almost swallowed it whole.`}</p>
    <p>{`Byleth observed Ingrid who was now handing out her sandwiches to everyone else, and remembered Felix mentioning that the swim team manager didn’t know anything about Dimitri or the mermaids since she was only engaged to Glenn, not married.`}</p>
    <p>{`“I’m going to ask,” Reus said, a slight cheeky smile plastered on his face.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Ask him what?” Byleth asked, utterly confused. Her brain wasn’t working well today.`}</p>
    <p>{`“If he had a good night with his lover-” She elbowed her twin before he could finish his sentence.`}</p>
    <p>{`“Don’t tease him, he’s shy. And I’m sure that...those..hickeys came from somewhere else,” Byleth said, looking away. She still wasn’t sure why she felt like she was jealous at the thought of Dimitri having a lover. Weirdly, she never felt this way before. Even when Reus sometimes abandoned her for new friends when they were in school.`}</p>
    <p>{`“Pfft,” Reus snorted, “Yeah, right.”`}</p>
    <p>{`“This is so cool!” Byleth heard Ashe say, “They even have a dress up and transformation studio! Oh I’ve always wanted to be a knight!”`}</p>
    <p>{`Both Byleth and Reus leaned forwards and squinted at Ashe’s phone from over his shoulder, “Really? Is it expensive?” Byleth asked.`}</p>
    <p>{`“Ermm…” The freckled male hummed as he scrolled down the page, “Individually yes, but it says here group prices are cheaper, would you guys like to dress up?” Ashe turned around and Byleth swore his eyes sparkled as he pleaded like a giant puppy in front of her eyes.`}</p>
    <p>{`She and Reus looked at each other, and Byleth could tell exactly what her twin brother was thinking just by the subtle changes in his face, `}<em parentName="p">{`‘We want to but we’re shy and have never done this before.’`}</em></p>
    <p>{`“Uh...We’re...We want to...You go on ahead...” Reus began but Ashe protested.`}</p>
    <p>{`“But um...Well Ashe, er we’ve never done this before and we’re rather embarrassed…” Byleth nodded in agreement. Also she was spot on.`}</p>
    <p>{`Ashe grabbed Reus’s hand, “It’ll be so fun! Look, there are so many choices! You can dress as an Archer, a Sniper, a Bow Knight, and even a Wyvern Knight! Here, I’ll send you guys the link!”`}</p>
    <p>{`Her phone pinged and she tapped on the link Ashe sent her. Thanks to her love of History, Byleth was very, very, tempted to do this whole dress up thing. The costumes looked high quality and clean. She was particularly attracted to the ‘Dancer’ costume actually, even though it was a little bit revealing.`}</p>
    <p>{`“I gotta say...these look pretty good,” Reus said, “I really like this ‘Enlightened One’...”`}</p>
    <p>{`Byleth nodded, “I know what you mean. So...you...wanna...join them? Since you know...there’s a group discount…” She said sheepishly, eyeing her brother.`}</p>
    <p>{`Reus scratched his cheek, “Y-Yeah...I mean...you know...might as well,” he shrugged and she agreed, feeling excited then relieved Reus was on board instead of teasing her about it.`}</p>
    <p>{`The rest of the bus trip was Ashe excitedly telling everyone about it since the group discount was for a minimum of 8 people. Sylvain and Ingrid were easily persuaded while Dimitri and Dedue didn’t have much commentary apart from saying they’d think about it.`}</p>
    <p>{`Felix, of course, was unapproachable but Ashe seemed completely immune to Felix’s World Class Frown, “I insist Felix, i-it’s good bonding,” Ashe said. Byleth and Reus, the ever curious twins, had their eyes and ears attuned to the conversation unfolding behind them. Byleth was on standby in case Felix actually decided to eat Ashe or something.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“....I don’t want to bond, go away.”`}</p>
    <p>{`“Y-You could even dress up as...swordsman o-or a sorcerer, l-look…!” Ashe shoved his phone in Felix’s face. “There are s-several options!”`}</p>
    <p>{`“Do you think Felix will agree?” A deep, gruff voice next to them said. As if on cue, Byleth and Reus turned their heads to see even Dimitri and Dedue were very discreetly listening to the same conversation as them.`}</p>
    <p>{`Dimitri shrugged, “I...I don’t know...but Felix has always enjoyed swords...even when we were children.”`}</p>
    <p>{`“Gronder Fields!” The bus driver called out suddenly and they all spilled out of the bus and to their absolute horror, it was already full of people. Even though Sylvain had booked their tickets online, the queue to get in was snaking round the theme park almost.`}</p>
    <p>{`Byleth looked back to see Dimitri shuffling out of the bus with a very prominent scowl on his face, almost on par with Felix’s. She was worried about how he’d take the whole experience and didn’t manage to talk to him much. She caught his eye and she gave him a quick ‘Ok?’ hand sign, to which he seemingly understood and nodded in reply.`}</p>
    <p>{`“Oh man, I hope we manage to get a slot at the dress up studio…” Ashe said with his bottom lip jutting out.`}</p>
    <p>{`Ingrid placed a hand on his shoulder, “Me too, okay everyone, just so we’re on the same page, is everyone here up for the dress up? I wouldn’t want us to be held up trying to make a decision when we finally get there.”`}</p>
    <p>{`“Definitely,” Sylvain raised his hand. And So did Byleth and Reus.`}</p>
    <p>{`“You two?” Ingrid turned to Dimitri and Dedue. The gentle giant pair looked at each other without saying anything, it was as if they were having an in depth conversation with just their eyes.`}</p>
    <p>{`“We would like to join you, if that is alright,” Dedue finally said 3 seconds later.`}</p>
    <p>{`“Yay, great!” Ingrid smiled, then her expression sombered when she moved onto Felix.`}</p>
    <p>{`“Felix?” She asked, somewhat cautiously. Ingrid couldn’t see but Ashe was unleashing the full force of his puppy eyes onto Felix behind her shoulder. “I promise I won’t tell Glenn,” she said with a serious voice.`}</p>
    <p>{`“...”`}</p>
    <p>{`“...”`}</p>
    <p>{`Everyone in their small group was on the edge of suspense, all hoping for Felix to say yes but the chances of him agreeing was almost as rare as him smiling. They all spun their heads, yes, even Dimitri and Dedue turned to look at Felix with shocked eyes when he agreed.`}</p>
    <p>{`“Ugh, fine,” Felix groaned as he slumped his shoulders, “Why is this happening to me…”`}</p>
    <p>{`As they inched closer to the entrance, “Felix...have you….decided what you want to...wear?” Dimitri asked.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“No, shut up,” Felix said, blushing.`}</p>
    <p>{`Dimitri merely smiled and nodded, which was another improvement in his temperament. But then again, Felix was like family to him, maybe it didn’t count.`}</p>
    <p>{`Ashe suggested they should head to the transformation studio first, so that they had more to choose from and wearing such thick cosplay clothing would be uncomfortable if they did it in the afternoon. And he was right, thankfully they were a few steps faster than the other guests in the park, just as they stood in line to pay, the queue behind them was getting longer.`}</p>
    <p>{`“Hey Dimitri, what’re you wearing? With pecs like yours you should do the brawler one, yeah?” Sylvain slung an arm over Dimitri, stroking his chest with a wink. Dimitri, visibly uncomfortable and blushing, shook his head.`}</p>
    <p>{`“Oh, is your name Dimitri, sir?” The lady at the counter asked, “We have a special secret costume menu for people who are called Dimitri, Edelgard, Claude, or anyone who is named after Fódlan’s past rulers.”`}</p>
    <p>{`“...Yes...my name is...Dimitri,” Dimitri said, fidgeting where he stood.`}</p>
    <p>{`“That’s so cool!” Ashe squeaked.`}</p>
    <p>{`“Do you have identification that I can verify?” The lady asked. For some reason, Byleth panicked. Did mermaids have ID? What if he didn’t?`}</p>
    <p>{`“...Yes, hold on.” Dimitri said and fished out his wallet from his back pocket, pulling out a very yellowed looking piece of plastic. She leaned over and saw a little girl’s picture on the card with a neck length bob cut and big sparkly blue eyes.`}</p>
    <p>{`“Goddess, Dimitri is that you?” Sylvain snatched the card from his hands after the lady had verified it, “Oh my goddess you were such a cute little `}<em parentName="p">{`girl`}</em>{`,” Sylvain snorted.`}</p>
    <p>{`“Oh no you’re right, you’re even prettier than me!” Ingrid said, “Though you do need to get it changed though. It’s so old, it’s a good thing the lady here accepted it.” Dimitri nodded.`}</p>
    <p>{`“Phew, who thought a man with an ass like yours was such an innocent little girl eh?” Sylvain winked as he smacked Dimitri’s butt.`}</p>
    <p>{`“This is stupid, just get on with it,” Felix growled, “Stop molesting Dimitri, he’s about to cry,” he barked at Sylvain who apologized but Dimitri shook his head.`}</p>
    <p>{`The package they chose included a couple of printed photos, and they could also use their own phones to take pictures, plus they were allowed to walk around the park for an hour in costume as well.`}</p>
    <p>{`Byleth still had her eyes on the dancer costume, but she felt shy...it was a very alluring costume. Not to mention Reus would probably tease her as well. `}<em parentName="p">{`Brothers`}</em>{`, she rolled her eyes.`}</p>
    <p>{`“Hey, what do you have in mind?” Ingrid plopped down next to her on a sofa as the studio assistants prepped the costumes, “I’ve chosen Pegasus Knight and they even have a horse you can take pictures with! Ah, this was such a good idea!”`}</p>
    <p>{`“Really? Well, I...can’t really decide,” Byleth said, scratching her cheek, “The one I want looks too revealing but...my heart is set on it.”`}</p>
    <Ads mdxType="Ads" />
    <p>{`Ingrid grabbed her hand, “Then go for it! You have a great figure! Don’t be shy, I’ll chase away the boys if they make you too uncomfortable.”`}</p>
    <p>{`“Oh no, no, Ingrid. I’m really chubby and err...my belly will...leak out-”`}</p>
    <p>{`Ingrid shook her head, “Nuh-uh, we didn’t pay all that money just for you to choose something you dislike. And trust me, you look amazing! Which one is it anyway?”`}</p>
    <p>{`Byleth pulled up the screenshot she took and Ingrid nodded her head furiously, “Oh yes, Byleth, `}<em parentName="p">{`yes`}</em>{`.”`}</p>
    <p>{`“...You sure? I mean…” Byleth blushed, feeling the encouragement fueling her excitement.`}</p>
    <p>{`“If you don’t, I’ll just force it on you, and it’s not even that revealing! It’s just a little thigh high is all,” Ingrid zoomed in on the image and nodded again, “And if it doesn’t suit you, we’ll just choose something else, yeah?”`}</p>
    <p>{`“Thanks, Ingrid,” Byleth gave her a quick hug. They had become quite good friends since Reus joined the swim team, but even before that they had enjoyed each other’s company as acquaintances back in secondary school since they shared a lunch table.`}</p>
    <p>{`“We’re sorry Miss, the colour you wanted is still at the dry-cleaners, but we have it in `}<RemoteLink to="https://i.redd.it/kx7pqawgq8h31.jpg" mdxType="RemoteLink">{`black`}</RemoteLink>{` instead, would that be okay with you?” The attendant came up to them with an apologetic look. Byleth nodded, a little disappointed but then black would be better at hiding all the bits she didn’t like about herself.`}</p>
    <p>{`Having said that, the costume was surprisingly complicated to wear. It had a few openings and Byleth’s head popped out of holes that it wasn’t supposed to. The back part of the toga-like dress was even attached to accessories that were supposed to go on her wrists, it was just an overall a very complex costume, she even wore it backwards the first time.`}</p>
    <p>{`She finally got her head through the right opening, she took in a deep breath since she was already tired from wrestling with the dress before adjusting her breasts. She had never worn ‘pasties’ before and she was feeling very awkward and shy at not wearing a proper bra. Well...there was a first for everything.`}</p>
    <p>{`She stood in front of the changing room mirror longer than intended, making sure that all her important bits were sufficiently covered. They had also provided safety shorts that were also included in the fee, along with the pasties and thankfully, the thigh opening could be adjusted with hidden fastenings.`}</p>
    <p>{`“Miss, do you need help?” The attendant standing outside the changing room inquired.`}</p>
    <p>{`“I-I’m okay…!” She replied, finally mustered enough courage to step out.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Holy shit... Reus, is that your sister?” Sylvain, who was wearing the `}<RemoteLink to="https://cdn.staticneo.com/ew/thumb/a/a7/Fe3h_class_cavalier.jpg/300px-Fe3h_class_cavalier.jpg" mdxType="RemoteLink">{`Cavalier`}</RemoteLink>{` armour, frantically tapped Reus.`}</p>
    <p>{`“Tsk- What? I’m trying to figure out where this belt goes-” Reus looked up and gawked at her. All of a sudden she felt extremely embarrassed her brother was still gawking at her after a few seconds. “No,” Reus said.`}</p>
    <p>{`He stomped over and covered her from Sylvain’s view, “You’ll get pregnant at this rate.”`}</p>
    <p>{`“What?” she blurted out, “You’re smothering me, get off-”`}</p>
    <p>{`“Don’t let Sylvain look at you anymore, you’ll get pregnant from his horniness. And go choose something else, your boobs are leaking out.” Reus started pushing her towards the clothes rack.`}</p>
    <p>{`“I don’t want to, I like this dress,” she said defiantly as she resisted his pushing.`}</p>
    <p>{`“Yeah Reus, get off her, if she wants to wear this, let her!” Sylvain said, tugging on Reus’s very decorative `}<RemoteLink to="https://64.media.tumblr.com/bda41d51b09ea4e515c1bcfe36026275/tumblr_pylxzvbXat1ypwyq1o3_r2_1280.png" mdxType="RemoteLink">{`costume`}</RemoteLink>{` as if he was on her side, fighting for a noble cause.`}</p>
    <p>{`“Shut up, stop looking at my sister,” Reus shoved a hand onto Sylvain’s face, pushed him away and Sylvain tumbled to the floor with a loud crash since he was wearing quite a lot of armour.`}</p>
    <p>{`“....Uh….a little help here?” Sylvain called out, looking like an overturned turtle struggling to get back up. Their little fiasco was interrupted by a loud familiar groan.`}</p>
    <p>{`“I look like an idiot,” Felix said, his lips pressed together in annoyance.`}</p>
    <p>{`Reus, who was halfway helping Sylvain stand up, let go and went over to look at Felix.`}</p>
    <p>{`Even Byleth trotted over excitedly, “What’s this costume called?” She asked, prodding at the thick armour Felix was wearing.`}</p>
    <p>{`“`}<RemoteLink to="https://i2.wp.com/i.redd.it/3aeo2vwxnse31.jpg" mdxType="RemoteLink">{`Mortal Savant `}</RemoteLink>{`or something, it was this or some stupid mage costume. Ashe helped me choose,” Felix replied gruffly.`}</p>
    <p>{`“I think he made the right decision, you look really cool, Felix,” Byleth said. “The swords on your back here look really badass too.”`}</p>
    <p>{`“Hrmph,” Felix looked away...and looked a little pink, “At least these smell clean.”`}</p>
    <p>{`A little while later, Ashe came out looking super manly in his `}<RemoteLink to="https://static.wikia.nocookie.net/fireemblem/images/7/72/Ashe_sniper.jpg/revision/latest/scale-to-width-down/1000?cb=20191001031244" mdxType="RemoteLink">{`Sniper`}</RemoteLink>{` costume. Byleth was especially glad to see him so happy with his choice. “I couldn’t decide between this or a Bow Knight but ahh, I...felt this costume calling out to me you know?” Byleth nodded, she understood perfectly.`}</p>
    <p>{`“You look amazing, Byleth! This flatters y-your figure really well!” Ashe said and she blushed a little, squeaking out a small ‘Thanks’.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Where’s everyone else?” Sylvain asked after a while, somehow managing to clamber to his feet.`}</p>
    <p>{`“They were having problems finding something to fit Dedue since he’s so big,” Felix explained, trying to sit on the sofa but his accessories and armour were in the way.`}</p>
    <p>{`“...Sorry...you had to wait for us…” Dimitri’s voice caught their attention, when Byleth turned around to see...There it was again.`}</p>
    <p>{`Her heart immediately started thumping hard against her ribcage, as if she was running a marathon and she couldn’t look him in the eye, even though he was clearly staring at her.`}</p>
    <p>{`“Bro!” Sylvain called out, “Dude, you look like a Prince! Shit, is this the costume only Dimitri’s were allowed to wear?”`}</p>
    <p>{`Dimitri nodded, “I...think it’s a `}<RemoteLink to="https://static.wikia.nocookie.net/fireemblem/images/d/dd/B17-111HN_artwork.png/revision/latest/scale-to-width-down/596?cb=20210418162711" mdxType="RemoteLink">{`Lord`}</RemoteLink>{` outfit…” Dimitri said softly. Once Sylvain released him from his grip after being distracted by something, Dimitri quickly walked over, looking a little pale.`}</p>
    <p>{`“Are you okay?” She asked with a chuckle, “Not used to the attention, people or the clothes?”`}</p>
    <p>{`Dimitri looked even more flustered, “A-All of it…”`}</p>
    <p>{`“Can I ask why you have hickeys on your neck?” She blurted out, the image she saw coming to her when he came over and she was unable to help herself.`}</p>
    <p>{`He tilted his head, “...Hickey?”`}</p>
    <p>{`“Erm...love bites?” She said, trying to think of other explanations.`}</p>
    <p>{`He furrowed his brows at her, “I...don’t understand.”`}</p>
    <p>{`“Uh...the uh...marks on your neck, um, here.” She pointed at the spot where she saw them.`}</p>
    <p>{`He pressed down the high neck collar of his costume and tried to angle himself to see it, “It’s like...little red spots on your neck when your lover sucks on your skin,” she explained.`}</p>
    <p>{`He was in the midst of trying to see behind his head when he suddenly stopped and rolled up his sleeve, “Do you mean...these red spots?”`}</p>
    <p>{`She glanced at it and nodded. She felt rather taken aback since his lover was so...thorough and liked symmetry? Somehow she didn’t like the thought of him having a lover very much.`}</p>
    <p>{`“Oh...ummm...these are from...Rufus…” Dimitri whispered.`}</p>
    <p>{`Her eyes widened in shock, “Your...lover is a man? N-Not that I’m discriminating! I just..didn’t know you had a lover…”`}</p>
    <p>{`He narrowed his eyes at her, “Lover…? Umm, no Rufus is...my friend.”`}</p>
    <p>{`“Your friend gave you love bites?” She asked, even more shocked.`}</p>
    <p>{`Dimitri looked around before leaning down and whispered into her ear, “...No, Rufus is an...octopus.” Byleth nearly choked on her saliva. She looked back down at his sleeve before he quickly rolled it back down and it made so much sense now. She let out a sigh of relief.`}</p>
    <p>{`“He gets a little bit too excited sometimes. Rufus is a...giant octopus who is...red...hence his name…” Byleth nodded in reply. It made even more sense why the spots were so symmetrical. “I saw him yesterday when I went to the beach…”`}</p>
    <Ads mdxType="Ads" />
    <p>{`“...Does he mind...that you eat..octopuses…?” Byleth asked, she was very curious.`}</p>
    <p>{`Dimitri crossed his arms, “I’m...uh...not sure. He...doesn’t really talk much.” She chuckled in response.`}</p>
    <p>{`“Sorry, I had to ask,” she said, and he gave her a brief stare before breaking out in a chuckle as well.`}</p>
    <p>{`She stared at his bright countenance as he laughed, his eyes smiled and he looked genuinely happy.`}</p>
    <p>{`“You look very handsome, Dimitri,” Byleth said and smiled, her heart felt like she wanted to compliment him more, to tease him just to see him flustered and blushing and...she wanted it all for herself. She didn’t want anyone else enjoying the view she had in front of her. It was the first time she felt so...possessive. And she didn’t know why.`}</p>
    <p>{`She still didn’t know why she felt jealous when she initially assumed Dimitri had a lover. Well, he is very good looking and while he was still working on his rather terrifying demeanor, Dimitri was kind.`}</p>
    <p>{`Dimitri looked at her, his eyes intense and his lips slightly agape, “You look...beautiful.”`}</p>
    <p>{`Byleth shook her head, “Thanks but...this dress might look nicer on someone else.”`}</p>
    <p>{`“No, it looks perfect on you,” Dimitri said with the suavest voice she’d ever hear him say. He brushed his fingers on her skin at the opening of her dress at her side “You’re beautiful,” he said, this time with a firm voice then he eased into a smile.`}</p>
    <p>{`Byleth felt her breath hitch in her throat, all she wanted right now was for Dimitri to look at her like that all the time. With passion in his voice and a smile that made her legs weak. Her breathing was getting faster and she couldn’t bear to look at his eyes anymore, for the intensity was making her dizzy.`}</p>
    <p>{`It was as though time had stopped for them, as if her twin brother and classmates weren’t there in the waiting room, but it was as if there were only two of them in the world. She wasn’t entirely sure...but she felt maybe...just maybe...Dimitri felt the same way - the same heart pounding, stomach turning emotions she was feeling right now. Byleth couldn’t help but feel like their hearts were synchronized right at this moment.`}</p>
    <p>{`“Geez, look at the both of you looking so in love!” Sylvain said, breaking the spell between them. “And who knew Dimitri could get a girl alone in a corner like that! You sly dog.”`}</p>
    <p>{`Byleth and Dimitri quickly turned away from each other, she tucked a lock of hair behind her ear to ease the sudden awkwardness between them.`}</p>
    <p>{`“Stop it, Sylvain,” Ingrid said. She didn’t even notice Ingrid and Dedue had come into the room during her little moment with Dimitri. “Come on, Byleth, let's go take some pictures!”`}</p>
    <p>{`Throughout their entire photo-taking session, Sylvain’s words rang out in her head, `}<em parentName="p">{`‘In love’,`}</em>{` he said.`}</p>
    <p>{`Was...she….was she in love with Dimitri? And on cue, her heart skipped a beat at the thought. `}<em parentName="p">{`No way`}</em>{`. Stupid Sylvain.`}</p>
    <Ads mdxType="Ads" />
    <p>{`She looked at Dimitri in panic, who was awkwardly standing with Dedue as the photographer gave them instructions to not look so wooden. Dimitri caught her gaze and gave her a small smile.`}</p>
    <p>{`“Perfect,” the photographer said after capturing the shot, “That’s a great smile, kid.”`}</p>
    <p>{`Again, Byleth’s heart thumped in her chest when Dimitri smiled at her.`}</p>
    <p><em parentName="p">{`Oh no.`}</em></p>
    <p><em parentName="p">{`Oh no, no, no.`}</em>{` She said to herself.`}</p>
    <p>{`Like a videotape on rewind, all the moments they shared together flashed in her brain. Her mind was particularly focused on all the mermaid kisses they had and her stomach spinned. The whole ‘butterflies in your stomach’ idiom suddenly made sense to her.`}</p>
    <p>{`Is...this what love felt like?`}</p>
    <p>{`Byleth didn’t really focus the rest of the time they were in the theme park. All she wanted to do was just look at him, be near him,`}<em parentName="p">{` talk `}</em>{`to him. Imagine her, Byleth Eisner, the girl who got awarded ‘the person most like to take a vow of silence’ in secondary school, `}<em parentName="p">{`wanting to talk`}</em>{` to someone.`}</p>
    <p>{`While she was quietly excited the days leading up to this excursion, right now she couldn’t seem to bring herself to look at the accurately reconstructed buildings, or pay attention to the amazing War Parade they managed to get good spots for. The War Parade was a reenactment in full costume of when a King, Queen, or Emperor would come back from a successful battle and bring back all their spoils of war.`}</p>
    <p>{`She wasn’t even bothered about feeling insecure in her costume anymore since Dimitri complimented her. Byleth tried so hard to observe the magnificent and opulent parade unfolding in front of her, she inadvertently glared at all the reenactment actors.`}</p>
    <p>{`“By, why are you glaring at people?” Reus nudged her, poking at the wrinkled spot in between her eyebrows.`}</p>
    <p>{`“Ow- I’m not, I’m...studying their costumes,” she said, swatting his hand away.`}</p>
    <p>{`“Even Felix is enjoying himself, look,” Reus gestured at Felix, the corner of his lips raised ever so slightly.`}</p>
    <p>{`They walked around the cultural part of the park since they weren’t allowed to go on the ride in their expensive dress up costumes, and thankfully they weren’t the only ones looking like a time machine spat them out in the wrong era.`}</p>
    <p>{`Walking around the park took up surprisingly a lot of time and before they knew it, the timers given by the transformation studio beeped. They quickly shuffled back as fast as they could back to the studio before the grace period was up and they were penalised.`}</p>
    <p>{`Ashe thanked them profusely for the amazing experience as they inspected the costumes for damage, but it was thanks to Sylvain’s charm and incessant flirting the staff gave them a discount coupon for lunch.`}</p>
    <p>{`“...You guys go on ahead, I’ll meet you for lunch,” Felix suddenly said as they walked to the rides.`}</p>
    <p>{`“What? Why? Do you need the bathroom?” Ingrid asked, “We can always wait for you-”`}</p>
    <p>{`“-It’s none of your business. I just want to be alone,” he spat.`}</p>
    <p>{`It wasn’t really strange Felix wanted time alone but he looked like he was desperately wanting to escape to somewhere. It wasn’t until Byleth caught a glimpse of a pamphlet sticking out of his back pocket that said ‘the Gronder Field Swordsman Experience’ that she understood why he wanted to be alone.`}</p>
    <p>{`“Okay, see you,” Byleth said and waved at Felix who gruffly walked off in the opposite direction.`}</p>
    <p>{`“What’s up with him? Too much human interaction for the Grinch?” Sylvain snorted. Byleth shook her head and pointed at the pamphlet sticking out of his back pocket.`}</p>
    <p>{`“He’s going for the swordsman experience,” she explained as the group squinted at Felix’s butt and they all quietly nodded, understanding. Of course, Sylvain vowed to tease the heck out of Felix later as if he had a death wish.`}</p>
    <p>{`Sylvain was someone Byleth and Reus met at primary school, longer than Felix and Ingrid, but even then Sylvain was already flirting with everyone - the lunch lady was particularly fond of the cheeky little boy who called her pretty. He often had extra helpings of everything and became rather tubby as a child. She actually thought Sylvain had grown up to be very good looking; a fact Sylvain agreed with very strongly as well it seems.`}</p>
    <p>{`“Okay, so how about we hit the rides, get in line for the nastiest rollercoaster they have, and then head out for lunch, yeah? Otherwise I cannot guarantee the contents of my stomach will remain in my stomach-” Ashe made a face at Sylvain, “-and then do some...calm activities like...uhmmm…”`}</p>
    <p>{`“Horse riding!” Ingrid chipped in, “They have a short countryside horse riding experience! The attendant told me when I was taking pictures just now.”`}</p>
    <p>{`“That’s...your idea of a calm activity?” Sylvain said, “Your lunch will be trotting and bouncing around with the horse.” Ingrid merely rolled her eyes and argued with Sylvain.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Byleth, on the other hand, was absolutely sure no rollercoaster could make her any more nervous than being next to Dimitri and having his hand accidentally brush hers as they walked in silence.`}</p>
    <Divider mdxType="Divider" />
    <p>{`A known and infamous loner his whole life, Dimitri almost couldn’t
contain his excitement at the thought of going to a theme park for the first
time in his life `}<em parentName="p">{`and`}</em>{` with friends. He was so happy he nearly let himself smile
when they invited him. Even Dedue was excited, evident by the two 6-pack
electrolyte carriers in his enormous backpack(the carrier was biodegradable of
course.)`}</p>
    <p>{`Dimitri was more than pleased when Ashe took the initiative to invite him to do the whole dress up thing (even though he had no idea what it was, but it sounded fun). Since Dimitri was a ‘prince’ back home, nobody really...tried to include him in anything. It was a lonely position to be in.`}</p>
    <p>{`From the reflection of the bus window, Dimitri noticed he could faintly watch what Byleth was doing. He immediately turned his head when he saw her staring at him but he was too late to catch her gaze. It seems she and Reus were talking about something rather serious, or maybe just normal topics since they always had the same expression on.`}</p>
    <p>{`Still, the ‘view’ the bus window offered was soothing to his eyes and he subconsciously thoroughly enjoyed looking at ‘it’. Especially when he heard laughter coming from ‘that’ direction, Dimitri would try and catch a glimpse of her lit up face - a sight so rare this was probably the 3rd or 4th time he’d seen her laugh.`}</p>
    <p>{`Dimitri felt a small pang of envy that he couldn’t make her laugh like her brother did. He digressed, Byleth was a new friend and of course he didn’t have the same level of bonding as her twin brother.`}</p>
    <p>{`Dimitri’s excitement for the theme turned into pure horror as he saw the throngs of people at the entrance. It was even more densely packed than the beach on a bright sunny day and Dimitri felt almost paralyzing levels of fear. University wasn’t so bad either, even though he was highly nervous and afraid...but this...this was...insane.`}</p>
    <p>{`He reluctantly got out of his seat and inched out of the bus with everyone else, sucking in deep breaths to calm himself. The worst thing a deep sea creature would do was eat you but humans...he shuddered. Dimitri was scared.`}</p>
    <p>{`Instinctively, Dimitri turned to Byleth as if looking at her gave him comfort. Due to his currently timid, shy and slightly observant nature, Dimitri was getting better at reading the subtle changes in Byleth’s expression. He likened it to being able to tell what kind of mood Felix was in based on the different types of scowls he wore throughout the day.`}</p>
    <p>{`As he squinted at her, trying to gauge her expression, Byleth shot him an ‘Ok?’ hand sign, something he understood since hand signs were something merfolk often used when travelling in the deep ocean not wanting to talk or make noise in case there were predators around.`}</p>
    <p>{`He nodded at her, feeling his anxiety calm down a little as he took a couple of deep breaths to regain his composure.`}</p>
    <p>{`Unfortunately, his calm demeanor flew out the door the second he laid eyes on Byleth when he entered the waiting room after squeezing into a Lord’s outfit made for a much smaller Dimitri.`}</p>
    <p>{`The little bits of milky white flesh that the dress exposed sent Dimitri’s brain and heart into a strange mix of overdrive and...heat. Dimitri may have been an underwater hermit at some point in his life, but even he knew the unusual passion he was feeling for Byleth right now wasn’t something regular ‘friends’ felt for each other.`}</p>
    <Ads mdxType="Ads" />
    <p>{`He certainly didn’t want to caress Dedue’s face lovingly while staring into his eyes.`}</p>
    <p>{`Dimitri scared himself a little, he had `}<em parentName="p">{`never, ever`}</em>{`, felt such strong emotions before. At the same time, he was deathly shy and couldn’t make eye contact with her but he also very strongly wanted to touch her.`}</p>
    <p>{`Before Sylvain could tease him any further about his butt or something (he wasn’t listening), he immediately made a beeline for Byleth and braced himself for the avalanche of feelings he was about to feel.`}</p>
    <p>{`Dimitri was feeling a little pale after that sudden rush of adrenaline from just `}<em parentName="p">{`looking`}</em>{` at Byleth, standing next to her now was...was heavenly. Of course, he took great care not to exhibit said emotions, since he didn’t know what would happen if he gave into it.`}</p>
    <p>{`They were having a conversation about Rufus, his pet octopus and about the marks left behind by Rufus’s suckers. It had been a while since he saw Rufus, and Dimitri felt a little guilty since Rufus’s species of Giant Octopus’ lived for only 3-5 years.`}</p>
    <p>{`Actually, truth be told, Dimitri was having a hard time trying to hold a conversation after Byleth said he looked handsome. In fact, he felt like he was about to faint from the sheer happiness her words gave him.`}</p>
    <p>{`But then as soon as he regained consciousness from his little stupor, he blurted out words he had never said to any human before, “You look...beautiful.” Rather smoothly at that.`}</p>
    <p>{`Byleth coyly tucked a lock of hair behind her ear, mumbling about not looking as pretty or something but he stood his ground and repeated the words to her, and she rewarded him with a smile that hypnotised him, well almost.`}</p>
    <p>{`Unfortunately, the human equivalent of a fly came and interrupted them. Dimitri felt a sense of protectiveness over her whenever Sylvain was around. As said redhead didn't have any issue ejaculating whatever it was he was feeling, while Dimitri struggled to get the words out in a complete sentence.`}</p>
    <p>{`But even when Byleth had changed back to whatever she was wearing before, Dimitri was still completely mesmerised by her, again feeling envious that she was smiling so easily with Reus and even Ingrid for that matter.`}</p>
    <p>{`Dimitri took out a little notebook he had recently purchased to take notes on the people around him and to better his social skills, he officialized the notebook by scribbling down what he felt about Byleth and what he knew about her personality as they were waiting for the others to change.`}</p>
    <p>{`If he was being honest with himself...Dimitri didn’t know when he started feeling like this. It just...happened. Even back when his father asked if Byleth was his future mate, he had vehemently denied it out deep down he knew she was special to him. But right now, everything happened so fast, like it took a minute for Dimitri to feel so...restless and troubled and...hot. His pants felt tight at one point.`}</p>
    <Ads mdxType="Ads" />
    <p>{`He sighed and hoped the Goddess would give him guidance.`}</p>
    <p>{`Once the last person had been transformed back into a normally dressed human, they, except Felix, all headed for the infamous ‘Rollercoasters’ Dimitri had heard so much about.`}</p>
    <p>{`He had seen it from the bus but up close…”Wow…” He managed to verbalise. It was a frighteningly tall structure with a long drop at its highest and a few large loops. He could already hear the screaming from where he stood.`}</p>
    <p>{`“This is the tallest Rollercoaster in the park!” Sylvain exclaimed, “Byleth, do you want to sit next to me? You can hold-”`}</p>
    <p>{`“No,” Reus and Byleth said at the same time then the male Eisner shoved Sylvain aside. Dimitri let out the breath he was holding, in case Byleth said yes. Which...he didn’t know why he would be nervous about it.`}</p>
    <p>{`He, along with his confusing thoughts and frown was swept in along with the rest of them into the long winding line for the roller coaster before he could say anything else.`}</p>
    <p>{`Dimitri felt a tug on his jacket and he looked down and straight into beautiful large dark blue eyes, “Will you be alright?” The owner of the voice whispered.`}</p>
    <p>{`He narrowed his eyes on her, confused, “Why would I not be alright?”`}</p>
    <p>{`“Have you been on a rollercoaster?” Byleth asked. He shook his head.`}</p>
    <p>{`“Should..I..be..um, concerned?”`}</p>
    <p>{`She shrugged, “You might like it.”`}</p>
    <p>{`“What if I don’t?” He asked, genuinely curious but it came out like he was teasing her.`}</p>
    <p>{`She looked as if she was suppressing a laugh but she obliged nonetheless, “You don’t have a choice, you’ll be strapped and possibly vomit on everyone next, behind and below you.”`}</p>
    <p>{`Dimitri was taken aback, “Is it...that...dangerous?”`}</p>
    <p>{`“Er...not...not really? Maybe? Like I said, you might like it.”`}</p>
    <p>{`Dimitri thought it might not be so bad but he was reminded of his apprehensiveness when he felt the ground rumble slightly when one of the roller Coaster trains zipped by them, leaving behind a loud trail of horrified screams from the people it carried.`}</p>
    <p>{`It wasn’t long before it was their turn but the order in which they lined up had been messed up, while Dimitri and Dedue planned to sit next to each other on the narrow 2 seater roller coaster train, somehow in the chaos Dimitri had been separated from Dedue and was shoved into the nearest seat.`}</p>
    <p>{`Dimitri was startled when the restraints automatically lowered itself, looking at the person next to him in panic. To his horror, embarrassment and excitement, it was Byleth.`}</p>
    <p>{`Byleth had a calm expression on her face, as though used to being on rides like this. In front of them were Reus and Ashe, Dedue and Ingrid behind them and Sylvain...was somewhere.`}</p>
    <p>{`“Phew,” he heard her let out a sigh.`}</p>
    <Ads mdxType="Ads" />
    <p>{`The vehicle, from which their legs were suspended from, jerked to life when the attendant pressed a button and a loud horn sounded.`}</p>
    <p>{`The roller coaster was a heavy and noisy contraption, the wheels and mechanisms squeaked and rumbled as they slowly rolled up to a peak, the peak he saw just now that had the longest drop he had ever seen.`}</p>
    <p>{`His body had a sudden rush of adrenaline, like the time when he was being chased by a shark. He took one last look at Byleth who was gripping the handles on the restraints very tightly, then Dimitri faced forward and braced himself as the train tipped over the edge.`}</p>
    <Divider mdxType="Divider" />
    <p>{`“I think..I’m...gonna…puk-” Sylvain kept retching as soon as they got off the ride,
he quickly slapped a hand over his mouth and ran to the bathroom that was conveniently
placed next to such a thrilling ride.`}</p>
    <p>{`“Oh n-no, I-I’ll go and check on him,” Ashe said after being handed a bottle of electrolytes by Dedue to give to poor Sylvain and ran after the vomiting redhead.`}</p>
    <p>{`Dimitri had to admit his legs felt weak when the ride came to a grinding halt. In fact he was still a little high from the adrenaline and let out a chuckle, “That was...amazing.”`}</p>
    <p>{`“I never thought I’d see you laugh in my lifetime,” Reus said flatly, “Wait- don’t tell me that was your first time?”`}</p>
    <p>{`Still in a small fit of laughter, Dimitri nodded. “It felt exactly like riding in a whirlpool!” He exclaimed, his voice a pitch higher than normal, recalling the fun times he had in the ocean’s own version of a roller coaster.`}</p>
    <p>{`“A what?” Ingrid said, while Reus raised an eyebrow at him.`}</p>
    <p>{`“You...you’ve been in a whirlpool?” Ingrid asked.`}</p>
    <p>{`Time stood still as panic washed over him and the adrenaline quickly faded from his being, “Oh-no...uh...I meant-”`}</p>
    <p>{`“H-He meant a ride called the whirlpool, didn’t you, Dimitri?” Byleth suddenly said and he nodded furiously, “You know those water park ones with water themed...rides.”`}</p>
    <p>{`Reus and Ingrid both nodded.`}</p>
    <p>{`“So it’s not really your first time is it?” Reus continued.`}</p>
    <p>{`Dimitri was unable to answer and just mumbled, “Oh...er...no...I guess. But this was...tremendously...fun.”`}</p>
    <p>{`“That’s great buddy, come on, let's go check on Sylvain. I want to go laugh at him,” Reus slapped his back and urged him to follow. Dimitri could do with a tinkle anyway and Dedue, as usual, faithfully and quietly followed behind him.`}</p>
    <p>{`They finally met up with Felix for lunch after they went on a couple of rides, like the ‘Demonic Beast Bumper Car’, which made Dimitri realised he had no idea how to operate a motor vehicle at all, causing him to lurch forward, then left and right, unintentionally crashing into other peoples cars. He apologized profusely but Ingrid commented he was great at it, confusing him.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Sylvain felt much better and colour returned to his face, along with his skirt-chasing ways when a pretty waitress came up and took their order in the Medieval Times Themed Restaurant.`}</p>
    <p>{`Dimitri was ravenous and even craved cooked red meat from the excitement of the day, who knew being happy made a person so hungry?`}</p>
    <p>{`Feeling the happiest he’s been since...since...forever, Dimitri decided to foot the bill for lunch to thank his new friends for a wonderful time.`}</p>
    <p>{`“Oh please don’t Dimitri,” Ingrid protested, “I ate way more than I should, it’s not right.”`}</p>
    <p>{`“Me too,” Byleth chipped in, she had gobbled down a huge piece of steak with sides and even shared a plate of beast meat teppanyaki with Reus.`}</p>
    <p>{`“I insist,” Dimitri said stubbornly, refusing to give up the bill. But then Dimitri suddenly felt unsure if this was a...social faux pas or if he was offending them by doing this. For a few seconds they stared at each other, then to his surprise, thanked him profusely.`}</p>
    <p>{`“Anything to make the beast happy,” Felix quipped. Felix had come back from his swordsman experience seemingly more happy than before, Dimitri even caught Felix relaxing his frown.`}</p>
    <p>{`The country-side horse experience was something Dimitri was also keen on trying, having liked horses as a child during the short time he spent on land with his human mother.`}</p>
    <p>{`Unfortunately, there was only one horse available for said excursion and he gladly gave it up to Ingrid who was a much more enthusiastic horse fan than him.`}</p>
    <p>{`Instead, the guide pointed them in the direction of a horse-drawn carriage experience nearby, a short 20 minute tour around the large estate.`}</p>
    <p>{`“No thanks, that looks way more bumpy than I’d like, I’ll..sit here,” Reus said as he sat down on a nearby bench. “You go ahead, By. Live your childhood Princess Fairy Tale dreams or something,” he snorted.`}</p>
    <p>{`Byleth gave him a quick slap on the arm, “I will. See you,” she said.`}</p>
    <p>{`“Can...I...join you?” Dimitri asked. And Byleth nodded at him, gesturing at him to follow her. Ashe had decided to visit the museum which housed a gift shop as well, then Reus decided instead of wasting away on a bench, he too was going to the museum with Ashe.`}</p>
    <p>{`Felix, who had already seen the museum, already sat on a bench for a good amount of time, who didn’t care for gift shops, and had pretty much walked the whole park during the time they split up, decided to join Byleth, Dimitri and Dedue on the carriage ride.`}</p>
    <Ads mdxType="Ads" />
    <p>{`But as expected...the four of them weren’t the most talkative bunch ever. Or maybe they were focused on the automatic recording of a tour guide explaining what they were looking at, or the significance of some rock. It was also a very bumpy ride, Dimitri could feel every single uneven cobblestone the wheels of the carriage rolled over, it made it hard to sip on his gatorade without spilling.`}</p>
    <p>{`“How does your family earn money, Dimitri?” Byleth suddenly asked when there was a lull in the recording.`}</p>
    <p>{`He tilted his head, “Um...Father runs a small, private lifeguard company.” Byleth nodded.`}</p>
    <p>{`“His family is also extremely rich from their investments from previous generations,” Felix added, “His is descendant from actual royalty anyway, plus mermaids don’t really need to ‘buy’ food and the like.”`}</p>
    <p>{`“I see...so even...mermaids invest in stuff,” she chuckled, but her expression instantly turned somber.`}</p>
    <p>{`“Can I ask you guys a question?” Dimitri nodded while Felix let out a grunt.`}</p>
    <p>{`“It’s about...the whole mermaid lore thing again, I...I had some time to think about it and I would like to know your opinions,” she said, speaking over the recorded tour guide voice.`}</p>
    <p>{`“Ugh, this again?” Felix groaned.`}</p>
    <p>{`“Sorry...I’m very curious because, Felix, you mentioned that your ancestors rewrote history right? To prevent the discovery of mermaids, your ancestors probably destroyed the original recordings and rewrote them. I mean...in the end...is...everything we’re doing meaningless? What we report in class, even the assignment we just handed in…These research we do...Why should we bother? Who’s to say that your descendant Felix, won't do the same one day? Maybe 300 years later?” Byleth sighed and was seemingly collecting her thoughts, “I feel...cheated.”`}</p>
    <p>{`The air stood still despite the noisy carriage they were in, Dimitri could sympathise somewhat with Byleth, having been told one thing your entire life that was the truth, only for you to discover it was a lie the entire time, it can mess with a person’s thinking.`}</p>
    <p>{`Felix merely kept silent, also seemingly gathering his thoughts, since he had his ‘thinking’ frown on.`}</p>
    <p>{`“Byleth,” Dimitri called out and she looked at him, “I...I don’t know what the truth is either. And...to be fair...these all happened over a thousand years ago...erm. I don’t know much about research...but...if you believe what you see and feel is the truth, and as long as nobody...uh...gets hurt...shouldn’t it alright to believe in it?” He paused.`}</p>
    <p>{`“The fact remains that...I...Dedue, my father, are all mermaids. How and why it happened...truthfully...it’s...more like a bedtime story to me. So...if you love researching and..learning about new truths...then...please don’t give up. Even if your work might get erased or not in the future, all that matters is right now - what you feel and how you feel about it. Umm...how should I say this? If you enjoy...it’s not a waste of time?”`}</p>
    <p>{`The seconds passed by and Dimitri could only feel embarrassed that he had spoken so much and they were actually listening to him. Him, Dimitri, the shy and passive...man. And no one interrupted him. He was finally starting to feel the fruits of persevering at his human life experience.`}</p>
    <p>{`“You’re...you’re right,” Byleth spoke up. “I...I was so caught up with finding out what was the real truth and what is not that...I forgot what mattered to me - enjoying the learning. Thank you, Dimitri.” And there it was again - her smile. She was smiling for him. All because he dared to speak and cheer her up.`}</p>
    <p>{`“Pfft,” Felix snorted, “Yeah. Sure. And besides, it was a strategic move to confuse people anyway; the more people argued about what is real and what is fake, the more they forget what they were supposed to be doing - finding actual evidence instead of trying to prove someone else wrong. So Byleth, you fell for it.”`}</p>
    <Ads mdxType="Ads" />
    <p>{`Byleth chuckled, “I did, didn’t I? Predictable.”`}</p>
    <p>{`“History is supposedly an account of what happened, but it’s rarely the full story, if ever,” Dedue suddenly added.`}</p>
    <p>{`“That is amazingly profound, Dedue,” Byleth said and thanked him.`}</p>
    <p>{`“I cannot claim credit for that, I saw it in a tv show on Netflix,” Dedue said with a straight face.`}</p>
    <p>{`Dimitri wondered which tv show Dedue was referring to as Byleth burst out into a chuckle again, “I’m a history student but I’m not setting a very good example, there is still room for improvement.”`}</p>
    <p>{`Nevertheless, Dimitri admired her tenacity though, regardless of whether she fell for a trick or not she took it in her stride to improve. Dimitri pulled his little notebook again and scribbled down new things he wanted to take not and remember;`}</p>
    <Image alt="dimitri scribbles" name="dimitri-scribbles.jpg" caption="Please save image or right click -> open in new tab to view the scribbles if the text is too small on your device." mdxType="Image" />
    <AuthorNote mdxType="AuthorNote">
  <p>
    Sorry for the long chapter!! I got the inspiration for the whole theme park
    idea from <RemoteLink to="http://edowonderland.net/en/" mdxType="RemoteLink">here!</RemoteLink>
  </p>
  <p>
    Also I apologize if I got roller coasters wrong...I...have not been to a
    theme park in almost 3 years since the coronavirus lockdown started ._. Also
    the TV show Dedue saw the quote from is Trollhunters on Netflix :D
  </p>
    </AuthorNote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      