import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { importOneImage } from '../../utils';
import Seo from '../../components/Seo';
import keywords from '../../utils/keywords';
import Banner from '../../components/MDXFanfiction/Index/Banner';
import Content from '../../components/MDXFanfiction/Index/Content';

const MDXFanfictionTemplate = ({ pageContext, location, data }) => {
  const {
    banner,
    genre,
    pairings,
    published,
    rating,
    status,
    summary,
    tags,
    title,
  } = pageContext;

  return (
    <>
      <Seo
        title={`${title} | Geeknabe - ACG Blog`}
        location={location}
        keywords={keywords}
        image={`https://geeknabe.com${importOneImage(banner)}`}
      />
      <Banner
        img={banner}
        title={title}
        tags={["<a href='/fanfiction/'>fanfiction</a>", ...tags]}
        published={published}
      />
      <Content
        summary={summary}
        genres={genre}
        pairings={pairings}
        rating={rating}
        status={status}
        chapters={data.allMdx.edges
          .sort((curr, prev) => {
            const {
              node: { frontmatter: currFront },
            } = curr;
            const {
              node: { frontmatter: prevFront },
            } = prev;
            const parsedPrevDate = new Date(prevFront.published).getTime();
            const parsedCurrDate = new Date(currFront.published).getTime();

            if (parsedCurrDate < parsedPrevDate) {
              return -1;
            }

            if (parsedCurrDate > parsedPrevDate) {
              return 1;
            }

            return 0;
          })
          .map(({ node }) => ({
            wordCount: node.wordCount.words,
            slug: node.slug,
            title: node.frontmatter.title,
            published: node.frontmatter.published,
          }))}
      />
    </>
  );
};

MDXFanfictionTemplate.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
  pageContext: PropTypes.shape({
    banner: PropTypes.string,
    genre: PropTypes.arrayOf(PropTypes.string),
    media: PropTypes.string,
    pairings: PropTypes.arrayOf(PropTypes.string),
    published: PropTypes.string,
    rating: PropTypes.string,
    status: PropTypes.string,
    summary: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string,
  }).isRequired,
  location: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
};

export default MDXFanfictionTemplate;

export const query = graphql`
  query ($querySlug: String) {
    allMdx(
      filter: { fileAbsolutePath: { regex: $querySlug } }
      sort: { order: ASC, fields: fileAbsolutePath }
    ) {
      edges {
        node {
          wordCount {
            words
          }
          slug
          frontmatter {
            title
            published
          }
        }
      }
    }
  }
`;
