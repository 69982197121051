import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Image = makeShortcode("Image");
const Ads = makeShortcode("Ads");
const RemoteLink = makeShortcode("RemoteLink");
const Blockquote = makeShortcode("Blockquote");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`To get the best photos for this blog post, I whipped out my Canon G16 Powershot and took a few pictures. To my dismay, my phone takes better photo than my Canon camera. It's amazing how advanced these smartphones are nowadays!`}</p>
    <Image name="taito-hatsune-miku-happy-cat-1.jpg" caption="Her fringe edges are really rough. I mean, that's to be expected for the price. But I'm happy with her face. Cute!" alt="Taito Hatsune Miku Birthday2021 Amp figure ~Happy Cat ver~ screenshot 1" mdxType="Image" />
    <Ads mdxType="Ads" />
    <p>{`Ahem, back to the topic... Miku is one of my first figurines and I hope is my last. They say once you get hooked, you're never coming back out. Now, you might have noticed that I said "one of my first figurines"... That's because as of this time of writing, I pre-ordered 3 more figurines - 1 from Taito, and 2 from Pop Up Parade. These brand names are lost on me but based on my experience after 3 days of continuous browsing for figurines... Here's what I noticed.`}</p>
    <RemoteLink to="https://www.taito.co.jp/en/" mdxType="RemoteLink">Taito</RemoteLink> are much, much cheaper.
They are usually used as prize figurines for UFO catcher machines in arcades. <RemoteLink to="https://special.goodsmile.info/popupparade/en/" mdxType="RemoteLink">
  Pop Up Parade
    </RemoteLink> on the other hand, is a series by Good Smile Company. They have more
character choices than Taito's, and I find their finish up to standards (At least,
from the photos I've seen). Though it does come with a heftier price tag, it is still
affordable.
    <Blockquote mdxType="Blockquote">
  Then what about Nendoroids? Why are they so expensive despite being much
  smaller? ?_?
    </Blockquote>
    <Image name="taito-hatsune-miku-happy-cat-2.jpg" caption="If you zoom in on her skirt, you'll see the paints aren't too pretty there. In person, the black paint is not impressive. Doable, but nothing fancy." alt="Taito Hatsune Miku Birthday2021 Amp figure ~Happy Cat ver~ screenshot 2" mdxType="Image" />
    <Ads mdxType="Ads" />
    <p>{`So how's this Hatsune Miku figurine from Taito? Not too shabby for a price tag of RM90/USD21. There's A TON of Hatsune Miku figurines out there and wow, each one of them is super cute! I find this `}<em parentName="p">{`Happy Cat ver`}</em>{` absolutely `}<strong parentName="p">{`adorable`}</strong>{`! The refreshing smile with a half-jumping pose! The pinkish accessories with a cat paw! `}<strong parentName="p">{`Hnng.`}</strong></p>
    <Blockquote mdxType="Blockquote">Mgrgr, cute!</Blockquote>
    <p>{`With that price tag, I wasn't expecting beautiful finish or glossy surface, but I was left pleasantly surprised at the size of the figurine. A quick measurement tells me the height (including the base) is around 23 cm! It's big, cute and wonderful to look at <3`}</p>
    <Image name="taito-hatsune-miku-happy-cat-4.jpg" caption="The back of her head ): The gradients on her twintails and sleeves are pretty nice, in all honesty." alt="Taito Hatsune Miku Birthday2021 Amp figure ~Happy Cat ver~ screenshot 4" mdxType="Image" />
    <p>{`I'm not too sure if the paint coming off is from being kept for a long time, or it was released as it is with scuffed paint. Regardless, I'm sure professionals who know their way around painting figurines can fix it themselves. I, for one, am just happy she's cute and cheap.`}</p>
    <p>{`The figurine is supported by the cat paw stand and a removable leg support. I'm not sure how effective the extra support is because it's not exactly tight-fitting. I have a feeling the figurine can't hold up for long if they gave it a removable support. Nevertheless, like I say, bang for buck! Even if the figurine can't stay up, I wouldn't feel that heartbroken knowing it's a decent, cheap figurine. But here's hoping the figurine stays up forever (T_T)`}</p>
    <Ads mdxType="Ads" />
    <Image name="taito-hatsune-miku-happy-cat-3.jpg" caption="The only thing supporting the figurine is the attachment to the cat paw stand. Then there's this extra support you can remove that's holding up her leg." alt="Taito Hatsune Miku Birthday2021 Amp figure ~Happy Cat ver~ screenshot 3" mdxType="Image" />
    <h2>{`All in all`}</h2>
    <p>{`I'm so glad there's so many local shops stocking up on Taito figurines. If you're itching to fill that figurine need for your favourite anime character, Taito ones are not bad at all! If it makes you happy owning a Hatsune Miku figurine, go for it.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      