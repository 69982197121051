import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXFanfiction/Chapter/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Divider = makeShortcode("Divider");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`“`}<em parentName="p">{`Reus!”`}</em>{` Dimitri cried out when Reus collapsed on his chest, “What’s wrong? Reus—wake up!”`}</p>
    <p>{`Noticing Reus’ pallid and clammy face, Dimitri shook the boy’s limp body, calling out his name but no response came. Quickly he pressed a few fingers against Reus’ neck. Thankfully there was still a pulse.`}</p>
    <p>{`Dimitri then heaved himself off the ground, cradling an unconscious Reus securely as he did so.`}</p>
    <p>{`Ashe jogged up to him panting, then frowned. “Oh man, not Reus too.” Without prompting, he helped Dimitri drape Reus onto his back.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Dimitri wasn’t surprised there were others. It was an especially hot day. He felt bad Reus was soaking up the sweat on his drenched back. “I’ll take him to the infirmary,” Dimitri said. But Ashe shook his head.`}</p>
    <p>{`“I-I have some bad news. The nurse took a day off so the infirmary is closed. But I’ve already informed Mr. Alois and someone else will be coming in. Please take Reus to the dorm, I’ll call you when the nurse arrives.”`}</p>
    <p>{`“Well done, Ashe. Has everyone else been taken to their dorms? I can assist once I make sure Reus is okay.”`}</p>
    <p>{`“O-oh yes! I mean no. I mean” —Ashe shook his head in frustration— “Sylvain and the others helped. We’re okay now. Coach left about 10 minutes ago, so I think we’re dismissed. Please, go now. I shouldn’t keep you!”`}</p>
    <p>{`Dimitri thanked Ashe and quickly made his way back to their dorm.`}</p>
    <p>{`On the field earlier, Dimitri noticed Reus acting… odd. He realised this when Reus’ hold around his throat loosened, when the oxygen returned to his brain. The fight was not what he expected; not an ounce of murderous intent oozed from Reus like that night, when he got pummeled on the sidewalk. In fact, the weight distribution felt strangely lighter even.`}</p>
    <p>{`Dimitri’s strength was monstrous. He knew that. He was also aware that Reus wasn’t eating his usual mounds of food. But could people lose `}<em parentName="p">{`so much`}</em>{` weight in a few days? Well it was possible technically , but what reason did Reus need to? He glanced back. Reus’ mop of mint green hair rested on his shoulder, his faint breathing echoing in his ear. Dimitri couldn't tell if his heart was racing due to adrenaline, or `}<em parentName="p">{`dare`}</em>{` he say it, love...?`}</p>
    <p>{`He mentally snorted. This man literally had his hands around Dimitri’s throat not 15 minutes ago. And yet he was still thinking of absurd things. Unless he `}<em parentName="p">{`liked`}</em>{` that kind of thing?`}</p>
    <p>{`“Get a hold of yourself!” Dimitri shuddered. The body on his back groaned, prompting him to pick up the pace.`}</p>
    <p>{`Dimitri somehow managed to open and close the door without dropping anything, or in this case any`}<em parentName="p">{`one`}</em>{` from his back, and gingerly laid Reus on his bed.`}</p>
    <p>{`Dimitri pushed aside the locks of hair that clung to Reus’s pale, sweaty face, subconsciously searching his face for any features similar to Byleth’s. Remarkably, despite being fraternal twins, the similarities were endless. The contour of their noses, eyebrows, and even...lips. Dimitri shook his head, noticing he was dangerously close to Reus’s face. If any of the guys walked in, they’d definitely get the wrong idea.`}</p>
    <p>{`He pushed himself off the bed, stretched and peeled off his sweat drenched sports shirt as he walked back to his bed. A wheeze came from Reus’s direction just as he was about to dry himself with a towel.`}</p>
    <Ads mdxType="Ads" />
    <p><em parentName="p">{`Still unconscious`}</em>{`, he thought and sighed worriedly. It would probably take at least half an hour for the nurse to arrive. Dimitri felt a bead of sweat roll down his chest when he realized Reus was probably suffocating from the heat. He was irritated that he selfishly thought to only remove `}<em parentName="p">{`his`}</em>{` shirt but not Reus'. Suddenly remembering Reus `}<em parentName="p">{`did`}</em>{` wear a shirt underneath that massive hoodie he was wearing, he moved towards the bed.`}</p>
    <p>{`Dimitri stood staring at the sleeping Reus for a good minute, a hand rubbing his chin as he formulated a plan on how he’d go about removing an unconscious person’s clothes without injuring them too much. Ignoring the fact that removing another male’s clothes was probably something other males didn’t do, but the situation called for it. Reus would understand.`}</p>
    <p>{`Hopefully.`}</p>
    <p>{`He placed a hand underneath Reus’ neck and another on his chest, propping him up as he slid a leg around Reus and sat behind him. Reus was absolutely small in his arms and in between his legs. He blushed stupidly, and for what, he didn’t know. Probably because it was such an intimate position.`}</p>
    <p>{`Another wheeze erupted from Reus’s chest, yanking Dimitri back to reality.`}</p>
    <p>{`Slowly, Dimitri pulled one arm out and then another. When Reus’ limbs were free he steadily pulled the whole hoodie over Reus’s head, holding it so it didn’t flop around. Dimitri peered down at Reus whose breaths were still shallow and ragged. Dimitri sat thinking again. This should’ve made Reus more comfortable, but it seemed that nothing had changed.`}</p>
    <p>{`Dimitri slid off the bed and once again, gently rested Reus’ head on the pillow. He knelt down beside the green-haired male, frowning. Where was the damned nurse? Ashe said he’d come as soon as possible!`}</p>
    <p>{`Reus let out another hoarse wheeze, and in that moment Dimitri decided to remove the shirt as well, hoping it would help with the breathing. The black cotton shirt Reus wore was so drenched with sweat Dimitri could squeeze a good amount of liquid out of it.`}</p>
    <p><em parentName="p">{`Huh, Reus has amazing pectoral muscles for someone so slender.`}</em>{` The thought ran through Dimitri’s mind as he observed the slight mounds on Reus’ chest.`}</p>
    <p>{`Again, Dimitri positioned himself behind Reus, this time the movements of removing Reus’s shirt were fluid now, but `}<em parentName="p">{`why`}</em>{` on earth was Reus’s chest wrapped up?`}</p>
    <p>{`Unless… he was… injured from their fight? Is that why he was eating less? Is that why he collapsed?`}</p>
    <p>{`Horror and kicked him in the gut like a wrecking ball.`}</p>
    <p>{`He remembered the time Reus dropped a bandage from his bag and Ashe picked it up, maybe it was an old injury? His panic receded slightly, thankful there was a slight chance he wasn’t the cause of this injury. Yet, Reus still managed to survive the battle royale they just had, `}<em parentName="p">{`with an injury?`}</em></p>
    <p>{`Dimitri, who took a course on first aid decided he was going to help Reus change his dressing. But as he unwrapped the unusually tight dressing (this was not recommended, as it would cut off blood circulation as taught by his instructor), he realised he hadn’t known pectoral muscles to be so… `}<em parentName="p">{`soft`}</em>{`.`}</p>
    <Ads mdxType="Ads" />
    <p>{`It struck him.`}</p>
    <p>{`These were not pectoral muscles.`}</p>
    <p>{`These were breasts.`}</p>
    <p>{`Female breasts.`}</p>
    <p>{`This was not Reus.`}</p>
    <p>{`Dimitri froze as a much more terrible horror dawned on him. His pale hand clasped his mouth before he could make any sound.`}</p>
    <p><em parentName="p">{`Goddess preserve me…!`}</em></p>
    <p>{`This person, whom he had enthusiastically undressed, was Byleth.`}</p>
    <Divider mdxType="Divider" />
    <p>{`She had the strangest dream. Stranger than the time she dreamt of the Goddess Sothis talking in her head when she was a child. This dream was turbulent. And suffocating. She felt like she was gasping for air while also being tossed around. Occasionally she’d feel something moist on her face, or that she was leaning on something warm.`}</p>
    <p>{`It took awhile for her to come to. The light burned her eyes as she forced it open and the smell of alcohol made her dizzy. Panic set in as she took in her surroundings, absolutely clueless as to where she was. The familiar but slightly worried face of the boy seated beside her bed was a sight for sore eyes.`}</p>
    <p>{`“A-Ashe?”`}</p>
    <p>{`“Reus… ?” Ashe looked up, relieved. Byleth propped herself up on her elbow and Ashe handed her a glass of water. “Thank goodness you’re okay! Are you feeling better?” She nodded, gulping the water down. “Oh sorry! You must be wondering where this is, it’s the infirmary. You passed out during training a-and Dimitri carried you here.”`}</p>
    <p>{`Oh right, Dimitri. She nearly strangled him and yet he still treated her—Reus, so kindly.`}</p>
    <p>{`Thankfully the throbbing in her head stopped, but she couldn’t put a finger on why breathing had become easier. Her chest felt strangely free and Byleth instinctively looked down, she was still dressed in her hoodie, she could feel the chest binder but it was loose. Suddenly it occurred to her that she hadn’t changed her pad yet. She could feel the blood leaking out of her. Quickly she thanked Ashe and flew out of bed and to the nearest toilet.`}</p>
    <p>{`“`}<em parentName="p">{`What?`}</em>{`” The voice on the other side of the phone cracked.`}</p>
    <p>{`“Reus, I-I fainted,” Byleth said quieter this time.`}</p>
    <p>{`Byleth had no choice but to call the real Reus for backup. Despite having come this far, her period would be the death of her.`}</p>
    <p>{`“Why? Did someone do something? Tell me you’re okay.”`}</p>
    <p>{`“It’s rare but—I mean my periods are usually mild but today was… painful.” Byleth was leaning against a cool wall after cleaning the ‘crime scene’. She still felt woozy and craving something sweet.`}<em parentName="p">{` Chocolate`}</em>{`, her mind demanded.`}</p>
    <p>{`“Do you… need me to come?” Reus’s voice changed to a softer tone, protective even.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“No, I just… need to lie down and stop being a boy.” She sighed and admitted, “It’s unusually bad this month.”`}</p>
    <p>{`“Well, I could take over you know. I mean nobody else is as good at being me, besides me.”`}</p>
    <p>{`“No, you’ll kill Dimitri.”`}</p>
    <p>{`Reus snorted, “I won’t. I promise. I’ll even `}<em parentName="p">{`talk`}</em>{` to him.”`}</p>
    <p>{`Byleth chewed her lip and squeezed her eyes shut. While grateful that the headache had stopped, she wished she could just fish out her uterus and fling it against the wall. Who’s idea was it to add killer cramps to monthly periods?!`}</p>
    <p>{`Time was running out, and she had been doing anything but the investigation over the past few days, like moping.`}</p>
    <p>{`“I’ll even leave chocolates on the bed—”`}</p>
    <p>{`“Okay deal,” she said quickly. Either Reus knew through the mythical twin telepathy, or he remembered the times she was curled up in bed hoarding all the chocolate.`}</p>
    <p>{`The plan was set. Reus was to somehow sneak into the school and discreetly take over from Byleth. She, as Reus, had been leaving for the town too often. All her effort would go to waste if Alois were to become suspicious now. It also didn’t sit well with her that she’d been abusing the good relationship Alois had with her father, since it was blatantly obvious how highly the man regarded Jeralt and his children.`}</p>
    <p>{`She trudged back to the dorm, stopping by the cafeteria to procure a sweet peace offering for her still raging uterus, and to get a change of clothes. If only she could shower. But fresh clothes would have to do.`}</p>
    <p>{`“Are you feeling better?” she jumped at Dedue’s sudden interaction. She hadn’t expected any of them to end the cold war so soon.`}</p>
    <p>{`“Yes, I am.” It was a partial lie. The sweet, peace offering did help alleviate some of the cramps.`}</p>
    <p>{`“Please accept this ointment.” In his large hand was a small bottle of medicated oil. “This is a new bottle that I had brought from my hometown.”`}</p>
    <p>{`“Are you sure? I wouldn't want to impose,” she said sheepishly, still a little cautious of his hidden wrath from the other night.`}</p>
    <p>{`“I insist. This will help you tremendously. And it will also serve as an apology for my behaviour the other night,” he said, pushing his hand towards her.`}</p>
    <p>{`Red crossed her cheeks. Was he a mind reader? “Oh no, I understand your point of view. He—`}<em parentName="p">{`I`}</em>{` acted out of anger.”`}</p>
    <p>{`Dedue nodded and placed it on her bed. “Nevertheless, I shall leave this here. In case you decide to use it. You will find its scent refreshing and clear.”`}</p>
    <p>{`Dimitri walked in, dripping with sweat and panting as if he just finished a marathon, and after they had a grueling training session, too. It seemed she still underestimated his strength and stamina.`}</p>
    <p>{`He greeted Dedue, made eye contact with her then swiftly turned his heel and walked back out.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Dedue hummed, side glancing at Byleth and she too hummed back, and shrugged.`}</p>
    <p>{`The atmosphere at dinner wasn’t as cold or hostile  anymore, but Dimitri seemed to choke on his food every time she looked his way.`}</p>
    <p>{`“Can you stop being so irritating and learn how to eat?” A very annoyed Felix said.`}</p>
    <p>{`Dimitri somehow managed to cough out an apology while hammering his chest.`}</p>
    <p>{`Things turned worse when she asked him if he was okay, and him going as far as spitting his food out, all while still avoiding eye-contact.`}</p>
    <p>{`“Uh-oh.” Sylvain and everyone else scooted his chair away from the fuming Felix covered in bits of food and spit.`}</p>
    <p>{`Felix didn’t have to say anything for Byleth to see him going through in his head the 101 ways to murder Dimitri right now.`}</p>
    <p>{`Placating Felix’s wrath took more tissues than expected, and it wasn’t soon until Dimitri had used up all he had. So Byleth handed him some. He took them without looking and said thanks. But he paused and did a double take.`}</p>
    <p>{`His blue eyes finally met hers, and they flitted between her hand and eyes.`}</p>
    <p>{`“Is there a problem with my tissue? It’s unused—”`}</p>
    <p><em parentName="p">{`“No!”`}</em></p>
    <p>{`They stared at each other wide-eyed at his sudden outburst, him blushing significantly more, then quickly excused himself.`}</p>
    <p>{`“I don’t say this very often but… what is `}<em parentName="p">{`wrong`}</em>{` with him?” Sylvain’s eyes trailed after Dimitri, then looked at her. “What did you do to him?”`}</p>
    <p>{`Ashe spoke up as she was about to defend herself, “Now that I think about it, he was acting weird all afternoon. He was...jumpy.”`}</p>
    <p>{`Felix made a face, “Ew.”`}</p>
    <p>{`“Truly, that is unusual,” Dedue also chipped in.`}</p>
    <p>{`“Did you catch him doing something suspicious?” Sylvain grinned and leaned in closer. “Oh come on Ashe, out with it!!”`}</p>
    <p>{`“Well, after training I went to tell him the nurse was in the infirmary because Reus passed out. And when I walked in, I think he didn’t hear me because when I called out to him, he jumped and looked like he had seen a g-ghost.” the group sat unusually still, not even Sylvian nor Felix said anything. “And the strangest thing— Dimitri pushed me out of the room and locked the door.”`}</p>
    <p>{`The atmosphere changed when they all turned their heads to see Dimitri walking back to their table, but then turned on his heel and went back again.`}</p>
    <p>{`Sylvain shook his head with the grimmest expression Byleth had ever seen.`}</p>
    <p>{`“Dimitri is broken.”`}</p>
    <Divider mdxType="Divider" />
    <p>{`The water flowing out of the tap wasn’t nearly cold nor was it strong enough to give Dimitri the waking up he needed. Slumped over a bathroom sink with his head underneath the tap, perhaps he had finally gone mad.`}</p>
    <Ads mdxType="Ads" />
    <p>{`He lifted his head  and looked into the mirror. Water dripped from the tips of his hair, blue eyes searching. Skin, pale. And then it clicked.`}</p>
    <p><em parentName="p">{`It. All. Made. Sense. `}</em></p>
    <p>{`The whole reason why Reus was so weird the first couple of days, his strange habits, his strange sense of fashion and why his uniform was so loose and frumpy on him. Ah, it also made perfect sense why Reus’ fighting style was familiar. And why he was fervently investigating Coach Kostas.`}</p>
    <p>{`Then something else occurred to him. Something absolutely mortifying.`}</p>
    <p>{`Recalling the times he had displayed himself in front of Reus like the statue David, he fell to his knees in utter horror. And when he thought it couldn’t get any worse, the memory of him so ‘helpfully’ undressing  Reus came to mind. A loud long groan escaped and echoed in the empty toilet.`}</p>
    <p>{`Dimitri picked himself off the floor. His neck and collar were wet but that was the least of his concerns right now. He stared long and hard into the mirror again but this time, his bright blue eyes shone with a new revelation.`}</p>
    <p>{`He would properly confront Reus. Not to expose him, rather to assist. Dimitri couldn’t keep the secret of what he had discovered to himself, not when every time he felt Reus’s gaze on him he turned into a spluttering mess and spat food out at Felix. He had to properly confirm it with Reus and assure that Dimitri was an ally.`}</p>
    <p>{`Dimitri had to admit, he felt a huge sense of relief that all this while, he had feelings for Byleth. And every moment he spent with Reus was actually with Byleth, however embarrassing those moments were. The guilt from having feelings for both twins was almost eating him from the inside. Well, he `}<em parentName="p">{`thought`}</em>{` he had feelings for both twins.`}</p>
    <p>{`But Dimitri was going to make sure it was for only `}<em parentName="p">{`one`}</em>{` twin.`}</p>
    <Divider mdxType="Divider" />
    <p>{`Byleth had found a nice cooling rock to sit on outside the greenhouse to wait for Reus, she looked down at her digital wrist watch, identical to Reus’s and squeezed the tiny light button, the time was almost 10pm. Her period cramps had subsided a little, although she was feeling a little sore down there. Overall, she was just exhausted.`}</p>
    <p>{`Earlier the dorm was abuzz with chatter when Dimitri returned, Sylvain literally threw himself at Dimitri to see if it really was ‘their’ Dimitri. Because there is no way ‘their’ Dimitri was ever ‘jumpy’. Never, not once. Not even when he takes a dump. Well, according to Sylvain anyway.`}</p>
    <p>{`Sweet Dedue even took Dimitri’s temperature. But when Dimitri looked her way, she felt a jolt of electricity.`}</p>
    <p>{`Even as Felix roasted Sylvain in the background, or as Ashe tripped and Dedue catching him, the moment Dimitri’s blue eyes locked with hers, it was as though time stood still. And that he was searching the depths of her innermost being.`}</p>
    <p>{`Almost as if in that particular second, their heartbeat synchronized.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Their little moment was broken when Dimitri intercepted a stray pillow from hitting her square in the face.`}</p>
    <p>{`“Sorry!” Sylvain called out. “Felix is being a dick.”`}</p>
    <p>{`She thanked Dimitri and excused herself, grabbing a spare change of clothes and pretended to head to the showers, but actually came to this particular spot instead. She had been sitting here for almost an hour since.`}</p>
    <p>{`Deep in reminiscence, Byleth hadn’t realised the spot of green she had been staring at the past minute was moving closer towards her, and that the green spot was actually Reus’s dishevelled hair.`}</p>
    <p>{`“Do you know how hard it was to find you?” Reus said, panting a little.`}</p>
    <p>{`She shrugged. “You ran all the way here?”`}</p>
    <p>{`“Almost. I told the bus driver to drop me off halfway and then I sneaked in.” Reus sat himself next to her on the rock. She handed him the clothes and he gave her his room key.`}</p>
    <p>{`“Right. I’m going to catch the last bus back to town.” Byleth stood up, adjusting her pants.`}</p>
    <p>{`“You know, I think the bus driver might be onto us.”`}</p>
    <p>{`“You make it sound as if there’s only one bus driver in the whole of Garreg Mach.”`}</p>
    <p>{`“Correction, there are three bus drivers who run the Garreg Mach shuttle buses to our school. ” Both Byleth and Reus turned around frantically at the unfamiliar voice. “So I think your little scheme is safe.”`}</p>
    <p>{`“Claude?” She squinted at a nearby bush.`}</p>
    <p>{`“—Von Riegan. The one and only, at your service. Again.” He grinned and winked at her. “I knew there was something odd about you. I didn’t actually think you were disguising yourself as your brother. `}<em parentName="p">{`Damn girl`}</em>{`.”`}</p>
    <p>{`Panic rose in her chest, “Look Claude, I can explain—”`}</p>
    <p>{`Reus stepped forward and put his arm in front of her. “You know him?” She nodded.`}</p>
    <p>{`“Wow, it’s a pleasure to meet you too, `}<em parentName="p">{`real Reus`}</em>{`. I’m Claude Von Riegan. I’ve seen you from afar but really, wow! The famous Reus who bit a guy. Come on, spill the tea.” Claude held a hand out to Reus, and Reus actually shook it.`}</p>
    <p>{`Byleth merely stared back at Claude and her brother, both who were now chatting excitedly about the whole thing.`}</p>
    <p>{`“I mean like, no offense,`}<em parentName="p">{` fake Reus`}</em>{`,” Claude turned to her, “But like, I would totally remember if I bit someone. `}<em parentName="p">{`That`}</em>{` was kind of a giveaway ya know.”`}</p>
    <p>{`She blinked back at him. “You...knew? Just from that?”`}</p>
    <p>{`Claude smirked. “I had a feeling.”`}</p>
    <p>{`Reus let out a whistle. “Damn.”`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Also I saw you sneak out in the middle of the night a couple of times so naturally the normal thing to do would be to follow you.” He shrugged. “Don’t look at me like that! Do you know how `}<em parentName="p">{`amazingly`}</em>{` boring this place is? I can only irritate Lorenz so many times in a day. Of course I’m gonna stalk the first thing I see acting suspiciously.”`}</p>
    <p>{`“You didn’t see her shower did you?” Reus asked, turning his big-brother mode back on.`}</p>
    <p>{`“On the contrary,” Claude looked at her and faked a dramatic sigh, “The lone handsome knight sat outside protecting you as you showered in peace.”`}</p>
    <p>{`“T-thank you, Claude. I didn’t know.” Panic rose in her throat that she hadn’t sensed his presence at all. Nevertheless Byleth was grateful.`}</p>
    <p>{`“Naw, no worries. It wasn’t all the time though so don’t go falling in love with me or Dimitri might even hit me or something.” He chuckled.`}</p>
    <p>{`“Dimitri...” Reus said, looking at Byleth. It was then something came over her and she punched her brother in the gut.`}</p>
    <p>{`“What the hell was that for?!” Reus cried out, doubling over in pain. Claude gasped for real this time.`}</p>
    <p>{`“Reus,” Byleth knelt next to him, “Promise me you’ll apologize.”`}</p>
    <p>{`Reus wheezed, “I was going to, you `}<em parentName="p">{`ass,`}</em>{` why did you punch me?!”`}</p>
    <p>{`“You made Dimitri feel so bad he let me choke him today during training.” She paused, letting her words sink in, “He `}<em parentName="p">{`let me`}</em>{` beat the shit out of him because `}<em parentName="p">{`you`}</em>{` made him feel like crap for having feelings.”`}</p>
    <p>{`“It was `}<em parentName="p">{`you`}</em>{`!” Claude said out loud, “You gave Dimitri Alexandre Blaiddyd a black eye and swollen ribs! Ho boy—” He stopped when Byleth and Reus nearly gawked at him. He shook his head. “Right sorry, carry on.”`}</p>
    <p>{`“So uh, I guess I’ll see you soon?” Reus shifted awkwardly, still clutching his now sore stomach.`}</p>
    <p>{`Byleth nodded. “Saturday night. I need to continue my investigation. With any luck I might find a key piece of evidence. If he hasn’t spent the money yet. Which, unfortunately, I think he has.”`}</p>
    <p>{`“Like a bank statement?” Reus said.`}</p>
    <p>{`“Yes, but it always auto-logs out. I need to guess his password—”`}</p>
    <p>{`“Oh that’s easy. I can help.”`}</p>
    <p>{`Byleth’s eyes widened at Claude, “I can’t involve any more people in this. And this is technically a crime and-”`}</p>
    <p>{`Claude did a chef’s kiss. “My specialty.”`}</p>
    <p>{`“How can we trust you though? I `}<em parentName="p">{`literally`}</em>{` just met you,” Reus said, crossing his arms.`}</p>
    <p>{`“You don’t have to.” Reus squinted at him, mimicking his suspicious look. “I’m kidding, please let me help. I'm bored to tears.”`}</p>
    <p>{`“I-I can’t put you at risk,” she admitted.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“I already have a good guess as to what it is though.” He shrugged. “Meet me back here on Saturday night and bring a large bag of fresh french fries.”`}</p>
    <p>{`“F-French fries?”`}</p>
    <p>{`“Yes, if this goes down well and I’m right, which I normally am, all I’m asking for is 50% of your french fries for the rest of your life.” Byleth couldn’t honestly tell if Claude was joking or being serious, but she accepted nevertheless.`}</p>
    <p>{`“Alright, now that’s all settled—shh. Wait. Someone’s coming.” Claude’s head perked up, scanning the area. “Crap it’s Ferdinand von Aegir—hide!”`}</p>
    <p>{`Just as she was about to see who this Ferdinand von Aegir was, Claude shoved her into a nearby bush, grabbed Reus and stood in front of it, effectively hiding her from view.`}</p>
    <p>{`“‘Sup Ferdie.” Claude slung an arm around Reus, who awkwardly did the same.`}</p>
    <p>{`“Good Evening Claude, and you must be...Reus Eisner?”`}</p>
    <p>{`Reus nodded and they shook hands.`}</p>
    <p>{`“Wutchu’ doing out here, von Aegir? It’s past your bedtime is it not?”`}</p>
    <p>{`“Well, if it’s any of your business, unfortunately I had forgotten a garden pruner from my personal collection and it must all be properly catalogued before I turn in for the night. What about you?” Byleth had almost forgotten they were all crowding outside the greenhouse.`}</p>
    <p>{`“Actually Ferdie, you walked in on one of my trysts with Reus.” Claude planted a kiss on Reus’s cheek, who looked as if someone puked in his mouth but Claude elbowed him and Reus forced a neutral expression back on his face.`}</p>
    <p>{`“Oh my, I’m terribly sorry. I will… excuse myself.” Ferdinand left awkwardly without even retrieving his pruner and when the coast was clear again, Byleth emerged from the thankfully non-prickly bush she had been propelled into.`}</p>
    <p>{`“Did you just kiss my brother?” Byleth said once she dusted the leaves off her.`}</p>
    <p>{`Claude laughed, “Yes, I had to. Ferdie is very… stubborn. But he knows when to give people privacy when needed. Sorry about that old chap.” He patted a pale Reus on the shoulder.`}</p>
    <p>{`“Okay, let's get you out of here,” Claude gestured at the exit, pausing to look at her, “uh, what’s your name again?”`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Byleth.”`}</p>
    <p>{`“I hope that’s your real name and not another sibling of yours.” She couldn’t help but let out a laugh.`}</p>
    <p>{`“Yes, I am Byleth Eisner.”`}</p>
    <Divider mdxType="Divider" />
    <p>{`Literally nothing else was on Dimitri’s mind except Byleth, and sometimes Reus, even more so because Reus apologized. Or if `}<em parentName="p">{`what`}</em>{` he saw the other day was right, it was actually Byleth who apologised. Nevertheless, it was a kind gesture and all was well.`}</p>
    <p>{`Except tonight was the night he, Dimitri Alexandre Blaiddyd, will confront Byleth Eisner about her secret.`}</p>
    <p>{`“Reus do you have a moment? I’d like to discuss something.” Fortunately no one in the dorm paid him any attention. He didn’t know what to tell them if they, or rather Sylvain in particular, teased him again.`}</p>
    <p>{`Reus got up to follow him, stuffing his phone into his pocket.`}</p>
    <p>{`“What’s it about?” he asked and Dimitri turned when the door closed behind them. “Sounds urgent.”`}</p>
    <p>{`Dimitri looked around and decided the area around the dorm wasn’t safe enough. “It is and I ask that you trust me.”`}</p>
    <p>{`Reus raised a suspicious eyebrow, or a curious one? Dimitri couldn’t tell with that deadpan face of his, but Reus complied.`}</p>
    <p>{`“Why does everyone like coming to the greenhouse for secret things? You guys do realise the walls are made out of `}<em parentName="p">{`glass`}</em>{` and people `}<em parentName="p">{`can`}</em>{` see through right?” Reus scoffed, lightly chuckling.`}</p>
    <p>{`“You have a point. I’m sorry, would you like to change the venue? I can think of another location if this is uncomfortable.”`}</p>
    <p>{`“Naw, s’okay. We’re already here. Anyway, what’s this ‘urgent’ thing about?” Reus imitated air-quotes with his fingers. Dimitri hoped Reus’ mood would just be as light hearted when he brought the topic up.`}</p>
    <p>{`He took a deep breath. “I know what’s been happening.”`}</p>
    <p>{`Reus blinked. “What’s been happening?”`}</p>
    <p>{`Something stirred inside Dimitri seeing the way Reus’ eyes grew wide and his cheeks turned pink as he stepped forward. Something… like wildfire in his veins.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Wh-what are you doing? Dude this is weird. Stop being weird.” Reus inched backwards towards the wall, but Dimitri couldn’t help himself from getting serious. He had to let Reus—no, Byleth. He `}<em parentName="p">{`needed`}</em>{` her to know that he was an ally and that she could stop hiding and confide in him.`}</p>
    <p>{`“I… know your secret. Byleth.”`}</p>
    <p>{`“Byleth? No wait—  I’m Reus.” ‘Byleth’ held a hand up against Dimitri to push him away, but Dimitri gripped her wrist, shock visible on her face.`}</p>
    <p>{`But… did Byleth’s hand grow a little larger, or was he merely mistaken?`}</p>
    <p>{`“What the hell? Dimitri you’re strong as fu—!”`}</p>
    <p>{`“Byleth, I know it’s you. You don't have to hide from me. I-I have to apologize for the way I discovered and I—”`}</p>
    <p>{`“Dude, you’re hurting me!”`}</p>
    <p>{`“I-I’m sorry!” Dimitri promptly let go of her hand, repeating his apology. “It’s just, I’ve been keeping this secret for a couple of days and I may have gotten a little overexcited.”`}</p>
    <p>{`“For shit’s sake, I’m Reus!” Byleth’s surprisingly gruff voice cracked as she grabbed Dimitri’s collar.`}</p>
    <p>{`Rather than get angry Dimitri merely chuckled, and his voice dropped to a whisper, “It’s okay. I know you have to keep up appearances. I’m… glad we can talk privately now.” Dimitri’s hand rested on her cheek. “I’m sorry you had to endure the wrath of everyone in the dorm alone. I wasn’t strong enough. I sincerely thought it was best I didn’t talk to Reus—you, because he was already so angry with me since our date.”`}</p>
    <p>{`“I was and I am `}<em parentName="p">{`now,”`}</em>{` Byleth growled. “Now get your nasty hand off my face before I—`}<em parentName="p">{`mmph?!`}</em>{`”`}</p>
    <p>{`Dimitri lingered and for a moment, so did ‘Byleth’. But only for a moment. Strange, he thought. These lips weren’t as soft as he remembered and felt somewhat chapped, unlike that night. Honestly, it disappointed him a little.`}</p>
    <p>{`She squirmed beneath him and her hands pushed hard against his chest. Just as he pulled away, a force as hard as a hammer slammed into his ribs and he fell back onto the ground, knocking over some potted succulents in the process.`}</p>
    <p>{`“`}<em parentName="p">{`What the fuck is wrong with you?!`}</em>{`” Reus roared, looking absolutely flustered and enraged.`}</p>
    <p>{`“B-Byleth...?”`}</p>
    <p>{`“Fuck this shit, I can’t take it anymore.” She threw her hands in the air. “I’m `}<em parentName="p">{`the real`}</em>{` Reus okay?! Very male and full of testosterone and all that chemistry shit. For the past 3 weeks my sister has been impersonating me to investigate your shitty coach.”`}</p>
    <Ads mdxType="Ads" />
    <p>{`“I-I know!” Dimitri’s voice turned quiet, “That’s why I kissed you. I-I thought you were Byleth.”`}</p>
    <p>{`“Well you thought wrong, you `}<em parentName="p">{`ass`}</em>{`! Or can’t your eyes tell the difference between a male and female?!” Reus grabbed a bewildered Dimitri by the collar again and pulled his face just inches from him.`}</p>
    <p>{`“`}<em parentName="p">{`Look at me`}</em>{`!” She hissed angrily, “Look at the stubble on my chin and feel my chest.” Byleth’s hot breath tickled his face, and as Byleth lifted her shirt, she took Dimitri’s hand and placed it on the chest underneath. Without a doubt, this was a man’s chest. Firm and muscled. Not soft and jelly-like like the one he undressed that day.`}</p>
    <p>{`And the faint stubble on the chin and upper lip were dead giveaways to Dimitri’s totally miscalculated error. Reality sunk in and he staggered back, nearly tripping over his own foot.`}</p>
    <p>{`“If this isn’t enough for you, I `}<em parentName="p">{`might`}</em>{` even let you grab my nuts!”`}</p>
    <p>{`Dimitri vigorously shook his head.`}</p>
    <p>{`Horror was an understatement.`}</p>
    <p>{`Reality had just fired at Dimitri a cannon ball filled with panic, dread, and fear all rolled into one, and it hit him right in his gut, tearing into his core.`}</p>
    <p>{`He didn’t kiss Byleth.`}</p>
    <p>{`He kissed Reus.`}</p>
    <p>{`The very-real-and-now-absolutely-seething Reus.`}</p>
    <p>{`“If this isn’t the juiciest bit of testosterone-filled drama did my little eyes ever see!”`}</p>
    <p>{`Dimiti and Reus froze at the male voice and blood drained from their faces when the person revealed himself.`}</p>
    <p>{`“Oh no.”`}</p>
    <p>{`“Oh `}<em parentName="p">{`yes,`}</em>{` Dimitri.” Claude winked and Reus’ jaw dropped. “Don’t gape at me, please carry on! I need to see how this terribly angst melodrama ends.” Claude licked his lips with a smack and grinned like the gossip-fueled Cheshire Cat he was.`}</p>
    <p>{`Dimitri should’ve known that nowhere on this earth was safe enough for secrets. He eyed Claude, still grinning and  watching expectantly for some steamy action to take place. His gut told him Claude would use this whole mess to his advantage somehow. Blackmail was Claude's middle name, after all. And Dimitri was probably right, too. At times like this he hated being right.`}</p>
    <p>{`Dimitri looked to the cloudy night sky, murmuring a desperate prayer as he saw his world potentially crumble before his eyes.`}</p>
    <p><em parentName="p">{`I’ve made a terrible mistake.`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      