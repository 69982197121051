import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const LocalLink = makeShortcode("LocalLink");
const RemoteLink = makeShortcode("RemoteLink");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`When playing my Nintendo Switch undocked, I find myself discontent with the quality of the music from it’s speakers. Sure, I could just jab my earphones into the headphone jack and be on my merry way, but I started appreciating a wireless setup if it can be done. I’ve heard of bluetooth adapters/transmitters for Nintendo Switch, but I was skeptical of the seemingly bulky size of these products, as well as its usefulness. All a bluetooth adapter needs to do is to allow my bluetooth earbuds to connect to it and pipe sound through it. Sounds easy enough but are these adapters able to project the sound without delay? Does it disconnect often? I’ve read enough reviews to tune out any bluetooth adapter/transmitter ads for Nintendo Switch. It doesn’t seem like any third party companies have nailed it yet.`}</p>
    <Ads mdxType="Ads" />
    <p>{`3 years in, third party products improved by leaps and bounds. I have a `}<LocalLink to="/blog/lypertek-tevi-review/" mdxType="LocalLink">{`Lypertek Tevi`}</LocalLink>{` that I really want to use so I could enjoy the best sound experience there is when gaming on my Nintendo Switch. The opportunity came when I saw a sleek and thin bluetooth transmitter in question for the Nintendo Switch in an ad. I’ve been using `}<RemoteLink to="http://www.gulikit.com/article_read_215.html" mdxType="RemoteLink">{`GuliKit Route Air`}</RemoteLink>{` for 2 months now, and I think I’m ready to give my verdict - it’s unexpectedly good at what it does!`}</p>
    <Image alt="GuliKit Route Air is sleek and thin. Plug it in and forget about it." caption="What it says. I really love the sleek design tho!" name="gulikit-route-air-review-geeknabe-4.jpg" mdxType="Image" />
    <h2>{`Sleek and thin design`}</h2>
    <p>{`I dug around and found that the GuliKit company actually made a `}<RemoteLink to="http://www.gulikit.com/article_read_194.html" mdxType="RemoteLink">{`bluetooth transmitter`}</RemoteLink>{` before GuliKit Route Air. Oh boy, it’s so… bulky. I could see how this is a problem if you’re playing undocked. It’ll easily get in the way, plus you can’t use it if your Switch is docked.`}</p>
    <Image alt="GuliKit Route+ image sourced from the website itself." caption="GuliKit Route+ is phat and bulky. You can't use kickstand mode while it is plugged in." name="gulikit-route-air-review-geeknabe-6.jpg" mdxType="Image" />
    <p>{`Their new product GuliKit Route Air however, is thin enough to stab it into your Nintendo Switch’s charging port and forget about it. The second best thing about GuliKit Route Air is being able to use it while docked. It comes with an extension so you can plug it into your dock’s USB port.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="GuliKit Route Air can be plugged into your dock!" caption="It fits snugly while plugged in." name="gulikit-route-air-review-geeknabe-1.jpg" mdxType="Image" />
    <h2>{`Transmits to 2 sound output at the same time`}</h2>
    <p>{`The third best thing about GuliKit Route Air is the dual streaming capability. You can listen to your Nintendo Switch blasting Animal Crossing music through your earbuds `}<strong parentName="p">{`AND `}</strong>{`your speakers at the same time! I’ve personally tried it and found that one of the outputs will always have a slight delay in piping out the sound. Overall, the playback experience is serviceable. It’s not something I’ll use often but I could see this be an issue if the sound is not matching up to the game on screen. Playing rhythm games would be a terrible experience!`}</p>
    <h2>{`HiFi capable`}</h2>
    <p>{`This is the main reason I got this bluetooth transmitter! I could do with a normal pair of earphones, but Lypertek Tevi is capable of a decent audiophile experience. To fully utilize the Tevi’s strength, I need a bluetooth transmitter that uses Bluetooth 5.0 and audio codecs. With this in mind, GuliKit Route Air is a godsend! I really couldn’t ask for more. The audio experience is much, much better than through the headphone jack. Please try comparing the audio through the headphone jack versus through GuliKit Route Air! The sound from GuliKit Route Air has better clarity and base. You could hear details you couldn’t before. It’s so much more satisfying than through the headphone jack. Plus, no wires!`}</p>
    <Image alt="GuliKit Route Air has three colours for you to pick." caption="GuliKit Route Air comes in Nintendo Switch Lite's blue, white and black." name="gulikit-route-air-review-geeknabe-5.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`How is the experience?`}</h2>
    <p>{`According to the manual, all you need to do is pair it once and it’ll automatically pair with your output devices whenever it’s plugged in. I find this both true and false. I initially paired it with my Lypertek Tevi and found it hard to pair at first. I had to make sure all bluetooth-enabled devices are turned off before I tried pairing GuliKit Route Air with my Tevi. It took a while but eventually I managed to pair the two. However, subsequent pairings are still wonky, thus forcing me to start the pairing process again whenever I want to use my Lypertek Tevi with my GuliKit Route Air.`}</p>
    <p>{`Pairing with my Edifier speaker is a smooth experience on the other hand. Once paired, GuliKit Route Air will always attempt to connect with my speakers automatically with no trouble. If plugged into the Nintendo Switch when undocked, GuliKit Route Air draws from your Switch’s battery. When plugged into the dock, it draws from your dock instead. I find it really troublesome since I use my speaker for my laptop and the plugged in GuliKit Route Air keeps reconnecting to my speaker with a loud beep. It does get annoying since I need to turn off power for my dock or unplug the GuliKit Route Air instead, but it’s a small inconvenience I could live with.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Despite all the hoo-has, Gulikit Route Air has `}<strong parentName="p">{`never disconnected`}</strong>{` for me even when I walked away with my earbuds in while my Nintendo Switch is in another room. When connected to my Tevi, there’s static occasionally but it has never disconnected from GuliKit Route Air. Seeing that there’s always trouble with my Tevi but not with my Edifier speaker when using GuliKit Route Air, I think the troubles stem from my Tevi instead. It’s disheartening but it’s something I expected when it comes to dealing with bluetooth devices.`}</p>
    <p><strong parentName="p">{`Important Note: With the latest update, Nintendo Switch is now able to connect to Bluetooth-enabled audio devices. I did not use the feature since my Gulikit could do that anyway, plus it guarantees the best audio fidelity. You might want to try Nintendo Switch's default Bluetooth first before getting this.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      