import PropTypes from 'prop-types';
import styled, { createGlobalStyle } from 'styled-components';

import $ from '../../../../styles/globals';

const Section = styled.section``;

const Container = styled.div`
  padding: ${$.layout.padding4}px 0;
`;

const Breadcrumbs = styled.div`
  font-family: Rubik Regular;
  font-weight: bold;
  color: ${$.brown4};
  text-transform: uppercase;
  letter-spacing: 1.3px;
  line-height: 1.3em;
  font-size: 14px;
  margin-bottom: ${$.layout.margin10}px;

  ${$.screen.mobile} {
    margin-bottom: ${$.layout.margin4}px;
  }

  > a {
    text-decoration: none;
    color: ${$.color.orange};
    &:visited {
      color: ${$.color.orange};
    }
  }

  > span.tag-separator {
    margin: 0 ${$.layout.margin1}px;
  }
`;

const NightMode = createGlobalStyle`
  body.night-mode {
    ${Breadcrumbs} {
      color: ${$.white1};
      > a {
        color: ${$.brown1};
        &:visited {
          color: ${$.brown1};
        }
      }
    }
  }
`;

const BreadcrumbsList = ({ tags }) => (
  <Section>
    <Container>
      <NightMode />
      <Breadcrumbs
        dangerouslySetInnerHTML={{
          __html: tags.join('<span class="tag-separator">·</span>'),
        }}
      />
    </Container>
  </Section>
);

BreadcrumbsList.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default BreadcrumbsList;
