import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Lost Song is an original 12-episode TV anime series that I’m dying to talk about, but I simply couldn’t find the right, catchy title for this blog post. I finished it ages ago on Netflix and I was left stunned at what they did to the ending. It’s fun and enrapturing from the beginning! I was left impressed with the `}<em parentName="p">{`smart loop`}</em>{` that they did. You see, there’s a ton of 12-episode original anime out there that really blows it out of the water. It’s proof that anime directors and writers have their own tales to spin and they’ll do it right if you give them a chance to. Lost Song is one of them.`}</p>
    <p><strong parentName="p">{`Note: I tried writing without any spoilers in it, but I ended with a ton of minor spoilers. Beware! Tread carefully!`}</strong></p>
    <Ads mdxType="Ads" />
    <h2>{`You sing from the heart`}</h2>
    <p>{`Lost Song is about 2 girls with a talent for singing. Songs that they sang weaves magic to the world, creating miracles that are otherworldly. Their talent is so rare, countries seek to bring them under control for their own selfish agendas. The plot stems from showing the two girls’ stories and how their paths intertwine to end the cycle for a grand finale. Of course, we are shown plenty of singing along the way.`}</p>
    <p>{`We are first introduced to Rin, a plucky young girl with an adorable design. She comes from a small village, and she loves to sing. Her close families and friends knew about her powers, so they urged her to never sing `}<em parentName="p">{`that special song`}</em>{` to keep her powers a secret. Unfortunately, her village was attacked and she’s forced to showcase her powers to save an injured knight. The knights realized the powers she possessed and gave chase, forcing Rin and her younger brother Al to flee from her ravaged hometown.`}</p>
    <p>{`The other songstress, Finis is a stark contrast to Rin. She’s demure and quiet, with a heart of gold. Her design accentuates her feminine features while Rin’s lean more towards an innocent young girl. While Rin hails from a small village and keeps her powers a secret, Finis sings for the people at the behest of the royal family. While Rin was only shown to sing a single song, Finis can sing three songs. Compared to Rin, Finis is shown to be a more powerful songstress and is beloved by the capital’s citizen.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Both have the same powers but they are very different from one another in terms of situation and character. As the story progresses, you see Rin discovering the hidden secrets behind her songs as she pursues Finis. On the other hand, Finis knew nothing about Rin as she went about her way, causing the very `}<em parentName="p">{`loop`}</em>{` we are treated to in the story.`}</p>
    <Image alt="Lost Song anime character Rin" caption="Rin, the mysterious young girl with the power of the Songs." name="lost-song-rin-geeknabe-blog.jpg" mdxType="Image" />
    <Image alt="Lost Song anime character Finis" caption="Finis, songstress with the power of the Songs." name="lost-song-finis.jpg" mdxType="Image" />
    <h2>{`Polar opposites and rightfully so`}</h2>
    <p>{`While the singing is the focus of the story, I’m super invested with the two main characters, Rin and Finis. Rin is voiced by Konomi Suzuki, who sang my favourite opening for No Game No Life’s movie (There is a reason), while Finis is voiced by Yukari Tamura. Both are established singers/voice actresses in the industry and their voice is really lovely in the show. The casting for these two characters is almost perfect. The songs in the Lost Song series are straightforward in terms of lyrics and very little background music to accompany it, thus it’s up to the singer to make the song shine. Both singers have a distinctive style to their singing and I find it really genius. Their singing and lyric choices both contrast each other’s character despite singing the same song!`}</p>
    <Ads mdxType="Ads" />
    <Video title="Lost Song" src="https://www.youtube.com/embed/y3ykrav72Vs" mdxType="Video" />
    <p>{`Rin is a young girl from a small village. Her short hair, red bow and a simple white dress are reminiscent to the titular girl from the fairy tale, Little Red Riding Hood. Whereas Finis is every bit a `}<em parentName="p">{`lady`}</em>{` we are familiar with. A white, puffy dress that covers every inch (except her chest... ) with great looking hair down her back. The contrasting images of the two girls are obviously intentional and along with their singing styles, makes for a great accompaniment to the plot. We knew they are contrasting characters for the plot, but to see the studio hammering the point all over every creative choices available makes me giddy as a potential fan.`}</p>
    <p>{`The opening of the song, Utaeba Soko ni Kimi ga Iru Kara is sung by Konomi Suzuki whereas the ending, Tears Echo was sung by Yukari Tamura. It’s nothing special, probably… Until you check out the lyrics! Both songs are them singing their hearts out to one another (plot-wise) which adds to the delicious details they are already putting in into the series.`}</p>
    <Video title="Lost Song" src="https://www.youtube.com/embed/dVi7jcbjKuc" mdxType="Video" />
    <h2>{`The songs are a banger, seriously`}</h2>
    <p>{`It’s no rock, it’s no Latin choir, it’s no dubstep. If you are familiar with RWBY, you’ll know that the series puts out songs that further explains the character’s backstory. It’s a treat to the fans and a really smart way to tell more stories without the need to… animate them. Here in Lost Song, the lyrics are akin to the song’s namesake. There are altogether 6 songs if I am not mistaken, with 4 songs about mother nature - Fire, Water, Wind and Earth and the last two about healing and destruction. The Song of Wind is all about the raging power of hurricanes and tornadoes, etc. Singing the song gives you power over the wind. The whole scene with the raging wind and sing-along was pretty fun to watch. It feels like an anime-style musical except there’s no animals singing along with you. The anime chooses only to put out a short excerpt of the song which is pretty nice on its own. If you listen to the full song, it bears a different mood! You can find the full song on Youtube if you’re up for it.`}</p>
    <Ads mdxType="Ads" />
    <p>{`The main songs that are sung at the beginning of the story and the ending bears the most weight throughout the story. These songs are called The Song of Healing and The Song of Destruction in the story. While the 4 nature-ish song lyrics speak more of their respective elements, The Song of Healing and Destruction speaks out to one another, literally. Once again, it’s an obvious contrasting plot element that I really enjoy seeing.There are some smartly hidden details in the songs that require you to perk up your ears as you go about your second rewatch. In every song that Rin sings in each episode, you can hear Finis singing along with Rin as well in the background! It’s a bit hard to catch so you might need to use earphones for this. I was really delighted when I noticed it, as I loop the songs for the hundredth time.`}</p>
    <h2>{`The side characters are hilarious, even if it’s for the plot`}</h2>
    <p>{`While the two girls took up most of the screentime, the side characters fill in the gaps with their goals and quirks. These characters aren’t meant to take up your time, they are just there to accompany the girls on their journey and as comedy relief. Despite that, they have pretty good designs and some really silly cliche quirks. I enjoyed all of the characters, but I really only have two favourites, Pony Goodlight and Monica Lux.`}</p>
    <p>{`Honestly, I could never take Pony Goodlight seriously. Like, what’s with that name? And her chest is even more ridiculous! I was laughing when I first saw her design in the series. She was first seen trying to haggle her inn room’s price down to zero in a silly way. Pony was shown to be a comedy relief in the beginning but in actuality, she’s a big sister character that serves as a mature voice and guide to Rin along her journey. You know, the dependable big sis. She’s also our first point in the story that explains the Songs’ mysterious powers and lore. All in all, Pony is a really nice lady that offers to bring Rin and Al to the capital because she couldn’t bear to see two lost child wandering on their own.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Lost Song anime Pony Goodlight character" caption="Pony Goodlight's character design." name="lost-song-anime-pony-goodlight.jpg" mdxType="Image" />
    <p>{`Monica Lux is another quirky character. Her silly habit (and character crux) is that she falls asleep whenever she senses danger. Well, technically she is always in danger with Rin so she’s always asleep! Her quirky habit is just an excuse for the writers to write in some silly, dangerous situations the cast needs to rescue her from, and one that I honestly enjoy. Her hoodie is amazingly cute too.`}</p>
    <Image alt="Lost Song anime Monica Lux character" caption="Monica Lux character design." name="lost-song-anime-monica-lux.jpg" mdxType="Image" />
    <p>{`Then there’s one character that I feel obligated to mention - Henry Leobolt. His design is as literal as it can get! He’s the typical dashing knight on a white horse, with an equally chivalrous principle in life to boot. While every character introduced in this series wasn’t meant to be deep, Henry’s character feels on the nose for me. He’s too straight, too honest, and that makes him seem like an idiot for getting into situations that could have been avoided. His character is only there to progress the plot in a grand manner which I think is a pity. I would have loved to see him in a different light but the plot dictates that he be treated that way.`}</p>
    <h2>{`It’s for teens`}</h2>
    <p>{`When I watched Ep. 1, it feels like a kid’s show and the writers definitely knew what they were doing. We were seeing things happening to Rin, a young child so I definitely appreciate that they were hammering this point in when they changed scenes to show what Finis went through as well. It wasn’t that it is kiddish, it’s intentionally so for Rin’s side of the story. The whole series is definitely for teens because last I checked, there are some heavy scenes and tons of blood. It is definitely not for kids. Heck, Netflix rates this as 16+. The starting scenes may give you the impression that it’s for kids but the plot twist near the end is worth staying for.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`All in all`}</h2>
    <p>{`While browsing Netflix’s original anime section, Lost Song is an unexpected gem. I’d put this series next to Egao no Daika in terms of originality and execution. The focal point of my enjoyment in this series is definitely the songs, followed by the plot and characters. If you are looking for a quick 12-episode anime marathon, this is a good one.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      