import PropTypes from 'prop-types';
import styled, { createGlobalStyle } from 'styled-components';

import $ from '../../../../styles/globals';
import NotebookIcon from '../../../../assets/icons/notebook.svg';

const Title = styled.div`
  font-family: Rubik Regular;
  font-weight: bold;
  letter-spacing: 2px;
  font-size: 20px;
  text-transform: uppercase;
  color: ${$.brown4};
  display: flex;
  flex-direction: row;
  align-items: center;

  > svg {
    margin-left: ${$.layout.margin1}px;
    width: 20px;
    height: 20px;
    fill: ${$.brown4};
    position: relative;
    top: -1px;
  }
`;

const Container = styled.div`
  border: 1px solid ${$.grey1};
  background-color: ${$.color.white};
  padding: ${$.layout.padding4}px;
  font-size: 17px;
  font-family: Rubik Regular;
  line-height: 1.5em;
  border-radius: 15px;
  color: ${$.color.lightblack};

  ${$.br}

  > div:last-child {
    p {
      margin: 1.5em 0;
      &:last-child {
        margin: 0;
      }
    }
  }

  hr {
    border: none;
    border-top: 1px solid ${$.color.brown3};
  }

  a {
    font-family: Rubik Regular;
    font-weight: bold;
    color: ${$.color.orange};
    text-decoration: none;
    word-break: break-all;
    &:visited {
      color: ${$.color.orange};
    }
  }

  img {
    width: 100%;
  }

  strong {
    font-family: Rubik Regular;
    font-weight: bold;
  }
`;

const NightMode = createGlobalStyle`
  body.night-mode {
    ${Title} {
      color: ${$.white1};
      > svg {
        fill: ${$.brown1};
      }
    }
    ${Container} {
      background-color: ${$.night};
      color: ${$.white1};
    }
  }
`;

const AuthorNote = ({ children }) => (
  <Container>
    <NightMode />
    <Title>
      <span>Author&apos;s Note</span>
      <NotebookIcon />
    </Title>
    {children}
  </Container>
);

AuthorNote.defaultProps = {
  children: <></>,
};

AuthorNote.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
};

export default AuthorNote;
