import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const RemoteLink = makeShortcode("RemoteLink");
const Blockquote = makeShortcode("Blockquote");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`If you have heard of Honkai Star Rail, there's a good chance you are a Genshin Impact player. I wager you've hit AR60, maxed out most of your characters and has even indulged in resin-less behaviour. Are you in the 1% of players that ice bridged from Liyue to Inazuma? What about stealing a waverider and bringing it all the way to Sumeru? If all of this sounds familiar to you, welcome to the club.`}</p>
    <p>{`The title of this post is kindaaaa made in jest, but deep down we all knew... Those sneaky devs paid us only a measly 30K mora for our feedback essays in Genshin and implemented all the good stuff in Star Rail. I can at least take comfort in knowing that even Star Rail does not have relics loadout... for now.`}</p>
    <p>{`Since there's not much things to do in Genshin, it's a no-brainer move to try Star Rail. I say `}<em parentName="p">{`try`}</em>{` because that's what I meant to do. I plan to try the game and back the hell out of it. Unfortunately, the game is pretty darn good. And when I say good, I mean...`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Pretty Darn Good No. 1: Everyone looks their age`}</h2>
    <p>{`I browse Twitter a lot. Everyone knows Twitter is a cesspool of questionable opinions when it comes to the games/anime department. So far, I've yet to see anyone taking jabs at Star Rail's character/age design correlation. Maybe I'm in the lawfully good section of Twitter. Or perhaps everyone is distracted by Sampo's hips instead, I don't know. I'm of the opinion that the designs are pretty tasteful so far, though I wish the girls get to wear pants instead of tights. Two of my favourite designs in Star Rail is Tingyun and Yukong, as evidenced by this post's banner.`}</p>
    <h2>{`Pretty Darn Good No. 2: The dialogue`}</h2>
    <p>{`Less hand-holding, repeating dialogues and fluff. I appreciate dialogues that go straight to the point with less jargon. Not to mention the pop culture references! It's a fun way to make the young players smile from the sass and `}<RemoteLink to="https://www.reddit.com/r/HonkaiStarRail/comments/13pey5q/why_is_this_achievement_called_chad_noriss/" mdxType="RemoteLink">{`the older players really sad if no one understood the reference. I'm not old, am I?`}</RemoteLink></p>
    <p>{`Meanwhile, in Genshin...`}</p>
    <Blockquote mdxType="Blockquote">
  Narana the Padisarah-picking Nara had arranged to invite Arana the Aranara to
  the bar to befriend Lambad, but alas, Arana the Ararakalari-exercising Aranara
  professed a preference to Padisarah-picking Nara, Narana, for venturing to
  Vanarana to view the panorama.
    </Blockquote>
    <p>{`Granted, I may have picked a terrible choice to showcase Genshin's problems but I wish to highlight just how many terms you need to keep track of when you start a new region's questline. They'll pepper the terms right at you, and expect you to know what's happening. I have not encountered this problem in Star Rail... yet.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Pretty Darn Good No. 3: Your MC can be whatever you want to be because your choices matter`}</h2>
    <p>{`I never really enjoyed Paimon being my spokesperson, not to mention how any of the dialogue choices I pick in Genshin never really matter. In Star Rail, my MC can be a saint, an adventurous Trailblazer or even a gremlin. You get achievements based on what answers you pick. There are even quests that ends differently based on what you choose.`}</p>
    <Image alt="Honkai Star Rail messaging system" caption="Who came up with this messaging idea? Please give them a raise." name="honkai-star-rail-messages.png" mdxType="Image" />
    <h2>{`Pretty Darn Good No. 4: The user experience in this game is pretty banger`}</h2>
    <p>{`With Genshin, I have so many complains. With Star Rail, I have nothing but praise.`}</p>
    <ol>
      <li parentName="ol">{`Maps have different levels shown. You just need to click around to select it.`}</li>
      <li parentName="ol">{`Total treasure chests available and total treasure chests found is shown on the map.`}</li>
      <li parentName="ol">{`Puzzles are right there. On the map!`}</li>
      <li parentName="ol">{`You can dispatch your units to the same expedition again.`}</li>
      <li parentName="ol">{`Clicking on the materials you need will show you the options on where and how to get it. Now here's the best part... Clicking on the synthesis option brings up the option to do so. You can even access the domains real quick through this menu.`}</li>
      <li parentName="ol">{`You can change between your controller and keyboard when playing on PC.`}</li>
      <li parentName="ol">{`Battles are amazingly seamless no matter how fast you click.`}</li>
      <li parentName="ol">{`Autobattle is a blessing.`}</li>
    </ol>
    <p>{`There's more I have not mentioned. It's the small things that counts when building a great user experience.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Pretty Darn Good No. 5: I can play with one hand.`}</h2>
    <p>{`If you're playing on mobile, you just need 1 finger to tap during battle. On PC, you can use your keyboard to do the same. If you're using a controller, you can technically do the same but not for spamming Ultimate(s).`}</p>
    <Image alt="Honkai Star Rail battle" caption="This man carries my team hard with his dandy stick." name="honkai-star-rail-battle.png" mdxType="Image" />
    <h2>{`Pretty Darn Good No.6: I can finally play on mobile.`}</h2>
    <p>{`I dread playing Genshin on my phone. I have an iPhone 11 and that's not exactly a dated phone... is it? Even at lowest graphic quality, Genshin chugs on my phone. And it dries my battery up as if it's a free flow service. I thank Star Rail for being a turn-based game. Not only can I play this game with 1 hand, I could play this game on my phone without it draining my battery fast.`}</p>
    <h2>{`Pretty Darn Good No.7: Rogue-like dungeons. ROGUE-LIKE DUNGEONS Y'ALL!`}</h2>
    <p>{`I'm having a blast with Simulated Universe. The Path Resonance gives an interesting spin for each dive you do. Bosses are tough if you don't bring characters/choose blessings that counter the mechanics. The more you dive, the more entries and achievement you unlock. These give you Stellar Jades. Every week, Simulated Universe refreshes their points to encourage you to go clear them again.`}</p>
    <Image alt="Simulated Universe menu" caption="This mode is challenging, and I love it." name="simulated-universe-honkai-star-rail.png" mdxType="Image" />
    <h2>{`Pretty Darn Good No.8: Dailies is so easy`}</h2>
    <p>{`I just need to take a photo, or use Technique twice to call it a day. Why can't Genshin have this? They should at least reduce the dialogue length. I've talked to Godwin so many times now...`}</p>
    <Ads mdxType="Ads" />
    <h2>{`All in all, a comforting game to play after work`}</h2>
    <p>{`Animal Crossing used to be that relaxing game you play after work to unwind. But you can't play Animal Crossing with one hand, nor can you tap the autobattle button and watch pretty splash arts and effects flash by while you eat. (I know AC is not that kind of game but you get me?)`}</p>
    <p>{`I think folks underestimate how important it is to make sure your game is not only fun to play, but `}<strong parentName="p">{`easy`}</strong>{` to play. Making Star Rail an area-based exploration game with turn-based combat, along with modern user experience design is a fantastic idea.`}</p>
    <p>{`I don't want to wrestle with the game to have a fun time. I want to `}<em parentName="p">{`play`}</em>{` the game without thinking too much about it, but there's no harm in throwing in some difficulty. If you enjoy a turn based RPG, you'll enjoy this game.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      