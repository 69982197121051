import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Webcomic/Webtoon may have taken over the world, but oldie shounen manga has a special place in my heart. I understand that webcomics are super proliferated on the Internet as of late, so it makes sense that almost no one has their eyes on manga nowadays. I wrote this in an attempt to get folks to read older titles! So I tried my best to pick out titles that's... not exactly popular or talked about for this blog post.`}</p>
    <p>{`If you have heard of these titles, then that's great! If you have not, please add them to your reading list. These titles are completed as of this writing, so you do not have to worry about being left hanging :D This list is not ordered.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Completed Shounen Manga Recommendation #1: Im - Great Priest Imhotep`}</h2>
    <p>{`This title just appeared out of the blue and captured my attention straightaway. It uses a standard shounen formula with a dash of mystery, kinda like Blue Exorcist or Blood Lad. It helps that there are less chapters so it makes for a fun read since the plot is super tight. In every chapter, there will be sub-plots getting resolved or new hidden mystery unveiled! The ending is an honest-to-goodness satisfying wrap.`}</p>
    <Image name="im-great-priest-imhotep.jpg" caption="I think there's... a bit of spoilers in this image. Eh, it's not a spoiler if it has no context!" alt="Im - Great Priest Imhotep" mdxType="Image" />
    <p>{`If you're thinking about The Mummy movie when you read this manga's title then no, you're off by a lot. In Im: Great Priest Imhotep, our MC is a good guy. The story starts with a global organization supposedly unsealing an Egyptian High Priest named Imhotep to help them rid the world of Makai. These "Makai" are evil beings who wish to impersonate gods and create calamities wherever they are.`}</p>
    <p>{`The first chapter has Imhotep sealing a fire makai within Hinome, a high school who lost her voice thanks to the makai in her. Throw in episodic makai sealing adventure, the growing friendship between Hinome and Imhotep, and you have the plot. Who is Imhotep, and what are these makai(s)? The mystery in the story is pretty much unraveling Imhotep's past and the so-called "gods" whom them makai are aiming at.`}</p>
    <p>{`If you enjoy Egyptian aesthetics, then this is a good read! It's a pity that the author didn't fully explore Hinome as a character but instead, focused solely on Imhotep and his "good friend". The rest of the cast is not too shabby, definitely likeable. But I could use more screentime for the side cast though!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Completed Shounen Manga Recommendation #2: +Anima`}</h2>
    <p>{`The term shounen encompasses such a wide scope! This manga is one of my favourite titles back in the days. It's a little between the theme of coming-of-age and fantasy adventure. Is this really shounen though? The message behind this title feels more like seinen to me. Nevertheless, everyone on the Internet has deemed this a shounen title. So be it.`}</p>
    <Image name="plus-anima.jpg" caption="The boy with black wings is Cooro. The girl is Nana, the boy with short hair is Husky and eye-patched boy is Senri." alt="+Anima" mdxType="Image" />
    <p>{`In a world of fantasy, children who have metamorphic abilities exist. These children are called +Anima, and society shun them for being different from the rest of their kind. These +Anima children have unique abilities that seem like a miracle to humanity. +Anima children with wings can fly. Some have sharp claws and strong arms, while some could breath underwater indefinitely.`}</p>
    <p>{`The story focus on a group of 4 +Anima children - Cooro, Husky, Senri, and Nana. Each of these children have a sad past `}<em parentName="p">{`because`}</em>{` they are +Anima. As the series progresses, you see them coming to terms with their past and helping others do the same too. What is +Anima, and why does it manifest in children only?`}</p>
    <p>{`The underlying theme makes for a great read, but I'm not a super fan of how the author enjoys jumping from one location to another without explaining how they get there. One moment they are in a city, and the next they are in... a forest? Did they teleport or something? `}<em parentName="p">{`Sheesh.`}</em>{` In all honesty, reading about these +Anima children braving their past is splendid. All in all, an evocative read about the children.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Completed Shounen Manga Recommendation #3: Tegami Bachi`}</h2>
    <p>{`To my surprise, I have not written about Tegami Bachi at all! Good. I get to write about this title in this blog post. Do you like delivering letters in an apocalyptic world? I hope not, because Tegami Bachi's world is a mess. Nice to read as a story, but not so nice to be in it!`}</p>
    <Image name="tegami-bachi.jpg" caption="What's a shounen without rivals and allies? Sending letters is a tough job, I'll have you know!" alt="Tegami Bachi" mdxType="Image" />
    <p>{`The story takes place in a land of perpetual night partly illuminated by an artificial sun called AmberGround. Our protagonist is Lag Seeing (Strange name, I know), a boy with a special amber eye. Though young, he took on a job as a delivery boy called Letter Bee with his partner Niche. In this world, delivering letters is a super dicey job. After all, outside of humanity's safe haven are Gaichuu(s), giant insect monsters who are capable of wiping cities outright. Why would a 12-year-old boy took on such a dangerous job? Just what are these Gaichuu(s)?`}</p>
    <p>{`The world itself is a fascinating concept. Loading bullets with memories? Come to think of it, that's kinda cheesy, but it feels right at home in the plot. I'm particularly fond of Niche, Lag's partner. Battle scenes with her in it tends to be really exciting!`}</p>
    <p>{`It's been a while since I last finished the story, but I remember it has a happy ending. You could try the anime too if manga is not your cup of tea.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Completed Shounen Manga Recommendation #4: Saike Mata Shite Mo`}</h2>
    <p>{`I remember being super engrossed in the MC's endeavour to save his childhood friend. The story progresses from a relatively simple idea and from thereon, it became... like any other shounen. There's the rule of three when it comes to the main cast, and there's this "danger level" that went from your local school to a global crisis.`}</p>
    <Image name="saike-mata-shite-mo.jpg" caption="How many times did this poor boy died?" alt="Saike Mata Shite Mo" mdxType="Image" />
    <p>{`Our protagonist is Saike, a third year middle school student. He's an introvert who keeps his head down wherever he goes. He doesn't really have any strong ambitions or whatnot... Saike is just content living within his own means and world. Though, he does have a cute childhood friend he is fond of. Mikan, you see, is the exact opposite of Saike. She's outgoing and has a dream of entering an art high school! Things were pretty normal for the both of them until Mikan died in an accident in front of Saike. Crushed by her sudden departure, Saike went back to the scene to mourn. It is at this very moment, the cliff gave way, and he fell into the pond. Thus did Saike left the world... Or so he thought. Time looped for him, and he finds himself reliving the day Mikan died. Can he change Mikan's fate this time?`}</p>
    <p>{`The beginning of this title is one of the best parts for me. If he fails to save Mikan, he'll need to rewind time again. (The exact workings of his ability will be explained in the story) How does he do that? Well, he needs to jump into the pond and die. And he dies a lot. The story expands on his ability after he manages to save Mikan once and for all. It turns out he is not the only ability user in the story, and most of them are out for blood.`}</p>
    <p>{`A fun read at the beginning. The rest of the story is up to your personal taste.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Completed Shounen Manga Recommendation #5: Vampire Juujikai (The Record of the Fallen Vampire)`}</h2>
    <p>{`Fortune favours me the day I picked this manga title to read at random. I thought it's `}<em parentName="p">{`meh`}</em>{`, but it turned out to be pretty good! I know the art is acquired taste, but the build-up makes up for it!`}</p>
    <Image name="the-record-of-the-fallen-vampire.jpg" caption="I couldn't find a decent image for this title until I saw Viz has a nice, hi-res banner. Thanks Viz!" alt="The Record of the Fallen Vampire" mdxType="Image" />
    <p>{`In a world where vampires are real, humans are locked in an everlasting fight to prevent the Vampire King from reclaiming his sealed queen. The Vampire King in question is Strauss, and he's been looking for his queen for thousands of years (Apparently. The exact number is lost on me.) Humans aren't about to let that slide since having the queen out and about would mean total destruction of mankind. But how can humans match up with the powerful and youthful vampire king? Simple. They send in dhampirs to finish the job. I say finish, but usually the best they can do is trade a few blows before Strauss flick them off. The best chance mankind has against Strauss is the Black Swan, a power that nullifies all magic before it. This power is passed down from one host to another if the previous host has died. By accumulating the previous hosts experience, Black Swan grows in strength. Will this power gifted to mankind one day defeat their greatest enemy?`}</p>
    <p>{`Re-reading the whole manga again made me feel silly that I didn't catch it initially. First and foremost, why on earth didn't the powerful Vampire King just wipe out humanity? Why did he give Black Swan hosts a chance to fight back? Is he... a good guy? Looks like it! I won't spoil you further, make sure to read the manga! It's honestly pretty good.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`All in all`}</h2>
    <p>{`I hope you enjoyed the recommendation!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      