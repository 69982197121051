import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const Blockquote = makeShortcode("Blockquote");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Men take pride in masculinity and the ladies, femininity. Being in a relationship is honestly just about two individuals so `}<em parentName="p">{`different`}</em>{`, they are always waiting to butt heads if they can’t come to an agreement for the long run. So how do couples stay together? One could always bring in `}<em parentName="p">{`logic`}</em>{` to cool things down but in the end, it has always been the `}<strong parentName="p">{`heart`}</strong>{` that talks things out.Spice and Wolf is a`}<em parentName="p">{` beautiful testament`}</em>{` to this. The anime was adapted from the Japanese light novel with the same title, written by Isuna Hasekura. Thank you for being born, Hasekura. You wrote a masterpiece.`}</p>
    <Ads mdxType="Ads" />
    <p><em parentName="p">{`Note: I have only watched the anime S1 and S2 on Netflix. This was written before I knew about the manga! I have not read the light novel! I just noticed that there’s a sequel! AAAAAA`}</em></p>
    <p>{`Spice and Wolf is all about the delicate affairs between a man and a woman. The story begins with Kraft Lawrence, a merchant who moves from town to town to sell his wares and has never opened his heart to anyone. He was strictly business-minded with no room for the ladies. He began to change when he met Holo, a wolf-deity who hitched a hike in his wagon. She wishes to return to her hometown up north, Yoitsu. After much deliberation, Lawrence agreed to guide her back home.`}</p>
    <p>{`The plot for Spice and Wolf is bloody simple. A guy and a girl went on a journey together, overcame hardship, fought and reconciled before falling deep in love. Simple is great, but it was the mature teasing and witty jabs that pulled me in! Netflix rated this anime as suitable for 13 years old and older, but I think the dialogue would probably be lost on the younger kids. Honestly, there’s so much underlying implication that only older folks could catch. Watching this anime requires all six senses fully open and ready to scan (for moe scenes)! Both Holo and Lawrence’s dialogues are such a joy to listen to. Spice and Wolf is honestly just 26 episodes of two adults teasing amicably and getting comfortable with each other. Some things don’t need to be said outright, they let their action do the talking. Logic trumps all, but ultimately it is the heart that makes the final decision. Everything is `}<strong parentName="p">{`implicitly `}</strong>{`hinted at in this series, it’s `}<em parentName="p">{`beautiful`}</em>{`.`}</p>
    <p>{`It’s even more so when you noticed how Spice and Wolf depicted the complicated meshes that goes on in a relationship. Lawrence was first wary and skeptical of Holo, and the girl is aware of it. She was mindful of how she talks at first, but it wasn’t long before both felt comfortable in each other’s presence. It was really done really subtly too! He wouldn’t dare to look at Holo in the eye initially (I’m sure because Holo is super pretty to him) and Holo would only speak in a manner befitting a wise wolf. They both treat each other as journeying partners that stuck together out of circumstances. They are friendly with each other, yes, but only because they are together for now.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Pleaseeee my wife! Don't leave me!" caption="The scene when Lawrence remembered how he first reacted to Holo’s transformation is super sweet. He could have continued to cower in fear and Holo wouldn’t have blamed him. It’s a normal human reaction after all. Instead, being the nice guy he is, he started trying to get Holo back by randomly throwing excuses." name="spice-and-wolf-anime-screenshot-3.jpg" mdxType="Image" />
    <p>{`They got way much closer after a serious incident happened. Lawrence finally saw Holo as the girl she is - a lovely girl with a witty tongue, albeit a lonely one. Holo too, saw Lawrence beyond his merchant mask -  a kind-hearted man who would choose his companion over profit. It was after this point in the story that their dialogue changed from purely friendly to being really close friends. It’s really subtle so you gotta be extra attentive while watching! The switch was so lovely I’m just pressing the Next Episode button on my Netflix app without stopping. Somehow I manage to finish watching S1 in one night!`}</p>
    <p>{`While S1 cements the two’s future, S2 is the absolute climax to the two’s relationship. A moment of weakness in Lawrence caused the two to quarrel (S2E3, vs Amati… LOL). If you think about it, it’s actually just a small matter, no biggie. But it was Lawrence’s hesitation/lack of self-confidence that sparked the whole problem. It warped his view of the whole situation when clearly, an explanation and an apology is all he needs to do. He didn’t know what his feelings towards Holo were. Plus, he was clearly super hesitant to make a strong decision when confronted. Holo is clearly giving him`}<em parentName="p">{` her heart`}</em>{` but Lawrence himself doubted if he was worthy to take it. I was expecting Lawrence to `}<em parentName="p">{`“run”`}</em>{` from the difficult question the entire episode but he didn’t.`}</p>
    <Image alt="Holo best waifu" caption="Holo giving up here just pricks my conscience. She wants to keep travelling with Lawrence but seeing how he turns tongue-tied to the important questions just breaks her heart…" name="spice-and-wolf-anime-screenshot-6.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <Image alt="Lawrence and Holo's first couple quarrel" caption="Even when yelling at Lawrence in frustration, she questioned herself what she thinks of her partner. She never blames it at him. Truth be told, she was only super mad when Lawrence kept quiet throughout the questioning and denying her advance. If he actually replied calmly and apologized, I’m sure Holo would forgive him for not telling her about Yoitsu." name="spice-and-wolf-anime-screenshot-1.jpg" mdxType="Image" />
    <p>{`Throughout the whole `}<em parentName="p">{`“arc”,`}</em>{` how both Holo and Lawrence acted just pulls at my heartstring. I have an inkling Holo didn’t hold it against Lawrence at all, and Lawrence himself did everything he could to get Holo back. The two did what they did because they have the other partner’s interest at heart. It’s so lovely to watch because that’s the very essence of a happily ever after! These two are literally at it because they care for each other. (Urgh, their fondness for each other is so pure...)`}</p>
    <p>{`Heck, all episodes after Amati’s incident just destroyed my soft, soft heart. They both came to an understanding that they fancy each other after denying Amati’s advances. Things between the lovebirds just… ramped up so `}<strong parentName="p">{`hard`}</strong>{`. THEY ARE LITERA-... Oof. Yea, I’ll stop before I start to gush non-stop.`}</p>
    <Image alt="Lawrence tucking his princess in mwah mwah" caption="HE CALLS HER PRINCESS ADADASDWEAKDJAIEA!!!" name="spice-and-wolf-anime-screenshot-8.jpg" mdxType="Image" />
    <p>{`I once posted on Twitter that Spice and Wolf are just two lovebirds flirting but it’s so much more than that. What the series depicted is the hidden hardship of a couple staying together, and caring for each other. Couples don’t gravitate together immediately, `}<em parentName="p">{`it takes time.`}</em>{` Everyone’s different so you’ll definitely butt heads! Eventually, you’ll reconcile because your partner is more important than a stupid fight. If you love your partner, you’ll want him/her to be happy. And that’s what both Lawrence and Holo did! Lawrence made sure to take good care of Holo and would always agree to her requests. He specifically spelled out that nothing is more important to him than to see Holo happy. Holo herself puts Lawrence needs first before hers. You’ll notice that she never blames Lawrence for the mistakes he made. `}<strong parentName="p">{`She questioned herself first before anything else. `}</strong>{`She never puts herself between her and his dream of owning a store because she knew how much Lawrence cherishes her dream.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Season 2 Episode 12, at 17:44 minutes is the exact timeframe where `}<strong parentName="p">{`I died of moe`}</strong>{`. He exchanged the inn deed for Holo’s freedom! It was an act that goes against what Lawrence is - a profit-minded merchant. He may have chosen Holo over profits in previous dealings, but he has always managed to make some moolahs one way or another. This time, it was truly a deal that goes all in or none at all. He could have closed one eye and went on with his life but no, he needs his wife. I’m telling ya, it’s a proper wedding. He did all of these - knelt down, confessed and proposed to Holo. She accepts his feelings and they kiss! Everything is done in A CHURCH. They then walk down hand-in-hand with bells toiling in the background.`}</p>
    <Image alt="WEDDING WEDDING WEDDING WEDDING" caption="WEDDING WEDDING WEDDING WEDDING" name="spice-and-wolf-anime-screenshot-4.jpg" mdxType="Image" />
    <p>{`S2 could have ended right there and then BUT YESSSS THEY ADDED Episode 13. We are truly blessed. It has so much sneaky symbolism, I cackled at the part about sheeps. Holo is still worried about a human’s lifespan but that’s fine by her. Her happiness may not last, but it is something that she’ll cherish.`}</p>
    <Blockquote mdxType="Blockquote">
  Matters of the heart may be complicated, but it’s worth the trouble.
    </Blockquote>
    <p>{`Annnnd I’m off to read the manga because I’m sure S3 will never come. Please watch Spice and Wolf Season 1 and Season 2!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      