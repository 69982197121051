import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXFanfiction/Chapter/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Divider = makeShortcode("Divider");
const MessageBox = makeShortcode("MessageBox");
const Ads = makeShortcode("Ads");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`“Alright children, you got your phones? Money?” Sylvain stood in front of her and Dimitri like a worried parent.`}</p>
    <p>{`“Yes to both,” Byleth said, actually opening her backpack and checking the contents.`}</p>
    <p>{`“Okay good. You, Dimitri.” Sylvain turned to the blonde with very serious eyes, “My good man, do you have condoms—”`}</p>
    <p>{`Felix punched Sylvain in the gut in reflex as she and Dimitri stood in shock, more so Dimitri than her. She eventually also punched the red-head’s arm.`}</p>
    <p>{`Dedue stepped over the groaning Sylvain and handed them their shuttle bus tickets. “I took the liberty of purchasing them ahead for you. Please do not worry about the payment as I owe the both of you more than the monetary value of these tickets.”`}</p>
    <p>{`“No, Dedue please—”`}</p>
    <p>{`Dedue shook his head at Dimitri’s protest.`}</p>
    <p>{`“But… I didn’t do anything?” Byleth stared down at her ticket then attempted to return it to Dedue who merely pushed it back to her.`}</p>
    <p>{`“You and your family invited me to lunch. That alone has more value than this meager piece of parchment. Please take it. I insist.”`}</p>
    <p>{`Seeing as Dedue was not going to budge figuratively and literally, she gave him a small smile.`}</p>
    <p>{`“Thanks Dedue. It’s really sweet of you.”`}</p>
    <p>{`“Also, Dimitri, you can’t seriously be going on a date dressed like that?” Sylvain said, after managing to pick himself off the sidewalk. Byleth thought he looked well dressed. Very Dimitri—a pair of crisp, beige slacks with a brown belt, a creaseless white shirt, tie and a fitted, navy blazer. She prayed that whatever Reus got her would be just as decent.`}</p>
    <p>{`“Who the hell wears a tie on a date? What are you, the crown prince of some country?” Felix rubbed his temples. “Animals have no sense of fashion.”`}</p>
    <p>{`“Well, i-it’s a very… mature look,” Ashe said.`}</p>
    <p>{`“I agree.” Dedue nodded.`}</p>
    <p>{`Sylvain stepped forward, yanked the tie off, almost choking Dimitri in the process and unbuttoned so many buttons on Dimitri’s shirt that his abs were on display. Dimitri’s arms flew to cover himself like an exposed damsel.`}</p>
    <p>{`“Boy, you gotta flaunt these `}<em parentName="p">{`delicious`}</em>{` washboard abs.” Sylvian slapped Dimitri’s hands away and took a step back to admire the masterpiece before him. “You know what, might as well lose the shirt—” Dimitri smacked Sylvain’s intrusive hands away before he could be stripped any further.`}</p>
    <p>{`“Sylvain, I’ll have you know that I shall not have you dictating my wardrobe tonight or any other night, in fact! ” Dimitri’s expression turned sour as he stood awkwardly with his entire torso exposed to the cold mountain winds. Byleth turned away, feeling quite hot.`}</p>
    <p>{`“And yet, here you stand.” Sylvain smirked, crossing his arms proudly. “What do you guys think?”`}</p>
    <p>{`“No,” Felix said flatly. Dedue and Ashe shook their heads as well.`}</p>
    <p>{`“No?” Sylvain pouted.`}</p>
    <p>{`Felix shook his head. “He looks like you.”`}</p>
    <p>{`“And that’s a bad thing?”`}</p>
    <p>{`Everyone nodded unanimously. Relieved, Dimitri quietly took the tie back from an incredibly disappointed-looking Sylvain.`}</p>
    <p>{`“Just in case,” he said and quickly straightened out his clothes again.`}</p>
    <Divider mdxType="Divider" />
    <p>{`The bus was unsurprisingly empty, seeing as it was 5pm in the evening. Students weren’t usually allowed out on weekdays but Mr. Alois had given them permission as planned.`}</p>
    <p>{`She and Dimitri were sitting in silence, though it wasn’t really silent. The bus conductor had strange sitar music playing in the background. They were quite comfortable actually, just a tiny bit awkward, only because Byleth and Dimitri had been texting all day, but he didn’t know that.`}</p>
    <p>{`If only Byleth could tell Dimitri the truth and let the real Reus take over her investigation.`}</p>
    <p>{`If only. But right now, she was Dimitri’s Reus. And she couldn’t risk mucking up something as simple as this. She sucked in a breath to calm her nerves. Act normal. Be Reus.`}</p>
    <p>{`Dimitri pulled his phone out to check for messages and looked crestfallen when there weren’t any.`}</p>
    <p>{`“She didn’t text you?” Byleth asked, amused.`}</p>
    <p>{`He shook his head. “I’m sure she’s busy. Garreg Mach town is very lively and full of activities, you know.”`}</p>
    <p>{`“And I bet you have a whole line-up planned for her, huh?” She kicked off her shoes, turned around in her seat so that her back was leaning against the window and looked at Dimitri. It was so bizarre that when she was Reus she could speak freely to him, but as Byleth, she’d catch him looking at her sometimes with a raw, fiery intensity in his eyes that she’d never seen before as Reus.`}</p>
    <p>{`Dimitri shifted in his seat as well, angling his body to look at her. He was blushing a little.`}</p>
    <p>{`“I hope they are to her satisfaction. I’ve done extensive research on things she might like based on the things I know about her.” Dimitri opened his backpack and pulled out a blue binder. He was about to do a short presentation as to where he was going to take Byleth but then when he made eye contact with her, she blinked furiously at him. Eyebrows drawn together.`}</p>
    <p>{`“I-I’m sorry, was this too much?” Dimitri slowly tried to hide his binder away from view but she burst out laughing.`}</p>
    <p>{`“N-no! It’s—Let me see.” Grinning, she leaned over to grab it but he lifted it above his head.`}</p>
    <p>{`“Reus please, it’s so embarrassing now that I think about it—”`}</p>
    <p>{`“You’ve already bragged about it. Now let me see!” She just had to see what he had gathered about her in his colour tab coded binder. “I have to make sure you didn’t write creepy stuff about my sister.” Byleth stood on her knees to reach above Dimitri’s head, who then held it to his side away from them.`}</p>
    <p>{`“So you `}<em parentName="p">{`did`}</em>{` write creepy stuff about her?” Byleth smirked at him. “Dimitri Alexandre Blaiddyd, I’m disappointed!”`}</p>
    <p>{`“What? Never! I— Hey—!” When he was distracted by her comment, she snatched the binder and flipped it open to a random page, it was all neatly typed and printed in colour. One of the bullet points that caught her attention read:`}</p>
    <p>{`‘`}<em parentName="p">{`The most mesmerizing smile I have ever laid eyes upon.’`}</em></p>
    <p>{`Byleth looked up at Dimitri who was hovering over her trying to seize his binder back, but he stopped when he saw her expression. She tried to hold back her smile but then couldn’t help and let herself smile at him.`}</p>
    <p>{`She was completely lost in his eyes when the bus abruptly stopped, jerking her sideways, and Dimitri’s muscular arms were wrapped around her torso before she fell out of her seat.`}</p>
    <p>{`“Sorry!” the bus driver called out, “That darn cat jumped out of nowhere!”`}</p>
    <p>{`Dimitri released her and she shoved the binder at him. “You can have this back—b-before I toss it out, it’s… I don’t want to see your weird fantasies about my sister.” Byleth shook her head and leaned back on the window, hiding her blushing face with one hand.`}</p>
    <p>{`“Sorry,” Dimitri mumbled and gingerly returned his ‘Miss Byleth Eisner’ binder back into his bag.`}</p>
    <p>{`They sat in silence the rest of the bus trip. The thing Byleth knew, Dimitri was gently shaking her to wake up. She groggily stumbled out the bus and pulled her phone out for directions. They weren’t standing in front of some cheap backpackers hotel, in fact it was quite a nice looking boutique hotel.`}</p>
    <p>{`“How is he affording this?” Byleth said a little too loud to herself.`}</p>
    <p>{`Dimitri, who was stretching his neck to look up at the nameplate, looked at her. “I’m sorry?”`}</p>
    <p>{`“I said how is `}<em parentName="p">{`she`}</em>{` affording this.” Byleth shook her head as they walked into the lobby. “Do you mind waiting here? I’m going to check on her. It’s uh, her first date so…”`}</p>
    <p>{`“Of course.” Dimitri immediately sat himself onto a nearby plush sofa.`}</p>
    <p>{`She took a lift and bashed her head against the door, `}<em parentName="p">{`This is really happening. I can’t believe this is real, oh my goddess my first date and I’m lying to him—`}</em>{` The lift door opened and a stranger stepped in. She straightened herself while still cringing, praying this whole date would go well.`}</p>
    <p>{`Byleth knocked on the door, a topless and slightly annoyed Reus answered.`}</p>
    <p>{`“About time you got here,” he said, shutting the door behind her.`}</p>
    <p>{`“What happened to the backpackers’ hotel?” She tossed her bag aside and removed the hoodie she was wearing, which was much too warm for the town, despite the cool autumn weather. It was much colder up on the mountain their school was built on.`}</p>
    <p>{`“Well, the old people I went on tour with today liked me so much they asked me to stay in the spare room they had. One of their friends had emergency hip surgery but their room was already paid for and it was too late to cancel. So here I am,” Reus replied, his arms spread out.`}</p>
    <p>{`“Right. So, where are my clothes?” Reus pulled out three different dresses from the wardrobe and laid it on the bed for her.`}</p>
    <p>{`“Wow, I didn’t expect you to be so generous with your choices.”`}</p>
    <p>{`“It was a buy three for— anyway, I even got you this boob-tube-bra-thing to go with your dresses.” He handed her a black bag. “The bra shop lady thinks I’m weird and I’ve accepted it. I’m going to go take a dump, so you just do your thing.”`}</p>
    <p>{`There were only actually two dresses that Reus got her; a strappy short, jumpsuit and a little black cotton dress with a neckline that would otherwise be fine on a regular-chested woman. Though, her being well-endowed made the dress into one with a plunging neckline instead. She pursed her lips as she decided between the two. It was also nice of him to throw in a complimenting denim jacket, just in case.`}</p>
    <p>{`“Oh I forgot, I got you shoes as well!” her brother yelled from the bathroom.`}</p>
    <p>{`She chose the black dress and as she squeezed herself into the dress, her brother came out to grab the clothes she wore on her way here.`}</p>
    <p>{`“How does it fit— Whoa, good thing I got you that jacket eh?” Reus nodded at her cleavage. “Why didn’t you choose the other one?”`}</p>
    <p>{`“That’s a jumpsuit, it’s going to be hard to pee and stuff,” Byleth stuck a hand inside the low cut dress to further adjust its fit against her chest. The bra was slightly too small but at least it covered most of `}<em parentName="p">{`it`}</em>{`.`}</p>
    <p>{`“What? Just whip out your— Oh right. You’re not a guy.” Reus snickered. “Get it, get it?”`}</p>
    <p>{`She rolled her eyes at him.`}</p>
    <p>{`A good 15 minutes later in the lobby, as she exited the elevator she saw Dimitri sitting as straight as a board right where she left him, however he was almost completely obscured by the unusually large bouquet of roses in his hands.`}</p>
    <p>{`“Oh Goddess.” Reus muttered under his breath.`}</p>
    <p>{`She nudged him. “Be nice.”`}</p>
    <p>{`“B-Byleth! Hi! Um, you look...” Dimitri blushed furiously as he kept his eyes on hers. “You look amazing.” Now Byleth didn’t know where to put her face.`}</p>
    <p>{`“Thanks, I had help choosing this.” She threw Reus a side glance.`}</p>
    <p>{`“Here.” Dimitri handed her the large bouquet of roses. It even had a small teddy bear in the middle. Byleth looked down as she sniffed the flowers and mumbled a soft ‘thanks’.`}</p>
    <p>{`“I booked a restaurant for the three of us. I-I hope it’s to your liking.” Dimitri gestured towards the door. “Actually Sylvain suggested it and I’m not sure if it’s a good idea now.”`}</p>
    <p>{`“I’m sure Sylvain’s recommendation must be good, judging by how many girls he’s—” She quickly elbowed her brother in the ribs. “R-Reus, you said something like that, right?”`}</p>
    <p>{`“Oh yeah, Sylvain totally. Cool dude. So cool.” Reus blurted out without thinking. Byleth shot him a glare. “I-I mean, not. Cool.” When Dimitri wasn’t looking, the twins stared at each other, gesturing angrily.`}</p>
    <p>{`“Despite his skirt-chasing ways,” Dimitri said, his tone disapproving, “We’re still working on that— I must admit he’s quite knowledgeable in this area.” Dimitri turned to them and the twins ceased immediately. Awkward grins plastered on their faces. Dimitri smiled back, extending his arm out the entrance. “Shall we?”`}</p>
    <Divider mdxType="Divider" />
    <p>{`Byleth fidgeted in her seat across from Dimitri, occasionally glancing at the elegant plates, polished cutlery on its sides, crystal wine glasses and the glittering chandelier hanging in the middle of the room. Just the hushed conversations and soft classical music would’ve been enough to indicate how luxurious this establishment was.`}</p>
    <p>{`Not only was she inappropriately dressed, the meals were ridiculously expensive for teenagers like them. She sucked in a breath at the number of digits following each exquisitely named dish. A part of her regretted not checking when Sylvain had recommended this place with enthusiasm, saying that he came here often. How was he affording this? Dimitri didn’t seem at all bothered by how posh this place was either.`}</p>
    <p>{`There were so many things she didn’t know about Dimitri. It came as no surprise. She peeked at him from behind the menu. Besides his usual looks, his suit looked tailor-made to perfection. Even his necktie looked of the best quality. It was probably true then; students at Garreg Mach were rich kids who didn’t need the sponsorship at all.`}</p>
    <p>{`“Please order anything you like, this meal is on me,” Dimitri said to the both of them. “And I am aware of how much nutrition you need as well Reus, please do not hesitate to order as much as you need.”`}</p>
    <p>{`“Yes Dimitri,” Reus eyed Byleth for a split second. “I `}<em parentName="p">{`have`}</em>{` been eating so much haven’t I? Must be all the training.”`}</p>
    <p>{`Byleth smiled nervously at Dimitri as she stepped on Reus’s foot.`}</p>
    <p>{`True to his reputation, Reus shamelessly ordered many things on the menu while Byleth stuck to merely ordering an appetizer and a main course. Noting she was going to be hungry later.`}</p>
    <p>{`“How’s your investigation going?” She leaned forward. “You mentioned interviewing people. Any progress?”`}</p>
    <p>{`“I have actually, and I intend to give you a list of information that I am currently gathering when it’s completed. Please rest assured.” Dimitri gave her a reassuring smile. “How has Garreg Mach been treating you?”`}</p>
    <p>{`“It’s a uh.” She gulped. “It’s surprisingly an active little town,” she blurted, remembering what Dimitri mentioned on the bus.`}</p>
    <p>{`“It’s very charming—I especially love the museum. Have you visited it yet?”`}</p>
    <p>{`She shook her head and his face lit up.`}</p>
    <p>{`“Ah, very good!” He inched closer and spoke in a quiet voice bubbling with excitement, “I have procured tickets for a special exhibition tonight. It’s about the War of the Eagle and Lion. A historic battle—”`}</p>
    <p>{`“Wait, what?” Reus who was texting, suddenly interrupted, “You’re taking your date to a museum?” he said in disbelief and amusement.`}</p>
    <p>{`“Oh, is this not appropriate? Based on my research, I thought this would be a good way to introduce Garreg Mach and at the same time further her interest in weaponry,” Dimitri said carefully.`}</p>
    <p>{`“It’s fine! I-I love museums.” Byleth kicked Reus under the table.`}</p>
    <p>{`Reus looked at her in astonishment, slightly wincing. “You do? How come I’ve never seen you visit one before, then?”`}</p>
    <p>{`Her gaze hardened at her brother. “I just haven’t had the time or the opportunity.”`}</p>
    <p>{`“I’m sorry if it’s not to your liking, I can always—”`}</p>
    <p>{`“No, I would `}<em parentName="p">{`love`}</em>{` to.” She unconsciously placed a hand on his, but quickly pulled away when Reus cleared his throat.`}</p>
    <p>{`Self-control was evidently proving more difficult than she’d anticipated.`}</p>
    <p>{`Thankfully their meals arrived before Reus could make a fuss and Byleth set herself to focus on the scrumptious meal placed before her. However it struck her as odd that Reus cleared his throat whenever she put something in her mouth, that she looked up at him the first couple of times. When he caught her eye, he mouthed something she couldn’t quite decipher, and ignored him. Then he started coughing and his face started to flush when one of his further attempts went wrong.`}</p>
    <p>{`“Reus! Try to breathe—I’ll fetch some water.” Dimitri quickly left the table while onlookers whispered amongst themselves, watching the spectacle.`}</p>
    <p>{`“I’m—`}<em parentName="p">{`cough`}</em>{`—okay.” Reus hammered his chest. “Stop eating—`}<em parentName="p">{`wheeze`}</em>{`—like me.”`}</p>
    <p>{`“What?” Byleth bent closer while soothing his back. “I don’t—”`}</p>
    <p>{`“Eat like a lady, dammit. You’re eating like a man.” Reus took a deep breath. “Dimitri can’t keep his eyes off you.”`}</p>
    <p>{`“Here.” They both looked up at Dimitri refilling Reus’ glass, who then drained it in one go.`}</p>
    <p>{`Colour drained from her face. Now that they were left with dessert, she wished she had listened to Reus’ indecipherable signals. So much for impressing Dimitri with elegance. He probably couldn’t stop staring because she looked like a tramp eating.`}</p>
    <p>{`She shook her head and sighed.`}</p>
    <p>{`“Is… something the matter?” Dimitri’s brows were furrowed. “Is Saghert and Cream not to your liking?”`}</p>
    <p>{`“N-no! It’s great, really great. Much, `}<em parentName="p">{`much`}</em>{` better than the one we have back in school—!”`}</p>
    <p>{`“You’ve had Saghert and Cream in our school cafeteria before?” he asked and looked as if he was trying to recall something.`}</p>
    <p>{`“Oh! Um, Reus said, uh—” She kicked her brother under the table. Reus sputtered.`}</p>
    <p>{`“Yeah, I mean for the price, it `}<em parentName="p">{`better`}</em>{` be good.” He smiled sheepishly and awkwardly patted her on the shoulder, hopefully deflecting the situation. “Is it possible for you to get a refund on my ticket for the uh, museum?”`}</p>
    <p>{`Byleth looked at Reus in panic, she didn’t want him around for the date at first, but she also didn’t trust herself to be alone with Dimitri. Not after she had let herself touch his hand. This was all overwhelming. One could say that dates were not her forte, whatmore this was her first date.`}</p>
    <p>{`“You’re not joining us?” Dimitri looked surprised.`}</p>
    <p>{`“Nah, I’m not a museum-person.” Byleth didn’t expect that. As far as she knew, Reus enjoyed history as well, even though he didn’t seem the type. Maybe he wanted to give them some time alone, or maybe he felt awkward tagging along on his sister’s date.`}</p>
    <p>{`She snorted to herself. Yeah, that was probably it.`}</p>
    <p>{`“Anyway, it’s not often we get a night out on a weekday, right?” Reus grinned. “Hey, could you give my ticket to Ashe? He said he wanted to see the exhibition.”`}</p>
    <p>{`“He did?” Byleth and Dimitri said simultaneously, and she was puzzled, if she didn’t hear anything about it before, how would Reus know?`}</p>
    <p>{`“He said so in the group chat,” Reus replied.`}</p>
    <p><em parentName="p">{`Oh right.`}</em></p>
    <p>{`“I don’t mean to be rude, but could I just check a couple of messages?” Dimitri asked, and she nodded. He pulled out his phone and after a few seconds his face turned red. She couldn’t tell the expression he had as he typed out a response. She peeked at her brother’s phone and let out a discreet snort.`}</p>
    <MessageBox totalUnread={0} contactNumber="BLUE LIONS FTW SUCK IT" items={[{
      timestamp: {
        day: 'Tuesday',
        time: '06:45PM'
      },
      seen: 'Seen',
      right: '<p>guys he’s taking her to a museum lol</p>'
    }, {
      name: 'Sylvain',
      left: '<p>ew</p>'
    }, {
      name: 'Dedue',
      left: '<p>A wise choice. As it is educating and it offers them a peaceful environment to bond.</p>'
    }, {
      name: 'Sylvain',
      left: '<p>its for old ppl and nerds</p>'
    }, {
      name: 'Sylvain',
      left: '<p>dimtri</p>'
    }, {
      name: 'Sylvain',
      left: '<p>dimiet</p>'
    }, {
      name: 'Sylvain',
      left: '<p>*dimiri</p>'
    }, {
      name: 'Sylvain',
      left: '<p>*DIMITRI</p>'
    }, {
      name: 'Sylvain',
      left: '<p>don’t take her to a museum u weirdo wats wrong witchu</p>'
    }, {
      seen: 'Seen',
      right: '<p>she agreed to it tho</p>'
    }, {
      name: 'Sylvain',
      left: '<p>omg wat</p>'
    }, {
      name: 'Ashe',
      left: '<p>oh man, they have a special weapons exhibition i wanted to see</p>'
    }, {
      name: 'Sylvain',
      left: '<p>dimiTRI U CANT SMOOCH IN A MUSUEAM</p>'
    }, {
      seen: 'Seen',
      right: '<p>lol if he smooches my sister ill punch him</p>'
    }, {
      name: 'Sylvain',
      left: '<p>lmao record i wanna see</p>'
    }, {
      name: 'Sylvain',
      left: '<p>DIMITRI KISS HER</p>'
    }, {
      seen: 'Seen',
      right: '<p>-.-</p>'
    }, {
      seen: 'Seen',
      right: '<p>dude</p>'
    }, {
      name: 'Sylvain',
      left: '<p>DIMITRI STOP IGNORING ME</p>'
    }, {
      name: 'Sylvain',
      left: '<p>reus wat r they doing</p>',
      centerText: '<p>Felix has left the chat</p>'
    }, {
      name: 'Sylvain',
      left: '<p>wtf fleix come back</p>'
    }, {
      seen: 'Seen',
      right: '<p>eating</p>',
      centerText: '<p>Sylvain added Felix to the chat</p>'
    }, {
      name: 'Sylvain',
      left: '<p>hey why u leave</p>'
    }, {
      name: 'Sylvain',
      left: '<p>*did</p>'
    }, {
      name: 'Felix',
      left: '<p>this stupid group is too noisy and by group i mean you</p>'
    }, {
      name: 'Sylvain',
      left: '<p>aww bb dont b mad 😘</p>',
      centerText: '<p>Felix has left the chat</p>'
    }, {
      centerText: '<p>Sylvain added Felix to the chat</p>'
    }, {
      name: 'Felix',
      left: '<p>stop spamming or you’ll be shitting microchips out for a week when I shove your phone down your throat.</p>'
    }, {
      timestamp: {
        day: 'Tuesday',
        time: '07:30PM'
      },
      name: 'Dimitri',
      left: '<p>I will do no such thing. Reus, you can trust me.</p>'
    }]} mdxType="MessageBox" />
    <Divider mdxType="Divider" />
    <p>{`At the end of dinner Dimitri attempted to pay the bill with a shiny gold credit card, which was declined.`}</p>
    <p>{`“That’s odd,” Dimitri said, and urged the waiter to try again. It was declined, again. With his brows knitted, he pulled out what looked like a debit card which to his relief, was accepted.`}</p>
    <p>{`“Call me when you’re done,” Reus yelled, doing the ‘I’m watching you’ gesture as he disappeared in the opposite direction, holding her massive bouquet as a favour.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Byleth gripped the edges of her denim jacket, panic setting in. She had been alone with Dimitri before but it was different now that she felt `}<em parentName="p">{`something`}</em>{` for him, however fast it was happening.`}</p>
    <p>{`They walked along a lake towards the museum in silence.`}</p>
    <p>{`“I...have to confess something,” Dimitri spoke suddenly. Byleth looked at him in alarm, her heart nearly stopped and she held her breath. “I have actually been to this exhibit before.” She let go of the breath she was holding in a shaky exhale.`}</p>
    <p>{`“R-Really? When?” She looked up at him. Byleth didn’t recall him going out while she was around.`}</p>
    <p>{`“About two weeks ago, during the holidays before school started. I’m extremely drawn to this exhibition for some reason.” `}<em parentName="p">{`Ah, that made sense. `}</em></p>
    <p>{`“Wow, it’s that good, huh?”`}</p>
    <p>{`The moon shone brightly in the sky, lighting their path. It was a shame the stars were obscured by clouds because Dimitri looked radiant, like a prince almost.`}</p>
    <p>{`“Well yes, I suppose. They have replicas and even the original soldiers’ uniforms. I don’t want to spoil it for you, but it’s all quite well done.” He said, rather pleased. She could see the excitement in his eyes when she looked up at him.`}</p>
    <p>{`“I can’t wait.” She meant it.`}</p>
    <p>{`Dimitri smiled. “We’re here.” He gestured at a surprisingly large colonial-looking building for such a small town.`}</p>
    <p>{`“I hope you’ll find my company sufficient,” Dimitri suddenly said after they scanned their tickets at the entrance.`}</p>
    <p>{`“I’m sorry?”`}</p>
    <Ads mdxType="Ads" />
    <p>{`“As in”— he scratched his cheek —“I hope you find me enough to take you on this tour, instead of relying on a tour guide.”`}</p>
    <p>{`“Oh, definitely! You look the part, anyway—with the blazer and tie.” She giggled. “Sorry.”`}</p>
    <p>{`She was expecting him to protest, but he merely looked at her with curiosity in his blue eyes. “Your laugh—it’s oddly familiar and yet… I can’t help but feel drawn to it.”`}</p>
    <p>{`She clapped a hand over her mouth. If she wasn’t careful he would figure out the truth too soon.`}</p>
    <p>{`“Forgive me,” he said and she shook her head.`}</p>
    <p>{`Dimitri led them through various rooms with different projections and replicas, all the while explaining in full detail of battles throughout the ages. She never realised how much joy it was to watch someone be so serious and passionate about something, that she couldn’t help but secretly smile whenever he told of some backstory, or how he admired the strategist during the battle—lost in his wonderful world.`}</p>
    <p>{`How colourful his world must be.`}</p>
    <p>{`Time flew by and before she knew it, they were in the last room. There were hardly anyone around, except for a couple.`}</p>
    <p>{`“And this… is Areadbhar,” Dimitri said solemnly as they stopped in front of a strange but menacing-looking lance.`}</p>
    <pre>
  ‘Originally wielded by Blaiddyd, one of the Ten Elites who fought in the War
  of Heroes. It was passed down the Royal Family of Faerghus and according to
  various accounts, it was also wielded by King Dimitri against the Empire in
  1186.’
    </pre>
    <p>{`Byleth’s eyes widened and looked between Dimitri and the plaque.`}</p>
    <p>{`“I know what you’re thinking.” He chuckled. “I was named after this King Dimitri. It’s a coincidence, I promise.”`}</p>
    <Ads mdxType="Ads" />
    <p>{`“I see. So, you `}<em parentName="p">{`do`}</em>{` learn new things when you’re in a museum.” She smiled. “It kinda makes sense now, why your name sounded so familiar.”`}</p>
    <p>{`“Well, there are many Blaiddyds in the continent now. It’s not a surprise if I share the same name with a few people.”`}</p>
    <p>{`“I hope I don’t mix you up with your… name-twins, then.”`}</p>
    <p>{`Dimitri chuckled again.`}</p>
    <p>{`“Then—” He stopped suddenly and turned around, and there was a little boy tugging at the edge of his blazer.`}</p>
    <p>{`“H-have… you seen my mama?” The child was on the verge of tears. Dimitri looked around, and Byleth noticed the two people who were in the room with them had gone.`}</p>
    <p>{`Dimitri crouched down and patted the child’s head.`}</p>
    <p>{`“Hello, what’s your name?”`}</p>
    <p>{`“D-di-dimitri,” the little boy spluttered. Byleth was in complete awe at the number of coincidences she was discovering in a single night.`}</p>
    <p>{`“Hello, my name is Dimitri too,” he said, smiling.`}</p>
    <p>{`“I-I-I want my mama.” The boy squatted and promptly started sobbing.`}</p>
    <p>{`Dimitri looked up at her. “Do you think we should stay here until his mother comes, or take him to the reception?”`}</p>
    <p>{`“I didn’t see him when we came in, so he probably wandered in from somewhere else. I think taking him to the reception might be a better idea.”`}</p>
    <p>{`Little Dimitri had fat tears rolling down his plump cheeks now.`}</p>
    <p>{`“Very well. Dimitri, can you walk? I think I know where to find your mother.” The bigger Dimitri said, pulling out a handkerchief to wipe the boy’s eyes.`}</p>
    <p>{`“M-mama.” Little Dimitri called out as he grabbed Big Dimitri’s collar. Byleth looked around again and jogged to the entrance of the previous room to see if there was anyone.`}</p>
    <p>{`“There’s nobody here, I don’t think he could’ve been gone for long from his mother. Come on, let’s get him to the reception.”`}</p>
    <Ads mdxType="Ads" />
    <p>{`Big Dimitri picked up the crying little boy with ease and set him on the side of his hip as if he’d done it a thousand times before.`}</p>
    <p>{`As Byleth walked behind him, something came over her as she watched Dimitri carry the child in his arms. Her heart thumped hard against her ribcage; she felt giddy and had to lay a hand over it to calm herself. She couldn’t help imagining a future where Dimitri was carrying their child so naturally like he just did.`}</p>
    <p>{`“Byleth?” Dimitri’s voice pulled her back to reality and she realised they were ahead of her. She shook her head free of the vision and quickly caught up with them.`}</p>
    <p>{`Being in the final room of the exhibit, they emerged into the reception area and saw a couple speaking frantically at the front desk.`}</p>
    <p>{`“Excuse me,” Dimitri called out, “Is this your child? I believe his name is—”`}</p>
    <p>{`“Mama!” The little boy looked up from Dimitri’s shoulder and wriggled himself out of Dimitri’s arms and ran to the woman.`}</p>
    <p>{`“Oh, Dimitri!” the mother cried and immediately pulled her son into a tight embrace. The father took them both into his arms and kissed the boy’s head before turning to them and thanking them profusely.`}</p>
    <p>{`“It’s nothing,” Dimitri said with a smile. “I’m glad we could help.”`}</p>
    <Divider mdxType="Divider" />
    <p>{`As soon as the parents said their goodbyes, Byleth texted Reus their location. It was already close to 10pm, yet there were still people in the area.`}</p>
    <p>{`“Reus says he’ll meet us at the restaurant we ate in,” Byleth said, keeping her phone away.`}</p>
    <p>{`Silence.`}</p>
    <p>{`“I—” they both said together, then laughed.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Please, you first.” Dimitri gestured to her. Byleth seemed to ponder for a moment before the smallest of smiles curled on her lips.`}</p>
    <p>{`“Shall we walk?”`}</p>
    <p>{`There were many firsts for Dimitri tonight—first date, first time buying roses for a lady, first unofficial museum tour guide. He was glad to add ‘strolling under the moonlit night on a date’ to the list.`}</p>
    <p>{`As they walked side by side, their hands kept brushing and they kept bumping shoulders. It took Dimitri every ounce of power in his body to stop himself from holding her hand, or stare at her too much while they chatted casually.`}</p>
    <p>{`It was quite the achievement, for someone lacking experience with girls. He even managed to hold a few conversations with her without blushing himself to death. Here was something to brag about to Sylvain when this was over so the skirt-chaser would get off his back.`}</p>
    <p>{`As they neared the restaurant, Dimitri led them to a bench to wait for Reus without holding her hand, which disappointed him if he was being honest.`}</p>
    <p>{`“It is a lovely night, is it not?” he said, looking up at the sky. It had been a lovely one for him at least, despite the little credit card hiccup. He was so transfixed by the way Byleth ate during dinner, her laughter, and even the way she quarrelled with Reus was captivating. This made him wonder why he even had doubts about his feelings for her in the first place.`}</p>
    <p>{`What was odd, was supposedly how he felt `}<em parentName="p">{`something`}</em>{` for Reus, evident during the bus ride when they had that little moment. But when they brushed hands on the way to the restaurant, Dimitri felt nothing.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“It’s been a very… eventful one,” Byleth replied, “I’m so glad we helped that little boy. And his name was Dimitri too. That’s just mind blowing.”`}</p>
    <p>{`“I did tell you I’m not the only one named Dimitri.”`}</p>
    <p>{`“You’re right.” She giggled. Then pointed to his shoulder as she gasped. There was a wet spot where the boy had rested his head. “It’s got… snot on it.” She let out a laugh.`}</p>
    <p>{`Dimitri craned his head to check while she took out a packet of tissue from her jacket pocket, and scooted closer to try and wipe it off. He kept his eyes fixed on her as she scrubbed the wet spot but to no avail. The tissue had begun flaking on the fabric. Her expression was neutral except for a tiny wrinkle between her brows. Even that looked adorable. He sighed.`}</p>
    <p>{`“I’m sorry, I seem to have made it worse,” she said, dusting the rolled-up tissue particles off his shoulder.`}</p>
    <p>{`“Please do not worry. It’ll come off. It’s nothing the school’s dry cleaners can’t fix,” Dimitri said casually. When he saw how earnestly she was still trying to correct her mistake, he felt this need to rub the crease off her forehead and worse, the longer she lingered in his personal space, the more he was losing control over himself.`}</p>
    <p>{`“Ah, it still won’t come off—” She looked up at him and he caught her gaze. Her big eyes glanced down at his lips, and he did the same. Every fiber of his being was desperate to pull her in and taste her soft lips.`}</p>
    <p>{`His body started leaning towards her, but dread stopped him when he recalled the promise he made to Reus. His heart danced wildly and the pulse in his ears deafening. He was sure she could hear it too. When she didn’t move and when her lips were slightly parted, the last strand of control Dimitri had left his body.`}</p>
    <p>{`He went for it against his better judgement. Closing his eyes he pressed his lips on hers. He felt her stiffen at first, but then relaxed. Blackberries and vanilla, were the two things that came to his mind. She moved in closer. Time seemed to have come to stand still, as though the universe was blessing them with their own private moment of bliss. That was until he felt his entire body being wrenched from the kiss and a fist made contact with his chin.`}</p>
    <p>{`The next thing Dimitri saw when he opened his eyes was a bouquet of ruined roses on the ground.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      