import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Ironically, I started writing this piece waaaaay before the COVID-19 went viral. And I’ve enforced these habits on myself even harder than before. But wait, lemme tell you what made me develop these habits. I have a family member who smokes and eats at their keyboard (not judging, I snack a lot at my keyboard too.) I was helping them clean their room, they tipped their keyboard over, and um. There was….there was a whole civilization of cigarette ash and snack crumbs + some bugs that fell out. That traumatized me.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`DISCLAIMER: I’m a not medically trained person, nor am I an expert on health. These are my personal habits & practices, what works for me may not work for you. I do not guarantee anything, and I am not responsible for any injuries, illness, damage or accidents should this article be referenced.`}</em></strong></p>
    <Ads mdxType="Ads" />
    <h2>{`1. Sanitize your most frequently used items and surfaces`}</h2>
    <p>{`….Or at least the ones you come into contact the most with. For me, it’s mainly my phone, my Nintendo Switch console, especially the controllers, all PC peripherals (mouse, keyboard(s)), and my desk/table.I mainly use alcohol pads/swabs for smaller surfaces, and regular baby wipes for stuff like my PC monitor. These alcohol pads & baby wipes are quite affordable, here baby wipes come in bundles and in a massive pack too.`}</p>
    <p>{`I normally sanitize a minimum of once a week, or sometimes 2. Another benefit of sanitizing frequently is that it gets rid of the oiliness on your electronics.  A box of single-use alcohol pads lasts me about 4 months, as I normally buy the ones that come in packs of 100 single-use pads. While the baby wipes I buy never seem to run out hahahaha.`}</p>
    <p>{`I put half right next to my TV, and another half on my bedside table. Because I gotta look at it to remember to sanitize hehe. I haven’t tried buying a big bottle of isopropyl alcohol though. I think it might be more economical than single-use pads. And cotton pads are also generally very cheap too. Hey, whaddya know? I found another way to sanitize! Thanks, guys!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`2. Ventilate your gaming area`}</h2>
    <p>{`This actually is my favourite tip, lemme tell you why. So, you know how us gamers are often portrayed playing in a dark, damp, dingy looking room with the only light coming from the screen you’re playing from? I know a few people like this, be it friends or family members. If this is you, that’s okay. I personally prefer playing with sunlight coming in with lots and lots of breeze flowing in the room. One benefit I find is that this helps with motion sickness when I play certain games.`}</p>
    <p>{`BUT. BUUUUUT. If that really is you, you know, the dark game room one, or any type of gamer for that matter, on the rare occasion you do leave your den, leave your door open. Or at least leave a window slightly open. Even if it’s 15 minutes. Try and get some sort of opening to circulate the air in your room. Because all that fart, body odour and probably some airborne germs are going to fester and stick to your furniture. `}<em parentName="p">{`You`}</em>{` can’t smell it, cuz, you know, it’s `}<em parentName="p">{`your`}</em>{` smell, but when someone walks in, `}<strong parentName="p"><em parentName="strong">{`PHEW`}</em></strong>{`.`}</p>
    <p>{`And, I eat + snack wherever it is I’m gaming. The smell lingers. I don’t like using perfume or air fresheners, because all that does is mask the smell, or worst, mixes in with the smell and your favourite scent now smells like some weird garlic, floral ocean scent. Besides, I’m old so some smells give me headaches. Sigh.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Where I live, mosquitos are abundant therefore, all my windows are equipped with nets to keep bugs and pests out. So what I do is, when I leave for work, I leave a window open. That’s what, a good 8-9 hours of air circulation? Ok, let’s be real, I sometimes forget to open the window, but try to let some air in once in a while ok?`}</p>
    <h2>{`3. Keep yourself hydrated at all times`}</h2>
    <p>{`Drinking water is always good, and I find myself with so many problems when I don’t drink enough. I mean, I’m a working adult with back problems and sleep deprivation. I hear it helps with weight loss?? Gives you better skin?? I’m not sure how founded these claims are, but sure. I just know if I don’t drink enough, I get headaches.`}</p>
    <p>{`For the record, I rarely drink soft drinks or sodas. Or coffee for that matter. I’m a water girl. My favourite drink is cold, iced water. And while it’s my favourite beverage, I always forget to drink because…you know…it’s such a hassle to put your controller down and open the cap…and you’re in the moment…`}<em parentName="p">{`you know.`}</em></p>
    <Ads mdxType="Ads" />
    <p>{`I solved this problem for myself by buying a MASSIVE 2-litre bottle with a straw, that is sitting always sitting next to me. Or on me, awkwardly cradled in my arms while I balance a controller/switch console in my hands, sipping on it as I play. Hey, it works ok! Anyway, remember to drink `}<strong parentName="p"><em parentName="strong">{`water`}</em></strong>{` okay, `}<em parentName="p">{`not`}</em>{` sugary juices or sodas. Those are high in sugar, and I spend all my free time sitting or lying down, playing games. All that sugar is going to add to my baby fat that I’ve been trying to lose for years, 13 years to be exact.`}</p>
    <h2>{`Have some more tips`}</h2>
    <p>{`I know this sounds stupid, but don’t forget to shower once a day. When I’m on holiday, I often find myself gaming the entire day, shovelling my meals down, and just as I’m going to bed, “OH CRAP I FORGOT TO SHOWER.” On that note, wash your hands as often as you can. Nothing beats clean, running water.`}</p>
    <p>{`Finally, get some eye drops to refresh your eyeballs and TAKE BREAKS. Annoy your dog, spy on your neighbour, ask your mum how she’s doing, reply to your boyfriend/girlfriend’s text. I hope these tips will help you, especially with the coronavirus these days. I’ve seen some places advising you to avoid public places if you’re sick or suspecting something. Thankfully, us gamers are more than happy to stay home eh?`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      