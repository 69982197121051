import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Blockquote = makeShortcode("Blockquote");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`There’s just so much to love about Honzuki no Gekokujou, or better known as Ascendance of a Bookworm. Season 1 acts as an introduction to the world where Motosu Urano is reincarnated in. We see her struggling to adjust to her new body with Myne’s friends and families. With knowledge from her previous life, she first tried to improve the quality of life for her family and from thereon, everything changed. Business opportunities opened up. Myne began to enjoy her lifestyle here despite not having any books to read.`}</p>
    <p>{`Then season 2 switches from talking about life in downtown to a life in church. Social class comes into play. We see the hardship from the orphans and the hidden side to being a noble. Nobles aren't all talk, you `}<strong parentName="p">{`need`}</strong>{` to act like one too. Being a commoner even back when she was still Motosu Urano prevented Myne from seeing things from the noble’s perspective. She’s mature only due to her life experiences before she died. Now, here’s an entirely new environment she needs to adjust to. Season 2 is such a different take in this isekai world, it renewed my interest in the series as a whole.`}</p>
    <Ads mdxType="Ads" />
    <p>{`So what’s interesting about season 2? Well, it’s all about the `}<em parentName="p">{`life lessons`}</em>{` it is trying to tell the viewers about. I was reeling in the feeling of familiarity whenever Myne applied such `}<strong parentName="p">{`obvious solutions`}</strong>{` to her new `}<em parentName="p">{`workplace.`}</em>{` These solution of hers`}{`*`}<em parentName="p">{` `}{`*`}{`*`}{`are `}</em>{`common sense`}<em parentName="p">{` that no one ever told me about in my first job. It’s something that’s really obvious in life everyone expects you to know when you start being independent. The adults or anyone who knows their salt can explain to you all they want, but you’ll never understand the logic behind it `}<strong parentName="em">{`until`}</strong>{` you `}</em>{`experience`}{`*`}{` it once. So here are 4 lessons that I really wanted to share with everyone.`}</p>
    <p><em parentName="p">{`Note: This post is written after finishing Episode 7.`}</em></p>
    <h2>{`Hard work is rewarded fairly`}</h2>
    <p>{`We see Myne and Lutz parroting this line day in and day out in S2…`}</p>
    <Blockquote mdxType="Blockquote">Those who do not work do not get to eat.</Blockquote>
    <p>{`It’s not a fancy life principle that the commoners living in downtown can `}<em parentName="p">{`choose `}</em>{`to follow but a common sense that they must abide by. Life is tough as a commoner since being poor means you are excluded from many of life’s conveniences. What a noble can pay for is what a commoner must work for.`}</p>
    <Ads mdxType="Ads" />
    <p>{`So when Myne talked about this to the grey-robed attendants, everyone scratched their heads. To these attendants, being picked by nobles to serve them is an `}<em parentName="p">{`honour`}</em>{`. All they need to do is follow everything their masters asked for and they get to eat and sleep happily. So it’s obvious that they do not get the meaning behind the term Myne is telling them again and again. To Fran and the rest, their work as an attendant is not exactly work per se. What they are doing as an attendant is simply seen as `}<em parentName="p">{`“what is expected of them`}</em>{`”.`}</p>
    <Image alt="Lutz and Gil butting heads over getting fed" caption="Gil is demanding that Myne feed him like what is expected of her to do as his master. But Lutz interjected that Gil did nothing to deserve being fed." name="ascendance-of-a-bookworm-season-2-screenshot-1.jpg" mdxType="Image" />
    <p>{`So when Myne insists that they see their current title as a `}<em parentName="p">{`job `}</em>{`instead, I was clapping my hands (in my head). That was really, really smart of Myne. She’s doing this at first since the grey-robed can’t see past Myne’s background as a commoner. So instead of demanding that everyone treat her like a noble, she did what she could `}<strong parentName="p">{`do `}</strong>{`at that point in time - by giving a reward to those who did their job well. Not only is she instilling a sense of `}<strong parentName="p">{`fairness `}</strong>{`among the attendants, she is promoting good work ethics as well. Everyone will do their work properly since they know their effort will be rewarded justly.`}</p>
    <p>{`The second smartest thing that she did to drill this ethic deep down to the orphans is when she gave alms of the gods to Wilma alone `}<em parentName="p">{`in front of everyone.`}</em>{` Alms of the gods is given to all orphans fairly regardless of their contribution to the church. So when Myne only gave Wilma her alms favourably, everyone complained. But it was quickly shut down by Myne by telling them “`}<em parentName="p">{`If you work hard, then even you can get what Wilma did”. `}</em>{`I find this such a great take by Myne since it’s showing two things to everyone working for - `}<strong parentName="p">{`appreciation`}</strong>{` and `}<strong parentName="p">{`acknowledgement`}</strong>{`. While it’s nice to get a big fat cheque for the work you did, showering you with kind words feels even better. And what makes it double better? Praising your hard work in front of everyone :) It’s great for team morale and further instills the sense of `}<em parentName="p">{`fairness `}</em>{`at the workplace.`}</p>
    <Image alt="Myne rewarding Gil with the praise he deserves." caption="All in all, Gil is a troubled boy who just wants attention and acknowledgement. Look past the materialistic rewards and you'll agree that kind words are all it takes for a good reward." name="ascendance-of-a-bookworm-season-2-screenshot-2.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`Respect is not given, it’s earned`}</h2>
    <p>{`It’s not enough that you `}<strong parentName="p">{`have`}</strong>{` a title of a `}<em parentName="p">{`noble,`}</em>{` you need to act like one to earn the respect of everyone below you. It’s the same thing even in real life. Ask yourself this, will you respect your team leader, teacher, manager or anyone who holds the title to `}<em parentName="p">{`lead `}</em>{`if they do not conduct themself as a `}<strong parentName="p">{`leader`}</strong>{`? Myne first ignored her attendants' complaints when they were first assigned to them. She acted like a child (befitting her age, to be honest) and dismissed what is expected of her as a blue-robed apprentice priestess. While both Myne and the attendants are at fault, it ought to be Myne to pull her weight here. After all, she’s their master! She should act and behave as someone deserving of their respect if she wants their loyalty.`}</p>
    <Image alt="Fran, such a cute boi" caption="Fran whistling a different tune the moment Ferdinand walks out of the room. These grey-robed attendants do not look favourably upon Myne's background as a commoner... Usually nobles would scold their attendants for giving such a rude remark like Fran's." name="ascendance-of-a-bookworm-season-2-screenshot-3.jpg" mdxType="Image" />
    <p>{`You can’t demand everyone at work to look up to you from the get-go. You need to `}<strong parentName="p">{`earn`}</strong>{` it. Your subordinates or team members will do what they are expected to, but they’ll never respect you. Heck, they may even gossip about you whenever they can. So act in accordance with what you think a model leader should be!`}</p>
    <Image alt="Gil being a rude boi" caption="Rude. Neither does Myne! But alas, Myne needs to buck up or the status quo will stay that way." name="ascendance-of-a-bookworm-season-2-screenshot-4.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`Kindness is returned tenfold`}</h2>
    <p>{`You know, being kind is somewhat a sore topic for everyone in life. After all, life is honestly more to a give-and-take situation in most cases if I dare simplify it to a sentence. If you want something, then give me something in return. It’s how the economy works, your workplace works and even in social circles at times. So when Myne asked to become the Orphanage Director, she got dragged into a secret room and interrogated to the fullest. Being the Orphanage Director is honestly a liability, perhaps one too heavy for a young girl. Ferdinand asked, “What is in it for you?” to which she answered, “Nothing.”. Honestly, she’s both right and wrong in this case. A kind deed expects nothing in return, but `}<em parentName="p">{`kindness is always repaid by kindness`}</em>{`.`}</p>
    <Image alt="Myne being resolute in her decision." caption="The reading-books-in-peace part is an excuse. Thank you Myne for being such a realistic character I could get behind. Anyone would hesitate to take up the role." name="ascendance-of-a-bookworm-season-2-screenshot-5.jpg" mdxType="Image" />
    <p>{`When you are being kind to someone, you are actually depositing in their `}<em parentName="p">{`emotional bank account.`}</em>{` It’s a concept introduced in 7 Habits of Highly Effective People by Stephen Covey which describes the amount of trust that’s been built up in a relationship. By being kind to someone else, you are building a positive relationship with said person. There’s literally no deficit in being kind. You feel happier and the other party now feels strong goodwill towards you. It’s honestly a win-win.`}</p>
    <p>{`A good example is Myne giving out recipes for her shampoo and even her pound cake recipes to Freida. No one expected for her to give it out for free but she did it anyway. This is her depositing into their emotional bank accounts. In return, everyone helped her out when her Devouring flared up. Benno didn’t even hesitate to help out despite being a business partner only because Myne did so many favours for him.`}</p>
    <Image alt="Myne thanking the children." caption="Here we see the orphans thinking of Myne and harvesting fruits for her. They weren't doing this because they were asked to. You will find that you'll reciprocate kindness with kindness automatically. That's just how it works." name="ascendance-of-a-bookworm-season-2-screenshot-6.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`Communication is key`}</h2>
    <p>{`I find Episode 6 the best in illustrating the importance of communication be it to your friends, family or at your workplace. No one is a mind reader after all. If you don’t voice up, no one will know what your worries are. When Lutz ran away from home, I saw it as both sides (Lutz and his family) making assumptions and never opening their hearts to one another when they needed to. Honestly, both sides are at fault. Lutz tried to tell his family and get their blessings, but he never tried to understand his family’s worries first. On the other hand, his family never openly told Lutz `}<em parentName="p">{`their worries`}</em>{` and where they are coming from, thus causing the great Episode 6 misunderstanding.`}</p>
    <Image alt="Lutz being hard-headed" caption="Stop thinking about yourself for one moment, Lutz. Try to understand where your family is coming from!" name="ascendance-of-a-bookworm-season-2-screenshot-7.jpg" mdxType="Image" />
    <p>{`Myne too, shouldn’t hastily jump to conclusions without listening to both side’s comments. Honestly, Ferdinand is the only mature one here in this episode.`}</p>
    <Image alt="Such a nice guy" caption="Ferdinand abusing his authority to solve his calculator's problems." name="ascendance-of-a-bookworm-season-2-screenshot-8.jpg" mdxType="Image" />
    <p>{`So before you make any assumptions, talk it out. Explain where you are coming from and your worries before making a decision `}<strong parentName="p">{`together`}</strong>{`. Relationship with your friends, families and even your lover is all about making concessions whenever you can.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`All in all`}</h2>
    <p>{`I was re-watching the show while writing this post, and it cements how great this anime is. Sure, anime `}<strong parentName="p">{`should `}</strong>{`be flashy since that’s what we enjoy anime for, but sometimes you just need to take a break and let a slice-of-life anime remind you of life's common sense. If you haven’t watched Ascendance of a Bookworm, please do! If the anime is not to your liking, try the manga instead. I find it slower-paced and explain even more of Myne’s thoughts.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      