import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const Blockquote = makeShortcode("Blockquote");
const RemoteLink = makeShortcode("RemoteLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`BNA (Brand New Animal) is a new anime from Studio Trigger that I’m really excited to watch since it’s unveiling. It has `}<em parentName="p">{`style`}</em>{`, the animation looks charming and screams a unique take on the beastman-versus-human cliche. I `}<strong parentName="p">{`welcome`}</strong>{` the main character’s design (a tanuki beastman(?) without the usual oversexualization), it’s a breath of fresh air. There’s this “`}<em parentName="p">{`cool detective`}</em>{`” vibe coupled with vaporwave aesthetics from its trailer. I personally really love the vaporwave aesthetics I’m seeing from Studio Trigger’s recent work (Promare). So when Netflix released BNA in my region, I jumped at it… and finished the show on the same day. 12 episodes go by really quickly if you enjoy the plot!`}</p>
    <Ads mdxType="Ads" />
    <p>{`BNA talks about Michiru, a recently turned beastman-from-human. Beastmen has long since existed in this world and like every anime tackling racism, beastmen are persecuted by humans simply for being different. Michiru has long since stopped going to school to keep her secret safe, but she decided enough is enough. The first episode shows Michiru making a daring journey to Anima City, a supposedly haven for beastmen to live in a human-centric civilisation. Michiru hopes to discover a cure to her `}<em parentName="p">{`beastman disease`}</em>{` as she calls it in Anima City. Upon arriving in the city, she met a mysterious wolf beastman with monstrous strength and skill. He calls himself Shirou Ogami (which pretty much alludes to him being a white wolf) and he’s fishy. Like, really, really fishy. The name Shirou Ogami is just so `}<em parentName="p">{`obvious`}</em>{` when I first heard it. These two meetings spark the start of the beastmen’s `}<em parentName="p">{`salvation.`}</em></p>
    <Image alt="Shooting scene in BNA Episode 1" caption="This shooting scene happened in Episode 1 and I LOVE IT. The baddies actually changed clips!" name="bna-studio-trigger-3.jpg" mdxType="Image" />
    <p>{`I went into this show fully expecting an angsty take about bridging two races together but nay, the plot `}<strong parentName="p">{`never`}</strong>{` touched on the human’s side of the story `}<em parentName="p">{`at all`}</em>{`. From Episode 1 to 12, the spotlight has never moved away from the beastmen, and for good reasons too. Our heroine Michiru is a spunky young girl who wears her heart on her sleeve. She did whatever she feels like doing right on the dot but it was `}<em parentName="p">{`never highlighted`}</em>{` as an act of immaturity on her part. Michiru is in fact, the `}<strong parentName="p">{`crux`}</strong>{` of this entire show. Beastmen are shown to be `}<em parentName="p">{`pitiful and helpless`}</em>{` against the flow of human and beastmen society. It’s a dog eat dog world in Anima City, and to live is to abide by the flow set by the stronger beastmen.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Each episode (if not progressing Michiru’s main story) highlights a `}<em parentName="p">{`troubling`}</em>{` aspect of beastmen society. It’s a classic strong-eats-weak world in the beastmen society, but the beastmen’s values do not have a place in modern society. Anima City is a safe haven for beastmen created to escape from human’s persecution on paper, but her citizens are troubled, stressed and nowhere to go. Anima City isn’t a haven, it’s a `}<strong parentName="p">{`cage`}</strong>{` for her people. BNA shows the beastmen `}<em parentName="p">{`stuck in a limbo`}</em>{`, unable to hold themselves up with beastmen pride and rejected by human society as outcasts. They can’t fit in with the humans by adopting human values because these two races are simply too different. And neither can they continue their beastmen ways. The era has changed and the beastmen as a collective whole `}<strong parentName="p">{`must progress `}</strong>{`beyond the ways of their ancestors. The answer to the beastmen’s future seems to lie with picking between two sides - to be human or a beastmen instead. Unfortunately, the theme shown in this show is that the situation prevents them from choosing.`}</p>
    <Image alt="BNA ending scene" caption="This show has a ton of vaporwave aesthetics in the opening and ending. Heck, it made the whole anime feels American. They added texture to every surface and background. Expressions are exaggerated and characters in motion looks and feels sharp! It's definitely Trigger's style animation-wise." name="bna-studio-trigger-4.jpg" mdxType="Image" />
    <p>{`This is further subtly hinted with the beastman’s partial transformation. They look like a human in their natural state, but they can transform into their beast form at will. Now for a race that calls themselves `}<em parentName="p">{`beastmen`}</em>{`, that doesn’t seem right. If they hate humans so much, why do they have a human form? This doesn’t make sense plot-wise. The beastmen are stuck in between a rock and a hard place. They need to `}<em parentName="p">{`choose`}</em>{` to `}<strong parentName="p">{`progress`}</strong>{` but they `}<strong parentName="p">{`can’t. `}</strong>{`Lost and stressed, these beastmen are spiralling themselves to doom.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="cock a doodle doo" caption="Who's your alarm clock? Your landlord." name="bna-studio-trigger-1.jpg" mdxType="Image" />
    <p>{`Thus came Michiru and Shirou. Both characters represent polar opposite messages in the show. Shirou is a true-blue beastmen, down to his very soul. He thinks like one and acts like one. Thus, he detests humans simply for `}<em parentName="p">{`being human`}</em>{` and refuses to understand them. He did come around in the end thanks to Michiru. Our tanuki girl, on the other hand, has experience being a human. She was adamant on dismissing beastmen at first, but eventually she came around to seeing beastmen as individuals instead of a collective whole. Both characters represent a different way of living as beastmen but is it wrong for them to pick a side? No, the show’s message is very clear in the last episode (the dialogue between Michiru and Shirou clearly hinted at it) -`}</p>
    <Blockquote mdxType="Blockquote">
  Choose how you wish to live and respect everyone else’s way of life. Come what
  may, there is always a way to accommodate each other's lifestyle.
    </Blockquote>
    <Ads mdxType="Ads" />
    <p>{`I didn’t want to spoil the story but the ending ties up beautifully with the message. Coupled with some strong Studio Trigger aesthetics, it’s a fun ride from the opening to the ending. Both songs are on `}<RemoteLink to="https://open.spotify.com/track/54BW4qpq5ms4bnzBgiWVOo?si=0xRuUE3GRniMg_DkQZ2o2g" mdxType="RemoteLink">{`Spotify`}</RemoteLink>{`!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      