import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Blockquote = makeShortcode("Blockquote");
const RemoteLink = makeShortcode("RemoteLink");
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This is, by far, one of the best impulsive light novel purchase I've done. I tried the manga initially and find myself liking the premise a lot. An uppity princess who saves the day by being... the same as before?! Hell, who'd expect that! Certainly not the heroine herself!`}</p>
    <Blockquote mdxType="Blockquote">I have a good feeling this will get an anime real soon.</Blockquote>
    <p>{`This series is being translated over at J-Novel. As of writing, I believe there are 7 English volumes out in the `}<RemoteLink to="https://j-novel.club/series/tearmoon-empire" mdxType="RemoteLink">{`digital wild`}</RemoteLink>{`, but the physical copies are being printed... really slow. I managed to grab Volume 1 and Volume 2, and I'm about to get myself Volume 3. I'm thankful that Volume 1 and 2 is a nice, completed story arc by itself since that made the wait for Volume 3 less painful. You could easily stop at Volume 2 if you are money conscious. Volume 2 ends the arc nicely! I was told that Volume 3 and onwards is an ongoing plot with a different take. I'm somewhat excited to get the rest of the volumes but here's a quick review and what I think of the first 2 volumes!`}</p>
    <Ads mdxType="Ads" />
    <p>{`Volume 1 and 2 is all about Princess Mia, the sole princess to the Tearmoon Empire trying her very best to change her pitiful guillotine end. In her first life, the Empire is unprepared for a sudden famine throughout its land. The common folk rose up to rebel against the royal family who is clearly more interested in lining up their belly fat (Yup, the royal family loves to eat good). Thus did Princess Mia reached a sad end in her first life.`}</p>
    <p>{`When she woke up in her second life, she discovered that she is back in her 10-year-old body with her memories intact. Lying next to her is a bloody diary that she worked hard to fill at every waking moment in her first life. As she browsed through her diary, she realized that her impending doom was a series of chaining events that `}<strong parentName="p">{`could`}</strong>{` be averted. But a dolt is a dolt. You can't raise IQ points several days after you woke up as a 10-year-old!`}</p>
    <Image name="tearmoon-empire-volume-1-pullout.jpg" caption="Here's my copy's poster art that comes attached with the book. I like it a lot!" alt="Tearmoon Empire Volume 1 poster" mdxType="Image" />
    <p>{`This leaves Mia no choice but to first get other smart folks to help avert each chaining events and hopefully, she gets to live a long life this time. Her hardship in her first life has made her somewhat... mature and experienced. But that's all in her head, seriously. On the outside, she still behaves like a know-it-all young princess. Folks scoff at every word she spouts but wait-... `}<em parentName="p">{`What if she's right?`}</em>{` (Spoiler alert, she is not right at all.)`}</p>
    <p>{`Thus did everyone involved develop a `}<em parentName="p">{`"Princess Mia says a lot of things, but we are just too dumb to catch it all!"`}</em>{`! No, no, my dear side character A... She truly did not know what she is saying! She did not give you the hairpin out of goodness in her heart! Mia did it because she didn't want to let others steal it! It's these never-ending silly misunderstandings that made the book really fun to read.`}</p>
    <p>{`Most of the running gag starts off with Princess Mia trying to sabotage the other person due to her first life's misery. Sometimes, she is just winging it. Her thoughts that led to all of this read off as really petty and hilariously so! Strangely, everyone did not realize her petty jabs but instead, misunderstood every single word that came right out of her mouth. It reached a point where even Mia screamed "WHY?!" inwardly. Lady luck seems to be on Mia's side in this book because everything `}<strong parentName="p">{`ALWAYS`}</strong>{` resolve peacefully and with the best conclusion. Eh, not that she bothers to find out why. Mia always ride the flow which makes it priceless. Uh-huh, she totally planned to do that.`}</p>
    <Ads mdxType="Ads" />
    <p>{`With all said and done, I think the writing is pretty brilliant in itself. The author somehow managed to make all petty misunderstandings work out logically. None of the resulting misunderstandings feel on the nose at all. The happy conclusion from each encounter, however, does feel overblown, but that only lends to the humorous side more. It's silly, and it should be taken so :D`}</p>
    <p>{`I wasn't expecting the story to go full-blast serious as I reached the end of Volume 2. To my surprise, the story provided a solid explanation to why Mia died in her first life. I call it surprising because I was so sure I'm reading about how she stop herself from being dragged to the guillotine, not solve a mystery I didn't know existed!`}</p>
    <Image name="tearmoon-empire-volume-2-pullout.jpg" caption="Here's the one in Volume 2." alt="Tearmoon Empire Volume 2 poster" mdxType="Image" />
    <p>{`It all ties up pretty nicely in the end. At the end of Volume 2, Mia has, in a sense, triumphed over the culprit that caused her death in her first life, gained more allies than expected and successfully secured a future "groom". It's a wonderfully satisfying ending that still manage to give a bit of leeway to wondering what comes next. This is why I thought Volume 2 is a good point to stop. Mia avoided her death flag and is happy with where she is. All in all, a satisfying end for Mia's story.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`All in all`}</h2>
    <p>{`This series has zilch mean characters in it. At least, that's the impression it gives me. While petty is her middle name, Princess Mia is not a terrible princess. She is simply ignorant in many ways which ultimately led to her death in her first life. You could tell she starts off as really mad at the people who have wronged her initially, but the Princess clearly cares in her own way. You could summarize her character as empty in the head, but `}<em parentName="p">{`somewhat`}</em>{` filled at the right place in her heart. Certainly a character that grows on you!`}</p>
    <p>{`The manga totally nailed many of these hilarious misunderstanding scenes, including her thoughts in the matter. Do try the manga if you can! If you enjoyed it, get the light novel's Volume 1 and Volume 2. If you're up for it, get the rest of the volumes! Enjoy Tearmoon Empire!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      