import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`As you know, otome isekai webtoons are getting… `}<em parentName="p">{`varied`}</em>{` in terms of cliches. There’s the time reverse cliche, the villainess cliche, the reincarnated cliche and even a NPC cliche. The most recent one is this `}<em parentName="p">{`“stanning”`}</em>{` cliche where you have someone from our world transported/reincarnated into the world of handsome 2D men. There’s no flag to avoid nor a bad end to change… Our heroine is basically a big fan of the characters in the world so all they do is admire them from afar with much gusto. Perhaps the better word for this cliche is the “simping” cliche. Snort.I honestly really enjoyed the idea here because honestly, that’s so us right? How can you resist not `}<em parentName="p">{`stanning`}</em>{` these characters?! Here are some of my favourite stanning otome isekai webtoons!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Otome Isekai Webtoon Recommendation #1: I became the hero’s mom (남주의 엄마가 되어버렸다)`}</h2>
    <p>{`This is one of those webtoon with a questionable title and cliche, but you quickly changed your mind after reading it. You went from “Goodness gracious, how far have I fallen...” to “Eh, this is not bad at all!”.`}</p>
    <Image alt="I became the hero’s mom novel cover" caption="This is the novel cover. I did thought of adding the webtoon cover here but this is just so sweet..." name="i-became-the-heros-mom-webtoon-recommendation.jpg" mdxType="Image" />
    <p>{`Our MC is a fangirl who loves the OG light novel this story’s fantasy world is based on. She died in an accident while on her way to a fansign event. The next thing she knew, she was reincarnated as one of the cast from the book. Her new name is now Ayesha Escliffe, the only daughter of the noble Escliffe family. She wasn’t sure what to do as Ayesha in this world… But the moment she noticed that this world is THAT Persona light novel she loved, she went gaga. After all, her favourite character Abel is 7 years old! She gets to see the kid version of her favourite character <3 Thus her journey as a fangirl starts all over again… by watching over Abel from afar.`}</p>
    <p>{`At least, that’s how it is supposed to go. Her attempts to send gifts to Abel anonymously were foiled and she was interrogated by Abel’s father, Duke Hades. Like, who on earth sends a ton of gifts to a Duke’s son without a word? Lady, you’re acting like a stalker. She thought she’s a goner for sure when she got caught but for some reasons, Duke Hades proposed to her there and then. Wait, what? :O`}</p>
    <p>{`The art’s pretty good and Abel is a dear. Hades actually misunderstood Ayesha all the way because he too, is as pure as his adopted son. Ayesha is a newbie in the romance department so you watch all three stumble around to become a happy family. Good stuff all in all.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Otome Isekai Webtoon Recommendation #2: I stan the prince (황자님께 입덕합니다)`}</h2>
    <p>{`Another series that got my sides all knotted up. There’s no reincarnation, no nothing in this story which is kinda not answering this blog post’s title. But there’s stanning! And it’s definitely otome! It just lacks the part that makes it isekai!`}</p>
    <Image alt="I stan the prince webtoon cover" caption="From the left, Angela and the prince she stans for (HAHA)" name="i-stan-the-prince-webtoon-recommendation.jpg" mdxType="Image" />
    <p>{`Our MC is Angela, a fanfic writer. Oh, she loves to write fanfic of her delusions about the dethroned Prince Rayburn, a prince no one loves. He’s hot, he’s nice and he has a BL-ish scene with his aide according to Angela. At first, she writes only for her own enjoyment but silly Angela shared her fanfic with a merchant! Any merchant would jump at the chance to make money so he took her fanfic draft and published it (LMAO). Angela didn’t know about this at all. She only found out when someone praised her mastahpiece right in front of her. Imagine your hot delusions getting published, you’ll be embarrassed too. But the fanfic took the country by storm. It actually changed the public’s perception of Prince Rayburn into a favourable one. Of course, Prince Rayburn hates it and the Chancellor hates it even more.`}</p>
    <p>{`Upon seeing how a book changed the public’s view so easily, Rayburn’s sister Hoya took her in and ordered her to write a sequel. Just like that, the fanfic writer is now next to her object of stanning. What will happen next, I wonder… Oh, there’s definitely romance brewing! How else would a village girl come to know the prince? A standard romance plot right there ;PThe way they drew the eyes is really unique actually. There’s not much sparkles and glitter you expect from an otome webtoon. The art is really grounded in a sense. But the dialogue stemming from her `}<em parentName="p">{`stanning`}</em>{` thoughts are hilarious XD Good read all in all.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Otome Isekai Webtoon Recommendation #3: When the Villainess loves (악녀가 사랑할 때)`}</h2>
    <p>{`I think this is one of the popular ones everyone has heard of. Art is really nice and most readers enjoy eye candy a lot, which kinda explains the popularity.`}</p>
    <Image alt="PRINCESS LIBERTIA FROM WHEN THE VILLAINESS LOVES NOVEL COVER" caption="Once again, I have opted to add the novel's cover instead. Why, nabe-chan, why? I mean... LOOK AT OUR GIRL PRINCESS LIBERTIA. I love that the webtoon followed her hairstyle on this novel's cover! She's pretty here too. The webtoon art is good, trust me on this." name="when-the-villainess-loves-webtoon-recommendation.jpg" mdxType="Image" />
    <p>{`Our MC died while reading her favourite light novel which features a possessive prince who would kill those around the female lead in the name of love. This led to our MC preferring the villainess, Princess Libertia over the female lead. So when she died and was reincarnated into the body of Princess Libertia, she went AAAAA. In the story, Princess Libertia is the big baddie no one could love due to her family’s antagonistic position against the royal family. She’s an ice cold witch who would harass anyone because she hated them. But it turns out there’s more to her… Princess Libertia is `}<strong parentName="p">{`ill`}</strong>{` from an incurable sickness called Raphaelis. This disease attacks the soul directly. In the story, Princess Libertia is dying anyway so she decided to act like the villainess everyone expects her to.`}</p>
    <p>{`So where does our Korean fangirl fit in this puzzle? Well, to cure this illness, all you need to do is swap out the soul of the body. This is why our fangirl gets to be Libertia in her stead. As Libertia, her Raphaelis is technically cured but she’ll keep vomiting blood every 3 days for a year. So all our fangirl needs to do is hold on for a year and after that, she’s free to enjoy her life! Unfortunately, that’s not what the whole palace thinks. They misunderstood her actions and thought she had a change of heart because they found out about her terminal illness. Snort snort. Nuh uh, this girl is totally cured! Besides, she just wants to be a fangirl! Since she knew about everyone’s doomed fate, she decided to interfere as Princess Libertia so everyone in the story gets a happy end. Afterwards, she’ll bolt off from the castle to live a happy life on her own. The plan is perfect but you know how these stories go <3`}</p>
    <p>{`I love her dresses. It’s puffy and really sweet! As a fangirl, her thoughts are so relatable. Bewb window everyone. Bewb window. I’m ending the summary here. You’ll appreciate the "unique" windows soon enough.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`All in all`}</h2>
    <p>{`I have a feeling more `}<em parentName="p">{`stanning `}</em>{`series will pop up now that the writers noticed that we love these cliches. I think it’s easier to write a story which you have experience in. Everyone has experience in obsessing over a character, right? XD I hope you enjoy these three otome isekai webtoon recommendations!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      