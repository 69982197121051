import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Blockquote = makeShortcode("Blockquote");
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const RemoteLink = makeShortcode("RemoteLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`When K.K. Slider came to my island to sing the theme song, I rejoiced. Yes! I got the Island Designer App! I can now remodel my island to whatever I want it to be. A gym? Hell yea! A maze? Why not! As ideas flitted around in my head, the credits ended.`}</p>
    <p>{`Tom Nook congratulated me and told me to do whatever I want to make myself happy with my island. Yes, thank you raccoon overlord. My character then stood in the middle of my island awaiting orders. Then it struck me.`}</p>
    <Blockquote mdxType="Blockquote">Darnit... What do I do next?</Blockquote>
    <p>{`I’ve been following along to fulfill Tom Nook’s request so my goals have always been in sight. Right now, I have none. The goals are now set by me and me alone. I’m not a creative person and without any requirements, I simply can’t think of any good ideas. I enjoy working along a preset requirements and making the most of it! But now, Animal Crossing has essentially given me a brush and an empty canvas, then told me I am the master of my own canvas. There’s absolutely no set rules, no nothing. Just me and my brush. I speak as though I’m complaining but honestly, this is by far the biggest and happiest conundrum this game has given me.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Since I have no idea on how to decorate my island, I thought let’s check out the creative masters from Twitter. I’ve seen a ton of great ideas from Twitter! An obstacle race, a shopping district, and a Japanese busy street? Wow, I’m floored at everyone’s ideas and I wanted to incorporate them all onto my island. But there’s only so much your island can fit. Besides, you need to add bell-making/compendium-filling areas as well to support your furniture splurges and fill up your museum. I’m talking about fruit trees, breeding rare flowers for rare bugs, etc. So in a way, you’ll need to make sure to craft these dedicated areas to cover all bases:`}</p>
    <Image alt="Animal Crossing New Horizons tree stump field" caption="A field of tree stumps!" name="animal-crossing-new-horizons-tree-stump-field.jpg" mdxType="Image" />
    <ul>
      <li parentName="ul">{`An area with nothing but tree stumps. Jewel Beetles are a good source of bells!`}</li>
      <li parentName="ul">{`Flower breeding! You’ll need rare hybrid flower colours to unlock rare bugs that sell for a ton of bells. These flowers also look quite nice.`}</li>
      <li parentName="ul">{`Fruit trees. E.g. apple, pear, orange, peach. They go for a lot and should be the bulk of your passive bell-making income.`}</li>
    </ul>
    <Image alt="Animal Crossing: New Horizons orchard on my island" caption="My orchard! I made sure to squeeze these trees into a tight area for optimal arrangement. Just make sure you leave 1 box of space between these trees so the fruit can drop and you can walk in-between these trees." name="animal-crossing-new-horizons-orchard.jpg" mdxType="Image" />
    <p>{`Phew. That’s a lot of areas. But let’s not forget about these bodies of water too while you design your island:`}</p>
    <ul>
      <li parentName="ul">{`A river. Animal Crossing considers your bodies of water as a river if it is long in shape and the water flows. The easiest to make one is to connect a long body of water to a waterfall.`}</li>
      <li parentName="ul">{`Mouth of a river. Some rare fish breeds are here too!`}</li>
      <li parentName="ul">{`A pond. The water here must be still.`}</li>
    </ul>
    <Ads mdxType="Ads" />
    <p>{`You need these rivers and ponds because as the seasons change, the fishes you can get are different too! I’m sure you would want to complete your compendium as soon as possible. I left out the beach since you can’t remodel your beach. All you can do to the beach area is add custom design on the ground and add furniture to it.`}</p>
    <p>{`Now that we are done with listing of the essential areas, next is about grid spacing. You must have noticed by now, Animal Crossing’s spaces are essentially boxes. Each dropped item takes up 1 box. You can move your furniture by half a box. You can “sidestep” into an adjacent tight space too, but only if there’s half a box of space.`}</p>
    <Image alt="Animal Crossing: New Horizons sidestepping" caption="If you need to sidestep on your island, I call it a bad arrangement. Your furniture should be arranged in a way that lets you slide in and out easily. If you sidestep, then it means your space is cramped." name="animal-crossing-new-horizons-side-step-geeknabe-blog.jpg" mdxType="Image" />
    <p>{`I didn’t like the idea of remodeling on the go, so I made sure to jot down and listed all of the game’s restrictions before I start demolishing cliffs and rivers. There’s so much to take in, I find myself just overwhelmed with the possibilities. I wanted to design many things, but there’s simply too many equally good options out there! Imagine having choice A and B to choose from, but both are almost the same. This is a problem I encounter when given a web page to design too. There’s so many options, I didn’t know where to begin. It was seriously overwhelming! But I told myself this isn’t how I should be thinking about this problem.`}</p>
    <p>{`Knowing that I almost went back to the same bad habit I have when I first started out as a beginner coder, I took a deep breath.`}<em parentName="p">{` I shouldn’t be putting all my chips on the whole island all in right from the get-go.`}</em>{` You don’t expect to tackle a huge island right from the start!`}</p>
    <Ads mdxType="Ads" />
    <p>{`When given a large problem to solve, we developers take it step by step. You look at the big picture wholly, then split them into smaller problems to solve. You then start off from the bottom. Solve the smaller problems one by one and eventually, you defeat the big bad problem. It’s the same methodology many large software platforms (and how engineers make a big rocket or car) take - split them up and solve it one by one!`}</p>
    <p>{`So I started looking at this island of mine as a huge problem that I wish to tackle from an overall perspective. I first thought of the requirements that would soon form the basis of my island. My first and foremost requirement is that it should be `}<em parentName="p">{`designed`}</em>{` in a way that I can easily change things up `}<strong parentName="p">{`anytime`}</strong>{` I want. Animal Crossing is a game that has so much furniture, I’m sure inspiration will strike every now and then.`}</p>
    <p>{`Then other requirements soon well up naturally following my line of thought. Walking around the island shouldn’t require tools either! Your island should be as accessible as possible. It’s why the game gives you bridges and inclines. There should be open areas to let the stones and weeds to spawn. Generally speaking I shouldn’t over-decorate the beaches so I can fish and dig up clams happily. I’ll leave the pier and the secret beach alone so I can easily access it at any time.`}</p>
    <Image alt="Animal Crossing: New Horizon secret beach" caption="I didn't want to pull out my ladder to get to the secret beach..." name="animal-crossing-new-horizons-secret-beach-incline.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <p>{`Now that I have a good idea on `}<strong parentName="p">{`what I want`}</strong>{` and `}<strong parentName="p">{`should have`}</strong>{` on my island, here comes the next problem - `}<strong parentName="p">{`how`}</strong>{` should I incorporate all of these requirements and ideas? I knew I needed to tackle them one by one, but how?! I could slowly build an area one by one and join them up using pathways but I didn’t like the idea because I knew, this is just asking for trouble if I wanted to remodel my island again. Am I going to dig up my flowers again just to relocate them? Demolish cliffs and rivers again? It’s a lot of work so no thank you!`}</p>
    <Image alt="Animal Crossing: New Horizons flower field" caption="This is a screenshot of my friend's island. I dread the work needed to dig these flowers up and relocate them. That's a whole load of work!" name="animal-crossing-new-horizons-flower-field.jpg" mdxType="Image" />
    <Image alt="I offered to help her out but maybe I shouldn't..." caption="My friend's island again. She mentioned that there's no space left in her home so she placed them alllllll outside. Can you imagine the effort needed to clean this place up? (lol)" name="animal-crossing-new-horizons-messy-island.jpg" mdxType="Image" />
    <p>{`I’ve seen many islands by my friends just consisting of small areas and a ton of pathways throughout the island. It’s an idea I entertained but it didn’t really answer my first and foremost requirement - `}<em parentName="p">{`it should be designed in a way that makes it easy to change things up.`}</em>{` I am not fond of thinking of an island as a whole because I have a ton of ideas I want to implement. Besides, I am simply not super good at thinking in a big picture! I work better if I’m given a smaller area to focus on. Then an idea occurred to me. In that case, let’s just split the island into small areas that I can change up anytime! I’m a web developer, so I’m good (the only thing I’m good at too) at thinking in boxes. Let’s just segregate areas that I can decorate or modify into boxes then!`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Animal Crossing: New Horizons island area segregation idea" caption="An empty area with pathways laid down like a plaza! I knew I'll have an idea to decorate this spot soon, so I'm just going to leave it empty for now." name="animal-crossing-new-horizons-empty-area.jpg" mdxType="Image" />
    <p>{`I first modelled the museum and my house area to be their own area on their own. I know it’s not viable to keep changing these two areas so they both get a dedicated area on their own. The heart of my island is after all, the easy and ever-changing grid-like areas. I flattened 70% of my island to do this, and began using pathways to make a plaza-like area. The plaza-like areas I laid down denotes that this is an area of its own. It’s just a state of mind but it gives me a strong impression of a “boundary” or an “area”. I could do whatever I want within this box! A gym, a flower field, or even a restaurant! Anything is possible within this small box-like area. Since it’s a small area, I could quickly demolish the place and make a new one if I want to.`}</p>
    <p>{`The next problem I wish to solve is making the whole island accessible without the need of tools. I didn’t enjoy pulling out the ladder tool or a vaulting pole just to get across, so I made sure to place inclines wherever possible. Rivers too, do not need a bridge if properly designed. With the inclines and bridges out of the way, the other problem when it comes to accessibility is the way the furniture and buildings are placed on the grid-like area.`}</p>
    <p>{`Isabelle rates your island higher if you place a ton of furniture and fencing around your island, so I need to make sure to do that. At the same time, I need to place them slightly further apart to allow footpaths. So every furniture is placed at least 1 box away from each other to prevent my character needing to sidestep in. I find the whole experience to be much more happier if I do not need to sidestep to get past my furniture. As for the fencing, I didn’t place them in a way that blocks my path. I place my fencing at corners that I rarely walk into, or at areas that I rarely use.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Animal Crossing: New Horizons Nook's Cranny cafe area." caption="I make sure to place my buildings either to the side or at the center for easy pathways. Then there's more space for more furniture! I call this my little cafe area." name="animal-crossing-new-horizons-cafe-area.jpg" mdxType="Image" />
    <p>{`I use the grid-like area as a way to place my buildings too. If I plan to put two villager houses in a box, I put them bordering the corners so I could have space to walk between them. The idea here is to make sure these buildings are not blocking one another, and is easy to zig zag past these buildings.`}</p>
    <p>{`And that’s it. That’s how I make a sustainable, accessible and easy to maintain island. I can change it up anytime, and I don’t feel bad doing it either. Since Animal Crossing is all about enjoying yourself, remodeling your island shouldn’t feel like a chore. Sure, you could remodel a big part of the island for aesthetics purposes. After all, it’s your island! The hard work you put in would definitely pay off after seeing your masterpiece at hand. But what if you need to remodel it again? I `}<strong parentName="p">{`honestly`}</strong>{` would love doing a big renovation just to dedicate a huge area for a theme but I `}<em parentName="p">{`dread`}</em>{` the work needed. The current grid layout fits my play style just fine (for now).`}</p>
    <Image alt="Grid City in Hotpot island. Yes, my island is called Hotpot." caption="Welcome to Grid City where there's no pathways, just grid-like areas." name="animal-crossing-new-horizons-grid-city.jpg" mdxType="Image" />
    <Image alt="Hotpot island map" caption="Island map!" name="animal-crossing-new-horizons-hotpot-island-layout-geeknabe-blog.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <p>{`Regardless, I’m sure there are many players that feel overwhelmed with the size of this canvas. My advice is this - don’t think of your island as a whole big piece of puzzle you need to solve wholly. Handle it piece by piece. Think small, `}<em parentName="p">{`be dumb.`}</em>{` It’s a favourite saying of mine when I have to handle a huge project as well as a software design principle - `}<RemoteLink to="https://en.wikipedia.org/wiki/KISS_principle" mdxType="RemoteLink">{`keep it simple, stupid`}</RemoteLink>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      