import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const RemoteLink = makeShortcode("RemoteLink");
const Image = makeShortcode("Image");
const Ads = makeShortcode("Ads");
const Blockquote = makeShortcode("Blockquote");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Guess who has a digital store on `}<RemoteLink to="https://shopee.com.my/knowia92" mdxType="RemoteLink">{`Shopee Malaysia`}</RemoteLink>{`? Yup, me! After years of collecting merch, I think it's time for me to let go of them. I named it nabe-chan's Repository because everything in here is mostly preloved merch. You know... Like from my storage/collection? I may even sell non-anime products.`}</p>
    <Image alt="geeknabe Shopee store" caption="It's a humble store. I probably should decorate it more..." name="geeknabe-shopee-store.png" mdxType="Image" />
    <p>{`Merch that I may have on my Shopee store, I'm still in the process of putting them up:`}</p>
    <ol>
      <li parentName="ol">{`Clear files`}</li>
      <li parentName="ol">{`Shikishi boards`}</li>
      <li parentName="ol">{`Small pouches`}</li>
      <li parentName="ol">{`Unopened Nintendo accessories`}</li>
      <li parentName="ol">{`Plushies`}</li>
      <li parentName="ol">{`Stationeries like notebooks and whatnot`}</li>
      <li parentName="ol">{`English light novels`}</li>
      <li parentName="ol">{`Keychains`}</li>
    </ol>
    <Ads mdxType="Ads" />
    <p>{`These are the by-products of me seeing something cool on the shelf and never thinking twice before buying them. I have more stuff that I have not listed yet so please look forward to it!`}</p>
    <p>{`Note: I offer free shipping for certain products.`}</p>
    <p>{`I wrote this blog post in hopes that someone found my store but wasn't sure if the things I sell is legit. Don't worry because everything in my possession are all legit anime merch. I wouldn't put up battered goods for sale either.`}</p>
    <Blockquote mdxType="Blockquote">Let my impulsive purchase be your lucky purchase :D</Blockquote>
    <p>{`Thank you for visiting `}<RemoteLink to="https://shopee.com.my/knowia92" mdxType="RemoteLink">{`my store`}</RemoteLink>{`!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      