import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../../../styles/globals';
import NormalCircle from './NormalCircle';
import StartingCircle from './StartingCircle';
import LastCircle from './LastCircle';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: stretch;

  ${$.screen.desktop} {
    width: 15%;
  }

  ${$.screen.tablet} {
    width: 25%;
  }

  ${$.screen.mobile} {
    width: 20%;
  }
`;

const DateContainer = styled.div`
  font-family: Rubik Regular;
  color: ${$.color.lightblack};
  letter-spacing: 1px;
  text-align: right;
  text-transform: uppercase;
  width: 70%;
  ${$.ellipsis}

  ${$.screen.desktop} {
    font-size: 19px;
    br {
      display: none;
    }
  }

  ${$.screen.tablet} {
    font-size: 18px;
    br {
      display: none;
    }
  }

  ${$.screen.mobile} {
    font-size: 16px;
    margin-right: ${$.layout.margin1}px;
  }
`;

const CircleContainer = styled.div`
  display: flex;
  width: 30%;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
`;

const Line = styled.div`
  min-height: 30px;
  width: 2px;
  flex-grow: 2;
  background-color: ${$.brown4};
`;

const DotContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 0;
  transition: height 0.3s ease;
  &.active {
    height: 30px;
  }
`;

const Dot = styled.div`
  width: 2px;
  height: 8px;
  background-color: ${$.brown4};
  margin-bottom: ${$.layout.margin1}px;
`;

const Timeline = ({ first, last, date, showContinuation }) => {
  const strDate = (() => {
    const currDate = date.split('/');
    const months = [
      '',
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const day = currDate[0];
    const month = months[currDate[1]];
    const year = currDate[2];

    return {
      html: `${day} <br />${month} <br />${year}`,
      text: `${day} ${month} ${year}`,
    };
  })();

  let circles = (
    <>
      <NormalCircle />
      <Line />
      <DotContainer className={showContinuation === true ? 'active' : ''}>
        <Dot />
        <Dot />
        <Dot />
      </DotContainer>
    </>
  );

  if (first === true) {
    circles = (
      <>
        <StartingCircle />
        <Line />
        <DotContainer className={showContinuation === true ? 'active' : ''}>
          <Dot />
          <Dot />
          <Dot />
        </DotContainer>
      </>
    );
  }

  if (last === true) {
    circles = <LastCircle />;
  }

  return (
    <Container first={first} last={last}>
      <DateContainer title={strDate.text}>
        <span dangerouslySetInnerHTML={{ __html: strDate.html }} />
      </DateContainer>
      <CircleContainer>{circles}</CircleContainer>
    </Container>
  );
};

Timeline.defaultProps = {
  first: false,
  last: false,
  date: '1/1/1970',
  showContinuation: false,
};

Timeline.propTypes = {
  first: PropTypes.bool,
  last: PropTypes.bool,
  showContinuation: PropTypes.bool,
  date: PropTypes.string,
};

export default Timeline;
