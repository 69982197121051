/* eslint-disable */

import React from 'react';

import './src/assets/css/reset.css';
import './src/assets/css/fonts.css';
import './src/styles/globals.css';

import smoothscroll from 'smoothscroll-polyfill';
import 'prismjs';
import 'prismjs/themes/prism-okaidia.css';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-css';
import 'prismjs/components/prism-bash';

import Root from './src/components/Root';
import Layout from './src/components/Layout';

// adds smooth scrolling
smoothscroll.polyfill();

// wraps layout at every page without the need to rerender.
const wrapPageElement = ({ props, element }) => {
  const getLocation = () => {
    const pathname = props.location.pathname;

    return pathname.replace(/\//g, '');
  };

  return <Layout location={getLocation()}>{element}</Layout>;
};

const onRouteUpdate = () => {
  /**
   * Updates page view.
   */
  const customEvent = new CustomEvent('routeChanged');

  window.dispatchEvent(customEvent);

  const updateCollection =
    process &&
    process.env &&
    process.env.NODE_ENV === 'development' &&
    window.location.pathname === '/';

  if (updateCollection) {
    // update the blogpost-collection.json with all blog links
  }
};

const wrapRootElement = Root;

export { wrapPageElement, onRouteUpdate, wrapRootElement };
