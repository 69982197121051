import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const RemoteLink = makeShortcode("RemoteLink");
const Ads = makeShortcode("Ads");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The manga portrayed Myne as a little older and serious in her demeanor. As a matter of fact, the entire manga series was much more upfront and serious on the whole slice-of-life of an average commoner. The anime on the other hand, was a total 180 degree change. Eyes are drawn bigger and childish, and the adults' anatomy reminds me a lot of a kid's show. The mood was really light. I half-expected it to be a little more sombre but I'm totally good with this take too.`}</p>
    <p>{`Note: I watched Episode 1 to 3, followed up to the latest manga scanlated chapter and read a decent chunk of the fan-translated light novel. Images are sourced from `}<RemoteLink to="http://booklove-anime.jp/" mdxType="RemoteLink">{`Ascendance of a Bookworm (Honzuki no Gekokujou: Shisho ni Naru Tame ni wa Shudan wo Erandeiraremasen) official website`}</RemoteLink>{`.`}</p>
    <Ads mdxType="Ads" />
    <p>{`The first episode was a little lackluster and had a ton of static scenes. The manga was pretty rad even in the first few chapters so I was pretty disappointed if this was what the best they can do. The ocassional cut to chibi Myne doing her inner monologue and the chibi ending had me convinced - this show wasn't going to take itself seriously, and they want you to do the same. It's a super chill show!`}</p>
    <p>{`There were some hiccups here and there in terms of animation. One of them being that they pour more effort animating the leaf furling and the Myne's family walking in the opening `}<strong>{`than`}</strong>{` the episode itself. There's barely any movement other than the mouth flaps. Even the chibi scenes were a lot more expressive and probably had more frames.`}</p>
    <p>{`The first episode's pacing and choice of cuts were a little weird as well. The errors were so glaring... There were even some scenes that really felt out of place, such as when Myne was digging for books at her home. The background art changed from having nothing on the floor to a ton of stuff poured on the floor. Blink and you might miss this. I'm honestly okay with it since I love the original plot but these errors just make me sad that they are treating this anime like this...`}</p>
    <Ads mdxType="Ads" />
    <p>{`There are also scenes were they show Myne gawking at the place after she first opened the door. She was drawn pretty badly here with those soulless eyes just staring straight ahead. They even reused the same Myne when showing her gawking in another room! Fortunately, these problems are only found in the first episode. The story picked up a lot faster in the next one, and the characters were a lot more expressive.`}</p>
    <p>{`I was about halfway of episode 2 when I realize how hooked I was. Myne had the cutest reaction to everything. Usually isekai-ed protagonist will have accepted their fate in the first 5 minutes of episode 1, but Myne was still battling her inner fears by the third episode. Her body is small and frail so she struggles to do even the smallest task.`}</p>
    <p>{`Life as a commoner is harsh to someone from the modern era. Watching her living an honest life with her family was the raison d'être of this show!`}</p>
    <Ads mdxType="Ads" />
    <p>{`Unfortunately, the animation isn't anything special. They clearly prioritised the chibi Myne scenes and only did the bare minimum for the episode itself. Personally speaking, I think that is a fair exchange for a slice-of-life show. Honestly, all they need to do is inject more fluff and soff scenes for this show to succeed <3 Continue the current's plot quirkiness and pacing after episode 2 and you have a winner <3`}</p>
    <p>{`The manga has already captured my heart, and the anime baited me hard. I enjoyed it a lot. The pacing and its vibe reminded me a lot of `}<RemoteLink to="https://myanimelist.net/anime/251/Kyou_kara_Maou" mdxType="RemoteLink">{`Kyo Kara Maoh`}</RemoteLink>{`. It's one of those rare shows that is better as a marathon once all the episodes are out. I'll definitely binge this series once all the episodes are out.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      