import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const LocalLink = makeShortcode("LocalLink");
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const RemoteLink = makeShortcode("RemoteLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This is a continuation from the previous blog post I wrote. Weeks ago, I wrote about the first 5 ongoing webtoons that I really enjoyed (insert post). It got a bit lengthy so I chopped it up. Here’s Part 2! Here's the previous `}<LocalLink to="/blog/great-ongoing-webtoons-that-you-should-read-part-1/" mdxType="LocalLink">{`Part 1`}</LocalLink>{`! This list is not ordered.`}</p>
    <h2>{`Great Ongoing Webtoon Recommendation #1: Let’s Play`}</h2>
    <p>{`Samara “Sam” Young is a young indie game developer working in the same company headed by her doting father, Young Technologies. She knows the ins and outs of her job well, and she loves it. However, she’s not exactly leader material since she prefers 1s and 0s to a managerial position. She’s shy, doesn’t think highly of herself and always puts herself down. Plus, Sam has social anxiety. She dresses up really conservatively and blushes at the mention of her love life. Her life changed directions when the free-to-play PC game she created, `}<em parentName="p">{`Ruminate`}</em>{` earned a scathing review from a popular streamer named Marshall Law. His fans proceed to `}<em parentName="p">{`review bomb`}</em>{` her game on the platform it was hosted, thus reducing her dream to make it as a game developer down to zero.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="LETSU PURRAY banner in WEBTOONS site" caption="Let's Play banner in WEBTOONS official site." name="geeknabe-webtoon-recommendation-lets-play-banner.jpg" mdxType="Image" />
    <p>{`She did all she could to appeal to the platform to remove those bad reviews since it is not fair to give a bad review when clearly, these commenters have never played the game. But the damage has been done. While she mulls on how to salvage her reputation, the very same streamer moved in next to her apartment.`}</p>
    <p>{`Alright so first of all, I like this webtoon because I see myself in Sam. I have no confidence in my looks and my fashion sense, so I dress up like a pauper most of the time. Folks have been appealing to me to take care of myself better by dressing up but in most cases, `}<em parentName="p">{`I don’t know how.`}</em>{` I enjoy games, comics and anime a lot to the point that I can’t relate to my friends. They were talking about kids and getting married while I’m talking about webtoons here! And I definitely don’t enjoy watchful eyes on me as I present my project. I know I had to do it because it’s work, but it still gives me the creeps. Thoughts like “`}<em parentName="p">{`what if I’m not good enough?”`}</em>{` or “`}<em parentName="p">{`did I explain it correctly?`}</em>{`”often streak past whenever I do small talks with fellow developers. These thoughts plague me less now since I know I'm better at my job now, but it's a past that still haunts me.`}</p>
    <Image alt="He thinking hard." caption="On the left is JQuery code, and on the right is PHP code. Nice, both are web-related programming languages. I pause a long time too if you ask me a tech related question. It's more like thinking how the whole program flows followed by how best to answer you in the simplest way." name="geeknabe-webtoon-recommendation-lets-play-panel-1.jpg" mdxType="Image" />
    <p>{`This webtoon really knows the software development industry well. So far, every explanation or depiction on the tech stack mentioned is correct, including how the `}<RemoteLink to="https://www.theatlantic.com/magazine/archive/2017/04/why-is-silicon-valley-so-awful-to-women/517788/" mdxType="RemoteLink">{`male-centric industry views their female co-workers`}</RemoteLink>{`. I really like how the author depicted how women are seen in the software industry because it’s true. We see Sam who clearly knows her job well and is more than qualified to take up a role in the dev team. Instead, she was given data entry tasks instead.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="SPEAK TO THE HAND" caption="This is how my superiors deflect any questions, requests or suggestions as well. 'Let's discuss this in a meeting.' or 'Let's discuss this later.' Urghhh, I get it Charles." name="geeknabe-webtoon-recommendation-lets-play-panel-2.jpg" mdxType="Image" />
    <p>{`That’s crazy because you are asking a `}<strong parentName="p">{`full-fledged software developer`}</strong>{` to work on `}<em parentName="p">{`data entry`}</em>{`? It’s like hiring a CEO to make coffee! This irked me to no end because I could see this happening in many, many places. So when Sam finally got a chance to work on a much complex task, I did a `}<em parentName="p">{`WOOHOO`}</em>{` out loud (in my head). It’s a lovely read for me since I keep seeing many jargons and thought processes I’m familiar with. But all in all, it’s not a webtoon focusing on software development! At its core, it’s about seeing Sam growing up as an individual. The author has a way of making me, the reader, feel super proud watching Sam move out of her comfort zone when she needs to. Sometimes work demands a lot out of us but when you gotta do it, you just gotta do it. Great work embracing your fears Sam. I talked about software development and Sam’s character but `}<RemoteLink to="https://www.webtoons.com/en/romance/letsplay/list?title_no=1218" mdxType="RemoteLink">{`Let's Play`}</RemoteLink>{` is still a webtoon on romance and exploring mental health. Spoiler alert, it depicted depression pretty well and`}<RemoteLink to="https://en.wikipedia.org/wiki/Takotsubo_cardiomyopathy" mdxType="RemoteLink">{` takotsubo`}</RemoteLink>{` is a real heart disease.`}</p>
    <h2>{`Great Ongoing Webtoon Recommendation #2: Mage & Demon Queen`}</h2>
    <p>{`I don’t think I have ever read such a wholesome, fluffy, gay read.. `}<strong parentName="p">{`ever.`}</strong>{` I love it to the point that I read this webtoon first on a Thursday morning before I get my coffee. In a world where magic and demons exist, our heroine Malori is on a grand quest… She climbs a deadly tower and at its top, meets the Demon Queen Velverossa. Both poised for battle, they began shooting deadly magics at each other! But Malori is just an adventurer student. She may be strong but only amongst her classmates. The demon queen scoffed at her skills. Malori is nowhere strong enough to beat the demon queen alone. Sensing her defeat at hand, Malori shouted her wholehearted declaration towards Velverosa at the top of her lungs.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Achievement unlocked! Malori proposed to Demon Queen Velverosa!" caption="While adventurers try to down the Demon Queen, Malori proposed to her???!" name="geeknabe-webtoon-recommendation-mage-and-the-demon-queen-panel-1.jpg" mdxType="Image" />
    <Image alt="Tsun tsun Velverosa" caption="Tsun Demon Queen says No! At the next panel she killed Malori with her blasts." name="geeknabe-webtoon-recommendation-mage-and-the-demon-queen-panel-2.jpg" mdxType="Image" />
    <p>{`Yes, they are gay for each other. Malori is honest with her feelings but Velverosa? She’s a tsun. An adorable tsun, if I may add. The story starts off with a dramatic entrance, battle and ending by Malori as she works her darn hardest to get the attention of her beloved demon queen. But after experiencing the `}<em parentName="p">{`Nth`}</em>{` defeat, she realized this isn’t working out. Velverossa is too strong for her to solo. So with the blessings of no one, she roped in her best friend Cerik to concoct a plan… A plan to make Velverosa stop and listen to her! Obviously Cerik call bullsh`}{`*`}{`t (What Malori is doing is basically stalking after all) on her plan, so Malori has no other choice but to blackmail him.`}</p>
    <Image alt="Kinky." caption="Oh to be a boy with a stocking kink." name="geeknabe-webtoon-recommendation-mage-and-the-demon-queen-panel-3.jpg" mdxType="Image" />
    <p>{`The story is honestly so much fun. The jokes are delivered fast, on-point and it’s super ludicrous. Snake women with stockings?! How on earth did the author come up with this kink (lol). Unless the author herself is...? Ahem! At any rate, I’m 100% sure that all readers following `}<RemoteLink to="https://www.webtoons.com/en/comedy/mage-and-demon-queen/list?title_no=1438&page=1" mdxType="RemoteLink">{`Mage & Demon Queen`}</RemoteLink>{` are onboard the same ship. Come join us as a Malori x Velverosa shipper too!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Great Ongoing Webtoon Recommendation #3: Swimming Lessons for a Mermaid`}</h2>
    <p>{`This is a new series that just recently received an English translation in WEBTOONS. I’m in love with how simple and charming it is! Soo Lee is a member of his school’s swimming team. He loves swimming so that works out but unfortunately, his time record is not good enough even for swimming meets. So he asked his coach to use the swimming pool at night to train. It was there that he saw Choa Shin, crouching by the pool. An unfortunate tap at the shoulder tripped the poor girl into the pool, causing Soo Lee to quickly jump in to save his classmate. But instead of seeing a girl struggling to breath, he saw a fish tail! And her hair turned gold!`}</p>
    <Image alt="Choa Shin is a mermaid in disguise!" caption="To be fair, this is a nice colour combination of hair colour, pupil colour and fish tail. There's something really soft and sublime about this two colour choices." name="geeknabe-webtoon-recommendation-swimming-lessons-for-a-mermaid-panel-1.jpg" mdxType="Image" />
    <p>{`Choa Shin is a true-blue mermaid, albeit not living up to the mythical lore. You see, she can’t swim. Mermaids rely on tail strength to swim but Choa is born with a weak tail. She can only flop for a bit before her tail cramps up. Her sisters realized that the ocean is probably not the best environment for her, so they negotiated with a sea witch to give their baby sister human legs. It’s a jarring experience, but Choa soon learns how to walk with the help of her human father. Yup, mother is a mermaid and father is a human. Thus life as a human began for Choa. Fast forward to another few years and we arrived at the situation in Chapter 1.`}</p>
    <Image alt="Banner for Swimming Lessons for a Mermaid" caption="Swimming Lessons for a Mermaid banner at WEBTOONS official site." name="geeknabe-webtoon-recommendation-swimming-lessons-for-a-mermaid-banner.jpg" mdxType="Image" />
    <p>{`I usually don’t comment or recommend a series that I am not confident in. After all, at the time of writing, this series only has up to 15 chapters in WEBTOONS. That’s not much to go on, is there? But the simplistic story draws me in so well. I find Chapter 1 a great introductory to the world of `}<RemoteLink to="https://www.webtoons.com/en/romance/swimming-lessons-for-a-mermaid/list?title_no=1912" mdxType="RemoteLink">{`Swimming Lessons for a Mermaid`}</RemoteLink>{`. It sets up the two characters (possibly a soon-to-be romantic couple) and the core focus of the story - guy is a good swimmer while girl is a mermaid bad at swimming. It’s so `}<strong parentName="p">{`obvious`}</strong>{` that I could almost `}<em parentName="p">{`smell`}</em>{` the plot. I am 10 billion percent sure I’m in for a good time after finishing Chapter 1.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Great Ongoing Webtoon Recommendation #4: How to Become a Dragon`}</h2>
    <p>{`Tis’ yet another series I never expected to recommend. Like, blimey! It only has 9 chapters as of this time of writing. So what made me recommend this? If you noticed the familiar art, that’s because it’s from the same author that did `}<RemoteLink to="https://www.webtoons.com/en/romance/super-secret/list?title_no=666" mdxType="RemoteLink">{`Super Secret`}</RemoteLink>{`! It just so happened that I really enjoyed Super Secret. Judging from the track record, I presumed that this new series by eon should be equally enjoyable and so far, I’m right on the money.`}</p>
    <Image alt="How to Become a Korean Ryuu banner" caption="How to Become a Dragon banner at WEBTOONS official site." name="geeknabe-webtoon-recommendation-how-to-become-a-dragon-banner.jpg" mdxType="Image" />
    <p>{`Our MC in this story is Young Yoo, a super genius home tutor. Every student that he tutored scored superb marks. He’s basically every parent’s personal hero and such, they favor him so much they’ll give him expensive gifts (E.g. ginseng) every month. It’s not like he enjoys tutoring others, Young Yoo is just really good at teaching. However, he is only doing this to earn enough money before he can kiss this tutoring business goodbye. One night, something strange happened as he lay down on his bed. Wait, it’s… wet? He looked up to his ceiling only to find a strange boy watching him. Obviously Young yelled his lungs off. The strange boy has long hair, antlers(?), wears strange robes as clothing and speaks weird. The boy introduced himself as Bari, an `}<RemoteLink to="https://en.wikipedia.org/wiki/Korean_dragon" mdxType="RemoteLink">{`Imoogi`}</RemoteLink>{`. He haughtily demands that Young help him pass the Nakdong River Dragon exam in order to become a full-fledged dragon.`}</p>
    <Image alt="Bari demanding Young help him pass the dragon test" caption="A mythical creature barged into your room and said this to you. What do you do? A. Scream? B. Call the police? C. Accept your fate to progress the plot further." name="geeknabe-webtoon-recommendation-how-to-become-a-dragon-panel-1.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <p>{`All of this literally went over Young’s head. We are talking about folks living in a modern era, of course Bari sounds like a nutcase. Young isn’t convinced about the whole mythical snake and dragon exam until Bari tells him about the cause of his bad karma. Okay, so Young is half-convinced now but Bari is still getting kicked out of his room tomorrow. Shoo shoo!`}</p>
    <p>{`Unlike Super Secret that did the panels in fours, `}<RemoteLink to="https://www.webtoons.com/en/fantasy/how-to-become-a-dragon/list?title_no=1973&page=1" mdxType="RemoteLink">{`How to Become a Dragon`}</RemoteLink>{` has a proper panel flow you’d expect from… a webtoon. The art is as good as I remembered, and the colour palette choices are less cutesy. I really like Bari’s design! Most modern fantasy just plastered some cat ears on their characters and call it a day. Bari felt like a genuine dunce-y mythical snake that could pass as his lore’s namesake. Young on the other hand, is a bloody realist and cynic. He feels relatable, and I love it. Super Secret’s dialogue flowed so well and I can see the author worked his magic here too. There's only 9 chapters in so I’ll reserve my personal judgement until a sizable amount of chapters are out. But all in all, I’m convinced this webtoon will stay in my list of favourites.`}</p>
    <Image alt="Bari in his snake form." caption="Look at Bariiii. He nom-ing!" name="geeknabe-webtoon-recommendation-how-to-become-a-dragon-panel-2.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`Great Ongoing Webtoon Recommendation #5: Miss Abbott and The Doctor`}</h2>
    <p>{`There’s a ton of webtoon with great art and this is no different. However, the art is really, really unique. Instead of beautiful lineart and colours, this webtoon is nothing but pencil scribbles. Or was it ball pen scribbles instead? Nevertheless, I’m convinced the author drew the first chapter on paper and scanned it in. I must say when I first read it, I thought it’s a strange art aesthetic to go with. A webtoon with no colours? It’s a bold move. I thought it was done to garner more attention, and the art will soon change as the story progresses. However, the deeper I go, I realized this isn’t the case.`}</p>
    <Image alt="I think this is the only fully coloured image for this webtoon..." caption="Miss Abbott and the Doctor banner at WEBTOONS official site" name="geeknabe-webtoon-recommendation-abbott.jpg" mdxType="Image" />
    <p>{`The art `}<strong parentName="p">{`IS`}</strong>{` how I `}<em parentName="p">{`imagine`}</em>{` having my artsy best friend doodle a cute story in her notebook and letting me read it. I would then persuade my friend to publish it to a comic platform with the art intact. At least, that’s how I imagine the case to be with this webtoon. The characters have simplistic facial features with equally adorable expressions to go with. There is no boundary drawn to highlight an area (E.g. panel). The story is told only with characters, a simple background and a ton of whitespace as you scroll down. Sometimes there’s no background at all! The more I read it, the more I’m amazed by how the author did this. How on earth is this scribbly story so bloody `}<strong parentName="p">{`charming?`}</strong>{`!`}</p>
    <Image alt="Squeeze fluff." caption="It's so adorable, my little heart is doing a squeeze here..." name="geeknabe-webtoon-recommendation-miss-abbott-and-the-doctor-panel-2.jpg" mdxType="Image" />
    <p>{`Cati Abbott is a proper lady (by name I guess), but she has a wild side. That’s because she’s plucked from a native American tribe by her equally plucky explorer stepmother. The story starts with Cati realizing her crush, Doctor Marino is back in town. Oh to be a maiden in love. Lucky for her, Doctor Marino is equally head over heels for her too. Since the story starts off with them already in love, the beginning chapters are just them getting into flirty situations that all fangirls would gush for as they slowly come to terms with their feelings. The writing nuances are really adorable here and occasionally, the author would add some colours to highlight certain aspects of the story. You’re so used to the monochromatic scribbles in this story that adding a tiny bit of colour seems to make the `}<RemoteLink to="https://www.webtoons.com/en/romance/miss-abbott-and-the-doctor/list?title_no=707&page=1" mdxType="RemoteLink">{`Miss Abbott and the Doctor `}</RemoteLink>{`come alive just for a brief moment! It’s amazing what a tiny splash of colour can do.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`All in all`}</h2>
    <p>{`Yes! 5 down, another 5 to go! I hope you enjoyed this part’s webtoon recommendation. I have added links to said webtoon in their sections so please click on those to read them on WEBTOONS official site. Support the authors y’all!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      