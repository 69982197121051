import { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import items from '../series.json';
import $ from '../../../styles/globals';
import Pagination from '../../Pagination';
import InFeedAd from '../../Ads/InFeed';

const Section = styled.section``;

const Container = styled.div`
  padding: 50px 0;
`;

const StyledInFeedAd = styled(InFeedAd)`
  margin: 20px auto;
  width: 100%;
`;

const InnerContainer = styled.div`
  padding: 30px 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  grid-gap: 30px;

  ${$.screen.tabletA} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${$.screen.tabletB} {
    grid-template-columns: repeat(3, 1fr);
  }

  ${$.screen.mobile} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const Card = styled(Link)`
  overflow: hidden;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  text-align: center;
  border: 1px solid ${$.color.lightblack};
  transform: translateY(0);
  transition: all 0.3s ease;

  ${$.screen.desktop} {
    &:hover {
      transform: translateY(-0.3em);
    }
  }
`;

const Total = styled.div`
  font-family: Raleway Regular;
  font-size: 13px;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
  color: ${$.white1};
  margin-bottom: 10px;
`;

const Title = styled.div`
  color: ${$.white1};
  font-family: Porkys;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 20px;
  line-height: 1.3em;
`;

const CardBanner = styled.div`
  ${({ color }) => `background-color: ${color};`}
  margin: 10px;
  padding: 10px;
`;

const Description = styled.div`
  padding: 0 ${$.layout.padding3}px ${$.layout.padding3}px
    ${$.layout.padding3}px;
  color: ${$.color.lightblack};
  line-height: 1.3em;
  font-family: Raleway Regular;

  > div:first-child {
    text-transform: uppercase;
    font-family: Raleway Bold;
    font-size: 15px;
    margin-bottom: ${$.layout.margin4}px;
  }
`;

const randomizedColors = () => {
  const colors = [
    $.color.orange,
    $.color.green2,
    $.color.red1,
    $.blue2,
    $.brown5,
  ];

  return colors[Math.floor(Math.random() * (colors.length - 1) + 1)];
};

const Content = () => {
  const totalSeries = 8;
  const [slice, setSlice] = useState({ start: 0, end: totalSeries });
  const sortedItems = items.sort((curr, prev) => {
    if (curr.name > prev.name) {
      return 1;
    }

    if (curr.name > prev.name) {
      return 0;
    }

    return -1;
  });

  return (
    <Section>
      <Container>
        <StyledInFeedAd />
        <InnerContainer>
          {sortedItems
            .slice(slice.start, slice.end)
            .map(({ name, slug, links, description }) => (
              <Card
                to={`/series/${slug}/`}
                key={slug}
                title={`Click to go to ${name}`}
              >
                <CardBanner color={randomizedColors()}>
                  <Total>series</Total>
                  <Title>{name}</Title>
                </CardBanner>
                <Description>
                  <div>{`Total ${links.length}.`}</div>
                  <div>{description}</div>
                </Description>
              </Card>
            ))}
        </InnerContainer>
        <Pagination
          totalItems={items.length}
          maxItem={totalSeries}
          setSlice={setSlice}
        />
      </Container>
    </Section>
  );
};

export default Content;
