import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Blockquote = makeShortcode("Blockquote");
const Ads = makeShortcode("Ads");
const RemoteLink = makeShortcode("RemoteLink");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`There was once when webtoons were scarce. At least, it was scarce because only the best of the best titles are being fan-translated. Back then, folks are still revelling in reading shoujo and shounen manga which is ironically, more abundant than the current times. From what I can see, the isekai genre has overflowed the current manga scene! It’s really hard to find proper classic shounen or shoujo titles nowadays.`}</p>
    <p>{`Webtoons back then had a really, really different approach to their stories. With no one to clamp down on their content, the long strip is a `}<em parentName="p">{`boundless canvas`}</em>{` for the author to portray their imagination. I’m telling ya, my first impression on webtoons when I first encountered one is this:`}</p>
    <Blockquote mdxType="Blockquote">
  If mangaka didn’t have an obligation to appeal to the readers, this is what
  they would have drawn.
    </Blockquote>
    <Ads mdxType="Ads" />
    <p>{`It was mind-blowing, seeing what folks can do on a long strip with only their imagination to pull us in. Colours spill to the next panel or at times, you won’t see a boxed panel at all. Portrayal of emotions is very different when seen in colour. Emotions felt super `}<strong parentName="p">{`raw`}</strong>{`! It was as if the long strip canvas gave extra `}<em parentName="p">{`wiggle room`}</em>{` to the author to draw their hearts out compared to a single page in a comic.`}</p>
    <p>{`The art is one thing, but the plot is `}<strong parentName="p"><em parentName="strong">{`chef kiss`}</em></strong>{` amazing. I swear, many of my favourite webtoon authors are `}<strong parentName="p">{`sadists`}</strong>{`. My favourite webtoon titles (from years back) had barely changed. From `}<em parentName="p">{`Kubera`}</em>{`, `}<em parentName="p">{`Abide in the Wind`}</em>{`, and `}<strong parentName="p">{`The Pale Horse`}</strong>{`… Each one of these webtoon’s plot themes is `}<strong parentName="p">{`tragedy`}</strong>{`. Seriously, what on earth is going on in these authors’ minds?`}</p>
    <p>{`Abide in the Wind is completed but the fan translation isn’t. I had to read spoilers just to find out what happened. Let’s just say that story didn’t exactly make sense, but the events leading up to the ending just breaks my heart. Kubera is still ongoing and I haven’t a clue how the author will end it. It’s a classic tragedy where everyone gets played like a fiddle. Then there’s `}<strong parentName="p">{`The Pale Horse`}</strong>{`, the webtoon I wish to discuss in this blog post.`}</p>
    <p><em parentName="p">{`Note: There are spoilers in this blog post.`}</em></p>
    <Ads mdxType="Ads" />
    <p>{`I remember reading it many years ago but only up to S1 and a few chapters in S2. The plot is so brutally `}<em parentName="p">{`sorrowful`}</em>{`, I remember dropping it because I couldn’t take it anymore. Turns out I dropped it at the right time because the scanlation stopped at the same time too. I tried looking around for a spoiler thread but no luck either ): I took a peek at the `}<RemoteLink to="http://webtoon.daum.net/webtoon/view/palehorse" mdxType="RemoteLink">{`official platform (Daum) it was originally hosted on`}</RemoteLink>{` and wow, The Pale Horse has just recently finished serialization this year (2020)! Next thing I know, someone had started uploading the English chapters for free on ze Internet again. Yay, bless you random Internet stranger.`}</p>
    <Image alt="The Pale Horse webtoon" caption="A screencap of the The Pale Horse page on Daum. The art is lovely." name="the-pale-horse-panel-1.jpg" mdxType="Image" />
    <p>{`In case you haven’t noticed, the title `}<em parentName="p">{`The Pale Horse`}</em>{` references `}<RemoteLink to="https://en.wikipedia.org/wiki/Four_Horsemen_of_the_Apocalypse" mdxType="RemoteLink">{`The Four Horsemen of the Apocalypse`}</RemoteLink>{`, specifically `}<strong parentName="p">{`Death.`}</strong>{` I didn’t even notice the reference until it was specifically shown and explained at the end of S1! Long story short, The Pale Horse is set in France in the year 1835. It talks about the story of an immortal witch who seeks death as her sole goal in her long life. Obviously the plot dictates that she can’t die, so she fills the void in her heart by chasing after love. S1 marks the start of this tragedy. Her long life has turned her into a `}<strong parentName="p">{`cynical monster`}</strong>{`. After seeing a lady’s purest form of love from afar, she became enamored by the idea. She wants to be loved like how this lady, Lea Dupre is doing it. So she became Lea Dupre’s daughter that Lea lovingly calls `}<em parentName="p">{`my rose`}</em>{`. Rose Dupre tries to love and be loved as a village girl. Unfortunately, some things could not be changed.`}</p>
    <Image alt="The Pale Horse webtoon panel 1" caption="This scene really tore my heart out. Lea Dupre knew all this while that her 'daughter' is a blood sucking monster. Regardless, she still love her as her own flesh and blood." name="the-pale-horse-webtoon-panel-1.jpg" mdxType="Image" />
    <Image alt="The Pale Horse webtoon panel 2" caption="This is a continuation of the scene above. Lea did try to reason with her survival instinct. She may have raised Rose for the last decade, but she's seen what Rose is capable of. But her motherly instinct took over and... yea, I better not spoil that. I find that scene super emotional!" name="the-pale-horse-webtoon-panel-2.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <p>{`As Rose Dupre, she is a sweet daughter of a villager that loved her childhood friend. As a witch however, she’s a blood-sucking monster with the ability to turn any human she ate into a blood-sucker like her. Yup, not only is she immortal, Rose is also a monster that drinks blood when her craving hits. Her blood-sucking tendencies is something she couldn’t resist no matter how hard she tried. It caused friction between the villagers and her until eventually, she is forced to accept that she is a `}<strong parentName="p">{`monster`}</strong>{` that would never be `}<em parentName="p">{`happy and loved`}</em>{`.`}</p>
    <p>{`You might be thinking, hey isn’t she basically a `}<strong parentName="p">{`vampire`}</strong>{`? Nah, the term `}<em parentName="p">{`vampire`}</em>{` is not important here. In fact, it was rarely thrown around. The term `}<em parentName="p">{`monster `}</em>{`was used instead to highlight the horrifying differences between man and Rose. Time and time again, Rose and her people are called `}<strong parentName="p">{`monsters`}</strong>{`. They could never find peace since they `}<em parentName="p">{`need`}</em>{` to feed on humans and thus, humans would retaliate back. This `}<em parentName="p">{`sick cycle`}</em>{` is basically what fuels the tragedy between what tores up Rose on the inside as well as anyone related to her. She wished to be loved and love, but what she couldn’t change what she truly is -`}<em parentName="p">{` a monster.`}</em>{` The humans that she loved couldn’t accept a monster in their midst either. So what is she to do? She can’t ask for love normally, and the humans couldn’t give it to her.`}</p>
    <p>{`But Rose did try. In the span of her infinite life, she tried to love `}<em parentName="p">{`normally`}</em>{`. Have a normal life, normal kids and love like a human whose lifespan is less than a century. But normalcy is not a concept you could tack on an immortal witch with a human heart. Along the way, `}<em parentName="p">{`she gave up.`}</em>{` Seriously, most plots would have a baddie we could blame everything on but in The Pale Horse? There are no baddies in this story, just `}<em parentName="p">{`unavoidable consequences`}</em>{` that `}<strong parentName="p">{`fed`}</strong>{` this tragedy we are reading about. Every character’s response and action is so `}<strong parentName="p">{`human`}</strong>{`, I can’t help but empathize with their situation. Even Rose, the immortal witch, is `}<em parentName="p">{`human`}</em>{` in her thoughts and actions.`}</p>
    <Image alt="The Pale Horse webtoon panel 3" caption="Rose once tried to be a mother to two children she gave birth to. While she's on the run with the two children, Rose suddenly... gave up. The beast was chasing them down and Rose just let their hands go. This left a lasting impact on the children, as they grew up to hate her. I honestly don't blame Rose for this. She tries to be a normal mother but she's far from normal." name="the-pale-horse-panel-3.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <p>{`I’m currently at Chapter 135 and I’m hooked deep. I’ve seen readers calling Rose an emo lady but I find that false. Who’s to blame for wanting love? In S1, we see Rose coming to terms with her existence and leaving the village for good. However, little did she know that her childhood friend, Pierre will not forgive her for what she did. In S2 which I’m still at, had Pierre causing a ton of trouble in Paris. He got involved with quarries (humans that got turned into monsters) and even got his hands dirty in order to kill Rose. His thought process went from a naive kid to a shameless criminal… And who’s to blame? Absolutely no one, not even himself. I swear, the amount of torture my babies are going through! ):`}</p>
    <p>{`Part of my enjoyment comes from the sadist plot, the other part comes from the wonderful art and dialogue. Folks often say bloggers should have a way with words. Unfortunately for me, I have none. The only word that came to me whenever I soak in the scenes in The Pale Horse is this - `}<strong parentName="p">{`RAW.`}</strong>{` Emotional scenes in this webtoon are bloody `}<strong parentName="p">{`raw. `}</strong>{`Be it hateful, endearment or fear, the scenes invoked really strong emotions by playing with colours, graphics and dialogue. The author is really good at this.`}</p>
    <p>{`Then there’s the art. I’m no pro at appreciating art, but the texture and colouring reminds me a lot of renaissance art. I believe it was the way the author did the highlighting on the skin and the masterful way she added texture on the backgrounds and clothing. Couple this with graphic design artistry, it’s `}<strong parentName="p">{`beautiful.`}</strong></p>
    <Ads mdxType="Ads" />
    <h2>{`All in all`}</h2>
    <p>{`This webtoon evokes inexplicable emotions in me whenever I read it. This webtoon is not meant to have a happy ending. It’s good at what it does and if you love tragic webtoons, this one's for you.`}</p>
    <p><em parentName="p">{`EDIT: I added (1) to the blog post title because I’m still reading the chapters as they are released on ze Internet. Chapter 150 and above is slowly peeling away the mystery behind Rose and Lemon’s past. Reading their past has further hinted to me just what kind of “creature” Rose is. I have a feeling I’ll be back with another blog post about my thoughts to this webtoon!`}</em></p>
    <p><em parentName="p">{`EDIT 2: I have just finished Chapter 185. Emotions are still running high, I can’t believe what I just read. It’s like my rollercoaster ride just went down a slope one after another. Do I have new thoughts/perspective on this webtoon? You betcha.`}</em></p>
    <p><em parentName="p">{`EDIT 3: I wanted to write more on this title since after the story moves on to Rose + Pierre teaming up, the plot went way beyond what I expected it to be. It is gearing to be story with an unexpected ending! I still couldn't summarize my thoughts well about the plot so far... I will be leaving (2) on hiatus until more chapters are out in English.`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      