import styled from 'styled-components';

import $ from '../../../styles/globals';
import BookIcon from '../../../assets/icons/book.svg';
import HeartIcon from '../../../assets/icons/heart.svg';
import Divider from '../Chapter/Shortcodes/Divider';

const Section = styled.section`
  background-color: ${$.color.yellow};
`;

const Container = styled.div`
  padding: 50px 0;
  text-align: center;
`;

const Title = styled.h1`
  color: ${$.brown5};
  font-family: Porkys !important;
  font-size: 50px !important;

  ${$.screen.desktop} {
    letter-spacing: 1px;
  }

  ${$.screen.mobile} {
    font-size: 40px !important;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: ${$.layout.margin1}px;

  > svg {
    &:last-child {
      width: 40px;
      height: 40px;
      margin-left: ${$.layout.margin2}px;
      fill: ${$.brown5};
    }

    &:first-child {
      width: 30px;
      height: 30px;
      margin-right: ${$.layout.margin2}px;
      fill: ${$.color.red2};
    }
  }
`;

const Subtitle = styled.h2`
  font-family: Rubik Regular !important;
  font-weight: bold;
  color: ${$.brown4};
  font-size: 17px !important;
  line-height: 1.3em;

  ${$.screen.mobile} {
    font-size: 16px !important;
  }
`;

const StyledDivider = styled(Divider)`
  width: 100vw;
  position: relative;

  ${$.screen.desktop} {
    left: calc(((100vw - 1200px) / 2) * -1);
  }

  ${$.screen.tablet} {
    left: -30px;
  }

  ${$.screen.mobile} {
    left: -30px;
  }
`;

const Banner = () => (
  <Section>
    <Container>
      <TitleContainer>
        <HeartIcon />
        <Title>Fanfiction</Title>
        <BookIcon />
      </TitleContainer>
      <Subtitle>
        Exclusive fanfic writing corner for mari-chan to flex her pen (and
        ships).
      </Subtitle>
    </Container>
    <StyledDivider />
  </Section>
);

export default Banner;
