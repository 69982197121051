import PropTypes from 'prop-types';
import styled from 'styled-components';

import $ from '../../styles/globals';
import HeartIt from './HeartIt';

const Section = styled.section`
  margin: ${$.layout.margin8}px 0;

  ${$.screen.desktop} {
    width: 700px !important;
    padding-left: calc((100vw - 700px) / 2) !important;
    padding-right: calc((100vw - 700px) / 2) !important;
  }

  ${$.screen.tablet} {
    width: 700px !important;
    padding-left: calc((100vw - 700px) / 2) !important;
    padding-right: calc((100vw - 700px) / 2) !important;
  }

  ${$.screen.mobile} {
    margin: ${$.layout.margin5}px 0;
  }
`;

const Container = styled.div``;

const Article = styled.article`
  line-height: 1.85em;
  font-family: Rubik Regular;
  font-size: 19px;
  letter-spacing: -0.1px;
  word-break: break-word;
  color: ${$.brown4};

  p {
    margin: 1.5em 0;
  }

  pre {
    overflow: auto;
    background-color: ${$.color.black};
    color: ${$.white1};
    border-radius: ${$.borderRadius.radius1}px;
    padding: ${$.layout.padding2}px;
    line-height: 1.3em;
    font-size: 17px;
    font-family: 'Lucida Console', Monaco, monospace;
    width: calc(100% - ${$.layout.padding2 * 2}px);
    margin-bottom: ${$.layout.margin4}px;
    margin-top: ${$.layout.margin2}px;

    ${$.horizontalScroll}
  }

  code {
    background-color: ${$.color.black};
    color: ${$.white1};
    font-family: 'Lucida Console', Monaco, monospace;
    line-height: 1.2em;
    border-radius: ${$.borderRadius.radius1}px;
    font-size: 17px;
    padding: ${$.layout.padding2}px;
  }

  ${$.br}

  p.spoiler-tag {
    background-color: ${$.brown5};
    color: transparent;
    &:hover {
      cursor: pointer;
    }
    &.show-spoiler {
      color: ${$.color.green2};
      background-color: transparent;
    }
  }

  a {
    font-family: Rubik Regular;
    font-weight: bold;
    text-decoration: none;
    color: ${$.color.orange};
  }

  strong,
  b {
    font-family: Rubik Regular;
    font-weight: bold;
  }

  em,
  i {
    font-family: Rubik Regular;
    font-style: italic;
  }

  ul {
    list-style-type: square;
  }

  ol {
    list-style-type: decimal;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: Raleway Bold !important;
    margin-top: 60px !important;

    & + p {
      margin: ${$.layout.margin4}px 0 1.5em 0;
    }
  }

  ${$.screen.desktop} {
    position: relative;

    a {
      transition: color 0.4s ease;
      &:hover {
        color: ${$.brown5};
      }
    }

    h1 {
      font-size: 34px !important;
    }

    h2 {
      font-size: 30px !important;
    }

    h3 {
      font-size: 26px !important;
    }

    ul,
    ol {
      padding-left: ${$.layout.padding4}px;
      margin: ${$.layout.margin3}px 0;
    }

    div[data-oembed-type='video'] {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin: ${$.layout.margin4}px 0;
      text-align: center;
    }
  }

  ${$.screen.tablet} {
    h1 {
      font-size: 34px !important;
    }

    h2 {
      font-size: 30px !important;
    }

    h3 {
      font-size: 26px !important;
    }

    ul,
    ol {
      padding-left: ${$.layout.padding4}px;
      margin: ${$.layout.margin3}px 0;
    }

    div[data-oembed-type='video'] {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin: ${$.layout.margin4}px 0;
      text-align: center;
    }
  }

  ${$.screen.mobile} {
    line-height: 1.8em;

    h1 {
      font-size: 32px !important;
    }

    h2 {
      font-size: 28px !important;
    }

    h3 {
      font-size: 24px !important;
    }

    ul,
    ol {
      padding-left: ${$.layout.padding5}px;
      margin: ${$.layout.margin3}px 0;
    }

    div[data-oembed-type='video'] {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin: ${$.layout.margin4}px 0;
      text-align: center;
    }
  }
`;

const Content = ({ hearts, setHearts, author, children }) => (
  <Section>
    <Container>
      <Article>{children}</Article>
      <HeartIt
        hearts={hearts}
        setHearts={setHearts}
        text={`Enjoyed the post? Give ${author} some hearts!`}
      />
    </Container>
  </Section>
);
Content.defaultProps = {
  hearts: { shown: '-', realTotal: 0 },
  setHearts: () => {},
  author: 'nabe-chan',
  children: <></>,
};

Content.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  author: PropTypes.string,
  hearts: PropTypes.shape({
    shown: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    realTotal: PropTypes.number,
  }),
  setHearts: PropTypes.func,
};

export default Content;
