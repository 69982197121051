import $ from '../../styles/globals';

const getStatusColor = (status) => {
  switch (status.toLowerCase()) {
    case 'ongoing': return $.color.orange;
    case 'completed': return $.color.green1;
    case 'semi-hiatus': return $.color.red2;
    case 'hiatus': return $.color.red1;
    default: return $.brown5;
  }
};

export { getStatusColor };
