import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Blockquote = makeShortcode("Blockquote");
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const RemoteLink = makeShortcode("RemoteLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`If you’ve been following webtoons `}<strong parentName="p">{`on`}</strong>{` WEBTOONS (pun intended), you’ll notice that there’s a new addition to the ORIGINAL lineups for the Saturday tab. It’s called `}<em parentName="p">{`Your Throne`}</em>{` and it has one of the most shoujo-ish art I’ve seen for a webtoon. Big eyes, elaborate art and sparkles in the background… The banner even has a male character along with the heroine. It screams romance but it’s `}<strong parentName="p">{`NOT`}</strong>{` a romance webtoon. Heck, the `}<em parentName="p">{`Romance`}</em>{` tag is missing from the banner. It’s only tagged as `}<em parentName="p">{`Fantasy`}</em>{` so there’s not much to go on with. Just what is this webtoon all about? If you’ve been following my blog, you’ll know that I usually don’t recommend webtoons willy-nilly `}<em parentName="p">{`unless`}</em>{` it’s super good `}<strong parentName="p">{`OR`}</strong>{` it’s a title I really enjoy. (While we are on topic, I recommend trying `}<em parentName="p">{`This Witch of Mine!`}</em>{`)`}</p>
    <Blockquote mdxType="Blockquote">So yes, Your Throne is really, really good. </Blockquote>
    <p>{`It has tension. We’re being led by the nose from chapter to chapter with nothing much to go on except the heroine’s goal. The heroine schemes yet all of her methods are bold and cruel. She is cunning and would do everything it takes to wrestle power for herself. The other heroine is a bit of an airhead, but with good reason too. Don’t let her sweet looks deceive you, she’s equally selfish too. As the plot unfolds, we get more plot twists until the author finally reveals what is `}<em parentName="p">{`truly`}</em>{` going on in the background. These girls are not what they seem to be! Now this is a story telling that I could get behind.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Medea Solon, protagonist of Your Throne." caption="Medea Solon, the protagonist in this story. She's smart, cruel and cunning rolled into one. Also, she's good at sword fighting. Medea got to where she is through hard work and grit. She deserves her place but no, Psyche had to swoop in happily >:(" name="your-throne-webtoon-panel-1-geeknabe.jpg" mdxType="Image" />
    <p><em parentName="p">{`Note: Raws are up to Chapter 26 as of writing. Fan translations go up to Chapter 19. WEBTOON has up to Chapter 5. Minor spoiler for the beginning of this webtoon’s plot.`}</em></p>
    <p>{`The crown prince Eros is in love but it is his duty to pick the most viable candidate to be his crown princess. A contest was conducted to pick a crown princess, and two maidens stepped up to vie for his love - Medea Solon and Psyche Callista. As the eldest daughter of Duke Solon, Medea is praised for her intellect but feared for her cruelty. She completed all of the tasks assigned to her during the contest flawlessly. Politics, sword fighting, knowledge… She literally trumped her opponent, Psyche in every sense. Many onlookers agreed that Medea had shown the most promise to be selected. But when the day to pick a winner arrived, the crown went to Psyche instead. Psyche is the only daughter of Count Callista. She is sweet and demure, and projects naivety in both looks and behaviour. Angered by the unfair judgement, Medea vowed to pay back the humiliation ten fold.`}</p>
    <p>{`Since then, Medea tried to get Psyche out of the picture. The throne is hers and Psyche is but an obstacle to her rightful place. The sweet Psyche knew all along about Medea’s schemes but she still wanted to make amends. On the day when Psyche is supposed to visit the temple, Medea secretly meets her there as well. Psyche tried to reconcile with Medea but goodwill can only go so far. They both scuffled and as they were pulled underwater, Medea made a selfish wish to God. The next day, both girls found out their souls have been swapped. Medea is in Psyche’s body while Psyche is in hers. Medea could only laugh in glee when she woke up in Psyche’s body. Meanwhile, Eros has declared that Medea will be sentenced to death… So what does this mean for Psyche in Medea’s body?!`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Psyche Callista, deuteragonist of Your Throne webtoon." caption="Medea in Psyche's body. I really couldn't relate to Psyche. Her actions and behaviour just seems selfish. She didn't try to even help herself and thus, landed in her current situation." name="your-throne-webtoon-panel-2-geeknabe.jpg" mdxType="Image" />
    <p>{`I view both Medea and Psyche as the heroines while the prince takes more of a backseat in the beginning. He’s the man both Medea and Psyche wants to get their hands on, yes, but Medea only views him as her ticket to `}<strong parentName="p">{`power`}</strong>{`. Psyche on the other hand, looks at Eros with pink goggles. She’s so deep in love, she couldn’t see past his facade. The plot has you thinking that Medea is `}<strong parentName="p">{`evil`}</strong>{` while Psyche is the `}<strong parentName="p">{`real heroine`}</strong>{`, but this is not true at all. In fact, the hints are there in the first five chapters.`}</p>
    <p>{`Medea is shown to be cruel in Chapter 1 where she chopped a man’s tongue before killing him. Granted, he insulted the daughter of a ducal house so he is `}<em parentName="p">{`kinda`}</em>{` asking for it... Nevertheless, we knew for one that she’s not to be trifled with. But if she’s truly a fearsome lady, would she have commanded full loyalty from her subordinate? No, she’s super charismatic and her aura commands respect. It’s honestly fitting for a ducal lady, and for someone that has contributed a ton to the country. Medea definitely has some good in her but she’ll retaliate if you wronged her as further shown in latter chapters. Medea is the type of character that practices `}<em parentName="p">{`an eye for an eye, a tooth for a tooth `}</em>{`to the tee.`}</p>
    <Image alt="Medea talking sense here. Psyche, the hell you doing." caption="I agree with Medea here. Psyche clearly heard her maid talking bad about Medea. She shouldn't brush it off but instead, Psyche should chastise her maid. That's plain rude of a maid to talk about a noble." name="your-throne-webtoon-panel-5-geeknabe.jpg" mdxType="Image" />
    <p>{`Honestly, everything that happened so far that leads to Chapter 1 feels justified. From what Medea sees, Psyche took everything that is supposed to be hers. Her family is in disarray and the public’s perception of her has changed drastically. If this is not Psyche’s fault, then who is to blame for Medea’s sudden misfortune?`}</p>
    <Ads mdxType="Ads" />
    <p>{`On the other hand, Psyche is a character asking for trouble in the world of nobles. She’s bloody oblivious to how every thoughtless action of hers can be seen otherwise. Psyche is all sweet on the inside but it was shown that she’s selfish as well. She could have rejected the crown princess position but no, the allure is too great. Psyche wants the prince’s love no matter what and she wouldn’t yield. Both ladies are fighting for the crown princess’s position but what did Eros do? Well, he sat on the sidelines and enjoyed the view. Dude is equally guilty, I’m telling ya. If he had the heart to stop the catfight, he would have properly told Psyche off when she chose the ring. But no, he smirked at the thought and let it be.`}</p>
    <p>{`After the body switch, we see Medea effortlessly mimicked Psyche while Psyche had troubles impersonating Medea. Soon, it was revealed that there’s more to this crown princess contest. Psyche is hiding something but what, exactly? This webtoon `}<strong parentName="p">{`IS `}</strong>{`about revenge, but it’s against someone you least expect. I didn’t want to spoil anymore so I’ll stop here. Seriously, this webtoon has me clawing my hair out wondering what’s going to happen next. The art is amazing and hurray, blood! When was the last time I saw blood in a webtoon… Read `}<RemoteLink to="https://www.webtoons.com/en/fantasy/your-throne/list?title_no=2009&page=1" mdxType="RemoteLink">{`Your Throne webtoon here`}</RemoteLink>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      