import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const RemoteLink = makeShortcode("RemoteLink");
const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Following the success and amazing hype Fruits Basket brought, I just thought how amazing it is to have the OTHER OLD ANIME to get a new season or maybe, a reboot. I mean, surely other anime deserve the treatment too, yea? I consider Fruits Basket to be a popular series, but one that only super fans would appreciate. Fruits Basket is the type of series that has a ton of dialogue that speaks of `}<em parentName="p">{`feelings.`}</em>{` Folks who aren’t used to this type of dialogue will feel that it’s super corny, which is kinda why I feel amazed that Fruits Basket is popular enough that it got a reboot.`}</p>
    <p>{`That means it went mainstream, yea? I feel it’s so `}<strong parentName="p">{`unfair`}</strong>{` that Fruits Basket gets the reboot treatment while other equally great anime gets the “what’s that anime?” treatment. So here are my personal favourite anime titles that I really love to see a new season or a reboot.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Anime that deserves a reboot #1: Gakuen Alice`}</h2>
    <p>{`Along with Fruits Basket, I consider Gakuen Alice the other drama romance with corny dialogue. It feels as if corny dialogues are a thing back then… I tried re-reading it again a decade later and found that I couldn’t stomach the dialogue. I guess I’m the one who grew up because back then, I thought these dialogues were super romantic! Gakuen Alice, if you have not heard of it, is written by Higuchi Tachibana, a wonderful mangaka with a creative take for magical background. Be it creatures, ideas, mechanics… This mangaka is really good at what she does!`}</p>
    <Image alt="Gakuen Alice" caption="Good stuff... Good stuff... Please, come back to the limelight, Alice Academy!" name="gakuen-alice.jpg" mdxType="Image" />
    <p>{`Gakuen Alice has the story focused on Mikan, a girl who followed her best friend into a mysterious school for elites. Now, when I say elite, I mean super powers kinda elite. Children with super powers called Alice are scouted all over the country and enrolled in Alice Academy. The government did this so they could monitor these children and nurture them so they could contribute back to the country. Students who enrolled here will never get to leave until they graduate.For someone who loves her best friend, this doesn’t sit well with Mikan. So she left her village to sneak into the school. Alas, she was embroiled in a kidnapping and was found to have an Alice herself. She soon found herself enrolled in the school too. Things were happy at first but little did Mikan know, there’s a ton of secrets in this school…`}</p>
    <p>{`In the first half of the manga, there’s a lot of school life shenanigans. School festivals and events are super unique since all of these kids have super powers. They do things different too. There’s classmate camaraderie here and there, romance and of course, the government asking children to do their dirty work! Oof. It’s super hilarious at first. The jokes are really on point! Then the latter half gets more serious by exposing how even the teachers are victims of the school’s obsession with getting the students miserable. The anime (2004) has a super fun vibe to it, a bit kiddy too. They definitely got the jokes going, but I’m not too sure how they’ll incorporate the serious vibe in the latter half if the anime ever went past 26 episodes.So reboot is necessary! Come on, do a new Gakuen Alice! With the success of Fruits Basket, Gakuen Alice will surely take off! Gakuen Alice is best consumed as a manga. Anime is fine, but manga has more delicious jokes and despair.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Anime that deserves a new season #2: Pandora Hearts`}</h2>
    <p>{`If Gakuen Alice has corny dialogue, then BEHOLD! The winner of the corniest dialogue in all of manga history - Pandora Hearts. That’s not to say it is really, really bad. I actually love the corny dialogues. Each one of them hits the spot! The plot is so bloody twisty, I have no idea what will happen next. And of course, PAIN. Lots of pain in this story but it got a happy ending so all is good. The author’s other work, The Case Study of Vanitas, got an anime if you’re interested.`}</p>
    <Image alt="Pandora Hearts" caption="Gilbert <3 Break </3 ): Did I cry reading the ending? Maybe..." name="pandora-hearts.jpg" mdxType="Image" />
    <p>{`Here’s the basic plot without any spoilers. Our MC is Oz, a boy from the house of Vessalius. The story has four main families with a secret of their own, and Oz belongs to one of those families. On the day of his coming of age ceremony, an unexpected event happened. He was dragged to the Abyss, a deep hell-like place where supernatural monsters called Chains exist. Abyss is kinda like a taboo place so Oz is kinda screwed. To escape, he signed a contract with a Chain and voila, he got out. However, 10 years have passed since he was trapped in the Abyss. Familiar faces have changed since then. Oz is forced to join an organization called Pandora in order to survive… and to find out the reason why he was dragged to the Abyss.`}</p>
    <p>{`The beginning of the story is actually, really well done. It serves as a wonderful mystery, lots of setting up that only fuels more questions. When Oz emerges from the Abyss, not only is he forced to be more mature, he needs to face his biggest question - Why did his father abandoned him? Is his existence truly a sin? There’s a lot of feels going on here, plus a ton of subplot running at the same time. The mangaka masterfully keeps adding new things but they all tie up nicely to the final conclusion. This title is basically one of those that kept pulling the rug under you. You get a bit frustrated because what you suspected is not what it seems, but the great thing is that the mystery keeps getting better. The good guys are actually the bad guys, while the bad guys are… the victims. Things like that. Afterwards, you just stop trying to piece things together and let the story bring you to its conclusion instead.`}</p>
    <p>{`If they ever do a new season, they need to go all the way till the end. At least until the Tragedy of Sablier. 40 episodes should do just fine to let all that despair sink in. There’s a 12-episode anime already available, but feel free to read the manga for the full experience.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Anime that deserves a new season #3: Princess Tutu`}</h2>
    <p>{`Princess Tutu is one of those unlikely and creative takes on classical music + fairy tale + ballet, all mashed up into an anime of pure brilliance. I honestly don’t think it will get a new season because the original anime studio broke up and the staff was absorbed elsewhere. I remember reading an interview by the original directors that they really want to make a new season but circumstances dictate otherwise.`}</p>
    <Image alt="Princess Tutu" caption="She is beautiful, she is grace... SHE IS PRINCESS TUTU!" name="princess-tutu.jpg" mdxType="Image" />
    <p>{`All in all, Princess Tutu is basically a story of an ugly duckling growing up to be a beautiful swan. A mad storyteller trapped a town in a story he wrote. He did this with a magical pen that writes out the livelihood of the people there without stopping. The townspeople are turned into animals that could talk and walk but they could never leave the town, nor will they have thought of it. Our heroine is an ugly duckling who fell in love with a prince dancing ballet near the lake. Her emotions were so strong, it caused her to change into a human named Ahiru the moment she wore a magical pendant. Every episode, the prince or a friend would be in trouble as dictated by the storyteller and Ahiru would transform into Princess Tutu to save the day. There’s a lot of fairy tale allegory included with matching classical music playing in the background, as we watch the ugly duckling growing out of her side character role and owning her role in the story.`}</p>
    <p>{`The story is true to the fairy tale it is based on. An ugly duckling realizes her strength and slowly grows up into a beautiful swan. There’s basically just 3 other characters you should take note of. The prince, the raven and the knight. The prince is… okay but I think all the viewers are always rooting for the knight character instead. The raven is absolutely fantastic too. She’s not super evil as the story makes her out to be. That’s just her role in the “story”. I find the raven realizing her love and the prince reciprocating her affection is so romantic. Now shoo, give the ugly duckling and the knight their space. But alas, the new season will never come… ):The manga only has a few chapters before the anime studio snapped it up to spin their own take on it. In a way, this is an original anime. The most popular classical music they love to use in each episode is `}<RemoteLink to="https://www.youtube.com/watch?v=M8J8urC_8Jw&ab_channel=avrilfan2213avrilfan2213" mdxType="RemoteLink">{`Nutcracker `}</RemoteLink>{`and`}<RemoteLink to="https://www.youtube.com/watch?v=9cNQFB0TDfY&ab_channel=TheUnforgettablesTvTheUnforgettablesTv" mdxType="RemoteLink">{` Swan in the Lake`}</RemoteLink>{`. It’s really recognizable. Bonus track - `}<RemoteLink to="https://www.youtube.com/watch?v=YyknBTm_YyM&ab_channel=avrilfan2213avrilfan2213" mdxType="RemoteLink">{`Danse Macabre`}</RemoteLink>{`. I think this only plays in one of the episodes but I love it a lot.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Anime that deserves a reboot #4: Petite Princess Yucie`}</h2>
    <p>{`Similar to how the anime studio snapped up Princess Tutu, Petite Princess Yucie did have a manga. Only a few chapters though. Consider this an original anime!`}</p>
    <Image alt="Petite Princess Yucie" caption="I tried finding a good cover for this section but even this image doesn't have everyone in it. It's a good show!" name="petite-princess-yucie.jpg" mdxType="Image" />
    <p>{`Our heroine in this story is Yucie, a girl who never grew past 17. Due to a magical interference of sorts, her body remains a 10 year old. Desperate to grow up, she joined the prestigious Princess Academy where she learns magic, dance and etiquette… Everything a princess must know. But her goal is something else… The Eternal Tiara. This tiara grants the wearer one wish and Yucie hopes to use it to finally break the spell that was cast on her. But she’s not the only one vying for it! Each realm sent a princess candidate of their own with their own hopes too.It turns out, the Eternal Tiara was split into 5 fragments and would choose only one princess from each realm as its sole owner. Yucie must fight with the other 4 candidates to get her wish come true!`}</p>
    <p>{`So… are there any catfights in this story? Nah! They weren’t friendly at first but Yucie’s eagerness won them over. It’s a sweet story of romance and friendship but I wasn’t happy with the ending. Seriously, this series needs a reboot with a proper take with the friendship part! I find the romance part okay in general. It has enough moe scenes to get the viewers go `}<em parentName="p">{`Kyaa Kyaa!`}</em>{` But the ending really sours the whole thing. What’s the point if the Eternal Tiara is assembled then? I demand a reboot!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Anime that deserves a new season #5: Accel World`}</h2>
    <p>{`This is my personal hot take. I prefer Accel World over the author’s other work, Sword Art Online. Accel World has a wonderful take of embracing your own strength over your weaknesses. Inner beauty is, of course, as important as what’s on the outside.`}</p>
    <Image alt="Accel World" caption="Accel World poster <3" name="accel-world.jpg" mdxType="Image" />
    <p>{`Accel World has the plot centered on a game unbeknownst to the public - Brain Burst. In a world where you can login into cyberspace, Brain Burst doesn’t seem that unlikely. However, what you can do with the Burst Points is pretty much `}<em parentName="p">{`unreal.`}</em>{` You can slow down time in the real world with BP! A cyberspace skill that affects reality? This is pretty much why anyone who has access to this game got so obsessed with it. With BP, they could turn their lives around. Our hero is Haru, who was invited to play Brain Burst by Kuroyukihime after she saved him from delinquents. Haru has zero to none self confidence in him and now, he has to fight in Brain Burst if he wishes to help Kuroyukihime. Along the way, Haru gained allies, more confidence and self respect as he started to view his Brain Burst avatar as his second self. He is the first avatar who has wings. Just that alone garnered attention across the Brain Burst game. He feels indebted to Kuroyukihime who literally gave him wings to soar, and thus he agreed to help her reach Level 10 in this game. Level 10 is the highest anyone could hope to achieve, and it promises a meeting with the game’s creator. Just what is Brain Burst? Well, Kuroyukihime hopes to find out.`}</p>
    <p>{`Accel World has very little teen drama, save for the heart to heart scenes where Haru realizes his potential. Due to the Brain Burst effect, everyone looks like a teen but their mind has aged a ton due to the time slowing effect. Thus, everyone in this story has a really mature mindset and are absolute schemers since BP is way too good to give up. Kuroyukihime is the type of character that is done growing up. She’s a role model for our Haru. A goal. But even so, there’s still room to grow when it comes to the matters of the heart. I love stories that involve character growth, and Accel World excels in that by centering most of the growth through Brain Burst battles. It’s amazing stuff, really moves your heart. New season please! PLEASE!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Anime that deserves a new season #6: Moribito Guardian of the Spirit`}</h2>
    <p>{`This is that one title everyone agrees is good from the start to finish but the ending was so satisfying, no one bothered to request for a 2nd season. Now here’s the thing.. It’s so good to me that I think it deserves a new season that does the same thing as the first season. We need more Balsa!`}</p>
    <Image alt="Moribito cover" caption="Balsa <3" name="moribito.jpg" mdxType="Image" />
    <p>{`We follow the adventures of Balsa, a thirty year old female bodyguard who’s good at what she does. She wields her spear with ease, is fastidious, and would never back down from getting a job done. She was travelling around until one day, she saved a prince from drowning by accident. The queen invited her over to thank her, only for Balsa to notice that she has a hidden request for the female bodyguard for hire. The queen fervently pleaded with Balsa to save her son, Chagum, from being assassinated as ordered by the king. Balsa had no choice but to agree and fled quickly with the boy in tow.The choreography for her spear work is fascinating to look at. The way she spins her spear, jabs or even knocking down the assassins are really easy to follow! Chagum learning to humble himself is also a joy to watch. You could do away with the supernatural part of the story and I’ll still be okay with the plot! Honestly, the whole anime is mesmerizing to watch. The story, Balsa, the fight choreography... Now if they could continue Balsa’s story, that would be great!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Anime that deserves a new season #7: Panty & Stocking with Garterbelt`}</h2>
    <p>{`Y’all ended the anime with an open ending and not expect folks to request for a new season? Preposterous! Now if you have watched/heard of this anime, make sure you’re over 18! Not that it matters on the Internet but hey, I am a responsible blogger who makes sure my readers are well-informed.`}</p>
    <Image alt="Panty and Stocking with Garterbelt" caption="Panty and Stocking la la la~ The opening is pretty lit." name="panty-and-stocking-with-garterbelt.jpg" mdxType="Image" />
    <p>{`Panty and Stocking are two angels cast down from Heaven because they are super troublemakers. They were sent to Daten City to make amends by defeating evil spirits before they could come back up. The evil spirits are…. Well… Yea why not I add a few videos and let them do the talking. The English dub is pretty good!`}</p>
    <Video title="" src="https://www.youtube.com/embed/wwI6-sUflHY" mdxType="Video" />
    <Video title="" src="https://www.youtube.com/embed/HJPBr8OlmAM" mdxType="Video" />
    <p>{`Panty and Stocking with Garterbelt is my favourite not because it scratches my salacious niche, but the art direction! Ideas! Crude jokes! They are really super well done. Plus, when it comes down to it, it's hilarious. You know the fun you get watching Tengen Toppa Gurren Lagann? This is that kind of fun, but it’s more `}<em parentName="p">{`salacious`}</em>{`. I’m not too sure why S2 didn’t get the green light even after that open ending. Come on I need more naug-.. Ahem, more episodes! This is an original anime.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Anime that deserves a new season #8: Problem Children Are Coming from Another World, Aren’t They?`}</h2>
    <p>{`I love this show so much. Disregarding the fanservice, it’s as meta as it could get! You could tell the author did a ton of research just to lay down the rules of the world and lore. You could argue that all you need to do to learn about world myths is to watch this show.`}</p>
    <Image alt="Problem Children are coming... from... somewhere, I guess" caption="What a long title..." name="problem-children-are-coming-from-another-world-arent-they.jpg" mdxType="Image" />
    <p>{`I guess this is kinda like an isekai? Izayoi, Asuka and Yo are three children with supernatural powers from different timelines, summoned to a world called Little Garden that is in need of their “Gifts”. You see, this other world places strict importance on Gift Games, a game where you outdo the other party with the use of your Gifts. As they try to win more Gift Games to secure a better future for the community they joined, they eventually learn more about Little Garden.`}</p>
    <p>{`I personally really enjoyed Re:Creators which kinda worked the same way. Stories with their own meta are really, really fun to watch. Maybe because learning how the world works and making your way around those rules are one of the best fun I have while playing any video games. There’s a ton of mechanics deeply rooted into the world of Little Garden. We see Izayoi constantly testing things out while Asuka and Yo wanted to see how far their Gifts could go. True, these children are overpowered in this world but even so, they could never match up with demon lords from Little Garden.`}</p>
    <p>{`If this gets a new season, I’m super excited to see how these kids will go up against even stronger communities!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Anime that deserves a new season #9: Yona of the Dawn`}</h2>
    <p>{`Best shoujo that came out these last few years. Best guy characters. Best backstories. Best heroine growth. Nuff said, bring on S2!`}</p>
    <Image alt="Akatsuki no Yona cute af" caption="GIVE. NEW. SEASON." name="yona_blog.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`Anime that deserves a new season #10: Otome Youkai Zakuro`}</h2>
    <p>{`Let's give a big applause to a josei romance that respects both parties, and does not shy from showing the goodness in every character there is. I was super disheartened when the hiatus happened because the author announced that she had to take care of her newborn son. As an aunt who has to watch the toddlers, I totally understand her situation. She did post a few chapters before going MIA again though. Here’s hoping she continues her story soon!`}</p>
    <Image alt="Otome Youkai Zakuro" caption="The girls <3" name="otome-youkai-zakuro.jpg" mdxType="Image" />
    <p>{`Otome Youkai Zakuro takes place in an alternate Japan, where westernization has made it a tad difficult for humans and spirits to live alongside each other. In order to maintain harmony between the two kinds, human representatives were chosen and sent to the Ministry of Spirit Affairs to start a partnership of sorts. From the spirit side are three half-spirit girls - Zakuro, Susukihotaru and Bonbori & Hozuki. From the human side, Kei, Riken and Ganryu who are military men. Together, they would set off to solve problems that stem from human & spirit misunderstandings.`}</p>
    <p>{`The partnership just screams couple setup and you’re damn right. And I love it. Zakuro dislikes Jesuit practices while her partner Kei has a fear of spirits. Both still overcome their fear and dislike because they see something good in each other. Susukihotaru is afraid of Riken because he towers over her. She changed her mind when she saw how Riken constantly tries to give her solace and comfort by adjusting to her pace. He even crouched down because he overheard her saying how she is afraid of his height. The last two girls are Bonbori and Hozuki. These two are twins and no one could tell them apart. They were inseparable since birth so Ganryu had to work extra hard to fit into their world. But he didn’t need to do that since he’s the first person to see them for who they are.Aren’t these couples the sweetest things. Season 2! SEASON 2! I need more fluff!!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`All in all`}</h2>
    <p>{`So what’s your personal favourite anime that deserves a reboot/new season? I know for sure there’s a ton of good anime out there that needs more content! I hope you enjoy my silly writing. And yes, I’m still sad these didn’t get any reboot/new season news while Shaman King got one. Damnit…`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      