import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const RemoteLink = makeShortcode("RemoteLink");
const LocalLink = makeShortcode("LocalLink");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`After clocking in a disgusting amount of time into Genshin Impact, you start to see the sort of team composition pattern you tend to prefer in this game. My honest to goodness favourite team composition has always been Geo due to how useful Geo resonance is. But as of late I couldn’t deny how strong the Melt and Vape team composition is if you’re willing to put in the extra brain cells to set it up. Boom boom 30K damage per screenshot guaranteed! Obviously the numbers aren’t the best for whales or meta players but honestly? It’s good enough to clear the content.`}</p>
    <p><em parentName="p">{`Note: Using the terms Elemental Burst or Elemental Skill is a bit wordy, so I will be using the short form Q for Elemental Burst and E for Elemental Skill.`}</em></p>
    <p>{`The game has been nice to me so far by giving me at least 1 copy of each 4-star to play with. You’re given a free Fischl, Diona and in 2.0 update, a free Beidou! I have built most of the meta 4-star ones for Spiral Abyss use so the rest of the 4-star is just icing on top. The 4-star(s) have really enjoyable utility for support but what stood out to me are the 4-star DPS(s). Of course, their damage couldn’t beat the 5-star characters but as 4-star ones, they are good enough. Here are my personal top 3 DPS/Sub DPS characters and how to build them <3`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Top 3 Favourite Genshin Impact DPS #1: Ningguang`}</h2>
    <p>{`You really can’t go wrong with `}<RemoteLink to="https://genshin.honeyhunterworld.com/db/char/ningguang/?lang=EN" mdxType="RemoteLink">{`Ningguang `}</RemoteLink>{`when it comes to impressive charge attacks, great kit synergy and her super bursty Q. Her charge attack has an impressive multiplier, her Q is easy to fill and she just wrecks everything if built right. All of her constellations are made to increase her damage to impressive levels. Obviously, her damage output couldn’t beat the Pyro/Cryo 5-stars but as a Geo 4-star character, she’s amazingly good.`}<LocalLink to="/blog/genshin-impact-zhongli-and-ningguang-geo-team-build-guide/" mdxType="LocalLink">{`I once wrote a guide for her here`}</LocalLink>{` which I would say, still relevant for Genshin Impact’s v2.0 update. But let me summarize how to build her here:`}</p>
    <Image alt="Ningguang smiling" caption="Ningguang doesn't have her own showcase trailer by miHoYo! HERESY." name="ningguang-genshin-impact.jpg" mdxType="Image" />
    <h3>{`Best Artifact Set`}</h3>
    <p>{`Her best and most ubiquitous artifact set is 2 set `}<RemoteLink to="https://genshin.honeyhunterworld.com/db/art/family/a_15001/?lang=EN" mdxType="RemoteLink">{`Gladiator`}</RemoteLink>{` and 2 set `}<RemoteLink to="https://genshin.honeyhunterworld.com/db/art/family/a_15014/?lang=EN" mdxType="RemoteLink">{`Petra`}</RemoteLink>{`. You could build 4 set `}<RemoteLink to="https://genshin.honeyhunterworld.com/db/art/family/a_15015/?lang=EN" mdxType="RemoteLink">{`Bolide `}</RemoteLink>{`for her, but the 2 set combo is the easiest to build and min-max for her. (Plus, not conditional to use) If you want to know how most F2P characters clear 36 stars in Spiral Abyss, they did it by min-maxing their artifacts. And by that, I mean utilizing the sub-stats on artifacts to the max. So if you want Ningguang to hit hard, you’ll have to try your best to give her a high crit rate and crit damage. Many say that the rule of thumb is to hit 1:2 ratio for the crit rate:crit damage split and I agree… somewhat. The best crit rate to have on any DPS is a 60 and above. This ensures that they crit `}<strong parentName="p">{`more often`}</strong>{`. (This is tried and tested. My Ningguang has a 70% crit rate and she crits `}<strong parentName="p">{`a lot`}</strong>{`) Try your best on crit damage and you’re good to go!`}</p>
    <Ads mdxType="Ads" />
    <h3>{`Best Weapon`}</h3>
    <p>{`Anything that boosts her elemental attacks or charge attacks. She doesn’t need Energy Recharge because her Q only costs 40 energy to fill and she fills it on her own really fast. Even faster if you have another Geo character in the team. You could use `}<RemoteLink to="https://genshin.honeyhunterworld.com/db/weapon/w_5312/?lang=EN" mdxType="RemoteLink">{`Dodoco Tales`}</RemoteLink>{` or `}<RemoteLink to="https://genshin.honeyhunterworld.com/db/weapon/w_5307/?lang=EN" mdxType="RemoteLink">{`Solar Pearl`}</RemoteLink>{` for easy damage. I’m currently using `}<RemoteLink to="https://genshin.honeyhunterworld.com/db/weapon/w_5402/?lang=EN" mdxType="RemoteLink">{`Skyward Atlas`}</RemoteLink>{` and it’s the best! If you’re using `}<RemoteLink to="https://genshin.honeyhunterworld.com/db/weapon/w_5303/?lang=EN" mdxType="RemoteLink">{`Mappa Mare`}</RemoteLink>{`, I’ll have to question your decision...`}</p>
    <h3>{`Best Team Composition`}</h3>
    <p>{`Ningguang `}<strong parentName="p">{`must be used`}</strong>{` in a `}<RemoteLink to="https://genshin-impact.fandom.com/wiki/Elemental_Resonance" mdxType="RemoteLink">{`Geo Resonance`}</RemoteLink>{` team with a shield. The other 2 team member slot is icing on the cake, use whoever you like. The reason I’m saying this is because:`}</p>
    <ol>
      <li parentName="ol">{`Geo Resonance team is amazing. You get a shield and boom, damage dealt increases by 15% and enemies Geo RES decrease by 20%. If that doesn’t scream good damage buff, I don’t know what is.`}</li>
      <li parentName="ol">{`Ningguang has paper thin defense. She `}<strong parentName="li">{`needs`}</strong>{` a shield because she just ain’t made to be running around dodging. Her attacks took way too long to start up, not to mention her charge attack.`}</li>
      <li parentName="ol">{`Her kit is basically made to be used with a Geo Resonance team due to her Geo-based attacks. Pleaseee! Use her with another Geo character!`}</li>
    </ol>
    <h3>{`Best Attack Pattern`}</h3>
    <p>{`1 normal attack, 1 charge attack. Hit the enemies hard and `}<strong parentName="p">{`fast.`}</strong>{` Use as you see fit as you manage your stamina gauge. Use your E first, run through it for a Geo DMG bonus buff then use your Q. Watch with glee as your Jade Screen (E) fires the next volley of bullets.When using Q, you might notice her bullets hitting the walls of a dungeon/cave. Her Q is best used in an open area and right in front of the enemy to prevent them from… not hitting the enemy. Make sure to give some space between you and the enemy too so the targeting AI doesn't go bust! I notice my Q flying up and away even though I am standing super close and right in front of the enemy. Sigh. This is a problem since forever! When will you fix this, miHoYo?`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Top 3 Favourite Genshin Impact DPS #2: Yanfei`}</h2>
    <p>{`I remember being really hyped for this Pyro lawyer because I do not have a Pyro DPS. I have absolutely no regrets after building her to her fullest(?) potential. `}<RemoteLink to="https://genshin.honeyhunterworld.com/db/char/feiyan/?lang=EN" mdxType="RemoteLink">{`Yanfei `}</RemoteLink>{`can easily hit 10K damage with her charge attack/E without needing a damage buff! That sounds good on paper until you realize the staggering amount of stamina her charge attack needs… Her C6 helps to reduce that stamina needs to a zero but I ain’t got a C6 Yanfei. As her damage relies a lot on charge attack, I find it really hard to use her in Spiral Abyss since you’ll be running around a lot. At least Ningguang is easier to use since her Q and E is easier to fill up/fast cooldown. For Floor 9 to 10, she’s good enough if you know what you’re doing. Depending on enemy layout, you are better off bringing someone else for Floor 11 and 12.`}</p>
    <Image alt="Yoimiya trailer screenshot" caption="Before I got Yoimiya, I really like her! Really good Pyro DPS character." name="yanfei-genshin-impact.jpg" mdxType="Image" />
    <p>{`Nevertheless, Yanfei’s strength lies in being the one to proc `}<strong parentName="p"><RemoteLink to="https://genshin-impact.fandom.com/wiki/Melt" mdxType="RemoteLink">{`Melt`}</RemoteLink>{`/`}<RemoteLink to="https://genshin-impact.fandom.com/wiki/Vaporize#:~:text=Type&text=Vaporize%20is%20the%20Elemental%20Reaction,attack%20that%20triggers%20the%20reaction." mdxType="RemoteLink">{`Vape`}</RemoteLink></strong>{` using her charge attack or E. Her solo damage is good enough but wow, boom boom 30K damage guaranteed using Melt and without any external buff! Now imagine the potential damage if you use her inside Bennett's Q, Mona's Q and Swirl shred...`}</p>
    <Ads mdxType="Ads" />
    <h3>{`Best Artifact Set`}</h3>
    <p>{`If you’re playing Melt/Vape/Overload teams, you really, really need a 4 set `}<RemoteLink to="https://genshin.honeyhunterworld.com/db/art/family/a_15006/?lang=EN" mdxType="RemoteLink">{`Crimson Witch`}</RemoteLink>{` (CW) on Yanfei. You could use 4 set `}<RemoteLink to="https://genshin.honeyhunterworld.com/db/art/family/a_15003/?lang=EN" mdxType="RemoteLink">{`Wanderer Troupe`}</RemoteLink>{` (WT) like me if you have good sub-stats on them but consider it a transition set. If you play Yanfei for some time, you’ll notice that it's easy to proc Melt/Vape using her for some impressive damage. WT is okay if you play solo but you’re missing out on CW goodness in Melt/Vape teams.`}</p>
    <p>{`As usual, balance her crit rate and crit damage like any DPS. Her attack should be 1.6K (1.6K is actually a decent attack for Yanfei if you don’t have 5-star catalyst) and above with at least 60 crit rate on her. I didn’t `}<em parentName="p">{`try`}</em>{` to stack Elemental Mastery on her (WT gives a decent 80 for starters) because her crits are so much more important. She has a passive that adds a second attack during her charge attack if you crit so the higher the crit rate on her, the better.`}</p>
    <h3>{`Best Weapon`}</h3>
    <p>{`Treat Yanfei like how you treat Ningguang or any DPS catalyst user. Most of her damage comes from her charge attack so Dodoco Tales passive fits her. I am currently using Solar Pearl on her and I think that does its job pretty well. I tried Mappa Mare on her and it’s… definitely interesting. Her reaction attacks are pretty nice but her normal attacks are bleah.`}</p>
    <Ads mdxType="Ads" />
    <h3>{`Best Team Composition`}</h3>
    <p>{`If you’re playing in a mono pyro Yanfei DPS team, have fun with 2 Pyro characters and 2 Geo characters. Boom boom attack buffs with a shield on! If you want to play Melt team, make sure to add 2 Cryo characters for the `}<RemoteLink to="https://genshin-impact.fandom.com/wiki/Elemental_Resonance" mdxType="RemoteLink">{`Cryo Resonance`}</RemoteLink>{` buff. Honestly, it’s so much fun. I enjoy using Diona, Rosaria along with Bennett in a team with Yanfei. As for Vape teams, Xingqiu is a must of course. Honestly, Yanfei can be slotted into any team that uses reaction and she’ll do just fine. I tried Overload once with a non-C6 Fischl and a C2 Beidou. Absolute chaos! Plus, all of Yanfei’s attacks home in on the enemies. Alas, my Overload damage is not that good so I reverted back to a Melt team.Yanfei’s E and Q can hit enemies in Venti’s Q, but her charge attack could only hit enemies `}<strong parentName="p">{`on the ground`}</strong>{`. If the enemy is flying around, you’re outta luck.`}</p>
    <h3>{`Best Attack Pattern`}</h3>
    <p>{`3 normal attacks followed by 1 charge attack. Use as you see fit as you monitor her stamina gauge. Use her Q, and attack as per usual. However, I find myself using her charge attack and E more often than I use Q. Usually I use Q whenever I wish to proc Melt quickly by standing inside a group of enemies. Just keep using charge attack when possible for the best Melt/Vape damage and you should do fine.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Top 3 Favourite Genshin Impact Sub DPS #3: Rosaria`}</h2>
    <p>{`I wasn’t sold on her at first. I tried building her as a physical DPS and I was left with a `}<em parentName="p">{`meh`}</em>{` by her damage output. However! `}<RemoteLink to="https://genshin.honeyhunterworld.com/db/char/rosaria/?lang=EN" mdxType="RemoteLink">{`Rosaria’s `}</RemoteLink>{`true value lies in her E and Q! Her E refreshes super fast while her Q does decent damage at every pulse and at the beginning. Her Q fills up really fast too if you have 160% Energy Recharge (ER) or when teamed up with a fellow Cryo user. I use her solely as an E/Q spammer for Spiral Abyss. She’s really nice in a Melt team too. Her charge attack can do some decent damage if you need her on field.`}</p>
    <h3>{`Best Artifact Set`}</h3>
    <p>{`As a sub DPS, her easiest to use and farm for artifact sets are 2 set `}<RemoteLink to="https://genshin.honeyhunterworld.com/db/art/family/a_14001/?lang=EN" mdxType="RemoteLink">{`Blizzard Strayer`}</RemoteLink>{` and 2 set`}<RemoteLink to="https://genshin.honeyhunterworld.com/db/art/family/a_15007/?lang=EN" mdxType="RemoteLink">{` Noblesse Oblige`}</RemoteLink>{`. However, I only have 2 set Noblesse equipped on her at this time of writing. The other two artifacts are whatever good crit sub-stats I could stack on her. My personal view on this is that 1) I don’t need Rosaria to hit hard. I just need her to hit decent. 2) She needs to spam E and Q often for battery and whenever possible, enable Melt/`}<RemoteLink to="https://genshin-impact.fandom.com/wiki/Frozen" mdxType="RemoteLink">{`Freeze`}</RemoteLink>{`. Plus, I try my best to build most of my characters as standalone good characters so they could fit into any team whenever I feel fancy. Not to mention her passive enables her to increase crit rate (based on her own crit rate) of nearby party members if she uses Q.`}</p>
    <h3>{`Best Weapon`}</h3>
    <p>{`For fastest Q spamming, use `}<RemoteLink to="https://genshin.honeyhunterworld.com/db/weapon/w_3307/?lang=EN" mdxType="RemoteLink">{`Favonius Lance`}</RemoteLink>{` or `}<RemoteLink to="https://genshin.honeyhunterworld.com/db/weapon/w_3306/?lang=EN" mdxType="RemoteLink">{`Prototype Starglitter`}</RemoteLink>{`. For Physical Rosaria, use `}<RemoteLink to="https://genshin.honeyhunterworld.com/db/weapon/w_3302/?lang=EN" mdxType="RemoteLink">{`Crescent Pike`}</RemoteLink>{`. I am currently using `}<RemoteLink to="https://genshin.honeyhunterworld.com/db/weapon/w_3303/?lang=EN" mdxType="RemoteLink">{`Deathmatch `}</RemoteLink>{`since that makes my life easier by adding Crit Rate to Rosaria.`}</p>
    <Ads mdxType="Ads" />
    <h3>{`Best Team Composition`}</h3>
    <p>{`She’s best in a Cryo Resonance team, seriously. She fits into a Melt and Freeze team too! Using her in a Swirl team is also super fun. Her Q can hit enemies in Venti’s Q. She’s that easy to use once you keep spamming her E and Q. Her Q only does 4 pulses before it expires (Unless you have her C2 which… I don’t have), and her Q’s AOE isn’t exactly super big like Ganyu’s. Nevertheless, it is enough.`}</p>
    <h3>{`Best Attack Pattern`}</h3>
    <p>{`Use Q whenever it’s filled. Use E to battery the team and yourself. Everything just… dies so fast if you keep up the E and Q spamming.`}</p>
    <h2>{`All in all`}</h2>
    <p>{`I hope this helps to understand how folks play their 4-star DPS characters to the best they could! Honestly, you could play however you want but understand that any character can be OP if you know what you’re doing. Min-max your artifacts, play reaction-based comps, understand the characters’ passive buffs and use Party Resonance to buff your teammates. And remember! `}<strong parentName="p">{`You have food buffs too.`}</strong>{` Enjoy!`}</p>
    <Ads mdxType="Ads" />
    <p>{`Extra Note: Or, you could just… check out this Discord bot called `}<RemoteLink to="https://galaxybot.net/start/" mdxType="RemoteLink">{`Galaxy bot`}</RemoteLink>{`. If you’re in a Discord, please give this a try. I have tried the bot and the build guides are really good. Or perhaps you could try `}<RemoteLink to="https://keqingmains.com/" mdxType="RemoteLink">{`KeqingMains`}</RemoteLink>{`. They know their stuff.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      