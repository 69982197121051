import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Hello everyone! It's been ages since my last blog update. I'm writing this post to tell everyone what I've been doing for the past year(?). Wait, has it been a year already?`}</p>
    <p>{`It was all about work at first. Things got really busy at work, and I've just been trying to learn as much as possible to upskilling myself. I'm terrible at multitasking so it's either work or blog. So I choose the former.`}</p>
    <p>{`Once the dust settled, I find myself... struggling with self-worth. I swear, social media platforms are the absolute worst. Everyone I know is either getting married or their career is going swimmingly. I wasn't doing bad myself but compared to everyone on the Internet? I'm doing badly, apparently. I tried my best to stay away from these negative thoughts but one way or another, family and friends will eventually ask me questions that made me rethink my lifestyle.`}</p>
    <p>{`So I told myself this really can't go on. I need to do something about my life, so I decided to aim for low-hanging fruits. I'm of the opinion that if you start small, you'll feel more fulfilled and it gets easier to find the will to keep going.`}</p>
    <Ads mdxType="Ads" />
    <p>{`The first thing I did (for the sake of my mental health) is to delete all of my personal social media accounts. I steered away from social media unless I have to and keep in touch with my close friends and family only. If I'm being honest, this is the best thing I've done. I'm so much happier not oversharing AND knowing every little details of an acquaintance's life. They can come tell me in person if they wish to, thank you.`}</p>
    <p>{`The next thing I did, is to work on my weight. Due to my sedentary lifestyle, I consider myself to be on the heavier side. I'm not saying I'm absolutely okay with my weight but as usual, everyone has something to say whenever they see me in person. This really irked me since I can't exactly jab back, "Did I ask?" without being rude. So I decided to shut them up the healthier way; it's time to lose weight.`}</p>
    <p>{`Boy, it was worth it. Everyone who met me no longer call me fat. They ask me how I did it instead.`}</p>
    <p>{`Not long after, I decided to do an even bigger change in my life. I've been wanting to move out once my finance is in a better shape, so I did. Getting used to the new daily routine in a place far from home took a while, but I think I'm finally settled in.`}</p>
    <p>{`So who's up for more posts about games, anime and manga?`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      