const layout = (() => {
  const padding = 5;
  const margin = 5;
  const obj = {};
  let i = 0;

  for (; i <= 10; i += 1) {
    obj[`padding${i}`] = padding * i;
    obj[`margin${i}`] = margin * i;
  }

  return obj;
})();

const borderRadius = (() => {
  const radius = 5;
  let i = 0;
  const obj = {};

  for (; i <= 10; i += 1) {
    obj[`radius${i}`] = radius * i;
  }

  return obj;
})();

const variousColors = {
  color: {
    red1: '#ff6666',
    red2: '#a12b1b',
    green1: '#44cf6c',
    green2: '#41a35d',
    orange: '#EC682B',
    white: '#fff',
    black: '#000',
    lightblack: '#444242',
    yellow: '#f7d95e',
  },
  night: '#15202b',
  blue1: '#00acee',
  blue2: '#3b5998',
  white1: '#FAFAFA',
  brown1: '#f0dccb',
  brown2: '#b2967d',
  brown3: '#BCAAA4',
  brown4: '#553829',
  brown5: '#6D4C41',
  grey1: '#EBEBEB',
  grey2: '#a1a1a1',
};

const globalStyling = {
  ...variousColors,
  transition: 'cubic-bezier(0.4, 0, 0.2, 1)',
  layout,
  borderRadius,
  br: `br {
    content: '';
    display: block;
    margin: 0.75em 0;
  }`,
  verticalScroll: `
    /* width */
    ::-webkit-scrollbar {
      width: 10px;
    }
  
    /* Track */
    ::-webkit-scrollbar-track {
      background: ${variousColors.brown1};
      border-radius: ${borderRadius.radius1}px;
    }
  
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${variousColors.brown4};
      border-radius: ${borderRadius.radius1}px;
    }
    `,
  horizontalScroll: `
    /* height */
    ::-webkit-scrollbar {
      height: 10px;
    }
  
    /* Track */
    ::-webkit-scrollbar-track {
      background: ${variousColors.brown1};
      border-radius: ${borderRadius.radius1}px;
    }
  
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${variousColors.brown4};
      border-radius: ${borderRadius.radius1}px;
    }
    `,
  screen: {
    mobile: '@media only screen and (max-width: 767px)',
    tablet: '@media (min-width: 768px) and (max-width: 1199px)',
    tabletA: '@media (min-width: 768px) and (max-width: 899px)',
    tabletB: '@media (min-width: 900px) and (max-width: 1199px)',
    desktop: '@media only screen and (min-width: 1200px)',
    pwa: '@media all and (display-mode: standalone)',
  },
  ellipsis: 'white-space: nowrap; overflow: hidden; text-overflow: ellipsis;',
  shadow: {
    normal: '0px 3px 6px rgba(0, 0, 0, 0.1)',
    repressed: '0px 3px 8px rgba(0, 0, 0, 0.15)',
  },
};

export default globalStyling;
