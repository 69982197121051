import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const RemoteLink = makeShortcode("RemoteLink");
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const Blockquote = makeShortcode("Blockquote");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I was chatting with `}<RemoteLink to="https://twitter.com/YumDeku" mdxType="RemoteLink">{`YumDeku`}</RemoteLink>{` one fine day, and the topic of site speed cropped up. It then occurred to me that some things I thought are common knowledge is only `}<strong parentName="p">{`because`}</strong>{` I encountered them often while doing web development. I face a screen as my job for at least 8 hours a day, and I know my way around the web a bit. I thought I should share some of what I knew that may be of help to you as a blogger or just a general web user. I hope you find them useful! If you have tips not mentioned here, please share at the comment section below :)`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Screenshot a full web page directly in Google Chrome`}</h2>
    <p>{`It sounds magical but it really isn’t. You can screenshot the web page you’re in using Google Chrome’s Developer Tools. Here’s how to do it.`}</p>
    <ol>
      <li parentName="ol">{`Open up your Developer Tools by clicking on the triple dot menu on Google Chrome, and choose More Tools > Developer Tools. You could use the keyboard shortcut as well. `}<em parentName="li">{`Ctrl + Shift + I`}</em>{` for Windows, and `}<em parentName="li">{`Cmd + Shift + I`}</em>{` for Mac.`}</li>
      <li parentName="ol">{`Now that the Developer Tools is open, press `}<em parentName="li">{`Ctrl + Shift + P`}</em>{` for Windows, or `}<em parentName="li">{`Cmd + Shift + P`}</em>{` for Mac. A hovering bar should appear.`}</li>
      <li parentName="ol">{`Type '`}<em parentName="li">{`screenshot' `}</em>{`and 4 screenshot options should appear. These options should be self-explanatory. Try it out!`}</li>
    </ol>
    <Image alt="List of options in Chrome's Developer Tools for screen capture." caption="I know, I was mind-blown when I first found out about this." name="screenshot_devtool_chrome.jpg" mdxType="Image" />
    <h2>{`Audit your blog’s performance using Lighthouse Audit`}</h2>
    <p>{`In every Google Chrome’s Developer Tools, there’s a tab titled `}<strong parentName="p">{`Audits. `}</strong>{`This tool helps developers to gauge whether our site is performant and SEO-friendly or not. I know most folks are Wordpress users, so you shouldn’t have to worry about your site since Wordpress does most of the job for you. However, adding Wordpress plugins or having a ton of third-party scripts may slow your site down. At the very least, running this tool can give you a better idea on how to improve your site’s speed and get into Google’s good books. Here’s how to access and use the Audits tool.`}</p>
    <Ads mdxType="Ads" />
    <ol>
      <li parentName="ol">{`Open up your Developer Tools by clicking on the triple dot menu at the top right corner in Chrome, and choose More Tools > Developer Tools. You could use the keyboard shortcut as well. Ctrl + Shift + I for Windows, and Cmd + Shift + I for Mac.`}</li>
      <li parentName="ol">{`At the very top of the Developer Tools, you should see a long line of tabs starting from `}<em parentName="li">{`Element`}</em>{`. There should be an `}<em parentName="li">{`Audits`}</em>{` tab down the line. If you don’t see it, click on the arrows to show a dropdown. Click on the `}<em parentName="li">{`Audits`}</em>{` tab.`}</li>
      <li parentName="ol">{`Let’s check how our site performs in a simulated Mobile phone. Check all options under the `}<em parentName="li">{`Audits section`}</em>{`, choose Mobile device, No throttling and Clear storage. Run the audits. Now we wait for the results to return.`}</li>
      <li parentName="ol">{`The best thing about this tool isn’t the numbers they show you. It’s the checklist that tells you what and how to improve your site. Try your best to follow them to improve your site’s performance!`}</li>
    </ol>
    <Image alt="Google's Lighthouse audit options." caption="Choose to simulate a Mobile device or on a desktop." name="lighthouse_audit_options.jpg" mdxType="Image" />
    <Image alt="Google's Lighthouse audit's sample diagnostics result." caption="Try to aim for a score of 80 and above. You'll find more information on how to improve the scores if you check the result." name="lighthouse_audit_diagnostics.jpg" mdxType="Image" />
    <p>{`A fair warning - this tool should not be used as a black-and-white indicator of your site’s performance. Use it as a “fairly good guess” on your site’s speed and performance.`}</p>
    <h2>{`Use Grammarly to improve… your grammar`}</h2>
    <p>{`My English commandment isn't the best and I have spelling errors often. So I rely on `}<RemoteLink to="https://www.grammarly.com/" mdxType="RemoteLink">{`Grammarly`}</RemoteLink>{` to check my writing for me. It’s a great tool that runs seamlessly in your browser as an add-on. It also has an IOS and an Android app but I rarely use them. It checks my writing as I type in Google Docs and in text boxes. Basically, as long as Grammarly can catch what you’re writing (certain sites block Grammarly due to the way they code their site), it can check your writing. It’s also free! You could use it to check any word definition by double-clicking on a word in a page too.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Expanded page for Grammarly tool." caption="You can expand Grammarly and it'll tell you all you need to know to improve your writing and spelling." name="grammarly_expanded.jpg" mdxType="Image" />
    <p>{`Another great feature it has is that it tells me what type of mood I am presenting to the reader as I type. This is great when writing emails or replying someone at work. If I get really tired, my curt responses may sound really rude, or I may accidentally overspeak in my emails without me noticing. Grammarly checks that and tells you if your tone is too casual, serious or fun. Great tool overall.`}</p>
    <Image alt="Grammarly tone checker." caption="I sound neutral. That's good. Thanks Grammarly!" name="grammarly_tone.jpg" mdxType="Image" />
    <p><em parentName="p">{`EDIT: After using this tool for some time, I notice that it keeps giving me weird sentence choices and call that the go-to sentence to use. I have since uninstalled Grammarly app from my browser. I do use the Grammarly site once in a while to get confirmation on a short sentence or so. If your English language mastery requires Grammarly, do give it a try. If your English mastery is pretty good however, you don't need this tool.`}</em></p>
    <h2>{`Record your screen and turn it into a GIF`}</h2>
    <p>{`I like gifs. It’s like a mini video that doesn’t need to be played beforehand. There are times I find some really cool things happening on my screen, but I do not like any of the screen recorder apps out there. Then`}<RemoteLink to="https://giphy.com/apps/giphycapture" mdxType="RemoteLink">{` Giphy Capture`}</RemoteLink>{` came along. Bless this useful app. All you need to do is select which area on your screen you wish to record, press Play and stop when you think it’s enough. Click on a few options and voila, a gif is made. Impressively easy to use too.`}</p>
    <Image alt="Giphy Capture for Mac only." caption="The user interface is really simple. I really like it on Mac." name="giphy_capture_ad.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <p>{`Unfortunately, it’s only available for Mac users. So I looked around for a Windows alternative and I found`}<RemoteLink to="https://www.screentogif.com/" mdxType="RemoteLink">{` ScreenToGif`}</RemoteLink>{`. I gave it a whirl and honestly, it has a ton of features. I think it’s not too bad. You can even create APNG with this! It’s also an open-source project so it is unlikely for it to stop getting fixes and updates.`}</p>
    <Image alt="Screentogif user interface." caption="Screentogif user interface for Windows. It's not as simple as Giphy Capture, but it's pretty damn powerful." name="screentogif_user_interface.jpg" mdxType="Image" />
    <h2>{`Screen capture made easy with Lightshot`}</h2>
    <p>{`Mac’s screen capture tool is amazing. It lets you choose whether to capture an area, a window or copy the screenshot directly to your clipboard. Sadly, Window's print screen tool is not as fancy as Mac’s. I tried Greenshot at first, but my Windows machine is really old, and `}<RemoteLink to="https://getgreenshot.org/" mdxType="RemoteLink">{`Greenshot `}</RemoteLink>{`makes it chug really hard. Poor Windows. So I uninstalled it and tried `}<RemoteLink to="https://app.prntscr.com/en/index.html" mdxType="RemoteLink">{`Lightshot`}</RemoteLink>{`. Boy, it’s such a vast improvement. You only need to press Print Screen to trigger Lightshot, and all its functionality is made available to you within a single press of a key. It's available for Mac and Windows.`}</p>
    <Image alt="Lightshot ad for Mac." caption="Lightshot is really lightweight and responsive." name="lightshot_for_mac.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`Connect your social media accounts through IFTTT`}</h2>
    <p>{`The credit for this tip goes to `}<RemoteLink to="https://twitter.com/MoeGamer" mdxType="RemoteLink">{`Pete (MoeGamer)`}</RemoteLink>{`. This is an amazing platform for applets. Here's a quick definition pulled from Oxford dictionary on what an applet is.`}</p>
    <Blockquote mdxType="Blockquote">
  A very small application, especially a utility program performing one or a few
  simple functions.
    </Blockquote>
    <p>{`Login to `}<RemoteLink to="https://ifttt.com/" mdxType="RemoteLink">{`IFTTT`}</RemoteLink>{`, and check out some of the applets fellow users have submitted. You can schedule Tweets using Google Calendar, or thank your Twitter followers automatically. All you need to do is click a few buttons, sync with your account and it goes live! There are even applets for Facebook or Instagram.`}</p>
    <Image alt="List of applets for Twitter on IFTTT." caption="List of applets for Twitter alone in IFTTT. This is the first I see a platform that connects two social medias directly." name="applets_on_ifttt.jpg" mdxType="Image" />
    <p>{`That’s all I have for now. Maybe I'll add more tips down the line, or even write one blogpost for SEO.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      