import { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../styles/globals';
import Bubble from './Bubble';
import EmailIcon from '../../assets/icons/email.svg';
import { randomInt } from '../../utils';

const Container = styled.div`
  margin-bottom: ${$.layout.margin4}px;
  background-color: ${$.color.white};
  box-shadow: ${$.shadow.normal};

  ${$.screen.desktop} {
    display: flex;
    flex-direction: row;
  }

  ${$.screen.tablet} {
    display: flex;
    flex-direction: row;
  }

  ${$.screen.mobile} {
    width: 100%;
  }
`;

const ProfilePic = styled.div`
  background-color: ${({ bgColor }) => bgColor};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(40% - 20px);
  padding: 10px;

  ${$.screen.desktop} {
    align-items: flex-start;
    justify-self: stretch;
  }

  ${$.screen.tablet} {
    justify-self: stretch;
  }

  ${$.screen.mobile} {
    width: 100%;

    > * {
      width: 50%;
    }
  }
`;

const ProfileCard = styled.div`
  text-align: left;
  padding: ${$.layout.padding2px} ${$.layout.padding3}px;
  width: calc(60% - ${$.layout.padding3 * 2}px);

  ${$.screen.mobile} {
    width: calc(100% - ${$.layout.padding3}px * 2);
  }
`;

const Name = styled.div`
  font-family: Rubik Regular;
  font-weight: bold;
  text-transform: uppercase;
  color: ${$.brown5};
  font-size: 26px;
  margin-bottom: ${$.layout.margin1}px;

  ${$.screen.mobile} {
    font-size: 20px;
  }
`;

const Type = styled.div`
  font-family: Rubik Regular;
  font-weight: bold;
  text-transform: uppercase;
  color: ${$.brown4};
  font-size: 16px;
  margin-bottom: ${$.layout.margin3}px;
`;

const SukiKiraimono = styled.table`
  font-family: Rubik Regular;
  width: 100%;
  color: ${$.brown4};
  border: 2px solid ${$.brown3};
  font-size: 16px;
  margin-bottom: ${$.layout.margin5}px;

  td {
    padding: ${$.layout.padding1}px ${$.layout.padding2}px;
  }

  tr {
    &:first-child {
      border-bottom: 2px solid ${$.brown3};
    }
    > td {
      &:first-child {
        font-family: Rubik Regular;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 15px;
        border-right: 2px solid ${$.brown3};
      }
    }
  }
`;

const OpenEmail = styled.a`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  margin-bottom: ${$.layout.margin3}px;
  > svg,
  > div {
    transition: all 0.3s ease;
    fill: ${$.brown4};
    color: ${$.brown4};
  }

  &:hover {
    > svg {
      fill: ${$.color.orange};
    }
    > div {
      color: ${$.color.orange};
    }
  }
`;

const StyledEmail = styled(EmailIcon)`
  fill: ${$.brown4};
  width: 30px;
  display: inline-block;
  margin-right: ${$.layout.margin1}px;
`;

const EmailAddText = styled.div`
  font-size: 20px;
  color: ${$.brown5};
  display: inline-block;
`;

const Quote = styled.div`
  font-family: Rubik Regular;
  font-style: italic;
  color: ${$.brown3};
  text-align: center;
  font-size: 16px;
  line-height: 1.2em;

  ${$.br}
`;

const Title = styled.div`
  font-family: Rubik Regular;
  font-weight: bold;
  color: ${$.brown4};
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 1px;
  align-self: center;
  text-align: center;
  margin-bottom: ${$.layout.margin5}px;
  padding: 0 ${$.layout.padding2}px;

  ${$.screen.desktop} {
    margin-bottom: ${$.layout.margin1}px;
  }
`;

const Card = ({
  quoteList,
  email,
  fullName,
  type,
  likes,
  dislikes,
  bgColor,
  image,
  title,
  className,
}) => {
  const [chosenQuote, setChosenQuote] = useState(0);

  useEffect(() => {
    setChosenQuote(randomInt(quoteList.length - 1));
  }, []);

  return (
    <Container className={className}>
      <ProfilePic bgColor={bgColor}>
        <Bubble image={image} />
        <Title>{title}</Title>
      </ProfilePic>
      <ProfileCard>
        <Name>{fullName}</Name>
        <Type>{`type: ${type}`}</Type>
        <OpenEmail
          title="Click to email me!"
          href={`mailto:${email}`}
          rel="noopener noreferrer"
        >
          <StyledEmail />
          <EmailAddText>{email}</EmailAddText>
        </OpenEmail>
        <SukiKiraimono>
          <tbody>
            <tr>
              <td>Likes</td>
              <td>{likes}</td>
            </tr>
            <tr>
              <td>Dislikes</td>
              <td>{dislikes}</td>
            </tr>
          </tbody>
        </SukiKiraimono>
        <Quote
          dangerouslySetInnerHTML={{ __html: `"${quoteList[chosenQuote]}"` }}
        />
      </ProfileCard>
    </Container>
  );
};

Card.defaultProps = {
  className: '',
};

Card.propTypes = {
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  bgColor: PropTypes.string.isRequired,
  likes: PropTypes.string.isRequired,
  dislikes: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  quoteList: PropTypes.arrayOf(PropTypes.string).isRequired,
  email: PropTypes.string.isRequired,
};

export default Card;
