import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const RemoteLink = makeShortcode("RemoteLink");
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`After years of using my `}<RemoteLink to="https://www.hyperxgaming.com/us/keyboards/alloy-fps-pro-mechanical-gaming-keyboard" mdxType="RemoteLink">{`HyperX Alloy FPS`}</RemoteLink>{` mechanical keyboard, I decided enough is enough. It’s super long since it’s a full-sized keyboard, it’s heavy and the cables are thick! I absolutely hated the cables cluttering my desk. Since the cable is thick and braided, I find it really hard to bend it in small corners. My table is small enough as it is and yet the keyboard is eating up all of my writing space. I find my right arm tensing up since it had to travel far from typing on the keyboard to moving the mouse on the right side of the keyboard. Sure, I could switch up the mouse placement so I could do with less travel, but I couldn’t get used to this arrangement.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="HyperX Alloy FPS's braided cable example" caption="HyperX Alloy FPS has some thick cables. Not only that, it branches out to three types of different cable heads. It's thick, it's long, it's HARD TO BEND!" name="hyperx-alloy-fps-pro-cable.jpg" mdxType="Image" />
    <p>{`I bought HyperX when I have no idea what my preference is like. It’s a gaming keyboard so it has the N-key rollover feature. It also has red backlighting which I rarely turn on since it bothers me while I work. Looking back, these features marketed towards gamers are wasted on me as a casual user. I only used my keyboard for casual Internet browsing or coding. So I decided to look for a keyboard that is honestly, just a decent mechanical keyboard for typing.And that is how I stumbled upon `}<RemoteLink to="https://keychron.com" mdxType="RemoteLink">{`Keychron`}</RemoteLink>{` K2.`}</p>
    <Image alt="Keychron K2's extra items in the box" caption="The box comes with the keyboard itself, a metallic keycap puller, extra keycap sets + Windows keycap and a USB-C cable for charging." name="keychron-k2-review-unboxing.png" mdxType="Image" />
    <p>{`I have heard many good things about Keychron’s product. I first learned about this company on Kickstarter, when it had just begun marketing their new keyboard, K6. The keycaps aesthetics alone attracted me to take a look at it’s specs. Fast forward to 2 months later, I’m in love with this keyboard. At this point I’m `}<strong parentName="p">{`convinced`}</strong>{` of the one sole reason it sold so well - Keychron K2 is `}<em parentName="p">{`super convenient`}</em>{` to have! I don’t think I have seen any other budget mechanical keyboard done so well for the general user market.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`So what is Keychron K2?`}</h2>
    <p>{`Keychron K2 is a 75% mechanical keyboard that has `}<em parentName="p">{`every`}</em>{` key you need for your daily usage, all squeezed into one small board that is only `}<em parentName="p">{`slightly `}</em>{`wider than a 60% mechanical keyboard. I say every need because technically speaking, you do not need the numeric keypad `}<em parentName="p">{`unless `}</em>{`you enter numbers often. The switches in K2 are Gateron switches (Red, Blue or Brown switches), and you need to choose to get a White or RGB backlighting. Keychron offers a choice between a plastic frame casing, or an aluminium one for your K2.`}</p>
    <p>{`K2 is both a wired and a wireless mechanical keyboard with a bulky battery pack in it. You can plug in a cable or choose to connect to your keyboard with Bluetooth. So as long as your device has Bluetooth connectivity, K2 can connect to it. Now the next point is the highlight of this mechanical keyboard - K2 can seamlessly swap Bluetooth connections between three registered devices even if these devices are an Android phone, Windows machine, Macbook or an iPhone! You don’t need to install any additional software to make this keyboard `}<strong parentName="p">{`fully compatible`}</strong>{` with your devices. It works out of the box, so you can take this keyboard wherever you go and connect it to whatever devices you want. It seriously just works, blimey.`}</p>
    <p>{`The battery is super long-lasting, and if you need to charge it, K2 uses USB-C cable. Convenient, isn’t it? What is there not to love about this handy keyboard! And the best part? `}<strong parentName="p">{`It’s a mechanical keyboard`}</strong>{`! If you look around the current keyboard market, you’ll be hard pressed to find a good Bluetooth keyboard that is `}<em parentName="p">{`mechanical`}</em>{`. At most, they are either chiclets or membrane keyboards. Not to say that these keyboards are bad, but once you try typing on a mechanical keyboard, it’s hard to go back to using membrane ones.`}</p>
    <Ads mdxType="Ads" />
    <p>{`I got myself a K2 with plastic frame casing, Gateron Blue switches and with white backlighting from the official website. This is by far the cheapest choice amongst the others, but it works just fine for me. Delivery was delayed thanks to COVID-19, but it reached me really quickly once they had the opportunity to send it over. Keychron’s office is in Hong Kong so they send it to me from ShenZhen. They took care to wrap my box in a thick bubble wrap so it reaches me in tip-top condition.`}</p>
    <h2>{`What’s good about K2?`}</h2>
    <p>{`If I really have to summarize it down to a single point, it’s definitely the Windows and MacOS out-of-the-box compatibility. Nevertheless, let’s talk about the noteworthy points of this keyboard.`}</p>
    <h3>{`Plastic frame is unexpectedly sturdy`}</h3>
    <p>{`If I could, I would have treated myself to a K2 with an aluminium case. Alas, I’m but a broke blogger so I picked the cheapest choice. I tried flexing it but it’s as sturdy as a rock. A quick knock on it’s back tells me it is hollow on the inside. Despite being a plastic frame, it doesn’t feel cheap on my hands.`}</p>
    <h3>{`Light and portable`}</h3>
    <p>{`I added this point here because I believe some folks actually look for a weighty keyboard so it doesn’t move easily when pushed during intensive gaming sessions. If you are looking for a heavy keyboard, then this is not for you. It’s very light! You can easily put this in your bag and forget about it until you need it.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Comparing Keychron K2 with Apple's Magic Keyboard" caption="Keychron K2 is only 1 column longer than Apple's Magic Keyboard. I hope this gives you a sense of the K2's size." name="keychron-k2-thickness-compared-with-apple-magic-keyboard.png" mdxType="Image" />
    <h2>{`Right side keys’ new position takes some time to get used to`}</h2>
    <p>{`Since everything else on the right side is squeezed in to fit onto a smaller board, their positions are slightly off from your usual full-sized keyboard. If you are a touch typist, then the board’s layout will take some time to get used to. I myself kept making typos! The right shift key is smaller than the usual ones, so it’ll be hard to find keycaps for it. It just so happens that I use the right shift key often so I often tapped the arrow key next to it.`}</p>
    <h2>{`Side toggle’s text is bloody small`}</h2>
    <p>{`In order to switch from using a Windows layout to a MacOS layout or to use a Bluetooth connection instead of using your cable, you will need to use the toggles found at the left side of this keyboard. You will need to squint hard to see the text next to the toggle! Once you memorized the positions, it’s not a big deal but I could see why everyone was complaining initially.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`K2 is tall enough to warrant a wrist rest`}</h2>
    <p>{`Many reviewers took note of K2’s height almost immediately when they tested it out and I agree. It’s definitely tall enough that requires your hands to hover over the keys to type accurately. However, I do not agree with the footnote on needing a wrist rest. I’d say it’s a tall keyboard but not too tall that you need a wrist rest. I have a really small table to work with, so I didn’t get a wrist rest that will occupy more space. Instead, I adjusted my chair so I could place my arms comfortably on the armrests instead. I fixed my sitting position so I could type comfortably on K2. There are comments that mentioned folks with bigger hands wouldn’t encounter this problem either, so I do not consider this a deal breaker. So don’t jump into getting a wrist rest before trying K2’s height out! You may not even need a wrist rest in the first place.`}</p>
    <Image alt="Keychron K2 vs HyperX in casing height" caption="Keychron K2's side view compared with my HyperX keyboard. As you can see, it's ridiculously tall. Coupled with the height of the keycaps, it's even taller." name="keychron-k2-thickness.png" mdxType="Image" />
    <h2>{`Swapping between devices is seamless`}</h2>
    <p>{`K2 can store up to three devices to connect to by assigning their profiles to the number keys 1 to 3. You need to (obviously) connect your device to Keychron K2 first before you can start swapping profiles. I find the initial connection really smooth and easy to set up. A few taps from the K2 to acknowledge the handshake and voila, profile stored. You can then hold down the Fn key and tap the numbers to swap your K2’s connection to the stored devices. It’s really fun how seamless I can swap profiles! Before long, I was swapping between connecting to my Mac Mini and my iPhone.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`You don’t need a software to reassign Windows/MacOS keys`}</h2>
    <p>{`As previously mentioned, everything just… works. I tried swapping profiles between a Windows laptop and my Mac Mini to test the feel of it. It definitely works as advertised. If it doesn’t work, please check that you have switched to the correct layout through the side toggle. Keychron even supplied Windows keycap in case you are not used to the idea of CMD keycaps.`}</p>
    <h2>{`Battery life is pretty good`}</h2>
    <p>{`If you checked out the official website, Keychron compared their battery size and life to Apple Magic Keyboard. I personally think this is an unfair and misleading advertisement, since K2’s battery usage is much, much more than the Magic Keyboard. I used K2 often during weekdays and weekends. I’d say 8 hours during weekdays and 2 hours during the weekends. With this usage, the battery lasted me at least 1 ½ weeks or 2 weeks. I think it could have been longer if not for the backlighting constantly on.`}</p>
    <p>{`Yes, just a heads up, you could toggle the backlighting using a dedicated key. There’s some cool lighting effects but I usually choose the one that uses the least lighting to save battery. That’s because you could `}<strong parentName="p">{`never turn off the backlighting`}</strong>{`. Not even when the K2 is connected to a power source!`}</p>
    <h2>{`All in all`}</h2>
    <p>{`I really enjoyed using K2 for work and to blog. I didn’t do any reviews on the switches since those are down to personal preference. I tried brown switches before but I find them too `}<em parentName="p">{`soft `}</em>{`to type. Blue switches give a nice sense of feedback and clickity clackity noises. If you don’t like how it sounds, you could use O-rings to dampen them.`}</p>
    <Ads mdxType="Ads" />
    <p>{`K2 pairs the satisfaction of typing on a mechanical keyboard and opens up the gateway to using a wireless setup. I could now blog on my phone! I couldn’t get past the idea of tapping on my phone’s keyboard to write. Words couldn’t flow out as easily as typing on a proper keyboard. Now, I could just start typing on my phone with K2 whenever inspiration strikes me. I could lay on the bed to type, or on the dining table without needing to drag out my laptop.`}</p>
    <p>{`Having K2 compatible with Windows and MacOS is a boon! I use my Mac Mini for work purposes but I have a Windows laptop for my personal use. Since I don’t have a keyboard that supports MacOS, I had to use the Magic Keyboard instead. If I want to use my Windows laptop, I need to swap out the Magic Keyboard for my HyperX instead. I have since kissed them goodbye because all I need now is my Keychron K2 keyboard for both devices. This is such a convenient wired and wireless mechanical keyboard! I am not swapping it out for anything else.You can check out `}<RemoteLink to="https://www.keychron.com/products/keychron-k2-wireless-mechanical-keyboard" mdxType="RemoteLink">{`Keychron’s official website`}</RemoteLink>{` for the product. They are coming out with new keyboards so check those out too!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      