import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import $ from '../../../styles/globals';
import topicList from '../topics.json';
import { importOneSVG, importOneImage } from '../../../utils';
import Button from '../../Button';
import InFeedAd from '../../Ads/InFeed';

const Section = styled.section``;

const Container = styled.div``;

const CardContainer = styled.div`
  display: grid;
  padding: 50px 0;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  grid-gap: 20px 30px;

  ${$.screen.tabletA} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${$.screen.tabletB} {
    grid-template-columns: repeat(3, 1fr);
  }

  ${$.screen.mobile} {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: ${$.shadow.normal};
  border-radius: 10px;
  overflow: hidden;
  min-height: 320px;
  height: 100%;

  ${$.screen.mobile} {
    min-height: initial;
    height: initial;
  }
`;

const ImageBanner = styled.div`
  width: 100%;
  height: 120px;
  position: relative;

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    z-index: -1;
  }
`;

const Gradient = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: linear-gradient(
    360deg,
    rgba(53, 46, 46, 0.8) 35%,
    rgba(255, 255, 255, 0) 100%
  );
`;

const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 10px;
  left: 15px;
  z-index: 1;

  > h1 {
    color: ${$.white1};
    font-family: Porkys !important;
    font-size: 26px !important;
    letter-spacing: 1px;
    margin-right: ${$.layout.margin2}px;
  }

  > svg {
    width: 24px;
    height: 24px;
    margin-right: ${$.layout.margin2}px;
  }

  > span {
    font-family: Rubik Regular;
    font-size: 20px;
    color: ${$.white1};
  }
`;

const SubTagsContainer = styled.div`
  padding: ${$.layout.padding3}px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-self: stretch;
  align-items: center;
  justify-content: center;
  flex-grow: 1;

  ${$.screen.mobile} {
    display: none;
  }
`;

const SubTags = styled.div`
  font-size: 15px;
  line-height: 1.5em;
  margin-right: ${$.layout.margin1}px;
  ${$.ellipsis}
`;

const StyledInFeedAd = styled(InFeedAd)`
  margin: 50px auto 20px auto;
  width: 100%;
`;

const VisitTopicPage = styled(Link)`
  padding: ${$.layout.padding2}px;

  > * {
    width: 100%;
  }
`;

const Cards = ({ items }) => {
  const topics = {};
  const otherTags = {};

  items.forEach(
    ({
      node: {
        frontmatter: { tags },
      },
    }) => {
      const primaryTag = tags[0];

      topics[primaryTag] = topics[primaryTag] ? topics[primaryTag] + 1 : 1;

      if (tags.length > 0) {
        tags.forEach((tag, index) => {
          otherTags[primaryTag] = otherTags[primaryTag] || [];
          if (index > 0 && otherTags[primaryTag].indexOf(tag) === -1) {
            otherTags[primaryTag].push(tag);
          }
        });
      }
    }
  );

  return (
    <Section>
      <Container>
        <StyledInFeedAd />
        <CardContainer>
          {Object.keys(topics).map((tag) => {
            const totalPerTopic = topics[tag];
            const { icon, img } = topicList.filter(
              ({ tag: name }) => name === tag
            )[0];
            const subTags = otherTags[tag];

            return (
              <Card key={tag}>
                <ImageBanner>
                  <Gradient />
                  <img src={importOneImage(img)} alt={`${tag} banner`} />
                  <Title>
                    {importOneSVG(`icons/${icon}`)}
                    <h1>{tag}</h1>
                    <span>{`(${totalPerTopic})`}</span>
                  </Title>
                </ImageBanner>
                <SubTagsContainer>
                  {subTags.splice(0, 6).map((subTag, index) => (
                    <SubTags
                      key={`${tag}_${subTag}_${index}`}
                    >{`#${subTag}`}</SubTags>
                  ))}
                  {subTags.length > 6 && (
                    <SubTags>... and many other tags!</SubTags>
                  )}
                </SubTagsContainer>
                <VisitTopicPage to={`/topic/${tag}/`}>
                  <Button>Visit Page</Button>
                </VisitTopicPage>
              </Card>
            );
          })}
        </CardContainer>
      </Container>
    </Section>
  );
};

Cards.defaultProps = {
  items: [],
};

Cards.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
};

export default Cards;
