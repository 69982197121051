import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import $ from '../../styles/globals';
import Button from '../Button';
import { importOneSVG, importOneImage } from '../../utils';

const Section = styled.section`
  ${({ img }) => `
    background-image: url('${img}');
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: -1;
      background: rgba(0, 0, 0, 0.65);
    }`}
`;

const Container = styled.div`
  padding: 30px 0;
`;

const Title = styled.h1`
  font-family: Rubik Regular !important;
  font-weight: bold;
  font-size: 40px !important;
  color: #fff;
  margin-bottom: ${$.layout.margin5}px;
  display: flex;
  flex-direction: row;
  align-items: center;

  > svg {
    width: 38px;
    height: 38px;
    fill: #fff;
    margin-right: ${$.layout.margin2}px;
  }

  ${$.screen.mobile} {
    font-size: 35px !important;
  }
`;

const Description = styled.div`
  font-size: 17px !important;
  line-height: 1.3em;
  color: #fff;
  font-family: Rubik Regular !important;
  margin-bottom: ${$.layout.margin5}px;

  strong {
    font-family: Rubik Regular;
    font-weight: bold;
  }

  i,
  em {
    font-family: Rubik Regular;
    font-style: italic;
  }

  a {
    font-family: Rubik Regular;
    font-weight: bold;
    color: ${$.color.orange};
    text-decoration: none;
  }

  ${$.screen.mobile} {
    font-size: 16px !important;
  }
`;

const StyledButton = styled(Button)`
  text-transform: initial;
  color: ${$.color.black};
  letter-spacing: 0.5px;
  width: 200px;
  border: 2px solid ${$.white1};
  background-color: ${$.white1};

  ${$.screen.desktop} {
    &:hover {
      background-color: transparent !important;
      color: ${$.white1};
    }
  }

  ${$.screen.mobile} {
    font-size: 15px;
  }
`;

const Banner = ({ img, name, description, icon }) => (
  <Section img={importOneImage(img)}>
    <Container>
      <Title>
        {importOneSVG(`icons/${icon}`)}
        {name}
      </Title>
      <Description dangerouslySetInnerHTML={{ __html: description }} />
      <Link to="/topic/" title="See more topics">
        <StyledButton>See more topics</StyledButton>
      </Link>
    </Container>
  </Section>
);

Banner.defaultProps = {
  icon: '',
  name: '',
  description: '',
  img: '',
};

Banner.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.string,
  img: PropTypes.string,
};

export default Banner;
