import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const RemoteLink = makeShortcode("RemoteLink");
const LocalLink = makeShortcode("LocalLink");
const Ads = makeShortcode("Ads");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Thank you so much for this opportunity `}<RemoteLink to="https://twitter.com/YumDeku" mdxType="RemoteLink">{`@Yumdeku`}</RemoteLink>{`. I got nominated by his `}<RemoteLink to="https://www.myanime2go.com/" mdxType="RemoteLink">{`myanime2go`}</RemoteLink>{` team once for Real Neat Blog award `}<LocalLink to="/blog/geeknabe-got-nominated-for-real-neat-blog-award-2019/" mdxType="LocalLink">{`last year`}</LocalLink>{` and he did it again. Absolutely mad lad. Here’s his nomination `}<RemoteLink to="https://www.myanime2go.com/2020/02/mystery-blogger-award-goes-to.html" mdxType="RemoteLink">{`post`}</RemoteLink>{` for more info. Alright, let’s see what this award is all about.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Thankfully, the source of the award is much easier to track compared to Real Neat Blog award this time. The award is first thought of by Okoto Enigma. You can find the original post explaining the award `}<RemoteLink to="https://www.okotoenigmasblog.com/my-greatest-creation-yet/" mdxType="RemoteLink">{`here`}</RemoteLink>{`. Thank you Okoto Enigma-san, very cool. Here, have a free backlink.Similar to Real Neat Blog award, there are some rules to follow. As a law-abiding citizen, I pledge to follow `}<em parentName="p">{`some`}</em>{` of them!`}</p>
    <ol>
      <li parentName="ol">{`Put the award logo/image on your blog. (Yes!)`}</li>
      <li parentName="ol">{`List the rules. (Here you go!)Thank whoever nominated you and provide a link to their blog. (Duh.)`}</li>
      <li parentName="ol">{`Mention the creator of the award and provide a link as well. (Very sly of you, Okoto Enigma. This blogger knows his SEO well. Done!)`}</li>
      <li parentName="ol">{`Tell your readers 3 things about yourself.`}</li>
      <li parentName="ol">{`You have to nominate 10 - 20 people.`}</li>
      <li parentName="ol">{`Notify your nominees by commenting on their blog.`}</li>
      <li parentName="ol">{`Ask your nominees any 5 questions of your choice; with at least 1 weird or funny question.`}</li>
      <li parentName="ol">{`Share a link to your best post(s).`}</li>
    </ol>
    <p>{`This is by far, the smartest blog award rules I’ve seen. Not only does it help to boost your blog, but it also helps to give a free link to the original poster. Nice. Alright, let’s start answering the questions.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Three Facts About Me`}</h2>
    <h3>{`1. I actually really like hotpot meals`}</h3>
    <p>{`Alright, this may sound a bit like a joke answer but hear me out - hotpot meals are the best. I don’t like eating food on the dry side, so meals with some soup or gravy is the best for me. Plus, you get to trick everyone to cooking their own meals. Hah! EQUALITY!`}</p>
    <h3>{`2. I’m an INFP`}</h3>
    <p>{`I’m gonna copy Yum and take the Myers-Briggs test. I used to be INTJ but I guess people do change over the course of time.`}</p>
    <h3>{`3. I prefer manga over anime`}</h3>
    <p>{`Anime is great but have you read the manga, the one and original source? There’s just something charming about manga for me. Since there’s barely any colours, you had to fill in the blanks yourself. Imagination plays a strong part in `}<em parentName="p">{`moving the story`}</em>{` for me, and it takes less time to complete a chapter than watching 1 episode of anime for me.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Here are the answers to Yum’s questions:`}</p>
    <h2>{`1. What are your thoughts about paranormal?`}</h2>
    <p>{`I am both terrified and intrigued by paranormal events. We are afraid of things that we do not understand, and that is understandable. What’s more terrifying is that these events clearly happened BUT there is no possible explanation of how it happened. If there are documentaries on these events, I’ll definitely watch the heck out of it. If it ever happened to me, I think I’ll faint right on the spot. I am seriously easy for the taking. So dear paranormals, please don’t come find me…`}</p>
    <h2>{`2. What anime, manga and gaming story that really stuck with you?`}</h2>
    <p>{`Now, this is a tough question. I have so many favourites but because it’s been so long, even memories of it being my favourite start to fade away. True, there are many great stories out there that I can’t begin to name them all. If I had to name the one and only story that really left a strong impression on me, it’s Doraemon.It’s a really simple story at its core. Robot cat from the future jumps back to the past to help a certain wimpy boy to shine in life. With Doraemon’s handy pocket, he’ll pull out useful futuristic gadgets to help Nobita beat back his bullies! (And along the way, learn some life lessons)`}</p>
    <p>{`It’s my favourite show since childhood. I literally grew up with Doraemon. I love the gadgets they feature every episode. I really enjoy the cast. We have a bully, a rich boy, the perfect in everything classmate and a sweet girl. Every character niche is filled, and every episode ends with a life lesson learned.Besides, the robot cat has a door that can go anywhere. Where do I find a cat like that?!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`3. What is the sexiest and the least sexy name?`}</h2>
    <p>{`Sexiest name - YumDeku. Least sexy name - YumDeku. There, the balance has been restored.`}</p>
    <h2>{`4. What’s your plan for your blog?`}</h2>
    <p>{`S-E-C-R-E-T <3. Ahem, there’s a reason why I wanted my own domain, my own handmade-with-love blog… I can do whatever I want with it! Adding new sections is no big deal. I have a new section planned out for Mari-chan, so please stay tuned!`}</p>
    <h2>{`5. What is your favourite trait about yourself?`}</h2>
    <p>{`I’m cute in person too <3. Well, if you ask me silly questions, you get silly answers! :D`}</p>
    <p>{`Here are some of my and Mari-chan’s best blog posts:`}</p>
    <ol>
      <li parentName="ol">{`Topic: anime - `}<LocalLink to="/blog/anime-studios-should-take-creative-liberties-with-the-original-source/" mdxType="LocalLink">{`Anime studios should take creative liberties with the original source`}</LocalLink></li>
      <li parentName="ol">{`Topic: manga - `}<LocalLink to="/blog/10-completed-webtoons-you-should-read-part-1/" mdxType="LocalLink">{`10 completed webtoons you should read (Part 1)`}</LocalLink></li>
      <li parentName="ol">{`Topic: games - `}<LocalLink to="/blog/my-time-at-portia-6-tips-and-tricks-to-improve-your-experience/" mdxType="LocalLink">{`My Time at Portia 6 tips and tricks to improve your experience`}</LocalLink></li>
      <li parentName="ol">{`Topic: games - `}<LocalLink to="/blog/get-the-tomtoc-slim-case/" mdxType="LocalLink">{`Get the Tomtoc Slim Case`}</LocalLink></li>
    </ol>
    <Ads mdxType="Ads" />
    <p>{`Here are my questions to lucky bloggers!`}</p>
    <ol>
      <li parentName="ol">{`The red pill gives you the ability to enter any fiction world there is. The blue pill gives you the ability to make any fiction world a reality. Pick a pill, choose a world, and tell us why!`}</li>
      <li parentName="ol">{`You are given the chance to choose any fiction you love into a live-action TV series. Pick one, and who would you choose to star in it?`}</li>
      <li parentName="ol">{`What’s the best book (fiction or non-fiction, it doesn’t matter) you’ve ever read?`}</li>
      <li parentName="ol">{`What’s your favourite movie of all time?`}</li>
      <li parentName="ol">{`Will you try a boba tea hotpot?`}</li>
    </ol>
    <p>{`Okay, all done! Here are the lucky bloggers I nominate. Check out their blog and Twitter! I have linked them below.`}</p>
    <Ads mdxType="Ads" />
    <ol>
      <li parentName="ol">{`ArcaneDragon `}<RemoteLink to="https://twitter.com/arcanedrag0n" mdxType="RemoteLink">{`Twitter`}</RemoteLink>{` `}<RemoteLink to="https://www.dragonscodex.net/" mdxType="RemoteLink">{`Blog `}</RemoteLink></li>
      <li parentName="ol">{`Mel `}<RemoteLink to="https://twitter.com/MelinAnimeland" mdxType="RemoteLink">{`Twitter`}</RemoteLink>{` `}<RemoteLink to="https://melinanimeland.wordpress.com/" mdxType="RemoteLink">{`Blog`}</RemoteLink></li>
      <li parentName="ol">{`Scott `}<RemoteLink to="https://twitter.com/MechAnimeReview" mdxType="RemoteLink">{`Twitter`}</RemoteLink>{` `}<RemoteLink to="https://mechanicalanimereviews.com/" mdxType="RemoteLink">{`Blog `}</RemoteLink></li>
      <li parentName="ol">{`S Selcouth `}<RemoteLink to="https://twitter.com/s_selcouth" mdxType="RemoteLink">{`Twitter`}</RemoteLink>{` `}<RemoteLink to="http://www.yourock.fyi/" mdxType="RemoteLink">{`Blog`}</RemoteLink></li>
      <li parentName="ol">{`Logy `}<RemoteLink to="https://twitter.com/Phosisdepressed" mdxType="RemoteLink">{`Twitter`}</RemoteLink>{` `}<RemoteLink to="https://logysblog.design.blog/" mdxType="RemoteLink">{`Blog`}</RemoteLink></li>
      <li parentName="ol">{`Leth `}<RemoteLink to="https://twitter.com/AlwaysLethargic" mdxType="RemoteLink">{`Twitter`}</RemoteLink>{` `}<RemoteLink to="https://lethargicramblings.wordpress.com/" mdxType="RemoteLink">{`Blog`}</RemoteLink></li>
    </ol>
    <p>{`Thank you for reading. I can’t wait to read your replies!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      