import styled from 'styled-components';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import { getStrDate } from '../helper';
import $ from '../../../styles/globals';
import { importOneImage } from '../../../utils';

const Container = styled(Link)`
  width: 100%;
  border-radius: 10px;
  display: block;
  text-decoration: none;
  height: 400px;
  box-shadow: ${$.shadow.normal};
  position: relative;
  overflow: hidden;

  ${$.screen.desktop} {
    transition:
      box-shadow 0.4s ${$.transition},
      transform 0.4s ${$.transition};
    transform: translateY(0);
    &:hover {
      transform: translateY(-0.5em);
      box-shadow: ${$.shadow.repressed};
    }
  }

  ${$.screen.mobile} {
    min-height: 250px;
    height: 10px;
  }
`;

const ImgContainer = styled.div`
  width: 100%;
  height: 100%;

  > * {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

const ContentBackground = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  background: linear-gradient(
    0deg,
    rgba(85, 56, 41, 1) 4%,
    rgba(203, 82, 0, 0.04) 80%
  );

  ${$.screen.mobile} {
    align-items: flex-start;
    justify-content: flex-start;
    background: rgba(85, 56, 41, 0.55);
  }
`;

const Content = styled.div`
  padding: ${$.layout.padding5}px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${$.screen.tablet} {
    padding: ${$.layout.padding4}px;
  }

  ${$.screen.mobile} {
    padding: ${$.layout.padding4}px;
  }
`;

const Title = styled.div`
  font-family: Raleway Bold;
  font-size: 32px;
  color: ${$.color.white};
  margin-bottom: ${$.layout.margin3}px;
  line-height: 1.1em;
  hyphens: auto;

  ${$.screen.tablet} {
    font-size: 30px;
  }

  ${$.screen.mobile} {
    font-size: 24px;
    margin-bottom: ${$.layout.margin2}px;
  }
`;

const Tagged = styled.div`
  font-family: Rubik Regular;
  font-weight: bold;
  font-size: 18px;
  background-color: ${$.color.yellow};
  text-transform: uppercase;
  padding: 5px 10px;
  border-radius: 20px;
  color: ${$.color.lightblack};
  letter-spacing: 2px;
  margin-bottom: ${$.layout.margin2}px;
`;

const TimeToRead = styled.div`
  font-family: Rubik Regular;
  font-weight: bold;
  font-size: 15px;
  letter-spacing: 1px;
  color: ${$.color.white};
`;

const OtherPost = ({ img, url, title, timeToRead, published, tag }) => (
  <Container
    to={url}
    title={`${title}${timeToRead ? ` | ${timeToRead} min` : ''}`}
  >
    <ImgContainer>
      <img src={importOneImage(img)} alt={title} />
    </ImgContainer>
    <ContentBackground>
      <Content>
        <Tagged>{tag}</Tagged>
        <Title>{title}</Title>
        <TimeToRead>
          {`${getStrDate(published)}${
            timeToRead ? ` · ${timeToRead} min` : ''
          }`}
        </TimeToRead>
      </Content>
    </ContentBackground>
  </Container>
);

OtherPost.defaultProps = {
  img: '',
  url: '',
  title: '',
  timeToRead: 1,
  published: '',
  tag: 'anime',
};

OtherPost.propTypes = {
  tag: PropTypes.string,
  img: PropTypes.string,
  url: PropTypes.string,
  title: PropTypes.string,
  timeToRead: PropTypes.number,
  published: PropTypes.string,
};

export default OtherPost;
