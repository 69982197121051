import styled from 'styled-components';
import $ from '../styles/globals';

const Section = styled.section``;

const Container = styled.div``;

const Title = styled.h1`
  margin: 50px 0;
  font-size: 40px !important;
`;

const Subtitle = styled.h2`
  font-size: 30px !important;
  margin-bottom: 20px;
`;

const Content = styled.div`
  p {
    margin-bottom: 20px;
    line-height: 1.3em;
    font-size: 16px;
  }

  a {
    font-weight: bold;
    color: ${$.color.orange};
  }
`;

const PrivacyPolicy = () => (
  <Section>
    <Container>
      <Title>Privacy Policy</Title>
      <Content>
        <Subtitle>General Information</Subtitle>
        <p>
          Our team at Geeknabe is committed to protecting your privacy when you
          visit our website, geeknabe.com. Visitors can access and browse our
          site without disclosing any of their personal data. geeknabe.com do
          not collect personal information such as mailing, names or email
          addresses.
        </p>
        <Subtitle>Disclosure of Information</Subtitle>
        <p>
          We do not automatically log personal data nor do we link information
          automatically logged by other means with personal data about specific
          individuals. geeknabe.com will not rent, sell, or trade your personal
          information to others. We may use IP addresses to compile and provide
          aggregate statistics about our visitors, traffic patterns, and
          administer the site. IP addresses are not linked to personally
          identifiable information. We collect only the personal data that you
          may volunteer while using our services. We do not collect information
          about our visitors from other sources, such as public records or
          bodies, or private organizations.
        </p>
        <Subtitle>Third Party Use of Cookies</Subtitle>
        <p>
          Some content or applications, including advertisements, on the Website
          are served by third-parties, including advertisers, ad networks and
          servers, content providers, and application providers. These third
          parties may use cookies alone or in conjunction with web beacons or
          other tracking technologies to collect information about you when you
          use our website. The information they collect may be associated with
          your personal information or they may collect information, including
          personal information, about your online activities over time and
          across different websites and other online services. They may use this
          information to provide you with interest-based (behavioral)
          advertising or other targeted content.
        </p>
        <p>
          Please check{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://policies.google.com/privacy?hl=en&gl=US"
          >
            Google&apos;s Privacy Policy
          </a>{' '}
          for further details.
        </p>
        <Subtitle>Privacy Policy Changes</Subtitle>
        <p>
          Although most changes are likely to be minor, geeknabe.com may change
          its Privacy Policy from time to time. Please check this page
          frequently for any changes to its Privacy Policy. Your continued use
          of this site after any change in this Privacy Policy will constitute
          your acceptance of such change.
        </p>
      </Content>
    </Container>
  </Section>
);

export default PrivacyPolicy;
