import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXFanfiction/Chapter/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const MessageBox = makeShortcode("MessageBox");
const AuthorNote = makeShortcode("AuthorNote");
const Image = makeShortcode("Image");
const RemoteLink = makeShortcode("RemoteLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The voice on the other end of the call when she finally got through was cracked and sounded sore. “Mmm?”`}</p>
    <p>{`“Reus, check your messages,” Byleth hissed. The greenhouse was now her preferred spot for confidential phone calls.`}</p>
    <p>{`“Mmmgh.” Reus cleared his throat. “O-okay.”`}</p>
    <Ads mdxType="Ads" />
    <p>{`Byleth took this moment to scan the area for human traffic. Some of the plants had some water droplets which meant they had been watered and no one would come in for a while.`}</p>
    <p>{`“`}<em parentName="p">{`-ck,`}</em>{`” Byleth had heard the end of a curse word which perfectly summed up how she felt right now. “Reus, if dad sees me here, we’re both screwed.”`}</p>
    <p>{`“I know, I know. I’m sorry.” She ran a hand down her face. “Do you think you could catch a bus later today? I’ll meet you at Garreg Mach town. There’s a shuttle bus that comes to the school a few times a day.”`}</p>
    <p>{`“And where are `}<em parentName="p">{`you`}</em>{` going to hide while I’m there?” She could hear him turn over on his bed.`}</p>
    <p>{`She blinked a couple of times, “I’m...going to pretend I’m visiting you and then pretend to go back to Faerghus, I think. Anyway, they have rooms here for parents who are visiting from out of town. I’ll see if I can book a room for myself or something.”`}</p>
    <p>{`“I `}<em parentName="p">{`cannot`}</em>{` believe this is happening, By.” His voice still sounded hoarse. “Also are these pictures from your investigation?”`}</p>
    <p>{`“Yes, please save it and if anything happens to me, it’ll be up to you and Dimitri.” She sat on  a relatively clean brick, still in her PJ’s.`}</p>
    <p>{`“Dimi-who?”`}</p>
    <p>{`“Dimi`}<em parentName="p">{`tri`}</em>{`, he’s my- `}<em parentName="p">{`your`}</em>{` dorm leader. He caught me breaking in. Oh man do I have so many things to tell you.” Byleth stood up and she heard him groan again.`}</p>
    <p>{`“I’ll go book the tickets now. I’ll call you later.”`}</p>
    <p>{`“Wait!” She was about to end the call but remembered something essential. “Have you got any spare cash?”`}</p>
    <p>{`“... Why?”`}</p>
    <Ads mdxType="Ads" />
    <p>{`“You have to buy me clothes. I only packed `}<em parentName="p">{`your`}</em>{` clothes, I couldn’t risk being caught with a bra. Speaking of, you need to buy me one, too. And some panties.” She chuckled out of pure disbelief as she said this. It was so surreal.`}</p>
    <p>{`“I `}<em parentName="p">{`hate`}</em>{` this. I knew this was too good to be true. ... I can’t believe I’m asking, but what’s your size?”`}</p>
    <p>{`“Uh, just get me a sports bra,” she said, a little shy.`}</p>
    <p>{`“I still need your cup size, how else am I going to describe how big your boobs are to the lady? As big as my head?” Reus snorted. She sighed and whispered it into the phone.`}</p>
    <p>{`“`}<em parentName="p">{`What?!`}</em>{`” Byleth held the phone away from her ear. She repeated herself. “No, no I heard you the first time. Ahem. Do you uh… have a preferred brand? You do know that this is incredibly beyond me in terms of expertise.”`}</p>
    <p>{`“Think of it as training for when you get a `}<em parentName="p">{`real`}</em>{` girlfriend. Anything will do, we don’t have time,” she said, suddenly remembering to look around for eavesdroppers. There were students walking outside but they weren’t a threat.`}</p>
    <p>{`“Speak for yourself. Anyway, keep your phone on you. I’ll text if anything comes up,” Reus said, and they hung up.`}</p>
    <p>{`Byleth let out a long breath. Taking in the situation she was in, it was nothing less than shit. That’s right, she was in deep shit if it all went south. She checked the time and realized it was too late for breakfast, the cafeteria was preparing for lunch at this hour. No matter, she just had to get changed and prepare for Reus’s arrival later today. According to Google, Adrestia was a six hour bus ride to Garreg Mach town. They would go into a changing room somewhere and swap identities back.`}</p>
    <p>{`On her way back to her dorm she decided to get a room booked.`}</p>
    <p>{`“Hi, may I know if the guest rooms are available this weekend?” Byleth said, leaning over the counter where the R.A, or Resident Advisor was usually at. She noticed it was the same person who signed her in when she first arrived.`}</p>
    <p>{`“Why yes, of course! Is someone visiting you?” The RA asked.`}</p>
    <p>{`Byleth squinted at the name tag, “Yes Mr. Alois, my sister. It’s just for one night, what are the rates?” He rummaged around a drawer and handed her a laminated piece of paper. She was relieved, it wasn’t expensive at all. “So nice of your sister to come and visit, just her alone?”`}</p>
    <p>{`“My father is coming too but I think he’s already got a hotel booked or something. She’s coming a day early so...that’s why I’m here.” Mr. Alois nodded and leaned in. “We can always add an extra bed or mattress for a small fee if you’d like. It’s always nice to have family together!”`}</p>
    <p>{`“I’ll keep that in mind.” He smiled, gave her a form to fill up and asked for a small deposit, she realised she didn’t have her wallet on her and asked if she could come back in ten minutes. The R.A was very obliging about it.`}</p>
    <p>{`Byleth sprinted back to her room, hoping her dorm mates were decently dressed. To her surprise it was empty. She picked up a shirt, sniffed it and decided it was clean enough to change into. She retrieved her wallet and stuffed it into her changing bag and returned to complete the transaction at the R.A.`}</p>
    <p>{`While walking to the bathroom to change her phone rang. It was ‘Byleth’.`}</p>
    <p>{`“Hi `}<em parentName="p">{`Byleth`}</em>{`,” she said loudly, “Can I call you back?”`}</p>
    <p>{`“By, bad news.” Reus ignored her.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“I’ll have to call you back, I’m in the bathroom—”`}</p>
    <p>{`“There are no more busses this afternoon or tonight. It seems everyone is returning home after Manuela’s concert. The hotel concierge told me the earliest bus they have left is at 1am and it’ll reach Garreg Mach town tomorrow morning at around 7am if the traffic is clear.”`}</p>
    <p>{`“Hold on.” She beelined into a stall, averting her eyes from the two boys using the urinals. “That’s cutting it too close.” She scratched the back of her head thinking of a contingency plan. “But… we don’t have a choice. Just get it.”`}</p>
    <p>{`“Already did. Just letting you know. I’ll text you when I get on.”`}</p>
    <p>{`Evening came. Apart from hitting the gym with the gang, Byleth had nothing else to do but wait in anticipation for her brother. She retreated to the library to write down the timeline of events that happened from when she first arrived, as a reference of sorts for Reus when he came. She tried being as detailed as possible, writing the time and dialogue as much as she could remember.`}</p>
    <p>{`She sat crossed legged on the chair, her hand instinctively moving to tuck a lock of hair behind her ear but stopped midway when her fingers brushed against the buzz undercut. Her fingers curled and she brought her hand back down to her lap. She glanced at her phone again. Screen still dark. Time moved painfully slowly for Byleth, every second felt like an hour waiting for any news from Reus.`}</p>
    <p>{`Even waiting for the dinner bell to ring wasn’t this agonizing.`}</p>
    <p>{`“I forgot to mention this but my family is coming this weekend to visit me,” she said when there was a lull in their conversation at dinner.`}</p>
    <p>{`Sylvain sat up straight and leaned forward. “Right, who are your family members, and what are their genders?”`}</p>
    <p>{`“My mum died when I was born,” she began. The atmosphere became awkward but she continued, “Who, I’m sure was a woman.” She smirked and they relaxed a little. “And my dad is a man and my sister...well, she’s my sister.”`}</p>
    <p>{`“Is your sister hot?” Sylvain and Felix asked at the same time. Felix rolled his eyes. “I knew you were going to ask that.”`}</p>
    <p>{`“Ngaaw, how did you know?” Sylvain nudged him.`}</p>
    <p>{`“You don’t seem to know any other dialogue, apparently,” Felix said. Even Dimitri chuckled.`}</p>
    <p>{`“He’s right you know,” Dimitri chipped in.`}</p>
    <p>{`“Whatever,” Sylvain turned his attention back to her, “So is she hot, or not?”`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Has it ever mattered to you before?” Felix spoke up again, this time not bothering to look at the red-head.`}</p>
    <p>{`A pause. “No, actually.” Sylvain acknowledged. “Either way, all women are beautiful, regardless,” he said proudly. Byleth was thankful she didn’t have to describe herself. She didn’t consider herself ‘hot’, just… normal. Hot, was Reus’s idol, Manuela.`}</p>
    <p>{`“It’s nice that your family is coming to see you!” Ashe said.`}</p>
    <p>{`“It is,” she replied, then groaned. “But my dad is here to see the principal too. About me, I guess.”`}</p>
    <p>{`“Principal Rhea is a busy woman. I wonder if your father will be able to see her?” Dedue said.`}</p>
    <p>{`She stuffed a spoonful of Pheasant Roast dribbled with Berry Sauce, into her mouth. “Oh I’m sure he’ll manage. I think they’re acquaintances or something.”`}</p>
    <p>{`“So then why’s your sister coming?” Felix asked.`}</p>
    <p>{`“Uhh—” Byleth wiped her mouth with her sleeve. “To check on me I guess? My dad wasn’t around when I got into that fight, so my school had to call the nearest relative and it happened to be my sister. That feels like a whole lifetime ago,” she said. The fact that she was here now, masquerading as Reus was so surreal and equally as scary.`}</p>
    <p>{`After dinner she spoke again with Mr. Alois the R.A and told him her ‘sister’ wouldn’t be spending the night, explaining that she had taken a midnight bus, and so instead of letting her deposit go to waste she decided to sleep in the room. She texted their group chat that she wouldn’t be back in the dorm that night.`}</p>
    <p>{`Byleth was almost tempted to just permanently pay for this room forever; it had been a week since she took a proper shower, and it felt so good scrubbing every single inch of her body. It was risky but she decided to sleep with her chest unbound and the freedom felt strange, almost sore even.`}</p>
    <p>{`Byleth fell asleep feeling refreshed and exhausted at the same time, her hair dry for once. Until she was rudely awoken by her buzzing phone. She squinted at the screen, ‘Byleth’ had texted her the bus number plate and told her he was on it. She tossed her phone under her pillow and drifted back to sleep.`}</p>
    <p>{`Earlier she had set her alarm for 6am to prepare before picking Reus up from town. Suddenly waking up, she checked her phone. It was five minutes to 6am. Byleth forced her eyes to adjust to the brightness in the dark. The last message she got from Reus was `}<em parentName="p">{`‘Traffic is bad, might reach late. Will message when at Varley.’`}</em>{` According to the schedule, his bus should’ve arrived at Varley, the town before his Garreg Mach, about an hour ago and should have been on their way there by now. Worry rose in her chest, despite exhaustion forcing her heavy eyes to close again.`}</p>
    <p>{`Her alarm rang and Byleth reluctantly rolled out of bed, was ready by 6:30am and dropped the room keys in the check-out box. Securing her backpack, she ran to the entrance of the school where the earliest shuttle bus would come at 6:45am; it would take almost an hour to reach the town.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Bus after bus dropped their passengers off. As efficient as she was, there was still no Reus in sight. She was about to call him at 7:15am when he called her instead.`}</p>
    <p>{`“Just reached Varley. The bus driver says it’ll be another two hours before we arrive. Goddess I hope dad is late as usual.” Reus sounded worried as well.`}</p>
    <p>{`“I’m already here, so don’t worry. Did you get the stuff?” She sat up from the seat she had been slouching into for the past hour.`}</p>
    <p>{`“Yeah uh, I don’t know if it’s your style but it was on sale and cheap so…”`}</p>
    <p>{`“Sure, sure.” They hung up. Again, time went by in slow motion for Byleth. She was afraid to move from her spot for fear the bus might magically appear. Or that if she walked away somewhere she’d miss Reus.`}</p>
    <p>{`Another hour later she got a message from her dad in the group chat,`}</p>
    <MessageBox totalUnread={0} contactNumber="the Eisners" items={[{
      timestamp: {
        day: 'Sunday',
        time: '08:16AM'
      },
      name: 'Dad',
      left: '<p>at rest stop in Oghma</p>'
    }, {
      name: 'Dad',
      left: '<p>will b ther at 12. Hope ur cafe food is gud. Am starving. Meet me at entrance</p>'
    }]} mdxType="MessageBox" />
    <p>{`She left him on `}<em parentName="p">{`seen`}</em>{`, almost replying as Reus before deleting her unsent message.
Reus’s bus finally reached at 10am, when he emerged on the bus she leaped on him,
in both panic and affection. He looked back at her in pure confusion and awe.`}</p>
    <p>{`“Holy shit, you `}<em parentName="p">{`really`}</em>{` look like me, it's scary.” He looked at the back of her head, observing the clothes she was wearing; his clothes.`}</p>
    <p>{`“Yes, I know. Now come on we have to change, dad’s going to be here any minute.” She dragged him by the arm into a small cafe and ordered a drink to use their bathroom.`}</p>
    <p>{`Reus pulled out a paper bag from his duffle bag and Byleth quickly inspected the clothes he got her.`}</p>
    <p>{`“You got me this?” She lifted up a black tight fitting T-shirt that had a huge cut out in the middle of the chest area that didn’t cover much. “You do remember the reason you were expelled right? You beat a guy up—”`}</p>
    <p>{`“  —For looking at your tits—`}<em parentName="p">{`I know`}</em>{`.” He snatched it back from her. “I got you something else.” He rummaged through the bag. “Here.” Reus handed her a white tank top that ironically covered more than the first option he got her, a beige cardigan ‘`}<em parentName="p">{`to cover up,’`}</em>{` he said, a black pair of shorts and black lace tights.`}</p>
    <p>{`“Hey these look good, but I’m surprised you didn’t just get me a `}<em parentName="p">{`normal`}</em>{` T-shirt and jeans.” She nodded at the skimpy looking blouse he got her. It didn’t look too bad, but she didn’t dare imagine the chaos it would cause in an all-boys school if she wore it.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Yeah... no. If I dressed you too similarly as me someone might recognize you. Had to make you as feminine looking as possible. But `}<em parentName="p">{`that`}</em>{` particular one was too revealing. I went to a different shop and the sales girl was really helpful,” Reus said, proud of himself. “Oh I got you a sports bra as requested. And some panties. Now that was awkward, the lady thought I was buying it for my girlfriend. Wanted to sell me some `}<em parentName="p">{`strings`}</em>{` instead.” He shuddered as he pointed inside the bag. She laughed.`}</p>
    <p>{`Byleth was grateful the bathroom was clean and somewhat spacious, even though she had half a mind to toss the tights, it was too chilly to not wear them and soldiered on.`}</p>
    <p>{`She emerged as a completely different person and handed the clothes she wore to Reus. Byleth somehow managed to do a small half-bun held up by some pins to differentiate herself more from her twin, seeing as they had the same hairstyle.`}</p>
    <p>{`They waited for the 10:45am shuttle bus to the school, and on the bus Byleth handed Reus the note she made for him, the one that recounted her experiences over the past week.`}</p>
    <p>{`Arriving just as the clock struck noon, they ran back to the dorm, Byleth going with him so that he could dump his luggage there, under the pretense of ‘leaving my sisters things here first while we wait for my dad’. From habit and forgetting she was Byleth again, she led the way and opened the door to the dorm and walked right in as her, or rather Reus’ dorm mates were changing into their gym clothes, she was immune to this sight by now. They weren’t, however.`}</p>
    <p>{`“Miss! This is the boy’s dormitory!” Dimitri cried, hastily pulling up his shorts, Sylvain, Felix and Ashe following suit to cover up. Dedue was already dressed.`}</p>
    <p>{`Her eyes widened and she looked down at herself. `}<em parentName="p">{`Crap I’m a girl again!`}</em>{` “Sorry! I was uh—”`}</p>
    <p>{`“Hey, sorry my sister got lost on the way back from the toilet, I’m leaving her stuff here until my dad gets here.” Reus, the real Reus moved in front of her and walked to his bed, kicking the luggage underneath it. Byleth had drawn a small map of everything for him, including his dorm.`}</p>
    <p>{`“S-sure.” Dimitri spluttered.`}</p>
    <p>{`“Nice meeting you, Reus’s sister!” Sylvain yelled as Reus closed the door.`}</p>
    <p>{`“They have no idea, do they?” Reus said as a matter of factly, he let her lead the way back to the school entrance.`}</p>
    <p>{`“I hope not,” she replied, “By the way, uh...do you think we should tell dad what’s happening?”`}</p>
    <p>{`He looked at her in alarm. “What? Why?”`}</p>
    <p>{`“I’m suddenly feeling… guilty for lying like this and for potentially getting you in trouble. I already have my evidence anyway-”`}</p>
    <p>{`“After all this? By, you’re seeing this to the end. As for dad, I don’t know. He didn’t exactly take it well on the phone when I was expelled.”`}</p>
    <p>{`She sighed, “I mean, dad was upset at how the school treated you more than you getting into a fight. We’ll see how it goes. He might even know something is up, you know how he is.”`}</p>
    <p>{`Reus slung an arm around her. “Yeah. You’re doing the right thing...the wrong way.” They both chuckled.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Ten minutes later, they were swept up in their father’s arms. “We’ve missed you too dad…!” Byleth said, gasping for air. “Dad this is embarrassing—” Reus was hugged even tighter.`}</p>
    <p>{`“Oh come on, give your old man some slack, I’ve not seen the both of you for two months! It’s really nice to see you both. Especially you, Byleth! I wasn’t expecting you at all.” Their dad released them, “And you cut your hair!! Man, I’ve not seen the two of you with the same hairstyle since you were still bathing together!” He laughed.`}</p>
    <p>{`They started walking towards the cafeteria, with Byleth discreetly leading the way.`}</p>
    <p>{`“Reus, I’m sorry I wasn’t here in person for the whole thing, I-I really couldn’t get away—”`}</p>
    <p>{`“S’okay dad, really. I had By with me here.”`}</p>
    <p>{`“Well, at least you’re settling in good. I have to thank Rhea for accepting you,” Jeralt said.`}</p>
    <p>{`“And you, kid!” He called out to her. “How’re you taking your uh...defeat? I hope you didn’t spend all that money in one place!” He teased. Byleth and Reus both threw a sideways glance at each other.`}</p>
    <p>{`“I’m coping, I guess?” she said. “A-anyway I hear the food is decent here, right Reus?”`}</p>
    <p>{`“Oh yeah, totally. I uh really love the uh…” Reus elbowed Byleth.`}</p>
    <p>{`“Gronder Meat Skewers!” she blurted out, “You said it was good huh? I wonder if they have it today.”`}</p>
    <p>{`As they entered the cafeteria, ordered their food and sat down, Mr. Alois the Resident Advisor yelled, “Mr. Eisner?!” The three of them looked up, including Byleth who quickly looked down. She heard her dad grunt. “Not this guy,” he muttered under his breath.`}</p>
    <p>{`“Dad, you know him?” Byleth asked.`}</p>
    <p>{`“Used to.” Jeralt forced a smile and stood up as Mr. Alois walked over.`}</p>
    <p>{`Jeralt gave the beaming man an awkward pat on the back, relieved there weren’t many students in the cafeteria to witness this. “Yeah. Uh, thanks for looking after my son.” He gestured at Reus, who despite his lack of expression was absolutely confused, Byleth noted. It was the subtle movements in his face.`}</p>
    <p>{`“I knew your name was familiar, young man! Ah, I see your sister has safely arrived too. How wonderful to meet your family!” Beaming, Mr. Alois patted both her and Reus’s shoulders. “What did you order? No- No matter, I’ll just ask the cashier and pay!”`}</p>
    <p>{`“Alois, no—And, he’s gone. ... What?” Byleth and Reus were giving him the `}<em parentName="p">{`‘What the hell is going on’`}</em>{` look. Jeralt groaned.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“This is why I didn’t want to come here.” He sighed. “Alright, alright. I used to work here as head of security, it’s where I met your mother. That guy, Alois, used to work with me. And then I got married, you guys were born, your mother died and here we are.”`}</p>
    <p>{`“But you said you met mum while rescuing her from a dangerous situation!” Byleth accused, and Jeralt guffawed, shaking his head.`}</p>
    <p>{`“You mean you didn’t?” Reus asked.`}</p>
    <p>{`“`}<em parentName="p">{`Technically`}</em>{`, I did.” Jeralt wiped a tear from his eye, “She was investigating a coworker misappropriating some club funds and he got violent when she confronted him in the staff room. Ah, good times.” Byleth sat up straight; her situation sounded eerily familiar. Reus seemed to be thinking the same as he made eye contact with her.`}</p>
    <p>{`“So is that how you managed to get me a spot in this school so fast? Actually, why didn’t you enroll me here from the start?”`}</p>
    <p>{`“Yes, and to be honest, this place has too many painful memories for me. But seeing as you needed to go to school somewhere after `}<em parentName="p">{`that`}</em>{` happened, I pulled some strings.” Jeralt shrugged.`}</p>
    <p>{`Their meal arrived, much more than they had ordered and Jeralt chalked it up to Alois adding to their order.`}</p>
    <p>{`A while later, Sylvain appeared behind Reus and squeezed Reus’s shoulders in greeting ”Hey Reus!” Both Byleth and her brother turned around. “You ass, you didn’t say your sister was hot!” She quickly turned away and nearly spat out the piece of meat she was chewing, Sylvain was flirting with her in front of her father `}<em parentName="p">{`and`}</em>{` brother. That’s some balls of steel. She prayed Reus wouldn’t beat the crap out of him like he did the other guy.`}</p>
    <p>{`To her surprise, Reus chuckled. “Dude, I bit a guy for saying shit about my sister. `}<em parentName="p">{`Of course`}</em>{` she’s hot.” Byleth’s face turned so red even Jeralt started laughing.`}</p>
    <p>{`“Hi Mr. Eisner, I’m Sylvain Gautier, I share a dorm with Reus here.” Sylvain extended a hand as he winked at Byleth, who was still looking away, for fear Sylvain would recognize her. ‘`}<em parentName="p">{`I never forget a woman’s face’`}</em>{` was what he said once.  It would be wise to avoid him if she wanted to pull this off.`}</p>
    <p>{`“Nice to meet you young man, do join us.” Jeralt returned the handshake.`}</p>
    <p>{`“Sylvain, why did you run off like that!” Dimitri said as he caught his breath, then straightened immediately upon seeing Jeralt. “Oh my apologies, you must be Reus’s father, I’m Dimitri.” Dimitri bowed. “I’m sorry about Sylvain Reus, I hope he hasn’t done any damage to your sister’s….virtue…” Byleth turned around at Dimitri’s voice, whose speech trailed off when they made eye contact. She noted his cheeks turning pink.`}</p>
    <p>{`“Too late, he already said she’s hot.” Reus smirked, Byleth was pleased Reus was blending in so naturally, as if he really did spend the past week with them. Felix emerged and elbowed Dimitri, who snapped out of his daze.. “Sorry about these two idiots, Mr. Eisner. I’ll get rid of them.”`}</p>
    <p>{`Jeralt let out a throaty laugh, “Don’t worry! Please join us, there’s far too much food for just the three of us.”`}</p>
    <p>{`Sylvain wriggled himself out of Felix’s grip and quickly sat down next to Byleth. “I can’t say no if you insist like that, Mr. Eisner.” She stood up and excused herself to the ladies. Sylvain let out a small whine when she rejected his offer to escort her, just in case she got lost.`}</p>
    <p>{`The ladies toilet was situated outside the cafeteria, near the greenhouse which was where she bumped into Dedue who was about to turn into the cafeteria for lunch.`}</p>
    <p>{`“Sorry,” Byleth said, avoiding eye contact with him.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“I am sorry as well,” Dedue said but then didn’t move from his spot, she could feel his gaze bore a hole in her. “Please pardon my boldness, but are we acquainted?”`}</p>
    <p>{`“Don’t think so.” She continued to avert her eyes away from him. “W-where’s the bathroom? For ladies, I mean.” `}<em parentName="p">{`Of course the ladies, you’re obviously a girl now.`}</em></p>
    <p>{`Still keeping his hard gaze on her, he gave her the directions, she said her thanks and briskly walked away before Dedue could deduce anything else.`}</p>
    <p>{`Standing in front of the mirror, she stuffed a hand into her bra and adjusted her breasts. Byleth felt a little uncomfortable wearing a bra after having her chest flattened for so many days.`}</p>
    <p>{`Walking back to her table, she saw that Dedue and Ashe had joined the others and were listening to her Dad’s stories about some of his ‘treacherous’ assignments.`}</p>
    <p>{`“How did you get out unscathed then, Mr. Eisner?” Ashe leaned in eagerly, Jeralt grinned like a Chesire cat. A glint in his eye. Byleth knew that look.`}</p>
    <p>{`“I didn’t kid, that’s how I got this scar here.” He tapped his cheek. “If I had dallied any sooner, I would say I would’ve lost half of my face!”`}</p>
    <p>{`“Whoaa!” Ashe’s eyes lit up. “Mr. Eisner, you’ve gotta train us sometime!”`}</p>
    <p>{`“I agree, you seem more competent than that useless bag of meat that is our current Coach.” Felix’s lips twitched into a grin, the cunning type.`}</p>
    <p>{`“He did train us for the tournament though—oh, Miss Eisner!” Dimitri stood up so abruptly his chair fell backwards.`}</p>
    <p>{`“Oh, just call me Byleth,” she said, making sure it was as girly as possible and flashed him a smile`}</p>
    <p>{`“M-Miss Byleth. Hello.” Dimitri sheepishly picked up his chair and sat back down.`}</p>
    <p>{`Sylvain was holding back his laugh as Felix hissed disapprovingly at Dimitri. “What’s the matter with you?”`}</p>
    <p>{`“N-nothing.” Dimitri covered his mouth, looking away. Still pink-faced.`}</p>
    <p>{`“Ah crap, I’ve gotta go check-in to my hotel now,” Jeralt said looking down at his watch, “You boys want anything else to eat? My treat.”`}</p>
    <p>{`They all looked at each other and shook their heads.`}</p>
    <p>{`“Thank you for the offer, Mr. Eisner, but you’ve given us enough treats already!” Ashe said.`}</p>
    <p>{`“Nonsense. Here, take this.” Jeralt shoved a card in Reus’s face.`}</p>
    <p>{`“Order anything they want. You kids need to eat more to grow. Oh no, wait, that card is in overdraft.” He pulled his hand back and gave Reus another card. “I’ll come pick you guys up for dinner, yeah?”`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Thanks Mr. Eisner!” Ashe said.`}</p>
    <p>{`“No problem kid.”`}</p>
    <p>{`“Dad wait,” Byleth walked over to her dad as he was leaving. “Can I stay with you? I didn’t book a hotel room— ”`}</p>
    <p>{`“Of course! Anyway I gotta go check-in and then I’m gonna take a nap. I drove all the way here, ya know. You want to come with me now?”`}</p>
    <p>{`“Um, no. I wanna catch up with Reus.”`}</p>
    <p>{`Jeralt shrugged. “Call me if you need anything.”`}</p>
    <p>{`Byleth took her seat next to Reus, who stood up to go order food for his ‘friends’.`}</p>
    <p>{`“I’ll come with you!” Ashe said`}</p>
    <p>{`“Allow me to come as well.” Dedue joined in. Reus turned back to look at Byleth, who mouthed a ‘good luck’ to him. As soon as they left the table, Sylvain scooted his chair nearer to her. If she wasn’t supposed to be undercover, she would’ve knocked his lights out. So persistent! Felix did warn her about it though.`}</p>
    <p>{`Sylvain opened his mouth but then closed it again. “Have we met?”`}</p>
    <p>{`“Yeah, about an hour ago?” Byleth said nonchalantly.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Sylvain chuckled. “Right. I mean, I know you’re twins but damn you two really, `}<em parentName="p">{`really`}</em>{` look alike. Are you sure we’ve not met before?”`}</p>
    <p>{`“I hate to say this, but I agree with this idiot here,” Felix spoke up.`}</p>
    <p>{`“I-I uh, I think we might’ve met her at the tournaments before this? Her team was our um... opponents for the finals,” Dimitri avoided making any eye contact with her. Good.`}</p>
    <p>{`“Ohh! `}<em parentName="p">{`Damn`}</em>{` girl, I knew I’d seen you somewhere before! I never forget a girl’s face!” Sylvain pounded his fists on the table in glee.`}</p>
    <p>{`“Hrmph. I see. Would you like to spar with me today?” Felix asked.`}</p>
    <p>{`“My man, that is `}<em parentName="p">{`not`}</em>{` how you pick up girls. This is why you’re single.” Sylvian patted the annoyed Felix on the shoulder.`}</p>
    <p>{`“You’re one to talk. You’re single right now.” Felix snapped back.`}</p>
    <p>{`“By choice!” Sylvain cried out.`}</p>
    <p>{`“Shuddup.”`}</p>
    <p>{`Byleth let out a tinkle of a laugh, hoping to throw them off her trail. Please, let her appear feminine enough to not look like Reus. “I wouldn’t mind, but I’m afraid I’m not dressed for the occasion, nor did I bring the proper clothes. Maybe next time?” That said, she was feeling a little guilty, but also extremely amused that they hadn’t recognized her, yet.`}</p>
    <p>{`She couldn’t help but feel that the universe would prove her wrong, soon.`}</p>
    <AuthorNote mdxType="AuthorNote">
  <p>
    THANK YOU FOR READING SO FAR!!! I'VE READ ALL YOUR COMMENTS AND FEEDBACK AND
    I SCREENSHOT EVERYTHING!! Also special thanks to my editor(s)!!
  </p>
  <p>
    Edit: @illusidy drew Byleth’s outfit idk how srsly how she plucked the exact
    image out of my mind its gorgeous sdakjfhaksdjhf
  </p>
  <Image name="byleth-look.png" caption="Byleth's look in this chapter. Thank you @illusidy!" mdxType="Image" />
  <p>
    <RemoteLink to="https://twitter.com/illusidy/status/1264546371333320705?s=20" mdxType="RemoteLink">
      https://twitter.com/illusidy/status/1264546371333320705?s=20
    </RemoteLink>
  </p>
  <p>SHE ALSO DREW REUS AND HIS SMUG ASS---</p>
  <Image name="reus.png" caption="Smug Reus!" mdxType="Image" />
  <p>
    <RemoteLink to="https://twitter.com/illusidy/status/1263815254854610944?s=20" mdxType="RemoteLink">
      https://twitter.com/illusidy/status/1263815254854610944?s=20
    </RemoteLink>
  </p>
  <p>
    I cry/squeal/die everytime anyone reads/comments/retweets/likes my fanfic
    LET ALONE DRAW FAN ART IM--I CANNOT-!!!!!!!!!!!!! THANK YOU FOR SPENDING
    TIME ON THIS FIC OF MINE!!!!!!!!!!!!!!!!!!!!!!!!!! I LOVE YOU ALL!!!!!
  </p>
    </AuthorNote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      