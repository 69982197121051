import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const RemoteLink = makeShortcode("RemoteLink");
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const Blockquote = makeShortcode("Blockquote");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Kufa Vampir has every right to be proud of his pupil because this... ain't an isekai plot for once! I know how hard it is to watch shows with an isekai opening because it seems super baity... Nevertheless, there are a ton of isekai shows that have great stories! However, for the past year, we've been getting at least a few anime with the same starting dialogue. It can get really tiring just seeing the typical `}<em>{`"I was reincarnated..."`}</em>{` opening.`}</p>
    <p>{`You don't need those! Sometimes all you need in a fantasy story is just decent worldbuilding opening act, and Assassin's Pride delivered on that.`}</p>
    <em>
  Note: This piece is written after watching episode 1 and 2. I have also caught
  up with the latest scanlated manga. All pictures from this blog post are
  sourced from{' '}
  <RemoteLink to="https://assassinspride-anime.com/en/" mdxType="RemoteLink">
    Assassin's Pride official website
  </RemoteLink>
  .
    </em>
    <p>{`This story starts off by explaining our heroine, Melida Angel struggling to prove her worth to her family with the help of a mysterious yet capable private tutor, Kufa Vampir. Of course, nothing is as simple as it seems. As the story unfolds, we are introduced to added hidden plots. Nevertheless, the plot is still very simple to follow, and both titular characters are really likeable.`}</p>
    <Ads mdxType="Ads" />
    <p>{`I watched the episodes as a manga reader so I went into the series feeling quite optimistic. The source material is really good. Surely the anime would be equally great? Hopefully... Fingers crossed!`}</p>
    <p>{`After finishing the first episode, I was `}<em>{`disappointed`}</em>{` by the choices the studio made. The production was pretty good, but the pacing isn't up to par with what I expected. The anime focused only on the important parts/dialogues and took their own creative liberties to highlight minor sub-plots. E.g. Kufa's scene with the injured cat was probably added to highlight Kufa's tender side.`}</p>
    <Image caption="Melida's cousin, Elise Angel. You can tell they are cousins from those two little cute ponytails? Pony... bobs?" alt="Assassin's Pride Elise Angel" name="assassins-pride-image-1.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <p>{`Many details that made Kufa a much more grounded character was pretty much non-existent. His inner monologue provided a different facet than what we knew from the anime. They even changed an important scene to squeeze the ending into the first episode. The changes are understandable, but not a `}<strong>{`good one`}</strong>{`. I hope this quick pacing is only limited to the first few episodes.`}</p>
    <p>{`Nevertheless, `}<RemoteLink to="http://emt2.co.jp/" mdxType="RemoteLink">{`EMT Squared`}</RemoteLink>{` did a great job with Assassin's Pride. They did not skimp on the art and animation. The moment I saw how they animated the hair locks and fabric, I sighed in relief. Assassin's Pride is in good hands. The background art is pretty detailed as well. Most of the scenes took place at night, so I can't help but noticed how well done these scenes are.`}</p>
    <p>{`They added lens flare on the light sources, background blur when the scenes focus on the character's face. They added a layer of night ambience too. Add all those together, even static scenes are fun to look at. It's no Ufotable, but it's evident that the studio put in a lot of effort to make the episodes as enjoyable as possible.`}</p>
    <Ads mdxType="Ads" />
    <Blockquote mdxType="Blockquote">
  The added cinematic effects helped a lot in making this show much more
  enjoyable than usual.
    </Blockquote>
    <p>{`The BGM was pretty rad in the first 2 episodes. I'll leave the final judgment for after the full season is out. However, I noticed something strange with the sound mixing. Voices sometimes overpower the sound effect, or music being way too soft to accentuate the exciting scenes at times. The opening song that started only at Episode 2 is pretty catchy. Pair that with the graphic design-like art from the opening, I'd say it's done pretty well. Obviously, nothing beats my favourite `}<RemoteLink to="https://www.youtube.com/watch?v=L49mJLk_w1U" mdxType="RemoteLink">{`opening`}</RemoteLink>{`.`}</p>
    <p>{`Overall, I'm really happy with how they are handling Assassin's Pride. I have a few complaints on the pacing but all in all, it's an understandable decision. It is still an enjoyable series that you should try watching. Then again, maybe half of my enjoyment came from listening to Yuuki Ono's bizarrely sexy take on Kufa's voice.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      