import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const LocalLink = makeShortcode("LocalLink");
const RemoteLink = makeShortcode("RemoteLink");
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I once talked about this underrated manga in my `}<LocalLink to="/blog/my-top-5-anime-manga-games-in-2020/" mdxType="LocalLink">{`Top 5 for 2020 blog post`}</LocalLink>{`. I think it was published some time ago, but it was only in 2020 that I discovered this title. There’s a ton of manga out there that writes about this cliche, but `}<strong parentName="p">{`Killing Me/Killing You (キリングミー/キリングユー)`}</strong>{` is a great revisit for this cliche with fresh paint.`}</p>
    <RemoteLink to="https://twitter.com/Immortal1664" mdxType="RemoteLink">
  Killing Me/Killing You
    </RemoteLink> is about two immortals looking for a way to die in this apocalyptic
world. We first see how the world came to end through the eyes of Mithia. A meteor
shower appeared without warning and wiped out Earth’s surface. Mithia was a young
child when it happened. She rushed outside to view the wondrous view and voila, a
meteor shard embedded itself on her forehead. Since then, she couldn’t die no matter
what she did nor could she pull the accursed shard out of her head. Thus, she decided
to go on a journey to look for someplace, something or someone to kill her. It is
during this lonely journey that she met Youthanasia, a mysterious man with an equally
mysterious cap that covers his head. Like her, Youthanasia couldn’t die which is
why he’s on a journey to kill himself too. Since both of their goals align, these
two immortals decided to travel together.
    <Ads mdxType="Ads" />
    <Image alt="Killing Me/Killing You Mithia" caption="This is Mithia. She is super cute. Wait until you see her die again and again..." name="killing-me-killing-you-manga-art.jpg" mdxType="Image" />
    <p>{`I went in with no idea how the author will do this cliche, so I was really surprised at the type of character these two are! First, let’s talk about Mithia. She’s a super noisy and enthusiastic girl when it comes to dying. At every point in the story, she’ll go around doing anything to die. For example, jumping to her death and twisting her neck. Youthanasia often has to go in right after her to retrieve her body. Since she’s an immortal, these injuries heal quickly and are more of a nuisance than anything. Her enthusiasm to die is understandable, but her goal to end her life is actually way simpler than that - she’s lonely. She mentioned a ton of times in the story that she has nothing going for her in this life. The only conclusion she could derive from that is death.`}</p>
    <p>{`I expected her character to be really gloomy and barely living her life. Instead, I got a really peppy heroine who goes around cheering folks up who had their lives ruined by the meteorite? She says she is lonely, but she goes around encouraging folks to live their best lives? Yooo what is going on. How could you be so wholesomeeeee! This whole manga is so contradictory but I guess that’s what makes it so good. Mithia’s story is pretty much all there is to it, but Youthanasia has secrets he isn’t telling his travelling partner. Youthanasia is the type of character I expected from this cliche. He’s a realist and guards himself well. Something or someone must have hurt this man. You do get hints throughout the story but so far, nothing conclusive yet. The characterization of Youthanasia could have ended there but the author made him a really kind man towards anyone who needs his help. This makes him a perfect partner for Mithia.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Youthanasia and Mithia from Killing Me/Killing You" caption="From the left, Youthanasia and Mithia. Both just recovered from a deadly attack." name="kiling-me-killing-you-manga-page.jpg" mdxType="Image" />
    <p>{`She’s jumpy and often gets in trouble so Youthanasia is always there to help her out. Mithia too, balances out their relationship by showing a positive outlook in life to Youthanasia. It’s honestly a match made in heaven (No romance!) along with their journey to inspire folks to live. Together, they showed everyone that there’s hope beyond the current situation. I’m really enjoying the dialogue and how they approached the plot. I can’t wait to see how they’ll react when they look back and go, “Whoa, we inspired these folks to do all of that?”`}</p>
    <p>{`There’s only several chapters translated so far and I’m sad. This wholesome manga deserves attention. Now that you know of this manga, please go give this title a try! `}<RemoteLink to="https://twitter.com/KMKY_official" mdxType="RemoteLink">{`Here's the official twitter!`}</RemoteLink></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      