import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I have this stereotypical view on `}<strong parentName="p">{`mobile games`}</strong>{` - nice to have but absolutely terrible in gaining my attention span in the long run. I’d download the game to give it a try then uninstall as soon as I am bored with it. Most mobile games don’t want to give you a deep gaming experience, they just want you to keep you occupied enough through the most `}<em parentName="p">{`simplistic`}</em>{` gameplay.`}</p>
    <p><em parentName="p">{`Note: All screenshots are taken from my iPhone 11.`}</em></p>
    <Ads mdxType="Ads" />
    <p>{`I played my fair share of `}<strong parentName="p">{`gacha mobile games`}</strong>{`. Some are fun but most are super `}<em parentName="p">{`manipulative`}</em>{`! They’ll limit the energy bar so you’ll feel compelled to pay to unlock more energy to continue playing! Some games are more generous than usual while some have a different modus operandi to get the money coughing. Regardless, there are many gacha games that give out free gems and pulls because they really want you to stay and that’s great! The players are more than happy to stay but in the end, it’s the gameplay and community size that speaks the loudest. While I couldn’t vouch for the community, I could certainly vouch for `}<strong parentName="p">{`Tales of Crestoria’s`}</strong>{` gameplay and graphic quality!`}</p>
    <Image alt="Tales of Crestoria menu screen" caption="I'm just going to leave the menu screen here so you know who are the main cast! I will be dumping some screenshots in this blog post with these characters, so they are technically... not spoiler?" name="tales-of-crestoria-23.jpg" mdxType="Image" />
    <p>{`Tales of Crestoria is a mobile game that I’m really looking forward to ever since the trailer debuted 2 years ago. It’s been so long since `}<em parentName="p">{`I last played a Tales game`}</em>{`. Besides, it’s `}<strong parentName="p">{`free`}</strong>{`! What’s there to stop me from giving it a try?`}</p>
    <Video title="" src="https://www.youtube.com/embed/QN80N77c8TE" mdxType="Video" />
    <Ads mdxType="Ads" />
    <p>{`I’ve been playing for two months now and I can safely say I’m `}<em parentName="p">{`hooked`}</em>{`. I try to login everyday to give it a whirl and complete the daily missions, maybe even complete the Story Quests!  Tales of Crestoria `}<strong parentName="p">{`is`}</strong>{` a gacha game, but this is still a Tales game to the core. The world of Crestoria is governed by Vision Orbs, a tool given to every citizen when they are born. Think of Vision Orbs as a free camera that allows you to record a person’s wrongdoing. Once recorded, it gets broadcasted to every Vision Central where folks could judge if the wrongdoer deserves to get `}<em parentName="p">{`punished`}</em>{` (No, not with sticks and stones!). If everyone thinks you deserve to be brought to justice, the Vision Orb breaks and sears itself on the wrongdoer’s body. This brand is called the Stain of Guilt which marks the person as a criminal. Now, everywhere you go, an `}<strong parentName="p">{`enforcer`}</strong>{` will magically appear out of thin air to hunt you down until you finally breathed your last.`}</p>
    <Image alt="Misella and Kanata from Tales of Crestoria" caption="From the left, Misella and Kanata. They both have the hots for each other, and this is not a spoiler. It's canon :3" name="tales-of-crestoria-6.jpg" mdxType="Image" />
    <p>{`Our main characters,`}<strong parentName="p">{` Kanata Hjuger`}</strong>{` and `}<strong parentName="p">{`Misella `}</strong>{`are two unlucky folks who were branded as `}<em parentName="p">{`transgressors`}</em>{` because they were wrongly accused. They both believe what they did is right `}<em parentName="p">{`despite`}</em>{` knowing the consequences. The two are about to throw in the towel until Vicious comes along, telling them to live while owning your sins. Along with their new comrade, they had to keep running from enforcers `}<strong parentName="p">{`and `}</strong>{`knights that wish to bring them in. `}<em parentName="p">{`But is this truly the way to live?`}</em>{` As you progress through the Story Quests, mysteries about the Vision Orbs start to clear up a little. You’ll get to see how the characters struggle to accept that if they wish to live, they’ll need to come to terms with their sin. I honestly think the plot is super dramatic but eh, this is a Tales game. The dramatic flair works really well for this franchise.`}</p>
    <Ads mdxType="Ads" />
    <p>{`They included some new minor characters exclusive to Tales of Crestoria but the focus is largely on the main cast. The rest is padded out with characters from the `}<em parentName="p">{`older titles`}</em>{`. Yes, characters from older titles make an appearance too in the story! Obviously a ploy to justify the gacha elements, but I find this an absolute delight because there’s a ton of throwback to the roles in their original game. `}<em parentName="p">{`Luke`}</em>{` from`}<strong parentName="p">{` Tales of Abyss`}</strong>{` for example, screwed up his work as a peace ambassador in Tales of Crestoria! Then there’s Cress who runs a dojo and taught Kanata how to fight. It’s seriously a nice touch for the fans. And I’m absolutely stoked to see Tear in the game.`}</p>
    <Image alt="Magilou from Tales of Berseria" caption="If your favourite character isn't in the game as a playable character, then they ought to be in the list of Memoria Stones instead. Memoria Stones are like equippable gems to boost your playable characters' stats." name="tales-of-crestoria-41.jpg" mdxType="Image" />
    <p>{`Other than the Story Quest which is obviously, the core of the gameplay… There’s a ton of other modes to play too. Gacha players will be familiar with these modes - `}<em parentName="p">{`Arena, Raids, Power-Up/Gald Quests, Story Quests, Limited Quests and Phantom Tower.`}</em></p>
    <Image alt="Tales of Crestoria's gameplay modes" caption="Here are the gameplay modes in this game. This is the Quest screen. You can access the Raid mode on the Home screen." name="tales-of-crestoria-30.jpg" mdxType="Image" />
    <p><strong parentName="p">{`Arena`}</strong>{` lets you battle PvP but the other party is controlled by AI. You get matched with teams that are almost similar with you in ranking for a fair fight. I notice that the teams can be pretty tough to beat if they are overwhelmingly powerful, but with some smart strategizing you could easily defeat a team slightly stronger than you. `}<strong parentName="p">{`Raids`}</strong>{` let you fight a super boss for great rewards. I didn’t play this much but one thing I know for sure, you will need to power up your team `}<strong parentName="p">{`a lot`}</strong>{` if you want to solo the fight. I usually need to request third party help!`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Arena gameplay in Tales of Crestoria" caption="After winning in an Arena fight, you get goodies and Prestige. Increasing your Prestige will raise your enemy difficulty and prizes." name="tales-of-crestoria-24.jpg" mdxType="Image" />
    <p><strong parentName="p">{`Power-Up/Gald Quests`}</strong>{` shower you with EXP pots or gald if you win the battle. It’s a type of quest common in most gacha games to help you level up your team or rank them up! `}<strong parentName="p">{`Story Quests`}</strong>{` is as implied, relating to the main story for Crestoria. It’s split up by Main Story and Side Stories. Follow along the main cast’s story in Main Story while clearing Side Stories to get a better view of the side cast’s POV. There’s a ton of things going on here in terms of user interface and experience, I find it really nice since most gacha games just offer boring options to tap. An overworld map in the background gives a nice sense of dimension here.`}</p>
    <Image alt="null" caption="The Story mode's control is a bit confusing. If I wish to view the overall chapter list (usually it is zoomed in on a single chapter), what buttons do I press? Here on this Story mode, we see the overworld map at the background. Tapping on one of the sub-chapters will zoom in on the map!" name="tales-of-crestoria-1.jpg" mdxType="Image" />
    <p><strong parentName="p">{`Limited Quests`}</strong>{` are time-based quests that the official Twitter account will announce. You can clear these quests for some nice rewards, like more medals to exchange rare items for or even a rare character! It’s a race to get it done as soon as possible so you could finish it before the time is up, so I usually `}<strong parentName="p">{`try to`}</strong>{` complete these quests once I’m done with the Story Quests for the day. I say try because the amount of time needed to complete them usually scares me off… `}<strong parentName="p">{`Phantom Tower`}</strong>{` is yet another nice addition to test your mettle. Clearing each floor gives you great rewards, but the higher you climb the tougher the enemies are.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Each mode is really fun since the battles are great to watch and the music is a blast! Not to mention the time needed to clear each one if you really put your heart into it. The game wants to keep you occupied for long hours and so far, it’s done the job. But enough about the modes, here’s the one thing I really want to talk about - `}<strong parentName="p">{`graphics`}</strong>{`.`}</p>
    <p>{`Mobile game graphics are limited, I get it. You need to support a ton of older phones and take into account their performance level. So most mobile games opt for 2D art or chibi art, whichever that suits their game’s vision since those are the surefire way to cater to the older phones. But here is Tales of Crestoria, happily doing beautiful 2D art `}<strong parentName="p">{`AND `}</strong>{`3D models.`}</p>
    <Image alt="AEGIS ALVER! He plays the straight guy act in Tales of Crestoria's main cast." caption="The game shows a quick animation of the gacha summon, followed by a really cool voice acting if you pulled a new character you can use for your battle team." name="tales-of-crestoria-16.jpg" mdxType="Image" />
    <p>{`It’s not just amazing, these models look superb on my phone. Go to the Summoning page, and there’s the 3D models playing in the background. Go to the Character Room, and there’s the 3D model again. On your main menu, there’s Kanata again! It’s everywhere and it’s giving me a sense of `}<strong parentName="p">{`liveliness`}</strong>{`. No longer am I seeing flat user interfaces and 2D art in a gacha game, Crestoria made sure there is dimension and depth on every mode you are in.`}</p>
    <Ads mdxType="Ads" />
    <p>{`And did I mention that most of their lines are `}<strong parentName="p">{`voiced?`}</strong>{` No English option unfortunately, it’s all JP dub. I noticed some discrepancy between the English translation and Japanese dub but no worries, I think the English translation did the work perfectly. Yuna, one of Crestoria’s main cast for example, has a unique dialect that didn’t make sense when translated to English word by word. If they fudged up here, I wouldn’t think much about it but instead, they went the extra mile and included a ton of smart puns in. Long story short, the English dialogues are a joy to read given how straightforward the dialogues are in Japanese!`}</p>
    <p>{`Okay, so how about battles in Crestoria? Forgoing the action battles that is the staple of Tales games, Crestoria did turn-based instead. It’s a `}<strong parentName="p">{`4-vs-4 battle`}</strong>{` where everyone took turns to smack each other’s HP down. Each character is given 2 skills, 1 normal attack and 1 Mystic Artes (only available to SSR characters). Skills have a cooldown while Mystic Artes require the characters to keep filling up their Overlimit (OL) gauge until it’s ready for action. I’m telling ya, the flashy effects are great. Mystic Artes is a full blast cutscene along with the usual cut-in we’re all familiar with! The skills animation are yet another throwback to their older titles, and the characters' moves/poses are similar to their original game! I remember that Radiant Howl and Burning Havoc moveset from Luke… I spam it a ton on my PS2!`}</p>
    <Image alt="Arena battle in Tales of Crestoria" caption="An Arena battle screenshot. It's 4-vs-4! You're given 2 skills to tap, and a normal attack tap. The biggest diamond on the right is the Mystic Arte skill, which you can use once your OL gauge is full." name="tales-of-crestoria-25.jpg" mdxType="Image" />
    <p>{`There’s also a ton of mechanics that I didn’t explain such as the Memoria Stone, Cooking, Ascension, Guild, etc. This game is super packed, built really well and I’m stoked they put in so much effort to make this game a reality. The user interface is really nice to look at and super lively since there’s a ton of animation going on screen. Music is basically just simple guitar tracks instead of the usual RPG orchestra but oh boy, it’s so good I have it on loop at work.`}</p>
    <Ads mdxType="Ads" />
    <p>{`The story so far is pretty much `}<em parentName="p">{`there`}</em>{` but it’s padded out with battles just so you’ll waste your Action Points! Ironically everything else in this game is pretty good so it didn’t bother me much. The game makes sure that you are rewarded adequately for every successful battle you completed. Everything you do pretty much rewards you in order to make it easier for your team to improve. I personally think that the gacha is pretty lenient! I pulled 10 SSR (The best units) units without needing to pay at all. I play super casually so the gems needed for each pull are from completing quests or as login rewards only. Either my luck is super good or the game just wants the best for me after noticing my sad, sad team.`}</p>
    <p>{`Honestly, this is a great Tales game that I hope more folks try this game! At the very least, please play for the story. The only two things that irk me in this game is the need to show me a loading screen for every tap I do `}<strong parentName="p">{`AND `}</strong>{`the terrible timing for server maintenance. Boy, the maintenance lasts really long too.They released a new, short animation while I was dilly-dallying in writing this. Enjoy the animation readily available on YouTube!`}</p>
    <Video title="Tales of Crestoria" src="https://www.youtube.com/embed/GLsuACdAfeA" mdxType="Video" />
    <p>{`I wanted to add a ton of screenshots in-between the paragraphs, but I find it a tad intrusive. So here are the screenshots that I didn't include in the write-up above!`}</p>
    <Image alt="Tales of Crestoria Screenshot 1" caption="I am loving how they managed to add a list of older games AND using their game logo as well! You will see this popup when you need to filter the characters' by game or read each game's story summary. Yup, they added each older game's summary too!" name="tales-of-crestoria-40.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <Image alt="Tales of Crestoria Screenshot 2" caption="This is the most annoying screen in this game. My Internet is so slow that every tap I do in this game will show this loading screen for a long time. I had to turn on my mobile data to play this game. Many folks complained on Twitter about this issue (of showing a loading screen for anything you tapped on) but I'm afraid the loading screen is here to stay." name="tales-of-crestoria-38.jpg" mdxType="Image" />
    <Image alt="Tales of Crestoria Screenshot 3" caption="Tales of Crestoria has a ton of CG for important scenes." name="tales-of-crestoria-31.jpg" mdxType="Image" />
    <Image alt="Tales of Crestoria Screenshot 4" caption="Why is this an important CG? That's because it's about Yuna! She is a great character, we must appreciate this CG." name="tales-of-crestoria-19.jpg" mdxType="Image" />
    <Image alt="Tales of Crestoria Screenshot 5" caption="Yes, they have skits in Tales of Crestoria! Unfortunately, most of the skits I encountered are way too short..." name="tales-of-crestoria-17.jpg" mdxType="Image" />
    <Image alt="Tales of Crestoria Screenshot 6" caption="I love the battle results screen. Sometimes the characters would bust out their quotes if they are from the same game! I got Kanata and Vicious talking together if I have them on my battle team." name="tales-of-crestoria-28.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <Image alt="Tales of Crestoria Screenshot 7" caption="You can level your character affinity by having them in the battle team too. What does it do? Well, it unlocks new Voice Clips and rewards leveling goodies. If you leveled up your main cast characters (E.g. Kanata, Misella), you may unlock character-specific cutscenes!" name="tales-of-crestoria-22.jpg" mdxType="Image" />
    <p>{`I hope you enjoyed the screenshots! Make sure to play the game, it needs a playerbase to stay alive.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      