import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';

import $ from '../../../styles/globals';
import { getDate } from '../../../utils';

const Container = styled.div`
  height: 400px;
  overflow: auto;
  border: 1px solid ${$.grey1};
  padding: ${$.layout.padding2}px;
  border-radius: 15px;
  margin-bottom: ${$.layout.margin5}px;

  ${$.screen.tablet} {
    height: 50vh;
  }

  ${$.screen.mobile} {
    height: 40vh;
    margin-top: ${$.layout.margin3}px;
  }

  ${$.verticalScroll}

  > * {
    border-bottom: 1px solid ${$.grey1};
  }
`;

const Chapter = styled(Link)`
  text-decoration: none;
  display: flex;
  padding: ${$.layout.padding3}px ${$.layout.padding2}px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  font-size: 18px;
  color: ${$.brown4};
  font-family: Rubik Regular;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  line-height: 18px;

  ${$.screen.mobile} {
    font-size: 16px;
  }
`;

const NewPill = styled.div`
  border-radius: 15px;
  background-color: ${$.color.red1};
  padding: 3px ${$.layout.padding2}px;
  font-size: 14px;
  color: ${$.color.white};
  margin-left: ${$.layout.margin2}px;

  ${$.screen.mobile} {
    font-size: 14px;
  }
`;

const PublishedDate = styled.div`
  font-size: 15px;
  color: ${$.brown3};
  font-family: Rubik Regular;
  font-weight: bold;

  ${$.screen.mobile} {
    font-size: 14px;
  }
`;

const showNewPill = (str) => {
  const givenDate = new Date(str);
  const currDate = Date.now();

  return Math.abs(givenDate.getTime() - currDate) <= 1000 * 60 * 60 * 24 * 14;
};

const Chapters = ({ chapters }) => {
  const newChapters = [...chapters].reverse();

  return (
    <Container>
      {newChapters.map(({ title, published, slug }) => (
        <Chapter key={slug} to={`/${slug}/`} title={`Go to ${title}`}>
          <Title>
            <span>{title}</span>
            {showNewPill(published) === true && <NewPill>New</NewPill>}
          </Title>
          <PublishedDate>{getDate(published)}</PublishedDate>
        </Chapter>
      ))}
    </Container>
  );
};

Chapters.defaultProps = {
  chapters: [],
};

Chapters.propTypes = {
  chapters: PropTypes.arrayOf(PropTypes.object),
};

export default Chapters;
