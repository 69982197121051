import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Violet Evergarden has a new movie out on Netflix and it’s 1 hour 30 minutes long. In a way, that also translates to 90 minutes of visual-gasm. Buildings are elaborately drawn. Streets are wide and lively. Photography techniques are applied here and there. There’s a ton of bokeh effects, and Kyoto Animation did not skip out on framing each shot perfectly. It’s structured the same as the usual Violet Evergarden episode, albeit in movie format.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Violet Evergarden: Eternity and the Auto Memory Doll is set a year after the TV series. Thanks to her brilliant work she did for the Kingdom of Drossel (Ep. 5), Violet was recommended by the royal family to undertake a difficult task - ensure that Isabella, a lady from the York noble family to have a successful high society debut in the coming three months. Since Isabella is a student in an all-girls school, Violet would need to work as her customer's handmaiden. Violet would gently remind her of her school schedule, how to talk to fellow classmates, and would step in if Isabella misspoke. As time goes by, Violet earned Isabella’s trust and soon, Isabella confessed that she was once Amy Bartlett. When she is still Amy, she has a younger sister named Taylor Bartlett that she loved very much.`}</p>
    <p>{`The movie can generally be split to 2 parts. The first part tackles Amy as she gradually opened her heart to Violet while the next part is set 4 years later. The TV series focused on Violet’s emotional growth, as well as her work in writing letters for her customers. This time, however, it’s all about sending letters! And when it’s about getting letters to their destination, Benedict is our man. He gets more screen time in this movie and I’m honestly not complaining.`}</p>
    <p>{`When I wrote this blog post, I have no intention of reviewing the movie because there’s honestly very little I could say. Kyoto Animation has perfected their craft - the plot is simple, and the visuals are stunning. Violet Evergarden fans `}<strong parentName="p">{`are `}</strong>{`fans because they are looking for a touching story to enjoy, and this is `}<em parentName="p">{`exactly`}</em>{` what this movie is all about. There are `}<em parentName="p">{`so`}</em>{` many scenes that were beautifully framed, I kept tapping my screenshot key while watching this movie. Violet smiles a lot! So this is not a review post. I just wanted to share the screenshots I took! They are not ordered and maybe some minor spoilers? I won't put a caption on the images so there's no context. Enjoy!`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Violet Evergarden: Eternity and the Auto Memory Doll screenshot" caption="1" name="violet-evergarden-eternity-and-the-auto-memory-doll-25.png" mdxType="Image" />
    <Image alt="Violet Evergarden: Eternity and the Auto Memory Doll screenshot" caption="2" name="violet-evergarden-eternity-and-the-auto-memory-doll-28.jpg" mdxType="Image" />
    <Image alt="Violet Evergarden: Eternity and the Auto Memory Doll screenshot" caption="3" name="violet-evergarden-eternity-and-the-auto-memory-doll-5.png" mdxType="Image" />
    <Image alt="Violet Evergarden: Eternity and the Auto Memory Doll screenshot" caption="4" name="violet-evergarden-eternity-and-the-auto-memory-doll-24.png" mdxType="Image" />
    <Image alt="Violet Evergarden: Eternity and the Auto Memory Doll screenshot" caption="5" name="violet-evergarden-eternity-and-the-auto-memory-doll-27.png" mdxType="Image" />
    <Ads mdxType="Ads" />
    <Image alt="Violet Evergarden: Eternity and the Auto Memory Doll screenshot" caption="6" name="violet-evergarden-eternity-and-the-auto-memory-doll-14.png" mdxType="Image" />
    <Image alt="Violet Evergarden: Eternity and the Auto Memory Doll screenshot" caption="7" name="violet-evergarden-eternity-and-the-auto-memory-doll-12.png" mdxType="Image" />
    <Image alt="Violet Evergarden: Eternity and the Auto Memory Doll screenshot" caption="8" name="violet-evergarden-eternity-and-the-auto-memory-doll-2.png" mdxType="Image" />
    <Image alt="Violet Evergarden: Eternity and the Auto Memory Doll screenshot" caption="9" name="violet-evergarden-eternity-and-the-auto-memory-doll-15.png" mdxType="Image" />
    <Image alt="Violet Evergarden: Eternity and the Auto Memory Doll screenshot" caption="10" name="violet-evergarden-eternity-and-the-auto-memory-doll-7.png" mdxType="Image" />
    <Ads mdxType="Ads" />
    <Image alt="Violet Evergarden: Eternity and the Auto Memory Doll screenshot" caption="11" name="violet-evergarden-eternity-and-the-auto-memory-doll-13.png" mdxType="Image" />
    <Image alt="Violet Evergarden: Eternity and the Auto Memory Doll screenshot" caption="12" name="violet-evergarden-eternity-and-the-auto-memory-doll-19.png" mdxType="Image" />
    <Image alt="Violet Evergarden: Eternity and the Auto Memory Doll screenshot" caption="13" name="violet-evergarden-eternity-and-the-auto-memory-doll-22.png" mdxType="Image" />
    <Image alt="Violet Evergarden: Eternity and the Auto Memory Doll screenshot" caption="14" name="violet-evergarden-eternity-and-the-auto-memory-doll-6.png" mdxType="Image" />
    <Ads mdxType="Ads" />
    <Image alt="Violet Evergarden: Eternity and the Auto Memory Doll screenshot" caption="15" name="violet-evergarden-eternity-and-the-auto-memory-doll-17.png" mdxType="Image" />
    <Image alt="Violet Evergarden: Eternity and the Auto Memory Doll screenshot" caption="16" name="violet-evergarden-eternity-and-the-auto-memory-doll-3.png" mdxType="Image" />
    <Image alt="Violet Evergarden: Eternity and the Auto Memory Doll screenshot" caption="17" name="violet-evergarden-eternity-and-the-auto-memory-doll-23.png" mdxType="Image" />
    <Image alt="Violet Evergarden: Eternity and the Auto Memory Doll screenshot" caption="18" name="violet-evergarden-eternity-and-the-auto-memory-doll-11.png" mdxType="Image" />
    <Ads mdxType="Ads" />
    <Image alt="Violet Evergarden: Eternity and the Auto Memory Doll screenshot" caption="19" name="violet-evergarden-eternity-and-the-auto-memory-doll-20.png" mdxType="Image" />
    <Image alt="Violet Evergarden: Eternity and the Auto Memory Doll screenshot" caption="20" name="violet-evergarden-eternity-and-the-auto-memory-doll-9.png" mdxType="Image" />
    <Image alt="Violet Evergarden: Eternity and the Auto Memory Doll screenshot" caption="21" name="violet-evergarden-eternity-and-the-auto-memory-doll-26.png" mdxType="Image" />
    <Image alt="Violet Evergarden: Eternity and the Auto Memory Doll screenshot" caption="22" name="violet-evergarden-eternity-and-the-auto-memory-doll-8.png" mdxType="Image" />
    <Ads mdxType="Ads" />
    <Image alt="Violet Evergarden: Eternity and the Auto Memory Doll screenshot" caption="23" name="violet-evergarden-eternity-and-the-auto-memory-doll-16.png" mdxType="Image" />
    <Image alt="Violet Evergarden: Eternity and the Auto Memory Doll screenshot" caption="24" name="violet-evergarden-eternity-and-the-auto-memory-doll-21.png" mdxType="Image" />
    <Image alt="Violet Evergarden: Eternity and the Auto Memory Doll screenshot" caption="25" name="violet-evergarden-eternity-and-the-auto-memory-doll-18.png" mdxType="Image" />
    <Image alt="Violet Evergarden: Eternity and the Auto Memory Doll screenshot" caption="26" name="violet-evergarden-eternity-and-the-auto-memory-doll-10.png" mdxType="Image" />
    <Image alt="Violet Evergarden: Eternity and the Auto Memory Doll screenshot" caption="27" name="violet-evergarden-eternity-and-the-auto-memory-doll-4.png" mdxType="Image" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      