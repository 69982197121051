import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import $ from '../../../styles/globals';
import { importOneImage } from '../../../utils';

const Figure = styled.figure`
  margin-top: ${$.layout.margin3}px;
  margin-bottom: ${$.layout.margin7}px;

  ${$.screen.desktop} {
    position: relative;
    width: 130%;
    left: -15%;
  }

  ${$.screen.tablet} {
    position: relative;
    width: 90vw;
    min-width: 100%;
    left: calc((90vw - 700px) / 2 * -1);
  }

  ${$.screen.mobile} {
    width: 100%;
  }
`;

const PostImage = styled.div`
  margin-bottom: ${$.layout.margin2}px;
  width: 100%;

  img {
    object-fit: cover;
    display: block;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: ${$.borderRadius.radius1}px;
  }
`;

const Caption = styled.figcaption`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: ${$.brown3};
  font-family: Rubik Regular;
  font-weight: bold;
  font-size: 16px;
  line-height: 1.4em;
`;

const Image = ({ caption, alt, name }) => {
  const [blogpostURL, setBlogpostURL] = useState('');

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const path = window.location.pathname
        .split('/')
        .filter((section) => section);

      setBlogpostURL(path[1]);
    }
  }, []);

  return (
    <Figure>
      <PostImage>
        {blogpostURL && (
          <img
            src={importOneImage(`images/mdxblog/${blogpostURL}/${name}`)}
            alt={alt}
          />
        )}
      </PostImage>
      <Caption>{caption}</Caption>
    </Figure>
  );
};

Image.defaultProps = {
  alt: '',
  caption: '',
};

Image.propTypes = {
  alt: PropTypes.string,
  name: PropTypes.string.isRequired,
  caption: PropTypes.string,
};

export default Image;
