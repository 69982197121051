import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Most people on my facebook Nintendo Switch group got a regular Switch or a Switch lite to play Pokemon Sword/Shield. But I bought mine to play Fire Emblem Three Houses. I can tell you it was love at first sight for me when I laid eyes on Dimitri. It was then `}<em parentName="p">{`I knew`}</em>{`, I didn’t have a Switch but now I needed one.`}</p>
    <p><em parentName="p">{`Disclaimer note: Let me just say I’m a ‘casual’ gamer, I’m not a strategist or a completionist by ANY means. In fact I’m pretty dumb & slow lmao. Therefore, my thoughts on this game, are of a person who plays purely for fun.`}</em></p>
    <Ads mdxType="Ads" />
    <h2>{`What was I expecting?`}</h2>
    <p>{`To be honest, I was pretty hyped up after watching so many trailers popping up on my Facebook feed and on my YouTube. It seemed like such a serious game, if you die in this game, `}<em parentName="p">{`you die.`}</em>{` But thank god for the casual mode for newbies like me!`}</p>
    <Image alt="All Fire Emblem houses you can choose!" caption="You'll want to lay down your life for all of them after finishing the game. I KNOW I DO NOW." name="fire_emblem_three_houses_all_houses.jpg" mdxType="Image" />
    <p>{`I’m a coward, so I started out with `}<em parentName="p">{`Casual/Easy`}</em>{` mode. Even then, I was expecting battles to be hard! I’m completely new to the turn-based genre, let alone a grid-based tactical game or games with a ‘`}<em parentName="p">{`class`}</em>{`’ system. I did some research, but I didn’t want to spoil the game too much. However, on this combination it’s very forgiving!!`}</p>
    <p>{`I even told myself, ‘I want to play Dimitri’s route, but I don’t wanna mess it up. So imma play Claude’s route first.’ Ok sorry Claude fangirls I treated him as my ‘test run’ hehe. But I promise, I PROMISE I treated the Golden Deer kids well. Actually, it was kind of hard to start a new route cuz I was really attached to them. Story wise, I knew I was in for a ride. Phew, it does not fail to GRAB YOUR HEART STRINGS AND YANK AT IT. The voice acting in this game takes the damn cake for ALL THE EMOTION IT CAUSED ME. UGH MY HEARTTT.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Is it fun? Replayable? Boring? `}</h2>
    <p>{`I WAS SO STRESSED STARTING THIS GAME OUT. I HAD TO PLAY IT PERFECTLY. MY KIDS MUST BE WELL TRAINED, I MUST USE THE CORRECT STRATEGY. Etc.I was concerned with how other people were playing the game, I forgot how to have fun. SO DON’T BE LIKE ME.`}</p>
    <Image alt="There are 25 save files for Fire Emblem Three Houses after the update." caption="Yeaaaaaaah, thank god for 25 save files update! Now I can have 23 slots for the Blue Lions route ^_^" name="fire_emblem_three_houses_save_files.jpg" mdxType="Image" />
    <p>{`Once I learned to let go (after 10 hours in), take in the graphics, process the information given, enjoy the eargasmic voice acting, instead of rushing through the story because I was TOO excited, only then did I start enjoying myself.`}</p>
    <p>{`This game is crazy replayable! When you finished your first route, imma bet your first thoughts are ‘I need more. Pls help.’ And you’re in luck!! Apart from the 3+1 routes available, each individual route is also replayable AND THANKFULLY THEY EXPANDED THE 5 SAVE SLOTS TO 25 HAH! Remember I said I was stressing out about how everyone was playing? Yeah no, screw that. Play how you want! You wanna make everyone flyers?? Half of them archers?? DO IT.`}</p>
    <p>{`As far as my memory serves, in the beginning before you decide your house, the dialogue is almost similar but with minor differences. All the students from the 3 houses have very different personalities, and you just love them (except 1 or 2, cough`}<em parentName="p">{`leonie`}</em>{`cough). So if you wanna try one style of playing on your first playthrough, or another strategy on your 5th, please do it. It can get a `}<em parentName="p">{`tiny bit`}</em>{` repetitive if you’ve been continuously playing it, I’d recommend taking a break after 2 playthroughs or so and then come back.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`What else?`}</h2>
    <p>{`Like many fans, I flocked to Youtube to get more of Fire Emblem Three Houses, it did not disappoint. The videos of the cast are SO LOVEABLE, they get together and it fanservice. Butthey’re very relateable and genuine, plus you can tell they have a bond with their characters. Especially Joe Zieja (at this point, there’s no difference between him and Claude) and Chris Hackney (he’s so protective of Dimitri I can’t-).`}</p>
    <Image alt="Fire Emblem Three Houses Limited Edition with Nintendo Switch Tsum Tsum edition" caption="I gave up my 2 weeks lunch money for this baby. Switch for size comparison." name="fire_emblem_three_houses_EU_limited_edition.jpg" mdxType="Image" />
    <p>{`Oh right, the DLC. Should you get it? As far as I know, it’s mostly cosmetic stuff right now as the 4th Wave hasn’t been released yet (as of January 2020).3 months after the game released, I realized I needed to have the Collectors Edition and kind of panicked because of course it was sold out. I `}<em parentName="p">{`nearly`}</em>{` resorted to ebay until I found a local seller. So I can’t decide for you whether the DLC is worth 25USD or not, but I love this game too much to not get it.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Conclusion:`}</h2>
    <p>{`When I told my Nintendo Switch buddies I’ve never played a Fire Emblem game before, do you know how hard it was to keep rejecting so many offers to play `}<em parentName="p">{`Awakening`}</em>{`?? I am `}<em parentName="p">{`SO`}</em>{` glad I saved my Fire Emblem virginity for this one. I can see why it’s so hyped, and why so many fan art has been created. I’m this close to resurrecting a dead Twitter account just to follow these artists.`}</p>
    <Image alt="Sothis is a precious goddess." caption="Sothis sleeps on the throne once you completed the first run of the game." name="fire_emblem_three_houses_blog.jpg" mdxType="Image" />
    <p>{`There are things that can be improved of course, some storyline pacing, the text boxes could be bigger, etc. None of these take away the experience, unless you’re playing on the Switch lite, then the text boxes might be a problem for you. But otherwise, for me these aren’t game breaking problems.`}</p>
    <p>{`Actually, the only real problem for me is moving on from the Blue Lions house to finish the Black Eagles route heh. One last advice is, don’t go on the game’s sub-reddit or tumblr if you haven’t finished the game. So much spoilers! So although I’m 2 routes and 120+ hours in, if you think I’m done, hahaaa. Nope. Not done by a long shot.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      