import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Blockquote = makeShortcode("Blockquote");
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const RemoteLink = makeShortcode("RemoteLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I know I'm late to the party but I've been wanting to talk about these 2 romance shoujo manga that everyone on Twitter has been raving about last month. It's hard not to notice when fans has been tweeting specific panels that's amazingly good at grabbing your attention. Both titles are technically(?) ongoing but it's the type of plot that won't stray too far from the main theme. I'm sure as it chugs along, we'll eventually see a happy ending in these two romance titles. I mean, what's a romance manga if we don't see the OG couple hold hands, mwah mwah and finally get married :D`}</p>
    <Blockquote mdxType="Blockquote">
  Also, I'm starting to see a pattern. Dear shoujo manga authors, y'all just
  need this one recurring theme and you're done. Fans on Twitter will sing high
  praise of your work (And I can see why)
    </Blockquote>
    <Ads mdxType="Ads" />
    <h2>{`Romance Shoujo Manga #1: Hotaru no Yomeiri/Firefly Marriage (ホタルの嫁入り)`}</h2>
    <p>{`This story is set in Meiji era, one of my favourite era for a romance manga to take place in. Our FL is Satoko, a talented and beautiful young lady from a noble family. It seems that the heavens had everything laid out for her but like they say, you can't have it too good. You see, Satoko has a short-life expectancy. She doesn't have long to live, which is why her father is hell-bent on making sure her future husband is a good man.`}</p>
    <Image alt="Hotaru no Yomeiri cover art" caption="Couldn't find any good coloured art except for this particular page. It's a great cover art for Chapter 1. Really encompasses the whole plot." name="hotaru-no-yomeiri-cover-art.jpeg" mdxType="Image" />
    <p>{`But that would make a boring romance story. So the author makes sure she gets kidnapped within the first few pages of Chapter 1 and meet Goto, her future fiance in the next panel. Honestly, that was pretty sick if you ask me.`}</p>
    <Image alt="Hotaru no Yomeiri panel" caption="Satoko meeting her saviour and he's... a professional killer? But wait he's kinda hot." name="hotaru-no-yomeiri-manga-panel-1.png" mdxType="Image" />
    <p>{`Satoko Kirigaya is a wonderful FL so far. She is strong-willed, and has every reason to be. Our girl recognizes the threat she is in and it's not the kidnappers. To save herself, she quickly offer herself up to the killer and he took it. And that's how the ship sail!`}</p>
    <p>{`I personally think their first meeting is super intense. He could have easily sliced her up but no, it was a fancy whim of his that stayed his blade. The story did their first meeting really well, and I believe that's what captured the readers' attention. The story moves on to take place in a red-light district island and honestly, that's a smart choice to further the plot.`}</p>
    <p>{`There's this sense of urgency in the story. Satoko's trapped in this island with her only ally(?) being Goto, the guy she should be terrified of. After all, he could turn on her anytime. No way out, no one to turn to. How will she flee the island and back to her family?`}</p>
    <p>{`The story is still ongoing and I'm not sure how it will end. From the first few pages, I think it will be a bittersweet ending.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Romance Shoujo Manga #2: Uchi no Kuro Madoushi ga Kawaisugiru!/My Black Mage Is Too Cute! (うちの黒魔導士がかわいすぎる!)`}</h2>
    <p>{`This title only has 2 chapters officially, but it got so popular that they announce that it will be an ongoing series. Wonderful job fans! I could see why it gets more chapters but the ML is not someone I will get attracted to in real life because I definitely do not have mind-reading superpowers. Here's the `}<RemoteLink to="https://twitter.com/AkinoUdonya0308" mdxType="RemoteLink">{`author's Twitter`}</RemoteLink>{`. The image below is from the `}<RemoteLink to="https://twitter.com/AkinoUdonya0308/status/1687272133737771008/photo/1" mdxType="RemoteLink">{`author's tweet`}</RemoteLink>{`.`}</p>
    <Image alt="twitter art from the author of Uchi no Kuro Madoushi ga Kawaisugiru!" caption="I find this really cute. Author has a thin lineart style with really nice approach to lighting." name="uchi-no-kuro-madoushi-ga-kawaisugiru-twitter-art.jpeg" mdxType="Image" />
    <p>{`The story is set in a fantasy, magical world where students study how to do magic spells, learn up every fantasy monsters out there and how to defeat them... Basically everything you would want to try in a Harry Potter-esque world. Our FL is Aria, a third year high school prefect who does a wonderful job of mentoring first year students. Except there's this one troublesome first year boy that Aria couldn't seem to guide well...`}</p>
    <p>{`His name is Jade, and he's known for his rude tongue and an equally terrifying family background. You see, he's from a family of black mages and the public sees them as a symbol of bad luck. But Aria is no quitter. Every student deserves a chance to learn and Jade is no different. When Aria grabbed onto him, she realize that Jade thinks and says two different things. Wait, Aria can read minds if she touches the person! Wonderful, I can see a recurring plot already.`}</p>
    <p>{`Aria notices that Jade love to say something mean but he never fails to conjure up sickeningly sweet thoughts of her on the inside. Urgh, this is perfect MOE MATERIAL. The funny part is he wasn't even thinking of PG13. It was bordering T for Teens!! I will need to update this blog's rating if he is thinking 18SX, thank god.`}</p>
    <Image alt="" name="uchi-no-kuro-madoushi-ga-kawaisugiru-manga-panel-1.png" caption="NOT EVEN HANDHOLDING?? Y'all, this boy is BOLD." mdxType="Image" />
    <p>{`This is where Aria thinks, "Oh he is just a nice kid on the inside." and will treat him gently. And the whole MOE continues. Hnnng.`}</p>
    <p>{`Will Aria's "gentle" persuasion finally melts Jade's heart on the outside? I look forward to seeing new chapters from this title.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`All in all`}</h2>
    <p>{`So there ya have it, these two romance shoujo titles that Twitter fans been talking about. Do you see a pattern to fans' favourite cliche? I certainly do! Y'all love bad boys and I totally see why. Specifically, guys who are meanies to everyone and only turn soft (or eventually turn soft) to the girl they love. At first, I thought the younger readers have a sick taste. Who would give up kind 2D boys as their boyfriend and go for the mean ones? That's not sustainable in the long run. I certainly wouldn't want to stay in a relationship where the boy only knows how to be mean to you and call it a "relationship".`}</p>
    <p>{`Then I notice my favourite 2D boy when I was young is Natsume Hyuuga from Alice Academy. Then it clicked. Ohhhh so that's why these two titles are hot stuff. I'm ashamed I didn't notice it sooner.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      