import { useRef, useState, useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import $ from '../../../styles/globals';
import Facebook from '../../../assets/icons/facebook.svg';
import Twitter from '../../../assets/icons/twitter.svg';
import ShareLink from '../../../assets/icons/link.svg';
import atFanfictionChapter from '../../Navbar/atFanficChapter';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${$.layout.padding3}px ${$.layout.padding1}px ${$.layout.padding1}px
    ${$.layout.padding2}px;
  border-radius: ${$.borderRadius.radius1}px;
  border: 1px solid ${$.grey1};
  position: relative;

  > * {
    margin-right: ${$.layout.margin3}px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const Title = styled.div`
  font-size: 15px;
  text-transform: uppercase;
  font-family: Rubik Regular;
  letter-spacing: 1px;
  background-color: ${$.white1};
  padding: ${$.layout.padding1}px;
  position: absolute;
  top: -13px;
  left: 9px;
`;

const FacebookIcon = styled(Facebook)`
  width: 24px;
  height: 24px;

  > path {
    transition: fill 0.2s ease;

    &:first-child {
      fill: ${$.color.lightblack};
    }
    &:last-child {
      fill: ${$.white1};
    }
  }

  ${$.screen.desktop} {
    &:hover {
      > path {
        &:first-child {
          fill: ${$.blue2};
        }
      }
    }
  }
`;

const TwitterIcon = styled(Twitter)`
  width: 24px;
  height: 24px;

  > path {
    transition: fill 0.2s ease;
    fill: ${$.color.lightblack};
  }

  ${$.screen.desktop} {
    &:hover {
      > path {
        fill: ${$.blue1};
      }
    }
  }
`;

const CopyLinkContainer = styled.div``;

const CopyLinkIcon = styled(ShareLink)`
  width: 30px;
  height: 30px;
  position: relative;
  left: -2px;
  > path {
    transition: fill 0.2s ease;
    fill: ${$.color.lightblack};
  }

  &.active {
    &:hover {
      > path {
        fill: ${$.color.green1};
      }
    }
  }

  ${$.screen.desktop} {
    &:hover {
      cursor: pointer;
      > path {
        fill: ${$.brown3};
      }
    }
  }
`;

const CopyInput = styled.input.attrs({ type: 'text' })`
  width: 0;
  height: 0;
  position: absolute;
  opacity: 0;
  pointer-events: none;
`;

const NightMode = createGlobalStyle`
  body.night-mode {
    ${Title} {
      background-color: ${$.night};
      color: ${$.white1};
    }
    ${FacebookIcon} {
      > path {
        &:first-child {
          fill: ${$.brown1};
        }
        &:last-child {
          fill: ${$.brown2};
        }
      }
      ${$.screen.desktop} {
        &:hover {
          > path {
            &:first-child {
              fill: ${$.blue2}; 
            }
            &:last-child {
              fill: ${$.white1};
            }
          }
        }
      }
    }
    ${TwitterIcon} {
      > path {
        fill: ${$.brown1};
      }

      ${$.screen.desktop} {
        &:hover {
          > path {
            fill: ${$.blue1};
          }
        }
      }
    }
    ${CopyLinkIcon} {
      > path {
        fill: ${$.brown1}
      }
      ${$.screen.desktop} {
        &:hover {
          cursor: pointer;
          > path {
            fill: ${$.brown2};
          }
        }
      }      
    }
  }
`;

const ShareBox = () => {
  const inputRef = useRef(null);
  const [clickedCopy, setClickedCopy] = useState(false);
  const [currLink, setCurrLink] = useState('');

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setCurrLink(window.location.href);
    }
  }, []);

  return (
    <Container title="Share this blog post to social media platforms!">
      {atFanfictionChapter() && <NightMode />}
      <Title>Share</Title>
      <a
        href={`https://www.facebook.com/sharer.php?u=${currLink}`}
        target="_blank"
        rel="noopener noreferrer nofollow"
        title="Share to Facebook"
      >
        <FacebookIcon />
      </a>
      <a
        href={`https://twitter.com/intent/tweet?url=${currLink}`}
        target="_blank"
        rel="noopener noreferrer nofollow"
        title="Share to Twitter"
      >
        <TwitterIcon />
      </a>
      <CopyToClipboard
        text={currLink}
        onCopy={() => {
          setClickedCopy(true);
          setTimeout(() => {
            setClickedCopy(false);
          }, 1000);
        }}
      >
        <CopyLinkContainer title="Copy link">
          <CopyLinkIcon className={clickedCopy === true ? 'active' : ''} />
          <CopyInput ref={inputRef} contenteditable />
        </CopyLinkContainer>
      </CopyToClipboard>
    </Container>
  );
};
export default ShareBox;
