import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`You see, when folks throw around the word `}<em parentName="p">{`mecha anime`}</em>{`, I expect angsty teenagers sitting in cockpits of fully weaponized robots, capable of murder and destruction of all kinds. These teenagers would constantly question their role while the adults do not. It’s basically the kids going `}<strong parentName="p">{`“I FIGHT FOR MY OWN BELIEFS/FRIENDS!”`}</strong>{` while the adults go `}<strong parentName="p">{`“Just listen to my orders, damnit!”`}</strong>{`. I mean, this is the standard mecha anime everyone expects, right? Throw in some mystery and some big world government plot and it’s `}<em parentName="p">{`perfect.`}</em>{` Just like that, this hotpot has concocted a perfect mecha anime plot! It works, it’s entertaining and folks love it. But wait, just what is this Back Arrow? Where are my angsty teenagers? This can’t be right!`}</p>
    <p><em parentName="p">{`Note: I have watched up to Episode 4 for this first impression post!`}</em></p>
    <Ads mdxType="Ads" />
    <p>{`Okay, okay… Let’s start from the beginning. So I’ve been seeing my good aniblogger folks talking about Back Arrow occasionally. Adjectives like `}<em parentName="p">{`fun `}</em>{`and `}<em parentName="p">{`unexpected`}</em>{` were thrown in whenever I read their tweets. Obviously this got me interested because I love mecha anime. I love seeing mah robots cutting down fellow pilots in the name of survival, mission, bla bla bla. So I dived into Episode 1 and.. Huh? They started off by narrating about a wall, and the first thing I see is a Three Kingdom-looking general and tactician in a hi-tech setting. Huh?`}</p>
    <Image alt="Wakaranai yo... What is happening?" caption="I’m not as cute as the anime girl here, but her expression is basically me for the entirety of Episode 1." name="nabechan-wakaranai-yo.jpg" mdxType="Image" />
    <p>{`Then these folks go dumping an arm band forcefully onto their arms and a mecha suit popped up with them inside. Huhhhh.... Yup, I can tell you got confused, dear reader. Let me give you a quick summary.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Back Arrow takes place in the world of Lingalind, where the inhabitants only know of a world confined within the walls. To them, what’s within this wall is all there is. There is nothing on the other side of the wall. They worship this wall, and call it an almighty, all-protecting `}<em parentName="p">{`World Wall`}</em>{` that nurtures Lingalind. Every now and then, capsules will fall from the sky and within these capsules are “gifts” from the wall. Thus these capsules are called “Rakuho” which means `}<em parentName="p">{`gift from the heavens`}</em>{`. Inside these capsules are usually `}<em parentName="p">{`Bind Warpers`}</em>{`, a type of armbands that allows you to transform into a `}<em parentName="p">{`Briheight,`}</em>{` a mecha suit that represents your `}<em parentName="p">{`conviction`}</em>{`. In a way, depending on your conviction, your Briheight powers are different too. Of course, these gifts are perfect for warring and it is what these folks at Lingalind use it for. There are two prominent nations at war with each other - Republic of Rekka and Supremacy of Lutoh. When a Rakuho falls, they would quickly sortie to capture the fallen Rakuho.`}</p>
    <Image alt="Back Arrow Key Visual" caption="From the front, Back Arrow, Elsha, Atlee and Bit." name="back-arrow-key-visual.jpg" mdxType="Image" />
    <p>{`One day, a Rakuho fell onto a small village in a remote region of Iki Territory called Edger Village. Inside the Rakuho, they found a boy instead of Bind Warpers. The boy lost his memories but could only remember one thing with “conviction” - He hails from beyond the wall. And he needs to get back!`}</p>
    <Ads mdxType="Ads" />
    <p>{`And that’s the gist of the story. I’ll be fair when I say this… I’m disappointed at the lack of technology showcase I’m used to in mecha shows. Where’s the cockpit? Where’s the ship, cannons, sabers or rockets? ): But after completing Episode 1, I got hooked. Seriously, the mecha tag feels like an afterthought to the writers! Back Arrow feels more like a fantasy show and the mecha is there just for fun. Do you wanna see cute robots decking it out with some silly super powers? Eh, why not!`}</p>
    <p>{`The cast’s clothing design is a bit on the silly side, where I couldn’t really see a thematic design. The design kinda feels like they just slap on anything that looks cool and what the viewers would expect in a wild wild west-inspired village/ancient China dynasty. But these designs actually lend to the incredulity of the plot. Ok, maybe it’s not as incredulous as I make it out to be. The mood, vibe and plot is just a nice balance between humour and seriousness. Back Arrow, our main character, isn’t presented as a super pushy guy with a one-track mind. He’s just really simple-minded. He kinda reminds me of Luffy from One Piece. Atlee, the supposed Sheriff of the village isn’t a one-trick pony either. Elsha and Bit too, are really likeable too. Their personality isn’t on the nose and their actions feel really natural in the story.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Atlee's Briheight" caption="This is Atlee's Briheight. I really like the design because it looks really sweet <3" name="back-arrow-atlee-briheight.jpg" mdxType="Image" />
    <p>{`The plot isn’t mind-blowing but I find it simple enough to understand. Guy came down in a Rakuho, lost his memories, wishes to go beyond the wall and the village where he landed got involved. It’s really simple and the humour feels just nice. Along with the cast, the colour palette of this anime and the plot simplicity, this anime doesn’t feel like they are trying too hard nor is it going for complexity to stand out. I think of it as one of those anime that just want to have fun doing their own thing with a simple story to tell. It might get complex later on but Back Arrow is still a fun ride regardless.`}</p>
    <p>{`A recommended watch? Yes. Try the first episode and see if you like the vibe of it! If yes, then you’re in for a good ride.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      