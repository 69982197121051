import PropTypes from 'prop-types';

import Seo from '../components/Seo';
import keywords from '../utils/keywords';
import Banner from '../components/Series/Banner';
import Content from '../components/Series/Content';

const SeriesTemplate = ({ pageContext, location }) => {
  const { metad, name, description, links } = pageContext;

  return (
    <>
      <Seo
        title={`${name} | Geeknabe - ACG Blog`}
        description={metad}
        location={location}
        keywords={keywords}
      />
      <Banner title={name} description={description} />
      <Content items={links} />
    </>
  );
};

SeriesTemplate.propTypes = {
  pageContext: PropTypes.shape({
    metad: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    links: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  location: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
};

export default SeriesTemplate;
