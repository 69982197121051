import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const RemoteLink = makeShortcode("RemoteLink");
const Blockquote = makeShortcode("Blockquote");
const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I remember my first `}<em parentName="p">{`fascination`}</em>{` with `}<strong parentName="p">{`space`}</strong>{`. When I was still studying in high school, my Science teacher would briefly talk about our solar system. It wasn’t anything `}<em parentName="p">{`special`}</em>{`, really. My Science teacher taught the syllabus as part of her job, and I memorized each planets’ order as required of me as a student. This is part of the test, memorize each planet’s position starting from the one closest to the sun, she said. And so I did. Once the exam is done, I quickly move on to hobbies and memories that are more `}<strong parentName="p">{`worthy`}</strong>{` of my `}<em parentName="p">{`teenage time`}</em>{` than some planets up in the sky. Come to think of it, it wasn’t that I `}<strong parentName="p">{`couldn’t`}</strong>{` care less about `}<em parentName="p">{`extraterrestrial topics`}</em>{`. I simply couldn’t `}<strong parentName="p">{`fathom`}</strong>{` the fact that the `}<em parentName="p">{`moon is much closer to us than the sun`}</em>{`! That we are but `}<strong parentName="p">{`a speck of dust`}</strong>{` in this big universe.`}</p>
    <p>{`I couldn’t back then `}<em parentName="p">{`but now`}</em>{`, the unknown space fascinates me the same way the `}<em parentName="p">{`speed of our technology advancement`}</em>{` does. Do you know that we have come `}<strong parentName="p">{`so far`}</strong>{` in `}<em parentName="p">{`robotics`}</em>{`? We could make burger patties that `}<em parentName="p">{`taste like meat with plants`}</em>{`! Your very own personal computer is much, much faster than the same computer that powered the rocket that `}<em parentName="p">{`flew to the moon`}</em>{`!`}</p>
    <Ads mdxType="Ads" />
    <Image alt="NASA nebula" caption="I remember browsing through NASA's website just to appreciate these photos." name="nasa-nebula-cloud-photo.jpg" mdxType="Image" />
    <RemoteLink to="https://nssdc.gsfc.nasa.gov/photo_gallery/caption/hst_pillars_m16.txt" mdxType="RemoteLink">
  Here's the link to the above photo's caption.
    </RemoteLink>
    <p>{`Every sleepless night, I’ll browse YouTube to watch these videos. My favourite are videos from `}<strong parentName="p"><RemoteLink to="https://kurzgesagt.org/" mdxType="RemoteLink">{`Kurzgesagt`}</RemoteLink></strong>{`. I used to follow `}<RemoteLink to="https://www.youtube.com/user/Vsauce" mdxType="RemoteLink">{`Vsauce`}</RemoteLink>{` too! Both are excellent channels that tackle topics for the `}<strong parentName="p">{`curious mind`}</strong>{`. I didn’t want to break the post’s flow so I’ll add some YouTube video links at the end of this write-up. Please do consider following them! What is out there up in the sky? `}<em parentName="p">{`Is there an end to the vast universe?`}</em>{` Perhaps the most important question no one could answer is this…`}</p>
    <Blockquote mdxType="Blockquote">Are we truly alone in the universe?</Blockquote>
    <p>{`There’s too many `}<strong parentName="p">{`unknowns`}</strong>{` out there in space. We have plenty of hypotheses but no way to prove our theories. Humanity has since been `}<em parentName="p">{`enthralled`}</em>{` by the idea of unseen beings up in the sky and perhaps, beyond what we can see with our eyes. This `}<strong parentName="p">{`odd feeling`}</strong>{` of wanting to `}<em parentName="p">{`know more`}</em>{` about space is what powers this 39-chapter manga, ST&RS. It’s my favourite when it first serialized on `}<strong parentName="p">{`Weekly Shonen Jump`}</strong>{` back in `}<em parentName="p">{`2011`}</em>{`! It’s a pity no one remembers this manga, and there’s barely any fanart of it. It’s such an underrated manga that I hope with this write-up, more folks will come to know of it. This manga deserves more folks reading it!`}</p>
    <Ads mdxType="Ads" />
    <p>{`It all started when humanity received the first contact from an extraterrestrial being on August 10, 2019. The message goes as such - `}<em parentName="p">{`“Let us meet on Mars on July 7, 2035”`}</em>{`. The message sent everyone on Earth to a flurry of excitement. `}<strong parentName="p">{`We're not alone!`}</strong>{` It was then the space agency, `}<strong parentName="p">{`ST&RS`}</strong>{` was formed to `}<em parentName="p">{`represent Earth`}</em>{` as well as to prepare everything needed for the `}<strong parentName="p">{`promised meeting`}</strong>{`. At the same time on August 10, 2019, a baby in Japan uttered his first words - `}<em parentName="p">{`“Mars”.`}</em>{` This baby’s name is Maho, and he would attend the Space Academy established by ST&RS with his two friends in the coming future... all for the sake of going to Mars for the promised meeting!`}</p>
    <p>{`There’s a lot to like about ST&RS. The first chapter introduces Maho, our protagonist of ST&RS. He’s curious about space, and had an `}<strong parentName="p">{`uncanny ability`}</strong>{` to map things out in 3D in his head. He’s a `}<strong parentName="p">{`space geek`}</strong>{` in his own right, but he never tries to one up his friends. His childhood friend, Meguru `}<em parentName="p">{`cares a lot`}</em>{` about her space fanboy friend. She has her heart in the right place, and she’s every bit as proper as you expect a `}<em parentName="p">{`socially normal`}</em>{` person to be. Amachi is the smart one in the group. He’s polite and good in his studies. His dream is to be a respectable doctor like his father. All three of them are in the same class, so you could argue that these three just hitching off right from the start is `}<strong parentName="p">{`expected`}</strong>{`. They quickly became good friends with neither characters overpowering the other in terms of spotlight and plot. You could say that they fit each other like `}<em parentName="p">{`perfect puzzle pieces`}</em>{` in ST&RS.`}</p>
    <Image alt="ST&RS characters" caption="From the left, Meguru, Amachi and Maho." name="st&rs-main-cast.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <p>{`Throughout the story before and after they get to attend the Space Academy, they were given tasks that I honestly think befits the idea of what an astronaut`}<strong parentName="p">{` should know and do`}</strong>{`. From `}<em parentName="p">{`keen observation`}</em>{` to `}<strong parentName="p">{`resourcefulness`}</strong>{`, each task brings out an `}<em parentName="p">{`equal challenge`}</em>{` and `}<strong parentName="p">{`opportunity`}</strong>{` for the main cast to shine. I’m really glad that for once, there’s a manga out there that `}<em parentName="p">{`accentuated teamwork`}</em>{` that fits the motif of the story perfectly.`}</p>
    <p>{`Everyone in ST&RS is burning with `}<strong parentName="p">{`curiosity for the unknown`}</strong>{` and this is where I think the writer did real good. Every decision made is for the sake of the meeting. Even the students are in not because of the school’s prestige! They are here because they want to go to space and be the first to meet aliens, and this is the `}<strong parentName="p">{`main theme for ST&RS`}</strong>{`. The plot and the mood in the manga is driven by this `}<em parentName="p">{`honest and frank desire to see the unknown`}</em>{`. This is what appeals to me the most about this manga! The overall vibe is really `}<em parentName="p">{`wholesome`}</em>{` in a way that pulls me in. It wasn’t long before I’m on the ship as well, just gearing up with the team to go see some aliens on Mars!`}</p>
    <Image alt="Maho assembling the Apollo spaceship by mapping it in his head." caption="Maho assembling the Apollo spaceship by mapping it in his head." name="maho-assembling-apollo.jpg" mdxType="Image" />
    <p>{`Unfortunately, what I presume to be the manga’s `}<strong parentName="p">{`strength`}</strong>{` is also where it `}<em parentName="p">{`fails to appeal`}</em>{` to the readers of `}<strong parentName="p">{`Weekly Shonen Jump`}</strong>{`. The reason ST&RS only had 39 chapters was because it was axed. The journey to the (supposed) ending was sped up which in my opinion, `}<em parentName="p">{`ruined the thrill`}</em>{` that the journey to the goal should give us readers. The very same wholesome mood that pulled me in couldn’t attract the WSJ readers. Now bear in mind this is just my theory, but I `}<strong parentName="p">{`believe`}</strong>{` the whole reason ST&RS failed to capture the attention of folks is because it lacked `}<strong parentName="p">{`challenge.`}</strong>{` The whole story went on too `}<em parentName="p">{`smoothly`}</em>{`! The cast did encounter some troubles, yes. But it was quickly resolved as easily as `}<strong parentName="p">{`ABC`}</strong>{`. Tension wasn’t delivered as such, and the students’ troubles aren't as troubling as you expect it to be. Maho too, lacks a `}<em parentName="p">{`strong presence `}</em>{`as a shonen protagonist. I previously mentioned how Maho and his two friends fit each other like puzzle pieces and this is exactly what I meant! Maho’s presence in the story didn’t feel like a protagonist, he felt like just one of the team members that happen to be the main cast.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Seriously, there’s nothing about Maho that stood out to me as a protagonist unless you count the obligatory childhood friend. Moreover, all three of them just befriended each other with `}<em parentName="p">{`no troubles`}</em>{`. There’s a reason why most stories enjoy doing the cliche `}<em parentName="p">{`“we went through the trouble, now we are better friends than ever”`}</em>{`. This gives the reader that these friends have a `}<em parentName="p">{`stronger bond than just normal friends`}</em>{`. It’s one of my favourite storytelling techniques since it gives the main cast a stronger, united stand in the story. But unfortunately, Maho, Meguru and Amachi are just really nice folks who are in the same team. You really couldn’t justify their bonds other than the fact that they are together because the writer wants them to.`}</p>
    <p>{`ST&RS’s theme is about uniting on the same front, propelled by the same curiosity which I honestly think is what's stopping this manga from going all the way up in WSJ’s ranking. That’s not saying that the manga is `}<em parentName="p">{`done terribly`}</em>{`! I really enjoyed it but the theme doesn’t fit the `}<strong parentName="p">{`expected excitement a shounen should have`}</strong>{`. The `}<em parentName="p">{`wholesomeness`}</em>{`, and everyone doing it together is what’s `}<strong parentName="p">{`great`}</strong>{` about this manga. The writer have successfully channelled that `}<strong parentName="p">{`inquisitive feeling for space`}</strong>{` through the characters and established plot. It’s good enough I could feel it in my bones. As I flit across these panels, I could feel the very same `}<strong parentName="p">{`exuberance`}</strong>{` these topics of celestial bodies give me!`}</p>
    <Ads mdxType="Ads" />
    <p>{`ST&RS is `}<strong parentName="p">{`different`}</strong>{`. It is a unique experience that tries to `}<em parentName="p">{`split away`}</em>{` from the usual shounen formula. I think it would have done better if not serialized in WSJ. Maybe the writer could get good enough to give ST&RS a better pacing and scenario it deserved. The ending got too `}<em parentName="p">{`fantasy-like`}</em>{` for my taste (I blame it on the rushed plot) but all in all, it’s a great read. My only regret is that I won’t be able to read the writer’s intended plot for Maho and the cast’s journey to Mars. Here are the list of videos I wish to share with everyone. Enjoy!`}</p>
    <Video title="" src="https://www.youtube.com/embed/rhFK5_Nx9xY" mdxType="Video" />
    <Video title="" src="https://www.youtube.com/embed/nIiLqNQOgPA" mdxType="Video" />
    <Video title="" src="https://www.youtube.com/embed/VRm7oRCTkjE" mdxType="Video" />
    <Video title="" src="https://www.youtube.com/embed/ULGi3UkgW30" mdxType="Video" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      