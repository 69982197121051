import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';

import $ from '../../../styles/globals';
import Stats from './Stats';
import Author from './Author';

const Section = styled.section``;

const Container = styled.div`
  padding: ${$.layout.padding4}px 0 ${$.layout.padding2}px 0;
`;

const Text = styled.h1`
  font-family: Raleway Bold !important;
  line-height: 1.1em;
  color: ${$.brown4};
  margin-bottom: ${$.layout.margin2}px;
  letter-spacing: -1px;

  ${$.screen.desktop} {
    font-size: 46px !important;
  }

  ${$.screen.tablet} {
    font-size: 42px !important;
  }

  ${$.screen.mobile} {
    font-size: 36px !important;
  }
`;

const Excerpt = styled.h2`
  color: ${$.brown4};
  line-height: 1.5em;
  font-family: Rubik Regular !important;
  margin-bottom: ${$.layout.margin5}px;
  font-size: 18px !important;

  ${$.screen.desktop} {
    font-size: 19px !important;
  }
`;

const Tags = styled.div`
  font-family: Rubik Regular;
  font-weight: bold;
  color: ${$.brown4};
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-size: 16px;
  margin-bottom: ${$.layout.margin4}px;

  > a {
    text-decoration: none;
    color: ${$.color.orange};
    &:visited {
      color: ${$.color.orange};
    }
  }

  > span.tag-separator {
    margin: 0 ${$.layout.margin1}px;
  }
`;

const Banner = ({
  title,
  excerpt,
  tags,
  published,
  timeToRead,
  slug,
  author,
  hearts,
  pic,
  pageViews,
  lastUpdated,
}) => {
  const { allSitePage } = useStaticQuery(graphql`
    {
      allSitePage(filter: { path: { regex: "/topic/" } }) {
        edges {
          node {
            path
          }
        }
      }
    }
  `);

  const tagsWithLinks = tags.map((tag) => {
    let newTag = `<span>${tag}</span>`;

    allSitePage.edges.forEach(({ node: { path } }) => {
      if (path.indexOf(tag.toLowerCase()) > -1) {
        newTag = `<a title="Visit posts tagged as topic: ${tag}" href="${path}">${tag}</a>`;
      }
    });

    return newTag;
  });

  return (
    <Section>
      <Container>
        <Tags
          dangerouslySetInnerHTML={{
            __html: tagsWithLinks.join('<span class="tag-separator">·</span>'),
          }}
        />
        <Text>{title}</Text>
        <Excerpt>{excerpt}</Excerpt>
        <Author
          published={published}
          timeToRead={timeToRead}
          name={author}
          pic={pic}
        />
        <Stats
          title={title}
          hearts={hearts}
          slug={slug}
          pageViews={pageViews}
          lastUpdated={lastUpdated}
          published={published}
        />
      </Container>
    </Section>
  );
};

Banner.defaultProps = {
  title: '',
  excerpt: '',
  tags: [],
  published: '',
  slug: '',
  timeToRead: 1,
  author: 'nabe-chan',
  pic: '',
  hearts: '-',
  pageViews: 0,
  lastUpdated: '',
};

Banner.propTypes = {
  pageViews: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  author: PropTypes.string,
  pic: PropTypes.string,
  slug: PropTypes.string,
  lastUpdated: PropTypes.string,
  hearts: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  excerpt: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  published: PropTypes.string,
  timeToRead: PropTypes.number,
};

export default Banner;
