import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Blockquote = makeShortcode("Blockquote");
const Image = makeShortcode("Image");
const Ads = makeShortcode("Ads");
const RemoteLink = makeShortcode("RemoteLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I’ve been wanting to write about this series since Chapter 1! I was stoked when I realized this new series is about space. The 2-page spread to show the planet they landed in Chapter 1 is gorgeous! From the looks of things, I was expecting a story full of adventure, exploration and maybe even bro-ship because all four of the main cast are boys.`}</p>
    <Blockquote mdxType="Blockquote">
  Alas, my expectations were dashed the moment I laid my eyes on this panel.
    </Blockquote>
    <Image alt="Agravity Boys manga panel 1." caption="He led a sad life..." name="agravity-boys-panel-3.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <p>{`My impression of this series did a whole 180 degree switch. Agravity Boys is actually a comedy! The gags are `}<em parentName="p">{`silly`}</em>{` and I have no idea where this series is heading `}<strong parentName="p">{`at all.`}</strong>{` Agravity Boy’s whole premise is to watch these four boys make merry and mischief in their own way. If they only bat a baseball into someone’s home, then that’s fine. I mean, everyone makes mistakes right?`}</p>
    <p>{`However, these four are the `}<em parentName="p">{`cream of the crop`}</em>{`! They are the best amongst the best of what humanity is capable of. So if you add a bunch of `}<strong parentName="p">{`naughty boys`}</strong>{` who are bros with each other along with their high intelligence, what would you get? `}<strong parentName="p">{`A universe-level trouble.`}</strong>{` (This is really what happened in the story, trust me.) These boys are honestly, just super hilarious troublemakers that didn’t really grow up with age.`}</p>
    <p>{`If you ask any troublemakers why they did it, they’ll answer,`}<strong parentName="p">{` “Because I can”`}</strong>{` and that is exactly what’s happening in Agravity Boys. Their mothers would `}<em parentName="p">{`smack their bottoms`}</em>{` for causing so much trouble to their neighbours but fortunate for them, they are the last of their kind. Agravity Boys talks about 4 boys who were sent off to space to colonize an earth-like planet called α-Jumbro. Things were going smoothly on their journey until Day 2, HQ told them a nuclear war broke out and they are the last hope of humanity. On Day 3, they were sucked into a black hole and voila, they reached α-Jumbro when the journey to this planet is supposed to take them 20 years! They were discussing their findings of the new home when a Higher Being appeared before them with a tantalizing offer.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Agravity Boys manga panel 2" caption="Higher Being (HB) offered them an elixir that can turn one of them into a girl. They didn't take the elixir in the end, but they are still thirsty for some girls. Sheesh. So they hope that there's a surviving ship out there full of women... Sigh, such lost causes. Adorable." name="agravity-boys-panel-2.jpg" mdxType="Image" />
    <p>{`I could go on, but let’s just say that the first chapter is basically having the boys being true to themselves. Tachikaze Saga is the leader and his place in the team is as the pilot. He has a good head on his shoulders and would take charge when needed. Chris Erwitt is the doctor. He’s the shortest among his peers, but his heart is the biggest of them all. He also looks like a girl (I am pretty sure this is intentional. It's a running joke!). Geralt Zeman is a space physicist. He is nothing short of genius but he has a few screws loose. He practices the motto `}<strong parentName="p">{`“I accept only the best and it just happens I’m the best.”`}</strong>{` but he’s a softie on the inside. Babazulagi Kiplagat is the engineer. He’s the big guy in the team and is good at inventing machineries to solve their problems. Unfortunately, he is the biggest pervert among all four of his teammates. He coded up a p`}{`*`}{`rn search engine from scratch.`}</p>
    <p>{`While I think the gags really trump all else in this manga, I find the art barely serviceable sometimes. I usually wouldn't have minded that much but when it makes enjoying the manga hard... Then we have a problem. The panels can get really small to read! Due to the size, the art can look “smeared” so it’s really hard to read for me. It’s not as though the art is terrible per se but I find that the mangaka is trying to cram too many things into a small panel. I appreciate the details but there's no point if I can't look at it properly because it looks smeared/blurry. But we’re here for the gags anyway so… hopefully the art improves further in the future! `}<em parentName="p">{`But is it here to stay? `}</em>{`Does Agravity Boys give me axed`}{`*`}{` vibes? `}<strong parentName="p">{`Nope!`}</strong>{` I’m 100% sure it’s here to stay because the gags are hilarious. We’re 38 chapters in and the train has yet to stop chugging!`}</p>
    <p><strong parentName="p">{`EDIT: It got axed I think. Whelp, that didn't last long. lol`}</strong></p>
    <Ads mdxType="Ads" />
    <p>{`Chapter 1 had them fight amongst themselves because they want Chris to turn into a girl. For some reasons, the length of their pen`}{`*`}{`s got included in. `}<em parentName="p">{`Sheesh, boys.`}</em>{` Chapter 3 has them racing to get Geralt back to their ship. Is he injured? Does he need medical attention?! No to both questions, he just needs to `}<strong parentName="p">{`poop`}</strong>{`. Then there’s Chapter 4, where they did all they could to prevent Chris from getting in the water. I literally went `}<strong parentName="p">{`w h a t`}</strong>{` while reading this chapter. It turns out they were afraid of getting turned on when looking at Chris’s naked torso. Double `}<strong parentName="p">{`w h a t.`}</strong>{` Chris, I don’t know what you think of your friends but we know what they think of you. Get out of there, it’s `}<strong parentName="p">{`DANGEROUS`}</strong>{`!!`}</p>
    <Image alt="Agravity Boys manga panel 3" caption="This is a summary from the previous chapter. I think I'll just leave it to your imaginations on what's going on because it's funnier that way. You ANIMALLLLLS!" name="agravity-boys-panel-4.jpg" mdxType="Image" />
    <Image alt="Agravity Boys manga panel 4" caption="Did the mangaka just used a 2-page spread for this?! Congrats Geralt, you did it. You took a dump successfully!" name="agravity-boys-panel-1.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <Image alt="Agravity Boys manga panel 5" caption="Okay this chapter is nuts. So they were trying to stop Chris from going into the water but when Chris did get in for a soak, the mangaka's address was used to censor his chest! LMAO yes, this is real! I screencapped this from MangaPlus!" name="agravity-boys-panel-5.jpg" mdxType="Image" />
    <h2>{`All in all`}</h2>
    <p>{`Each chapter is a riot! Every chapter is just silly fun among the boys. It’s a type of humour they can get away with because the cast are all boys. Do that with a girl among their numbers and you’ll get a different effect (and a different genre too lmao)! The gags are what I’d call `}<strong parentName="p">{`top kek`}</strong>{` but is there an end goal for the Agravity Boys? Surely they must be thinking of trying to save Earth or establish communication with the other survivors? Nah, the boys just want to have fun and I’m more than happy to watch them cause mischief on their rudderless boat! They do get a bit serious in Chapter 31 but it’s still fun and games to them in the end.You can read `}<RemoteLink to="https://mangaplus.shueisha.co.jp/titles/100077" mdxType="RemoteLink">{`Agravity Boys on MangaPlus`}</RemoteLink>{`. It’s good to the point that I find it strange no one's reading it... Does it need an anime to be popular? ): If it does get an anime, I'll be the first to write about it!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      