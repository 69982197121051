import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const RemoteLink = makeShortcode("RemoteLink");
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Long time no see! I assure you, blog is not dead but my eyes and energy level is. No one told me trying to do everything on your own is so tiring... I've been trying get better rest, eating well and taking in supplements, but nothing can help me when it comes to house chores! Anyways, long story short, I have a `}<RemoteLink to="https://shopee.com.my/knowia92" mdxType="RemoteLink">{`Shopee store`}</RemoteLink>{` now! All I do is sell my merch I no longer want as well as merch I found in a bargain bin. If you're willing to put in some time updating your product listing and paying for some ads, it's decent lunch money. Honestly, I'm just glad I have a small-time gig to pay off my groceries.`}</p>
    <Ads mdxType="Ads" />
    <p>{`I sometimes get some notes from the buyer asking for extra protection or padding for the merch they bought from my store. I'm writing this blog post to clarify on two things:`}</p>
    <ol>
      <li parentName="ol">{`I only ship the merch in padded envelope and cardboard boxes.`}</li>
      <li parentName="ol">{`These shipments are further wrapped in stretch film to prevent it from getting wet.`}</li>
    </ol>
    <p>{`Without further ado, here's how I do it. If you are a new Shopee seller (Or Lazada, Carousell, it doesn't matter), I hope this post gives you a good idea on what to do.`}</p>
    <h2>{`Step 1: What type of parcel should you use?`}</h2>
    <p>{`If you are selling thin items, I suggest getting a padded envelope. These padded envelopes are fantastic to put in paper, files or shikishi board in it for maximum protection if you don't want to keep using a cardboard box.`}</p>
    <Image alt="Padded envelope" caption="Here's what I mean by padded envelopes." name="padded-envelope.jpg" mdxType="Image" />
    <p>{`If you are selling bulkier items, then a cardboard box is the way to go. I do not suggest wrapping your product in bubble wrap, then further wrapping it in a black stretch film. It gives a bad impression to the buyer, plus a few cons:`}</p>
    <ol>
      <li parentName="ol">{`Sure, you can wrap it tightly to the item's shape, but it is easy to peel the stretch film off and puncture it.`}</li>
      <li parentName="ol">{`You need to wrap multiple times to make sure it is protected.`}</li>
      <li parentName="ol">{`Not a strong padded protection.`}</li>
    </ol>
    <p>{`If you shop online often, I'm sure you have a sizable cardboard box collection already. If not, you can easily buy them online at a cheap price. I bought mine from this `}<RemoteLink to="https://shopee.com.my/epanda" mdxType="RemoteLink">{`Epanda shop`}</RemoteLink>{` on Shopee. They have a ton of packaging materials at a cheap price.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Step 2: How to secure the item in the parcel`}</h2>
    <p>{`There's basically 2 things you need to be mindful of as you place your item in the parcel:`}</p>
    <ol>
      <li parentName="ol">{`Make sure it is secure enough so it does not move when you shake the box.`}</li>
      <li parentName="ol">{`Each side of the parcel must be padded/protected.`}</li>
    </ol>
    <p>{`If you're using a padded envelope, hold it right there! You're not done yet even after putting the item inside the envelope.`}</p>
    <p><strong parentName="p">{`Place 2 thin but sturdy cardboard as to sandwich the item inside the envelope`}</strong>{`.`}</p>
    <p>{`Why? Because the courier fellas will surely toss the padded envelope around or bend it, so the cardboard is there to make sure it doesn't bend and protected.`}</p>
    <p>{`If you're using a cardboard box, then pick a box that fits the size of the item you're shipping. Leave adequate space around it so you have space to put bubble wrap. Since we're talking about bubble wrap, do you know that there are different types of wrap out there?`}</p>
    <h3>{`Air Bubble Wrap`}</h3>
    <p>{`I think these are called inflatable bubble wraps? Not too sure on the proper term but boy, it's way better than bubble wrap. If you're shipping something expensive, I suggest using this is possible. It's basically a much sturdier bubble wrap but it's way harder to pop it.`}</p>
    <Image alt="Air bubble wrap" caption="You can inflate these bubble wrap and wrap each item individually for maximum protection." name="air-bubble-wrap.png" mdxType="Image" />
    <h3>{`Honeycomb Paper Wrap`}</h3>
    <p>{`This is that one wrap I really like but oof, I think I'll stick to the plastic one. It's super pricey for an eco-friendly bubble wrap. The only pros I could think of if you want to use this wrap is:`}</p>
    <ol>
      <li parentName="ol">{`Your items aren't huge. Or you can wrap it individually and that's enough for protection.`}</li>
      <li parentName="ol">{`Your items look posh as your buyer opens the parcel up.`}</li>
    </ol>
    <Image alt="Honeycomb paper wrap" caption="Eco-friendly to mother earth but my wallet says otherwise." name="honeycomb-paper-wrap.png" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h3>{`Additional Note`}</h3>
    <p>{`Before you pack your item, make sure to take a picture of your item's condition and how you pack it. You can then choose to send the picture to your buyer or keep it for reference, in case the buyer starts a dispute case.`}</p>
    <h2>{`Step 3: Secure your parcel from rain`}</h2>
    <p>{`Now that your item in the parcel is secure, seal the box/padded envelope away and give it a shake. Is it secure? Perfect. You did your best for what's inside. And now, let's wrap the parcel on the outside. Let's use stretch film! You can get these stretch film cheap so just use as much as you want.`}</p>
    <Image alt="Stretch film" caption="Stretch film rolls." name="stretch-film.png" mdxType="Image" />
    <p>{`Wrap it around the parcel and do not leave any open corners. This is to ensure no rain can get inside. Once you're done, feel free to stick a FRAGILE or DO NOT BEND sticker, then slap the waybill on the parcel. I usually stick the waybill above the stretch film so the courier company can scan the waybill properly. Make sure to protect the waybill too by sticking cellophane tape on it.`}</p>
    <Image alt="Example of a well wrapped parcel." caption="Here's an example of how I would wrap my parcel." name="parcel-example.jpg" mdxType="Image" />
    <h2>{`All in all`}</h2>
    <p>{`So that's how I would wrap my parcel. I hope this gives you a good idea of a good parcel wrap. Thanks for reading!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      