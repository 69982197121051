import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Blockquote = makeShortcode("Blockquote");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I finally got down to watching Episode 4 of Hanako-kun and I must say, I am loving every second of it. Studio Lerche respects the original source and made sure everything is replicated to a tee. The colours, the panels, the graceful poses by the characters mimic the original manga panels. It honestly feels great to see them handle the transition this way. Fans of the original source will definitely love what they did here because I really like it so far.`}</p>
    <Ads mdxType="Ads" />
    <p>{`It also helps that the original source is good enough that those that have never touched the manga feel right at home watching it as a standalone anime adaptation. It had the perfect blend of action and comedy. The flow of the scenes doesn’t feel clunky and the plot progression feels just nice. Plus, the colours are seriously poppy and bright which makes it a delight to see the background for the first time in colour. The world of Hanako-kun is beautifully imagined and Studio Lerche made sure they do it right.`}</p>
    <p>{`Before I begin this post, I would like to make it clear that I really, really love what they did to the series in a whole. However, as I continue watching the episodes, I feel that there’s something seriously lacking to it.`}</p>
    <p>{`Now at Episode 4, the realization finally sank in.`}</p>
    <Blockquote mdxType="Blockquote">
  Here’s the problem - it is the exact copy of the manga. 
    </Blockquote>
    <Ads mdxType="Ads" />
    <p>{`You might be thinking, is it supposed to be different though? What’s wrong with being an exact copy of the original source? Well, there are things that will work in manga format and there are some that will translate better only in anime format. So, it’s normal to see how these anime studios would try to chop the content or present their own take to it. I’m honestly fine with it most of the time if the anime studios would try to change some stuff. Things simply do not work in anime format! Of course, if they change the entire premise of the plot then that’s a whole different story. (I’m looking at you, Tokyo Ghoul)`}</p>
    <p>{`Regardless, my point here is that Lerche did almost little to none to differentiate their work with the original source save for some of the creative takes (E.g. panels sliding in) which ironically, is still an exact copy of the manga’s panels.`}</p>
    <Image alt="Yashiro Nene from Jibaku Shounen Hanako-kun looking down" caption="Here, Nene is looking down." name="yashiro-nene-1.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <Image alt="Yashiro Nene from Jibaku Shounen Hanako-kun waiting for the item to reach the bottom" caption="Nene then listens for the object to reach the bottom." name="yashiro-nene-2.jpg" mdxType="Image" />
    <Image alt="The exact same manga panel" caption="You can find this scene in the manga. It's the exact same manga panel." name="yashiro-nene-3.jpg" mdxType="Image" />
    <p>{`Lerche’s version of Hanako-kun doesn’t feel like it belongs to them. They took so much effort to respect the original source, and it felt like the only thing they did is to animate the manga as a third party. Add some music, some colours directly taken from the manga’s colour palette and voila, Lerche’s job here as an animation studio is done. They were playing it safe, and I honestly think it is a shame. I’m not saying they shouldn’t play safe, but they should at the very least, put their own flair on the final product.`}</p>
    <p>{`Right now, it felt like I am watching a really well-done adaption of a series that I could have just skipped out on because I can get the `}<em parentName="p">{`same experience just by reading the manga.`}</em>{`A good example of a series that felt like it was made by a creative studio with their own twist is Kimetsu no Yaiba. You see, Kimetsu no Yaiba as a whole doesn’t need to be changed, no. The plot is good on its own and it screams “`}<em parentName="p">{`made to be animated`}</em>{`” which is great. That way, Studio Ufotable doesn’t even need to change the final product that much. It’s `}<em parentName="p">{`that easy`}</em>{` for the transition to work. But nope, they took the original source, respected the plot and went 1 step further with it.`}</p>
    <Ads mdxType="Ads" />
    <p>{`One of my favourite scene in the Kimetsu no Yaiba anime is the one with the Drum Demon Kyogai. In the manga, it happened in Chapter 24 and in the anime, starting from the end of Episode 12. The series just started getting good at this point and the main leads are fighting a demon that is somewhat on a mid-boss level. As you anime watchers know, fights with mid-bosses usually aren’t that exciting. It’s cool, it’s decent but nothing as fancy as the fight with the final boss. Heck, the manga is just at Chapter 24! I honestly did not expect to see anything exciting in the anime adaption for this fight.`}</p>
    <Image alt="Kimetsu no Yaiba manga panel with loud SFX" caption="This is somewhere at the start of the encounter with Kyogai." name="kimetsu-no-yaiba-manga-panel.jpg" mdxType="Image" />
    <p>{`Now here’s where the Ufotable magic happened. They took the whole manga chapter that consists of panels with a ton of loud sound effects, easy enough to understand transitions (honestly, the panels here are pretty messy) and made it exciting. While I read this chapter, it doesn’t feel like the stakes are high. It’s just another fighting scene I’ve seen a hundred times in any other shounen manga. But Ufotable doesn’t think that.`}</p>
    <Ads mdxType="Ads" />
    <p>{`There are lives at stake. Tanjiro is seriously hurt and this demon is way out of his league. As the viewer, you should feel stressed watching his struggle. It’s do or die for Tanjiro. They gave weight to his monologue as he struggles against this formidable foe. And then the room spinning happened. Damn, it’s so satisfying to see something so simple done in such an excellent way. By the way, you’ll find that this scene wasn’t immaculately depicted in the manga.`}</p>
    <p>{`Kimetsu no Yaiba anime is so satisfying to watch. Ufotable did a ton of changes that serve only to accentuate the stakes at hand. They know exactly how to rile up the viewers and they did. The room spinning scene is them flexing to say, “Here’s something only an anime adaption can do!” They put their own flairs to it and it’s so satisfying to watch the very same plot from the manga enhanced by details that only they can do through an anime.`}</p>
    <p>{`Now I have a good reason to watch the anime for a different experience because I’m not here watch Kimetsu no Yaiba written and drawn by Koyoharu Gotoge. I am watching an anime written by the original author but `}<strong parentName="p">{`animated by Ufotable.`}</strong>{` Same plot, but different experience. Ufotable did something really amazing here with Kimetsu no Yaiba. It screams `}<em parentName="p">{`Ufotable’s Kimetsu no Yaiba`}</em>{`.`}</p>
    <Ads mdxType="Ads" />
    <p>{`So here’s the thing, I know many folks out there would bring out pitchforks if I ever utter this very title of this blog post in the public. You must understand that there are just scenes in the manga that simply won’t work in the anime. Heck, some prolonged arc may not even make sense in anime format. Viewers are here for an experience they can only appreciate through 2D animation, and I strongly believe that anime studios `}<strong parentName="p">{`should `}</strong>{`strive to deliver what the viewers want. Mimic the manga 100% and things will undoubtedly fall flat. It’s a shame that Lerche didn’t try to flex a bit with Hanako-kun. A shame, really...`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      