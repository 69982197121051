import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const RemoteLink = makeShortcode("RemoteLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I hold Yuu Watase in high regard when it comes to `}<strong parentName="p">{`shoujo manga`}</strong>{`. While most readers recognized her more from `}<strong parentName="p">{`Fushigi Yugi`}</strong>{`, I started from `}<strong parentName="p">{`Absolute Boyfriend`}</strong>{` first. She had this really unique art compared to other shoujo manga artists. While everyone is busy drawing flowers in the background and sparkly eyes, she’s busy drawing girls and men with grounded proportions. The way Watase-sensei drew the eyes and hair is super recognizable, along with her personal preference for fantasy clothing. I find her art really messy in Arata Kangatari, but she got way better in Fushigi Yugi: Genbu Kaiden!`}</p>
    <p>{`The reason I wrote this post is partly nostalgia (again), and to tell everyone about the lovely classical shoujo works that seem to dwindle year by year. The classics are great, everyone! There’s more to the world of manga other than the `}<em parentName="p">{`popular otome isekai niche`}</em>{`. Here are some of my`}<em parentName="p">{` favourite works`}</em>{` by `}<strong parentName="p">{`Yuu Watase`}</strong>{`! The list is not ordered.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Classic Shoujo Manga Recommendation #1: Absolute Boyfriend (Zettai Kareshi)`}</h2>
    <p>{`Hellloooo classic! Have you ever wanted a `}<em parentName="p">{`perfect boyfriend?`}</em>{` And by perfect, I mean loving you just the way you are, dotes on you whenever you need it and is really, really good looking! Just the way you like it too <3 These criterias are a tall order but with Lover Shop, you can indeed get the boyfriend you want! Enter Riiko, a high school girl living alone who’s desperate for a boyfriend. She’s tried confessing to many potential boyfriends but none of them said Yes. Poor Riiko. Her chance came when she picked up a ringing phone lying on the ground. The caller insisted that he return the favour if they meet up to return the phone.`}</p>
    <Image alt="Absolute Boyfriend/Zettai Kareshi characters" caption="From the left, Soushi, Riiko and PERFECT BOYFRIEND. It's girly and sweet, I think anyone who loves a classic shoujo will enjoy this title." name="zettai-kareshi-absolute-boyfriend-manga.jpg" mdxType="Image" />
    <p>{`It turns out the phone’s owner is a strapping young man who’s from the sales industry. The moment she sat down, he tried to offer her a deal she couldn’t refuse. That’s right, `}<strong parentName="p">{`a boyfriend.`}</strong>{` Riiko thinks he sounds super shady (He is!) but a boyfriend? Yea, why not! She visited the website, and a Lover Shop appeared on her browser. She customized a boyfriend just for fun and voila, a box from said shop arrived at her apartment the next day. Riiko unboxed it and wow, a naked boyfriend appeared (HAHAHA). How will life change for her now that she got a bonafide boyfriend?`}</p>
    <p>{`As a shoujo manga heroine, Riiko lives in an environment that screams `}<em parentName="p">{`perfect for misunderstandings `}</em>{`and `}<strong parentName="p">{`romance`}</strong>{`. First, her childhood friend Soushi who lives next door is a good looking guy. It’s no secret that he has the hots for her. Second, no one’s at home but her. Her parents are conveniently away. You getting what I mean? :P The boyfriend is so sweet, I’m melting <3 Zettai Kareshi is definitely good stuff! There’s a `}<RemoteLink to="https://en.wikipedia.org/wiki/Zettai_Kareshi_(TV_series)" mdxType="RemoteLink">{`Japanese TV drama for Zettai Kareshi`}</RemoteLink>{` too if you’re interested!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Classic Shoujo Manga Recommendation #2: Fushigi Yugi: Genbu Kaiden`}</h2>
    <p>{`The four mythological beasts or the `}<RemoteLink to="https://en.wikipedia.org/wiki/Four_Symbols#:~:text=They%20are%20the%20Azure%20Dragon,Warrior%22)%20of%20the%20North." mdxType="RemoteLink">{`guardians of the four cardinal directions`}</RemoteLink>{` are super popular in any fantasy series touching upon Chinese myths. The Fushigi Yugi series is loosely based on these four mythical beasts. A girl from our world got pulled into `}<strong parentName="p">{`The Universe of the Four Gods `}</strong>{`(This is not a real book! Watase-sensei created this.) book and gets chosen as a priestess for said god to bring peace to the kingdom. But the priestess is not alone! She needs to gather all 7 of the Celestial Warriors who each bear a superpower in order to summon the mythical beast. You know, gather all 7 and make a wish cliche?`}</p>
    <Image alt="Fushigi Yugi Genbu Kaiden cover" caption="CLAMP art for their shoujo titles is one thing, but Yuu Watase's art and colour choices is impeccable <3" name="fushigi-yugi-genbu-kaiden-cover.jpg" mdxType="Image" />
    <p>{`It’s standard `}<strong parentName="p">{`isekai`}</strong>{` fare, but what’s interesting is how Watase-sensei created an enticing shoujo situation with the Celestial Warriors. It’s shoujo so of course there’s romance with the gang! You’d expect there to be 4 stories since there are 4 beasts, but the first Fushigi Yugi had both Suzaku (South) and Seiryuu (East) in it already. So there’s only 3 Fushigi Yugi titles out in the wild. You read them in this order: `}<strong parentName="p">{`Fushigi Yugi`}</strong>{` -> `}<strong parentName="p">{`Fushigi Yugi Genbu Kaiden`}</strong>{` -> `}<strong parentName="p">{`Fushigi Yugi Byakko Senki`}</strong>{`. While I’ve read Genbu and Byakko, I couldn’t bring myself to read the first one. I couldn’t bring myself to like the vibe in the first Fushigi Yugi. It didn’t help that Miaka had a boisterous personality, and Yui is just… `}<em parentName="p">{`bleah`}</em>{`. I could have mentioned that there are 6 shoujo titles that I wish to recommend, but I didn’t finish Fushigi Yugi. So I will drop the title here for you to discover that on your own! You don't need to read the first Fushigi Yugi to know the plot. Each Fushigi Yugi is a standalone story with very minimal throwbacks.`}</p>
    <p>{`Now, on to Genbu Kaiden! Fushigi Yugi stories often start off by pulling a girl from different eras. The two girls from the first Fushigi Yugi were pulled from the modern era while `}<strong parentName="p">{`Genbu Kaiden`}</strong>{` pulled one from the Taisho era. Takiko is a 17 year old girl who lives with her mother who has contracted tuberculosis. Takiko is bold and daring, unlike girls her own age. She’s pretty handy with a naginata too. Her family situation is troubling and she’s in love with a man 10 years her age. It’s a unique setup for someone who’s about to be whisked away to another world, that’s for sure. One day, her father returned from China with the Universe of the Four Gods book. He was hard at work at deciphering it, to the point that he ignored Takiko and his ailing wife. It sounds irresponsible but his heart is at the right place. He claimed that the book could save his wife but it was too late. His wife passed away and Takiko, in her fury, proceeded to snatch the book away to tear it.`}</p>
    <p>{`It is at this moment that the book shone and transferred Takiko to the world of the four gods, in the kingdom of Hokkan ruled by Genbu. It’s a short and sweet 40 chapters of a great journey to collect all 7 Celestial Warriors and summon Genbu. The ending is super sweet too, so please read this! I think it’s one of Watase-sensei’s best works.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Classic Shoujo Manga Recommendation #3: Fushigi Yugi: Byakko Senki`}</h2>
    <p>{`With `}<strong parentName="p">{`Byakko Senki`}</strong>{`, we can safely say that the Fushigi Yugi series took place in the same universe! Byakko Senki happened 100 years after Genbu Kaiden in the Four Gods world. Byakko Senki’s a bit strange since Watase-sensei published the one-shot Byakko Ibun first, then continued the story in Byakko Senki. So please read the one-shot first to get a clear idea on the beginning of Byakko Senki. I did say that all titles are completed but Byakko Senki is currently on hiatus since 2018. I am not sure if Watase-sensei will pick it back up but I have a feeling this series will be left hanging for years to come.`}</p>
    <Image alt="Fushigi Yugi Byakko Senki cover" caption="This is the last of the Fushigi Yugi(s), unless Watase-sensei had a different idea..." name="fushigi-yugi-byakko-senki.jpg" mdxType="Image" />
    <p>{`Our Byakko priestess is Suzuno, a 8 year old girl from the Taisho era which coincidentally, is Takao Osugi’s daughter. He’s the guy from Genbu Kaiden that Takiko had a crush on! Without going too much into spoiler territory, he was entrusted with the Universe of the Four Gods book by the translator himself. Despite not being deeply involved with the Genbu Kaiden plot, Takao believed that the book is indeed otherworldly. He tried many methods to destroy it, but the book remains stubborn. He trusted his predecessor when he spoke of how the book took Takiko into another world. Thus, he feared that the book would one day take his daughter too. Unfortunately, `}<em parentName="p">{`he’s right.`}</em></p>
    <p>{`The book protected Suzuno during the great Kanto earthquake. Takao realized that Suzuno is destined to be a priestess and told her to open the book. Suzuno is quickly whisked away to her destined world and arrived in a desert… as an 8 year old girl. What on earth can an 8 year old do? I find this part of the story really cruel. At least make the heroine a bit older? Like, the poor girl is only 8! Anyway, she soon met with the protagonist of Byakko Ibun before returning back to the real world. 10 years later, Suzuno is now a high school girl. She has since forgotten about her adventure in the world of Four Gods, but destiny works in strange ways. She found the book again in her school’s library and began to research the truth about the translator. Given how intricate the story is with Genbu Kaiden, Suzuno realized the truth behind the book. Will Suzuno choose to go back in, or fight her destiny?`}</p>
    <p>{`I’m sad there’s only 5 chapters + 1 one-shot in Byakko Senki... I personally think Byakko is an even bigger step-up from Genbu Kaiden! The art is more intricate and facial expressions look more natural. Plus, the heroine knew about her fate from the get-go unlike the other protagonists. With the inclusion of the one-shot, I’m pretty sure that the whole gathering of seven Celestial Warriors will play really differently than the Genbu Kaiden series. Please, I need this story to continue...`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Classic Shoujo Manga Recommendation #4: Alice 19th`}</h2>
    <p>{`Phew. This one’s pretty tough to recall since I read it a decade ago. I remember enjoying Alice 19th’s magical concept. Besides, rabbits and ribbons! Count me in!`}</p>
    <Image alt="Alice 19th manga cover" caption="I love those little loops they have around their shoulders when they transform to a Lotis Master! I call them ribbons in my head." name="alice-19th-shoujo-manga-yuu-watase.jpg" mdxType="Image" />
    <p>{`Alice Seno is the youngest in her family. You know how things are when you’re the youngest… Everyone asks you to help them with work that they could easily do themselves! (No, this isn’t part of the summary, I’m legit complaining) Alice has a big sister called Mayura and her parents are super biased towards her. Everything her sister wants, her sister gets. Obviously, this made Alice really jealous of her sister who has it all. Mayura has the attention of Wakamiya, her crush from the archery club. She’s popular in school as well. Mayura is everything Alice isn’t.`}</p>
    <p>{`Things changed when one day, she saw a rabbit in the middle of a crossroad. Despite the opportunity, no one stepped up to save it. Alice then rushed out to save the poor bunny from being a flat cake. She kept the bunny safe for a bit, but it hopped off. Alice chased after the rabbit, only to find a magical bracelet on the ground.`}</p>
    <Ads mdxType="Ads" />
    <p>{`The rabbit left her mind as she focused on the more important things - Wakamiya. She wanted to confess but she lacked the courage to do so. Alice ran off and it was then the bunny appeared before her again. Except this time, it had a humanoid form and called herself Nyozeka? Nyozeka told Alice that the bracelet she gave her is the 19th Lotis `}<em parentName="p">{`“Courage”`}</em>{`, and that Alice has the capability to become a `}<strong parentName="p">{`Lotis Master. `}</strong>{`Lotis Master has powers over their given Lotis Word. Using their powers, they could enter the Inner Heart of others. The whole trouble started when Alice accidentally wished Mayura away and got her older sister swallowed by darkness. Now, she has to fix what she has started by getting her sister back. But first, she needs training… Will she be able to fix her relationship with her sister, and get her family back together?`}</p>
    <p>{`Shoujo manga either tackles straightforward romance, comedy or the intricacies of the human relationship with others. As such, shoujo dialogue can get a bit cheesy, especially when it comes to dialogues about baring your heart for everyone to see. If I do that in RL nowadays, I’d get blank faces. So make sure to talk heart-to-heart with a Lotis Master, a 2D character or with your bff only, okay? <3 It’s quite heartwarming to see Alice being courageous and saying what needs to be said. Please read `}<strong parentName="p">{`Alice 19th`}</strong>{`! It’s great stuff.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Classic Shoujo Manga Recommendation #5: Ayashi no Ceres`}</h2>
    <p>{`Here’s the final recommendation for this blog post! `}<strong parentName="p">{`Ayashi no Ceres`}</strong>{` is super different than the rest of the Yuu Watase titles recommended here. I personally find it strange when I first read it years back but it’s really good.`}</p>
    <Image alt="Ayashi no Ceres manga art" caption="This shoujo is lengthier than her usual works. I really need to revisit this shoujo title..." name="ayashi-no-ceres-shoujo-manga-yuu-watase.jpg" mdxType="Image" />
    <p>{`Mikage Aya is our heroine in this title! She’s 16 years old and right in the smack of her rebellious age. Aya, Aki (her twin brother) and her friends went to a fortune teller only to be told an ominous fortune that will happen on her 16th birthday. After finishing karaoke with the gang, a thief snatched a purse right in front of her. She jumped into action and almost died by falling onto the asphalt road from the bridge. Right before her body makes contact with the ground, images flashed right in front of her and she floated safely down. The sudden lack of gravity shook her. Aya was about to be an unfortunate car accident victim until a man in black saved her and left as quickly as he arrived. The entire incident did bother Aya and Aki but being teenagers, they quickly recovered from the shock.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Suddenly, their parents dropped a bomb on them while looking all serious-like. They are to visit their grandparents house after school on their birthday. Upon entering the hall, they found everyone already waiting for them. The moment a shriveled hand was brought out, Aya’s body burst with energy while Aki’s body bled. See, this is some occult stuff. It was creepy when I first read it! I thought this was a horror manga. Their grandfather quickly declared that Aya must die while Aki needs to live. Using her powers, Aya managed to run from her family and was saved by a group of allies who told her the truth.`}</p>
    <p>{`She’s a descendant of a `}<RemoteLink to="https://jigsaw-japan.com/2017/09/09/the-miho-pines-and-the-legend-of-the-hagoromo" mdxType="RemoteLink">{`Tennyo from the same famous myth`}</RemoteLink>{` everyone is familiar with. The Tennyo was bathing in the river and had her Hagoromo stolen by a fisherman. Since she couldn’t fly back to the heavens, she stayed on Earth. She married the fisherman and gave birth to a child. Only when she found the Hagoromo hidden by the fisherman that she could finally go home. Descendants of Tennyo can make things float, but Aya is much more powerful than that. Will Aya fight back her own family in order to survive?`}</p>
    <p><strong parentName="p">{`Ayashi no Ceres`}</strong>{` has a more intense vibe compared to the other shoujo titles Watase-sensei did. To me, shoujo is supposed to be fluffy and romantic. Instead, Watase-sensei wrote an intense shoujo that has the heroine desperate to survive. This title was a massive surprise to me when I first chanced upon it. It’s a good shoujo, please do try it!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`All in all`}</h2>
    <p>{`I have added a blog post about Yuu Watase into Google's archive. My job is done. `}<strong parentName="p">{`dramatically whips cape to leave`}</strong>{` But seriously though, all her works are great, even Arata Kangatari! It’s a shame that it is on hiatus right now. I really hope she comes back to continue Arata Kangatari, or Byakko Senki. In the meantime, please enjoy the 5 shoujo titles I recommended here :)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      