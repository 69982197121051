import PropTypes from 'prop-types';
import styled from 'styled-components';

import $ from '../../../styles/globals';
import Counter from '../HeartIt/Counter';
import PageViewCounter from '../PageViewCounter';
import TotalComments from '../../DisqusComments/TotalComments';
import { getDate } from '../../../utils';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  ${$.screen.mobile} {
    flex-direction: ${({ reverse }) => (reverse ? 'column' : 'row')};
    align-items: ${({ reverse }) => (reverse ? 'flex-start' : 'center')};
  }
`;

const Stats1 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  > *:first-child {
    margin-right: ${$.layout.margin3}px;
  }
`;

const Stats2 = styled.div`
  ${({ lastUpdated }) => `
  display: flex;
  flex-direction: row;
  align-items: center; 
  > *:first-child {
    margin-right: ${lastUpdated ? $.layout.margin3 : '0'}px;
  }

  ${$.screen.mobile} {
    justify-content: space-between;
    ${lastUpdated ? 'width:100%;' : ''}
    > *:first-child {
      margin-right: 0;
    }
  }
`}
`;

const LastUpdatedDate = styled.div`
  font-family: Rubik Regular;
  color: ${$.brown3};
  font-size: 14px;
`;

const Stats = ({ title, slug, hearts, pageViews, lastUpdated, published }) => (
  <Container reverse={lastUpdated && lastUpdated !== published}>
    <Stats1>
      <Counter hearts={hearts} />
      <PageViewCounter total={pageViews} />
    </Stats1>
    <Stats2 lastUpdated={lastUpdated && lastUpdated !== published}>
      {lastUpdated && lastUpdated !== published && (
        <LastUpdatedDate>
          <time dateTime={new Date(lastUpdated).toISOString()}>
            {`Last updated ${getDate(lastUpdated)}`}
          </time>
        </LastUpdatedDate>
      )}
      <TotalComments title={title} slug={slug} />
    </Stats2>
  </Container>
);

Stats.defaultProps = {
  title: '',
  slug: '',
  hearts: '-',
  published: '',
  pageViews: 0,
  lastUpdated: '',
};

Stats.propTypes = {
  published: PropTypes.string,
  lastUpdated: PropTypes.string,
  pageViews: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  slug: PropTypes.string,
  hearts: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
};

export default Stats;
