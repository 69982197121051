import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`So. It has `}<strong parentName="p">{`finally`}</strong>{` come to this. I was reading my daily manga updates and I stumbled upon yet another `}<em parentName="p">{`new isekai manga`}</em>{`. Of course, I wasn’t going to ignore the update! I clicked on it and began reading it which I find super enjoyable (as usual). The formula is getting repetitive enough that I had a cheeky idea to make a blog post about it. If we’re talking about isekai manga, anime or even light novels, the `}<strong parentName="p">{`NO. 1`}</strong>{` common point between these titles is that they feature `}<strong parentName="p">{`skills`}</strong>{`.`}</p>
    <p>{`It’s not just some vague skills or characteristics of a character to push the plot, but `}<strong parentName="p">{`skills`}</strong>{` inspired from video games! Ask any gamer out there and they’ll start to rattle about stats, meta or game mechanics whatsoever. Be it RPG, MMO, TCG or even board games… Almost every form of video game-like entertainment juggles numbers and rules to follow. It was `}<em parentName="p">{`this`}</em>{` approach that every isekai out there copied.`}</p>
    <Ads mdxType="Ads" />
    <p>{`This blog post acts as an `}<strong parentName="p">{`isekai manga recommendation`}</strong>{` as well. I’ll include some snippets or screenshots along with the manga titles, so do look them up! Here are the most common 4 game-like skills I noticed from `}<strong parentName="p">{`every`}</strong>{` isekai I’ve read.`}</p>
    <h2>{`Game-like Skills in Isekai Manga #1: Appraisal`}</h2>
    <p>{`Why must every darn object in the fantasy world be accompanied with a floating info box you often see in games? There’s a reason this skill is the first in the list! The story in `}<strong parentName="p">{`every isekai`}</strong>{` goes like this (Imagine me rapping really quickly) - Truck killed MC, MC is confused, skimpy or wise looking God appeared, skills are granted, isekai starts! Some MC chose to request for the skill `}<strong parentName="p">{`Appraisal`}</strong>{` before they start the adventure and they have pretty valid reasons for it. I mean, you’re about to be dropped into a world with no help. Gathering information is super crucial for survival! Meanwhile, some isekai just hand `}<em parentName="p">{`Appraisal`}</em>{` over as part of the fantasy world’s worldbuilding...`}</p>
    <p>{`Appraisal is a super simple skill in general, but I enjoyed how some isekai(s) out there tried to be creative about it. E.g. by using Appraisal more, MC could glean more information from the object.`}</p>
    <Image alt="Reincarnated as an Aristocrat with an Appraisal Skill screencap" caption="This is from “Reincarnated as an Aristocrat with an Appraisal Skill“. The skill is in the title (and the screencap), enjoy!" name="isekai-manga-recommendation-geeknabe-1.jpg" mdxType="Image" />
    <Image alt="Otome Game Tensou, Ore ga Heroine de Kyuuseishu screencap 1" caption="This is pulled from Otome Game Tensou, Ore ga Heroine de Kyuuseishu!? The MC has the ability to analyze any character he sees, including the lesser NPC. He got isekai-ed into an otome game so everything he sees is just CG from said otome game… It's hilarious, please give it a try." name="isekai-manga-recommendation-2.jpg" mdxType="Image" />
    <Image alt="Isekai Yakkyoku screencap 1" caption="“Isekai Yakkyoku” is one of my favourite isekai! MC is a pharmacologist that died in our world and was sweeped into a young boy’s body. He can quickly scope out health problems using his Divine Sight." name="isekai-manga-recommendation-geeknabe-3.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`Game-like Skills in Isekai Manga #2: Status`}</h2>
    <p>{`Remember folks! If you got reincarnated into a fantasy world, make sure to say `}<strong parentName="p">{`“Status!” `}</strong>{`out loud to check the goodies a higher being has granted you. The goodies will most definitely include the following - your stats, overpowered skills, passive or maxed out blessings and a title. Double check if you have the `}<em parentName="p">{`Language Comprehension `}</em>{`skill too, because that’s super important when talking to locals.`}</p>
    <p>{`I personally think it’s super rare to find isekai(s) that do not have `}<strong parentName="p">{`Status`}</strong>{` incorporated in. It’s an easy way to get things up to speed, and it’s clear cut too. Representing a character’s qualities are much, much easier with numbers and texts. Some isekai may opt to make Status as part of the sub-plot too. E.g. the higher level you are, the more information you can glean.`}</p>
    <p>{`Isekai that made knowing the enemies’ Status as a way to turn the tables love adding skills such as `}<em parentName="p">{`The Eye of All-Knowing`}</em>{` or `}<em parentName="p">{`Inspection`}</em>{`. These skills allow the main cast to peep on their enemies or the other way around. Obviously if you have the Inspection skill, there’s equally useful skills to hide or lie about your Status.`}</p>
    <Image alt="So I'm a Spider, So What? screencap 1" caption="This spider doesn’t need introductions! In case you’re new to the isekai world, this is Kumoko from “So I’m a Spider, So What?”! The background from this panel is all of her skills listed out." name="isekai-manga-recommendation-geeknabe-4.jpg" mdxType="Image" />
    <Image alt="Yasei no Last Boss ga Arawareta! screencap 1" caption="This is pulled from “A Wild Last Boss Appeared!”. Super good and it should be tagged as mystery too! MC is pulled from the modern world due to a heinous plot by the Goddess in the fantasy world." name="isekai-manga-recommendation-geeknabe-5.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`Game-like Skills in Isekai Manga #3: Minimap`}</h2>
    <p>{`Information is key. Now that the isekai manga’s MC knew about `}<em parentName="p">{`Statuses`}</em>{` and earned the ability to `}<strong parentName="p">{`appraise objects`}</strong>{`, the next overpowered skill to add to their arsenal is this - `}<em parentName="p">{`Minimap`}</em>{`. It’s a familiar concept to many gamers out there! You may control your character in the game through first person or third person view, but there should always be a minimap at the top of your screen.`}</p>
    <p>{`The minimap will tell you the location of the enemies or even treasure boxes within an acceptable range. Handy, isn’t it? Some isekai even have this minimap tell them `}<strong parentName="p">{`when `}</strong>{`the enemies are approaching… Shouldn’t the skill be called Auto Alert instead? Some `}<strong parentName="p">{`isekai manga`}</strong>{` attempted to replicate this with magic. With the caster at the center, a thin-like magic is spread out to scan for potential enemies.`}</p>
    <Image alt="By the Grace of the Gods screencap 1" caption="This manga is titled “By the Grace of the Gods”. MC is a sweet guy who has the curiosity of a cat. He experiments a lot which led to creating the magic “Probe”. See? Minimap! There's an anime coming soon too! You need to watch it, this manga is really good." name="isekai-manga-recommendation-geeknabe-6.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`Game-like Skills in Isekai Manga #4: Storage`}</h2>
    <p>{`Last but not least, the one skill I would love to have - Storage! With this skill, folks can put any darn thing they want into a black hole that has unlimited space. The characteristics of this skill may or may not include:`}</p>
    <div>
  <ul>
    <li>
      Weight limit. E.g. The maximum weight the Storage skill could hold is
      5000KG. 
    </li>
    <li>
      You can’t put a living being inside. Or you can, whatever floats the
      isekai’s boat.
    </li>
    <li>
      Time is stopped in the Storage, so food stays piping warm or never expires
      as long as they are not taken out.
    </li>
    <li>
      All objects placed into Storage are promptly classified and stacked. E.g.
      potions added in, and are classified into HP or MP potions along with
      their total. HP Potions x99, anyone?
    </li>
    <li>Since dead things aren’t living, you can store carcasses!</li>
    <li>It’s a rare skill, so only magical items or the MC has this skill.</li>
  </ul>
    </div>
    <p>{`If Storage isn’t a skill on their own in said isekai, I’m sure the isekai manga (Or light novel, for that matter) author will find a way to add Space Magic as a preface for creating a storage skill.`}</p>
    <Image alt="Tensei Shitara Ken Deshita screencap 1" caption="Fran takes out every monster she kept inside her Master’s storage. “Tensei Shitara Ken Deshita”, enjoy! It’s super good, I think folks who love the whole isekai game-skill mechanic will enjoy this one." name="isekai-manga-recommendation-geeknabe-7.jpg" mdxType="Image" />
    <Image alt="New Gate screencap 1" caption="Oof, a rare skill! Wow, I didn’t expect that! Anyway, this is from “New Gate”, yet another hidden gem!" name="isekai-manga-recommendation-geeknabe-8.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`All in all`}</h2>
    <p>{`And that’s that! Thanks for reading, I hope the these recommended isekai manga titles are enjoyable to you too.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      