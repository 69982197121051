import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const LocalLink = makeShortcode("LocalLink");
const Ads = makeShortcode("Ads");
const RemoteLink = makeShortcode("RemoteLink");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`So some time ago, I wrote about 20 completed webtoons that I really enjoyed and split that into 2 parts. Check them out in my topic page `}<LocalLink to="/topic/manga/" mdxType="LocalLink">{`here`}</LocalLink>{`!`}</p>
    <p>{`This time, I want to recommend all of my favourite ongoing Webtoons that I’m currently reading. I didn’t want to put a number in this blog post’s title since I expect more updates to this post in the coming months. Alas, I kinda went too far in describing each of my favourite webtoon so this blog post got too long! So I’m going to split it up into parts while I take my time writing about them.Anyway, here we go! Take a look at Part 1 of my favourite ongoing Webtoons list <3 This list is not ordered.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Ongoing WEBTOONS Recommendation #1: Tower of God`}</h2>
    <p>{`If I were to summarize this webtoon, I can `}<em parentName="p">{`generally`}</em>{` classify Tower of God as a fantasy battle webtoon. You take all of the characteristics of a likeable character and stuff them into the protagonist. You then create a fantasy world with meta-like underlying mechanics that forms the basis of physics in said world. Next, create the world’s varied residents, sprinkle in some pseudo-politics and a dash of story-telling about self-serving individuals. Lastly, add in the greatest antagonist you can think of in terms of power, charisma and purpose. Combine them all and you have Tower of God, a series that `}<RemoteLink to="https://towerofgod.fandom.com/wiki/SIU" mdxType="RemoteLink">{`SIU`}</RemoteLink>{` has crafted as part of his grand`}<RemoteLink to="https://towerofgod.fandom.com/wiki/Talse_Uzer_Story" mdxType="RemoteLink">{` Talse Uzer Story`}</RemoteLink>{` universe. Honestly speaking, Tower of God is every bit of a shounen folks expected. `}<em parentName="p">{`But!`}</em>{` Unlike many struggling shounen out there, this webtoon stuck to the same plot basis throughout the chapters. Simply put, this story’s quality has never strayed from its humble beginning.`}</p>
    <Image alt="Tower of God webtoon banner" caption="Tower of God banner taken directly from Webtoon's official website" name="tower-of-god-banner-geeknabe.jpg" mdxType="Image" />
    <p>{`Our Tower of God protagonist is Baam, a young boy who knew nothing about the world. It was when he met Rachel that his world got slightly bigger. So when Rachel pushed him away to `}<em parentName="p">{`climb the Tower, `}</em>{`he chased after the girl. This is the story of Baam The 25th, an `}<strong parentName="p">{`irregular `}</strong>{`fated to defeat the King of the Tower, Zahard. The quick summary is but a modest umbrella view of the story. As Baam continues to climb the tower, the story keeps adding more stakes. More characters jumped into the fray every episode, yet SIU never fails to use these new characters to push for Baam’s growth. Things are not what it seems as more hidden plots are unearthed. The casts are always teetering between life and death in every battle they are involved in. It’s either losing a few limbs or lives for these Tower of God residents, there is no in-between.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Tower of God anime poster" caption="Tower of God anime poster! The lineart is gorgeous." name="tower-of-god-anime-banner-geeknabe.jpg" mdxType="Image" />
    <p>{`I’ve been following this series for years. I jumped in joy when I heard that it got an anime! There are 477 chapters so far on WEBTOON, and it just so happened that each chapter is really lengthy. If you find this overwhelming, you can try the anime first before jumping into the webtoon. `}<RemoteLink to="https://www.webtoons.com/en/fantasy/tower-of-god/list?title_no=95&page=1" mdxType="RemoteLink">{`Tower of God`}</RemoteLink>{` is worth the investment so please try reading this story!`}</p>
    <h2>{`Ongoing WEBTOONS Recommendation #2: Kubera`}</h2>
    <p>{`I mentioned that this list is not ordered. I'm sorry, I sort of lied to you. Before Tower of God, my personal No. 1 favourite has always been Kubera.`}<LocalLink to="/blog/the-next-webtoon-they-should-animate-after-tower-of-god-is-kubera/" mdxType="LocalLink">{` I wrote about it here if you're interested!`}</LocalLink>{` This webtoon is a fantasy story but it is tagged as `}<em parentName="p">{`tragedy. `}</em>{`There are no winners in this story, only victims.`}</p>
    <Image alt="Kubera webtoon banner" caption="Kubera banner taken directly from Webtoon's official website" name="kubera-webtoon-banner-geeknabe.jpg" mdxType="Image" />
    <p>{`Leez Haias is a village girl blessed with good family and friends. On her birthday, she left to pick mushrooms in a forest nearby. When she returned, her village was wiped out from the map. This marks the start of her journey to take revenge on the perpetrator and along the way, learn about a world reliant on the mercy of gods indifferent to the struggles of mortals.`}<em parentName="p">{`Sigh. `}</em>{`It took me a while to write that short summary. I didn’t know where to start! Tower of God is one thing, but Kubera is a whole different type of `}<em parentName="p">{`complexity.`}</em>{` Kubera talks about the struggles of humans, suras and gods caught in a whirlpool of conflict over the power of the `}<em parentName="p">{`name. `}</em>{`I’m `}<strong parentName="p">{`technically `}</strong>{`giving you spoilers but heck, you need to know ahead if you wish to enjoy the story better.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Kubera is complex but a worthy investment" caption="How complex can a webtoon be? Here is a list of things you should read to get a better understanding of Kubera. Also, not shown here is each category's sub category :D" name="kubera-complexity-wiki-geeknabe.jpg" mdxType="Image" />
    <p>{`Humans are the mortals in this story. They are weak, short-lived but are able to borrow the powers of the god to protect themselves. Suras are the immortal, powerful beings that have existed since the beginning of the universe. As such, they look down on feeble mortals. They feed on their kind as food by the way. Gods are non-aging beings granted a jurisdiction in the universe. While they are powerful, these gods can be killed. Kubera’s gods walk with the mortals and are seen as individuals by the world’s residents rather than concepts like in most stories. The gods and the suras are at war with each other with the humans caught in-between. Actually, scratch that. The humans knew nothing of the war but they are the victims of everything cooked up by the higher beings. You see these suras and gods whispering deceit to each other and using the other for their selfish benefits... Little did they know it was all a grand opera caused by the primeval gods, beings that existed even before the universe came into being.`}</p>
    <p>{`We first watch the plot unfold through the eyes of Leez. She is never tutored so she knows little of the world. Thankfully, Asha serves as our second set of eyes into the world of Kubera. She is a powerful magician herself, and as a scholar she knows a lot about the world. The meeting for these two `}<em parentName="p">{`players `}</em>{`soon caused a wave of change in the world.`}</p>
    <p>{`The story requires a ton of `}<em parentName="p">{`reading between the lines`}</em>{` finesse but it’s so satisfying once everything clicks in your head. “It has been foreshadowed since Chapter X!” You will encounter this thought a lot while reading this webtoon. The general flow while reading this webtoon is usually like this:`}</p>
    <ol>
      <li parentName="ol">{`You read a new chapter weekly. New info is introduced by Currygom the author. You add that to the list of “Things you know nothing about in Kubera”.`}</li>
      <li parentName="ol">{`Shocking revelation happened in the latest chapter! It correlates with the explanation and behaviour of Character X in Chapter Y to Chapter Z!`}</li>
      <li parentName="ol">{`You go back to said chapter to check it out again with the new revelation in mind.`}</li>
      <li parentName="ol">{`Repeat Step 1 to Step 3 for every new story arc.`}</li>
    </ol>
    <p>{`If you like stories with deep lore and getting super invested in the characters, `}<RemoteLink to="https://www.webtoons.com/en/fantasy/kubera/list?title_no=83&page=1" mdxType="RemoteLink">{`Kubera `}</RemoteLink>{`is for you.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Ongoing WEBTOONS Recommendation #3: Yumi’s Cell`}</h2>
    <p>{`Kubera and Tower of God can get really overwhelming to read. So once in a while, I like to read a quick and simple romance webtoon to lighten up the mood. That webtoon is Yumi’s Cell! Note: This blog post was written years back. This webtoon is now completed in 2021!`}</p>
    <Image alt="Yumi's Cell webtoon banner" caption="Yumi's Cell banner taken directly from Webtoon's official website" name="yumis-cell-webtoon-banner-geeknabe.jpg" mdxType="Image" />
    <p>{`I simply adore the concept of this hilarious romance comedy. Our MC is Yumi, a young office lady in South Korea that busies herself thinking about love and deciphering her social circles. Yumi over thinks a `}<em parentName="p">{`lot`}</em>{` on `}<strong parentName="p">{`every`}</strong>{` social cue her colleagues may have consciously (or subconsciously) showed her. We see her trying to piece everything in her mind, then coming up with a socially acceptable counter! This honestly sounds like your everyday life, isn’t it? We look at your friends’ and families’ cues and try to make sense of it.`}</p>
    <p>{`But here’s the twist. In Yumi’s Cell, thoughts by every character in this series is depicted by a hilarious set of chibi personalities and desire. And just like everyone’s inner voice, `}<em parentName="p">{`they argue`}</em>{` a lot on what to do when asked to act! Should you eat your favourite food for lunch, or choose the healthier option? A friend is asking for love advice! What do you reply?`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Yumi's humble beginning..." caption="... And her brain cells." name="yumis-cell-webtoon-comic-pabel-geeknabe.jpg" mdxType="Image" />
    <p>{`The webtoon is filled with these adorable scenes of her brain cells arguing among each other but the webtoon is not without Yumi's growth as an individual. All in all, this series is still about Yumi trying to find her way in life. She’s currently a white collar worker, but is she truly happy in her current position? She wants to get married but is her current boyfriend `}<em parentName="p">{`the one?`}</em>{` The art in the beginning chapters is not super polished, but the art quality gets much better in later chapters!The series has been chugging for some time now, and I don’t see it ending anytime soon. I honestly think `}<RemoteLink to="https://www.webtoons.com/en/slice-of-life/yumi-cell/list?title_no=478&page=1" mdxType="RemoteLink">{`Yumi's Cell`}</RemoteLink>{` is a fun read for young adults. Give this a try!`}<br /><br />{`Note: This webtoon is now COMPLETED! And it even got a `}<RemoteLink to="https://mydramalist.com/60627-yumi-s-cell" mdxType="RemoteLink">{`K-drama announcement`}</RemoteLink>{`!`}</p>
    <h2>{`Ongoing WEBTOONS Recommendation #4: Boyfriend of the Dead`}</h2>
    <p>{`I hope you like zombies, comedy and `}<em parentName="p">{`breaking the fourth wall `}</em>{`jokes because this webtoon has all of these! Boyfriend of the Dead does not take itself seriously and you shouldn’t too (Except the romance stuff. It’s serious y’all!).`}</p>
    <p>{`A zombie apocalypse happened in a blink of an eye. People are turning into zombies! It’s a legitimate crisis! But Alex couldn’t give two sh`}{`*`}{`ts about it. Give her branded bags and clothes, or die by her golf club.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="If you see this girl, please stay as far away as possible. Do not attempt to make contact! I repeat! DO NOT ATTEMPT TO ENDANGER YOUR (UNDEAD) LIFE!" caption="Alex doing her usual one-hit KOs." name="boyfriend-of-the-dead-webtoon-comic-panel-1-geeknabe.jpg" mdxType="Image" />
    <p>{`Then there’s (tentatively) N, a zombie that snapped out of his flesh-eating tendencies after watching a bunch of his kin gobbling up artificial snack. `}<em parentName="p">{`Example of artificial snack shown below.`}</em></p>
    <Image alt="Unhealthy snack. Eat at own risk." caption="So this is how an unhealthy snack look like as a real person..." name="boyfriend-of-the-dead-webtoon-comic-panel-2-geeknabe.jpg" mdxType="Image" />
    <p>{`What horror! N prefers his meat `}<em parentName="p">{`au naturale `}</em>{`so this shook him to the core. It took a while, but he quickly recovered from the shock and assessed his current situation. He has no heartbeat. Half of his face is gone. Everything about him screams `}<em parentName="p">{`dead. `}</em>{`He couldn’t remember anything from when he’s human either. With no goal in mind, he wandered the place `}<em parentName="p">{`without trying to `}</em>{`eat any of the humans he came across. His reasons are among the following:`}</p>
    <ol>
      <li parentName="ol">{`Too oily. Diet consists of McDonalds or fried chicken.`}</li>
      <li parentName="ol">{`Too skinny. E.g. like a stick.`}</li>
      <li parentName="ol">{`Too old. E.g. your grandma.`}</li>
    </ol>
    <p>{`So when N met Alex, it was `}<strong parentName="p">{`love at first smell`}</strong>{`. She smells like wagyu beef to N! (lol) He wasted no effort trying to get close to her just so she would stop clubbing innocent zombies (and potentially him) to death. Now that he earned her trust, he tried to `}<em parentName="p">{`eat `}</em>{`her but things did not go the way he expected…`}</p>
    <Ads mdxType="Ads" />
    <Image alt="A self-insert Pokemon meme never fails to make me laugh" caption="What will Trainer N do? Throw a HumanBall? Fight? Run?" name="boyfriend-of-the-dead-webtoon-comic-panel-3-geeknabe.jpg" mdxType="Image" />
    <p>{`Each chapter is so `}<strong parentName="p">{`fun `}</strong>{`to read. I was laughing from Chapter 1 to the latest chapter! It’s as if the author wanted to draw this webtoon for the sake of memes and pop culture jokes. The art `}<em parentName="p">{`is `}</em>{`terrible in the beginning chapters and it `}<em parentName="p">{`still is`}</em>{` in the latest chapters, but the joke delivery makes up for it. It’s all comedy at first, but the latter chapters get into the heart of the plot. Just who did humanity in? How did the zombies retain their consciousness but not their memory from when they are still human? Can a zombie and a human be a real couple?! Find out more in `}<RemoteLink to="https://www.webtoons.com/en/comedy/boyfriend-of-the-dead/list?title_no=1102&page=1" mdxType="RemoteLink">{`Boyfriend of The Dead`}</RemoteLink>{`!`}</p>
    <h2>{`Ongoing WEBTOONS Recommendation #5: Space Boy`}</h2>
    <p>{`Oh man, I love this series a lot! If you enjoy works that teaches you to look beyond the outer facade for the good in people, then this is for you. There are two character of importance in Space Boy, Amy and Oliver. I like to think both as the protagonists since they carry so much weight in the plot but one can argue that Amy is the protagonist while Oliver is the deuteragonist.`}</p>
    <Image alt="Space Boy webtoon banner" caption="Space Boy's banner from Webtoon's official website." name="space-boy-webtoon-banner-geeknabe.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <p>{`Space Boy is set in an age where space colonies exist. Amy is a 16 year old schoolgirl that has lived all her life in a mining colony out in space with her family. She only knew of a starlit sky instead of a blue one. She has a best friend named Jemmah and a loving family in the colony. Everything was going swimmingly until one day, a costly accident happened during her father’s shift. It wasn’t her father’s fault at all, but the company had to shift the blame. Amy’s father got fired from his job and the mining company insists that they leave the colony for Earth. But the trip back to Earth takes `}<strong parentName="p">{`30 years`}</strong>{`! Passengers would need to be put in suspended animation for the trip. So Amy bade goodbye to her friends knowing that the next time they meet, her friends would be 30 years older than her.`}</p>
    <Image alt="Amy arguing with her mum in the beginning chapters" caption="This is really heartbreaking to see. It's not as if Amy refuse to see her best friend. She just couldn't accept that 30 years has passed and yet she's still 16 years old deep down. Plus, Jemmah even had a kid!" name="space-boy-webtoon-comic-panel-1-geeknabe.jpg" mdxType="Image" />
    <p>{`Upon reaching Earth, she struggled to match with everyone’s pace. On this planet, the sky is blue. Movies and music that Amy knew are all `}<em parentName="p">{`outdated `}</em>{`by now. Folks here wear net gear, a silly looking pair of glasses that does wonders when connected to the Internet. Things are `}<em parentName="p">{`different `}</em>{`and Amy isn’t used to this. Nevertheless, it’s a new life now. She `}<strong parentName="p">{`has `}</strong>{`to get used to Earth.`}</p>
    <p>{`Amy’s next challenge is to go to school and make new friends. It was there that she met Oliver, a strange boy who barely spoke at all. You see, Amy is special. She can see each person’s character as colours but she dubbed this as `}<em parentName="p">{`a person’s flavour.`}</em>{` Here’s an explanation of Amy’s condition, `}<RemoteLink to="https://en.wikipedia.org/wiki/Synesthesia" mdxType="RemoteLink">{`synesthesia`}</RemoteLink>{`. While she can see everyone’s flavour, she just couldn’t see Oliver’s. This piqued her interest in the boy, leading her to uncover some of space’s deepest secrets.If you enjoy the little summary that I did, then this read is definitely up your alley. `}<RemoteLink to="https://www.webtoons.com/en/sf/space-boy/list?title_no=400&page=1" mdxType="RemoteLink">{`Space Boy`}</RemoteLink>{` has some of the most delightful art depicting Amy’s thoughts.`}</p>
    <Ads mdxType="Ads" /> 
    <Image alt="Space Boy panel example. Seriously read this gem." caption="On her way to her new home, Amy is restless. She couldn't sleep until weariness overtook her. I really loved the art depicted here." name="space-boy-webtoon-comic-panel-2-geeknabe.jpg" mdxType="Image" />
    <Image alt="Space Boy panel example 2! READ. THIS. NOW." caption="Here, Amy got a makeup session from her new friend. She wasn't sure of the idea at first but she relented in the end. Her thoughts separated into each panel makes for an interesting flow as you scroll to read the chapter." name="space-boy-webtoon-comic-panel-3-geeknabe.jpg" mdxType="Image" />
    <h2>{`All in all`}</h2>
    <p>{`So here's my first 5 webtoon recommendations. What do you think? I sneakily left links in my write up so you can go read them ASAP once you're done with each summary. All of these titles can be found for free on `}<RemoteLink to="https://www.webtoons.com/en/" mdxType="RemoteLink">{`Webtoon`}</RemoteLink>{`! I have another 10 webtoons to write (maybe more as I browse WEBTOON's catalogue!) so please look forward to Part 2! Thank you for reading.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      