import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const LocalLink = makeShortcode("LocalLink");
const Ads = makeShortcode("Ads");
const RemoteLink = makeShortcode("RemoteLink");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Re:Zero has this special spot in my isekai ranking where I dub it as `}<em parentName="p">{`"wonderful story, but I refuse to read the light novels"`}</em>{`. I think I'll just catch the next anime season when they decide to air it. Why? Well, the story is still ongoing and there's (at least) 29 volumes released in Japan so far. That's... not going to bode well for my wallet. So instead of buying `}<strong parentName="p">{`ANOTHER`}</strong>{` set of light novels (I think committing to getting the complete set of `}<LocalLink to="/blog/ascendance-of-a-bookworm-light-novel-review/" mdxType="LocalLink">{`Ascendance of a Bookworm light novels`}</LocalLink>{` is the most I'm willing to go for), I decided to dip into getting figurines instead.`}</p>
    <p>{`If you look deep enough, you'll eventually find figurines that are super affordable and lower than the market price! Woo! I managed to get this lovely Beatrice figurine at a good price (RM69/USD16) because no one wants a figurine in a dented box. Lucky for me!`}</p>
    <p>{`I could have settled for a Rem figurine, but Beatrice has a special seat in my character ranking list after watching S2. Amongst the many characters in Re:Zero, her backstory is the one that moved my heart the most. This wasn't the case before S2 though. Re:Zero's S1 is memorable to me due to how they portrayed Subaru. Anyone would be pushed to the brink too if they couldn't confide in anyone but themselves. I initially thought one way or another, the author would write in Emilia as Subaru's No. 1 ally despite his secrecy. Strange enough, it was Rem who took that spot.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Perhaps it was thanks to this that Rem has a lot of fans. I swear, the amount of Rem figurines with different outfits is potentially more than Hatsune Miku figurines. So far, I only found 4 Beatrice figurine - `}<RemoteLink to="https://www.goodsmile.info/en/product/6917/Nendoroid+Beatrice.html" mdxType="RemoteLink">{`Beatrice Nendoroid`}</RemoteLink>{`, `}<RemoteLink to="https://www.banpresto.jp/prize/detail/0016864.html" mdxType="RemoteLink">{`Beatrice Ichiban Kuji To Be Continued Ver.`}</RemoteLink>{`, `}<RemoteLink to="https://myfigurecollection.net/item/775136" mdxType="RemoteLink">{`Beatrice Dragon Dress Ver.`}</RemoteLink>{` (which I am covering in this blog post) and `}<RemoteLink to="https://ninoma.com/products/ichiban-kuji-re-zero-starting-life-in-another-world-c-prize-beatrice-big-hoodie-ver" mdxType="RemoteLink">{`Beatrice Ichiban Kuji Big Hoodie Ver.`}</RemoteLink>{` Maybe there are more Beatrice figurine out there that I am not aware of, but here are the ones I manage to find in my local online shopping platform. Strange how few figurines she got... I thought she's one of the popular ones. Nevertheless, I'm glad I manage to snag a Beatrice figure at a cheap price! Enjoy the review!`}</p>
    <p>{`This is the first SEGA premium figurine in my collection, so I wasn't sure how's the quality like. I wasn't expecting a super HQ figure at all given the price, so I was pleasantly surprised at how the figurine holds up. I look at three things when judging a figurine's quality, namely:`}</p>
    <ol>
      <li parentName="ol">{`The hair.`}</li>
      <li parentName="ol">{`The face.`}</li>
      <li parentName="ol">{`The design's detail.`}</li>
    </ol>
    <p>{`You might think, "Hey, isn't that everything then?" Now hold your horses and let me explain...`}</p>
    <p><em parentName="p">{`Note: This is purely my own opinion, as befitting the sole reason behind "writing a blog post". I only tweaked the brightness and the contrast of the photos a teeny weeny bit, so it looks presentable for the blog.`}</em></p>
    <Ads mdxType="Ads" />
    <h2>{`Figurine Quality Indicator 1: The Hair`}</h2>
    <p>{`Anime characters have wild hair, you see. Just look at Yugi from Yu-gi-oh. Dude has some of the craziest hairdo! Just thinking of the hair gel he needs to style it up scares my wallet into sealing itself tightly. The hair design is one of the most defining traits of an anime character, thus it makes sense that I'll be extra judgmental when it comes to my favourite character's figurine. The hair `}<strong parentName="p">{`MUST`}</strong>{` be perfect, especially the hair tips and hair locks.`}</p>
    <p>{`My Beatrice's Dragon Dress Ver. figurine hair honestly looks pretty good, except the drills, unfortunately. You could tell how they assembled the drills if you look at it from the back. In a way, I'm thankful the attached area can be seen from the back view only since most of us will probably look at the figurine from the front.`}</p>
    <Image name="rezero-beatrice-dragon-dress-ver-2.png" caption="Notice her drills? ): It's probably unavoidable, but I wish they could seal the edges nicely." alt="Re:Zero Beatrice Dragon Dress Ver." mdxType="Image" />
    <p>{`My figurine has this "chalky" effect at the tip of her hair locks. I am not sure if this is an intended effect or a mass production error. Regardless, I'm glad it's not that obvious unless you zoom in on the hair.`}</p>
    <Image name="rezero-beatrice-dragon-dress-ver.png" caption="Notice the end of her hair locks? It has this spotty paint on them." alt="Re:Zero Beatrice Dragon Dress Ver." mdxType="Image" />
    <p>{`All in all, her hair details are acceptable to me. For that price, you get what you get ¯`}{`\\`}{`_`}{`(ツ)`}{`_`}{`/¯`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Figurine Quality Indicator 2: The Face`}</h2>
    <p>{`Specifically, the eyes, followed by how her face looked like from every angle. My point being... If I buy a Beatrice figurine, I expect it to look like Beatrice from the anime if seen in `}<em parentName="p">{`any angle`}</em>{`.`}</p>
    <p>{`One of the main reasons I bought this figurine is because of this particular figurine's choice of expression. Beatrice, to me, is a haughty character with an ever pouty face. With that expression, you could tell that it's Beatrice from afar.`}</p>
    <p>{`It helps that her eyes are easy to recognize from a single glance. If the hairdo is the most defining trait of an anime character, then the eyes is second in the list. Honestly, I love her expression and her eyes so much. This is Beatrice, all right!`}</p>
    <Image name="rezero-beatrice-dragon-dress-ver-3.png" caption="Full view of Beatrice. I hope it looks good on mobile!" alt="Re:Zero Beatrice Dragon Dress Ver." mdxType="Image" />
    <h2>{`Figurine Quality Indicator 3: The Design's Detail`}</h2>
    <p>{`I'm cool with any figurine with a special outfit. But if it didn't look like what's on the box, I'll be sorely disappointed. I didn't really mention it in my `}<LocalLink to="/blog/taito-hatsune-miku-2021-birthday-happy-cat-ver-review/" mdxType="LocalLink">{`Hatsune Miku figurine blog post`}</LocalLink>{`, but I'm not exactly happy with the figurine's detail. On the box, the finish looks all good and clean. After the unboxing? Uhh, it doesn't look like the one on the box ):`}</p>
    <Ads mdxType="Ads" />
    <p>{`This Beatrice figurine on the other hand, is the exact copy of what I saw on the box. The ribbon edges are well-defined, the dragon dress's details are what you see on the box and her small crown on her head. Mm, cute! Her dress's hue is less vibrant than what you'd expect, but if you take it all in, the figurine is 100% the same as it is on the box. Wonderful details all in all.`}</p>
    <h2>{`All in all`}</h2>
    <p>{`Another thing I love about this figurine is the baseplate. It's pink as befitting Beatrice's color theme with Re:Zero's title. It's the little things that makes a figurine whole.`}</p>
    <Image name="rezero-beatrice-dragon-dress-ver-4.png" caption="The baseplate is really tall. Is it to compensate for her height?!" alt="Re:Zero Beatrice Dragon Dress Ver." mdxType="Image" />
    <p>{`I was going to get the To Be Continued Ver. because I like her dress from the show. Then I discovered the Dragon Dress Ver. and changed my mind! I realize how important it is for the figurine to have a unique pose and outfit design to catch your attention. I'm not super fond of a specially designed outfit for a character that I have already associated with their OG outfit, but this dragon dress gets a pass!`}</p>
    <Ads mdxType="Ads" />
    <p>{`It's all thanks to the addition of the pink ribbons. If you scrutinize the ribbons' position closely, you'll notice that each ribbon is placed at the same place as her OG dress, including the one on her neck! It's a great design that doesn't feel out of place if you're the type that prefers figurine with their outfit from the anime.`}</p>
    <p>{`Design-wise, this is my favourite figurine so far. A wonderful purchase indeed <3`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      