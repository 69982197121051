import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const RemoteLink = makeShortcode("RemoteLink");
const Ads = makeShortcode("Ads");
const Blockquote = makeShortcode("Blockquote");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`In case you haven’t been following up with the blogging news, there’s an ongoing community event by `}<RemoteLink to="https://twitter.com/The_Moyatorium" mdxType="RemoteLink">{`Moya`}</RemoteLink>{` and `}<RemoteLink to="https://twitter.com/JS_Reviews" mdxType="RemoteLink">{`Jon `}</RemoteLink>{`that ends on November 29th. Every week, Moya will post a set of prompts that you could choose from to talk about. You could reply to her on Twitter within the 140 character limit, or write a blog post (which I have chosen) instead. And the topic for this community event? `}<strong parentName="p">{`Be comfortable with writing controversial topics.`}</strong>{` I joined this event because I would `}<em parentName="p">{`love`}</em>{` to try writing about controversial topics for once. Unfortunately, I do not think I have it in me (which I’ll explain later).`}</p>
    <p>{`Week 1’s prompts are about explaining a controversial topic from an anime/manga/any media that bothers you. I wanted to tackle this prompt but I didn’t think I had enough comments on said topic for a blog post, so I chose one of the Week 2’s prompts instead. I have added it as a quote for everyone to refer to :)`}</p>
    <Ads mdxType="Ads" />
    <Blockquote mdxType="Blockquote">
  When discussing subjects that are difficult or controversial, how do you
  strike a balance between presenting a fair picture of the issue and staying
  true to your views? Has your view ever changed as you wrote?
    </Blockquote>
    <p>{`On the topic of writing controversial, it is hard to strike a balance because first of all, your initial approach has already started off as biased. You have an opinion, and you wish to elaborate on it. That alone has skewed your writing towards a certain conclusion.`}</p>
    <p>{`When writing an opinion-based post, it has always been up to the way you present your points. I have read plenty of blog posts on certain issues in anime and in tech. Everyone has something to say, no doubt. While everyone has a dissimilar approach to things but all writers has this in common when writing about a controversy - said topic didn’t `}<em parentName="p">{`sit well`}</em>{` with them.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Long story short, it is your `}<strong parentName="p">{`feelings on the topic`}</strong>{`. You didn’t like it, so you wrote a piece on it to justify how you feel about it. It’s definitely tough to write a piece to explain your `}<em parentName="p">{`feelings`}</em>{` on the topic, especially when the disagreeable part of it comes solely from your preferences/morals. I mean, I can’t exactly write `}<em parentName="p">{`“No reason, I just don’t like it.”`}</em>{` on a controversial topic. It’s part of the reason I didn’t want to tackle Week 1’s prompt to talk about any controversial topics in anime/manga. In all honesty, I have `}<strong parentName="p">{`plenty`}</strong>{` of scenes in anime I wish to point out. For example, Danmachi S2’s scene where Bell was chased by Ishtar Familia.`}</p>
    <Image alt="Bell Cranel chased by the Ishtar Familia in Danmachi S2" caption="Folks find this funny but I disagree. It's creepy ):" name="bell-cranel-chased-by-ishtar-familia.jpg" mdxType="Image" />
    <p>{`It felt really uncomfortable to me because I’m a strong believer that both sexes should be treated fairly. If it shouldn’t be done to the ladies, then it should be the same to the men as well. Watching the entire chase scene in the anime, a media that would be watched by many folks from different ages will reinforce the idea that`}<em parentName="p">{` “this is okay”`}</em>{`. `}<strong parentName="p">{`No, it’s not okay.`}</strong>{` If it is meant to be a joke, then it should be quickly brushed off as one. However, the scene lasted a whole episode! The older viewers could differentiate fiction from reality, but what about the younger kids?`}</p>
    <Ads mdxType="Ads" />
    <p>{`The paragraph above should give you a sense the type of writing I’ll do when it comes to controversial topics. I rely a lot on my `}<em parentName="p">{`feelings`}</em>{` on said issue. The major takeaway from my writing at the end of it will just be `}<em parentName="p">{`“I didn’t like this at all”`}</em>{`. Not only does that give zero value to my blog, it didn’t feel good writing and publishing it on my blog for everyone to see. I try to write controversial topics only if I have a factual or an established statement of it, which finally brings us to answering the prompt.`}</p>
    <p>{`How do you be `}<strong parentName="p">{`fair`}</strong>{` when talking about a controversial issue? First, you need to approach the topic from a `}<em parentName="p">{`factual,`}</em>{` a `}<em parentName="p">{`morality`}</em>{` or a `}<em parentName="p">{`personal experience`}</em>{` perspective, then use that to tie in with your personal thoughts on the issue. Why? Well, it’s because these are the fundamental topics that everyone `}<em parentName="p">{`agrees`}</em>{` with. They need context before they could start choosing Yes or No. `}<strong parentName="p">{`Ironic`}</strong>{`, isn’t it? In order to write a fair post on a controversial topic, you must first find an established ground to talk from. For example, what is your context? If you’re writing about an issue you’re disagreeing with due to something personal, then elaborate on where you’re coming from. You’ll find that everything afterwards will flow naturally once you have a singular point to start from. Readers would start to see things from your perspective. Of course, whether they disagree or not is entirely up to them.`}</p>
    <Ads mdxType="Ads" />
    <p>{`I rarely change my mind as I write. I only start writing if I know what my end goal is. True, the art of writing is having a conversation with yourself. It forces you to review things again as you try to explain your points. This is true when writing about topics that require critical thinking. Unfortunately, my feelings on the controversial topic are always stronger than my logical mind.`}</p>
    <h2>{`All in all`}</h2>
    <p>{`That’s my submission for Controversed Week 2! I’ll link the `}<RemoteLink to="https://moyatorium.wordpress.com/2020/11/02/announcing-controversed-a-november-workshop/" mdxType="RemoteLink">{`Controversed event information here`}</RemoteLink>{` so I hope anyone who’s interested after reading my post could join this community event.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      