import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const LocalLink = makeShortcode("LocalLink");
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const RemoteLink = makeShortcode("RemoteLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`After the disaster that was the Nyko Thin Case for the Nintendo Switch (you can read the review I did of it `}<LocalLink to="/blog/nyko-thin-case-nintendo-switch-review/" mdxType="LocalLink">{`here`}</LocalLink>{` - p.s I hate it almost as bad as I hate COVID please don’t buy it), I scoured the internet for new clothes to adorn my beautiful Disney Tsum Tsum Edition Switch which was now...ruined thanks to the Nyko Thin Case. Anyway, I don’t hold grudges, I only destroyed the Nyko Thin Case I owned and cursed it to hell.`}</p>
    <Ads mdxType="Ads" />
    <p>{`I had a few requirements for what I wanted in a Nintendo Switch case;`}</p>
    <ol>
      <li parentName="ol">{`Dockable`}</li>
      <li parentName="ol">{`Easy to remove joy-con covers (I like to regularly remove the joy-con covers to clean the most used part of my console)`}</li>
      <li parentName="ol">{`Not Nyko`}</li>
      <li parentName="ol">{`And most importantly, it had to be transparent (I didn’t shell out extra money to buy a limited edition switch just to have the decals covered now, did I? XD)`}</li>
    </ol>
    <p>{`I had narrowed it down to the `}<strong parentName="p">{`GuliKit Transparent Protective Case`}</strong>{` and some brandless one, the differences were;`}</p>
    <ol>
      <li parentName="ol">{`While the GuliKit’s joycon cover is easy to remove; it is open faced. Meaning all your buttons and thumbsticks are exposed to...grime, finger prints, oil stains and everything else. But it had little notches at the bottom to make it easier to grip.`}</li>
      <li parentName="ol">{`The brandless one also had easy to remove covers AND a separate plastic piece to protect your buttons and whatnot. And this is cheaper! XD`}</li>
      <li parentName="ol">{`The GuliKit one may not fit in my current switch carrier; The Tomtoc Slim case due to the extra notches at the bottom.`}</li>
    </ol>
    <p>{`So...I was really apprehensive about getting the GuliKit one because of the open faced joy-con covers, but then a friend of mine, who runs a small Nintendo Switch game and accessories online shop was having a mid-year sale to clear stock. And by pure coincidence, had the Gulikit Transparent Protective Case on sale! I, being a good friend and wanting to support small businesses during these hard times, completed the transaction within 5 minutes.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Now if you read my `}<LocalLink to="/blog/nyko-thin-case-nintendo-switch-review/" mdxType="LocalLink">{`review of the Nyko Thin Case`}</LocalLink>{`, you’d notice that I HATE oil stains and what have you on my precious baby console. However, in this case, I willingly purchased the Gulikit case with a clear and open mind, that the open face joy-con covers will be prone to oil stains and everything else. (also, I just wanted an excuse to press the joycon buttons without protection XD the clickiness is on a whole different level! It feels good and satisfying XD)So my friend, being the ever efficient seller, shipped my new purchase the same day and I received it the very next day. Thus, I present to you, the `}<strong parentName="p">{`GuliKit Transparent Protective Case`}</strong>{`!`}</p>
    <Image alt="GuliKit Protective Case for Nintendo Switch 1" caption="Please pardon the reflection and my stubby baby sized hands XD" name="gulikit-protective-case-nintendo-switch-photo-1.jpg" mdxType="Image" />
    <p>{`The installation was SUPER EASY! And even though it's easy to install, it’s quite sturdy! Removing it for cleaning was a cinch too, but again, the plastic feels sturdy and thick, yet thin enough to dock without forcing it down the dock. It’s a touch tight but there was no force needed. But more importantly…!`}</p>
    <Image alt="GuliKit Protective Case for Nintendo Switch 2" caption="Please pardon the dust, I swear I juuust took it out of the box and even wiped it and my switch!...or...it could be the little tiny fibers from the wet wipe.." name="gulikit-protective-case-nintendo-switch-photo-2.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <p>{`THE BACK SIDE IS FULLY COVERED! Except for the kickstand and volume and charging hole etc etc. UNLIKE A `}<LocalLink to="/blog/nyko-thin-case-nintendo-switch-review/" mdxType="LocalLink">{`CERTAIN`}</LocalLink>{`, POORLY DESIGNED CASE, there are no weird openings or gaps! The opening for the kickstand is well...uhh..secured? Covered? Sealed? There are slightly lowered grooves for your fingers to grip I guess? But it’s not an actual cutout. And the notches at the bottom work well even with small hands. It’s not awkwardly placed and it's rather comfortable. The plastic feels nice and solid and I do feel a little bit more at ease dressing my switch in this. Best of all; IT FITS IN THE `}<LocalLink to="/blog/get-the-tomtoc-slim-case/" mdxType="LocalLink">{`TOMTOC SLIM CASE`}</LocalLink>{` TOO!`}</p>
    <h2>{`Conclusion for GuliKit Protective Case`}</h2>
    <p>{`Would I recommend this? Yes I would. But do keep in mind the open faced joy-con covers and the notches at the bottom, since some cases are slimmer than others. Also, check out nabe-chan’s review of the`}<LocalLink to="/blog/gulikit-route-air-review/" mdxType="LocalLink">{` GuliKit Route Air`}</LocalLink>{` since it’s from the same company!! :DD`}</p>
    <h2>{`Sanrio Characters Hand-bag Pouch by Hori Review`}</h2>
    <p>{`I bought ALL my Nintendo Switch Accessories before I even bought my actual Nintendo Switch console because I’m that person who wants everything ready when my new baby comes. You know, like parents who decorate the baby nursery before the baby is born XDMy main, and only switch carrying case is the Tomtoc `}<LocalLink to="/blog/get-the-tomtoc-slim-case/" mdxType="LocalLink">{`Slim Case in Pink`}</LocalLink>{`. And honestly, you really only need ONE case for the entirety of your switch’s life unless it breaks or something. I’ve added many pouches and cases to my cart only for me to delete them a day later because I. Don’t. Need. It.While Hori has consistently been releasing multiple themed accessories (the Animal Crossing ones sell out like hotcakes), I fell in love at first sight with this Sanrio themed one ever since I saw a preview of it in early 2021!!`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Hori Sanrio Pouch" caption="Look at the patterns! It's so cute!" name="hori-sanrio-pouch-nintendo-switch.jpg" mdxType="Image" />
    <p>{`I’ve always, always been a fan of Sanrio ever since I was as big as a corgi and Hello Kitty will forever hold a special place in my heart. So I added this to cart, but I just could not delete like I did with the others, for MONTHS. Yes this sat in my cart for at least 3 months because...I..I LOVE IT. And so my opportunity came when I had some vouchers that were expiring and I thought..you know what?`}</p>
    <Image alt="Hori Sanrio Pouch box" caption="Tis' but a secret indulgent purchase... (nabe-chan: I'm the one editing/proofreading all of your blog posts and fanfic chapters. You think this small caption will escape me?!)" name="hori-sanrio-pouch-nintendo-switch-box.jpg" mdxType="Image" />
    <p>{`I actually had to secretly buy this because I got told off by nabe-chan the first time I wanted to purchase it, ‘YOU ALREADY HAVE A SWITCH CASE! STOP WASTING MONEY! AND IT’S WHITE! IT’LL GET DIRTY FAST! `}<em parentName="p">{`smacks butt`}</em>{`’. It’s not very noticeable but even on screen, the white isn’t pure white, it’s like a...cream-ish, off white colour. Which is a good thing in a way because if and when it does turn yellow, it’s ‘part of the design’ so to speak XD -`}{`>`}{`  desperately trying to justify my purchase XD`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Hori Sanrio Pouch unboxed" caption="Looooook at the cute hello kitty ribbon/music note zipper ughhh my heart! I love pink and blue and this blue is so sweet to look at!" name="hori-sanrio-pouch-nintendo-switch-unboxed.jpg" mdxType="Image" />
    <p>{`The material is a...uhh...like a...faux letter...slightly stiff fabric material? Gosh I’m terrible at this so I’ll let the pictures speak for itself, you’ll know what I’m talking about XD`}</p>
    <Image alt="Hori Sanrio Pouch back view" caption="The back view" name="hori-sanrio-pouch-nintendo-switch-front.jpg" mdxType="Image" />
    <Image alt="Hori Sanrio unzipped" caption="Hori Sanrio pouch innards... Insides... Uhm yea." name="hori-sanrio-pouch-nintendo-switch-inside.jpg" mdxType="Image" />
    <Image alt="Hori Sanrio with Nintendo Switch inside" caption="Hori Sanrio with Nintendo Switch inside" name="hori-sanrio-pouch-nintendo-switch-with-nintendo-switch.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <Image alt="Hori Sanrio Pouch size comparison" caption="Hori Sanrio Pouch size comparison with a Nintendo Switch" name="hori-sanrio-pouch-nintendo-switch-size-comparison.jpg" mdxType="Image" />
    <p>{`The inner pouch is made of felt and has an inner pocket if you have a switch lite or maybe some earphones/bud or you can get away with slightly thicker wires. I have the Gulikit Transparent Protective Case on (it has extra notches at the bottom) While I didn’t really have to shove it in, but I did have to push it in a little, still, it zips up just fine. I should think if you have Switch Covers/Cases roughly the same width and size as the Gulikit one, it will fit.  It even has a little keyring hook thingy at the side if you wanna attach keychains, lanyards or wristlets!`}</p>
    <p>{`However, as much as I love this to bits, and I have to admit...it’s not as sturdy as the Tomtoc or the more harder and stiffer cases from other brands. If you dropped your switch in this pouch, you would feel a little bit of pain XD I also wouldn’t throw this in your bag with heavy books or water bottles clanking around in your bag if you want to take this out and about. Maybe if your bag only had a wallet, phone, some keys and tissue? Even though it would be easier to clean(with a damp cloth or my personal favourite; alcohol swabs) the other thing I’m not too sure about is the integrity of the....the uh...material. I’m slightly afraid of it flaking off and stuff. But...we’ll see. I have faith.`}</p>
    <Ads mdxType="Ads" />
    <p>{`I’m still gonna use my trusty, sturdy and now greyed-pink Tomtoc Slim Case because my switch feels more protected in it, although it's...so...hard...to...clean! They recommend you wipe it with a damp cloth...mmm yeah. It’s still...well it still has this gray layer over it. Anyway, I actually put my new Hori Sanrio pouch back inside its packaging to protect it from the elements and aging, since this is a soft pouch and I prefer a more solid case to protect my baby. This was more a...fun buy than a necessity, something I bought myself for my birthday with the extra bit of money I saved up. The one thing I like about it more than the Tomtoc one though, is that it’s a pouch right? That means it’s versatile! You can actually use it as a pencil case, makeup pouch, medicine bag or even an all in one purse/wallet! (I actually plan to at some point XD)`}</p>
    <h2>{`Conclusion for Hori Sanrio Pouch`}</h2>
    <p>{`Would I recommend this? Mmmmmm, maybe if you didn’t already own a carrying case, or you want something a little bit more flexible and less rigid then, yes. Of course, Hori has other stuff in this Sanrio Themed line up! I bought this because I already have a hard case. I also chose the pouch because the design is on the front AND the back! The hard, `}<RemoteLink to="https://www.play-asia.com/hybrid-pouch-for-nintendo-switch-sanrio-characters/13/70e89d" mdxType="RemoteLink">{`hybrid pouch`}</RemoteLink>{` only has the design on the front :( I got mine from a local seller, but I think `}<RemoteLink to="https://www.play-asia.com/sanrio-characters-hand-bag-pouch-for-nintendo-switch-switch-lit/13/70e89b" mdxType="RemoteLink">{`Play-Asia`}</RemoteLink>{` should still have it and other designs and accessories from Hori!Aaaaand, that’s all folks! Thank you for reading! Please feel free to ask me any questions about these purchases! Muah! Muah~!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      