import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const LocalLink = makeShortcode("LocalLink");
const Image = makeShortcode("Image");
const Ads = makeShortcode("Ads");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Everyone’s doing it, so I think I better hop to it! Geeknabe started operating in 2019 and it’s going stronger than ever as we start the year 2021! To illustrate our ever increasing strength, I have included two images below. Made by yours truly with Canva <3 I wrote a `}<LocalLink to="/blog/two-useful-blogging-tools-i-wish-to-share/" mdxType="LocalLink">{`blog post about using Canva and Keyword Surfer`}</LocalLink>{` to help beef up your blog stats, please check it out.`}</p>
    <Image alt="STRONK NABE-CHAN" caption="When we’re talking about strength, we’re clearly talking about MUSCLES! KINNIKU!" name="strong-nabechan-geeknabe.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <Image alt="SWEET AND SEXY MARI-CHAN" caption="I don’t have the heart to add muscles to mari-chan, my co-writer. So I’ll just slap on her cute mascot onto Anne Hathaway. We both love the lady, she’s classy. mari-chan really loves sexy dresses so I hope this edit is up to her standards." name="sexy-mari-chan-geeknabe.jpg" mdxType="Image" />
    <p>{`When it comes to BLOG! STRONK!, we’re clearly talking about page views as well as our growing blogging circle. mari-chan has been chugging a ton of fanfiction to satisfy her imagination. We both had a ton of fun deciding how the story should flow. But what makes it all the better? Reading everyone’s comment on how much they love the story. She has a few more ideas that she wishes to explore but it takes time. Please be patient if you’re waiting for more Dimileth fanfics! I’ve been cajoling her to tackle other couples, like Asheleth (I personally love this one.) but tall blonde men are clearly her type. How did I figure out her type? Well… She told me how much she loved the colour blonde, she actually went and dyed her hair blonde!`}</p>
    <p>{`As for me, I am planning to write even more blog posts that tickle everyone’s fancy. More lists obviously! And I wish to write more blog posts that touch on other subjects such as music or tech. I’ve been ignoring them a lot so I hope to find more inspiration and motivation to write them down. I don’t want to bore you with the minor details, so let me summarize all of my personal aspirations for my blog as points.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Blog Aspiration #1: Double the page views`}</h2>
    <p>{`I think this is a no-brainer aspiration. Every blogger loves numbers. The higher the better! The page likes/views are like candy for the brain. You don’t say it out loud but everyone knew more page views are always welcomed <3 Last year, my page views plateau-ed so I’m hoping to have it keep increasing! I noticed that they kinda tie to the amount of blog posts you publish so let’s keep at it!`}</p>
    <h2>{`Blog Aspiration #2: Reach 200 blog posts`}</h2>
    <p>{`Last year, I told myself that I should write 100 blog posts a year. I uh, technically wrote less than that since some posts were carried forward from 2019. However! I’m feeling good about this year! I used to keep a pace of 8 blog posts a month but now, I can do 10 blog posts a month. I think I’m at a good pace now that I could write even faster… Obviously, not as fast as Irina or Karandi!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Blog Aspiration #3: Write more tech posts`}</h2>
    <p>{`This is kinda tied to my career. Part of my wish when I did this blog post is to improve my writing and explaining things. It takes time to understand a subject but it takes experience to explain that subject to someone else!`}</p>
    <p>{`Plus, maintaining a blog is actually great to show on my resume. It shows that I have a project under my belt that is a solid proof that I know a bit of SEO, hosting, digital marketing and writing. I know some companies actually look for developers who have a hobby related to their work. At the very least, this is a good sign to them that you’re in this field for a long time. Dear potential future boss, please look at the tech section and not the anime section! I… I don’t know how to explain the anime section to you, but I could definitely explain the tech section!`}</p>
    <h2>{`Blog Aspiration #4: Be true to myself when it comes to picking which blog posts to publish`}</h2>
    <p>{`You know, I actually asked mari-chan to check on most of my blog posts with a controversial title. I often ask questions like, “Is this appropriate?” or “Is this written with the vibe I wish to give out?”. They say you can’t see certain things from your own perspective and they are right. Mari-chan helped a lot in telling me the things I missed. It’s a trend I wish to continue this year but this time, I want to write blog posts that may be something unexpected from my image. You see… This hotpot is not what everyone thinks she is! What kind of blog posts will I write now that I wish to discard this image?! Well, you’ll need to wait to find out! … Maybe I won’t. Because I’m kinda shy…`}</p>
    <Ads mdxType="Ads" />
    <h2>{`All in all`}</h2>
    <p>{`Let’s top the previous year with a good year! To everyone with a blog, all the best to you too!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      