import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const RemoteLink = makeShortcode("RemoteLink");
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const SpoilerTag = makeShortcode("SpoilerTag");
const Blockquote = makeShortcode("Blockquote");
const LocalLink = makeShortcode("LocalLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`A single utter of the term `}<em parentName="p">{`isekai`}</em>{` can bring about `}<strong parentName="p">{`two reactions`}</strong>{` from the Anime community. Some folks love it, some folks detest it. I understand the hate sentiment and yes, I agree that most of the isekai series’s beginning can be `}<em parentName="p">{`cliche`}</em>{` and `}<strong parentName="p">{`overused`}</strong>{`. However, many series merely use the opening scene as a pretense to then spin their own unique stories afterward. You can’t catch the readers’ attention unless you hook them in from the beginning after all! You gotta do what you gotta do if you want to beat the rest of the competition. Besides, if you look at anime series from the 2000s, there are even `}<em parentName="p">{`more stories`}</em>{` that involve getting whisked away to a fantasy world! (E.g. Escaflowne) `}<strong parentName="p">{`Isekai`}</strong>{` has been part of the anime cliches since `}<em parentName="p">{`forever`}</em>{`, it’s just not as `}<strong parentName="p">{`pronounced`}</strong>{` before this. Isekai is only getting traction lately due to more publishing studios looking at `}<RemoteLink to="https://ncode.syosetu.com/" mdxType="RemoteLink">{`Syosetu`}</RemoteLink>{` for potential `}<em parentName="p">{`winners`}</em>{`. Syosetu is a platform where many amateur writers post their work for the public readers to enjoy. It’s basically where most popular light novels that we enjoy originate from. If these published stories make it big, then they get the honour of being turned into `}<em parentName="p">{`manga `}</em>{`or an`}<em parentName="p">{` anime`}</em>{`.`}</p>
    <Ads mdxType="Ads" />
    <p>{`The second point that I wish to counter since I saw many making this point is that these isekai series have been popping up like mushrooms recently. Nuh-uh, these stories have been around since ~8 years ago actually. You should think of it as `}<em parentName="p">{`“they’ve been popping up like mushrooms at least 8 years ago”`}</em>{` instead. Just to name a few popular isekai anime you’ve heard of… `}<RemoteLink to="https://ncode.syosetu.com/n8611bv/" mdxType="RemoteLink">{`Arifureta`}</RemoteLink>{` started in 2013, and so did `}<RemoteLink to="https://ncode.syosetu.com/n6316bn/" mdxType="RemoteLink">{`Tensura`}</RemoteLink>{`. `}<RemoteLink to="https://ncode.syosetu.com/n9669bk/" mdxType="RemoteLink">{`Mushoku Tensei`}</RemoteLink>{` and `}<RemoteLink to="https://ncode.syosetu.com/n4402bd/" mdxType="RemoteLink">{`Overlord`}</RemoteLink>{` started in 2012. These three isekai stories are first posted on `}<strong parentName="p">{`Syosetu`}</strong>{` and were kindly picked up by publishing companies afterward. There’s a period of time (the dark ages...) when I’ll read fan-translated web novels religiously `}<strong parentName="p">{`everyday`}</strong>{`. If I finish a series, I’ll go scour the Internet for more. With that in mind, `}<RemoteLink to="https://www.novelupdates.com/" mdxType="RemoteLink">{`novelupdates`}</RemoteLink>{` is a `}<em parentName="p">{`blessing`}</em>{` for crazies like me.`}</p>
    <p>{`All of my web novel discovery couldn’t have happened if not for fan translators. Bless you, good people. Bless you too, J-Novel. I truly enjoyed every isekai web novel I came across, but I consider `}<strong parentName="p">{`these three`}</strong>{` to be the `}<strong parentName="p">{`best`}</strong>{` amongst the rest in terms of character development, and how the author chooses to progress the plot. I do have other favourites but I’ll put it in a different post some day. The list below IS `}<strong parentName="p">{`ORDERED`}</strong>{`!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Personal Top Isekai #3: Tensei Shitara Slime datta ken (That Time I Got Reincarnated as a Slime)`}</h2>
    <p>{`There’s just something really soothing about collecting skills, subordinates and eventually, making a mark upon the world as a `}<em parentName="p">{`true demon lord`}</em>{`. When I first encountered this web novel, I had a tepid first impression about it. There are many other similarly structured stories so Tensura is not exactly unique to me. However, what separates Tensei Shitara Slime datta ken (Tensura) with the rest is that it had a `}<em parentName="p">{`smooth plot progression`}</em>{` from the start. This makes it a compelling read as a soothing slice-of-life Sim with RPG elements. You have this slime that had everything handed to it without having the freebies done too much on the nose. The slime himself makes sassy remarks but has a strong sense of responsibility, shown through how he takes good care of his family. I wouldn’t say Rimuru is a `}<em parentName="p">{`perfect`}</em>{` protagonist, but his character is amiable and done in a way that there’s `}<em parentName="p">{`nothing`}</em>{` to dislike about him. Then his family of ogres, hobgoblins and even demon lords enter the fray! By the time you reach this part of the story, you’re already `}<strong parentName="p">{`invested`}</strong>{` with Rimuru’s adventure in building a place of his own.`}</p>
    <Image alt="Demon lords from Tensei Shitara Slime datta ken!" caption="The demon lords from Tensei Shitara Slime datta ken. It's fine if you don't recognize some of them, they didn't really appear in the story as frequently." name="slime-tensei-1.jpg" mdxType="Image" />
    <p>{`I couldn’t vouch for the published light novels since it’s totally a `}<strong parentName="p">{`different story`}</strong>{` than the one in Syosetu, but I could definitely vouch for the Syosetu one. It’s a good story that has an equally good explanation on how Rimuru’s situation came to be in the end. As for the manga, there’s a main series that is based off the published light novel, along with other spin-offs.`}</p>
    <p>{`The main manga is seriously good stuff, y’all. The art is `}<em parentName="p">{`impeccable`}</em>{`, and the pacing is `}<strong parentName="p">{`splendid`}</strong>{`. I read somewhere that the manga have their own spin on certain scenes despite having it based off the published light novel. I find the spin-offs not too shabby in their own right. The author knew what makes this series appealing to their readers and thus, these spin-offs are created to feed our need to see more of Rimuru’s slide-of-life adventures. It's a very smart business decision.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Now about the anime… It’s `}<strong parentName="p">{`brilliantly done.`}</strong>{` I’m telling ya, the studio didn’t need to go the extra mile but `}<em parentName="p">{`they did it anyway`}</em>{`. Firstly, I’m talking about the graphics you see when talking to his skills. Rimuru was shown to be simply talking to his skill like a thought bubble in both the light novel and manga. I personally think it’s fine if they emulate this in the anime too, but the graphics are a nice change of pace! Secondly, Rimuru from the manga and light novel didn’t emote as freely as he did in the anime. `}<em parentName="p">{`It’s an anime-exclusive!`}</em>{` I really enjoyed both of these inclusion from the studio. It made the anime a really fun watch! Then there’s how they handled Shizu’s arc. If not for the changes the studio did, I'm telling ya... It wouldn’t hit as hard as you’d expect. Season 2 is on their way and from how they ended in S1, we’re in for a good show :D Gosh, I'm excited!`}</p>
    <Image alt="NANI??!" caption="Rimuru emoting is one of my favourite parts in the anime. It makes the anime so much livelier!" name="slime-tensei-2.jpg" mdxType="Image" />
    <p>{`Tensura makes it to third place for me due to how pleasant the ride is. There's honestly `}<em parentName="p">{`nothing to dislike`}</em>{` about this story. It kept to the mood, threw in some exciting ones and continued the slice-of-life mood. Once you're invested, you can't stop wanting to read about Rimuru's journey!`}</p>
    <p>{`I was going to place `}<em parentName="p">{`Overlord`}</em>{` or `}<em parentName="p">{`Kumo desu ga nani ka`}</em>{` (So I'm a Spider, so What?) here, but I decided against it. Overlord’s core plot is very different even within their own niche. There are those who love reading Overlord for their intensive worldbuilding, and there are those who love reading how overpowered the whole “`}<em parentName="p">{`good`}</em>{`” cast is. In all honesty, I'd fall into both categories because I really enjoyed the light novels for what it is. As proof, I like it enough to get the English light novels as soon as they were released! I find the `}<em parentName="p">{`Overlord manga a decent recap`}</em>{` of the light novel as a whole but the `}<strong parentName="p">{`anime is`}</strong>{` `}<strong parentName="p">{`shiet`}</strong>{`. Now this is one point I refuse to back down from. S1 is decent, but S2 and S3 didn’t really capture the essence of what made Overlord great. So all in all, Overlord didn’t make it into the list because I find it to be a super niche series.`}</p>
    <Ads mdxType="Ads" />
    <p>{`As for Kumo desu ga nani ka, it’s great but the constant focus on skills improvement can make it feel `}<em parentName="p">{`stiff `}</em>{`in general`}{`*`}{`. I couldn’t discuss what I personally feel about this series as a whole without mentioning spoilers. Here ya go. Click/Tap on it to reveal spoilers. Highlighting it is okay too:`}</p>
    <SpoilerTag mdxType="SpoilerTag">
  Kumo-chan’s progress starts from zero to a hero. That in all is what we
  readers want to see - watching Kumo-chan go from a useless spider to a deadly
  Arachne. However, the series sings a different tune once Kumo-chan reaches
  godhood. The story is still decent right up to the Divine Beast war, but the
  story afterwards is such a stark contrast (despite still giving mention to
  skills and whatnot) to the beginning that I didn’t enjoy it as much. I
  intentionally skipped the side stories that explain the hero that Kumo-chan
  saved because honestly, I’m just here to read about Kumo-chan’s exploit.
  Tensura on the other hand, kept up the flow it had from the beginning. This is
  part of the reason why I think Tensura really is better in a whole sense.
    </SpoilerTag>
    <h2>{`Personal Top Isekai #2: Arifureta Shokugyō de Sekai Saikyō (Arifureta: From Commonplace to World's Strongest)`}</h2>
    <p>{`When it was announced that this series got an anime, I leaped with `}<strong parentName="p">{`joy`}</strong>{`. Then I had to `}<em parentName="p">{`sit back down`}</em>{` because there’s something fundamentally wrong with the released poster… I was squeamish because it didn’t look like the Arifureta series I enjoyed as a web novel. Thankfully, they delayed the anime and redid the whole character art (Phew). I find the anime and manga not too shabby on it’s own. Both had a different charm to it from how they depicted the cast and the plot in general. But all in all, my favourite Arifureta plot is still the `}<strong parentName="p">{`web novel’s`}</strong>{`!`}</p>
    <Image alt="Arifureta's first key visual poster. The anime was delayed and the studio replaced this first key visual with a new one." caption="The titular characters' poses feel shoddy, and their faces... Hmm. Something is wrong, I can't quite put a finger to it. The current poster you see floating around is so much better." name="arifureta-1.jpg" mdxType="Image" />
    <p>{`Arifureta started off with an uncommon starting plot back then - the whole class got `}<RemoteLink to="https://www.tanoshiijapanese.com/dictionary/entry_details.cfm?entry_id=33222" mdxType="RemoteLink">{`shoukan`}</RemoteLink>{` and received specific skills/jobs from their transfer. The black sheep in this story, Hajime was shown to be super resourceful with his own transmutation skill even `}<em parentName="p">{`before`}</em>{` he was thrown into the dungeon. The light novel went deep into detailing the bullies and Kaori’s affection for Hajime. Her own personal thoughts as well as Hajime’s feeling of insecurity were written in-depth too. I have always loved reading up small little details when it comes to these scenes, so the light novel’s length is just `}<strong parentName="p">{`perfect`}</strong>{` for me. This is part of the reason I’m of the impression that the anime and manga didn’t really hit as hard during the scene when our protagonist was dropped into the labyrinth. It went in too fast for me to feel invested which I think, is an `}<em parentName="p">{`inevitable problem.`}</em></p>
    <Ads mdxType="Ads" />
    <p>{`His suffering that triggered his slow descent to madness felt like a smooth (as f`}{`*`}{`ck) progression. The light novel took the time to tell us a story about Hajime. It gave us time to sympathize with our protagonist, and to let the severity of the Great Labyrinths sink in. His expertise in using his transmutation skills isn’t gifted to him like a `}<strong parentName="p">{`freebie`}</strong>{`. He molded it through experience and time. `}<em parentName="p">{`It was earned. `}</em>{`While many isekai equate strength with numbers (and places a strong emphasis on them), the series seems happy letting Hajime work his creativity through his transmutation skill. Numbers are important here, `}<em parentName="p">{`but`}</em>{` it only served to tell how powerful his team is on paper. Arifureta prefers to tell the proper numbers visually with lots of explosions, guns and magic. Yes, `}<strong parentName="p">{`guns`}</strong>{`. But the fun in seeing Hajime spinning up new guns, accessories and whatnot is `}<strong parentName="p">{`secondary`}</strong>{`. The `}<em parentName="p">{`real fun`}</em>{` is with how well the author wrote Hajime’s adventure!`}</p>
    <Image alt="Hajime and the rest of his gang from Arifureta" caption="I really enjoyed every scene that involves him shooting or blasting things." name="arifureta-3.jpg" mdxType="Image" />
    <p>{`You have Hajime picking up his lady team members as he goes from point A to B, all done in order to conquer all of the labyrinths. Along the way, we see Hajime developing better relationships with his teammates. They tease each other but still help one another if they are in a pickle. Sure, some may see the intimacy between Hajime and each of the girls as `}<em parentName="p">{`unsettling `}</em>{`(I find it somewhat true since the author did not try to play it off but instead, made effort to highlight it more) but it progressed in a way that soon felt `}<strong parentName="p">{`natural. `}</strong>{`I made peace with this narrative eventually since Hajime truly loved the girls and they love him back for it. I `}<strong parentName="p">{`frankly`}</strong>{` find it a nice complement to the adventure/power levelling section! To be honest, there’s `}<em parentName="p">{`very little reason`}</em>{` to hate a team that cares for each other. Then again, I admit, I have a personal bias in seeing a tight-knit group of friends adventuring together. Regardless! You can’t deny that the short and quick, friendly banters are fun to read. But enough about that, let’s talk about the part that I enjoyed the most in Arifureta - `}<strong parentName="p">{`THE LABYRINTHS.`}</strong></p>
    <p>{`Like, bloody hell. I have never `}<strong parentName="p">{`read`}</strong>{` a dungeon-exploring section so heart-pounding before. Each dungeon is really well-written in terms of the differences and what each treasure entails. The boss fights... No, actually scratch that. `}<strong parentName="p">{`ALL `}</strong>{`of the boss fights are `}<em parentName="p">{`fun and exciting`}</em>{` to read. Teamwork came into play a lot, followed by some really smart scenes in incorporating everyone’s strength. The web novel’s portrayal in each boss fights is lengthy yet it is so `}<em parentName="p">{`satisfying `}</em>{`to read. The way the author managed to gel it all together in a cohesive manner puts this series’s writing `}<em parentName="p">{`on a different scale. `}</em>{`I’m telling yea, it’s crazy. Each chapter is `}<strong parentName="p">{`crazy long`}</strong>{`. You can tell the author put in superb effort to deliver the story of Arifureta. It screams quality for a web novel back then. I enjoyed Tensura, Overlord and many others but damn, the writing for Arifureta is on a `}<strong parentName="p">{`different level`}</strong>{`. The author has a super strong imagination and grasp at writing fighting scenes that I think it deserves second place. `}<em parentName="p">{`However`}</em>{`, while Arifureta has great writing, I find Mushoku Tensei has a `}<em parentName="p">{`bigger and wider`}</em>{` scope when it comes to narrating everything to do about the protagonist. Arifureta is `}<em parentName="p">{`good `}</em>{`but Mushoku is `}<strong parentName="p">{`bigger… `}</strong>{`And it struck closer to heart.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Personal Top Isekai #1: Mushoku Tensei`}</h2>
    <p>{`Phew. Mushoku Tensei... I remember taking in `}<strong parentName="p">{`all of the chapters`}</strong>{` like a`}<em parentName="p">{` drug addict`}</em>{`. I would religiously copy and paste each paragraph into Google Translate if I find the fan translation too slow. I need to know what happens next! The translation by Google (back then) is crude. I couldn’t piece bits of the story fully due to my low understanding of the Japanese language, but I was `}<strong parentName="p">{`satisfied`}</strong>{`. I remember crying after finishing the story...`}</p>
    <Blockquote mdxType="Blockquote">
  That’s how much I love this story. It’s not just a good story to me, it’s
  GREAT.
    </Blockquote>
    <p>{`Mushoku Tensei talks about how a 34 year old nameless NEET was `}<strong parentName="p">{`duped`}</strong>{` into reincarnating in a world of swords and magic as Rudeus. We follow his adventure, his `}<strong parentName="p">{`growth`}</strong>{` and how he overcomes it all by first changing the one thing he could at the moment -`}<em parentName="p">{` his own self`}</em>{`. Both Arifureta and Tensura focus on the protagonist’s strength and are as `}<em parentName="p">{`shounen`}</em>{` as it can get. They do talk about interpersonal relationships but in Mushoku Tensei, it hits… `}<em parentName="p">{`different`}</em>{`. While I applaud Mushoku Tensei’s writing on the magic system, races and it’s worldbuilding, the core plot that really `}<em parentName="p">{`hits home`}</em>{` is how it explored personal growth and interpersonal relationships, especially familial ones! Not only that, we follow Rudeus’s growth from his wee baby hours to his death bed. There are many isekai stories out there that do this, but not many did it as `}<strong parentName="p">{`good`}</strong>{` as Mushoku Tensei.`}</p>
    <Image alt="Rudeus Greyrat and his family from Mushoku Tensei" caption="Rudeus with his family! This is when he's still young, about 10 years old. The story had barely begun..." name="mushoku-tensei-1.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <p>{`Many isekai stories out there quickly jumped into action by showing the already-reincarnated protagonist in the fantasy world from the start. I think that’s a fair take since our world honestly has nothing interesting to talk about but Mushoku Tensei `}<em parentName="p">{`took the time`}</em>{` to further explore our NEET protagonist’s circumstances. He’s a NEET, yes, but he has a strong desire to`}<em parentName="p">{` change for the better`}</em>{`. This was emphasized again and again through the actions he took when facing danger. He would berate himself for choosing the easy way, vow to be a better son for his family, and turn a new leaf in the new world. He taught himself to be `}<em parentName="p">{`responsible`}</em>{`. He learned `}<strong parentName="p">{`grit`}</strong>{`. He was `}<strong parentName="p">{`determined`}</strong>{`. Because he couldn’t in his old world, he prioritizes his `}<em parentName="p">{`family and friends`}</em>{` above all else. This led to the main casts being a tight-knit family not because of their circumstances that led them together but instead, they came together `}<em parentName="p">{`because`}</em>{` of Rudeus’s personality and hard work. He was the `}<em parentName="p">{`pillar `}</em>{`in his family. He made his friends see the light when everything fails. This was such a stark difference to the NEET him because he `}<em parentName="p">{`earnestly worked hard`}</em>{` to `}<strong parentName="p">{`change.`}</strong>{` Everything came together because Rudeus made it `}<em parentName="p">{`happen`}</em>{`. This made the readers feel that everything that Rudeus has is properly `}<em parentName="p">{`earned`}</em>{` and `}<em parentName="p">{`well-deserved`}</em>{`, including his `}<em parentName="p">{`harem.`}</em></p>
    <p>{`Without going into spoilers… Yes, dude has a harem and he got kids! It's a super wholesome way to go about writing about life in another world. I’m not going to spoil who these girls are but I love `}<strong parentName="p">{`each one of his wives`}</strong>{`. The author took care to give each girl their own backstory, personal struggle to whom they truly are and how they came to love Rudeus for who he is. The `}<em parentName="p">{`affection`}</em>{` didn’t happen in an `}<strong parentName="p">{`instant`}</strong>{` but instead, was slowly nurtured with time. This makes the romance feel much more `}<em parentName="p">{`grounded`}</em>{` compared to how Arifureta did it. Plus, the author wrote them `}<strong parentName="p">{`good`}</strong>{`. I really enjoy reading the girls’ backstories and circumstances because for once, they aren’t written as a `}<em parentName="p">{`heroine for the male lead`}</em>{`. They weren’t given a backseat in the story nor thrown to the background as a love interest. The girls are properly written as their `}<strong parentName="p">{`own person`}</strong>{`, capable of making their own decisions and pursuing their goals.`}</p>
    <Ads mdxType="Ads" />
    <Blockquote mdxType="Blockquote">
  Before they were girls, they were their own person. Mushoku Tensei have this
  lovable way of portraying the characters - sensible, tactfully written and
  most times, very human.
    </Blockquote>
    <Image alt="Eris and Roxy from Mushoku Tensei" caption="I love Eris and Roxy. These two are very proactive girls, and won't hesitate to fight for their beliefs." name="mushoku-tensei-2.jpg" mdxType="Image" />
    <p>{`Another part of Mushoku Tensei I really love is how the story talks about families… and how fleeting life can be. Authors love using deaths as a way to move the plot forward but in Mushoku Tensei, it is treated as part of worldbuilding. `}<strong parentName="p">{`People die when they are killed`}</strong>{`, yes, but Mushoku Tensei treated `}<strong parentName="p">{`death`}</strong>{` as `}<em parentName="p">{`inevitable`}</em>{`. Accidents happen and if you’re unlucky, that’s it. Things couldn’t stay the same forever and when a family member passes away, you learn to `}<em parentName="p">{`cope. `}</em>{`You’ll eventually heal with time. The author portrayed this beautifully, along with how Rudeus copes together with his family.`}</p>
    <p>{`If I were to summarize why I place Mushoku Tensei as the first in this writeup, I’d say it’s due to how `}<em parentName="p">{`human`}</em>{` each character is written. People aren’t all black and white. Folks struggle with their circumstances. Different families will have their own trouble. How you deal with it is how you choose to live your life. There are many topics tackled in Mushoku Tensei including racism that I didn’t talk about, but each topic is written in a morally grey-ish area. The author could have chosen to side with an ideology for Mushoku Tensei but he didn’t. Instead, he wrote about Rudeus Greyrat, an ex-NEET who wish to be a better self and cherish his family in his new life.`}</p>
    <p>{`I wrote a quick first impression post on the anime's Episode 1 `}<LocalLink to="/blog/mushoku-tensei-isekai-anime-episode-1-first-impression/" mdxType="LocalLink">{`here`}</LocalLink>{`!`}</p>
    <Image alt="Mushoku Tensei family shot" caption="Mushoku Tensei's LN Volume 13 cover. I love family photos like these... Gosh, I'm welling up. I can't wait for the anime!" name="mushoku-tensei-3.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`All in all`}</h2>
    <p>{`I hope you enjoyed my writeup for this topic! It was a struggle to write this post because I wish to do these titles justice. I plan to revisit older titles including shoujo and traditional shonen titles in other posts soon. Please do follow my Twitter for updates!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      