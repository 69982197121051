import styled, { createGlobalStyle } from 'styled-components';
import { nanoid } from 'nanoid';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import $ from '../styles/globals';
import data from './Navbar/items.json';
import { addKeys } from '../utils';
import atFanfictionChapter from './Navbar/atFanficChapter';

const Container = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border-top: 2px solid ${$.grey1};

  ${$.screen.desktop} {
    width: 1100px;
    padding-top: ${$.layout.padding6}px;
    padding-bottom: ${$.layout.padding4}px;
    padding-left: calc((100vw - 1100px) / 2);
    padding-right: calc((100vw - 1100px) / 2);
    flex-wrap: wrap;

    > div {
      &:nth-child(2),
      &:nth-child(3) {
        justify-self: flex-end;
      }
    }
  }

  ${$.screen.tablet} {
    width: calc(100vw - ${$.layout.padding6}px * 2);
    padding-top: ${$.layout.padding6}px;
    padding-left: ${$.layout.padding6}px;
    padding-right: ${$.layout.padding6}px;
    padding-bottom: ${$.layout.padding4}px;
    flex-wrap: wrap;
  }

  ${$.screen.mobile} {
    width: calc(100vw - ${$.layout.padding4}px * 2);
    padding-top: ${$.layout.padding6}px;
    padding-left: ${$.layout.padding4}px;
    padding-right: ${$.layout.padding4}px;
    padding-bottom: ${$.layout.padding4}px;
    flex-direction: column;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;

  ${$.screen.desktop} {
    margin-right: ${$.layout.margin10}px;
  }

  ${$.screen.tablet} {
    margin-right: ${$.layout.margin10}px;
  }

  ${$.screen.mobile} {
    margin-bottom: ${$.layout.margin3}px;
  }
`;

const ColumnTitle1 = styled(({ to, children, ...rest }) => (
  <Link to={to} {...rest}>
    {children}
  </Link>
))`
  text-decoration: none;
  color: ${$.brown2};
  letter-spacing: 1px;
  text-transform: uppercase;
  font-family: Rubik Regular;
  font-weight: bold;
  margin-bottom: ${$.layout.margin3}px;
  font-size: 19px;
  transition:
    color 0.2s ease-in-out,
    font-family 0.2s ease-in-out;
  &:hover {
    color: ${$.brown4};
  }
`;

const ColumnTitle2 = styled.div`
  font-family: Rubik Regular;
  font-weight: bold;
  color: ${$.brown2};
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: ${$.layout.margin3}px;
  font-size: 19px;
  transition:
    color 0.2s ease-in-out,
    font-family 0.2s ease-in-out;
  &:hover {
    color: ${$.brown2};
  }
`;

const ColumnItem = styled(({ to, children, ...rest }) => {
  if (typeof to !== 'undefined') {
    return (
      <Link to={to} {...rest}>
        {children}
      </Link>
    );
  }

  return <div {...rest}>{children}</div>;
})`
  text-decoration: none;
  color: ${$.brown2};
  font-family: Rubik Regular;
  font-size: 16px;
  transition: color 0.2s ease-in-out;
  margin-bottom: ${$.layout.margin3}px;
  &:hover {
    color: ${$.brown4};
  }
`;

const ColumnListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${$.screen.desktop} {
    flex-direction: row;
    > * {
      &:first-child {
        margin-right: ${$.layout.margin2}px;
      }
    }
  }
`;

const ColumnList = styled.div`
  display: flex;
  flex-direction: column;
`;

const Copyright = styled.div`
  margin-top: ${$.layout.margin8}px;
  color: ${$.brown2};
  font-family: Rubik Regular;
  font-size: 15px;
  line-height: 1.2em;

  ${$.screen.desktop} {
    font-size: 13px;
    flex-basis: 1100px;
  }

  ${$.screen.tablet} {
    flex-basis: 100vh;
  }
`;

const NightMode = createGlobalStyle`
  body.night-mode {
    ${ColumnItem} {
      color: ${$.white1};
      &:hover {
        color: ${$.brown1};
      }
    }
    ${ColumnTitle1} {
      color: ${$.white1};
      &:hover {
        color: ${$.brown1};
      }
    }
    ${ColumnTitle2} {
      color: ${$.white1};
    }
    ${Copyright} {
      color: ${$.white1};
    }
  }
`;

let topics;
const directory = [];

data.forEach(({ type, text, subs, ...details }) => {
  switch (type) {
    case 'item':
      directory.push({ text, key: nanoid(), ...details });
      break;
    case 'section':
      topics = addKeys(subs);
      break;
    default:
      break;
  }
});

const Footer = ({ className }) => (
  <Container className={className}>
    {atFanfictionChapter() && <NightMode />}
    <Column>
      <ColumnTitle1 to="/" title="Home">
        Home
      </ColumnTitle1>
      <ColumnTitle1 to="/releases/" title="Release Notes">
        Release Notes
      </ColumnTitle1>
      {directory.slice(0, 2).map(({ text, link, key }) => (
        <ColumnTitle1 to={link} title={text} key={key}>
          {text}
        </ColumnTitle1>
      ))}
    </Column>
    <Column>
      {directory.slice(2, directory.length).map(({ text, link, key }) => (
        <ColumnTitle1 to={link} title={text} key={key}>
          {text}
        </ColumnTitle1>
      ))}
      <ColumnTitle1 to="/privacy-policy/">Privacy Policy</ColumnTitle1>
    </Column>
    <Column>
      <ColumnTitle2>Topics</ColumnTitle2>
      <ColumnListContainer>
        <ColumnList>
          {topics.slice(0, 5).map(({ text, link, key }) => (
            <ColumnItem
              to={link}
              title={
                text === 'All topics'
                  ? 'View all topics'
                  : `Visit posts tagged as #${text}`
              }
              key={key}
            >
              {text}
            </ColumnItem>
          ))}
        </ColumnList>
        <ColumnList>
          {topics.slice(5, topics.length).map(({ text, link, key }) => (
            <ColumnItem
              to={link}
              title={`Visit posts tagged as #${text}`}
              key={key}
            >
              {text}
            </ColumnItem>
          ))}
        </ColumnList>
      </ColumnListContainer>
    </Column>
    <Copyright>
      &copy;
      {` Copyright 2019 - ${new Date().getFullYear()} Geeknabe. All rights reserved.`}
    </Copyright>
  </Container>
);

Footer.defaultProps = {
  className: '',
};

Footer.propTypes = {
  className: PropTypes.string,
};

export default Footer;
