import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`With the trend of isekai light novels getting animated, I was… `}<strong parentName="p">{`skeptical`}</strong>{` of how good `}<em parentName="p">{`The Saint’s Magic is Omnipotent`}</em>{` can get. It could be a winner or an anime I have heard of and skipped. For example, `}<em parentName="p">{`The Promised Neverland S2.`}</em>{` I have read the manga and web novel, so I feel that I owe it that much to watch the anime too. To my surprise, they did the series pretty well! The background music and camerawork carried the series far more than I expected.`}</p>
    <p>{`If you have not watched the anime yet, `}<strong parentName="p">{`The Saint’s Magic is Omnipotent (Seijo no Maryoku wa Banno Desu)`}</strong>{` is originally a slice-of-life, really comfy otome isekai web novel that got published. After that, a manga version is released.`}</p>
    <Ads mdxType="Ads" />
    <p>{`We follow Sei, a tired office lady who often works OT. She was transported to a fantasy world along with another high school girl named Aira. Here, there are no demon king to defeat but instead, an otherworlder is summoned to do their job as a Saint. Only a Saint could clear this world's miasma for the safety of the citizens. Upon arrival in the new world, Aira was deemed to be the Saint by the Prince and Sei was left to her own devices. Of course, this angered Sei a lot since she literally got abandoned the moment they pulled her from our world. Lucky for her, she quickly found a purpose in her new life - `}<em parentName="p">{`making potions`}</em>{`. In this magical world, potions are made by mixing herbs and pouring magic into the solution. Sei who loves dabbling herbs see this as an opportunity to start anew.`}</p>
    <p>{`So here we have this really comfy potion-making life of Sei, along with the super slow plot unfolding in front of her. There’s romance, a bit of drama here and there but `}<strong parentName="p">{`nothing intense`}</strong>{`. There's no one out to get her, totally no ladies jealousy plot, yada yada. Just feel good and moe moments of Sei's new life.`}</p>
    <Ads mdxType="Ads" />
    <p>{`This is why I was skeptical of this isekai anime. Like… what can they animate that will attract the viewers’ attention? There’s literally nothing happening in the story? Reading the scenes in the novel feels `}<em parentName="p">{`decent`}</em>{`, but watching a slow story on screen gives a different experience altogether. I believe the animators are aware of this. Thus, they give the anime a little spice, namely the music and interesting camerawork. Plus lighting and ambience of course.`}</p>
    <p>{`I went in expecting a really bright and peppy show, maybe even watercolour effects here and there. You know, like how Ao Haru Ride did it? Alas, I was mistaken once again. What caught my attention is how often they add scenes of random close-up of objects to break up the pace. There are shots that showed the character far away instead of making them the centerpiece, as well as lots of off-screen shots that really bring out the mood.`}</p>
    <Image alt="Seijo no Maryoku wa Banno Desu screenshot 1" caption="They just loveeeee doing shots like these lately." name="the-saints-magic-power-is-omnipotent-screenshot-1.jpg" mdxType="Image" />
    <Image alt="Seijo no Maryoku wa Banno Desu screenshot 2" caption="Let's do a shot of teapots, mirrors and windows! It actually feels pretty nice to break the pace up with these shots." name="the-saints-magic-power-is-omnipotent-screenshot-3.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <p>{`Not to mention the music! I should wear my headphones if I ever binge this show again because the short snippets in the background are some of the most beautiful BGM I’ve heard. It really brings out the mood and keeps up the `}<em parentName="p">{`slow but comforting`}</em>{` mood of each scene. The ending song is really good too.`}</p>
    <Image alt="Seijo no Maryoku wa Banno Desu screenshot 3" caption="The ending song is pretty rad. The zooming out and expressions shown here really bring out the shocking mood." name="the-saints-magic-power-is-omnipotent-screenshot-4.jpg" mdxType="Image" />
    <p>{`All in all, the show got this josei vibe that I prefer instead of a shoujo vibe for me. If the show keeps up this mood then just mayyyybe, I could hang in there until the last episode. Give this anime a try, maybe you'll like it. Here's a link to the first episode below. You could watch it on Muse Asia (if you are in SEA) or on Funimation.`}</p>
    <Video src="https://www.youtube.com/embed/fI4UwXfQJa4" title="Seijo no Maryoku no Bannou desu" mdxType="Video" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      