/* eslint no-param-reassign: 0 */

import { nanoid } from 'nanoid';
import { useEffect } from 'react';

// Adds shortid keys to an array of objects.
const addKeys = (items) =>
  items.map((item) => ({
    ...item,
    key: nanoid(),
  }));

// Add event listener. Allows multiple events.
// elem -> node in DOM
// events -> event name separated by spaces
// callback -> function to run
const on = (elem, events, callback) => {
  events.split(' ').forEach((event) => {
    elem.addEventListener(event, callback, { passive: true });
  });
};

// Remove event listener. Can remove multiple events.
// elem -> node in DOM
// events -> event name separated by spaces
// callback -> function to run
const off = (elem, events, callback) => {
  events.split(' ').forEach((event) => {
    elem.removeEventListener(event, callback);
  });
};

// Fire an event. Can fire multiple events.
// elem -> node in DOm
// events -> string separated by spaces.
const trigger = (elem, events) => {
  events.split(' ').forEach((eventName) => {
    const event = new CustomEvent(eventName);
    elem.dispatchEvent(event);
  });
};

// Returns stringified date. Pass in true to add year
const getStringifiedDate = (date, addYear) => {
  const split = date.split('/');
  const currDate = new Date(split[2], split[1], split[0]);
  const year = currDate.getFullYear();
  const month = currDate.toLocaleString('en-us', { month: 'short' });
  const day = currDate.getDay();

  if (addYear === true) {
    return `${month} ${day}, ${year}`;
  }

  return `${month} ${day}`;
};

// Adds a slider that you can hold down and drag horizontally
// Needs a DOM node and a state to keep track of whether user is dragging.
const horizontalSlider = (ref, setDragging) => {
  useEffect(() => {
    let heldDown = false;
    let initialPos = 0;
    let scrolledLeft = 0;

    const handleDragging = (e) => {
      let x;
      const noOverflow = ref.current.scrollWidth === ref.current.clientWidth;

      // If the scroll didn't overflow, why do we need scrolling?
      if (noOverflow) {
        return;
      }

      switch (e.type) {
        case 'mousedown':
          heldDown = true;
          setDragging(true);
          initialPos = e.pageX - ref.current.offsetLeft;
          scrolledLeft = ref.current.scrollLeft;
          break;
        case 'mouseup':
          heldDown = false;
          setDragging(false);
          break;
        case 'mouseleave':
          heldDown = false;
          setDragging(false);
          break;
        case 'mousemove':
          if (heldDown === true) {
            e.preventDefault();
            x = e.pageX - ref.current.offsetLeft;
            ref.current.scrollLeft = scrolledLeft - (x - initialPos) * 2;
          }
          break;
        default:
          break;
      }
    };

    on(ref.current, 'mousedown mouseup mousemove mouseleave', handleDragging);

    return () => {
      off(
        ref.current,
        'mousedown mouseup mousemove mouseleave',
        handleDragging
      );
    };
  }, []);
};

// import 1 image from webpack
const importOneImage = (pathToImage) =>
  require.context('../assets', true)(`./${pathToImage}`).default;

// import 1 svg from webpack
const importOneSVG = (pathToSVG, props = {}) => {
  const svgImage = importOneImage(pathToSVG);
  const { defaultProps } = svgImage;

  return svgImage({ ...props, ...defaultProps });
};

// Runs callback whenever user clicked outside of the given DOM node
const useOutsideClick = (ref, callback) => {
  const handleOutsideClick = (event) => {
    if (ref.current && ref.current.contains(event.target) === false) {
      callback(event);
    }
  };

  useEffect(() => {
    if (typeof document !== 'undefined') {
      document.addEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      if (typeof document !== 'undefined') {
        document.removeEventListener('mousedown', handleOutsideClick);
      }
    };
  }, []);
};

// returns the color as per the given rating. Used for blog post with reviews
const getColorPerRating = (rating) => {
  if (rating >= 0 && rating <= 1.9) {
    return '#FF6666';
  }

  if (rating >= 2 && rating <= 3.9) {
    return '#514949';
  }

  if (rating >= 4 && rating <= 5.9) {
    return '#B2967D';
  }

  if (rating >= 6 && rating <= 7.9) {
    return '#44CF6C';
  }

  return '#EC682B';
};

// returns a random number from the given max number.
const randomInt = (max) => Math.floor(Math.random() * Math.floor(max));

const roundUp = (value) => {
  if (!value) {
    return 0;
  }

  if (value >= 1000 && value <= 999999) {
    return `${(value / 1000).toFixed(2)}K`;
  }

  if (value >= 1000000) {
    return `${(value / 1000000).toFixed(2)}M`;
  }

  return value;
};

const getDate = (str) => {
  const givenDate = new Date(str.split('T')[0]);

  return givenDate.toLocaleDateString('en-GB', {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
  });
};

/**
 * Get position of the element on page.
 */
const getPosition = (el) => {
  const rect = el.getBoundingClientRect();
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

  return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
};

export {
  getPosition,
  addKeys,
  on,
  off,
  roundUp,
  trigger,
  horizontalSlider,
  importOneImage,
  importOneSVG,
  useOutsideClick,
  getStringifiedDate,
  getDate,
  getColorPerRating,
  randomInt,
};
