import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const LocalLink = makeShortcode("LocalLink");
const Video = makeShortcode("Video");
const RemoteLink = makeShortcode("RemoteLink");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Whenever the word K-drama pops up during a convo with friends, all I could think of is the unnecessary drama between the main cast before the show ends up with the main couple together. Dude A likes Girl C, but some family issue popped up. Now Dude B likes Girl C despite knowing that his best friend Dude A likes the same girl. Drama ensues. Lots of them too. Misunderstandings, not talking to resolve the issue, bla bla. Then a car accident happened, the male lead got into a coma bla bla. Does it ring a bell? Yes, it's K-drama y’all!`}</p>
    <p>{`We webtoon readers and K-drama viewers do tend to have overlapping interests. So I guess it’s inevitable that there ARE webtoons that mimic K-drama plot flow. Think of the webtoons that I’m about to recommend as super similar to K-drama shows, where you have a ton of relationship problems here and there caused by unnecessary sabotage. I wanted to recommend more but after filtering the list, I think these three romance webtoon titles best fit this post. Enjoy!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Drama Romance Webtoon Recommendation #1: The Remarried Empress (재혼 황후)`}</h2>
    <p>{`I think most of you readers who found this blog post should have read this webtoon title already. I remember reading this out of the blue because it was uploaded as a fan translation. Boy, the whole unnecessary drama is so, so `}<em parentName="p">{`delicious`}</em>{`. I added a really good trailer by WEBTOON below. Wow... I wish my `}<LocalLink to="/blog/the-next-webtoon-they-should-animate-after-tower-of-god-is-kubera/" mdxType="LocalLink">{`Kubera `}</LocalLink>{`got the same trailer.`}</p>
    <Video src="https://www.youtube.com/embed/mjUBWKSIGlU" title="The Remarried Empress video" mdxType="Video" />
    <p>{`Our female protagonist, Navier, is the Empress of the Eastern Empire and wife of Emperor Sovieshu. She’s every bit you expect an Empress to be. Beautiful, knowledgeable and the very definition of a noble lady. She was born and bred to be an empress from a young age. Thus she is close with her fiance even before they are to be officially wed. As of late however, her relationship with the Soveishu has started to fracture due to the lack of an heir. Plus, Sovieshu seems to be of the opinion that Navier is no longer approachable as she used to be as a child. So Sovieshu picked up a commoner girl named Rashta, and invited her to be his mistress because he thinks that would shake things up. Things then get progressively worse from there on. Drama ensues and finally, Sovieshu divorces Navier (Shown in Chapter 1) only to have her remarry at the same moment. Who’s her groom? Well, it’s the king of the neighbouring Western Empire, Heinrey. Well, damnnn!`}</p>
    <p>{`You see what I mean with drama?! This story has 4 main cast members: Sovieshu x Rashta, the selfish duo and Heinrey x Navier, the couple everyone roots for. At first, we see Sovieshu trying his best to be nice to Navier but truth be told, everything that happened afterwards is `}<strong parentName="p">{`entirely his fault`}</strong>{`. He assumes a lot of Navier without trying to see from his wife’s perspective. His actions are egotistical and selfish. Navier, on the other hand, clearly responds only when her hands are tied. As an empress, there’s a level of propriety that she must uphold which… for some reasons, always paints her as a baddie to Sovieshu. `}<em parentName="p">{`You stoopid Emperor…`}</em>{` Rashta on the other hand, is a pitiful commoner girl who got brought in and now forced to juggle staying relevant to the court while securing her position as a long-lived mistress.`}</p>
    <p>{`I see Rashta as someone who’s easy to manipulate due to her background. But after catching up to the latest chapter, I started to see Rashta as an equally selfish girl who deserves the intense dislike of the side cast from the story. Man, if you don’t want to get hated on, don’t play the fake-pity mistress game. Heinrey though… Man, he’s such a sweetie. So you have the hated duo and the sweet couple. It’s obvious what the author is going for. Definitely a good read. You can read this on `}<RemoteLink to="https://www.webtoons.com/en/fantasy/the-remarried-empress/list?title_no=2135&page=1" mdxType="RemoteLink">{`WEBTOON`}</RemoteLink>{`.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Drama Romance Webtoon Recommendation #2: Inso’s Law (인소의 법칙)`}</h2>
    <p>{`Phew. This webtoon is a rollercoaster ride. It started off as a generic `}<em parentName="p">{`“I entered the novel and I can’t get out”`}</em>{` kinda story, like those `}<LocalLink to="/blog/15-otome-isekai-manga-and-webtoons-recommendations-you-will-enjoy/" mdxType="LocalLink">{`isekai villainess webtoons`}</LocalLink>{`. But the recurring theme of this webtoon is super different. Definitely lots of drama here and there.`}</p>
    <Image alt="Inso's Law cover" caption="Uhm uhm... I accidentally cropped out Yeoryeong because I couldn't fit her in for this blog post's banner. Eh, you'll see her in Chapter 1 anyway." name="insos-law-cover.jpg" mdxType="Image" />
    <p>{`Our female lead is Ham Dan-i who was transported into the web novel after waking up. She doesn’t remember how she got there, but she knew the world she’s in is a perfect replica of a web novel. In this world, she’s delegated as the web novel’s female lead, Yeoryeong’s best friend. At first, Dan-i is lost on the whole transported thing. Her school and friends are different now. And she has to play the role of a bff to a friend she barely knew. Plus, her “bff” comes with a package of four handsome guy friends. Dan-i first distances herself from the web novel’s main cast, but soon finds herself getting closer to them regardless. Due to how perfect these characters seem to be, she convinced herself how average she is. This hurts her self confidence which is the recurring theme of this webtoon. Does she truly belong in this world with her “friends”?`}</p>
    <p>{`Our girl, Ham Dan-i is one insecure girl and rightfully so. She’s convinced that she doesn’t belong in this web novel world. Main girl Yeoryeong has perfect looks and grades while the other four guys are rich and handsome. Each one of the guys have their own niche (Genius, heir to a company, model, etc) that we are all too familiar with. Not to mention how she keeps thinking that the web novel world feels… real. Wait, what if it really is the real world? Gosh, this is confusing. The latest chapter (100+) has not clarified if the web novel world is the real world or whatnot. One thing for sure though, is that Dan-i has not realized how loved she is amongst her friends. I have a feeling `}<em parentName="p">{`(This is purely my assumption since the story has not clarified this but I have a good feeling about this)`}</em>{` that Dan-i `}<strong parentName="p">{`IS`}</strong>{` the MC of the web novel world. Yeoryeong clearly has a crush on her bff while the other four boys like Dan-i in their own way. Lots of sabotage from third parties here and there. Dan-i never asked her friends to help her out which really makes me sad. She always internalizes her pain without telling anyone. This story is a good read for `}<RemoteLink to="https://twitter.com/tofugu/status/879810255970074626" mdxType="RemoteLink">{`kokoro ga itai`}</RemoteLink>{` moments. Good stuff.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Drama Romance Webtoon Recommendation #3: See You in My 19th Life (이번 생도 잘 부탁해)`}</h2>
    <p>{`I’m an absolute sucker for romance stories that have a `}<em parentName="p">{`“destined to be together”`}</em>{` plot. It’s cheesy for sure, but it’s just so romantic… Ya know? I discovered this on WEBTOON and I binged it on the same day.`}</p>
    <Image alt="Good stuff. READ THIS NOW." caption="From the left, Seoha and Jieum (Joowon)." name="see-you-in-my-19th-life-webtoon.jpg" mdxType="Image" />
    <p>{`The story starts off with Joowon, a young girl who’s way too mature for her age. That’s because she has a secret… She has since lived through 17 lives, and she remembers every one of her lives. Thus, acting as a child is a tad difficult for her in her 18th life. As Joowon, she’s born into a rich family. Since her previous lives weren't good, she thought she should live quietly as a rich heiress this time. That is, until she met Seoha, a young boy whose actions and words have captivated her heart. The story then takes an unfortunate turn by killing Joowon and traumatizing Seoha in a car accident. Time goes by. The plot continues by having Joowon reincarnates into her 19th life as Jieum Ban. She quickly remembered her 18th life, and how attached she is to Seoha. Thus, she resolved to meet Seoha and make him fall in love with her all over again. But could she break through his walls… caused by her death as Joowon?`}</p>
    <p><strong parentName="p">{`smacks table dramatically`}</strong>{` THIS IS GOOD. SPECTACULAR. If this gets adapted as a K-drama, I'll definitely watch it! Try it <3 You can read it on `}<RemoteLink to="https://www.webtoons.com/en/romance/see-you-in-my-19th-life/list?title_no=1266&page=1" mdxType="RemoteLink">{`WEBTOON.`}</RemoteLink></p>
    <Ads mdxType="Ads" />
    <h2>{`All in all`}</h2>
    <p>{`Remember how we keep telling all our friends we don’t do drama but the moment there’s drama to talk about, we all turn around to get the popcorn? These webtoons certainly feel that way to me. Enjoy the drama, enjoy the showww!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      