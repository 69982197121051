import { Fragment, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { nanoid } from 'nanoid';

import $ from '../../../../../styles/globals';
import TypingStatus from './TypingStatus';
import SeenStatus from './SeenStatus';
import TopBar from './TopBar';

const Container = styled.div`
  max-width: 300px;
  width: 100%;
  height: 450px;
  border-radius: 10px;
  background-color: ${$.color.white};
  border: 1px solid ${$.grey1};
  overflow: hidden;
  margin: 0 auto ${$.layout.margin5}px auto;
`;

const LeftBubble = styled.div`
  background-color: ${$.grey1};

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 95%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-right-color: ${$.grey1};
    border-left: 0;
    border-bottom: 0;
    margin-top: -10px;
    margin-left: -5px;
  }
`;

const RightBubble = styled.div`
  background-color: ${$.color.green1};
  color: ${$.color.white};

  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: 95%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-left-color: ${$.color.green1};
    border-right: 0;
    border-bottom: 0;
    margin-top: -10px;
    margin-right: -5px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${$.layout.margin2}px;
  ${({ left }) =>
    left
      ? 'margin-left: 12px; align-items: flex-start;'
      : 'margin-right: 12px; align-items: flex-end;'};

  p {
    margin: 0 !important;
    line-height: 1.3em !important;
  }

  ${LeftBubble}, ${RightBubble} {
    display: ${({ hide }) => (hide ? 'none' : 'block')};
    position: relative;
    padding: ${$.layout.padding2}px;
    border-radius: 13px;
    max-width: calc(55% - ${$.layout.padding2 * 2}px);
    ${({ failed }) => (failed === true ? 'opacity: 0.5;' : '')}

    > div {
      &:first-child {
        font-family: Helvetica Medium;
        font-size: 14px;
        line-height: 14px;
        margin-bottom: ${({ showName }) =>
          showName === true ? `${$.layout.margin1}px` : '0'};
      }
    }
  }
`;

const TextContainer = styled.div`
  height: calc(100% - 60px - ${$.layout.padding2 * 2}px);
  font-family: Helvetica Light;
  font-size: 15px;
  letter-spacing: 0.2px;
  overflow: auto;
  color: ${$.color.lightblack};
  padding: ${$.layout.padding2}px ${$.layout.padding1}px;

  ${$.verticalScroll}
`;

const Timestamp = styled.div`
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: ${$.grey2};
  font-family: Helvetica Light;
  margin-bottom: ${$.layout.margin2}px;

  > span {
    &:first-child {
      font-family: Helvetica Medium;
      font-weight: bold;
      margin-right: ${$.layout.margin1}px;
    }
  }
`;

const CenterText = styled.div`
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-family: Helvetica Medium;
  font-style: italic;
  margin-bottom: ${$.layout.margin2}px;
`;

const MessageBox = ({ contactNumber, totalUnread, items }) => {
  const containerRef = useRef(null);
  const rowRef = useRef(null);

  useEffect(() => {
    const hasScrollTo = items.filter(({ scrollto }) => scrollto === true);

    if (typeof window !== 'undefined' && hasScrollTo.length > 0) {
      setTimeout(() => {
        if (containerRef.current && rowRef.current) {
          containerRef.current.scrollTop =
            rowRef.current.getBoundingClientRect().y;
        }
      }, 100);
    }
  }, []);

  return (
    <Container>
      <TopBar unread={totalUnread} contactNumber={contactNumber} />
      <TextContainer ref={containerRef}>
        {items.map(
          ({
            left,
            right,
            timestamp,
            seen,
            name,
            typingLeft,
            typingRight,
            centerText,
          }) => (
            <Fragment key={nanoid()}>
              {timestamp?.day && (
                <Timestamp>
                  <span>{timestamp.day}</span>
                  <span>{timestamp.time}</span>
                </Timestamp>
              )}
              {centerText && (
                <CenterText dangerouslySetInnerHTML={{ __html: centerText }} />
              )}
              <Row
                ref={rowRef}
                left={!right}
                failed={seen === 'Failed'}
                showName={!!name}
                hide={!left && !right}
              >
                {left && (
                  <LeftBubble>
                    <div>{name}</div>
                    <div dangerouslySetInnerHTML={{ __html: left }} />
                  </LeftBubble>
                )}
                {right && (
                  <RightBubble>
                    <div>{name}</div>
                    <div dangerouslySetInnerHTML={{ __html: right }} />
                  </RightBubble>
                )}
                {seen && <SeenStatus status={seen} />}
                {typingLeft && <TypingStatus />}
                {typingRight && <TypingStatus right />}
              </Row>
            </Fragment>
          )
        )}
      </TextContainer>
    </Container>
  );
};

MessageBox.propTypes = {
  contactNumber: PropTypes.string.isRequired,
  totalUnread: PropTypes.number,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
};

MessageBox.defaultProps = {
  totalUnread: null,
};

export default MessageBox;
