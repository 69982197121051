import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const LocalLink = makeShortcode("LocalLink");
const RemoteLink = makeShortcode("RemoteLink");
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Just a few days ago, a RL friend of mine came forward to congratulate me on my blog’s progress. It stuck out to me as really strange because there is nothing on my blog that’s worthy of note. At least, nothing super great to get congratulations out of nowhere. I simply write to my heart’s fancy and if you noticed lately, updates have been sparse. Honestly, I’m just writing whenever I feel like it. My friend then told me, “Is writing `}<LocalLink to="/blog/" mdxType="LocalLink">{`154 blog posts`}</LocalLink>{` a small feat to you? Doesn’t seem that way to me!”`}</p>
    <RemoteLink to="https://unsplash.com/photos/BmGikCHuoBU" mdxType="RemoteLink">
  Note: I got the banner image from here.
    </RemoteLink>
    <Ads mdxType="Ads" />
    <p>{`Oh. He meant that. I was so busy comparing myself with other big blogs that I almost forgot… Writing blog posts for years while keeping up with the latest geek news is not easy. Keeping this blog up since 2019 is actually no small feat! Granted, `}<LocalLink to="/about/" mdxType="LocalLink">{`mari-chan`}</LocalLink>{` helped to write a few blog posts so all of the hard work is not just me alone. Looking back, this blog is truly something. It’s a WIP that I’m really proud of.`}</p>
    <p>{`When I first started this blog, I knew I needed to have something special to gain traction. So I did the Fire Emblem Three Houses Tea Time app (EDIT: No longer available) to gain attention. Afterwards, it’s all about writing blog posts that tickles the viewers’ fancy. I didn’t enjoy writing this way at first but after seeing the page view counter, I changed my mind (LOL). That’s not to say I didn’t enjoy writing lists! I try my best to include underrated or rarely seen titles so it gets some highlights at least! If there are more readers reading my favourite manga titles, the better! I noticed many folks enjoy anime more than manga which I find a pity. Does black and white comics not tickle anyone’s imagination at all? ):`}</p>
    <p>{`I find it really sad that many folks actually jumped right in once webtoons became more popular. While I enjoy webtoons, I can’t help but think that they are… not that fun to read. Simple stories are nice, but I crave complexity sometimes. Regardless, I still wrote a few blog posts on webtoons and it blew right off. (God d`}{`*`}{`mnit...) So I’m somewhat torn between writing for views and for fun. At any rate, I wrote this because I wanted a record of how I’m feeling about 154 blog posts so far. This is my blog after all! I’m allowed some leeway, yea?`}</p>
    <p>{`I plan to keep this blog running as long as possible. There’s too much joy in here that I am not willing to give up. It’s like social media validation, but better. I hope to hit higher page views this year! I suggest using GA4 (Google Analytics) for more accurate reporting if you haven’t got to it!`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Geeknabe blog progress 1" caption="Since January 2021, my page views have been climbing steadily. Really happy with the progress! Slow and steady wins the race." name="geeknabe-blog-progress-1.jpg" mdxType="Image" />
    <Image alt="Geeknabe blog progress 2" caption="154 blog posts! And 10 links to apps and fanfic chapters. So much content! So happy to see the fruits of my effort on this dinky page." name="geeknabe-blog-progress-2.jpg" mdxType="Image" />
    <Image alt="Geeknabe blog progress 3" caption="If you clicked on this blog post and expected something, so sorry. I apologize to the 33 folks who clicked on this post. This is my first blog post <3" name="geeknabe-blog-progress-3.jpg" mdxType="Image" />
    <Image alt="Geeknabe blog progress 4" caption="I don’t know how this happened but this is still my best blog post <3" name="geeknabe-blog-progress-4.jpg" mdxType="Image" />
    <h2>{`All in all`}</h2>
    <p>{`Thank you to everyone who reads my blog posts. I know they are mostly silly, just lists and never critical, so I super appreciate folks reading them!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      