import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const RemoteLink = makeShortcode("RemoteLink");
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`If there’s one thing I fear the most after `}<RemoteLink to="https://techcrunch.com/2014/10/21/google-acquires-firebase-to-help-developers-build-better-realtime-apps/" mdxType="RemoteLink">{`Google bought over Firebase`}</RemoteLink>{`, is to see the company die. Thankfully, the opposite happened! Firebase actually thrived! Oh thank god. I really like `}<RemoteLink to="https://firebase.google.com/" mdxType="RemoteLink">{`Firebase Realtime Database`}</RemoteLink>{` because it is one of the easiest NoSQL I tried. You can do really quick syncing across browser tabs with it and the branching is dead simple to understand if you stare at JSON files everyday. Firebase Realtime Database is good enough for simple things like a Like Button or a Heart Counter which I have implemented in this blog. And the best part? It’s all free as long as you don’t go over their bandwidth limit.`}</p>
    <Ads mdxType="Ads" />
    <p>{`As long as you do not abuse the limit, you could potentially stay within the free limit if you know your stuff right. Just make sure to practice the `}<em parentName="p">{`“call the resource only if you need it”`}</em>{` rule and you should be fine. Here’s how you create a Like button by using Firebase Realtime Database as your database. I’ll be explaining how you’ll implement it in a GatsbyJS project.`}</p>
    <p><em parentName="p">{`Note: This blog post is beginner friendly but I expect the readers to know their basic web dev lingo.`}</em></p>
    <h2>{`Step 1: Create a Firebase project`}</h2>
    <p>{`This goes without saying but you can’t start without a Firebase project first. Login, choose `}<em parentName="p">{`“Add Project”`}</em>{`, follow the instructions and everything will be set up nicely for you by Firebase. You could ignore all of the sales talk and go straight to `}<em parentName="p">{`Build`}</em>{` > `}<em parentName="p">{`Realtime Database option`}</em>{`.`}</p>
    <Image alt="Create Firebase project button" caption="This is my favourite button because I only see it once in every account I have." name="firebase-new-project.jpg" mdxType="Image" />
    <h2>{`Step 2: Decide on a NoSQL database structure`}</h2>
    <p>{`NoSQL is structured like a JSON file, with a key-value pair that could keep branching again and again. Just a heads up, Firebase Realtime Database only allows you to go 32 levels deep so try not to go crazy. Regardless, our Like button will be fairly simple so you’ll only need 2 levels deep max.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Firebase Realtime Database create database button" caption="Create a realtime database here. Ignore the other database, that's not for our use case. Choose 'locked mode' when the option to choose the security rules popped up." name="firebase-realtime-database.jpg" mdxType="Image" />
    <p>{`The first time your database boots up, it says `}<em parentName="p">{`null`}</em>{`. That’s because it has nothing in it for now! Create a branch called `}<em parentName="p">{`“likes”`}</em>{` and leave it for now.`}</p>
    <Image alt="Firebase Realtime Database branch structure" caption="This is how the branch should look like. Just press the + icon to get started. Add a second branch called “page-1” to populate some data in it first. We’ll remove this branch later." name="firebase-realtime-database-structure.jpg" mdxType="Image" />
    <h2>{`Step 3: Create security rules for your Firebase Realtime Database`}</h2>
    <p>{`Next, is to make security rules so no one could simply access your database. Click on the `}<em parentName="p">{`Rules`}</em>{` tab > `}<em parentName="p">{`Rules Playground`}</em>{` to get started. You could test your security rules before publishing the rules here.`}</p>
    <p>{`The first thing we need to establish is `}<strong parentName="p">{`what we want the rules to do.`}</strong>{` For now, we know for sure that we don’t want anyone creating new branches in our database willy-nilly. Reading the likes counter is fine because we need to display that on your site. So all in all, only the likes counter should be read and new counters written in. Everything else is mute. Here’s how the rule should look like.`}</p>
    <Ads mdxType="Ads" />
    <pre>{`{
  "rules": {
    "likes": {
      "$page": {
        ".read": true,
        ".write": true,
        ".validate": "newData.isNumber()"
      }
    }
}
`}</pre>
    <p>{`We have a branch called`}<em parentName="p">{` “likes”`}</em>{`. Under the `}<em parentName="p">{`“likes”`}</em>{` branch, are the pages of our site we wish to track the likes counter on. `}<em parentName="p">{`“$page”`}</em>{` represents the varying page titles we will receive. Putting it under the `}<em parentName="p">{`“likes”`}</em>{` branch denotes that these pages will only `}<em parentName="p">{`be allowed`}</em>{` appear under the `}<em parentName="p">{`“likes”`}</em>{` branch.The rules are `}<strong parentName="p">{`true`}</strong>{` for `}<em parentName="p">{`“.read”`}</em>{` and `}<em parentName="p">{`“.write”`}</em>{`. This means that we are only letting unauthenticated reads and writes under this branch. `}<em parentName="p">{`“.validate”`}</em>{` is also added in for further security. The value `}<em parentName="p">{`“newData.isNumber()”`}</em>{` means that you will only accept write updates if the value of the key-value pair `}<strong parentName="p">{`is a number.`}</strong>{` And done! That’s all for the Firebase Realtime Database part. Next is the front-end part.`}</p>
    <h2>{`Step 4: Read the likes counter for said page`}</h2>
    <p>{`When a visitor visits your page, they should be shown the number of likes for said page first. Of course, we first need to install a plugin that makes it easy to integrate Firebase. Download `}<em parentName="p">{`gatsby-plugin-firebase.`}</em></p>
    <p><strong parentName="p">{`Note: gatsby-plugin-firebase no longer works optimally for the latest Firebase library. Please refer to this article on `}<RemoteLink to="https://invertase.io/blog/firebase-with-gatsby" mdxType="RemoteLink">{`how to implement Firebase in Gatsby`}</RemoteLink></strong>{`.`}</p>
    <p><strong parentName="p">{`Once you're done with the steps in the link, you're done! You can then read/write anything you want to Firebase easily. I'm leaving this post of mine untouched so you could get the general idea on how to implement transaction and update method.`}</strong></p>
    <p>{`Fill in your Firebase project details in `}<em parentName="p">{`gatsby-config.js`}</em>{`. You can find your Firebase project credentials under `}<em parentName="p">{`Project Overview`}</em>{` > `}<em parentName="p">{`the cog icon`}</em>{` > `}<em parentName="p">{`Project Settings`}</em>{`. You might need to generate a Web API Key if you have not done it yet.`}</p>
    <Ads mdxType="Ads" />
    <pre>{`// under gatsby-config.js
// these credentials are OK to be shown to the public
{      
  resolve: "gatsby-plugin-firebase",      
  options: {        
    credentials: {          
      apiKey: "<YOUR_FIREBASE_API_KEY>",          
      authDomain: "<YOUR_FIREBASE_AUTH_DOMAIN>",          
      databaseURL: "<YOUR_FIREBASE_DATABASE_URL>",          
      projectId: "<YOUR_FIREBASE_PROJECT_ID>",          
      storageBucket: "<YOUR_FIREBASE_STORAGE_BUCKET>",         
      messagingSenderId: "<YOUR_FIREBASE_MESSAGING_SENDER_ID>",          
      appId: "<YOUR_FIREBASE_APP_ID>"        
    }
  }
}`}</pre>
    <p>{`Once you’re done, it’s time to show everyone how much the visitors love your site. On your component page, include `}<em parentName="p">{`gatsby-plugin-firebase`}</em>{` and `}<em parentName="p">{`useEffect`}</em>{` to load the Firebase context once before doing anything else.`}</p>
    <pre>{`import React, { useContext, useEffect, useState } from ‘react’;
import { FirebaseContext } from 'gatsby-plugin-firebase';

const Component = () => {	
  const firebase = useContext(FirebaseContext);        
  const [likes, setLikes] = useState(0);	
  
  useEffect(() => {	    
    firebase.database().ref(“/likes/the current page”).once('value')           
      .then(snapshot => { 
        setLikes(snapshot.val()); 
      }).catch(() => { setLikes(0); });        
  }, [firebase])	
  
  return <div>{\`Likes: $\{likes\}\`}</div>;
};
`}</pre>
    <p>{`Make sure to include the proper path to the branch for the value. For example, you have stored the likes counter under `}<em parentName="p">{`"likes/page-1"`}</em>{`. So make sure to add that in into `}<em parentName="p">{`firebase.database.ref("likes/page-1").`}</em>{` If there is no value under the branch, you should set the returned value to 0.And that is how you load the likes counter for said page.`}</p>
    <h2>{`Step 5: Update likes counter for said page`}</h2>
    <p>{`The idea here is simple: a visitor clicks on a button and the database gets updated with a +1 to the current page's like counter. But here’s the problem, how do I make sure `}<em parentName="p">{`no race condition`}</em>{` happens if there are multiple visitors on the same page? Have no fear because all you need to do is use the `}<em parentName="p">{`transaction`}</em>{` method. Simply add this line of code to your button’s click handler and you’re done. Make sure the path is correct! If your security rule is set up nicely, this shouldn't return an error for you.`}</p>
    <pre>{`firebase.database().ref(“likes/current page”).transaction(currentCounter => currentCounter + 1);`}</pre>
    <Ads mdxType="Ads" />
    <h2>{`All in all`}</h2>
    <p>{`And that’s it. That’s how you create a Like button with Firebase Realtime Database in a GatsbyJS project. It’s really simple. All the best!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      