import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXFanfiction/Chapter/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`“Felix, for the love of all that is good and pure...please tell me you did not, `}<em parentName="p">{`did not`}</em>{` confess to her twin brother,” Sylvain had an iron grip on his shoulders and was on the verge of disbelief. `}</p>
    <p>{`“Shut up,” he snapped and shoved Sylvain away. Recalling the incident was just as painful as the real thing itself. `}</p>
    <p>{`“Oh dude,” Claude said from his seat, “`}<em parentName="p">{`Dude`}</em>{`, I literally saw everything. From the start!” He grinned. “It was a complete and utter disaster, I `}<em parentName="p">{`loved`}</em>{` it.” `}</p>
    <p>{`Sylvain quickly pulled up a chair next to Claude and urged him on, “You have to tell me.” `}</p>
    <p>{`“Okay, so-” `}</p>
    <Ads mdxType="Ads" />
    <p>{`“Say another word and `}<em parentName="p">{`I swear,`}</em>{` I will toss the both of you out the window right now,” Felix hissed at Claude who stared at him with a large Cheshire cat-like grin. `}</p>
    <p>{`“Oh no, anyway,” Claude continued, ignoring his threats, “Our boy Felix here, I don’t know what possessed him-” `}</p>
    <p>{`“`}<em parentName="p">{`Ugh,`}</em>{`” Felix groaned and punched Claude in the arm who let out a cry before stomping out of the classroom and slammed the door behind him. It was still recess and the incident just happened not 10 minutes ago. `}</p>
    <p>{`Even though the door was shut tightly, Felix could still Claude as clear as day, since the idiot sat next to the door. Felix slid down the wall outside his classroom and gripped the sides of his head, seriously contemplating tearing his hair out if it meant the embarrassment he felt would stop. `}</p>
    <p>{`“So Felix, as we all know, has this really intense crush on Byleth Eisner right? You know her, green hair, blueish-purple eyes, has a blank stare, almost joined at the hip with her twin brother cuz they’re so ridiculously close? Yeah, yeah, her.” Felix could hear Claude describe her. He only stayed to listen in case Claude got his facts wrong. `}</p>
    <p>{`“M’Kay, I was literally minding my own business outside the guys bathroom, ‘cause the signal is the best there, right? And then, I see the Eisner twins and their identical vacant expressions coming down the corridor - but then I’m mates with Reus Eisner, you see, so I said ‘Hi’ just as he went into the bathroom and waved at his sister. She’s nice, by the way, I like her.” Felix felt like he was `}<em parentName="p">{`this`}</em>{` close to strangling Claude, the little shit was turning it into such an unnecessarily long story, `}<em parentName="p">{`get to the freakin’ point dammit!`}</em>{` He just wanted to get the whole thing over with. `}</p>
    <p>{`“I see our hero trudging towards me, I wave at him but he doesn’t see me of course, and then…” Claude pauses dramatically, and Felix rolls his eyes, “...And then…! He sees her!” `}</p>
    <p>{`“Oh no,” Sylvain said out loud, “This is `}<em parentName="p">{`good`}</em>{`.” `}</p>
    <p>{`“Yeah! I know, right?! Felix is like this angry little catty cat, but whenever Byleth Eisner is around he’s literally speechless! And the knot in between his eyebrows even disappears! Yes, Yes, exactly! It’s so weird!” `}</p>
    <Ads mdxType="Ads" />
    <p>{`Felix couldn’t stand it anymore. He stood up and opened the door with the force of a great typhoon and glared daggers at Claude. `}</p>
    <p>{`“Ah hey, there you are - we’re just getting to the good bit,” Claude smirked and gestured at someone’s empty seat. `}</p>
    <p>{`“`}<em parentName="p">{`Hrmph`}</em>{`,” Felix grunted and begrudgingly sat on a different chair than the one Claude pointed at, facing his back at them. `}</p>
    <p>{` “Come on! And then what happened?” Sylvain urged, tapping on the desk for him to continue. `}</p>
    <p>{`“It was like something out of a movie, I tell you—Felix literally freezes on the spot, in the middle of the corridor, and you know how he always gets pissed off with people who stand in the middle of a hallway and he yells at them? Well, he became the very type of people he absolutely loathed. And then like, since they both came out of the same time from the bathroom and started walking side by side, Felix somehow...I don’t know, I seriously don’t know why `}<em parentName="p">{`anybody`}</em>{` would do this—” `}</p>
    <p><em parentName="p">{`Oh here we go.`}</em></p>
    <p>{`“—But he rushed over with his eyes closed, no, legit, I saw his eyes were `}<em parentName="p">{`shut`}</em>{`. I think he couldn’t handle looking her in the eye. Isn’t that right, buddy?” Claude jabbed a finger at his back.`}</p>
    <p>{`“Don’t talk to me,” Felis growled. `}</p>
    <p>{`“Yeah, so— `}<em parentName="p">{`oh my goddess`}</em>{`, he like, literally pins whatever or whoever it was to the wall and shouts, ‘Byleth Eisner will you go out with me!’” Claude imitates him and Felix turns around to punch him again. `}</p>
    <p>{`Felix then clapped a hand over Claude’s mouth, “Shut up! Don’t say it so loud!” `}</p>
    <Ads mdxType="Ads" />
    <p>{`But Claude merely pushes his hand away, and fuelled with gleeful, reckless abandon, continues, “And then, suddenly, this deep ass, manly voice says ‘I’m not Byleth. I’m Reus.’ in the `}<em parentName="p">{`most`}</em>{` deadpan voice ever, Sylvain!” Sylvain and Claude throw themselves into fits of laughter right in front of him. `}</p>
    <p>{`“You both suck,” Felix snapped and stormed out again, this time, he actually marched off to the rooftop and glared at anyone who dared look his way. The roof had a few couples in each of the far corners and he instantly regretted his choice of location to brood. But Felix was already embarrassed enough and didn’t want to walk back the same way he came. `}</p>
    <p>{`Besides, the various couples were too engrossed in each other to notice him anyway. `}</p>
    <p>{`He went up to the thick metal fencing and bashed his head multiple times on it, hoping to shake the embarrassment out of him somehow. Felix stared off into the distance for a few good minutes, letting the strong cool wind on the roof refresh his mind while he contemplated his life and his actions. `}</p>
    <p>{`Byleth and Reus Eisner were both in his martial arts club and the way...the way she did a triangle choke on that guy, Caspar was just...almost the most beautiful thing he’d ever seen. The frown she had on, the way the veins popped out of her neck as she choked him and the perfect form she demonstrated...`}</p>
    <p>{`“You’re Felix right?” Felix recognised that voice, he turned around in horror to see the very person he was the most anxious around standing less than 5 feet from him. `}</p>
    <p>{`“Umm, Felix Fraldarius right?...I think you confessed your feelings for me to my twin brother, just now?” He sighed in response and nodded at her. `}</p>
    <Ads mdxType="Ads" />
    <p>{`“You ran away so quickly after that I didn’t get a chance to reply, I asked around and some people said they saw you up here,” she chuckled, “I’ve seen you in our club, by the way.” `}</p>
    <p>{`“Yeah. I know,” he said cooly, crossing his arms. “Sorry, that...wasn’t supposed to happen at all.” `}</p>
    <p>{`“Oh…? You... mean you don’t have feelings for me?” Her expression changed, “Oh uh...sorry, yeah. Um. This is embarrassing—I’ll just go now.” She spun round on her heel and was about to walk away. Felix knew that this, `}<em parentName="p">{`this very moment,`}</em>{` was his chance to rectify his mistake. `}</p>
    <p>{`“Hey, hold on,” He called out to her. “I—No— I `}<em parentName="p">{`did`}</em>{` mean it. I just don’t know what came over me.” `}</p>
    <p>{`For some reason, a look of relief washed over her face and she let out a sigh, “That’s…”`}</p>
    <p>{`Felix didn’t think this through but… there was a very, very high chance, she was going to reject him. And he already felt like crap at the thought.`}</p>
    <p>{`“That’s great,” she said, and he did a double take. `}</p>
    <p>{`“W—Wait, what?”`}</p>
    <p>{`“I was going to say I `}<em parentName="p">{`would`}</em>{` like to go out with you, but since you said it wasn’t meant to happen but...hey. That’s great. Phew, it took a lot of courage to come here and find you, actually,” she said, smiling at him. “I always thought you looked really cool.” `}</p>
    <p>{`Felix held a hand up at her for a moment of pause and turned around, slapping himself a few times. Was this real? He felt a soft touch at his elbow and when he spun around, her concerned eyes were staring into his. `}</p>
    <Ads mdxType="Ads" />
    <p>{`“Are you okay? Should I… should I leave? I seem to be causing you distress…” She said and he shook his head at her. `}</p>
    <p>{`“Then...um...would...you like to exchange numbers?” She pulled out her phone and handed it to him.  `}</p>
    <p>{`Felix broke out into a smile and took her phone, keying in his number and saved his name. “Yeah...definitely.” `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      