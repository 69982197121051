import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Image = makeShortcode("Image");
const Ads = makeShortcode("Ads");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This is probably old news by now since I made a ruckus on Twitter about this, but I’m really proud of my work. So I would like to show it here in this blog post! I made banners for `}<strong parentName="p">{`each`}</strong>{` and `}<strong parentName="p">{`every one`}</strong>{` of the Tea Time quiz/answer pages. You can find the tea time app here (EDIT: Removed. No longer available online). There are 40 pages for each character, plus 1 page for the index page! In total, I made 41 banners :D I used Photoshop for the endeavour.`}</p>
    <Image alt="nabe-chan dayo! on Twitter. Please follow me over there!" caption="Proof I make a lot of noise on Twitter" name="geeknabe-twitter-screenshot-fire-emblem-three-houses-banner-blog.jpg" mdxType="Image" />
    <p>{`I added them to the meta image tag in every Tea Time page, so they will only show up when you copy and paste the link on a social media platform/chat rooms. Here’s the index page’s banner. I debated if I should put in Rhea as well, but four house leaders in a row make for a better spectacle.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Edelgard, Dimitri, Claude and Yuri from Fire Emblem Three Houses." caption="I wanted to put Claude first but it's simply not canon to put him first... I am sorry Claude." name="fire-emblem-three-houses-tea-time-answers-and-quizzes-geeknabe.jpg" mdxType="Image" />
    <p>{`Each character from their respective houses gets their own red, blue, yellow and black as their motif. I try to find the nicest colour palettes to go with their main colours. I mulled over the background a lot since I knew a nicely done background does wonder for the mood of the graphic. ~40% opacity + ~50% fill seems to work nicely! But I didn’t want to have everyone using the same background, so I picked their hair colour and added a subtle gradient to each background.`}</p>
    <Image alt="Felix from the Blue House! Ahem, I mean his ALLEGIANCE IS TO THE BOAR PRINCE." caption="Felix's banner. He's all blue so the background colour fits him just fine." name="felix-tea-time-answer-and-quiz-geeknabe.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <Image alt="Anna is the best!" caption="Anna's is special since she is a DLC character which I have no idea what colour to associate her with. Putting money for her background is a bit too much..." name="anna-tea-time-answer-and-quiz-geeknabe.jpg" mdxType="Image" />
    <p>{`I thought about the portrait a lot. I knew I wanted a floral pattern, but I didn’t know how to incorporate it nicely since each element has a jagged edge to them. Blurring the edges make the whole portrait looks much softer and in-line with the floral pattern. As for the font, I used Cormorant. It’s a nice font if used right.`}</p>
    <Image alt="Rhea's banner in full glory." caption="I try to match everyone's head so their head sizes look the same... But darn that headdress is in the way." name="rhea-tea-time-answer-and-quiz-geeknabe.jpg" mdxType="Image" />
    <Image alt="Lysithea's banner is all yellow, just like Claude's." caption="Lysithea can shock me anytime." name="lysithea-tea-time-answer-and-quiz-geeknabe.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <Image alt="Linhardt our sleepy boi from the Adrestian empire." caption="Here, the background for Linhardt's is greenish at the top. This is intentional." name="linhardt-tea-time-answer-and-quiz-geeknabe.jpg" mdxType="Image" />
    <p>{`I didn't want to put every banner here since it'll suck up your data dry, so please copy and paste the links to see the images on your social media platforms! Or if you know how to use the Developer Tools, check out the twitter:image tag. Thank you for viewing the banners.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      