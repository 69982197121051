import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const RemoteLink = makeShortcode("RemoteLink");
const Image = makeShortcode("Image");
const Ads = makeShortcode("Ads");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Magu-chan is one of the newer series to grace Weekly Shonen Jump on `}<RemoteLink to="https://mangaplus.shueisha.co.jp/titles/100102" mdxType="RemoteLink">{`MangaPlus`}</RemoteLink>{`. I’m seriously thankful for Shueisha for creating MangaPlus. If not for this app, we wouldn’t have noticed this series at all! Magu-chan: God of Destruction is most definitely comedy and absolutely nothing about it is battle shonen-ish. Comedy is great and all but there’s this `}<em parentName="p">{`something`}</em>{` `}<strong parentName="p">{`tugging`}</strong>{` at my heartstring whenever I flip those pages and Magu-chan appears. By the way, the title of this manga is referring to this thing right here. Hold on, let me screenshot the panel for yea.`}</p>
    <Image alt="Magu-chan: God of Destruction panel 1" caption="This thing right here." name="magu-chan-god-of-destruction-2.jpg" mdxType="Image" />
    <p>{`Look how cute it is <3 He’s calling us idiots! Haha, yes of course, we adults are all idiots <3 Whoa there, before you yell at me about my depraved sense of kawaii-ness, please take a look at the next screenshot.`}</p>
    <Image alt="Magu-chan: God of Destruction panel 2" caption="This thing and that thing above is the same thing!" name="magu-chan-god-of-destruction-3.jpg" mdxType="Image" />
    <p>{`So how are these two screenshots related? Well, the top one is from when Magu-chan is at his strongest and the bottom one is when Magu-chan is the cutest. I can almost hear everyone doing the `}<strong parentName="p">{`w h a t`}</strong>{` expression. What’s so cute about this… pink blob? It has one eye for an eye, uhhh hands with three fingers, lotsa legs and are those ears? When he moves, he probably does squishy sound effects. Nothing about this design screams `}<em parentName="p">{`cute`}</em>{` but `}<strong parentName="p">{`MAGU-CHAN IS BLOODY CUTE.`}</strong>{` Let me explain why!`}</p>
    <Ads mdxType="Ads" />
    <p>{`Magu-chan: God of Destruction first started off by explaining how ye olde gods `}<strong parentName="p">{`do exist`}</strong>{` but they are just sealed by heroes. At the peak of their strength, they have massive cults worshipping them. The title God of Destruction is not given willy-nilly! This one-eyed thing can really shoot destructive beams from his eye! Fast forward to the modern times, Magu-chan’s sealing crystal was found by a Ruru, a middle school girl. She somehow manages to unseal Magu by pure chance! Since Magu is stuck in the crystal for so long, he lost his terrifying form and reverted into his cute blob form.`}</p>
    <p>{`I just noticed the first thing Ruru did is yell out GROSS before throwing Magu away. But he looks so pitiful in water so Ruru kinda just saved him and voila, she got a god as a pet! It helps that Ruru is super honest to the bone. My girl… You should be more suspicious if a pink blob can `}<em parentName="p">{`speak`}</em>{` and `}<strong parentName="p">{`shoot beams`}</strong>{` from his eye! The interactions between Ruru and Magu is the best thing ever and really lends to the point that Magu is super cute. You see, Ruru is a super one-tracked but honest child. While most folks would run away screaming, Ruru never balked away from Magu since the pink blob never directly harmed her. She actually stopped to listen to Magu! Moreover, she didn’t shy away when Magu clearly needed help. Was it pity because Magu looked so lonely, or did she genuinely want a friend? Regardless, she’s honest to a fault. Such a `}<em parentName="p">{`sweet`}</em>{` `}<em parentName="p">{`lass`}</em>{`.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Then there’s Magu, who’s a walking God of Death. His common sense is super skewed compared to a modern person, so every word he said sounded absurd! He talks about how almighty he is, worshippers, cult, offerings, yada-yada… But he couldn’t even move a muscle when the time came. He’s so small, so helpless yet he talks big. No wonder Ruru felt like she needed to care for Magu. I get the feeling she’s looking after Magu like how you would care for a younger brother with zero social skills and common sense. It’s the sweet girl and the stupidly “`}<em parentName="p">{`pompous but clearly didn’t mean harm”`}</em>{` pink blob combo that did it for me. It’s such an endearing duo! I think of Magu like a puppy who’s trying to be fierce but all of us just go `}<em parentName="p">{`aww, he’s trying to bark.`}</em></p>
    <p>{`The second best thing about Magu is that he acts all menacingly but he didn’t even know that his scary act didn’t work! He even acts all cute-like without knowing about it! I mean, just look at these panels! Did I mention that all he needs to do is soak in water to revive? Hehe, cute <3`}</p>
    <Image alt="Magu-chan: God of Destruction panel 3" caption="FULL RECOVERY! Thank god he’s small so a small tub of water is enough for him." name="magu-chan-god-of-destruction-4.jpg" mdxType="Image" />
    <Image alt="Magu-chan: God of Destruction panel 4" caption="He’s just eating natto and his fangs and horns just burst out! It turns out he likes the food! Isn’t his sudden outburst the cutest? LMAO the sound effect here is SPLURT. Imagine something bursting out from a pink jello!" name="magu-chan-god-of-destruction-5.jpg" mdxType="Image" />
    <Image alt="Magu-chan: God of Destruction panel 5" caption="He asked if Ruru wanted to destroy anything but she declined. Thus the ?! reaction. Urgh so endearing. My dog did the same too when he was shocked. You can literally see ?! expression on his face!" name="magu-chan-god-of-destruction-6.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <Image alt="Magu-chan: God of Destruction panel 6" caption="Awww look he’s sad. Even Ruru was troubled after he did that face. Amazing how expressive this pink blob is. After all he only has one eye, some sort of legs and hands." name="magu-chan-god-of-destruction-7.jpg" mdxType="Image" />
    <Image alt="Magu-chan: God of Destruction panel 7" caption="I’m cackling so hard at this panel. He’s literally doing the thing he is making fun of! And the sound effect here is TODDLE. Only a baby toddles! Lord, save me from this kyuuun" name="magu-chan-god-of-destruction-8.jpg" mdxType="Image" />
    <Image alt="Magu-chan: God of Destruction panel 8" caption="Man this shot is the best. Imagine telling a god he’s useless." name="magu-chan-god-of-destruction-9.jpg" mdxType="Image" />
    <Image alt="Magu-chan: God of Destruction panel 9" caption="Did I mention how on point and funny the English translated dialogues are? CEASE YOUR YAPPING YA USELESS CONTRAPTION!" name="magu-chan-god-of-destruction-10.jpg" mdxType="Image" />
    <p>{`I could go on and on about Magu-chan, but ya just gotta read it for yourself. There’s a ton of other scenes like this, where Magu displayed absolutely zero understanding of the new world around him and Ruru just trying to accommodate him. It continued for a bit in this manner until finally, Magu-chan gave back to Ruru by showing some consideration to her situation. Like how a baby sees you super depressed so they went to get you their favourite toy just to cheer you up? Yup, like that! This series is an absolute bomb of a comedy <3 `}<RemoteLink to="https://mangaplus.shueisha.co.jp/titles/100102" mdxType="RemoteLink">{`Please give it a try. `}</RemoteLink>{`Let me end this write-up with my favourite panels.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Magu-chan: God of Destruction panel 9" caption="Oh no, SHAME ON YOU Magu-chan!" name="magu-chan-god-of-destruction-11.jpg" mdxType="Image" />
    <Image alt="Magu-chan: God of Destruction panel 10" caption="Who's a good God of Destruction? It's you! <3" name="magu-chan-god-of-destruction-13.jpg" mdxType="Image" />
    <Image alt="Magu-chan: God of Destruction panel 11" caption="Why is he reading Shojo Manga??" name="magu-chan-god-of-destruction-12.jpg" mdxType="Image" />
    <Image alt="Magu-chan: God of Destruction panel 12" caption="Awwwww he got dressed up! Man it looks so good on him!" name="magu-chan-god-of-destruction-14.jpg" mdxType="Image" />
    <Image alt="Magu-chan: God of Destruction panel 13" caption="This is how my dog reacts whenever I keep him away from his food. It's not like I don't want to hand it over, but I gotta eat too!" name="magu-chan-god-of-destruction-15.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <Image alt="Magu-chan: God of Destruction panel 14" caption="The sound effect here says GIRLY but it's done in such a grizzly font. The book is a friendship book! He's demanding that everyone who wants to 'work' for him write down their bio in the book HAHAHA" name="magu-chan-god-of-destruction-16.jpg" mdxType="Image" />
    <p>{`Oof I better stop. At this rate I’m going I’ll share the entire manga! Read Magu-chan: God of Destruction from MangaPlus!!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      