import PropTypes from 'prop-types';
import styled, { createGlobalStyle } from 'styled-components';
import $ from '../../../../styles/globals';
import atFanfictionChapter from '../../../Navbar/atFanficChapter';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Hr = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${$.brown2};
`;

const Diamond = styled.div`
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  background-color: ${$.brown2};
  position: absolute;
  top: -4.5px;
`;

const NightMode = createGlobalStyle`
  body.night-mode {
    ${Diamond} {
      background-color: ${$.brown1};
    }
    ${Hr} {
      background-color: ${$.brown1};
    }
  }
`;

const Divider = ({ className }) => (
  <Container className={className}>
    {atFanfictionChapter() && <NightMode />}
    <Hr />
    <Diamond />
  </Container>
);

Divider.defaultProps = {
  className: '',
};

Divider.propTypes = {
  className: PropTypes.string,
};

export default Divider;
