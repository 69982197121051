import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Image = makeShortcode("Image");
const Ads = makeShortcode("Ads");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`These titles used to be at the peak of their popularity. Everyone knew of them once upon a time but they just… `}<em parentName="p">{`fade into obscurity`}</em>{` all of a sudden. I find it so strange. Aren’t these really good? How come no one is talking about them anymore despite them being ongoing titles? I do follow the latest chapters whenever I see the titles get updated but I really can’t help but notice the lack of interest by the fans... Let’s see if these titles jog your memory!`}</p>
    <h2>{`Forgotten Shounen Manga #1: D.Gray-man`}</h2>
    <p>{`Hah! Sounds familiar? `}<em parentName="p">{`Long long time ago`}</em>{`… Ahem! I jest. Technically speaking this shounen title was first published in 2004. Oh wait, that’s 17 years of ongoing chapters. Anyway, this title is the jam. Unique monster designs, dialogue and plot is a bit cringey but doable, and a really gripping mystery about the Noah family and Innocence. Her art is so unique, I could recognize her art from afar! D.Gray-man even got a pretty nice anime series that went on for 100+ episodes? I didn’t really follow the anime much, just a few episodes here and there. Good stuff all in all.`}</p>
    <Image alt="D.Gray-man manga cover" caption="I really, really love her art. She got that grotesque angelic being designs pegged down. The uniforms are great, the details are superb..." name="d-gray-man-manga-cover.jpg" mdxType="Image" />
    <p>{`So what happened? Well, this title’s popularity kept up really nice until the author had a wrist injury. She stopped releasing chapters for some time before settling for a one-chapter-every-4-month schedule. Or was it 6 months for 1 chapter? It’s been so long I couldn’t remember the plot at all… I can’t help but think she has an art block or some private issue that she’s not saying. But I’m happy that she is trying to conclude the story somehow. Currently, the story is finally revealing who’s behind the Millenium Earl mask, and how the mystery ego inside Allen is connected to the whole shebang.`}</p>
    <p>{`The story is being told in an obligatory flashback but fully fleshed out now. The dialogue is as cheesy as I remember. Totally nothing has changed since the early chapters so that’s a nice plus. The only thing bad as a D.Gray-man fan is the super, super slow updates. I do feel disheartened by the lack of progress but in the end, you can’t help but cheer for Katsura Hoshino. After all, she is still steadfast in keeping the torch alive. You can do it sensei!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Forgotten Shounen Manga #2: Blue Exorcist`}</h2>
    <p>{`If I’m busy writing about it, I’m sure you have this suspicion that nabe-chan has read the manga and is probably still following up with the latest chapter. Well, yes but no. I read up until the part about the Impure King arc and just… stopped there. Strange isn’t it? The story has so much potential but it doesn’t attract me at all.`}</p>
    <Image alt="Blue Exorcist manga cover" caption="Oh wow did Rin master his form? Uh huh, I'm so lost. Maybe I really do need to go back in." name="blue-exorcist-manga-cover.jpg" mdxType="Image" />
    <p>{`They got this cast of budding exorcists just waiting to be developed but… only Shiemi caught my attention. The rest is just… okay. The story made its point really early so you have an idea of the end game somehow. They have a super strong adult cast, a budding cast of young kids, the main character with an end game… Plus, ever rising challenges as the story progresses. Lots of mystery too. All of them add up but unfortunately, I still couldn’t feel invested in the story. The cast feels… plain. Dialogues fall flat. Visuals are okay but that’s it. Just… okay.`}</p>
    <p>{`When it comes to exorcist-type manga, every other title could do what Blue Exorcist does but better. Maybe I got way too used to bombastic scenes… Not to say Blue Exorcist is bad in any way but everyone else got them better. I recently hopped back in to see what I’ve been missing and wow, the art got better. Maybe I’ll hop back in to see what’s going on.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Forgotten Shounen Manga #3: Black Butler`}</h2>
    <p>{`I swear, everyone is in love with Sebastian and poof, the interest in Black Butler died out all of a sudden. I find the anime’s first season to be nice. Second season is an original take on the story and it feels… `}<em parentName="p">{`bleah`}</em>{`. I enjoyed The Book of Circus and Book of Murder anime adaptation. Black Butler had everything to end on a great note but as the story continues, the spark just feels...`}<em parentName="p">{` fizzled out.`}</em></p>
    <Image alt="Black Butler manga cover" caption="You can't deny the monochrome aesthetics though..." name="black-butler-manga-cover.jpg" mdxType="Image" />
    <p>{`I’m fine with the author not exploring the plot on Ciel’s brother. It’s been done and over with once Ciel explained how `}<strong parentName="p">{`his story`}</strong>{` came to be. Same goes for Ciel’s loyal chef, maid and gardener. They got a bit of exposure, got a dedicated chapter and I’m fine with it. Done and over with! But alas, we gotta repeat the whole thing again but this time, the Undertaker has a hand in it. And guess what? It’s been foreshadowed. Huh… This is that one foreshadowing that I wish the author never bothered to explore. Right now, the story’s padding feels unnecessary. I didn’t even get to see Sebastian do his cool action scene lately which is kinda the whole reason I enjoyed Black Butler.`}</p>
    <p>{`Ciel and Sebastian doing cool stuff in the name of the Queen. Hell yea, the anime’s S1 is lit because of this. I’m still reading Black Butler because I want to know the ending but yeaaaa the story is super lackluster currently.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`All in all`}</h2>
    <p>{`I could have sworn I missed a few other shounen titles but these three are the ones that stood out the most to me. Man, it sure is tough being a mangaka with a writing/art block. I hope these series continue doing their own thing and end soon (So I could binge all of them in one go)!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      