import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const RemoteLink = makeShortcode("RemoteLink");
const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I’ve been playing `}<strong parentName="p">{`Genshin Impact`}</strong>{` for some time on PC. The tracks are lovely, but I find that the slow songs don't really match the game’s intended tempo in the overworld map. It’s okay in cities since you’re usually walking around exploring but once you’re out there opening chests, the background music feels a bit mismatched. It worked for `}<strong parentName="p">{`Breath of the Wild`}</strong>{` because the map is so empty and big. Plus, you rarely speed across the map to get from one location to another really quickly. This gives you ample time to enjoy the slow tracks. You feel at ease and in the mood to play the game slowly on your Nintendo Switch console. Because Breath of the Wild `}<strong parentName="p">{`is`}</strong>{` an exploration game meant to be savored slowly.`}</p>
    <Ads mdxType="Ads" />
    <p><em parentName="p">{`Note: I found this blog post’s banner in `}<RemoteLink to="https://forums.mihoyo.com/genshin/article/14123" mdxType="RemoteLink">{`miHoYo’s forum`}</RemoteLink>{`. Please download the wallpaper there!`}</em></p>
    <p>{`Genshin Impact, on the other hand, is fundamentally a gacha game. The mood in a `}<strong parentName="p">{`gacha game`}</strong>{` is different from a `}<strong parentName="p">{`console game`}</strong>{`! You log in, collect your freebies and begin completing your daily quests. You clear up your resins or maybe, go around opening chests. These activities are very routine and are usually done quickly in succession. The map in Genshin Impact is so packed, I usually hear the battle music more and before the background music could finish playing the opening!`}</p>
    <p>{`It wasn’t that the tracks by Yu-Peng Chen are bad, I just wish their chords are slightly faster to match the player’s intended gameplay tempo. To be fair, I really love the tracks. Just some time ago, `}<RemoteLink to="https://twitter.com/GenshinImpact/status/1316952005848551427?s=20" mdxType="RemoteLink">{`miHoYo announced that they added 63 Mondstadt tracks up on Spotify.`}</RemoteLink>{` It’s great and all, but I honestly prefer Liyue’s OST more. Thankfully, `}<RemoteLink to="https://www.youtube.com/channel/UCkbZZsBpZ7VLykAIDhKvAmA" mdxType="RemoteLink">{`Chewie Melodies`}</RemoteLink>{` to the rescue! Thank you Chewie Melodies, you did a great job with the piano tracks. No mistakes and perfect clarity! It’s a joy to listen to with audiophile earbuds. Chewie Melodies added their own piano rendition of 19 tracks, each of which are tracks that you must be super used to in Mondstadt and Liyue. Please give them a try, I’ll leave the links below.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Here's the `}<RemoteLink to="https://open.spotify.com/album/4B5efqHkeq0UaDGx8nYOuf" mdxType="RemoteLink">{`Spotify link to the original mihoyo OST album (City of Winds and Idylls).`}</RemoteLink>{`And here's `}<RemoteLink to="https://open.spotify.com/album/1dhvU0UfLcHTLcEKKDiZGX" mdxType="RemoteLink">{`Spotify link to Chewie Melodies’ Genshin Impact Piano Cover (Part 1)`}</RemoteLink>{` with 8 tracks.Last but not least, the `}<RemoteLink to="https://open.spotify.com/album/3MM2EjuyNHOoB8gO5UhUwU" mdxType="RemoteLink">{`Spotify link to Chewie Melodies’ Genshin Impact Piano Cover (Part 2)`}</RemoteLink>{` with 11 tracks. I noticed that Chewie Melodies has added the songs to YouTube, thank you for that. But please consider listening to them on Spotify! The more times you listen to the songs, the more moolahs Chewie gets! I added the two videos below, enjoy.`}</p>
    <Video title="genshin impact music" src="https://www.youtube.com/embed/siRWwID1pTU" mdxType="Video" />
    <Video title="genshin impact music" src="https://www.youtube.com/embed/xNXqbQX9P5M" mdxType="Video" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      