import styled from 'styled-components';

import $ from '../../styles/globals';

const Container = styled.div``;

const TitleContainer = styled.div`
  margin-bottom: ${$.layout.margin7}px;
`;

const InnerTitle = styled.h1`
  font-family: Porkys !important;
  color: ${$.brown4};
  font-size: 40px !important;
`;

const Subtitle = styled.div`
  margin-bottom: ${$.layout.margin10}px;
  font-family: Rubik Regular;
  font-size: 20px;
  color: ${$.brown5};
  line-height: 1.5em;

  a {
    color: ${$.color.orange};
    font-family: Rubik Regular;
    font-weight: bold;
    text-decoration: none;
    &:visited {
      color: ${$.color.orange};
    }
  }

  ${$.screen.mobile} {
    font-size: 18px;
  }
`;

const Title = () => (
  <Container>
    <TitleContainer>
      <InnerTitle>The team behind Geeknabe</InnerTitle>
    </TitleContainer>
    <Subtitle>
      {
        "Hello, I'm nabe-chan and I build this blog up from scratch. You'll find all sorts of geeky topics that I personally enjoy such as anime, manga and games. Sometimes, I even throw in some tech reviews or random help posts which I think is useful for folks to chance upon! This blog came into being on October 2019 (you can see the timeline of this blog in "
      }
      <a href="/releases/">Release Notes</a>) and have been chugging since then.
    </Subtitle>
  </Container>
);

export default Title;
