import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Blockquote = makeShortcode("Blockquote");
const Ads = makeShortcode("Ads");
const LocalLink = makeShortcode("LocalLink");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`My daily routine in finding new manga goes as such - check Webtoon app every morning to see if there's a new update, then look for something new to read there. If I find nothing, I move on to scourge the Internet instead. It's a diligent rinse and repeat until I think I'm done. Lately, there’s a ton of new otome isekai manga and webtoons, but it's getting super repetitive! They say if you don’t like the new ones, there’s no harm in revisiting your older favourites <3`}</p>
    <Blockquote mdxType="Blockquote">
  Old is good. Unfortunately, it's also where all the good shoujo went to ):
  Webtoon and manhwa is nice, but I just want simple shoujo stories at times.
    </Blockquote>
    <p>{`So I went around to look for older manga that I really enjoyed back in the days. It was then I came across titles that I completed back in 2010-ish. They are axed, unfortunately. `}<strong parentName="p">{`Axed manga`}</strong>{` is just another term for `}<strong parentName="p">{`cancelled manga `}</strong>{`before the story is completed`}<strong parentName="p">{`.`}</strong>{` Oof, re-reading them reminds me of the bittersweet memories when I realized the stories won’t get a proper conclusion. I guess Japanese fans don't like them as much as I do… Enjoy the `}<strong parentName="p">{`axed/cancelled manga recommendations`}</strong>{`!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`ST&RS`}</h2>
    <p>{`How many times will I recommend `}<em parentName="p">{`ST&RS`}</em>{`? `}<LocalLink to="/blog/stars-fascination-with-the-unknown/" mdxType="LocalLink">{`It’s 2 times now`}</LocalLink>{`! ST&RS is a manga that wonderfully captured the mysteries and wonders a space fan feels about the celestial realm. On 10th August 2019 (`}<em parentName="p">{`That’s last year!`}</em>{`), we Earthlings received a signal from outer space. It reads, `}<strong parentName="p">{`“Let us meet on Mars on July 7, 2035”`}</strong>{`. This sent everyone on Earth into a flurry.`}</p>
    <Image alt="ST&RS manga cover" caption="Sigh... It was pretty hard to find decent sized images for ST&RS." name="stars-1.jpg" mdxType="Image" />
    <p>{`A space agency named `}<strong parentName="p">{`ST&RS`}</strong>{` was quickly formed to help muster the space exploration progress and advancement. New space stations were built and budding astronauts were trained. Unbeknownst to humanity, a baby uttered his first word `}<em parentName="p">{`“Mars”`}</em>{` on the same day and time Earth received the signal. You can say it was destined that the baby named Maho will grow up to be `}<em parentName="p">{`obsessed with astronomy`}</em>{`. Along with his childhood friend, Hishihara Meguru and genius classmate Amachi Wataru, the trio would soon come together to join Space Academy, a school that trains aspiring astronauts for the Mars mission!`}</p>
    <p>{`It’s one of the first manga I’ve read that captures the `}<em parentName="p">{`wonders of space`}</em>{`. ST&RS is one of my favourite manga when it first released since it paved the way for me to appreciate space more! You could read more of what I think about ST&RS here. My motivation in reading ST&RS is because I wanted to see where the kids will go, and what aliens they’ll show us! The manga managed to accumulate 5 volumes before it ended in the most un-satisfying way… `}<em parentName="p">{`Urgh…`}</em>{` `}<strong parentName="p">{`(cries) `}</strong></p>
    <Ads mdxType="Ads" />
    <h2>{`Magico`}</h2>
    <p>{`Ah yes, `}<strong parentName="p">{`Magico`}</strong>{`… `}<em parentName="p">{`Good times, good times`}</em>{`. I was so sure it’s shoujo from the looks of the plot! Emma is a young country girl who left the boonies for a better life in the city. But for some reason, she `}<strong parentName="p">{`charmed every man`}</strong>{` when she entered the city…?`}<em parentName="p">{` (I would love to learn how! Please teach me Emma!)`}</em></p>
    <Image alt="Magico cleaned cover" caption="I was trying to look for a nice horizontal image of Magico and chanced upon this. It's missing my favourite girl, Luu though." name="magico-2.jpg" mdxType="Image" />
    <p>{`So uh, she managed to charm even the king who saw her in passing. `}<em parentName="p">{`He’s just there to progress the plot, don’t worry`}</em>{`. Anyway, she got kidnapped to marry the king and before they could seal the deal, a mage named Shion came barging in to save the day from inside… `}<em parentName="p">{`a dragon’s mouth`}</em>{`?`}</p>
    <p>{`Regardless, Emma was saved and the mysterious mage began to explain what’s the deal with the charm magic she unconsciously casted. Apparently, it was the effect of her heart’s overflowing magic! Oh, and she’s `}<strong parentName="p">{`Echidna`}</strong>{`, a powerful witch that returns every 500 years. You can think of her heart as a powerful magic battery that every mage out there would love to have. Emma was shocked to discover this and just when she’s trying to digest all of it… `}<strong parentName="p">{`Shion proposed to her`}</strong>{`. Emma is all `}<em parentName="p">{`:O `}</em>{`but Shion burst her bubble by explaining that it’s a magic ritual to seal Echidna’s powers once and for all! The name of the ritual? Magico. Just like that, the ship has `}<em parentName="p">{`sailed`}</em>{`. `}<strong parentName="p">{`Officially too! <3`}</strong></p>
    <Image alt="Magico ritual map" caption="An overview look at the complexity of Magico, the ritual to seal Echidna once and for all." name="magico-3.jpg" mdxType="Image" />
    <p>{`This is a fun romance shonen with an equally unique magic system. Magic isn't obtained or used willy-nilly in this world. You need to complete rituals to obtain said magic, and even then the magic has a counter. For example, use up all 100 times of the magic, and you’ll need to redo the ritual if you wish to keep using it. So the more powerful the magic is, the harder the ritual is to complete! It’s a great system that rewards hard work and determination. You see the main characters go through pain just to get a rewarding magic. Unfortunately, we will never get to see the proper journey to complete the Magico ritual... Because it got axed ):`}</p>
    <p>{`I’m happy it got a happy ending at least. It’s a super sweet romance story with a fun magic system! If you enjoyed my `}<LocalLink to="/blog/im-recommending-more-otome-isekai-manga-and-webtoons-part-1/" mdxType="LocalLink">{`otome isekai recommendations`}</LocalLink>{` (`}<em parentName="p">{`Because you agreed with my taste in romance manga and webtoons <3`}</em>{`), then you’ll love Magico.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Double Arts`}</h2>
    <p>{`It’s another romance manga that I really love. I see fans really enjoyed it when it was first released! But somehow, Double Arts was treated worse than Magico! It only got 23 chapters in before they ended the story. My heart bleeds…`}</p>
    <Image alt="Double Arts manga cover" caption="I'm surprised to see that there's fanart for Double Arts when I googled for the volume cover. Good good... From the left, Elraine, Denzell, Sui, and Kiri." name="double-arts-4.jpg" mdxType="Image" />
    <p><strong parentName="p">{`Double Arts`}</strong>{` takes place in a world where an epidemic known as `}<em parentName="p">{`Troy(?)`}</em>{` exists. Anyone who contracted this disease would slowly weaken, have seizures and finally, `}<em parentName="p">{`disappear without a trace`}</em>{`. It’s highly infectious as well since anyone who touches the patient be it their hair or skin would get infected immediately. Despite being discovered `}<em parentName="p">{`700 years ago`}</em>{`, there is `}<em parentName="p">{`no known cure`}</em>{`.`}</p>
    <p>{`The only way to help a patient is to call upon the `}<strong parentName="p">{`Sisters`}</strong>{`, an organization comprised mostly of girls who have displayed a high immunity to the disease. These Sisters could take in Troy’s poison into their own body to help lengthen the patient’s lifespan. In return, however… The sisters themselves would be the one to `}<em parentName="p">{`disappear`}</em>{` once their time is up.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Our heroine is Elraine, a sister who has helped to `}<em parentName="p">{`heal `}</em>{`many patients inflicted with Troy. She stopped by Turm to save a patient, and it was then her seizure acted up. She knew her time was up. Just when she’s about to disappear, a boy named Kiri touched her and she miraculously survived. Elraine then knew he’s the answer the sisters are looking for! `}<strong parentName="p">{`A potential cure for Troy!`}</strong>{` But she can’t exactly let go of his hand since her seizures would return. Well then, she’ll have to keep holding his hand until they manage to get him to the Sisters’ HQ. Holding hands is a nice preface for romance, I like the way this mangaka thinks! (・ω・)b`}</p>
    <Image alt="Sui from Double Arts" caption="This is Sui, one of the fighters in the team. She fights with a hoop which is pretty fun to watch." name="double-arts-5.jpg" mdxType="Image" />
    <p>{`But things don’t go as planned… Apparently there’s an armed force going around killing Sisters? Elraine and Kiri would need to fight these men off while holding hands?? Genius, I love it. The manga was cut off at the crucial point in the story - getting a new teammate! Make sure to check the extra chapters added after Chapter 23 because the new teammate is `}<strong parentName="p">{`super cute`}</strong>{`! Double Arts is a series that I think `}<strong parentName="p">{`does not deserve to get axed/cancelled.`}</strong>{` I think anyone could easily get super invested in the end goal! Besides, the character designs and the idea behind Double Arts martial art is pretty great. Why on earth this got axed, I haven’t got the slightest clue…`}</p>
    <Ads mdxType="Ads" />
    <h2>{`All in all`}</h2>
    <p>{`While I was writing all three manga recommendations, I noticed that each of these cancelled/axed manga was serialized in `}<strong parentName="p">{`Weekly Shonen Jump`}</strong>{`. That’s it guys! I have found the solution. Aspiring mangaka should never serialize in WSJ if they want their manga to last long. Enjoy these new reads!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      