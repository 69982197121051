import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const RemoteLink = makeShortcode("RemoteLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Sooo....like any fairly normal person...I did my research before I bought my Switch and quite a few YouTubers recommended this Nyko Thin Case because it’s thin and dockable, I thought, if so many of these reviewers keep suggesting this...I mean...it has to be good right? Off mari-chan went and purchased this highly recommended case BEFORE she even bought her switch because she didn't want her new baby to be naked. I’ve been using this for easily close to 2 years and...Heh. Yeah. `}<em parentName="p">{`No`}</em>{`. It is `}<strong parentName="p"><em parentName="strong">{`THE WORST NINTENDO SWITCH CASE EVER`}</em></strong>{`. And lemme add that it’s one of the most...badly designed things i’ve seen. Nyko...please tell me who approved this.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Exhibit A:`}</h2>
    <p>{`I personally cannot understand the reason why there is such a large, almost flapping gap? If there is anyone out there who can educate me on it’s reason, I will gladly listen.`}</p>
    <Image alt="Nyko Thin Case Photo 1" caption="Please uh...ignore the sticker XD And I did clean/wipe it...but wear and tear etc. also I fished it out of the trash to take this picture, please forgive me XD" name="nyko-thin-case-nintendo-switch-review-photo-1.jpg" mdxType="Image" />
    <p>{`Having said that... I can understand the volume one, and the kickstand one again...What other purpose do the extra openings serve? As you can see, dust, dead skin and everything else gets trapped in there so easily. But even more distressing than dust is...that my switch is PERMANENTLY ruined because of the very badly placed openings.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Exhibit B:`}</h2>
    <p>{`As you can see just a little right of the “DISNEY TSUM TSUM FESTIVAL” decal, the oil stains from my fingers. Thanks to this infuriatingly, idiotically designed case, my switch is now forever adorned with HUMAN FINGER OIL STAINS from yours truly.`}</p>
    <Image alt="Nyko Thin Case Photo 2" caption="The gap left disgusting oil stains! ):" name="nyko-thin-case-nintendo-switch-photo-2.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`Exhibit C:`}</h2>
    <p>{`Towards the left of the kickstand, you can faintly see the outline of….ANOTHER FINGER OIL STAIN MARK. Right, so whatever extra I paid for the TSUM TSUM EDITION is useless now as sadly no one is going to want to pay more for a switch decorated with human finger oil marks.`}</p>
    <Image alt="Nyko Thin Case Photo 3" caption="More oil stains! I swear these are avoidable but noooo, stupid gaps." name="nyko-thin-case-nintendo-switch-review-photo-3.jpg" mdxType="Image" />
    <p>{`As much as I dry my hands, it’s just...human nature for your skin to produce oil ya know? I did google ‘how to get rid of oil stains on matte surface’ and the solution was to use rubbing alcohol or an alcohol swab. Hahahahaha….yeah didn’t work. What you see in the picture above was after I vigorously scrubbed it. You can see the water marks from me using a damp cloth as well. So in hindsight, with the large opening (it doesn’t fit snugly either, it’s...literally a gaping hole), like I mentioned before, Dust, Dead Skin, Liquids and even tiny bugs can and will invade the casing...thereby raising the questions;What is the point of having a case to ‘protect’ your switch?`}</p>
    <p>{`Ok sorry, I’m just...phew. I’m really mad. I did occasionally remove the cover to give my baby a good proper clean but at that time the oil stains weren’t so visible. Thank you Nyko, you just dropped the value of my switch :<`}</p>
    <Ads mdxType="Ads" />
    <h2>{`The Joycon Cover.`}</h2>
    <Image alt="Nyko Thin Case Photo 4" caption="Disgusting. Time for a new one!" name="nyko-thin-case-review-joycon-covers-photo-1.jpg" mdxType="Image" />
    <p>{`No really, I religiously clean and wipe my switch still it...uh...well, you see how gross and yellow it became .`}{`_`}{`.Also, let me tell you, getting this onto the joycon is not the worst. But getting it off is a nightmare. Already joycons can be so so so sensitive and prone to drifting, this just exacerbates your stress because removing it is...HARD. JUST HARD OK!! You’re almost wrestling it out!! I was so worried about popping off the thumbsticks or accidentally scratching my beautiful Tsum Tsum joycons. But to be fair, I think most transparent TPU joycon covers are like this, the ones that are sleeve-like.  Still, I hate it and I will never buy transparent TPU switch ‘clothes’ anymore :>;`}</p>
    <h2>{`The Quality. `}</h2>
    <p>{`Lastly, the main plastic that goes on the tablet is amazingly flimsy. It’s not made of TPU, just cheap plastic. It wasn't cheap either you know! I bought this from a local seller but here’s the `}<RemoteLink to="https://www.amazon.com/Nyko-Thin-Case-Dockable-Protective-Protector/dp/B072KHS2Q5/ref=cm_cr_arp_d_product_top?ie=UTF8" mdxType="RemoteLink">{`amazon price`}</RemoteLink>{`. And check out their 1-2 star reviews; similar complaints as me! It supposedly clicks and snaps but it becomes...loose? The clasps don't really secure well and tbh I don’t how I ever entrusted the life of my switch to this. I’m pretty sure if you drop it won’t do smack to protect your baby. And I’m not about to try and push my luck anymore.`}</p>
    <Ads mdxType="Ads" />
    <p>{`On top of that, the clasp mechanism at the bottom of the switch, where your charging cable goes, can be hit or miss. From the start, I realised my switch wasn’t docking properly. It’d take some time for the image to appear on my monitor/tv or it just...won’t. And then a couple weeks ago my switch just would.not.charge at all while it was in the dock. It just wouldn’t! When I did get it to charge or dock, it would work fine until I GENTLY set my controller down on the table and then it would disconnect. Just like that.`}</p>
    <p>{`Imagine how fickle it is - I would text nabe-chan some memes, set my phone down on the table and suddenly the image from my monitor would disappear. This went on for a good hour or so and let me tell you the PANIC I WAS IN. I tried 3 different HDMI cables, 3 universal adapters, 2 extension cords, and hijacked my parent’s TV in the living room...yet two hours after trying different combinations, nothing was working. I was almost in tears when I reached out to nabe-chan who was very supportive throughout my ordeal, `}<em parentName="p">{`me: MY SWITCH WON’T DOCK!!!!!!`}</em>{` `}<em parentName="p">{`nabe-chan: gg rip god bless ameno`}</em></p>
    <p>{`As I was about to give up and added a new dock to my shopping cart online I suddenly decided to strip my switch naked and...Voilà. It worked. `}<em parentName="p">{`me: OMG IT WAS THE NYKO THIN CASE THAT WAS THE PROBLEM!`}{`*`}{`*`}{`nabe-chan: gg rip god bless ameno`}</em>{`I had the bottom snapped on correctly but somehow it became loose and undone? I hadn’t removed it in...what...a whole year? Ever since the pandemic lockdown in my country started in March 2020? Since I wasn’t going out anywhere and had no reason to remove it, I did clean it as a whole without removing its ‘clothes’ but again, removing it and then putting it back on...for some reason snapping it correctly together is just...stressful. Again, because it’s so flimsy, press too hard and you’re like ‘Oops did i break it?’ or ‘Is it snapped together yet? Ah yes- oh no it’s not...ok I got it- ah shucks its undone. Tsk.’ I mean I’m already going slightly insane having been in quarantine for so long this just..sent me over the edge and I’m happy to announce I will finally destroy it once this post goes live.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Conclusion:`}</h2>
    <p>{`I cannot speak for other Nyko products, and the only good thing that came out of this disaster of a product was the included screen protector that’s somewhat decent. You’re better off buying a standalone screen protector from other brands. I will `}<strong parentName="p"><em parentName="strong">{`NEVER`}</em></strong>{` recommend this product `}<em parentName="p">{`ever`}</em>{`. I hate it `}<em parentName="p">{`so much `}</em>{`I would give it the rude finger but we have to be family friendly on this blog XDBy the way, this is what the packaging looks like so you can avoid it like someone not wearing a mask during the pandemic :>`}</p>
    <Image alt="null" caption="p.s if you have any solutions on how to effectively remove oil stains please let me know T.T" name="nyko-thin-case-clear-for-nintendo-switch™.jpg" mdxType="Image" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      