import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I keep telling myself not to watch the show until `}<strong parentName="p">{`all`}</strong>{` of the episodes are out but alas, I am a weak-willed blogger. The goodness of this show is the same reason why I can’t tear myself away from Animal Crossing: New Horizons. I’m a sucker for relaxing, slice-of-life games/anime! There is just… something really appealing to me about this genre. Despite being tagged as an `}<strong parentName="p">{`isekai`}</strong>{`, Ascendance of a Bookworm only borrows bits from the genre we all know too well. It merely uses the isekai theme as a strong pivot in the beginning as we are slowly introduced to Myne’s new world. Sure, Myne relies a lot on her previous knowledge but there are equally a ton of setbacks for her in this world of magic.`}</p>
    <Ads mdxType="Ads" />
    <p><em parentName="p">{`Note: I watched up to Episode 4 at the time of this writing. Spoilers alert!`}</em></p>
    <p>{`With a frail body, we see Myne struggling to keep up with the world in S1. We are introduced to her family and friends, as well as her humble beginnings as an inventor. It’s slow but it’s a perfect introductory course to the world of Ascendance of a Bookworm. S1 ended on a perfect note to the next stage of Myne’s life which S2 quickly picks up in Episode 1.`}</p>
    <Image alt="Ascendance of a Bookworm S2 ending card for Episode 1" caption="Ending card for Episode 1" name="ascendance-of-a-bookworm-season-2-ending-card-1.jpg" mdxType="Image" />
    <p>{`S1 is all about living in a downtown area with the commoners while S2 talks about Myne’s new prospect as a church priestess. As a blue-robed priestess, she is to be treated as a noble. Since her background is that of a commoner, many treated her with disdain in the church. We see how she tries her best to learn the common sense of a noble, as well as treating her retainers befitting of a noble. But Myne is not exactly a noblesse-minded even back during her days as Urano Motosu in our world. She’s Myne, both a loving individual to her family and friends and a bookworm from our world. She `}<em parentName="p">{`learns `}</em>{`to be a noble, but her circumstances have always been special. Her being allowed to be a blue-robed priestess is simply the outcome of an unfortunate event, so it’s obvious that her commoner background would upset the church’s social class.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Chibi Myne pouting from Ascendance of a Bookworm S2" caption="Chibi Myne is back! And she's really mad the church's common sense is so different from what she's learned in downtown." name="ascendance-of-a-bookworm-chibi-myne.jpg" mdxType="Image" />
    <p>{`Thus, she was `}<em parentName="p">{`blessed `}</em>{`with three retainers + spies - Delia, Fran and Gil. Fran is a perfect retainer from head to toe, answering Myne’s questions politely with patience. Gil is a hot-headed brat but deep down, he simply craves for acknowledgement and attention from the adults. Delia is a bit of a tsun with a troubling vocab list for a young girl. Both Fran and Delia admitted that they were spies from their previous masters, while Gil is downright a troubling case passed from the orphanage to Myne. She didn’t expect to earn the trust and respect from these three so she quickly bailed from the outset.`}</p>
    <Image alt="New characters from Ascendance of a Bookworm S2" caption="From the left, Gil, Delia, Ferdinand and Fran." name="ascendance-of-a-bookworm-season-2-ending-card-2.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <p>{`A nagging session from Benno quickly changed her mind and she worked hard to convince these three over to her side. Episode 1 to Episode 3 each dedicates an episode for these three troubled retainers. Episode 4 talks about the hidden side of the church. Turns out Myne’s parents were right about disliking the way the church handles their own affairs! All grey-robed (retainers) hail from an orphanage managed by the church themselves. The church doesn’t see the orphanage as a house for thrown away children. They see it as a factory churning useful grey-robed retainers instead!`}</p>
    <p>{`The speed of which they zoom past the first three episodes is so fast, I wonder if they actually adapted the light novel properly. But after Episode 4, I get that they did it to quickly move into the core plot point of S2 - the orphanage.`}</p>
    <Image alt="I don't like this guy" caption="You wanna reform the what now, young girl?" name="ascendance-of-a-bookworm-fatty-guy.jpg" mdxType="Image" />
    <Image alt="Ascendance of a Bookworm ending scene" caption="The ending is such a treat for the eyes. Make sure to watch the ending to see some great stitching art!" name="ascendance-of-a-bookworm-ending.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <p>{`Reworking an orphanage from the bottom will certainly be a treat to see - it’s tough to change what’s already been set for so long. Even Fran warned Myne that it is a huge responsibility to take. Myne’s initial hesitation is a feeling I resonate with. You are asking a bookworm who only reads book day in and out to spearhead an institution’s reformation? Your overtly positive evaluation is flattering, Lutz.`}</p>
    <p>{`So here we go! We have an orphanage to save y’all! I can’t wait to see how Myne’s effort will get sabotaged by human hands or destined outcome.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      