import styled from 'styled-components';

import $ from '../../../../styles/globals';

const Container = styled.div`
  position: relative;
`;

const Circle = styled.div`
  width: 12px;
  height: 12px;
  background-color: ${$.color.green1};
  border-radius: 999px;
  border: 4px solid ${$.brown4};
`;

const StartingCircle = () => (
  <Container>
    <Circle />
  </Container>
);

export default StartingCircle;
