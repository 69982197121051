import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const RemoteLink = makeShortcode("RemoteLink");
const Image = makeShortcode("Image");
const LocalLink = makeShortcode("LocalLink");
const Ads = makeShortcode("Ads");
const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This season is not as packed as Winter 2021’s but wow, I didn’t expect myself to enjoy some of these titles! I wanted to write a full review for each one of these titles but it takes too much time to write a decent post (In other words, nothing comes to mind if I wish to write a 500-word post for each title). So here’s all of it in one medium-sized blog post!`}</p>
    <h2>{`Spring 2021 Anime Quick Review #1: Vivy Fluorite Eye’s Song`}</h2>
    <p>{`Vivy is one of my favourite titles for this season due to how they structured each episode’s sudden twist. Watching the show from Episode 1 got me going from “How interesting...” to “It got better!” but unfortunately, it ends with “Eh, not what I expected but that’s ok.”. New songs are uploaded to `}<RemoteLink to="https://music.apple.com/my/playlist/vivy-fluorite-eyes-song-playlist/pl.u-kv9lqoVFJ37G2JV" mdxType="RemoteLink">{`Apple Music`}</RemoteLink>{` after the episode aired. The songs are pretty good with Vivy’s singer progressively upping her game until it’s time for the final song.`}</p>
    <Image alt="Vivy The Fluorite Eye's Song artwork" caption="I believe this is the album cover for Vivy's songs? The artwork is great on their website." name="vivy-fluorite-eyes-song.jpg" mdxType="Image" />
    <p>{`Animation is really, really good. Especially when it comes to the fight scenes! The studio knew when best to plug in a static scene and a satisfying fight choreography. Of course, the quality of the show couldn’t match up with Jujutsu Kaisen-kinda quality but that’s ok. They knew when to do good work and it shows! The ending didn’t feel like it ties up nicely, but I’m satisfied that it ended the way it did. Vivy is one of those plots that works well within a 12-episode format and I’m glad that it did. Overall a really nice 1-cour show. I once wrote `}<LocalLink to="/blog/vivy-fluorite-eyes-song-episode-9-anime-review/" mdxType="LocalLink">{`my thoughts for Episode 9`}</LocalLink>{` which I think really did reflect the ending, maybe just by a bit.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Spring 2021 Anime Quick Review #2: Tensura Nikki`}</h2>
    <p>{`I wasn’t planning on watching this show at all, to be honest. I have already read the manga version of this show and I really enjoy it. I’d say Tensura Nikki is one of those types of manga that you just flick through in the magazine for fun and move on afterwards. What I didn’t know is how amazing they did this slice-of-life show! Absolutely zero violence unless you count Millim whacking her poor victims (And destroying doors).`}</p>
    <Image alt="Tensura Nikki artwork" caption="The slice-of-life goodness! So, so good. Thank you for existing, Tensura Nikki." name="tensura-nikki-art.jpg" mdxType="Image" />
    <p>{`The show has this smooth animation going for it with a softer, more moe-like look for everyone. Plus, lots of spotlight on the lesser-known characters like Ririna and Treyni for example. I didn’t know I needed to see Ririna hogging the spotlight till Tensura Nikki aired. Everyone is so cute living their own lives and having fun with festivals. I feel closer to the main cast now after watching the show. I’m ready for more Tensura goodness when Season 2 Part 2 hits!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Spring 2021 Anime Quick Review #3: Zombieland Saga Revenge`}</h2>
    <p>{`Yet another brilliant season to continue the prequel’s wonderful job. You started the show expecting some idol shenanigans and finished the show wondering if they really need that last scene. This show is crazy! Thank you Mamoru Miyano for your wonderful performance. Songs are great! I really enjoyed this particular song titled`}<RemoteLink to="https://www.youtube.com/watch?v=VRt_i5FI9-o&ab_channel=avexpictures" mdxType="RemoteLink">{` Saga Jihen`}</RemoteLink>{`. I added a video below where Mamoru Miyano is just doing his own thing with the rest of the cast. What a fun bunch!`}</p>
    <Video title="Zombieland Saga" src="https://www.youtube.com/embed/GbLBkQwPTI8" mdxType="Video" />
    <p>{`We get to see Yuugiri’s backstory that ties in nicely with the zombie lore. It’s a fun addition all in all. I am of the opinion that they didn’t really need to add any backstory about how the zombie thing came about because honestly, I’m just here to watch Franchouchou’s silly shenanigans. I think the ending scene confirms the existence of S3…? Woo, more Zombieland content!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Spring 2021 Anime Quick Review #4: Seijo no Maryoku wa Bannou desu`}</h2>
    <p>{`Another underrated title that I didn’t expect to enjoy! I may or may not have voiced `}<LocalLink to="/blog/the-saints-magic-power-is-omnipotent-first-impression/" mdxType="LocalLink">{`my uncertainty in this blog post`}</LocalLink>{` when the early episodes aired. I read the source (manga and light novel) so I was skeptical that the show could get better than the source. I mean, true enough… There’s very few things happening in the story. No conflict no nothing… Just cute fluffy scenes. Will this attract viewers? As a matter of fact, it does! I added the opening song below, enjoy <3`}</p>
    <Video title="Seijo no Maryoku wa Bannou desu" src="https://www.youtube.com/embed/u1nsl6pVP9k" mdxType="Video" />
    <p>{`After watching a few episodes, I changed my mind. Oh I love the moe scenes. I think I must be starving for cute romance scenes… Where’s my Yona of the Dawn S2 damnit. The anime did a wonderful job of adapting the story AND elevating the fluff when necessary. The opening is a banger, the insert BGM is really good and the magic effects are really nice to look at. As it turns out, you didn’t have to go all out for a show to be great. You just need to accentuate the romance part and viewers will swoon. Yup, that’s me alright.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Spring 2021 Anime Quick Review #5: Kumo desu ga, nani ka?`}</h2>
    <p>{`This is that one rare show where they butcher up the animation but the viewers still stayed all the way until the last episode aired. Where are the complaints?! I barely see any even on Reddit. It’s pretty amazing. I guess that’s how fun the show is to everyone who watches it. I’m part of the club that has read the light novel and manga so I know the show will be good. Little did I know that the show is really, really good due to how they interlaced the main story with the human’s side of the story.  I added the opening below, enjoy! BURSTY GREEDY SPIDER, let's go!`}</p>
    <Video title="Kumo desu ga, nani ka?" src="https://www.youtube.com/embed/oDhY1lpLhu4" mdxType="Video" />
    <p>{`They definitely did Kumoko justice when it comes to animating her playful side. CG is great, fight scenes are exciting. Even the game-like skills/menu doesn’t feel on the nose once you get into the show’s vibe. The final episode is a great tie-up for both timelines, and they definitely prepped nicely for the next season. S3 when?`}</p>
    <Ads mdxType="Ads" />
    <h2>{`All in all`}</h2>
    <p>{`Wait what, nabe-chan only watched 5 shows this season? Heresy! Wait, wait! Before you choke me to death, please take a nice look at my Watching list from my `}<RemoteLink to="https://anilist.co/user/geeknabe/animelist" mdxType="RemoteLink">{`Anilist`}</RemoteLink>{`. I’m doing my best! Next season seems to be lit as well. Looking forward to more anime to leave on my Watchin- Sorry, I mean Completed list.`}</p>
    <Image alt="bla bla" caption="Forgive me for I am weak. *leaves to play Genshin Impact*" name="geeknabe-watch-list-anilist.jpg" mdxType="Image" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      