import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const LocalLink = makeShortcode("LocalLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I bet you never saw this coming! … Hah, wrong franchise. This is a small project that’s been in the talks since a month ago. mari-chan wanted to write fanfics but the work is only for her own indulgence. She let me read some of her works and I’m convinced mari-chan is just modest about her writing. So I thought, I have a blog. Heck, I code it up from scratch. Why not make a fanfiction section for her?`}</p>
    <Ads mdxType="Ads" />
    <p>{`It’s a bit of a challenge since I need to rearrange the website and rethink my approach to rebasing my code. The blog is meant for blog posts so I arranged my source code as it is needed at the point of time. Now a new fanfiction section is tacked on! So it took a while to nail down the details and work it out. After 2 weeks of work (from planning and working on the code whenever I’m free), here we are! A section coded from scratch for mari-chan to flex her pen.`}</p>
    <p>{`Here are the new pages and changes! There’s an entirely new section for fanfiction updates on the`}<LocalLink to="/" mdxType="LocalLink">{` landing page`}</LocalLink>{`. I’ve also added a `}<LocalLink to="/topic/fanfiction/" mdxType="LocalLink">{`topic page`}</LocalLink>{` for fanfiction, and an entirely new`}<LocalLink to="/fanfiction/" mdxType="LocalLink">{` index page`}</LocalLink>{` to list out all fanfics we have in the site. There’s also an individual page solely to showcase the fanfic’s details and listing out the chapters. I made sure each page are as simple as it can be so it doesn’t clutter your view. I noticed many readers loves to view my site on mobile so I worked extra hard that it looks good on your mobile too.`}</p>
    <Ads mdxType="Ads" />
    <p>{`With a fanfiction section added, this blog has become somewhat gargantuan in source code size. While confirming the last details of my work before deploying it to the Internet, I took one good look at my project tree. Man, it’s huge. My blog has come a long way and I plan to add even more content!Thank you for following my blog! Here’s the`}<LocalLink to="/fanfiction/everything-changes-but-you-season-1/1/" mdxType="LocalLink">{` first chapter`}</LocalLink>{` to mari-chan’s fanfic. You should give it a try if Dimitri x Byleth pairing is your favourite pairing.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      