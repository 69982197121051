import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const RemoteLink = makeShortcode("RemoteLink");
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`If an AI learns to think like a human, should we treat them like our fellow kin? What if their “feelings” are all pre-programmed? Are they… human? There’s a ton of stories out there exploring this interesting idea but honestly, there’s only one or two takes you could do in these stories. Then there are stories like `}<RemoteLink to="https://www.imdb.com/title/tt0343818/" mdxType="RemoteLink">{`I, Robot`}</RemoteLink>{` that put both side’s morality in a gray area. That said, the writers just need to make the robots as cute girls and I’m all for it. Thank you Japan! Arigatai! (Sorry I started the paragraph super seriously but in the end, moe always wins in the end!)`}</p>
    <p>{`I wasn’t going to watch this show while it is airing. I am following wayyyy too many anime for this Spring Season 2021, adding another one will kill my eyes. I feel like screaming, AHHH STOP MAKING GOOD ANIME but alas, they ignored my plea and did an original anime called `}<em parentName="p">{`Vivy -Fluorite Eye’s Song-`}</em>{`. I love this show so far. Why is Vivy such a fun watch for me? Is it because the guy who helped with the plot is Tappei, the writer for Re:Zero? Or is it because there’s a ton of singing and it just so happened that I love anime with singing?`}</p>
    <Ads mdxType="Ads" />
    <p>{`Nah, it’s the way they tickle your fancy by giving you something to wonder and quickly flip that expectation in every episode. I think this is a wonderful, masterfully done storytelling technique. Vivy is such a gripping story every week. So here’s what I love in every Vivy episode!`}</p>
    <p><strong parentName="p">{`Note: Major spoilers.`}</strong></p>
    <h2>{`Episode 1 - An inevitable future?`}</h2>
    <p>{`The first episode opens up with humanoid looking robots going around murdering their masters in the most innocent way. What’s going on? At first, I didn’t know these are robots (They are later called AI in the story) since they look so much like humans. The only thing discerning them from humans is that small circle on their neck. Creepy! Then we are quickly shown a countdown on the top left of your screen. You have no idea what that's supposed to mean but given the flow of things, you thought for sure it's a doomsday countdown. It turns out to be false in the end. Gosh, when the countdown ended I was so stoked that I got it wrong. I love it when the story led you by the nose, only to laugh at your expectation. It’s so simple, the twist is unexpected!`}</p>
    <Image alt="Vivy Fluorite Eye's Song screencap 1" caption="I thought the box on the left is only seen by Vivy, but it turns out it's for us viewers to notice. I was soooo sure that it's the countdown to doomsday but nah, I got bamboozled." name="vivy-fluorite-eye-song-anime-screencap-1.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`Episode 2 - Is the bear friend or foe?`}</h2>
    <p>{`There’s this stinging suspicion that this cute bear who calls himself Matsumoto is not an ally to humanity at all. Who’s to say that he lied, and is `}<strong parentName="p">{`NOT`}</strong>{` from 100 years in the future? Perhaps he `}<strong parentName="p">{`IS`}</strong>{` the bot that went rogue and wants to change history to his favour? There’s literally zero reason to trust this bear but Vivy just gotta do it after all. Matsumoto feels like a `}<RemoteLink to="https://madoka.fandom.com/wiki/Kyubey" mdxType="RemoteLink">{`Kyubey from Madoka Magica`}</RemoteLink>{` to me, just more humanly in speech.`}</p>
    <p>{`Throughout the whole episode, Matsumoto kept going on about `}<em parentName="p">{`“Listen to me”`}</em>{` without hearing Vivy out. They did reconcile at the end in a feel good manner but it happened again. A TWIST! Bloody hell! We saw Momoka’s airplane blowing with Vivy watching helplessly because she was stopped by Matsumoto. It’s a scene where it reinforces the viewers’ suspicion that this bear is no friend at all. So… who do I believe? What a good twist. Damn you Tappei, this must be your doing.`}</p>
    <Image alt="Vivy Fluorite Eye's Song Matsumoto" caption="Is the bear evil? Maybe, maybe not." name="vivy-fluorite-eyes-song-matsumoto-design.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`Episode 3 & 4 - Welcome to Sunrise`}</h2>
    <p>{`These two episodes are great because it led us by the nose! I was so sure that Estella had gone rogue, or has a different mission not mentioned in the episode. Oh boy, I got it wrong not once, `}<strong parentName="p">{`but twice`}</strong>{`. Episode 3 makes you think that Estella may be the culprit after all. Then in Episode 4, they introduced new characters as the true culprit! Yes of course, make the viewers think A, then pull the rug by doing a B. Dammit all! And they did another twist by re-introducing a character from Episode 2. I really did not see it coming at all.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`All in all`}</h2>
    <p>{`See how thrilling it is? I was so sure of how the plot will unfold only to be proven wrong again and again. Plus, we're only at Episode 4! Now this is what I call classy bamboozling. I have no idea what’s the next big incident, which further fuels my fun of not knowing what will happen next. If they keep pulling the rug under my hotpot like this, this show will soon be my personal AOTY. I love it when the plot goes in a different direction that you did not expect them to. If you have not watched the show, please do!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      