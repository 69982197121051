import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';

import $ from '../../../styles/globals';
import RightChevron from '../../../assets/icons/right-chevron.svg';
import TVIcon from '../../../assets/icons/tv.svg';
import ShiitakeIcon from '../../../assets/icons/shiitake.svg';
import NotebookIcon from '../../../assets/icons/notebook.svg';
import GameboyIcon from '../../../assets/icons/gameboy.svg';
import MangaIcon from '../../../assets/icons/manga.svg';
import DesktopIcon from '../../../assets/icons/desktop.svg';
import BookWithBookmarkIcon from '../../../assets/icons/book-with-bookmark.svg';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: ${$.layout.margin5}px;
`;

const StyledLink = styled(Link)`
  ${({ color = $.brown5 }) => `
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    transform: translateY(0);
    transition: all 0.3s ease;
    color: ${color};

    > svg {
      width: 30px;
      height: 30px;
      fill: ${color};
      margin-right: ${$.layout.margin2}px;
      &:last-child {
        align-self: flex-end;
      }
    }

    > h2 {
      color: ${color};
      display: inline-block;
      font-family: Raleway Bold;
      font-size: 30px;
    }

    &:hover {
      text-decoration: underline;
    }

    ${$.screen.mobile} {
      > h2 {
        font-size: 28px; 
      }
      > svg {
        width: 28px;
        height: 28px;
      }  
    }
  `}
`;

const TagTitle = ({ tag }) => {
  let icon = '';
  let color = '';

  switch (tag) {
    case 'anime':
      icon = <TVIcon />;
      color = $.color.orange;
      break;
    case 'blog':
      icon = <ShiitakeIcon />;
      color = $.color.lightblack;
      break;
    case 'fanfiction':
      icon = <NotebookIcon />;
      color = $.color.green2;
      break;
    case 'games':
      icon = <GameboyIcon />;
      color = $.color.green1;
      break;
    case 'manga':
      icon = <MangaIcon />;
      color = $.color.red1;
      break;
    case 'tech':
      icon = <DesktopIcon />;
      color = $.color.lightblack;
      break;
    case 'lightnovel':
      icon = <BookWithBookmarkIcon />;
      color = $.blue1;
      break;
    default:
      break;
  }

  return (
    <Container id={`${tag}_title_anchor`}>
      <StyledLink
        color={color}
        to={`/topic/${tag}/`}
        title={`See more posts tagged as #${tag}`}
      >
        {icon}
        <h2>{tag}</h2>
        <RightChevron />
      </StyledLink>
    </Container>
  );
};

TagTitle.defaultProps = {
  tag: 'anime',
};

TagTitle.propTypes = {
  tag: PropTypes.string,
};

export default TagTitle;
