import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXFanfiction/Chapter/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Divider = makeShortcode("Divider");
const RemoteLink = makeShortcode("RemoteLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`A long time ago, in the age of warriors, dragons and deities, on the continent of Fódlan...lived mermaids. One might think it was not unlikely, seeing as warriors and deities tamed dragons to use them in battle; even then, mermaids were a myth.`}</em></p>
    <p><em parentName="p">{`Saint Seiros sunk a small and remote portion of the Holy Kingdom of Faerghus into the ocean in a fit of rage. It was only by the will of the Goddess that the small population of people seeking refuge on the island, including the King, a handful of his loyal followers and those of his bloodline, turned into mermaids to survive because she felt responsible for her daughter's madness.`}</em></p>
    <p><em parentName="p">{`The Goddess, by the name of Sothis, transformed them into mermaids, giving the people a sanctuary in the deep sea from her tyrannical daughter. Once the villain was defeated, the newly transformed mermaids realised they were able to transform back into humans once more....but there ended the goddess’ blessing.`}</em></p>
    <p><em parentName="p">{`It was essential they return to sea every so often...for their essence had been pledged to the ocean. Their life force now forever entwined with the waves. It was only for the purposes of reproduction were they allowed to live on land.`}</em></p>
    <p><em parentName="p">{`Or so the legend goes.`}</em></p>
    <Ads mdxType="Ads" />
    <p><em parentName="p">{`Countless lores and myths about mermaids have sprung up throughout the ages. Some say mermaids exist to this day, hiding in plain sight. Some say Saint Seiros was doing the world a favour by purging the world of these evil beings. `}</em></p>
    <p>{`“Papa!”`}</p>
    <p>{`“Yes, sweetheart?”`}</p>
    <p>{`Byleth, aged 5 tugged on her father’s sleeve “What’s replooducshen?”`}</p>
    <p>{`Jeralt felt awkward beads of sweat emerge all over his body. He scarcely knew how to explain where human children came from, let alone `}<em parentName="p">{`mermaid`}</em>{` babies - which he genuinely didn’t know.`}</p>
    <p>{`“Oh um...it’s..uh...it’s how mermaids make baby mermaids. It’s called reproduction-”`}</p>
    <p>{`“-PAPA HOW DO MERMAIDS MAKE BABIES?” Byleth whispered loudly as she pointed a finger at the museum screen that was screening a short video clip about the legend of the mermaids in ancient times.`}</p>
    <p>{`“Shhh`}<em parentName="p">{`-Shh`}</em>{`, Byleth, not so loud…!” He clapped a large hairy hand over her mouth, almost covering her entire face.`}</p>
    <p>{`“Yeah dad!” Her twin brother Reus, his first-born by a minute, chirped as he yanked on his other arm for attention. “Do they have super long poop like our guppies??”`}</p>
    <p>{`Jeralt frantically nodded apologies to the other museum goers who were in the small screening room with them.  “Well- uh, I suppose-”`}</p>
    <p>{`“DO THEY EAT FISH FOOD!!” Reus yelled on top of his little lungs, prompting Jeralt to sprint out of the room with his children, one under each arm, and his enormous backpack of snacks, drinks and spare clothes hanging precariously on one of his elbows.`}</p>
    <p>{`While his twins were normally extremely quiet and very peaceful children, somehow today’s visit to the national museum triggered something in them and thank the Goddess his wife insisted on using the child leash - equipped with identification tags. Half the continent and their miniatures were here today.`}</p>
    <p>{`As the museum was arranged in order to maximise one’s viewing pleasure, as soon as they stepped out of the screening room, the kids made a beeline and yanked him towards the replica mermaid skeleton...or..fish bone? Jeralt shook his head. He was worried the remains would be too morbid for his preschoolers but they were glued to the display.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Both Reus and Byleth had their little noses pressed right up against the glass, as with many other children present. Jeralt was instantly worried about the germs they’d be inhaling but then remembered a little bit would be good for their immune system. He hoped.`}</p>
    <p>{`“Is this real?! Is it? Is it? Is it, papa??” Reus blurted out in one breath.`}</p>
    <p>{`“No Reus, it’s fake. See here?” He pointed at the plaque. Not like they could read well but still.`}</p>
    <p>{`“But mermaids are real!” Byleth argued as she frowned. “The man in the room said so!”`}</p>
    <p>{`“Yeah! It’s not fake! It’s a real fish! It has the same tail as my guppy!!”`}</p>
    <p>{`Despite it being a public holiday, his wife, Sitri, had a flower arrangement course to teach. And of course, like most parents, evident by the inhumane amount of people in attendance at the museum today, Jeralt and his wife were in agreement that children should be taken to museums and theme parks for a more rounded and holistic childhood.`}</p>
    <p>{`Though, his sanity was beginning to question that said decision.`}</p>
    <p>{`Jeralt sighed, reminding himself that arguing with slightly bigger toddlers and trying to convince them that mermaids were purely myth wasn’t doing him any good. “Okay sweethearts, if you say so.”`}</p>
    <p>{`“All right kids, don’t disturb the mermaid resting in peace, let’s move on to the next exhibit - ah yes, daddy’s favourite...the cafeteria- `}<em parentName="p">{`Whoa`}</em>{`!” Jeralt was violently pulled back as the both of them stood stubbornly still in front of the glass cabinet.`}</p>
    <p>{`“Can we has picture?” Byleth pouted, her big, blue-ish purple puppy eyes an exact copy of their mother’s.`}</p>
    <p>{`“Pwease papa?” Reus backed his sister up, holding her hand and unleashing the full force of his pout too. “I wanna show mama.”`}</p>
    <p>{`Jeralt let out a relenting groan, “Sure... now just stand still while I get the camera out…” He fumbled and somehow managed to untangle himself and fished out the camera that was buried under some oreo crumbs.`}</p>
    <p>{`“Okay…” He mumbled under his breath, “Do we have film? Mmm. Yes, okay. Now stand there...no, Reus take your hand out of your pants- Byleth, sweetie put your tongue back in-”`}</p>
    <Ads mdxType="Ads" />
    <p>{`“1...2...3…!”`}</p>
    <p><em parentName="p">{`Flash`}</em>{`.`}</p>
    <Divider mdxType="Divider" />
    <p><em parentName="p">{`17 Years Later.`}</em></p>
    <Divider mdxType="Divider" />
    <p>{`“`}<em parentName="p">{`Tsk`}</em>{`,” Byleth expressed her annoyance when her coin dropped out of her pocket. She never ever had good things happen to her when it came to vending machines. Especially this one, the one that was right next to the swimming pool. She bent down to pick it up but a giant foot encased in Adidas aqua slippers stepped on the coin `}<em parentName="p">{`and`}</em>{` her finger.`}</p>
    <p>{`She winced.`}</p>
    <p>{`Byleth looked up to see the figure in front of her with menacing blue eyes glaring at her, dressed in nothing but speedo’s with droplets of water dripping from his hair into her eyes, she frowned at him.`}</p>
    <p>{`She jerked her finger back, attempting to rescue the coin but alas, it was still lodged under the giant foot.`}</p>
    <p>{`“Can I have my money back, please?” She said testily.`}</p>
    <p>{`The tall, blonde male with a haughty countenance merely stared down at her. She looked down to see his foot kicking the coin under the vending machine.`}</p>
    <p>{`“Wha-Hey!” She cried out as he quickly walked away with his gatorade, apparently ignoring her. She huffed a sigh as she peeked under the vending machine; it looked as if a tornado of dust and rubbish had settled under it, and she painfully made a decision to leave the coin there with its new dust family.`}</p>
    <p>{`Through gritted teeth, she swore; `}<em parentName="p">{`I hope I never have anything to do with him, ever.`}</em></p>
    <p>{`“GO REUS! COME ON PUT YOUR BACK INTO IT! ASHE! YOUR FORM IS SLOPPY!”`}</p>
    <p>{`Byleth, armed with the drink she finally purchased from the accursed machine, stood at one of the many entrances of the school swimming pool, observing the swimming team - something she did very frequently.`}</p>
    <p>{`The voice, amplified by a megaphone, belonged to the swim team coach of Garreg Mach University, Coach Manuela, or more commonly known as Professor Manuela when not in a swimsuit and track jacket, was a former Olympic swimmer whose team won silver back in a day. A fact she never forgot, and the reason why she was extremely strict and hard on her current trainees.`}</p>
    <Ads mdxType="Ads" />
    <p>{`She shook her head and yelled into the megaphone, “LORENZ-” She groaned, “-Someone GET LORENZ OUT OF THERE PLEASE!”`}</p>
    <p>{`Lorenz, as Byleth observed from the doorway, seemingly joined the swim team purely to attract girls and up his popularity level. Unfortunately, he wasn’t very good at swimming and thankfully he was just a bench warmer. Coach Manuela was absolutely set in her ways to win gold at the upcoming championship. Their rival school, University of Ylisse had been winning for the past decade or so and `}<em parentName="p">{`nothing `}</em>{`irritated her more.`}</p>
    <p>{`“KICK, CLAUDE! KICK HARDER!” Coach Manuela whistled at Claude, a boy in her brother’s team. A good swimmer for sure, but he was constantly scheming of ways to win instead of actually swimming.`}</p>
    <p>{`Her brother Reus, the reason why she procured said electrolyte drink for and why she was often seen at the swimming pool, had potential according to Manuela, and Reus knew he was good. But no one, not even Manuela herself could come close to the star that was Dimitri Alexandre Blaiddyd. The man who would carry their team to victory, Coach said.`}</p>
    <p>{`A swimmer so graceful, so elegant, that he made swimming look effortless. He made Reus, Claude and Ashe, the regulars of the swim team of Garreg Mach University, the Oxford of their continent, look like a flock of flailing birds in water.`}</p>
    <p>{`Dimitri was so terribly amazing at swimming that even the chancellor herself came to watch their training sometimes. There was something mesmerizing about the way Dimitri swam. Almost like...he was at home in the water. His arms were corded with thick muscle, working beautifully while he swam. His movements were powerful yet natural, his lungs held a ridiculous amount of air and barely needed to resurface.`}</p>
    <p>{`If only his personality was just graceful, but of course no one is perfect.`}</p>
    <p>{`For the most part, Dimitri was responsive. He was also strangely...clumsy. For a person who could swim so elegantly, he tripped a lot while walking. She saw him trip a few times while walking around the pool complex, sometimes on campus, and even once going up the stairs.`}</p>
    <p>{`But he was almost the most broodiest, rudest and grumpiest person to `}<em parentName="p">{`ever`}</em>{` walk the earth.`}</p>
    <p>{`In fact, `}<em parentName="p">{`he`}</em>{` was the jerk who kicked her coin under the vending machine.`}</p>
    <p>{`One time, Byleth was having a quick conversation with Reus about dinner during their 5 minute water break, when she felt a shadow looming over her along with droplets of water dripping onto the top of her head, “Move,” a low growl said from behind her.`}</p>
    <p>{`She spun around to see a pair of fierce blue eyes staring down through blonde bangs at her before she quickly shifted so that Dimitri could reach for his bottle.`}</p>
    <p>{`This was her second year in University and while she and Reus had been here since they were first years - Dimitri had been a transfer from down under or something during their second year. Dimitri’s father had been some sort of overachiever and thus Dimitri himself rumoured to be a legacy admission but to be fair, he did put in the work. Their team went from being the fallen champions, to suddenly winning a gold medal in their local tournament- it was such a huge shock to the swimming world, someone actually wrote an article about them.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Of course, Coach Manuela took over from the previous incumbent and weeded out all who she deemed unworthy but it was as though Dimitri’s presence had awakened a hidden swimming talent in his teammates. The local magazine had taken to dubbing him ‘The Saviour King’ - due to the fact that he `}<em parentName="p">{`might`}</em>{` just save Garreg Mach and take them to the national championship once more.`}</p>
    <p>{`Byleth wasn’t always hanging around the swim team though, while Reus got in with a swimming scholarship, she got in with an academic scholarship and was on her way to becoming a Professor herself one day. The vice-chancellor had taken a liking to the stoic, somber and somewhat emotionless girl and took her under his wing. Even though Seteth was strict, grouchy, hard to please, and extremely fussy, he was a fantastic teacher.`}</p>
    <p>{`Garreg Mach University was a `}<em parentName="p">{`very old`}</em>{` and `}<em parentName="p">{`very elite`}</em>{` university, with records showing it was almost 2,000 years old. It was also an enormous structure that sat on the top of a ridiculously large mountain, with a bustling town at its base. A private Garreg Mach Academy took up a small section of the sizable complex, with both university students and high school students sharing the library. It was also where Byleth could be found most of the time - when she was not supporting her twin brother at the pool.`}</p>
    <p>{`The university complex was so big, with various buildings added on almost every century, it could house almost all of its students in its many, many dorms. They also had students who rented rooms and boarding in Garreg Mach town, or just regular day students from the town below. A train station and bus stop had been built at the entrance of Garreg Mach; it took less than 15 minutes for students to commute to town and vice-versa.`}</p>
    <p>{`Fortunately for Byleth and Reus, who were both scholarship students, their lodgings at Garreg Mach University itself was heavily subsidised - so long as they maintained their performance and they shared a room.`}</p>
    <p>{`Reus, and a few of his teammates, had been scouted as part of Garreg Mach’s effort to revive the swimming team, even though Reus was from Garreg Mach Academy.`}</p>
    <p>{`One would think a University on top of a mountain having a swimming team might be ridiculous, but there was a time during the last millenia, Garreg Mach were at the peak of their performance.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Felix Fraldarius, her partner for their history assignment, dropped a stack of books beside her and sat down with a loud groan, earning him an angry ‘shush’ from the librarian.`}</p>
    <p>{`“I take it it wasn’t easy getting these…?” Byleth whispered.`}</p>
    <p>{`Felix shook his head. “The other librarian was an absolute ass about us making sure we kept these books in pristine condition because, I quote, ‘These are the only copies we have left’. Well then scan it and have it safely stored in cloud storage?” He rolled his eyes.`}</p>
    <p>{`She nodded, “I understand. Thanks for offering to get it though.”`}</p>
    <p>{`He shrugged, “Whatever, let’s just get this over with.” Felix had a sharp tongue and didn’t hold back, but he was very reliable.`}</p>
    <p>{`Byleth devoted her full attention to extracting the data and time flew by. Just as they were about to wrap up, a figure loomed over their table.`}</p>
    <p>{`“Oh, it's you,” Felix said flatly as he looked up.`}</p>
    <p>{`Dimitri, yes `}<em parentName="p">{`the`}</em>{` Dimitri was standing at their table. If he was here, it meant that Reus’s practice was over and they could finally get dinner.`}</p>
    <p>{`“I’m not done yet,” Felix said, as if talking to a pet.`}</p>
    <p>{`“I’ll wait,” Dimitri replied gruffly.`}</p>
    <p>{`Byleth looked at Felix, then at Dimitri who was walking away then tripped over his feet— ignoring the whispers and his meek fangirls waving at him— then back at Felix again.`}</p>
    <p>{`“What is it?” Felix asked without looking up, “I can tell when you’re staring.”`}</p>
    <p>{`“Since when were you...so close to Dimitri?” Byleth blurted it out.`}</p>
    <p>{`Felix set his pen down. “It’s...not what you think it is.”`}</p>
    <p>{`“So he’s not your...cousin or something?”`}</p>
    <p>{`“`}<em parentName="p">{`What?!`}</em>{` Ugh no-” A loud ‘shush’ from the librarian interrupted Felix. He hissed angrily, “Why would you think that?”`}</p>
    <p>{`Byleth leaned back into her chair, tapping on her chin then held a finger up. “For one, I’ve never seen you go home with anyone before. Not even when I or our other coursemates invite you out for dinner.”`}</p>
    <Ads mdxType="Ads" />
    <p>{`Felix nodded, “Fair enough.”`}</p>
    <p>{`He snorted, “You’re obsessed with him.”`}</p>
    <p>{`She gasped, “I’m not. I spend a lot of time around the swim team, and half the girls in class talk about him all the time. It’s hard to not notice a few things.”`}</p>
    <p>{`“Okay fine, he’s...like a distant relative or something, I don’t know. So my father says I have to live with him in our apartment downtown until we graduate.” Felix groaned, “He lost his keys in the ocean the other day so while a new one is being made, he’s been going home with me.”`}</p>
    <p>{`“Oh...I didn’t know,” she paused. An awkward silence shifted between them before she spoke up again, “Well you go on then. I’ll clean up here.”`}</p>
    <p>{`“No.”`}</p>
    <p>{`“No seriously, you should go. Dimitri looks...like a fish out of water in the library.” They both turned their heads at the blonde figure standing at the door, his hair still somewhat damp and looking rather alarmed at the female group nearby whispering loudly about him. “—and before those girls completely swarm him.”`}</p>
    <p>{`“Fine.” Felix sighed and hesitantly started packing. “I’ll send you my section when I’m done. We can go over it again tomorrow.”`}</p>
    <p>{`She nodded.`}</p>
    <p>{`As soon as they were out of sight, Byleth let out a long exhale and flexed her stiff arms. It was as though Felix was reluctant to spend time with Dimitri. Maybe he didn’t like sharing a space with someone so broody? She snorted to herself at the irony; if there was a championship for being grumpy, Felix would win first place.`}</p>
    <p>{`She trudged back to the dorm to drop her things off, opening the door to find Mercedes, Annette and Reus having a chat while they waited for her.`}</p>
    <p>{`The dorm provided decent meals that were included in their monthly fee, but any extras were to be paid from their own pockets. A lot of students had their meals in the cafeteria, even the ones who didn’t live on campus.`}</p>
    <p>{`Sometimes, Byleth, Reus and other scholarship students helped out in the cafeteria as part of their `}<RemoteLink to="https://www.hotcoursesabroad.com/study-abroad-info/student-finances/responsibilities-of-a-scholarship-recipient/" mdxType="RemoteLink">{`contract`}</RemoteLink>{`. Reus had life-guard duty once a week when the actual, paid life-guard had his day off, while Byleth sometimes did exam invigilator duties. They had monthly schedules and mostly stuck to jobs they were good at. It was actually a really good learning experience.`}</p>
    <p>{`“Thanks,” Byleth said to Ashe, also a member of Reus’s team and a scholarship student as he handed her her tray of food at the cashier. She and Reus always added extra food.`}</p>
    <p>{`“Hey By,” Reus said as he stuffed a piece of fried chicken into his mouth, “The swim team is having a party next week.”`}</p>
    <p>{`Byleth raised an eyebrow, “What for?”`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Well, assuming we make it into the tournament this weekend, then we’ll have a party,” Reus replied flatly.`}</p>
    <p>{`Anette snorted and quickly covered her mouth, “Oh gosh! Are you guys already planning your victory party?!”`}</p>
    <p>{`Reus shrugged, “Claude said if we lose, then the beer will cheer us up. And if we win, the beer will also cheer us up. Can’t argue with that.”`}</p>
    <p>{`“It’s such a Claude thing to say, isn’t it?” Mercedes chipped in.`}</p>
    <p>{`“Oh you girls are invited too, by the way,” Reus added.`}</p>
    <p>{`“How wonderful!” Mercedes smiled, “This is my final year, a few parties here and there would be nice.”`}</p>
    <p>{`“Would Claude be terribly offended if I brought my own juice?” Annette said sheepishly, “Alcohol and I don’t mix well…”`}</p>
    <p>{`Almost everyone at the table cringed, even Mercedes - remembering the last few times Annette had beer. At first, nobody knew alcohol triggered Annette’s ultimate clumsiness superpower that made her a walking disaster. And this is just the few dinners they’ve had with each other.`}</p>
    <p>{`“I...don’t think he will. It’s...it’s a good idea.” Reus quickly nodded after suppressing a shudder.`}</p>
    <p>{`Anette squealed, “The first party of the term! I’m so excited!”`}</p>
    <p>{`Byleth sighed, “I’m not really a party person to be honest.”`}</p>
    <p>{`“Ugh, me too actually,” Reus added.`}</p>
    <p>{`“The both of you are excellent wallflowers, I must say,” Mercedes giggled.`}</p>
    <p>{`“But…” Reus said, “If we get past the qualifying round, it’ll be quite a historic moment. I don’t think anyone alive has ever achieved this for the swim team. Not since the last century at least.”`}</p>
    <p>{`“The things I do for you.” Byleth patted his back.`}</p>
    <p>{`The next couple of days went by in a stressful blur for Byleth. While Felix could go back to his apartment down at Garreg Mach Town earlier that day, unfortunately he needed to be with Byleth in person for their assignment in the library; which also meant Dimitri sat at their table for a few days, since the only locksmith in town was unfortunately on sick leave. Felix offered many times for Dimitri to take the key first but the blonde insisted on sitting there.`}</p>
    <p>{`Byleth quietly observed Dimitri—she might as well, since he was right in front of her. And he looked very, very, awkward. He used his phone like an old person, squinting at it through his long, damp fringe. He even struggled to type on his laptop, typing with one finger on each hand like a toddler.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“D-do you need glasses?” Byleth finally said, unable to hold it in anymore.`}</p>
    <p>{`Dimitri looked up and glared at her, “Why do you ask me that?”`}</p>
    <p>{`“You look like you need it.” She nodded at his screen.`}</p>
    <p>{`“He’s fine,” Felix scoffed. “Leave him be.”`}</p>
    <p>{`Byleth shrugged and resumed her work. She was slowly getting used to his Felix-esque speech, even though he barely said 3 words to her every day. But she couldn’t deny she was especially appalled by him slamming books on the table, `}<em parentName="p">{`in a library`}</em>{` no less, without so much as an apology.`}</p>
    <p>{`Strangely, despite his ignorant behaviour, Dimitri would help them put their books back into their respective shelves everyday. He’d take a while, and when she turned to see what he was up to, Dimitri was slowly browsing the books, looking thoroughly engrossed with the contents. She didn’t know what to make of him; sometimes he was decent, most of the time he was standoffish.`}</p>
    <p>{`“Would you guys like to join us for dinner tomorrow? We’re going to the party straight after,” Byleth asked, pushing her thoughts back while shutting down her laptop.`}</p>
    <p>{`“I’m not going to the party,” Felix replied curtly. “It’s a waste of time.”`}</p>
    <p>{`Byleth cocked her head to the side, “But...we’ve pretty much finished the assignment? And it’s the weekend?”`}</p>
    <p>{`“Since when did you start having fun, Eisner?” Felix retorted.`}</p>
    <p>{`Byleth shrugged, “Fine, don’t say I didn’t ask.”`}</p>
    <Divider mdxType="Divider" />
    <p>{`Byleth was already up at 4.30am, Reus’s alarm having woken her up. Which was a good thing since she asked to help the two swim team managers, Edelgard and Ingrid, with making breakfast for the team for her scholarship extra credit program.`}</p>
    <p>{`Conveniently, even if they didn’t make it to the national championships, the qualifying rounds were always held in Garreg Mach town, as it was smack in the middle of the continent. Garreg Mach town, apart from where the university students frequented, was also somewhat of a resort town. It had a fair few olympic-sized pools to accommodate the lack of an ocean - plus the facilities to accommodate the multitude of students participating. In fact the town was counting on the business brought in by the qualifying rounds.`}</p>
    <p>{`While the boys were doing light warm ups in their own pool, Byleth, Edelgard, Ingrid assisted the cafeteria staff with the meal prep. The school always sponsored the athletes' meals for tournaments and competitions, regardless of whether the team was guaranteed to advance to the next level or not.`}</p>
    <p>{`The boys gobbled down their protein-packed breakfast, and Byleth’s eyes wandered over to Dimitri, who was sitting next to Reus. Reus, to begin with, was not the chattiest person, and Dimitri looked rather lonely sitting next to him.`}</p>
    <p>{`“Has anyone seen Ashe? His food is going to be cold,” Ingrid asked as she spun around looking for him, “He isn’t in the kitchen.”`}</p>
    <p>{`‘Oh-” Caspar opened his mouth to talk but choked a little, “He `}<em parentName="p">{`-cough-`}</em>{` is having panic diarrhea-” he lightly hammered his chest and swallowed.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“I understand how he feels to be honest,” Ferdinand Von Aegir, the team captain replied. “I’ll go check on him.”`}</p>
    <p>{`When they arrived, the venue was flooded with students, family members and even their own cheer team for the more well known teams. As the regulars started undressing their jerseys down to their speedos, Byleth, although immune to all of their almost naked bodies now, couldn’t help but notice the multitude of scars on Dimitri’s body up close. Some even looked like...bite marks.`}</p>
    <p>{`Strange how she didn’t notice it before, she supposed it wasn’t a polite thing to ask or point out anyway.`}</p>
    <p>{`Dimitri looked awkward and angry earlier but right now, his shoulders were relaxed, and he looked...untroubled.`}</p>
    <p>{`Byleth shook her head free of the Dimitri thoughts invading her mind. It was because he spent all that time with her and Felix in the library she was more partial to him now, even though they barely spoke to each other.`}</p>
    <p>{`“Good luck,” she said to her brother before giving him a quick hug and jogging up to the spectator seats.`}</p>
    <p>{`They all had their own heats to qualify, but the main one everyone anticipated was the relay. It was of course no surprise Dimitri won his event, since he was the star of their team. But amazingly, everyone else qualified in their heats, allowing them to race for medals and subsequently advance to the next round.`}</p>
    <p>{`Last year, almost no one cared about Garreg Mach even though that year’s batch of regulars did well, but it still wasn’t enough to qualify.`}</p>
    <p>{`This year, there were a few reporters and journalists for some magazines even. Garreg Mach was being dubbed as this year’s ‘dark horse’.`}</p>
    <p>{`When the timer stopped and Garreg Mach advanced to the next round, Byleth even allowed herself a squeal and ran down to hug her brother as soon as she was allowed.`}</p>
    <p>{`“Hah! I knew the party was a good incentive!” Claude puffed his chest out with pride.`}</p>
    <p>{`“Shut up.” Sylvain grabbed him and gave a good ruffle on the head.`}</p>
    <p>{`“Oh goddess, Dimitri, are you smiling?” Coach Manuela gasped and everyone swung their heads to see, but the mystical smile had disappeared, replaced by pink cheeks.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Well done, all of you!” Manuela said loudly as she stood on the bench, “You may have the weekend off-” Loud cheers erupted, but then stopped as Coach Manuela held a finger up, “-BUT! I want you all at the pool on Monday at 5.30am sharp! The next round is only 2 weeks away! I will not let this hard earned opportunity slip away! Alright! Off to the showers!”`}</p>
    <Divider mdxType="Divider" />
    <p>{`Reus didn’t really want to admit it, but Dimitri’s presence did calm everyone down. ‘We have Dimitri, it’ll be fine’, as if he was a safety net of sorts for them. Reus was able to completely focus since the pressure wasn’t on him, and thankfully he finished his heat with relative ease since Dimitri swam before him and they were miles ahead with points.`}</p>
    <p>{`Reus almost felt bad for the other team, Dimitri had been headlining all sorts of articles and he actually delivered on his abilities.`}</p>
    <p>{`“Hey, Dimitri,” Claude called out from behind him as they waited for their turn in the showers. “You coming tonight?”`}</p>
    <p>{`Dimitri looked away. “No.”`}</p>
    <p>{`“Are you serious? We can’t have a party without our star!” Claude said and swung an arm around the tall blonde.`}</p>
    <p>{`Dimitri shifted away from Claude and repeated himself, “No.”`}</p>
    <p>{`Just as Claude was about to say something, Dedue stepped in and whispered something into Dimitri’s ear.`}</p>
    <p>{`“Come on Claude, let’s not force the poor bloke yeah?” Sylvain went up to Claude and tugged his arm.`}</p>
    <p>{`“Well the invitation still stands if you’re interested,” Claude added before he strutted into the now vacant shower stall.`}</p>
    <p>{`“Hey what time is it anyway? The party, I mean,” Reus asked out loud as he knocked on Claude’s stall door.`}</p>
    <p>{`“After 7.30pm!” He replied.`}</p>
    <p>{`Reus raised an eyebrow, “What does that mean?”`}</p>
    <p>{`“It means after 7.30pm!” Claude said and the sounds of gushing water drowned out whatever else Claude said.`}</p>
    <p>{`Reus and his sister, Byleth were both raised to be on time and never late, and so they arrived exactly as per Claude’s instructions; at 7.31pm...since he said after 7.30pm. And to no one’s surprise, Claude was still setting up.`}</p>
    <p>{`The twins groaned, this was such a typical Claude thing.`}</p>
    <p>{`“Ahha! I knew you two would be early!” He said as he pushed them into his apartment that he shared with Raphael and Ignatz and shut the door behind them.`}</p>
    <p>{`“Hey guys!” Raphael called out from the kitchen and Ignatz waved at them as he set up the buffet and drinks table.`}</p>
    <p>{`“Sorry about the vague timing,” Ignatz said meekly, “Claude told everyone different times so they wouldn’t all come at once.”`}</p>
    <p>{`Reus nodded, “That’s actually rather clever.”`}</p>
    <p>{`“I know right?” Claude grinned, “Okay so did you guys bring anything?”`}</p>
    <p>{`“No,” Reus said.`}</p>
    <p>{`“Yes,” Byleth replied.`}</p>
    <p>{`Reus turned to his sister, “What?”`}</p>
    <p>{`She sighed, “You come to a party right on time yet forget to bring a gift?” She brought her backpack to the front and pulled out a large bottle of…`}</p>
    <p>{`“Hand sanitiser! So practical, such a Byleth-esque gift!” Claude accepted the gift with a laugh, “We’ll use it right now, I know just the spot for it at the buffet table.”`}</p>
    <Ads mdxType="Ads" />
    <p>{`As they walked in, Byleth dug around her bag and handed Claude another item, “Oh right, here’s the pump cap.”`}</p>
    <p>{`“I could kiss you, you genius!” Claude grabbed her and placed a quick peck on his sister's head as Reus stared daggers at him. Claude turned and winked at him, “You want a kiss too?”`}</p>
    <p>{`Reus groaned and pushed their host out of the way then made his way to the kitchen. “Hello, can I help with anything? I assumed Claude also tricked us into coming early to help.”`}</p>
    <p>{`The gentle giant let out a belly laugh, “That’s Claude for ya! I’m good here, why don’t you take a seat? You’re our guest!”`}</p>
    <p>{`Reus shook his head, “I’ll just get roped into some scheme of his.”`}</p>
    <p>{`“Ah, well if you really wanna help, could you open that pack of crackers and lay it out for the hors d'oeuvres?”`}</p>
    <p>{`Most of the swim team arrived soon after with a flustered Lorenz hauling a crate of beer and wine while Ferdinand came dragging a huge tub of ice for the drinks, save for Dimitri and Dedue, the elusive duo who refused to come.`}</p>
    <p>{`Yuri and Balthus came with a huge bucket of fried chicken which disappeared in minutes, along with the fancy snacks and appetizers Raphael made when it came out. Even though Claude had staggered the timing, almost all of their guests arrived at the same time and by 9pm, the party was in full swing.`}</p>
    <p>{`The TV area had a mini smash bros tournament going on, with Ashe unexpectedly slaughtering all the challengers, and the micro balcony sat Hubert and Edelgard who were sharing a quiet moment together, which was quite romantic since the balcony had great views of the pool below.`}</p>
    <p>{`And since Raphael and Ignatz refused to let the twins or any guest help, the wallflowers were happily huddled together in a corner, including him and his sister, who were both cramped on a beanbag, neither refusing to move due to their personal philosophy, ‘Move your meat, lose your seat’ — and everyone wanted the beanbag.`}</p>
    <p>{`Annette and Mercedes were having a lively conversation with Edelgard after she walked in from the balcony. Apparently, Edelgard joined the swim team as manager to overcome her inability to swim. A fact Reus didn’t know even though he was in the same club as her for almost 2 years.`}</p>
    <p>{`The loud cheering and noisy chatter died down when Claude dragged in the most unlikely trio who walked in to grace his apartment with their presence.`}</p>
    <p>{`Felix, Dedue and Dimitri stood awkwardly as the entire room stared at them.`}</p>
    <p>{`“Holy shit,” Casper said loudly and Dimitri turned his heel and was about to escape but Claude caught him and pushed him back in while grabbing Felix with a free hand.`}</p>
    <p>{`“Nuh-uh, no escape for you lot until you’ve had food, beer and a conversation.”`}</p>
    <p>{`Reus wasn’t sure but he was certain an inhumane groan erupted from Felix.`}</p>
    <Divider mdxType="Divider" />
    <p>{`“Just a question, if you will-”`}</p>
    <p>{`“What?” Dimitri snarled at Yuri who bravely sat next to him.`}</p>
    <p>{`Yuri held his hands up, “It’s...just a question. Not here to attack you.”`}</p>
    <p>{`Byleth nudged Reus who was chatting with someone on messenger and subtly nodded at the dining table next to them. Reus looked at her and she knew he understood what she meant. Even though Dimitri and Yuri were facing them, the twins were quite skilled at the art of eavesdropping.`}</p>
    <p>{`“So...in the shower room this morning, you were pretty adamant on not coming, what brings you here?” Yuri leaned on an elbow as he asked.`}</p>
    <p><em parentName="p">{`Wow.`}</em>{` Byleth thought. `}<em parentName="p">{`Straight to the point. Nice`}</em>{`.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Dimitri looked away, and from what Byleth could see, Dimitri looked annoyed but he still replied nonetheless, “No reason.”`}</p>
    <p>{`“Fair enough,” Yuri placed a hand on his shoulder and got up, “Well, don’t get too broody. This party is a happy one mostly thanks to you.”`}</p>
    <p>{`Reus and Byleth both let out the breath they were holding, it seems both of them were worried Dimitri would explode.`}</p>
    <p>{`Felix trudged over and they had an awkward chat which turned into discussion about their homework together, but then Felix was challenged by the new reigning smash bros champion, Sylvain.`}</p>
    <p>{`“Don’t be so upset Ashe, of course I’m good at smash bros. I `}<em parentName="p">{`smash`}</em>{`—very well,” Sylvain said and winked. Unfortunately the joke was lost on the sweet innocent Ashe.`}</p>
    <p>{`“Okay, that’s it, I give up. I’m going to the bathroom,” Byleth said after about an hour, finally conceding her spot on the beanbag which Reus quickly took over.`}</p>
    <p>{`“Ugh it’s warm,” Reus groaned.`}</p>
    <p>{`Byleth quickly went over to the bathroom only to see a ridiculously long line outside the door. She almost got in line when she remembered how desperate she was to pee. Unfortunately, it was the only bathroom in the apartment, so she decided to try her luck down at the apartment lobby to see if their bathrooms were open.`}</p>
    <p>{`Byleth did the ‘walk’ to hold it in and let out a sigh of relief when the door wasn’t locked. It wasn’t the cleanest, being a semi-public toilet in an apartment block housing students, but it’ll have to do, and thank the goddess it flushed.`}</p>
    <p>{`When she came out, reminding herself to use the hand sanitizer she brought when she got back, something in the corner of her eyes caught her attention.`}</p>
    <p><em parentName="p">{`Dimitri?`}</em>{` She thought to herself. `}<em parentName="p">{`No way`}</em>{`.`}</p>
    <p>{`The blonde mop of hair that belonged to a figure almost sprawled out on the lobby floor, was crawling towards the door that led to the pool. Did he trip again?`}</p>
    <p>{`“D-Dimitri?” The body didn’t respond.`}</p>
    <p>{`“Oh...sorry, wrong person..but..uh...d-do you need help?” She asked, cautiously approaching the stranger.`}</p>
    <p>{`An arm shot out and grabbed her hard, pulling her down to his level, she was met with familiar blue eyes, but they weren’t angry or glaring at her, his eyes were...pleading?`}</p>
    <p>{`“Dimitri? I thought you were someone else- Hey, what’s wrong?”`}</p>
    <p>{`His grip around her wrist didn’t loosen up, but he had sweaty hands...No...his...whole body was...sweating.`}</p>
    <p>{`“-ter…” He murmured.`}</p>
    <p>{`“Sorry?” She bent down lower to hear him.`}</p>
    <p>{`“W-water…” He rasped.`}</p>
    <p>{`“O-oh...umm..hold on. I’ll get Reus to bring a drink-” She said and tried to pull her phone out but he swatted it away.`}</p>
    <p>{`“N-no...the...pool…” Dimitri looked into her eyes, he was sweating so much his shirt was soaked.`}</p>
    <p>{`“You...want to drink from the pool…?” Byleth’s voice was getting increasingly more panicky.`}</p>
    <p>{`“Take...me…” Dimitri grunted as he tried to push himself off the floor and tried crawling again. She understood what he meant but couldn’t understand what was happening. `}<em parentName="p">{`At all`}</em>{`.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“No-Wait...um..h-hold on,” Byleth got up, picked up her phone and shoved it back into her pocket, quickly positioned herself and hoisted Dimitri’s arm around her neck then tried her best to support him without crumbling underneath his sheer weight.`}</p>
    <p>{`When she finally got Dimitri to his feet with a lot of effort from his end, he looked up and stopped. She looked up at his face and it was paler than before.`}</p>
    <p>{`“What’s wrong? What do you need?” She asked.`}</p>
    <p>{`“I can’t…” He wheezed, “I can’t...too..many...people..”`}</p>
    <p>{`“Can you...I don’t know, go somewhere else for...water? A sink?” She looked around for a more private source of water, whatever for, she still didn’t know.`}</p>
    <p>{`He shook his head and continued limping over, Byleth had no choice but to comply. Dimitri seemed to be in dire straits. He didn’t seem to be faking anything and he looked like he was even struggling to breathe. He couldn’t articulate what was wrong with him, yet he didn’t let her call for help.`}</p>
    <p>{`“Hey, hey Dimitri, look at me,” she stopped him, placing a hand on his chest, “I’ll call 711 for help okay? You need help-”`}</p>
    <p>{`“No…!” He grunted at her. “Just...take me...to the-” he wheezed again, “-pool.”`}</p>
    <p>{`“Do you absolutely need the pool?” He nodded weakly.`}</p>
    <p>{`“Do you need it...devoid of people?” She looked into his eyes and he gave another weak nod, followed by a painful looking inhale for air. She paused, trying hard to think of a way to clear the pool and the people there.`}</p>
    <p>{`“J-just hold on.” Byleth gingerly set Dimitri down on a lounge chair and he instantly went limp.`}</p>
    <p>{`Byleth jogged to the edge of the pool, pretending to look at something and loudly said, “Oh...ew...is that...is that diarrhea?”`}</p>
    <p>{`“What?!” A person nearby said, “Where?!”`}</p>
    <p>{`“Oh I think it just got sucked into the water pump…” Byleth said even louder.`}</p>
    <p>{`“Ew! Guys! Get out of the pool! Someone shat in it!” Another person cried out. And in minutes, everyone, including the people who were casually hanging out on the pool loungers disappeared.`}</p>
    <p>{`“That worked surprisingly well,” she said to herself with a chuckle as she jogged back to Dimitri. He was still breathing and barely conscious. It didn’t feel right, she should’ve called 711. Which she swore to do as soon as she got Dimitri into the pool.`}</p>
    <p>{`She only just managed to drag Dimitri to the edge of the pool when he opened his eyes and instead of splashing his face with the water like she assumed he would, Dimitri hauled himself into the pool.`}</p>
    <p>{`“Dimitri!” She cried out, he sank to the bottom and didn’t move. Panic was properly setting in now because Dimitri was drowning and she didn’t know how to swim.`}</p>
    <p>{`More than that, adrenaline kicked in and all logic flew out the window. Byleth bit her lips and jumped in, cursing the fact she never took swimming seriously.`}</p>
    <p>{`Whatever adrenaline her body was producing, it wasn’t enough. As the water engulfed all her senses, fear began to rise in her chest at an alarming pace. She forced her eyes open but could barely keep it open because the chlorine was stinging her eyes. She flapped around, trying to reach the surface for air but the deathly silence of being underwater disoriented her. `}<em parentName="p">{`Straight up, straight up!`}</em>{` She frantically repeated to herself. No matter how much she flailed, Byleth never seemed to break the surface. In fact, it felt like the water was devouring her the harder she tried.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Rescuing Dimitri’s lifeless body disappeared from her mind as her ‘fight’ instinct to survive activated. The pool turned out deeper than expected, even if she was calm and knew the basics of swimming, the odds of actually helping him were low. So many things went through her mind, her number one regret at the moment was not asking for help when she should’ve. She should’ve recruited the help of the other pool go-ers. Why did she listen to a man she barely knew and even disliked a little? He wasn’t even rational.`}</p>
    <p>{`The water she was forcefully swallowing burned her nose and throat as she trashed about, the water was heavy on her limbs as she still tried her best to open her eyes. She saw glimpses of light and tried to push herself towards it. But she was feeling tired, exhaustion was setting in.`}</p>
    <p><em parentName="p">{`This is it.`}</em></p>
    <p><em parentName="p">{`This is the end...Reus… Someone.... Anyone… Help...`}</em></p>
    <p>{`Byleth gave up trying to open her eyes and as she let out the last breath of air and the strength from her arms and legs vanished, she felt scared. Her life was ending `}<em parentName="p">{`just like that`}</em>{`.`}</p>
    <p>{`But as the hope disappeared, and after what felt like an eternity, she felt a gentle pressure on her lips and...air...fresh air filling her lungs. Her body wasn’t moving, she knew because she could still feel the weight of the water surrounding her, but slowly...it began to feel almost like...nothing.`}</p>
    <p>{`She opened her eyes and right in front of her was a face.`}</p>
    <p>{`A face with blue eyes, blonde hair and a mouth that was kissing her.`}</p>
    <p>{`Underwater.`}</p>
    <p>{`Almost forgetting she was underwater, she shoved the face away and it was...`}<em parentName="p">{`Dimitri?`}</em>{` Why was he kissing her? Isn’t he dead? Why is he-`}</p>
    <p><em parentName="p">{`Was..that..what...was that a...tail?`}</em></p>
    <p>{`She didn’t realise it but the chlorine and water weren’t stinging her eyes anymore, she was breathing in water as natural as she was breathing air. As if...water was air.`}</p>
    <p>{`But she didn’t notice that, all she could see and think about was the magnificent, the most beautiful shade of blue she had ever seen, dotted with pink iridescent scales on a tail that was attached to...a person.`}</p>
    <p>{`She blinked, and blinked. Looking at the tail and where it connected, and more importantly, to whom it was connected to.`}</p>
    <p><em parentName="p">{`What the f-`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      