import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DiscussionEmbed } from 'disqus-react';

import config from './config';
import $ from '../../styles/globals';

const Section = styled.section`
  margin: ${$.layout.margin8}px 0;
`;

const Container = styled.div`
  ${({ clicked }) =>
    clicked === true
      ? `
    background-color: #f9f9f9;
    border-radius: 5px;
    padding: ${$.layout.padding2}px;
    `
      : ''}
`;

const Button = styled.button`
  width: 100%;
  padding: ${$.layout.padding3}px;
  border: 1px solid ${$.brown2};
  font-size: 18px;
  font-family: Raleway Bold;
  color: ${$.brown4};
  text-transform: uppercase;
  background-color: ${$.brown1};
  transition: all 0.3s ease;

  ${$.screen.desktop} {
    &:hover {
      cursor: pointer;
      color: ${$.color.white};
      background-color: ${$.brown2};
    }
  }
`;

const CommentSection = ({ title, slug, className }) => {
  const [clicked, setClicked] = useState(false);
  const disqusConfig = config({ title, slug });

  return (
    <Section id="disqus-comments" className={className}>
      <Container clicked={clicked}>
        {clicked === true ? (
          <DiscussionEmbed shortname="geeknabe" config={disqusConfig} />
        ) : (
          <Button
            title="We use Disqus here. Click to comment!"
            onClick={() => {
              setClicked(true);
            }}
          >
            Click to comment
          </Button>
        )}
      </Container>
    </Section>
  );
};

CommentSection.defaultProps = {
  className: '',
};

CommentSection.propTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default CommentSection;
