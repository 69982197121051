import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const RemoteLink = makeShortcode("RemoteLink");
const Ads = makeShortcode("Ads");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Soo-Won is an enigma right from the start of Akatsuki no Yona. We were first introduced to Soo-Won as a kind and soft-spoken cousin to Yona. He sees Yona as a little sister but Yona sees him more than just a sweet cousin that grew up together in childhood. Together with Hak, they were inseparable even as royalties and a tribe general. Throughout the plot, we see side stories that further explore the relationship between the three.`}</p>
    <p><em parentName="p">{`Note: This contains spoilers for Chapter 185. Read at your own risk. The images are sourced from Amazon. `}<RemoteLink to="https://www.amazon.com/Yona-Dawn-Vol-Mizuho-Kusanagi/dp/1421587815" mdxType="RemoteLink">{`Here`}</RemoteLink>{` and `}<RemoteLink to="https://www.amazon.com/Yona-Dawn-Vol-Mizuho-Kusanagi/dp/1421587904/ref=pd_sbs_14_img_0/146-8543843-9346200?_encoding=UTF8&pd_rd_i=1421587904&pd_rd_r=a306ffa1-62ac-48f4-818f-a02c4319c15d&pd_rd_w=LE8Fx&pd_rd_wg=8J0ZK&pf_rd_p=5cfcfe89-300f-47d2-b1ad-a4e27203a02a&pf_rd_r=EM9C6NX07TKN8ZD26YPY&psc=1&refRID=EM9C6NX07TKN8ZD26YPY" mdxType="RemoteLink">{`here`}</RemoteLink>{`. The current chapter (Chapter 192) now began to unveil what happened between King Il and his brother, Yu-Hon. This post is written before that :)`}</em></p>
    <Ads mdxType="Ads" />
    <p>{`It’s obvious to see that despite the crux of Akatsuki no Yona `}<strong parentName="p">{`IS`}</strong>{` Yona and her four dragons, the bonds between Hak, Yona and Soo-Won is equally important to the story.`}</p>
    <p>{`Soo-Won is an exemplary king, focusing on the people’s welfare and strengthening the Kouka Kingdom, a feat unachievable for the late King Il. As a person which such grand ideas, you would think he’ll bring himself more arrogantly than the rest of his peers. Strangely, he doesn’t put himself on a pedestal. In most stories, the character playing the role of a king is either a character adhering to noblesse oblige or someone who thinks he’s above the rest, but Soo-Won is neither of these cliches.`}</p>
    <p>{`On the contrary, he is a detached person. He’s oddly sympathetic at times but only when necessary. He embraces topics of `}<em parentName="p">{`the `}</em>{`future, but dismisses ideas of `}<em parentName="p">{`HIS `}</em>{`future. He’s apathetic to almost `}<strong parentName="p">{`everything`}</strong>{` save for his goals - to strengthen the Kouka Kingdom. It’s admirable but no human can this big-hearted without personal motive sneaking in, which is why I find it strange how Soo-Won was portrayed in the story.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Sympathetic and apathetic in one package`}</h2>
    <p>{`I believe what was portrayed of Soo-Won in the first chapter is his true face unmasked. He is close to Yona and Hak, and regard them highly. He is soft-spoken but shown to be an adept with archery on horseback.`}</p>
    <p>{`He `}<strong parentName="p">{`IS`}</strong>{` aware of Yona’s confession but chose to ignore them. If we have only read the first few chapters, anyone would have assumed that the reason for this is because Soo-Won has steeled himself to murder her father in cold blood that night. However, the story has advanced way beyond that point. We now know Soo-Won had a noble motive(?) all along. The kind-hearted Soo-Won is in there somewhere but first, his goals take priority. I’ve honestly thought this is the only reason why Soo-Won is apathetic to anything unrelated to his goals.`}</p>
    <p>{`While Yona appeals to the dragon through heartfelt emotions, Soo-Won is calculative `}<strong parentName="p">{`AND `}</strong>{`charismatic. He did what he must to fulfil his goals in the quickest way. Initially, his actions rubbed me as a natural-born leader with a noble heart. I’ve seen many threads in `}<RemoteLink to="https://www.reddit.com/r/akatsukinoyona" mdxType="RemoteLink">{`Reddit `}</RemoteLink>{`questioning his actions and motives. I personally thought nothing of it because these characters aren’t exactly rare in fantasy stories. He simply wants to fulfil his father’s wish and do what he thinks he must do. But things start getting fishy. He avoids talking of a future but more for the present. Once again, I think nothing of it until chapter 185 happened.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Living with a time constraint`}</h2>
    <p>{`To be fair, there have been hints littered across the chapters. He shot down the talks to get a fiancée and rejects the notion of a heir. He thinks relying on the power of dragons is absolute hogwash too. I thought he is just following his principles but all along, `}<strong parentName="p">{`he’s just ill`}</strong>{`. `}<strong parentName="p">{`I have a good feeling it’s terminal too`}</strong>{`. If we were to fit this puzzle piece into the picture, everything makes sense.`}</p>
    <p><em parentName="p">{`EDIT: I KNEW IT. RECENT CHAPTER CONFIRMS IT`}</em></p>
    <h3>{`He committed regicide to avenge his father, and to get Kouka Kingdom back on her feet.`}</h3>
    <p>{`If you’re on Death Note’s to-do list, I’d scurry my feet too. Waiting for King Il to abdicate seems like an impossible plan, so off the king goes.`}</p>
    <h3>{`The tribe generals have been trying to play matchmaker, but he seems adamant to stay single.`}</h3>
    <p>{`It wasn’t that he’s disinterested in ladies. It’s a waste of time to get invested. Besides, we have all seen what it did to Zeno ):`}</p>
    <Ads mdxType="Ads" />
    <h3>{`A heir is impossible`}</h3>
    <p>{`No, I’m pretty sure he’s potent. He mentioned that raising a good one takes time which further fuels my suspicion that time is ticking for him.`}</p>
    <h3>{`Immortality sounds nice`}</h3>
    <p>{`He asked to talk to Zeno specifically to question him about his powers. He could have led the whole talk with things like `}<em parentName="p">{`“How does it work”`}</em>{`, or `}<em parentName="p">{`“Would you work with us”`}</em>{`. Instead, he questioned Zeno along the line `}<em parentName="p">{`“Does immortality exist”`}</em>{` instead. He was curious and probably had hopes it may help with whatever it is he is suffering from.`}</p>
    <h2>{`Next chapter will be interesting`}</h2>
    <p>{`At the end of the chapter, Soo-Won collapsed into the arms of Yona. We see her biting back the hate she felt rushing back as she consoles the current king. Soo-Won looks to be in the mood to talk too! We may get more information on just what his illness is all about, and the truth to the regicide.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      