import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const RemoteLink = makeShortcode("RemoteLink");
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This blog isn't built upon Wordpress, so I didn't get a pingback when `}<RemoteLink to="https://twitter.com/gloriagordon648" mdxType="RemoteLink">{`thenerdygirlnews `}</RemoteLink>{`nominated me in her `}<RemoteLink to="https://www.myanime2go.com/2019/12/real-neat-blog-award.html" mdxType="RemoteLink">{`blog post`}</RemoteLink>{`. `}<RemoteLink to="https://twitter.com/YumDeku" mdxType="RemoteLink">{`YumDeku`}</RemoteLink>{` was nice enough to message me when he added me to the list, so thanks for that! I'm super elated that folks in `}<RemoteLink to="https://www.myanime2go.com/" mdxType="RemoteLink">{`myanime2go.com`}</RemoteLink>{` nominated me. I’m so sorry it took so long to reply! Writing this took awhile. Thank you for nominating me!`}</p>
    <Ads mdxType="Ads" />
    <p>{`First things first, what is Real Neat Blog Award? I did google around to find the source, but it seems like a trend that first started from `}<RemoteLink to="https://dearkitty1.wordpress.com/2014/12/03/real-neat-blog-award-congratulations-to-the-nominees/" mdxType="RemoteLink">{`this blog`}</RemoteLink>{`. It has since gotten viral, evidently seen from the `}<RemoteLink to="https://www.google.com/search?q=real+neat+blog+award&oq=real+neat+blog+a&aqs=chrome.0.0j69i57j69i60l3.1928j0j4&sourceid=chrome&ie=UTF-8" mdxType="RemoteLink">{`first page of Google search`}</RemoteLink>{` result when you look for “`}<em parentName="p">{`real neat blog award”. `}</em>{`It's flooded with blog posts from the chain started by Real Neat Blog Award nomination!`}</p>
    <p>{`The original logo is really cute, but I choose to use the popular logo everyone seems to be using. So let's get started! I'm supposed to show the rules first.`}</p>
    <ol>
      <li parentName="ol">{`Display the logo (Done!)`}</li>
      <li parentName="ol">{`Thank the blogger for the award (Done!).`}</li>
      <li parentName="ol">{`Answer the questions from the one who nominated you.`}</li>
      <li parentName="ol">{`Nominate 7 to 10 bloggers.`}</li>
      <li parentName="ol">{`Ask them 7 questions.`}</li>
    </ol>
    <p>{`Next up, is the answers to the questions. Why must you ask such difficult questions YumDeku ahhhhh`}</p>
    <Ads mdxType="Ads" />
    <h2>{`1. Who is your Waifu/Husbando?`}</h2>
    <p>{`I have this immense crush on Kurapika when I was young. Back in the 2000s, the 1999 version was airing on TV so I got to know Hunter X Hunter from there. Watching the happy band of friends trying their darndest to survive in a deadly exam is something new to me. Most shows they choose to air on TV tends to be on the kid-friendly side, so HXH is seriously a bold move back then. The studio even chose to air it in the evening, when most kids are already back from school! I'm convinced that the answer to that is because someone on the TV's recommendation team is a weeb.`}</p>
    <Image alt="Kurapika from Hunter X Hunter" caption="Kurapika from 1999? I just picked a graphic that looks good. He's always good-looking though, no pressure." name="kurapika.jpg" mdxType="Image" />
    <p>{`When the spider arc started, I was overjoyed. An arc focusing on Kurapika? Fantastic. 1999's version is a totally different league compared to the other vanilla shows back then. If there's a spot for Most Memorable Husbando from Childhood, it's Kurapika.`}</p>
    <Ads mdxType="Ads" />
    <p>{`I know the question asked Waifu/Husbando, but I insist on answering who's my waifu too. Thank you male weebs, for it is your contribution and honest preferences that I am spoiled for choice when it comes to waifu material. Most Memorable Waifu goes to… the Driver and Blade pair from `}<RemoteLink to="https://en.wikipedia.org/wiki/Xenoblade_Chronicles_2" mdxType="RemoteLink">{`Xenoblade Chronicles 2`}</RemoteLink>{`, Morag and Brighid.`}</p>
    <Image alt="Morag and Brighid from Xenoblade Chronicles 2, a game exclusive to Nintendo Switch." caption="'Experience begets wisdom.' This graphic is from the JP site. From the left, Morag and Brighid." name="morag_and_brighid.png" mdxType="Image" />
    <p>{`It’s from a recent game, but these two comes to mind whenever someone mentions `}<em parentName="p">{`waifu. `}</em>{`There are a ton of other female characters that are written really well and had great designs, but nothing sticks to the back of my head as much as Morag and Brighid. I like Morag because she’s a model character I look up to - dutiful yet true to herself. Not many can do that.`}</p>
    <p>{`As for Brighid, well, what really caught my eye is her elegant design. I really like her flames and her dress. It looked really graceful! Together with Morag, they make for an interesting duo. Whenever I saw a 2D female character, the first thought that came to me is, “Oh, a girl.”. But whenever I saw the driver-blade pair, I thought of them as Morag and Brighid first.`}</p>
    <h2>{`2. What is your favourite anime opening?`}</h2>
    <p>{`I am glad you asked. Here you go!`}</p>
    <Video title="favourite op" src="https://www.youtube.com/embed/pOHwVZc6QYY" mdxType="Video" />
    <Ads mdxType="Ads" />
    <h2>{`3. Along the same lines, what’s your favourite anime soundtrack?`}</h2>
    <p>{`Alrighty, this is a wee bit tough. Most anime soundtracks don’t get uploaded or sold anywhere through official streaming platforms so it’s hard to find it. I used to go to Youtube often to listen to them. It was hell back then without the loop feature. I need to find a site to loop the song or find a song that includes `}<em parentName="p">{`looped/extended`}</em>{` in their Youtube video title. I have a ton of favourites! I couldn’t recall the older ones so here are some newer ones.`}</p>
    <ol>
      <li parentName="ol">
        <RemoteLink to="https://www.youtube.com/watch?v=1wG04fLGooI" mdxType="RemoteLink">
  Houseki no Kuni OST
        </RemoteLink>
      </li>
      <li parentName="ol">
        <RemoteLink to="https://www.youtube.com/watch?v=BkVx4cXVmSU&list=PLJbWNarT9D8msO9D8p3nvq7Wj9FBQvvkC" mdxType="RemoteLink">
  Violet Evergarden OST
        </RemoteLink>
      </li>
      <li parentName="ol">
        <RemoteLink to="https://www.youtube.com/watch?v=aXFpZ8fBALc" mdxType="RemoteLink">
  Made in Abyss OST
        </RemoteLink>
      </li>
      <li parentName="ol">
        <RemoteLink to="https://www.youtube.com/watch?v=7r3F59K636s&list=PLuGpmoWjxXwZ_Uu4gOOb9_JCAAB-N-6I9" mdxType="RemoteLink">
  Guilty Crown OST
        </RemoteLink>
      </li>
      <li parentName="ol">
        <RemoteLink to="https://www.youtube.com/watch?v=1uOpZtI7kC8" mdxType="RemoteLink">
  Fate Apocrypha OST
        </RemoteLink>
      </li>
      <li parentName="ol">
        <RemoteLink to="https://www.youtube.com/watch?v=LVpMFdBbzKw" mdxType="RemoteLink">
  Madoka Magica OST
        </RemoteLink>
      </li>
      <li parentName="ol">
        <RemoteLink to="https://www.youtube.com/watch?v=YllRGyLwPWY" mdxType="RemoteLink">
  Kill la Kill OST
        </RemoteLink>
      </li>
      <li parentName="ol">
        <RemoteLink to="https://www.youtube.com/watch?v=ZAc9_LNCEFM&list=PLPjgBWiybDLDxWLZHSCW0QJsuIIbSomI_" mdxType="RemoteLink">
  Akatsuki no Yona OST
        </RemoteLink>
      </li>
    </ol>
    <h2>{`4. What is your LEAST favourite anime genre?`}</h2>
    <p>{`Horror. The only reason is that I don’t do well with horror.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`5. If you could go into any anime world, which one would it be?`}</h2>
    <p>{`Hmmmm. I’m in a pickle. There are so many worlds I want to get in! But if I get to choose only one, then it’s most definitely the world of Pokemon. I first got into the series not from the games, but from the anime first. Catching Pokemon and battling with them? It was a crazy idea back then! Each Pokemon is unique and every episode from S1 had me hooked.`}</p>
    <h2>{`6. What advice would you have for new anime bloggers trying to get started?`}</h2>
    <p>{`I just got started myself so I honestly don’t think I’m in any position to advise newcomers. But if it’s about marketing it, then yes, I have some. Most newcomers wait for viewers to come read your blog. If you’re on Wordpress, then worry not because the platform helps you to promote. Regardless, no one will read your content if you don’t get the word out. You need to `}<strong parentName="p">{`PROMOTE! `}</strong>{`Get your hands dirty by signing up social media platforms where your potential readers are. `}<strong parentName="p">{`Tell the world`}</strong>{` you have articles waiting to be read!`}</p>
    <p>{`Be aware of how you `}<strong parentName="p">{`word your titles`}</strong>{`. Most folks like to word it vague because it sounds good. Unfortunately, if the title doesn’t give a good first impression to the readers, no one will click it. Make sure your titles inform your readers what to expect. Throw in some clickbaity words. `}<strong parentName="p">{`It’s fine to have clickbait titles if the content is good.`}</strong></p>
    <Ads mdxType="Ads" />
    <p><strong parentName="p">{`Look up SEO`}</strong>{` and try your best to understand SEO keywords. It helps in the long run of getting a constant, steady stream of readers from Google search. I enjoy reading articles from `}<RemoteLink to="https://neilpatel.com/blog/" mdxType="RemoteLink">{`Neil Patel`}</RemoteLink>{` as I find them really easy to understand and informative in general.`}</p>
    <h2>{`7. What anime animal sidekick, is your favourite?`}</h2>
    <p>{`Pukyuu from `}<RemoteLink to="https://en.wikipedia.org/wiki/Yona_of_the_Dawn" mdxType="RemoteLink">{`Akatsuki no Yona`}</RemoteLink>{`. Her name is Ao but her noises (pukyuu!) is so adorable. I nicknamed her Pukyuu in my head whenever I saw her in the manga chapters. Here’s a video of the pukyuu noises urgh my heart`}</p>
    <Video title="pukyuu" src="https://www.youtube.com/embed/3Wg603gPTnI" mdxType="Video" />
    <h2>{`Which bloggers are next?`}</h2>
    <p>{`I'm done answering the questions <3 Now it’s time to nominate the next seven lucky bloggers!`}</p>
    <ol>
      <li parentName="ol">
        <RemoteLink to="https://pinkiespokemon.com/1/" mdxType="RemoteLink">Pinkie’s Paradise</RemoteLink>
      </li>
      <li parentName="ol">
        <RemoteLink to="https://agirlandheranime.wordpress.com/" mdxType="RemoteLink">
  A Girl & Her Anime
        </RemoteLink>
      </li>
      <li parentName="ol">
        <RemoteLink to="https://crackerzheads.home.blog/" mdxType="RemoteLink">Crackerzheads</RemoteLink>
      </li>
      <li parentName="ol">
        <RemoteLink to="https://otakuauthor.com/" mdxType="RemoteLink">The Otaku Author</RemoteLink>
      </li>
      <li parentName="ol">
        <RemoteLink to="https://meriendeato.com/" mdxType="RemoteLink">Meriendeato</RemoteLink>
      </li>
      <li parentName="ol">
        <RemoteLink to="https://galvanicmedia.net/" mdxType="RemoteLink">Galvanic</RemoteLink>
      </li>
      <li parentName="ol">
        <RemoteLink to="https://nanamarfo.weebly.com/" mdxType="RemoteLink">Nana Marfo</RemoteLink>
      </li>
    </ol>
    <Ads mdxType="Ads" />
    <p>{`And now! Here are the questions from me :D`}</p>
    <ol>
      <li parentName="ol">{`It’s going to be 2020 soon, so what’s YOUR No. 1 anime of the decade?`}</li>
      <li parentName="ol">{`What character (anime/manga/games, take your pick) has left the strongest impression on you?`}</li>
      <li parentName="ol">{`Who’s your waifu/husbando?`}</li>
      <li parentName="ol">{`What is your take on fans’ acclaimed Top 3 (Naruto/Bleach/One Piece)?`}</li>
      <li parentName="ol">{`What advice do you have for new bloggers?`}</li>
      <li parentName="ol">{`What anime would you recommend to your friends that have never seen a single episode in their life?`}</li>
      <li parentName="ol">{`What’s your opinion on fanservice in anime?`}</li>
    </ol>
    <p>{`That’s all folks. Thank you very much to `}<RemoteLink to="https://www.myanime2go.com/2019/12/real-neat-blog-award.html" mdxType="RemoteLink">{`myanime2go.com`}</RemoteLink>{` for nominating me. Cheers to those I nominated to continue the chain!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      