import { graphql } from 'gatsby';
import { BreadcrumbJsonLd } from 'gatsby-plugin-next-seo';
import PropTypes from 'prop-types';

import SEO from '../components/Seo';
import Content from '../components/MDXBlogpost/IndexPage/Content';
import Banner from '../components/MDXBlogpost/IndexPage/Banner';
import keywords from '../utils/keywords';

const BlogIndexPage = ({ data }) => (
  <>
    <SEO
      title="Blog Index | Geeknabe - ACG Blog"
      description="Find a collection of every blog posts written by nabe-chan and mari-chan, the team behind Geeknabe."
      meta={[
        {
          property: 'og:type',
          content: 'website',
        },
      ]}
      keywords={keywords}
    />
    <BreadcrumbJsonLd
      itemListElements={[
        {
          position: 1,
          name: 'Latest Posts',
          item: 'https://geeknabe.com',
        },
        {
          position: 2,
          name: 'Blog',
          item: 'https://geeknabe.com/blog/',
        },
      ]}
    />
    <Banner total={data.allMdx.edges.length} />
    <Content items={data.allMdx.edges} />
  </>
);

BlogIndexPage.defaultProps = {
  data: {},
};

BlogIndexPage.propTypes = {
  data: PropTypes.objectOf(PropTypes.object),
};

export const query = graphql`
  query {
    allMdx(filter: { fileAbsolutePath: { regex: "/mdxblog/" } }) {
      edges {
        node {
          frontmatter {
            banner
            excerpt
            published
            tags
            title
          }
          slug
          timeToRead
        }
      }
    }
  }
`;

export default BlogIndexPage;
