import PropTypes from 'prop-types';

import About from '../components/About';
import Seo from '../components/Seo';
import keywords from '../utils/keywords';

const AboutPage = ({ location }) => (
  <>
    <Seo
      title="About the team | Geeknabe - ACG Blog"
      location={location}
      description="Find out more about nabe-chan and mari-chan, the team behind Geeknabe! We love writing about anime, manga and games. Follow our Twitter (we're more active there)!"
      keywords={['about geeknabe', ...keywords]}
      meta={[
        {
          property: 'og:type',
          content: 'website',
        },
      ]}
    />
    <About />
  </>
);

AboutPage.defaultProps = {
  location: {},
};

AboutPage.propTypes = {
  location: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default AboutPage;
