import PropTypes from 'prop-types';
import styled from 'styled-components';

import $ from '../../../styles/globals';
import Timeline from './Timeline';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: initial;
  width: 100%;
`;

const Info = styled.div`
  line-height: 1.5em;
  color: ${$.brown4};
  font-size: 17px;
  padding-left: ${$.layout.padding3}px;
  padding-bottom: ${$.layout.padding8}px;

  a {
    font-family: Rubik Regular;
    font-weight: bold;
    text-decoration: none;
    color: ${$.color.orange};
    &:visited {
      color: ${$.color.orange};
    }
  }

  strong {
    font-family: Rubik Regular;
    font-weight: bold;
  }

  h3 {
    font-family: Rubik Regular !important;
    font-weight: bold;
    font-size: 18px;
    margin-top: ${$.layout.margin3}px;
    margin-bottom: ${$.layout.margin1}px;
  }

  ${$.br}

  ul,
  ol {
    list-style-type: square;
    margin: ${$.layout.margin2}px 0;
    padding-left: ${$.layout.padding4}px;
  }

  ${$.screen.desktop} {
    width: calc(85% - ${$.layout.padding3}px);

    a {
      color: ${$.color.orange};
      transition: color 0.25s ease;
      &:hover {
        color: ${$.brown5};
      }
    }
  }

  ${$.screen.tablet} {
    width: calc(75% - ${$.layout.padding3}px);
  }

  ${$.screen.mobile} {
    width: calc(80% - ${$.layout.padding2}px);
    padding-left: ${$.layout.padding2}px;
    padding-bottom: ${$.layout.padding10}px;
  }
`;

const InnerInfo = styled.div`
  font-family: Rubik Regular;
`;

const Title = styled.div`
  font-family: Rubik Regular;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: ${$.layout.margin3}px;
`;

const Card = ({ note, first, date, last, title, showContinuation }) => (
  <Container>
    <Timeline
      date={date}
      first={first}
      last={last}
      showContinuation={showContinuation}
    />
    <Info first={first} last={last}>
      <Title>{title}</Title>
      <InnerInfo dangerouslySetInnerHTML={{ __html: note }} />
    </Info>
  </Container>
);

Card.defaultProps = {
  note: '',
  title: '',
  first: false,
  date: '',
  last: false,
  showContinuation: false,
};

Card.propTypes = {
  note: PropTypes.string,
  first: PropTypes.bool,
  last: PropTypes.bool,
  title: PropTypes.string,
  showContinuation: PropTypes.bool,
  date: PropTypes.string,
};

export default Card;
