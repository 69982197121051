import PropTypes from 'prop-types';
import styled from 'styled-components';

import $ from '../../styles/globals';
import { addKeys, getPosition } from '../../utils';

const Section = styled.section`
  width: 100%;
`;

const Container = styled.div``;

const NavbarContainer = styled.div`
  ${$.horizontalScroll}
  margin-bottom: ${$.layout.padding10}px;
  text-align: center;

  ${$.screen.mobile} {
    padding-bottom: ${$.layout.padding1}px;
    overflow-x: auto;
  }
`;

const InnerContainer = styled.div`
  overflow: hidden;
  border-radius: ${$.borderRadius.radius1}px;
  border: 2px solid ${$.brown5};
  display: inline-flex;
  flex-direction: row;

  > * {
    border-right: 2px solid ${$.brown5};
    &:last-child {
      border-right: none;
    }
  }

  ${$.screen.desktop} {
    margin: 0 auto;
  }

  ${$.screen.tablet} {
    margin: 0 auto;
  }
`;

const Pill = styled.div`
  font-family: Rubik Regular;
  font-weight: bold;
  color: ${$.brown5};
  text-transform: capitalize;
  background-color: ${$.white1};
  font-size: 16px;
  letter-spacing: 0.2px;
  padding: ${$.layout.padding2}px ${$.layout.padding3}px;

  ${$.screen.desktop} {
    transition: all 0.35s ease;

    &:hover {
      cursor: pointer;
      background-color: ${$.brown5};
      color: ${$.white1};
    }
  }
`;

const tags = addKeys([
  { value: 'anime', text: 'anime' },
  { value: 'manga', text: 'manga' },
  { value: 'games', text: 'games' },
  { value: 'fanfiction', text: 'fanfiction' },
  { value: 'blog', text: 'blog' },
  { value: 'tech', text: 'tech' },
]);

const Navbar = ({ className }) => (
  <Section className={className}>
    <Container>
      <NavbarContainer>
        <InnerContainer>
          {tags.map(({ key, value, text }) => (
            <Pill
              title={`Click to go to ${text} section`}
              key={key}
              onClick={() => {
                let getTagTitle;

                if (typeof window !== 'undefined') {
                  getTagTitle = document.getElementById(
                    `${value}_title_anchor`
                  );

                  if (typeof window !== 'undefined' && getTagTitle) {
                    window.scrollTo(0, getPosition(getTagTitle).top);
                  }
                }
              }}
            >
              {text}
            </Pill>
          ))}
        </InnerContainer>
      </NavbarContainer>
    </Container>
  </Section>
);

Navbar.defaultProps = {
  className: '',
};

Navbar.propTypes = {
  className: PropTypes.string,
};

export default Navbar;
