import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`That's what I'd normally say to the people in my local Nintendo Switch Facebook group when they ask for recommendations on, 'What switch case should I get?'`}</p>
    <p>{`But why you ask? It's simple. No, I mean, the case is simple, plain and it feels solid. It was almost love at first sight for me when I saw the pink one. So much so that, I bought it even before I bought my Switch!`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Pink tomtoc casing for Nintendo Switch" caption="Blue is Dimitri's colour, but have you heard of the colour pink?" name="tomtoc_pic_1.jpg" mdxType="Image" />
    <p>{`Also, I have something to confess, I've never been a fan of Mario/Luigi/Yoshi etc, Pokemon, Legend of Zelda or Splatoon for that matter. Which is why the Tomtoc Switch cases appeal to me. When I was researching for cases for my new baby (the switch), about what, 70%-80% of the search results were either Mario, Zelda or Pokemon. I think you know which cases I'm talking about.`}</p>
    <Image alt="You'll see this almost everywhere! Either this casing or a Zelda one." caption="You'll see this almost everywhere! Either this casing or a Zelda one." name="mario_switch_casing.jpg" mdxType="Image" />
    <p>{`On the off chance you do come across a plain looking case, it's probably made of that type of material that flakes off after a few years or some sort of non water-resistant material.`}</p>
    <Ads mdxType="Ads" /> 
    <Image alt="Zelda PDP casing" caption="It looks dandy now, but after heavy usage the outer layer will start to peel off miserably" name="zelda_pdp_skin_will_peel.jpg" mdxType="Image" />
    <p>{`I've used my pink Tomtoc for close to 3 months now, so here's my very short review!`}</p>
    <Image alt="Notice the little cut outs for my cute Tsum tsum edition buttons 💕💕" caption="Notice the little cut outs for my cute Tsum tsum edition buttons 💕💕" name="switch_casing.jpg" mdxType="Image" />
    <h3>{`PROS:`}</h3>
    <ul>
      <li parentName="ul">{`It's fantastically water resistant! I once went to Mcdonald's for dinner just holding my switch, phone and some cards stuffed inside my switch case. I had to run in the heavy rain back to my car. The water rolled off the case, it didn't sink into the material much, and it evaporated quickly as well.`}</li>
      <li parentName="ul">{`IT HAS AN ADJUSTABLE WRIST STRAP !!!`}</li>
      <li parentName="ul">{`I have the Nyko Thin Case on my switch and it fits perfectly!`}</li>
      <li parentName="ul">{`The colour choices are aesthetically pleasing. (Most likely won't clash with the colour scheme of the inside of your bag)`}</li>
      <li parentName="ul">{`Very easy to clean too! (Wipe with a damp cloth! Although I sometimes use an alcohol pad that I have a box of to clean my stuff - Phone, switch, joycons, controllers, wallet etc)`}</li>
      <li parentName="ul">{`The slightly rough texture feels nice when you're holding on to it. Gives your fingers a bit more to hold on to.`}</li>
      <li parentName="ul">{`If you have small hands and don't need a grip, this case is THE ONE for you.`}</li>
      <li parentName="ul">{`I actually use it as a makeshift 'grip' when I'm holding it sometimes, like unconsciously I'll pick it up together with my switch.`}</li>
    </ul>
    <Ads mdxType="Ads" />
    <h3>{`CONS:`}</h3>
    <ul>
      <li parentName="ul">{`If you NEED a grip to play your switch, want to take it with you on the go, need this particular brand, then oh boy have I got some news for you.`}</li>
      <li parentName="ul">{`It's snug, so you can't fit anything else besides your switch(with plastic case) and the games in the games slot. Maybe a card or an ID if you're just bringing this out.`}</li>
      <li parentName="ul">{`Which basically means it won't fit ANY grip that isn't molded to the shape of the switch. Oh you have a skull & co grip? NOPE. Satisfye grip?? WHOOPS SORRY. To be fair, these grips come with their own cases sooo.`}</li>
      <li parentName="ul">{`The pink model I got is apparently a revision, the original Tomtoc case has different indents for the shoulder buttons AND came with a small velvet pouch for your extras. This is a con for me, because you're getting one less item for the same price. I guess I paid a 'premium' for this cute pink colour.`}</li>
      <li parentName="ul">{`The lighter colours stain easier. My pink already looks like there's a slight grey overlay on it. Wiping it does help a little though, but I suspect it's due to the texture of the material. So if such issues bother you, get the darker colours. Or you could just ignore it, like me! I'm waaay too in love with the pink to let this bother me. (Yes I know, I really like the pink)`}</li>
      <li parentName="ul">{`Food stains or oily substances WILL stain it, pretty much permanently, again I suspect because of the texture.`}</li>
      <li parentName="ul">{`So remember I mentioned I use it as a makeshift 'grip'? Yeah, it's not a good thing per se, because the heat vents are on the back so your switch might become a little warm after a while.`}</li>
    </ul>
    <Ads mdxType="Ads" />
    <p>{`Despite said cons, if I was swimming in money, I'd get all the colours! I'm actually looking into getting the Tomtoc Switch case that comes with a protective case, because my Mumba Blade case didn't work out for me, but that's a story for another time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      