import PropTypes from 'prop-types';
import styled from 'styled-components';

import $ from '../../../../../styles/globals';
import CheckIcon from '../../../../../assets/icons/done.svg';
import CheckBothIcon from '../../../../../assets/icons/done-all.svg';
import CloseIcon from '../../../../../assets/icons/close.svg';

const OneCheckIcon = styled(CheckIcon)`
  fill: #a1a1a1;

  ~ span {
    color: #a1a1a1;
  }
`;

const DoubleCheckIcon = styled(CheckBothIcon)`
  fill: ${$.color.green1};

  ~ span {
    color: ${$.color.green1};
  }
`;

const CrossIcon = styled(CloseIcon)`
  fill: ${$.color.red1};

  ~ span {
    color: ${$.color.red1};
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${$.layout.margin1}px;

  > svg {
    width: 14px;
    height: 14px;
    margin-right: ${$.layout.margin1}px;
  }

  > span {
    font-size: 13px;
    font-family: Helvetica Light;
  }
`;

const SeenStatus = ({ status }) => {
  switch (status) {
    case 'None':
      return '';
    case 'Sent':
      return (
        <Container>
          <OneCheckIcon />
          <span>{status}</span>
        </Container>
      );
    case 'Seen':
      return (
        <Container>
          <DoubleCheckIcon />
          <span>{status}</span>
        </Container>
      );
    default:
      return (
        <Container>
          <CrossIcon />
          <span>{status}</span>
        </Container>
      );
  }
};

SeenStatus.propTypes = {
  status: PropTypes.string,
};

SeenStatus.defaultProps = {
  status: 'None',
};

export default SeenStatus;
