import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Image = makeShortcode("Image");
const Ads = makeShortcode("Ads");
const Blockquote = makeShortcode("Blockquote");
const RemoteLink = makeShortcode("RemoteLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`RATATATATATATATATATA TATATATATATATATA RATATATATATATATATATATATA TATATATATATA RATATATATATATATATATA TATATATATATATATA RATATATATATATATATATATA TATATATATATATA RATATATATATATA TATATATATATATATATATATA RATATATATATATATATAT ATATATATATATATATA PEW PEW PEW PEW PEW PEW PEW PEW PEW PEW PEW PEW PEW PEW PEW BANG BANG BANG BANG BANG BANG BANG BANG BANG BANG BANG BANG`}</p>
    <p>{`SWISH SWISH SLASH SLASH WOOSH WOOSH `}<strong parentName="p">{`SHINY BLOOD SPLATTERS`}</strong></p>
    <p>{`Lots of HEHE. HEHE HEHE HEHE. Examples of HEHE below.`}</p>
    <Image alt="Father Alexander Anderson from Hellsing Ultimate" caption="Since he is Father Alexander Anderson, I have taken a liking to calling him Father Aye Aye RON. If you get this reference, welcome to the club! He's always doing the HEHE." name="hellsing-ultimate-review-2.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <Image alt="Alucard from Hellsing Ultimate" caption="Dude Alucard only has one expression throughout the show, and that's the HEHE." name="hellsing-ultimate-review-1.jpg" mdxType="Image" />
    <p>{`UNLIMITED AMMO EVERYWHERE. Unlimited knives to throw and unlimited ammo on a gun!!`}</p>
    <Image alt="Father Alexander Anderson laughing like a hyena" caption="MWAHAHAHA I have chopped his head and stabby stab it with lotsa knives! How do I keep pulling those knives out like freebies?! I don't know! Must be some plot armor!" name="hellsing-ultimate-review-6.jpg" mdxType="Image" />
    <p>{`BIG GUNS. MORE GUNS. Altogether now! When shooting down folks we need to shoot them as a group!`}</p>
    <Image alt="Seras from Hellsing Ultimate" caption="THE BIGGER THE BETTER" name="hellsing-ultimate-review-11.jpg" mdxType="Image" />
    <Blockquote mdxType="Blockquote">I like war.</Blockquote>
    <Image alt="Major from Hellsing Ultimate" caption="So this is where the meme came from... Dude is crazy and that is fair." name="hellsing-ultimate-review-9.jpg" mdxType="Image" />
    <p>{`VATICAN SECRET ARMY. I CAN’T BELIEVE THEY ARE ALLOWED TO AIR THIS SERIES!`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Uhm... KKK?" caption="You know, this is a fun hypothesis. What if the Vatican really do have secret armies in modern times, and they dress up like this?!" name="hellsing-ultimate-review-15.jpg" mdxType="Image" />
    <p>{`BOOM BOOM BOOM BOOM BOOM BOOM BOOM BOOM BOOM BOOM BOOM BOOM BOOM BOOM BOOM BOOM BOOM BOOM BOOM BOOM BOOM BOOM BOOM BOOM BOOM`}</p>
    <Blockquote mdxType="Blockquote">BOOM BOOM BOOM BOOM BOOM BOOM BOOM BOOM BOOM</Blockquote>
    <Image alt="London on fire from Hellsing Ultimate" caption="London is burning! How on earth did airships trespass your borders??!" name="hellsing-ultimate-review-12.jpg" mdxType="Image" />
    <p>{`Smoking makes anyone look cool, especially lady leaders.`}</p>
    <Image alt="Sir Integra Fairbrook Wingates Hellsing from Hellsing Ultimate" caption="Hur hur, we're in a serious predicament. But first, let me take a smoke. Also my name is Sir Integra Fairbrook Wingates Hellsing! You don't have to remember that because I will remind you at least once per episode." name="hellsing-ultimate-review-7.jpg" mdxType="Image" />
    <p>{`AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA!! AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA!! AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA!! Lots of people dying onscreen for the viewers entertainment! Save them they are just random civilians!!`}</p>
    <Image alt="BOOM" caption="Death by explosion is not fun." name="hellsing-ultimate-review-13.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <Image alt="Lots of guns." caption="ONE GUN IS NOT ENOUGH. I need... TWO GUNS. And this guy is from Vatican!" name="hellsing-ultimate-review-14.jpg" mdxType="Image" />
    <Image alt="ZOMBIES" caption="Fully geared zombies here to bite you! Oh, sorry. I mean, SHOOT YOU. RATATATATA" name="hellsing-ultimate-review-16.jpg" mdxType="Image" />
    <h2>{`All in all`}</h2>
    <p>{`I like it. d(°∀°d) You get fully geared zombies shooting stuff. Lots of shooting. Shoot shoot shoot. Pew pew pew. I could review this earnestly with proper text and sentences, but I couldn’t get my mind out of the shooting. It’s great as background noise while I work! If anyone asked me what I think about Hellsing in general, I probably could only recall the free-for-all gunshots. And explosions. Lots of blood too. Hell yea.This entire show is crazy and I love it for it. It’s proof that you can do great work even if you do not give a damn on buttering up to your readers. This series is just the author wanting to add vampires, zombies and guns into a shooting fiesta! It’s his taste and I’m so in.Hm? You are asking me what I think about the plot? Wait, there’s a plot? This post is partially inspired by this `}<RemoteLink to="https://medium.com/@SnoozeInBrief/an-analysis-of-donald-trumps-election-win-and-the-prospects-for-his-presidency-f6a87eef6d70" mdxType="RemoteLink">{`Medium post`}</RemoteLink>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      