import styled from 'styled-components';
import $ from '../styles/globals';

const Section = styled.section``;

const Container = styled.div`
  ${$.screen.desktop} {
    margin: ${$.layout.margin10}px 0;
  }

  ${$.screen.tablet} {
    margin: ${$.layout.margin8}px 0;
  }

  ${$.screen.mobile} {
    margin: ${$.layout.margin6}px 0;
  }
`;

const Title = styled.h1`
  color: ${$.brown4};
  text-align: center;
  font-family: Porkys !important;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: ${$.layout.margin5}px;

  ${$.screen.desktop} {
    font-size: 60px !important;
  }

  ${$.screen.tablet} {
    font-size: 50px !important;
  }

  ${$.screen.mobile} {
    font-size: 40px !important;
    text-align: left;
  }
`;

const Description = styled.p`
  color: ${$.brown4};
  text-align: center;
  line-height: 1.4em;
  margin: 0 auto;
  width: 100%;
  max-width: 500px;
  font-size: 18px;

  a {
    font-family: Rubik Regular;
    font-weight: bold;
    text-decoration: none;
    color: ${$.color.orange};
  }

  ${$.br}

  ${$.screen.mobile} {
    max-width: initial;
    text-align: left;
  }
`;

const ScrollToButton = styled.button`
  color: ${$.color.white};
  border: 2px solid ${$.color.orange};
  border-radius: 5px;
  background-color: ${$.color.orange};
  font-family: Rubik Regular;
  font-size: 17px;

  ${$.screen.desktop} {
    transition: all 0.25s ease;
    &:hover {
      cursor: pointer;
      color: ${$.color.orange};
      background-color: ${$.color.white};
    }
  }
`;

const NotFound = () => (
  <Section>
    <Container>
      <Title>404 Not found</Title>
      <Description>
        Oh dear. The page you were looking for does not exist!
        <br />
        {'Are you looking for my blog posts? You can find them on my '}
        <a href="/">homepage</a>
        {
          '. To find other pages such as web apps, topics and fanfiction, please look at my '
        }
        <ScrollToButton
          onClick={() => {
            if (typeof window !== 'undefined') {
              window.scrollTo({ top: window.outerHeight, behavior: 'smooth' });
            }
          }}
        >
          footer
        </ScrollToButton>
        {' or the '}
        <ScrollToButton
          onClick={() => {
            if (typeof window !== 'undefined') {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }
          }}
        >
          top navigation bar
        </ScrollToButton>
        !
      </Description>
    </Container>
  </Section>
);

export default NotFound;
