import { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../../styles/globals';

const Container = styled.div`
  ${({ toggle }) => `
    background-color: ${toggle ? $.brown5 : 'transparent'};
    color: ${toggle ? 'transparent' : $.color.green2};
    &:hover {
      cursor: pointer;
    }
`}
`;

const SpoilerTag = ({ children }) => {
  const [toggle, setToggle] = useState(true);

  return (
    <Container
      toggle={toggle}
      onClick={() => {
        setToggle((prev) => !prev);
      }}
    >
      {children}
    </Container>
  );
};

SpoilerTag.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
    PropTypes.string,
  ]).isRequired,
};

export default SpoilerTag;
