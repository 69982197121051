import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Since the whole lockdown season started, everyone’s been clamoring for a copy of Ring Fit Adventure or Animal Crossing: New Horizons. This caused many of the local game sellers from my place to jack up the price for both games with an `}<strong parentName="p">{`unholy increase`}</strong>{` which is... to be expected, honestly. Animal Crossing aside, I noticed many new Nintendo Switch owners were curious about Ring Fit Adventure, but couldn’t really find substantial info on what `}<em parentName="p">{`exactly `}</em>{`you can do in this game. I hope that this blog post could give you a better idea of what to expect in this Nintendo Switch game!`}</p>
    <p>{`Ring Fit Adventure used to be `}<em parentName="p">{`barebones`}</em>{` when I first got it. There’s `}<strong parentName="p">{`minigames`}</strong>{` you could try with your `}<em parentName="p">{`friends or solo`}</em>{`, but it’s not something I enjoyed. After the update (v1.2.0), there’s a ton of things to try which pretty much `}<strong parentName="p">{`solidifies the value`}</strong>{` this game could offer! I’ve been playing for months now and yet I still haven’t reached the end game. Granted, each of my play sessions lasts for only `}<strong parentName="p">{`40 minutes max`}</strong>{`. Any longer and I’ll go out of breath! I’ve seen folks who could do longer so I guess I need to up my game ):`}</p>
    <p>{`Getting Ring Fit Adventure nets you the game, a pilates wheel and a leg strap. I believe the pilates wheel has some contraption to detect you pulling and squeezing it, so please don’t get a third party pilates wheel! I’ve seen some third party pilates going around which I’m not sure will work with the game. The leg strap however, is just a normal strap. I’ve seen folks on Reddit talking about how it’ll slide down during intense sessions so if you want to get a different leg strap, you could do so easily. You just need a leg strap with a holder for the joy-con!Alright, to the main topic of this blog post - what exactly can you do in Ring Fit Adventure?`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Nintendo Switch Ring Fit Adventure screenshot 1" caption="Here are the gameplay modes! The screen is split to two pages... This is Page 1. Adventure mode is the best fun I ever had." name="ring-fit-adventure-geeknabe-blog-screenshot-23.jpg" mdxType="Image" />
    <Image alt="Nintendo Switch Ring Fit Adventure screenshot 2" caption="This is page 2. I rarely check out the Tip Index to be fair." name="ring-fit-adventure-geeknabe-blog-screenshot-24.jpg" mdxType="Image" />
    <p>{`There are 4 gameplay options in this game - Adventure, Quick Play, Custom, Rhythm Game. The other 2 options, Multitask Mode and Tip Index are nice to have but you probably won’t use it at all.`}</p>
    <h2>{`Option 1 - Adventure`}</h2>
    <p>{`Ring Fit Adventure’s `}<strong parentName="p">{`Adventure `}</strong>{`lets you traverse the many worlds the game has to offer, all in order to defeat the Dragaux, the big bad dragon that got buffed up from working out too much. You’re not wrong if the whole premise sounds silly to you! The story is light-hearted and is probably there so they have an excuse to add in silly puns in the dialogue and to justify exercising our way through the map. By choosing to play Adventure, you’ll be playing a story that splits progress across different Worlds with the help of Ring and Tipp. Ring is your personal trainer while Tipp is the guy on the screen showing you the correct way to perform the exercise. I like Tipp. If Wii Fit Trainer could get an Amiibo, why not Tipp?`}</p>
    <Image alt="Tipp is a treasure. Amiibo please!" caption="This is Tipp. He tells you the correct pose for every exercise you're about to do. He also dances and throw confetti whenever you broke your previous record!" name="ring-fit-adventure-geeknabe-blog-screenshot-4.jpg" mdxType="Image" />
    <p>{`Complete each map by getting to the end of the sprawled up World, and you’ll clear said World just enough to move on to the next one! There’s quests as well which require you to backpedal to complete it, but it’s not necessary to move to the next World. The quests are mostly simple ones like `}<em parentName="p">{`“Defeat 6 monsters” `}</em>{`or `}<em parentName="p">{`“Complete the map within 1000 steps”.`}</em></p>
    <Ads mdxType="Ads" />
    <Image alt="Thank you Sentry!" caption="Here's an example of each World's map. You advance from one node to another. You don't need to complete them all to advance to the next World, but if you're a completionist... Go for it!" name="ring-fit-adventure-geeknabe-blog-screenshot-31.jpg" mdxType="Image" />
    <Image alt="Ring Fit Adventure screenshot for the Worlds and quest completion rate" caption="I've played up to World 15 so far. Is there more? I'm not too sure but I've heard that there's an extra difficulty if you complete all of the story's Worlds. By that time, you should be at Level 100+ so the new difficulty should help amp up your cardio sessions!" name="ring-fit-adventure-geeknabe-blog-screenshot-5.jpg" mdxType="Image" />
    <p>{`Each map has a beautiful environment to look at. There’s always some foxes or birds in the background which makes for a nice view. I’m not sure if it’s random, but you’ll get maps where it is in the morning, midday or at night! There’s even a map where it rains which I think is pretty cool.`}</p>
    <p>{`In order to clear each map they throw at ya, you’ll have to run along the preset path. All you need to do is jog in place while holding the pilates wheel. The map itself will throw a ton of obstacles at you where you’ll need to push or pull your pilates wheel. Sometimes you may even need to jump! The developers even sneakily threw in some obstacles where you’ll need to do squats or do overhead pulling just to proceed through the map. It’s pretty tough when I first started out but thankfully, I noticed that the game never tried to punish me even if I took a break. You could technically cheat your way by doing the least effort to clear the obstacles, or find a way to fool the pilates wheel! But that’s not why you’re playing this game, ya?`}</p>
    <Ads mdxType="Ads" />
    <Image alt="HOLD YOUR SQUAT!" caption="I call this being sneaky. I expected to just run the whole course but nope, here are some obstacles you need to clear. Squat Hold is still okay, there's another obstacle that I detest the most. It's the one where you stand on a swing and they make you do 7 squats consecutively before you can jump to the next swing." name="ring-fit-adventure-geeknabe-blog-screenshot-7.jpg" mdxType="Image" />
    <p>{`As you run along the path, you’ll encounter monsters! I was so amused when it first popped up. Like, oh no! Monsters appeared when I’m trying to get a decent jog, what am I to do with my pilates wheel?! Never fear, for the real battle is won by doing `}<strong parentName="p">{`more exercises`}</strong>{`. The battle is turn-based so you’ll take turns dishing out attacks by doing pushups while the monster wails at you when it’s their turn. You have a set of hearts as your health indicator while the monsters have their own health points bar. The battle is won by whittling them down, or you lose the battle by the monsters whittling yours. Win the battle and you get experience points! Woo! You may even `}<em parentName="p">{`level up`}</em>{`!`}</p>
    <p>{`You can choose which set of exercises (They call it `}<strong parentName="p">{`Fit Skills`}</strong>{`!) to do when it’s your turn. Each `}<em parentName="p">{`Fit Skill `}</em>{`has their own set of Attack, Defense, Range and Cooldown. The cooldown counter is there so you can’t spam the easiest Skill Fit against the monsters repeatedly, I’m sure of it. Such a shame, I really like doing Standing Twists.`}</p>
    <Ads mdxType="Ads" />
    <p>{`It’s really fun to experiment which skill hits the hardest or is the most versatile against these monsters! You could run from the battle too but it’ll boot you out of the map. In the end, you have no choice but to win the battle! I remember losing once because I couldn’t heal myself in time but it’s really hard to lose in general once your level is super high.`}</p>
    <Image alt="Chair Pose Yoga Move from Ring Fit Adventure" caption="Chair Pose is a yoga move. It's pretty tough when I first started since you gotta hold your squat as you move your arms slowly but now, I can do this easily." name="ring-fit-adventure-geeknabe-blog-screenshot-29.jpg" mdxType="Image" />
    <p>{`There are 4 types of exercise that you can do in battle - exercises that involve the arms, stomach, legs and yoga. Each exercise is colour-coded! Match the colour of your `}<em parentName="p">{`Fit Skill`}</em>{` with the monsters’ to earn extra damage! I honestly don’t think the colour matching mattered much but it’s nice to see the monster’s HP bar reducing faster.`}</p>
    <p>{`Each skill you choose needs to be completed by the number of reps the game requires you to do. E.g. complete 30 pushups, finish 40 side steps. The number of reps is affected by the level of difficulty you choose when you first started. The higher the level of difficulty, the more repetition the game will ask you to complete after choosing a skill to perform! Man, I remember picking Lvl 20 as the difficulty level. They gave me 26 squat reps or so to complete every time I picked the Hold Squat skill. Doing squats is one thing, but can you hold your squat for (at least) 8 seconds, stand back up and do it again for `}<strong parentName="p">{`30 times consecutively? `}</strong>{`Thankfully, the game didn’t call me `}<strong parentName="p">{`weak `}</strong>{`for `}<em parentName="p">{`taking a break midway`}</em>{`. My personal trainer, Ring, just kept telling me `}<em parentName="p">{`“You can do it!`}</em>{`” or `}<em parentName="p">{`“Take a break if you need to.”`}</em>{`. `}<strong parentName="p">{`Thanks, Ring!`}</strong></p>
    <Image alt="Fit Skills menu" caption="Here's all the Fit Skills I've unlocked. It's pretty varied! The arm Fit Skill can get pretty tough too. I set most of them to work my abs because I would like to lose my flabby tummy... Okay, so does it work? It does but only if you watch your diet too. I often sit for long hours due to my work, so Ring Fit helps me get motivated to get some sets in." name="ring-fit-adventure-geeknabe-blog-screenshot-1.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <p>{`The Adventure mode is pretty much where the bulk of the features are. You could equip workout clothes to improve your Attack and Defense or you could make drinks to heal up during battle.  There’s even a `}<strong parentName="p">{`skill tree`}</strong>{`! There are several ways you could get ingredients for your drinks or new workout clothes - defeat monsters, clear quests, collect ingredients as you jog or buy them from the shop! Yup, each World may or may not have a shop where you can sell or buy stuff. I honestly think the ingredient prices are a rip off. You’re better off replaying the maps to farm ingredients when you first started the game.`}</p>
    <p>{`As for the skill tree, you will need 2 things - `}<em parentName="p">{`skill points`}</em>{` and `}<em parentName="p">{`money`}</em>{`. Skill points are given to you when you level up and you’ll get money from selling your items, winning battles, collecting them as you jog, completing minigames or quests. I understand the need for drinks and clothing in Adventure mode, but I cracked up when the skill tree popped up. The skill tree is great, it just keeps `}<strong parentName="p">{`expanding`}</strong>{`! I was almost done unlocking everything and it just had to open up the tree further.`}</p>
    <Image alt="Ring Fit Adventure skill tree" caption="I levelled up and was about to unlock some Fit Skills... When it told me it expanded further! It's a nice touch to a RPG-esque exercising game. You can boost your Attack or Defense here... You can even unlock more hearts or new Fit Skill!" name="ring-fit-adventure-geeknabe-blog-screenshot-11.jpg" mdxType="Image" />
    <p>{`Ring Fit Adventure is a game that lasts so long precisely because the player can’t keep playing it nonstop for days. You’ll need to stop your session eventually. Once you call it quits, the game will show you a total of different reps you’ve completed as well as how long you have exercised. You may even earn titles if you broke your own records! This is such a great feature to push folks up. I know many out there enjoy collecting titles!`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Ring Fit total cumulative results" caption="Here's the list of sets I did during my session. They'll tell you the accumulated sets too." name="ring-fit-adventure-geeknabe-blog-screenshot-17.jpg" mdxType="Image" />
    <Image alt="Ring Fit Adventure results at the end of the session" caption="...As well as the total exercising time. It's not the total time you're playing the game, but the total time you spend moving your body. Make sure to set the correct age, weight and gender in the game so they can calculate the correct calories burned for you!" name="ring-fit-adventure-geeknabe-blog-screenshot-18.jpg" mdxType="Image" />
    <Image alt="Ring Fit Adventure Titles menu." caption="Here's the Titles menu. It's a nice touch to motivate you further. Nope, it doesn't do anything when set. Just nice to have I guess." name="ring-fit-adventure-geeknabe-blog-screenshot-15.jpg" mdxType="Image" />
    <p>{`Then there’s the Settings menu that you could only access if you entered the Adventure mode. I find this part really bothersome since I often find myself just wanting to check some settings outside of Adventure mode. You could change a ton of settings here!`}</p>
    <p>{`If you got a copy of Ring Fit Adventure in any region that you are not familiar with, don’t worry! It will automatically default to your system’s language. E.g. I got myself a Japanese copy and voila, it defaults to English for me when I first boot it up.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Ring Fit Adventure settings 1" caption="Set your difficulty here. It can go up to 30! I find anything beyond 20 is pretty tough. 15 or so is just nice for me. Once you get used to the momentum, consider raising it to 20 and above!" name="ring-fit-adventure-geeknabe-blog-screenshot-9.jpg" mdxType="Image" />
    <Image alt="Ring Fit Adventure settings 2" caption="You can check out the cumulative totals here. Ring Press includes the time you were running along the map and pressing it in." name="ring-fit-adventure-geeknabe-blog-screenshot-10.jpg" mdxType="Image" />
    <Image alt="Ring Fit Adventure settings 3" caption="You can change Ring's (your personal trainer) voice here. Choose a language, then choose to hear Male or Female." name="ring-fit-adventure-geeknabe-blog-screenshot-14.jpg" mdxType="Image" />
    <Image alt="Ring Fit Adventure settings 4" caption="There's a handy option called Assist Mode. If you have problems completing exercises that require the following actions, then you can enable Assist Mode. Once enabled, they'll let you skip the sets with a press of a button instead of working it out." name="ring-fit-adventure-geeknabe-blog-screenshot-13.jpg" mdxType="Image" />
    <h2>{`Option 2 - Quick Play`}</h2>
    <p>{`I clicked on `}<strong parentName="p">{`Quick Play`}</strong>{` expecting a feature where I can choose which exercise to do at my own pace. It turns out that Quick Play simply means a one time game that really didn’t suit anyone’s use case, unless you wish to show the game off to your friends and family. There are 4 types of different options you could do - simple challenges, minigames, complete sets or do a quick jog. If you’re looking to play local co-op, you could pick any challenge from the Simple tab. You’ll first complete your set, then you’ll pass your pilates wheel to a friend for them to complete theirs. It’s nothing groundbreaking, and I really don’t think it’s good beyond a touch-and-go session when your friends or family came over for a visit. You `}<strong parentName="p">{`COULD `}</strong>{`save your score for a Ranking Leaderboard online (vs Friends or vs World), but I have yet to see any of my friends joining in the fun ): Okay, you’re right. Maybe I have no friends.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Ring Fit Adventure mini-game" caption="Here's a mini game that you can play with your friends. First, you get your turn. Then the game will ask that you pass the wheel to your friend." name="ring-fit-adventure-geeknabe-blog-screenshot-16.jpg" mdxType="Image" />
    <p>{`Minigames however, is pretty fun. They have a ton of varied ones such as those that require you to squeeze the pilates wheel between your thighs, or the ones where you need to push and pull your wheel really quickly! These minigames test mostly on your reflexes and luck at times. Not something I’ll play often, but good enough to show off what the pilates wheel could do.`}</p>
    <Image alt="Ring Fit Adventure mini-game" caption="I personally really enjoyed Thigh Rider. Why? Because I get to sit for once." name="ring-fit-adventure-geeknabe-blog-screenshot-22.jpg" mdxType="Image" />
    <p>{`As for Sets, you could choose what muscles you wish to train and they’ll spruce up the necessary sets for ya. If you’re asking me if this works, then I’m afraid I have no answer for you. That’s because I never even attempted any of the sets! I prefer to `}<em parentName="p">{`jog`}</em>{`.`}</p>
    <Image alt="Ring Fit Adventure sets" caption="I wager folks who really want to work it out could... try this?" name="ring-fit-adventure-geeknabe-blog-screenshot-35.jpg" mdxType="Image" />
    <p>{`That said, you’re better off using another mode for your jogging sessions. The Jogging tab lets you choose a map and you’re free to jog through it without interruption! The maps are those that you have cleared from your playthrough in `}<strong parentName="p">{`Adventure`}</strong>{`. Jogging maps have their monsters removed but the obstacles are still there. It’s nice but it’s highly unlikely you’ll jog for 4 minutes in a single map and call it a day, right? If you wish to continue jogging, you’ll need to choose the next map manually. It’s really bothersome for me and I think I’m better off using Custom mode for this.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Option 3 - Custom`}</h2>
    <p><strong parentName="p">{`Custom`}</strong>{` lets you cherry pick any exercise you want and save them into sets! Let’s say you want to work on your stomach first before you start jogging. You could do that here! Custom mode lets you string up any sets of exercise into 10 slots. This is where I chain up my jogging maps. I try to pick those that let me jog without stopping, but it’s very rare to find a map that has zero obstacles.`}</p>
    <Image alt="Jogging custom set in Ring Fit Adventure" caption="Here's my list of maps I use for jogging. Some have obstacles but they don't take too much time to complete. If you want to just jog all the way through, pick Starting-Block Bridge and fill all 10 slots with it." name="ring-fit-adventure-geeknabe-blog-screenshot-30.jpg" mdxType="Image" />
    <h2>{`Option 4 - Rhythm Game`}</h2>
    <p>{`Here’s the last option -`}<strong parentName="p">{` Rhythm Game`}</strong>{`. As the name implies, the game will make these `}<strong parentName="p">{`beats`}</strong>{` flying your way with the music so you’ll need to catch them with your pilates wheel!`}</p>
    <Image alt="Ring Fit Adventure Rhythm game" caption="Squeeeze hard!" name="ring-fit-adventure-geeknabe-blog-screenshot-25.jpg" mdxType="Image" />
    <p>{`You can choose between two controls - work your arms by `}<strong parentName="p">{`pulling and pushing`}</strong>{` your pilates wheel (Arms + Legs) or placing your pilates wheel vertically `}<strong parentName="p">{`on your abdomen`}</strong>{` and squeezing it in (Core + Legs)! I prefer the `}<em parentName="p">{`arm control`}</em>{` since that is more my style. When you get really excited in `}<em parentName="p">{`Hard mode`}</em>{` when the beats come flying your way, the abdomen control just hurts a lot since you need to squeeze the pilates wheel really fast. Novice difficulty is still okay in terms of beat speed, but when you go a level higher, it gets way `}<em parentName="p">{`too fast`}</em>{`! Both controls require you to stand up or squat down so each control requires some intense `}<strong parentName="p">{`full body movement!`}</strong></p>
    <Ads mdxType="Ads" />
    <p>{`There’s a few battle music from the game, music from Breath of the Wild and even Mario Odyssey’s theme song! Unfortunately, there’s not much music you could choose from. It’s few enough that all 10 fingers of yours are enough to count them all. It’s not all bad news since each song is bloody long. Mario Odyssey’s song is at least 3 minutes! So now you see why abdomen control sucks?`}</p>
    <Image alt="Ring Fit Adventure Rhythm game" caption="Here's the Rhythm game menu. The music you can choose is pretty limited. I personally prefer the Four Masters Battle music." name="ring-fit-adventure-geeknabe-blog-screenshot-20.jpg" mdxType="Image" />
    <h2>{`All in all`}</h2>
    <p>{`I didn’t mention `}<strong parentName="p">{`Multitask mode`}</strong>{` and `}<strong parentName="p">{`Tip Index`}</strong>{` since they aren’t exactly gameplay options. Multitask mode simply lets you do 500 press/pull reps while your Switch is in sleep mode. It’s a nice feature for when you want to slot in some reps when you’re busy. As for the Tip Index, it’s just a list of tips you have encountered before in the game. I think the extra Custom and Adventure options are reason enough to get this game! I hope this writeup helps you to understand what you can do in Ring Fit Adventure.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      