import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Blockquote = makeShortcode("Blockquote");
const RemoteLink = makeShortcode("RemoteLink");
const LocalLink = makeShortcode("LocalLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Anime has been part of my life since childhood. I always woke up early every Saturday to catch the morning cartoons on TV. They’ll show Digimon, Zoids, Dragonball and I had a blast just switching between channels because they have this uncanny timing to show both Digimon and Zoids during the same time slot. Back then, there was barely any entertainment save for books and board games. Books are great but once you’re done with it, you lose that magical feeling compared to when you first read them. Board games are okay, but it’s no fun if no one plays with you. I live pretty far from my friends, plus there’s no smartphones or Whatsapp to quickly arrange a meetup. It’s tough trying to match everyone’s time, so the only time I ever get to play board games is after school (or during school hours tee hee). Honestly, anime is my best source of entertainment back then, and I love it to bits.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Then the Internet happened. My schoolmates introduced me to watching anime on Youtube (sliced to Part 1, Part 2, etc), and that’s when I fully evolve to a closet weeb. Most of my anime backlog actually originated from Youtube, and I’m personally really thankful to the Youtube exposure. I say `}<em parentName="p">{`closet weeb`}</em>{` because I’m actually pretty good at pretending to be a normal citizen. Just shake your head left and right whenever someone asks you about anime.`}</p>
    <p>{`“Did you watch the latest Inuyasha episode?” `}<strong parentName="p">{`shakes head`}</strong></p>
    <p>{`“Digimon S1 is amazing. Did you know they’ll air S2 soon?” `}<strong parentName="p">{`shakes head`}</strong></p>
    <p>{`But everything falls apart when they knew I had a Gameboy Advance. Pshh, they don’t know great consoles when they see one. Then again, I honestly hadn’t got a clue why everyone loves oppa and kpop so there’s that.Sooner or later, pirated sites began popping up, and the rest is history. We now have legit streaming sites that I am more than willing to pay. The anime industry is growing bigger by the year, and I’m really happy to see how open-minded my circle of friends are to Japanimation.`}</p>
    <p>{`Things have changed, but my schedule has not for the past decade. Whenever I’m free, I’ll browse Youtube to watch some anime. If I’m done with my backlog, I’ll go look for some `}<strong parentName="p">{`more anime`}</strong>{`. Fansubbers have a timetable to release fansubbed episodes so sooner or later, their timetable is also my timetable as well. Sometimes I’ll go look for some manga to read from aggregator sites. If I’m done with one, I move on to the next manga. I repeat this almost every day if I can. Now we have smartphones so that process has now moved on to reading and watching from your phone. Despite the fact I’m working now, I try to do this whenever I can.`}</p>
    <Ads mdxType="Ads" />
    <Blockquote mdxType="Blockquote">
  Here’s where the problem starts, and the reason why watching anime is no
  longer enjoyable for me.
    </Blockquote>
    <p>{`This may sound funny to you, but I really enjoy my work. I enjoy being a web developer `}<strong parentName="p">{`a lot. `}</strong>{`I love to code, to make things work, and to see it working flawlessly as expected. It’s really satisfying when it all comes together. But here’s the thing - if you want to become a great developer, you need to put in the time. This is fair because there’s so much to master as a developer, and each task requires hours to complete. This blog you are currently reading from? It took me 6 months to design and develop. Even after I have released it to the Internet, I am still discovering new things to improve on, and newer tech stacks to try. The work is never-ending and I don’t expect it to.`}</p>
    <p>{`I love playing games. I love watching anime. I love reading manga. I love coding. I didn’t mention it here, but I have other hobbies as well. I go to work from morning to evening and come back feeling absolutely tired. There’s so much to do, and I’m torn between my hobbies, errands, family and my social life. There are people out there that says “`}<em parentName="p">{`I can balance them all`}</em>{`.”. Let me tell you this at least.`}</p>
    <Ads mdxType="Ads" />
    <Blockquote mdxType="Blockquote">That’s absolutely bullshit. </Blockquote>
    <p>{`You can’t balance them all. It just means they are either sacrificing sleep or time to hang out with friends or family. It’s either this or that, you can’t choose in-between. I `}<em parentName="p">{`still `}</em>{`try to watch 1 or 2 anime episodes whenever I can, but I no longer am able to sit down and enjoy it fully. My mind keep wandering elsewhere. It keeps telling me that I should be doing something else, and not watching anime! I can’t focus on the screen, and I’m almost always trying to multi-task while I watch the episode.`}</p>
    <p>{`I’m already at that age where it’s time to let all of this go because there will never be enough time to do it all at once. There will be responsibilities that I need to take in the future and it’s obvious to see which will take precedence. It’s part of being an adult, and I really dislike it. You see, it’s not that I don’t want to embrace all of these changes from growing up. One day, eventually, you’ll need to accept it. But I don’t want to change.I don’t want to grow up.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Unfortunately, I don’t see it happening any other way. I don’t see myself `}<strong parentName="p">{`NOT`}</strong>{` watching any anime, but the frequency will definitely decrease gradually. I actually made this blog in an attempt to catalogue the last leg of my journey. Once I decided that I’m done with this side of my hobby, I plan to keep this project safe in my Github account. I may even make it public so it gets archived by `}<RemoteLink to="https://archiveprogram.github.com/" mdxType="RemoteLink">{`Github Archive Program`}</RemoteLink>{`. It’s a program by Microsoft + Github to safe keep all public projects in a vault in Antarctica for 1000 years. I explained it in my previous `}<LocalLink to="/blog/will-anime-and-manga-still-be-around-a-millennium-later/" mdxType="LocalLink">{`blog post here`}</LocalLink>{`. It's bittersweet, but at the very least I don't want to lose this big piece of my childhood as it is. When it is time to let go, I'll know.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      