import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const RemoteLink = makeShortcode("RemoteLink");
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const Video = makeShortcode("Video");
const LocalLink = makeShortcode("LocalLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`As of late, I’ve been super obsessed with `}<strong parentName="p">{`Genshin Impact`}</strong>{`. I’ve reached the supposed`}<em parentName="p">{` “end game”`}</em>{` where you invest most of your time just farming for the best artifacts and building your ultimate team that does the biggest damage numbers. I went back to reddit and religiously read most of the suggested builds to come up with what works best for me. There’s a ton of dedicated subreddit for each character from `}<RemoteLink to="https://www.reddit.com/r/Genshin_Impact/" mdxType="RemoteLink">{`Genshin on Reddit`}</RemoteLink>{`, so I suggest that you search these subreddit by typing your character name. E.g. `}<RemoteLink to="https://www.reddit.com/r/NingguangMains/" mdxType="RemoteLink">{`NingguangMains`}</RemoteLink>{`, `}<RemoteLink to="https://www.reddit.com/r/ZhongliMains/" mdxType="RemoteLink">{`ZhongliMains`}</RemoteLink>{`.`}</p>
    <p><strong parentName="p">{`Update Note:`}</strong>{` With the new Pale Flame and The Tenacity of Millelith artifacts out in 1.5, I've read many comments on how these two are made for Zhongli DPS and Burst/Shielder. I agree, but you couldn't deny that the basic combo Noblesse x2 + Petra x2 still works great for him. I am personally still using Bloodstained x2 + Gladiator x2 because their substats are way too damn hard to farm. Do I want to redo the whole grinding process? No. If you have just pulled Zhongli and wanted a surefire way for a Shielder/Burst, get Tenacity of Millelith 4-set. If you want a DPS Zhongli, get Pale Flame 4-set. Weigh your artifacts not just in sets, but in substats. Sometimes just using what you have is a joy as well.... (Grind only if you think you need to. Seriously.)`}</p>
    <Ads mdxType="Ads" />
    <p>{`Note: I will use a ton of gaming lingo here. If you’re familiar with these terms, move on. If not, here are the explanations.`}</p>
    <p><strong parentName="p">{`DPS`}</strong>{` is short for damage per second, usually used to imply who’s your best damage dealer in Genshin Impact.`}</p>
    <p><strong parentName="p">{`Main`}</strong>{` means that you only use said Genshin Impact character only, the other three in your team are there for support. E.g. Ningguang main, Zhongli main, Mona main. Each duplicate character you pull unlocks a `}<strong parentName="p">{`Constellation passive`}</strong>{`. Since there are 6 Constellations available, we denote the characters with 3 Constellations unlocked as C3. If you only pulled a character once, then it’s a C0.`}</p>
    <p><strong parentName="p">{`DOT`}</strong>{` stands for Damage Over Time. It’s a term to denote any damages that will persist for a period of time.`}</p>
    <p>{`Second Note: My current Adventure Rank (AR) is 53. Consider building your team and farming artifacts only after you reached AR45 because any artifact will work before that.`}</p>
    <p>{`I must say, there are `}<strong parentName="p">{`a ton`}</strong>{` of creative players who did some of the silliest team compositions and it just… works. I mean, a`}<RemoteLink to="https://www.youtube.com/watch?v=VISBeTT29WQ" mdxType="RemoteLink">{` DPS Barbara`}</RemoteLink>{`? `}<RemoteLink to="https://drive.google.com/file/d/1JPEKc6c1CtuspK0hos7rDGvNUCcKN8V6/view" mdxType="RemoteLink">{`DPS Mona`}</RemoteLink>{`? And a shield bot `}<RemoteLink to="https://www.reddit.com/r/Genshin_Impact/comments/kjuq4i/ive_joined_the_50k_hp_zhongli_club/" mdxType="RemoteLink">{`Zhongli with 50,000 HP`}</RemoteLink>{`? These folks know their elemental reaction ins and outs! I checked many of the guides posted by the dedicated main users and the amount of details are crazy. It ranges from doing a presumed `}<em parentName="p">{`damage calculation`}</em>{` to finding out what’s the `}<em parentName="p">{`best time to cancel your attack animation`}</em>{` in order to increase your DPS. It occurred to me that I may be nuts about this game, but there are nuttier folks out there.`}</p>
    <Ads mdxType="Ads" />
    <p>{`My current favourite team to play is the `}<strong parentName="p">{`Geo combo`}</strong>{`. It’s dead easy to play, super brain dead and really easy to smash some impressive damage numbers. If you just wanna spam that auto attack button, E and Q without thinking too much about it, a Geo team is the way to go. Obviously I need a better team if I wish to complete Spiral Abyss but I’m the type that just goes up till the highest floor I could and call it a day. Now folks who know their stuff might argue that you disagree with this blog post’s title because obviously, Zhongli and Albedo is the best Geo combo. Alas, I do not have an Albedo. All of my blog posts are based on my experience and I know for sure these two Geo users are the best in my team! This blog post is all about how I build my Zhongli and Ningguang, as well as my thoughts on what works for them. Enjoy!`}</p>
    <h2>{`Geo Main #1 - Zhongli: All-in-one nuker, shielder and DPS`}</h2>
    <p>{`Before v1.3 update, `}<RemoteLink to="https://genshin.honeyhunterworld.com/db/char/zhongli/" mdxType="RemoteLink">{`Zhongli `}</RemoteLink>{`does some of the lowest damage out there. His auto attack could go up to 300 at most? If he crits, then maybe 1,000. Compared to Ningguang who could easily quadruple his sorry damage, it’s a no brainer to make him a support for my Ningguang before v1.3 is out. I pulled him because of reasons initially and now, I’m really happy that they buffed him in v1.3! He went from a character that I swap in and out for his shield alone to an all-in-one really useful and powerful Geo archon that can do everything.`}</p>
    <p>{`I highly recommend that you build him to do ANYTHING. That is to say, he hits hard with his polearm, he shields your team (and it rarely expires from taking damage because his base HP is already high!) and once he summons that meteor, he does a solid 60,000 damage! He’s a wonderful asset to any team even if you only swap him in for his shield and meteor.`}</p>
    <Ads mdxType="Ads" />
    <h3>{`Suggested Artifacts for Zhongli`}</h3>
    <p>{`Here are the suggested artifacts that I’m using, have used and what I’ve seen everyone suggests for in the subreddit:`}</p>
    <ol>
      <li parentName="ol">
        <RemoteLink to="https://genshin.honeyhunterworld.com/db/art/family/gladiators_finale/" mdxType="RemoteLink">
  Gladiator
        </RemoteLink> x2 and <RemoteLink to="https://genshin.honeyhunterworld.com/db/art/family/bloodstained_chivalry/" mdxType="RemoteLink">
  Bloodstained{' '}
        </RemoteLink>
x2 - This is the artifact set I am currently using, partially because the substats
I rolled on them are really good, but I noticed that as long as your Crit Rate
and Crit Damage is decently high, you don’t need a Petra set on him. His hidden
ascension stat is already Geo Damage! With a Geo Damage goblet on him, the Geo
Damage should be sufficient enough.
      </li>
      <li parentName="ol">
        <RemoteLink to="https://genshin.honeyhunterworld.com/db/art/family/archaic_petra/" mdxType="RemoteLink">
  Petra{' '}
        </RemoteLink>
x2 and <RemoteLink to="https://genshin.honeyhunterworld.com/db/art/family/noblesse_oblige/" mdxType="RemoteLink">
  Noblesse{' '}
        </RemoteLink>
x2 - Use this artifact set if you want to fully focus on his meteor and shield.
When you hold down your E, watch as the geo pulse damages surrounding enemies
numbering at 4,000 to 5,000 damage!
      </li>
      <li parentName="ol"><strong parentName="li">{`Gladiator `}</strong>{`x2/`}<strong parentName="li">{`Bloodstained `}</strong>{`x2 and `}<strong parentName="li">{`Noblesse `}</strong>{`x2 - This is what folks are calling the hybrid set. You focus on both the auto attack numbers as well as Zhongli’s Elemental Burst damage. I personally tried this and decided that the best set for me is still `}<em parentName="li">{`Gladiator `}</em>{`x2 and `}<em parentName="li">{`Bloodstained `}</em>{`x2. The reason? Bloodstained 2-set artifact bonus boosts your auto attack while Gladiator 2-set artifact bonus boosts both your auto attack and skills! And uh, the substats I rolled are pretty good. In the end, it’s the substats that do the final talk!`}</li>
    </ol>
    <Ads mdxType="Ads" />
    <p>{`Ultimately, you’ll want these artifacts to have these substats - `}<strong parentName="p">{`Crit Rate`}</strong>{`, `}<strong parentName="p">{`Crit Damage`}</strong>{`, `}<strong parentName="p">{`Attack Percentage`}</strong>{` and `}<strong parentName="p">{`HP`}</strong>{`. Try to get a 1:2 ratio for Crit Rate:Crit Damage for best numbers. Obviously, try to aim for a higher Crit Damage if you could. I couldn’t tell you what’s the best artifact for each slot because you will need to balance your numbers.`}</p>
    <p>{`However, I’m sure of two things when it comes to artifacts - you `}<strong parentName="p">{`NEED`}</strong>{` a `}<em parentName="p">{`Crit Rate/Crit Damage circlet`}</em>{` and an `}<em parentName="p">{`Attack/Geo Damage goblet`}</em>{`. Firstly, having a Crit Rate or a Crit Damage circlet is basically a free 31% Crit Rate or a 62% Crit Damage if you maxed the circlet’s level. This leaves you free to balance your Atk Percentage or the opposite of the equipped circlet main stat elsewhere. As for the goblet’s slot, I suggest getting a Geo Damage goblet for no-brainers. I enjoy seeing my meteor doing big numbers so I went with Geo Damage, but an Attack goblet is equally good and potentially easier to farm as well!`}</p>
    <p>{`While each of Zhongli’s talents have a small HP percentage boost, it’s not exactly useful. I tried a HP build and noticed that it didn’t really contribute much to the final damage. HP is only good for his shield so it’ll last much longer against a torrent of attacks. Thus, I deem HP not important since his shield cooldown time is so low, you could easily refresh his shield once it expires!`}</p>
    <Ads mdxType="Ads" />
    <h3>{`Suggested Polearms for Zhongli`}</h3>
    <p>{`It goes without saying... If you managed to pull a 5-star polearm, use it. The base attack for any 5-star polearm tops any of the 4-star weapons and that is good enough of a reason to use it! 5-star weapons could reach 600~ base Attack while 4-star weapons could reach 400~ base attack. The 200 base Attack could make a huge difference! Obviously, not all could be lucky so here are some of the 4-star weapons Zhongli could use.`}</p>
    <ol>
      <li parentName="ol">
        <RemoteLink to="https://genshin.honeyhunterworld.com/db/weapon/favonius_lance/" mdxType="RemoteLink">
  Favonius Lance
        </RemoteLink> - I love this lance. Particularly because the energy recharge makes
it so darn easy to recharge his meteor ulti. Like, his meteor is constantly up
and recharges super quickly! Playing co-op is so much fun with this lance! If
you enjoy playing the meteor build, I highly suggest that you use this lance.
      </li>
      <li parentName="ol">
        <RemoteLink to="https://genshin.honeyhunterworld.com/db/weapon/deathmatch/" mdxType="RemoteLink">
  Deathmatch{' '}
        </RemoteLink>
- One of my favourite weapons due to the weapon’s substat being Crit Rate and
the easy-to-fulfill situational passive effect which further boosts Zhongli’s
attack when up against many or few enemies. Since the weapon has Crit Rate, it
leaves me free to focus on a Crit Damage circlet! However, this is a Battle Pass
weapon so please give the third option a try instead.
      </li>
      <li parentName="ol">
        <RemoteLink to="https://genshin.honeyhunterworld.com/db/weapon/crescent_pike/" mdxType="RemoteLink">
  Crescent Pike
        </RemoteLink> - The best F2P craftable weapon for Zhongli. It’s easy to refine
too! With the pillar out and constantly damaging enemies, you could easily get
Energy Particles to boost your attacks. There’s absolutely nothing to complain
about this weapon, but I’ve read that folks prefer the other craftable polearm
(<RemoteLink to="https://genshin.honeyhunterworld.com/db/weapon/prototype_starglitter/" mdxType="RemoteLink">
  Prototype Starglitter
        </RemoteLink>) only because Crescent Pike looks so ugly on Zhongli. (I personally
find this hilarious)
      </li>
    </ol>
    <Ads mdxType="Ads" />
    <h3>{`Zhongli Constellation`}</h3>
    <p>{`I only have a C0 Zhongli and I plan to get him up to C2 if he is up for grabs again! Honestly speaking, none of his Constellations are really good for his damage other than the obligatory +3 LVL for his Talents. Most of his Constellations are just `}<em parentName="p">{`nice to have`}</em>{`. Besides, do you even need healing if you have his shield constantly up? (C6 Zhongli heals you when his shield is up!)`}</p>
    <h3>{`Zhongli Build Conclusion`}</h3>
    <p>{`The reason I suggest that you build him to be all-in-one is because it’s a waste not to! I mean, our Geo Archon is so overpowered after the buff update. He’s basically an easy-to-use, one man team! I try to get all of his talents as high as possible because each one of them is so useful to the team and for himself. But of course, if you have other DPS characters you prefer, just slap random artifacts on him that boosts his HP and Geo Damage for his shield and meteor before calling it a day. If you’re still not using him even as a shield bot, then take note of his shield passive effect!`}</p>
    <Image alt="Zhongli Jade Shield passive effect" caption="This effect alone made Zhongli super useful and stronk. MAKE WAY FOR CEO OF GEO!" name="zhongli-shield-res-debuff.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h3>{`Zhongli Extra Note`}</h3>
    <p>{`There are two things I wish to share about Zhongli - when best to `}<strong parentName="p">{`cancel his auto attack animation`}</strong>{` and `}<strong parentName="p">{`footstooling`}</strong>{`. When Zhongli attacks, he jabs forward a few times, slams his polearm down, kicks his polearm so it whirls in place and does a final strong jab that sends the enemy flying. It’s fine until the part that sends the enemy flying! I find it tedious to chase after the enemy every time he finished his auto attack sequence. I could potentially kick the enemy off the cliff too! To prevent the enemy from flying, dash away and reposition yourself right after Zhongli kicks the lance for it to do a whirling animation. With the right timing, you can leave the lance whirling in place and your Zhongli will have repositioned himself to start auto attacking again without sending anyone flying. This makes my experience using Zhongli as an auto attacker much more fun!`}</p>
    <p>{`As for footstooling, it’s an advanced technique where you make Zhongli jump in place and do a plunge attack on the spot. It’s uber cool and fun, but tad tough for me to initiate with a controller… I attached a video below that explains how to do footstooling!`}</p>
    <Video title="footstooling tutorial" src="https://www.youtube.com/embed/rCazk_8--q4" mdxType="Video" />
    <h2>{`Geo Main #2: Ningguang: Geo Rocket Shooter`}</h2>
    <p>{`Pssh, who plays our Geo Mama as a support? NO ONE.`}<LocalLink to="/blog/genshin-impact-ningguang-is-a-hidden-5-star-character/" mdxType="LocalLink">{` I previously wrote about her here`}</LocalLink>{` and I’m about to do it again in this blog post… but with an updated understanding of her build. `}<RemoteLink to="https://genshin.honeyhunterworld.com/db/char/ningguang/" mdxType="RemoteLink">{`Ningguang`}</RemoteLink>{` excels in two things - uber high damage with low effort and easy-to-create crystal shards for shields. Since she’s a 4-star character, her Constellations is easy to max too. Plus, each Constellation of hers improves her damage by leaps and bounds. She’s your ultimate boss killer and one-target DPS unit. Plus, she’s easy to use! Her damage relies on Star Jade that she shoots out which coincidentally, is something she does often through her Elemental Burst, Starshatter. Starshatter is super easy to spam and it synergizes well with her Elemental Skill, Jade Screen!`}</p>
    <Ads mdxType="Ads" />
    <h3>{`How to use Ningguang`}</h3>
    <p>{`If you auto-attack using Ningguang once, it creates a hovering Star Jade behind her. If you do a charge attack afterwards, the Star Jade will shoot out along with a big pebble Ningguang creates to do extra damage. You should ascend her until you unlock Backup Plan talent! With Backup Plan, using a charge attack does not consume stamina if you have a Star Jade ready. The bulk of your damage relies heavily on your charge attack, so make sure to do auto attack first followed by a charge attack for extra damage and 0 stamina consumed. I personally prefer to do 1 auto attack followed by 1 charge attack. The damage numbers get really high and you get to KO the enemy faster this way.`}</p>
    <p>{`Her Elemental Skill, Jade Screen is a boon. Every time I use this skill, the lighter enemies get smacked into flying or into oblivion. It does two things - blocks projectiles and gives 12% Geo Damage for 10s if you walk through it. I tried to block many things and I found out it blocks the following:`}</p>
    <ol>
      <li parentName="ol">{`Ruin Guard rockets`}</li>
      <li parentName="ol">{`Whopperflower projectile attacks`}</li>
      <li parentName="ol">{`Abyss Mage’s projectile attacks (Cryo Abyss Mage shoots out cryo attacks!)`}</li>
      <li parentName="ol">{`Hillichurl Shooter and Hillichurl Grenadiers`}</li>
      <li parentName="ol">{`Fatui Cryo/Hydro shooters (The fat agents...)`}</li>
      <li parentName="ol">{`The slimmer Fatui agent (That wields a gun)`}</li>
    </ol>
    <p>{`Jade Screen is an absolute blast to spam since my Ningguang does a solid 20,000 damage and above whenever I use her skill!`}</p>
    <Ads mdxType="Ads" />
    <p>{`When using Starshatter, make sure to have adequate space between you and the enemy but not too far. I noticed that Starshatter homing goes haywire if the enemy is close enough to kiss you. It doesn’t do well in enclosed space either. E.g. cave. And make sure to have Jade Screen out before shooting Starshatter! It shoots out extra 5 Star Jades if you use Starshatter while Jade Screen is still on the field. See why I call her a geo rocket shooter now?`}</p>
    <h3>{`Suggested Artifacts for Ningguang`}</h3>
    <p>{`There’s only 1 best artifact set for Ningguang out there, and that is the `}<RemoteLink to="https://genshin.honeyhunterworld.com/db/art/family/archaic_petra/" mdxType="RemoteLink">{`Petra `}</RemoteLink>{`x2 and `}<RemoteLink to="https://genshin.honeyhunterworld.com/db/art/family/gladiators_finale/" mdxType="RemoteLink">{`Gladiator `}</RemoteLink>{`x2. The bulk of her damage comes from her charged attack. Plus, she’s a Geo elemental user! You’d want her to hit as hard as possible so this is that optimum artifact combo for her. You could try the Retracing Bolide x4 set, but I find it too situational (What if you couldn’t get a shield up in time?) to use. You could try, but the Petra x2 and Gladiator x2 set never went wrong for me.`}</p>
    <p>{`These are the stats you would want on her - `}<strong parentName="p">{`Crit Rate`}</strong>{`, `}<strong parentName="p">{`Crit Damage`}</strong>{`, `}<strong parentName="p">{`Attack Percentage`}</strong>{` and `}<strong parentName="p">{`Geo Damage`}</strong>{`. It’s simple and a no-brainer! I believe most, if not all DPS characters use these stats too. Get her Crit Rate:Crit Damage ratio up to 1:2 but if possible, get her Crit Damage as high as possible. You could easily up her Geo Damage through a Geo Damage goblet and the Petra artifact set so that’s easily settled.`}</p>
    <Ads mdxType="Ads" />
    <h3>{`Suggested Catalysts for Ningguang`}</h3>
    <p>{`Since her skills and passives are highly skewed towards playing DPS, it goes without saying that the best catalysts for her are those that have Crit Rate or Crit Damage as their substat. Of course, if you have a 5-star catalyst then use that! Needless to say, the best 5-star catalyst for Ningguang is `}<RemoteLink to="https://genshin.honeyhunterworld.com/db/weapon/lost_prayer_to_the_sacred_winds/" mdxType="RemoteLink">{`Lost Prayer to the Sacred Winds`}</RemoteLink>{`. Here are her suggested 4-star catalysts:`}</p>
    <ol>
      <li parentName="ol">
        <RemoteLink to="https://genshin.honeyhunterworld.com/db/weapon/solar_pearl/" mdxType="RemoteLink">
  Solar Pearl
        </RemoteLink> - A superb Battle Pass weapon. Notice the Crit Rate as the substat?
Not to mention the passive effects work so well for Ningguang! Increases Elemental
Skill, Elemental Burst and Normal Attack Damage by 20%? I’ll take that. Definitely
the best 4-star catalyst for Geo Mama.
      </li>
      <li parentName="ol">
        <RemoteLink to="https://genshin.honeyhunterworld.com/db/weapon/the_widsith/" mdxType="RemoteLink">
  The Widsith
        </RemoteLink> - Substat is Crit Damage but the passive effects are randomized.
Only 2 out of 3 passives (Attack and Elemental Damage increase) works great for
Ningguang. However, if you do not have a choice, this catalyst is good enough
for Ningguang. I personally feel it's best for Klee though...{' '}
      </li>
      <li parentName="ol">
        <RemoteLink to="https://genshin.honeyhunterworld.com/db/weapon/eye_of_perception/" mdxType="RemoteLink">
  Eye of Perception
        </RemoteLink> - Okay enough to use as a temporary placeholder until you get your
hands on Widsith or Solar Pearl! The passive effect is nice to have but not as
important as Solar Pearl’s passive effect.
      </li>
    </ol>
    <p>{`FYI, do not get `}<RemoteLink to="https://genshin.honeyhunterworld.com/db/weapon/mappa_mare/" mdxType="RemoteLink">{`Mappa Mare`}</RemoteLink>{` because Ningguang can’t do Elemental Reactions. The Geo element simply does not work like that.`}</p>
    <Ads mdxType="Ads" />
    <h3>{`Ningguang Constellation`}</h3>
    <p>{`I have a C3 Ningguang now thanks to the Lantern Rite event and Keqing’s banner! Each of her Constellation is so useful and scales damage really well. If you’re aiming for the minimum Constellation, get her up to C2 for her small AOE attacks along with an insta-ready to use Jade Screen. C2 Ningguang removes her Jade Screen’s CD altogether every 6s. Folks love her at C2 because she could easily spam 20,000 damage twice in a row! If you’re going all out, C6 Ningguang is worth it! Who wouldn’t want extra 7 Star Jades for a charged attack?!`}</p>
    <h3>{`Ningguang Build Conclusion`}</h3>
    <p>{`Don’t sleep on this character. She’s so easy and fun to use. Do you want to KO enemies fast? How about NOT swapping out characters to break Abyss Mage’s shields or Lawachurl’s armor? And uh, mining ores? This lady can do everything as long as she has a shield up!`}</p>
    <h2>{`Zhongli and Ningguang Synergy`}</h2>
    <p>{`After v1.3, Geo characters got a massive buff! 2 Geo characters in a team now grants 15% Attack Damage to anyone with a shield. Since both Ningguang and Zhongli could easily create shields, that buff condition is easily fulfilled. Your characters no longer need to dodge a Ruin Guard’s body slam attack when their shield is up, and they do extra damage! Perfect.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Both Ningguang and Zhongli’s Elemental Burst only requires 40 Energy. Since having 2 Geo characters will make it easier to fill energy particles (Same element fills your energy gauge faster!) faster, plus the cost needed is the lowest in the game. This allows you to spam their burst again and again.Zhongli’s pillar resonates with nearby Geo constructs to cause a small AOE damage to nearby enemies. Have Zhongli create a pillar and Ningguang use her Jade Screen nearby for effective DoT and energy particles generation. This is the reason why Albedo is deemed the best partner for Zhongli! It’s because Albedo’s Elemental Skill, Solar Isotoma is so easy to spam and it also resonates with Zhongli’s pillar. It’s easy to create a DoT and it generates a ton of energy particles super fast!`}</p>
    <h2>{`All in all`}</h2>
    <p>{`I love my Geo team! They carried me across all domains and bosses. I now solo every domain and boss easily with Zhongli’s shield and Ningguang’s high damage.`}</p>
    <p><strong parentName="p">{`Here are my Zhongli and Ningguang’s stats, weapons and artifacts along with the damage they are currently doing in the overworld`}</strong>{`. Despite all the mumbo jumbo I said, the best way to play the game is `}<em parentName="p">{`play however you want`}</em>{` and with `}<em parentName="p">{`whatever you got`}</em>{`. I got to this stage because I have played this game since it first started and has not stopped since (I play everyday). Don’t be disheartened by the damage the YouTubers are doing, they are rich and have a ton of time… F2P players should take it easy!`}</p>
    <Ads mdxType="Ads" />
    <p>{`Besides, once you start farming artifacts, that’s where the real min-maxing starts! 5-star artifacts do wonders for your damage. `}{`*`}{`Note: These damages include the Geo Resonance team buff along with their weapon/artifact passive effect buff and Zhongli’s Resistant Debuff (His shield passive effect). Both Ningguang and Zhongli has reached LVL90 in my team. I included the normal attacks and the damage when these characters crits. It’s an estimate based on what numbers I often see pop up on my screen if I can see the numbers. When battles get chaotic, you have no idea what's happening...`}</p>
    <p><em parentName="p">{`Extra Note: For even more damage easily, use bouken da bouken (Bennett’s Elemental Burst, Fantastic Voyage). You could even eat food before a fight! Sometimes I almost forgot we have food buffs in this game...`}</em></p>
    <h2>{`Zhongli's Damage`}</h2>
    <p><strong parentName="p">{`Weapon`}</strong>{`: LVL 90 Deathmatch R0`}<br />{`
`}<strong parentName="p">{`Artifacts`}</strong>{`: Bloodstained x2 + Gladiator x2`}<br />{`
`}<strong parentName="p">{`Auto Attack`}</strong>{`: Normal 800 to 1200~, With Crit 2000 to 4000~`}<br />{`
`}<strong parentName="p">{`Dominus Lapidis`}</strong>{`: Hold Damage 3,000 to 6,000~, Pulsing Damage 2,000 to 4,000~`}<br />{`
`}<strong parentName="p">{`Planet Befall:`}</strong>{` Normal 20,000~, With Crit 57,000~ (I’ve seen it go past 69,000 but rarely happens)`}</p>
    <Image alt="Zhongli Build Guide 1" caption="His Crit Rate relies on Deathmatch substat. It was tough trying to balance the numbers, oof. I have no idea how he got that 1,000~ DEF. His Geo Damage is at 75% which I think is standard for everyone?" name="zhongli-build-guide-1.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <Image alt="Zhongli Build Guide 2" caption="I was trying so hard to get a Bloodstained sands with Attack as the main stat. It was tiring smashing through 3 Abyss Mages... Okay, fine. I'll rearrange my artifacts so I could use other Attack sands artifact. I got lucky with this one!" name="zhongli-build-guide-3.jpg" mdxType="Image" />
    <h2>{`Ningguang's Damage`}</h2>
    <p><strong parentName="p">{`Weapon`}</strong>{`: LVL90 Skyward Atlas R0`}<br />{`
`}<strong parentName="p">{`Artifacts`}</strong>{`: Petra x2 + Gladiator x2`}<br />{`
`}<strong parentName="p">{`Auto Attack`}</strong>{`: Normal (Single gem) 1,300~, With Crit (Single gem) 3,600~`}<br />{`
`}<strong parentName="p">{`Charge Attack`}</strong>{`: Normal (Single gem) 4,000 to 8,000~, With Crit (Single gem) 10,000~ (I’ve seen it go up to 20,000 but rarely happens)`}<br />{`
`}<strong parentName="p">{`Starshatter`}</strong>{`: Normal (Single gem) 4,000~, With Crit (Single gem) 10,000~ (I’ve seen it go up to 20,000 but rarely happens)`}<br />{`
`}<strong parentName="p">{`Jade Screen`}</strong>{`: Normal 9,000~, With Crit 24,000~`}</p>
    <Image alt="Ningguang Build Guide 1" caption="I personally think her Crit Rate is too low, but alas... I'll leave farming Petra artifact domain for another day. I think I got PTSD from visiting that domain everyday. I just rely on my RNG and weekly bosses when it comes to getting a good Gladiator set." name="ningguang-build-guide-2.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <Image alt="Ningguang Build Guide 2" caption="The bulk of her damage comes from her Auto Attack and Charge Attack! I'm really happy with her damage, even if her Crit Rate is on the low side. She's my first LVL10 Talent I completed!" name="ningguang-build-guide-1.jpg" mdxType="Image" />
    <Image alt="Ningguang Build Guide 3" caption="It's important that you get her a Attack Sands on her! Watch Ningguang's damage skyrocket with a ATK sands!" name="ningguang-build-guide-5.jpg" mdxType="Image" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      