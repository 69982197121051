import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const RemoteLink = makeShortcode("RemoteLink");
const Video = makeShortcode("Video");
const Ads = makeShortcode("Ads");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`K at the movies or better known as the `}<RemoteLink to="https://twitter.com/K_at_the_movies" mdxType="RemoteLink">{`alphabet K, one of my aniblogger friends`}</RemoteLink>{` released an interesting video. He got the community of anibloggers to vote for the 100 worst anime openings which we did! It’s a whopping 30 minute video (Because there’s 100 anime opening scores to show, duh!) and it's a blast finding out how everyone rates their anime openings, mainly because everyone’s taste is so different! There’s the`}<em parentName="p">{` easy-to-please`}</em>{` folks, the`}<em parentName="p">{` 1-score-only`}</em>{`, `}<em parentName="p">{`absolutely-based scores`}</em>{` and most importantly, joke scores. Absolutely cheeky of them xD I added the video below, enjoy!`}</p>
    <Video src="https://www.youtube.com/embed/gxeuLQsO8FU" title="100 worst anime opening" mdxType="Video" />
    <p>{`So the video got me thinking of a blog post I thought of sharing with everyone. Hence, the title. What’s my 10 personal best anime openings? Since it’s personal, everyone is free to say `}<em parentName="p">{`“Are you sure, nabe-chan?!”`}</em>{` `}<strong parentName="p">{`Oh I’m very sure!`}</strong>{` My list has a pinch of bias but the openings are in the list because of the aesthetics, the song… Everything about the opening hits the darn spot. The list is not exactly ordered. They are the Top 9 because these are the first openings I think of when someone asked me the question. Enjoy!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Personal Best Anime Opening #1: Maria Holic (Hanaji)`}</h2>
    <p>{`This entire show is so gay, I love it. The opening just left such a strong impression that a decade later, I would vote this for one of the most memorable openings. Was it the colours or the beat that matches everything on screen? I think it’s the cheeky song with the memorable beats that really makes the opening hit it out of the park. The singer goes `}<em parentName="p">{`aaah~ aaah~`}</em>{`, it's amazing.`}</p>
    <Video title="Maria Holic op" src="https://www.youtube.com/embed/pOHwVZc6QYY" mdxType="Video" />
    <h2>{`Personal Best Anime Opening #2: Lucky Star (Motteke! Sailor Fuku)`}</h2>
    <p>{`I don’t need to watch the show because everyone on the Internet tells me about it. Then there’s my group of friends who would reference the dance whenever they can. Sorry guys, I still have not watched Lucky Star even though everyone recommended it. I think I’m happy with my current status quo. But I must say, you can’t deny how iconic this opening is!`}</p>
    <Video title="Lucky Star op" src="https://www.youtube.com/embed/6iseNlvH2_s" mdxType="Video" />
    <Ads mdxType="Ads" />
    <h2>{`Personal Best Anime Opening #3: Sugar Sugar Rune (Chocola ni Muuchu)`}</h2>
    <p>{`Urghhh bring back magical girls show that’s not PreCure! Sugar Sugar Rune has this special spot in my favourites for embracing your feminine side unabashedly. Not to mention how cute the opening is! This is that one show that I love in both anime and manga format.`}</p>
    <Video title="Sugar Sugar Rune op" src="https://www.youtube.com/embed/L2uLbiIN7uo" mdxType="Video" />
    <h2>{`Personal Best Anime Opening #4: Aria The Animation (Undine)`}</h2>
    <p>{`It’s… not exactly an anime opening I added above but this is the nicest edit for the opening. This is that one opening that I think really suits the anime in terms of direction and song. Aria The Animation is the most relaxing show for me, and the opening is `}<em parentName="p">{`chef kiss`}</em>{` perfection. My go-to song as background music whenever I study for an exam!`}</p>
    <Video title="Aria the Animation op" src="https://www.youtube.com/embed/3EIULHTeTrk" mdxType="Video" />
    <Ads mdxType="Ads" />
    <h2>{`Personal Best Anime Opening #5: Wotaku ni Koi wa Muzukashii (Fiction)`}</h2>
    <p>{`Did I add this purely because I find the hand animation absolutely the cutest? You are god kuso right. The show is fantastic and the main cast is an absolute delight! I find the song and the opening’s vibe just fits so well with the plot in general. Urgh, moeeee!`}</p>
    <Video title="Wotakoi op" src="https://www.youtube.com/embed/EdH70yDwm6c" mdxType="Video" />
    <h2>{`Personal Best Anime Opening #6: Mawaru Penguindrum (Noruniru)`}</h2>
    <p>{`I love this show so much. It’s one of Kunihiko Ikuhara's best work to me because there’s a ton of episodes (Actually it only has 24 episodes but hyperbole is my middle name) and lovely/minimalistic graphic design slipped in here and there. This whole opening didn’t make sense to me at all but I enjoyed how they made use of a white background to ensure the viewers were getting all the spicy details. Hell yea, minimalism!`}</p>
    <Video title="Penguindrum op" src="https://www.youtube.com/embed/BlEmAcDm8pY" mdxType="Video" />
    <Ads mdxType="Ads" />
    <h2>{`Personal Best Anime Opening #7: Violet Evergarden (Sincerely - TRUE)`}</h2>
    <p>{`I love the music in Violet Evergarden so damn much. It’s super bittersweet and tugs at my heartstring! This opening is no different. The whole show is all about discovering the heartaches of love and the opening aesthetics deliver it perfectly.`}</p>
    <Video title="Violet Evergarden op" src="https://www.youtube.com/embed/Fc-XPJE3oeM" mdxType="Video" />
    <h2>{`Personal Best Anime Opening #8: R.O.D. The TV (R.O.D.)`}</h2>
    <p>{`I always thought they cut out the song due to copyright or something, thus leaving only the tune for the anime version I was watching. Huhhh, turns out it doesn’t have lyrics in the first place for the opening version! The tune gives me a secret agent vibe and I love it. Classy opening!`}</p>
    <Video title="R.O.D op" src="https://www.youtube.com/embed/WxsoJ6gcDT4" mdxType="Video" />
    <Ads mdxType="Ads" />
    <h2>{`Personal Best Anime Opening #9: Land of the Lustrous (Kyoumen no Nami)`}</h2>
    <p>{`If you have not watched the show, I implore that you give this a try. One of the best CG animations I’ve seen for an anime. Given the theme of the show, CG actually fits super well. The whole opening is super trippy along with the song’s beat. Really unique stuff.`}</p>
    <Video title="Houseki no Kuni op" src="https://www.youtube.com/embed/C9MuI4gfyiA" mdxType="Video" />
    <h2>{`All in all`}</h2>
    <p>{`Thank you for reading! I hope you enjoy these anime openings! The songs are so lit. Just a heads up before I end the blog post... If you have Apple Music, you're in for a treat. `}<RemoteLink to="https://www.apple.com/newsroom/2021/05/apple-music-announces-spatial-audio-and-lossless-audio/" mdxType="RemoteLink">{`Apple recently announced that HiFi will be made available for free to all subscribers on Apple Music`}</RemoteLink>{`. You know what that means?! It means.... Listening to LiSa songs.... on the go... but the songs are streaming with CD quality <3`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      