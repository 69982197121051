import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Happy Valentine's Day everyone! I hope the year has brought my readers plenty of joy despite the ongoing covid pandemic. Get vaccinated and stay safe :)`}</p>
    <p>{`Valentine's Day evoke some of the most complicated feelings in me. Part of me abhors the incessant use of this day as a marketing ploy while the other half of me is really happy to see lots of lovey-dovey art on the Internet. Everyone's happy in their own way, so all is well!`}</p>
    <p>{`I've been gone from this blog for so long and I thought it's time I add a touch of sweetness to it... And by that I mean, ROMANCE! `}<strong parentName="p">{`Woo!`}</strong>{` God bless these authors for giving me so much joy in watching 2 fictional characters get together after 100 chapters (An exaggeration, but you know what I mean). So to share the joy, here are 5 of my favourite lovey-dovey moments from these romance manga titles. Enjoy!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Manga Moment #1: Gakuen Alice (Natsume x Mikan)`}</h2>
    <p>{`It's been so darn long since I last finished this manga, but this kiss scene in particular is still fresh as a mint in my mind! The entire build-up is pretty great, honestly. The readers know there's this rivalry between Natsume and Ruka for Mikan's affection. The boys are aware that either one will emerge the winner, but poor Mikan has just started being aware of both boys' affection.`}</p>
    <Image name="gakuen-alice-natsume-mikan-kiss.png" caption="I believe you can start reading from Chapter 48. This scene happened in Chapter 50." alt="Gakuen Alice" mdxType="Image" />
    <p>{`The mood is just right too, since it is during the Christmas party. She got a kiss from Youichi and Ruka on her cheeks so all that's left is a kiss on the lips :P I don't exactly remember the details, so I have to go read the chapter again just to write this blog post.`}</p>
    <p>{`It was reminded of how much I loved the initial story setup for Gakuen Alice. Lots of humour, clean art and creative use of Alices in the story! The last kiss between Natsume and Mikan is also the last scene for the Christmas party which makes the whole build-up so much interesting. You thought that was the end of it? Nope! Here's another kiss! And cheeky Natsume did a french kiss!`}</p>
    <p>{`I can't believe after 2 years writing on this blog, the term `}<em parentName="p">{`french kiss`}</em>{` will finally appear in Google searches of my blog...`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Manga Moment #2: The Case Study of Vanitas (Vanitas x Jeanne)`}</h2>
    <p>{`I thought Jun Mochizuki couldn't possibly beat her previous work (Pandora Hearts) when it comes to squeezing my heart. It turns out she can, and she's been beating my fragile heart black and blue with all of these moe scenes between Vanitas and Jeanne.`}</p>
    <Image name="the-case-study-of-vanitas-kiss-scene.jpg" caption="CHAPTER 42 Y'ALL." alt="The Case Study of Vanitas" mdxType="Image" />
    <p>{`Two unlikely individuals falling in love <3 I love how ruthless Jeanne and Vanitas is in seeing themselves initially. Jeanne is one dedicated bodyguard to her master. Meeting Vanitas unlocked an old memory that someone(?) has locked away... And somehow made her go as soft as marshmallow whenever she thinks of him. Her expression in the manga is super hardy initially but as the story progresses, we see her becoming lovelier and much friendlier to Vanitas and Noe.`}</p>
    <p>{`Vanitas on the other hand, is the usual case of low self-confidence. Vanitas was just fooling around with Jeanne. He just enjoys seeing her fumble around whenever he muttered sweet whispers to her ear. But wait, she reciprocated? Now it's Vanitas's turn to fumble with his words! The following chapter shows us how Vanitas never believed in himself really hurts to read. Here's hoping both will find it in themselves to love themselves and each other at the end of the story!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Manga Moment #3: Toilet-Bound Hanako-kun (Hanako x Yashiro)`}</h2>
    <p>{`One of my favourite manga when it comes to aesthetics, mystery and moe scenes. You know how wonderful it is to finally see your OTL kiss after 86 monthly chapters? Yup, that's the feeling.`}</p>
    <Image name="toilet-bound-hanako-kun-kiss-scene.jpg" caption="Chapter 86! You're welcomed." alt="Toilet-Bound Hanako-kun" mdxType="Image" />
    <p>{`There were a ton of moments where Hanako did a subtle nod to his feelings towards Yashiro. The painting arc really did me in good... I did find it weird that no one was actively trying to prevent Yashiro's early death after it was revealed. When Hanako told everyone that he is doing for Yashiro, I `}<strong parentName="p">{`melted`}</strong>{`. And the soft peck on her face when he told Yashiro he'll save her? I swooned. So it made perfect sense that I `}<strong parentName="p">{`died`}</strong>{` when they finally kissed.`}</p>
    <h2>{`Manga Moment #4: Otome Youkai Zakuro (Riken x Susukihotaru)`}</h2>
    <p>{`Urghhh. Hnnng. Just mentioning this title brings back a ton of memories for me. I first encountered this title as an anime adaptation. The girls are such sweet things and the boys they are paired up with are equally sweet. From the first episode onwards, it's just a slow hike towards seeing the couples get together. While Zakuro and Kei is the main pair, my attention is always on the Riken and Susukihotaru.`}</p>
    <Image name="otome-youkai-zakuro-riken-susukihotaru.png" caption="This is in Chapter 1!" alt="Otome Youkai Zakuro" mdxType="Image" />
    <p>{`Amongst the boys, Riken is the tallest. He easily overshadows his shorter, demure partner. When Susukihotaru mentioned that she's terrified of Riken (despite how gentle he seems), it feels relatable. If I ever met Dwayne Johnson in person, I think I'll go `}<em parentName="p">{`eeep`}</em>{`. The most memorable scene for me when it comes to this pair (and the sweetest) is when Riken found out Susukihotaru is terrified of his height... and he bends down for her.`}</p>
    <p>{`I remember this scene happened much later in the story, but it turns out I was mistaken. It actually happened in Chapter 1! No wonder I loved the manga haha. I hope the manga continues soon. It's been on hiatus for so long, I wonder what happened.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Manga Moment #5: Emma (William x Emma)`}</h2>
    <p>{`Have you heard of the Kaoru Mori, the author who drew Emma `}<strong parentName="p">{`AND`}</strong>{` A Bride's Story? If you have not, I highly recommend her works! Her art is amazing but her romance works are `}<em parentName="p">{`masterfully done`}</em>{`.`}</p>
    <Image name="emma-manga.png" caption="Chapter 1. William intentionally left his gloves so Emma could return it to him." alt="Emma" mdxType="Image" />
    <p>{`Our heroine in this story is Emma. She's worked as a maid since forever for her mistress, Kelly who's a retired widow. One day, William who was once a student of Kelly paid her a visit. It was then and there that William met his first love. But Emma is only a maid while William is from a wealthy family. Both hearts are in it, but traditional class distinctions is the one thing preventing them from getting together. It's a wonderful story of how William tries to reach out to Emma with nothing but love and determination.`}</p>
    <Ads mdxType="Ads" />
    <p>{`There's a ton of scenes that solidifies this title as one of the best romance manga to me, but the scene in Chapter 1 stood out to me. William clearly was mesmerized by Emma when he first saw her. He then left his gloves, so Emma would rush out to meet him. Such a cheeky way to get to know a lady!`}</p>
    <h2>{`All in all`}</h2>
    <p>{`I hope you enjoy reading this silly post as much as I do writing it! Have a lovely Valentine's Day!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      