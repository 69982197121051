import PropTypes from 'prop-types';
import styled from 'styled-components';

import $ from '../../../../../styles/globals';
import BackIcon from '../../../../../assets/icons/left-chevron.svg';

const Container = styled.div`
  width: calc(100% - ${$.layout.padding1 * 2}px);
  height: 50px;
  border-bottom: ${$.grey1};
  background-color: #edebeb;
  padding: ${$.layout.padding1}px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ContactNumber = styled.div`
  text-align: center;
  width: 80%;
  margin: 0 auto;
  font-size: 18px;
  color: ${$.color.lightblack};
  font-family: Helvetica Medium;
  ${$.ellipsis}
`;

const BackContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  top: 10px;

  > svg {
    fill: #3697ff;
    width: 24px;
    height: 24px;
  }

  > div {
    display: inline-block;
    border-radius: 10px;
    font-family: Helvetica Light;
    color: ${$.color.white};
    padding: 4px 7px;
    font-size: 12px;
    text-align: center;
    line-height: 12px;
    align-self: auto;
    background-color: #3697ff;
  }
`;

const TopBar = ({ contactNumber, unread }) => (
  <Container>
    <BackContainer>
      <BackIcon />
      {unread !== null && unread !== 0 && <div>{unread}</div>}
    </BackContainer>
    <ContactNumber title={contactNumber}>{contactNumber}</ContactNumber>
  </Container>
);

TopBar.propTypes = {
  contactNumber: PropTypes.string.isRequired,
  unread: PropTypes.number,
};

TopBar.defaultProps = {
  unread: null,
};

export default TopBar;
