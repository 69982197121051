import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { BreadcrumbJsonLd } from 'gatsby-plugin-next-seo';

import Cards from '../components/Topic/Index/Cards';
import Banner from '../components/Topic/Index/Banner';
import Seo from '../components/Seo';
import keywords from '../utils/keywords';

const TopicIndexPage = ({ location, data }) => (
  <>
    <Seo
      title="Topics | Geeknabe - ACG Blog"
      location={location}
      description="Check out all topics available in Geeknabe here!"
      keywords={keywords}
      meta={[
        {
          property: 'og:type',
          content: 'website',
        },
      ]}
    />
    <BreadcrumbJsonLd
      itemListElements={[
        {
          position: 1,
          name: 'Latest Posts',
          item: 'https://geeknabe.com',
        },
        {
          position: 2,
          name: 'Topic',
          item: 'https://geeknabe.com/topic/',
        },
      ]}
    />
    <Banner />
    <Cards items={data.allMdx.edges} />
  </>
);

TopicIndexPage.defaultProps = {
  location: {},
  data: {},
};

TopicIndexPage.propTypes = {
  location: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  data: PropTypes.objectOf(PropTypes.object),
};

export default TopicIndexPage;

export const query = graphql`
  query {
    allMdx(filter: { fileAbsolutePath: { regex: "/mdxblog/" } }) {
      edges {
        node {
          frontmatter {
            tags
          }
        }
      }
    }
  }
`;
