import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Image = makeShortcode("Image");
const Ads = makeShortcode("Ads");
const RemoteLink = makeShortcode("RemoteLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I wanted to add the term `}<strong parentName="p">{`shoujo`}</strong>{` or `}<strong parentName="p">{`josei`}</strong>{` to the title of this blog post, but decided against it. Some shoujo or josei manga don't necessarily have romance in them! So I chose to use the term `}<strong parentName="p">{`romance manga`}</strong>{` in the title instead. All three romance manga titles suggested in here give me the strongest `}<em parentName="p">{`moe feeling`}</em>{` in every scene. You can say that the image below is my perpetual state whenever I read these titles.`}</p>
    <Image alt="HEART ATTACK MEME" caption="If I die from reading these titles, then so be it." name="too-cute-heart-attack-meme.jpg" mdxType="Image" />
    <p>{`Most romance manga have these doki-doki scenes that make you go `}<em parentName="p">{`AAA THEY FINALLY KISSED`}</em>{` while some excel at breaking your heart in the name of lovey-dovey plot. But these titles? They make you go `}<em parentName="p">{`hnnnnng`}</em>{` at every possible lovey scene. Ever since I started reading these manga titles, I couldn’t stop going into`}<strong parentName="p">{` moe overdrive`}</strong>{`. They are really cute! Cute enough to spur me to write this`}<strong parentName="p">{` manga recommendation post`}</strong>{` so everyone will go read them ASAP.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Romance Manga Recommendation #1: Hokuou Kizoku to Moukinzuma no Yukiguni Karigurashi (北欧貴族と猛禽妻の雪国狩り暮らし)`}</h2>
    <p>{`This is one of currently ongoing favourite josei manga. The male lead is respectful and loving while the female lead answers to his affection with much gusto. These two are a super wholesome couple, I couldn’t recommend this manga enough.`}</p>
    <Image alt="Hokuou Kizoku to Moukinzuma no Yukiguni Karigurashi light novel cover" caption="I found this lovely cover of Hokuou Kizoku that I think everyone should see!" name="hokuou-kizoku-to-moukinzuma-no-yukiguni-karigurashi-light-novel-cover.jpg" mdxType="Image" />
    <p>{`Ritzhardt is our male lead in this story. He’s a noble yet he braids his hair. Among the ladies of high society, he’s known as the `}<em parentName="p">{`Yeti`}</em>{` who every now and then, would attend social parties to look for a spouse. He `}<em parentName="p">{`did `}</em>{`manage to bring back a lady once, but she annulled the engagement super quickly after coming to his territory. You see, life in Ritzhardt’s territory is `}<strong parentName="p">{`harsh`}</strong>{`. It’s `}<em parentName="p">{`cold `}</em>{`and there’s barely any entertainment. No high-born lady could withstand such an environment! Luck has it that a tough-looking lady caught Ritzhardt’s eye. Her name’s Sieglinde and she’s a renowned soldier. Back in the army, she’s known as the Crimson Eagle. Ritz fell in love at first sight. He proposed to her on the spot!`}</p>
    <Image alt="MARRIAGE PANEL FOR Hokuou Kizoku to Moukinzuma no Yukiguni Karigurashi" caption="Marry me? Of course it needs a 2-page spread." name="hokuou-kizoku-to-moukinzuma-no-yukiguni-karigurashi-manga-panel-1.jpg" mdxType="Image" />
    <p>{`Strangely enough, she accepted his proposal but with a condition - she will live in his wintry territory as his wife for a year. Kinda like a probation period because Sieglinde isn’t sure their relationship will work out or not. Thus begin the newly-wed slice-of-life period! You see the both of them doing activities together and teaching each other. Ritz never pushes Sieg away or does anything that makes her uncomfortable. Sieg on the other hand, welcomes the new experience when living in Ritz’s territory with an open mind. The both of them are so lovey-dovey in a comfy manner, you just go `}<em parentName="p">{`aww that’s relationship goals right there`}</em>{`. Please give this manga a try!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Romance Manga Recommendation #2: Mobuko no Koi (モブ子の恋)`}</h2>
    <p>{`The art is sweet and clean to read. It reminds me a lot of Koi wa Ameagari no You ni… A quick Google search confirms my suspicion. The `}<RemoteLink to="https://myanimelist.net/people/44774/Akane_Tamura" mdxType="RemoteLink">{`author for Mobuko no Koi`}</RemoteLink>{` used to be an assistant to `}<RemoteLink to="https://myanimelist.net/manga/88600/Koi_wa_Ameagari_no_You_ni" mdxType="RemoteLink">{`Koi wa Ameagari`}</RemoteLink>{`’s author!`}</p>
    <Image alt="Mobuko no Koi manga cover" caption="She's looking outside. Probably enjoying the view. But Irie is looking at her instead... *cries because this cover is so cute*" name="mobuko-no-koi-manga-cover.jpg" mdxType="Image" />
    <p>{`Our heroine Nobuko is a shy girl in her 20s. Most folks can order food from the waiter/waitress without much problem, but Nobuko is the type of girl that won’t correct the waiter/waitress if they got her order wrong. Most introverts aren’t the assertive type and that’s fine. However, Nobuko is in her own league when it comes to being `}<strong parentName="p">{`super introvert`}</strong>{`. She’s way too shy, way too quiet and way too nice. She calls herself a side character, the poor thing.`}</p>
    <p>{`Like every romance out there, her crush changed her in `}<em parentName="p">{`many ways`}</em>{`. She started wanting to be heard by her crush. She wants to be seen. Lucky for her, Irie `}<em parentName="p">{`did notice`}</em>{`. Mobuko’s crush has a crush on her too, fancy that! And the best part? He’s equally quiet too, but not as quiet as Mobuko. They say it takes two like-minded individuals to understand each other and this is what’s going on with Irie. While Mobuko is too shy to voice out, Irie took note of her awkwardness and helped her out many times. He’s the type that noticed Mobuko staying quiet and tells the waiter, “Excuse me, you mistook her order”.`}</p>
    <p>{`You get me? Like, you get the idea of this `}<em parentName="p">{`damn moe romance`}</em>{` now?! Everytime he does that, I just go`}<strong parentName="p">{` “Hnnng, MARRY HIM”.`}</strong>{` Mobuko no Koi is about a shy couple who works their hardest to get out of their comfort zone, yet understands each other without a word. `}<em parentName="p">{`Urgh so cute`}</em>{`. Definitely great stuff.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Romance Manga Recommendation #3: A Sign of Affection (ゆびさきと恋々)`}</h2>
    <p>{`Is it possible to die from reading about sweet, sweet romance? I’m writing this from my coffin and yes, it’s possible. The WiFi signal is terrible but I do my best because everyone needs to read this ASAP.`}</p>
    <Image alt="A sign of affection manga cover" caption="The cover is a phone wallpaper material, I'm telling ya." name="a-sign-of-affection-manga-cover.jpg" mdxType="Image" />
    <p>{`Yuki is a sweet college student with a super small world. It wasn’t that her worldview is small, she just didn’t know there’s a bigger world out there. You see, she’s `}<strong parentName="p">{`deaf`}</strong>{`. She owns a hearing aid but she could only hear garbled noise with it. She could get by with doing lip reading, writing her replies on her phone and showing the screen to the other fellow… But there’s a ton of things she’s missing out since she can't hear the world. If you don’t turn around to face her, she can't read your lips. You need to pat her shoulder to get her attention and never jump at her from behind. Small inconveniences to us able folks but big for her. Then, a chance encounter with Itsuomi on the train changed her world. You can say that he’s the very first that tries to be on the same playing field as her.`}</p>
    <p>{`He tries his best to learn sign language just so he could talk to her. He worded his words slowly and properly so she could read his lips. He’s so sweet to her, anyone will melt just by watching how he treats her. And it just so happened that Itsuomi loves to travel and see the world. He teaches Yuki about other countries, and tells her his experiences there. Is he the one? Duh. Yuki is smitten by Itsuomi (who wouldn’t). He’s just so sweet I ca-`}</p>
    <p>{`Lots of `}<RemoteLink to="https://en.wikipedia.org/wiki/Japanese_Sign_Language" mdxType="RemoteLink">{`JSL`}</RemoteLink>{` at work here. The speech bubbles are mostly written from Yuki’s perspective since she’s lip reading. Sometimes she misread their lips and that shows up in the speech bubbles. If the characters didn’t turn around, she couldn’t read their lips so that shows up in the story too. They do add in normal dialogue (because plot, duh) but the whole idea that certain dialogues aren't there because Yuki couldn’t lip-read is a great idea. This manga is great, I wholly recommend it. Please give it a try!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`All in all`}</h2>
    <p>{`I hope everyone enjoys the recommendation! Merry Christmas (I hope it’s not too late?) and Happy New Year!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      