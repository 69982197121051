import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Banner from '../components/Latest/Banner';
import Latest from '../components/Latest';
import Seo from '../components/Seo';
import keywords from '../utils/keywords';
import LatestSection from '../components/Latest/LatestSection';

const IndexPage = ({
  location,
  data: {
    latestMdx,
    animeMdx,
    mangaMdx,
    gamesMdx,
    techMdx,
    blogMdx,
    lightnovelMdx,
  },
}) => {
  const latestPostsSlugs = latestMdx.edges.map(({ node: { slug } }) => slug);
  const filteredMdx = [
    ...animeMdx.edges,
    ...mangaMdx.edges,
    ...gamesMdx.edges,
    ...techMdx.edges,
    ...blogMdx.edges,
    ...lightnovelMdx.edges,
  ].filter(({ node: { slug } }) => latestPostsSlugs.indexOf(slug) === -1);

  return (
    <>
      <Seo
        location={location}
        title="Home | Blog for Japanimation, manga and Nintendo fans"
        description="I'm nabe-chan, and I blog about anime, manga, light novels and games with mari-chan. You can find us on Twitter! @geeknabe, @_marichanx"
        keywords={['anibloggers', 'manga bloggers', ...keywords]}
        meta={[
          {
            property: 'og:type',
            content: 'blog',
          },
        ]}
      />
      <Banner />
      <LatestSection items={latestMdx.edges} />
      <Latest items={filteredMdx} />
    </>
  );
};

IndexPage.defaultProps = {
  location: {},
  data: {},
};

IndexPage.propTypes = {
  location: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  data: PropTypes.objectOf(PropTypes.object),
};

export default IndexPage;

export const query = graphql`
  {
    latestMdx: allMdx(
      limit: 4
      sort: { order: DESC, fields: frontmatter___published }
      filter: { fileAbsolutePath: { regex: "/mdxblog/" } }
    ) {
      edges {
        node {
          frontmatter {
            tags
            banner
            excerpt
            published
            title
          }
          slug
          timeToRead
        }
      }
    }
    animeMdx: allMdx(
      limit: 7
      sort: { order: DESC, fields: frontmatter___published }
      filter: {
        fileAbsolutePath: { regex: "/mdxblog/" }
        frontmatter: { tags: { eq: "anime" } }
      }
    ) {
      edges {
        node {
          frontmatter {
            tags
            banner
            excerpt
            published
            title
          }
          slug
          timeToRead
        }
      }
    }
    mangaMdx: allMdx(
      limit: 7
      sort: { order: DESC, fields: frontmatter___published }
      filter: {
        fileAbsolutePath: { regex: "/mdxblog/" }
        frontmatter: { tags: { eq: "manga" } }
      }
    ) {
      edges {
        node {
          frontmatter {
            tags
            banner
            excerpt
            published
            title
          }
          slug
          timeToRead
        }
      }
    }
    techMdx: allMdx(
      limit: 7
      sort: { order: DESC, fields: frontmatter___published }
      filter: {
        fileAbsolutePath: { regex: "/mdxblog/" }
        frontmatter: { tags: { eq: "tech" } }
      }
    ) {
      edges {
        node {
          frontmatter {
            tags
            banner
            excerpt
            published
            title
          }
          slug
          timeToRead
        }
      }
    }
    blogMdx: allMdx(
      limit: 7
      sort: { order: DESC, fields: frontmatter___published }
      filter: {
        fileAbsolutePath: { regex: "/mdxblog/" }
        frontmatter: { tags: { eq: "blog" } }
      }
    ) {
      edges {
        node {
          frontmatter {
            tags
            banner
            excerpt
            published
            title
          }
          slug
          timeToRead
        }
      }
    }
    gamesMdx: allMdx(
      limit: 7
      sort: { order: DESC, fields: frontmatter___published }
      filter: {
        fileAbsolutePath: { regex: "/mdxblog/" }
        frontmatter: { tags: { eq: "games" } }
      }
    ) {
      edges {
        node {
          frontmatter {
            tags
            banner
            excerpt
            published
            title
          }
          slug
          timeToRead
        }
      }
    }
    lightnovelMdx: allMdx(
      limit: 7
      sort: { order: DESC, fields: frontmatter___published }
      filter: {
        fileAbsolutePath: { regex: "/mdxblog/" }
        frontmatter: { tags: { eq: "lightnovel" } }
      }
    ) {
      edges {
        node {
          frontmatter {
            tags
            banner
            excerpt
            published
            title
          }
          slug
          timeToRead
        }
      }
    }
  }
`;
