import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const RemoteLink = makeShortcode("RemoteLink");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Just days ago, I was doing a revamp on my blog's homepage. I wanted to add a Twitter timeline, a Pinterest board of my account and a widget that shows blogs that I follow.`}</p>
    <p>{`Twitter's was fairly simple. All I needed to do is add a script tag and a link. Done. However, Pinterest's is more strict with where its contents are shown. If embedded on a site running on http, it returns an error. This is a problem because I need to see that it is working before I push it to production. I can't be pushing to production just to check how things are!`}</p>
    <Ads mdxType="Ads" />
    <p>{`So I tried looking around for a solution. I knew `}<RemoteLink to="https://www.gatsbyjs.org/docs/gatsby-cli/" mdxType="RemoteLink">{`Gatsby's CLI has a --https command`}</RemoteLink>{`, but from my prior experience, I need a self-signed certificate as well. You can easily create one using `}<RemoteLink to="https://www.openssl.org/" mdxType="RemoteLink">{`OpenSSL`}</RemoteLink>{`. Open up your terminal and type in `}<em parentName="p">{`openssl`}</em>{` to check if you have it. If not, you'll need to download it. OpenSSL comes with Mac OSX. For Windows, `}<em parentName="p">{`openssl`}</em>{` comes with Git Bash.`}</p>
    <p>{`On your terminal, change your directory to your project's root folder and type the following:`}</p>
    <pre>{`openssl req -nodes -new -x509 -keyout server.key -out server.cert`}</pre>
    <p>{`A few questions will pop up, but you can leave them all empty by tapping the Enter key. `}<strong parentName="p">{`However, do not leave Common Name and email address fields empty. Add `}<em parentName="strong">{`localhost`}</em>{` to the Common Name, and your email address to the email field.`}</strong></p>
    <pre>{`Common Name (eg, fully qualified host name) []: localhost
Email Address []: nabechandayo@example.com`}</pre>
    <p>{`Done. You'll find 2 files in your project root's folder - `}<em parentName="p">{`server.key`}</em>{` and `}<em parentName="p">{`server.cert`}</em>{`. These are your private key and self-signed certificate respectively. Next, you'll need to include these two files in whenever you run the command `}<em parentName="p">{`gatsby develop.`}</em></p>
    <Ads mdxType="Ads" />
    <p>{`In your `}<em parentName="p">{`package.json`}</em>{`, add the script below in. Run it, and everything seems to be running perfectly. That is, until you see an error from Gatsby's GraphQL Explorer. We're so close!`}</p>
    <pre>{`gatsby develop --https --key-file server.key --cert-file server.cert`}</pre>
    <Image alt="GatsbyJS's GraphQL Explorer don't like playing nice." caption="I know I signed it myself, error-san. It's my localhost! I should be able to do whatever I want to it!" name="https-for-gatsbyjs-development.jpg" mdxType="Image" />
    <p>{`To prevent this error from happening again, add this environment variable right before you run `}<em parentName="p">{`gatsby develop`}</em>{` as shown below.`}</p>
    <pre>{`NODE_TLS_REJECT_UNAUTHORIZED=0

// On Mac 
NODE_TLS_REJECT_UNAUTHORIZED=0 gatsby develop --https --key-file server.key --cert-file server.cert

// On Windows
set NODE_TLS_REJECT_UNAUTHORIZED=0&& gatsby develop --https --key-file server.key --cert-file server.cert
`}</pre>
    <p>{`Run it again, and try opening your localhost on Chrome, it should warn you but you can ignore it since the error is related to us self-signing the cert. Click on `}<em parentName="p">{`Proceed to localhost (unsafe)`}</em>{` to view your localhost.`}</p>
    <Image alt="Chrome's warning page for unsafe site" caption="Click on Proceed to move on. It should stop showing again the next time you run the server again." name="google-chrome-localhost-https-problem.jpg" mdxType="Image" />
    <p>{`You are now running localhost in https. Hurray!`}</p>
    <h2><strong parentName="h2">{`Pro-tip:`}</strong></h2>
    <p>{`Add `}<strong parentName="p">{`-H 0.0.0.0`}</strong>{` to your `}<em parentName="p">{`gatsby develop/serve`}</em>{` command to let other devices on the same network access your localhost. I use this often to check how the site looks like on other devices like IOS or Android phones.`}</p>
    <pre>{`gatsby develop -H 0.0.0.0`}</pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      