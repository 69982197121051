import PropTypes from 'prop-types';

import NotFound from '../components/NotFound';
import SEO from '../components/Seo';
import keywords from '../utils/keywords';

const NotFoundPage = ({ location }) => (
  <>
    <SEO
      location={location}
      title="404 Not Found | Geeknabe - ACG Blog"
      description="Oops! Looks like you met a dead end in my blog."
      meta={[
        {
          property: 'og:type',
          content: 'website',
        },
      ]}
      keywords={keywords}
    />
    <NotFound />
  </>
);

NotFoundPage.defaultProps = {
  location: {},
};

NotFoundPage.propTypes = {
  location: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default NotFoundPage;
