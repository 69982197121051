import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXFanfiction/Chapter/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`“`}<em parentName="p">{`Oh no`}</em>{`, Claude, what are you up to this time?” Lorenz, the bane of his existence, started lecturing him in the science lab. “Please don’t stink up the lab again, it was awful-”`}</p>
    <p>{`“Shh, shh, it’s fine Lorenz, it’s fine,” Claude pushed the purple haired male away. “I made a bet with Hilda that if I pranked someone of her choice, she’d buy me lunch for a week.” `}</p>
    <p>{`Claude liked to think of himself as a fun teenager, a few pranks on his classmates here and there didn’t hurt anyone. Besides, it wasn’t anything dangerous. He firmly stuck to the rule, ‘it’s only funny if everyone laughs’. And he liked making people laugh. `}</p>
    <p>{`His victim was Byleth Eisner. A quiet, unassuming girl in 2-B. He’d seen her a couple of times in the cafeteria or along the corridor. `}</p>
    <p>{`The grand plan was simple: he was going to dare her to drink this ‘bizarre’ looking drink he discovered recently.`}</p>
    <p>{`He was in the science lab trying to see if lemons actually turned a butterfly pea drink a different colour. This was actually a rather nutritious drink, the internet and his mother’s ‘Facebook Mom Group’ had been buzzing over the blue coloured flower for its alleged health benefits. This was going to be his ‘poison’, so to speak.`}</p>
    <Ads mdxType="Ads" />
    <p>{`He thought himself a genius for coming up with something `}<em parentName="p">{`so`}</em>{` spectacular. This was definitely going to work and Hilda would be on her way to bankruptcy by the end of the week, he cackled. `}</p>
    <p>{`He cleaned up his mess in the science lab and ran back home, excited and feeling all gleeful about his master scheme. He couldn’t just go up to her out of the blue so he took some time to actually be acquaintances with her first. `}</p>
    <p>{`But she was just the most...`}<em parentName="p">{`somber`}</em>{` teenager on the face of the earth. She never once smiled, or even showed any form of expression on her face. `}</p>
    <p>{`Claude prided himself as a master prankster, but this was going to be harder than it looked. Right now, his little prank project had turned into a mission to make Byleth elicit some sort of expression on her face. `}</p>
    <p>{`He finally decided on a day to carry out his plan and sat himself with Byleth and her small group of friends at lunch. `}</p>
    <p>{`“Hello everyone,” he greeted them with a smirk, “Would you believe me if I said if I have a bottle of poison with me and I’m going to dare one of you guys to drink it?” Claude grinned. It was great being a teenager, where one could afford to do the stupid and still kinda get away with it mostly unscathed.. `}</p>
    <p>{`“Ummm….” The kid named Ashe gawked at him, while the walking human statue, Dedue, merely stared with a frown. Yes, even `}<em parentName="p">{`he`}</em>{` had expressions. `}</p>
    <p>{`“I don’t believe you,” Byleth said as-a-matter-of-factly and crossed her arms. She was still a literal blank slate in front of him. `}</p>
    <Ads mdxType="Ads" />
    <p>{`He held up a finger and pulled out a small bottle containing blue liquid in it, “It won’t kill you but it...it’ll do stuff.” Actually, the wonders of this butterfly pea drink was allegedly endless. `}</p>
    <p>{`Claude emptied his cup of water from his tray and poured the drink into the transparent glass. He then picked up a lemon from his plate, “This lemon will activate the poison. Watch.” `}</p>
    <p>{`He promptly squeezed the citrus juice into the glass and instantly, the blue liquid changed to a light pink then as he squeezed even more, the liquid gradually changed into purple. `}</p>
    <p>{`“Voilà!” He gestured at the glass, Ashe’s jaw pretty much dropped to the floor and he stared at the glass inches from it. `}</p>
    <p>{`“Impressive,” Dedue muttered. `}</p>
    <p>{`Byleth didn’t say anything for a good minute as she looked at the butterfly pea plus lemon juice drink. “Go on Byleth, be a pal and test it out for me,” he grinned.  `}</p>
    <p>{`And just as according to plan, Byleth grabbed the glass and swallowed its contents in one gulp. `}</p>
    <p>{`“B-Byleth!” Ashe cried out, even Dedue looked concerned. `}</p>
    <p>{`But what he `}<em parentName="p">{`did not`}</em>{` expect was Byleth falling backwards out of the bench a few seconds later. Dedue grabbed her before she could hit her head on the linoleum tiles. Panic washed over Claude in a split second. `}</p>
    <Ads mdxType="Ads" />
    <p>{`Byleth’s body was limp in Dedue’s arms, even as the gentle giant gingerly shook her and lightly tapped her cheeks, she didn’t wake up. The people around them started murmuring and Claude urged Dedue to carry her to the infirmary before the situation escalated. `}</p>
    <p>{`The infirmary was open but of course it was `}<em parentName="p">{`just his luck`}</em>{` the school nurse was on their 5-min toilet break. “Shit!” Claude said, “This was `}<em parentName="p">{`not`}</em>{` supposed to happen! Is she allergic to anything?” He asked Dedue in a frantic voice. `}</p>
    <p>{`“Not that I know of. Was it really poison, Claude?”`}</p>
    <p>{`He shook his head violently, “No! It was just a...stupid colour changing juice my mum told me about!”  `}</p>
    <p>{`“Oh Byleth,” Ashe said shakily, holding onto her hand, “Please wake up! Claude, do something…! You caused this!” `}</p>
    <p>{`Claude was not in his right mind at all, this was the first time something like this happened in all his years of playing pranks on his classmates. He absolutely regretted not listening to his parents, who nagged him about the dangers of practical jokes. `}</p>
    <p>{`His panic levels rose with each passing second and the only thing that came to mind was, “I’m gonna try CPR!” He declared. `}</p>
    <p>{`Dedue had already placed her on an empty bed and immediately Claude copied what he saw on TV; He pinched her nose, opened her mouth, lowered his lips over hers and blew into her mouth with all his might. `}</p>
    <p>{`But again, something he did not anticipate happened. Byleth snorted, almost right into his mouth.  `}</p>
    <p>{`“Okay, okay, enough, I’m fine,” she said in between laughs and pushed him away. Claude stared at her as if she just came back from the dead.`}</p>
    <p>{`What the heck just happened?`}</p>
    <p>{`“Hilda told me about this sometime ago,” she explained. “And your mother is not the only mother obsessed with butterfly pea juice, you know.” `}</p>
    <Ads mdxType="Ads" />
    <p>{`“You’re alive!” Ashe cried out again and Dedue let out a visible sigh. `}</p>
    <p>{`Claude slumped down the side of the bed, feeling as if he just ran a marathon. “Don’t do that!” `}</p>
    <p>{`Byleth sat up straight and stared at him, “No, `}<em parentName="p">{`you`}</em>{` don’t do that,” she said sternly. “It’s not funny. No free lunch is worth daring a person to drink something they don’t know what’s inside. What if I was actually allergic to something?” `}</p>
    <p>{`Wow. Claude was actually getting lectured by a classmate about pranks. Most of the time it was really well received. He was weirdly...enjoying the change of reaction now that he achieved his goal, somewhat. “Alright, alright. You’re right. It...wasn’t funny. I’m sorry.” He did actually feel bad. `}</p>
    <p>{`“Now promise me you won’t ever do that again,” she said firmly. He swore she might’ve had a little smirk on while lecturing him for a brief second. `}</p>
    <p>{`“Sure, teach,” he nodded.  `}</p>
    <p>{`“Good,” Byleth said, hopping off the bed and apologized to her friends. The three of them left and presumably went to continue having lunch. Truth be told, Claude lost his appetite the second Byleth ‘fainted’. `}</p>
    <p>{`Now that the adrenaline was wearing off, he was feeling really crappy about the whole thing. Why did he think this was a good idea in the first place? Why did he let Hilda goad him into doing something like this? No, he shouldn’t be putting the blame on others. This was his fault and his fault alone. He was a reformed man now. `}</p>
    <p>{`The next few days, Claude started having regular lunches with Byleth, just to see if she was okay after the incident. And maybe, to see if she was capable of proper emotions instead of just a one off thing, like that little smirk she did when she scolded him. Just a harmless observation really, and also of course, to pay for her lunches for a week as an apology, but she declined.  `}</p>
    <Ads mdxType="Ads" />
    <p>{`“I don’t trust you,” she said blandly. `}</p>
    <p>{`“Ouch, that hurts,” he clutched his chest and laughed. `}</p>
    <p>{`“You did try to ‘poison’ me,” she replied. `}</p>
    <p>{`“Hey teach, I’m trying to make amends here!” He retorted. The ‘teach’ nickname stuck, since she was the first person aside from his parents to properly tell him off. `}</p>
    <p>{`But as the days turned into weeks, Claude found himself more and more attracted to Byleth. Who, by the way, did express proper emotions, especially when she ate something delicious and she ate a lot. `}</p>
    <p>{`However, Claude also found her pure, deadpan expression somehow...alluring to him. He liked looking into her big eyes when she said something wacky without an ounce of emotion on her face. But also when she smirked, or...frowned and...`}</p>
    <p>{`He gasped to himself, was he...was he falling for her? These were the tell tale signs of a teenage crush, and Claude Von Riegan had fallen for its clutches. `}</p>
    <p>{`He sighed, and months later, finally stopped fighting his emotions and openly embraced his feelings for her. Resigning to just enjoy her company and hope to the Goddess she didn’t have a boyfriend. He would absolutely be crushed. `}</p>
    <p>{`“Hey, Claude!” He heard someone call out to him after school as he was walking to the train station. Claude turned around to see his favourite person walking towards him. “‘Sup, teach?” He smiled at her. `}</p>
    <p>{`“...” `}</p>
    <p>{`“...”`}</p>
    <p>{`“...”`}</p>
    <p>{`“Come on, say something, this is getting awkward,” he chuckled.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Was she...blushing? Claude quickly looked away, he couldn’t handle such a cute sight in front of him. `}</p>
    <p>{`“Do you...want to grab a meal with me?” She finally said and he looked at her like she had gone mad. She had refused, for months and months, to let him buy her the apology meal he kept offering, all because she openly declared she didn’t trust him. `}</p>
    <p>{`Yet here she was, in broad daylight, inviting him out for a meal. Oh Claude was beyond ecstatic, he nearly did a fist pump even. `}</p>
    <p>{`He cleared his throat, “Sure, where and when?” `}</p>
    <p>{`She shifted her feet, not looking him in the eye for some reason, “...Now. Anywhere you like.” `}</p>
    <p>{`“Okay, I think I know just the place. And there are student discounts too,” he said with a big grin on his face. “Come on-”`}</p>
    <p>{`Just as he was about to gesture for her to walk with him, she slipped her hand into his. Claude blushed right up to his ears and eyeballs, and he didn’t know if that was even possible. `}</p>
    <p>{`She pulled him and started walking, snapping him out of his euphoria, saying, “You’re paying.” `}</p>
    <p>{`Claude gripped her hand and said, “Yes.” `}</p>
    <p>{`“I eat a lot,” she said, not looking at him at all. Her hand was small and soft in his hand. He loved it. `}</p>
    <p>{`“I know,” he replied. “I like watching you eat copious amounts of rice everyday. It’s fascinating.” `}</p>
    <p>{`“You’re weird, Claude.” `}</p>
    <Ads mdxType="Ads" />
    <p>{`He chuckled, “I know.” `}</p>
    <p>{`Claude couldn’t resist and laced their fingers a few seconds later. `}</p>
    <p>{`And to his delight, she didn’t resist. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      