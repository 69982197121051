import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const RemoteLink = makeShortcode("RemoteLink");
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I have a penchant for anything cute. Just send me a meme, gif or a fanart of anything round or/and fluffy, and you’ll see me drop down to my knees instantaneously. So it’s no wonder that this `}<RemoteLink to="https://molcar-anime.com/" mdxType="RemoteLink">{`PUI PUI MOLCAR`}</RemoteLink>{` will make me cry in a heartbeat. I mean, have you seen anything this cute before?!`}</p>
    <p>{`PUI PUI MOLCAR is a stop-motion anime series (It’s being marketed as an anime! Don’t hurt me!) that is set in a world where guinea pigs are cars for their humans. Their humans sit in them to get to work, get groceries done, etc… but their cars have a will of their own. I mean, they ARE guinea pigs. They have to be fed, kept happy, and so forth. To be fair, I don’t know how to make sense of the first episode I just watched but it’s so cute… So, so cute! I guess that’s all it matters! Their expression and those wiggly actions whenever they move is perfect. The stop motion folks must have done a lot of work just readjusting the guinea pig felt puppets!`}</p>
    <Ads mdxType="Ads" />
    <p>{`I wasn’t even aware of this series until I saw a tweet by a `}<RemoteLink to="https://twitter.com/bombori777/status/1351421477652885506?s=20" mdxType="RemoteLink">{`Japanese hamster twitter account that I followed`}</RemoteLink>{`. They won’t stop making fanart for the series with their own hamsters. Urghhh, critical hit! So I googled and noticed that Muse Asia actually posted the shorts for free on their YouTube channel. Huzzah! Each PUI PUI MOLCAR episode takes about 3 minutes or so. Really short but it’s more than enough! And the best part? These guinea pig cars have NAMES. I just saw their `}<RemoteLink to="https://anilist.co/anime/127690/Pui-Pui-Molcar" mdxType="RemoteLink">{`Anilist page`}</RemoteLink>{` and I’m so floored. Oh ma ma mia, if this is a dream, do NOT wake me up!`}</p>
    <Image alt="PUI PUI MOLCAR character names" caption="I died when I saw their names. Potato! XD" name="pui-pui-molcar-anime-characters.jpg" mdxType="Image" />
    <p>{`There are very little things to say for this series, other than the fact that it’s being touted as an anime and I just want to tell you how much I love it! I thought they were hamsters at first, but guinea pigs are fine too. I have added a ton of screenshots (Because my fingers can’t leave the Printscreen buttons at all, each screencap is too cute) so enjoy!`}</p>
    <Ads mdxType="Ads" />
    <Image alt="PUI PUI MOLCAR anime screencap 1" caption="Their series’s starting screen! Oops, I mean, PUI PUI MOLCAR ANIME LOGO! From the left, Abby, Choko, Potato, Shiromo and Teddy. I LOVE YOU" name="pui-pui-molcar-anime-screencap-1.jpg" mdxType="Image" />
    <Image alt="PUI PUI MOLCAR anime screencap 2" caption="Urgh argh hnnng so cute. Episode 1’s title screen!" name="pui-pui-molcar-anime-screencap-2.jpg" mdxType="Image" />
    <Image alt="PUI PUI MOLCAR anime screencap 3" caption="He’s in a traffic jam and he’s trying to take a peek at the front!" name="pui-pui-molcar-anime-screencap-3.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <Image alt="PUI PUI MOLCAR anime screencap 4" caption="I died a little when I saw an ambulance molcar crying and a gaping mouth Potato. Like, its so adorable to see it in motion." name="pui-pui-molcar-anime-screencap-4.jpg" mdxType="Image" />
    <Image alt="PUI PUI MOLCAR anime screencap 5" caption="So the solution for this traffic jam is to climb over the other molcars HAHA. Wiggle wiggle wiggle" name="pui-pui-molcar-anime-screencap-5.jpg" mdxType="Image" />
    <Image alt="PUI PUI MOLCAR anime screencap 6" caption="The second best thing is that inside these molcars are their humans! They are supposed to be driving their molcars but it’s the other way around instead HAHA" name="pui-pui-molcar-anime-screencap-6.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <Image alt="PUI PUI MOLCAR anime screencap 7" caption="Everyone got to their destination! A happy end!" name="pui-pui-molcar-anime-screencap-7.jpg" mdxType="Image" />
    <h2>{`All in all`}</h2>
    <p>{`Go watch it. NOW. It's uber cute if you see them in action! You can watch it on Muse Asia YouTube channel which I have added below. I hope Muse Asia didn't block your region!`}</p>
    <Video src="https://www.youtube.com/embed/Vnd406Qma5g" title="Pui Pui Molcar EP1" mdxType="Video" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      