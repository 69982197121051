import styled, { createGlobalStyle } from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import $ from '../../../styles/globals';
import HeartIt from '../../MDXBlogpost/HeartIt';
import ShareBox from '../../MDXBlogpost/SocialTool/ShareBox';
import LeftChevronIcon from '../../../assets/icons/left-chevron.svg';
import RightChevronIcon from '../../../assets/icons/right-chevron.svg';

const Section = styled.section`
  margin: ${$.layout.margin8}px 0;

  ${$.screen.desktop} {
    width: 700px !important;
    padding-left: calc((100vw - 700px) / 2) !important;
    padding-right: calc((100vw - 700px) / 2) !important;
  }

  ${$.screen.tablet} {
    width: 700px !important;
    padding-left: calc((100vw - 700px) / 2) !important;
    padding-right: calc((100vw - 700px) / 2) !important;
  }
`;

const Container = styled.div``;

const ShareContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  margin-top: ${$.layout.margin5}px;
`;

const NavigationContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: ${$.layout.margin10}px;
`;

const PrevChapter = styled(Link)`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  color: ${$.brown5};
  font-family: Rubik Regular;
  font-weight: bold;
  transition: color 0.3s ease;
  opacity: ${({ show }) => show};

  > svg {
    transition: fill 0.3s ease;
    fill: ${$.brown5};
    width: 20px;
    height: 20px;
  }

  ${$.screen.desktop} {
    &:hover {
      color: ${$.brown3};
      > svg {
        fill: ${$.brown3};
      }
    }
  }
`;

const NextChapter = styled(Link)`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  color: ${$.brown5};
  font-family: Rubik Regular;
  font-weight: bold;
  transition: color 0.3s ease;
  opacity: ${({ show }) => show};

  > svg {
    transition: fill 0.3s ease;
    fill: ${$.brown5};
    width: 20px;
    height: 20px;
  }

  ${$.screen.desktop} {
    &:hover {
      color: ${$.brown3};
      > svg {
        fill: ${$.brown3};
      }
    }
  }
`;

const IndexLink = styled(Link)`
  text-decoration: none;
  font-family: Rubik Regular;
  font-weight: bold;
  transition: color 0.3s ease;
  color: ${$.brown5};
  margin: 0 ${$.layout.margin3}px;
  &:visited {
    color: ${$.brown5};
  }

  ${$.screen.desktop} {
    &:hover {
      color: ${$.brown3};
    }
  }
`;

const NightMode = createGlobalStyle`
  body.night-mode {
    ${NextChapter}, ${PrevChapter} {
      color: ${$.white1};
      > svg {
        fill: ${$.brown1};
      }
      ${$.screen.desktop} {
        &:hover {
          color: ${$.brown1};
          > svg {
            fill: ${$.brown1};
          }
        }
      }
    }
    ${IndexLink} {
      color: ${$.white1};
      &:visited {
        color: ${$.brown1};
      }
      ${$.screen.desktop} {
        &:hover {
          color: ${$.brown1};
        }
      }
    }
  }
`;

const Navigation = ({ hearts, setHearts, titleSlug, next, prev }) => (
  <Section>
    <Container>
      <NightMode />
      <NavigationContainer>
        <PrevChapter
          to={prev}
          show={prev ? 1 : 0}
          title="Go to previous chapter"
        >
          <LeftChevronIcon />
          <span>Prev</span>
        </PrevChapter>
        <IndexLink to={titleSlug} title="Back to Index">
          Back to Index
        </IndexLink>
        <NextChapter show={next ? 1 : 0} to={next} title="Go to next chapter">
          <span>Next</span>
          <RightChevronIcon />
        </NextChapter>
      </NavigationContainer>
      <HeartIt
        text="Enjoyed the chapter? Click to give mari-chan hearts!"
        hearts={hearts}
        setHearts={setHearts}
      />
      <ShareContainer>
        <ShareBox />
      </ShareContainer>
    </Container>
  </Section>
);

Navigation.defaultProps = {
  next: '',
  prev: '',
};

Navigation.propTypes = {
  titleSlug: PropTypes.string.isRequired,
  next: PropTypes.string,
  prev: PropTypes.string,
  hearts: PropTypes.oneOfType([PropTypes.object]).isRequired,
  setHearts: PropTypes.func.isRequired,
};

export default Navigation;
