import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXFanfiction/Chapter/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Divider = makeShortcode("Divider");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Dimitri couldn't believe what he was hearing, “Could…could you say that again, professor?”`}</p>
    <p>{`She…his Ashen Demon professor, was `}<em parentName="p">{`blushing`}</em>{` as she repeated her words, “I…don't mind being like this with you. I like it, actually.”`}</p>
    <p>{`He blinked down at her. In fact he still couldn't believe what he heard — he was effectively pinning her against the wall because he panicked and didn't want Sylvain to know the Professor was in his room — and she…likes it?`}</p>
    <p>{`But more than that, Dimitri could not believe the words that were about to spill out of his mouth, nor could he stop himself from saying, “Professor…can…I…kiss you?”`}</p>
    <p>{`Her big green eyes stared back at him in shock and he took a step back in more panic.`}</p>
    <p>{`“S-Sorry, sorry, I'm so sorry, professor! Please forgive me— Ah, it wasn't a joke, but it was extremely impudent and rude of me— `}<em parentName="p">{`mmmphh?!!`}</em>{`”`}</p>
    <p>{`He was dreaming. He was sure of it. The professor had gone on her toes and gently pressed her lips on his.`}</p>
    <p>{`“Dimitri? Are you alright?” She waved a hand in his face, “I-I've never kissed anyone before—”`}</p>
    <p>{`Dimitri grabbed her head and crashed his lips onto hers, furiously devouring her as if all his emotions from the moment they met, until this moment, had been unleashed. He channelled all his yearning, pining and heartache for her into this sloppy, wet kiss.`}</p>
    <p>{`Her lips were soft, and so supple. Dimitri had no experience kissing either, but he sucked on them and she let out a moan. Ah. That's all it took for the hardness in between his legs to rise.`}</p>
    <p>{`“Dimitri— `}<em parentName="p">{`ahh—`}</em>{`” She pulled away and gasped, “—wait—”`}</p>
    <p>{`“No,” he whispered, grabbing her hand and pulling her close to him again. He kissed her again but this time, used his thumb to part her lips a little and pushed his tongue in. He couldn't taste anything but there was a hunger in him that wanted to taste every part of her nonetheless.`}</p>
    <p>{`She leaned into their kiss and slid her tongue in as well. The sounds of their kisses filled his room and his hips were involuntarily grinding against her.`}</p>
    <p>{`“Di-Dimitri, please wait—” she pushed his chest.`}</p>
    <p>{`His eyes shot open and he looked down to see the professor's eyes glazed and her lips glistening with his saliva. His eyes moved to where he gripped both her wrists. Only then did it hit him that he might've been kissing her against her will.`}</p>
    <p>{`But before he could say anything, the Professor laughed as though she read his mind. “I just thought…maybe…we should lock the door. If you didn't already,” she said softly as she met his gaze.`}</p>
    <p>{`“Oh. Umm. Y-Yes. That might be a good idea,” he sputtered. He shuffled over and with a click, the deed was done. Dimitri could hear his heartbeat in his ears.`}</p>
    <p><em parentName="p">{`Was this really happening?!`}</em></p>
    <p>{`If…if he remembered his formal education and unwilling informal education with Sylvain, if this continued…they were going to…`}</p>
    <p>{`Dimitri steadied his breathing.`}</p>
    <p>{`They were going to have sex.`}</p>
    <p>{`And sex, more often than not, lead to babies.`}</p>
    <p>{`An heir.`}</p>
    <p><em parentName="p">{`His`}</em>{` heir.`}</p>
    <p>{`With the professor.`}</p>
    <p>{`Surprisingly, the thought didn't scare him.`}</p>
    <p>{`In fact he was getting giddy with excitement. He might still be a student, but he was ready. Technically he was a prince and was already old enough to rule a kingdom, surely he was ready to father a child?`}</p>
    <p>{`“I…I don't have any sexual experience even though I'm older than you and your teacher. If you're uncomfortable with any of this, please let me know, Dimitri. We would be—” she chuckled, “Actually, we've already broken a few rules.”`}</p>
    <p>{`He turned around and hugged her, “I want nothing more than to make love to you, right now.”`}</p>
    <Divider mdxType="Divider" />
    <p>{`Byleth felt a squeeze in between her legs. She felt hot and impatient. Dimitri's words sent a fire that spread throughout her being. He said he wanted to make love to her.`}</p>
    <p>{`Actually, this whole time, Byleth was astounded by his and her boldness. In her line of work, she'd never dream she'd live long enough to experience all these wonderful, yet slightly confusing feelings in her life. Let alone in one night.`}</p>
    <p>{`Her father gave her the basics of how children were conceived, sure, but it was Manuela who shoved that sordid yet amazingly detailed illustrated book at her. Byleth felt shy whenever she stood beside Dimitri after she read it.`}</p>
    <p>{`She'd seen him topless countless times and of course he was well built. But it was only after she read that book she was hyper aware of what he might be packing down there.`}</p>
    <p>{`However, she didn't need to imagine anymore, it was right there; a huge, almost throbbing bulge pressed against her belly. Even through the layers of clothing they wore, she could feel how hard it was. And…it turned her on.`}</p>
    <p>{`Time didn't matter anymore as the both of them were embraced in each other's arms, deep kissing, tasting, licking. Everything was in a hazy-like pleasure for Byleth, she didn't even realise they were on his bed or that she was naked. She didn't notice until she saw her hands on his bare, chiselled chest.`}</p>
    <p>{`Everything about this boy—no, `}<em parentName="p">{`man,`}</em>{` was beautiful. Even his nipples were enticing.`}</p>
    <p>{`Byleth was eager to please and climbed on top of him, but her kisses were clumsy, sloppy. She didn't care, she wanted to kiss every part of him and as she did, Dimitri's hands were placed firmly on her ass, pressing down and grinding her on his very solid, swollen penis.`}</p>
    <p>{`They moaned into each other's mouth, Byleth was already feeling incredibly lewd before the actual act and was desperate for more. It seems Dimitri thought the same as he flipped them over and took over the action himself. His raw strength and manliness had Byleth completely breathless.`}</p>
    <p>{`“Goddess,” he breathed as he kissed her nape, “You have no idea how much I've wanted this,” Byleth let out a breathy moan as he sucked hard on her neck, quite possibly leaving a mark. He grinned down at her, “Shall I…leave it where it's visible, professor? So tomorrow when you meet the Archbishop, she'll know.”`}</p>
    <p>{`Who knew this polite, dreamy, blonde hair, blue eyed prince had the potential to be such a devilish tease? “I'll just tell her the Prince of Faerghus just made love to me.” She smirked at him and licked her lips.`}</p>
    <p>{`“Oh, Professor,” Dimitri lifted her up on his lap effortlessly and wrapped his arms around her, giving her more deep kisses. They did that a lot tonight, kissing, that is. Her hands wandered down and enveloped his erection with her hand, teasing the tip with her thumb.`}</p>
    <p>{`She looked down to see he was leaking, and spat into her hand to stroke it more. Her hand was small and could barely wrap round his girth. The look on his face was that of ecstasy as she rubbed his member.`}</p>
    <p>{`“`}<em parentName="p">{`Nnngh`}</em>{`, professor,” he groaned, “Mmm, yes— just there — so good—!”`}</p>
    <p>{`It didn't take long before he ejaculated in her hands, he blushed and panicked, “I'm so sorry! It got on your face too, ahhh, I didn't mean to finish so fast!” Byleth would've been disappointed if that was the case, but she looked down again and saw he was still very erect, even though he just came.`}</p>
    <p>{`She didn't say anything, merely staring into his eyes as she licked his slightly bitter tasting semen clean from her hands. He panicked again and grabbed his cape to wipe her hands but she stopped him, slurping up the sticky remnants from in between her fingers.`}</p>
    <p>{`This must've turned him on again, since he stopped her immediately and pinned her, holding her hands down.`}</p>
    <p>{`He gave her a quick kiss before sucked on her nipple while he pinched and fondled the other; she could actually feel herself getting wet at the sensation.`}</p>
    <p>{`Dimitri moved his kisses downwards and opened her legs, suddenly, she felt shy, “Please don't look, it's…embarrassing.”`}</p>
    <p>{`He chuckled and leaned over to whisper in her ear, “No, it's not. It's beautiful.” He looked into her eyes and they kissed as he stroked her slit with his erection.`}</p>
    <p>{`When they pulled away, she looked down and was taken aback by how…how massive it was. He was only 18 and was sure to grow even more but Goddess, even now it was like an arm.`}</p>
    <p>{`“Ah, my apologies, I should probably loosen you up first,” Dimitri said as he lowered his mouth in between her legs.`}</p>
    <p>{`Byleth sat upright, “N-No, I haven't washe—!” She reached out to cover her bits but he already dove right in, sending a nice jolt up her spine and caused her nipples to instantly harden. “`}<em parentName="p">{`A-Ahhh…`}</em>{`! D-Dimitri, n-no…!”`}</p>
    <Divider mdxType="Divider" />
    <p>{`He didn't respond to her protests. Her silky seam was just begging to be eaten. Dimitri spread her open and slowly licked her from top to bottom, stopping at her swollen bud, giving it a gentle suck.`}</p>
    <p>{`Her protests stopped and were replaced by heaving breaths and moaning. The hand that was going to stop him now rested on the top of his head.`}</p>
    <p><em parentName="p">{`Make sure she's wet enough, lick your fingers and if possible, cut your fingernails before you insert it in. Slowly — Felix, stop glaring at me — slowly, insert one finger and then two when she's comfortable. And don't just force your thing in! Especially you, Dimitri and your monstrous strength!`}</em></p>
    <p>{`Sylvain's words echoed in his mind. He had strongly protested when Sylvain was 'educating' the boys one day but he still kept an open ear. He didn't want to hurt his future queen by being a brute.`}</p>
    <p><em parentName="p">{`Finally, just be confident, stay calm and don't fumble! Ah wait wait, yes most importantly, actually enjoy the damn process and love her…or him, whatever, I'm not particular — Ashe, why are you crying—`}</em></p>
    <p>{`He pushed her legs open even more and gave her long, slow licks, making sure no part of her beautiful, glistening slit was left behind. “Tell me, where it feels good,” he asked, his mouth still licking and sucking.`}</p>
    <p>{`“....H-Here…” She opened herself up and rubbed the spot just above her swollen, red bud. Ah, it seems she liked indirect contact. Without hesitation, he dove right in, sucking the spot she showed him and sliding his fingers in between her petals.`}</p>
    <p>{`He was thoroughly enjoying this (and made a mental note to do more research).`}</p>
    <p>{`When Dimitri inserted a finger, her hips jerked upwards and she let out a cry. Taking it as a good sign, he continued caressing the inside of her walls. His fingers already felt good inside her warm wetness. Dimitri was leaking at the thought of inserting his member in.`}</p>
    <p>{`First one, then two fingers as he continued his rhythm of sucking and licking.`}</p>
    <p>{`“I love…how— `}<em parentName="p">{`nngh—`}</em>{`” her hips arched upwards, “how rough your fingers are—! `}<em parentName="p">{`Ohh—`}</em>{` don't stop…!” Dimitri was pleased, very pleased. His calloused hands had a fan.`}</p>
    <p>{`In fact, Dimitri could already insert three fingers, but he was slightly worried she wasn't relaxed enough to receive him yet, until…`}</p>
    <p>{`“Please…put it in,” she begged him as he fingered her with three fingers, his entire mouth and jaw wet with drool and juices. He bitterly hated the fact that he couldn't actually taste her. “I need you…!” She cried out and sat up to kiss him.`}</p>
    <p>{`“Mmmm, salty,” she moaned as they kissed.`}</p>
    <p>{`Dimitri smirked at her, he liked that she was begging him. It made him feel like a man. He wiped his mouth with the back of his hand and went on his knees, sliding his very erected penis in between her folds to coat himself with her love juice. Dimitri was already feeling crazy amounts of pleasure just pleasuring her, he loved the view and how erotic everything sounded.`}</p>
    <p>{`“Don't tease me,” she mewled.`}</p>
    <p>{`He took a deep breath and slowly pushed himself in. A groan erupted from his throat and his mind went blank. Not even the incessant hauntings of his past could get a word in.`}</p>
    <p>{`His hips bucked forward on its own as he spread her legs wider. “Nnnghh, you're sucking me in — `}<em parentName="p">{`ahhnnghh—`}</em>{` so good…!” He rasped as he pulled out and pushed in again. The professor was tight and warm, it was melting his penis and his mind at the same time.`}</p>
    <p>{`“Are you…`}<em parentName="p">{`ughhh`}</em>{`, alright, professor?” He could barely get the words out to be honest.`}</p>
    <p>{`“You're so big…! But I'm…alright…don't stop, please…! `}<em parentName="p">{`Ohhh`}</em>{`, goddess, Dimitri, yes—” Dimitri's entire being had never felt such insane levels of bliss before so much so he could feel his nipples hardening. Even more so when her hands brush over them.`}</p>
    <p>{`He arched his back and let out moans after moans as he repeatedly plunged into her warm, squeezing walls. The sounds of their slapping skins echoed in his room and he wondered if Sylvain could hear.`}</p>
    <p>{`He didn't care.`}</p>
    <p>{`Dimitri looked down to see the professor was stifling her cries of pleasure by covering her mouth. Dimitri pulled her hands away and pinned them above her head and he thrust deeper into her. “I want to hear your voice,” he panted. “Let me hear your voice.”`}</p>
    <p>{`She bit her bottom lip, “No— I can't… `}<em parentName="p">{`Mmmphh…`}</em>{` ahh…it's so good…!” A grunt escaped him as he got bigger inside of her at the sound of her moaning. The professor normally didn't have so much to say, so he was absolutely thrilled to hear her being so verbal now. Especially since `}<em parentName="p">{`he`}</em>{` was the cause of her…arousing cries of pleasure.`}</p>
    <p>{`But it didn't matter to be honest, whether she was holding her voice back or not, the sounds of their skins slapping each other was loud enough anyway. The professor let out a sob when he pinched her perky nipple with one hand and rolled it in between his fingers.`}</p>
    <p>{`He smirked and released her hands, now giving his full attention to her lush, full breasts, and she squirmed under him, gripping the sheets.`}</p>
    <p>{`As his mind, body and penis drowned in pleasure, the princely persona he put on daily had disappeared completely. Right now, he was just Dimitri, a man making sweet love to a woman he'd been pining for ages.`}</p>
    <p>{`“Professor…! Can I.. `}<em parentName="p">{`nngg—ughh…`}</em>{` can I…go faster? Please?” Dimitri pleaded in her ear as he kissed and licked it.`}</p>
    <p>{`“...Yes…!” His hips were moving on their own, thrusting and honestly, it was pure, raw fucking at this point, there were no other words to describe it. He fucked her deep and hard.`}</p>
    <p>{`“It's…coming..something is… `}<em parentName="p">{`ahhh`}</em>{`, Dimitri, I c-can't…!”`}</p>
    <p>{`He kissed her trembling, red lips. “Me…too, professor, you feel so…good, so so good.”`}</p>
    <p>{`She pulled him down and wrapped her arms around his neck as he cradled her in his arms. Strangely, his crest didn't activate at all and he was fucking her `}<em parentName="p">{`hard`}</em>{`.`}</p>
    <p>{`He moaned in her ear as he neared his climax, “Professor, I'm coming, I'm coming, `}<em parentName="p">{`nnnggg-ahhh`}</em>{`, I'm going to finish inside you, okay?”`}</p>
    <p>{`“Y-Yes—!” She had already begun squeezing him as the waves of pleasure was evident on her face and spilled out of her lips in heavy pants and a few cries.`}</p>
    <p>{`His seed exploded deep inside her womb while her walls tightened around him, heightening his rapture as they panted and gripped each other desperately in their ecstasy.`}</p>
    <p>{`Dimitri collapsed onto her, then rolled over, still panting as if he ran across a mountain. They didn't say anything but smiled while holding each other.`}</p>
    <p>{`Dimitri coiled his arms around her, they were both very sticky but he just wanted her in his embrace. She didn't seem to mind and even planted a trail of kisses on his sweaty chest as he sniffed her hair.`}</p>
    <p>{`They were holding each other in silence for quite a while actually, when she leaned over and gave him a tender kiss on his lips. “I…that was…amazing, Dimitri,” the professor said, tucking her hair behind her ear. “I finally understand why people pay to do this now.”`}</p>
    <p>{`Dimitri let out a hearty laugh and pulled her back into a cuddle, “That's such…such a…something only `}<em parentName="p">{`you'd`}</em>{` say. It's adorable the way you think.”`}</p>
    <p>{`“I didn't know you were so experienced too, Dimitri,” she said, looking up at him.`}</p>
    <p>{`He blushed, “O-Oh? Umm, I…this was my first time…I don't know. Something just…some sort of carnal desire just took over and seemed to know what to do. Plus…Sylvain gave us…I mean me, some…tips.”`}</p>
    <p>{`“So…the boar in you finally broke free, huh?” She grinned.`}</p>
    <p>{`It took him a while to understand what she meant but he chuckled once he got the joke, “I suppose Felix was right all this while.”`}</p>
    <p>{`They cuddled in comfortable silence for a few more minutes when he realised he hadn't told her the most important thing, “Professor,” he sat up straight.`}</p>
    <p>{`“Yes?”`}</p>
    <p>{`“I…I've always…loved you. So…thank you for…giving me a taste of love, even if it's been one-sided all this while. And I'm sorry about that night at the Goddess Tower. I…never thought I deserved such happiness, actually I still don't believe—”`}</p>
    <p>{`“Shh.” She placed a finger on his lips, then kissed him. “You do, Dimitri.”`}</p>
    <p>{`He stroked her lips with his thumb, “The smile on your face is like…it's like whenever you smile at me, I feel peace. And just now, being with you, there are no words to describe it.”`}</p>
    <p>{`She didn't say anything and climbed on top of him and kissed him, sticking her tongue into his mouth. When they pulled away, a string of saliva connected them to each other.`}</p>
    <p>{`“Mmm, yes. I think I love you too, Dimitri. That night when you joked about…well, you know. I was…incredibly hurt. And I get proposals like that from other students as well — they all joke about marrying me once they inherit their father's titles but from you? It stung.”`}</p>
    <p>{`He looked away, “I will bear the shame for the rest of my life, I cannot apologise enough.”`}</p>
    <p>{`“No, no, please don't.” She cupped his face in her hands, “I'm not expressing myself well,” she huffed, “Erm…It was…refreshing because then I…understood how I felt for you. I was feeling guilty for days. You are a student, I'm your Professor. But just now, I couldn't control myself anymore. I'm just as relieved as you are, to be honest.”`}</p>
    <p>{`Dimitri's jaw nearly fell open. “I…Oh…I…Professor, I…” He looked into her eyes and a surge of overwhelming love erupted inside him.`}</p>
    <p>{`“Ah…your ummm…” the professor looked down and he followed her gaze. His junior crown prince was standing at attention again.`}</p>
    <p>{`He covered his face and looked away.`}</p>
    <p>{`She laughed and ruffled his hair, “You're adorable.”`}</p>
    <p>{`At the sound of her laughter, the boar inside him had awakened again. He turned them over and pinned her down, he was still embarrassed but he had this desire to dominate her once more.`}</p>
    <p>{`“Can…we…do it again?” He whispered, kissing her nape and trailed down to her beautiful full breasts before looking into her eyes again.`}</p>
    <p>{`She nodded shyly, “Yes, Your Highness.” Dimitri didn't know hearing his professor call him his formal title while naked could make him feel so turned on, he grew bigger in fact.`}</p>
    <p>{`One day, Dimitri was going to have to apologise to the neighbouring rooms and his fellow housemates when it was appropriate. Preferably after they had graduated and were mountains apart as Dimitri had made love to the Professor `}<em parentName="p">{`all night`}</em>{`.`}</p>
    <p>{`Thankfully the professor brought over that basket of food, since apparrently a few rounds of raw, intense, passionate sex took up a lot of stamina and energy.`}</p>
    <p>{`And for the first time in a long, `}<em parentName="p">{`long`}</em>{` time, Dimitri slept soundly and peacefully, with his beloved professor tucked under his arm.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      