import PropTypes from 'prop-types';
import styled, { createGlobalStyle } from 'styled-components';

import atFanfictionChapter from '../../Navbar/atFanficChapter';
import HeartIcon from '../../../assets/icons/heart.svg';
import $ from '../../../styles/globals';

const Container = styled.div`
  display: flex;
  flex-direction: ${({ swap }) => (swap === true ? 'row-reverse' : 'row')};
  align-items: center;
  user-select: none;
  position: relative;
`;

const TotalHearts = styled.div`
  font-size: 15px;
  color: ${$.brown4};
`;

const HeartContainer = styled.div`
  > svg {
    position: relative;
    top: 1px;
    width: 16px;
    height: 16px;
    fill: ${$.color.red1};
    ${({ swap }) =>
      swap === true
        ? `margin-left: ${$.layout.margin1}px;`
        : `margin-right: ${$.layout.margin1}px;`};
  }
`;

const NightMode = createGlobalStyle`
  body.night-mode {
    ${TotalHearts} {
      color: ${$.white1};
    }
  }
`;

const Counter = ({ hearts, swap }) => (
  <Container swap={swap} title={`Total hearts received: ${hearts}`}>
    {atFanfictionChapter() && <NightMode />}
    <HeartContainer swap={swap}>
      <HeartIcon />
    </HeartContainer>
    <TotalHearts>{hearts}</TotalHearts>
  </Container>
);

Counter.defaultProps = {
  hearts: 0,
  swap: false,
};

Counter.propTypes = {
  swap: PropTypes.bool,
  hearts: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Counter;
