import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Lately, all I can think of is food. And I blame it all on the never-ending covid situation back at my place. It's the same ol’ everyday… The cases are slowing down but there are still new cases every day nonetheless. My folks got used to ordering take outs or cooking our own food, but nothing beats eating right there and then in a restaurant. Good food is nice, but the atmosphere is important too!`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Corn potage" caption="I never tried corn potage. Is it good?" name="corn-potage.jpg" mdxType="Image" />
    <p>{`Watching this show reminded me of how much I love to eat. Ahem! Yessss food. I love piping hot food! Cold food is great but only if it’s dessert! Restaurant to Another World Season 2 continues the good old tradition of introducing our isekai residents by their favourite food. A lizardman warrior? Nah, he’s omurice dude. The guy who loves shrimp tempura? Nah, he’s EBI FURAI. And a beautiful elf witch? Her name’s Pudding A la Mode, silly! I cackled so hard when the customers just refer to each other by their favourite food. It’s like those waiters just discussing among themselves to see if I’ll come over for lunch. I used to work full time in a department store and in a bid to save money, I’ll always get the cheap lunch set. The waiters call me the lunch set chicken rice girl xD`}</p>
    <Image alt="Restaurant to Another World S2 Screencap 1" caption="I can't get enough of watching them eat either! The food looks so simple yet these isekai-jin is eating them up real good." name="restaurant-to-another-world-s2-screencap-1.jpg" mdxType="Image" />
    <p>{`I admit, I’m not the most attentive anime blogger among my peers since I’m the type to enjoy the anime vibe rather than to over analyse. You’ll notice that my blog postings are often “This is nice” or “That is super fun”. So it finally hit me that all of these customers are actually from the same fantasy world, just from different continents. That’s… not very otherworldly. I was hoping that the customers are all from different worlds… But I suppose this is how you make these customers seem more connected to each other even outside of the restaurant. The biggest giveaway wasn’t the mention of Eastern or Western continent, but the way the customers do their prayer meals. It’s the same as Aletta’s but they mentioned different gods? If any of those gods came over for a meal, that would be pretty cool!`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Restaurant to Another World S2 Screencap 2" caption="Cheese is pretty expensive at my place. I wish I could slap on that much cheese on my bread!" name="restaurant-to-another-world-s2-screencap-2.jpg" mdxType="Image" />
    <p>{`Episode 1 showed us a cat demon adventurer enjoying cheesecake and that, my friend… Is the most relatable part of the show. Because why stop at 1 cheesecake? Episode 2 is a funny one. Romero? Julietta? Born to be pale? Can’t eat garlic? Goodness, the blatant hints are hilarious. They then proceed to order steak and wine. Author-san, could you be less obvious than this?!There really isn't anything to talk about the show. Watch them eat good food! Honestly, such a wholesome show <3 Restaurant to Another World is one of those shows I am super pleased to recommend to my friends. It’s relaxing to watch and really fun to see the customers eat good food. Can’t wait for the next episode!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      