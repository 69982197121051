import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div`
  ${({ font }) => `
  font-family: ${font} !important;

  em {
    font-style: italic;
    font-family: ${font} !important;
  }
  strong {
    font-weight: bold;
    font-family: ${font} !important;
  }
`}
`;

const CustomFont = ({ children, font }) => (
  <Container font={font}>{children}</Container>
);

CustomFont.defaultProps = {
  children: <></>,
  font: '',
};

CustomFont.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.element,
  ]),
  font: PropTypes.string,
};

export default CustomFont;
