export default ({ slug, title }) => {
  const redirectFanfiction =
    typeof window !== 'undefined'
      ? (() => {
          const isEverythingChangesButYouS1 =
            slug.indexOf('everything-changes-but-you-season-1') > -1;
          const chapterNumber = slug.split('/')[3];
          const obj = {
            slug,
            title,
            url: window.location.origin + window.location.pathname,
          };

          if (isEverythingChangesButYouS1) {
            switch (Number(chapterNumber)) {
              case 11:
                obj.url = window.location.origin + window.location.pathname;
                break;
              default:
                if (chapterNumber === '10-1') {
                  obj.url = `${window.location.origin}/fanfiction/everything-changes-but-you/10/`;
                  obj.title = title
                    .replace(' (Season 1)', '')
                    .replace('10-1', '10');
                  obj.slug = '/fanfiction/everything-changes-but-you/10/';
                } else {
                  obj.url = `${window.location.origin}/fanfiction/everything-changes-but-you/${chapterNumber}/`;
                  obj.title = title.replace(' (Season 1)', '');
                  obj.slug = slug.replace(
                    'everything-changes-but-you-season-1',
                    'everything-changes-but-you'
                  );
                }

                break;
            }
          }

          return obj;
        })()
      : { url: '', slug: '', title: '' };

  return {
    url: typeof window !== 'undefined' ? `${redirectFanfiction.url}` : '',
    identifier: redirectFanfiction.slug,
    title: redirectFanfiction.title,
  };
};
