import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const RemoteLink = makeShortcode("RemoteLink");
const Image = makeShortcode("Image");
const LocalLink = makeShortcode("LocalLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`It was a quiet, rainy night. It’s a given that the temperature will be slightly colder than usual but I sneezed `}<strong parentName="p">{`twice.`}</strong>{` Someone is talking about me, I’m sure of it! Who is it?! Then I saw the tweet by `}<RemoteLink to="https://twitter.com/MagicConan14" mdxType="RemoteLink">{`Aria `}</RemoteLink>{`about this Kore wa Watashi Blog Award. Woo hoo! I got tagged! And whoa, `}<RemoteLink to="https://twitter.com/YuAlexius" mdxType="RemoteLink">{`Yu Alexius`}</RemoteLink>{` tagged me too <3 Awww you guys!`}</p>
    <p>{`I really love answering these tagged award posts but after being tagged so many times, it’s… scaring me. These awards require you to use 110% of your brain juice to answer the questions so I now dodge them like how Kirito dodged bullets in GGO. But this one seems fun!`}</p>
    <Image alt="Kore wa Watashi Blog Award" caption="HAHAHA I’m gonna try guessing the anime characters here. Goku from Dragon Ball is a given. The gloves are from Sailormoon?! Hm, the shorter pants are from One Piece’s Luffy?" name="kore-wa-watashi-blog-award-2020.jpg" mdxType="Image" />
    <h2>{`Rules`}</h2>
    <ul>
      <li parentName="ul">{`The purpose of this tag is very simple! Describe yourself through anime! To do this you will answer all of the questions below!`}</li>
      <li parentName="ul">{`After this is done you will tag 6 people to pass the tag onto! If you’re tagged for a second time you only have to tag 3 in your next post and 1 on your third attempt and should you so choose beyond. `}<strong parentName="li">{`(Nah, I’ll tag only 3.)`}</strong></li>
      <li parentName="ul">{`Tell us why you nominated these people! `}<strong parentName="li">{`(Because they are my blogging buddies, duh.)`}</strong></li>
      <li parentName="ul">{`People who ask to be tagged should be added to your tag list with a link to their blog! Tell something nice about them as well.`}</li>
      <li parentName="ul">{`Remember to have fun with the tag and be proud of who you are, you are an amazing person, and the fact that so many anime and anime characters share traits with you shows you are pretty breathtaking! `}<strong parentName="li">{`(I’m… breathtaking? Says the hotpot as she choked another victim. Ha ha, choked -> breathtaking, geddit?)`}</strong></li>
    </ul>
    <h2>{`Q1: Describe a hobby or passion you have through an anime series! For example Shokugeki no Soma if you like cooking or Initial D if you like cars.`}</h2>
    <p>{`The one thing I really enjoy in mah life is probably having free time to myself. So uh, getting whisked away to another world with cheat skills (minus the obligatory harem) sounds pretty fab to me! I would probably go on an adventure on my own to see the fantasy world. But if you insist that I describe my passion instead, then I’ll go with the Knights & Magic isekai series. It’s about a genius programmer who got reincarnated into a world of mecha and magic. Sounds fab, honestly! I would love to use my programming skills to code up something good.`}</p>
    <Image alt="Knight's and Magic screencap" caption="If I ever get into a fantasy world through reincarnation, please make me a cute girl! Please ye gods!" name="mecha-anime.png" mdxType="Image" />
    <h2>{`Q2: Simplify yourself to an anime archetype (tsundere, dojikko, shota, shounen protagonist etc). Don’t overthink what an archetype is, go with your gut. Name a character that is also that archetype.`}</h2>
    <p>{`I don’t know what type I’m supposed to beeee! This is one tough question… But mari-chan always told me she imagines me to be this emoji. It’s `}<RemoteLink to="https://www.facebook.com/UsagyuuunQuan/" mdxType="RemoteLink">{`Usagyuuun`}</RemoteLink>{`!`}</p>
    <p>{`Usagyuuun is super naughty and is a troublemaker. Or at least, that's my impression of this jittery and bouncy rabbit mascot. I guess I fit into the naughty archetype?! The only character I could think of that fits into this archetype is Kaoru and Hikaru from Ouran High School Host Club.`}</p>
    <h2>{`Q3: Describe your looks through comparing it with anime characters. You can decide how many!`}</h2>
    <p>{`Where do I find an anime hotpot to describe? ):## Q4: Describe your romantic bias and or sexuality through anime characters you find attractive.`}</p>
    <LocalLink to="/blog/waifu-highlight-1-alisa-reinford/" mdxType="LocalLink">No.</LocalLink>
    <h2>{`Q5: Tell us about your day to day life and what anime that would fit into! You can be creative here!`}</h2>
    <p>{`I don’t think my day to day life is anything fancy. I guess my current WFH setup is similar to No Game No Life’s first episode. There’s this PC in my room that I use for work and games. Occasionally, I’ll go out to grab some groceries or food. I really did not want to go out so often unless I needed to because of the current COVID-19 situation. Otherwise I’d be going out more ): My bff and my cousins can't come to visit me at all, I'm sad.`}</p>
    <h2>{`Q6: Describe your hopes and dreams through an anime character.`}</h2>
    <p>{`I just want financial stability. Where can I find a tree that grows money? Other than that, I guess I want a meaningful life where I make it my lifelong dream to reach a goal! I really like Runway de Waratte (Manga version). Seeing both the MC and the male lead pushing so hard to reach their dream feels great.  I think they nailed the whole goal obsession and progress in life. I would love that kind of wholesome goal-pursuing life too!`}</p>
    <Image alt="Runway de Waratte screencap" caption="You can do it! Chase your dreams!" name="runway-de-waratte-screencap.jpg" mdxType="Image" />
    <h2><strong parentName="h2">{`Nominations`}</strong></h2>
    <p>{`Phew, there’s a lot of questions! Maybe reduce it to 5? Here comes the lucky bloggers <3`}</p>
    <ul>
      <li parentName="ul">
        <RemoteLink to="https://twitter.com/YumDeku" mdxType="RemoteLink">Yumdeku</RemoteLink>
      </li>
      <li parentName="ul">
        <RemoteLink to="https://twitter.com/PokemonPinkie" mdxType="RemoteLink">Pinkie</RemoteLink>
      </li>
      <li parentName="ul">
        <RemoteLink to="https://twitter.com/artof_anime" mdxType="RemoteLink">ArtofAnime</RemoteLink>
      </li>
    </ul>
    <p>{`Don't feel obligated to answer! I'm just tagging for fun :D`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      