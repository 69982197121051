import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const RemoteLink = makeShortcode("RemoteLink");
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Tower of God (ToG) anime is finally out and every aniblogger I know were either curious about it after Episode 1 or has a great first impression. I’m really happy to see the reception overall since this is the hottest (rightfully so) and on of my favourite webtoons to grace the `}<RemoteLink to="https://www.webtoons.com/en/fantasy/tower-of-god/list?title_no=95" mdxType="RemoteLink">{`webcomic platform`}</RemoteLink>{`. Let me give a quick summary of what to expect from the anime before talking about Kubera, yet another underrated webtoon.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Tower of God is all about `}<em parentName="p">{`wrestling power`}</em>{` for your own selfish `}<strong parentName="p">{`desire`}</strong>{`. Baam is a young boy trapped underground since he first opened his eyes. He has never seen the light, nor does he have a name. One day, a young girl named Rachel came down from a crack in the ceiling. Baam who only knew darkness since young soon befriends the girl, and they both became fast friends. Rachel would teach Baam everything she knew about the world and give him his current name. Not long after, she soon trusts Baam to tell him his dream - to see a vast and endless sky, and not one limited by a high ceiling. A chance to climb the Tower appeared one day and she did not hesitate to take it. Rachel who meant the whole world to Baam left him alone to pursue her dream. He was devastated but luck has it that he too, opened the door to the Tower.`}</p>
    <Image alt="Princess Yuri Zahard/Jahad from Tower of God webtoon" caption="Princess Yuri rushing to see Baam with Evan in tow." name="tower-of-god-webtoon-yuri.jpg" mdxType="Image" />
    <p>{`The Tower is the `}<em parentName="p">{`only`}</em>{` world that these ToG inhabitants ever knew. Think of it as their sole hometown that the villagers could never escape from. The Tower has no open sky and no air either. `}<em parentName="p">{`Shinsoo`}</em>{` is their air, and life source. The world outside is known by these Tower inhabitants as “a vast, endless space” that has air instead of Shinsoo. Folks from the “Outside” can come into the Tower as `}<em parentName="p">{`irregulars,`}</em>{` but people from the Tower can never leave the tower.`}</p>
    <p>{`The Tower has one unbreakable law - if you wish to go up, then pass the tests by each Floor Administrators. Participants of these tests are called `}<em parentName="p">{`regulars`}</em>{`. The higher you climb, the more power and infamy you get. Keep climbing, and you may soon join the ranks of those who have reached the highest floor possible: Rankers. Everyone who managed to earn the rights to be a `}<em parentName="p">{`regular `}</em>{`has their own desires to fulfil, be it money, power or fame… So these tests are often a bloody affair.`}</p>
    <p>{`We see Baam unknowingly joined the fray as an `}<em parentName="p">{`irregular,`}</em>{` and this will be an important point that drives the plot in Tower of God. Is Baam’s arrival at the Tower been planned all along? The coming tests are really exciting so anime viewers will be in for a treat.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Seeing `}<strong parentName="p">{`Tower of God`}</strong>{` animated so well, coupled with the good reception from the anime viewers makes me hopeful for the next webtoon I truly hope they jump into - `}<RemoteLink to="https://www.webtoons.com/en/fantasy/kubera/list?title_no=83" mdxType="RemoteLink">{`Kubera`}</RemoteLink>{`. I understand that most readers have heard of Tower of God more than Kubera, but believe me when I say this… It’s a great `}<em parentName="p">{`tragedy`}</em>{` fantasy story. Just like ToG, it has a meticulously planned power ranking, races and magic. `}<strong parentName="p">{`Kubera`}</strong>{`’s worldbuilding is deep and probably more complex than Tower of God’s.`}</p>
    <Image alt="Kubera webtoon logo title" caption="Kubera's logo title" name="kubera-webtoon-logo-title.jpg" mdxType="Image" />
    <p>{`Kubera is about a girl who was named after a god. `}<strong parentName="p">{`Leez`}</strong>{` lived all her life in her happy hometown. Her mother is loving, her friends are a good bunch, and there’s always good food on the table. Sure, she never goes to school but that’s a small price to pay for a happy life. On her 16th birthday, she was sent out to pick up mushrooms in a nearby forest. She couldn’t find any mushrooms but instead, met a mysterious man who warned her to run away from her village. Leez rushed back home just to see a huge crater where her hometown once stood`}<em parentName="p">{`.`}</em>{` A red bird-like monster circled around the sky and was about to discover Leez when a magician saved her.`}</p>
    <Image alt="Kubera webtoon main character Leez Haias or Kubera Leez" caption="She lost everything in a blink of an eye. Leez has no way of escaping the cruel fate the universe has in store for her." name="kubera-webtoon-1.jpg" mdxType="Image" />
    <p>{`With no one left, Leez is forced to follow the magician who calls herself Asha. This marks the beginning of Leez journey to find the real meaning behind her name. There’s a bigger story behind the scenes that involved all of the universe’s playing powers - the gods, the suras and the weak beings caught in-between, humans.`}</p>
    <Ads mdxType="Ads" />
    <p>{`The gods as their title implies, are the creators of the universe and all things in it. The suras, on the other hand, are powerful beings rivalling the gods in power. These beings are ageless and as such, are always at odds with each other `}<em parentName="p">{`just because they can`}</em>{`. The humans are the weakest amongst the three and thus, they need to live in cities with barriers to protect them. They have an afterlife administered by the gods and they can borrow power from the gods as `}<em parentName="p">{`magic.`}</em>{` Despite all three races being so different from one another, it is not uncommon to see human and sura couples, or friendship between sura and gods.`}</p>
    <Image alt="Agni and Brilith, pivotal side characters in Kubera webtoon" caption="From left, Agni and his summoner, Brilith. To enter the human realm, magicians must summon the god with his/her own life as an anchor. These two have deeper ties than the usual god and his summoner relationship." name="kubera-webtoon-agni-brilith.jpg" mdxType="Image" />
    <p>{`I call this a tragedy fantasy story because every `}<strong parentName="p">{`damned character`}</strong>{` introduced in the story has a sob story played by fate. Heck, even the gods are being played by the higher gods. The world-building done by the author goes so much deeper than what I explained in the prior paragraph! The names of the gods, as well as their jurisdiction, is based on Hindu mythology. You have Agni, the god of fire and Chandra, the god of darkness and many more. The suras too have names from Hindu myths - Ananta, Gandharva and so forth. These names represent `}<strong parentName="p">{`power`}</strong>{`, and it is the leading factor that pulls Leez into the ongoing tragedy plaguing the universe.`}</p>
    <p>{`Kubera as a webtoon does not have the nicest art out there, nor does it prioritize simplifying the dialogues to make the plot easier to grasp. The author, `}<strong parentName="p">{`Currygom`}</strong>{` specifically mentioned that she wanted to make it a novel first, but changed her mind. The dialogue can be confusing and wordy but every damn line these characters utter or do are meaningful. Hints are littered `}<em parentName="p">{`everywhere `}</em>{`so I often find myself re-reading older chapters for a refresher. The `}<em parentName="p">{`mystery`}</em>{` set up from S1 has just started to unravel in S3. Each chapter just introduces new characters, newer `}<em parentName="p">{`tragedies`}</em>{` and even more secrets.`}</p>
    <Ads mdxType="Ads" />
    <p>{`When I say `}<em parentName="p">{`tragedy`}</em>{`, I mean “played by gods and fate” tragedy. Our protagonist, Leez, has her hometown razed by a sura. This event is `}<em parentName="p">{`intentional.`}</em>{` Due to the shock, Leez finds herself relying heavily on Asha. Asha knew Leez is relying on her for emotional support, but she went ahead and `}<em parentName="p">{`abused`}</em>{` Leez. Leez kept convincing herself that Asha is her saviour so she overlooked every `}<em parentName="p">{`misdeed`}</em>{` Asha did to her. Leez even defended Asha when she is in a trial. We readers thought Asha is meant to be a`}<em parentName="p">{` hateful character`}</em>{` until Currygom showed Asha’s backstory… Seriously, Currygom sadistic streak spared no one.`}</p>
    <Image alt="Leez Haias and Asha Rahiro from Kubera webtoon" caption="Leez trying to talk to Asha before the trial. Here, Asha admitted to using Leez's broken emotional state to her own benefit. Leez already knew, but hearing it from Asha's own mouth breaks her heart." name="kubera-asha-webtoon-2.jpg" mdxType="Image" />
    <p>{`Gandharva, a sura we’ll be seeing often in the story named is yet another victim to Currygom's whims. He is constantly looking for his daughter wherever he is. Everyone knew his daughter is a confirmed kill but he fooled himself into believing she is still alive. Honestly, this caused a lot of unnecessary drama but once the full flashback is shown, I have nothing but pity for this character. There are honestly no baddies in this story, just victims.`}</p>
    <Image alt="Menaka and Gandharva from Kubera webtoon" caption="From left, Menaka and Gandharva. Menaka is Gandharva's wife. She is trying to convince Gandharva to change his cruel ways." name="kubera-webtoon-gandharva.jpg" mdxType="Image" />
    <p>{`Kubera has a ton of characters just waiting for their backstory to be fully explained linked to the `}<em parentName="p">{`play`}</em>{` set up by gods. What factions are there in this so-called war? Are the players on the `}<em parentName="p">{`chessboard`}</em>{` even acting on their will? This series is such a great read all in all. I hope the Crunchyroll folks will look into creating a Kubera anime. The tragedies are heartbreaking but it's so engrossing to read how the plot threads come together in the end.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      