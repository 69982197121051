import { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import $ from '../../../styles/globals';
import TinyImageCard from '../../TinyImageCard';
import TagTitle from './TagTitle';
import Horizontal from '../../Ads/Horizontal';

const Section = styled.section``;

const Container = styled.div``;

const TagSection = styled.div`
  margin-top: ${$.layout.margin4}px;
  margin-bottom: 40px;
`;

const PostsContainer = styled.div`
  ${$.screen.desktop} {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
    grid-gap: 25px;
  }

  ${$.screen.tabletA} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    grid-gap: 20px;
  }

  ${$.screen.tabletB} {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    grid-gap: 25px;
  }

  ${$.screen.mobile} {
    > * {
      margin-bottom: ${$.layout.margin2}px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

const Posts = ({ className, data }) => (
  <Section className={className}>
    <Container>
      {Object.keys(data)
        .sort()
        .map((tag) => {
          const totalPosts = 4;
          const blogPosts = data[tag].slice(0, totalPosts);

          return (
            <Fragment key={`${tag}_section`}>
              <TagSection>
                <TagTitle tag={tag} />
                <PostsContainer>
                  {blogPosts.map(
                    ({
                      key,
                      excerpt,
                      title: blogPostTitle,
                      image,
                      slug,
                      timeToRead,
                      published,
                    }) => (
                      <TinyImageCard
                        key={key}
                        img={image}
                        timeToRead={timeToRead}
                        title={blogPostTitle}
                        excerpt={excerpt}
                        slug={slug}
                        date={published}
                      />
                    )
                  )}
                </PostsContainer>
              </TagSection>
              {['blog', 'games'].indexOf(tag) > -1 && <Horizontal />}
            </Fragment>
          );
        })}
    </Container>
  </Section>
);

Posts.defaultProps = {
  className: '',
  data: {},
};

Posts.propTypes = {
  className: PropTypes.string,
  data: PropTypes.objectOf(PropTypes.array),
};

export default Posts;
