import PropTypes from 'prop-types';
import styled, { createGlobalStyle } from 'styled-components';

import $ from '../../../../styles/globals';
import BreadcrumbsList from './BreadcrumbsList';
import Stats from './Stats';
import Divider from '../Shortcodes/Divider';

const Section = styled.section`
  margin: ${$.layout.margin8}px 0;

  ${$.screen.desktop} {
    width: 700px !important;
    padding-left: calc((100vw - 700px) / 2) !important;
    padding-right: calc((100vw - 700px) / 2) !important;
  }

  ${$.screen.tablet} {
    width: 700px !important;
    padding-left: calc((100vw - 700px) / 2) !important;
    padding-right: calc((100vw - 700px) / 2) !important;
  }
`;

const Container = styled.div`
  padding: ${$.layout.padding4}px 0;
`;

const ChapterNumber = styled.h1`
  font-size: 28px !important;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${$.brown5};
  text-align: center;
  margin-bottom: ${$.layout.margin10}px;
`;

const Summary = styled.h2`
  font-size: 16px !important;
  line-height: 1.3em;
  color: ${$.brown4};
  font-family: Rubik Regular !important;
  margin-bottom: ${$.layout.margin4}px;

  a {
    text-decoration: none;
    color: ${$.color.orange};
    font-family: Rubik Regular;
    font-weight: bold;
    &:visit {
      color: ${$.color.orange};
    }
  }

  img {
    width: 100%;
  }

  p {
    margin-bottom: ${$.layout.margin3}px;
  }

  em {
    font-family: Rubik Regular;
    font-style: italic;
  }
`;

const StyledDivider = styled(Divider)`
  margin-top: 50px;
  margin-bottom: 70px;
`;

const ChapterSubtitle = styled.div`
  font-size: 18px !important;
  text-transform: uppercase;
  color: ${$.brown5};
  letter-spacing: 1px;
  font-family: Rubik Regular;
  font-weight: bold;
  text-align: center;
  margin-bottom: ${$.layout.margin10}px;
`;

const NightMode = createGlobalStyle`
  body.night-mode {
    ${ChapterNumber}, ${ChapterSubtitle}, ${Summary} {
      color: ${$.white1};
    }
  }
`;

const Banner = ({
  published,
  tags,
  summary,
  author,
  hearts,
  pageViews,
  title,
  index,
  slug,
}) => (
  <>
    <BreadcrumbsList tags={tags} />
    <Section>
      <NightMode />
      <Container>
        <ChapterNumber>{title}</ChapterNumber>
        <Summary dangerouslySetInnerHTML={{ __html: summary }} />
        <Stats
          pageViews={pageViews}
          title={title}
          published={published}
          author={author}
          hearts={hearts}
          index={index}
          slug={slug}
        />
      </Container>
    </Section>
    <section>
      <StyledDivider />
    </section>
  </>
);

Banner.defaultProps = {
  summary: '',
  author: { name: '', img: '' },
  hearts: '-',
  pageViews: 0,
};

Banner.propTypes = {
  published: PropTypes.string.isRequired,
  summary: PropTypes.string,
  slug: PropTypes.string.isRequired,
  index: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  pageViews: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hearts: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  author: PropTypes.objectOf(PropTypes.string),
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Banner;
