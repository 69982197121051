import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const RemoteLink = makeShortcode("RemoteLink");
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I love this controller so much <3 I usually use the products I wish to review for a few months because I want to be sure of my experience before writing about it. But this controller is ah-mah-zing! I have been using it everyday to play PC games for a month now. `}<RemoteLink to="https://www.8bitdo.com/sn30-pro-plus/" mdxType="RemoteLink">{`8Bitdo SN30 Pro+`}</RemoteLink>{` works on most platforms with a ton of flexibility which I’ll be talking about later on in this review.`}</p>
    <Ads mdxType="Ads" />
    <p>{`I’ve been hearing about 8Bitdo ever since`}<strong parentName="p">{` Nintendo Switch`}</strong>{` was first released years ago. Their controllers are affordable compared to Nintendo Switch Pro Controller, and they do a ton of bluetooth adapters. I have read a ton of reviews about how good their bluetooth adapters are, and how 8Bitdo is their go-to option for an affordable controller that works. All in all, the quality is superb for the price I’m paying.`}</p>
    <p><strong parentName="p">{`Update Note: 8bitdo released a new controller! I'm really happy with my current SN30 Pro+ but if you fancy something new, please take a look at `}<RemoteLink to="https://www.8bitdo.com/pro2/" mdxType="RemoteLink">{`8BitDo Pro 2`}</RemoteLink>{`.`}</strong></p>
    <Image alt="8Bitdo SN30 Pro+ Review Image 1" caption="I thought they'll pack the controller in a hard plastic (Like how Logitech F310 packaging is) so a big box with the controller in it is a nice surprise." name="8bitdo-sn30-pro-plus-review-packaging.jpg" mdxType="Image" />
    <h2>{`The experience`}</h2>
    <p>{`If you’re an avid console player, I’m sure you have held a Dualshock (PS4), an XBox controller or a Nintendo Switch Pro Controller, just to give an example. We define`}<em parentName="p">{` “comfort”`}</em>{` of holding these in our hands with these aspects:`}</p>
    <ol>
      <li parentName="ol">{`How does it feel to grip the controller for `}<em parentName="li">{`long hours`}</em>{`? Does it fit nicely in our hands? Does it force us to strain our fingers because it doesn’t fit our hand size?`}</li>
      <li parentName="ol"><em parentName="li">{`How hard is it to feel and push a button down`}</em>{`? Does it click immediately, or requires more pressure? Does it give nice tactile feedback? How big are the buttons so it doesn’t feel too small to tap on?`}</li>
      <li parentName="ol"><em parentName="li">{`How smooth is it when you push the joysticks`}</em>{`? Is it hard to push it to a certain direction? Is the height of the joystick too tall or too low to comfortably push it? Due to the different hand sizes and/or joystick cap surfaces, it’s easy for your thumb to slide off which then forces you to reposition your thumb again.`}</li>
      <li parentName="ol">{`The D-pad should be a directional pad that makes it easy to `}<em parentName="li">{`press two directions together`}</em>{`. E.g. up and left together. This is why many didn’t like the D-pad on Joy-Cons since it is tough to do that.`}</li>
      <li parentName="ol">{`How snappy is it to tap on the shoulder buttons? Does it `}<em parentName="li">{`feel tight or loose to press those down`}</em>{`?`}</li>
    </ol>
    <Ads mdxType="Ads" />
    <p>{`It’s minor nitpicking, but all of these come together to form an opinion of said controller. This is why folks love the Xbox controller. It’s the complete package in terms of comfort. I personally really love `}<strong parentName="p">{`Nintendo Switch Pro Controller `}</strong>{`because it feels great in my hands too. I understand that as an avid player, you’ll be sitting on your couch/chair to play games for hours. You’ll want to forget that you are holding a controller. Moving the joystick or clicking buttons should feel as natural as using gestures on an iPad.`}</p>
    <Image alt="8Bitdo SN30 Pro+ Review Image 2" caption="I grabbed this image from their official website, just to give you an idea how it fits snuggly in your hand. This controller has 3 different colours! Each colour is a super nice choice." name="8bitdo-sn30-pro-plus-cover.jpg" mdxType="Image" />
    <p>{`Well, there’s absolutely nothing to worry about when it comes to using `}<strong parentName="p">{`8Bitdo SN30 Pro+`}</strong>{`. ABXY buttons are big enough to accommodate your thumbs. There's nice tactile feedback if you press the buttons gently, no extra pressure needed. I wager even from a long term use, the buttons’ feedback feeling will remain the same. Another nice detail I noticed is that the X and Y buttons are concave while A and B do not. Plus, they actually printed the labels outside of the buttons so we don’t have to worry about the labels fading off from long term use.`}</p>
    <p>{`The joysticks’ height is a tad lower than I expected it to be. My hands are average-sized (To give you an idea, I’m a girl with average sized hands) and even so, I noticed that the joystick’s height is a tad lower than expected but still higher than a Joy-Cons’. After hours of using the controller, the height is actually `}<em parentName="p">{`really comfortable`}</em>{`. My thumbs never slide off the joystick! It’s easy to push the joystick too. A slight nudge and the controller quickly responds with minor changes to my character’s position. Yay, I’ve been using Logitech F310 for my Genshin Impact gaming session, and the joystick is stupidly bad. 8Bitdo’s 360 degree joystick is a wonderful upgrade.`}</p>
    <Ads mdxType="Ads" />
    <p>{`And the D-pad? Works as expected, just like how I remember the good old Nintendo consoles to be. I love the Game Boy Advance’s D-pad! It could feel a bit tight to press down, but the tactile feedback is just nice. I think once you have used it for some time, the D-pad should feel right at home after it loosens up.The shoulder buttons are much, much looser than I expect them to be. I’m used to the Joy-Cons super tight shoulder buttons. After using it a ton on Genshin Impact, I find myself preferring 8Bitdo SN30 Pro+’s loose shoulder buttons. I think a loose one is better to tap on!`}</p>
    <h2>{`The Bluetooth connectivity`}</h2>
    <p>{`According to the website, it works on Mac, Windows, Nintendo Switch, Android, Steam and Raspberry Pi. Granted, I have not tried connecting this 8Bitdo controller to a Mac or Android… I have only tried it on my Nintendo Switch, Windows PC and iOS. Each Start button can be pressed with a X, Y, A or B button for different controller modes. E.g. to use X-input, hold Start button with the X button. I managed to connect to my iPhone and the controller was recognized as a Dualshock controller.`}</p>
    <p>{`I managed to connect to my Windows PC as a Nintendo Switch Pro Controller too! I tried to use the Pro Controller setting for Genshin Impact, but it didn’t work. Shame, because this setting has gyro. I wanted to use the gyro to do Aimed Shot attacks. Only X-input worked for my PC games. Connect to your PC by holding the Start button with the X button for 3s until the LED lights stop blinking. It connected so fast and I was on my way to playing Genshin Impact/Tales of Berseria afterwards. I love it when the tech just works! Connecting it back to PC after turning it off is a cinch too. Turn it back on, wait for a few seconds and the controller will do a soft rumble to tell you it’s connected to your PC.`}</p>
    <Ads mdxType="Ads" />
    <p>{`I tried connecting to my Nintendo Switch afterwards but it wasn’t connecting automatically despite the initial pairing. It turns out that it remembers the `}<strong parentName="p">{`last connection `}</strong>{`before it was turned off, so I need to hold the Start button with the Y button for it to connect to my Nintendo Switch again if `}<em parentName="p">{`I have connected to my PC prior to this`}</em>{`. If you always play your Nintendo Switch or PC, then turning it on will connect it immediately. Otherwise, you’ll need to connect it by holding the Start button combo again.`}</p>
    <p>{`FYI, I did the usual process when trying to connect the controller to my Nintendo Switch. I go to Controllers -`}{`>`}{` Change Grip/Order on my Nintendo Switch home menu for the first time pairing. Hold Start + Y until the LED lights steadies, and your Nintendo Switch should recognize 8Bitdo as a Pro Controller. I tried waking the Nintendo Switch up with my 8Bitdo but the moment the Switch’s screen turns off, the connection dies too. So I had to make sure my Nintendo Switch was turned on before I could use my 8Bitdo controller.`}</p>
    <h2>{`The battery`}</h2>
    <p>{`I don’t have much to comment on the battery because if there’s not much charge left on it, I just plug in the USB-C cable that comes with the controller and continue playing while it’s charging. It mentioned that a 4-hour charge gives you a solid 20 hours of gameplay. I believe that’s more than enough leeway to get it charged in the meantime!`}</p>
    <Ads mdxType="Ads" />
    <Image alt="8Bitdo SN30 Pro+ Review Image 3" caption="The battery pack is removable. I saw 2 slots for 2 AA batteries. Options are always nice!" name="8bitdo-sn30-pro-plus-review-battery-removable.jpg" mdxType="Image" />
    <p>{`Another thing I would like to mention is that the controller’s battery pack is removable. I love that they gave us an option because one day, the controller’s battery would spoil and all you need to do is A) get a new battery pack or B) get 2 AA batteries and be on your way! I love gadgets that give us users choices!`}</p>
    <h2>{`Extra features`}</h2>
    <p>{`This controller comes with a customizable turbo function for your grinding needs. Apparently you could even customize the button mapping, the shoulder button’s and the joysticks’ sensitivities as well as the rumble intensity. And you can do macros? I admit, I never tried these options but having these features are better than none at all.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`All in all`}</h2>
    <p>{`This… This controller is better than a Pro Controller. I’m so happy I got it for my PC gaming and Animal Crossing sessions. The rumbling is not as good as a Pro Controller but it’s good enough. Connecting it to the console is seamless and it feels good in my hands. I have zero complaints for it given that I got it at a great price. If you’re on the fence for this controller, don’t. Get a 8Bitdo SN30 Pro+ and stop browsing for a better controller. THIS. IS. IT.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      