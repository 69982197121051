import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Image = makeShortcode("Image");
const RemoteLink = makeShortcode("RemoteLink");
const Ads = makeShortcode("Ads");
const Video = makeShortcode("Video");
const Blockquote = makeShortcode("Blockquote");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I wanted to write a blog post on otome isekai webtoons, but I realize the good romance lately are all... just plain romance. Goodbye, otome isekai! Hello, korean webtoons based off novels. There are one or two which I am following the novel because it's... just so much better.`}</p>
    <p>{`Ehe, allow me to whisk you away to a world of great romance plot (Filtered by yours truly!) <3 I have many order blog posts about romance webtoons, do give them a try too!`}</p>
    <p>{`The list below is not ordered because I love them all equally.`}</p>
    <h2>{`Recommended Romance Webtoons #1: Sound of Bread`}</h2>
    <p>{`I think this is an original story by the author. Their first story too, if I am not mistaken! Great work miqin!`}</p>
    <Image name="sound-of-bread-banner.jpg" alt="Sound of Bread banner" caption="From left, Kimmie and Kale." mdxType="Image" />
    <p>{`Set in our world, we follow Kimmie, a bright girl who's aiming to become a world renowned conductor in Paris. She comes from a family of lawyers, so her choice of career is met with plenty of objection from her family. But Kimmie is adamant about her career choice! This would be a normal romance story (where Kimmie met a guy and they fall in love) but Kimmie is special, you see... She's a `}<RemoteLink to="https://en.wikipedia.org/wiki/Synesthesia" mdxType="RemoteLink">{`synesthete`}</RemoteLink>{`! Kimmie can hear music from eating food.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Most food she eats has bad music. Sometimes she ate food with good music, and all is well for that day. One fine day, she ate bread that has the best music she's ever heard! It turns out it was baked by a baker apprentice named Kale. He's rude and says whatever he wants. Kimmie hates him, but you can't deny his bread tastes (and sounds) amazing... You know the drill `}<em parentName="p">{`wink`}</em></p>
    <p>{`Read the series `}<RemoteLink to="https://tapas.io/series/sound-of-bread/info" mdxType="RemoteLink">{`here`}</RemoteLink>{`!`}</p>
    <h2>{`Recommended Romance Webtoons #2: Knight in Flight`}</h2>
    <p>{`I love strong woman. I love it even more when both her attitude and work screams independent woman <3 This series `}<em parentName="p">{`IS`}</em>{` romance, but the story pans out the "royal castle" politics more. The art might be off-putting for some people but I can guarantee the story is wonderful if this is up your alley.`}</p>
    <Image name="knight-in-flight.jpg" alt="Knight in Flight art" caption="From left, Prince Theonel and Amadionte." mdxType="Image" />
    <p>{`Our protagonist is Amadionte Elvin, a female knight in service to her kingdom. Due to her gender, she wasn't respected by her colleagues. But no matter, Amadionte is used to their jeering. Things took a wild turn when she was assigned to be Prince Theonel's personal bodyguard. This assignment confuses Amadionte, who is a daughter to a noble family belonging to a neutral faction. Amadionte never agreed with her father's opinions in politics nor with his views on her career. Regardless, she knew she had to tiptoe carefully around this assignment. After all, she can't be seen being goody with the prince. Otherwise, the princess who's aiming for the throne might not take too kindly with her assumed `}<em parentName="p">{`allegiance`}</em>{`!`}</p>
    <p>{`Surely just maintaining her knightly vows is enough for this prince, no? Alas, the more Amadionte interact with the prince... The more she sees who he really is. Perhaps this prince is more than what the eye can see? How will Amadionte help the prince break free from this struggle for the throne?`}</p>
    <p>{`It goes without saying the romance is between Amadionte and the prince <3 I enjoy stories that show politics in the royal castle. They may be talking nicely to each other, but behind their backs they scheme real hard! There's basically two political factions aiming to place their candidates on the throne; the princess backed by most nobles and the prince backed by his uncle.`}</p>
    <Ads mdxType="Ads" />
    <p>{`The prince is super meek in appearance and behaviour, but that is only a ploy to divert attention away from him. Unfortunately this made his uncle assumed that he is a weakly prince. Thus, all efforts to push the prince to be king is being spearheaded by the uncle.`}</p>
    <p>{`The princess herself leads her own faction. Oh boy, this is one ambitious princess. She wants the throne for herself simply because she thinks it is her birthright. Even folks supporting her are equally ambitious. This makes the whole `}<em parentName="p">{`race`}</em>{` so interesting because here you have a princess being deterred only by her gender, while the prince (who's being supported by his uncle because he is male and he doesn't think the princess deserves the throne) doesn't want to be the king. How will the prince weasel out of this?`}</p>
    <p>{`Then you have Amadionte. Both a lady yet so knightly in strength! Honestly, I love the protag. I'm always captivated by Amadionte whenever she gets screen time <3 Despite coming from a noble family hated by many, she embodies chivalrous qualities along with an adorable feminine hobby. She loves lace and anything sweet! But her height and wide shoulders make it hard to fit into demure dresses. It's a cute quirk that I think many folks could relate to it.`}</p>
    <p>{`I have absolutely no clue how the story will go. Romance is slow but it is secondary. Enjoy the plot! I honestly love the writing.`}</p>
    <h2>{`Recommended Romance Webtoons #3: When the Flowers Bloom`}</h2>
    <p>{`This is Korean Romeo x Juliet. Seriously. I kid you not. The whole meeting between the couple is like setting things up for disaster. But all the readers go aww, they should `}<strong parentName="p">{`still`}</strong>{` get together.`}</p>
    <Image name="when-the-flowers-bloom.jpeg" caption="This is one underrated romance webtoon. I'm enamored... For real." alt="When The Flowers Bloom" mdxType="Image" />
    <p>{`In the year 1556, a father received a horrifying prophecy of his newborn son, Eungtae. While his son will grow up strong to protect his homeland, he will ultimately meet his early death. That is, if his son could not avoid the poison of the `}<RemoteLink to="https://hgtvhome.sndimg.com/content/dam/images/hgtv/stock/2018/2/9/shutterstock_253463395-Siriwat-Chamnanyoch_trumpet-vine.jpg.rend.hgtvcom.616.411.suffix/1518214711091.jpeg" mdxType="RemoteLink">{`trumpet vine`}</RemoteLink>{`. It was a strange prophecy, but one that the father does not wish for its fulfillment. So he cut down every trumpet vine in the vicinity of his home and lovingly raised his son. Thus did Eungtae grew up strong and well. He knew he is loved, but he grew frustrated with how much his father controls his life.`}</p>
    <Ads mdxType="Ads" />
    <p>{`So whenever Eungtae gets to go riding, he made sure to ride as far as he could. It was then that he met Yeon, a beautiful young lady surrounded by trumpet vines. Hoho, are you thinking what I'm thinking? Is this the foretold tragedy that will end his life early? Eungtae fell in love at first sight with Yeon, and would often ride out to talk to her. Yeon too, start to enjoy his company. Soon, she starts to spill the beans on her tragic past.`}</p>
    <p>{`Just what is this trumpet vine all about? Is there a higher power at work here, since Eungtae `}<strong parentName="p">{`still`}</strong>{` came across trumpet vines no matter how his father made him avoid it?`}</p>
    <p>{`I'm just going to drop this song right here because it's giving me the same vibes as this webtoon. Do try the anime this song is from. It's cheesy but in a good way.`}</p>
    <Video src="https://www.youtube.com/embed/diOXQ6Zvesk" title="Romeo x Juliet" mdxType="Video" />
    <h2>{`Recommended Romance Webtoons #4: As You Like It, Margrave`}</h2>
    <p>{`Oh, I like this a lot. At least, for the first part of the story. I binged it in one go! There's some implied naughty scenes but nothing visually obvious. But enough about that, I'm here for romance!`}</p>
    <Image name="as-you-like-it-margrave.jpg" caption="The art is ephemeral. Enjoy the art!" alt="As You Like It, Margrave" mdxType="Image" />
    <p>{`Set in a fantasy world with fairies and magic, our protagonist has nary the magical affinity (apparently). But that didn't stop her from being the feisty maid, Dian! Dian works for lovely Lady Selene who saved her from being starved to death. She adores her lady a lot, but unfortunately her lady is about to be married off to an old noble. Lady Selene didn't want the marriage to happen, but she has no choice in this matter. Dian didn't like the sound of that, so she offered to be her doppelganger to buy Lady Selene enough time to flee.`}</p>
    <p>{`Dian thought going straight to the groom's castle is a suicidal mission, so she planned to visit Margrave Evenhart unannounced to buy time instead. Margrave Evenhart strangely did not reject her visit (Dian is disguising as Lady Selene when she visited Evenhart). Instead, he allowed her to stay for a few days. Will these few days invoke a long-forgotten memory once sealed?`}</p>
    <p>{`Yeaaaa babeh, I love stories that involve some sort of "fated" shenanigans. I won't spoil, but a fair warning; the flashback is really, really long, but it's worth it. Enjoy the ride!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Recommended Romance Webtoons #5: Becoming the Villain's Family`}</h2>
    <p>{`Sometimes you get great stories but with so-so art. I personally don't mind the art as long as the story is fantastic. Then you have wonderful stories with equally fantastic art! I notice many comments saying the art is lovely, but I disagree.`}</p>
    <p>{`I think the art is unique in a way that reminds me of early 2000s manhwa art. Everyone back then has this pronounced eyelashes and strands of hair. Plus their limbs are super sharp. If you have read `}<RemoteLink to="https://en.wikipedia.org/wiki/Bride_of_the_Water_God" mdxType="RemoteLink">{`Bride of the Water God`}</RemoteLink>{`, then you'll get what I mean xD`}</p>
    <Image name="becoming-the-villains-family.jpg" caption="PINK! Pink hair protagonist? Count me in! I found this cover and I thought it's lovely. The art in the webtoon is different though." alt="Becoming The Villain's Family" mdxType="Image" />
    <p>{`Our protagonist is Aria, a lovely young lady whose mother is a Siren. Sirens are fantastical creatures that are able to bewitch their listeners with their songs. Aria's mother was captured by Count Cortez and was made to sing for her kidnappers. She's afraid that her daughter will soon meet the same fate, so she made Aria drink a potion that will make her mute. When her mother died, Aria stop drinking the potion and thus, was able to speak again.`}</p>
    <p>{`This quickly spiral into a tragedy for Aria. Enslaved and locked in a cage, she is doomed to sing forever for nobles. That is, until she is saved by the Grand Duke Valentine. But it was then and there that she lost her life. The next time she opened her eyes, she is back to being a 10-year-old again. With her memories intact, she staged her escape from the controls of her father and plotted to meet the family of her now younger saviour. Will the Valentines accept her?`}</p>
    <p>{`It's a bit slow, but I can see the story having good potential. You should hop on and try the webtoon. Maybe you'll like the art and story!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Recommended Romance Webtoons #6: Another Typical Fantasy Romance`}</h2>
    <p>{`It has come to my attention that anything with fluff is a good romance. Now here's a romance that has nothing but fluff! I dub this story, `}<em parentName="p">{`"Top 100 Things to say and do for your S.O."`}</em>{`.`}</p>
    <Image name="another-typical-fantasy-romance.jpg" caption="This is that one time the male lead is a gruffy bear-looking guy with scars all over his face. That's hot! Where do I find more male leads like him? <3" alt="Another Typical Fantasy Romance" mdxType="Image" />
    <p>{`Lithera was once one of us... An avid fangirl who's really happy she was dropped into a favourite fantasy romance novel. Everything was going swimmingly since Lithera was dropped as the female lead. However, one thing lead to another and she was unfortunately murdered by her jealous husband. Oops. The gods were so apologetic that this happened to her, so they grant her a second chance to live as Lithera again. With knowledge of the tragic future, Lithera is adamant on `}<strong parentName="p">{`not`}</strong>{` going anywhere near her first life's route. Therefore, she aims to be the wife of her bff penfriend, Grand Duke Pellus. Yup, yup... She basically requested a contract marriage like all romance stories out there.`}</p>
    <p>{`But we all know how contract marriage goes in these stories <3`}</p>
    <p>{`Pellus is one big guy with scars as his makeup. I swear, if I ever meet such a guy in real life, I will back off by instinct... But the funny thing is, this guy is one big softie for Lithera! It's absolutely adorable! If you enjoy fluff moments, this is for you.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Recommended Romance Webtoons #7: How to get my husband on my side`}</h2>
    <p>{`Aite, this story hurts my kokoro. By a lot. I caught up with the webtoon and went on to try the novel. The novel is way better, do give it a try! Fair warning, this story has self-harm, mentions of abuse and anorexia.`}</p>
    <Image name="how-to-get-my-husband-on-my-side.jpg" caption="Used the novel cover because the novel is great!" alt="How to get my husband on my side" mdxType="Image" />
    <p>{`Before she is Rudbeckia (Ruby), a villainess from a novel, she is an adopted daughter of a super rich family in Spain. Thanks to that, she studied in a prestigious school and learned ballet, riding and even tennis. Due to her being adopted at a young age, she doesn't understand why she is being bullied for having Asian features. Her adopted family, while rich and free, is a bit cuckoo in their heads. She eventually ingrain habits to be a good daughter as to not offend her adoptive family. She brought along this habit of hers when she died and became Rudbeckia, Princess of Romagna.`}</p>
    <p>{`The story starts with her being wedded off to Izek Omerta, a famous paladin. Ruby was smiling on the outside when she received the news, but deep inside she knew she's in deep trouble. After all, according to the story of the novel, Ruby the villainess will be slain by her husband. Ruby didn't want to die, so she tries her very best to cajole to her husband. In the process, however, she may have ticked off other folks...`}</p>
    <p>{`I remember reading the whole novel in one go and cried whenever scenes of Ruby struggling with her anorexia + self-harm thoughts came up. Initially, you the reader have no clue what is going on with her thoughts. Why is Ruby, the protagonist saying the total opposite of what she is thinking? Why are her thoughts so childish? Since the novel/webtoon is being written from her perspective, you'll notice how warped her thoughts are. At first, I thought nothing of it until it finally reached a point where I wonder if I am interpreting the whole scene correctly!`}</p>
    <Ads mdxType="Ads" />
    <p>{`But it all made sense once you consider her depressive mental state. Ruby was abused in her previous life in Spain and now, as "Ruby". She's doing the best in this life, so she doesn't have to experience it all over again. But alas, some things don't change. Her suicidal thoughts really hurt to read not because it's suicidal, but because no one could understand and help her. Due to this, you could tell she put up a barrier to protect herself. And the author just had to make it worse by having folks bully her because they detest her family. Man, what does her family got to do with her? Poor girl, she can't catch a break.`}</p>
    <p>{`Thankfully, we have Izek in this story. The webtoon skipped out a lot but in the novel, Izek noticed signs of abuse from Ruby and was being really, really careful in how he treats her. If you take that stance while reading the novel, then his actions made a lot of sense. Even in the novel, he's a man of few words so you gotta read carefully in-between to understand Izek's thoughts.`}</p>
    <p>{`Oops, I went off on a tangent just describing the novel when I'm supposed to be talking about the webtoon. The art is great, but they skipped out a lot that makes the story good. Heck, just read the novel. Trust me, it's good. Prepare tissue!`}</p>
    <h2>{`Recommended Romance Webtoons #8: Under the Oak Tree`}</h2>
    <p>{`I think everyone has heard of this novel/webtoon already. I wish to include this title here regardless because I wish to talk about it. It's good. Way better than I expected in unexpected ways.`}</p>
    <Image name="under-the-oak-tree.jpg" caption="I think the raws are still ongoing. That's pretty awesome." alt="Under the Oak Tree" mdxType="Image" />
    <p>{`Under the Oak Tree is about two lovebirds braving the odds to stay together... Except the one doing the most growth is our sweet heroine, Maximillian Croyso (Max). Our girl belongs to a powerful ducal family. By right, she should be treated like a princess back at home as the youngest sister in the family. However... Due to her incurable stuttering, she was always treated as if she is the shame of the family.`}</p>
    <p>{`The story opens up with Max wedded off to Riftan, a powerful knight in his own right. Quickly after the couple had their first night, Riftan left for a 3-year campaign. Now that it is over, he came back to get Max who is still living in her father's home. The rest of the story then takes place in Anatol where Max can truly breathe and spread her wings.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Max is a girl with low self-confidence which we can all blame on her nasty, abusive father. He would beat her up and get priests to heal her once he is done. All of this `}<em parentName="p">{`because`}</em>{` she stutters, and he deems her a useless daughter. It gets me mad to see her being abused >:( The romance really picks up once Riftan made her stay in Anatol as the Lady of Calypse. Riftan treats Max like a princess! Making sure all of her needs are met and... lots of skinship.`}</p>
    <Blockquote mdxType="Blockquote">
  Uh-huh, yeaaaaa "skinship", I'll leave that to you to find out, dear readers.
    </Blockquote>
    <p>{`While the "skinship" is nice, I think the writing is pretty fab. There's a consistent world-building going on here, especially when it comes to magic and politics. The story can be boiled down to Max trying to be the best version of herself and Riftan, a big bear who is all over Max. The story relies on momentum to move the plot forward... The author clearly wants to create tons of achievement for Max but at times, it feels a bit on the nose. Nevertheless, the romance is great. Ah~ The things you do for love <3 It's an enjoyable story that pulls you along. Do give the novel a try! Webtoon has really nice art (Apparently this is the artist's first webtoon!) and oomph, `}<strong parentName="p">{`abs`}</strong>{`... Ahem! Anyway, enjoy the story!`}</p>
    <h2>{`All in all`}</h2>
    <p>{`I hope you enjoyed my romance webtoon recommendation! Most of these are available officially. If you don't know where to look, maybe you should summon yarr inner pirate :D`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      