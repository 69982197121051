import PropTypes from 'prop-types';
import styled from 'styled-components';

import $ from '../../../styles/globals';
import { roundUp } from '../../../utils';
import SocialTool from '../../MDXBlogpost/SocialTool';
import { getStatusColor } from '../helpers';
import Twitter from '../../SocialFollow/Twitter';
import Horizontal from '../../Ads/Horizontal';
import Divider from '../Chapter/Shortcodes/Divider';

const Container = styled.div`
  margin-bottom: ${$.layout.margin5}px;
`;

const Summary = styled.div`
  line-height: 1.4em;
  font-family: 17px;
  color: ${$.brown5};
  font-family: Rubik Regular;
  margin: ${$.layout.margin4}px 0;

  strong {
    font-family: Rubik Regular;
    font-weight: bold;
  }

  p {
    margin-bottom: ${$.layout.margin2}px;
  }

  a {
    font-family: Rubik Regular;
    font-weight: bold;
    text-decoration: none;
    color: ${$.color.orange};
    &:visit {
      color: ${$.color.orange};
    }
  }
`;

const TotalChapters = styled.div`
  color: ${$.color.green1};
  font-size: 30px;
  font-family: Rubik Regular;
  font-weight: bold;
  letter-spacing: -1px;
  text-transform: uppercase;
  margin-right: ${$.layout.margin3}px;

  ${$.screen.mobile} {
    font-size: 25px;
  }
`;

const Stats = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${$.layout.margin4}px;
`;

const TotalWords = styled.div`
  color: ${$.color.red2};
  font-size: 30px;
  font-family: Rubik Regular;
  font-weight: bold;
  letter-spacing: -1px;
  text-transform: uppercase;

  ${$.screen.mobile} {
    font-size: 25px;
  }
`;

const StyledSocialTool = styled(SocialTool)`
  margin-top: 40px;
`;

const Status = styled.div`
  font-size: 20px;
  letter-spacing: 1px;
  font-family: Rubik Regular;
  font-weight: bold;
  text-transform: uppercase;
  display: inline-flex;
  padding: 10px;
  border-radius: 10px;
  background-color: ${({ status }) => getStatusColor(status)};
  margin: ${$.layout.margin3}px 0;
  color: ${$.color.white};

  ${$.screen.mobile} {
    font-size: 18px;
  }
`;

const OtherStats = styled.div`
  font-size: 16px;
  letter-spacing: 1px;
  font-family: Rubik Regular;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: ${$.layout.margin2}px;
  color: ${$.color.lightblack};
`;

const Info = ({ chapters, summary, genres, pairings, rating, status }) => {
  let totalWords = 0;

  chapters.forEach(({ wordCount }) => {
    totalWords += wordCount;
  });

  return (
    <Container>
      <Divider />
      <Status status={status}>{status}</Status>
      <Stats>
        <TotalChapters>
          {`${chapters.length} ${
            chapters.length <= 1 ? 'chapter' : 'chapters'
          }`}
        </TotalChapters>
        <TotalWords>{`${roundUp(totalWords)} words`}</TotalWords>
      </Stats>
      <OtherStats>{`Genre: ${genres.join(', ')}`}</OtherStats>
      <OtherStats>{`Pairings: ${pairings.join(', ')}`}</OtherStats>
      <OtherStats>{`Rating: ${rating}`}</OtherStats>
      <Summary dangerouslySetInnerHTML={{ __html: summary }} />
      <Twitter nabe={false} />
      <Horizontal />
      <StyledSocialTool link="/fanfiction/" text="Back to selection" />
    </Container>
  );
};

Info.defaultProps = {
  pairings: [],
};

Info.propTypes = {
  chapters: PropTypes.arrayOf(PropTypes.object).isRequired,
  summary: PropTypes.string.isRequired,
  genres: PropTypes.arrayOf(PropTypes.string).isRequired,
  pairings: PropTypes.arrayOf(PropTypes.string),
  rating: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};

export default Info;
