import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const RemoteLink = makeShortcode("RemoteLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`A quick google tells me that a ton of other sites has already made a Pascal quote compilation post. No doubt they have the whole set and probably done a better job than this hobbyist blogger here! But alas, I’m not here to talk about Pascal’s wise quotes! We’re here to have some fun poking fun with the silly ones.`}</p>
    <Ads mdxType="Ads" />
    <p>{`I only managed to get Pascal a few times (Curse you RNG!) because Nintendo hasn’t been nice to me. I either don’t get any scallops at all or I don’t get any scallops at all, there’s no in-between. So far, I managed to get a ton of pearls (ARGH!), a mermaid dress (Huh?) and a measly 3 mermaid DIYS (YARR!) from the accursed beaver… He’s a beaver, right?`}</p>
    <p>{`Look, you look adorable munching the scallop but could you please at least give me more DIYs? Pretty please? ): Anyway, quotes! I skipped out on a few because I forgot to mash that screenshot button but here are my favourites.`}</p>
    <p><em parentName="p">{`Note: I wrote this for fun because the quotes made me cackle.`}</em></p>
    <h2>{`1. All you time travelers beware`}</h2>
    <p>{`This is Nintendo making a jab at us, right? They knew we were making boat loads of cash by time travelling here and there! Quiet Pascal! It IS all about the Bells! How else am I going to boast to my friends I have 30 million Bells in my bank account and yet I haven’t cleared my house loan?!`}</p>
    <Image alt="Pascal Quote 1" caption="Why is weeding the first in the list? Of course Bells are important. I need to sweep the entire store to make Timmy and Tommy happy. Buying their wares gives them commission!" name="animal-crossing-new-horizons-pascal-quotes-1.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`2. Coconuts are doing fine, thank you very much`}</h2>
    <p>{`Remember guys, `}<RemoteLink to="https://www.washingtonpost.com/news/wonk/wp/2017/06/15/seven-percent-of-americans-think-chocolate-milk-comes-from-brown-cows-and-thats-not-even-the-scary-part/" mdxType="RemoteLink">{`chocolate milk doesn't come from brown cows`}</RemoteLink>{`. I was a bit confused reading this because you don’t get coconut milk immediately if you crack a coconut open… You get coconut water. What on earth is Pascal on about?`}</p>
    <Image alt="Pascal Quote 2" caption="I love how he goes maaan. Pascal my maaan, is it okay to decorate the whole island with pumpkin starts?" name="animal-crossing-new-horizons-pascal-quotes-2.jpg" mdxType="Image" />
    <h2>{`3. I made this joke once. My family didn’t like it.`}</h2>
    <p>{`Because I’m clearly not a cute beaver from Animal Crossing. I guess you need to look cute for the joke to work. I tried `}<RemoteLink to="https://i.pinimg.com/originals/8f/0b/ba/8f0bba31a0c2a3ed3b8d2bc663f91fea.jpg" mdxType="RemoteLink">{`googling `}<em parentName="p">{`traffic jelly`}</em></RemoteLink>{` and it turns out that the joke isn’t as creative as you think. Everyone thought of it already ):`}</p>
    <Image alt="Pascal Quote 3" caption="What is with folks obsession with PBJ? Is it that good?" name="animal-crossing-new-horizons-pascal-quotes-3.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`4. Do you mean the Spiderman movies?`}</h2>
    <p>{`To be fair, Pascal had a point. Maaan, what wouldn’t I do for an extra serving of carbonara! … Wait. Scallops go well with pasta! Carbonara with scallops sounds delicious. Oh, this quote is genius.`}</p>
    <Image alt="Pascal Quote 4" caption="What movies do you think Animal Crossing villagers watch? Gasp! What if the whole island is actually the set for The Truman Show?" name="animal-crossing-new-horizons-pascal-quotes-5.jpg" mdxType="Image" />
    <h2>{`5. That’s stealing, Pascal`}</h2>
    <p>{`I rather pay for the shoes, at least the poor guy gets something out of me borrowing his shoes for a mile. After all, I worked hard to have 30 million Bells. Let me waste the cash, darnit.`}</p>
    <Image alt="Pascal Quote 5" caption="Nuh uh, I ain’t doing that. That’s only 2000 walking steps!" name="animal-crossing-new-horizons-pascal-quotes-6.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`6. C.C. from Code Geass would love that`}</h2>
    <p>{`I have no idea where Pascal got that statistics. I mean, who wishes for world peace? I wish for more money daily. World pizza honestly sounds delicious to be fair! The correct way(?) to eat pizza is with your hands. Please don’t kill me when I say I eat pizza with a knife and fork.`}</p>
    <Image alt="Pascal Quote 6" caption="I tried googling for pizzas with scallops on it and I got many hits! Please go take a look, the recipes look delish." name="animal-crossing-new-horizons-pascal-quotes-7.jpg" mdxType="Image" />
    <h2>{`7. There are two ways you can interpret this confession`}</h2>
    <p>{`The honest and pure way of reading this too deep is that Pascal wants to be a swimmer. The other way of reading this is that he wants to be `}<RemoteLink to="https://external-preview.redd.it/JwHD-VUuRv-mNUvQ0LsqMiyXy32ZQfpHPAOsgfRS2zw.png?auto=webp&s=293a5dd1655e8d31cd1fe9aff021a70cc3135332" mdxType="RemoteLink">{`this guy.`}</RemoteLink></p>
    <Image alt="Pascal Quote 7" caption="Thankfully he didn’t say he never wears pants. Hm wait, none of the villagers/NPC in this game wear pants." name="animal-crossing-new-horizons-pascal-quotes.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <h2>{`All in all`}</h2>
    <p>{`Pascal is a mad lad and I am sorry for this low effort blog post. I had way too much fun cackling when Pascal appeared with his wise quotes. Maaan, he does lay them down deep.`}</p>
    <Image alt="Pascal Quote 8" caption="Me playing 400 hours on Animal Crossing New Horizons is not a waste of time! I feel validated! Thank you Pascal!" name="animal-crossing-new-horizons-pascal-quotes-4.jpg" mdxType="Image" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      