import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../styles/globals';

const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
`;

const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + span {
    background-color: ${({ falseColor }) => falseColor};
  }

  &:checked + span:before {
    transform: translateX(100%);
  }
`;

const Slider = styled.span`
  border-radius: 99px;
  position: absolute;
  outline: none;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ trueColor }) => trueColor};
  transition: 0.4s;

  &:before {
    position: absolute;
    content: '';
    height: calc(100% - 8px);
    width: calc(50% - 4px);
    left: 4px;
    bottom: 4px;
    background-color: ${({ tabColor }) => tabColor};
    transition: 0.4s;
    border-radius: 99px;
  }
`;

const Toggle = ({
  className,
  trueColor,
  falseColor,
  tabColor,
  onClick,
  checked,
}) => (
  <Switch className={className}>
    <Checkbox
      checked={checked}
      falseColor={falseColor}
      onChange={(e) => {
        onClick(e.target.checked);
      }}
    />
    <Slider tabColor={tabColor} trueColor={trueColor} />
  </Switch>
);

Toggle.defaultProps = {
  tabColor: $.white1,
  className: '',
  trueColor: $.blue1,
  falseColor: $.grey1,
  onClick: () => {},
  checked: false,
};

Toggle.propTypes = {
  checked: PropTypes.bool,
  onClick: PropTypes.func,
  tabColor: PropTypes.string,
  className: PropTypes.string,
  trueColor: PropTypes.string,
  falseColor: PropTypes.string,
};

export default Toggle;
