import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const RemoteLink = makeShortcode("RemoteLink");
const Ads = makeShortcode("Ads");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I was trying to set up the open-source blogging platform, `}<RemoteLink to="https://github.com/TryGhost/Ghost" mdxType="RemoteLink">{`Ghost as a headless CMS`}</RemoteLink>{` for work since paying for an account on `}<RemoteLink to="https://ghost.org/" mdxType="RemoteLink">{`Ghost.org`}</RemoteLink>{` isn’t cutting it. We wanted to invite more contributors and add more staff users to help us write, and the pricing plans are cutting too deep into our wallets for something we aren’t using a lot. I noticed that the guides for setting up `}<RemoteLink to="https://docs.bitnami.com/aws/apps/ghost/" mdxType="RemoteLink">{`Ghost Bitnami`}</RemoteLink>{`'s other features aren’t properly documented or they require some extra digging. So I wrote this with hopes that this post would have answered your questions. Here are the things that I’ll be covering in this blog post:`}</p>
    <ol>
      <li parentName="ol">{`Installing Ghost Bitnami on an EC2 instance.`}</li>
      <li parentName="ol">{`Setting up the DNS records in Route53 in an existing zone to point to your EC2 instance.`}</li>
      <li parentName="ol">{`Setup auto renewal for Let’s Encrypt cert using Bitnami’s tool.`}</li>
      <li parentName="ol">{`How to set up AWS S3 bucket to host our Ghost images.`}</li>
      <li parentName="ol">{`Configure outbound SMTP using a Gmail account.`}</li>
    </ol>
    <Ads mdxType="Ads" />
    <h2>{`Installing Ghost Bitnami on an EC2 instance`}</h2>
    <p>{`This is an easy step but I didn’t want to skip it. This step is here for clarity. Visit your AWS Console and click on `}<strong parentName="p">{`EC2`}</strong>{`. Click on `}<em parentName="p">{`Launch Instance`}</em>{` to go to the AMI selection screen. Click on `}<em parentName="p">{`AWS Marketplace`}</em>{` option and start typing `}<em parentName="p">{`Ghost,`}</em>{` and this option should appear.`}</p>
    <Image alt="Here's Ghost Bitnami option in AWS AMI Marketplace!" caption="Here's Ghost Bitnami option in AWS AMI Marketplace!" name="ghost-bitnami-on-aws-marketplace.jpg" mdxType="Image" />
    <p>{`Pick this option and pick `}<strong parentName="p">{`t2.nano`}</strong>{` if you’re using solely for Headless CMS. Feel free to pick anything else as per your own user requirements, but remember that you need a minimum of 10GB storage for this AMI. Make sure to save your `}<em parentName="p">{`.pem`}</em>{` key too! You’ll need it to SSH into your EC2 instance.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Once it’s up and running, try visiting your blog’s public address. The Ghost blog should appear. You can visit your Ghost’s admin panel at `}<em parentName="p">{`http://{your public address}/ghost/.`}</em>{` To login to the admin panel, Ghost Bitnami has set up an admin account. In order to view the `}<RemoteLink to="https://docs.bitnami.com/aws/faq/get-started/find-credentials/" mdxType="RemoteLink">{`username and password`}</RemoteLink>{`, you will need to click on your `}<em parentName="p">{`EC2 Instance`}</em>{` > `}<em parentName="p">{`Actions`}</em>{` > `}<em parentName="p">{`Instance Settings`}</em>{` > `}<em parentName="p">{`Get system log`}</em>{`. If you can’t find it, try `}<em parentName="p">{`Actions`}</em>{` > `}<em parentName="p">{`Monitor and troubleshoot`}</em>{` > `}<em parentName="p">{`Get system log`}</em>{`. You should be able to see the username and password here. Use that to login into your Ghost blog admin panel! If you are seeing a blank log, that means it is still booting up. Be patient! Now that the Ghost blog is ready, we are ready to put this blog behind a proper website address!`}</p>
    <h2>{`Setting up Route53 to point to Ghost’s EC2 Instance`}</h2>
    <p>{`Go to your AWS Console, and visit Route53. Choose a hosted zone that you have and click `}<em parentName="p">{`Create Record.`}</em>{` Pick IP address or another value, depending on the record type and enter your blog’s EC2 Instance IP address in it. Make sure to pick the dropdown values in the image below!`}</p>
    <Image alt="Route53 settings for Ghost Bitnami" caption="Please make sure to follow these dropdown values. Feel free to add the IP address and record name of your own." name="ghost-bitnami-blog-on-route53.jpg" mdxType="Image" />
    <p>{`Once Route53 is done setting up, try visiting the new blog’s address. You might encounter a problem where it says it couldn’t find the site or even if it could, it keeps redirecting back to the EC2 instance’s IP address. We need to set up the `}<RemoteLink to="https://docs.bitnami.com/aws/apps/ghost/administration/generate-configure-certificate-letsencrypt/" mdxType="RemoteLink">{`auto renewal Let’s Encrypt cert tool`}</RemoteLink>{` to fix this. From your own terminal, SSH into the EC2 instance. Run this command below to initiate the SSH connection:`}</p>
    <pre>{`ssh -i {your pem key}.pem bitnami@{your ec2’s public IPv4 DNS}`}</pre>
    <p>{`Once you’re in, run this command to start the tool called bn-cert:`}</p>
    <pre>{`sudo /opt/bitnami/bncert-tool`}</pre>
    <p>{`Follow the instructions, but make sure to add your blog’s address (E.g. blog.example.com) when the option came up! Once it’s done, try visiting your blog through `}<strong parentName="p">{`https`}</strong>{` and it should be working! Now you have a working blog running on an EC2 Instance :)`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Make Ghost host the images on an AWS S3 bucket instead`}</h2>
    <p>{`By default, Ghost Bitnami for AWS Cloud Stack stores the images in `}<em parentName="p">{`home/bitnami/apps/ghost/htdocs/content/images`}</em>{`. I’m using`}<strong parentName="p">{` t2.nano`}</strong>{` so I really shouldn’t be relying on it to host our images reliably. Besides, it will be troublesome to back up the images if the EC2 Instance went kaput. To start uploading images directly to S3 and still have it show up nicely on Ghost, we will use the plugin called `}<strong parentName="p">{`ghost-storage-adapter-s3`}</strong>{`. You can find the `}<RemoteLink to="https://github.com/colinmeinke/ghost-storage-adapter-s3/blob/master/README.md" mdxType="RemoteLink">{`plugin here`}</RemoteLink>{`.`}</p>
    <p>{`It’s a standard process when it comes to attaching the necessary policies for the S3 bucket. But if you need help, please refer to the plugin’s readme! Here’s how to install it after creating the S3 bucket:`}</p>
    <p>{`SSH into your EC2 Instance, and run `}<strong parentName="p">{`npm install ghost-storage-adapter-s3`}</strong>{` inside a new folder you created under `}<em parentName="p">{`home/bitnami/apps/ghost/htdocs/`}</em>{`. This is because we do not want the `}<em parentName="p">{`node_modules`}</em>{` folder to interfere with Ghost’s `}<em parentName="p">{`node_modules`}</em>{` folder under `}<em parentName="p">{`/version`}</em>{`. It's strange... but I find this the best approach to prevent hiccups.`}</p>
    <p>{`Next, make a new folder in `}<em parentName="p">{`/content/adapters/storage`}</em>{` in order to move the contents of the recently installed S3 plugin into it. We do this by running the command:`}</p>
    <pre>{`cp -r {your folder}/node_modules/ghost-storage-adapter-s3  ./content/adapters/storage/s3`}</pre>
    <Ads mdxType="Ads" />
    <p>{`Once the plugin is moved, please `}<em parentName="p">{`cd`}</em>{` into the `}<em parentName="p">{`/content/adapters/storage/s3`}</em>{` folder and run `}<em parentName="p">{`npm install`}</em>{` to install the plugin’s dependencies of the plugin you just moved. The adapter is now ready. But we need to set up the `}<strong parentName="p">{`config.production.json`}</strong>{` file to have the S3 adapter’s settings. Update the JSON with the following details:`}</p>
    <pre>
  {`  //...
  "storage": {
    "active":"s3",
    "s3": {
      "accessKeyId": "your access key id",
      "secretAccessKey": "your access key secret",
      "region": "the region of your bucket",
      "bucket": "name of the bucket",
      "acl": "public-read"
    }
  }
`}
    </pre>
    <p>{`Finalize it by running `}<em parentName="p">{`ghost restart`}</em>{` to restart Ghost. Voila! Any new images being added into blog posts should now be properly uploaded to S3. If you wonder why there are two images for every single image being uploaded, that’s how Ghost rolls apparently. I didn’t continue on setting up the images to use CloudFront but you can find it on the plugin’s readme!`}</p>
    <p>{`Phew. We’re almost there. Now that the blog’s address is pointing correctly, the SSL is being renewed automatically and the images are properly uploaded to S3… We need to set up the outbound email because it’s important for sending forgotten passwords and inviting new members!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Configuring Outbound SMTP with Gmail`}</h2>
    <p>{`The trick here is to use `}<RemoteLink to="https://support.google.com/accounts/answer/185833?hl=en" mdxType="RemoteLink">{`App Passwords`}</RemoteLink>{`. In order to use this feature, you need to enable 2FA on your account. If you’re using a personal Gmail, app passwords will work right away. If you’re using a GSuite email, then you will need to go to your `}<strong parentName="p">{`Google Admin Console`}</strong>{`. Click on `}<em parentName="p">{`Security`}</em>{` > `}<em parentName="p">{`Basic Settings`}</em>{` > `}<em parentName="p">{`Less Secure apps`}</em>{` > `}<em parentName="p">{`Go to settings for less secure apps`}</em>{`. Select on `}<strong parentName="p">{`Allow users to manage less secure apps`}</strong>{`, and save! As long as this option is disabled, any created App Passwords on an email under the GSuite plan won’t work.`}</p>
    <p>{`Here’s how you update your Ghost config to include the outbound SMTP settings. Create an App Password and SSH into your EC2 Instance. Look for `}<strong parentName="p">{`config.production.json`}</strong>{` under `}<em parentName="p">{`home/bitnami/apps/ghost/htdocs`}</em>{` and add this section in.`}</p>
    <pre>
  {`  ...
  "mail": {
    "from": "'Ghost Blog' <noreply@example.com>",
    "transport": "SMTP",
    "options": {
      "host": "smtp.gmail.com",
      "service": "Gmail",
      "port": 465,
      "secure": true,
      "auth": {
        "user": "add your gmail account here",
        "pass": "add your app password here"
      }
    }
  },
  ...
`}
    </pre>
    <p>{`Save the file and reboot the servers by running these commands:`}</p>
    <pre>{`sudo /opt/bitnami/ctlscript.sh restart ghost
sudo /opt/bitnami/ctlscript.sh restart apache
`}</pre>
    <p>{`Your Ghost blog can now send emails. You should try it out by sending an invite to yourself!`}</p>
    <h2>{`How to update and backup your Ghost blog`}</h2>
    <p>{`SSH in and run `}<em parentName="p">{`ghost update`}</em>{`. That’s it. Let’s say something went terribly wrong and you need to backup your Ghost blog’s content. You can find the option to do so in your `}<em parentName="p">{`Ghost Admin Panel`}</em>{` > `}<em parentName="p">{`Labs`}</em>{` > `}<em parentName="p">{`Export your content`}</em>{`. However, it will only contain the blog posts, users and settings. No images will be included in. Weird… Thankfully, we have all our images in our S3 bucket ;) Make sure to download Redirects and Routes settings too because the `}<strong parentName="p">{`Export your content`}</strong>{` option didn’t include those.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Extra Tip: Use Cyberduck`}</h2>
    <p>{`Do you hate using the terminal to browse folders and files in an EC2 instance? Then use `}<RemoteLink to="https://cyberduck.io/" mdxType="RemoteLink">{`Cyberduck`}</RemoteLink>{`! I use it to browse EC2 Instances and S3 buckets. It makes downloading folders and uploading a cinch.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      