import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const RemoteLink = makeShortcode("RemoteLink");
const Image = makeShortcode("Image");
const Ads = makeShortcode("Ads");
const Blockquote = makeShortcode("Blockquote");
const LocalLink = makeShortcode("LocalLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I once asked on `}<RemoteLink to="https://twitter.com/geeknabe/status/1213437421871779840" mdxType="RemoteLink">{`Twitter`}</RemoteLink>{` if anyone had any questions they want to get answered from my perspective and experience as a web developer, and I received a few questions! The question was asked in early January, and I apologize it took so long to write an answer post!This post answers @SecludedObserve’s question on SEO.`}</p>
    <Image alt="Mallow asks about SEO in this Tweet reply screenshot." caption="Long long time ago, the golden SEO question was asked." name="twitter-seo-question.jpg" mdxType="Image" />
    <Ads mdxType="Ads" />
    <p>{`Ok, so let’s talk business. Why are you in this blog-writing business? You could say it’s for fun, or to make a decent part-time moolah. Regardless of `}<strong parentName="p">{`what `}</strong>{`you are writing blog posts for, you need `}<em parentName="p">{`readers.`}</em></p>
    <p>{`So where do these readers come from, and how would you attract even more readers to your blog? Most folks rely on social platforms but these take time and effort. You need to post often and interact with your viewers. It’ll work, but to see just one or two clicks is disheartening. Some even opt to buy ads to bring in the viewers. Ads `}<em parentName="p">{`do`}</em>{` work, but it can cost a ton in the long run and isn’t worth it unless you plan to keep at it. The easiest and surest way to keep readers coming in is… Search Engine Optimization (SEO).`}</p>
    <h2>{`So what exactly is SEO?`}</h2>
    <p>{`SEO is basically getting on to search engines’ good books so it’ll index your blog `}<em parentName="p">{`properly `}</em>{`and `}<em parentName="p">{`favourably`}</em>{`. When we say SEO, we’re always referring to Google! All in all, here’s why you `}<strong parentName="p">{`need Google`}</strong>{` - most folks haven’t got a clue about your blog.`}</p>
    <p>{`Most googlers would just search for a question and somehow arrive at your blog post, and this is what we want the most. All we need to do is just sit back and relax... And somehow, you got interested readers coming your way! Isn’t that the most ideal situation? Folks who googles to get their questions answered are your best readers. Here’s why:`}</p>
    <ol>
      <li parentName="ol">{`If they googled for a term and they spotted your blog post, they are `}<strong parentName="li">{`more likely to stay and read your post`}</strong>{`. If other content in your blog fits their fancy, they might even `}<em parentName="li">{`follow you`}</em>{`! They are already interested in the first place since they are googling about it, so this is more likely to happen.`}</li>
      <li parentName="ol">{`Everyone googles on a daily basis. Have you checked the number of searches for the blog post you are trying to write? This guarantees `}<strong parentName="li">{`someone discovering your blog post`}</strong>{` even more! However, despite the specific keyword receiving a ton of searches a month, it doesn’t guarantee more viewership for your blog. As to why this is so and how to win the race, `}<em parentName="li">{`this topic alone deserves another blog post.`}</em></li>
    </ol>
    <p>{`You might think that the readership will just come rolling in with a well-written post, but that’s a `}<em parentName="p">{`dangerous`}</em>{` stance to take. You need to proactively work on it because not only are you a blogger, you are also the `}<strong parentName="p">{`webmaster`}</strong>{` of your blog. It’s inevitable that you’ll need to step up your role and dig deep into SEO because your readership counter hinges solely on Google’s good grace. Posting on social media `}<strong parentName="p">{`does `}</strong>{`have a wider reach, but it’s only the interested readers that will click your links. Pinterest is fair game, but it takes way too much work to do the graphics and schedule the pin. The ideal blogging process should only have these three steps - write, post and promote (a little) your new blog post.`}</p>
    <Ads mdxType="Ads" />
    <p>{`If we want to play the SEO game, we should play a game of minimizing your weaknesses and maximizing your strengths. It’s not enough that you’re listed in Google’s search results! Any Tom and Harry can do that. No, we are aiming for the best spot we can get - the very first spot on the first page.`}</p>
    <p>{`Even if you can’t get the first spot, we should at the very least try to aim for the first five spots. It’s imperative that we do this because any other spots won’t yield the ROI (return on investment) we are looking for. I’m going to quote a recent `}<RemoteLink to="https://www.zerolimitweb.com/organic-vs-ppc-2019-ctr-results-best-practices/" mdxType="RemoteLink">{`blog post (2019)`}</RemoteLink>{` on this.`}</p>
    <Blockquote mdxType="Blockquote">
  On the first page alone, the first five organic results account for 67.60% of
  all the clicks and the results from 6 to 10 account for only 3.73%.
    </Blockquote>
    <p><em parentName="p">{`Note: I originally wrote this post by dumping a ton of info in it, and I went way overboard. So I decided to chop it up and further refine the parts as their individual blog post. I’ll slowly release these blog posts over time once I’m confident with it.`}</em></p>
    <Ads mdxType="Ads" />
    <h2>{`Telling Google to index your blog post for specific keywords`}</h2>
    <p>{`What drives Google searches are `}<strong parentName="p">{`keywords`}</strong>{`. Folks googling for things are sure to be looking for something. They enter the term “`}<em parentName="p">{`How to...`}</em>{`” and voila, all posts that `}<strong parentName="p">{`contain`}</strong>{` the keyword “How to...” pops up. Great, your goal is clear now. You should write blog posts that have the keywords “How to...”! Easy. … Or is it? Remember, you’re not the only bait in the wide, open sea. Everyone’s trying their best to earn the top spot in Google search results `}<strong parentName="p">{`every day`}</strong>{`. Chances are they are better than you at what they do. To fight with these top contenders is suicide and not worth the effort. The art of looking for the right keywords is a field by itself and there’s a ton of blog posts that knew what they are talking about, so I’ll let them do the talking. Generally speaking, you should be looking for blog posts explaining about `}<em parentName="p">{`long-tail keywords`}</em>{`. It’s your best ticket to guaranteed traffic as bloggers.`}</p>
    <p>{`Many of these blog posts out there explained about keyword researching but almost zilch posts talk about how to register these keywords in your blog post. Let me list out everything that I know of.`}</p>
    <Ads mdxType="Ads" />
    <h3>{`Make sure to update your meta tags`}</h3>
    <p>{`Have you ever wonder how do social media platforms know what image to get or the description to display when you post a link on their site? They actually don’t know, so you got to tell them. Every time you post a link, these social media platforms send a bot over to scrap your page for info. If you hint them properly, they’ll get the correct details. Otherwise, they’ll just pick the first image they found as the link’s thumbnail, followed by the first 150 or so (?) characters as the description. Obviously this is not what you want, so you need to tell the bots what to scrap from your page.`}</p>
    <p>{`You do this by adding meta tags in your `}<inlineCode parentName="p">{`<head>`}</inlineCode>{` tag of your blog post. These are the`}<strong parentName="p">{` basic fields`}</strong>{` you should have on every page in your blog.`}</p>
    <ol>
      <li parentName="ol"><inlineCode parentName="li">{`<title>{your blog post title}</title>`}</inlineCode></li>
      <li parentName="ol"><inlineCode parentName="li">{`<meta name="description" content="{write your content here}" />`}</inlineCode></li>
      <li parentName="ol"><inlineCode parentName="li">{`<meta property="og:title" content="{your title here}"/>`}</inlineCode></li>
      <li parentName="ol"><inlineCode parentName="li">{`<meta property="og:description" content="{your content here}" />`}</inlineCode></li>
      <li parentName="ol"><inlineCode parentName="li">{`<meta property="twitter:description" content="{your content here}" />`}</inlineCode></li>
      <li parentName="ol"><inlineCode parentName="li">{`<meta property="og:image" content="{your image link here}" />`}</inlineCode></li>
      <li parentName="ol"><inlineCode parentName="li">{`<meta property="twitter:image" content="{your image link here}" />`}</inlineCode></li>
      <li parentName="ol"><inlineCode parentName="li">{`<meta property="og:url" content="{your blog post url here}" />`}</inlineCode></li>
    </ol>
    <p>{`Now you might be wondering, why are there seemingly repeated meta fields? That’s because those starting with "og:" are used by social media bot (e.g. Facebook, Twitter), "twitter:" are for twitter bot and the basic meta tags are for search engine bot. You can learn more about "og:" tags from Open Graph and "twitter:" tags from`}<RemoteLink to="https://developer.twitter.com/en/docs/tweets/optimize-with-cards/overview/markup" mdxType="RemoteLink">{` Twitter’s Developer page`}</RemoteLink>{`. If you have all three types of meta field tags covered, your blog post will look good wherever it is posted.`}</p>
    <Ads mdxType="Ads" />
    <p>{`While "og:" tags work fine for Twitter bots, I included "twitter:" tags as well because Twitter has this nifty feature called Twitter cards. If you feed the bots meta tags with property names starting with "twitter:", they’ll prioritize displaying your link as a card as opposed to a normal link with an image. It looks way better and probably attract more attention too. You can learn more about`}<RemoteLink to="https://developer.twitter.com/en/docs/tweets/optimize-with-cards/overview/abouts-cards" mdxType="RemoteLink">{` Twitter cards`}</RemoteLink>{` here.`}</p>
    <Image alt="Twitter card example" caption="Here's a Twitter card in action. The image is large, the title and description of the blog post is shown nicely." name="twitter-card-example-geeknabe-seo-blog-post.jpg" mdxType="Image" />
    <Image alt="Link with no information shared on Twitter" caption="This is a link without any Twitter card popping up. Even an image is not showing! This is not useful for readers at all." name="shared-link-without-twitter-card.jpg" mdxType="Image" />
    <p>{`Here’s a quick explanation of what each meta tags inform the bots.`}</p>
    <ol>
      <li parentName="ol">{`Title - Your blog post must have a title, right?`}</li>
      <li parentName="ol">{`Description - Explain what your blog post is all about. Basically, a summary.`}</li>
      <li parentName="ol">{`Image - The image you wish to display when someone pasted your link in social media platforms / on search engines.`}</li>
      <li parentName="ol">{`Url - Canonical link for the current page. Basically tells the bot this is the original link everyone’s been sharing/talking about.`}</li>
    </ol>
    <p>{`If you’re not sure what your blog post will look like when you share your link, you can check them out on Facebook’s `}<RemoteLink to="https://developers.facebook.com/tools/debug" mdxType="RemoteLink">{`Sharing Debugger`}</RemoteLink>{` and Twitter’s `}<RemoteLink to="https://cards-dev.twitter.com/validator" mdxType="RemoteLink">{`Card Validator`}</RemoteLink>{`. One good thing about Facebook’s Sharing Debugger is that you can use it to request Facebook bots to scrap your link again, effectively making it refresh the link’s cached view on Facebook.`}</p>
    <Ads mdxType="Ads" />
    <Image alt="Facebook Sharing Debugger scrapped link example" caption="An example of Facebook Sharing Debugger in action. You can click on Scrape Again to pull newer meta tags from your link." name="facebook-sharing-debugger-example-geeknabe-blog-post.jpg" mdxType="Image" />
    <Image alt="Twitter Card Validator scrapped link example" caption="Twitter Card Validator at work. This one just displays the card. You can't request Twitter to scrape your link again." name="twitter-card-validator-geeknabe-seo-blog-post.jpg" mdxType="Image" />
    <p>{`These tags may seem irrelevant to the SEO topic at hand but it’s as important as the content of your blog. I’ll explain why.`}</p>
    <h3>{`Do not overlook title and description`}</h3>
    <p>{`You see, Google spares no effort in scrapping your blog post. If you were to google for a blog post now, you are more likely to see the `}<strong parentName="p">{`bolded`}</strong>{` keywords in a meta description and title! That means it’s a hit to your search term.`}</p>
    <Image alt="Example of bolded keywords in Google search results" caption="Bolded ones are keywords that fits your Google search term." name="google-bolded-search-result-seo-keyword.jpg" mdxType="Image" />
    <p>{`So pay attention to what keywords and how you’re writing your title and description. Try not to write too long, or Google will truncate your title and description. As of 2019, the suggested length for the title is `}<em parentName="p">{`70 characters`}</em>{`, while the description is`}<em parentName="p">{` 222 characters long`}</em>{`. You can find the exact numbers `}<RemoteLink to="https://seopressor.com/blog/google-title-meta-descriptions-length/" mdxType="RemoteLink">{`here`}</RemoteLink>{`.`}</p>
    <Ads mdxType="Ads" />
    <h3>{`Images are being scrapped by Google too`}</h3>
    <p>{`Google looks at your images too. If you included a meta tag for an image, there’s a good chance Google will show it right next to your link in its search result on mobile.`}</p>
    <Image alt="Example of images shown next to Google search result on mobile" caption="How images are shown next to the Google search result on mobile" name="google-search-results-mobile-seo-keywords.jpg" mdxType="Image" />
    <p>{`With an image, it’s more appealing for the potential readers to click on it. You can use any type of image types (.JPG, .PNG) except for `}<strong parentName="p">{`.SVG`}</strong>{` ones, and Google will be able to display your image. I’m going to assume you’ll most probably be using the same image for both (og:image and twitter:image) meta tags, so make sure to use an image sized below 5MB, or Twitter bots will refuse to take the image. Check out my other blog post on `}<LocalLink to="/blog/easily-resize-images-for-your-blog/" mdxType="LocalLink">{`how to resize images easily`}</LocalLink>{`. It is best to use a landscaped image at 1200x768 for the best look for Twitter cards and on Facebook.`}</p>
    <p>{`But wait, there’s more! Do you know that Google actually shows your images `}<em parentName="p">{`from`}</em>{` your blog post `}<strong parentName="p">{`IN Google’s Image Search?`}</strong>{` But how does Google know how to categorize your photos? Well, when you search for a specific keyword in Image Search, it’s actually trying to look up the image’s filename AND the content in the `}<em parentName="p">{`alt`}</em>{` description.`}</p>
    <Ads mdxType="Ads" />
    <p>{`In layman’s term, you should rename your image from `}<em parentName="p">{`DS12345.jpg`}</em>{` to `}<em parentName="p">{`paris-beautiful-scenery.jpg`}</em>{` instead before you upload it to your blog. You should also make sure your `}<em parentName="p"><inlineCode parentName="em">{`<img>`}</inlineCode></em>{` tag in your blog post has an alt description with the appropriate keywords in it. E.g. `}<inlineCode parentName="p">{`<img src="paris-beautiful-scenery.jpg" alt="Eiffel Tower in Paris 2020" />`}</inlineCode>{`. So make sure that these two have keywords in them to make it easier for Google to understand and index your photos appropriately.`}</p>
    <Image alt="Linhardt is one sleepy boi. Thank you for hovering over this image." caption="Here, you see this image having an alt with all the searchable keywords." name="image-with-alt-seo-geeknabe-blog-post.jpg" mdxType="Image" />
    <h3>{`Add keywords to your blog content`}</h3>
    <p>{`Google loves matching her searches to what seems to be the correct content that the folks wish to look at. To tell Google `}<strong parentName="p">{`you’re `}</strong>{`the content people are looking for, do the following:`}</p>
    <ol>
      <li parentName="ol">{`Add keywords that you wish to rank for in your content in natural sentences. (Now you know why sites love to write really long post that keeps on repeating the same terms over and over again...)`}</li>
      <li parentName="ol">{`Write a decently long post. (This is more aligned with the idea that you will hit more keywords if you have a long post, not because Google will rank a long, long blog post better.)`}</li>
      <li parentName="ol">{`Write good quality content folks will enjoy. If they hit Back after reading for a bit, that signals to Google you’re not the content they want and Google will place you in a lower bracket in its search result. So make sure to catch folks’ attention (in a good way, of course) in the opening paragraph and write good content so you get a lower bounce rate.`}</li>
    </ol>
    <Ads mdxType="Ads" />
    <h3>{`Your blog post’s URL`}</h3>
    <p>{`So we have Google looking at title, description, images and content. What’s left? That’s right, your blog post’s URL. If you noticed, most popular sites now have explicitly descriptive URLs. These have two benefits:`}</p>
    <ol>
      <li parentName="ol">{`The URLs are now more descriptive AND easier for casual users to guess what’s the next URL. For example, we have this URL `}<em parentName="li">{`geeknabe.com/topic/anime`}</em>{`. We can tell that its a page for the topic anime. Then surely there’s a page for the topic `}<em parentName="li">{`manga`}</em>{`?`}</li>
      <li parentName="ol">{`Descriptive URLs also serve as SEO. Google looks at your blog post’s URL for keywords as well.`}</li>
    </ol>
    <p>{`So make sure to add keywords to your blog post URL! A good practice is to make sure all pages in your blog are categorized based on topics and shown explicitly in your URL. E.g. `}<em parentName="p">{`/blog/this-is-a-blog-post`}</em>{` or `}<em parentName="p">{`/category/tech/this-is-a-tech-blog-post`}</em>{`. Google has gotten really smart in understanding URLs so you should try to play along with their rules.`}</p>
    <ol>
      <li parentName="ol">{`Replace spaces in the URL with dashes. Google will understand it all the same. E.g.`}<em parentName="li">{` /this-is-a-title.`}</em></li>
      <li parentName="ol">{`Google understands that this is a blog post from 1st Jan 2020. E.g. `}<em parentName="li">{`/2020/01/01/this-is-a-blog-post`}</em>{`.`}</li>
      <li parentName="ol">{`Google indexes URLs with string queries as a new link. E.g. `}<em parentName="li">{`/blog/this-is-a-blog-post?page=1 `}</em>{`gets indexed as well because it is frequently visited by viewers.`}</li>
    </ol>
    <Ads mdxType="Ads" />
    <h2>{`Add a robots.txt file`}</h2>
    <p>{`If you intend to let anyone and everyone `}<strong parentName="p">{`index`}</strong>{` your site, you.. probably don't need this. But if you wish to start excluding certain pages from being indexed, or start being more crawler bot-conscious, you need a `}<em parentName="p">{`robots.txt`}</em>{` file. A `}<em parentName="p">{`robots.txt `}</em>{`file is a set of instructions to tell crawler bots such as Googlebot, Twitterbot or Facebot to crawl your site the way you want it to. It's great for SEO, and helps to prevent duplicate pages and prevent these bots from crawling your site willy-nilly. `}<strong parentName="p">{`However, be warned that bad bots will disregard your robots.txt! Make sure you have a way to block these bots from your hosting provider.`}</strong>{` It's important to first understand the syntax of the robots.txt file since a single typo could make the bot disregard the entire file! The example below shows how to disallow every bot to index your entire site.`}</p>
    <pre>
  # The syntax below means you disallow EVERY bot to come index/scrape your
  site!
  <br />
  # User-agent is the name of the bot. I have added a * as a wild card to
  indicate all bots.
  <br />
  # Disallow is the syntax to tell the bot "I do not allow you to index/scrape
  <br />
  # all website pages that matches this path".
  <br />
  # The path shown below is /, which means every path that falls under /
  (technically, the entire site)
  <br />
  User-agent: *
  <br />
  Disallow: /
    </pre>
    <p>{`Of course, we can't disallow all bots since that would mean Google bots, Facebook and Twitter couldn't index our site! So we need to allow them in our robots.txt.`}</p>
    <pre>
  # Googlebot is Google's bot name. Twitterbot is, well, Twitter's bot.
  <br />
  # facebot and facebookexternalhit is Facebook's bots!
  <br />
  # Allow is the syntax to tell the bot "I allow you to index/scrape all website
  pages that matches this path".
  <br />
  # The path shown below is /, which means every path that falls under /
  (technically, the entire site)
  <br />
  User-agent: Googlebot
  <br />
  Allow: /
  <br />
  User-agent: facebot
  <br />
  Allow: /
  <br />
  User-agent: facebookexternalhit
  <br />
  Allow: /
  <br />
  User-agent: Twitterbot
  <br />
  Allow: /
    </pre>
    <Ads mdxType="Ads" />
    <p>{`You might want to cherry pick certain paths instead. In this case, you can use `}<strong parentName="p">{`Allow`}</strong>{` or `}<strong parentName="p">{`Disallow`}</strong>{` to match the paths.`}</p>
    <pre>
  # Disallow Facebook to scrap every website pages that fall under topic/
  <br />
  User-agent: facebot
  <br />
  Disallow: /topic/
  <br />
  # Allow Googlebot-Image (Google Image's bot) to index every image that ends
  with .png
  <br />
  User-agent: Googlebot-Image
  <br />
  Allow: /*.png$
    </pre>
    <p>{`There are many other bots out there so the safest to do is first, add a rule to say `}<em parentName="p">{`"You disallow all bots"`}</em>{`, followed by a whitelist of any bots you deem safe. If you're still not sure on the details, please refer to how I did it on my own `}<RemoteLink to="/robots.txt" mdxType="RemoteLink">{`robots.txt`}</RemoteLink>{`! I have done all that is mentioned in this section. You can check your robots.txt here, using `}<RemoteLink to="https://support.google.com/webmasters/answer/6062598" mdxType="RemoteLink">{`Google's tool`}</RemoteLink>{`.`}</p>
    <h3>{`Lastly, add a sitemap`}</h3>
    <p>{`If you have followed all of the tips I mentioned, then hurray! We’ve done as much as we could to make our blog post keyword-friendly for Google. But wait! How do we tell Google we got a blog post up to be scrapped? Now, here’s where a `}<strong parentName="p">{`sitemap `}</strong>{`comes in. A sitemap is a file that contains`}<strong parentName="p">{` every link `}</strong>{`from your blog you want Google to index. It looks something like this:`}</p>
    <Image alt="Google.com's sitemap structure" caption="Google.com's sitemap. You can visit it at google.com/sitemap.xml" name="google-sitemap-seo-geeknabe-blog-post.jpg" mdxType="Image" />
    <p>{`If your blog is handmade with love, then you will need to crack those knuckles and get to work on this sitemap. If your blog is on WordPress, here you `}<RemoteLink to="https://wordpress.org/plugins/google-sitemap-generator/" mdxType="RemoteLink">{`go`}</RemoteLink>{`. Please double-check if you already have it generated before you do anything else!Next, make the sitemap publicly available and submit it to `}<RemoteLink to="https://search.google.com/u/1/search-console/welcome" mdxType="RemoteLink">{`Google Search Console`}</RemoteLink>{`. This Console is a platform every webmaster needs to know of if you have your own domain. Make sure to follow the instructions from Google to prove that you own the domain first before you can use it.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Google Search Console tells you `}<em parentName="p">{`everything`}</em>{` you need to know about what folks are looking up when they saw your blog post. It has other features as well, such as checking if you have dead links indexed, or the performance of your mobile pages. It’s basically a platform to tell you what Google thinks of your blog and how Google treats your page. What’s important about this Console is that you can submit your `}<strong parentName="p">{`sitemap`}</strong>{` link to inform Google you have new pages to index. Once received, Google will index all links in the sitemap within the next few days.`}</p>
    <Image alt="Screenshot of Google Search Console" caption="How Google Search Console look like" name="geeknabe-google-search-console.jpg" mdxType="Image" />
    <p>{`And that’s it. You’ll soon see your blog post amongst the search result whenever you Google for the keywords you stuffed so hard into your blog post. If more folks click on your link, it gets pushed to higher search results pages. Promote your blog post on social media to get a headstart! All the best.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      