import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../../styles/globals';

const Container = styled.iframe`
  width: 100%;
  height: 400px;
  margin: ${$.layout.margin3}px 0;
`;

const Video = ({ src, title }) => (
  <Container
    loading="lazy"
    src={src}
    frameBorder="0"
    title={title}
    allowFullScreen
  />
);

Video.propTypes = {
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Video;
