import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXFanfiction/Chapter/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Divider = makeShortcode("Divider");
const AuthorNote = makeShortcode("AuthorNote");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`“Seriously, Byleth Sitri Eisner, I know I said Dimitri wasn’t a bad person and all, but why are you suddenly going to the beach with him?” Reus said with a very concerned voice as he sat himself on her bed. “I thought you hated him.”`}</p>
    <p>{`She shrugged, “I need a break.”`}</p>
    <p>{`“Wha—no, I mean, two months ago you literally couldn’t stop being angry at him for almost running you over in the corridor when he bumped into you, and now we’re going on weekend getaways?” Reus scooted closer to her, “A-Are you dating him?”`}</p>
    <p>{`By instinct Byleth slapped her brother’s shoulder who leaped away at the last second, also by instinct. “No!” She replied angrily and quickly turned her face away before Reus could see her blushing face.`}</p>
    <p>{`Reus not-so-gently pinched her flabby stomach, “Is Felix going with you?”`}</p>
    <Ads mdxType="Ads" />
    <p>{`She swatted his hand away, “No, he said he wanted some time alone. And Dimitri is going to teach me how to swim,” Byleth added. He technically wasn’t, but she could ask to make the trip seem more legitimate...somewhat.`}</p>
    <p>{`Her brother rolled his eyes, “Yeah, it’s not like we don’t have an `}<em parentName="p">{`olympic sized`}</em>{` swimming pool that’s literally a stone’s throw away from this room, and it’s not like you have a `}<em parentName="p">{`twin brother`}</em>{` who is on the `}<em parentName="p">{`swimming team`}</em>{`.”`}</p>
    <p>{`“It’s a day trip!” Byleth snapped, “Why are you being so weird about this?”`}</p>
    <p>{`Reus shrugged, “I don’t know, I can sense something going on and you’re not telling me.”`}</p>
    <p>{`She let out an exasperated sigh, “Nothing is going on. I’m just...going on a weekend day trip with a friend, okay?”`}</p>
    <p>{`Reus squinted at her for a good while as she held her own squint at him. In the end, Reus let out a sigh and conceded. “Fine,” he groaned.`}</p>
    <p>{`Byleth continued her packing, now remembering to take along her rarely used swimsuit. Of course Reus was right, something was going on and she just `}<em parentName="p">{`could not`}</em>{` tell him. And of course she felt the guilt the size of a planet for not telling him.`}</p>
    <p>{`“What time will you be back?” Reus suddenly said, making her jump since she was swimming deep in her guilt-ridden thoughts.`}</p>
    <p>{`“Oh, um...By dinner, I should think? We’re only going to the beach in Aegir,” she replied as she gave her bag one final check. “I’ll text you.”`}</p>
    <p>{`Reus rolled his eyes and then sighed. He opened his arms and wiggled his fingers, Byleth snorted but then gave her brother the hug he wanted, “I don’t know what you’re up to and I don’t really trust Dimitri outside of swimming.” Reus sighed again, “Just...text me whenever.”`}</p>
    <p>{`She said goodbye to her brother and jogged down to the bus stop. It was 8 am on Saturday and the cold mountain air was at its peak. Byleth shivered a little as she took in a deep, refreshing breath.`}</p>
    <p>{`As she approached the bus stop at the university entrance, two figures came into view. Between the two, Felix’s scowl stood out like a beacon in the morning mist.`}</p>
    <p>{`Byleth carefully walked up to the pair, trying to assess the situation. “Good Morning,” she greeted them.`}</p>
    <p>{`“Dimitri has a fever,” Felix said flatly.`}</p>
    <p>{`“What? Why?”`}</p>
    <Ads mdxType="Ads" />
    <p>{`“He desperately needs the ocean—like now,” Felix said, looking down at his watch. “The whole episode with the alcohol exacerbated things, he’s not supposed to be due for another week.”`}</p>
    <p>{`Byleth was in the throes of utter confusion. Due? Exacerbated?`}</p>
    <p>{`She took a closer look at Dimitri whose countenance was pale and his lips were cracked. Next to him were already a few empty bottles of various electrolytes. Byleth knew their little weekend ‘getaway’ was for Dimitri to replenish his essence, despite having spent a lot of her time with him, she couldn’t tell he was suffering. He must’ve taken a turn in the night.`}</p>
    <p>{`“Does...soaking him in salt water not work?” Even though she asked, she had no idea what to do. She knew Dimitri drank the vending machine’s supply of gatorade and whatever electrolyte beverage he could get his hands on to maintain his hydration level, but it seems it just wasn’t cutting it anymore. Dimitri could barely keep his eyes open.`}</p>
    <p>{`Felix shook his head, “It’s not strong enough, table salt cannot be compared to the ocean’s essence.”`}</p>
    <p>{`“Aren’t you coming with us?” Byleth asked, checking the time. The bus should be here in about 5 minutes.`}</p>
    <p>{`Felix shook his head again. “No. He’s fine. I’m going back to sleep.”`}</p>
    <p>{`“Wha-Felix, wait, how are you so calm about this? Won’t he die?” Byleth asked, her voice in a slight panic.`}</p>
    <p>{`Felix groaned, “Not yet—it’s not as bad as his beer episode, but he’ll just be a little weak. You might need to hold on to him though.”`}</p>
    <p>{`Byleth was about to say something when Felix cut in, “His legs don’t work so well when he’s like this.”`}</p>
    <p>{`“S-so just...hold on to him...like?” Byleth could feel the blush creeping up her neck.`}</p>
    <p>{`Felix shrugged, “Hold his hand, arm, neck—if you can reach it. Put him on a leash, however you want. Manhandle him, even— if that’s your thing.”`}</p>
    <p>{`“M-Manhandle..! Where’s Dedue?” She looked around frantically but the giant was nowhere in sight. Surely Dimitri’s burly vassal was a much more useful person than she was. And could carry Dimitri, if needed.`}</p>
    <p>{`“This idiot made Dedue go ahead to prepare something, I don’t know. You’ll just have to deal with `}<em parentName="p">{`it`}</em>{` yourself,” Felix gestured at the wheezing merman slumped over the bus stop bench.`}</p>
    <p>{`Byleth sucked in a deep breath and waved at Felix’s silhouette angrily tossing the empty bottles into the trash. The bus pulled up once Felix was out of sight and Dimitri groggily pushed himself off the bench. Byleth stood close to him in case his legs gave way.`}</p>
    <p>{`The bus driver immediately sped off as soon as he validated their tickets and Dimitri lurched forward and nearly fell on his face had Byleth not been in front of him. She clutched onto his arm and led him to an empty seat near the back.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Dimitri let out a soft groan when he sat down, looking as though he was pooling into the seat. He pulled out a bottle of some sports drink from his backpack, and from what she could see, his entire bag was filled to the brim with drinks.`}</p>
    <p>{`“Ah, we should’ve gone to the toilet. Will you be alright?” She said as Dimitri emptied the bottle in seconds. He nodded weakly, closing his eyes again and leaned on the bumpy bus window.`}</p>
    <p>{`She assumed Dimitri probably didn’t have any ‘pee’ so to speak; his body was desperately absorbing every single drop of electrolyte that passed his throat. It was only an hour’s ride, but Byleth couldn’t stop herself from fidgeting and imagining the worst case scenario. What if something inexplicably terrible happened to him and she was powerless to do anything about it?`}</p>
    <p>{`The minutes went by and Byleth noticed Dimitri was constantly licking his parched and cracked lips. A thought sprang to mind. She immediately dug her bag for her lip balm, uncapped it, and hoped Dimitri wouldn’t mind if it had been before.`}</p>
    <p>{`Her hand slowly approached him, but his eyes shot open before she even got near his face. Dimitri glared at her, “What are you doing?” he croaked.`}</p>
    <p>{`“It’s lip balm, it’ll moisturise your lips and make it a little bit more comfortable for you,” she replied unfazed and wiggling the watermelon flavoured lip balm tube at him. He squinted at it for a second and then licked his dry lips. He glanced at the small tube in her hand, and then at her, and pursed his lips.`}</p>
    <p>{`“....Sure,” he said after another second passed.`}</p>
    <p>{`Byleth was a little hesitant to apply it for him, but it seemed like he didn’t know how to and thought it fine to demonstrate by doing it for him. She gently dabbed on a generous amount until his lips looked a pinkish, in fact. She showed Dimitri how to smack his lips to even out the distribution and he mumbled a soft ‘Thanks’ before leaning back on the rattling window.`}</p>
    <p>{`Byleth had fallen asleep a short while later, having woken up only when she felt something heavy pressing down on her head. Her eyes fluttered open to Dimitri’s entire body leaning on her, she could even hear his ragged wheezing.`}</p>
    <p>{`She tried to gently push him aside so as not to wake him, but Dimitri was just...massive and had the weight to match. Byleth heaved him aside, making sure his head didn’t hit the window and checked where they were.`}</p>
    <p>{`The LED sign inside the bus indicated they were near their destination. Again she gently tried to wake him up but he wasn’t budging. In a panic, having thought he died in his sleep, she violently shook him and thanked the goddess when he opened his eyes. Albeit a little grumpily.`}</p>
    <p>{`“We’re nearly there,” she said sheepishly. He nodded and stretched, drinking another bottle of gatorade.`}</p>
    <p>{`To her surprise, as soon as they got out of the bus, Dimitri perked up a little as the sea breeze hit them. The look on his face reminded her of a child coming home and smelling his mother’s cooking.`}</p>
    <p>{`Dimitri grabbed her hand and pulled her towards the beach, seemingly knowing where to go even though he stumbled over a few times. Byleth had to use all her strength to pull him back when he tripped one or two times to prevent him from kissing the sandy pavement.`}</p>
    <p>{`“Sorry,” he mumbled. Felix was right, Dimitri’s legs weren’t very cooperative at the moment. In fact she had to fend off a couple of strangers who offered to help transport Dimitri to a first-aid station.`}</p>
    <p>{`Felix mentioned he needed to return to sea monthly to replenish his essence. “It’s like his period. The need comes every 28 days or so and he gets cranky when it’s almost that time of the month,” Felix explained nonchalantly once. Byleth couldn’t help but chuckle at his analogy at the time.`}</p>
    <p>{`Despite being a relatively early Saturday morning, the beach already had a few people setting up umbrellas and claiming the perfect sun tanning spot. Dimitri strode past them with her in tow, and led them to a very remote part of the beach, far away from any pockets of humans, with only rocks and barnacles in sight. Even though he was gingerly avoiding the wet, mossy rocks and really sharp looking barnacles, Dimitri made his way quite expertly, as if there was a guide in front of him.`}</p>
    <p>{`The alarm bells in Byleth were starting to ring, and the whole ‘what if he murders me here’ thought ran through her head.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“W-Where are we going? Isn’t this enough ocean for you?” She asked, trying to hide the panic in her voice.`}</p>
    <p>{`“No,” he said without hesitation, “I cannot be seen by anyone.”`}</p>
    <p>{`“Right, sorry,” she said, mentally smacking herself.`}</p>
    <p>{`“And...erm, it’ll be safer for your belongings as well,” Dimitri added. And just as he said that, they stood in front of a wall of rocks.`}</p>
    <p>{`Dimitri let go of her hand and effortlessly moved the rocks aside as if they were a wall made out of pillows. Before she could access where they were and mentally map her escape route, Dimitri bent down and pulled her into the cove.`}</p>
    <p>{`The cove was the size of a small room with barely any standing space. Inside was a wicker basket labelled ‘`}<strong parentName="p">{`Spare Clothes`}</strong>{`’, written in some form of old ink. She assumed the clothes inside would probably be from a few different time periods, judging by the insanely yellowed label.`}</p>
    <p>{`Dimitri took one last gulp of his drink, dropped his bag on the sandy ground and started stripping shamelessly in front of her.`}</p>
    <p>{`Byleth was speechless and quickly spun around before Dimtiri removed his jeans. “Are you wearing a swimsuit underneath?” Dimitri suddenly said, Byleth turned around to ask what sort of a question was that supposed to be, when she was, again, greeted by considerable male organs.`}</p>
    <p>{`She yelped before turning her back to him, “Wh-What? No, but-”`}</p>
    <p>{`“Sorry, but please come with me.” Dimitri grabbed her bag and tossed it next to his. “Leave your phone here, it will get damaged.”`}</p>
    <p>{`“Why? I thought I was just going to wait here for you,” she said, looking up at the cove ceiling to avoid making eye contact with his gonads again. “At least use a towel or something,” she mumbled.`}</p>
    <p>{`He didn’t say anything but took her phone from her and left it with her bag, then grabbed her arm and pushed her out of the cove. “I can’t afford to wait any longer.” Dimitri said as he quickly stacked the rocks back.`}</p>
    <p>{`“What do you mean?” She asked, but he then took her hand and pulled her towards the ocean with him without saying anything.`}</p>
    <Divider mdxType="Divider" />
    <p>{`“Dimitri, hey, wait could you please tell me what’s going on?” She tried to wriggle
her wrist free from his grasp but Dimitri’s iron grip wouldn’t budge.`}</p>
    <p>{`He didn’t reply but finally let go of her and took a big leap into the ocean. “Dimitri!” She called out. Byleth let out a groan before removing her shoes and socks, leaving it behind some rocks, rolled up her jeans as high as she could and waded into the ocean. She scanned the waves, trying to look for him beneath the crystal clear waters.`}</p>
    <p>{`As she shielded her eyes from the sun with her hand and scanned the body of water in front of her, a mesmerizing kaleidoscope of various blues with pink iridescent scales, some fins, and tendrils adorning the sides of his tail, caught her eye and it utterly bewitched her.`}</p>
    <p>{`Byleth just couldn’t take her eyes off it— how sunlight bounced and reflected off the scales, revealing a soothing rainbow of colours, and oh, how water distorted the image like a hypnotising dream made it all the more mesmerising. She never wanted to take her eyes off it.`}</p>
    <p>{`She followed it until she was almost waist high in the water as it swam further away. Byleth wanted to reach out and touch it but stopped when she realized the waves were getting rougher.`}</p>
    <p>{`Like that fateful night, panic rose in her chest. No longer in that hypnotizing trance, she rushed to wade back to dry land. She heard a shout and turned around in time to see Dimitri leap out of the water doing a summersault— the shimmering magnificence of his tail almost blinding her. Clearly, the ocean’s pure salt water had rejuvenated Dimitri.`}</p>
    <p>{`She was in so much awe that she didn’t even realise she was once again moving towards deeper waters.`}</p>
    <p>{`Something caught her foot, snapping her out of it. She looked down to see Dimitri holding her ankle, he gave it a quick tug and she fell into the water. Despite it only reaching her waist, the force at which she was yanked had her completely submerged. Her arms flailed and she struggled to push herself upwards, slipping a couple of times from the soft sand bank under her feet. The current seemed to pull her further into the ocean. The water came up to her chin now.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Dimitri broke through the surface with a refreshing glow on his usually grumpy face. His signature bangs were slicked back, exposing his fair forehead. An amazing sight, but Byleth was unfortunately going to drown.`}</p>
    <p>{`“Dimitri!” Byleth sputtered, salt water stung her eyes and burned her nostrils as she struggled to stay afloat. “What in the world—?!”`}</p>
    <p>{`“Don’t panic,” Dimitri came to her and held her face in his large hands. “Please relax.”`}</p>
    <p>{`Before she could retort he placed a long kiss on her lips. Byleth shut her eyes and pushed him away but before she could protest again, Dimitri pulled her past shallow waters towards the open sea, submerging her entire being in water.`}</p>
    <p>{`Byleth thought she was going to be drowned by him but again, like that night, she was breathing underwater as though it was the most normal thing to do.`}</p>
    <p>{`“Hold on to me,” Dimitri said, opening his arms. His voice was clear as day and she understood him as if he was talking to her on land.`}</p>
    <p>{`“Wha...What’s happening?” She found herself speaking and gasped, she too was talking as if she was on land. The water that entered her mouth wasn’t choking her, neither did it sting her eyes and nose as she talked.`}</p>
    <p>{`She was floating somewhat, but she didn’t feel the need to break the surface for air at all. Just...being engulfed in water like this felt...peaceful, calm and serene. Beneath her was a small coral reef and a few varieties of the fishes from ‘Finding Nemo’ swimming around it. It was just surreal to be able to exist with them and see them so closely without any equipment.`}</p>
    <p>{`Actually, Byleth couldn’t tell if she was sinking or drifting with the current. Even though she was confused as hell about the situation she was in, being surrounded by the shape of water embracing her so warmly felt wonderful.`}</p>
    <p>{`Dimitri swam closer towards her, “I would like to take you to my... hometown,” he said his cheeks a little pink, “Dedue...er...has gone ahead to let the others know.”`}</p>
    <p>{`“What?” She said, now completely snapping out of her stupor. “B-but I’m not...You mean a whole community of merpeople?” Dimitri nodded.`}</p>
    <p>{`“It’s nothing much, but I would like to show you my..world. A-As a friend. I’ve...uh...well. I’ve..never made a..proper outside friend before,” Dimitri said, looking away and blushing right up to his ears. “Would you...l-like to see it? My home, I mean.”`}</p>
    <p>{`Byleth’s heart just about melted, is this what they meant by mermaids enchanting people? Well, Byleth thought, they’re already here. If he was going to kill her, he would’ve done it already.`}</p>
    <p>{`She nodded, and Dimitri coiled his thick muscled arms around her waist, “Please..h-hold on tightly,” he whispered.`}</p>
    <p>{`“Wait, will I run out of air? Or does this mermaid magic last forever?” Byleth asked.`}</p>
    <p>{`Dimitri shook his head, “It will reset once you break the surface. Please, trust me.”`}</p>
    <Ads mdxType="Ads" />
    <p>{`She paused for a moment as her rationality slowly resurfaced. Isn’t this what all these charming and cunning men say all the time to trick women? What’s more, Dimitri was `}<em parentName="p">{`actively `}</em>{`looking for someone to copulate with.`}</p>
    <p>{`The logic and sanity in her head was having a fierce battle with the bursting curiosity in her. A dozen scenarios played in her head while she took in the scenery around her. Byleth gave herself a good minute to think whether she should just follow this guy to his underwater hometown when she only knew him for a few months.`}</p>
    <p>{`She took a deep breath (a feat she never knew could be possible underwater), made a decision and gave in to her curiosity, afterall...a real life mermaid was right here in front of her eyes.`}</p>
    <p>{`Byleth `}<em parentName="p">{`really`}</em>{` wanted to see and learn more, as would any normal person who met a mermaid. Honestly, logic didn’t stand a chance.`}</p>
    <p>{`Byleth nodded at Dimitri, reluctantly wrapping her arms around his neck. His body was warm and firm and her heart was about to explode at how intimate they were. But she calmed herself down by remembering that Dimitri not only kissed her, but various other souls who were lost at sea. And he most likely hugged them like this and...pressed them up against his broad, chiseled chest like this.`}</p>
    <p>{`“My home is in Faerghus, but it won’t take long to get there,” he said as he adjusted his hold on her, making sure she was secure in his arms. “If you feel uncomfortable or need to rest, let me know.”`}</p>
    <p>{`And with a kick of his tail, they zoomed off into the distance.`}</p>
    <p>{`The only way Byleth could describe the sensation was as if she was on an extremely high speed roller coaster and the g-force was so strong she felt as if she was about to pass out. Being flung around in the air was one thing, having water whipping across your face is a completely different matter. (Editor: pun intended)`}</p>
    <p>{`Dimitri’s tail was incredibly powerful, each time he gave his tail a kick, they propelled even further and faster. His swimming speed as a human was nowhere near to the speeds he was swimming at now. Dimitri was most likely faster than a speedboat.`}</p>
    <p>{`The water whizzed by her in an actual blur, all she could see were bubbles, foam and gradually, nothing as pure darkness engulfed them the deeper they descended into the ocean depths— and her mind couldn’t handle it anymore. Her heart was thumping wildly in her chest as Dimitri swam into the abyss.`}</p>
    <p>{`She didn’t know she was capable of feeling such petrifying levels of fear. She couldn’t even say anything. Soon, they were enveloped in darkness and as much as she tried to keep her eyes open, the inhuman levels of speed forcefully shut her eyelids on her, while her arms went weak and her grip around his neck loosened.`}</p>
    <p>{`The last thing she heard was Dimitri calling out to her.`}</p>
    <Divider mdxType="Divider" />
    <p>{`“Byleth?” Dimitri said when he felt the grip around his neck loosening and the body
in his arms going soft. He immediately stopped and looked intensely at the limp body,
trying to find signs of life. Thankfully, she was still breathing, noticeable by
the rising and falling of her very large chest. He quickly looked away, even though
there was no one around to accuse him of anything.`}</p>
    <p>{`Truth be told, Dimitri wasn’t thinking straight. He’d experience dehydration before yes, and nearly actually died from it `}<em parentName="p">{`that night`}</em>{` too. But the feeling of his life force hanging on the verge was a whole different type of pain and suffering.`}</p>
    <p>{`He’d heard other mermaids describe it as being similar to what a human might experience when they have a severe cold on top of feeling like his entire body was just...dry and sore and painful.`}</p>
    <Ads mdxType="Ads" />
    <p>{`But...when Byleth offered him that lip balm thing and applied it for him...it wasn’t just the actual product that soothed him. The way she gently rubbed it on for him and looked so focused doing it...For a moment Dimitri’s entire being was free of pain.`}</p>
    <p>{`Dimitri also thought the way she squealed when he stripped was amusing. Not that he did it on purpose to elicit such a reaction, it was just something he always did. Having a woman around made no difference to him.`}</p>
    <p>{`And the way she was so clearly mesmerised by his tail, he couldn’t help but show off a little with that summersault - which was something very normal to him but Byleth looked so happy it excited Dimitri to see her wear that expression since she had a vacant expression most of the time, like Reus.`}</p>
    <p>{`They were quite deep under water now and Dimitri was a little bit concerned about Byleth. Light barely penetrated the water at their current depth. It was at a level where all merfolk swam to avoid being discovered. Like the rest of his community, his eyes were made to see in the dark, cold depths and his body was built to withstand the pressure.`}</p>
    <p>{`While a mermaid’s kiss not only gave a person the ability to breathe underwater, it also gave them some mermaid-like traits, like withstanding the intense pressure and frigid underwater temperatures. Dimitri was thankful he wasn’t the first mermaid to discover this ability, he was also thankful Byleth wasn’t the first human these abilities were ‘tested’ on. It was a knowledge that he, and those in his generation grew up with.`}</p>
    <p>{`As he brushed a finger over her lips that were still thankfully breathing in the water effortlessly, he thought about when he kissed her just now. It’s like he told her, while he was aware that the act of kissing was something intimate, to him, him giving her the ability to almost be like his kind wasn’t anything special. But still...he sighed.`}</p>
    <p>{`Dimitri ran a hand through his now short hair while holding on to her with his other. Giving her that kiss just now felt...different. It felt...soft...and...good.`}</p>
    <p>{`He shook his head. No, he was just overthinking it. He is just happy to have a friend from the ‘outside’ world that wasn’t someone from the Fraldrius family. Or at least he hoped they were friends. Byleth was sort of forced into this by Felix and indirectly, his irresponsibility that night.`}</p>
    <p>{`His ‘education’ with her was going well, as compared to when he was shadowing Felix. He and Felix didn’t talk much, if at all. Which was a slight pity since Felix was an adorable and loving child. Dimitri remembered Felix used to love splashing around in the water with him.`}</p>
    <p>{`Dedue didn’t count since they were always together. With Byleth he was...comfortable. With her, even though she was a quiet person as well, she didn’t scowl or glare at him, or even force him to talk and rushed him when he couldn’t get the words out. She was patient and kind to him.`}</p>
    <p>{`Dimitri swam up to where the light was able to pierce through the waters and gently tapped her cheeks, hoping to wake her up. He peered into her face, looking for signs of life. He pressed his ear to her chest to listen to her heartbeat while his own seemed to squeeze tightly. Why wasn’t she waking up yet?`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Um...what are you doing?” A voice sounded from above him. He released her in a panic but quickly grabbed her back when she started sinking.`}</p>
    <p>{`“S-sorry, I wanted to see i-i-if you were still breathing,” he explained sheepishly.`}</p>
    <p>{`“Yeah, I...I think I passed out from the g-force,” she said softly, clutching her head. “It was...exhilarating and then just pure darkness...” For once, she actually looked scared.`}</p>
    <p>{`“Just hold on to me, I can’t risk being seen,” was all Dimitri could say as he began his descent again.`}</p>
    <p>{`She looked around, to him it was clear as day but to her the darkness must’ve been terrifying.`}</p>
    <p>{`“I..can’t see you,” she said. “H-How deep are we?” He could hear the panic in her voice.`}</p>
    <p>{`“Deep enough,” he replied. “D-Don’t..be scared. I won’t let you go.”`}</p>
    <p>{`Dimitri could feel the deep breath she took, “I know,” she said. He tucked her head securely in the crook of his neck and held her body firmly in place. He felt another jolt of electricity when she wrapped her arms around him.`}</p>
    <p>{`Dimitri maintained his swimming at the current level, unsure if Byleth could withstand going any deeper. As much as he wanted to soothe her fears, he refrained from talking to her. Deep sea creatures were attuned to the dark and were easily attracted to noise. Not wanting to risk losing his new friend and any future ones, he also took note to swim slower when transporting humans in his arms.`}</p>
    <p>{`But she was tiny compared to him, he had to control his strength so as to not crush her as he swam. And every few kilometers or so, he’d stop and check whether she was okay. “Are you cold?” He asked, forgetting that while he was well adapted to the freezing cold temperatures, she was not.`}</p>
    <p>{`She paused.`}</p>
    <p>{`“Byleth?”`}</p>
    <p>{`“...Actually, no. It also helps that you’re very warm.” He nodded in reply but again, forgot she couldn’t see him. “Okay.”`}</p>
    <p>{`Dimitri knew the waters around Fodlan like the back of his hand; it was his home for the past 2 decades. It also helped that he recognized the different coral reefs and judging by the difference in water temperature, they were already in the Rhodos Coast. “Just a bit more,” he whispered to her.`}</p>
    <p>{`Dimitri stopped in front of an extremely long tunnel, it was a shortcut underneath Kleiman the merfolk used. Normally, they’d be able to cut through to Fhirdiad with their speed in less than 10 minutes but right now he had a human with him.`}</p>
    <p>{`“Is something wrong?” Byleth asked, her voice sounded a little bit worried.`}</p>
    <p>{`He grunted, “No. Nothing.”`}</p>
    <p>{`Dimitri decided against it. While the tunnel was normally illuminated to him, again, to Byleth it was just pure darkness. He didn’t want her to feel any more scared than she already was, and the tunnel could be a little bit too eerie, even for him, which was why he always swam at full speed when using it. Plus the occasional deep-sea predator could be lying in wait as well. He didn’t want to endanger her no matter what.`}</p>
    <Ads mdxType="Ads" />
    <p>{`He swam round Kleiman going as fast as he could without Byleth passing out again and descended again, this time into the entrance to his hometown; an undiscovered trench hidden at the very depths of the Faerghus ocean. A sight that made Dimitri feel instantly at peace.`}</p>
    <p>{`“W-Where are we?” Byleth’s trembling voice reminded him that the human in his arms may not feel the same serenity as him. “I..can’t tell if my eyes are open or closed,” she said.`}</p>
    <p>{`“We’ve reached the entrance,” Dimitri said, reaching the ocean floor, “Can you feel the ground?”`}</p>
    <p>{`“I-I think so,” she said, he let her go but held on to her hand.`}</p>
    <p>{`At the bottom of the pitch black trench was a tunnel-like entrance, blocked by a wall of rocks and boulders. Dimitri swam forward, placed his hand on the wall and sang. He had never brought a human that wasn’t a Fraldarius down here before and he wondered what it felt like experiencing this for the first time.`}</p>
    <p>{`His voice activated a deep, ancient magic the Goddess bestowed upon them when ‘the Fall’ happened. The walls and rocks moved to reveal another entrance in the trench, Dimitri carried Byleth again and they swam through the short tunnel.`}</p>
    <p>{`“Your Highness,” the two guards greeted him when he emerged into a small room, it was their ‘reception’ area of sorts that opened into their ‘main street’. He nodded at them.`}</p>
    <p>{`“They are ready for you, sir,” one of them said as he swam past them.`}</p>
    <p>{`“This...is...amazing,” Byleth said, he looked down and saw her visibly gawking at his home in awe. The expression was clear on her face and Dimitri smiled to himself. It was normal to him, but from a first-timers perspective, the warm glow of the sun illuminating their little haven must’ve been breathtaking.`}</p>
    <p>{`Just as he exited the reception, Dedue swam towards him and said, “Your Highness, I..I..tried to tell and stop your father, but..”`}</p>
    <p>{`Dimitri frowned, still holding on to Byleth, “But what?”`}</p>
    <p>{`“He misunderstood and-”`}</p>
    <p>{`“Dimitri! My boy!” From a distance, a loud voice boomed and Dimitri’s father swam towards them with his arms open.`}</p>
    <Ads mdxType="Ads" />
    <p>{`“Ah, is this her? Hrmm..yes, yes,” His father said, swimming around him and Byleth inspecting something.`}</p>
    <p>{`“Nice to meet you! I’m Lambert V, yes I’m the fifth Lambert in the family but you can call me...Dad,” His father chuckled while Dimitri let out a loud groan.  “I’ll have Rodrigue come and have a chat with her about the whole mermaid thing, I like her very much already, yes,” he said to Dimitri in a not-whisper.`}</p>
    <p>{`Dimitri stared at his father, and then at a remorseful looking Dedue for an explanation.`}</p>
    <p>{`“Father, what are you talking about?”`}</p>
    <p>{`His father looked equally confused, “Why, Dedue mentioned you were bringing your girlfriend to meet me. I assume you’ll be mating with her soon, yes?”`}</p>
    <p>{`“S-sorry?” Byleth suddenly spoke up. “I’m what?”`}</p>
    <p>{`“N-No, Byleth, This is not what I b-brought you here for,” Dimitri’s anxiety rose to new heights. He shoved Byleth at Dedue and pulled his father away.`}</p>
    <p>{`“Father, I did not send Dedue here to tell you she’s my girlfriend,” Dimitri said angrily, in the distance he could see his father even broke out the party seashells to decorate the place, “She is a `}<em parentName="p">{`friend`}</em>{`, who is a `}<em parentName="p">{`girl`}</em>{` and she is the one helping me with human studies.”`}</p>
    <p>{`His father placed a hand on his chin, “Did he not say that…?” He paused, “I’m sure he said...`}<em parentName="p">{`Oh`}</em>{`...no. Yes. He said…’Friend of Dimitri who is a girl’. Yes. Well...um. I will just put away the decorations...” Dimitri groaned. “Dad…”`}</p>
    <p>{`“What?” His father said, “Do you want to keep the decorations up? I mean it’s already up.”`}</p>
    <p>{`Dimitri squeezed his temples, “Yeah we’ll just keep it up. We don’t get much reason to celebrate so..”`}</p>
    <p>{`“`}<em parentName="p">{`Fantastic!”`}</em></p>
    <p>{`“She’s just a friend,” Dimitri hissed. His father looked over Dimitri’s shoulder, “She is very nice looking. You sure you don’t have feelings for her?”`}</p>
    <p>{`“No. She’s just a good friend to me!” He said loudly, “I just thought it’d be nice to finally bring a real friend I made to our home, okay? She’s the first person who isn’t Felix or Glen, and she even saved my life!” Dimitri sighed, he was utterly embarrassed but he also felt bad, his father was looking forward to this whole human-life experience thing for him. And now the guilt was starting to pile on when his father made a sad face.`}</p>
    <p>{`“Sorry,” Dimitri mumbled, “I just...don’t want to scare her.”`}</p>
    <p>{`“It’s okay, son. I’m sorry too.” His father patted his head. “Oh, you cut your hair? Wow, it looks very… land-like.”`}</p>
    <p>{`“Thank you,” Dimitri replied, “It was a...strange experience.”`}</p>
    <p>{`His father chuckled, “Why don’t you show her around while we get some food ready? She’s okay with seafood right?” Dimitri nodded.`}</p>
    <p>{`He quickly swam over to Dedue who was clutching onto Byleth.`}</p>
    <p>{`“Thank you, Dedue,” Dimitri said, Dedue nodded and released her then swam away.`}</p>
    <p>{`“I’m sorry for being rude,” Byleth said, “I didn’t introduce myself to your father yet.”`}</p>
    <p>{`Dimitri shook his head, “No, we were the rude ones. He’s...like me..um, ever since my mother died.. he hasn’t...well,” he paused, unsure of how to describe his father’s eccentricity without sounding like an unfilial child. “Erm, he doesn’t venture much to the surface anymore. He still...misses her, quite a bit.”`}</p>
    <Ads mdxType="Ads" />
    <p>{`She gasped slightly, “I’m so, so, sorry. Can I apologize now?” Byleth asked, looking for his father.`}</p>
    <p>{`He was only holding her hand at first but now he placed both hands on her shoulders, giving her a firm grip, “We can do that later...but...w-would you like a tour?”`}</p>
    <p>{`Her eyes were hesitant at first but she perked up, and his heart perked up too.`}</p>
    <AuthorNote mdxType="AuthorNote">
  <p>
    *This short section was me attempting to allude to how mermaids would
    sometimes be accused of luring sailors to their deaths. Or like sailors
    would be enchanted by a siren’s singing and be completely hypnotized.
    Something like that XD
  </p>
  <p>
    I mean, to be fair, a regular fish with a colourful tail in an aquarium
    would already grab my attention for a good solid moment, what more a GIANT
    MAN-FISH IN THE FORM OF DIMITRI HAHAHA. 
  </p>
  <p>
    Anyway, the next chapter describes Dimitri’s hometown with a bit more
    detail! Stay tuned :>
  </p>
    </AuthorNote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      