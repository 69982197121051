import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Blockquote = makeShortcode("Blockquote");
const Ads = makeShortcode("Ads");
const RemoteLink = makeShortcode("RemoteLink");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Compared to the previous Act, this Archon Quest has been particularly `}<strong parentName="p">{`enlightening`}</strong>{`. Tons of questions are answered, but it introduced newer questions.`}</p>
    <Blockquote mdxType="Blockquote">Fantastic. I love it.</Blockquote>
    <p>{`I wanted to complete all Chasm World Quests first before writing this blog post, but I `}<em parentName="p">{`don't want`}</em>{` to rush through what I believe to be a sizeable chunk of lore in these quests. Not to mention my writing muse only comes when I'm busy in IRL. I need to write my thoughts down!`}</p>
    <p><strong parentName="p">{`Important Note: I frequent subreddits talking about Genshin's lore, but most of these are my own thoughts. I try my best to be as factual as possible when necessary, but this is ultimately a personal thought post. Spoiler alert!`}</strong></p>
    <Ads mdxType="Ads" />
    <p>{`Genshin's timeline can be ultimately broken down to 4 focal points in the game's history (This is my personal thoughts after piecing through Enkanomiya's lore).`}</p>
    <p>{`The first focal are the Primordial Dragons, said to be the embodiment of the 7 elements in Teyvat. The second is the arrival of Primordial One, which we could safely(?) presume it to be the `}<RemoteLink to="https://houkai3rd.fandom.com/wiki/Fire_Moth#Project:_Ark" mdxType="RemoteLink">{`Project Ark`}</RemoteLink>{` mentioned in Honkai Impact 3. The third is the beginning of the ancient civilisation found littered across Enkanomiya, Chasm and possibly Dragonspine. Finally, the 7 nations in the current game. While the first three focal have their lore littered everywhere in the game for us to piece, Genshin chose to highlight the `}<RemoteLink to="https://genshin-impact.fandom.com/wiki/Cataclysm" mdxType="RemoteLink">{`Cataclysm 500 years ago`}</RemoteLink>{` as the driving plot for the main story.`}</p>
    <p>{`You know, I honestly wish they choose to ignore everything else in the lore and just tell me what's up with the Cataclysm. Every piece of lore in this game is so intertwined, I had a hard time figuring which is which in the timeline. I'll leave someone smarter than me to piece the full timeline while I peck at this small Act released in 2.6!`}</p>
    <p>{`My thoughts below! I'm planning to write it all down, so I could come back and have a good laugh if my silly assumptions and theories are proven after the game's story ends! (Let's hope the story ends within the next 3 years) But first, let's collect our thoughts on `}<strong parentName="p">{`what`}</strong>{` this act answered first.`}</p>
    <h2>{`Answered Question #1: What IS erosion?`}</h2>
    <p>{`Perhaps the very first question I had since learning about it in Zhongli's Story Quest. Zhongli explained that erosion is what took away Azhdaha's reasoning that ultimately turned the Geo Dragonlord against him. Erosion eats up your memories and "chips away" your mind to the point of no return.`}</p>
    <p>{`My very first thought on erosion flits between "Oh, you mean dementia?" and the commonly used plot point in many fantasy stories tackling immortality, specifically the line you'll often hear... "All will eventually return to dust." The explanation Zhongli gave seems fair if we were to take it at face value. Erosion is what it is, a natural decay of memories imposed on all mortal things in Teyvat, including powerful long-lived ones.`}</p>
    <Ads mdxType="Ads" />
    <p>{`Then what is this "curse" that eats at Dain? It's implied to be the very same erosion spoken of by Zhongli and Ei. This... ultimately brings up more questions from me but at the very least, we are clear that 1) erosion is the supposed law in Teyvat and 2) erosion chips away at your memories. Now I know that Dain specifically mentioned "immortality" to be the curse, but I think what the gods wanted to inflict upon the Khaenri'ahn people is not immortality, but what comes from immortality, specifically the effects of erosion.`}</p>
    <p>{`Now keep this in mind as you continue reading...`}</p>
    <h2>{`Answered Question #2: What is the title Twilight Sword?`}</h2>
    <p>{`Dain my boy... Your splash art title is called the `}<em parentName="p">{`Bough Keeper`}</em>{` which gives me a ton of question marks floating above my head, given Honkai's propensity to talk about trees and whatnot. Not to mention Genshin has a ton of trees in it too... I swear, Liyue, Mondstadt and Inazuma each have a tree. And the next region, Sumeru, IS a bloody tree. This IS a spoiler post, so I hope you didn't mind me mentioning a Sumeru leak!`}</p>
    <p>{`Seirai Island, Enkanomiya and even Dragonspine each have some semblance of trees or withered branches in them! Did you notice that claiming your rewards from domains involve reviving a petrified tree? What is up with trees in this game... Definitely food for thought.`}</p>
    <p>{`Anyway, back to the topic... Twilight Sword is the title of Khaenri'ah's Royal Guard Captain, a title last held by Dainsleif. He once led the Black Serpent Knights who unfortunately, couldn't withstand the "erosion" forced on them by the gods. At the very least, we know that the "gods" are free to wield erosion as they please, given how they cursed everyone in Khaenri'ah.`}</p>
    <p>{`It's a minor (and perhaps, fanciful) detail, but I find it interesting that everything related to Khaenri'ah has the propensity of using the terms like twilight or eclipse. Even Khaenri'ah folks have star-shaped pupils!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Answered Question #3: Hillichurls appeared 500 years ago... Hm...`}</h2>
    <p>{`After completing the Dragonspine quest, I have the strongest inclination that the hillichurls I murder every day are not any "normal" monsters. Given how deep the lore is in this game, it doesn't take a genius to piece "Hillichurls" + "Khaenri'ah" together. I watched enough anime to know this!`}</p>
    <p>{`Alas, when it is finally confirmed... It does hurt my conscience.`}</p>
    <Blockquote mdxType="Blockquote">... Just a little, I suppose.</Blockquote>
    <p>{`Dain affirmed that 500 years of erosion has taken away their sense of self, so they are not human anymore. Alright, I'll do the honours of ending their pain as quickly as possible.`}</p>
    <h2>{`Answered Question #4: What IS the Abyss Order's true purpose?`}</h2>
    <p>{`My first impression on Abyss Order is highly skewed towards the Abyss Mage's gang gang woo. Boy, do I hate them... Especially the Hydro and Cryo Abyss Mage duo. From the very first Archon Quest, we see an Abyss Mage manipulating Dvalin to attack Mondstadt. Surely the Abyss Order `}<strong parentName="p">{`IS`}</strong>{` the final boss of the game? This is expanded further by how we found an upside down Statue of Seven before confronting your twin.`}</p>
    <p>{`Putting aside how creepy it is that quest line was, everything about the Abyss Order that you, the player, has encountered screams evil to you. They are putting ordinary folks from the 7 nations in danger for the sake of revenge against Celestia. If not for a short scene explaining the other twin's resolve, I would have stand by my impressions on the Abyss Order.`}</p>
    <Ads mdxType="Ads" />
    <p>{`In the scene where your twin laid down a small bouquet of flowers, we learned about Inteyvat, the national flower of Khaenri'ah. Dain has this to say about the flower:`}</p>
    <Blockquote mdxType="Blockquote">
  It is the national flower of Khaenri'ah: the "Inteyvat." It once bloomed all
  over the nation. It would only last two weeks before wilting. But if you were
  to pluck one and take it out of Khaenri'ah, the petals would stop growing and
  turn hard. Only when it finally returned to its home soil would the petals
  grow soft once more, and finally turn to dust... So the Inteyvat is a symbol
  for a wanderer far from home, signifying the tenderness of the homeland.
    </Blockquote>
    <p>{`In Chinese, Inteyvat (因提瓦特 Yintiwate) means `}<strong parentName="p">{`because of Teyvat`}</strong>{`. The 因 (Yin) comes from the word 因为 (Yinwei) which can be loosely translated as "because of". 提瓦特 (Tiwate) is obviously the Chinese characters for Teyvat. But why name the national flower in this manner? Do Khaenri'ah truly hate Teyvat that much? Or is the naming done so because of a fondness for Teyvat? Just a heads-up, 因为 (Yinwei) is used with a passive voice, thus why I wonder in what manner did the naming came to be.`}</p>
    <p>{`Regardless, the flower themselves mean a lot to your twin. While the very goal (as confirmed once more by your twin) of the Abyss Order is to strike back at Celestia, their very reason to do so is for Khaenri'ah. Without seeing the true face behind the Abyss Order, we could never empathize with their goal. Now that we do see them, I find myself empathizing with the victims.`}</p>
    <p>{`But is what the Abyss Order aiming for... really for the good of Khaenri'ah? Dain don't think so. In fact, the story wants you to mull about this point for a bit.`}</p>
    <Image name="genshin-impact-2.6-archon-quest-screenshot-1.png" caption="Maybe what this act wanted to tell us is this - Your twin isn't seeing the full picture here. Are they really doing this for Khaenri'ah? I have a dastard feeling our twin is being manipulated..." alt="2.6 Archon Quest Screenshot 1" mdxType="Image" />
    <p>{`Your twin is so dead set at shooting down Celestia that they admit, they should have focused on their people first. But they proceed to absolutely ignore the part where Khaenri'ah people will and most definitely, die as they suffer from the spring's amplification. That doesn't seem right...?`}</p>
    <p>{`The answer is simple. `}<strong parentName="p">{`Your twin is only focused on revenge for the nation Khaenri'ah, not it's people.`}</strong>{` Dain is right when he says they don't care about the people. Dain thinks your twin is not only wrong with where they put resolve in, he also believes that the gods are not to be trifled with. The Abyss Order's very goal is pure folly, thus why your twin must be stopped.`}</p>
    <p>{`The chapter ends with Dain tossing a jabbing remark at you. Seriously Dain, after all we've been through... You've seen enough atrocity of each side to form your own thoughts - the Abyss Order and Celestia. Who will you side with? Will you protect what's left, or fight for a destroyed nation?`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Answered Question #5: The Abyss Order underlings are new to the job.`}</h2>
    <p>{`We knew that everyone in the Abyss Order is Khaenri'ahn. But this chapter completely threw me out of the loop. First, we learned that Khaenri'ahns are inflicted with erosion. Those who have a strong will are able to resist the erosion to an extent, thus they are able to keep their sense of self. For example, Dain and Halfdan. When the amplifier is activated, only hillichurls, the Black Serpent Knights and Dain reacted. The Abyss Lector who activated the amplifier did not.`}</p>
    <p>{`This proves that Abyss Heralds/Lectors/whatnot and possibly gang gang woo as well, are new to the team. Even if this is disproved by some technicality, at the very least we know that they don't have the curse. Are they created after the fall of Khaenri'ah? Or did they get recruited(?) after the Cataclysm? Maybe they ARE Khaenri'ahn, but they somehow didn't get the curse? Well for starters, Kaeya exists and he definitely didn't have the curse. Questions, questions... We could only assume for now, but I'll throw in another bone.`}</p>
    <p>{`In the "Chasm Delvers" chain quest, you'll eventually reach a quest where you'll need to "purify" all 5 locks to access the Celestian pillar. Abyss monsters will attack you while you purify the locks. I noticed that if you do not defeat the Abyss monsters but instead, focus on purifying the locks, the monsters gets "purified" once you finish the job.`}</p>
    <p>{`Huh, interesting. Assuming this is not a game mechanic and is tied to lore, they don't have the curse, but they can be purified. I wager there are more elements than the 7, and we're being fed tidbit info about them.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`New Question #1: Wait... Is erosion really from the Sustainer of Heavenly Principles as implied by Zhongli in his Story Quest?`}</h2>
    <p>{`Now that I've written down the things I believe this chapter has answered, let's move on to the ones that it introduces. EROSION! Urgh, this has been on my mind since I finished the Archon Quest long enough that I have silly theories about it. I was looking around the area where the ancient civilization spring is, and I saw... the very same statue lining up Enkanomiya ruin.`}</p>
    <p>{`Huh, what is going on? How did an upside down spring soothe the curse of immortality? We understood the effects of erosion, but `}<strong parentName="p">{`what exactly is erosion`}</strong>{`? My small pea brain would have thought it normal until I start mulling about the new info this act has told us.`}</p>
    <p>{`First and foremost, we found out that Khaenri'ahn folks are cursed to be "immortal", but the negative effects are strongly implied to be leaning towards "erosion". Isn't this what the Archons and Azhdaha are inflicted with? Their memories slowly chipped away beyond their control until they lost their self-awareness... I could theorize a few possibilities from what we know:`}</p>
    <ol>
      <li parentName="ol">{`If we understood erosion as the law of Teyvat, then it comes naturally to all who are long-lived. Mortals who are NOT supposed to live long couldn't withstand it, thus they had it worse than the adepti(s) and youkai(s). Therefore, a "curse". This also proves that the "gods" of Celestia simply used what was already there and manipulated it for their own use.`}</li>
      <li parentName="ol">{`If erosion is instead, the power of Celestia and by extension, the Sustainer of Heavenly Principles's... Does that mean that there is a way to counteract it? Trying to go against a dictator's power versus a natural law does seem more plausible. Is this why the ancient civilization with that convenient spring was destroyed?`}</li>
      <li parentName="ol">{`If either (1.) or (2.) is correct, then Azhdaha could have possibly clued us in. He mentioned that Zhongli once shared his powers to slow down his erosion, but it was in vain. Perhaps the power he mentioned is from Celestia and by extension, the... Gnosis? Does that mean that Celestia has the power to slow down erosion as well? Or maybe Zhongli is just that powerful back then.`}</li>
    </ol>
    <p>{`Theory 1 makes more sense here, and perhaps, it is all there is. Maybe I thought too deeply about this... `}<strong parentName="p">{`I ultimately do not know.`}</strong>{` These are all my silly theories after all. We don't know anything about Celestia save for the floating abode I keep seeing in the sky, and way too little about this Enkanomiya civilization.`}</p>
    <Ads mdxType="Ads" />
    <h2>{`New Question #2: Just what is Khaenri'ah's sin? Could you please explain before you leave, Dain?`}</h2>
    <p>{`We knew they did something, but what... exactly? Being technologically advanced doesn't answer the question. After all, your twin wouldn't be so adamant on trying to claw his way up to Celestia otherwise. The gods must have reacted to something in Khaenri'ah to demolish an entire nation. But what... exactly? We know Rhinedottir has a hand in this with those creepy wolves she created, but what is the full story?`}</p>
    <p>{`I wouldn't have thought too deeply into this if not for your twin's reaction. It felt as if your twin strongly believes that the calamity wrought upon Khaenri'ah is unjust. But what we have heard from the game's story so far implies that Khaenri'ah brought it all on themselves. So what's the real story?`}</p>
    <h2>{`New Question 3: What is special about Dain that he retained his sanity for so long?`}</h2>
    <p>{`I had to re-read the entire act's dialogue just to catch this one. Dain briefly mentioned that he retained his self-awareness because he has "it". Just what is... "it"? It does feels strange that Dain is the most lucid of all Khaenri'ah knights with the curse, doesn't it?`}</p>
    <p>{`Definitely makes you wonder. Pretty boy in blue isn't telling us the full story here.`}</p>
    <h2>{`All in all`}</h2>
    <p>{`There's a lot to take in for 2.6's patch. Even the World Quests gave us lore crumbs! I just finished the quest that requires you to take 5 photos of fossils and give them to the Sumeru NPC. I strongly feel that Teyvat is upside down but this quest takes the cake. It's possible that Teyvat is Celestia-made as well!`}</p>
    <Image name="genshin-impact-2.6-world-quest-screenshot-1.png" caption="The fossils aren't form naturally? I- what-...? Does this mean the bedrock supporting Teyvat is Celestia-made? I have no clue." alt="2.6 World Quest Screenshot 1" mdxType="Image" />
    <p>{`Maybe this entire write-up will be proven false later. Maybe I'm thinking about the whole erosion thing the wrong way. Nevertheless, I finally got this out of my chest after double-checking the game's dialogue for 1 whole day. Thank you for reading.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      