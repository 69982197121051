import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXBlogpost/Layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Ads = makeShortcode("Ads");
const Video = makeShortcode("Video");
const LocalLink = makeShortcode("LocalLink");
const RemoteLink = makeShortcode("RemoteLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I wouldn't say I'm the greatest fan of Kalafina nor am I the most knowledgeable about the group. But the group has amassed such a great following when it comes to memorable anisongs, anyone who's in the loop would have heard of their songs at least once. Their unique operatic-ish singing can be heard from a drop of the pin. Just the first verse and everyone goes, `}<em parentName="p">{`"Oh, that's Kalafina!"`}</em></p>
    <p>{`I do agree that their voices are hypnotic, but what draws me to the group is their accomplished songwriter... `}<strong parentName="p">{`Yuki Kajiura`}</strong>{`. I'm of the strong opinion that Yuki Kajiura is the very backbone of Kalafina. Without her, Kalafina wouldn't have enjoyed the popularity that it did back when the group is active.`}</p>
    <Ads mdxType="Ads" />
    <p>{`I first know of Yuki Kajiura from a PS2 video game, strange enough. I'm a big fan of Xenosaga 3 on PS2, as well as their biggest fan of the VGM. Xenosaga is not exactly a happy-go-lucky game so the tune choices are to be expected. Nevertheless, the music tugs at the heartstring at every chance it can. The cutscenes emotional impact wouldn't have felt the same without the music! Plus, one thing about Yuki Kajiura... is how much she loves adding evocative vocal whenever she can.`}</p>
    <Video src="https://www.youtube.com/embed/1HM8RUtKBcU" title="Xenosaga 3 OST" mdxType="Video" />
    <p>{`Fast-forward to 2005, I encountered Yuki Kajiura's songs again. This time, in Tsubasa Chronicles, an anime! Once again, she's writing music for another depressing-ish show. Yuki Kajiura has a distinctive style that you could quickly pick up. One of my favourite songs from the show is `}<em parentName="p">{`I talk to the rain`}</em>{`. It often plays and `}<strong parentName="p">{`always`}</strong>{` in a heartbreaking scene. If you have not watched the show, I suggest the manga instead! The anime is not a full adaptation. Plus, it wasn't exactly true to the story's theme. Read the manga!`}</p>
    <Video src="https://www.youtube.com/embed/OisxV-pJwZE" title="Tsubasa Chronicles OST" mdxType="Video" />
    <p>{`Yuki Kajiura has always been a popular composer, and her name became super iconic after Tsubasa Chronicle's release. Next thing I knew, I heard her songs again (sang under Kalafina) in Madoka Magica and Fate franchises. I knew she enjoys writing melodies that uses a lot of strings to evoke sadness, but an operatic approach to anisongs? That's a first!`}</p>
    <Ads mdxType="Ads" />
    <h2>{`Kalafina Songs in Madoka Magica`}</h2>
    <p>{`Everyone knows Magia right? I hope you aren't the type of viewer that skips anime ending songs!`}</p>
    <Video src="https://www.youtube.com/embed/QwquipMpsiQ" title="Madoka Magica OST" mdxType="Video" />
    <p>{`Another favourite of mine is Hikariful! I was really moved when I watched the last Madoka Magica episode. The "no vocal" version is pretty fab, but the full song is even better.`}</p>
    <Video src="https://www.youtube.com/embed/fjj5WbtenTM" title="Madoka Magica OST" mdxType="Video" />
    <h2>{`Kalafina Songs in Fate franchise`}</h2>
    <p>{`I actually found most of their anisongs for the Fate franchise on Spotify. Almost all of Kalafina's songs are a banger, so I often play their playlist on shuffle for hours.`}</p>
    <Video src="https://www.youtube.com/embed/v6vwADf_qzQ" title="Fate Stay/Night OST" mdxType="Video" />
    <Ads mdxType="Ads" />
    <Video src="https://www.youtube.com/embed/-su426eFStM" title="Fate Stay/Night OST" mdxType="Video" />
    <p>{`I find myself not super invested in Fate Stay/Night (UBW) because it just feels like it is always padding for time. It's alright in general. But whoa, when it comes to Fate Zero? Fantastic show! It's made better with a great opening song by Kalafina :D`}</p>
    <Video src="https://www.youtube.com/embed/b1bXu0XZDlU" title="Fate Zero OST" mdxType="Video" />
    <h2>{`Kalafina Song in Kuroshitsuji`}</h2>
    <p>{`Lacrimosa is one of the first songs that got me into Kalafina! Kuroshitsuji is so popular back then but the popularity kinda went poof after some time. `}<LocalLink to="/blog/3-shounen-manga-that-everyone-seems-to-have-forgotten/" mdxType="LocalLink">{`I wrote a piece on Kuroshitsuji and other two popular shounen that is still ongoing`}</LocalLink>{` but no one seems to be talking about them. If you wish to travel down the memory lane, do give that post a try!`}</p>
    <Video src="https://www.youtube.com/embed/6kgJBzCXr4Y" title="Kuroshitsuji OST" mdxType="Video" />
    <Ads mdxType="Ads" />
    <h2>{`Kalafina Song in Aldnoah.Zero`}</h2>
    <p>{`Yet another banger song. The same can't be said for the show's ending, however.`}</p>
    <Video src="https://www.youtube.com/embed/ZwfjS5CvedM" title="Aldnoah.Zero OST" mdxType="Video" />
    <h2>{`Kalafina Song in Arslan Senki`}</h2>
    <p>{`I chastise folks who skip anime ending but I too, am guilty of this. I watched Arslan Senki `}<em parentName="p">{`without`}</em>{` watching the ending once. This song quickly became my favourite when I found it on Spotify!`}</p>
    <Video src="https://www.youtube.com/embed/mxABgqQdX7g" title="Arslan Senki OST" mdxType="Video" />
    <h2>{`All in all`}</h2>
    <p>{`I listed some of the songs that I think is the best from their list. I personally enjoyed their other tracks too. Hikari no Senritsu, storia... Superb songs with equally evocative singing by the girls. I was shocked when I heard the vocal group broke up after Yuki Kajiura left. Man, things aren't the same anymore...`}</p>
    <p>{`While writing this post, I discovered that they actually uploaded their songs on their `}<RemoteLink to="https://www.youtube.com/channel/UCnRbD__LEKBj_ZR_dC8KmzQ" mdxType="RemoteLink">{`official YouTube channel`}</RemoteLink>{`. Do check that channel out for great Kalafina MV(s)!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      